/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-29 11:15:31
 * @LastEditTime: 2019-08-29 20:20:54
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import actions from '../../store/actions'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  isFetchingUser: state.user.isFetchingUser,
  info: state.user.info,
  isLogin: state.user.isLogin,
  userFetchFailed: state.user.userFetchFailed,
  courseCloningProgress: state.courses.courseCloningProgress,
  isShowPopover: state.courses.isShowPopover,
  courseCloneTitle: state.courses.courseCloneTitle,
  courseCloneId: state.courses.courseCloneId,
  failOrgLoad: state.organizations.orgListFail
})

const mapDispatchToProps = dispatch => ({
  clearCourseCloning: () => dispatch(actions.courses.clearCourseCloning()),
  showPopover: params => dispatch(actions.courses.showPopover(params)),
  getUser: () => dispatch(actions.user.getUser()),
  getOrganizations: () => dispatch(actions.organizations.get()),
  getTopics: () => dispatch(actions.topics.getTopics()),
  getOrganizationsAuto: () => dispatch(actions.organizations.getAutoComplete())
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
