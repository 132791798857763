import React, { Component } from 'react'

import { SearchInput } from './slyled'

export default class extends Component {
  render () {
    const props = this.props

    return (
      <SearchInput {...props} />
    )
  }
}
