import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import Gallery from './Gallery'

const mapStateToProps = state => ({
  library: state.library,
  topics: state.topics,
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
  getLibraryList: actions.library.getLibraryList,
  getPublicList: actions.library.getPublicList,
  getOrgList: actions.library.getOrgList,
  download: actions.library.download,
  upload: actions.library.upload,
  clearUploadList: actions.library.clearUploadList,
  updateTopicList: actions.topics.updateTopicList
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Gallery)
