import React, { Component } from 'react'
import { Icon } from 'antd'
import { Audio, Stream } from './components'
import { builder } from '../../../constants'
import './Preview.scss'
import { useTranslation } from 'react-i18next'
import { customContentClass } from './Preview.ctrl'

const { types, icons } = builder

const Placeholder = ({ type }) => {
  const { t } = useTranslation()
  return (
    <div className="el-placeholder">
      <div className="el-placeholder__icon">
        <Icon type={icons[type]} />
      </div>
      <div className="el-placeholder__text">
        {types[type] &&
          types[type].toLowerCase() +
            ` ${t('general:component').toLowerCase()}`}
      </div>
    </div>
  )
}

const TextPreview = props => {
  const {
    node: { params },
    scale = 1
  } = props
  return (
    <div className="preview">
      <div
        className="ql-editor"
        style={{
          fontSize: `${14 * scale}px`
        }}
        dangerouslySetInnerHTML={{ __html: params.content }}
      />
    </div>
  )
}

const ImagePreview = props => {
  const {
    node: { params }
  } = props
  return (
    <div
      className="preview preview_image"
      style={{
        backgroundSize: 'cover',
        backgroundImage: 'url(' + params.content + ')'
      }}
    />
  )
}

// {
/*<div
  className="preview preview_image"
  style={{
    backgroundSize: 'cover',
    backgroundImage: 'url(' + params.content + ')',
    position: 'absolute'
  }}
>
  <h2 style={{position: 'absolute', color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.4)'}}>[ AR ]</h2>
</div>*/
// }

const VideoPreview = props => {
  const {
    node: { params }
  } = props
  const isVimeo = params.content.indexOf('vimeo') > 0

  if (isVimeo) {
    return (
      <div className="embed-container">
        <iframe
          title="video"
          src={params.content + '?color=0068FF&title=0&byline=0&portrait=0'}
          frameBorder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />
      </div>
    )
  }

  return (
    <div className="preview preview_video">
      <video
        controls
        style={{ width: '100%', height: '100%', backgroundSize: 'cover' }}
      >
        <source src={params.content} />
      </video>
    </div>
  )
}

const AnnotationPreview = props => {
  const {
    node: { params }
  } = props
  const { t } = useTranslation()
  return (
    <div className="preview preview_annotation">
      {t('labels:id_annotation')}: {params.content}
    </div>
  )
}

const BackgroundPreview = props => {
  const {
    node: { params }
  } = props
  if (props.node.type === 'BACKGROUND_IMAGE' && params.content !== null) {
    return (
      <div
        className="preview preview_background"
        style={{
          backgroundImage: 'url(' + params.content + ')'
        }}
      />
    )
  }
  if (
    props.node.type === 'BACKGROUND_IMAGE_AUTO' &&
    props.node.auto &&
    params.content !== null
  ) {
    return (
      <div
        className="preview preview_background"
        style={{
          backgroundImage: 'url(' + params.content + ')'
        }}
      />
    )
  }
  return (
    <div
      className="preview preview_background"
      style={{
        backgroundImage: ''
      }}
    />
  )
}

const QuizPreview = props => {
  const {
    node: { params }
  } = props
  const { t } = useTranslation()

  return (
    <div className="preview preview_annotation">
      {params.isSimulation ? 'SimulationID' : t('labels:id_quiz')}:{' '}
      {params.content}
    </div>
  )
}

const ScenarioPreview = props => {
  const {
    node: { params }
  } = props

  return (
    <div className="preview preview_annotation">
      ScenarioID: {params.content}
    </div>
  )
}

export default class Preview extends Component {
  render() {
    const {
      node,
      node: { params },
      isView,
      elementHeight,
      elementWidth,
      scale
    } = this.props
    if (
      params &&
      !params.content &&
      node.type !== types.BACKGROUND_IMAGE &&
      node.type !== types.BACKGROUND_IMAGE_AUTO
    ) {
      return (
        <div className={customContentClass(node.type === types.TEXT)}>
          <Placeholder type={node.type} />
        </div>
      )
    }

    return (
      <div className={customContentClass(node.type === types.TEXT, isView)}>
        {node.type === types.TEXT && <TextPreview node={node} scale={scale} />}
        {node.type === types.IMAGE && <ImagePreview node={node} />}
        {node.type === types.ARIMAGE && <ImagePreview node={node} />}
        {node.type === types.VIDEO && <VideoPreview node={node} />}
        {node.type === types.AUDIO && (
          <Audio
            elementHeight={elementHeight}
            elementWidth={elementWidth}
            node={node}
          />
        )}
        {node.type === types.ANNOTATION && <AnnotationPreview node={node} />}
        {(node.type === types.BACKGROUND_IMAGE ||
          node.type === types.BACKGROUND_IMAGE_AUTO) && (
          <BackgroundPreview node={node} />
        )}
        {node.type === types.QUIZ && <QuizPreview node={node} />}
        {node.type === types.VIDEO_STREAM && <Stream node={node} />}
        {node.type === types.SCENARIO && <ScenarioPreview node={node} />}
      </div>
    )
  }
}
