import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'
import {
  TopicsInput,
  ImageSelect,
  AllowAccess
} from '../../../../../../../components/Form'
import _ from 'lodash'
import ChannelContext from '../../ChannelContext'

function ChannelFormMain(props) {
  const { t, form, updateChannel, createChannel, id } = props

  const { apiData, channelData, setChannelData } = useContext(ChannelContext)

  const { getFieldDecorator } = form

  const lastValue = useRef(null)

  const handleSubmit = () => {
    form.validateFields(() => {
      const { cover_id, cover, title } = channelData

      const coverId = cover_id || (cover && cover.id)

      if (!coverId || !title) return

      if (id) {
        updateChannel()
      } else {
        createChannel()
      }
    })
  }

  useEffect(() => {
    if (lastValue.current) {
      const { param, value } = lastValue.current
      if (channelData[param] === value) {
        handleSubmit()
      }
    }
  }, [channelData])

  const onTopicsChange = arr => {
    updateChannelState(
      'topics',
      arr.map(item => item.id)
    )
  }

  const onCoverChange = ({ id }) => {
    updateChannelState('cover_id', id)
  }

  const onTitleChange = useMemo(
    () => _.debounce(v => handleTitleChange(v), 1000),
    []
  )

  const onAllowAccessEmailsChange = useMemo(
    () => _.debounce(v => handleAllowAccessEmailsChange(v), 1000),
    []
  )

  const onDescriptionChange = useMemo(
    () => _.debounce(v => handleDescriptionChange(v), 1000),
    []
  )

  const handleTitleChange = title => {
    updateChannelState('title', title)
  }

  const handleAllowAccessEmailsChange = val => {
    updateChannelState('host_emails', val)
  }

  const handleDescriptionChange = description => {
    updateChannelState('description', description)
  }

  const updateChannelState = (param, value) => {
    console.log({ updateChannel: param }, 'testt')
    setChannelData(prev => ({ ...prev, [param]: value }))

    lastValue.current = {
      param,
      value
    }
  }

  return (
    <Form>
      <Form.Item label={t('labels:title')}>
        {getFieldDecorator('title', {
          rules: [
            {
              required: true,
              message: t('v4:input_your_channel_name')
            }
          ],
          initialValue: channelData.title || apiData.title
        })(<Input onChange={e => onTitleChange(e.target.value)} />)}
      </Form.Item>

      <TopicsInput
        defaultTopics={apiData.topic_list}
        onTopicsChange={onTopicsChange}
      />

      <AllowAccess
        form={form}
        onChange={onAllowAccessEmailsChange}
        defaultHostEmails={channelData.host_emails || apiData.host_emails}
      />

      <ImageSelect coverInput={apiData.cover} onCoverChange={onCoverChange} />

      <Form.Item
        style={{ marginTop: '20px' }}
        label={t('labels:description')}
        colon={false}
        required={false}
      >
        {getFieldDecorator('description', {
          initialValue: channelData.description || apiData.description
        })(
          <Input.TextArea
            placeholder={t('v4:text')}
            autoSize={{ minRows: 5, maxRows: 20 }}
            onKeyDown={e => {
              if (
                e.keyCode === 32 &&
                (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                  e.target.value.charAt(e.target.selectionStart) === ' ')
              ) {
                e.preventDefault()
              }
            }}
            onChange={e => onDescriptionChange(e.target.value)}
            style={{ fontSize: '1.6rem', resize: 'none' }}
            wrap={'soft'}
          />
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create()(withTranslation()(ChannelFormMain))
