import React, { Fragment, useEffect, useState } from 'react'
import './AddCover.scss'
import { ImageSelect } from '../../../../../components/Form'

const AddCover = ({ t, selectedCover, setSelectedCover, coverError }) => {
  const [cover, setCover] = useState(null)
  useEffect(() => {
    if (selectedCover.id) {
      setCover(selectedCover)
    }
  }, [selectedCover])
  return (
    <Fragment>
      <ImageSelect
        label={t('v4:cover_picture')}
        coverInput={cover}
        onCoverChange={setSelectedCover}
      />
      {coverError && (
        <div className="cover_content_upload">
          <div
            style={{
              color: '#ff4d4f',
              fontSize: '12px',
              height: '1.5rem',
              textAlign: 'left'
            }}
          >
            {t('v3:required_cover_image')}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default AddCover
