import React, { Component } from 'react'
import { Form, Descriptions } from 'antd'
import { withTranslation } from 'react-i18next'

class ZoomForm extends Component {
  render() {
    const { t } = this.props
    return (
      <section className="section" style={{ padding: '2.5rem 0' }}>
          <Form>
          {/* <Row>
          <Col md={6}>
            <p>Supports up to 5000 participants for the event</p>
          </Col>
          <Col md={6}>
            <p>Support up to 10 live video participants</p>
          </Col>
          </Row> */}
          <Descriptions title={t('v2:zoom_is_not_integrated')}>
            <Descriptions.Item>{t('v2:integrate_zoom_to_start_meeting')}</Descriptions.Item>
          </Descriptions>
        </Form>
        </section>
    )
  }
}

export default withTranslation()(ZoomForm)
