import React, { useState, useMemo } from 'react'

import { Alert, Divider, Icon, Pagination, Tabs } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'
import moment from 'moment'
import { Search } from '../../../../../../../components'
import { getMinutes } from '../../../../../../../utils'
import { userImageNoBack } from '../../../../../images'
import TableInfoColumns from './internal/TableInfoColumns'
import TableCoverImage from './internal/TableCoverImage'

const { TabPane } = Tabs

const customExpandIcon = props => {
  return <Icon type={props.expanded ? 'up' : 'down'} {...props} />
}

const UserEventTable = props => {
  const {
    t,
    data: {
      events: { data, loading }
    }
  } = props

  const eventColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      {
        title: t('reports:start_end_live'),
        dataIndex: 'startend',
        render: (text, item) => {
          return (
            <>
              {item.stream_start !== null
                ? `${moment(item.stream_start).format('HH:mm')}`
                : item.start_conference !== null
                ? `${moment(item.start_conference).format('HH:mm')}`
                : '- -'}
              |
              {item.stream_stop !== null
                ? `${moment(item.stream_stop).format('HH:mm')}`
                : item.stop_conference !== null
                ? `${moment(item.stop_conference).format('HH:mm')}`
                : '- -'}{' '}
            </>
          )
        }
      },
      {
        title: t('reports:live_date'),
        dataIndex: 'start_date_time',
        render: item => moment(item).format('MMM D, YYYY')
      },
      {
        title: t('reports:join_leave_date'),
        dataIndex: 'joinleave',
        render: (text, item) => {
          return (
            <>
              {item.connected_at !== null
                ? `${moment(item.connected_at).format('HH:mm')}`
                : '- -'}
              |
              {item.disconnected_at !== null
                ? `${moment(item.disconnected_at).format('HH:mm')}`
                : '- -'}
            </>
          )
        }
      },
      {
        title: t('reports:watched_time'),
        dataIndex: 'time',
        render: (text, item) => {
          return (
            <>
              {item.connected_at === null || item.disconnected_at === null
                ? '- -'
                : getMinutes(item.connected_at, item.disconnected_at)}
            </>
          )
        }
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(data.length)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const events = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  console.log('check data log', total)

  return (
    <ReportTable
      isDateActive
      borderRadius
      columns={eventColumns}
      loading={loading}
      dataSource={events}
      expandIconAsCell={false}
      expandIconColumnIndex={6}
      expandIcon={customExpandIcon}
      expandRowByClick
      pagination={false}
      expandedRowRender={record => {
        return expandEventRow(record)
      }}
      title={() => (
        <>
          <div className="user-report-table-title">
            <div>
              <SearchEventMargin>
                <Search
                  placeholder={t('reports:search_event')}
                  onChange={e => onSearch(e.target.value)}
                />
              </SearchEventMargin>
            </div>
            <div className="user-report-table-pagination">
              <span>{t('general:page').toUpperCase()}</span>
              <PaginationRightAligned>
                <Pagination
                  total={total}
                  simple
                  current={currentPage}
                  pageSize={10}
                  onChange={onPaginationChange}
                />
              </PaginationRightAligned>
            </div>
          </div>
          <Divider style={{ margin: '0px' }} />
        </>
      )}
    />
  )
}

const expandEventRow = record => {
  const totalPolls = record.poll_vote ? record.poll_vote.length : 0
  const totalComments = record.comment ? record.comment.length : 0
  return (
    <div className="card-container">
      <Tabs type="card">
        <TabPane tab={`Polls (${totalPolls})`} key="1">
          {record.poll_vote &&
            record.poll_vote.length > 0 &&
            record.poll_vote.map((item, i) => (
              <div className="poll_data" key={i}>
                {item && (
                  <>
                    <h3>Q: {item.question}</h3>
                    <p>A: {item.answer}</p>
                  </>
                )}
              </div>
            ))}
          {totalPolls === 0 && (
            <Alert
              message="Sorry ! No Polls are available for this event."
              type="info"
              showIcon
            />
          )}
        </TabPane>
        <TabPane tab={`Comments (${totalComments})`} key="2">
          {totalComments > 0 &&
            record.comment.map(item => (
              <div className="user-comment">
                <img
                  alt=""
                  className="user-image"
                  src={!item.avatar ? userImageNoBack : item.avatar}
                />
                <div className="user-comment-info">
                  <h5>
                    {item.username} <span className="circle" />{' '}
                    {moment(item.date).fromNow()}
                  </h5>
                  <p>{item.message}</p>
                </div>
              </div>
            ))}
          {totalComments === 0 && (
            <Alert
              message="Sorry ! No Comments are available for this event."
              type="info"
              showIcon
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default UserEventTable
