import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as userActions from '../../../store/actions/user'
import actions from '../../../store/actions'

import View from './View'

export default connect(
  state => ({
    user: state.user,
    dashboardCourses: state.courses.dashboardCourses
  }),
  dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    getDashboardCourses: params =>
      dispatch(actions.courses.getDashboardCourses(params))
  })
)(View)
