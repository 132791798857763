import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CourseCreateMain from './CourseCreateMain'

import actions from '../../../../../store/actions'

import * as courseBuilderActions from '../../../../../store/actions/courseBuilder'
import * as lessonBuilderActions from '../../../../../store/actions/lessonBuilder'

export default connect(
  state => ({
    courseBuilder: state.courseBuilder,
    course: state.courses.courseById,
    topics: state.topics,
    user: state.user
  }),
  dispatch => ({
    courseBuilderActions: bindActionCreators(courseBuilderActions, dispatch),
    fetchLessonById: bindActionCreators(
      lessonBuilderActions.fetchLessonById,
      dispatch
    ),
    upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
    updateUser: (id, data, dbData) => dispatch(actions.user.updateUser(id, data, dbData)),
    createGroup: (orgId, data) =>
    dispatch(actions.organizations.createGroup(orgId, data)),
    reorderLessons: array =>
      dispatch(actions.courseBuilder.reorderLessons(array)),
    updateTopicList: newTopic =>
      dispatch(actions.topics.updateTopicList(newTopic))
  })
)(CourseCreateMain)
