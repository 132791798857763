import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../../store/actions'
import * as courseBuilderActions from '../../../../../../store/actions/courseBuilder'

import Template from './Template'

export default connect(
  state => ({
    courses: state.courses,
    audioSynthesizer: state.audioSynthesizer,
    courseBuilder: state.courseBuilder,
    library: state.library
  }),
  dispatch => ({
    upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
    getWorkshopCourses: params =>
      dispatch(actions.courses.getWorkshopCourses(params)),
    getLessonsById: id => dispatch(courseBuilderActions.getLessonsList(id)),
    getVoices: bindActionCreators(
      actions.audioSynthesizer.listTTSAudioSynthesizer,
      dispatch
    )
  })
)(Template)
