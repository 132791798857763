import { createContext } from 'react'

const ChannelContext = createContext({
  title: '',
  cover_id: '',
  topics: [],
  host_emails: [],
  description: ''
})

export default ChannelContext
