import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Cards, Spinner, Search, TopPageLink } from '../../../components'
import history from '../../../history'
import { HelpMessage, Row } from './styled'
import { Pagination } from 'antd'
import './List.scss'
import { api } from '../../../services'

const CourseEventTemplate = ({
  data,
  firstFetch,
  secFetch,
  t,
  app,
  type,
  mode,
  message
}) => (
  <section className="section section_light">
    {firstFetch || secFetch ? (
      <Spinner size="small" />
    ) : data.length ? (
      <Cards
        data={data}
        type={type}
        mode={mode}
        width={30}
        onClick={app.handleClick}
        harizontal
      />
    ) : (
      <HelpMessage>{t(`${message}`)}</HelpMessage>
    )}
  </section>
)

class TrainingPage extends Component {
  state = {
    search: '',
    page: 1,
    pageSize: 10,
    dataList: [],
    count: 0,
    isFetching: true
  }

  componentDidMount() {
    this.fetchData()
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  fetchData = () => {
    const {
      match: { params }
    } = this.props
    const { mode } = params
    const { search, page, pageSize } = this.state
    const data = {
      search,
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      mode
    }
    api.assessments.getAll(data).then(resp => {
      const {
        data: { rows: assessmentsData, count }
      } = resp
      const nowLiveAssessment =
        assessmentsData.length > 0
          ? assessmentsData.map(i => {
              i.type = 'Live Assessment'
              return i
            })
          : []
      this.setState({
        isFetching: false,
        dataList: nowLiveAssessment,
        count
      })
    })
  }

  handleClick = item => {
    history.push(`/assessments/${item.id}`)
  }

  onSearch = _.debounce(
    value =>
      this.setState(
        {
          search: value,
          page: 1
        },
        () => {
          this.fetchData(true)
        }
      ),
    1000
  )
  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true
      },
      () => {
        this.fetchData(true)
      }
    )
  }

  render() {
    const {
      t,
      match: { params }
    } = this.props
    const { mode } = params
    const { page, pageSize, dataList, isFetching, count } = this.state
    return (
      <Fragment>
        <section className="section">
          <Row style={{ alignItems: 'center' }}>
            <div className="head head_nopadding">
              <TopPageLink page={t('title_assessment')} path="/assessments" />
            </div>
            <div className="head head_nopadding search">
              <Search
                placeholder={t('training:search_assessment')}
                onChange={e => this.onSearch(e.target.value)}
              />
            </div>
          </Row>
        </section>

        {/* Now Live Assessment */}
        <CourseEventTemplate
          data={dataList}
          firstFetch={isFetching}
          secFetch={null}
          title={'now_live_assessment_title'}
          sub_title={'now_live_assessment_subTitle'}
          message={'now_live_assessment_message'}
          type={'Now Live Assessment'}
          mode={mode}
          t={t}
          app={this}
          total={count}
          current={page}
          pageSize={pageSize}
        />
        <div className="section section_light">
          <Pagination
            total={count}
            current={page}
            pageSize={pageSize}
            onChange={this.onChangePage}
          />
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('training')(TrainingPage)
