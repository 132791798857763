import styled from 'styled-components'

const ExistingLesson = styled.div`
`

ExistingLesson.Search = styled.div`
  margin-bottom: 2rem;
  padding: 0 2rem;
`

export {
  ExistingLesson
}
