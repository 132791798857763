import * as user from './user'
import * as courses from './courses'
import * as library from './library'
import * as organizations from './organizations'
import * as audioSynthesizer from './audioSynthesizer'
import * as annotation from './annotation'
import * as roles from './roles'
import * as quizes from './quizes'
import * as lessons from './lessons'
import * as reports from './reports'
import * as assessments from './assessments'
import * as discussion from './discussion'
import * as topics from './topics'
import * as events from './events'
import * as help from './help'
import * as notes from './note'
import * as badge from './badge'
import * as certificate from './certificate'
import * as maze from './maze'
import * as scorm from './scorm'
import * as skillJourney from './skillJourney'
import * as reviews from './reviews'
import * as summarization from './summarization'
import * as calendar from './calendar'
import * as channel from './channel'
import * as marketplace from './marketplace'
import * as sharebot from './sharebot'
import * as midJourney from './midjourney'
import * as trivia from './trivia'
import * as podcast from './podcast'

export default {
  user,
  courses,
  audioSynthesizer,
  lessons,
  library,
  organizations,
  annotation,
  roles,
  quizes,
  reports,
  assessments,
  discussion,
  topics,
  events,
  help,
  notes,
  reviews,
  badge,
  certificate,
  maze,
  scorm,
  skillJourney,
  summarization,
  calendar,
  channel,
  marketplace,
  sharebot,
  midJourney,
  trivia,
  podcast
}
