import React from 'react'
import { Button, Typography, Tooltip } from 'antd'

import './index.css'

const { Title } = Typography

const FormHeader = ({
  t,
  isMarketPlace,
  onToggleMarketPlace,
  courseId,
  isAdmin,
  hide
}) => {
  console.log('isAdmin', isAdmin)
  return (
    <div className="create-course">
      <div className="create-course__content">
        <Title level={3}>
          {hide ? t('v4:new_scorm_course') : t('workshop:new_course')}
          {isMarketPlace ? ' (Market Place)' : ''}
        </Title>
      </div>
      <div className="button-marker_place-div">
        <Tooltip
          placement="top"
          title={
            !isAdmin ? t('v4:not_admin') : courseId ? '' : t('v4:no_course')
          }
        >
          {!hide && (
            <Button
              size="large"
              shape="round"
              onClick={onToggleMarketPlace}
              className="button-blue_color"
              disabled={!isAdmin ? true : courseId ? false : true}
            >
              {isMarketPlace
                ? 'Save & Continue'.toUpperCase()
                : t('buttons:market_place').toUpperCase()}
            </Button>
          )}
        </Tooltip>
      </div>
    </div>
  )
}

export { FormHeader }
