/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-20 11:47:36
 * @LastEditTime: 2019-08-29 20:44:06
 * @LastEditors: Please set LastEditors
 */
const ModalStyles = {
  padding: '0 !important'
}

export { ModalStyles }
