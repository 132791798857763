import { 
  LIST_SKILL_JOURNEY, 
  FETCHING_SKILL_JOURNEY,
  WORKSHOP_SKILLJOURNEYS_SUCCESS,
  WORKSHOP_SKILLJOURNEYS_FETCHING,
  WORKSHOP_SKILLJOURNEYS_HAS_MORE,
 } from '../types'

const initialState = {
  fetch: false,
  list: [],
  workshopSkillJourneys: {
    list: [],
    fetch: false,
    hasMore: true
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_SKILL_JOURNEY:
      return {
        ...state,
        list: action.payload
      }

    case FETCHING_SKILL_JOURNEY:
      return {
        ...state,
        fetch: action.payload
      }

    case WORKSHOP_SKILLJOURNEYS_SUCCESS:
      return {
        ...state,
        workshopSkillJourneys: {
          ...state.workshopSkillJourneys,
          list: action.payload
        }
      }

    case WORKSHOP_SKILLJOURNEYS_FETCHING:
      return {
        ...state,
        workshopSkillJourneys: {
          ...state.workshopSkillJourneys,
          fetch: action.payload
        }
      }

    case WORKSHOP_SKILLJOURNEYS_HAS_MORE:
      return {
        ...state,
        workshopSkillJourneys: {
          ...state.workshopSkillJourneys,
          hasMore: action.payload
        }
      }

    default:
      return state
  }
}
