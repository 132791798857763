import React, { Fragment } from 'react'
import Badge from '../../../../Courses/Create/nestedComponents/CourseCreateMain/components/Badge'
import Certificate from '../../../../Courses/Create/nestedComponents/CourseCreateMain/components/Certificate'
import {
  Form,
  Input,
  Icon,
  Button,
  Row,
  Col,
  Card,
  InputNumber,
  Modal,
  message,
  Popconfirm
} from 'antd'
import _ from 'lodash'

let id = 0

class AssessmentForm extends React.Component {
  state = {
    showCertificateModal: false,
    showBadgeModal: false,
    isBadgeLoading: false,
    isCertificateLoading: false,
    badge_id: null,
    cert_id: null
  }

  componentDidMount() {
    const { live_assessments = [], badge_id, cert_id } = this.props

    if (live_assessments.length === 0) {
      this.add()
    } else {
      this.props.form.setFieldsValue({
        questions: live_assessments
      })
      id = live_assessments.length - 1
    }

    this.setState({
      badge_id,
      cert_id
    })
  }

  remove = k => {
    const { form } = this.props
    const keys = form.getFieldValue('keys')
    if (keys.length === 1) {
      return
    }

    form.setFieldsValue({
      keys: keys.filter(key => key !== k)
    })
  }
  sumbit = _.debounce(() => this.handleSubmit(), 1000)

  add = async () => {
    const { form } = this.props
    const keys = form.getFieldValue('keys')
    id++
    const nextKeys = [...keys, id]
    form.setFieldsValue({
      keys: nextKeys
    })
  }

  handleSubmit = () => {
    this.props.form.validateFields((err, values) => {
      this.props.App.handleForm()
    })
  }

  toggleBadgeModal = () =>
    this.setState(
      prevState => ({
        showBadgeModal: !prevState.showBadgeModal
      }),
      () => console.log(this.state.showBadgeModal)
    )

  toggleCertificateModal = () =>
    this.setState(prevState => ({
      showCertificateModal: !prevState.showCertificateModal
    }))

  deleteCertificate = () => {
    this.setState({ isCertificateLoading: true })
    this.props.App.handleRewardImageUpload('Certificate', null)
    this.setState({
      showCertificateModal: false,
      isCertificateLoading: false,
      cert_id: null
    })
  }

  deleteBadge = () => {
    this.setState({ isBadgeLoading: true })
    this.props.App.handleRewardImageUpload('Badge', null)
    this.setState({
      isBadgeLoading: false,
      showBadgeModal: false,
      badge_id: null
    })
  }

  badgePresentationUpload = (body, imageType) => {
    const { upload } = this.props
    this.toggleLoadingState(imageType)
    this.setState({ showBadgeModal: false, showCertificateModal: false })
    upload(body)
      .then(res => {
        const { id } = res
        this.setImageValues(imageType, id)
        this.props.App.handleRewardImageUpload(imageType, id)
      })
      .catch(err => {
        this.toggleLoadingState(imageType)
        message.error('Error occured. Try Again !')
      })
  }

  setImageValues = (type, id) => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading,
        cert_id: id
      }))
    } else {
      this.setState(state => ({
        isBadgeLoading: !state.isBadgeLoading,
        badge_id: id
      }))
    }
  }

  handlePassScore = _.debounce(v => this.handleScore(v), 1000)
  handleScore = pass_score => {
    this.props.App.setState({ pass_score }, () => this.props.App.handleForm())
  }

  toggleLoadingState = async type => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading
      }))
    } else {
      this.setState(state => ({ isBadgeLoading: !state.isBadgeLoading }))
    }
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      live_assessments = [],
      t,
      pass_score,
      isSaving,
      current
    } = this.props

    const {
      showBadgeModal,
      showCertificateModal,
      cert_id,
      badge_id,
      isBadgeLoading,
      isCertificateLoading
    } = this.state

    getFieldDecorator('keys', {
      initialValue: [...Array(live_assessments.length).keys()]
    })

    const keys = getFieldValue('keys')

    const center = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    const id = this.props.match.params.id || current.id

    const formItems = keys.map((k, index) => (
      <Form.Item
        label={index === 0 ? t('v2:question') : ''}
        required={false}
        key={k}
      >
        <Row type="flex" justify="space-between" align="middle">
          <Col style={center} span={1}>
            {index + 1}.
          </Col>
          <Col span={22}>
            {getFieldDecorator(`questions[${k}]`, {
              validateTrigger: ['onChange', 'onBlur'],
              initialValue: live_assessments[k] || ''
            })(
              <Input
                disabled={isSaving && !id}
                onChange={() => this.sumbit()}
              />
            )}
          </Col>
          <Col style={center} span={1}>
            {keys.length > 1 ? (
              <Icon
                className="dynamic-delete-button"
                type="delete"
                onClick={() => this.remove(k)}
                style={{ color: 'red' }}
              />
            ) : null}
          </Col>
        </Row>
      </Form.Item>
    ))

    return (
      <Fragment style={{ marginTop: 15 }}>
        <Row type="flex" align="middle" justify="space-between">
          <Col md={6}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:pass_score')}
            >
              {getFieldDecorator('pass_score', {
                initialValue: pass_score,
                rules: [
                  {
                    validator: (rule, value, cb) => value > 0 && value <= 100,
                    message: t('v4:pass_score_mark')
                  }
                ]
              })(
                <InputNumber
                  size="large"
                  tighter="true"
                  style={{ width: '100%' }}
                  onChange={this.handlePassScore}
                  disabled={isSaving && !id}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={17} style={{ marginTop: '1rem' }}>
            <div className="canva-editor-items">
              {cert_id ? (
                <Fragment>
                  <Popconfirm
                    title={t('Are you sure delete ?')}
                    onConfirm={this.deleteCertificate}
                    okText={t('buttons:yes')}
                    cancelText={t('buttons:no')}
                  >
                    <Button
                      className="item"
                      shape="round"
                      icon="delete"
                      style={{
                        backgroundColor: 'red',
                        color: 'white'
                      }}
                      disabled={isSaving && !id}
                      loading={isCertificateLoading}
                    >
                      {t('labels:delete_certificate')}
                    </Button>
                  </Popconfirm>

                  <Button
                    className="item"
                    shape="round"
                    icon="check"
                    style={{
                      backgroundColor: 'green',
                      color: 'white'
                    }}
                    disabled
                  >
                    {t('v4:certificate_added')}
                  </Button>
                </Fragment>
              ) : (
                <Button
                  className="item"
                  shape="round"
                  icon="pic-right"
                  onClick={this.toggleCertificateModal}
                  loading={isCertificateLoading}
                  style={{
                    backgroundColor: cert_id ? 'green' : null,
                    color: cert_id ? 'white' : null
                  }}
                  disabled={isSaving && !id}
                >
                  {cert_id
                    ? t('v4:certificate_added')
                    : t('v4:create_certificate')}
                  {cert_id && <Icon type="check" />}
                </Button>
              )}

              {badge_id ? (
                <Fragment>
                  <Popconfirm
                    title={t('Are you sure delete ?')}
                    onConfirm={this.deleteBadge}
                    okText={t('buttons:yes')}
                    cancelText={t('buttons:no')}
                  >
                    <Button
                      className="item"
                      shape="round"
                      icon="delete"
                      loading={isBadgeLoading}
                      style={{
                        backgroundColor: 'red',
                        color: 'white'
                      }}
                      disabled={isSaving && !id}
                    >
                      {t('labels:delete_badge')}
                    </Button>
                  </Popconfirm>

                  <Button
                    className="item"
                    shape="round"
                    icon="check"
                    style={{
                      backgroundColor: 'green',
                      color: 'white'
                    }}
                    disabled
                  >
                    {t('v4:create_badge')}
                  </Button>
                </Fragment>
              ) : (
                <Button
                  className="item"
                  icon="security-scan"
                  onClick={this.toggleBadgeModal}
                  shape="round"
                  loading={isBadgeLoading}
                  style={{
                    backgroundColor: badge_id ? 'green' : null,
                    color: badge_id ? 'white' : null
                    // pointerEvents: badge_id ? 'none' : 'all'
                  }}
                  disabled={isSaving && !id}
                >
                  {badge_id ? t('v4:badge_added') : t('v4:create_badge')}
                  {badge_id && <Icon type="check" />}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <h2>{t('v4:report_live_assessments')}</h2>
        <Card style={{ marginTop: 5 }}>
          <Form>
            {formItems}
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                shape="round"
                icon="plus"
                onClick={this.add}
              >
                {t('buttons:add_question')}
              </Button>
            </Form.Item>
          </Form>
        </Card>

        {showBadgeModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={showBadgeModal}
            onCancel={this.toggleBadgeModal}
            footer={null}
            width="1229px"
          >
            <Badge
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Badge')
              }
              {...this.props}
            />
          </Modal>
        )}
        {showCertificateModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={showCertificateModal}
            onCancel={this.toggleCertificateModal}
            footer={null}
            centered
            width="1229px"
          >
            <Certificate
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Certificate')
              }
              {...this.props}
            />
          </Modal>
        )}
      </Fragment>
    )
  }
}

export default AssessmentForm
