import {
  AUTH_SUCCESS,
  AUTH_FAILED,
  AUTH_FETCHING,
  USER_SUCCESS,
  USER_FAILED,
  USER_FETCHING,
  USER_ATTRIBUTES_FETCHING,
  USER_LOGIN_SUCCESS,
  USERNAME_SUCCESS
} from '../types'

const initialState = {
  isAuthenticated: null,
  isFetchingAuth: true,
  isFetchingUser: false,
  isFetchingUserAttributes: false,
  info: {
    id: null,
    topics: []
    // username: null,
    // attributes: {
    //   name: null,
    //   picture: null,
    //   'custom:country': null,
    //   'custom:organization': null,
    //   'custom:position': null,
    //   'custom:topics': ''
    // }
  },
  userFetchFailed: false,
  isLogin: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: true
      }

    case AUTH_FAILED:
      return {
        ...state,
        isAuthenticated: false
      }

    case AUTH_FETCHING:
      return {
        ...state,
        isFetchingAuth: action.payload
      }

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true
      }

    case USER_SUCCESS:
      return {
        ...state,
        info: {
          ...action.payload,
          topics: action.payload.topics || [],
          isSuperAdmin: action.payload.is_super_admin,
          isAdmin: action.payload.role && action.payload.role.name === 'Admin'
        },
        userFetchFailed: false
      }

    case USER_FAILED:
      return {
        ...state,
        info: initialState.info,
        userFetchFailed: true
      }

    case USER_FETCHING:
      return {
        ...state,
        isFetchingUser: action.payload
      }

    case USER_ATTRIBUTES_FETCHING:
      return {
        ...state,
        isFetchingUserAttributes: action.payload
      }

    case USERNAME_SUCCESS:
      const { first_name, last_name } = action.payload
      return {
        ...state,
        info: {
          ...state.info,
          first_name,
          last_name
        }
      }

    default:
      return state
  }
}
