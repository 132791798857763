/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-08 11:28:55
 * @LastEditTime: 2019-12-09 14:35:28
 * @LastEditors: Please set LastEditors
 */
import styled from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Page = styled.div``

Page.Column = styled.div`
  margin-bottom: 2.4rem;
`

Page.Reward = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  span {
    display: inline-block;
    margin-left: 5px;
    font-size: 15px;
    font-weight: 700;
  }
`
Page.Bullet = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 3px;
  font-size: 13px;
  font-weight: 400;
  color: #8d8d8d;

  &::before {
    content: '•';
    color: #aaaaaa;
    margin: 0 10px;
  }
`
Page.List = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(74px, 1fr));
  grid-gap: 5px;
`

Page.HeadButtons = styled.div`
  padding: 3.2rem;
  padding-top: 0;
  display: flex;
  gap: 1rem;
  align-items: center;

  button {
    padding: 0.5rem 3rem;
  }
`

Page.Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: border 0.3s ease-in-out;
  img {
    object-fit: contain;
    height: 74px;
    width: 70px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #2a6ae4;
    background: #fafafa;
  }
`

Page.ErrorContainer = styled.div`
  display: flex;
  height: 74px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 25px;
  font-style: italic;
`

Page.Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  border: 1px dashed ${colors.darkGreyTwo};
  transition: border-color 0.2s;
  cursor: pointer;
  height: 400px;
  width: 100%;
  &:hover {
    border-color: ${colors.blue};
  }

  ${props =>
    props.active &&
    `
    background: rgba(0, 104, 255, 0.1);
    border-color: ${colors.blue};
  `}

  ${props =>
    props.background &&
    `
    background-image: url(${props.background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`
Page.Modal = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
`
Page.Modal.Image = styled.img`
  height: 180px;
  width: 180px;
  object-fit: contain;
`

Page.Modal.Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  margin: 30px 0;
`
Page.Modal.Details = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #000000;
`

Page.CertificateContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`

Page.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`
Page.Dropzone.Button = styled.div`
  padding: 0.4rem 2.4rem;
  text-decoration: uppercase;
  border: 1px solid ${colors.darkGreyThree};
  border-radius: 5rem;
  background: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  transition: border-color 0.2s, color 0.2s, opacity 0.2s;

  i {
    margin-right: 0.8rem;
  }

  ${Page.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props =>
      props.hide &&
      `
      background: rgba(255, 255, 255, 0.3);
      border-color: ${colors.white};
      color: ${colors.white};
      opacity: 1;
    `}
  }

  ${props =>
    props.hide &&
    `
    opacity: 0;
    border-color: ${colors.white};
    color: ${colors.white};
  `}
`

export { Page }
