import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Comment, Avatar, Tooltip, Rate, Alert, Empty, Button, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import './SidebarReviews.scss'

import { ReviewsButtonModal } from './Reviews'
import * as reviewsActions from '../../store/actions/reviews'

const SidebarReviews = ({ reviewId, userId, type }) => {
  const reviews = useSelector(state => state.reviews.reviews)

  const [userReviews, setReviews] = useState(reviews)

  useEffect(() => {
    setReviews(reviews)
  }, [reviews])

  const hasUserReviewed = !!reviews.find(item => item.user_id === userId)
  const noReviews = reviews.length === 0

  return (
    <div className="reviews_container">
      <div className="reviews_list">
        {!noReviews &&
          userReviews.map(review => <RatingItem review={review} hasUserReviewed={hasUserReviewed} userId={userId} />)}
        {noReviews && <Empty description={'No Reviews Found.'} />}
      </div>

      {!hasUserReviewed && (
        <ReviewsButtonModal id={reviewId} userId={userId} type={type} />
      )}
      {hasUserReviewed && (
        <Alert message={`You have already rated this ${type}.`} type="info" />
      )}
    </div>
  )
}

const RatingItem = ({ review, hasUserReviewed, userId }) => {
  const dispatch = useDispatch()
  const {
    avatar,
    username,
    rating,
    id,
    user_id,
    review: text,
    created_at } = review
  const [isEdit, setIsEdit] = useState(false)
  const [reviewData, setReviewData] = useState({ isEdit: false, text: text, rating: rating })
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const updateReview = async () => {
    setIsReviewLoading(true)
    const data = {
      review: reviewData.text,
      rating: reviewData.rating
    }
    await dispatch(reviewsActions.editReviewsRequest(id, data))
    setIsEdit(false)
    setIsReviewLoading(false)
  }
  const actions = [<Fragment>
    <span key="comment-basic-like">
      <Tooltip title="Rating">
        <Rate disabled={userId === user_id && !isEdit} defaultValue={reviewData.rating} onChange={value => {
          setReviewData({ ...reviewData, rating: value })
        }} />
      </Tooltip>
      <span style={{ paddingLeft: 8, cursor: 'auto' }}>
        {rating.toFixed(1)} / 5
      </span>
    </span>&nbsp;&nbsp;
    {userId === user_id && !isEdit && <Button style={{ borderRadius: 25 }} onClick={() => { setIsEdit(true) }}>Edit</Button>}
    {userId === user_id && isEdit && <Button style={{ borderRadius: 25 }} type='primary' onClick={updateReview} loading={isReviewLoading}>Save</Button>} </Fragment>
  ]

  return (
    <>
      <Comment
        actions={actions}
        author={<b>{username}</b>}
        avatar={<Avatar src={avatar} alt={username} />}
        content={<p>{userId === user_id && isEdit ? <Input defaultValue={reviewData.text} onChange={e => {
          setReviewData({ ...reviewData, text: e.target.value })
        }} /> : text} </p>}
        datetime={
          <Tooltip title={moment(created_at).format('YYYY-MM-DD hh:mm A')}>
            <span className="reviews_list_time">
              {moment(created_at).fromNow()}
            </span>
          </Tooltip>
        }
      />
      <hr />
    </>
  )
}

export default SidebarReviews
