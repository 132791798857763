import React, { Component } from 'react'
import AgoraRTC from 'agora-rtc-sdk'
import { Row, Tooltip, Modal, Button, message, Icon } from 'antd'
import _ from 'lodash'
import debounce from 'lodash/debounce'
import userImage from '../../../assets/user.svg'
import { AddParticipant } from '../../AntMediaService/internal'
import { api } from '../../../../../../services'
import http from '../../../../../../services/api/http'
import { Card } from '../../../../../../components/Card/styled'
import { Prompt } from 'react-router-dom'
import { config } from '../../../../../../constants'
import mute from '../../../assets/mute.svg'
import unmute from '../../../assets/unmute.svg'
import EventMenus from '../../EventMenus'
import './agorastyles.scss'
import BreakoutForm from '../../BreakoutForm'
import Whiteboard from '../../AgoraWhiteboard/AgoraWhiteboard'

import axios from 'axios'
const configforaxios = {
  // baseURL: apiAntHost,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}
const request = () => {
  return axios.create(configforaxios)
}

// Options for joining a channel
// var option = {
//   appID: "38116902f5fa439bbd02729950f2306f",
//   channel: "abc",
//   token: "00603dd63b5dca84caab08dca73ccadef0e,IADWcey4EMzCR91l8Se+sty3MDp5Jl+meKIXZG6lvkTclRYZV3NPOhTdIgAfstRzDJXqYQQAAQC0aelhAgC0aelhAwC0aelhBAC0aelh",
//   uid: '',
//   key: '',
//   secret: ''
// }

let initSocketConf = null
let connectionInterval = null
let inConferenceInterval = null
let liveCount = null
let userDetails = null
let prompt = null
let shareClient
let screenStream
let min = Math.pow(10, 5)
let max = Math.pow(10, 8)
let totalStreamId = []
export default class AgoraCall extends Component {
  constructor(props) {
    super(props)
    this.toggleAudioRef = React.createRef()
    this.client = {}
    this.localStream = {}
    // this.shareClient = {}
    this.shareStream = {}
    this.lastFetchId = 0
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
    this.state = {
      isJoined: false,
      canShowModal: false,
      canShowEndModal: false,
      isCameraOff: false,
      isMicOff: false,
      showVideo: false,
      showPrompt: false,
      streamList: [],
      stateSharing: false,
      showParticipant: false,
      emailList: [],
      fetchingEmail: false,
      emails: [],
      initialEmails: [],
      inviting: false,
      failInvitation: false,
      successInvitation: false,
      isLoading: false,
      videoTiles: false,
      tileId: '',
      uId: '',
      applicationId: '',
      agoraChannel: '',
      totalCount: '',
      remoteUserDetails: [],
      allowedUserEmpty: false,
      screenShareId: '',
      screenStreamList: [],
      showScreenIcon: true,
      isGridActive: false,
      eventStartTime: null,
      isLocalVideoPlaying: false,
      joinedUserId: '',
      screenShareUserId: '',
      screenShareToken: '',
      createBreakoutRoom: false,
      roomCount: 0,
      rooms: [
        {
          title: '',
          is_recording: false,
          is_transcribed: false,
          transcribe_locale: '',
          is_ai_enabled: false
        }
      ],
      roomErrors: [{ title: '', error_msg_ai: '' }],

      isWhiteboardSharing: false,
      whiteboardRequest: 0,
      whiteboardResponse: 0,
      wbRoomToken: '',
      wbRoomUUID: '',
      wbConsumerId: 0
    }
  }

  componentDidMount() {
    const { userId, hostEmails } = this.props

    totalStreamId.push(userId)
    this.props.App.onParticipants({
      attendees: totalStreamId
    })

    this.fetchUserEmails()

    if (hostEmails && hostEmails.length > 0) {
      this.setState({
        emails: hostEmails,
        initialEmails: hostEmails
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      tileId,
      stateSharing,
      streamList,
      isJoined,
      totalCount,
      allowedUserEmpty,
      screenStreamList,
      isGridActive,
      remoteUserDetails,
      uId,
      isLocalVideoPlaying,
      isWhiteboardSharing
    } = this.state

    const {
      userId,
      streamCount,
      allowedUser,
      isConferenceHost,
      isConfCreator,
      event: { has_breakout_room, event_type },
      App
    } = this.props

    const prevTileId = prevState.tileId
    const screenShareStream =
      streamList &&
      streamList.filter(val => val.streamId >= min && val.streamId <= max)
    if (screenStreamList.length !== screenShareStream.length) {
      this.setState({
        screenStreamList: screenShareStream
      })
    }
    if (
      stateSharing === false &&
      screenShareStream.length !== 0 &&
      screenStreamList.length !== screenShareStream.length
    ) {
      this.setState({
        showScreenIcon: false
      })
    }
    if (
      stateSharing === false &&
      screenShareStream.length === 0 &&
      screenStreamList.length !== screenShareStream.length
    ) {
      this.setState({
        showScreenIcon: true
      })
    }

    if (prevState.streamList !== streamList) {
      let totalAttendees = streamList.map(item => item.streamId)
      totalAttendees.push(parseInt(totalStreamId))
      this.props.App.onParticipants({
        attendees: totalAttendees
      })
    }

    const localVideoElements = document.querySelectorAll('.local_video')
    const remoteVideoElement = document.querySelector(`.chimeId_${tileId}`)
    const previousVideoElement = document.querySelectorAll(
      `.chime_main__view.chimeId_${prevTileId}`
    )

    const activeOtherUser = remoteUserDetails.filter(
      item => item.user_id !== userId
    )[0]

    const isLocalUserAllowed =
      !isConferenceHost &&
      allowedUser &&
      allowedUser.length > 0 &&
      allowedUser.includes(userId)

    const localItemId =
      isConferenceHost || isLocalUserAllowed
        ? userId
        : activeOtherUser && activeOtherUser.user_id

    const isScreenBeingShared = stateSharing || screenStreamList.length !== 0

    if (
      !isWhiteboardSharing &&
      !isScreenBeingShared &&
      screenShareStream.length === 0 &&
      !isGridActive
    ) {
      if (
        tileId &&
        tileId === prevTileId &&
        previousVideoElement &&
        previousVideoElement.length > 0
      ) {
      } else if (tileId === localItemId || !tileId) {
        this.removeFromActiveSpeaker(previousVideoElement[0])
        localVideoElements.forEach(element => {
          this.makeActiveSpeaker(element)
        })
      } else if (tileId !== localItemId) {
        this.makeActiveSpeaker(remoteVideoElement)

        if (previousVideoElement && previousVideoElement.length > 0) {
          previousVideoElement.forEach(item => {
            this.removeFromActiveSpeaker(item)
          })
        } else {
          localVideoElements.forEach(element => {
            this.removeFromActiveSpeaker(element)
          })
        }
      }
    }

    if (stateSharing || screenShareStream.length !== 0) {
      if (screenShareStream.length !== 0) {
        const screenId = screenShareStream[0].streamId

        const screenShareTile = document.querySelector(`.chimeId_${screenId}`)

        this.makeActiveSpeaker(screenShareTile)
        if (previousVideoElement && previousVideoElement.length > 0) {
          previousVideoElement.forEach(item => {
            this.removeFromActiveSpeaker(item)
          })
        } else {
          localVideoElements.forEach(element => {
            this.removeFromActiveSpeaker(element)
          })
        }
      }
    }

    if (isWhiteboardSharing) {
      const screenShareTile = document.getElementById('sharewhiteboard')

      this.makeActiveSpeaker(screenShareTile)
      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          this.removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          this.removeFromActiveSpeaker(element)
        })
      }
    }

    if (prevState.totalCount !== totalCount && isJoined) {
      streamCount(totalCount)
    }

    if (
      (allowedUser && allowedUser.length) !==
      (prevProps.allowedUser && prevProps.allowedUser.length)
    ) {
      if (!isConferenceHost && userId && uId) {
        if (
          allowedUser &&
          allowedUser.length > 0 &&
          allowedUser.includes(userId) &&
          !isLocalVideoPlaying
          // && this.localStream !== null
        ) {
          this.setState(
            {
              isLocalVideoPlaying: true
            },
            () => {
              this.localStream = AgoraRTC.createStream({
                streamID: uId,
                audio: true,
                video: false,
                screen: false
              })
              // Initialize the local stream
              this.localStream.init(
                () => {
                  this.client.publish(this.localStream, function(err) {
                    console.log('publish failed', err)
                  })
                },
                function() {
                  console.error('init local stream failed ')
                }
              )
              if (prevProps.allowedUser !== allowedUser) {
                this.setState({
                  allowedUserEmpty: true
                })
              }
            }
          )
        }
        if (allowedUserEmpty) {
          if (allowedUser && allowedUser.length === 0) {
            this.client && this.client.unpublish(this.localStream)
          }
        }
      }
    }

    if (prevProps.onJoin !== this.props.onJoin) {
      if (this.props.onJoin) {
        this.startEvent()
      }
    }
    if (prevProps.isInvite !== this.props.isInvite) {
      if (this.props.isInvite) {
        if (this.state.initialEmails.length >= 5 && event_type === 'webinar') {
          message.error(
            'You have reached your maximum number of participants that you can invite.'
          )
          this.props.App.onInvite()
          return
        }
        this.showAddparticipant()
      }
    }
    if (this.props !== prevProps) {
      console.log(
        'breakout clicked inside this thing',
        App.state.createRoom,
        isJoined,
        isConfCreator,
        has_breakout_room
      )
      if (
        App.state.createRoom &&
        isJoined &&
        isConfCreator &&
        has_breakout_room
      ) {
        this.showBreakoutForm()
      }
    }
  }

  showBreakoutForm = () => {
    this.props.App.setState({ createRoom: false })
    this.setState({ createBreakoutRoom: true })
  }

  formValidation = () => {
    const { rooms, roomErrors: errors } = this.state
    if (rooms.length > 0) {
      const test = rooms.map((each, i) => {
        if (each.title === '') {
          const title =
            errors &&
            errors.map((e, j) => {
              if (j === i) {
                e.title = '*required'
              }
              return e
            })
          this.setState({ title }, () => {
            return true
          })
          return true
        }
        return false
      })
      return test
    }
    return
  }

  createBreakoutForm = async () => {
    const { eventId } = this.props
    const { rooms } = this.state
    const hasError = await this.formValidation()
    console.log('has Error Room', hasError)
    if (hasError.includes(true)) return
    this.setState(
      {
        isCreatingBreakoutRoom: true
      },
      () => {
        api.events
          .createBreakoutEvent(eventId, rooms)
          .then(res => {
            console.log('create breakout resp', res)
            if (res.status === 200) {
              message.success(res && res.data)
              this.setState({
                rooms: [
                  {
                    title: '',
                    is_recording: false,
                    is_transcribed: false,
                    transcribe_locale: '',
                    is_ai_enabled: false,
                    error_msg_ai: ''
                  }
                ],
                createBreakoutRoom: false,
                isCreatingBreakoutRoom: false
              })
            }
          })
          .catch(err => {
            console.log('error in stopping event', err)
            message.error('Something went wrong.')
            this.setState({
              createBreakoutRoom: false,
              isCreatingBreakoutRoom: false
            })
          })
      }
    )
  }

  setBreakoutRoomCount = value => {
    const { rooms, roomErrors: errors } = this.state
    let newarr = []
    let newErr = []
    if (value === rooms.length) {
      newarr = rooms
      newErr = errors
    } else if (value < rooms.length) {
      newarr = rooms.filter((item, i) => i < value)
      newErr = errors.filter((err, j) => j < value)
    } else {
      newarr = [
        ...rooms,
        ...[...Array(value - rooms.length)].map(() => {
          const roomsObj = {
            title: '',
            is_recording: false,
            is_transcribed: false,
            transcribe_locale: '',
            is_ai_enabled: false
          }
          return roomsObj
        })
      ]
      newErr = [
        ...errors,
        ...[...Array(value - errors.length)].map(() => {
          const errObj = { title: '', error_msg_ai: '' }
          return errObj
        })
      ]
    }
    this.setState({
      rooms: newarr,
      roomErrors: newErr,
      roomCount: value
    })
  }

  removeRoom = index => {
    const filterRooms = this.state.rooms.filter((item, i) => i !== index)
    const filterErr = this.state.roomErrors.filter((item, i) => i !== index)
    const roomCount = this.state.roomCount - 1

    this.setState({
      roomCount,
      rooms: filterRooms,
      roomErrors: filterErr
    })
  }

  handleBreakoutRoom = (index, field, value) => {
    const { rooms: arrBreakout, roomErrors: errors } = this.state
    const newBreakout =
      arrBreakout &&
      arrBreakout.map((item, i) => {
        if (i === index) {
          if (field === 'title' && !value) {
            item[field] = ''
            return item
          }
          if (field === 'title' && value) {
            item[field] = value
            const err =
              errors &&
              errors.map((e, j) => {
                if (j === index) {
                  e.title = ''
                }
                return e
              })
            this.setState({ roomErrors: err })
            return item
          }

          if (field === 'is_ai_enabled' && item.transcribe_locale) {
            const cleanLocale =
              item.transcribe_locale !== ''
                ? item.transcribe_locale.split('-')
                : []
            if (!cleanLocale.includes('en')) {
              // item.error_msg_ai = 'AI service available in English only'
              const err =
                errors &&
                errors.map((e, j) => {
                  if (j === index) {
                    e.error_msg_ai = 'AI service available in English only'
                  }
                  return e
                })
              this.setState({ roomErrors: err })
              item[field] = value
              return item
            }
          }

          item[field] = value
          return item
        }
        return item
      })
    this.setState({ rooms: newBreakout })
    return
  }

  handleCheckChange = (index, field, value) => {
    console.log('is recording value', index, field, value)
    const { rooms: arrBreakout } = this.state
    const newBreakout =
      arrBreakout &&
      arrBreakout.map((item, i) => {
        if (i === index) {
          if (!value) {
            item.is_transcribed = false
            item.transcribe_locale = ''
            item.is_ai_enabled = false
          }
          item.is_recording = value
        }
        return item
      })
    this.setState({ rooms: newBreakout })
    return
  }

  handleChangeLocale = (index, value) => {
    const { rooms: arrBreakout, roomErrors: errors } = this.state
    const newBreakout =
      arrBreakout &&
      arrBreakout.map((item, i) => {
        if (i === index) {
          if (item.is_ai_enabled) {
            const cleanLocale =
              item.is_ai_enabled && value !== '' ? value.split('-') : []
            if (!cleanLocale.includes('en')) {
              // setErrors([...errors, errors[i].error_msg_ai = 'AI service available in English only'])
              const err =
                errors &&
                errors.map((e, j) => {
                  if (j === index) {
                    e.error_msg_ai = 'AI service available in English only'
                  }
                  return e
                })
              this.setState({ roomErrors: err })
              item.transcribe_locale = value
              return item
            }
          }
          item.transcribe_locale = value
          // item.error_msg_ai = ''
          this.setState({
            roomErrors: [
              ...errors,
              (errors[index].error_msg_ai = ''),
              (errors[index].title = '')
            ]
          })

          return item
        }
        return item
      })
    this.setState({ rooms: newBreakout })
    return
  }

  makeActiveSpeaker = element => {
    if (element) {
      if (!element.classList.contains('chime_main__view')) {
        element.classList.add('chime_main__view')
      }
      if (element.classList.contains('remoteChimePlayer')) {
        element.classList.remove('remoteChimePlayer')
      }
    }
  }

  removeFromActiveSpeaker = element => {
    if (element) {
      if (element.classList.contains('chime_main__view')) {
        element.classList.remove('chime_main__view')
      }
      if (!element.classList.contains('remoteChimePlayer')) {
        element.classList.add('remoteChimePlayer')
      }
    }
  }

  startEvent = async () => {
    const {
      userId,
      eventId,
      isConferenceHost,
      username,
      onStreamStopped,
      stopstream,
      handleBroadcastChange,
      wbRoomToken
    } = this.props

    this.setState({
      isJoined: true,
      showVideo: true,
      showPrompt: true,
      isLoading: true
    })

    handleBroadcastChange()

    const eventTitle = `event_${eventId}`
    const reqURL = `events/${eventId}/agora/`
    const payload = {
      title: eventTitle,
      name: isConferenceHost
        ? `${username}#${userId}`
        : `passive${username}#${userId}`,
      region: 'us-east-1'
    }

    //api call
    const response = await http({
      method: 'PUT',
      url: `${reqURL}join`,
      data: payload
    }).catch(() => {
      this.setState({
        showVideo: false,
        videoTiles: false,
        showPrompt: false
      })
      onStreamStopped()
      stopstream()
      handleBroadcastChange()
      return
    })

    const { agora_token, channel_name, uid } = response.data.JoinInfo
    const {
      screen_token,
      uid: screenShareUserId
    } = response.data.ScreenShareInfo
    // Create a client
    this.client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' })

    this.client.enableAudioVolumeIndicator()
    this.client.on('volume-indicator', volumes => {
      if (volumes.attr !== '') {
        volumes &&
          volumes.attr.forEach(volume => {
            if (volume.uid >= min && volume.uid <= max) {
              return
            }

            if (volume.level > 0) {
              this.setState({
                tileId: volume.uid
              })
            }
          })
      } else {
        this.setState({
          tileId: null
        })
      }
    })

    this.setState(
      {
        channel_name,
        videoTiles: true,
        isLoading: false,
        uId: uid,
        screenShareToken: screen_token,
        screenShareUserId
      },
      () => {
        // Initialize the client
        this.client.init(
          agora_token,
          () => {
            this.subscribeStreamEvents()
            // Join a channel
            this.client.join(
              agora_token,
              channel_name,
              uid ? +uid : null,
              uid => {
                if (isConferenceHost) {
                  this.localStream = AgoraRTC.createStream({
                    streamID: uid,
                    audio: true,
                    video: true,
                    screen: false
                  })
                  // Initialize the local stream
                  this.localStream.init(
                    () => {
                      this.localStream.play('local_stream')
                      this.client.publish(this.localStream, function() {
                        console.log('publish failed')
                      })
                    },
                    function() {
                      console.error('init local stream failed ')
                    }
                  )
                  this.client.on('connection-state-change', function() {
                    console.log('audience')
                  })
                }
              },
              err => {
                console.error('client join failed', err)
              }
            )
          },
          err => {
            console.error(err)
          }
        )
      }
    )
    this.initializeSocket()

    setTimeout(() => {
      this.handleLocalStream(this.localStream)
    }, 2000)

    inConferenceInterval = setInterval(() => {
      initSocketConf.send(
        JSON.stringify({
          action: 'inConference',
          userId: userId,
          user_name: username,
          channel_id: eventId,
          channel_type: 'EVENT'
        })
      )
    }, 60000)

    if (!this.state.wbRoomToken) {
      this.startAgoraWb().catch(() => {
        console.log("whiteboard didn't start")
      }) // dont forget
    }
  }

  initializeSocket = () => {
    const { eventId, userId, userOrg, isConfCreator } = this.props
    initSocketConf = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )
    initSocketConf.onopen = () => {
      if (isConfCreator) {
        initSocketConf.send(
          JSON.stringify({
            action: 'startConference',
            stream_id: `event_${eventId}`,
            channel_type: 'EVENT',
            channel_id: eventId
          })
        )
      }
      initSocketConf.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: userOrg
        })
      )

      initSocketConf.send(
        JSON.stringify({
          action: 'getEventStartDate',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId
        })
      )

      liveCount = setInterval(() => {
        initSocketConf.send(
          JSON.stringify({
            action: 'sendCount',
            channel_type: 'EVENT',
            channel_id: eventId,
            stream_id: `event_${eventId}`,
            encoder: 'agora_conference'
          })
        )
      }, 2000)

      userDetails = setInterval(() => {
        initSocketConf.send(
          JSON.stringify({
            action: 'getLiveUserDetails',
            channel_type: 'EVENT',
            channel_id: eventId,
            stream_id: `event_${eventId}`,
            encoder: 'agora_conference'
          })
        )
      }, 1000)

      connectionInterval = setInterval(() => {
        initSocketConf.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 20000)

      initSocketConf.send(
        JSON.stringify({
          action: 'requestWhiteboardStatus',
          channel_id: eventId,
          userId: userId
        })
      )
    }

    initSocketConf.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.event === 'sendCount') {
        this.setState({
          totalCount: data.views
        })
      }

      if (data.event === 'getEventStartDate') {
        if (!this.state.eventStartTime) {
          this.setState(
            {
              eventStartTime: data.message
            },
            () => {
              this.props.onEventTime(data.message)
            }
          )
        }
      }
      if (data.event === 'getLiveUserDetails') {
        this.setState(
          {
            remoteUserDetails: data.user_details
          },
          () => {
            this.props.App.onParticipants({
              attendees: data.user_details
            })
          }
        )
      }
      if (data.event === 'muteUser') {
        if (data.content === userId) {
          // this.state.isMicOff ? this.unmuteLocalMic() : this.muteLocalMic()
          this.toggleAudioRef.current.click()
        }
      }
      if (data.event === 'endConference') {
        // if (!isConfCreator) {
        this.props.handleAgoraBroadcast()
        try {
          this.client && this.client.unpublish(this.localStream)
          this.localStream && this.localStream.close()
          this.client &&
            this.client.leave(
              () => {
                console.log('Client succeed to leave.')
              },
              () => {
                console.log('Client failed to leave.')
              }
            )
        } finally {
          this.client = null
          this.localStream = null
        }
        // }
      }

      if (data.event === 'updateParticipant') {
        this.props.App.addToParticipants(data.participants)
      }

      if (data.event === 'sendWhiteboardStatus') {
        console.log('akif', data.wbRoomToken)
        this.setState({
          isWhiteboardSharing: data.wbStatus,
          wbRoomToken: data.wbRoomToken,
          wbRoomUUID: data.wbRoomUUID,
          wbConsumerId: data.consumerId
        })
      }

      if (data.event === 'requestWhiteboardStatus') {
        if (data.userId !== userId) {
          this.sendWhiteboardStatus(this.state.isWhiteboardSharing)
        }
      }
    }

    initSocketConf.onclose = () => {
      clearInterval(connectionInterval)
      clearInterval(liveCount)
      clearInterval(connectionInterval)
    }
    initSocketConf.onerror = () => {
      clearInterval(connectionInterval)
      clearInterval(liveCount)
      clearInterval(userDetails)
    }
  }

  subscribeStreamEvents = () => {
    let rt = this
    rt.client.on('stream-added', function(evt) {
      let stream = evt.stream
      rt.client.subscribe(stream, function() {
        console.log('Subscribe stream failed')
      })
    })

    rt.client.on('peer-leave', function(evt) {
      rt.removeStream(evt.uid)
    })

    rt.client.on('stream-subscribed', function(evt) {
      let stream = evt.stream
      rt.addStream(stream)
    })

    rt.client.on('stream-removed', function(evt) {
      let stream = evt.stream
      rt.removeStream(stream.streamId)
    })
  }

  removeStream = uid => {
    this.state.streamList.map((item, index) => {
      if (item.streamId === uid) {
        item.close()
        let tempList = [...this.state.streamList]
        tempList.splice(index, 1)
        this.setState({
          streamList: tempList
        })
        return null // Explicitly return null after modifying state
      }
      return null // Ensure a consistent return value for all cases
    })
  }

  addStream = (stream, push = false) => {
    const { streamList } = this.state
    this.setState(
      {
        streamList: [stream].concat(streamList)
      },
      () => {
        this.playRemote()
      }
    )
  }

  handleStreamList = streamList => {
    this.props.handleAgoraMute(streamList)
  }

  handleLocalStream = localStreamDetail => {
    this.props.handleLocalMute(localStreamDetail)
  }

  playRemote = () => {
    const { streamList } = this.state
    streamList &&
      streamList.map(item => {
        let id = item.streamId
        item.play(`remote_${id}`)
        return null // Explicitly return null after modifying state
      })
    this.handleStreamList(streamList)
  }

  handleStopApi = () => {
    const { eventId, onStreamStopped, stopstream } = this.props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          onStreamStopped()
          stopstream()
        }
      })
      .catch(() => {
        console.log('error in stopping event')
      })
  }

  leaveEvent = async () => {
    const {
      handleBroadcastChange,
      onLeaveEvent,
      isHandRaised,
      userId,
      App
    } = this.props

    this.setState({
      canShowModal: false,
      canShowEndModal: false,
      showVideo: false,
      showPrompt: false,
      videoTiles: false,
      isJoined: false,
      streamList: [],
      isCameraOff: false,
      isMicOff: false
    })

    if (isHandRaised) {
      App.declineToAsk(userId)
    }
    App.onHeaderItemClick()
    onLeaveEvent()
    prompt = null
    await handleBroadcastChange()
    this.stopSocket()

    try {
      if (!_.isEmpty(this.localStream)) {
        this.client && this.client.unpublish(this.localStream)
        this.localStream && this.localStream.close()
      }
      if (this.state.stateSharing) {
        shareClient && shareClient.unpublish(screenStream)
        screenStream && screenStream.close()
      }
      this.client &&
        this.client.leave(
          () => {
            console.log('Client succeed to leave.')
          },
          () => {
            console.log('Client failed to leave.')
          }
        )
    } finally {
      this.client = null
      this.localStream = null
      this.setState({
        stateSharing: false
      })
    }

    if (inConferenceInterval) {
      clearInterval(inConferenceInterval)
      inConferenceInterval = null
    }
    if (liveCount) {
      clearInterval(liveCount)
      liveCount = null
    }
    if (userDetails) {
      clearInterval(userDetails)
      userDetails = null
    }
  }

  endEvent = async () => {
    // if (e.currentTarget.classList.contains("disabled")) {
    //   return;
    // }
    const { eventId, handleBroadcastChange, endChimeEvent } = this.props

    const eventTitle = `event_${eventId}`
    const reqURL = `events/${eventId}/agora/`

    const payload = {
      title: eventTitle
    }

    await http({
      method: 'PUT',
      url: `${reqURL}end`,
      data: payload
    })
    this.props.App.onHeaderItemClick()
    this.setState({
      canShowModal: false,
      canShowEndModal: false,
      showVideo: false,
      showPrompt: false,
      videoTiles: false,
      isJoined: false,
      streamList: []
    })

    prompt = null
    await handleBroadcastChange()
    this.stopSocket()
    this.handleStopApi()
    setTimeout(() => {
      endChimeEvent()
    }, 2000)
    this.sendWhiteboardStatus(false)

    try {
      this.client && this.client.unpublish(this.localStream)
      this.localStream && this.localStream.close()
      if (this.state.stateSharing) {
        shareClient && shareClient.unpublish(screenStream)
        screenStream && screenStream.close()
      }
      this.client &&
        this.client.leave(
          () => {
            console.log('Client succeed to leave.')
          },
          () => {
            console.log('Client failed to leave.')
          }
        )
    } finally {
      this.client = null
      this.localStream = null
      this.setState({
        stateSharing: false
      })
    }
    if (inConferenceInterval) {
      clearInterval(inConferenceInterval)
      inConferenceInterval = null
    }
    if (liveCount) {
      clearInterval(liveCount)
      liveCount = null
    }
    if (userDetails) {
      clearInterval(userDetails)
      userDetails = null
    }
  }

  stopSocket = () => {
    const { eventId, userOrg, userId, isConfCreator } = this.props
    if (isConfCreator) {
      initSocketConf.send(
        JSON.stringify({
          action: 'stopConference',
          stream_id: `event_${eventId}`,
          channel_type: 'EVENT',
          channel_id: eventId
        })
      )
    }
    initSocketConf.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: userOrg
      })
    )
    clearInterval(connectionInterval)
    connectionInterval = null
  }

  // generateRandomAgoraUid = (min, max) => {
  //   const random = require('random-number');
  //   const gen = random.generator({
  //         min,
  //         max,
  //         integer: true
  //     })
  //     return gen()
  // }

  generateRandomAgoraUid = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  sharingScreen = async () => {
    const {
      stateSharing,
      channel_name,
      screenShareToken,
      screenShareUserId: uid
    } = this.state

    if (stateSharing) {
      this.setState(
        {
          stateSharing: false
        },
        () => {
          try {
            shareClient && shareClient.unpublish(screenStream)
            screenStream && screenStream.close()
            shareClient &&
              shareClient.leave(
                () => {
                  console.log('Client succeed to leave.')
                },
                () => {
                  console.log('Client failed to leave.')
                }
              )
          } finally {
            shareClient = null
            screenStream = null
          }
        }
      )
    } else {
      this.setState(
        {
          stateSharing: true
        },
        async () => {
          // const random_uid = this.generateRandomAgoraUid(min, max)

          // const reqURL = `events/${eventId}/agora/`
          // const payload = {
          //   random_uid
          // }
          // //api call
          // const response = await http({
          //   method: 'POST',
          //   url: `${reqURL}screen-share`,
          //   data: payload
          // }).catch(e => {
          //   console.log('Error while join: ')
          // })
          // const { screen_token, uid } = response.data.screen_share_info
          this.setState({
            screenShareId: uid
          })
          // const token = '0062786565d9f59499bbe7bfcc2967ff33dIAA6Z2WVLhT+1clf2l4fx6RIBRAqwLWbLpTLwveWl2inKjXfOLLSY0iIIgBwX+womP/fYQQAAQD4895hAgD4895hAwD4895hBAD4895h'
          shareClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
          shareClient.init(
            screenShareToken,
            function() {
              console.log('AgoraRTC screenClient initialized')
            },
            function(err) {
              console.log('[ERROR] : AgoraRTC screenClient init failed', err)
            }
          )

          screenStream = AgoraRTC.createStream({
            streamID: uid,
            audio: false, // Set the audio attribute as false to avoid any echo during the call.
            video: false,
            screen: true, // screen stream
            screenAudio: true,
            mediaSource: 'screen' // Firefox: 'screen', 'application', 'window' (select one)
          })
          // initialize the stream
          // -- NOTE: this must happen directly from user interaction, if called by a promise or callback it will fail.
          screenStream.init(
            function() {
              shareClient.join(
                screenShareToken,
                channel_name,
                uid ? +uid : null,
                function() {
                  // screenStream.play('sharevideo')
                  shareClient.publish(screenStream, function() {
                    console.log('[ERROR] : publish screen stream error: ')
                  })
                },
                function(err) {
                  console.log(
                    '[ERROR] : join channel as screen-share failed',
                    err
                  )
                }
              )
            },
            () => {
              this.setState({
                stateSharing: false
              })
            }
          )
        }
      )
    }
  }

  fullScreenMode = () => {
    const { screenStreamList } = this.state
    if (screenStreamList.length !== 0) {
      // const screenId = screenStreamList[0].streamId

      let elem = document.getElementById('agora_fullScreen')
      if (elem && elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem && elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem && elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem && elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      }
    }
  }

  turnOffLocalCamera = () => {
    this.setState({ isCameraOff: true }, () => {
      this.localStream.muteVideo()
    })
  }

  turnOnLocalCamera = () => {
    this.setState({ isCameraOff: false }, () => {
      this.localStream.unmuteVideo()
    })
  }

  muteLocalMic = () => {
    this.setState({ isMicOff: true }, () => {
      this.localStream.muteAudio()
    })
  }

  unmuteLocalMic = () => {
    this.setState({ isMicOff: false }, () => {
      this.localStream.unmuteAudio()
    })
  }

  toggleModal = () => {
    this.setState(state => ({ canShowModal: !state.canShowModal }))
  }

  toggleEndModal = () => {
    this.setState(state => ({ canShowEndModal: !state.canShowEndModal }))
  }

  showAddparticipant = () => {
    this.setState(
      state => ({
        showParticipant: !state.showParticipant,
        successInvitation: false
      }),
      () => {
        if (this.state.showParticipant) {
          this.props.App.onInvite()
        }
      }
    )
  }

  sendInvitations = () => {
    const { event, eventId } = this.props
    const { emails, initialEmails } = this.state
    const newAdded = _.difference(emails, initialEmails)

    const data = {
      title: event.title,
      add_participant: newAdded,
      cover_id: event.cover_id
    }

    this.setState({ inviting: true }, () => {
      api.events
        .updateById(eventId, data)
        .then(response => {
          const { data: respData } = response
          initSocketConf.send(
            JSON.stringify({
              action: 'updateParticipant',
              channel_type: 'EVENT',
              channel_id: eventId,
              participants: respData.hosts
            })
          )
          this.setState(
            {
              inviting: false,
              successInvitation: true,
              emails: respData.host_emails,
              initialEmails: respData.host_emails
            },
            () => {
              this.showAddparticipant()
            }
          )
        })
        .catch(() => {
          this.setState(
            {
              inviting: false,
              failInvitation: true,
              emails
            },
            () => {
              setTimeout(() => {
                this.setState({ failInvitation: false })
              }, 3000)
            }
          )
          // message.error(error)
        })
    })
  }

  fetchUserEmails = value => {
    const { userOrg } = this.props
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ emailList: [], fetchingEmail: true })
    api.organizations.getUsersByOrganizationId(userOrg).then(res => {
      if (fetchId !== this.lastFetchId) {
        return
      }
      res.data.forEach(val => {
        if (val.user) {
          const dataValue = {
            text: val.user.email,
            value: val.user.email
          }
          this.setState(state => {
            if (dataValue.value.includes(value)) {
              const emailList = [...state.emailList, dataValue]
              return {
                emailList,
                fetchingEmail: false
              }
            }
          })
        }
      })
    })
  }

  handleEmailChange = selectedValue => {
    this.setState({
      emailList: [],
      emails: selectedValue,
      fetchingEmail: false
    })
  }

  // menu = () => (
  //   <Menu>
  //     <Menu.Item>
  //       <a role="button" onClick={() => this.toggleModal()}>
  //         {t('v4:leave')}
  //       </a>
  //     </Menu.Item>
  //     <Menu.Item>
  //       <a role="button" onClick={() => this.toggleEndModal()}>
  //         {t('end_for_all')}
  //       </a>
  //     </Menu.Item>
  //   </Menu>
  // )

  toggleMute = userAttendee => this.sendMuteUserId(userAttendee)

  sendMuteUserId = user => {
    const { eventId } = this.props

    initSocketConf.send(
      JSON.stringify({
        action: 'muteUser',
        content: user,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }

  handleMute = () => {
    const { isMicOff } = this.state
    isMicOff ? this.unmuteLocalMic() : this.muteLocalMic()
  }

  sendWhiteboardStatus = value => {
    const { eventId, userId } = this.props
    const { wbRoomToken, wbRoomUUID } = this.state
    initSocketConf.send(
      JSON.stringify({
        action: 'sendWhiteboardStatus',
        channel_id: eventId,
        wbStatus: value,
        consumerId: userId,
        wbRoomToken,
        wbRoomUUID
      })
    )
  }

  startAgoraWb = async () => {
    // getting SDK token
    let whiteBoardSDKToken = ''

    const headersSdkToken = {
      'Content-Type': 'application/json',
      region: 'us-sv'
    }

    const paramsSdkToken = {
      accessKey: config.agoraWbAccesKey,
      secretAccessKey: config.agoraWbSecretKey,
      lifespan: 0,
      role: 'admin'
    }

    const tokenResponse = await request().post(
      'https://api.netless.link/v5/tokens/teams',
      paramsSdkToken,
      { headers: headersSdkToken }
    )
    whiteBoardSDKToken = tokenResponse.data

    // create room by using SDK token
    const headersRoomInfo = {
      token: whiteBoardSDKToken,
      region: 'us-sv',
      'Content-Type': 'application/json'
    }

    const paramsRoomInfo = { isRecord: false }

    const roomResponse = await request().post(
      'https://api.netless.link/v5/rooms',
      paramsRoomInfo,
      {
        headers: headersRoomInfo
      }
    )

    // getting room token by using SDK token
    const headersRoomToken = {
      token: whiteBoardSDKToken,
      'Content-Type': 'application/json',
      region: 'us-sv'
    }
    const paramsRoomToken = { lifespan: 3600000, role: 'admin' }

    // setting state for room token and room uuid for whiteboard
    this.setState({ wbRoomUUID: roomResponse.data.uuid }, async () => {
      const responseRoomToken = await request()
        .post(
          `https://api.netless.link/v5/tokens/rooms/${this.state.wbRoomUUID}`,
          paramsRoomToken,
          { headers: headersRoomToken }
        )
        .catch(err => console.log(err))

      this.setState({ wbRoomToken: responseRoomToken.data })
    })
  }

  render() {
    const {
      isConferenceHost,
      allowJoin,
      t,
      App,
      isConfCreator,
      event,
      isConferenceParticipant,
      allowedUser: raiseHandAllowed,
      isHandRaised,
      raiseHandRequesting,
      userId,
      organization
    } = this.props
    const {
      isJoined,
      canShowModal,
      createBreakoutRoom,
      roomCount,
      isCreatingBreakoutRoom,
      rooms,
      roomErrors,
      canShowEndModal,
      isCameraOff,
      isMicOff,
      stateSharing,
      showParticipant,
      emailList,
      emails,
      fetchingEmail,
      inviting,
      failInvitation,
      successInvitation,
      streamList,
      showVideo,
      videoTiles,
      isLoading,
      showPrompt,
      remoteUserDetails,
      screenStreamList,
      showScreenIcon,
      tileId,
      isGridActive,
      initialEmails
    } = this.state
    const isLocalUserAllowed =
      !isConferenceHost &&
      raiseHandAllowed &&
      raiseHandAllowed.length > 0 &&
      raiseHandAllowed.includes(userId)

    const isPanelView =
      (isGridActive &&
        (stateSharing || (screenStreamList && screenStreamList.length > 0))) ||
      !isGridActive

    const len = streamList && streamList.length
    var MINWIDTH =
      len <= 4
        ? '40%'
        : len > 4 && len < 10
        ? '30%'
        : len >= 10 && len < 17
        ? '20%'
        : len >= 17 && len < 25
        ? '15%'
        : '13%'

    const isMaxParticipants =
      event.event_type === 'webinar' &&
      initialEmails &&
      initialEmails.length >= 5
    return (
      <div>
        {failInvitation && message.error('Please try again.')}
        {successInvitation &&
          message.success(
            'Your invitation sent successfully. Participant(s) will get notification in their email'
          )}
        {showVideo && (
          <>
            <Prompt
              when={showPrompt}
              message={() => {
                prompt = window.confirm(t('events:prompt_message'))
                if (prompt) {
                  this.leaveEvent()
                  return prompt
                } else {
                  return false
                }
              }}
            />
            <Row>
              <div className="conference_agora" id="agora_fullScreen">
                <div className="streams">
                  <div
                    className={`${
                      isPanelView ? 'scrollbar_chime' : 'grid__view'
                    }`}
                  >
                    {this.state.isWhiteboardSharing && (
                      <div className="">
                        <Whiteboard
                          wbRoomUUID={this.state.wbRoomUUID}
                          wbRoomToken={this.state.wbRoomToken}
                          userId={userId}
                          consumerId={this.state.wbConsumerId}
                        />
                      </div>
                    )}

                    {!videoTiles ? (
                      <video
                        autoPlay
                        muted
                        playsInline
                        className="default_element"
                      />
                    ) : (
                      <>
                        {isConferenceHost && (
                          <div
                            className={`${
                              isPanelView
                                ? 'local_video chime_main__view'
                                : 'grid__view__item'
                            }   ${!isPanelView &&
                              tileId === userId &&
                              '_active-speaker'}`}
                            style={{
                              minWidth: !isPanelView ? MINWIDTH : 'default'
                            }}
                          >
                            <div
                              id="local_stream"
                              className="agora_main_view"
                            />

                            {isCameraOff && (
                              <DisabledContainer
                                participants={remoteUserDetails}
                                id={userId}
                              />
                            )}
                            <VolumeControls
                              id={userId}
                              name={'You'}
                              isAudioMuted={isMicOff}
                              toggleMute={this.toggleMute}
                              isConfCreator={isConfCreator}
                            />
                          </div>
                        )}

                        {!isConferenceHost && isLocalUserAllowed && (
                          <div
                            className={`${
                              isPanelView
                                ? 'local_video chime_main__view'
                                : 'grid__view__item'
                            }   ${!isPanelView &&
                              tileId === userId &&
                              '_active-speaker'}`}
                            style={{
                              minWidth: !isPanelView ? MINWIDTH : 'default'
                            }}
                          >
                            <DisabledContainer
                              participants={remoteUserDetails}
                              id={userId}
                            />
                            <VolumeControls
                              id={userId}
                              name={'You'}
                              isAudioMuted={isMicOff}
                              toggleMute={this.toggleMute}
                              isConfCreator={isConfCreator}
                            />
                          </div>
                        )}

                        {!isConferenceHost &&
                          streamList &&
                          streamList.length === 0 && (
                            <div className="chime_main__view">
                              <div className="disabled__container">
                                <h1
                                  style={{
                                    color: 'white',
                                    fontWeight: 'bolder'
                                  }}
                                >
                                  Waiting for host to join...
                                </h1>
                              </div>
                            </div>
                          )}
                        {streamList.map((item, index) => {
                          let id = item.streamId
                          let remotename = remoteUserDetails.find(
                            remote => id === remote.user_id
                          )
                          const isVideoDisabled = item.peerMuteVideo

                          const isSharedScreen =
                            screenStreamList &&
                            screenStreamList.length > 0 &&
                            screenStreamList[0].streamId === id

                          return (
                            <div
                              className={`${
                                !isPanelView
                                  ? 'grid__view__item'
                                  : !isConferenceHost &&
                                    !isLocalUserAllowed &&
                                    index === 0 &&
                                    !isSharedScreen
                                  ? `local_video chime_main__view`
                                  : 'remoteChimePlayer'
                              } chimeId_${id} ${!isPanelView &&
                                tileId === id &&
                                '_active-speaker'}`}
                              style={{
                                minWidth: !isPanelView ? MINWIDTH : 'default'
                              }}
                              key={id}
                            >
                              <div
                                id={`remote_${id}`}
                                className="agora_side_view"
                              />
                              {isVideoDisabled && (
                                <DisabledContainer
                                  participants={remoteUserDetails}
                                  id={id}
                                />
                              )}
                              {!isSharedScreen && (
                                <VolumeControls
                                  id={id}
                                  name={remotename && remotename.user_name}
                                  isAudioMuted={item && item.peerMuteAudio}
                                  toggleMute={this.toggleMute}
                                  isConfCreator={isConfCreator}
                                />
                              )}
                            </div>
                          )
                        })}
                        {/* <div id="sharevideo" /> */}
                      </>
                    )}
                    {/* </div>   */}
                    {isLoading && (
                      <Card.OverlayPlayer>
                        {t('general:loading')}
                      </Card.OverlayPlayer>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}

        {allowJoin && (
          <>
            <div className={`${isJoined ? 'controller-items' : ''}`}>
              {isJoined && (
                <EventMenus
                  isAudio={!isMicOff}
                  show={App.state.show}
                  isVideo={!isCameraOff}
                  // videoRef={toggleVideoRef}
                  audioRef={this.toggleAudioRef}
                  raiseHandAllowed={raiseHandAllowed}
                  isHandRaised={isHandRaised}
                  isGridActive={isGridActive}
                  raiseHandRequesting={raiseHandRequesting}
                  isConfCreator={isConfCreator}
                  isRecording={event.is_recording}
                  isConferenceHost={isConferenceHost}
                  allowScreenSharing={showScreenIcon}
                  isScreenSharing={stateSharing}
                  isBreakoutRoom={event.has_breakout_room}
                  isConferenceParticipant={isConferenceParticipant}
                  isRemoteScreenSharing={
                    isJoined && screenStreamList.length !== 0 && !stateSharing
                  }
                  onLeave={this.toggleModal}
                  onClickMute={this.handleMute}
                  onClickVideo={() =>
                    isCameraOff
                      ? this.turnOnLocalCamera()
                      : this.turnOffLocalCamera()
                  }
                  onEndForAll={this.toggleEndModal}
                  onScreenShare={this.sharingScreen}
                  onClickBreakoutRooms={params => {
                    App.onHeaderItemClick(params)
                    App.onBreakoutRoom(isConfCreator && event.has_breakout_room)
                  }}
                  onClickParticipants={params => App.onHeaderItemClick(params)}
                  onClickFullScreen={this.fullScreenMode}
                  onClickGrid={() =>
                    this.setState({ isGridActive: !this.state.isGridActive })
                  }
                  onHandRaise={App.handleRaiseHand}
                  onExitHandRaise={() => {
                    this.setState({ isLocalVideoPlaying: false }, () => {
                      App.declineToAsk(userId)
                    })
                  }}
                  localStream={this.localStream}
                  isAgora
                  isWhiteboardSharing={this.state.isWhiteboardSharing}
                  onClickWhiteboardSharing={() => {
                    this.sendWhiteboardStatus(!this.state.isWhiteboardSharing)
                  }}
                  consumerId={this.state.wbConsumerId}
                  userId={userId}
                  wbRoomToken={this.state.wbRoomToken}
                />
              )}
            </div>
            {canShowModal && (
              <Modal
                title={t('labels:alert')}
                visible={canShowModal}
                onOk={this.leaveEvent}
                onCancel={this.toggleModal}
              >
                <h3>{t('events:event_modal_warn')}</h3>
              </Modal>
            )}
            {canShowEndModal && (
              <Modal
                title={t('labels:alert')}
                visible={canShowEndModal}
                onOk={this.endEvent}
                onCancel={this.toggleEndModal}
              >
                <h3>{t('events:event_end_warn')}</h3>
              </Modal>
            )}
            {showParticipant && !isMaxParticipants && (
              <Modal
                title={t('v2:add_participants')}
                visible={showParticipant}
                onOk={this.sendInvitations}
                onCancel={this.showAddparticipant}
                footer={[
                  <Button key="back" onClick={this.showAddparticipant}>
                    cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={inviting}
                    onClick={this.sendInvitations}
                  >
                    {t('v2:send_invitations')}
                  </Button>
                ]}
              >
                <AddParticipant
                  emailList={emailList}
                  emails={emails}
                  fetchUserEmails={this.fetchUserEmails}
                  invitedEmails={initialEmails}
                  handleEmailChange={this.handleEmailChange}
                  fetching={fetchingEmail}
                  event={event}
                  t={t}
                />
              </Modal>
            )}
          </>
        )}

        {createBreakoutRoom && (
          <Modal
            title={t('v2:create_breakout_room')}
            visible={createBreakoutRoom}
            onOk={this.showBreakoutForm}
            width={'60vw'}
            centered
            onCancel={this.showBreakoutForm}
            footer={[
              <Button
                key="submit"
                type="primary"
                loading={isCreatingBreakoutRoom}
                onClick={this.createBreakoutForm}
                disabled={!roomCount}
              >
                {t('buttons:create')}
              </Button>
            ]}
          >
            <BreakoutForm
              setBreakoutRoomCount={this.setBreakoutRoomCount}
              breakoutRoom={roomCount}
              removeRoom={this.removeRoom}
              handleBreakoutRoom={this.handleBreakoutRoom}
              handleCheckChange={this.handleCheckChange}
              handleChangeLocale={this.handleChangeLocale}
              rooms={rooms}
              errors={roomErrors}
              organization={organization}
            />
          </Modal>
        )}
      </div>
    )
  }
}

export const VolumeControls = ({
  id,
  toggleMute,
  isAudioMuted,
  name,
  isConfCreator
}) => (
  <div className="volume__controls">
    {isConfCreator ? (
      <Tooltip placement="left" title={`Mute ${name}`}>
        <button
          className={`volume_button`}
          onClick={() => {
            toggleMute(id)
          }}
        >
          {isAudioMuted ? (
            <img alt="" src={mute} />
          ) : (
            <img alt="" src={unmute} />
          )}
        </button>
      </Tooltip>
    ) : (
      <button className={`volume_button disable_mute`}>
        {isAudioMuted ? <img alt="" src={mute} /> : <img alt="" src={unmute} />}
      </button>
    )}{' '}
    <span>{name}</span>
  </div>
)

export const DisabledContainer = ({ id, participants }) => {
  const disabledParticipant = participants.filter(item => item.user_id === id)

  const participant = disabledParticipant && disabledParticipant[0]
  const cover = participant && participant.avatar
  return (
    <div className="agora_disabled disabled__container">
      {/* {cover ? ( */}
      <img alt="" src={cover || userImage} className="disabled__video__image" />
      {/* ) : (
      <div className="disabled__video__image disabled__name">
        {name && name.charAt(0)}
      </div>
      )}  */}
      <div className="disabled__video__icon">
        <Icon className="video_off_icon" type="video-camera" />
      </div>
    </div>
  )
}
