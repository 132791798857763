import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../../store/actions'

import Analytic from './Analytic'

export default connect(
  state => ({
    data: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getLessonReport: bindActionCreators(
      actions.reports.getLessonReport,
      dispatch
    )
  })
)(Analytic)
