import { api } from '../../services'
import {
  AUTH_SUCCESS,
  AUTH_FAILED,
  AUTH_FETCHING,
  USER_SUCCESS,
  USER_FAILED,
  USER_FETCHING,
  USER_ATTRIBUTES_FETCHING,
  USER_LOGIN_SUCCESS,
  USERNAME_SUCCESS
} from '../types'

import { config } from '../../constants'

function signIn(email, password, isMarketplace) {
  return dispatch => {
    return api.user.signIn(email, password).then(response => {
      console.log(response, 'rrrrr........')
      if (isMarketplace) {
        window.location.replace(`https://${config.cookieDomain}/marketplace`)
        return
      } else {
        dispatch({
          type: AUTH_SUCCESS
        })
        dispatch({
          type: USER_LOGIN_SUCCESS
        })
        return response
      }
    })
  }
}

function authenticate() {
  return dispatch => {
    return api.user
      .authenticate()
      .then(response => {
        dispatch({
          type: AUTH_SUCCESS
        })

        dispatch({
          type: AUTH_FETCHING,
          payload: false
        })

        return response
      })
      .catch(error => {
        dispatch({
          type: AUTH_FAILED
        })

        dispatch({
          type: AUTH_FETCHING,
          payload: false
        })

        throw error
      })
  }
}

function getUser() {
  return dispatch => {
    dispatch({
      type: USER_FETCHING,
      payload: true
    })

    return api.user
      .getUser()
      .then(response => {
        dispatch({
          type: USER_SUCCESS,
          payload: response
        })

        dispatch({
          type: USER_FETCHING,
          payload: false
        })

        return response
      })
      .catch(error => {
        dispatch({
          type: USER_FAILED
        })

        dispatch({
          type: USER_FETCHING,
          payload: false
        })

        throw error
      })
  }
}

function getUserAttributes() {
  return dispatch => {
    return api.user.getUser().then(response => {
      dispatch({
        type: USER_SUCCESS,
        payload: response
      })

      dispatch({
        type: USER_ATTRIBUTES_FETCHING,
        payload: false
      })
      return response
    })
  }
}

function updateUser(id, attributes, dbAttributes) {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: USER_ATTRIBUTES_FETCHING,
      payload: true
    })
    return api.user
      .updateUser(
        id,
        attributes,
        dbAttributes && { ...state.user.info, ...dbAttributes }
      )
      .then(() => dispatch(getUserAttributes()))
      .catch(err => {
        dispatch({
          type: USER_ATTRIBUTES_FETCHING,
          payload: false
        })
        throw new Error(err.message)
      })
  }
}

const dispatchUserNameInfo = info => dispatch => {
  dispatch({
    type: USERNAME_SUCCESS,
    payload: info
  })
}

export { signIn, authenticate, getUser, updateUser, dispatchUserNameInfo }
