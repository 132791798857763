import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { Editor } from '../../ImageEditor'
import { general } from '../../../constants'
import { PopupMedia } from '../../../views/Library/internal'
import { modal_style } from './styled'

import _ from 'lodash'

const ImageUploadModal = props => {
  const [selectedItem, setSelectedItem] = useState(null)

  const {
    modalOpen,
    hideModal,
    imageRef,
    isMazeCreate,
    handleFileInsert
  } = props

  const handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const item = _.head(tempData.filter(value => value.isSelected))

    setSelectedItem(item)
  }

  return (
    <>
      <Modal
        visible={modalOpen}
        onCancel={hideModal}
        footer={null}
        width={'80%'}
        centered
        style={modal_style}
      >
        <div className="wrap_modal_tabs">
          <PopupMedia
            isPageBuilder
            isMazeCreate={isMazeCreate}
            handleInsertData={handleInsertData}
            types="images"
          />
        </div>
        {selectedItem && (
          <Modal
            visible={selectedItem}
            width="70%"
            onClick={() => setSelectedItem(null)}
            footer={null}
            centered
            onCancel={() => {
              setSelectedItem(null)
            }}
          >
            {/* <Preview library={selectedItem} ref={this.imageRef} /> */}

            {/* {showTextModal && (
                  )} */}
            <Editor library={selectedItem} ref={imageRef} />

            <div className="wrap_btn_actions">
              <Button
                type="danger"
                size="large"
                className="action_button"
                onClick={() => setSelectedItem(null)}
              >
                {general.CANCEL}
              </Button>
              {/* <Button
                    className="action_button"
                    size="large"
                    type="secondary"
                    onClick={() => {
                      this.handleShowTextModal()
                    }}
                  >
                    Add Text
                  </Button> */}
              <Button
                type="primary"
                size="large"
                className="action_button"
                onClick={() => handleFileInsert(selectedItem)}
              >
                {general.INSERT}
              </Button>
            </div>
          </Modal>
        )}
      </Modal>
    </>
  )
}

export default ImageUploadModal
