export default {
  general: {
    save: 'บันทึก',
    autosaved: 'บันทึกอัตโนมัติ',
    back_to: 'กลับไปยัง',
    by: 'โดย',
    chat: 'การพูดคุย',
    completed: 'เสร็จ',
    canceled: '취소됨',
    component: '부품',
    confirm: '확인',
    course: '코스',
    date: '날짜',
    details: '상세내용',
    empty: '정보없음',
    enrolled: '등록됨',
    free: '무료',
    graph: '그래프',
    loading: '로딩중...',
    lesson: '레슨',
    lessons: '레슨',
    live_assessment: '라이브 평가',
    live_event: '라이브 이벤트',
    no: '아니오',
    of: '###의',
    or: '또는 ',
    organization: '기관',
    page: '페이지',
    pages: '페이지들',
    personal: '개인적',
    poll: '투표',
    rating: '평가',
    reset: '리셋',
    signin: '로그인',
    signup: '계정생성',
    soon: '곧',
    type: '입력',
    uploading: '업로드 중...',
    yes: '네',
    something_went_wrong: '무언가 잘못 되었습니다!',
    view_all: '모두보기',
    upload_successful: '업로드 성공',
    upload_successful_message: '당신의 엑셀 문서가 성공적으로 업로드 되어 현재 처리 중입니다. 시간이 다소 걸릴 수 있습니다.',
    check_email_upload_successful: '업로드 된 사용자 목록 <1>보고서</1>를 보려면 <0>당신의 이메일을 확인</0>해 주세요.',
    is_private: '사적'
  },
  account: {
    title: '계정',
    subtitle: '당신의 설정과 계정 정보를 관리하세요.',
    saved: '계정이 성공적으로 변경되었습니다.'
  },
  assessments: {
    title: '평가들',
    subtitle: '평가 생성',
    finished: '평가가 끝났습니다',
    countdown: '라이브 평가가 ### 후에 방송될 것입니다',
    create: '평가 생성',
    list_title: '당신의 평가들',
    delete_warning: '정말로 이 아이템을 삭제하시겠습니까?',
    details_title: '평가 정보 추가',
    details_subtitle: '당신의 평가 이름을 만들고 시청자들에게 평가로 부터 무엇을 배울 수 있는지 알려주세요.',
    disclaimer: `제 녹화 이벤트가 다음에 해당하지 않을 것을 약속합니다: \n     \t•어떤 제 3자의 권한을 침범\n     \t•명예 훼손, 음란, 무례, 성적, 음란물, 폭력적, 모욕적, 학대적, 위협적이거나 차별적\n      인 콘텐츠, 심지어 별표로 위장한 경우와 같은 모든 불법적 활동을 포함\n     \t•다른 사람들에 대한 사적 공격들을 포함\n     \t•다른 참가자들, 공적 인물들 또는 연예인들 거짓 행세\n     \t•해당 개인의 동의 없이 그 개인 또는 그 개인의 사적정보의 녹화물을 포함하고 전 세계 모두의 정보 보호 규제법을 어기지 않음\n     \t•어떤 상업적 시도 또는 어떤 형태의 “스팸”을 포함`,
    assessment_details: '평가 정보',
    assessment_settings: '평가 설정'
  },
  buildersettings: {
    title: '출판 설정',
    subtitle: '이벤트에 누가 열람이 가능하고 참가가 가능한지 설정을 추가하세요.'
  },
  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: '추가',
    add_page: '페이지 추가',
    add_lesson: '레슨 추가',
    add_answer: '새 정답 추가',
    add_group: '그룹 추가',
    add_organization: '기관 추가',
    add_question: '질문 추가',
    add_quiz: '퀴즈 추가',
    add_users: '사용자 추가',
    back: '뒤로',
    back_to_course: '코스 전체보기로 돌아가기',
    back_to_lesson: '레슨 전체보기로 돌아가기',
    back_to_media: '미디어 라이브러리로 돌아가기',
    back_to_details: '상세정보로 돌아가기',
    cancel: '취고',
    clear: '지우기',
    close: '닫기',
    clone: '복사',
    close_lesson: '레슨 닫기',
    clone_course: '코스 복사',
    confirm: '확인',
    create: '생성',
    download: '다운로드',
    download_mass_user_tamplate: '형식 다운로드하기',
    add_mass_users: '집단 사용자들 추가',
    upload_excel_list: '엑셀 목록 업로드',
    'delete': '미디어 삭제',
    edit: '편집',
    edit_course: '코스 편집',
    enroll: '등록',
    finish: '끝내기',
    upload_image: '이미지 업로드',
    upload_image_video: '이미지 또는 비디오 업로드',
    select_media_library: '미디어 라이브러리 선택',
    make_public: '공개하기',
    make_private: '비밀로 하기',
    make_org: '기관 생성',
    media: '미디어 라이브러리',
    my_theme: '내 주제',
    next: '다음',
    next_lesson: '다음 레슨',
    next_step: '다음 단계',
    previous_step: '이전 단계',
    publish: '출판',
    publish_assessment: '당신의 평가 출판하기',
    publish_course: '당신의 코스 출판하기',
    publish_event: '당신의 이벤트 출판하기',
    reset: '리셋',
    reset_elements: '요소드르 리셋',
    save: '저장',
    creating: '생성중...',
    updating: '업데이트중...',
    saved: '저장됨',
    save_for_later: '미뤄두기',
    save_settings: '설정 저장',
    select_files: '파일 선택',
    select_theme: '주제 선택',
    start_lesson: '레슨 시작',
    update: '업데이트',
    upload: '업로드',
    upload_audio: '오디오 업로드',
    upload_photo: '프로파일 사진 업로드',
    upload_video: '비디오 업로드',
    enrolled: '등록됨',
    unenroll: '등록취소',
    from_library: '미디어 라이브러리',
    remove: '제거',
    removed: '제거됨',
    start_broadcast: '방송 시작',
    end_broadcast: '방송 종료',
    camera: '카메라',
    screen: '화면',
    screen_with_camera: '카메라 화면',
    insert: '삽입',
    inserting: '삽입'
  },
  card: {
    quick_add: '빠른 추가',
    add: '추가',
    clone: '복사',
    completed: '레슨 완료됨'
  },
  course: {
    upcoming_course: '곧 다가오는 코스',
    unPublishEvent: '출판되지 않은 이벤트',
    unPublishAssessment: '출판되지 않은 평가',
    pastCourse: '훈련 코스',
    upcoming: '다가오는 라이브 이벤트',
    pastEvent: '과가 라이브 이벤트',
    pastAssessment: '과거 라이브 평가',
    upcomingAssessment: '다가오는 라이브 평가',
    created: '생성된 날짜:',
    assignees: '지정인',
    organisations: ' {{ 기관들 }} 기관들, <2> {{ 사용자들 }} 사용자들</2>',
    event_date: '라이브 이벤트 날짜  <1> {{ 잘짜 }} </1>',
    delete_warning: '정말로 삭제하시겠습니까?',
    clonning_no_lesson: '이 코스를 복사하려면 최소 한 개의 레슨을 추가하세요',
    clone_warning: '정말로 복사하시겠습니까',
    clone_success: '현재 복사 중입니다',
    disclaimer: '제 녹화 이벤트가 다음에 해당하지 않을 것을 약속합니다: \n     \t•어떤 제 3자의 권한을 침범\n     \t•명예 훼손, 음란, 무례, 성적, 음란물, 폭력적, 모욕적, 학대적, 위협적이거나 차별적\n      인 콘텐츠, 심지어 별표로 위장한 경우와 같은 모든 불법적 활동을 포함\n     \t•다른 사람들에 대한 사적 공격들을 포함\n     \t•다른 참가자들, 공적 인물들 또는 연예인들 거짓 행세\n     \t•해당 개인의 동의 없이 그 개인 또는 그 개인의 사적정보의 녹화물을 포함하고 전 세계 모두의 정보 보호 규제법을 어기지 않음\n     \t•어떤 상업적 시도 또는 어떤 형태의 “스팸”을 포함'
  },
  coursecreate: {
    dropzone_text: '미디어 삽입',
    sidebar_text: '<1/> 당신의 <3/> 레슨들을 위한 주제를 선택하세요',
    delete_warning: '정말로 이 레슨을 삭제하시겠습니까?'
  },
  coursepage: {
    quizview_loading: '정답 확인 중... 기다려주세요',
    quizview_answer: '당신의 답'
  },
  coursequiz: {
    title: '모든 레슨 내 퀴즈들'
  },
  dashboard: {
    tooltip_title: '다시 이 비디오를 보여주지 마세요',
    page_title: '당신 코스들의 통계',
    page_subtitle: '당신이 생성한 코스들의 상태',
    profile_add_info: '프로파일을 완성하려면 당신의 <1>{{uncompletedText}}</1>를 추가하세요',
    profile_build: '<1>여기</1>에 당신의 프로파일을 만드세요, 당신의 흥미와 기술들을 알려주세요.',
    profile_title: '프로파일 강세',
    more_details: '더 자세한 정보'
  },
  errors: {
    answer: '답을 입력해 주세요',
    answer_correct: '옳은 답을 선택해 주세요',
    code: '당신의 코드를 입력하세요',
    criterion: '기준을 입력하세요',
    duration: '기간이 필요합니다',
    element: '요소를 입력하세요',
    email: '이메일을 입력하세요',
    email_invalid: '유효한 이메일이 아닙니다',
    feedback_correct: '올바른 피드백을 입력하세요',
    feedback_incorrect: '올바르지 않은 피드백을 입력하세요',
    host: '이벤트 호스트를 입력하세요',
    host_assessment: '평가 호스트를 입력하세요',
    image: '이미지를 업로드하세요',
    location: '당신의 위치를 업로드하세요',
    live_location: '방송 위치를 선택하세요',
    limit: '라이브 스트리밍 분을 입력하세요',
    min_3char: '최소 3 문자',
    number: '양수를 입력하세요',
    host_email_require: '호스트 이메일이 필요합니다',
    attendee_email_require: '참가자들의 이메일이 필요합니다',
    name: '당신의 이름을 입력하세요',
    not_found_course: '코스가 발견되지 않았습니다',
    not_found_event: '이벤트가 발견되지 않았습니다',
    not_found_assessment: '평가가 발견되지 않았습니다',
    password: '당신의 비밀번호를 입력하세요',
    password_digit: '최소 한 개의 숫자',
    password_short: '비밀번호가 짧습니다, 최소 10개 문자가 필요합니다',
    password_special: '최소 한개의 특수문자',
    password_uppercase: '최소 한개의 대문자',
    password_lowercase: '최소 한개의 소문자',
    phone: '당신의 전화번호를 입력하세요',
    phone_invalid: '유효하지 않은 전화번호',
    position: '당신의 위치를 입력하세요',
    question: '질문을 입력하세요',
    start_date: '시작 날짜 & 시간이 필요합니다',
    tag: '태그를 선택하세요',
    topics: '최소 한개의 주제를 선택하세요',
    live_topics: '주제를 추가하세요',
    title: '제목을 입력하세요',
    title_assessment: '평가 제목을 입력하세요',
    title_course: '코스 제목을 입력하세요',
    title_empty: '제목을 비울 수 없습니다',
    title_event: '이벤트 제목을 입력하세요',
    title_lesson: '레슨 제목을 입력하세요',
    title_quiz: '퀴즈 이름을 입력하세요',
    username: '당신의 아이디를 입력하세요',
    upload_error: '오류 - 파일이 호환되지 않습니다!',
    upload_more_than_10: '한번에 최대 10개 까지의 파일을 업로드 할 수 있ㅅ브니다',
    topics_max: '최대 3개 까지의 주제를 더하세요',
    upload_excel: '올바른 파일을 업로드 했는지 확인하세요',
    title_excel: '형식 업로드 오류:',
    file_size_exceed: '파일 사이즈 초과',
    subtitle_excel: '제공된 형식을 사용하고 열들에 작성했는지 확인하세요',
    select_broadcast_option: '이 이벤트 방송을 위한 옵션을 선택하세요',
    multi_hosts_number_error: '방송자들의 수는 이 이벤트를 위해 지정된 수를 초과할 수 없습니다. ',
    conference_people_number_error: '참가자들의 수는 이 이벤트를 위해 지정된 수를 초과할 수 없습니다. ',
    select_max_host: '이 이벤트를 위해 최소 한명의 방송자를 선택하세요',
    select_max_conference: '이 이벤트를 위해 최소 한명의 참가자를 선택하세요',
    stream_stop_error: '갑자기 스트리밍이 중지되었습니다!',
    unsecure_context: '치명적 오류: 안전하지 않은 환경으로 브라우져가 카메라와 마이크에 접속할 수 없습니다. SSL을 설치하고 https로 접속하세요',
    ws_not_supported: '치명적 오류: 웹소켓이 이 브라우져에서 지원되지 않습니다'
  },
  events: {
    title: '라이브 이벤트 상세정보 추가',
    change_host: '호스트 변경',
    finished: '이벤트가 끝났습니다',
    countdown: '### 후에 라이브 이벤트가 방송됩니다',
    subtitle: '당신 라이브 이벤트 이름을 정하고 관중들에게 이 이벤트로 부터 무엇을 배울 수 있는지 알려주세요.',
    poll_disabled: '이 이벤트에서는 투표가 불가능합니다',
    disclaimer: '제 녹화 이벤트가 다음에 해당하지 않을 것을 약속합니다: \n     \t•어떤 제 3자의 권한을 침범\n     \t•명예 훼손, 음란, 무례, 성적, 음란물, 폭력적, 모욕적, 학대적, 위협적이거나 차별적\n      인 콘텐츠, 심지어 별표로 위장한 경우와 같은 모든 불법적 활동을 포함\n     \t•다른 사람들에 대한 사적 공격들을 포함\n     \t•다른 참가자들, 공적 인물들 또는 연예인들 거짓 행세\n     \t•해당 개인의 동의 없이 그 개인 또는 그 개인의 사적정보의 녹화물을 포함하고 전 세계 모두의 정보 보호 규제법을 어기지 않음\n     \t•어떤 상업적 시도 또는 어떤 형태의 “스팸”을 포함',
    prompt_message: '이 페이지를 떠나면 이벤트가 종료됩니다. 정말로 이 페이지를 떠나시겠습니까?',
    duration_exceed_message: '당ㅇ신의 방송 시간이 초과되면 앱이 방송을 종료할 것입니다.',
    before_schedule_message: '계획된 이벤트 시간 전에 방송을 시작하게 됩니다. 방송을 중지하면 이벤트가 종료됩니다.',
    browser_unsupportive_message: '당신의 브라우져는 화면 공유를 지원하지 않습니다. 이 링크로 지원되는 브라우져들을 볼 수 있습니다',
    cancel_switch_host_message: '호스트가 불가능합니다. 이 이벤트는 당신 쪽에서 계속됩니다.',
    coaching_prompt_message: '이벤트 종료 시 까지 이 페이지를 떠날 수 없습니다.',
    event_modal_warn: '이 이벤트를 떠나시겠습니까?',
    confirm_message: 'OK를 클릭하면 이벤트가 종료됩니다.',
    screen_share_denied: '화면 공유를 거절하였습니다',
    media_not_found: '카메라와 마이크가 없거나 당신 기기에서 접속이 허용되지 않았습니다',
    media_not_access: '다른 어떤 프로세스가 카메라와 마이크를 사용하고 있으며 기기의 접속을 막고있습니다',
    constraint_error: '당신의 비디오와 오디오 조건을 충족하는 기기를 발견하지 못했습니다. 비디오 및 오디오 조건을 바꾸세요.',
    media_access_denied: '카메라와 마이크에 접속하도록 허용받지 않았습니다'
  },
  filters: {
    days7: '지난 7일',
    days15: '지난 15일',
    days30: '지난 30일',
    days365: '지난 365일',
    date: '생성된 날짜',
    relevance: '관련',
    popularity: '인기',
    name: '이름',
    group: '그룹',
    Newest_to_Oldest: '최신순',
    Oldest_to_Newest: '가장 오래된 순',
    A_Z: 'ㄱ 에서 ㅎ',
    Z_A: 'ㅎ 에서 ㄱ'
  },
  helpers: {
    password: '당신의 계정을 안전하게 지키기 위해 최소 한개의 소문자와 한개의 숫자가 요구됩니다. 또한 최소 10개 글자가 팔요합니다.',
    phone: '전화 국가 코드를 포함해 주세요, 예를 들어: +65',
    username: '당신의 아이디는 공적 정보로 다뤄지며 필요시 다른 사용자들과 공유됩니다. 그러므로, 당신의 사적 정보를 당신의 아이디에 사용하지 않을 것을 권고드립니다.',
    public_description_normal: '당신의 <0><0/></0>는 저장되었으며 비밀로 초기화됩니다. 이는 당신만이 이 컨텐츠를 볼 수 있다는 의미입니다. 이 컨텐츠를 공개하려면 아래 옵션 중 하나를 선택할 수 있습니다. 당신의 코스를 대중에게 공개한다는 것은 이 플랫폼 모든 사용자들이 그 코스를 사용할 수 있다는 의미입니다.  이 특정 사용자 옵션을 통해 Sharelook 계정에 연결된 원하는 사용자들의 이메일 주소를 사용해 특정 사용자들과 당신의 코스를 공유할 수 있습니다. ',
    public_description_admin: '이 <0><0/></0>는 저장되었으며 비밀로 초기화됩니다. 이는 당신(그리고 이 컨텐츠 창조자)만이 이 컨텐츠를 볼 수 있다는 의미입니다. 이 컨텐츠를 공개하려면 아래 옵션 중 하나를 선택할 수 있습니다. 당신의 코스를 대중에게 공개한다는 것은 이 플랫폼 모든 사용자들이 그 코스를 사용할 수 있다는 의미입니다 – 이는 당신 기관 외 사람들도 포함합니다. 그 코스를 기관에게 공개한다는 것은 그 기관 내 모든 사용자들이 그 코스를 볼 수 있다는 뜻입니다. 또한 당신은 그 코스를 1개 이상의 그룹들에게 공개할 수 있으며 이는 그 그룹들 내 사용자들 만이 그 코스를 볼 수 있다는 것을 의미합니다. 마지막으로, 원하는 사용자들의 Sharelook 계정 이메일 주소를 사용하여 당신 기관 내 그리고 기관 외 특정 사용자들에게 공개할 수도 있습니다.'
  },
  labels: {
    add: '추가',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: '정답 종류',
    answers: '정답들',
    back_to_quiz: '퀴즈 목록으로 돌아가기',
    back_to_question: '질문 목록으로 돌아가기',
    chat: '채팅',
    chat_available: '이 이벤트에서는 채팅이 불가능합니다',
    chat_report: '채팅 리포트',
    choice_single: '일형식',
    choice_multiple: '다형식',
    choice_free: '"Free" 선택',
    choice_sorting: '"Sorting" 선택',
    choice_matrix: '"Matrix Sorting" 선택',
    clone_lesson_permission: '허가 복사',
    code: '코드',
    correct: '정답',
    created: '생성 날짜',
    clone_modal: '코스 복사',
    cloning_course: '코스 복사중...',
    cloning: '복사중...',
    cloning_course_desc: '이 과정은 어느정도 시간이 걸릴 수 있습니다. 기다려주세요',
    cloning_success: '코스 "<0/>" 가 성공적으로 복사되었습니다!',
    description: '설명',
    disclaimer: '부인성명',
    drop_file: '여기에 파일을 놓으세요...',
    duration: '기간',
    edit: '편집',
    email: '이메일',
    event: '이벤트',
    event_all: '그의 모든 이벤트들',
    event_date: '라이브 이벤트 로컬 날짜/시간',
    event_this: '이 이벤트',
    expire: '###에 만료',
    expiry_date: '만료 날짜',
    forgot: '패스워드를 잊어버렸나요?',
    group: '그룹',
    groups_list: '그룹들 목록',
    host: '호스트',
    id_annotation: '주석 아이디',
    id_quiz: '퀴즈 아이디',
    incorrect: '옳지 않음',
    interests: '흥미들',
    invited: '초대됨',
    live_chat: '라이브 토론',
    live_stream_limit: '라이브 스트리밍 분',
    contact_text: '월별 라이브 스트리밍 제한을 바꾸려면 ###에 연락해 주세요',
    contact_admin: '<0>당신의 월별 라이브스트리밍 제한을 바꾸려면 </0> <1>admin@sharelook.com</1>에 연락해 주세요',
    limit_stream_text: '남은 라이브스트리밍 분: <0>{{ limit }}</0> 분 <1/><2/> <3>당신의 월별 라이브스트리밍 제한을 바꾸려면 </3> <4>admin@sharelook.com</4>에 연락해주세요',
    location: '위치',
    lessons: '레슨',
    location_broadcast: '호스트 서버 위치',
    location_broadcast_subtitle: '오디오비주얼 지연을 줄이기 위해 방송 호스트 위치에 가장 가까운 위치를 추가하세요',
    media_manager: '미디어 매니져',
    minutes: '분',
    name: '이름',
    online_quiz: '온라인 퀴즈',
    organiser: '저자',
    organization: '기관',
    overall_emotion: '전체적 경험:',
    organizations_list: '기관 목록',
    password: '패스워드',
    participants: '참가자들',
    picture: '사진 (16:9)',
    phone: '전화',
    photo: '사진',
    poll: '투표',
    position: '직책',
    processing: '처리중',
    privacy_settings: '사생활보호 설정',
    question: '질문',
    quiz_create: '새 퀴즈 만들기',
    quiz_name: '퀴즈 이름',
    quiz_update: '퀴즈 업데이트',
    select_organization: '기관 선택',
    settings: '설정',
    show: '보기',
    show_graph: '그래프 결과 보기',
    signin: '로그인',
    signup: '계정생성',
    sort_by: '###로 정리하기',
    start_date: '시작 날짜',
    tags: '태그들',
    terms: '저는 이 <1>약관</1>들에 동의합니다.',
    title: '제목',
    topics: '주제들',
    topic: '주제',
    total_comments: '총 댓글들',
    username: '아이디',
    users_list: '사용자들 목록',
    users_emails: '특정 사용자 이메일을 입력해 주세요',
    description_specifiction_email: '다수 특정 사용자들과 공유하기 위해 줄 하나당 한개의 이메일 주소를 입력해주세요.',
    message_specific_email: '특정 사용자의 이메일을 입력해 주세요.',
    viewers: '시청자들',
    edit_course: '편집',
    edit_assessment: '편집',
    edit_event: '편집',
    delete_course: '삭제',
    delete_event: '삭제',
    delete_assessment: '삭제',
    view_course: '보기',
    view_event: '보기',
    view_assessment: '보기',
    date_event: '라이브 이벤트 날짜들',
    date_assessment: '라이브 평가 날짜',
    clone_of: '복사',
    clone_course: 'Create a clone of this course that you can modify independently of the original, includes all lessons and pages.',
    make: '만들기',
    available_to: '모든 ShareLook 사용자가 이용가능',
    uploaded_by: '업로드: ',
    course: '코스',
    assessment: '평가',
    user_not_exist: '사용자가 존제하지 않습니다, 초대를 보내시겠습니까?',
    check_user: '알림',
    user_not_exist_in_org: '이 기관에 사용자가 존제하지 않습니다, 초대를 보내시겠습니까?',
    title_modal_stream: '기관 라이브스트리밍 제한 변경',
    des_warning_stream: '변경을 하게 됩니다',
    monthly_stream: '월별 라이브스트리밍 제한:',
    min_per_month: '분/월',
    broadcast_label: '하고 싶은 것:',
    hosts_emails: '특정 호스트의 이메일 입력',
    attendee_emails: '특정 참가자의 이메일 입력',
    description_host_specifiction_email: '한 줄당 한개의 이메일을 입력하세요',
    presenter_label: '프레젠터',
    presenter_label_conference: '사람 수',
    event_privacy: '컨퍼런스 모드'
  },
  broadcastOptions: {
    stream_from_phone: '폰으로 라이브 스트리밍하기',
    other_webrtc: 'PC에서 웨비나 하기',
    multihost: '내 PC에서 웨비나 멀티캐스트',
    peer2peer: '코칭 세션',
    conference: '라이브 컨퍼런스'
  },
  lessoncreate: {
    title: '레슨 상세내용 더하기',
    subtitle: '레슨 이름을 정하고 시청자들이 이 레슨에서 무엇을 배울 수 있는지 알려주세요',
    input_label_title: '레슨 제목',
    input_label_topics: '레슨 주제',
    input_label_description: '레슨 설명',
    delete_warning: '정말로 이 페이지를 삭제하시겠습니까?'
  },
  lessonoverview: {
    title: '레슨 추가',
    subtitle: '이미 존재하는 레슨 추가 또는 새 레슨 생성',
    new_lesson: '새 레슨 생성',
    new_lesson_sub: '처음부터 새로운 레슨 만들기!',
    existing_lesson: '이미 존재하는 레슨 선택하기',
    existing_lesson_sub: '이미 존재하는 레슨으로의 링크, 이 레슨에 적용된 변경내용들은 다른 코스들에도 적용됩니다',
    clone_lesson: '이미 존재하는 레슨 복제하기',
    clone_lesson_sub: '이 코스 레슨의 특수 카피를 만듭니다, 이 레슨에 적용된 변경내용들은 본 레슨에는 적용되지 않습니다.',
    clone_lesson_modal_sub: '코스에 추가하고자 하는 레슨 아래의 복사 버튼을 클릭해주세요'
  },
  management: {
    groups_title: '그룹들 관리',
    delete_warning: '확실합니까?',
    organizations_title: '내 기관들',
    organizations_subtitle: '목록에 기관 추가하기',
    users_title: '사용자 관리',
    users_position: '시니어 어카운트 매니져',
    user_not_found: '사용자 발견 안됨',
    select_org: '사용자들 관리를 위해 기관을 발견 및 선택해주세요'
  },
  audio: {
    modal_title: '오디오 입력',
    list_view: '목록 뷰',
    create_audio_modal_title: '오디오 생성',
    edit_audio_modal_title: '오디오 편집',
    public_audio_not_found: '오디오 발견 안됨',
    private_audio_not_found: '아직 오디오를 업로드하지 않았습니다',
    are_you_sure_to_delete: '정말로 삭제하시겠습니까?',
    type_something: '입력해보세요',
    select_voice: '선택',
    file_name: '파일 이름',
    description: '설명',
    topic: '주제',
    key_words_tag: '키워드 태그',
    mp3_audio: 'MP3 오디오',
    created: '생성됨',
    duration: '기간',
    close: '닫기'
  },
  image: {
    modal_title: '이미지 삽입',
    image: '이미지',
    pixel: '픽셀',
    dimension: '수치',
    public_image_not_found: '이미지 없음',
    private_image_not_found: '아직 아무 이미지도 업로드하지 않았습니다'
  },
  annotation: {
    modal_title: '주석'
  },
  text: {
    modal_title: '텍스트 편집기'
  },
  video: {
    modal_title: '비디오 삽입',
    image: '비디오',
    mp4_video: 'MP4 비디오',
    public_video_not_found: '비디오 없음',
    private_video_not_found: '아직 비디오를 업로드하지 않았습니다'
  },
  media: {
    title: '미디어 라이브러리',
    subtitle: '당신 파일 라이브러리',
    modal_title: '미디어 업로드',
    delete_warning: '삭제 확인',
    delete_message: '삭제된 미디어는 사용된 페이지에 계속해서 나타날 것입니다',
    dropzone_text: '또는 드래그 드랍',
    delete_success: '미디어가 성공적으로 삭제 되었습니다',
    upload_file: '파일 업로드',
    drag_drop: '또는 드래그 드랍',
    import_file: '컴퓨터에서 파일(들) 불러오기',
    no_media: '아직 미디어를 업로드 하지 않았습니다',
    media_type: '미디어 종류',
    video: '비디오',
    image: '이미지',
    audio: '오디오',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: '미디어가 없습니다',
    of: '###의',
    disclaimer: '제 녹화 이벤트가 다음에 해당하지 않을 것을 약속합니다: \n     \t•어떤 제 3자의 권한을 침범\n     \t•명예 훼손, 음란, 무례, 성적, 음란물, 폭력적, 모욕적, 학대적, 위협적이거나 차별적\n      인 콘텐츠, 심지어 별표로 위장한 경우와 같은 모든 불법적 활동을 포함\n     \t•다른 사람들에 대한 사적 공격들을 포함\n     \t•다른 참가자들, 공적 인물들 또는 연예인들 거짓 행세\n     \t•해당 개인의 동의 없이 그 개인 또는 그 개인의 사적정보의 녹화물을 포함하고 전 세계 모두의 정보 보호 규제법을 어기지 않음\n     \t•어떤 상업적 시도 또는 어떤 형태의 “스팸”을 포함',
    my_media: '내 미디어',
    public_library: '공공 라이브러리',
    organization_library: '기관 라이브러리',
    upload_from_computer: '컴퓨터에서 업로드',
    create: '생성',
    male: '남성',
    female: '여성',
    processing_audio: '오디오 처리 중',
    create_annotation: '각주 추가 중',
    popup_over_lesson_text: '클릭 후 각주가 레슨 페이지 위에 나타날 것입니다',
    edit_detail: '자세정보 편집',
    delete: '삭제',
    go_create_audio: '오디어 생성',
    show_more_details: '더 많은 정보 보기',
    show_less_details: '정보 숨기기',
    drop_files: '업로드 경로와 파일들을 드랍하세요',
    or: '또는',
    import_files: '컴퓨터에서 파일들 가져오기'
  },
  navigation: {
    account: '계정',
    assessments: '평가',
    dashboard: '대쉬보드',
    groups: '그룹 관리',
    logout: '로그아웃',
    organisations: '기관',
    organizations_users: '기관 & 사용자들',
    reports: '리포트',
    schedule: '스케쥴',
    training: '트레이닝',
    users: '사용자들',
    workshop: '내 워크샵',
    nowLive: '방송'
  },
  onboarding: {
    step1_title: '단계 1 : 자기소개를 조금 해 주세요',
    step1_subtitle: '이름이 무엇인가요? 어디 나라에 사시나요? 직책이 무엇인가요?`',
    step2_title: '단계 2 : 향상할 수 있는 기술을 몇가지 선택해주세요',
    step2_subtitle: '다음으로, 더 잘 이해하고자 하는 것을 알려주세요. 아직 안 배운 것이 있나요? 새 분야의 공부? 당신이 사랑하는 이들이 하는 일들?'
  },
  pagebuilder: {
    title: '레슨 생성',
    subtitle: '시청자들이 배울 수 있는 컨텐츠를 만들 시간입니다, 사진, 비디오, 인포그래픽들을 사용할 수 있습니다',
    select_layout: '페이지 레이아웃 선택',
    template1: '빈',
    template2: '제목 & 부제목',
    template3: '제목 & 텍스트',
    template4: '제목, 미디오 & 텍스트',
    template5: '제목 & 미디어',
    template6: '제목 & 퀴즈'
  },
  pageconstructor: {
    title: '레슨 생성 및 복사',
    subtitle: '시청자들이 배울 수 있는 컨텐츠를 만들 시간입니다, 사진, 비디오, 인포그래픽들을 사용할 수 있습니다'
  },
  passwordreset: {
    success_coded: '당신이 제공한 이메일 주소로 인증 코드를 보냈습니다, 아래 필드에 붙여넣기 하여 새 패스워드를 설정하세요.',
    success_changed: '성공적으로 새 패스워드를 설정했습니다, 이제 로그인할 수 있습니다.'
  },
  placeholders: {
    search: '검색',
    answer: '정답',
    chat: '말해보세요...',
    code: '코드',
    criterion: '기준',
    date_event: '날짜 & 시간 선택',
    date_start: '시작 날짜',
    date_expire: '만료 날짜',
    description: '설명',
    elements: '요소 정리',
    email: '이메일',
    feedback_correct: '피드백 옮음',
    feedback_incorrect: '피드백 옳지 않음',
    host: '호스트',
    keywords: '키워드(들) 검색',
    lesson_description: '레슨 설명',
    location: '위치',
    minutes: '분',
    name: '이름',
    organiser: '저자',
    organization: '기관',
    password: '패스워드',
    phone: '전화',
    photo: '사진',
    picture: '사진',
    point: '포인트',
    position: '직책',
    question: '질문',
    quiz: '퀴즈 이름',
    search_course: '코스로 검색',
    search_media: '미디어 검색',
    search_name: '이름으로 검색',
    search_title: '제목으로 검색',
    search_training: '훈련 검색',
    settings: '설정',
    tags: '태그',
    title: '제목',
    topics: '주제들',
    username: '아이디',
    keyword: '키워드',
    max_500_characters: '최개 500 글자',
    no_description: '이 파일에 설명이 없습니다',
    no_keyword: '이 파일에 키워드가 없습니다',
    no_topics: '이 파일에 설정된 주제가 없습니다',
    feedback: '메시지',
    contact: '어떻게 도와드릴 수 있나요?'
  },
  publishsettings: {
    title: '공개 설정',
    event_subtitle: '누가 당신의 이벤트를 보고 참가할 수 있는지 당신의 이벤트에 설정을 추가하세요',
    assessment_subtitile: '당신의 평가의 이름을 정하고 시청자들이 무엇을 배울 수 있는지 알려주세요',
    course_subtitle: '누가 당신의 코스를 볼 수 있고 참가할 수 있는 지 당신의 코스에 설정을 추가하세요',
    disclaimer: '1. 등록\n사용자로서 등록/계정을 생성함으로써 당신은 저희 [사용자동의서]에 동의하고 저희 [사생활보호정책]를 읽었음을 인지합니다.\n 2. 코스 만들기\n 저희의 도구를 사용하여 코스 컨텐츠를 만듬으로써, 당신은 저희 [사용자동의서]에 동의하고 저희 [사생활보호정책]를 읽었음을 인지합니다. \n 3. 이벤트 만들기\n저희의 도구를 사용하여 이벤트 컨텐츠를 만듬으로써, 당신은 저희 [사용자동의서]에 동의하고 저희 [사생활보호정책]를 읽었음을 인지합니다. \n 4. 평가 만들기\n저희의 도구를 사용하여 평가 컨텐츠를 만듬으로써, 당신은 저희 [사용자동의서]에 동의하고 저희 [사생활보호정책]를 읽었음을 인지합니다.',
    dates_validation_error: '두 날짜 모두 채워져야 합니다',
    emails_validation_error: '이메일 목록이 유효하지 않습니다.',
    specific_email_validation_error: '한개의 이메일만 가능합니다',
    i_agree_to_these: '저는 이것에 동의합니다',
    terms_and_conditions: '약관',
    PublicDescription: '무료 독립 사용자들: (개인 및 공공 옵션들) 당신의 [코스|이벤트|평가]가 저장되었고 비밀로 초기화됩니다. 이는 당신만이 이 컨텐츠를 볼 수 있다는 것을 의미합니다. 이 컨텐츠 공개를 위해서는 아래에서 선택이 가능합니다. 이는 코스가 이 플랫폼의 모든 사용자들에게 공개된다는 의미입니다.',
    OrganisationsDescription: 'Org 사용자들:  ""Finish"" 대신 ""Publish""을 사용하고, 출판 옵션들 없이, 다른 사용자들과 같이 출판 페이지로 가져옴) 당신의 [코스|이벤트|평가]가 저장되었고 비밀입니다. 이는 당신과 당신 기관의 운영자들만이 열람이 가능하다는 것을 의미합니다. 사용자로서, 당신은 운영자 권한 없이 스스로 자신의 기관내에서 자신의 컨텐츠를 출판할 수 없습니다. 당신의 운영자들 중 한명에게 연락하여 당신의 컨텐츠를 기관 전체, 기관 내 특정 그룹, 또는 특정 사용자들에게 공적으로 출판하기를 요청하세요. ',
    OrganisationGroupsDescription: 'Org 운영자들: (기관 내에서 만들어진 컨텐츠에 대해: 비밀, 공개, 기관, 그룹, 특정 사용자들): 이 [코스|이벤트|평가]는 저장되었고 비밀로 초기화되어 있습니다. 이는 당신(그리고 이 컨텐츠 창조자)만이 이 컨텐츠를 볼 수 있다는 의미입니다. 이 컨텐츠를 공개하려면 아래 옵션 중 하나를 선택할 수 있습니다. 당신의 코스를 대중에게 공개한다는 것은 이 플랫폼 모든 사용자들이 그 코스를 사용할 수 있다는 의미입니다 – 이는 당신 기관 외 사람들도 포함합니다. 그 코스를 기관에게 공개한다는 것은 그 기관 내 모든 사용자들이 그 코스를 볼 수 있다는 뜻입니다. 또한 당신은 그 코스를 1개 이상의 그룹들에게 공개할 수 있으며 이는 그 그룹들 내 사용자들 만이 그 코스를 볼 수 있다는 것을 의미합니다. 마지막으로, 원하는 사용자들의 Sharelook 계정 이메일 주소를 사용하여 당신 기관 내 그리고 기관 외 특정 사용자들에게 공개할 수도 있습니다.',
    SpecificUsersDescription: '유료 독립 사용자들: (개인, 공공, 특정 사용자들) 당신의 [코스|이벤트|평가]가 저장되었고 비밀로 초기화됩니다. 이는 당신만이 이 컨텐츠를 볼 수 있다는 것을 의미합니다. 이 컨텐츠 공개를 위해서는 아래에서 선택이 가능합니다. 이 코스를 이는 코스가 이 플랫폼의 모든 사용자들에게 공개된다는 의미입니다. 특정 사용자 옵션을 사용하면 당신의 코스를 Sharelook 계정에 연결된 이메일 주소들을 사용하여 특정 사용자들과 공유할 수 있습니다. '
  },
  quiz: {
    quiz_empty: '아직 퀴즈를 생성하지 않았습니다',
    questions_empty: '아직 퀴즈를 생성하지 않았습니다'
  },
  regions: {
    asia_pacific_australia: '호주',
    asia_pacific_india: '인도',
    asia_pacific_japan: '일본',
    asia_pacific_s_korea: '한국',
    asia_pacific_singapore: '싱가폴',
    asia_pacific_taiwan: '대만',
    eu_belgium: '벨기에',
    eu_germany: '독일',
    eu_ireland: '아일랜드',
    south_america_brazil: '브라질',
    us_central_iowa: '아이오와',
    us_east_s_carolina: '사우스 캐롤라이나',
    us_east_virginia: '버지니아',
    us_west_california: '캘리포니아',
    us_west_oregon: '오레곤'
  },
  reports: {
    title: '리포드들',
    workshop_report: '워크샵 보고서',
    user_report: '사용자 보고서',
    organization_workshop: '기관 워크샵',
    course: '코스',
    live_event: '라이브 이벤트',
    workShop_graph: '워크샵 그래프',
    organization_accaaount: '기관 계정',
    all: '모두',
    name: '이름',
    id_number: '아이디 번호',
    email: '이메일',
    search_user_report: '사용자 보고서 검색',
    participant: '참가자',
    participants: '참가자들',
    host: '호스트',
    username: '아이디',
    event: '이벤트',
    quiz: '퀴즈',
    assessment: '평가',
    time: '시간',
    no_recent_activity: '최근 활동내역 없음',
    workshops: '워크샵',
    courses: '코스들',
    live_events: '라이브 이벤트',
    live_assessments: '라이브 평가',
    quizzes: '퀴즈들',
    overview: '전체보기',
    by: '에 의해',
    views: '시청수',
    status: '상태',
    poll: '설문',
    comment: '댓글',
    comments: '댓글들',
    no_comments: '댓글 없음',
    no_polls: '설문 없음',
    live_date: '라이브 날짜',
    score: '점수',
    question: '질문',
    submit: '접수',
    recent_activity: '최근 활동',
    ago: '전',
    no_data_found: '정보 없음',
    start_end_live: '라이브 시작 / 종료',
    join_leave_date: '참가 / 나가기',
    watched_time: '시청 시간',
    complete: '완료',
    in_complete: '완료',
    today: '오늘',
    seven_days: '7 일',
    last_month: '지난 달',
    one_year: '1년',
    all_time: '전체',
    report: '보고',
    from_date: '시작 날짜',
    to_date: '끝 날짜',
    all_workshop: '모든 워크샵',
    all_participants: '모든 참가자들',
    live_assessment: '라이브 평가',
    users: '사용자들',
    download: '다운로드',
    recent: '최근',
    WorkShop: '워크샵',
    Activity: '활동',
    created_date: '생성 날짜',
    lessons: '레슨들',
    lesson: '레슨',
    completed: '완료됨',
    report_download: '보고 다운로드',
    this_month: '이 달',
    search_course: '코스 검색 ',
    participants_graph: '참가자 그래프',
    viewed: '시청',
    enrolled: '등록됨',
    description: '설명',
    topic: '주제',
    analytic: '분석',
    quiz_list: '퀴즈 목록',
    select_one: '한 개 선택',
    group_status: '그룹 상태',
    duration_min: '길이 (분)',
    submit_poll: '설문 접수',
    download_events: '이벤트 다운로드',
    search_event: '이벤트 검색',
    duration: '길이',
    total_participants: '총 참가자들',
    message: '메시지',
    avg_message: '평균 메시지',
    per_message: '메시지/이벤트 퍼센트'
  },
  signup: {
    success_step1: '당신이 제공한 이메일 주소로 인증 코드를 보냈습니다, 아래 필드에 붙여넣기하여 당신의 등록을 인증하세요.',
    success_step2: '감사합니다. 성공적으로 등록되었습니다.',
    privacy: 'Sharelook의 <1> 약관</1> 과 <3> 사생활보호정책</3>에 동의합니다'
  },
  stream: {
    emotion_impressive: '훌륭한',
    emotion_unimpressive: '별로',
    message_not_started: '곧 다가옴',
    message_running: '라이브',
    message_processing: '저희는 현재 플레이백을 위해 라이브 녹화를 처리 중 입니다. 비디오를 시청하고 평가와 피드백을 계속해서 추가하기 위해 잠시후 다시 확인해주세요.'
  },
  tabs: {
    audience: '관중',
    course: '코스 전체보기',
    details: '라이브 이벤트 상세정보',
    description: '설명',
    host: '호스트',
    lesson: '레슨 전체보기',
    lessons: '레슨',
    overview: '전체보기',
    page: '페이지 빌더',
    publish: '출판',
    settings: '출판 설정',
    total_views: '총 시청수',
    live_participates: '라이브 참가자들',
    live_chats: '라이브 채팅',
    live_quiz: '라이브 투표',
    trainer_rating: '훈련가 평가',
    trainer_times: '훈련가 시간',
    courses_quizzes: '코스 퀴즈들',
    user_list: '사용자 목록',
    discussion: '토론',
    notes: '내 노트들'
  },
  topics: {
    SALES_TRAINING: '세일즈 훈련',
    NEGOTIATION_TRAINING: '협상 훈련',
    CROSS_CULTURAL_AWARENESS: '다문화 지식',
    NETWORKING_SKILLS: '네트워킹 기술',
    SOFTWARE_ARCHITECTURE: '소프트웨어 아키텍쳐',
    PROJECT_MANAGEMENT: '프로젝트 매니지먼트',
    DIGITAL_TRANSFORMATION: '디지털 트랜스포메이션',
    IT_CONSULTING: 'IT 컨설팅',
    MANAGEMENT_CONSULTING: '매니지먼트 컨설팅',
    HR_CONSULTING: 'HR 컨설팅',
    INTERNET_OF_THINGS: 'Internet of Things (IoT)',
    CYBERSECURITY: '사이버 시큐리티',
    PRIVACY_AND_GDRP: '사생활보호 및 GDPR'
  },
  training: {
    title: '훈련',
    title_assessment: '평가',
    subtitle: '코스 및 라이브 이벤트',
    enrolled_title: '등록된 코스들 및 라이브 이벤트',
    enrolled_subtitle: '당신이 등록한 코스 및 라이브 이벤트들',
    enrolled_message: '당신은 현재 아무 코스 또는 라이브 이벤트에 등록되어 있지 않습니다',
    past_live_event_title: '과거 라이브 이벤트',
    past_live_event_subTitle: '끝난 라이브 이벤트',
    past_live_event: '과거 라이브 이벤트가 없습니다',
    recomended_title: '추천된 코스 및 라이브 이벤트들',
    now_live_event_title: '현재 라이브',
    now_live_event_subTitle: '현재 라이브 또는 곧 라이브 될 이벤트들',
    now_live_event: '현재 라이브 이벤트가 없습니다',
    webinar_event_title: '웨비나 채널',
    webinar_event_subTitle: '끝난 웨비나',
    webinar_event: '현재 웨비나가 없습니다',
    p2p_event_title: '코칭 세션',
    p2p_event_subTitle: '끝난 코칭 세션들',
    p2p_event: '현재 코칭 세션이 없습니다',
    now_live_assessment_title: '현재 라이브',
    now_live_assessment_subTitle: '현재 라이브 또는 곧 라이브 될 평가',
    now_live_assessment_message: '현재 라이브 평가가 없습니다',
    upcoming_assessment_title: '다가오는 라이브 평가',
    upcoming_assessment_subtitle: '다가오는 라이브 평가들',
    upcoming_assessment_message: '현재 다가오는 라이브 평가가 없습니다',
    past_live_assessment_title: '과거 라이브 평가',
    past_live_assessment_subTitle: '끝난 라이브 평가들',
    past_live_assessment_message: '현재 과거 라이브 평가들이 없습니다',
    recomended_subtitle: '당신의 <0><0/></0> 을 근거로 하여 코스와 라이브 이벤트들을 선별하였습니다',
    search: '훈련 검색',
    search_assessment: '평가 검색',
    recomended_message: '현재 추천된 코스나 평가들이 없습니다'
  },
  warnings: {
    'delete': '확실한가요?'
  },
  workshop: {
    title: '내 워크샵',
    subtitle: '당신만의 코스를 만들거나 라이브 이벤트를 주췌하세요',
    new_course: '새 코스 만들기',
    new_event: '새 라이브 이벤트 만들기',
    new_assessment: '새 라이브 평가 만들기',
    courses_title: '코스 & 라이브 이벤트를 만들었습니다',
    courses_subtitle: '만든 코스를 편집 & 출판하고 라이브 이벤트들을 계획하세요',
    tags_course: '코스',
    tags_live: '라이브 이벤트',
    tags_assessment: '라이브 평가'
  },
  help_center: {
    sidebar: {
      title: '어떻게 도와드릴까요?',
      back_to: '대쉬보드'
    },
    menu: {
      help_center: '도움 센터',
      whats_new: '새소식',
      feedback: '피드백',
      feedback_title: 'Sharelook에 피드백 보내기',
      terms_and_conditions: '약관',
      privacy_policy: '사생활보호 정책',
      contact_us: '연락처'
    },
    get_started: {
      title: 'Sharelook 시작하기',
      description: {
        main: '사용이 쉬운 코스 빌더에 라이브 비디오를 결합하였습니다',
        tagline: 'ShareLooks의 특허 라이브 방송 시스템으로 참가를 이끌어내고, 학습을 자동화하고, 지식 공유를 최대화 할 수 있습니다'
      }
    }
  },
  whats_new: {
    title: 'ShareLook으로 시작하세요',
    description: {
      main: '스토리 텔링 스타일의 학습을 빠른 시간 내에 저렴하게 만들 수 있다는 것을 아셨나요? ShareLook 코스 빌더와 해당 재능을 조합해야 합니다. 저희에게 그 방법을 물어보실 수 있습니다',
      tagline: '태그라인'
    }
  },
  create_workshop: '워크샵 생성하는 법',
  create_course: '코스 생성하는 법',
  create_user: '사용자 생성하는 법',
  create_group: '그룹 생성하는 법',
  create_webinar: '웨비나 생성하는 법',
  create_live_event: '라이브 이벤트 생성하는 법',
  create_live_assessment: '라이브 평가 생성하는 법',
  create_live_on_mobile: '모바일에서 라이브 생성',
  personalize_profile: '프로파일 개인화',
  manage_group: '그룹 관리',
  report: '보고',
  add_annotations: '주석/팝업 추가 방법',
  add_audio: '오디오 추가 방법',
  add_header_and_footer: '머릿글 & 바닥글 추가 방법',
  add_image: '이미지 추가 방법',
  add_quiz_content: '퀴즈 컨텐츠 추가 방법',
  add_text_content: '텍스트 컨텐츠 추가 방법',
  add_user: '사용자 추가 방법',
  add_video: '비디오 추가 방법',
  clone_course: '코스 복사 방법',
  find_live_broadcast: '라이브 방송 검색 방법',
  contact_us: {
    title: '연락처',
    subject_placeholder: '주제를 입력하세요*',
    content_placeholder: '메시지를 입력하세요*',
    subject_required: '*주제가 필요합니다',
    content_required: '*메시지가 필요합니다',
    submit: '보내기',
    cancel: '취소',
    sent: '보내짐'
  },
  feedback: {
    title: 'sharelook에 피드백 보내기',
    attach_title: '파일 첨부하기',
    choose: '파일 선택하기',
    detail: '파일이 지원 팀과 공유될 것입니다',
    content_placeholder: '메시지를 입력하세요*',
    content_required: '*메시지가 필요합니다',
    submit: '보내기',
    cancel: '취소',
    sent: '보내짐'
  },
  discussion: {
    join_discussion: '토론 참가'
  },
  notes: {
    title: '내 노트들',
    add_note: '새 노트 추가하기'
  }
}
