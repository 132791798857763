import http, { questionAnswerAiInstance } from './http'

function mazeProvider(data) {
  return http({
    method: 'POST',
    url: '/programme',
    data
  })
}

function getAvatar() {
  return http({
    method: 'GET',
    url: '/programme/avatar'
  })
}

function deleteById(id) {
  console.log('deletebyidworkedapi', id)
  return http({
    method: 'DELETE',
    url: '/programme/' + id
  })
}

function listMazes(params) {
  return http({
    method: 'GET',
    url: `/programme?mode=${params.mode}&limit=${params.limit}&offset=${params.offset}${params.search ? "&search=" + params.search : ""}`
  })
}

function getMazeById(id) {
  return http({
    method: 'GET',
    url: `/programme/${id}`
  })
}

function updateMaze(id, data) {
  return http({
    method: 'PUT',
    url: `/programme/${id}`,
    data
  })
}

function recommendedCourse(data) {
  console.log('log recommendedCourse', data)
  return http({
    method: 'POST',
    url: '/courses/maze',
    data
  })
}

function generateMazeByAi(data) {
  return questionAnswerAiInstance({
    method: 'POST',
    url: '?service=maze-scenario',
    data
  })
}

export {
  mazeProvider,
  getAvatar,
  deleteById,
  recommendedCourse,
  listMazes,
  getMazeById,
  updateMaze,
  generateMazeByAi
}
