import { USER_BADGE_FETCHING, BADGE_GET_BY_ID } from '../types'

import { api } from '../../services'

function getBadgesById(id) {
  return dispatch => {
    dispatch({
      type: USER_BADGE_FETCHING,
      payload: true
    })

    return api.badge.getBadgesById(id).then(response => {
      dispatch({
        type: BADGE_GET_BY_ID,
        payload: response.data
      })

      dispatch({
        type: USER_BADGE_FETCHING,
        payload: false
      })
    })
  }
}

export { getBadgesById }
