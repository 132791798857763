import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../store/actions'
import Audio from './Audio'

const mapStateToProps = state => ({
  library: state.library,
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLibraryList: actions.library.getLibraryList,
      showEditAudioModal: actions.audioSynthesizer.showEditAudioModal,
      getPublicList: actions.library.getPublicList,
      getOrgList: actions.library.getOrgList,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateLibraryStatus: actions.library.updateLibraryStatus,
      updateOrgLib: actions.library.updateOrgLib,
      handleMakeType: actions.library.handleMakeType,
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Audio)
