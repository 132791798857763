import React, { Component } from 'react'
import Text from '../../../../../../../../../../../src/components/Builder/Elements/Text'
import { withTranslation } from 'react-i18next'
import '../../../styles.scss'

class EditTextComponent extends Component {
  // constructor (props) {
  //   super(props)
  // }

  handleInput = (content, delta, source, editor) => {
    const { setTextContent } = this.props
    setTextContent(content)
  }

  render () {
    const {
      pageBuilder: { content }
    } = this.props
    return (
      <Text content={content} isHideSaveButton handleInput={this.handleInput} />
    )
  }
}

export default withTranslation('text')(EditTextComponent)
