import React, { Fragment, useState } from 'react'

import './MultipleImageSelect.scss'
import {
  Row,
  Col,
  List,
  Spin,
  Result,
  Popconfirm,
  Card,
  Badge,
  Button,
  Modal
} from 'antd'
import GalleryIcon from '../../../../../../../assets/gallery_icon.png'

function MultipleImageSelect({
  onOpenModal,
  images = [],
  isImageLoading,
  onDeleteImage
}) {
  const [imageToPreview, setImageToPreview] = useState('')
  return (
    <Fragment>
      <section className="section">
        <Row>
          <Col span={12}>
            <div className="head">
              <div className="head__title">{'Select Images'}</div>
              <div className="head__description">
                {'You can select multiple images for the scenario.'}
              </div>
            </div>
          </Col>
        </Row>
        {images.length > 0 && (
          <Row gutter={32}>
            <Col span={imageToPreview ? 12 : 24}>
              <List
                size="large"
                className="maze-image-list"
                header={
                  <>
                    Your selected images{' '}
                    <Badge
                      count={images.length}
                      style={{
                        backgroundColor: '#fff',
                        color: '#999',
                        border: '1px solid #d9d9d9'
                      }}
                    />
                    <h3>
                      <i>Click to preview</i>
                    </h3>
                  </>
                }
                bordered
                dataSource={images}
                renderItem={item => {
                  return (
                    <List.Item className="maze-image-list-item" hoverable>
                      <img
                        onClick={() => setImageToPreview(item.link)}
                        className={`maze-selected-image ${item &&
                          item.link === imageToPreview &&
                          'focus-image'}`}
                        alt="maze"
                        src={item.link}
                        loading="lazy"
                      />
                      <Popconfirm
                        placement="topRight"
                        title={'Are you sure to delete this image?'}
                        onConfirm={() => onDeleteImage(item.id)}
                        okText="Yes"
                        cancelText="No"
                        icon={false}
                      >
                        <Button
                          className="maze-selected-image-close"
                          type="danger"
                          size="small"
                          shape="circle"
                          icon="close"
                        />
                      </Popconfirm>
                    </List.Item>
                  )
                }}
              />
            </Col>
          </Row>
        )}
        <Spin spinning={isImageLoading}>
          <Card
            hoverable
            style={{ margin: '1rem 0', flex: '1' }}
            onClick={onOpenModal}
          >
            <Result
              icon={<img src={GalleryIcon} alt="" />}
              title="Select Image from the Media Library"
            />
          </Card>
        </Spin>
        <Modal
          visible={imageToPreview}
          footer={false}
          onCancel={() => setImageToPreview('')}
          className="image-popup-show"
          centered
        >
          <img
            loading="lazy"
            src={imageToPreview}
            alt="Preview"
            style={{ width: '100%' }}
          />
        </Modal>
      </section>
    </Fragment>
  )
}

export default MultipleImageSelect
