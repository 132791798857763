import React, { createRef, Fragment } from 'react'
import './styles.scss'
import { useTranslation, withTranslation } from 'react-i18next'
import {
  Icon,
  Tabs,
  Skeleton,
  Modal,
  Row,
  Col,
  Form,
  Alert,
  Input,
  Spin,
  Button,
  InputNumber,
  message,
  Tag
} from 'antd'
import api from '../../services/api'
import DeleteIcon from '../../assets/bin.svg'

import _ from 'lodash'
import CreatableSelect from 'react-select/creatable'

import Badge from '../Courses/Create/nestedComponents/CourseCreateMain/components/Badge'
import Certificate from '../Courses/Create/nestedComponents/CourseCreateMain/components/Certificate'

import PublishSkillJourney from './PublishSkillJourney'
import { Spinner, TopPageLink } from '../../components'

import moment from 'moment'
import { Container, Draggable } from 'react-smooth-dnd'
import { marketplace } from '../Courses/Create/nestedComponents/CourseCreateMain/images'
import ReactSVG from 'react-svg'
import { BottomSection, MiddleSection } from '../../components/Marketplace'
import ImageSelect from '../../components/Form/ImageSelect'

const { TabPane } = Tabs
const { Search } = Input

const TYPE = {
  COURSE: 'COURSES',
  EVENT: 'EVENTS',
  SCORM: 'SCORMS',
  MAZE: 'METAMAZE',
  VIDEOS: 'VIDEOS',
  PODCASTS: 'PODCASTS'
}

let emptyItemsObject = {
  courses: [],
  events: [],
  mazes: [],
  scorms: [],
  videos: [],
  podcasts: []
}

class SkillJourney extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingCourses: true,
      loadingEvents: true,
      loadingVideos: true,
      loadingPodcasts: true,
      loadingMazes: true,
      loadingScorms: true,
      activeTabKey: '1',
      isTopicLoading: false,
      options: [],
      isTopicsMax: false,
      value: [],
      courses: [],
      events: [],
      videos: [],
      podcasts: [],
      mazes: [],
      scorms: [],
      basketList: [],
      inputValue: '',
      checkedItems: emptyItemsObject,
      basket: emptyItemsObject,
      title: '',
      organiser: '',
      score: '',
      description: '',
      objectives: '',
      video_link: '',
      category: '',
      marketplaceDescription: '',
      outline: '',
      is_free: false,
      price: 5,
      shareProfile: false,
      isCoverLoading: false,
      isImageLoading: false,
      isCover: false,
      imageLink: '',
      openBadgeModal: false,
      openCertificateModal: false,
      isCertificateLoading: false,
      isBadgeLoading: false,
      isBackground: false,
      isSkillLoading: false,
      isPublish: false,
      isSaving: false,
      marketplaceData: null,
      isBasketError: false
    }
    this.imageRef = createRef()
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props

    if (id) {
      this.setState(
        {
          isSkillLoading: true
        },
        () => {
          api.skillJourney.getSkillById(id).then(res => {
            this.setData(res.data)
          })
        }
      )
    }

    this.getProducts()
  }

  setData = obj => {
    const {
      user: {
        info: { bio, linkedin, username }
      }
    } = this.props
    let {
      title,
      organiser,
      pass_score,
      cover,
      exp_date,
      start_date,
      id,
      setting,
      cert_id,
      badge_id,
      description,
      topics = [],
      course_list = [],
      scorm_list = [],
      event_list = [],
      maze_list = [],
      discussion_room_list = [],
      collection_list = [],
      marketplace
    } = obj
    course_list = course_list.map(item => ({ ...item, type: TYPE.COURSE }))
    scorm_list = scorm_list.map(item => ({ ...item, type: TYPE.SCORM }))
    event_list = event_list.map(item => ({ ...item, type: TYPE.EVENT }))
    maze_list = maze_list.map(item => ({ ...item, type: TYPE.MAZE }))
    collection_list = collection_list.map(item => ({
      ...item,
      type: TYPE.VIDEOS
    }))
    discussion_room_list = discussion_room_list.map(item => ({
      ...item,
      type: TYPE.PODCASTS
    }))
    let combinedList = [
      ...course_list,
      ...scorm_list,
      ...event_list,
      ...maze_list,
      ...discussion_room_list,
      ...collection_list
    ]
    combinedList.sort((a, b) => a.order - b.order)
    if (topics.length > 0) {
      this.getOldTopics(topics)
    }

    this.setState({
      title,
      organiser,
      score: pass_score,
      certId: cert_id,
      badgeId: badge_id,
      imageLink: cover,
      isSkillLoading: false,
      basketList: combinedList,
      basket: {
        ...this.state.basket,
        bio: bio,
        username: username,
        linkedin: linkedin,
        courses: course_list,
        scorms: scorm_list,
        events: event_list,
        mazes: maze_list,
        podcasts: discussion_room_list,
        videos: collection_list
      },
      start_date,
      exp_date,
      skillId: id,
      skillSetting: setting,
      description,
      // MarketPlace Data
      ...(marketplace
        ? {
            objectives: marketplace.course_obj,
            video_link: marketplace.video_link,
            category: marketplace.category_id,
            marketplaceDescription: marketplace.course_description,
            outline: marketplace.course_outline,
            is_free: marketplace.is_free,
            price: marketplace.price,
            shareProfile: marketplace.is_shared_profile
          }
        : null)
    })
  }

  getProducts = async () => {
    const params = {
      mode: 'skill',
      order: 'created_at',
      offset: 0,
      limit: 1000,
      date: 'all',
      search: '',
      from: '',
      to: ''
    }
    switch (this.state.activeTabKey) {
      case '1':
        api.courses
          .getCoursesList(params)
          .then(res => {
            this.setState({
              courses: res.data,
              filteredCourses: res.data,
              loadingCourses: false
            })
          })
          .catch(() =>
            this.setState({
              loadingCourses: false
            })
          )

        break

      case '2':
        api.reports
          .getMazeReport({
            offset: 0,
            limit: '',
            searchByTitle: '',
            date:
              (params && params.from && params.to
                ? 'other'
                : params && params.date) || '',
            ...(params && params.from && { from: params.from }),
            ...(params && params.to && { to: params.to })
          })
          .then(res => {
            this.setState({
              mazes: res.data.data.total,
              filteredMazes: res.data.data.total,
              loadingMazes: false
            })
          })
          .catch(() =>
            this.setState({
              loadingMazes: false
            })
          )

        break

      case '3':
        api.scorm
          .getScormList()
          .then(res => {
            this.setState({
              scorms: res.data,
              filteredScorms: res.data,
              loadingScorms: false
            })
          })
          .catch(() =>
            this.setState({
              loadingScorms: false
            })
          )
        break

      case '4':
        api.events
          .get(params)
          .then(res => {
            const filteredEvents = res.data.filter(item => {
              const newTime = moment(item.start_date_time).format('LLLL')
              const diffMinutes = moment().diff(newTime, 'minutes')
              const newDuration = 0 || 0 + 10
              const checkInterval = diffMinutes - newDuration
              return checkInterval <= 0
            })
            this.setState({
              events: filteredEvents,
              filteredEvents: filteredEvents,
              loadingEvents: false
            })
          })
          .catch(() =>
            this.setState({
              loadingEvents: false
            })
          )
        break
      case '5':
        let channelQuery = {
          mode: 'training',
          offset: 0,
          limit: 1000
        }
        api.channel
          .listChannels(channelQuery)
          .then(res => {
            const filteredVideos = res.data
            this.setState({
              videos: filteredVideos,
              filteredVideos: filteredVideos,
              loadingVideos: false
            })
          })
          .catch(() =>
            this.setState({
              loadingVideos: false
            })
          )
        break
      case '6':
        let podcastQuery = {
          mode: 'all',
          event_type: 'discussion_room',
          offset: 0,
          limit: 1000,
          schedule_state: 'all'
        }
        api.events
          .get(podcastQuery)
          .then(res => {
            const filteredPodcasts = res.data && res.data.data
            this.setState({
              podcasts: filteredPodcasts,
              filteredPodcasts: filteredPodcasts,
              loadingPodcasts: false
            })
          })
          .catch(() =>
            this.setState({
              loadingPodcasts: false
            })
          )
        break

      default:
        break
    }
  }

  onTabChange = val => {
    this.setState({ activeTabKey: val }, () => this.getProducts())
  }
  handleCheckItem = (isChecked, item) => {
    switch (this.state.activeTabKey) {
      case '1':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            courses: isChecked
              ? [...prevState.checkedItems.courses, item]
              : prevState.checkedItems.courses.filter(
                  course => course.id === item.id
                )
          }
        }))
        break

      case '2':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            mazes: isChecked
              ? [...prevState.checkedItems.mazes, item]
              : prevState.checkedItems.mazes.filter(maze => maze.id === item.id)
          }
        }))
        break

      case '3':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            scorms: isChecked
              ? [...prevState.checkedItems.scorms, item]
              : prevState.checkedItems.scorms.filter(
                  scorm => scorm.id === item.id
                )
          }
        }))
        break

      case '4':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            events: isChecked
              ? [...prevState.checkedItems.events, item]
              : prevState.checkedItems.events.filter(
                  event => event.id === item.id
                )
          }
        }))
        break
      case '5':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            videos: isChecked
              ? [...prevState.checkedItems.videos, item]
              : prevState.checkedItems.videos.filter(
                  video => video.id === item.id
                )
          }
        }))
        break
      case '6':
        this.setState(prevState => ({
          checkedItems: {
            ...prevState.checkedItems,
            podcasts: isChecked
              ? [...prevState.checkedItems.podcasts, item]
              : prevState.checkedItems.events.filter(
                  podcast => podcast.id === item.id
                )
          }
        }))
        break
      default:
        break
    }
  }

  addToBasket = () => {
    document.querySelectorAll('.skill-check').forEach(el => {
      el.checked = false
    })

    const {
      courses,
      events,
      mazes,
      scorms,
      videos,
      podcasts,
      checkedItems,
      basketList,
      basket
    } = this.state
    const combineAndDeduplicate = (basketItems, checkedItems, type) => {
      const combinedItems = [...basketItems, ...checkedItems]
      const uniqueItems = combinedItems.reduce((acc, item) => {
        if (!acc.some(existingItem => existingItem.id === item.id)) {
          acc.push({
            ...item,
            type
          })
        }
        return acc
      }, [])
      return uniqueItems
    }

    const newBasketCourses = combineAndDeduplicate(
      basket.courses,
      checkedItems.courses,
      TYPE.COURSE
    )
    const newBasketEvents = combineAndDeduplicate(
      basket.events,
      checkedItems.events,
      TYPE.EVENT
    )
    const newBasketScorms = combineAndDeduplicate(
      basket.scorms,
      checkedItems.scorms,
      TYPE.SCORM
    )
    const newBasketMazes = combineAndDeduplicate(
      basket.mazes,
      checkedItems.mazes,
      TYPE.MAZE
    )
    const newBasketVideos = combineAndDeduplicate(
      basket.videos,
      checkedItems.videos,
      TYPE.VIDEOS
    )
    const newBasketPodcasts = combineAndDeduplicate(
      basket.podcasts,
      checkedItems.podcasts,
      TYPE.PODCASTS
    )
    const combinedBasketList = [
      ...newBasketCourses,
      ...newBasketEvents,
      ...newBasketScorms,
      ...newBasketMazes,
      ...newBasketVideos,
      ...newBasketPodcasts
    ]
    const basketListIds = new Set(basketList.map(item => item.id))
    const itemsNotInBasketList = combinedBasketList.filter(
      item => !basketListIds.has(item.id)
    )
    const newBasketList = basketList.concat(itemsNotInBasketList)

    this.setState(
      {
        basketList: newBasketList,
        basket: {
          courses: newBasketCourses,
          events: newBasketEvents,
          scorms: newBasketScorms,
          mazes: newBasketMazes,
          videos: newBasketVideos,
          podcasts: newBasketPodcasts
        },
        filteredCourses: this.getUniqueItems(courses, newBasketCourses),
        filteredEvents: this.getUniqueItems(events, newBasketEvents),
        filteredScorms: this.getUniqueItems(scorms, newBasketScorms),
        filteredMazes: this.getUniqueItems(mazes, newBasketMazes),
        filteredVideos: this.getUniqueItems(videos, newBasketVideos),
        filteredPodcasts: this.getUniqueItems(podcasts, newBasketPodcasts),
        checkedItems: emptyItemsObject
      },
      () => {
        this.handleParam()
      }
    )
  }

  getUniqueItems = (array1, array2) =>
    array1.filter(obj => array2.indexOf(obj) === -1)

  removeFromBasket = (id, type, list) => {
    const {
      courses,
      events,
      scorms,
      mazes,
      videos,
      podcasts,
      basket,
      basketList
    } = this.state
    if (list) {
      let arr = basketList.filter(item => item.id !== id)
      this.setState({
        basketList: arr
      })
    }
    switch (type) {
      case TYPE.COURSE:
        let arr = basket.courses.filter(course => course.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            courses: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            courses: arr
          },
          filteredCourses: this.getUniqueItems(courses, arr)
        }))
        break

      case TYPE.EVENT:
        arr = basket.events.filter(event => event.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            events: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            events: arr
          },
          filteredEvents: this.getUniqueItems(events, arr)
        }))
        break
      case TYPE.MAZE:
        arr = basket.mazes.filter(maze => maze.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            mazes: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            mazes: arr
          },
          filteredMazes: this.getUniqueItems(mazes, arr)
        }))
        break
      case TYPE.SCORM:
        arr = basket.scorms.filter(scorm => scorm.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            scorms: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            scorms: arr
          },
          filteredScorms: this.getUniqueItems(scorms, arr)
        }))
        break
      case TYPE.VIDEOS:
        arr = basket.videos.filter(video => video.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            videos: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            videos: arr
          },
          filteredVideos: this.getUniqueItems(videos, arr)
        }))
        break
      case TYPE.PODCASTS:
        arr = basket.podcasts.filter(podcast => podcast.id !== id)
        this.setState(prevState => ({
          basket: {
            ...prevState.basket,
            podcasts: arr
          },
          checkedItems: {
            ...prevState.checkedItems,
            podcasts: arr
          },
          filteredPodcasts: this.getUniqueItems(podcasts, arr)
        }))
        break
      default:
        break
    }
  }
  onDragDrop = dropResult => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { removedIndex, addedIndex } = dropResult
    if (removedIndex !== null && addedIndex !== null) {
      let newSequences = [...this.state.basketList]
      const [removed] = newSequences.splice(removedIndex, 1)
      newSequences.splice(addedIndex, 0, removed)
      this.setState({ basketList: newSequences })
    }
    id && this.handleParam()
    this.handleParam()
    return null
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handlePublish = body => {
    this.setState(
      {
        isPublish: true
      },
      () => this.handleSubmit(body)
    )
  }

  handleParam = _.debounce(body => {
    const { isSaving } = this.state
    if (isSaving) {
      this.handleParam()
    } else {
      this.handleSubmit(body)
    }
  }, 1000)

  handleSubmit = async (obj = {}) => {
    const {
      title,
      organiser,
      imageLink,
      value,
      score,
      description,
      skillId = null,
      certId,
      badgeId,
      isPublish,
      basketList
    } = this.state

    let courses = [],
      scorms = [],
      events = [],
      mazes = [],
      videos = [],
      podcasts = []
    basketList.forEach((list, i) => {
      list.order = i
      switch (list.type) {
        case TYPE.COURSE:
          list.score = list.pass_score
          courses.push(list)
          break
        case TYPE.MAZE:
          list.score = list.minimum_pass_score
          mazes.push(list)
          break
        case TYPE.SCORM:
          list.score = list.pass_score
          scorms.push(list)
          break
        case TYPE.EVENT:
          list.score = list.pass_score
          events.push(list)
          break
        case TYPE.VIDEOS:
          list.score = list.pass_score
          videos.push(list)
          break
        case TYPE.PODCASTS:
          list.score = list.pass_score
          podcasts.push(list)
          break
        default:
          break
      }
    })
    const data = {
      title,
      description,
      cover_id: imageLink.id,
      pass_score: Number(score),
      topics: _.map(value, 'id'),
      organiser: organiser,
      cert_id: certId,
      badge_id: badgeId,
      courses,
      scorms,
      events,
      mazes,
      videos,
      podcasts
    }
    const setLoading = loading => this.setState({ isSaving: loading })

    const handleError = () => {
      setLoading(false)
      message.error('Error in request. Please try again!')
    }

    try {
      setLoading(true)

      if (isPublish) {
        const publishData = { ...data, ...obj }
        await api.skillJourney.updateSkillJourney(publishData, skillId)
        this.props.history.push(`/skill-journey/${skillId}`)
      } else if (skillId) {
        const skillData = { ...data, ...obj }
        await api.skillJourney.updateSkillJourney(skillData, skillId)
      } else {
        const response = await api.skillJourney.createSkillJourney(data)
        this.setState({ skillId: response.data.id })
      }

      setLoading(false)
    } catch (error) {
      handleError()
    }
  }

  getOldTopics = (topics = []) => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 5) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleParam()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  toggleBadgeModal = () => {
    this.setState(state => ({ openBadgeModal: !state.openBadgeModal }))
  }

  toggleCertificateModal = () => {
    this.setState(state => ({
      openCertificateModal: !state.openCertificateModal
    }))
  }

  badgePresentationUpload = (body, imageType) => {
    const { t, upload } = this.props
    this.toggleLoadingState(imageType)
    this.setState({ openBadgeModal: false, openCertificateModal: false })
    upload(body)
      .then(res => {
        const { id } = res
        this.toggleLoadingState(imageType)
        if (imageType === 'Certificate') {
          this.setState(
            {
              certId: id
            },
            () => this.handleParam()
          )
        } else {
          this.setState(
            {
              badgeId: id
            },
            () => this.handleParam()
          )
        }
      })
      .catch(() => {
        this.toggleLoadingState(imageType)
        message.error(t('v4:error_occured'))
      })
  }

  toggleLoadingState = type => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading
      }))
    } else {
      this.setState(state => ({ isBadgeLoading: !state.isBadgeLoading }))
    }
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  onSearch = e => {
    const value = e.target.value.toLowerCase()
    const activeTabKey = this.state.activeTabKey

    const contentTypes = {
      '1': 'courses',
      '2': 'mazes',
      '3': 'scorms',
      '4': 'events',
      '5': 'videos',
      '6': 'podcasts'
    }

    const contentType = contentTypes[activeTabKey]

    if (contentType) {
      const allItems = this.getUniqueItems(
        this.state[contentType],
        this.state.basket[contentType]
      )
      const filteredItems = allItems.filter(item =>
        item.title.toLowerCase().includes(value)
      )

      this.setState({
        [`filtered${contentType.charAt(0).toUpperCase() +
          contentType.slice(1)}`]: filteredItems
      })
    }
  }

  handleItem = _.debounce((item, v) => this.handleInputItem(item, v), 1000)

  handleInputItem = (item, value) => {
    this.setState(
      {
        [item]: value
      },
      () => {
        const { form } = this.props
        const { imageLink, basket } = this.state
        form.validateFields((err, values) => {
          if (!err) {
            const hasCover = imageLink && imageLink.id
            const hasItemInBasket =
              basket && Object.values(basket).every(x => x.length > 0)

            if (hasCover && hasItemInBasket) {
              this.handleParam()
            } else {
              this.setState({
                isCover: !hasCover,
                isBasketError: !hasItemInBasket
              })
            }
          }
        })
      }
    )
  }

  onScoreChange = _.debounce(
    (val, id, type) => this.handleScore(val, id, type),
    1000
  )

  handleScore = (val, id, type) => {
    const scoreTypes = {
      [TYPE.COURSE]: { key: 'courses', scoreField: 'pass_score' },
      [TYPE.MAZE]: { key: 'mazes', scoreField: 'minimum_pass_score' },
      [TYPE.SCORM]: { key: 'scorms', scoreField: 'pass_score' },
      [TYPE.EVENT]: { key: 'events', scoreField: 'pass_score' }
    }

    const typeInfo = scoreTypes[type]

    if (typeInfo) {
      const { key, scoreField } = typeInfo

      this.setState(
        prevState => ({
          basket: {
            ...prevState.basket,
            [key]: prevState.basket[key].map(item =>
              item.id === id ? { ...item, [scoreField]: val || 0 } : item
            )
          }
        }),
        this.handleParam
      )
    }
  }

  getTotalScore = ({ courses, mazes, events, scorms }) => {
    const courseScore = this.getScore(courses)
    const mazeScore = this.getScore(mazes)
    const eventScore = this.getScore(events)
    const scormScore = this.getScore(scorms)

    return courseScore + mazeScore + eventScore + scormScore
  }

  getScore = arr =>
    arr.reduce(
      (acc, item) =>
        Number(item.pass_score || item.minimum_pass_score || 0) + acc,
      0
    )
  getColor = type => {
    let color = 'default'
    switch (type) {
      case TYPE.COURSE:
        color = 'blue'
        break
      case TYPE.MAZE:
        color = 'green'
        break
      case TYPE.SCORM:
        color = 'geekblue'
        break
      case TYPE.EVENT:
        color = 'red'
        break
      case TYPE.VIDEOS:
        color = 'gold'
        break
      case TYPE.PODCASTS:
        color = 'purple'
        break
      default:
        break
    }
    return color
  }

  onMarketplaceButtonClick = () => {
    if (this.state.isMarketPlaceActive) {
      const {
        user: {
          info: { bio, linkedin, username }
        }
      } = this.props
      const {
        objectives,
        video_link,
        category,
        marketplaceDescription,
        outline,
        is_free,
        price,
        shareProfile
      } = this.state
      let marketplace = {
        video_link,
        category_id: category,
        course_obj: objectives,
        course_description: marketplaceDescription,
        course_outline: outline,
        bio,
        linkedin,
        username,
        is_free,
        price,
        shareProfile
      }
      this.handleParam({ marketplace: marketplace })
    }
    this.setState(prevState => ({
      isMarketPlaceActive: !prevState.isMarketPlaceActive
    }))
  }

  handleCoverChange = data => {
    this.setState({
      imageLink: data,
      isCover: false
    })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator },
      t
    } = this.props

    const {
      isTopicLoading,
      isTopicsMax,
      courses,
      filteredCourses,
      loadingCourses,
      events,
      filteredEvents,
      loadingEvents,
      videos,
      filteredVideos,
      loadingVideos,
      podcasts,
      filteredPodcasts,
      loadingPodcasts,
      mazes,
      filteredMazes,
      loadingMazes,
      scorms,
      filteredScorms,
      loadingScorms,
      options,
      value,
      title,
      description,
      organiser,
      isCoverLoading,
      isImageLoading,
      isCover,
      imageLink,
      modalOpen,
      isBackground,
      selectedItem,
      certId,
      badgeId,
      openCertificateModal,
      openBadgeModal,
      isBadgeLoading,
      isCertificateLoading,
      isSkillLoading,
      start_date,
      exp_date,
      score,
      image,
      file,
      basketList,
      isMarketPlaceActive,
      marketplaceData
    } = this.state
    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom: isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }

    if (isSkillLoading) return <Spinner />

    return (
      <Fragment>
        <TopPageLink
          hasMarketPlace
          page={t('buttons:workshop')}
          path="/workshop"
        />
        <div className="skill_journey__container">
          <div className="skill_journey__title">
            {t('v4:new_skill_journey')}
            <Button
              size="large"
              shape="round"
              onClick={this.onMarketplaceButtonClick}
              className="shl-primary-btn"
            >
              {isMarketPlaceActive ? (
                <>{t('v3:save_and_continue')}</>
              ) : (
                <>
                  <ReactSVG src={marketplace} className="svg-icon" />
                  {t('buttons:market_place').toUpperCase()}
                </>
              )}
            </Button>
          </div>
          <Form onSubmit={this.handlePublish}>
            <div className="create-course__content">
              <Row gutter={32}>
                <Col md={12}>
                  <Form.Item
                    label={t('labels:title')}
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('title', {
                      rules: [
                        {
                          required: true,
                          message: t('errors:title_course')
                        },
                        {
                          whitespace: true,
                          pattern: '.{3,}',
                          message: t('errors:min_3char')
                        }
                      ],
                      initialValue: title
                    })(
                      <Input
                        size="large"
                        placeholder={t('placeholders:title')}
                        maxLength={100}
                        onChange={e => this.handleItem('title', e.target.value)}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    label={t('labels:organiser')}
                    colon={false}
                    required={false}
                  >
                    {getFieldDecorator('organiser', {
                      initialValue: organiser
                    })(
                      <Input
                        addonBefore={t('general:by')}
                        size="large"
                        tighter="true"
                        placeholder={t('placeholders:organiser')}
                        onChange={e =>
                          this.handleItem('organiser', e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col md={12}>
                  <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
                    {t('labels:topics').toUpperCase()}
                  </div>
                  <CreatableSelect
                    styles={customStyles}
                    components={{
                      ClearIndicator: null,
                      DropdownIndicator: null,
                      LoadingIndicator: this.LoadingIndicator
                    }}
                    isMulti
                    placeholder=""
                    inputId="react-select-2-input"
                    isLoading={isTopicLoading}
                    menuIsOpen={this.isModalSelectOpen()}
                    onBlur={() => this.setState({ inputValue: '' })}
                    onChange={this.handleChange}
                    onCreateOption={this.handleCreate}
                    onInputChange={this.loadOptions}
                    options={options}
                    value={value}
                  />

                  {isTopicsMax && (
                    <div className="error_message">
                      {t('errors:topics_max')}
                    </div>
                  )}
                </Col>
              </Row>

              <ImageSelect
                coverInput={imageLink}
                onCoverChange={this.handleCoverChange}
              />
            </div>

            {isMarketPlaceActive && (
              <MiddleSection
                form={form}
                label={t('general:skill')}
                isFreeItem={this.state.is_free}
                category={this.state.category}
                price={this.state.price}
                videoLink={this.state.video_link}
                onSwitch={v => this.handleItem('is_free', v)}
                onCategory={v => this.handleItem('category', v)}
                onPrice={v => this.handleItem('price', v)}
                onVideoLink={v => this.handleItem('video_link', v)}
              />
            )}

            <div className="margin_top ">
              <BottomSection
                form={form}
                isMarketPlace={isMarketPlaceActive}
                label={t('general:skill')}
                description={description}
                outline={this.state.outline}
                objectives={this.state.objectives}
                shareProfile={this.state.shareProfile}
                marketplaceDescription={this.state.marketplaceDescription}
                onDescription={v => this.handleItem('description', v)}
                onObjectives={v => this.handleItem('objectives', v)}
                onOutline={v => this.handleItem('outline', v)}
                onShareProfile={v => this.handleItem('shareProfile', v)}
                onMarketPlaceDescription={v =>
                  this.handleItem('marketplaceDescription', v)
                }
              />
            </div>
          </Form>

          {!isMarketPlaceActive && (
            <>
              <div className="skill_journey__contents">
                <div className="tabs-content">
                  <Tabs onChange={this.onTabChange}>
                    <TabPane tab="Courses" key="1">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={
                              loadingCourses ||
                              (courses && courses.length === 0)
                            }
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingCourses && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {filteredCourses && filteredCourses.length > 0 ? (
                          filteredCourses.map(course => (
                            <div className="check-item" key={course.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={course.id}
                                onChange={e =>
                                  this.handleCheckItem(e.target.checked, course)
                                }
                              />
                              <label for={course.id}>{course.title}</label>
                            </div>
                          ))
                        ) : loadingCourses ? null : (
                          <Alert
                            message={t('v2:sorry')}
                            description={t('v2:no_courses_available')}
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>

                    <TabPane tab="Metamaze" key="2">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={mazes.length === 0}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingMazes && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {filteredMazes && filteredMazes.length > 0 ? (
                          filteredMazes.map(maze => (
                            <div className="check-item" key={maze.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={maze.id}
                                onChange={e =>
                                  this.handleCheckItem(e.target.checked, maze)
                                }
                              />
                              <label for={maze.id}>{maze.title}</label>
                            </div>
                          ))
                        ) : loadingMazes ? null : (
                          <Alert
                            message="Sorry !"
                            description="No mazes are available."
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>

                    <TabPane tab="Scorms" key="3">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={scorms.length === 0}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingScorms && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {!loadingScorms &&
                        filteredScorms &&
                        filteredScorms.length > 0 ? (
                          filteredScorms.map(scorm => (
                            <div className="check-item" key={scorm.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={scorm.id}
                                onChange={e =>
                                  this.handleCheckItem(e.target.checked, scorm)
                                }
                              />
                              <label for={scorm.id}>{scorm.title}</label>
                            </div>
                          ))
                        ) : loadingScorms ? null : (
                          <Alert
                            message={t('v2:sorry')}
                            description={t('v2:no_scorms_available')}
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>

                    <TabPane tab="Events" key="4">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={events.length === 0}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingEvents && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {!loadingEvents &&
                        filteredEvents &&
                        filteredEvents.length > 0 ? (
                          filteredEvents.map(event => (
                            <div className="check-item" key={event.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={event.id}
                                onChange={e =>
                                  this.handleCheckItem(e.target.checked, event)
                                }
                              />
                              <label for={event.id}>{event.title}</label>
                            </div>
                          ))
                        ) : loadingEvents ? null : (
                          <Alert
                            message={t('v2:sorry')}
                            description={t('v2:no_events_available')}
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>

                    <TabPane tab="Video" key="5">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={videos.length === 0}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingVideos && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {!loadingVideos &&
                        filteredVideos &&
                        filteredVideos.length > 0 ? (
                          filteredVideos.map(video => (
                            <div className="check-item" key={video.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={video.id}
                                onChange={e =>
                                  this.handleCheckItem(e.target.checked, video)
                                }
                              />
                              <label for={video.id}>{video.title}</label>
                            </div>
                          ))
                        ) : loadingVideos ? null : (
                          <Alert
                            message={t('v2:sorry')}
                            description={t('v3:no_video_available')}
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>
                    <TabPane tab="Podcast" key="6">
                      <Row type="flex" justify="space-between" align="bottom">
                        <Col span={12}>
                          <span className="choose-products">
                            {t('v2:choose_products')}
                          </span>{' '}
                        </Col>
                        <Col span={12}>
                          <Search
                            placeholder="Search"
                            onChange={value => this.onSearch(value)}
                            disabled={podcasts.length === 0}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <div className="items">
                        {loadingPodcasts && (
                          <Skeleton paragraph={{ rows: 3 }} active />
                        )}
                        {!loadingPodcasts &&
                        filteredPodcasts &&
                        filteredPodcasts.length > 0 ? (
                          filteredPodcasts.map(podcast => (
                            <div className="check-item" key={podcast.id}>
                              <input
                                type="checkbox"
                                className="skill-check"
                                id={podcast.id}
                                onChange={e =>
                                  this.handleCheckItem(
                                    e.target.checked,
                                    podcast
                                  )
                                }
                              />
                              <label for={podcast.id}>{podcast.title}</label>
                            </div>
                          ))
                        ) : loadingPodcasts ? null : (
                          <Alert
                            message={t('v2:sorry')}
                            description={t('v3:no_podcast_available')}
                            type="warning"
                            showIcon
                          />
                        )}
                      </div>
                    </TabPane>
                  </Tabs>
                  <hr />
                  <div className="button-basket">
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      onClick={this.addToBasket}
                    >
                      {t('v2:add_to_basket')}
                    </Button>
                  </div>
                </div>
                <div className="basket">
                  <div className="skill_journey__title">{t('v2:basket')}</div>
                  <div className="skill_journey__basket">
                    <div className="skill_journey__basket_items">
                      <div className="skill_journey__basket_title">
                        <ul className="name">
                          <li>{t('reports:score')}</li>
                        </ul>
                        <span>
                          {basketList.length} {t('v3:items')}
                        </span>
                      </div>
                      {basketList.length > 0 ? (
                        <Container groupName="1" onDrop={this.onDragDrop}>
                          {basketList.map((item, index) => (
                            <Draggable key={index} payload={item}>
                              <div
                                className="skill_journey__basket_list"
                                key={item.id}
                              >
                                <div className="score">
                                  <InputNumber
                                    defaultValue={item.pass_score || 0}
                                    disabled
                                  />
                                </div>
                                <div className="text">
                                  <span>
                                    {item.type && (
                                      <Tag
                                        color={this.getColor(item.type)}
                                        bordered
                                        style={{ borderRadius: 4 }}
                                      >
                                        {item.type.toLowerCase()}
                                      </Tag>
                                    )}
                                    &nbsp;{item.title}
                                  </span>
                                  <img
                                    alt="deleteicon"
                                    src={DeleteIcon}
                                    onClick={() =>
                                      this.removeFromBasket(
                                        item.id,
                                        item.type,
                                        true
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Draggable>
                          ))}
                        </Container>
                      ) : (
                        <Alert message={t('v3:no_item_baskets')} type="error" />
                      )}
                    </div>
                    <div className="skill_journey__footer">
                      <Row style={{ display: 'flex', marginTop: '26px' }}>
                        <span style={{ marginTop: '8px' }}>
                          {t('v4:pass_score').toUpperCase()}
                        </span>
                        <Form.Item
                          colon={false}
                          required={false}
                          // label={t('v4:pass_score')}
                        >
                          {getFieldDecorator('pass_score', {
                            rules: [
                              {
                                validator: (rule, value) =>
                                  value > 0 && value <= 100,
                                message: t('v4:pass_score_mark')
                              }
                            ]
                          })(
                            <InputNumber
                              value={score || 0}
                              min={0}
                              max={100}
                              // size="large"
                              // tighter="true"
                              // style={{ width: '100%' }}
                              onChange={value =>
                                this.handleItem('score', value)
                              }
                            />
                          )}
                        </Form.Item>
                      </Row>
                      <Button
                        className="item"
                        shape="round"
                        onClick={this.toggleCertificateModal}
                        loading={isCertificateLoading}
                        style={{
                          backgroundColor: certId ? 'green' : null,
                          color: certId ? 'white' : null
                          // pointerEvents: certId ? 'none' : 'all'
                        }}
                      >
                        {certId ? t('v4:certificate_added') : 'Certificate'}
                        {certId && <Icon type="check" />}
                      </Button>
                      <Button
                        className="item"
                        onClick={this.toggleBadgeModal}
                        shape="round"
                        loading={isBadgeLoading}
                        style={{
                          backgroundColor: badgeId ? 'green' : null,
                          color: badgeId ? 'white' : null
                          // pointerEvents: badgeId ? 'none' : 'all'
                        }}
                      >
                        {badgeId ? t('v4:badge_added') : 'Badge'}
                        {badgeId && <Icon type="check" />}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <PublishSkillJourney
                onPublish={this.handlePublish}
                skillId={this.state.skillId}
                setting={this.state.skillSetting}
                isSaving={this.state.isSaving}
                data={this.state}
                App={this}
              />
            </>
          )}
        </div>

        {openBadgeModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openBadgeModal}
            onCancel={this.toggleBadgeModal}
            footer={null}
            width="1229px"
            centered
          >
            <Badge
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Badge')
              }
              {...this.props}
            />
          </Modal>
        )}
        {openCertificateModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openCertificateModal}
            onCancel={this.toggleCertificateModal}
            footer={null}
            width="1229px"
            centered
          >
            <Certificate
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Certificate')
              }
              {...this.props}
            />
          </Modal>
        )}
      </Fragment>
    )
  }
}
export default Form.create()(withTranslation('coursecreate')(SkillJourney))
