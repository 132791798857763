import { api } from '../../services'
import {
  ORGANIZATIONS_SUCCESS,
  ORGANIZATIONS_FETCHING,
  ORGANIZATIONS_AUTOCOMPLETE_SUCCESS,
  ORGANIZATIONS_AUTOCOMPLETE_FETCHING,
  ORGANIZATIONS_SUCCESS_BY_ID,
  ORGANIZATIONS_FETCHING_BY_ID,
  USERS_FETCHING_BY_ORGANIZATION_ID,
  USERS_SUCCESS_BY_ORGANIZATION_ID,
  RESET_USERS_SUCCESS_BY_ORGANIZATION_ID,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_SUCCESS,
  CREATE_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_USER_SUCCESS,
  UPDATE_ORGANIZATION_USER_SUCCESS,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  UPDATE_GROUP_SUCCESS,
  GET_CATEGORY_SUCCESS,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  CATEGORY_SEARCH_ORGANIZATION,
  CATEGORY_SEARCH_ORGANIZATION_FAIL,
  CATEGORY_SEARCH__ORGANIZATION_SUCCESS,
  USERS_SEARCH_ORGANIZATION,
  USERS_SEARCH_ORGANIZATION_FAIL,
  USERS_SEARCH__ORGANIZATION_SUCCESS,
  ORGANIZATIONS_FAILURE
} from '../types'

const get = params => dispatch => {
  dispatch({
    type: ORGANIZATIONS_FETCHING,
    payload: true
  })

  return api.organizations.get(params).then(response => {
    dispatch({
      type: ORGANIZATIONS_SUCCESS,
      payload: response.data
    })
    dispatch({
      type: ORGANIZATIONS_FETCHING,
      payload: false
    })
    return response.data
  }).catch(err => {
    dispatch({
      type: ORGANIZATIONS_FAILURE,
      payload: err
    })
  })
}

const searchOrg = value => dispatch => {
  dispatch({
    type: ORGANIZATIONS_FETCHING,
    payload: true
  })

  return api.organizations.searchMyOrganizations(value).then(response => {
    dispatch({
      type: ORGANIZATIONS_SUCCESS,
      payload: response.data
    })
    dispatch({
      type: ORGANIZATIONS_FETCHING,
      payload: false
    })
    return response.data
  })
}

const getAutoComplete = params => dispatch => {
  dispatch({
    type: ORGANIZATIONS_AUTOCOMPLETE_FETCHING,
    payload: true
  })

  return api.organizations.get(params).then(response => {
    dispatch({
      type: ORGANIZATIONS_AUTOCOMPLETE_SUCCESS,
      payload: response.data
    })
    dispatch({
      type: ORGANIZATIONS_AUTOCOMPLETE_FETCHING,
      payload: false
    })
    return response.data
  })
}

const getOrganizationById = id => dispatch => {
  dispatch({
    type: ORGANIZATIONS_FETCHING_BY_ID,
    payload: true
  })

  return api.organizations.getById(id).then(response => {
    dispatch({
      type: ORGANIZATIONS_SUCCESS_BY_ID,
      payload: response.data
    })
    dispatch({
      type: ORGANIZATIONS_FETCHING_BY_ID,
      payload: false
    })
    return response
  })
}

const getUsersByOrganizationId = id => dispatch => {
  dispatch({
    type: USERS_FETCHING_BY_ORGANIZATION_ID,
    payload: true
  })

  return api.organizations.getUsersByOrganizationId(id).then(response => {
    dispatch({
      type: USERS_SUCCESS_BY_ORGANIZATION_ID,
      payload: response.data
    })
    dispatch({
      type: USERS_FETCHING_BY_ORGANIZATION_ID,
      payload: false
    })
    return response
  })
}
const resetUsersByOrganizationId = () => dispatch => {
  dispatch({
    type: RESET_USERS_SUCCESS_BY_ORGANIZATION_ID,
    payload: null
  })
}

const createOrganization = data => dispatch => {
  return api.organizations.create(data).then(response => {
    dispatch({
      type: CREATE_ORGANIZATION_SUCCESS,
      payload: response.data
    })
    return response.data
  })
}

const deleteOrganizationById = id => dispatch => {
  return api.organizations.deleteById(id).then(() => {
    dispatch({
      type: DELETE_ORGANIZATION_SUCCESS,
      payload: id
    })
    return Promise.resolve()
  })
}

const createOrganizationUser = (id, data) => dispatch => {
  return api.organizations
    .createOrganizationUser(id, data)
    .then(response => {
      dispatch({
        type: CREATE_ORGANIZATION_USER_SUCCESS,
        payload: response.data
      })
      return response.data
    })
    .catch(err => {
      throw new Error(err.message)
    })
}

const updateOrganizationUser = (orgId, userId, data) => dispatch => {
  return api.organizations
    .updateOrganizationUser(orgId, userId, data)
    .then(response => {
      dispatch({
        type: UPDATE_ORGANIZATION_USER_SUCCESS,
        payload: response.data
      })
      return response.data
    })
}

const deleteOrganizationUser = (orgId, id) => dispatch => {
  return api.organizations.deleteOrganizationUser(orgId, id).then(() => {
    dispatch({
      type: DELETE_ORGANIZATION_USER_SUCCESS,
      payload: id
    })
    return Promise.resolve()
  })
}

const updateOrganization = (id, data) => dispatch => {
  return api.organizations.update(id, data).then(response => {
    dispatch({
      type: UPDATE_ORGANIZATION_SUCCESS,
      payload: response.data
    })
    return response.data
  })
}

const deleteGroup = (orgId, groupId) => dispatch => {
  return api.organizations.deleteGroup(orgId, groupId).then(() => {
    dispatch({
      type: DELETE_GROUP_SUCCESS,
      payload: {
        orgId,
        groupId
      }
    })
    return Promise.resolve()
  })
}

const createGroup = (orgId, data) => dispatch => {
  return api.organizations.createGroup(orgId, data).then(response => {
    dispatch({
      type: CREATE_GROUP_SUCCESS,
      payload: {
        orgId,
        data: response.data
      }
    })
    return response.data
  })
}

const updateGroup = (id, orgId, data) => dispatch => {
  return api.organizations.updateGroup(id, orgId, data).then(response => {
    dispatch({
      type: UPDATE_GROUP_SUCCESS,
      payload: {
        orgId,
        id,
        data: response.data
      }
    })
    return response.data
  })
}
const deleteCategory = (id) => dispatch => {
  return api.organizations.deleteCategory(id).then(() => {
    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: {
        id
      }
    })
    return Promise.resolve()
  })
}
// const deleteCategory = (id) => {

//   return api.organizations
//     .deleteCategory(id)
//     .then(response => {
//       return response
//     })
//     .catch(error => {
//       console.error('Error fetching question detail:', error)
//     })
// }
const getCategories = () => dispatch => {
  return api.organizations.getCategories().then((response) => {
    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: {
        data: response.data
      }
    })
    return response.data
  })
}

const createCategory = (data) => dispatch => {
  return api.organizations.createCategory(data).then(response => {
    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: {
        data: response.data
      }
    })
    return response.data
  })
}

const updateCategory = (id, data) => dispatch => {
  return api.organizations.updateCategory(id, data).then(response => {
    dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      payload: {
        id,
        data: response.data
      }
    })
    return response.data
  })
}

const searchCategory = (id, searchType, searchValue) => dispatch => {
  dispatch({
    type: CATEGORY_SEARCH_ORGANIZATION
  })

  try {
    return api.organizations
      .searchCategory(id, searchType, searchValue)
      .then(response => {
        dispatch({
          type: CATEGORY_SEARCH__ORGANIZATION_SUCCESS,
          payload: response.data
        })
        return response
      })
  } catch (error) {
    dispatch({
      type: CATEGORY_SEARCH_ORGANIZATION_FAIL
    })
  }
}
const searchUsers = (id, searchType, searchValue) => dispatch => {
  dispatch({
    type: USERS_SEARCH_ORGANIZATION
  })

  try {
    return api.organizations
      .searchUsersInOrganization(id, searchType, searchValue)
      .then(response => {
        dispatch({
          type: USERS_SEARCH__ORGANIZATION_SUCCESS,
          payload: response.data
        })
        return response
      })
  } catch (error) {
    dispatch({
      type: USERS_SEARCH_ORGANIZATION_FAIL
    })
  }
}

export {
  get,
  getAutoComplete,
  getOrganizationById,
  getUsersByOrganizationId,
  createOrganization,
  updateOrganization,
  deleteOrganizationById,
  createOrganizationUser,
  deleteOrganizationUser,
  updateOrganizationUser,
  deleteGroup,
  createGroup,
  updateGroup,
  getCategories,
  deleteCategory,
  createCategory,
  updateCategory,
  searchCategory,
  resetUsersByOrganizationId,
  searchUsers,
  searchOrg
}
