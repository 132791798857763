import React from 'react'
import { HelpItem } from './styled'
import ReactSVG from 'react-svg'

export default (props) => {
  const { url, exact, icon, heading } = props
  const to = url || '/'

  return (
    <HelpItem>
      <HelpItem.Link to={to} exact={exact}>
        <HelpItem.Inner>
          <HelpItem.Icon>
            <ReactSVG src={icon} />
          </HelpItem.Icon>
          <HelpItem.Heading>{heading}</HelpItem.Heading>
        </HelpItem.Inner>
      </HelpItem.Link>
    </HelpItem>
  )
}
