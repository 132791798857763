import { connect } from 'react-redux'
import UpdateStatusModal from './UpdateStatusModal'

const mapStateToProps = state => ({
  library: state.library
})

export default connect(
  mapStateToProps,
  null
)(UpdateStatusModal)
