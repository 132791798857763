import React from 'react'
import { Content } from '../../styled'
import Privacy from '../../../Pages/internal/Privacy'
import { withTranslation } from 'react-i18next'

const PrivacyPolicy = (props) => {
  const {
    t
  } = props
  return (
    <Content>
      <Content.Heading>{t('menu.privacy_policy')}</Content.Heading>
      <Content.Description>
        <Privacy />
      </Content.Description>
    </Content>
  )
}

export default withTranslation('help_center')(PrivacyPolicy)
