import React, { Fragment, useEffect, useState } from 'react'
import {
  Form,
  Button,
  Input,
  Spin,
  Row,
  Col,
  Select,
  Card,
  Tabs,
  Modal
} from 'antd'
import { withTranslation } from 'react-i18next'
import { QuillStyled } from '../Builder/Elements/Text/styled'
import { toolbar } from '../Builder/Elements/Text/Text.ctrl'
import _ from 'lodash'
import './QuestionAnswerAI.scss'
import Slider from 'react-slick'

const { Option } = Select
const { TabPane } = Tabs
const { TextArea } = Input
const langOptions = [
  { name: 'English (United States)', code: 'en-US' },
  { name: 'German (Germany)', code: 'de-DE' },
  { name: 'French (France)', code: 'fr-FR' },
  { name: 'Russian (Russia)', code: 'ru' },
  { name: 'Spanish (Spain)', code: 'es' },
  { name: 'Thai (Thailand)', code: 'th' },
  { name: 'Korean (Korea)', code: 'ko' },
  { name: 'Hebrew', code: 'h3-He' }
]

const QuestionAnswerAI = props => {
  const [showImageModal, setShowImageModal] = useState(false)
  const [imageToPreview, setImageToPreview] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null)

  const previewImage = index => {
    setImageToPreview(index)
    setShowImageModal(true)
  }

  const destroyImageModal = () => {
    setShowImageModal(false)
    setImageToPreview(null)
  }

  const selectImage = image => setSelectedImage(image)

  useEffect(() => {
    if (selectedImage) {
      props.onSelectImage(selectedImage)
    }
  }, [selectedImage])

  const {
    isWideView,
    handleSubmit,
    getFieldDecorator,
    handleAiLanguage,
    hasInitialize,
    hasInitializeGerman,
    isLoading,
    showSearchResult,
    resultData,
    summariseResult,
    summariseResultOptionOne,
    onChangeTab,
    tabIndex,
    summarySearch,
    loadSummarize,
    errorMsg,
    titleSearch,
    audio,
    handleInput,
    content,
    isProcessing,
    selectedText,
    onChangeText,
    t
  } = props

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <h1>Next</h1>,
    prevArrow: <h1>Previous</h1>
  }

  const searchResult =
    showSearchResult &&
    resultData &&
    resultData.map((data, idx) => {
      const showSummary = summariseResult.filter(s => s.id === idx)
      const showSummary1 = summariseResultOptionOne.filter(s => s.id === idx)
      const images = data && data.image_list

      console.log('imagers', images)
      return (
        <Fragment>
          <Card
            size="small"
            title={titleSearch}
            style={{ width: '100%' }}
            key={idx}
          >
            <Tabs
              defaultActiveKey="1"
              onChange={e => onChangeTab(e, idx)}
              activeKey={tabIndex[idx] || '1'}
              onTabClick={e => console.log('>>>>>>', e)}
            >
              <TabPane tab="Answer" key="1">
                <p>{data.answer}</p>
              </TabPane>
              <TabPane tab="Context" key="2">
                <p>{data.context}</p>
              </TabPane>
              <TabPane
                tab={summarySearch(data.answer, data.context, idx)}
                key="3"
                onClick={() => console.log('>>>>>>')}
              >
                {loadSummarize && 'Processing...'}
                {_.isEmpty(errorMsg)
                  ? showSummary.length > 0 && (
                      <div className="sum__ans">
                        <div className="sum__ans_item">
                          <p>{t('v3:option_one')}</p>
                          {showSummary1[0] && showSummary1[0].data ? (
                            showSummary1[0].data
                          ) : (
                            <Spin tip="Loading..." size="small" />
                          )}
                        </div>
                        <div className="sum__ans_item">
                          <p>{t('v3:option_two')}</p>
                          {showSummary[0].data}
                        </div>
                      </div>
                    )
                  : errorMsg}
              </TabPane>
              {!audio && (
                <TabPane tab="Image" key="4">
                  <div className="ai-image-slider">
                    <Slider {...settings}>
                      {images &&
                        images.map((image, index) => (
                          <div className="ai_image_container">
                            <img
                              alt=""
                              onClick={() => selectImage(image)}
                              onDoubleClick={() => previewImage(index)}
                              src={image}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>

                  {showImageModal && (
                    <Modal
                      footer={false}
                      centered={true}
                      closable={false}
                      visible={showImageModal}
                      className="ai-preview-modal"
                      onCancel={() => destroyImageModal()}
                    >
                      <Slider
                        {...{ ...settings, initialSlide: imageToPreview }}
                      >
                        {images &&
                          images.map((image, index) => (
                            <div className="slide_image_container">
                              <img
                                alt=""
                                src={image}
                                onClick={() => {
                                  destroyImageModal()
                                  selectImage(image)
                                }}
                              />
                            </div>
                          ))}
                      </Slider>
                    </Modal>
                  )}
                </TabPane>
              )}
            </Tabs>
          </Card>
          &nbsp;
        </Fragment>
      )
    })

  return (
    <>
      {isWideView && (
        <Row>
          <Col span={12}>
            &nbsp; &nbsp;
            <Form onSubmit={handleSubmit}>
              <Form.Item
                label={t('v2:enter_question')}
                style={{ marginBottom: '7px' }}
              >
                {getFieldDecorator('question', {
                  rules: [
                    {
                      required: true,
                      message: t('v2:enter_question')
                    }
                  ]
                })(
                  <Input
                    placeholder={`${t('v2:eg')}: ${t(
                      'v2:what_make_sales_manager'
                    )}`}
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('language', {
                  initialValue: 'en-US',
                  rules: [
                    {
                      required: true,
                      message: t('v2:please_select_lang')
                    }
                  ]
                })(
                  <Select
                    showSearch
                    style={{ width: 185 }}
                    size="small"
                    optionFilterProp="children"
                    onChange={handleAiLanguage}
                  >
                    {langOptions.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {hasInitialize && hasInitializeGerman && (
                <Button
                  type="primary"
                  icon="search"
                  shape="round"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t('v2:Search')}
                </Button>
              )}
              {(!hasInitialize || !hasInitializeGerman) && (
                <Spin tip="Wait a few seconds..." />
              )}
            </Form>
            &nbsp; &nbsp;
            {/* {isLoading && <h2>{t('v2:please_wait_loading_results')}</h2>} */}
            {showSearchResult && !isLoading && (
              <Fragment>
                &nbsp; &nbsp;
                {resultData.length > 0 ? (
                  <h2>
                    {t('v2:Results')}({resultData.length})
                  </h2>
                ) : (
                  <h2>{t('v2:no_result_found_try_asking_questions')}</h2>
                )}
                <div className="result-relevant-answers">{searchResult}</div>
              </Fragment>
            )}
          </Col>
          <Col span={11} offset={1}>
            {!audio ? (
              <>
                &nbsp; &nbsp;
                <div className="el-editor">
                  <div className="el-editor__component">
                    <QuillStyled
                      preserveWhitespace
                      value={content || ''}
                      onChange={handleInput}
                      modules={{
                        toolbar
                      }}
                      style={{}}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="textarea-warp">
                <TextArea
                  disabled={!!isProcessing}
                  value={selectedText}
                  onChange={onChangeText}
                  placeholder={t('audio:type_something')}
                  autoSize={{ minRows: 25, maxRows: 25 }}
                />
              </div>
            )}

            {selectedImage && (
              <div className="ai-image-preview">
                <h2>{t('v3:selected_image')} </h2>
                <img alt="" src={selectedImage} />
              </div>
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

export default withTranslation()(QuestionAnswerAI)
