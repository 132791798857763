import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import { styleguide } from '../../constants'

const { colors } = styleguide

export const DropdownToggle = styled.a`
  height: 100%;
  line-height: 1;
  position: relative;
  padding-right: 2.5rem;
  text-decoration: none;

  ${props =>
    props.active &&
    css`
      ${IconArrow} {
        transform: translateY(-50%) rotate(0);
      }
    `}
`

export const IconArrow = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 1rem
  transform: translateY(-50%) rotate(180deg);
  transition: all 0.3s;

  svg {
    width: 1.2rem;
    height: 1.2rem;

    path {
      fill: ${colors.blakGray};
      stroke: ${colors.blakGray};
    }
  }
`
