import { Integration } from "./Integration.js"

export class MenuItem {
	parentMenuItem
	activity
	activityIdentifier
	document
	level
	enabled
	el
	controller
	children
	menuElementId

	constructor(controller, parentMenuItem, activity, document) {
		this.parentMenuItem = parentMenuItem
		this.activity = activity
		this.activityIdentifier = activity.getIdentifier()
		this.menuElementId = "MenuItem" + this.activityIdentifier
		this.document = document
		this.controller = controller

		if (!this.parentMenuItem) {
			this.level = 0
		} else {
			this.level = this.parentMenuItem.level + 1
		}

		this.enabled = true
		let node = this.document.createElement("li")
		node.id = this.menuElementId;
		this.el = node

		this.children = []
		Integration.PopulateMenuItemElement(node, document, activity, this.level, this)

	}


	enable(activityIdentifier) {
		if (this.enabled === false) {
			this.el.tabIndex = 0;
			if (this.activity && this.activity.getScormType()) {
				this.el.onclick = () => {
					this.controller.choiceRequest(activityIdentifier);
					return true;
				};
			}

		}
		this.enabled = true;
	}


	render(activity) {
		let nodeToInsertAfter,
			i,
			prevMenuItem,
			lastChildOfPrevMenuItem;

		if (!this.parentMenuItem) {
			const parentNode = Integration.GetHtmlElementToInsertMenuWithin();
			this.menuElementId = "MenuItem#root"
			this.el.id = this.menuElementId

			parentNode.insertBefore(this.el, null);
			return;
		}

		for (i = 0; i < this.parentMenuItem.children.length; i += 1) {
			if (this.parentMenuItem.children[i].activityIdentifier === this.activityIdentifier) {
				break;
			}
		}

		// if this is the first activity in the parent, insert right after the parent
		if (i === 0) {
			nodeToInsertAfter = this.document.getElementById(this.parentMenuItem.menuElementId);
		} else {
			// otherwise, find the immediately previous sibling's last (grand)child
			prevMenuItem = this.parentMenuItem.children[i - 1];
			lastChildOfPrevMenuItem = this.getLastSubmenuItem(prevMenuItem);
			nodeToInsertAfter = this.document.getElementById(lastChildOfPrevMenuItem.menuElementId);
		}

		// there is no "insertAfter" method that is cross browser compatible, so we want to insert
		// between the "after" node and whatever comes next (its nextSibling)
		nodeToInsertAfter.parentNode.insertBefore(this.el, nodeToInsertAfter.nextSibling);

	}

	getLastSubmenuItem(menuItem) {
		// if there are no children, then this is the last item so return it
		if (menuItem.children.length === 0) {
			return menuItem;
		}

		// otherwise, find the last (grand)child of the last child in this menu item
		return this.getLastSubmenuItem(menuItem.children[menuItem.children.length - 1]);
	}

}