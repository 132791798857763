import { Button, Form, Icon, Input, Modal, Tooltip, message } from 'antd'
import React, { useState } from 'react'

import './styles.scss'
import { CreatableSelect } from '../../../../../../../../../components'

import scenarioFormOptions from './scenarioFormOptions'
import { formatLabel } from '../../utils'
import { api } from '../../../../../../../../../services'
import { useTranslation } from 'react-i18next'

function ScenarioForm({ onCancel, form, onSubmit, previewData }) {
  const { getFieldDecorator, validateFields } = form

  const [isGeneratingScenario, setIsGeneratingScenario] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((err, values) => {
      if (!err) {
        setIsGeneratingScenario(true)
        console.log('Received values of form: ', values)
        const pageContent = extractSelectedPageText()
        const scenarioElements = JSON.stringify(values)

        generateScenario(pageContent, scenarioElements)
      }
    })
  }

  function generateScenario(pageContent, scenarioElements) {
    const data = {
      params: {
        course_content: pageContent,
        scenario_elements: scenarioElements
      }
    }

    api.courses
      .generateScenario(data)
      .then(res => {
        const resData = res.data.body && res.data.body[0]
        onSubmit(resData)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsGeneratingScenario(false)
      })
  }

  function extractSelectedPageText() {
    const selectedPages = previewData.filter(page => page.pageSelected)

    let concatenatedText = ''

    selectedPages.forEach(page => {
      page.data.forEach(element => {
        if (element.type === 'TEXT' && element.display === 'CONTENT') {
          concatenatedText += element.params.content + ' '
        }
      })
    })

    return concatenatedText
  }

  return (
    <Modal
      title="Scenario Generation Form"
      visible
      footer={null}
      centered
      closable
      onCancel={onCancel}
      className="scenario-generation"
    >
      <Form className="scenario-form" onSubmit={handleSubmit}>
        {Object.entries(scenarioFormOptions).map(([key, values]) => (
          <Form.Item
            labelAlign="top"
            label={
              <span className="scenario-form-label">
                <span>{formatLabel(key)} </span>
                <Tooltip title={values.description}>
                  <Icon type="info-circle" />
                </Tooltip>
              </span>
            }
            key={key}
          >
            {getFieldDecorator(key, {
              initialValue: Array.isArray(values.value) ? [] : values.value,
              rules: [
                {
                  required: true,
                  message: `Please input your ${formatLabel(key)}!`
                }
              ]
            })(
              values.value &&
                Array.isArray(values.value) &&
                values.value.length > 0 ? (
                <CreatableSelect
                  fieldKey={key}
                  updateOptionValue={(value, field) =>
                    form.setFieldsValue({ [field]: value })
                  }
                  options={values.value}
                  placeholder={`Select or type your ${formatLabel(key)}`}
                />
              ) : (
                <Input.TextArea
                  placeholder={`Enter your ${formatLabel(key)}`}
                />
              )
            )}
          </Form.Item>
        ))}
        <div className="form-button-center">
          <Form.Item>
            <Button
              className="shl-primary-btn"
              htmlType="submit"
              shape="round"
              size="large"
              loading={isGeneratingScenario}
            >
              {t('v3:generate_scenario')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  )
}

export default Form.create()(ScenarioForm)
