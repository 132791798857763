import React from 'react'

import './WelcomeForm.scss'

import BrainIcon from '../../../../assets/brain.svg'
import HatIcon from '../../../../assets/graduation-hat.svg'
import history from '../../../../history'
import { Icon, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

function WelcomeForm({ onChangeRoute }) {
  const { t } = useTranslation()
  return (
    <div className="welcome-form-container">
      <div className="welcome-form-title">
        <h1>{t('v3:welcome_to_sharelook')}</h1>
        <p>{t('v3:sharelook_you_want_go')}</p>
      </div>

      <div className="welcome-form-buttons">
        <WelcomeFormButton
          icon={HatIcon}
          text={t('v3:learning_center')}
          onClick={() => {
            onChangeRoute()
            history.push('/training')
          }}
          infoText={t('v3:shareLook_learning_management_system')}
        />
        <WelcomeFormButton
          icon={BrainIcon}
          text={t('v3:knowledge_center')}
          subText="(ShareBot)"
          onClick={() => {
            onChangeRoute()
            history.push('/knowledge-center')
          }}
          infoText={t('v3:shareLook_powerful_ai_system')}
        />
      </div>
    </div>
  )
}

const WelcomeFormButton = ({ icon, text, onClick, subText, infoText }) => {
  return (
    <button className="welcome-form-button" onClick={onClick}>
      <div className="welcome-form-button-icon">
        <img alt="" src={icon} />
      </div>
      <div className="welcome-form-button-text">
        <span>
          {text}
          <Tooltip className="welcome-form-button-tooltip" title={infoText}>
            <Icon type="info-circle" />
          </Tooltip>
        </span>
        {subText && <span>{subText}</span>}
      </div>
    </button>
  )
}

export default WelcomeForm
