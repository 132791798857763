import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TopPageLink } from '../../components'
import Routes from './routes'
import { Page } from './styled'
import actions from '../../store/actions'

class Management extends Component {
  componentDidMount() {
    const {
      getOrganizations,
      getOrganizationsAuto,
      user: { isAdmin, isSuperAdmin },
      history
    } = this.props
    const params = {
      offset: 0,
      limit: 20
    }
    if (isSuperAdmin || isAdmin) {
      getOrganizations()
      getOrganizationsAuto(params)
    } else {
      history.push('/')
    }
  }

  render() {
    return (
      <Page>
        <TopPageLink page="Training" path="/" />
        <Routes />
      </Page>
    )
  }
}

export default connect(
  state => ({
    user: state.user.info
  }),
  dispatch => ({
    getOrganizations: () => dispatch(actions.organizations.get()),
    getOrganizationsAuto: () =>
      dispatch(actions.organizations.getAutoComplete())
  })
)(Management)
