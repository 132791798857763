/*
 * @Author: your name
 * @Date: 2019-09-05 15:53:46
 * @LastEditTime: 2019-11-07 11:30:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Assessments/Edit/styled.js
 */
import styled, { css } from 'styled-components'
import { Form } from 'antd'

import { styleguide } from '../../../constants'

const { base, colors } = styleguide

const Page = styled.div`
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
`

Page.Inner = styled.div`
  padding: 3.5rem;
`

Page.Rows = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

Page.Wrapper = styled.div`
  width: calc(50% - 1.8rem);
`

Page.Form = styled(Form)`
  && {
    border-top: 0.1rem solid #ddd;
    padding: 2.8rem 0;
  }
`

Page.Label = styled.div`
  font-size: 1.1rem;
  font-weight: 500;
  text-transform: uppercase;
`

const TabsWrapper = styled.div`
  && {
    width: 100%;
    box-sizing: border-box;
    background-color: #f7f7f7;

    .ant-tabs-bar {
      margin-bottom: 0;
      border-bottom-color: transparent;
    }

    .ant-tabs-nav {
      width: 100%;

      > div:first-child {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        background-color: #f7f7f7;
      }
    }

    .ant-tabs-ink-bar {
      bottom: 0.1rem;
      height: 0.3rem;
    }

    .ant-tabs-tab {
      text-align: center;
      padding: 0.8rem 1.6rem 1.2rem;
      font-family: ${base.fontFamily};
      color: ${colors.darkGreyTwo};
      font-size: ${base.fontSize};
      font-weight: bold;

      &:hover {
        color: ${colors.darkGreyTwo};
      }

      &.ant-tabs-tab-active {
        color: ${colors.darkGreyOne};
      }
    }
  }
`

Page.Dropzone = styled.div`
  height: 25.5rem;
  border: 1px dashed ${colors.darkGreyTwo};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color .2s;

  &:hover {
    border-color: ${colors.blue};
  }

  ${props =>
  props.error &&
  css`
      border-color: ${colors.red};
    `}

  ${props =>
  props.active &&
  css`
      background: rgba(0, 104, 255, 0.1);
      border-color: ${colors.blue};
    `}

  ${props =>
  props.background &&
  css`
      background: url(${props.background}) no-repeat center;
      background-size: cover;
    `}
`

Page.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`

Page.Dropzone.Button = styled.div`
  border-radius: 5rem;
  border: 1px solid ${colors.darkGreyThree};
  display: flex;
  font-size: 1.1rem;
  font-weight: 600;
  align-items: center;
  padding: 1rem 2.4rem;
  text-transform: uppercase;
  transition: border-color 0.2s, color 0.2s, opacity 0.2s;

  i {
    margin-right: 0.8rem;
  }

  ${Page.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props =>
  props.hide &&
  css`
        background: rgba(255, 255, 255, 0.3);
        border-color: ${colors.white};
        color: ${colors.white};
        opacity: 1;
      `}
  }

  ${props =>
  props.hide &&
  css`
      opacity: 0;
      border-color: ${colors.white};
      color: ${colors.white};
    `}
`
const modal_style = {
  overflow: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}

export { Page, TabsWrapper, modal_style }
