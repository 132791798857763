import React, { useRef, useEffect, useState } from 'react';
import _ from 'lodash'
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import './Map.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA';

const Map = (props) => {
  const mapContainerRef = useRef(null);
  let logitude;
  let latitude;
  let scale;

  if (!_.isEmpty(props.mediaDat.custom_data) && !_.isEmpty(props.mediaDat.custom_data.geoloc)) {
    logitude = props.mediaDat.custom_data.geoloc.log
    latitude = props.mediaDat.custom_data.geoloc.lat
    scale = props.mediaDat.custom_data.geoloc.scale
  } else {
    logitude = 0
    latitude = 0
    scale = 3
  }

  const [lng, setLng] = useState(logitude);
  const [lat, setLat] = useState(latitude);
  const [zoom, setZoom] = useState(scale);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });

    const marker = new mapboxgl.Marker({
      draggable: true
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    });

    map.addControl(
      geocoder
    );

    geocoder.on('results', (results) => {
      // setTimeout(() => {
      //   console.log("Got results", results)
      //   const lngLat = marker.getLngLat();
      //   const {lng, lat} = map.getCenter();        
      //   setLng(lng);
      //   setLat(lat);
      //   setZoom(map.getZoom().toFixed(4));
      //   marker.setLngLat([lng, lat]).addTo(map)
      // }, 15000)
    })

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.addControl(new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    }));

    // map.on('click', () => {
    //   console.log('on move');
    //   setLng(map.getCenter().lng.toFixed(8));
    //   setLat(map.getCenter().lat.toFixed(8));
    //   setZoom(map.getZoom().toFixed(4));
    // });

    const onDragEnd = () => {
      const { onGeoLocation } = props
      const lngLat = marker.getLngLat();
      setLng(lngLat.lng.toFixed(8));
      setLat(lngLat.lat.toFixed(8));
      setZoom(map.getZoom().toFixed(4));
      let geoloc = {
        log: lngLat.lng.toFixed(8),
        lat: lngLat.lat.toFixed(8),
        scale: map.getZoom().toFixed(4)
      }
      onGeoLocation(geoloc)
    }

    map.on('moveend', () => {
      console.log('A moveend event occurred.');
      const { lng, lat } = map.getCenter();
      setLng(lng);
      setLat(lat);
      setZoom(map.getZoom().toFixed(4));
      marker.setLngLat([lng, lat]).addTo(map)
    });

    map.on('zoomstart', () => {
      console.log('A zoomstart event occurred.');
      setZoom(map.getZoom().toFixed(4));
    });

    marker.on('drag', onDragEnd);
    // Clean up on unmount
    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={!props.editAssets && !props.enableSave ? { border: '1.5px solid red' } : { border: '0px' }}>
      {/* <div className='sidebarStyle'>
        <div>
          Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
        </div>
      </div> */}
      <div className='map-container' ref={mapContainerRef} />
    </div>
  );
};

export default Map;
