import React, { Component, Fragment } from 'react'
import {
  Button,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Switch,
  Tabs,
  Alert
} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Col, Row } from '../../../styles'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import ImageCropContainer from '../../../components/ImageCrop'
import Attachment from './Attachment'
import className from 'classnames'
import history from '../../../history'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { api } from '../../../services'
import Poll from './Poll'
import { broadcastOptions, general, regions, config } from '../../../constants'
import { PopupMedia } from '../../../views/Library/internal'
import Preview from '../../../views/Library/components/Preview'
import { DropzoneLoading, modal_style } from './styled'
import { bytesToSize } from '../../../utils'
import CreatableSelect from 'react-select/creatable'
import './styles.scss'
import debounce from 'lodash/debounce'
import Header from './Header'
import { TopSection, BottomSection } from './Marketplace'
import TrainerSection from '../../../components/TrainerSection'
import ShowContactsModal from './ShowContactsModal'
const { Option } = Select

class Details extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.lastFetchId = 0
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
    this.state = {
      isFetching: true,
      isSaving: false,
      coverId: null,
      coverLink: null,
      validate: false,
      isLoading: false,
      no_of_people_conf: 'uptoTen',
      current: {
        id: null,
        title: '',
        email: '',
        description: '',
        broadcast_location: '',
        topics: [],
        topic_list: [],
        is_chat: true,
        is_poll: false,
        is_recording: false,
        no_of_people_conf: null,
        poll: [
          {
            question: '',
            answers: ['']
          }
        ],
        marketplace: {
          category_id: null,
          video_link: '',
          price: null
        },
        encoder: '',
        host_emails: [],
        host_ids: [],
        attachments: []
      },
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      image: null, // your-image-url or as base64
      file: null,
      isTopicsMax: false,
      isImageLoading: false,
      isBroadcast: false,
      isConference: false,
      emails: [],
      isHostsLenExceed: false,
      isEdit: false,
      emailList: [],
      fetching: false,
      timezone: '',
      is_free: null,
      pdfFiles: [],
      filenames: [],
      attachmentIds: [],
      toSelectFile: false,
      toTranscribe: false,
      transcribeLocale: '',
      showTranscribe: false,
      isEventMarketPlace: false,
      publish: false,
      showContactModal: false,
      hasError: false
    }
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  componentDidMount() {
    const {
      clearUploadList,
      match: { params }
    } = this.props
    const { id } = params
    clearUploadList()
    if (id) {
      this.getEventsData()
    } else {
      this.setState({
        isFetching: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { emails } = this.state
    if (emails !== prevState.emails && emails) {
      const no_of_people_conf =
        emails && emails.length > 0 && emails.length === 10
          ? 'uptoTen'
          : emails.length > 10 || emails.length === 20
          ? 'uptoTwenty'
          : emails.length > 20 || emails.length === 25
          ? 'uptoTwentyFive'
          : 'uptoTen'
      this.setState({ no_of_people_conf })
      this.props.form.setFieldsValue({ no_of_people_conf })
    }
  }

  getEventsData = () => {
    this._isMounted = true
    const id = this.props.match.params.id || this.state.current.id || null

    api.events
      .getById(id)
      .then(response => {
        if (this._isMounted) {
          const cover = response.data.cover ? response.data.cover.link : ''
          this.setCover(response.data.cover_id, cover)
          const {
            data: { topics, attachments }
          } = response
          const isBroadcast =
            response.data.encoder === 'wowza_gocoder' ||
            response.data.encoder === 'other_webrtc' ||
            response.data.encoder === 'stream_from_phone'
          const isConference =
            response.data.encoder === 'conference' ||
            response.data.encoder === 'chime_conference'
          const emails = response.data.host_emails
            ? response.data.host_emails
            : []
          const no_of_people_conf = emails.length
            ? emails.length > 0 && emails.length === 10
              ? 'uptoTen'
              : emails.length > 10 || emails.length === 20
              ? 'uptoTwenty'
              : emails.length > 20 || emails.length === 25
              ? 'uptoTwentyFive'
              : 'uptoTen'
            : 'uptoTen'
          // : emails.length > 30 && emails.length === 40 ? 'uptoForty' : 'uptoTen' : 'uptoTen'
          // const encoder = response.data.encoder === 'conference' || response.data.encoder === 'chime_conference' ? 'conference' : response.data.encoder
          // const toTranscribe = response.data.is_transcribed
          // const transcribeLocale = response.data.transcribe_locale ? response.data.transcribe_locale : ''
          // const showTranscribe = response.data.encoder === "wowza_gocoder" || response.data.encoder === "other_webrtc" ? true : false
          this.setState(
            {
              current: {
                ...this.state.current,
                ...response.data
              },
              isFetching: false,
              isBroadcast,
              isConference,
              // toTranscribe,
              // transcribeLocale,
              // showTranscribe,
              emails,
              no_of_people_conf,
              isEdit: true,
              hasError: false
            },
            () => {
              this.getOldTopics(topics)
              this.getAttachmentDetail(attachments)
              this.showErrorMessageForMarketPlace()
            }
          )
        }
      })
      .catch(err => {
        console.log('error event by id', err)
        this.setState({ hasError: true, isEdit: true, isFetching: false })
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  showErrorMessageForMarketPlace = () => {
    const { t, form } = this.props
    const { isEventMarketPlace, current } = this.state
    const eventId = this.props.match.params.id || current.id || null

    if (!eventId) return
    if (isEventMarketPlace) return

    form.validateFields((err, values) => {
      console.log('values', values)

      const {
        marketplace: { category_id, video_link, is_free, price }
      } = current

      let isFree =
        typeof this.state.is_free === 'boolean'
          ? this.state.is_free
          : current.marketplace
          ? is_free
          : false

      const data = {
        title: values.title,
        category_id: isEventMarketPlace ? values.category : category_id,
        video_link: isEventMarketPlace ? values.video_link : video_link,
        is_free: isFree,
        price: isEventMarketPlace ? Number(values.price) : price,
        encoder_type: values.encoder_type,
        location: current.broadcast_location
      }

      let errorData = Object.keys(data).filter(k => {
        if (data[k] === '' || data[k] === undefined || data[k] === null) {
          return true
        } else {
          return false
        }
      })

      if (errorData.length) {
        message.warning(t('v4:not_complete'))
      } else {
        message.success(t('v4:complete'))
      }
    })
  }

  getAttachmentDetail = ids => {
    if (ids && ids.length > 0) {
      return ids.forEach(id => {
        api.library.getMediaInfoById(id).then(response => {
          this.setPDF(response.data)
        })
      })
    }
  }

  setPDF = file => {
    this.setState(prevState => {
      const filterIds = prevState.attachmentIds.filter(i => i !== file.id)
      const filterNames = prevState.filenames.filter(i => i.id !== file.id)
      return {
        attachmentIds: [...filterIds, file.id],
        filenames: [...filterNames, file]
      }
    })
  }

  setCover = (id, link) => {
    this.setState({
      coverId: id,
      coverLink: link
    })
  }

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }

  handleFileUpload = () => {
    this.setState({ toSelectFile: true })
  }

  hideAttachModal = () => {
    this.setState({ toSelectFile: false })
  }

  onHandleDelete = id => {
    this.setState(
      state => {
        const filteredIds = state.attachmentIds.filter(i => i !== id)
        const filteredFiles = state.filenames.filter(i => i.id !== id)
        return {
          attachmentIds: filteredIds,
          filenames: filteredFiles
        }
      },
      () => {
        this.handleParam()
      }
    )
  }

  onDropPdf = dropped => {
    const file = dropped[0]
    const { pdfFiles } = this.state
    if (!file) {
      return
    }
    const { clearUploadList } = this.props
    const { upload } = this.props
    const newFiles = pdfFiles.concat(dropped)
    return this.setState(
      {
        toSelectFile: false,
        modalOpen: false,
        pdfFiles: newFiles
      },
      () => {
        dropped.forEach(file => {
          upload(file)
            .then(response => {
              this.setPDF(response)
            })
            .then(() => {
              this.handleParam()
              clearUploadList()
            })
        })
      }
    )
  }

  checkedCompletUpload = item => {
    const { uploadSuccess } = this.props.library
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )
    return !(uploadSuccess.length && filterSuccessFiles.length)
  }

  handleInsertFile = ({ rows: privateData }, publicMedia, orgMedia) => {
    let tempData = privateData

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      tempData = [...privateData, ...publicData]
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      tempData = [...privateData, ...orgData]
    }
    let array = tempData
      .filter(t => t.isSelected)
      .map(file => this.setPDF(file))
    Promise.all(array).then(() => {
      this.handleParam()
      this.setState({ toSelectFile: false, modalOpen: false })
    })
  }

  handleRemoveSelected = () => {
    this.setState({ attachmentIds: [], filenames: [] })
  }

  handleForm = () => {
    const {
      form,
      user: {
        info: {
          username,
          organization: { media_service }
        }
      }
    } = this.props
    // let activeOrgInfo = {}

    const {
      current,
      coverId,
      value,
      emails,
      attachmentIds,
      publish,
      isEventMarketPlace,
      isBroadcast
    } = this.state
    const {
      marketplace: { category_id, video_link, is_free, price }
    } = current

    const id = this.props.match.params.id || current.id || null
    this.setState({
      validate: true
    })
    form.validateFields((err, values) => {
      console.log(
        'values log event detail',
        values,
        'err',
        err,
        'state',
        this.state
      )
      if (!coverId) {
        return
      }

      if (_.isEmpty(value)) {
        return
      }

      if (!err) {
        let isFree =
          typeof this.state.is_free === 'boolean'
            ? this.state.is_free
            : current.marketplace
            ? is_free
            : false
        let encoderType =
          values.encoder_type === 'conference'
            ? media_service === 'CHIME'
              ? 'chime_conference'
              : values.encoder_type
            : values.encoder_type

        const data = {
          title: values.title,
          description: values.description,
          location: values.location,
          host: values.host,
          topics: _.map(value, 'id'),
          cover_id: coverId,
          marketplace: {
            category_id: isEventMarketPlace ? values.category : category_id,
            video_link: isEventMarketPlace ? values.video_link : video_link,
            is_free: isFree,
            price: isEventMarketPlace ? Number(values.price) : price,
            username: username
          },
          no_of_people_conf: Number(values.no_of_people_conf),
          is_chat: values.is_chat,
          is_recording: isBroadcast
            ? true
            : values.is_recording || current.is_recording,
          is_poll: values.is_poll,
          poll: current.poll,
          encoder_type: encoderType,
          is_transcribed: current.is_transcribed,
          transcribe_locale: current.transcribe_locale,
          host_emails: emails,
          attachments: attachmentIds
        }
        this.setState({
          isSaving: true
        })
        if (current.cover_id && id) {
          api.events
            .updateById(id, data)
            .then(() => {
              this.getEventsData()
              // this.setState(
              //   {
              //     isLoading: false,
              //     isSaving: false,
              //     current: {
              //       ...this.state.current,
              //       ...response.data
              //     }
              //   },
              //   () => {
              if (publish) history.push('/events/' + id + '/settings')
              // }
              //   )
            })
            .catch(error => {
              message.error(error.response.data.errorMessage)

              this.setState({
                isSaving: false,
                isLoading: false
              })
            })
        } else {
          api.events.create(data).then(response =>
            this.setState({
              isSaving: false,
              isLoading: false,
              current: {
                ...this.state.current,
                ...response.data
              }
            })
          )
        }
      }
    })
  }

  handleChangeLocale = value => {
    this.setState(
      {
        transcribeLocale: value
      },
      () => {
        this.handleParam()
      }
    )
  }

  handleChangeOption = value => {
    // if (value === 'multihost') {
    //   this.setState(
    //     {
    //       isMultiHost: true,
    //       emails: [],
    //       isHostsLenExceed: false,
    //       isConference: false,
    //       showTranscribe: false,
    //       toTranscribe: false,
    //       transcribeLocale: ''
    //     },
    //     () => {
    //       this.handleParam()
    //     }
    //   )
    // } else
    if (value === 'conference') {
      return this.setState(
        {
          isBroadcast: false,
          emails: [],
          isHostsLenExceed: false,
          isConference: true,
          showTranscribe: false,
          toTranscribe: false,
          transcribeLocale: ''
        },
        () => {
          this.handleParam()
        }
      )
    } else if (
      value === 'wowza_gocoder' ||
      value === 'other_webrtc' ||
      value === 'stream_from_phone'
    ) {
      return this.setState(
        {
          showTranscribe: true,
          isBroadcast: true,
          isConference: false
        },
        () => {
          this.handleParam()
        }
      )
    }
    return this.setState(
      {
        isBroadcast: false,
        isConference: false,
        showTranscribe: false,
        toTranscribe: false,
        transcribeLocale: ''
      },
      () => {
        this.handleParam()
      }
    )
  }

  handleSubmitForm = () => {
    this.setState(
      {
        isLoading: true,
        publish: true
      },
      () => {
        this.handleForm()
      }
    )
  }

  handleCheckTranscribe = value => {
    if (!value) {
      this.setState({ transcribeLocale: '' })
    }
    this.setState({ toTranscribe: value }, () => {
      this.handleParam()
    })
  }

  handleParam = _.debounce(param => {
    // if (param === 'host' || param === true) {
    if (param === 'host') {
      return
    }
    this.handleForm()
  }, 1000)

  handleSwitch = val => {
    this.setState(
      {
        is_free: val
      },
      () => this.handleForm()
    )
  }

  onDrop = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  addPoll = () => {
    this.setState(state => ({
      current: {
        ...state.current,
        poll: [
          ...state.current.poll,
          {
            question: '',
            answers: ['']
          }
        ]
      }
    }))
  }

  deletePoll = index => {
    this.setState(state => ({
      current: {
        ...state.current,
        poll: state.current.poll.filter((item, i) => i !== index)
      }
    }))
  }

  addAnswer = rootIndex => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers ? [...item.answers, ''] : ['']
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  deleteAnswer = (rootIndex, index) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers.filter((a, j) => j !== index)
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  handleQuestion = (index, value) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === index) {
        item.question = value
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  handleAnswer = (rootIndex, index, value) => {
    const arrPoll = this.state.current.poll
    const newPoll = arrPoll.map((item, i) => {
      if (i === rootIndex) {
        item.answers = item.answers.map((a, j) =>
          j === index ? (a = value) : a
        )
      }
      return item
    })
    this.setState(state => ({
      current: {
        ...state.current,
        poll: newPoll
      }
    }))
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching && !_.isEmpty(topicList)) {
      const topic = topics.map(item =>
        _.head(topicList.filter(topic => topic.id === item))
      )
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])
          this.setState(
            {
              isTopicLoading: false,
              isTopicsMax: false,
              options: [...options, newOption],
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleParam()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    return {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  handleUploadImage = data => {
    const { upload, clearUploadList } = this.props

    return this.setState({ isImageLoading: true }, () => {
      upload(data).then(response => {
        this.setCover(response.id, response.link)
        this.handleParam()
        clearUploadList()
        this.setState({
          image: null,
          file: null,
          isImageLoading: false
        })
      })
    })
  }

  handleFileInsert = item => {
    this.setCover(item.id, item.link)
    this.setState(
      {
        modalOpen: false,
        selectedItem: null
      },
      () => {
        this.handleParam()
      }
    )
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
    }
  }
  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  checkFinish = ({ start_date_time: startDataTime, duration = 0 }) => {
    const isAllowPermission = this.getUserRole()
    if (isAllowPermission) {
      if (startDataTime) {
        const newTime = moment(startDataTime).format('LLLL')
        const diffMinutes = moment().diff(newTime, 'minutes')
        const newDuration = duration || 10
        const checkInterval = diffMinutes - newDuration

        return checkInterval <= 0
      }
      return true
    } else {
      return false
    }
  }
  getUserRole = () => {
    const {
      user: {
        info: { isAdmin, isSuperAdmin }
      }
    } = this.props
    const { is_finished: isFinished } = this.state.current
    if (!isFinished) {
      return isAdmin || isSuperAdmin
    } else {
      return !isFinished
    }
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []

    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  fetchUserEmails = value => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ emailList: [], fetching: true })
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        res.data.forEach(val => {
          if (val.user) {
            const dataValue = {
              text: val.user.email,
              value: val.user.email
            }
            this.setState(state => {
              if (dataValue.value.includes(value)) {
                const emailList = [...state.emailList, dataValue]
                return {
                  emailList,
                  fetching: false
                }
              }
            })
          }
        })
      })
  }

  handleEmailChange = selectedValue => {
    this.setState(
      prevState => {
        if (prevState.showContactModal) {
          return {
            emailList: [],
            emails: selectedValue,
            fetching: false,
            showContactModal: false
          }
        }
        return {
          emailList: [],
          emails: selectedValue,
          fetching: false
        }
      },
      () => {
        const {
          form: { setFieldsValue }
        } = this.props
        setFieldsValue({ host_emails: this.state.emails })
      }
    )
  }

  handleHostEmailChange = () => {
    this.setState({
      emailList: [],
      fetching: false
    })
  }

  handleToggleMarketPlace = () => {
    this.setState(
      {
        isEventMarketPlace: !this.state.isEventMarketPlace
      },
      () => {
        this.getEventsData()
      }
    )
  }

  toggleContactModal = () => {
    this.setState(prevState => ({
      showContactModal: !prevState.showContactModal
    }))
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      library: { uploadProgress },
      t,
      user,
      organizations: { rows }
    } = this.props
    const {
      coverId,
      coverLink,
      validate,
      current,
      isFetching,
      isSaving,
      isLoading,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      file,
      image,
      modalOpen,
      selectedItem,
      isImageLoading,
      isBroadcast,
      isConference,
      isEdit,
      fetching,
      emailList,
      emails,
      toSelectFile,
      pdfFiles,
      attachmentIds,
      filenames,
      isEventMarketPlace,
      hasError,
      no_of_people_conf,
      showContactModal
    } = this.state

    const groupOptions = rows
      ? rows.filter(o => o.id === user.info.active_organization_id)[0]
      : {}
    const routeId = this.props.match.params.id || null
    const eventId = this.props.match.params.id || current.id || null
    const spinning = routeId ? isFetching : false
    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }
    const customStyles = {
      control: provided => {
        return {
          ...provided,
          borderRadius: '0px',
          borderColor: _.isEmpty(value) && validate ? '#FF4B55' : '#D8D8D8',
          boxShadow: 0,
          transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
          marginBottom:
            (_.isEmpty(value) && validate) || isTopicsMax ? '0px' : '24px'
        }
      },
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }
    if (isFetching) {
      return null
    }

    console.log('object view events', isFetching, isEdit, hasError)
    return (
      <Fragment>
        {!isFetching && isEdit && hasError && (
          <section className="section">
            <Alert message={t('errors:not_found_event')} type="error" />
          </section>
        )}
        {!hasError && (
          <>
            <div className="p-tabs">
              <Tabs activeKey={'details'}>
                <Tabs.TabPane tab={t('tabs:details')} key="details" disabled />
                <Tabs.TabPane
                  tab={t('tabs:settings')}
                  key="settings"
                  disabled
                />
              </Tabs>
            </div>
            <Header
              {...this.props}
              eventId={eventId}
              isEventMarketPlace={isEventMarketPlace}
              onToggleMarketPlace={this.handleToggleMarketPlace}
            />
            <Spin spinning={spinning}>
              <section className="section" style={{ paddingBottom: '10rem' }}>
                <Form>
                  <Row>
                    <Col md={isEventMarketPlace ? 0 : 6}>
                      {!isEventMarketPlace && (
                        <>
                          <Form.Item
                            label={t('labels:title')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('title', {
                              rules: [
                                {
                                  required: true,
                                  message: t('errors:title_event'),
                                  max: 320
                                }
                              ],
                              initialValue: current.title
                            })(
                              <Input
                                name="title"
                                placeholder={t('placeholders:title')}
                                size="large"
                                maxLength={100}
                                onChange={this.handleParam}
                              />
                            )}
                          </Form.Item>
                          <Form.Item
                            label={t('labels:host')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('host', {
                              rules: [
                                {
                                  required: true,
                                  message: t('errors:host')
                                }
                              ],
                              initialValue:
                                current.host && current.host.email
                                  ? current.host.email
                                  : user.info.email
                                  ? user.info.email
                                  : emails[0]
                            })(
                              <Select
                                showSearch
                                placeholder="Select host's email"
                                notFoundContent={
                                  fetching ? <Spin size="small" /> : null
                                }
                                filterOption={false}
                                disabled={!this.checkFinish(current)}
                                onSearch={this.fetchUserEmails}
                                onChange={this.handleHostEmailChange}
                                style={{ width: '50%' }}
                              >
                                {emailList.map(d => (
                                  <Option key={d.value}>{d.text}</Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>

                          <Form.Item
                            label={t('labels:description')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('description', {
                              initialValue: current.description
                            })(
                              <Input.TextArea
                                rows={4}
                                name="description"
                                placeholder={t('placeholders:description')}
                                size="large"
                                maxLength={1000}
                                onChange={this.handleParam}
                                style={{ fontSize: '1.6rem', resize: 'none' }}
                              />
                            )}
                          </Form.Item>
                        </>
                      )}
                      {isEventMarketPlace && (
                        <TopSection
                          {...this.props}
                          {...this.state}
                          onFieldChange={this.handleParam}
                        />
                      )}
                      <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
                        {t('labels:topics').toUpperCase()}
                      </div>
                      <CreatableSelect
                        styles={customStyles}
                        components={{
                          ClearIndicator: null,
                          DropdownIndicator: null,
                          LoadingIndicator: this.LoadingIndicator
                        }}
                        isMulti
                        placeholder={t('labels:topics')}
                        inputId="react-select-2-input"
                        isLoading={isTopicLoading}
                        menuIsOpen={this.isModalSelectOpen()}
                        onBlur={() => this.setState({ inputValue: '' })}
                        onChange={this.handleChange}
                        onCreateOption={this.handleCreate}
                        onInputChange={this.loadOptions}
                        options={options}
                        value={value}
                      />
                      {_.isEmpty(value) && validate && (
                        <div className="error_message">
                          {t('errors:live_topics')}
                        </div>
                      )}
                      {isTopicsMax && (
                        <div className="error_message">
                          {t('errors:topics_max')}
                        </div>
                      )}

                      {isEventMarketPlace && <TrainerSection />}

                      {!isEventMarketPlace && (
                        <>
                          <Form.Item
                            label={t('labels:broadcast_label')}
                            // extra={t('labels:location_broadcast_subtitle')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('encoder_type', {
                              rules: [
                                {
                                  required: true,
                                  message: t('errors:select_broadcast_option')
                                }
                              ],
                              initialValue:
                                current.encoder === 'conference' ||
                                current.encoder === 'chime_conference'
                                  ? 'conference'
                                  : current.encoder
                            })(
                              <Select
                                optionFilterProp="children"
                                showSearch
                                size="large"
                                disabled={!this.checkFinish(current) || isEdit}
                                onChange={e => this.handleChangeOption(e)}
                                filterOption={(input, option) =>
                                  !_.isArray(option.props.children) &&
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {Object.keys(broadcastOptions).map(item => (
                                  <Select.Option key={item} value={item}>
                                    {t(`broadcastOptions:${item}`)}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                          {isConference && (
                            <>
                              <Form.Item
                                label={t('labels:attendee_emails')}
                                colon={false}
                                required={false}
                              >
                                {getFieldDecorator('host_emails', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t(
                                        'errors:attendee_email_require'
                                      )
                                    }
                                  ],
                                  initialValue: emails
                                })(
                                  <Select
                                    mode="multiple"
                                    placeholder={t('placeholders:email')}
                                    notFoundContent={
                                      fetching ? <Spin size="small" /> : null
                                    }
                                    filterOption={false}
                                    onSearch={this.fetchUserEmails}
                                    onChange={this.handleEmailChange}
                                    style={{ width: '100%' }}
                                  >
                                    {emailList.map(d => (
                                      <Option key={d.value}>{d.text}</Option>
                                    ))}
                                  </Select>
                                )}
                              </Form.Item>
                              <span
                                role="button"
                                style={{
                                  position: 'relative',
                                  top: '-53px',
                                  float: 'right',
                                  paddingRight: '10px',
                                  cursor: 'pointer'
                                }}
                                onClick={() => this.toggleContactModal()}
                              >
                                <SearchOutlined />
                              </span>

                              <Form.Item
                                label={t('v4:no_of_people_in_conference')}
                                colon={false}
                                required={false}
                              >
                                {getFieldDecorator('no_of_people_conf', {
                                  rules: [
                                    {
                                      required: true,
                                      message: t('v4:team_size')
                                    }
                                  ],
                                  initialValue: no_of_people_conf
                                })(
                                  <Select
                                    optionFilterProp="children"
                                    placeholder={t(
                                      'v4:no_of_people_placeholder'
                                    )}
                                    notFoundContent={
                                      fetching ? <Spin size="small" /> : null
                                    }
                                    filterOption={false}
                                    onChange={this.handleParam}
                                    style={{ width: '100%' }}
                                  >
                                    {Object.keys(
                                      general.NUMBER_OF_PEOPLE_CONF
                                    ).map(d => (
                                      <Option key={d} value={d}>
                                        {t(`conferenceTeamOptions:${d}`)}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Form.Item>
                            </>
                          )}

                          {/* {(showTranscribe) && */}
                          {/* <Form.Item
                        label={t('v4:transcribe_settings')}
                        colon={false}
                        required={false}
                        >
                        {getFieldDecorator('is_transcribed', {
                          valuePropName: 'checked',
                          initialValue: toTranscribe
                        })(
                          <Switch
                            name='is_transcribed'
                            checkedChildren={t('v4:transcribe')}
                            unCheckedChildren={t('v4:transcribe')}
                            style={{ marginRight: '10px' }}
                            onChange={this.handleCheckTranscribe}
                          />
                        )}

                        {toTranscribe &&
                          <>
                            { getFieldDecorator('transcribe_locale',
                          {
                            rules: [
                              {
                                required: true,
                                message: t('v4:select_transcribe_locale')
                              }
                            ],
                            initialValue: transcribeLocale
                          }
                          )(
                            <Select
                              showSearch
                              style={{ width: 200 }}
                              placeholder='Transcribe to'
                              optionFilterProp='children'
                              onChange={e => this.handleChangeLocale(e)}
                              filterOption={(input, option) =>
                                !_.isArray(option.props.children) &&
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {transcribeKeys.map(item => (
                                <Option key={item.code} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                            )}
                        </>
                        }

                        </Form.Item> */}
                          {/* } */}

                          <Form.Item
                            label={t('labels:location_broadcast')}
                            extra={t('labels:location_broadcast_subtitle')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('location', {
                              rules: [
                                {
                                  required: true,
                                  message: t('errors:live_location')
                                }
                              ],
                              initialValue: current.broadcast_location
                            })(
                              <Select
                                optionFilterProp="children"
                                showSearch
                                size="large"
                                disabled={!this.checkFinish(current)}
                                onChange={this.handleParam}
                                filterOption={(input, option) =>
                                  !_.isArray(option.props.children) &&
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {regions.map(continent => (
                                  <Select.OptGroup
                                    key={continent.title}
                                    value={continent.title}
                                  >
                                    {Object.keys(continent.list).map(item => (
                                      <Select.Option key={item} value={item}>
                                        {t(`regions:${item}`)}
                                      </Select.Option>
                                    ))}
                                  </Select.OptGroup>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </>
                      )}
                    </Col>
                    <Col md={isEventMarketPlace ? 0 : 6}>
                      <Form.Item
                        label={t('labels:picture')}
                        colon={false}
                        required={false}
                      >
                        <Dropzone
                          accept={'image/*'}
                          onDrop={this.onDrop}
                          style={{}}
                          disabled={uploadProgress.length > 0}
                        >
                          {({ isDragActive }) => {
                            return isImageLoading ? (
                              <DropzoneLoading type="loading" />
                            ) : (
                              <div
                                className={className(
                                  'upload-dropzone',
                                  'upload-dropzone_event',
                                  {
                                    'upload-dropzone_active': isDragActive,
                                    'upload-dropzone_invalid':
                                      !coverId && validate
                                  }
                                )}
                                style={{
                                  backgroundImage: 'url(' + coverLink + ')'
                                }}
                              >
                                <div className="wrap_upload_buttons">
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                  >
                                    {t('buttons:upload_image')}
                                  </Button>
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setState({
                                        modalOpen: true
                                      })
                                    }}
                                  >
                                    {t('buttons:select_media_library')}
                                  </Button>
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.useCanva()
                                    }}
                                  >
                                    {t('v4:canva')}
                                  </Button>
                                </div>
                              </div>
                            )
                          }}
                        </Dropzone>
                      </Form.Item>
                    </Col>
                    {isEventMarketPlace && (
                      <BottomSection
                        {...this.props}
                        {...this.state}
                        onFieldChange={this.handleParam}
                        handleTimezone={this.handleTimezone}
                        handleSwitch={this.handleSwitch}
                      />
                    )}

                    <Col md={6}>
                      {this.checkFinish(current) && (
                        <Form.Item
                          label={t('labels:settings')}
                          colon={false}
                          required={false}
                        >
                          {getFieldDecorator('is_chat', {
                            valuePropName: 'checked',
                            initialValue: current.is_chat
                          })(
                            <Switch
                              name="is_chat"
                              checkedChildren={t('general:chat')}
                              unCheckedChildren={t('general:chat')}
                              style={{ marginRight: '10px' }}
                              onChange={this.handleParam}
                            />
                          )}
                          {!isBroadcast &&
                            getFieldDecorator('is_recording', {
                              valuePropName: 'checked',
                              initialValue: current.is_recording
                            })(
                              <Switch
                                name="is_recording"
                                checkedChildren={t('v4:is_record')}
                                unCheckedChildren={t('v4:is_record')}
                                style={{ marginRight: '10px' }}
                                onChange={this.handleParam}
                              />
                            )}
                          {getFieldDecorator('is_poll', {
                            valuePropName: 'checked',
                            initialValue: current.is_poll
                          })(
                            <Switch
                              name="is_poll"
                              checkedChildren={t('v4:poll')}
                              unCheckedChildren={t('v4:poll')}
                              onChange={this.handleParam}
                            />
                          )}
                          {getFieldDecorator('file_pdf', {
                            initialValue: current.attachments
                          })(
                            <Button
                              type="primary"
                              shape="round"
                              onClick={this.handleFileUpload}
                              style={{
                                width: '19%',
                                height: '25px',
                                marginLeft: '10px'
                              }}
                            >
                              {attachmentIds.length === 0
                                ? t('v4:to_attach')
                                : t('v4:file_attached')}
                            </Button>
                          )}
                          {uploadProgress.length > 0 &&
                            uploadProgress.map((item, ind) => (
                              <div
                                key={item.uid}
                                className="upload-modal__item"
                              >
                                <div className="upload-modal-item">
                                  <div className="upload-modal-item__percent-border">
                                    <div
                                      className={`upload-modal-item__percent ${
                                        item.status === general.CANCELED
                                          ? 'upload-modal-item__percent__cancel'
                                          : ''
                                      }`}
                                      style={{ width: item.percent + '%' }}
                                    />
                                  </div>
                                </div>
                                {item.status !== general.CANCELED && (
                                  <div className="upload-modal-item__weight">
                                    {this.checkedCompletUpload(item) && (
                                      <span>
                                        {bytesToSize(item.loaded)} {t('of')}{' '}
                                        {bytesToSize(item.total)}
                                      </span>
                                    )}
                                    {!this.checkedCompletUpload(item) && (
                                      <span className="upload-modal-item__weight__complete">
                                        {pdfFiles[ind] && pdfFiles[ind].removed
                                          ? t('general:removed')
                                          : t('general:completed')}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            ))}
                        </Form.Item>
                      )}

                      {filenames.length > 0 && getFieldValue('file_pdf') && (
                        <Attachment
                          data={filenames}
                          t={t}
                          handleDelete={this.onHandleDelete}
                        />
                      )}
                      {getFieldValue('is_poll') && (
                        <Poll
                          data={current.poll}
                          addQuestion={this.addPoll}
                          deleteQuestion={this.deletePoll}
                          addAnswer={this.addAnswer}
                          deleteAnswer={this.deleteAnswer}
                          handleQuestion={this.handleQuestion}
                          handleAnswer={this.handleAnswer}
                          savePoll={this.handleForm}
                          isSaving={isSaving}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </section>
              <div className="p-footer">
                <div className="p-footer__inner">
                  <div className="p-footer__item">
                    <Button
                      type="default"
                      className="rounded"
                      onClick={this.handleForm}
                      loading={isSaving}
                    >
                      {t('buttons:save_for_later')}
                    </Button>
                  </div>
                  <div className="p-footer__item">
                    <Button
                      type="primary"
                      className="rounded"
                      disabled={!eventId}
                      loading={isLoading}
                      onClick={this.handleSubmitForm}
                    >
                      {t('buttons:publish')} <Icon type="right" />
                    </Button>
                  </div>
                </div>
              </div>
            </Spin>
          </>
        )}
        {image && (
          <ImageCropContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
            t={t}
          />
        )}
        {modalOpen && (
          <Modal
            visible={modalOpen}
            onCancel={this.hideModal}
            footer={null}
            width={'80%'}
            style={modal_style}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                types={!toSelectFile ? 'images' : 'pdf'}
                handleInsertData={this.handleInsertData}
                handleInsertFile={this.handleInsertFile}
                selectedFiles={current.attachments}
                handleRemoveSelected={this.handleRemoveSelected}
                getFileName={this.getFileName}
              />
            </div>
            {selectedItem && (
              <Modal
                visible={selectedItem}
                onCancel={this.hidePreviewModal}
                footer={null}
              >
                <Preview library={selectedItem} />
                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {t('labels:cancel')}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {t('labels:insert')}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
        {toSelectFile && (
          <Modal
            title={t('v4:select_file_attach')}
            visible={toSelectFile}
            onCancel={this.hideAttachModal}
            className="custom-ant-modal"
            destroyOnClose
            width={600}
            footer={null}
            // width={'40%'}
            // style={modal_style}
          >
            <Dropzone
              accept={'.pdf'}
              onDrop={this.onDropPdf}
              style={{}}
              disabled={uploadProgress.length > 0}
              multiple
            >
              {({ isDragActive }) => (
                <div
                  className={className(
                    'upload-dropzone',
                    'upload-dropzone_event',
                    {
                      'upload-dropzone_active': isDragActive
                    }
                  )}
                >
                  <div className="wrap_upload_buttons">
                    <Button
                      className="rounded"
                      size="large"
                      icon="filepdfoutlined"
                    >
                      {t('v4:upload_pdf')}
                    </Button>
                    <Button
                      className="rounded"
                      size="large"
                      icon="filepdfoutlined"
                      onClick={e => {
                        e.stopPropagation()
                        this.setState({
                          modalOpen: true
                        })
                      }}
                    >
                      {t('buttons:select_media_library')}
                    </Button>
                    <Button
                      className="rounded"
                      size="large"
                      icon="picture"
                      onClick={e => {
                        e.stopPropagation()
                        this.useCanva()
                      }}
                    >
                      {t('v4:canva')}
                    </Button>
                  </div>
                </div>
              )}
            </Dropzone>
          </Modal>
        )}
        <Modal
          visible={showContactModal}
          onCancel={this.toggleContactModal}
          width={700}
          footer={null}
        >
          <ShowContactsModal
            active_organization_id={user.info.active_organization_id}
            groupOptions={groupOptions}
            t={t}
            addEmails={this.handleEmailChange}
            selectedEmails={getFieldValue('host_emails')}
          />
        </Modal>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('events')(Details))
