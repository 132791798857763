import React from 'react'
import { Button } from 'antd'
import './header.scss'

const Header = props => {
  const { t, isEventMarketPlace, onToggleMarketPlace, eventId } = props
  console.log('eventId', eventId)
  return (
    <>
      <section className="section marketplace">
        <div className="head head_nopadding">
          <div className="head__title">
            {t('title')}
            {isEventMarketPlace ? '  ( Marketplace )' : ''}
          </div>
          <div className="head__subtitle">{t('subtitle')}</div>
        </div>
        <Button
          size="large"
          shape="round"
          onClick={onToggleMarketPlace}
          className="button__marketplace"
          disabled={!eventId}
        >
          {isEventMarketPlace
            ? 'Save & Continue'.toUpperCase()
            : t('buttons:market_place').toUpperCase()}
        </Button>
      </section>
    </>
  )
}

export default Header
