import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import ReactSVG from 'react-svg'
import _ from 'lodash'
import { Field } from '../../../../../styles'
import { Input, Checkbox, Button, Alert, Modal, Radio } from 'antd'
import { Page } from '../../styled'
import DatePicker from '../DatePicker'
import PrivacySelector from '../PrivacySelector'
import OrganizationPicker from '../OrganizationPicker'
import GroupPicker from '../GroupPicker'
import { general } from '../../../../../constants'
import moment from 'moment'

const Setting = props => {
  const {
    App: {
      handleSelectedDate,
      disabledDate,
      checkFinish,
      clearAlert,
      renderError,
      closeCheckModal,
      handleSendEmail,
      handleCheckForm,
      onAdminOnly,
      handleSelectedType,
      checkSelectedType,
      handleSelectedOrganizations,
      handleSelectedGroups,
      handleSelectedAllOrganizations,
      handleChangeTerms,
      onSendEmail,
      handleParam
    },
    t,
    eventTime,
    eventDuration,
    error_message,
    errorData,
    isOverLimit,
    error,
    isCheckModal,
    isNotExist,
    isNotInOrg,
    selectedType,
    isAdminOnly,
    isSendMail,
    isLoading,
    isDisable,
    list,
    user: {
      info: { isAdmin, isSuperAdmin, active_organization_id }
    },
    encoderType,
    tag,
    isMarketPlaceEnabled,
    selectedOrganizations,
    selectedGroups,
    checkedTerms,
    isSaving,
    current,
    allocated_time_slots,
    shouldHideNonMarketplaceFields = false
  } = props

  const isPublicEvent =
    tag.value === 'agora_conference' || tag.value === 'webinar'

  const isCoaching = tag.value === 'peer2peer'

  const marketplacePrivacyType = {
    id: 4,
    name: t('labels:Marketplace'),
    description: 'MarketPlaceDescription',
    disabled: !isMarketPlaceEnabled
  }

  const privacyTypes = [
    isSuperAdmin && {
      id: 1,
      name: 'Public',
      description: 'PublicDescription',
      disabled: !isPublicEvent
    },
    (isSuperAdmin || isAdmin) && {
      id: 2,
      name: t('v4:organisations'),
      description: 'OrganisationsDescription'
    },
    (isSuperAdmin || isAdmin) && {
      id: 3,
      name: t('v4:org_groups'),
      description: 'OrganisationGroupsDescription'
    },
    marketplacePrivacyType
  ].filter(i => !!i)

  const filteredList =
    !_.isEmpty(list) &&
    list.filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))

  const [dateError, setDateError] = useState(false)

  const handleDate = (params, value) => {
    if (
      params === 'eventTime' &&
      moment(value)
        .add(5, 's')
        .isBefore(new Date())
    ) {
      setDateError(true)
    } else {
      setDateError(false)
      handleSelectedDate(params, value)
    }
  }

  console.log('event setting option', privacyTypes, [privacyTypes[3]])

  return (
    <>
      <section className="section_divider" style={{ paddingTop: '1.5rem' }}>
        {!shouldHideNonMarketplaceFields && (
          <Page>
            <DatePicker
              onChange={handleDate}
              eventTime={eventTime}
              eventDuration={eventDuration}
              disabledDate={disabledDate}
              allocated_time_slots={allocated_time_slots}
              isShowNoted
              current={current}
              encoderType={encoderType}
              disabled={eventTime && !checkFinish(eventTime, eventDuration)}
              t={t}
            />
          </Page>
        )}
        {(error || dateError) && (
          <div className="date_time_error">
            <Alert
              message={t('v2:error')}
              description={
                general[dateError ? 'date_time_error_peer' : error_message]
              }
              type="error"
              closable
              showIcon
              banner
              onClose={clearAlert}
            />
          </div>
        )}
        {isOverLimit && (
          <div className="date_time_error">
            <Alert
              message={t('v2:error')}
              description={renderError(t, errorData)}
              type="error"
              closable
              showIcon
              banner
              onClose={clearAlert}
            />
          </div>
        )}

        {encoderType !== 'live_assessment' && (
          <>
            <Page>
              <PrivacySelector
                selected={selectedType}
                isCoaching={isCoaching}
                types={isCoaching ? [marketplacePrivacyType] : privacyTypes}
                isAdmin={isSuperAdmin || isAdmin}
                onChange={handleSelectedType}
                checkSelectedType={checkSelectedType}
              />
            </Page>
            {!isCoaching && (
              <>
                {checkSelectedType(2) && (
                  <Page>
                    <OrganizationPicker
                      encoder={tag}
                      list={filteredList}
                      activeOrg={active_organization_id}
                      onChange={handleSelectedOrganizations}
                      selectedOrganizations={selectedOrganizations}
                    />
                  </Page>
                )}
                {checkSelectedType(3) && (
                  <Page>
                    <GroupPicker
                      list={filteredList}
                      encoder={tag}
                      selectedGroups={selectedGroups}
                      onChange={handleSelectedGroups}
                      activeOrg={active_organization_id}
                      onChangeAll={handleSelectedAllOrganizations}
                    />
                  </Page>
                )}
              </>
            )}
          </>
        )}
        {(selectedType.some(item => item > 0) || isCoaching) && (
          <Page>
            <Field>
              <Field.Label>{t('labels:disclaimer')}</Field.Label>
              <Field.Control>
                <Input.TextArea
                  disabled
                  rows={6}
                  style={{ resize: 'none' }}
                  value={t('events:disclaimer')}
                />
              </Field.Control>
            </Field>
            <Field>
              <Field.Label>
                <Checkbox checked={checkedTerms} onChange={handleChangeTerms}>
                  <Trans i18nKey="labels:terms">
                    {t('i_agree_to_these')}
                    <a href="/pages/terms-conditions" target="_blank">
                      {t('terms_and_conditions')}
                    </a>
                  </Trans>
                </Checkbox>
              </Field.Label>
            </Field>
          </Page>
        )}
      </section>
      {isCheckModal && (
        <Modal
          visible={isCheckModal}
          centered
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReactSVG src="/images/icons/menu/bells.svg" />
              <div style={{ paddingLeft: '2rem' }}>
                {t('labels:check_user')}
              </div>
            </div>
          }
          onCancel={closeCheckModal}
          footer={[
            <Button key="back" shape="round" onClick={closeCheckModal}>
              {t('v4:no')}
            </Button>,
            <Button
              key="submit"
              shape="round"
              type="primary"
              onClick={handleSendEmail}
            >
              {t('v4:yes')}
            </Button>
          ]}
        >
          {!_.isEmpty(isNotExist) && (
            <div style={{ marginBottom: '1rem' }}>
              <p style={{ color: '#333333' }}>{t('labels:user_not_exist')}</p>
              <div
                style={{
                  backgroundColor: '#f1f1f1',
                  borderRadius: '.5rem',
                  padding: '1rem'
                }}
              >
                {!_.isEmpty(isNotExist) &&
                  isNotExist.map((item, index) => (
                    <div className="selectedMedia">
                      <div className="titleMedia">
                        {index + 1}. {item.email}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {!_.isEmpty(isNotInOrg) && (
            <div>
              <p style={{ color: '#333333' }}>
                {t('labels:user_not_exist_in_org')}
              </p>
              <div
                style={{
                  backgroundColor: '#f1f1f1',
                  borderRadius: '.5rem',
                  padding: '1rem'
                }}
              >
                {!_.isEmpty(isNotInOrg) &&
                  isNotInOrg.map((item, index) => (
                    <div className="selectedMedia">
                      <div className="titleMedia">
                        {index + 1}. {item.email}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Modal>
      )}
      <div className="p-footer">
        <div className="p-footer__inner">
          {checkSelectedType(1) && (
            <div className="p-footer__item1">
              <Checkbox onChange={onAdminOnly} checked={isAdminOnly}>
                {t('v4:admin_only')}
              </Checkbox>
            </div>
          )}

          {/* {enable_ai &&
              <Checkbox onChange={e => { onEnableAI(e.target.checked) }} checked={is_ai_enabled}>
                Enable AI
              </Checkbox>
            } */}
          {selectedType.some(
            item => item === 2 || item === 3 || item === 4
          ) && (
            <div className="p-footer__item">
              <Radio.Group>
                <Radio
                  value={isSendMail}
                  checked={isSendMail}
                  onChange={() => {
                    onSendEmail(true)
                  }}
                >
                  {t('v2:send_invite_email')}
                </Radio>
                <Radio
                  value={!isSendMail}
                  checked={!isSendMail}
                  onChange={() => {
                    onSendEmail(false)
                  }}
                >
                  {t('v2:do_not_send_invite_email')}
                </Radio>
              </Radio.Group>
            </div>
          )}

          <div className="p-footer__item">
            <Button
              className="rounded"
              loading={isSaving}
              onClick={handleParam}
            >
              {t('buttons:save')}
            </Button>
          </div>
          <div className="p-footer__item">
            <Button
              className="rounded"
              type="green"
              loading={isLoading}
              onClick={handleCheckForm}
              disabled={isDisable || isSaving}
            >
              {t('buttons:publish_event')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting
