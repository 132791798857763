import React from 'react'
import { Button } from 'antd'
import './header.scss'
import NewHeader from '../../../../../components/NewHeader'
import { useTranslation } from 'react-i18next'

const Header = props => {
  const { isEventMarketPlace, onToggleMarketPlace, eventType, current } = props
  const { t } = useTranslation()
  return (
    <>
      <section className="section marketplace">
        <div className="head head_nopadding">
          <div className="head__title">
            {t('v2:create_live_event')}
            {isEventMarketPlace ? '  ( Marketplace )' : ''}
          </div>
        </div>
        <div className="marketplace-head">
          {eventType === 'conference' ||
          eventType === 'live_assessment' ? null : isEventMarketPlace ? null : (
            <Button
              size="large"
              shape="round"
              onClick={onToggleMarketPlace}
              className="button__marketplace"
              disabled={!current.id} //UNCOMMENT TO DEPLOYYYY
            >
              {t('buttons:market_place')}
            </Button>
          )}
          <NewHeader />
        </div>
      </section>
    </>
  )
}

export default Header
