export default {
  getStarted: 'https://www.youtube.com/embed/TafJgEaizww',
  whatsNew: 'https://www.youtube.com/embed/-Jd3MGGlujI',
  createCourse: 'https://www.youtube.com/embed/G8fuPDRWtzU',
  createGroup: 'https://www.youtube.com/embed/pkkgGiDo-hg',
  createLiveAssessment: 'https://www.youtube.com/embed/904WNycw2vM',
  createLiveEvent: 'https://www.youtube.com/embed/NaEVPpOLB9w',
  createWebinar: 'https://www.youtube.com/embed/vrU-MnjxzQQ',
  createLiveOnMobile: 'https://www.youtube.com/embed/JWvjPrlQ7oo',
  addAnnotations: 'https://www.youtube.com/embed/hImA1gsR3pE',
  addAudio: 'https://www.youtube.com/embed/03ABmWXZAhM',
  addHeaderAndFooter: 'https://www.youtube.com/embed/gcLrqfG8Qx0',
  addImage: 'https://www.youtube.com/embed/oEfksNia2XQ',
  addQuizContent: 'https://www.youtube.com/embed/EFWtiTlNQOs',
  addTextContent: 'https://www.youtube.com/embed/vc5lB0J9teI',
  addUser: 'https://www.youtube.com/embed/1BOSn6mKnpA',
  addVideo: 'https://www.youtube.com/embed/7nh72gM5qN0',
  cloneCourse: 'https://www.youtube.com/embed/cb9t7WA94y8',
  findLiveBroadcast: 'https://www.youtube.com/embed/wXN3D38sCjY',
  transcribeTranslate: 'https://www.youtube.com/embed/f6xYnWcqTH4'
}

//<iframe width="853" height="480" src="https://www.youtube.com/embed/f6xYnWcqTH4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>