import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import Preview from './Preview'
import { withRouter } from 'react-router-dom';

const { viewEvent, setCountdownTimer } = actions.events
const { clearNotes } = actions.notes
const { getDiscussionLog, clearDiscussion } = actions.discussion
const { updateUser } = actions.user


const mapStateToProps = state => {
  return {
    user: state.user,
    log: state.discussion.log,
    organizations: state.organizations.list
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      viewEvent,
      setCountdownTimer,
      clearNotes,
      getDiscussionLog,
      clearDiscussion,
      updateUser
    },
    dispatch
  )

  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Preview));
