import React, { useEffect, useState } from 'react'
import { Button, Input } from 'antd'
import { types } from '../types'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const defaultTag = {
  [`${types.MAZE}`]: 'all',
  [`${types.SKILLJOURNEY}`]: 'all',
  [`${types.COURSE}`]: 'new',
  [`${types.EVENT}`]: 'upcoming'
}

function TrainingListActions({
  type = types.EVENT,
  loading,
  onTagSelect,
  onListSearch,
  openSequenceState,
  sequenceState,
  handleSaveSequence
}) {
  const user = useSelector(state => state.user)
  const {
    info: { isSuperAdmin, isAdmin }
  } = user
  const [activeTag, setTag] = useState(defaultTag[type])

  const { t } = useTranslation()

  const mazeAndSkillTags = [
    {
      id: 'all',
      name: t('media:all')
    }
  ]

  const courseTags = [
    {
      id: 'new',
      name: t('v4:new')
    },
    {
      id: 'progress',
      name: t('v4:in_progress')
    },
    {
      id: 'completed',
      name: t('general:completed')
    },
    {
      id: 'recommended',
      name: t('v4:recommended_course')
    }
  ]

  const eventTags = [
    {
      id: 'upcoming',
      name: t('v4:up_coming')
    },
    {
      id: 'past',
      name: t('v4:past_live')
    }
  ]

  useEffect(() => {
    if (loading) return

    if (onTagSelect) {
      onTagSelect(activeTag)
    }
  }, [activeTag])

  const tagsByType =
    type === types.EVENT
      ? eventTags
      : type === types.COURSE
      ? courseTags
      : mazeAndSkillTags

  const onChangeSearch = _.debounce(v => onListSearch(v), 1000)

  return (
    <div className="training-list-actions">
      <div className="training-list-tags">
        {tagsByType &&
          tagsByType.map(({ id, name }) => (
            <TagButton
              key={id} // Added a key prop here
              loading={loading}
              activeTag={activeTag}
              tagName={name}
              tagId={id}
              onTagClick={val => setTag(val)}
            />
          ))}
      </div>

      <div className="training-list-filters">
        {(isSuperAdmin || isAdmin) &&
          (type === types.COURSE || type === types.SKILLJOURNEY) && (
            <Button
              loading={loading}
              style={{
                background: '#00AF12',
                color: '#fff',
                marginBottom: 10,
                marginRight: 10
              }}
              shape="round"
              onClick={() => {
                if (loading) return
                if (!sequenceState) {
                  openSequenceState(true)
                } else {
                  handleSaveSequence()
                }
              }}
            >
              {!sequenceState ? t('v4:manage_sequence') : t('v4:save_sequence')}
            </Button>
          )}
        <Search
          placeholder={t('v2:search')}
          onChange={e => onChangeSearch(e.target.value)}
          style={{ width: 300 }}
          disabled={loading}
        />
      </div>
    </div>
  )
}

const TagButton = ({ activeTag, tagName, tagId, loading, onTagClick }) => (
  <Button
    disabled={activeTag !== tagId && loading}
    loading={activeTag === tagId && loading}
    style={{
      background: activeTag === tagId ? '#256BF6' : '#E7E7E7',
      color: activeTag === tagId ? '#fff' : 'inherit'
    }}
    shape="round"
    onClick={() => {
      if (loading) return
      onTagClick(tagId)
    }}
  >
    {tagName}
  </Button>
)

export default TrainingListActions
