import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../store/actions/index'
import Report from './Report'

export default connect(
  state => ({
    data: state.reports,
    user: state.user,
    maze: state.maze
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getReport: bindActionCreators(actions.reports.getReport, dispatch),
    getCourseWorkshopReport: bindActionCreators(
      actions.reports.getCourseWorkshopReport,
      dispatch
    ),
    getUserTimeSpentReport: bindActionCreators(
      actions.reports.getUserTimeSpentReport,
      dispatch
    ),
    getEventWorkshopReport: bindActionCreators(
      actions.reports.getEventWorkshopReport,
      dispatch
    ),
    getSkillJourneyWorkshopReport: bindActionCreators(
      actions.reports.getSkillJourneyWorkshopReport,
      dispatch
    ),
    getMazeReport: bindActionCreators(actions.reports.getMazeReport, dispatch)
    // searchUserData: bindActionCreators(actions.reports.searchUserData, dispatch)
    //getUserReports: bindActionCreators(actions.reports.getUserReports, dispatch)
  })
)(Report)
