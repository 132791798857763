import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const HelpItem = styled.div`
  position: absolute;
  bottom: 0;
`

HelpItem.Link = styled(NavLink)`
  position: relative;
  display: block;

  &:link,
  &:hover,
  &:visited {
    text-decoration: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: .5rem;
    background: transparent;
    transition: border-color .25s;
  }

  &.active {
    background: ${colors.light};
  }

  &.active::before {
      background: ${colors.blue};
    }
  }
`

HelpItem.Inner = styled.div`
  display: flex;
  align-items: center;
`

HelpItem.Icon = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 7.2rem;
  height: 7.2rem;

  & > div,
  & > div > div {
    display: flex;
    align-items: center;
  }
  svg{
    fill: ${colors.white};
  }
`

HelpItem.Heading = styled.div`
  flex-shrink: 0;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 2.4rem 2.4rem 2.4rem 0;
  color: #777;
  transition: color .25s;

  ${HelpItem.Link}:hover &,
  ${HelpItem.Link}.active & {
    color: ${colors.blue};
  }
`

export {
  HelpItem
}
