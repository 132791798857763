/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'שמור',
    autosaved: 'שמור אוטומטית',
    back_to: 'בחזרה אל',
    by: 'על ידי',
    chat: 'צ’אט',
    completed: 'הושלם',
    canceled: 'בוטל',
    component: 'מרכיב',
    confirm: 'אשר',
    course: 'קורס',
    date: 'תאריך',
    details: 'פרטים',
    empty: 'אין נתונים',
    enrolled: 'רשום',
    free: 'חינם',
    graph: 'גרף',
    loading: 'טוען....',
    lesson: 'שיעור',
    lessons: 'שיעורים',
    live_assessment: 'הערכה בשידור חי',
    live_event: 'אירוע בשידור חי',
    no: 'לא',
    of: 'של',
    or: 'או',
    organization: 'ארגון',
    page: 'עמוד',
    pages: 'עמודים',
    personal: 'אישי',
    poll: 'סקר',
    rating: 'דירוג',
    reset: 'איפוס',
    signin: 'כניסה',
    signup: 'הרשמה',
    soon: 'בקרוב',
    type: 'סוג',
    uploading: 'מעלה...',
    yes: 'כן',
    something_went_wrong: 'משהו השתבש!',
    view_all: 'הצג הכל',
    upload_successful: 'הועלה בהצלחה',
    upload_successful_message:
      'מסמכי ה-אקסל שלך הועלו בהצלחה וכעת הם בעיבוד. זה עשוי לקחת קצת זמן. ',
    check_email_upload_successful:
      'בבקשה <0>תבדוק את הדוא”ל שלך</0> עבור <1>הדוח</1> רשימת המשתמשים שהועלתה',
    is_private: 'פרטי',
    to_attach: 'לצרף קובץ',
    file_attached: 'מצורף קובץ'
  },

  account: {
    title: 'חשבון',
    subtitle: 'נהל את ההגדרות ואת פרטי החשבון שלך.',
    saved: 'החשבון עודכן בהצלחה'
  },

  assessments: {
    title: 'אומדנים',
    subtitle: 'צור הערכה',
    finished: 'ההערכה הסתיימה',
    countdown: 'ההערכה בשידור חי תשודר ב ',
    create: 'צור הערכה',
    list_title: 'האומדנים שלך',
    delete_warning: 'האם אתה בטוח שברצונך למחוק פריט זה?',
    details_title: 'הוסף פרטי ההערכה',
    details_subtitle:
      'תן שם לאומדן שלך ותגיד לקהל שלך מה הם צריכים לצפות ממנו.',
    disclaimer: `אני מתחייב שהאומדן המוקלט שלי לא:
    - פוגע בזכויות של צד שלישי כלשהו
    - מכיל כל אי חוקיות, כולל תוכן משמיץ, מגונה, גס, מזויף, פורנוגרפי, אלים, פוגע, מעליב, מאיים או מפלה, אפילו מוסווה לכוכביות
    - מכיל התקפות אישיות על אנשים אחרים
    - מכיל כל התחזות למשתתפים אחרים, אישי ציבור או ידוענים
    - מכיל כל הקלטה או מידע אישי על אדם כלשהו ללא הסכמתו של אדם כזה ואינו מפר כל תקנה להגנה על מידע באף חלק בעולם
    - מכיל כל שידול מסחרי או כל סוג של "ספאם"'`,
    assessment_details: 'פרטי ההערכה',

    assessment_settings: 'הגדרות ההערכה'
  },

  buildersettings: {
    title: 'הגדרות פרסום',
    subtitle:
      'הוסף הגדרות לאירוע שלך, מי יכול להציג את האירוע שלך ולקיים אינטראקציה איתו'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'הוסף',
    add_page: 'הוסף עמוד',
    add_lesson: 'הוסף שיעורים',
    add_answer: 'הוסף תשובה חדשה',
    add_group: 'הוסף קבוצה',
    add_organization: 'הוסף ארגון',
    add_question: 'הוסף שאלה',
    add_quiz: 'הוסף בחינה',
    add_users: 'הוסף משתמשים',
    back: 'חזרה',
    back_to_course: 'חזרה לקורס',
    back_to_lesson: 'חזרה לשיעור',
    back_to_media: 'חזרה לספריית המדיה',
    back_to_details: 'חזרה לפרטים',
    cancel: 'ביטול',
    clear: 'נקה',
    close: 'סגור',
    clone: 'שכפל',
    close_lesson: 'סגור שיעור',
    clone_course: 'שכפול קורס',
    confirm: 'אשר',
    create: 'צור',
    download: 'הורדה',
    download_mass_user_tamplate: 'הורדת תבנית',
    add_mass_users: 'הוסף משתמשים המוניים',
    upload_excel_list: 'העלה רשימת אקסל',
    delete: 'מחק מדיה',
    edit: 'עריכה',
    edit_course: 'עריכת קורס',
    enroll: 'לְהִרָשֵׁם',
    finish: 'סיום',
    upload_image: 'העלה תמונה',
    upload_image_video: 'העלה תמונה או וידאו',
    select_media_library: 'בחר ספריית מדיה',
    make_public: 'הפוך לציבורי',
    make_private: 'הפוך לפרטי',
    make_org: 'צור ארגון',
    media: 'ספריית מדיה',
    my_theme: 'הנושא שלי',
    next: 'הבא',
    next_lesson: 'שיעור הבא',
    next_step: 'השלב הבא',
    previous_step: 'השלב הקודם',
    publish: 'פרסם',
    publish_assessment: 'פרסם את ההערכה שלך',
    publish_course: 'פרסם את הקורס שלך',
    publish_event: 'פרסם את האירוע שלך',
    reset: 'איפוס',
    reset_elements: 'אפס אלמנטים',
    save: 'שמור',
    creating: 'מייצר...',
    updating: 'מעדכן...',
    saved: 'שמור',
    save_for_later: 'שמור למועד מאוחר יותר',
    save_settings: 'שמור הגדרות',
    select_files: 'בחר קבצים',
    select_theme: 'בחר נושא',
    start_lesson: 'התחל שיעור',
    update: 'עדכון',
    upload: 'העלה',
    upload_audio: 'העלה קובץ אודיו',
    upload_photo: 'העלה תמונת פרופיל',
    upload_video: 'העלה סרטון',
    enrolled: 'רשום',
    unenroll: 'בטל הרשמה',
    from_library: 'ספריית מדיה',
    remove: 'הסר',
    removed: 'הוסר',
    start_broadcast: 'התחל שידור',
    end_broadcast: 'סיים שידור',
    camera: 'מצלמה',
    screen: 'מסך',
    screen_with_camera: 'מסך עם מצלמה',
    insert: 'הוסף',

    inserting: 'מוסיף',
    upload_pdf: 'העלה PDF',

    portrait: 'דְיוֹקָן',
    landscape: 'נוֹף'
  },

  card: {
    quick_add: 'הוספה מהירה',
    add: 'הוסף',
    clone: 'שכפל',
    completed: 'השיעורים הושלמו'
  },

  course: {
    upcoming_course: 'הקורס הקרוב',
    unPublishEvent: 'בטל פרסום האירוע',
    unPublishAssessment: 'בטל פרסום ההערכה',
    pastCourse: 'קורס הכשרה',
    upcoming: 'האירוע בשידור חי הקרוב',
    pastEvent: 'האירוע בשידור חי הקודם',
    pastAssessment: 'ההערכה בשידור חי הקודמת',
    upcomingAssessment: 'ההערכה בשידור חי הקרובה',
    created: 'תאריך יצירה:',
    assignees: 'מועמדים',
    organisations: ' {{ ארגונים }} ארגונים, <2> {{ משתמשים }} משתמשים</2>',
    event_date: 'תאריך שידור חי האירוע <1> {{ תאריך }} </1>',
    delete_warning: 'האם אתה בטוח שברצונך למחוק את זה',
    clonning_no_lesson: 'הוסף לפחות שיעור אחד על מנת לשכפל קורס זה.',
    clone_warning: 'האם אתה בטוח שברצונך לשכפל את זה',
    clone_success: 'נמצא בתהליך שכפול',
    disclaimer: `אני מתחייב שהאומדן המוקלט שלי לא:
    - פוגע בזכויות של צד שלישי כלשהו
    - מכיל כל אי חוקיות, כולל תוכן משמיץ, מגונה, גס, מזויף, פורנוגרפי, אלים, פוגע, מעליב, מאיים או מפלה, אפילו מוסווה לכוכביות
    - מכיל התקפות אישיות על אנשים אחרים
    - מכיל כל התחזות למשתתפים אחרים, אישי ציבור או ידוענים
    - מכיל כל הקלטה או מידע אישי על אדם כלשהו ללא הסכמתו של אדם כזה ואינו מפר כל תקנה להגנה על מידע באף חלק בעולם
    - מכיל כל שידול מסחרי או כל סוג של "ספאם"`
  },

  coursecreate: {
    dropzone_text: 'הכנס מדיה',
    sidebar_text: 'בחר נושא <1/> לשיעורים <3/> שלך',
    delete_warning: 'האם אתה בטוח שברצונך למחוק את השיעור הזה?'
  },

  coursepage: {
    quizview_loading: 'התתשובה בבדיקה. אנא המתן...',
    quizview_answer: 'התשובה שלך'
  },

  coursequiz: {
    title: 'בחינות לכל השיעורים'
  },

  dashboard: {
    tooltip_title: 'אל תציג את הסרטון הזה שוב',
    page_title: 'סטטיסטיקת הקורסים שלך',
    page_subtitle: 'מצב הקורסים שיצרת',
    profile_add_info:
      'הוסף את <1>{{uncompletedText}}</1> כדי להשלים את הפרופיל שלך.',
    profile_build:
      'בנה את הפרופיל שלך <1>כאן</1>, ספר לנו את תחומי העניין שלך ואת הכישורים שלך.',
    profile_title: 'חוזק פרופיל',
    more_details: 'פרטים נוספים'
  },

  errors: {
    answer: 'אנא הזן תשובה',
    answer_correct: 'בחר את התשובה הנכונה',
    code: 'אנא הזן את הקוד שלך',
    criterion: 'אנא הזן קריטריון',
    duration: 'משך זמן נדרש',
    element: 'אנא הזן אלמנט',
    email: 'אנא הזן את הדוא"ל שלך',
    email_invalid: 'הדוא"ל שהוזן אינו תקין',
    feedback_correct: 'אנא הזן משוב נכון',
    feedback_incorrect: 'אנא הזן משוב שגוי',
    host: 'אנא הזן מארח האירוע',
    host_assessment: 'אנא הזן מארח האומדן',
    image: 'אנא העלה תמונה',
    location: 'הזן את המיקום שלך',
    live_location: 'הזן את המיקום השידור',
    limit: 'אנא הזן את מספר הדקות של השידור חי',
    min_3char: 'מינימום 3 תווים',
    number: 'אנא הזן מספר חיובי.',
    host_email_require: `דוא"ל של מארחים נדרשים.`,
    attendee_email_require: `דוא"ל של משתתפים נדרשים`,
    name: 'אנא הזן את שמך',
    not_found_course: 'קורס לא נמצא',
    not_found_event: 'אירוע לא נמצא',
    not_found_assessment: 'אומדן לא נמצא',
    password: 'אנא הזן את הסיסמה שלך',
    password_digit: 'לפחות ספרה אחת',
    password_short: 'הסיסמה קצרה מדי, מינימום 10 תווים',
    password_special: 'לפחות תו מיוחד אחד',
    password_uppercase: 'לפחות תו אחד גדול',
    password_lowercase: 'לפחות תו אחד קטן',
    phone: 'אנא הזן את הטלפון שלך',
    phone_invalid: 'מספר טלפון לא תקין',
    position: 'אנא הזן את עמדתך',
    question: 'אנא הזן שאלה',
    start_date: 'תאריך ושעה התחלה נדרשים',
    tag: 'אנא בחר תג',
    topics: 'אנא בחר נושא אחד לפחות.',
    live_topics: 'אנא הוסף נושאים',
    title: 'אנא הזן כותרת',
    title_assessment: 'אנא הזן כותרת ההערכה',
    title_course: 'אנא הזן את כותרת הקורס',
    title_empty: 'הכותרת לא יכולה להיות ריקה',
    title_event: 'אנא הזן כותרת האירוע',
    title_lesson: 'אנא הזן כותרת השיעור',
    title_quiz: 'הזן שם הבוחן',
    username: 'אנא הזן את שם המשתמש שלך',
    upload_error: 'שגיאה - הקובץ לא תואם!',
    upload_more_than_10: 'אתה יכול להעלות רק 10 קבצים בבת אחת.',
    topics_max: 'אנא הוסף מקסימום 3 נושאים.',
    upload_excel: 'אנא וודא שהעלית את הקובץ הנכון.',
    title_excel: 'שגיאה בהעלאת תבנית:',
    file_size_exceed: 'גודל הקובץ חורג',
    subtitle_excel: 'אנא וודא שאתה משתמש בתבנית שסופקה והעמודות ממולאות כראוי.',
    select_broadcast_option: 'אנא בחר אפשרות אחת לשידור אירוע זה',
    multi_hosts_number_error: 'מספר המגישים לא יכול לחרוג מהמצוין עבור האירוע.',
    conference_people_number_error: `מספר המשתתפים לא יכול לחרוג מהמצוין באירוע.`,
    select_max_host: 'בחר לפחות מגיש אחד לאירוע.',
    select_max_conference: 'בחר לפחות משתתף אחד לאירוע',
    stream_stop_error: 'השידור החי הופסק באופן בלתי צפוי!',
    unsecure_context:
      'שגיאה חמורה: הדפדפן לא יכול לגשת למצלמה ולמיקרופון בגלל הקשר לא מאובטח. אנא התקן SSL והיכנס דרך https',
    ws_not_supported: 'שגיאה חמורה: WebSocket לא נתמך בדפדפן זה',
    no_org: 'כדי להמשיך בחר לפחות ארגון אחד.',
    no_org_group: 'כדי להמשיך בחר לפחות בקבוצת ארגון אחת.',
    waiting_to_join: 'מחכה שמישהו יצטרף!',
    select_transcribe_locale: 'בחר שפה לתמלול',
    transcribe_limit: 'אנא הזן תמלול דקות',
    monthly_transcribe: 'מגבלת תמלול חודשית:',
  },

  events: {
    title: 'הוסף פרטי האירוע בשידור חי',
    change_host: 'שנה מארח',
    finished: 'הסתיים האירוע בשידור חי ',
    countdown: 'השידור החי של האירוע יתחיל בעוד ',
    subtitle:
      'תן שם לשידור החי של האירוע שלך ותגיד לקהל שלך מה עליהם לצפות ללמוד ממנו.',
    poll_disabled: 'סקר אינו זמין באירוע זה',
    disclaimer: `אני מתחייב שההערכה המוקלטת שלי לא:
    - פוגע בזכויות של צד שלישי כלשהו
    - מכיל כל אי חוקיות, כולל תוכן משמיץ, מגונה, גס, מזויף, פורנוגרפי, אלים, פוגע, מעליב, מאיים או מפלה, אפילו מוסווה לכוכביות
    - מכיל התקפות אישיות על אנשים אחרים
    - מכיל כל התחזות למשתתפים אחרים, אישי ציבור או ידוענים
    - מכיל כל הקלטה או מידע אישי על אדם כלשהו ללא הסכמתו של אדם כזה ואינו מפר כל תקנה להגנה על מידע באף חלק בעולם
    - מכיל כל שידול מסחרי או כל סוג של "ספאם"'`,
    prompt_message:
      'היציאה מהדף הזה יסיים את האירוע שלך. האם אתה בטוח לעזוב את הדף הזה?',
    duration_exceed_message:
      'זמן השידור שלך חורג, האפליקציה עומדת להפסיק את השידור.',
    before_schedule_message:
      'אתה עומד להתחיל את השידור לפני שעת האירוע שנקבעה. שימו לב כי עצירת השידור תסיים את האירוע.',
    browser_unsupportive_message:
      'הדפדפן שלך אינו תומך בשיתוף מסך. אתה יכול לראות דפדפנים נתמכים בקישור זה',
    cancel_switch_host_message: 'המארח אינו זמין. האירוע יימשך מהקצה שלך.',
    coaching_prompt_message: 'אינך יכול לצאת מדף זה עד שהאירוע יסתיים.',
    event_modal_warn: 'האם אתה רוצה לעזוב את האירוע?',
    confirm_message: 'האירוע שלך יסתיים אם תלחץ על אישור.',
    screen_share_denied: 'שללת לשתף את המסך שלך',
    media_not_found: 'המצלמה או המיקרופון לא נמצאו או אינם מורשים בהתקן שלך.',
    media_not_access:
      'המצלמה או המיקרופון משמשים לתהליך אחר שאינו מאפשר לקרוא התקנים אלה',
    constraint_error:
      'לא נמצא מכשיר שמתאים לאילוצי הווידיאו והשמע שלך. אתה יכול לשנות אילוצי וידאו ושמע',
    media_access_denied: 'אינך מורשה לגשת למצלמה ולמיקרופון.'
  },

  filters: {
    days7: '7 ימים אחרונים',
    days15: '15 ימים אחרונים',
    days30: '30 ימים אחרונים',
    days365: '365 ימים אחרונים',
    date: 'תאריך יצירה',
    relevance: 'רלוונטיות',
    popularity: 'פופולריות',
    name: 'שם',
    group: 'קבוצה',
    Newest_to_Oldest: 'הכי חדש לישן ביותר',
    Oldest_to_Newest: 'הכי ישן לחדש ביותר',
    A_Z: 'A - Z',
    Z_A: 'Z - A'
  },

  helpers: {
    password:
      'כדי להבטיח את אבטחת חשבונך, אנו דורשים לפחות אות קטנה אחת וספרה אחת בסיסמה שלך. עליך גם אורך מינימלי הסיסמא של 10 תווים.',
    phone: 'כלול את קוד המדינה שלך בטלפון, לדוגמה: +65',
    username:
      'שם המשתמש שלך הוא מידע ציבורי והוא מוצג למשתמשים אחרים לפי הצורך. ככאלה, אנו ממליצים שלא להשתמש במידע אישי בשם המשתמש שלך',
    public_description_normal:
      '<0><0/> </0> שלך נשמר ונחשב כברירת מחדל כפרטי. המשמעות היא שרק אתה יכול לראות את התוכן הזה. כדי לפרסם תוכן זה אתה יכול לבחור אחת מהאפשרויות שלהלן. פרסום הקורס שלך לציבורי פירושו שהוא יהיה זמין לכל המשתמשים בפלטפורמה. האפשרות המשתמשים הספציפיים תאפשר לך לשתף את הקורס שלך עם משתמשים ספציפיים באמצעות כתובת הדוא"ל המשויכת לחשבון Sharelook שלהם.',
    public_description_admin:
      '<0><0/> </0> זה נשמר ונחשב כברירת מחדל כפרטי. משמעות הדבר היא שרק אתה (ויוצר התוכן) יכולים לראות את התוכן הזה. כדי לפרסם תוכן זה אתה יכול לבחור אחת מהאפשרויות שלהלן. פרסום הקורס שלך לציבורי פירושו שהוא יהיה זמין לכל המשתמשים בפלטפורמה - כולל אלה שמחוץ לארגון שלך. פרסום זה לארגון פירושו שכל המשתמשים בארגון יוכלו לראות אותו. אתה יכול גם לפרסם אותו בקבוצה אחת או יותר, כלומר רק המשתמשים בקבוצות אלו יוכלו לראות את זה. לבסוף, יש לך אפשרות לפרסם אותה למשתמשים ספציפיים בתוך הארגון ומחוצה לו באמצעות כתובת הדוא"ל של חשבון Sharelook שלהם.'
  },

  labels: {
    add: 'הוסף',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'סוג תשובה',
    answers: 'תשובות',
    back_to_quiz: 'חזרה לבוחן',
    back_to_question: 'חזרה לרשימת השאלות',
    chat: 'צ’אט',
    chat_available: "'צ'אט אינו זמין באירוע זה",
    // chat_report: 'דוח הצ'אט',
    choice_single: 'בחירה יחידה',
    choice_multiple: 'בחירה מרובה',
    choice_free: '”בחירה “חופשית',
    choice_sorting: 'בחירה “מיון”',
    choice_matrix: 'בחירה “מטריקס למיון”',
    clone_lesson_permission: 'הרשאת שכפול',
    code: 'קוד',
    correct: 'נכון',
    created: 'תאריך יצירה',
    clone_modal: 'שכפול קורס',
    cloning_course: 'משכפל את הקורס...',
    cloning: 'משכפל...',
    cloning_course_desc: 'תהליך זה ייקח זמן. אנא המתן בסבלנות.',
    cloning_success: 'הקורס "<0/>" שוכפל בהצלחה!',
    description: 'תיאור',
    disclaimer: 'הצהרת אחריות',
    drop_file: 'גרור קובץ כאן...',
    duration: 'משך זמן',
    edit: 'עריכה',
    email: 'דוא"ל',
    event: 'אירוע',
    event_all: 'כל האירועים שלו',
    event_date: 'תאריך / זמן מקומי האירוע בשידור חי',
    event_this: 'האירוע הזה',
    expire: 'פג תוקף ב',
    expiry_date: 'פג תוקף בתאריך',
    forgot: 'שכחת סיסמא?',
    group: 'קבוצה',
    groups_list: 'רשימת הקבוצות',
    host: 'מארח',
    id_annotation: 'מזהה ביאור',
    id_quiz: 'מזהה בוחן',
    incorrect: 'לא נכון',
    interests: 'תחומי עניין',
    invited: 'מוזמן',
    live_chat: 'צ’אט חי',
    live_stream_limit: 'דקות הסטרימינג בשידור חי',
    contact_text: 'כדי לשנות את מגבלת הסטרימינג החודשית שלך, אנא צור קשר',
    contact_admin:
      '<0>אם ברצונך לשנות את מגבלת הסטרימינג החודשית שלך, אנא צור קשר</0> <1>admin@sharelook.com</1>',
    // limit_stream_text:
    // 'מס הדקות שנותרו לסטרימינג בשידור חי': <0> {{limit}} </0> דקות <1 /> <2 /> <3> לשינוי מגבלת הסטרימינג החי החודשית אנא צרו קשר עם </ 3> <4> admin@sharelook.com </4>',
    location: 'מיקום',
    lessons: 'שיעורים',
    location_broadcast: 'מיקום שרת מארח',
    // location_broadcast_subtitle: 'הוסף את המיקום הכי קרוב למיקום של מארח השידור כדי להפחית את העיכובים באודיו \ וידאו',
    media_manager: 'מנהל מדיה',
    minutes: 'דקות',
    name: 'שם',
    online_quiz: 'בוחן בשידור חי',
    organiser: 'מחבר',
    organization: 'ארגון',
    overall_emotion: 'החוויה הכוללת:',
    organizations_list: 'רשימת ארגונים',
    password: 'סיסמא',
    participants: 'משתתפים',
    picture: 'תמונה (16:9)',
    phone: 'טלפון',
    photo: 'תמונה',
    poll: 'סקר',
    position: 'מיקום',
    processing: 'מעבד',
    privacy_settings: 'הגדרות פרטיות',
    question: 'שאלה',
    quiz_create: 'צור בוחן חדש',
    quiz_name: 'שם הבוחן',
    quiz_update: 'עדכן בוחן',
    select_organization: 'בחר ארגון',
    settings: 'הגדרות',
    show: 'הצג',
    show_graph: 'הצג את התוצאה בגרף',
    signin: 'כניסה',
    signup: 'הרשמה',
    sort_by: 'מיין לפי',
    start_date: 'תאריך התחלה',
    tags: 'תגיות',
    terms: 'אני מסכים <1>לתנאים והגבלות אלה</1>.',
    title: 'כותרת',
    topics: 'נושאים',
    topic: 'נושא',
    total_comments: 'סה"כ תגובות',
    username: 'שם משתמש',
    users_list: 'רשימת משתמשים',
    users_emails: 'הכנס את הדוא"ל של משתמשים ספציפיים',
    description_specifiction_email:
      'אנא הזן כתובת דוא"ל אחת בשורה כדי לשתף זאת עם מספר משתמשים ספציפיים.',
    message_specific_email: 'אנא הכנס הדוא"ל של המשתמש הספציפי.',
    viewers: 'צופים',
    edit_course: 'עריכה',
    edit_assessment: 'עריכה',
    edit_event: 'עריכה',
    delete_course: 'מחק',
    delete_event: 'מחק',
    delete_assessment: 'מחק',
    view_course: 'הצג',
    view_event: 'הצג',
    view_assessment: 'הצג',
    date_event: 'תאריך האירוע בשידור חי',
    date_assessment: 'תאריך האומדן בשידור חי',
    clone_of: 'שכפל את',
    clone_course:
      'צור שכפול של קורס זה, שתוכל לשנות ללא תלות במקור, כולל את כל השיעורים והדפים.',
    make: 'צור',
    available_to: 'זמין לכל משתמש ב- ShareLook',
    uploaded_by: 'הועלה על ידי: ',
    course: 'קורס',
    assessment: 'הערכה',
    user_not_exist: 'המשתמש לא קיים, האם תרצה לשלוח לו הזמנה?',
    check_user: 'התראה',
    user_not_exist_in_org:
      'המשתמש אינו קיים בארגון זה, האם תרצה לשלוח לו הזמנה?',
    title_modal_stream: 'שנה הגבלת השידור חי של הארגון',
    des_warning_stream: 'אתה עומד להשתנות',
    monthly_stream: 'מגבלת שידור חי החודשית:',
    // min_per_month: 'דקות \ חודש',
    broadcast_label: 'אני רוצה לעשות:',
    hosts_emails: `הכנס דוא"ל של מארחים ספציפיים`,
    attendee_emails: `הכנס דוא"ל של משתתפים ספציפיים`,
    description_host_specifiction_email: 'אנא הכנס כתובת דוא"ל אחת בכל שורה.',
    presenter_label: 'מגיש',
    presenter_label_conference: 'מספר אנשים',
    event_privacy: 'מצב ועידה',
    alert: 'עֵרָנִי!',
    warning: 'אַזהָרָה!',
    ok: 'בסדר',
    cancel: 'לְבַטֵל',
    done: 'בוצע',
    insert: 'לְהַכנִיס',
    private: 'פְּרָטִי',
    public: 'פּוּמְבֵּי',
    no_connection: 'חיבור לאינטרנט אבד',
    switch: 'החלף',
    share_screen: 'שתף מסך',
    stop_share_screen: 'עצור את מסך השיתוף',
    share_screen_with_camera: 'שתף את המסך עם המצלמה',
    stop_share_screen_with_camera: 'עצור את שיתוף המסך עם המצלמה',
    camera_on: 'מצלמה מופעלת',
    camera_off: 'מצלמה כבויה',
    mic_on: 'בטל השתקה',
    mic_off: 'לְהַשְׁתִיק',
    fullscreen: 'מסך מלא',
    live_now: 'תחיה עכשיו',
    schedule_on: 'לוח זמנים ל',
    organisations: 'ארגונים',
    org_groups: 'קבוצות ארגון',
    specific_user: 'משתמש ספציפי',
    gif: 'GIF',
    unavailable: 'אינו זמין',
    yes: 'כן',
    no: 'לא',
    event_duration_message: 'מקסימום 300 דקות (5 שעות)',
    send_email: 'שלח הודעת דואר אלקטרוני',
    admin_only: 'מנהל בלבד',
    join: 'לְהִצְטַרֵף',
    leave: 'לעזוב',
    share: 'לַחֲלוֹק',
    share_text: 'אנא הקלד דוא"ל עם מי ברצונך לשתף קובץ זה',
    share_text_title: 'לַחֲלוֹק PDF',
    select_users: 'בחר משתמשים',
    delete: 'לִמְחוֹק', // to_translate
    attachment_event: 'קבצים מצורפים לאירוע:', // to_translate
    transcribe_limit: 'תמלל דקות',
  },

  broadcastOptions: {
    stream_from_phone: 'שידור חי עם הטלפון שלי',
    other_webrtc: 'וובינר מהמחשב שלי',
    multihost: 'וובינר מרובה מארחים מהמחשב שלי',
    peer2peer: 'שיעור פרטי',
    conference: 'כנס בשידור חי'
  },

  lessoncreate: {
    title: 'הוסף פרטי שיעור',
    subtitle: 'תן שם לשיעור שלך ותגיד לקהל שלך מה עליהם לצפות ללמוד ממנו.',
    input_label_title: 'ותרת שיעור',
    input_label_topics: 'נושאי השיעור',
    input_label_description: 'תיאור השיעור',
    delete_warning: 'האם אתה בטוח שברצונך למחוק דף זה?'
  },

  lessonoverview: {
    title: 'הוסף שיעור',
    subtitle: 'הוסף שיעור קיים או צור שיעור חדש',
    new_lesson: 'צור שיעור חדש',
    new_lesson_sub: 'צרו שיעור חדש לגמרי, מאפס!',
    existing_lesson: 'בחר שיעור קיים',
    existing_lesson_sub:
      'קישור לשיעור קיים, שינויים שבוצעו בשיעור זה, ישפיעו גם על שיעור זה בקורסים אחרים',
    clone_lesson: 'שכפל את השיעור הקיים',
    clone_lesson_sub:
      'הכינו עותק ייחודי של השיעור בקורס זה, שינויים שיבוצעו בשיעור זה לא ישפיעו על השיעור המקורי.',
    clone_lesson_modal_sub:
      'אנא לחץ על כפתור השכפול שמתחת לשיעור שתרצה להוסיף לקורס שלך.'
  },

  management: {
    groups_title: 'נהל קבוצות',
    delete_warning: 'האם אתה בטוח?',
    organizations_title: 'הארגונים שלי',
    organizations_subtitle: 'הוסף ארגונים לרשימה שלך',
    users_title: 'נהל משתמשים',
    users_position: 'מנהל חשבון בכיר',
    user_not_found: 'משתמש לא נמצא',
    select_org: 'אנא מצא ובחר ארגון לניהול משתמשים'
  },

  audio: {
    modal_title: 'הכנס אודיו',
    list_view: 'הצג רשימה',
    create_audio_modal_title: 'צור אודיו',
    edit_audio_modal_title: 'ערוך אודיו',
    public_audio_not_found: 'אודיו לא נמצא',
    private_audio_not_found: 'עדיין לא העלית שום אודיו',
    are_you_sure_to_delete: 'האם אתה בטוח רוצה למחוק?',
    type_something: 'הקלד משהו',
    select_voice: 'בחר',
    file_name: 'שם הקובץ',
    description: 'תיאור',
    topic: 'נושא',
    key_words_tag: 'תג מילות מפתח',
    mp3_audio: 'MP3 אודיו',
    created: 'נוצר',
    duration: 'משך זמן',
    close: 'סגור'
  },

  image: {
    modal_title: 'הוסף תמונה',
    image: 'תמונה',
    pixel: 'פיקסל',
    dimension: 'גודל',
    public_image_not_found: 'תמונה לא נמצאה.',
    private_image_not_found: 'עדיין לא העלית שום תמונה'
  },

  annotation: {
    modal_title: 'הערה'
  },

  text: {
    modal_title: 'עורך טקסט'
  },

  video: {
    modal_title: 'הכנס וידאו',
    image: 'וידאו',
    mp4_video: 'MP4 וידאו',
    public_video_not_found: 'הוידאו לא נמצא',
    private_video_not_found: 'עדיין לא העלית שום קובץ וידאו'
  },

  media: {
    title: 'ספריית מדיה',
    subtitle: 'ספריית הקבצים שלך',
    modal_title: 'העלה מדיה',
    delete_warning: 'אשר את המחיקה',
    delete_message: 'מדיה שנמחקה עדיין תופיע בדפים שבהם נעשה בהם שימוש',
    dropzone_text: 'או גרור ושחרר',
    delete_success: 'המדיה נמחקה בהצלחה',
    upload_file: 'העלה קובץ',
    drag_drop: 'או גרור ושחרר',
    import_file: 'ייבא קבצים מהמחשב שלך',
    no_media: 'עדיין לא העלית שום מדיה',
    media_type: 'סוג מדיה',
    video: 'וידאו',
    image: 'תמונה',
    audio: 'אודין',
    gif: 'GIF תמונה',
    pdf: 'PDF קבצים',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'מדיה לא נמצאה.',
    of: 'של',
    disclaimer: `אני מתחייב שהמדיה שלי אינה עושה:
  - פוגע בזכויות של צד שלישי כלשהו
    - מכיל כל אי חוקיות, כולל תוכן משמיץ, מגונה, גס, מזויף, פורנוגרפי, אלים, פוגע, מעליב, מאיים או מפלה, אפילו מוסווה לכוכביות
    - מכיל התקפות אישיות על אנשים אחרים
    - מכיל כל התחזות למשתתפים אחרים, אישי ציבור או ידוענים
    - מכיל כל הקלטה או מידע אישי על אדם כלשהו ללא הסכמתו של אדם כזה ואינו מפר כל תקנה להגנה על מידע באף חלק בעולם
    - מכיל כל שידול מסחרי או כל סוג של "ספאם"`,
    my_media: 'המדיה שלי',
    public_library: 'הספרייה הציבורית',
    organization_library: 'ספריית הארגון',
    upload_from_computer: 'העלה מהמחשב',
    create: 'צור',
    all: 'הכל',
    male: 'זכר',
    female: 'נקבה',
    processing_audio: 'מעבד אודיו',
    create_annotation: 'צור הערה',
    popup_over_lesson_text: 'הערה זו תופיע בדף השיעור לאחר לחיצה',
    edit_detail: 'ערוך פרט',
    delete: 'מחק',
    go_create_audio: 'ליצירת אודיו',
    show_more_details: 'הצג יותר פרטים',
    show_less_details: 'הצג פחות פרטים',
    drop_files: 'גרור קבצים לכאן והיכן להעלות אותם',
    or: 'או',
    import_files: 'יבא קבצים מהמחשב שלך',
    human: `בן אנוש`,
    shared_file: 'הקובץ שלך שותף בהצלחה',
    error_shared_file: 'יש בעיה בשיתוף הקובץ. אנא נסה שוב'
  },

  navigation: {
    account: 'חשבון',
    assessments: 'הערכות',
    dashboard: 'לוח מחוונים',
    groups: 'ניהול הקבוצה',
    logout: 'יציאה',
    organisations: 'ארגונים',
    organizations_users: 'ארגונים ומשתמשים',
    reports: 'דוחות',
    schedule: 'לוח זמנים',
    training: 'הַדְרָכָה',
    nowLive: 'שידור ח',
    users: 'משתמשים',
    workshop: 'הסדנה שלי'
  },

  onboarding: {
    step1_title: 'שלב 1: ספר לנו קצת על עצמך',
    step1_subtitle: 'מה שמך? מאיפה אתה? מהי תפקידך?',
    step2_title: 'שלב 2: בחר מיומנות שאתה יכול להשתפר בהן',
    step2_subtitle:
      'בשלב הבא, ספר לנו מה תרצה להבין טוב יותר. אולי משהו שטרם למדת? תחום לימודים חדש? מה יקירך עובד?'
  },

  pagebuilder: {
    title: 'צור שיעור',
    subtitle:
      'הגיע הזמן ליצור את התוכן לקהל שלך, כך שהוא ילמד ממך, תוכל ליצור תמונות, קטעי וידאו ואינפוגרפיקה',
    select_layout: 'בחר פריסת עמוד',
    template1: 'ריק',
    template2: 'כותרת ותת-כותרת',
    template3: 'כותרת וטקסט',
    template4: 'כותרת, מדיה וטקסט',
    template5: 'כותרת ומדיה',
    template6: 'כותרת ובוחן'
  },

  pageconstructor: {
    title: 'צור ושכפל שיעור',
    subtitle:
      'הגיע הזמן ליצור את התוכן לקהל שלך, כך שהוא ילמד ממך, תוכל ליצור תמונות, קטעי וידאו ואינפוגרפיקה'
  },

  passwordreset: {
    success_coded:
      'שלחנו לך קוד אישור לכתובת הדוא"ל שסיפקת, אנא העתק את הקוד לשדה למטה כדי להגדיר סיסמא חדשה.',
    success_changed: 'הגדרת בהצלחה סיסמה חדשה. אתה יכול להיכנס עכשיו.'
  },

  placeholders: {
    search: 'חיפוש',
    answer: 'תשובה',
    chat: 'תגיד משהו...',
    code: 'קוד',
    criterion: 'קריטריון',
    date_event: 'בחר תאריך ושעה',
    date_start: 'תאריך התחלה',
    date_expire: 'תאריך תפוגה',
    description: 'תיאור',
    elements: 'מיין אלמנטים',
    email: 'דוא"ל',
    feedback_correct: 'משוב נכון',
    feedback_incorrect: 'משוב לא נכון',
    host: 'מארח',
    keywords: 'חיפוש מילות מפתח',
    lesson_description: 'תיאור שיעור',
    location: 'מיקום',
    minutes: 'דקות',
    name: 'שם',
    organiser: 'מארגן',
    organization: 'ארגון',
    password: 'סיסמה',
    phone: 'טלפון',
    photo: 'תמונה',
    picture: 'תמונה',
    point: 'נקודה',
    position: 'מיקום',
    question: 'שאלה',
    quiz: 'שם הבוחן',
    search_course: 'חיפוש לפי קורס',
    search_media: 'חיפוש מדיה',
    search_name: 'חיפוש לפי שם',
    search_title: 'חיפוש לפי כותרת',
    search_training: 'חיפוש הדרכה',
    settings: 'הגדרות',
    tags: 'תגיות',
    title: 'כותרת',
    topics: 'נושאים',
    username: 'שם משתמש',
    keyword: 'מילות מפתח',
    max_500_characters: 'מקסימום 500 תווים ',
    no_description: 'לקובץ זה אין תיאור',
    no_keyword: 'לקובץ זה אין מילות מפתח',
    no_topics: 'לקובץ זה אין ערכת נושא',
    feedback: 'הודעה',
    contact: 'איך אנחנו יכולים לעזור?'
  },

  publishsettings: {
    title: 'הגדרות פרסום',
    event_subtitle:
      'הוסף הגדרות לאירוע שלך, מי שיכול לצפות ולקיים אינטראקציה באירוע שלך',
    assessment_subtitile:
      'תן שם להערכה שלך ותגיד לקהל שלך מה עליהם לצפות ללמוד ממנו.',
    course_subtitle:
      'הוסף הגדרות לשיעור שלך, מי שיכול לצפות ולקיים אינטראקציה בשיעור שלך',
    disclaimer: '1. הרשמה',
    /*
  על ידי הרשמה כמשתמש / יצירת חשבון אתה מסכים ל [הסכם המשתמש] שלנו ומאשר בקריאת [מדיניות הפרטיות] שלנו

  2. יצירת קורס

  על ידי שימוש בכלים שלנו ויצירת תכני קורס, אתה מסכים ל [הסכם המשתמש] שלנו ומאשר בקריאת [מדיניות הפרטיות] שלנו

  3. יצירת אירוע

  על ידי שימוש בכלים שלנו ויצירת אירוע, אתה מסכים ל [הסכם המשתמש] שלנו ומאשר בקריאת [מדיניות הפרטיות] שלנו

  4. יצירת אומדן

  על ידי שימוש בכלים שלנו ויצירת אומדן, אתה מסכים ל [הסכם המשתמש] שלנו ומאשר בקריאת [מדיניות הפרטיות] שלנו
  ', */
    dates_validation_error: 'ש למלא את שני התאריכים.',
    emails_validation_error: 'רשימת הדוא"ל אינה חוקית.',
    specific_email_validation_error: 'רק מייל אחד מותר.',
    i_agree_to_these: 'אני מסכים לאלה ',
    terms_and_conditions: 'תנאים והגבלות.',
    PublicDescription:
      'משתמשים עצמאיים חינם: (אפשרויות פרטיות וציבוריות) [קורס | אירוע | אומדן] נשמר ונחשב כברירת מחדל כפרטי. המשמעות היא שרק אתה יכול לראות את התוכן הזה. כדי לפרסם תוכן זה, תוכל לבחור להפוך אותו לציבורי למטה. המשמעות היא שהקורס יהיה זמין לכל המשתמשים בפלטפורמה.',
    OrganisationsDescription:
      'משתמשים בארגונים: (במקום "סיום" להשתמש ב"פרסם "ולהביא אותם לדף הפרסום זהה למשתמשים אחרים אך ללא כל אחת מאפשרויות הפרסום.) [קורס | אירוע | אומדן] נשמר, והוא פרטי. משמעות הדבר היא שרק אתה והמנהלים בארגון שלך יכולים לראות את זה. כמשתמש, אינך יכול לפרסם את התוכן שלך בארגון שלך בעצמך מכיוון שהדבר מחייב זכויות מנהל. אנא צור קשר עם אחד ממנהלי המערכת שלך ובקש ממנו לפרסם את התוכן שלך בפומבי, לכל הארגון, לקבוצות ספציפיות בארגון שלך או למשתמשים ספציפיים.',
    OrganisationGroupsDescription:
      'מנהלי ארגונים: (לתוכן שנעשה בתוך הארגון: פרטי, ציבורי, ארגון, קבוצות, משתמשים ספציפיים): [קורס | אירוע | אומדן] נשמר ונחשב כברירת מחדל כפרטי. משמעות הדבר היא שרק אתה (ויוצר התוכן) יכולים לראות את התוכן הזה. כדי לפרסם תוכן זה אתה יכול לבחור אחת מהאפשרויות שלהלן. פרסום הקורס שלך לציבורי פירושו שהוא יהיה זמין לכל המשתמשים בפלטפורמה - כולל אלה שמחוץ לארגון שלך. פרסום זה לארגון פירושו שכל המשתמשים בארגון יוכלו לראות אותו. אתה יכול גם לפרסם אותו בקבוצה אחת או יותר, כלומר רק המשתמשים בקבוצות אלו יוכלו לראות את זה. לבסוף, יש לך אפשרות לפרסם אותו למשתמשים ספציפיים בתוך הארגון ומחוצה לו באמצעות כתובת הדוא"ל של חשבון Sharelook שלהם.',
    SpecificUsersDescription:
      'משתמשים עצמאיים ששולמו: (משתמשים פרטיים, ציבוריים וספציפיים) [קורס | אירוע | אומדן] נשמר ונחשב כברירת מחדל כפרטי. המשמעות היא שרק אתה יכול לראות את התוכן הזה. כדי לפרסם תוכן זה אתה יכול לבחור אחת מהאפשרויות שלהלן. פרסום הקורס שלך לציבורי פירושו שהוא יהיה זמין לכל המשתמשים בפלטפורמה. אפשרות המשתמשים הספציפיים תאפשר לך לשתף את הקורס שלך עם משתמשים ספציפיים באמצעות כתובת הדוא"ל המשויכת לחשבון Sharelook שלהם.'
  },

  quiz: {
    quiz_empty: 'עדיין לא יצרת בוחן',
    questions_empty: 'עדיין לא יצרת שאלה'
  },

  regions: {
    asia_pacific_australia: 'אוסטרליה',
    asia_pacific_india: 'הודו',
    asia_pacific_japan: 'יפן',
    asia_pacific_s_korea: 'דרום קוריאה',
    asia_pacific_singapore: 'סינגפור',
    asia_pacific_taiwan: 'טייוואן',
    eu_belgium: 'בלגיה',
    eu_germany: 'גרמניה',
    eu_ireland: 'אירלנד',
    south_america_brazil: 'ברזיל',
    us_central_iowa: 'איווה',
    us_east_s_carolina: 'דרום קרוליינה',
    us_east_virginia: " וירג'יניה' ",
    us_west_california: 'קליפורניה',
    us_west_oregon: 'אורגון'
  },

  reports: {
    title: 'דוחות',
    workshop_report: 'דוח הסדנה',
    user_report: 'דוח המשתמש',
    organization_workshop: 'סדנת הארגון',
    course: 'קורס',
    live_event: 'אירוע בשידור חי',
    workShop_graph: 'גרף הסדנת',
    organization_account: 'חשבון ארגון',
    all: 'הכל',
    name: 'שם',
    id_number: 'מספר מזהה',
    email: 'דוא"ל',
    search_user_report: 'חפש דוח משתמש',
    participant: 'משתתף',
    participants: 'משתתפים',
    host: 'מארח',
    username: 'שם משתמש',
    event: 'אירוע',
    quiz: 'בוחן',
    assessment: 'הערכה',
    time: 'זמן',
    no_recent_activity: 'אין פעילות לאחרונה',
    workshops: 'סדנאות',
    courses: 'קורסים',
    live_events: 'אירועים בשידור חי',
    live_assessments: 'הערכות בשידור חי',
    quizzes: 'בחינות',
    overview: 'סקירה כללית',
    by: 'על ידי',
    views: 'צפיות',
    status: 'סטטוס',
    poll: 'סקר',
    comment: 'תגובה',
    comments: 'תגובות',
    no_comments: 'אין תגובות',
    no_polls: 'אין סקרים',
    live_date: 'תאריך השידור חי',
    score: 'ציון',
    question: 'שאלה',
    submit: 'הגש',
    recent_activity: 'פעילות לאחרונה',
    ago: 'לפני',
    no_data_found: 'לא נמצאו נתונים',
    start_end_live: 'התחל / סיים שידור חי',
    join_leave_date: 'הצטרף / תעזוב',
    watched_time: 'זמן צפייה',
    complete: 'הושלם',
    in_complete: 'לא הושלם',
    today: 'היום',
    seven_days: '7 ימים',
    last_month: 'חודש האחרון',
    one_year: 'שנה אחת',
    all_time: 'כל הזמנים',
    report: 'דוח',
    from_date: 'מתאריך',
    to_date: 'לתאריך',
    all_workshop: 'כל הסדנה',
    all_participants: 'כל המשתתפים',
    live_assessment: 'הערכה בשידור חי',
    users: 'משתמשים',
    download: 'הורדה',
    recent: 'לאחרונה',
    WorkShop: 'סדנה',
    Activity: 'פעילות',
    created_date: 'תאריך שנוצר',
    lessons: 'שיעורים',
    lesson: 'שיעור',
    completed: 'הושלם',
    report_download: 'הורדת דוח',
    this_month: 'החודש',
    search_course: 'חיפוש קורס',
    participants_graph: 'גרף המשתתפים',
    viewed: 'נצפה',
    enrolled: 'נרשם',
    description: 'תיאור',
    topic: 'נושא',
    analytic: 'ניתוח',
    quiz_list: 'רשימת בחינות',
    select_one: 'בחר אחת',
    group_status: 'סטטוס הקבוצה',
    duration_min: 'משך זמן (דקות)',
    submit_poll: 'הגש את סקר',
    download_events: 'הורדת אירועים',
    search_event: 'חיפוש אירוע',
    duration: 'משך זמן',
    total_participants: 'סה”כ משתתפים',
    message: 'הודעה',
    avg_message: 'הודעה ממוצה',
    per_message: 'אחוז הודעות / אירוע'
  },

  signup: {
    success_step1:
      'שלחנו לך קוד אימות לכתובת הדוא"ל שסיפקת, אנא העתק את הקוד לשדה למטה כדי לאשר את הרשמתך.',
    success_step2: 'תודה. נרשמת בהצלחה.',
    privacy: `אני מסכים ל <1> תנאים וההגבלות</1> של שראלוק ו <3> מדיניות הפרטיות</3>`
  },

  stream: {
    emotion_impressive: 'מרשים',
    emotion_unimpressive: 'לא מרשים',
    message_not_started: 'בקרוב',
    message_running: 'שידור חי',
    message_processing:
      'אנו מעבדים כעת את הפעלת הווידיאו של הקלטת השידור החי. אנא חזור מאוחר יותר כדי לצפות בסרטון ולהמשיך להוסיף דירוגים ופידבקים'
  },

  tabs: {
    audience: 'קהל',
    course: 'סקירת הקורס',
    details: 'פרטי האירוע בשידור חי',
    description: 'תיאור',
    host: 'מארח',
    lesson: 'סקירת השיעור',
    lessons: 'שיעורים',
    overview: 'סקירה כללית',
    page: 'בונה עמוד',
    publish: 'פרסם',
    settings: 'הגדרות פרסום',
    total_views: 'סה"כ צפיות',
    live_participates: 'משתתפים לשידור החי',
    // live_chats: 'צ'אטים חיים',
    live_quiz: 'סקרים חיים',
    trainer_rating: 'דירוג מאמן',
    trainer_times: 'זמני המאמן',
    courses_quizzes: 'בחנות הקורסים',
    user_list: 'רשימת משתמשים',
    discussion: 'דיון',
    notes: 'ההערות שלי',
    attachments: 'מצורף נוסף'
  },

  topics: {
    SALES_TRAINING: 'הדרכת מכירות',
    NEGOTIATION_TRAINING: 'הדרכת משא ומתן',
    CROSS_CULTURAL_AWARENESS: 'מודעות חוצה תרבויות',
    NETWORKING_SKILLS: 'כישורי רשת',
    SOFTWARE_ARCHITECTURE: 'אדריכלות תוכנה',
    PROJECT_MANAGEMENT: 'ניהול פרויקטים',
    DIGITAL_TRANSFORMATION: 'טרנספורמציה דיגיטלית',
    IT_CONSULTING: 'ייעוץ IT',
    MANAGEMENT_CONSULTING: 'ייעוץ ניהולי',
    HR_CONSULTING: 'ייעוץ משאבי אנוש',
    INTERNET_OF_THINGS: 'האינטרנט של הדברים (IoT)',
    CYBERSECURITY: 'אבטחת סייבר',
    PRIVACY_AND_GDRP: 'פרטיות ו- GDPR'
  },

  training: {
    title: 'הדרכות',
    title_assessment: 'אומדנים',
    subtitle: 'קורסים ואירועים בשידור חי',
    enrolled_title: 'קורסים רשומים ואירועים בשידור חי',
    enrolled_subtitle: 'קורסים ואירועים בשידור חי שנרשמת אליהם',
    enrolled_message: 'כרגע אינך רשום לשום קורסים ואירועים.',
    past_live_event_title: 'אירועים ששודרו בעבר',
    past_live_event_subTitle: 'אירועים בשידור חי מוגמרים',
    past_live_event: 'אין כרגע אירועים ששודרו בשידור חי.',
    recomended_title: 'קורסים ואירועים מומלצים בשידור חי',
    now_live_event_title: 'עכשיו בשידור חי',
    now_live_event_subTitle: 'אירועים בשידור חי הרגע או שיהיו בקרוב מאוד',
    now_live_event: 'אין כרגע אירוע בשידור חי',
    webinar_event_title: 'ערוץ הוובינר',
    webinar_event_subTitle: 'וובינרים מוגמרים',
    webinar_event: 'כרגע אין וובינר.',
    p2p_event_title: 'פגישות הכשרה',
    p2p_event_subTitle: 'פגישת הכשרה מוגמרת',
    p2p_event: 'אין כרגע פגישת הכשרה.',

    now_live_assessment_title: 'עכשיו בשידור חי',
    now_live_assessment_subTitle: 'הערכה בשידור חי הרגע או שיהיה בקרוב מאוד',
    now_live_assessment_message: 'אין כרגע אומדן בשידור חי.',

    upcoming_assessment_title: 'ההערכות הקרובות בשידור חי',
    upcoming_assessment_subtitle: 'ההערכות הקרובות בשידור חי',
    upcoming_assessment_message: 'נכון לעכשיו אין הערכה בשידור חי בקרוב.',

    past_live_assessment_title: 'הערכות ששודרו בעבר',
    past_live_assessment_subTitle: 'הערכות ששודרו מוגמרים',
    past_live_assessment_message: 'אין כרגע הערכות ששודרו בשידור חי.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    /* recomended_subtitle:
  'ריכזנו קורסים ואירועים בשידור חי על בסיס <0><0/> </0>' שלך', */
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'חיפוש הדרכה',
    search_assessment: 'חיפוש הערכה',
    recomended_message: 'כרגע אין קורסים ואירועים מומלצים.'
  },

  warnings: {
    delete: 'האם אתה בטוח?'
  },

  workshop: {
    title: 'הסדנה שלי',
    subtitle: 'צור ותארח קורס או אירוע בשידור חי משלכם',
    new_course: 'צור קורס חדש',
    new_event: 'צור אירוע חדש בשידור חי',
    new_assessment: 'צור הערכה חדשה בשידור חי',
    courses_title: 'צור קורסים ואירועים בשידור חי',
    courses_subtitle: 'ערוך ופרסם את הקורס שנוצר ותכנן אירועים בשידור חי',
    tags_course: 'קורס',
    tags_live: 'אירוע בשידור חי',
    tags_assessment: 'הערכה בשידור חי'
  },

  help_center: {
    sidebar: {
      title: 'איך נוכל לעזור לך?',
      back_to: 'לוח מחוונים'
    },
    menu: {
      help_center: 'מרכז העזרה',
      whats_new: 'מה חדש',
      feedback: 'משוב',
      feedback_title: 'שלח משוב ל- sharelook',
      terms_and_conditions: 'תנאים והגבלות',
      privacy_policy: 'מדיניות פרטיות',
      contact_us: 'צור קשר'
    },
    get_started: {
      title: 'התחל להשתמש ב- sharelook',
      description: {
        main: 'שילבנו וידיאו בשידור חי לבונה קורסים קל לשימוש',
        tagline:
          'באמצעות מערכת השידור החיה המוגנת בפטנט של ShareLook, אתה יכול ליצור מעורבות, למידה אוטומטית, ולמקסם את שיתוף הידע.'
      }
    },
    whats_new: {
      title: 'התחל להשתמש ב- sharelook',
      description: {
        // main: 'האם ידעת שיצירת למידה בסגנון סיפור יכולה להיווצר תוך זמן קצר ולא כל כך יקר? עליך לשלב את בונה הקורסים של ShareLook ואת הכישרונות המתאימים. אתה יכול לשאול אותנו "איך",
        tagline: ''
      }
    },
    create_workshop: 'כיצד ליצור סדנה',
    create_course: 'כיצד ליצור קורס',
    create_user: 'כיצד ליצור משתמש',
    create_group: 'כיצד ליצור קבוצה',
    create_webinar: 'כיצד ליצור וובינר',
    create_live_event: 'כיצד ליצור אירוע בשידור חי',
    create_live_assessment: 'כיצד ליצור הערכה בשידור חי',
    create_live_on_mobile: 'צור שידור חי בנייד',
    personalize_profile: 'התאם אישית פרופיל',
    manage_group: 'נהל קבוצה',
    report: 'דווח',
    add_annotations: 'כיצד להוסיף הערות / חלונות קופצים',
    add_audio: 'כיצד להוסיף אודיו',
    add_header_and_footer: 'הוספת כותרת עליונה ותחתונה',
    add_image: 'כיצד להוסיף תמונה',
    add_quiz_content: 'כיצד להוסיף תוכן לבוחן',
    add_text_content: 'כיצד להוסיף תוכן טקסט',
    add_user: 'כיצד להוסיף משתמש',
    add_video: 'כיצד להוסיף וידאו',
    clone_course: 'כיצד לשכפל קורס',
    find_live_broadcast: 'כיצד למצוא שידור חי',
    contact_us: {
      title: 'צור קשר',
      subject_placeholder: 'הזן נושא*',
      content_placeholder: 'הזן הודעה*',
      subject_required: '*הנושא נדרש',
      content_required: '*הודעה נדרשת',
      submit: 'שלח',
      cancel: 'בטל',
      sent: 'שלח'
    },
    feedback: {
      title: 'שלח משוב ל- sharelook',
      attach_title: 'צרף קובץ',
      choose: 'בחר קובץ',
      detail: 'הקובץ ישותף עם צוות התמיכה',
      content_placeholder: 'הזן הודעה*',
      content_required: '*הודעה נדרשת',
      submit: 'שלח',
      cancel: 'בטל',
      sent: 'שלח'
    }
  },
  discussion: {
    join_discussion: 'הצטרף לדיון'
  },
  notes: {
    title: 'ההערות שלי',
    add_note: 'הוסף הערה חדשה'
  },
  groups: {
    search_group: 'קבוצת ארגון חיפוש'
  }
}
