import React, { Component } from 'react'

import { TagWrapper } from './styled'

class Tag extends Component {
  onClick = () => {
    const { onClick, value } = this.props

    if (onClick) {
      onClick(value)
    }
  }

  render () {
    const { text, active, disabled } = this.props

    return (
      <TagWrapper active={active} disabled={ disabled } onClick={this.onClick}>
        {text}
      </TagWrapper>
    )
  }
}

export default Tag
