import {
	CleanExternalString
} from './Utilities.js'

import {
	RemoveIndiciesFromCmiElement,
	ExtractIndex,
	ExtractSecondaryIndex,
	TranslateDualStausToSingleStatus,
	IsValidCMITimeSpan,
	IsValidCMITime,
	IsValidCMIDecimal,
	isCmiString4096,
	IsValidCMIIdentifier,
	isInteractionPatternValid,
	NormalizeRawScore,
	TranslateSingleStatusIntoCompletion,
	TranslateSingleStatusIntoSuccess,
	ConvertCmiTimeSpanToIso8601TimeSpan,
	ConvertCmiTimeToIso8601Time,
	ConvertIso8601TimeSpanToCmiTimeSpan
} from './ScormUtilities.js'

import {
	arySupportedElements,
	aryVocabularies,
	SCORM_STATUS_COMPLETED,
	SCORM_STATUS_FAILED,
	SCORM_STATUS_NOT_ATTEMPTED
} from './ScormSupportedElements.js'

const SCORM_TRUE = "true",
	SCORM_FALSE = "false",
	SCORM_ERROR_NONE = 0,
	SCORM_ERROR_GENERAL = 101,
	SCORM_ERROR_INVALID_ARG = 201,
	SCORM_ERROR_NO_CHILDREN = 202,
	SCORM_ERROR_NO_COUNT = 203,
	SCORM_ERROR_NOT_INITIALIZED = 301,
	SCORM_ERROR_NOT_IMPLEMENTED = 401,
	SCORM_ERROR_ELEMENT_IS_KEYWORD = 402,
	SCORM_ERROR_READ_ONLY = 403,
	SCORM_ERROR_WRITE_ONLY = 404,
	SCORM_ERROR_INCORRECT_DATA_TYPE = 405,
	SCORM_WRONG = "wrong",
	SCORM_INCORRECT = "incorrect";

const SCORM_CORE_CHILDREN =
	"student_id,student_name,lesson_location,credit,lesson_status,entry,total_time,lesson_mode,exit,session_time,score",
	SCORM_CORE_SCORE_CHILDREN = "raw,min,max",
	SCORM_STUDENT_DATA_CHILDREN =
		"mastery_score,max_time_allowed,time_limit_action",
	SCORM_STUDENT_PREFERENCE_CHILDREN = "audio,language,speed,text",
	SCORM_OBJECTIVES_CHILDREN = "id,score,status",
	SCORM_OBJECTIVES_SCORE_CHILDREN = "raw,min,max",
	SCORM_INTERACTIONS_CHILDREN =
		"id,objectives,time,type,correct_responses,weighting,student_response,result,latency";

let strBaseElement = ''

export class RunTimeAPI {

	constructor() {
		this.ErrorNumber = SCORM_ERROR_NONE
		this.ErrorString = ""
		this.ErrorDiagnostic = ""
		this.Initialized = false
		this.CmiCore = null //null
		this.ScormSco = null
		this.Activity = null

		console.log("Runtime api")
	}

	InitializeForDelivery(e){
  	this.ErrorNumber = SCORM_ERROR_NONE
    this.ErrorString = ""
    this.ErrorDiagnostic = ""
    this.Initialized = false
    this.CmiCore = e.cmiCore
		this.ScormSco = e.scormSco
		this.Activity = e
	}

	LMSInitialize(val) {
		console.log("Initialize")
		this.ClearErrorState()
		return this.CheckForInitializeError() ? (this.Initialized = true, SCORM_TRUE) : SCORM_FALSE
	}

	LMSFinish(val) {
		console.log("Finish")

		this.ClearErrorState()
		if (!this.CheckForFinishError()) {
			return SCORM_FALSE
		}
	}

	
	LMSGetValue(key) {
		console.log("lmsGetvalue " + key)

		this.ClearErrorState()
		key = CleanExternalString(key)

		const cmiKey = RemoveIndiciesFromCmiElement(key),
			firstIndex = ExtractIndex(key),
			secondIndex = ExtractSecondaryIndex(key);

		if (this.CheckForGetValueError(key, cmiKey, firstIndex, secondIndex)) {
			let a = this.RetrieveGetValueData(key, cmiKey, firstIndex, secondIndex)
			if (!a) {
				return ""
			} else {
				return a
			}
		} else {
			return ""
		}
	}

	LMSSetValue(key, value) {
		this.ClearErrorState()
		key = CleanExternalString(key)
		value = CleanExternalString(value)
		const cmiKey = RemoveIndiciesFromCmiElement(key),
			firstIndex = ExtractIndex(key),
			secondIndex = ExtractSecondaryIndex(key);

		return this.CheckForSetValueError(key, value, cmiKey, firstIndex, secondIndex)
			? (this.StoreValue(key, value, cmiKey, firstIndex, secondIndex), this.SetDirtyData(), SCORM_TRUE)
			: SCORM_FALSE

	}

	SetDirtyData(){
		this.Activity.setHasDirtyData()
	}

	LMSCommit(val) {

	}

	LMSGetDiagnostic() {

	}

	LMSGetErrorString() {

	}

	LMSGetLastError() {

	}


	RetrieveGetValueData(key, cmiKey, firstIndex, secondIndex) {
		var a = "", objective, interaction;
		switch (cmiKey) {
			case "cmi.core._children":
				a = SCORM_CORE_CHILDREN;
				break;
			case "cmi.core.student_id":
				a = this.CmiCore.student_id
				break;
			case "cmi.core.student_name":
				a = this.CmiCore.student_name
				break;
			case "cmi.core.lesson_location":
				a = this.CmiCore.lesson_location
				break;
			case "cmi.core.credit":
				a = this.CmiCore.credit;
				break;
			case "cmi.core.lesson_status":
				a = TranslateDualStausToSingleStatus(
					this.CmiCore.completion_status,
					this.CmiCore.success_status
				)
				break;
			case "cmi.core.entry":
				a = this.CmiCore.entry
				break;
			case "cmi.core.score._children":
				a = SCORM_CORE_SCORE_CHILDREN
				break;
			case "cmi.core.score.raw":
				a = this.CmiCore.score_raw
				break;
			case "cmi.core.score.max":
				a = this.CmiCore.score_max
				break;
			case "cmi.core.score.min":
				a = this.CmiCore.score_min
				break;
			case "cmi.core.total_time":
				a = ConvertIso8601TimeSpanToCmiTimeSpan(this.CmiCore.total_time)
				break;
			case "cmi.core.lesson_mode":
				a = this.CmiCore.lesson_mode
				break;
			case "cmi.suspend_data":
				a = this.CmiCore.suspend_data;
				break;
			case "cmi.launch_data":
				a = this.ScormSco.datafromlms
				break;
			case "cmi.objectives._children":
				a = SCORM_OBJECTIVES_CHILDREN
				break;
			case "cmi.objectives._count":
				a = this.CmiCore.objectiveCount()
				break;
			case "cmi.objectives.n.id":
				objective = this.CmiCore.findNObjective(firstIndex)
				if (!objective || !objective.n_id) {
					a = ""
				} else {
					a = objective.n_id
				}
				break;
			case "cmi.objectives.n.status":
				objective = this.CmiCore.findNObjective(firstIndex)
				if (!objective || !objective.completion_status || !objective.success_status) {
					a = ""
				} else {
					a = TranslateDualStausToSingleStatus(
						objective.completion_status,
						objective.success_status
					)
				}
				break;
			case "cmi.objectives.n.score._children":
				a = SCORM_OBJECTIVES_SCORE_CHILDREN;
				break;
			case "cmi.objectives.n.score.raw":
				objective = this.CmiCore.findNObjective(firstIndex)
				if (!objective || !objective.score_raw) {
					a = ""
				} else {
					a = objective.score_raw
				}
				break;
			case "cmi.objectives.n.score.max":
				objective = this.CmiCore.findNObjective(firstIndex)
				if (!objective || !objective.score_max) {
					a = ""
				} else {
					a = objective.score_max
				}
				break;
			case "cmi.objectives.n.score.min":
				objective = this.CmiCore.findNObjective(firstIndex)
				if (!objective || !objective.score_min) {
					a = ""
				} else {
					a = objective.score_min
				}
				break;
			case "cmi.comments":
				a = !this.CmiCore.cmi_comments ? "" : this.CmiCore.cmi_comments
				break;
			case "cmi.comments_from_lms":
				a = !this.CmiCore.cmi_comments_from_lms ? "" : this.CmiCore.cmi_comments_from_lms
				break;
			case "cmi.student_data._children":
				a = SCORM_STUDENT_DATA_CHILDREN
				break;
			case "cmi.student_data.mastery_score":
				a = !this.ScormSco.mastery_score ? "" : this.ScormSco.mastery_score;
				break;
			case "cmi.student_data.max_time_allowed":
				a = !this.ScormSco.max_time_allowed ? "" : this.ScormSco.max_time_allowed;
				break;
			case "cmi.student_data.time_limit_action":
				a = !this.ScormSco.time_limit_action ? "" : this.ScormSco.time_limit_action;
				break;
			case "cmi.student_preference._children":
				a = SCORM_STUDENT_PREFERENCE_CHILDREN
				break;
			case "cmi.student_preference.audio":
				a = "" // not done for now
				break;
			case "cmi.student_preference.language":
				a = "" // not done for now
				break;
			case "cmi.student_preference.speed":
				a = ""
				break;
			case "cmi.student_preference.text":
				a = ""
				break;
			case "cmi.interactions._children":
				a = SCORM_INTERACTIONS_CHILDREN;
				break;
			case "cmi.interactions._count":
				a = this.CmiCore.interactionCount()
				break;
			case "cmi.interactions.n.objectives._count":
				interaction = this.CmiCore.findNInteraction(firstIndex)
				if (!interaction) {
					a = 0
				} else {
					a = interaction.objectiveCount()
				}
				break;
			case "cmi.interactions.n.correct_responses._count":
				interaction = this.CmiCore.findNInteraction(firstIndex)
				if (!interaction) {
					a = 0
				} else {
					a = interaction.correctResponseCount()
				}
				break;
			case "cmi._version":
				// 	(a = SCORM_CMI_VERSION);
				a = ''
				break;
			default:
				this.SetErrorState(
					SCORM_ERROR_GENERAL,
					"Getting the data element you requested is not supported although it is listed as being supported, please contact technical support.  Element-"
					// strElement
				)
				a = ""
		}
		return a;
	}

	StoreValue(key, value, cmiKey, firstIndex, secondIndex) {
		this.WriteDetailedLog(
			"StoreValue (" + key + ", " + value + ", " + cmiKey + ", " + firstIndex + ", " + secondIndex + ") "
		)
		let objective, interaction
		switch (cmiKey) {
			case "cmi.core.lesson_location":
				this.WriteDetailedLog("Element is: lesson_location")
				this.CmiCore.lesson_location = value
				break;
			case "cmi.core.lesson_status":
				this.WriteDetailedLog("Element is: lesson_status")
				if (((this.CmiCore.success_status === TranslateSingleStatusIntoSuccess(value) &&
					this.CmiCore.completion_status === TranslateSingleStatusIntoCompletion(value)) ||
					this.CmiCore.completion_status !==SCORM_STATUS_COMPLETED ||
					(this.CmiCore.completion_status === SCORM_STATUS_COMPLETED &&
						this.CmiCore.success_status === SCORM_STATUS_FAILED) ||
					"passed" === value)) {
					this.WriteDetailedLog("Allowing status change")
					this.CmiCore.success_status = TranslateSingleStatusIntoSuccess(value)
					this.CmiCore.completion_status = TranslateSingleStatusIntoCompletion(value)
					this.CmiCore.lesson_status = value
				}
				break;
			case "cmi.core.exit":
				this.WriteDetailedLog("Element is: exit")
				this.CmiCore.exit = value
				break;
			case "cmi.core.session_time":
				this.WriteDetailedLog("Element is: session time")
				this.CmiCore.session_time = ConvertCmiTimeSpanToIso8601TimeSpan(value)
				break;
			case "cmi.core.score.raw":
				this.WriteDetailedLog("Element is: score.raw")
				if ("" === value) {
					value = null
				}
				this.CmiCore.score_raw = value
				this.CmiCore.score_scaled = NormalizeRawScore(
					this.CmiCore.score_raw,
					this.CmiCore.score_min,
					this.CmiCore.score_max
				);
				break;
			case "cmi.core.score.max":
				this.WriteDetailedLog("Element is: score.max")
				"" === value && (value = null)
				this.CmiCore.score_max = value
				break;
			case "cmi.core.score.min":
				this.WriteDetailedLog("Element is: score.min")
				"" === value && (value = null)
				this.CmiCore.score_min = value
				break;
			case "cmi.suspend_data":
				this.WriteDetailedLog("Element is: suspend data")
				this.CmiCore.suspend_data = value
				break;
			case "cmi.objectives.n.id":
				this.WriteDetailedLog("Element is: objectives.id")
				if (this.CmiCore.objectiveCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new objective at index " + firstIndex)
					this.CmiCore.AddObjective()
				}
				this.CmiCore.findNObjective(firstIndex).n_id = value
				break;
			case "cmi.objectives.n.status":
				this.WriteDetailedLog("Element is: objectives.status")
				if (this.CmiCore.objectiveCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new objective at index " + firstIndex)
					this.CmiCore.AddObjective()
				}
				objective = this.CmiCore.findNObjective(firstIndex)
				objective.success_status = TranslateSingleStatusIntoSuccess(value)
				objective.completion_status = TranslateSingleStatusIntoCompletion(value)
				break;
			case "cmi.objectives.n.score.raw":
				this.WriteDetailedLog("Element is: objectives.score.raw")
				if (this.CmiCore.objectiveCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new objective at index " + firstIndex)
					this.CmiCore.AddObjective()
				}
				"" === value && (value = null)
				this.CmiCore.findNObjective(firstIndex).score_raw = value
				break;
			case "cmi.objectives.n.score.min":
				this.WriteDetailedLog("Element is: objectives.score.min")
				if (this.CmiCore.objectiveCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new objective at index " + firstIndex)
					this.CmiCore.AddObjective()
				}
				"" === value && (value = null)
				this.CmiCore.findNObjective(firstIndex).score_min = value
				break;
			case "cmi.objectives.n.score.max":
				this.WriteDetailedLog("Element is: objectives.score.max")

				this.WriteDetailedLog("Element is: objectives.score.min")
				if (this.CmiCore.objectiveCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new objective at index " + firstIndex)
					this.CmiCore.AddObjective()
				}
				"" === value && (value = null)
				this.CmiCore.findNObjective(firstIndex).score_max = value
				break;
			case "cmi.comments":
				this.WriteDetailedLog(
					"Element is: comments length of " +
					this.CmiCore.cmi_comments.length
				);
				this.CmiCore.cmi_comments = this.CmiCore.cmi_comments + " " + value
				break;
			case "cmi.student_preference.audio":
				this.WriteDetailedLog("Element is: audio")
				// not handled
				break;
			case "cmi.student_preference.language":
				this.WriteDetailedLog("Element is: language")
				// not handled
				break;
			case "cmi.student_preference.speed":
				this.WriteDetailedLog("Element is: speed")
				// not handled
				break;
			case "cmi.student_preference.text":
				this.WriteDetailedLog("Element is: text")
				// not handled
				break;
			case "cmi.interactions.n.id":
				this.WriteDetailedLog("Element is: interactions.id")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				this.CmiCore.findNInteraction(firstIndex).n_id = value
				break;
			case "cmi.interactions.n.objectives.n.id":
				this.WriteDetailedLog("Element is: interactions.objectives.id")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				interaction = this.CmiCore.findNInteraction(firstIndex)
				if (interaction.objectiveCount() <= secondIndex) {
					this.WriteDetailedLog("Adding new interaction objective at index " + firstIndex)
					interaction.AddObjective()
				}
				interaction.findNObjective(secondIndex).objective_id = value
				break;
			case "cmi.interactions.n.time":
				this.WriteDetailedLog("Element is: interactions.time")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				const u = ConvertCmiTimeToIso8601Time(value)
				this.CmiCore.findNInteraction(firstIndex).timestamp = u
				break;
			case "cmi.interactions.n.type":
				this.WriteDetailedLog("Element is: interacitons.type")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				this.CmiCore.findNInteraction(firstIndex).type = value
				break;
			case "cmi.interactions.n.correct_responses.n.pattern":
				this.WriteDetailedLog(
					"Element is: interactions.correct_responses.pattern"
				)

				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}

				interaction = this.CmiCore.findNInteraction(firstIndex)
				if (interaction.correctResponseCount() <= secondIndex) {
					this.WriteDetailedLog("Adding new interaction objective at index " + firstIndex)
					interaction.AddCorrectResponse()
				}
				interaction.findNCorrectResponse(secondIndex).pattern = value
				break;
			case "cmi.interactions.n.weighting":
				this.WriteDetailedLog("Element is: interactions.weighting")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				this.CmiCore.findNInteraction(firstIndex).weighting = value
				break;
			case "cmi.interactions.n.student_response":
				this.WriteDetailedLog("Element is: interactions.student_response")

				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				this.CmiCore.findNInteraction(firstIndex).student_response = value
				break;
			case "cmi.interactions.n.result":
				this.WriteDetailedLog("Element is: interactions.result")

				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}

				value === SCORM_WRONG && (value = SCORM_INCORRECT)
				this.CmiCore.findNInteraction(firstIndex).result = value
				break;
			case "cmi.interactions.n.latency":
				this.WriteDetailedLog("Element is: interactions.latency")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}

				this.CmiCore.findNInteraction(firstIndex).latency = value
				break;
			case "cmi.interactions.n.text":
				this.WriteDetailedLog("Element is: interactions.text")
				if (this.CmiCore.interactionCount() <= firstIndex) {
					this.WriteDetailedLog("Adding new interaction at index " + firstIndex)
					this.CmiCore.AddInteraction()
				}
				this.CmiCore.findNInteraction(firstIndex).description = value
				break;
			default:
				return (
					console.log("Unrecognized data model element in StoreData"),
					this.SetErrorState(
						SCORM_ERROR_GENERAL,
						"Setting the data element you requested is not supported although it is listed as being supported, please contact technical support.  Element-"
						// strElement
					),
					false
				);
		}
		return true;
	}


	CheckForGetValueError(key, cmiKey, fistIndex, secondIndex) {
		if (!this.Initialized) {
			return (
				this.SetErrorState(
					SCORM_ERROR_NOT_INITIALIZED,
					"GetValue called when not initialized. element-" + key
				),
				false
			)
		}

		if (arySupportedElements[cmiKey] && null !== arySupportedElements[cmiKey]) {
			strBaseElement = cmiKey
			if (arySupportedElements[cmiKey].Supported) {
				if (arySupportedElements[cmiKey].SupportsRead) {
					return true
				} else {
					this.SetErrorState(
						SCORM_ERROR_WRITE_ONLY,
						"The parameter '" + key + "' is write-only."
					)
					return false
				}
			} else {
				this.SetErrorState(
					SCORM_ERROR_NOT_IMPLEMENTED,
					"The parameter '" + key + "' is not implemented."
				)
				return false
			}
		}

		if (0 < cmiKey.search(/._children$/)) {
			if (((strBaseElement = cmiKey.replace("._children", "")),
				arySupportedElements[strBaseElement] &&
				arySupportedElements[key] !== null)
			) {
				return (
					this.SetErrorState(
						SCORM_ERROR_NO_CHILDREN,
						"The parameter '" + key + "' does not support the _children keyword."
					),
					false
				);
			}
		} else if (0 < cmiKey.search(/._count$/) &&
			((strBaseElement = cmiKey.replace("._count", "")),
				void 0 !== arySupportedElements[strBaseElement] &&
				arySupportedElements[key] !== null)
		) return (
			this.SetErrorState(
				SCORM_ERROR_NO_COUNT,
				"The parameter '" + key + "' does not support the _count keyword."
			),
			false
		);

		this.SetErrorState(
			SCORM_ERROR_INVALID_ARG,
			"The parameter '" + key + "' is not recognized."
		)
		return false
	}


	CheckForSetValueError(key, value, cmiKey, firstIndex, secondIndex) {
		if (!this.Initialized)
			return (
				this.SetErrorState(
					SCORM_ERROR_NOT_INITIALIZED,
					"SetValue called when not initialized. strElement-" +
					key +
					" strValue-" +
					value
				),
				false
			);

		if (!arySupportedElements[cmiKey]) {
			if (0 < cmiKey.search(/._children$/)) {
				if (
					((cmiKey = cmiKey.replace("._children", "")),
						arySupportedElements[cmiKey])
				)
					return (
						this.SetErrorState(
							SCORM_ERROR_NO_CHILDREN,
							"The parameter '" + cmiKey + "' does not support the _children keyword."
						),
						false
					);
			} else if (
				0 < cmiKey.search(/._count$/) &&
				((cmiKey = cmiKey.replace("._count", "")),
					arySupportedElements[strBaseElement])
			)
				return (
					this.SetErrorState(
						SCORM_ERROR_NO_COUNT,
						"The parameter '" + cmiKey + "' does not support the _count keyword."
					),
					false
				);
			return (
				this.SetErrorState(
					SCORM_ERROR_INVALID_ARG,
					"The parameter '" + key + "' is not recognized."
				),
				false
			);
		}


		if (!arySupportedElements[cmiKey].Supported)
			return (
				this.SetErrorState(
					SCORM_ERROR_NOT_IMPLEMENTED,
					"The parameter '" + key + "' is not implemented."
				),
				false
			);

		if (0 < cmiKey.search(/_children$/) || 0 < cmiKey.search(/_count$/))
			return (
				this.SetErrorState(
					SCORM_ERROR_ELEMENT_IS_KEYWORD,
					"The parameter '" + key + "' is a keyword and cannot be written to."
				),
				false
			);

		if (!arySupportedElements[cmiKey].SupportsWrite)
			return (
				this.SetErrorState(
					SCORM_ERROR_READ_ONLY,
					"The parameter '" + key + "' is read-only."
				),
				false
			);

		let n = true;

		switch (cmiKey) {
			case "cmi.core.lesson_location":
				if (value.length > 255) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.core.lesson_location may not be greater than 255 characters, your value (" +
						value +
						") is " +
						value.length +
						" characters."
					)
					n = false
				}
				break;
			case "cmi.core.lesson_status":
				if (!this.IsValidVocabElement(value, "status")) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value for cmi.core.lesson_status is not in the CMI vocabulary. Your value: " +
						value
					)
					n = false
				}
				if (value === SCORM_STATUS_NOT_ATTEMPTED) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.core.lesson_status cannot be set to 'not attempted'.  This value may only be set by the LMS upon initialization."
					)
					n = false
				}
				break;
			case "cmi.core.exit":
				if (!this.IsValidVocabElement(value, "exit")) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value for cmi.core.exit is not in the CMI vocabulary. Your value: " +
						value
					)
					n = false
				}
				break;
			case "cmi.core.session_time":
				if (!IsValidCMITimeSpan(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value for cmi.core.session_time is not formatted properly. Your value: " +
						value
					)
					n = false
				}
				break;
			case "cmi.core.score.raw":
				this.WriteDetailedLog("Element is: score.raw")

				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.core.score.raw must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					}
					else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.core.score.raw must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.core.score.max":
				this.WriteDetailedLog("Element is: score.max")

				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.core.score.max must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					} else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.core.score.max must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.core.score.min":
				this.WriteDetailedLog("Element is: score.min")


				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.core.score.min must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					} else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.core.score.min must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.suspend_data":
				this.WriteDetailedLog("Element is: suspend_data")
				if (!isCmiString4096(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.core.suspend_data may not be greater than " +
						// Control.Package.Properties.SuspendDataMaxLength +
						" characters, your value is " +
						value.length +
						" characters. Your value\n" +
						value
					)
					n = false
				}
				break;
			case "cmi.objectives.n.id":
				this.WriteDetailedLog("Element is: objectives.id")

				if (!IsValidCMIIdentifier(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value '" + value + "' is not a valid CMI Identifier"
					)
					n = false
				}
				if (!this.CmiCore.IsValidObjectiveIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, objective indicies must be set sequentially starting with 0"
					)
					n = false
				}
				break;
			case "cmi.objectives.n.status":
				this.WriteDetailedLog("Element is: objectives.status")
				if (!this.CmiCore.IsValidObjectiveIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, objective indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!this.IsValidVocabElement(value, "status")) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value for cmi.objectives.n.status is not in the CMI vocabulary. Your value: " +
						value
					)
					n = false
				}
				break;
			case "cmi.objectives.n.score.raw":
				this.WriteDetailedLog("Element is: objectives.score.raw")
				if (!this.CmiCore.IsValidObjectiveIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, objective indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.objectives.n.score.raw must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					} else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.objectives.n.score.raw must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.objectives.n.score.min":
				this.WriteDetailedLog("Element is: objectives.score.min")
				if (!this.CmiCore.IsValidObjectiveIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, objective indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.objectives.n.score.min must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					} else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.objectives.n.score.min must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.objectives.n.score.max":
				this.WriteDetailedLog("Element is: objectives.score.max")
				if (!this.CmiCore.IsValidObjectiveIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, objective indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (value !== "") {
					if (IsValidCMIDecimal(value)) {
						const s = parseFloat(value)
						if (s < 0 || s > 100) {
							this.SetErrorState(
								SCORM_ERROR_INCORRECT_DATA_TYPE,
								"cmi.objectives.n.score.max must be a valid decimal between 0 and 100.  Your value is: " +
								s
							)
							n = false
						}
					} else {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.objectives.n.score.max must be a valid decimal.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.comments":
				this.WriteDetailedLog("Element is: comments")
				if (!isCmiString4096(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.comments may not be greater than 4096 characters, your value (" +
						value +
						") is " +
						value.length +
						" characters."
					)
					n = false
				}
				break;
			case "cmi.student_preference.audio":
				this.WriteDetailedLog("Element is: audio")
				//not implemented
				break;
			case "cmi.student_preference.language":
				this.WriteDetailedLog("Element is: language")
				//not implemented
				break;
			case "cmi.student_preference.speed":
				this.WriteDetailedLog("Element is: speed")
				//not implemented
				break;
			case "cmi.student_preference.text":
				this.WriteDetailedLog("Element is: text")
				//not implemented
				break;
			case "cmi.interactions.n.id":
				this.WriteDetailedLog("Element is: interactions.id")
				if (!IsValidCMIIdentifier(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value '" + value + "' is not a valid CMI Identifier"
					)
					n = false
				}
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}
				break;
			case "cmi.interactions.n.objectives.n.id":
				this.WriteDetailedLog("Element is: interactions.objectives.id")

				if (!IsValidCMIIdentifier(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value '" + value + "' is not a valid CMI Identifier"
					)
					n = false
				}

				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!this.CmiCore.IsValidInteractionObjectiveIndex(firstIndex, secondIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						secondIndex +
						"' is not valid, interaction objective indicies must be set sequentially starting with 0"
					)
					n = false
				}
				break;
			case "cmi.interactions.n.time":
				this.WriteDetailedLog("Element is: interactions.time")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!IsValidCMITime(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.interactions.n.time must be a valid time.  Your value is: " + value
					)
					n = false
				}
				break;
			case "cmi.interactions.n.type":
				this.WriteDetailedLog("Element is: interacitons.type")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!this.IsValidVocabElement(value, "interaction")) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"The value for cmi.interactions.n.type is not in the CMI vocabulary. Your value: " +
						value
					)
					n = false
				}
				break;
			case "cmi.interactions.n.correct_responses.n.pattern":
				this.WriteDetailedLog(
					"Element is: interactions.correct responses.pattern"
				)

				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!this.CmiCore.IsValidInteractionCorrectResponseIndex(firstIndex, secondIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						secondIndex +
						"' is not valid, interaction correct response indicies must be set sequentially starting with 0"
					)
					n = false
				}

				const interaction = this.CmiCore.findNInteraction(firstIndex)
				if (interaction) {
					if (!isInteractionPatternValid(interaction.type, value)) {
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"cmi.interactions.n.correct_responses.n.pattern must be a valid CMIFeedback - value must be consistent with interaction type.  Your value is: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.interactions.n.weighting":
				this.WriteDetailedLog("Element is: interactions.weighting")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!IsValidCMIDecimal(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.interactions.n.weighting must be a valid decimal.  Your value is: " +
						value
					)
					n = false
				}
				break;
			case "cmi.interactions.n.student_response":
				this.WriteDetailedLog("Element is: interactions.student_response")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}
				break;
			case "cmi.interactions.n.result":
				this.WriteDetailedLog("Element is: interactions.result")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!this.IsValidVocabElement(value, "result")) {
					if(!IsValidCMIDecimal(value)){
						this.SetErrorState(
							SCORM_ERROR_INCORRECT_DATA_TYPE,
							"The value for cmi.interactions.n.result is not in the CMI vocabulary. Your value: " +
							value
						)
						n = false
					}
				}
				break;
			case "cmi.interactions.n.latency":
				this.WriteDetailedLog("Element is: interactions.latency")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (!IsValidCMITimeSpan(value)) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.interactions.n.latency must be a valid timespan.  Your value is: " +
						value
					)
					n = false
				}
				break;
			case "cmi.interactions.n.text":
				this.WriteDetailedLog("Element is: interactions.text")
				if (!this.CmiCore.IsValidInteractionIndex(firstIndex)) {
					this.SetErrorState(
						SCORM_ERROR_INVALID_ARG,
						"The index '" +
						firstIndex +
						"' is not valid, interaction indicies must be set sequentially starting with 0"
					)
					n = false
				}

				if (value.length > 500) {
					this.SetErrorState(
						SCORM_ERROR_INCORRECT_DATA_TYPE,
						"cmi.interactions.n.text may not be greater than 500 characters, your value is " +
						value.length +
						" characters. Your value\n" +
						value
					)
					n = false
				}
				break;
			default:
				this.WriteDetailedLog("Element Not Matched")
				this.SetErrorState(
					SCORM_ERROR_GENERAL,
					"Setting the data element you requested is not supported although it is listed as being supported, please contact technical support.  Element-" +
					key
				)
				n = false
		}

		if (n) {
			this.WriteDetailedLog("Call is error free.")
		}
		return n
	}

	ClearErrorState() {
		this.ErrorNumber = SCORM_ERROR_NONE
		this.ErrorDiagnostic = ""
	}

	CheckForInitializeError(e) {
		if (e && e !== "") {
			this.SetErrorState(
				SCORM_ERROR_INVALID_ARG,
				"Invalid argument to LMSInitialize (arg=" + e + ")"
			)
			return false
		} else if (this.Initialized) {
			this.SetErrorState(SCORM_ERROR_GENERAL, "LMSInitialize has already been called.")
			return false
		}
		return true
	}

	CheckForFinishError(e) {
		if (!this.Initialized) {
			this.SetErrorState(
				SCORM_ERROR_NOT_INITIALIZED,
				"Finished called when not initialized."
			)
			return false
		} else if (e !== "") {
			this.SetErrorState(
				SCORM_ERROR_INVALID_ARG,
				"Invalid argument to LMSFinish (arg=" + e + ")"
			)
			return false
		}
		return true
	}

	SetErrorState(e, t) {
		debugger
		this.ErrorNumber = e
		this.ErrorDiagnostic = t
	}

	IsValidVocabElement(e, t) {
		var i, r = aryVocabularies[t];
		if (null === r) return !1;
		for (i = 0; i < r.length; i++) if (r[i] === e) return true;
		return false;
	}

	WriteDetailedLog(msg) {
		console.log(msg)
	}
}
