import React, { Component, createRef } from 'react'
import { Modal, Button } from 'antd'
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import '../ImageEditor/ImageEditor.scss'
import { general } from '../../constants'

const icona = require('tui-image-editor/dist/svg/icon-a.svg')
const iconb = require('tui-image-editor/dist/svg/icon-b.svg')
const iconc = require('tui-image-editor/dist/svg/icon-c.svg')
const icond = require('tui-image-editor/dist/svg/icon-d.svg')

const myTheme = {
  'menu.backgroundColor': 'white',
  'common.backgroundColor': '#151515',
  'downloadButton.backgroundColor': 'white',
  'downloadButton.borderColor': 'white',
  'downloadButton.color': 'black',
  'menu.normalIcon.path': icond,
  'menu.activeIcon.path': iconb,
  'menu.disabledIcon.path': icona,
  'menu.hoverIcon.path': iconc
}

export default class TextOnImage extends Component {
  imageEditor = createRef()
  imageUrl = this.props.cropImage ? this.props.cropImage : ''

  saveImage = () => {
    const imageEditorInst = this.imageEditor.current.imageEditorInst
    const data = imageEditorInst.toDataURL()
    this.props.handleSaveChanges(data)

    console.log('image data: ', data)
    // console.log('image file: ', file)
  }

  render() {
    const { handleCloseTextModal } = this.props
    return (
      <Modal
        className="custom-ant-modal"
        title="Add Text"
        visible={true}
        onCancel={handleCloseTextModal}
        width="65%"
        footer={[
          <Button
            type="default"
            shape="round"
            size="default"
            className="ant-btn rounded"
            onClick={handleCloseTextModal}
            key="cancel"
          >
            {general.CANCEL}
          </Button>,
          <Button
            type="primary"
            shape="round"
            size="default"
            className="ant-btn rounded ant-btn-primary"
            onClick={() => {
              this.saveImage()
            }}
            key="insert"
          >
            {general.INSERT}
          </Button>
        ]}
      >
        <div className="el-editor">
          <div className="el-editor__component">
            <ImageEditor
              includeUI={{
                loadImage: {
                  path: this.imageUrl,
                  name: 'image'
                },
                theme: myTheme,
                menu: ['text'],
                initMenu: '',
                uiSize: {
                  height: `calc(100vh - 160px)`
                },
                menuBarPosition: 'bottom'
              }}
              cssMaxHeight={window.innerHeight}
              cssMaxWidth={window.innerWidth}
              selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70
              }}
              usageStatistics={false}
              ref={this.imageEditor}
            />
          </div>
        </div>
      </Modal>
    )
  }
}
