import React, { Component } from 'react'
import {
  Icon,
  Row,
  Col,
  Avatar,
  Divider,
  Collapse,
  Typography,
  Radio
} from 'antd'
import { CustomCheckbox, CustomAvatar } from '../../../../../../styled.js'
import { withTranslation } from 'react-i18next'
import { Spinner } from '../../../../../../../../components/index'
import _ from 'lodash'
import '../../../../style.css'
import { report } from '../../../../../../../../constants'

const { Panel } = Collapse
const { Text } = Typography
class QuizByUser extends Component {
  state = {
    showQuiz: false,
    user: 0
  }
  select = (e, i) => {
    e.preventDefault()
    this.setState({
      user: i
    })
  }
  changeShowQuizState(quiz) {
    this.setState({
      showQuiz: true
    })
  }
  questionByUser(question) {
    switch (question.type) {
      case 'matrix':
        return (
          <Row gutter={20} style={{ padding: 20 }}>
            {question.quiz_answer.map(x => {
              for (let userAnswer of question.answer) {
                if (x.criterion === userAnswer.criterion) {
                  if (userAnswer.correct) {
                    return (
                      <Col span={12} style={{ marginTop: 20 }}>
                        {x.content ? (
                          <img
                            style={{ height: 100, width: 150 }}
                            alt={userAnswer.element}
                            src={x.content}
                          />
                        ) : (
                          <img
                            style={{ height: 100, width: 150 }}
                            alt={userAnswer.element}
                            src="/images/Rectangle 861.1.png"
                          />
                        )}
                        <span
                          style={{
                            margin: 20,
                            padding: 20,
                            border: '1px solid green',
                            color: 'green'
                          }}
                        >
                          {userAnswer.element}
                        </span>
                      </Col>
                    )
                  } else {
                    return (
                      <Col span={12} style={{ marginTop: 20 }}>
                        {x.content ? (
                          <img
                            style={{ height: 100, width: 150 }}
                            alt={userAnswer.element}
                            src={x.content}
                          />
                        ) : (
                          <img
                            style={{ height: 100, width: 150 }}
                            alt={userAnswer.element}
                            src="/images/Rectangle 861.1.png"
                          />
                        )}
                        <span
                          style={{
                            margin: 20,
                            padding: 20,
                            border: '1px solid red',
                            color: 'red'
                          }}
                        >
                          {userAnswer.element}
                        </span>
                      </Col>
                    )
                  }
                }
              }
              // Return a default value if none of the conditions are met
              return <></>
            })}
          </Row>
        )

      case 'multiple':
        return question.quiz_answer.map((x, index) => {
          let status = 3
          for (let userAnswer of question.answer)
            if (x.answer === userAnswer.answer)
              if (userAnswer.correct) status = 1
              else status = 2

          if (status === 1) {
            return (
              <span>
                <CustomCheckbox disabled backgroundColor="green" defaultChecked>
                  {x.answer}
                </CustomCheckbox>
                <br />
              </span>
            )
          } else if (status === 2) {
            return (
              <span>
                <CustomCheckbox disabled backgroundColor="red" defaultChecked>
                  {x.answer}
                </CustomCheckbox>
                <br />
              </span>
            )
          } else {
            if (x.correct) {
              return (
                <span>
                  <CustomCheckbox disabled backgroundColor="green">
                    {x.answer}
                  </CustomCheckbox>
                  <br />
                </span>
              )
            } else {
              return (
                <span>
                  <CustomCheckbox disabled backgroundColor="black">
                    {x.answer}
                  </CustomCheckbox>
                  <br />
                </span>
              )
            }
          }
        })

      case 'sorting':
        return (
          <div>
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => <Icon type="dash" rotate="90" />}
            >
              {question.answer.map((x, index) => {
                let color = { color: '#FF0000' }
                if (x.correct) color = { color: '#219653' }
                //else color={color:'#FF0000'}
                return (
                  <Panel
                    header={<div style={color}>{x.answer}</div>}
                    key={index}
                  ></Panel>
                )
              })}
            </Collapse>
          </div>
        )
      case 'free':
        return (
          <div>
            {question.answer.length > 0 &&
              question.answer.map(ans => {
                if (ans.answer !== '') {
                  if (ans.correct) {
                    return <div className="text-green">{ans.answer}</div>
                  } else {
                    return <div className="text-red">{ans.answer}</div>
                  }
                } else {
                  return (
                    <div
                      style={{
                        textDecoration: 'line-through',
                        background: 'rgba(235,235,235,0.9)'
                      }}
                    >
                      {report.ANSWER_NOT_AVAILABLE}
                    </div>
                  )
                }
              })}
          </div>
        )
      case 'single':
        return question.quiz_answer.map(x => {
          let userSelected = false
          for (let userAnswer of question.answer) {
            if (x.answer === userAnswer.answer) {
              userSelected = true
            }
          }

          return (
            <Radio defaultChecked={userSelected} disabled>
              <span className={`${x.correct ? 'text-blue' : 'text-red'}`}>
                {' '}
                {x.answer}
              </span>
            </Radio>
          )
        })
      default:
        return
    }
  }
  componentDidMount() {
    const { id } = this.props
    this.props.getQuizReport(id, 'byUser')
  }
  render() {
    const { fetch } = this.props.data.LessonQuiz
    const {
      data: { users = [], questions_count }
    } = this.props.data.byUser
    const { t } = this.props

    return fetch ? (
      <Spinner />
    ) : (
      <div style={{ backgroundColor: '#fff', margin: '0 20px' }}>
        {users.map((x, index) => {
          return (
            <CustomAvatar
              size={40}
              active={() => {
                if (index === this.state.user) {
                  return '#2F80ED'
                }
              }}
              onClick={e => this.select(e, index)}
              src={x.avatar.link}
            />
          )
        })}
        <Divider />
        {users.map((x, index) => {
          if (index === this.state.user) {
            const progressData = x.user_answer.reduce((acc, item) => {
              const m = item.answer.reduce((ac, answer) => {
                if (answer.correct === true && ac) {
                  return (ac = true)
                } else return (ac = false)
              }, [])
              if (m === true)
                if (acc['correct']) acc['correct']++
                else acc['correct'] = 1
              else if (acc['wrong'] !== null) acc['wrong']++
              else acc['wrong'] = 1
              return acc
            }, [])

            return (
              <div>
                <Row>
                  <Col lg={2} md={2}>
                    <Avatar size={48} alt={x.username} src={x.avatar.link} />
                  </Col>
                  <Col lg={5} md={6}>
                    <div className="username">{x.username}</div>
                    <Text>
                      {t('v2:submitted')} {_(x.user_answer).first().date}
                    </Text>
                  </Col>
                  <Col lg={17} md={16}>
                    <div className="progress">
                      {progressData.correct && (
                        <div
                          style={{
                            width:
                              (
                                (progressData.correct * 100) /
                                questions_count
                              ).toFixed(2) + '%',
                            color: '#1DBA61'
                          }}
                        >
                          <div>
                            {progressData.correct} {t('labels:correct')}{' '}
                          </div>
                          <div
                            className="progress-bar"
                            style={{
                              width: '100%',
                              height: '1.5rem',
                              backgroundColor: '#1DBA61'
                            }}
                          ></div>
                        </div>
                      )}
                      {progressData.wrong && (
                        <div
                          style={{
                            width:
                              (
                                (progressData.wrong * 100) /
                                questions_count
                              ).toFixed(2) + '%',
                            color: '#FF7777'
                          }}
                        >
                          <div>
                            {progressData.wrong} {t('v2:wrong')}
                          </div>
                          <div
                            className="progress-bar"
                            style={{
                              width: '100%',
                              height: '1.5rem',
                              backgroundColor: '#FF7777'
                            }}
                          ></div>
                        </div>
                      )}
                      {progressData.respond && (
                        <div
                          style={{
                            width:
                              (
                                (progressData.respond * 100) /
                                questions_count
                              ).toFixed(2) + '%',
                            color: '#156DFB'
                          }}
                        >
                          <div>
                            {progressData.respond} {t('v2:response')}
                          </div>
                          <div
                            className="progress-bar"
                            style={{
                              width: '100%',
                              height: '1.5rem',
                              backgroundColor: '#156DFB'
                            }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Divider />
                {x.user_answer.map((y, indexy) => {
                  return (
                    <div>
                      <Row>
                        <Col lg={20}>
                          {' '}
                          <div className="question">
                            Q{indexy + 1}. {y.quiz_question}
                          </div>
                        </Col>
                        <Col sm={6} lg={2}>
                          {t('reports:score')}
                        </Col>
                        <Col sm={6} lg={2}>
                          {y.scores}
                        </Col>
                      </Row>
                      <div className="answer">{this.questionByUser(y)}</div>
                    </div>
                  )
                })}
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
    )
  }
}
export default withTranslation('quiz')(QuizByUser)
