import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { MyNotesContainer } from './styled'
import NotesForm from './Form'
import Note from './Note'
import { Alert } from 'antd'
import _ from 'lodash'

class MyNotes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedOutside: false,
      activeTab: '',
      editing: false
    }
  }
  componentDidMount() {
    this.props.getNotes(this.props.noteId, this.props.noteType)
  }

  handleClick = () => {
    if (!this.state.clickedOutside) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    this.setState(prevState => ({
      clickedOutside: !prevState.clickedOutside,
      activeTab: '',
      editing: false
    }))
  }

  handleOutsideClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }
    this.handleClick()
  }

  onClickTabItem = tab => {
    if (this.state.clickedOutside) {
      this.setState({
        activeTab: tab,
        editing: false,
        clickedOutside: false
      })
    } else {
      this.setState({
        activeTab: tab,
        editing: false
      })
    }
  }

  setEditing = () => {
    this.setState({
      editing: true
    })
  }

  render() {
    const { userId, noteId, noteType, user, isLoading, t } = this.props
    const { clickedOutside, activeTab, editing } = this.state
    const notes = _.get(this.props, 'notes', [])

    return (
      <MyNotesContainer
        ref={node => {
          this.node = node
        }}
      >
        <MyNotesContainer.Overlay onClick={this.handleClick} />
        <NotesForm noteId={noteId} noteType={noteType} />
        {notes &&
          notes.length > 0 &&
          notes.map((note, key) => {
            if (note && parseInt(note.user_id) === parseInt(userId)) {
              return (
                <Note
                  noteId={noteId}
                  noteType={noteType}
                  note={note}
                  currentUser={userId}
                  userName={user.info.name}
                  setEditing={this.setEditing}
                  key={note.id}
                  isRoot
                  getByEventId={this.props.getByEventId}
                  z
                  clickedOutside={clickedOutside}
                  editingNotes={editing}
                  activeTab={activeTab}
                  onClickTabItem={this.onClickTabItem}
                />
              )
            }
            return null
          })}
        {isLoading && notes.length === 0 && (
          <h2>{t('loading')}</h2>
          // <Skeleton paragraph={{ rows: 5 }} active />
        )}

        {!isLoading && notes && notes.length === 0 && (
          <Alert
            message={t('sorry')}
            description={t('you_do_not_have_any_notes')}
            type="warning"
            showIcon
          />
        )}
      </MyNotesContainer>
    )
  }
}

export default withTranslation('v2')(MyNotes)
