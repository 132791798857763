import React, { Component, Fragment } from 'react'
import { Search, Cards, Filter, TopPageLink } from '../../../components'

import {
  FlexBox,
  SearchResultCount,
  FilterBox,
  FilterTitle,
  FilterTitleLink,
  FilterSettingBox,
  CheckableTag
} from './styled'

const tagsFromServer = ['Embroidery', 'illustration', 'motion graphic', 'painting', 'sculpturing']

const filterList = [
  {
    text: 'Data Create',
    value: 'created_at'
  },
  {
    text: 'Relevance',
    value: 'relevance'
  },
  {
    text: 'Popularity',
    value: 'popularity'
  }
]

const list = [{
  id: 0,
  title: 'Textured Embroidery (Animals)',
  cover: {
    link: 'https://images.pexels.com/photos/1640076/pexels-photo-1640076.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
  },
  type: 'Course',
  courses_lessons: [{}],
  creator: 'Rollie Pollie',
  price: 100,
  create_at: new Date()
},
  {
    id: 1,
    title: 'Textured Embroidery (Animals)',
    cover: {
      link: 'https://images.pexels.com/photos/1078850/pexels-photo-1078850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    type: 'Course',
    courses_lessons: [{}, {}],
    creator: 'Rollie Pollie',
    price: 100,
    create_at: new Date()
  },
  {
    id: 2,
    title: 'Textured Embroidery (Animals)',
    cover: {
      link: 'https://images.pexels.com/photos/1078850/pexels-photo-1078850.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    type: 'Course',
    courses_lessons: [{}, {}, {}],
    creator: 'Rollie Pollie',
    price: 100,
    create_at: new Date()
  },
  {
    id: 3,
    title: 'Textured Embroidery (Animals)',
    cover: {
      link: 'https://images.pexels.com/photos/1640076/pexels-photo-1640076.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    type: 'Course',
    courses_lessons: [{}],
    creator: 'Rollie Pollie',
    price: 300,
    create_at: new Date()
  }]

class TrainingSearch extends Component {
  state = {
    tagsFromServer,
    selectedTags: [],
    list,
    filterList
  }

  handleChange (tag, checked) {
    const { selectedTags } = this.state
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter(t => t !== tag)
    this.setState({ selectedTags: nextSelectedTags })
  }

  render () {
    const { selectedTags } = this.state
    return (
      <Fragment>
        <TopPageLink page='Training (Overview)' path='/training'/>
        <section className='section'>
          <FlexBox>
            <h2>Search results</h2>
            <SearchResultCount>(1244 results)</SearchResultCount>
          </FlexBox>
          <Search
            value='value'
            style={{ maxWidth: '310px' }}
            readOnly
          />
          <FilterBox>
            <FilterTitle>
              Your other interests
              <FilterTitleLink href='/'>Edit</FilterTitleLink>
            </FilterTitle>
            <FilterSettingBox>
              <Filter data={filterList} defaultValue={filterList[0].text}>
                {
                  tagsFromServer.map((tag) =>
                    <CheckableTag
                      key={tag}
                      checked={selectedTags.indexOf(tag) > -1}
                      onChange={checked => this.handleChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  )}
              </Filter>
            </FilterSettingBox>
          </FilterBox>
          <Cards type='training' data={list} width={29}/>
        </section>
      </Fragment>
    )
  }
}

export default TrainingSearch
