import React, { useState, useEffect } from 'react'
import { Col, Form, Input, Select } from 'antd'
import Switch from '../../../../components/Switch'
import { regions } from '../../../../constants'
import _ from 'lodash'

const BottomSection = props => {
  const {
    t,
    form: { getFieldDecorator },
    current,
    current: { marketplace },
    onFieldChange,
    handleSwitch
  } = props

  const [freeStatus, setfreeStatus] = useState(false)

  const onSwitchChange = val => {
    setfreeStatus(val)
    handleSwitch(val)
  }

  useEffect(() => {
    if (typeof marketplace.is_free === 'boolean')
      setfreeStatus(marketplace.is_free)
  }, [])

  return (
    <div className="event__mp__bottom">
      <div className="row">
        <Col md={24}>
          <Form.Item
            colon={false}
            required={false}
            label={t('v4:promotional_video_link')}
          >
            {getFieldDecorator('video_link', {
              rules: [{ required: true, message: t('v4:input_video_link') }],
              initialValue: marketplace.video_link
            })(
              <Input
                size="large"
                maxLength={100}
                onChange={e => onFieldChange(e.target.value)}
              />
            )}
          </Form.Item>
        </Col>
      </div>
      <div className="row">
        <Col md={12}>
          <div className="flex__row">
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('is_free', {
                initialValue: marketplace.is_free
              })(
                <Switch
                  label="Free Events"
                  onChanged={e => onSwitchChange(e)}
                />
              )}
            </Form.Item>
            <Form.Item
              colon={false}
              required={false}
              className="price_style"
              label={t('labels:price (usd)')}
            >
              {getFieldDecorator('price', {
                initialValue: marketplace.price,
                rules: [
                  {
                    validator: (rule, value) => value >= 5,
                    required: false,
                    message: t('v4:enter_price')
                  }
                ]
              })(
                <Input
                  size="large"
                  type="number"
                  min={5}
                  maxLength={100}
                  onChange={e => onFieldChange(e.target.value)}
                  disabled={freeStatus}
                />
              )}
            </Form.Item>
          </div>
        </Col>
        <Col md={11}>
          <Form.Item
            label={t('labels:location_broadcast')}
            colon={false}
            required={false}
          >
            {getFieldDecorator('location', {
              rules: [
                {
                  required: true,
                  message: t('errors:live_location')
                }
              ],
              initialValue: current.broadcast_location
            })(
              <Select
                optionFilterProp="children"
                size="large"
                disabled
                filterOption={(input, option) =>
                  !_.isArray(option.props.children) &&
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {regions.map(continent => (
                  <Select.OptGroup
                    key={continent.title}
                    value={continent.title}
                  >
                    {Object.keys(continent.list).map(item => (
                      <Select.Option key={item} value={item}>
                        {t(`regions:${item}`)}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </div>
      <div className="row">
        <Col md={24}>
          <Form.Item
            label={t('labels:description')}
            colon={false}
            required={false}
          >
            {getFieldDecorator('description', {
              initialValue: current.description
            })(
              <Input.TextArea
                rows={4}
                name="description"
                placeholder={t('placeholders:description')}
                size="large"
                maxLength={1000}
                onChange={e => onFieldChange(e.target.value)}
                style={{ fontSize: '1.6rem', resize: 'none' }}
              />
            )}
          </Form.Item>
        </Col>
      </div>
    </div>
  )
}

export default BottomSection
