import React from 'react'
import { Input, Checkbox } from 'antd'
import { Page } from '../../../../Events/Create/styled'
import { Field } from '../../../../../styles'
import { Trans } from 'react-i18next'
import PrivacySelector from '../PrivacySelector/PrivacySelector'
import { OrganizationPicker } from '../../../../Courses/Create/nestedComponents/PublishSettings/components'
const Setting = ({
  t,
  list,
  isDisabled,
  checkedTerms,
  handleChangeTerms,
  isAdmin,
  selectedType,
  handleSelectedType,
  isSuperAdmin,
  organizationIds,
  setOrganizationIds
}) => {
  const privacyTypes = [
    // isSuperAdmin && {
    //   id: 1,
    //   name: 'Public',
    //   description: 'PublicDescription'
    // },
    {
      id: 2,
      name: 'Organisations',
      description: 'OrganisationsDescription'
    },
    // (isSuperAdmin || isAdmin) && {
    //   id: 3,
    //   name: 'Organisation Groups',
    //   description: 'OrganisationGroupsDescription'
    // },
    {
      id: 4,
      name: 'Marketplace',
      description: 'MarketPlaceDescription',
      disabled: isDisabled
    }
  ].filter(i => !!i)
  let filteredList = []
  if (list) {
    filteredList = list.filter(
      i => isSuperAdmin || (i.role && i.role.name === 'Admin')
    )
  }
  const handleSelectedOrganizations = id => {
    if (organizationIds.includes(id)) {
      setOrganizationIds(
        organizationIds.filter(organizationId => organizationId !== id)
      )
    } else {
      setOrganizationIds([...organizationIds, id])
    }
  }
  return (
    <>
      <section className="section_divider" style={{ paddingTop: '1.5rem' }}>
        <Page style={{ maxWidth: '120rem' }}>
          <Field>
            <PrivacySelector
              t={t}
              selectedType={selectedType}
              isAdmin={isSuperAdmin || isAdmin}
              types={privacyTypes}
              onChange={handleSelectedType}
            />
          </Field>
          {selectedType === 2 && (
            <Field>
              <div
                style={{
                  height: 450,
                  overflowY: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: 4,
                  padding: '1.8rem'
                }}
              >
                <OrganizationPicker
                  list={filteredList}
                  selectedOrganizations={organizationIds}
                  onChange={handleSelectedOrganizations}
                />
              </div>
            </Field>
          )}
        </Page>
        <Page>
          <Field>
            <Field.Label>{t('labels:disclaimer')}</Field.Label>
            <Field.Control>
              <Input.TextArea
                disabled
                rows={6}
                style={{ resize: 'none' }}
                value={t('events:disclaimer')}
              />
            </Field.Control>
          </Field>
          <Field>
            <Field.Label>
              <Checkbox checked={checkedTerms} onChange={handleChangeTerms}>
                <Trans i18nKey="labels:terms">
                  {t('i_agree_to_these')}
                  <a href="/pages/terms-conditions" target="_blank">
                    {t('terms_and_conditions')}
                  </a>
                </Trans>
              </Checkbox>
            </Field.Label>
          </Field>
        </Page>
      </section>
    </>
  )
}

export default Setting
