import { Progress } from 'antd'
import { Auth } from 'aws-amplify'
import React, { useEffect, useMemo } from 'react'

import { Unity, useUnityContext } from 'react-unity-webgl-latest'
import { config } from '../../../../../../../constants'

const SimulationUnityView = ({
  unityUrl,
  simulationId,
  userId,
  token,
  ids
}) => {
  const { loaderUrl, dataUrl, frameworkUrl, codeUrl } = unityUrl
  const { lessonId, courseId, pageId } = ids

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener
  } = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl
  })

  const loadingPercentage = useMemo(
    () => Math.round(loadingProgression * 100), [loadingProgression]
  )

  const getAccessJwtToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically

    const session = await Auth.currentSession()
    const newToken = await session.idToken.jwtToken
    return newToken
  }

  const handleNewToken = async msg => {
    if (msg === 'token_needed') {
      const token = await getAccessJwtToken()
      sendMessage('Data', 'Get_Fresh_Token', String(token))
    }
  }

  useEffect(() => {
    if (isLoaded) {
      sendMessage('Data', 'Get_courseid', String(courseId))
      sendMessage('Data', 'Get_userid', String(userId))
      sendMessage('Data', 'Get_simulationid', String(simulationId))
      sendMessage('Data', 'Get_token', String(token))
      sendMessage('Data', 'Get_baseurl', String(config.baseURL))
      sendMessage('Data', 'Get_lessonid', String(lessonId))
      sendMessage('Data', 'Get_pageid', String(pageId))
      addEventListener('GetToken', handleNewToken)
    }
    return () => {
      removeEventListener('GetToken', handleNewToken)
    }
  }, [isLoaded])

  useEffect(() => {
    return () => {
      unityProvider.setUnityInstance(null)
    }
  }, [])


  return (
    <div className="quiz-unity-container">
      {isLoaded === false && (
        <div className="quiz-unity-loading-overlay">
          <Progress type="circle" percent={loadingPercentage} />
          Loading Simulation...
        </div>
      )}

      <Unity
        className="quiz-unity"
        unityProvider={unityProvider}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    </div>
  )
}

export default SimulationUnityView
