import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form, Button, Select, Input, Icon, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../../../../../../../services'
import { updateMedia } from '../../../../../../../../../../services/api/library'
import CreatableSelect from 'react-select/creatable'
import { general } from '../../../../../../../../../../constants'
import './AddDetailImage.scss'

const { TextArea } = Input

class AddDetailImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
      keywords: '',
      description: '',
      isLoading: false,
      saveLabel: props.isFromCreateAudio
        ? props.t('buttons:insert')
        : props.t('buttons:save'),
      disableButton: false,
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false
    }
    this.loadOptions = _.debounce(this.loadOptions, 500)
  }

  enableInsertButton = () => {
    const { t, isFromCreateAudio } = this.props
    this.setState({
      saveLabel: isFromCreateAudio ? t('buttons:insert') : t('buttons:save'),
      disableButton: false
    })
  }

  handleChangeKeyword = value => {
    const keywords = value.join(',')
    this.setState({ keywords })
    this.enableInsertButton()
  }

  handleChangeDescription = e => {
    const description = e.target.value
    this.setState({ description })
    this.enableInsertButton()
  }

  handleSubmit = e => {
    e.preventDefault()
    const {
      getSelectedFile,
      upload,
      src,
      mediaId,
      handleFileUpload,
      t,
      isFromCreateAudio
    } = this.props
    const { value, description, keywords } = this.state

    this.props.form.validateFields(async err => {
      if (!err) {
        let tempMediaId = ''
        const data = {
          topics: _.map(value, 'id'),
          description,
          keywords,
          isSynthesizedAudio: !!isFromCreateAudio
        }
        this.setState({
          isLoading: true,
          saveLabel: isFromCreateAudio
            ? t('buttons:inserting')
            : t('buttons:saving')
        })
        if (mediaId) {
          tempMediaId = mediaId
        } else if (src !== undefined) {
          const upload_response = await upload(src)
          if (upload_response && upload_response.id) {
            tempMediaId = upload_response.id
          }
        } else {
          this.setState({
            isLoading: false,
            saveLabel: isFromCreateAudio
              ? t('buttons:insert')
              : t('buttons:save'),
            disableButton: false
          })
          return
        }

        if (tempMediaId) {
          const update_response = await updateMedia(tempMediaId, data)
          isFromCreateAudio
            ? handleFileUpload([update_response.data], true)
            : getSelectedFile([update_response.data])

          if (isFromCreateAudio) {
            let oldAudioData =
              JSON.parse(localStorage.getItem('audio_data')) || []
            const {
              audioSynthesizer: {
                selected: { selectedText, selectedVoice }
              }
            } = this.props

            const newAudioData = {
              text: selectedText,
              voice_type: selectedVoice,
              content_id: tempMediaId
            }
            oldAudioData.push(newAudioData)
            localStorage.setItem('audio_data', JSON.stringify(oldAudioData))
          }

          this.setState({
            isLoading: false,
            saveLabel: t('buttons:saved'),
            disableButton: true
          })
        }
      }
    })
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableInsertButton()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableInsertButton()
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.enableInsertButton()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    return {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
  }

  loadOptions = newValue => {
    const inputValue = newValue.replace(/\W/g, '')
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue,
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  render() {
    const {
      fileName,
      t,
      src,
      isFromCreateAudio,
      onHideDetailModal,
      uploadComplete
    } = this.props
    const {
      isLoading,
      saveLabel,
      disableButton,
      value,
      options,
      isTopicLoading,
      isTopicsMax
    } = this.state

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="upload-form">
          <Col span={6}>
            <label>{t('audio:file_name')}*</label>
          </Col>
          <Col span={18}>
            <Input value={fileName || `${src.name}.mp3`} disabled />
          </Col>
          <Col span={6}>
            <label>{t('audio:description')}</label>
          </Col>
          <Col span={18}>
            <div className="upload-form__textarea">
              <TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                onChange={this.handleChangeDescription}
                maxLength={500}
              />
              {/*<label>{t("placeholders:max_500_characters")}</label>*/}
            </div>
          </Col>
          <Col span={6}>
            <label>{t('audio:topic')}</label>
          </Col>
          <Col span={18}>
            <CreatableSelect
              /*styles={customStyles}*/
              components={{
                ClearIndicator: null,
                DropdownIndicator: null,
                LoadingIndicator: this.LoadingIndicator
              }}
              isMulti
              inputId="react-select-2-input"
              isLoading={isTopicLoading}
              menuIsOpen={this.isModalSelectOpen()}
              onBlur={() => this.setState({ inputValue: '' })}
              onChange={this.handleChange}
              onCreateOption={this.handleCreate}
              onInputChange={this.loadOptions}
              options={options}
              value={value}
            />

            {isTopicsMax && (
              <div className="error_message">{t('errors:topics_max')}</div>
            )}
          </Col>
          <Col span={6}>
            <label>{t('audio:key_words_tag')}</label>
          </Col>
          <Col span={18}>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              className="custom-select-tag"
              dropdownClassName="disable-select-dropdown"
              tokenSeparators={[',', '.', ';']}
              onChange={this.handleChangeKeyword}
            />
          </Col>

          {isFromCreateAudio && (
            <Col span={24}>
              <hr />
            </Col>
          )}

          <div className="btn-group">
            {isFromCreateAudio && (
              <Button
                type="default"
                shape="round"
                size="default"
                onClick={onHideDetailModal}
              >
                {general.CANCEL}
              </Button>
            )}

            <Button
              type="primary"
              shape="round"
              size="default"
              htmlType="submit"
              disabled={disableButton || uploadComplete}
              loading={isLoading}
            >
              {saveLabel}
            </Button>
          </div>
        </div>
      </Form>
    )
  }
}

AddDetailImage.defaultProps = {
  mediaId: 0,
  fileName: ''
}

AddDetailImage.propTypes = {
  mediaId: PropTypes.number,
  fileName: PropTypes.string,
  handleFileUpload: PropTypes.func
}

export default Form.create()(withTranslation()(AddDetailImage))
