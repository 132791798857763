import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Cards, Scrollbars, Spinner, Search } from '../../../components'
import history from '../../../history'
import { HelpMessage, Row } from './styled'
import { Button } from 'antd'
import './List.scss'

const CourseEventTemplate = ({
  data,
  firstFetch,
  secFetch,
  t,
  app,
  type,
  mode,
  title,
  message
}) => (
  <section className="section section_light">
    <div className="head header_with_button">
      <div className="head__title">{t(`${title}`)}</div>
      {data.length > 0 && (
        <div>
          <Button type="link" onClick={() => app.onViewAllClick(type)}>
            {t('general:view_all')}
          </Button>
        </div>
      )}
    </div>

    {firstFetch || secFetch ? (
      <Spinner size="small" />
    ) : data.length ? (
      <Scrollbars
        type="horizontal"
        style={{ width: '93vw' }}
        autoHeightMin={360}
        minimize="true"
        autoHeight
      >
        <Cards
          data={data}
          type={type}
          mode={mode}
          width={30}
          horizontal
          onClick={app.handleClick}
        />
      </Scrollbars>
    ) : (
      <HelpMessage>{t(`${message}`)}</HelpMessage>
    )}
  </section>
)

class TrainingPage extends Component {
  state = {
    search: '',
    page: 1,
    pageSize: 20
  }

  componentDidMount() {
    this.fetchData(true)
  }

  fetchData = (isInitialLoading = false, isTrainingOnly = false) => {
    const {
      getPastLiveAssessment,
      getUpcomingLiveAssessment,
      getNowLiveAssessment
    } = this.props

    const { search } = this.state

    getPastLiveAssessment(
      {
        limit: 50,
        order: 'created_at',
        search
      },
      isInitialLoading
    )

    getUpcomingLiveAssessment(
      {
        limit: 50,
        order: 'created_at',
        search
      },
      isInitialLoading
    )

    getNowLiveAssessment(
      {
        limit: 50,
        order: 'created_at',
        search
      },
      isInitialLoading
    )
  }

  handleClick = item => {
    history.push(`/assessments/${item.id}`)
  }

  onViewAllClick = type => {
    switch (type) {
      case 'Now Live Assessment':
        this.redirectTo('live')
        break
      case 'Upcoming Assessment':
        this.redirectTo('upcoming')
        break
      case 'Past Live Assessment':
        this.redirectTo('past')
        break
      default:
        break
    }
  }
  redirectTo = mode => {
    history.push(`/assessments/list/${mode}`)
  }

  onSearch = _.debounce(
    value =>
      this.setState(
        {
          search: value,
          page: 1
        },
        () => {
          this.fetchData(true)
        }
      ),
    1000
  )

  render() {
    const {
      assessments: {
        pastAssessment: { list: pastAssessments, fetch: pastAssessmentsFetch },
        upcomingAssessment: {
          list: upcomingAssessments,
          fetch: upcomingAssessmentsFetch
        },
        nowLiveAssessment: {
          list: nowLiveAssessments,
          fetch: nowLiveAssessmentsFetch
        }
      },
      t
    } = this.props

    return (
      <Fragment>
        <section className="section">
          <Row>
            <div className="head head_nopadding">
              <div className="head__title">{t('title_assessment')}</div>
            </div>
            <div className="head head_nopadding search">
              <Search
                placeholder={t('training:search_assessment')}
                onChange={e => this.onSearch(e.target.value)}
              />
            </div>
          </Row>
        </section>

        {/* Now Live Assessment */}
        <CourseEventTemplate
          data={nowLiveAssessments}
          firstFetch={nowLiveAssessmentsFetch}
          secFetch={null}
          mode="live"
          title={'now_live_assessment_title'}
          sub_title={'now_live_assessment_subTitle'}
          message={'now_live_assessment_message'}
          type={'Now Live Assessment'}
          t={t}
          app={this}
        />
        {/* Upcoming Assessment */}
        <CourseEventTemplate
          data={upcomingAssessments}
          firstFetch={upcomingAssessmentsFetch}
          secFetch={null}
          mode="upcoming"
          title={'upcoming_assessment_title'}
          sub_title={'upcoming_assessment_subtitle'}
          message={'upcoming_assessment_message'}
          type={'Upcoming Assessment'}
          t={t}
          app={this}
        />

        {/* Past Live Assessment */}
        <CourseEventTemplate
          data={pastAssessments}
          firstFetch={pastAssessmentsFetch}
          secFetch={null}
          mode="past"
          title={'past_live_assessment_title'}
          sub_title={'past_live_assessment_subTitle'}
          message={'past_live_assessment_message'}
          type={'Past Live Assessment'}
          t={t}
          app={this}
        />
      </Fragment>
    )
  }
}

export default withTranslation('training')(TrainingPage)
