import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Checkbox.scss'

class Checkbox extends Component {
  static propTypes = {
    ctrlOnCheckboxChange: PropTypes.func
  }

  state = { checked: false }

  handleCheckboxChange = e => {
    this.setState({ checked: e.target.checked })
    this.props.ctrlOnCheckboxChange(e)
  }

  render() {
    return (
      <label className="checkbox-container">
        <input
          ref={this.props.selectRef}
          name="filterbox"
          type="checkbox"
          checked={this.state.checked}
          onChange={this.handleCheckboxChange}
        />
        <span className="checkmark"></span>
      </label>
    )
  }
}

export default Checkbox
