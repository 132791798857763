import { TOGGLE_MAIN_MENU } from '../types'

const defaultState = {
  isOpen: false
}

const menu = (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_MAIN_MENU:
      return {
        isOpen: !state.isOpen
      }
    default:
      return state
  }
}

export default menu
