import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import EAnnotation from './EAnnotation'

const mapStateToProps = state => ({
  annotation: state.annotation,
  pageBuilder: state.pageBuilder
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditor: actions.annotation.setEditor,
      setEditorDefaults: actions.annotation.setEditorDefaults,
      add: actions.annotation.add,
      getById: actions.annotation.getById,
      updateById: actions.annotation.updateById,
      handleData: actions.annotation.handleData,
      upload: actions.library.upload,
      showAnnotationImageModal: actions.pageBuilder.showAnnotationImageModal,
      showAnnotationTextModal: actions.pageBuilder.showAnnotationTextModal,
      showAnnotationVideoModal: actions.pageBuilder.showAnnotationVideoModal,
      showAnnotationAudioModal: actions.pageBuilder.showAnnotationAudioModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EAnnotation)
