/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-08-27 11:02:55
 * @LastEditors: Please set LastEditors
 */
import styled, { css } from 'styled-components'
import { Icon } from 'antd'

import { styleguide } from '../../constants'

const { colors } = styleguide

const Lessons = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

Lessons.Count = styled.p`
  line-height: 2.4rem;
  font-weight: 300;
  font-size: 1.6rem;
  padding: 1.5rem;
  margin-bottom: 0;
  border: 1px solid ${colors.borderDark};
  background: #e9eff64d;
  border-radius: 16px 16px 0px 0px;

  ${props =>
    props.left &&
    css`
      text-align: left;
    `}

  ${props =>
    props.darkBorder &&
    css`
      border-color: ${colors.borderDark};
    `}
`

Lessons.List = styled.ul`
  flex: 1;
  padding: 0 1rem 1rem;
  margin: 0;
  border-bottom: 1px solid ${colors.darkGreyThree};
  border-left: 1px solid ${colors.darkGreyThree};
  border-right: 1px solid ${colors.darkGreyThree};
  min-height: 40rem;
  overflow-y: auto;
  border-radius: 0px 0px 16px 16px;
  position: relative;
  ${props =>
    props.dashed &&
    css`
      border-bottom: 1px solid #e3e3e3;
      border-left: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
    `}
`

Lessons.Item = styled.li`
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-bottom: 1px solid rgba(216, 216, 216, 0.85);

  &:last-of-type {
    border-bottom: 0;
  }

  &.active {
    color: ${colors.blue};
  }
`

Lessons.Trash = styled(Icon)`
  margin-left: auto;
`

Lessons.ItemNumber = styled.span`
  font-size: 1.1rem;
  font-weight: 300;
  color: inherit;
`

Lessons.ItemCover = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 1rem;
  border-radius: 8px;
  background: url(${props => props.cover}) center no-repeat;
  background-size: cover;
  background-color: ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  justify-content: center;
`

Lessons.ItemTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0;
  color: inherit;
`

export default Lessons
