import React, { useEffect, useState } from 'react'

import './QuizStyles.scss'
import { Modal } from 'antd'
import EQuiz from './EQuiz'

const viewTypes = {
  general: 'general',
  simulation: 'simulation',
  ai: 'ai'
}
const QuizHandler = props => {
  const {
    handleCloseQuizModal,
    pageBuilder,
    pageBuilder: { contentId }
  } = props

  const [viewType, setViewType] = useState(null)

  useEffect(() => {
    if (contentId) {
      setViewType(viewTypes.general)
    }
  }, [])

  const goToGeneralQuiz = () => {
    setViewType(viewTypes.general)
  }

  const goToSimulationQuiz = () => {
    setViewType(viewTypes.simulation)
  }

  return (
    <>
      {!viewType && (
        <Modal
          title={'Create Quiz'}
          visible
          onCancel={handleCloseQuizModal}
          footer={null}
          centered
        >
          <div className="quiz-choice-container">
            <div className="quiz-choice" onClick={goToGeneralQuiz}>
              General Quiz
            </div>
            <div className="quiz-choice" onClick={goToSimulationQuiz}>
              Simulation Quiz
            </div>
          </div>
        </Modal>
      )}

      {viewType === viewTypes.general && <EQuiz {...props} />}

      {viewType === viewTypes.simulation && (
        <EQuiz
          {...props}
          pageBuilder={{ ...pageBuilder, isSimulation: true }}
        />
      )}
    </>
  )
}

export default QuizHandler
