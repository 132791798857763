import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Empty, Button } from 'antd'
import { MessageContainer } from './styled'
import { withTranslation } from 'react-i18next'
import history from '../../history'

class EmptyMessage extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    onClickAction: PropTypes.func
  }

  static defaultProps = {
    title: '',
    buttonTitle: '',
    onClickAction: () => history.goBack()
  }

  render() {
    const { title, buttonTitle, onClickAction } = this.props

    return (
      <MessageContainer>
        <Empty description={title}>
          {buttonTitle && (
            <Button type="primary" onClick={onClickAction}>
              {buttonTitle}
            </Button>
          )}
        </Empty>
      </MessageContainer>
    )
  }
}

export default withTranslation()(EmptyMessage)
