import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../store/actions/index'
import Detail from './Detail_'

export default connect(
  state => ({
    data: state.reports,
    userInfo: state.user
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getReport: bindActionCreators(actions.reports.getReport, dispatch),
    getLibraryList: query => dispatch(actions.library.getLibraryList(query))
  })
)(Detail)
