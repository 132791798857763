import React, { Component } from 'react'

import { PollBox } from './styled'
import { withTranslation } from 'react-i18next'

class Poll extends Component {
  render () {
    const { data, t } = this.props

    return (
      <PollBox>
        <PollBox.Title>{t('labels:poll')}</PollBox.Title>
        <PollBox.Content>
          <PollBox.Question>{data.question}</PollBox.Question>
          <PollBox.QuestionsBox>
            {data.options.map((option, index) =>
              <PollBox.SingleVariant
                key={index}
                percent={option.percent}
              >
                {option.text}&nbsp;{option.percent}%
              </PollBox.SingleVariant>
            )}
          </PollBox.QuestionsBox>
        </PollBox.Content>
      </PollBox>
    )
  }
}

export default withTranslation()(Poll)
