/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-19 19:34:23
 * @LastEditTime: 2019-08-12 17:01:37
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import Element from './Element'

const mapStateToProps = state => ({
  annotation: state.annotation,
  droppedFile: state.lessonBuilder.droppedFile
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEditor: actions.annotation.setEditor,
      setEditElement: actions.annotation.setEditElement,
      upload: actions.library.upload,
      showEditAudioModal: actions.audioSynthesizer.showEditAudioModal,
      showEditAudioDetailModal:
        actions.audioSynthesizer.showEditAudioDetailModal,
      showEditTextModal: actions.pageBuilder.showEditTextModal,
      showEditImageModal: actions.pageBuilder.showEditImageModal,
      showEditVideoModal: actions.pageBuilder.showEditVideoModal,
      showAnnotationModal: actions.pageBuilder.showAnnotationModal,
      showEditQuizModal: actions.pageBuilder.showEditQuizModal,
      showInsertVideoModal: actions.pageBuilder.showInsertVideoModal,
      showCreateAudioModal: actions.pageBuilder.showCreateAudioModal,
      showScenarioModal: actions.pageBuilder.showScenarioModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Element)
