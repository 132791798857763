import React, { Component, Fragment } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Page } from './styled'
import { Field } from '../../../../../styles'
import {
  DatePicker,
  GroupPicker
} from '../../../../Courses/Create/nestedComponents/PublishSettings/components'
import { OrganizationPicker } from '../../../../Events/Create/components'
import PrivacySelector from './PrivacySelector'
import { Input, Checkbox, Button, Spin, message, Modal } from 'antd'
import history from '../../../../../history'
import { withTranslation, Trans } from 'react-i18next'
import { general } from '../../../../../constants'
import { api } from '../../../../../services'
import ReactSVG from 'react-svg'

class PublishMaze extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedType: [2],
      startValue: moment(),
      endValue: null,
      selectedOrganizations: [],
      selectedGroups: [],
      emails: [],
      isEmailsValid: false,
      checkedTerms: false,
      isAdminOnly: false,
      isDisable: true,
      isPublish: false,
      isSendMail: false,
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: [],
      enableMarketplace: false,
      isSaving: false
    }
    this.handleInsertUsers = _.debounce(this.handleInsertUsers, 1000)
    this.handleCheckUser = _.debounce(this.handleCheckUser, 500)
  }

  componentDidMount() {
    const {
      mazeBuilder: { mazeExpDate, mazeStartDate },
      maze: { mazeById }
    } = this.props
    this.checkEnableMarketPlace()
    if (mazeStartDate) {
      this.setState(
        {
          startValue: moment(mazeStartDate),
          endValue: mazeExpDate && moment(mazeExpDate)
        },
        () => {
          this.getOldSetting(mazeById)
        }
      )
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.checkEnableMarketPlace()
      const {
        maze: { mazeById }
      } = this.props

      if (mazeById !== prevProps.maze.mazeById) {
        this.getOldSetting(mazeById)
      }
    }
  }

  checkEnableMarketPlace = () => {
    const {
      mazeBuilder: {
        mazeId,
        mazeTitle,
        mazeImgId,
        mazeCategory,
        mazeIsFree,
        mazeMarketPlaceDescription,
        mazeObjectives,
        mazeOutline,
        mazePrice,
        mazeVideoLink
      }
    } = this.props
    let data = {
      mazeId,
      mazeTitle,
      mazeImgId,
      mazeCategory,
      mazeIsFree,
      mazeMarketPlaceDescription,
      mazeObjectives,
      mazeOutline,
      mazePrice,
      mazeVideoLink
    }
    let errorData = Object.keys(data).filter(k => {
      return data[k] === '' || data[k] === undefined || data[k] === null
    })

    console.log('error in marketplace', errorData)

    if (errorData.length === 0) {
      this.setState({
        enableMarketplace: true
      })
    }
  }

  getOldSetting = mazeById => {
    const { setting } = mazeById
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props

    console.log('get old setting', setting, setting ? 4 : 5)
    if (setting) {
      const {
        group_ids,
        publish_option,
        organization_ids,
        emails,
        is_admin_only: isAdminOnly
      } = setting
      if (publish_option === general.ORGANIZATION_AND_MARKETPLACE) {
        this.setState({ selectedType: [2, 5] })
      } else if (publish_option === general.ORGANIZATION) {
        this.handleSelectedType(2)
      } else if (publish_option === general.ORGANIZATION_GROUP) {
        this.handleSelectedType(3)
      } else if (publish_option === general.SPECIFIC_USER) {
        this.handleSelectedType(4)
      } else if (publish_option === general.PUBLIC) {
        this.handleSelectedType(1)
      } else if (publish_option === general.MARKETPLACE) {
        this.handleSelectedType(5)
      }
      this.handleInsertUsers(_.uniq(emails).join('\n'))
      this.setState({
        selectedOrganizations: organization_ids,
        selectedGroups: group_ids,
        emails,
        isAdminOnly
      })
    } else {
      console.log('at first', [active_organization_id])
      this.setState({ selectedOrganizations: [active_organization_id] })
    }
  }

  getLastOption = () => {
    if (this.checkSelectedType(2) && this.checkSelectedType(5)) {
      return general.ORGANIZATION_AND_MARKETPLACE
    } else if (this.checkSelectedType(1)) {
      return general.PUBLIC
    } else if (this.checkSelectedType(2)) {
      return general.ORGANIZATION
    } else if (this.checkSelectedType(3)) {
      return general.ORGANIZATION_GROUP
    } else if (this.checkSelectedType(5)) {
      return general.MARKETPLACE
    } else {
      return general.SPECIFIC_USER
    }
  }
  handlePublish = () => {
    const {
      mazeBuilder: { mazeId },
      handleMazeData,
      t,
      user: {
        info: { username, bio, linkedin }
      },
      mazeBuilder: {
        mazeCategory,
        mazeIsFree,
        mazeMarketPlaceDescription,
        mazeObjectives,
        mazeOutline,
        mazePrice,
        mazeShareProfile,
        mazeVideoLink
      }
    } = this.props

    const {
      selectedOrganizations,
      selectedGroups,
      emails,
      isEmailsValid,
      startValue,
      endValue,
      isAdminOnly: is_admin_only,
      isSendMail,
      enableMarketplace
    } = this.state

    const removedSpaceEmail = _.map(emails, email => email.trim().toLowerCase())

    let privacy = {
      is_public: this.checkSelectedType(1),
      is_admin_only: this.checkSelectedType(1) ? is_admin_only : false,
      organization_ids: selectedOrganizations,
      group_ids: selectedGroups,
      emails: _.uniq([...removedSpaceEmail]),
      publish_option: this.getLastOption(),
      is_marketplace: this.checkSelectedType(5),
      isSendMail
    }

    const body = {
      start_date_time: moment(startValue).valueOf(),
      end_date_time: moment(endValue).valueOf(),
      ...(enableMarketplace && {
        marketplace: {
          username: username,
          category_id: mazeCategory,
          is_free: mazeIsFree,
          price: mazePrice,
          video_link: mazeVideoLink,
          course_obj: mazeObjectives,
          course_outline: mazeOutline,
          is_shared_profile: mazeShareProfile,
          course_description: mazeMarketPlaceDescription,
          bio,
          linkedin
        }
      }),
      privacy
    }

    const data = {
      params: {
        programme_id: mazeId
      },
      action: 'programme_publish',
      body
    }

    if (!startValue) {
      message.error(t('dates_validation_error'))
      this.savingError()
      return
    }

    if (!_.isEmpty(emails) && !isEmailsValid) {
      message.error(t('emails_validation_error'))
      this.savingError()
      return
    }

    if (handleMazeData) {
      handleMazeData(data).then(() => {
        history.push(`/training`)
      })
    }
  }

  savingError = () => {
    this.setState({ isSaving: false })
  }

  handleSelectedDate = (field, value) => {
    this.setState({
      [field]: value
    })
  }

  handleSelectedType = id => {
    const { selectedType } = this.state

    // selectedType changed to array to add the usecase of multiple select of marketplace and organization in publish settings

    if (
      id === 1 ||
      id === 3 ||
      id === 4 ||
      selectedType[0] === 1 ||
      selectedType[0] === 3 ||
      selectedType[0] === 4
    ) {
      this.setState({
        selectedType: [id]
      })
    } else if (selectedType.some(i => i === id)) {
      this.setState({
        selectedType:
          selectedType.length === 1
            ? [id]
            : selectedType.filter(item => item !== id)
      })
    } else {
      this.setState({
        selectedType: [...selectedType, id]
      })
    }
  }

  checkSelectedType = id => this.state.selectedType.some(item => item === id)

  handleSelectedOrganizations = id => {
    const { selectedOrganizations } = this.state

    if (selectedOrganizations.includes(id)) {
      this.setState({
        selectedOrganizations: selectedOrganizations.filter(
          organizationId => organizationId !== id
        )
      })
    } else {
      this.setState({
        selectedOrganizations: [...selectedOrganizations, id]
      })
    }
  }

  handleSelectedGroups = groupId => {
    const { selectedGroups } = this.state

    this.setState({
      selectedGroups: selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId])
    })
  }

  componentWillReceiveProps(nextProps) {
    const {
      mazeBuilder: { mazeStartDate, mazeExpDate }
    } = this.props
    const {
      mazeBuilder: { mazeStartDate: nextStartDate, mazeExpDate: nextExpDate }
    } = nextProps

    if (!mazeStartDate && nextStartDate) {
      this.setState({ startValue: moment(nextStartDate) })
    }

    if (!mazeExpDate && nextExpDate) {
      this.setState({ endValue: moment(nextExpDate) })
    }
  }

  handleSelectedAllOrganizations = id => {
    const { list } = this.props
    const { selectedGroups } = this.state

    const { groups = [] } = list.find(i => i.id === id)
    const groupIds = groups.map(i => i.id)

    const selectedOrgGroups = groupIds.filter(i => selectedGroups.includes(i))

    if (groupIds.length === selectedOrgGroups.length) {
      this.setState({
        selectedGroups: selectedGroups.filter(
          i => !selectedOrgGroups.includes(i)
        )
      })
    } else {
      this.setState({
        selectedGroups: selectedGroups
          .filter(i => !groupIds.includes(i))
          .concat(groupIds)
      })
    }
  }

  handleChangeTerms = () => {
    const { isPublish } = this.state
    if (!isPublish) {
      this.setState(state => ({
        checkedTerms: !state.checkedTerms,
        isDisable: !state.isDisable
      }))
    }
  }

  checkedPublishButtonDisabling = () => {
    this.setState({
      isDisable: true,
      isPublish: true
    })
  }

  handleInsertUsers = value => {
    const emails = value.replace(/(\r\n|\n|\r)/gm, ',').split(',')
    const check = _.map(_.compact(emails), email =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    )
    const isSetValid = _.filter(check, item => item)
    if (isSetValid.length === check.length) {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: true
      })
    } else {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: false
      })
    }
  }

  onSendEmail = () => {
    this.setState(state => ({
      isSendMail: !state.isSendMail
    }))
  }

  handleCheckUser = () => {
    this.setState({
      isSaving: true
    })
    const { emails } = this.state
    if (!_.isEmpty(emails)) {
      if (this.state.isEmailsValid) {
        const removedSpaceEmail = _.map(emails, email => email.trim())
        const myJSON = JSON.stringify(removedSpaceEmail)
        const {
          user: {
            info: { active_organization_id: activeOrganization }
          }
        } = this.props
        api.events
          .checkUser(myJSON)
          .then(response => {
            const { data } = response
            const isNotExist = data.filter(email => !email.is_exist)
            const isNotInOrg = data.filter(
              email => email.is_exist && !email.in_organization
            )
            this.checkPersonalSpace(activeOrganization, isNotExist, isNotInOrg)
          })
          .catch(() => {
            this.savingError()
          })
      } else {
        const { t } = this.props
        this.savingError()
        message.error(t('emails_validation_error'))
      }
    } else {
      this.handlePublish()
    }
  }
  checkPersonalSpace = (activeOrganization, isNotExist, isNotInOrg) => {
    if (activeOrganization) {
      if (_.isEmpty(isNotExist) && _.isEmpty(isNotInOrg)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg,
          isLoading: false,
          isSaving: false
        })
      }
    } else {
      if (_.isEmpty(isNotExist)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg: [],
          isLoading: false,
          isSaving: false
        })
      }
    }
  }

  handleSendEmail = () => {
    this.handlePublish()
    this.setState({
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: []
    })
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('emails')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false
    })
  }

  onAdminOnly = () => {
    this.setState(state => ({
      isAdminOnly: !state.isAdminOnly
    }))
  }

  render() {
    const {
      list,
      user: {
        info: { isAdmin, isSuperAdmin, active_organization_id }
      },
      mazeBuilder: { mazeId, mazeStartDate },
      t
    } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      startValue,
      endValue,
      checkedTerms,
      emails,
      isDisable,
      isAdminOnly,
      isSendMail,
      isCheckModal,
      isNotExist,
      isSaving,
      isNotInOrg
    } = this.state
    const privacyTypes = [
      isSuperAdmin && {
        id: 1,
        name: 'Public',
        description: 'PublicDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 2,
        name: 'Organisations',
        description: 'OrganisationsDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 3,
        name: 'Organisation Groups',
        description: 'OrganisationGroupsDescription'
      },
      { id: 4, name: 'Specific User', description: 'SpecificUserDescription' },
      {
        id: 5,
        name: 'Marketplace',
        description: 'MarketPlaceDescription',
        disabled: !this.state.enableMarketplace
      }
    ].filter(i => !!i)

    const filteredList =
      !_.isEmpty(list) &&
      list.filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))

    return (
      <Fragment>
        <section className="section">
          <div className="head head_nopadding">
            <div className="head__title">{t('title')}</div>
            <div className="head__subtitle">{t('maze_subtitle')}</div>
          </div>
        </section>

        <section className="section section_divider">
          <Spin spinning={!mazeStartDate}>
            <Page>
              <Page.Field>
                <DatePicker
                  onChange={this.handleSelectedDate}
                  startValue={startValue}
                  endValue={endValue}
                />
              </Page.Field>
              <Page.Field>
                <PrivacySelector
                  selected={selectedType}
                  types={privacyTypes}
                  isAdmin={isSuperAdmin || isAdmin}
                  onChange={this.handleSelectedType}
                  checkSelectedType={this.checkSelectedType}
                />
              </Page.Field>
              {this.checkSelectedType(2) && (
                <Page.Field>
                  <OrganizationPicker
                    activeOrg={active_organization_id}
                    list={filteredList}
                    selectedOrganizations={selectedOrganizations}
                    onChange={this.handleSelectedOrganizations}
                  />
                </Page.Field>
              )}
              {this.checkSelectedType(3) && (
                <Page.Field>
                  <GroupPicker
                    list={filteredList}
                    selectedGroups={selectedGroups}
                    onChange={this.handleSelectedGroups}
                    onChangeAll={this.handleSelectedAllOrganizations}
                  />
                </Page.Field>
              )}
              {this.checkSelectedType(4) && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:users_emails')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        id="emails"
                        rows={3}
                        onChange={e => this.handleInsertUsers(e.target.value)}
                        defaultValue={emails.join('\n')}
                      />
                    </Field.Control>
                    <span>{t('labels:description_specifiction_email')}</span>
                  </Field>
                </Page.Field>
              )}
              {selectedType.some(item => item > 0) && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:disclaimer')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        disabled
                        rows={6}
                        style={{ resize: 'none' }}
                        value={t('publishmaze:disclaimer')}
                      />
                    </Field.Control>
                  </Field>
                  <Field>
                    <Field.Label>
                      <Checkbox
                        value={checkedTerms}
                        onChange={this.handleChangeTerms}
                      >
                        <Trans i18nKey="labels:terms">
                          {t('i_agree_to_these')}
                          <a href="/pages/terms-conditions" target="_blank">
                            {t('terms_and_conditions')}
                          </a>
                        </Trans>
                      </Checkbox>
                    </Field.Label>
                  </Field>
                </Page.Field>
              )}
            </Page>
          </Spin>
        </section>
        {isCheckModal && (
          <Modal
            visible={isCheckModal}
            centered
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactSVG src="/images/icons/menu/bells.svg" />
                <div style={{ paddingLeft: '2rem' }}>
                  {t('labels:check_user')}
                </div>
              </div>
            }
            onCancel={this.closeCheckModal}
            footer={[
              <Button key="back" shape="round" onClick={this.closeCheckModal}>
                No
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="primary"
                onClick={this.handleSendEmail}
              >
                Yes
              </Button>
            ]}
          >
            {!_.isEmpty(isNotExist) && (
              <div style={{ marginBottom: '1rem' }}>
                <p style={{ color: '#333333' }}>{t('labels:user_not_exist')}</p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotExist) &&
                    isNotExist.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!_.isEmpty(isNotInOrg) && (
              <div>
                <p style={{ color: '#333333' }}>
                  {t('labels:user_not_exist_in_org')}
                </p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotInOrg) &&
                    isNotInOrg.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Modal>
        )}
        <Page.Footer>
          <div className="p-footer">
            <div className="p-footer__inner">
              {this.checkSelectedType(1) && (
                <div className="p-footer__item1">
                  <Checkbox onChange={this.onAdminOnly} checked={isAdminOnly}>
                    {general.admin_only}
                  </Checkbox>
                </div>
              )}
              {(this.checkSelectedType(2) || this.checkSelectedType(3)) && (
                <div className="p-footer__item1">
                  <Checkbox onChange={this.onSendEmail} checked={isSendMail}>
                    {general.send_email}
                  </Checkbox>
                </div>
              )}

              <div className="p-footer__item">
                <Button
                  className="rounded"
                  size="large"
                  onClick={() => history.push(`/maze/${mazeId}/edit/scenario`)}
                >
                  {t('v4:back_to_maze')}
                </Button>
              </div>
              <div className="p-footer__item">
                <Button
                  className="rounded"
                  size="large"
                  type="green"
                  onClick={this.handleCheckUser}
                  loading={isSaving}
                  disabled={isDisable}
                >
                  {t('v4:publish_maze')}
                </Button>
              </div>
            </div>
          </div>
        </Page.Footer>
      </Fragment>
    )
  }
}

export default withTranslation('publishmaze')(PublishMaze)
