import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { en, serbia, mongolia, russian, italy, arabic, traditional_chinese, finnish, japanese, vietnamese, greek, bengali, turkish, swahili, korean, tamil, thai, hebrew, burmese, german, spanishEU, portuguese, mandarin, hindi, french, bahasa, malay, spanishUS } from './resources'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false
    },
    resources: {
      en,
      russian,
      thai,
      mongolia,
      serbia,
      hebrew,
      burmese,
      german,
      spanishEU,
      spanishUS,
      portuguese,
      mandarin,
      hindi,
      french,
      bahasa,
      malay,
      tamil,
      korean,
      turkish,
      swahili,
      greek,
      bengali,
      vietnamese,
      japanese,
      italy,
      arabic,
      traditional_chinese,
      finnish
    }
  })

export default i18n
