import {
  ADD_NOTE, ADD_NOTE_SUCCESS, ADD_NOTE_FAIL,
  CLEAR_NOTES,
  FETCH_NOTES,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAIL,
  EDIT_NOTE, EDIT_NOTE_SUCCESS,
  EDIT_NOTE_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_FAIL, DELETE_NOTE_SUCCESS,
  EDITING_NOTES, DONE_EDITING_NOTES
} from '../types'

const defaultState = {
  notes: [],
  error: {},
  isLoading: false,
  isEditing: false
}

const discussion = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_NOTES_SUCCESS:
      return {
        ...state,
        notes: action.payload,
        isLoading: false
      }

    case ADD_NOTE:
    case FETCH_NOTES:
    case EDIT_NOTE:
    case DELETE_NOTE:
      return {
        ...state,
        isLoading: true
      }
 
    case DELETE_NOTE_SUCCESS:
      let filteredArray = [...state.notes].filter((item) => {
        return item.id !== decodeURIComponent(action.payload)
      })
      return {
        ...state,
        notes: filteredArray,
        isLoading: false
      }

    case EDIT_NOTE_SUCCESS:
      let newState = [...state.notes]
      let noteIndex = newState.findIndex((item) => item.id === decodeURIComponent(action.payload.noteId))
      newState[noteIndex] = { ...newState[noteIndex], body: action.payload.content }

      return {
        ...state,
        notes: newState,
        isLoading: false
      }

    case ADD_NOTE_SUCCESS:
      const notes = [...state.notes]
      notes.push(action.payload)
      return {
        ...state,
        notes: notes,
        isLoading: false
      }

    case DELETE_NOTE_FAIL:
    case FETCH_NOTES_FAIL:
    case EDIT_NOTE_FAIL:
    case ADD_NOTE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }

    case EDITING_NOTES:
      return {
        ...state,
        isEditing: true
      }

    case DONE_EDITING_NOTES:
      return {
        ...state,
        isEditing: false
      }

    case CLEAR_NOTES: {
      return {
        ...state,
        notes: []
      }
    }
    default:
      return state
  }
}

export default discussion
