import React, { useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import className from 'classnames'
import moment from 'moment'
import UserImage from '../../assets/user.svg'
import './Chat.scss'

const Chat = props => {
  const {
    available,
    t,
    messages,
    isChatAble,
    isPast,
    user,
    currentPlayTime,
    isEnd
  } = props
  const [textChat, setTextChat] = useState('')

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })

    const chatContent = document.getElementById('chat-content')
    if (chatContent) {
      chatContent.scrollTop = chatContent.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendMessage = e => {
    const { sendMessage } = props
    if (sendMessage) {
      if (e.keyCode === 13) {
        e.preventDefault()
        sendMessage(textChat.trim())
        setTextChat('')
      }
    }
  }

  useEffect(() => {
    scrollToBottom('chat-content')
  }, [])

  if (!available) {
    return (
      <div className="chat">
        <div className="chat__head">{t('labels:live_chat')}</div>
        <div className="chat__disabled">{t('labels:chat_available')}</div>
      </div>
    )
  }

  const messageToShow = useMemo(() => {
    if (!isPast) return messages

    const pastMessages = messages.filter(
      message => currentPlayTime >= message.time || isEnd
    )
    return pastMessages
  }, [isPast, messages, currentPlayTime, isEnd])
  return (
    <div className="chat">
      <div className="chat__inner">
        <div className="chat__content" id="chat-content">
          {messageToShow.map((message, index) => (
            <div
              className={className('chat__message', {
                chat__message_my: message.user_name === user.info.username
              })}
            >
              <div
                className={className('chat-message', {
                  'chat-message_my': message.user_name === user.info.username
                })}
              >
                <div className="chat-message__data">
                  <ChatMessageInfo user={user} message={message} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`chat__footer`}>
        <textarea
          disabled={!isChatAble || isPast}
          value={textChat}
          type="text"
          className="chat__input"
          placeholder={t('placeholders:chat')}
          onChange={e => setTextChat(e.target.value)}
          onKeyDown={e => {
            sendMessage(e)
          }}
        />
        <div className="scroll_to_here" ref={messagesEndRef} />
      </div>
    </div>
  )
}

const ChatProfileLogo = ({ message }) => {
  const photo = message.avatar ? message.avatar : UserImage
  return (
    <div className="chat-message__image">
      <img src={photo} alt="" className="chat-message__avatar" />
    </div>
  )
}

const ChatMessageInfo = ({ user, message }) => {
  const isOwnChat = ({ info }, { user_name }) => user_name !== info.username
  const handleTimeStamp = ({ timeStamp }) => {
    if (timeStamp) {
      const newTimeStamp = moment(timeStamp).format()
      return moment(newTimeStamp).format('DD MMM YYYY HH:mm')
    }
    return ''
  }

  function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.split(urlRegex).map(part => {
      if (part.match(urlRegex)) {
        return (
          <a target="_blank" href={part} rel="noopener noreferrer">
            {part}
          </a>
        )
      }
      return part
    })
  }

  if (isOwnChat(user, message)) {
    return (
      <div className="chat-message__author">
        <ChatProfileLogo message={message} />
        <div className="chat-message__content chat-message__viewer">
          <span className="chat-message__username">
            {handleTimeStamp(message)}
          </span>
          <div className={className('chat-message__wrap_info')}>
            <span className="chat-message__username">
              {message.user_name} :
            </span>
            <span className="chat-message__text">
              {' '}
              {urlify(message.content)}
            </span>
          </div>
        </div>
      </div>
    )
  }
  return <span className="chat-message__text"> {urlify(message.content)}</span>
}

export default withTranslation()(Chat)
