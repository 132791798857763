import React from 'react'
import { Row, Col, Form, Input, Checkbox } from 'antd'
import './index.css'

const MarketPlaceBottomSection = props => {
  const {
    form: { getFieldDecorator },
    courseBuilder: {
      courseDescription,
      courseOutline,
      courseObjectives,
      courseMarketPlaceDescription,
      courseShareProfile
    },
    onDescription,
    onObjectives,
    onOutline,
    onShareProfile,
    onMarketPlaceDescription,
    isMarketPlace,
    t
  } = props

  const handleDescription = (val, field) => {
    switch (field) {
      case 'description':
        onDescription(val)
        break
      case 'course_obj':
        onObjectives(val)
        break
      case 'course_outline':
        onOutline(val)
        break
      case 'course_description':
        onMarketPlaceDescription(val)
        break
      default:
        break
    }
  }

  const generateEditor = (title, params, description) => {
    return (
      <div className="margin_top ">
        <Form.Item label={title} colon={false} required={false}>
          {getFieldDecorator(params, {
            rules: [
              {
                required: params === 'description' ? false : true,
                message: t(`errors:${params}`)
              }
            ],
            initialValue: description
          })(
            <Input.TextArea
              placeholder={t('v4:text')}
              autoSize={{ minRows: 5, maxRows: 20 }}
              onKeyDown={e => {
                if (
                  e.keyCode === 32 &&
                  (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                    e.target.value.charAt(e.target.selectionStart) === ' ')
                ) {
                  e.preventDefault()
                }
              }}
              onChange={e => handleDescription(e.target.value, params)}
              style={{ fontSize: '1.6rem', resize: 'none' }}
              wrap={'soft'}
            />
          )}
        </Form.Item>
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      <Row className="quill-div_row">
        <Col span={24}>
          {isMarketPlace && (
            <>
              {generateEditor(
                t('v4:course_objectives'),
                t('v4:course_obj'),
                courseObjectives
              )}
              {generateEditor(
                t('v4:course_outlines'),
                t('v4:course_outline'),
                courseOutline
              )}
              {generateEditor(
                t('labels:description'),
                t('v4:course_description'),
                courseMarketPlaceDescription
              )}
            </>
          )}
          {!isMarketPlace &&
            generateEditor('Description', 'description', courseDescription)}
        </Col>
      </Row>
      {isMarketPlace && (
        <Row>
          <Col span={24}>
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('is_shared_profile', {
                valuePropName: 'checked',
                initialValue: courseShareProfile
              })(
                <Checkbox
                  className="marketplace__checkbox"
                  onChange={e => onShareProfile(e.target.checked)}
                >
                  {t('v3:allow_sharing_trainer_info')}
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  )
}

export { MarketPlaceBottomSection }
