import React from 'react'
import { Checkbox } from 'antd'
import { isEmpty } from 'lodash'
import { Field, Row, Col } from '../../styles'
import {
  Organization,
  Groups,
  Group
} from '../../styles/blocks/orgGroupSelector'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

function GroupPicker(props) {
  const handleChange = (id, orgId) => {
    const { onChange } = props

    if (onChange) {
      onChange(id, orgId)
    }
  }

  const handleSelectAll = organizationId => {
    const { onChangeAll } = props

    if (onChangeAll) {
      onChangeAll(organizationId)
    }
  }

  const { selectedGroups = [], activeOrgId } = props

  const orgList = useSelector(state => state.organizations.list.rows)
  const user = useSelector(state => state.user)
  const { t } = useTranslation()

  const { isSuperAdmin } = user.info

  const list =
    !isEmpty(orgList) &&
    orgList
      .filter(i => isSuperAdmin || (i.role && i.role.name === 'Admin'))
      .filter(i => (i.groups || []).length)

  return (
    <Field>
      <Field.Label>{t('labels:select_organization')}</Field.Label>
      <Field.Control>
        <Row style={{ marginBottom: '-3.2rem' }}>
          {list &&
            list.map(organization => {
              const { groups = [], id, logo, title } = organization
              const selectedOrgGroups = groups.filter(i =>
                selectedGroups.includes(i.id)
              )
              const checkedOrganization =
                selectedOrgGroups.length === groups.length
              const indeterminateOrganizationsStatus =
                selectedOrgGroups.length &&
                selectedOrgGroups.length < groups.length

              const isDisabled = activeOrgId !== id
              return (
                <Col key={id} md={3}>
                  <Organization
                    disabled={isDisabled}
                    onClick={() => handleSelectAll(id)}
                  >
                    <Organization.Checkbox>
                      <Checkbox
                        checked={checkedOrganization}
                        indeterminate={indeterminateOrganizationsStatus}
                      />
                    </Organization.Checkbox>

                    {logo ? (
                      <Organization.Image src={logo ? logo.link : ''} alt="" />
                    ) : (
                      <Organization.DefaultImage>
                        {title.charAt(0).toUpperCase()}
                      </Organization.DefaultImage>
                    )}
                    <Organization.Name>{title}</Organization.Name>
                  </Organization>
                  <Groups disabled={isDisabled}>
                    <Groups.List>
                      {groups.map(group => (
                        <Groups.Item key={group.id}>
                          <Group onClick={() => handleChange(group.id, id)}>
                            <Group.Checkbox>
                              <Checkbox
                                checked={selectedGroups.includes(group.id)}
                              />
                            </Group.Checkbox>
                            <Group.Name>{group.title}</Group.Name>
                          </Group>
                        </Groups.Item>
                      ))}
                    </Groups.List>
                  </Groups>
                </Col>
              )
            })}
        </Row>
      </Field.Control>
    </Field>
  )
}

export default GroupPicker
