import {
  HANDLE_MAZE_INPUT,
  RESET_MAZE_BUILDER,
  SET_MAZE_CREATION,
  CREATE_MAZE
} from '../types'

export const handleMazeInput = (field, payload) => ({
  type: HANDLE_MAZE_INPUT,
  field,
  payload
})

export const resetMazeBuilder = () => ({
  type: RESET_MAZE_BUILDER
})

export const setMazeCreation = payload => ({
  type: SET_MAZE_CREATION,
  payload
})

export const createMaze = data => {
  return dispatch => {
    dispatch({
      type: CREATE_MAZE,
      payload: data
    })
  }
}
