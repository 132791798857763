import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from '../../styles'
import { Page, Tags, Tag } from './styled'

import Dropzone from 'react-dropzone'
import history from '../../history'
import ImageCropContainer from '../../components/ImageCrop'
import { Button, Icon, Alert, Form, Input, Spin } from 'antd'
import { withTranslation } from 'react-i18next'
import { general } from '../../constants'

class Onboarding extends Component {
  state = {
    isAvatarLoading: false,
    step: 1,
    error: null,
    info: {
      name: '',
      position: '',
      topics: []
    },
    isSubmiting: false,
    currentPage: 0,
    pageSize: 5,
    image: null, //your-image-url or as base64
    file: null
  }

  handleSubmit = () => {
    const { updateUser, user, t } = this.props
    const { info } = this.state
    const userTopics = info.topics

    this.setState({
      isSubmiting: true
    })

    if (!userTopics || !userTopics.length) {
      this.setState({
        error: t('errors:topics'),
        isSubmiting: false
      })
      return
    }

    const attributes = {
      name: info.name,
      'custom:position': info.position
    }
    const dbAttributes = {
      name: info.name,
      topics: userTopics
    }

    if (updateUser) {
      updateUser(user.info.id, attributes, dbAttributes).then(() => {
        this.setState({
          isSubmiting: false
        })

        history.push('/')
      })
    }
  }

  toStep = step => {
    this.setState({
      step
    })
  }

  handleTag = tag => {
    const { info } = this.state
    const selected = info.topics.includes(tag)

    if (selected) {
      this.setState({
        info: {
          ...info,
          topics: info.topics.filter(item => item !== tag)
        }
      })
    } else {
      if (info.topics.length < 5) {
        this.setState({
          info: {
            ...info,
            topics: [...info.topics, tag]
          }
        })
      }
    }
  }

  handleDrop = dropped => {
    const file = dropped[0]

    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  handleUploadImage = UploadImageData => {
    const {
      updateUser,
      user: { info },
      upload
    } = this.props
    const opts = { isPublic: false }

    upload(UploadImageData, opts)
      .then(response => {
        updateUser(info.id, null, { avatar_id: response.id })
      })
      .then(() => {
        this.setState({
          isAvatarLoading: false,
          file: null
        })
      })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null,
        isAvatarLoading: true
      })
    } else {
      this.setState({
        image: null,
        file: null,
        isAvatarLoading: false
      })
    }
  }

  handleFormSubmit = e => {
    e.preventDefault()
    const {
      form: { validateFields }
    } = this.props

    validateFields((err, values) => {
      if (!err) {
        this.setState({
          info: {
            ...this.state.info,
            name: values.name,
            // organization: values.organization,
            position: values.position
          }
        })
        this.toStep(2)
      }
    })
  }

  handlePage = index => {
    this.setState({
      currentPage: index
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      topics: { list, isFetching },
      user: {
        info: { avatar }
      },
      t
    } = this.props
    const {
      step,
      error,
      isAvatarLoading,
      info: { topics: userTopics, name, position },
      isSubmiting,
      currentPage,
      pageSize,
      image,
      file
    } = this.state

    return (
      <Fragment>
        {step === 1 && (
          <Page>
            <Container>
              <Page.Head>
                <Row>
                  <Col sm={10} smOffset={1}>
                    <Page.Heading>{t('step1_title')}</Page.Heading>
                    <Page.Description>{t('step1_subtitle')}</Page.Description>
                  </Col>
                </Row>
              </Page.Head>

              <Page.Content>
                <Page.Form>
                  <Row>
                    <Col sm={7}>
                      <Form onSubmit={this.handleFormSubmit}>
                        <Form.Item
                          label={t('labels:name')}
                          colon={false}
                          required={false}
                        >
                          {getFieldDecorator('name', {
                            initialValue: name,
                            rules: [
                              {
                                required: true,
                                message: t('errors:name')
                              }
                            ]
                          })(<Input name="name" maxLength={320} />)}
                        </Form.Item>

                        <Form.Item
                          label={t('labels:position')}
                          colon={false}
                          required={false}
                        >
                          {getFieldDecorator('position', {
                            rules: [
                              {
                                required: true,
                                message: t('errors:position')
                              }
                            ],
                            initialValue: position
                          })(<Input name="position" maxLength={320} />)}
                        </Form.Item>

                        {false && (
                          <Form.Item
                            label={t('labels:organization')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('organization', {
                              rules: [
                                {
                                  required: true,
                                  message: t('v4:organization'),
                                  max: 320
                                }
                              ],
                              initialValue: 'organization'
                            })(<Input name="organization" maxLength={320} />)}
                          </Form.Item>
                        )}
                      </Form>
                    </Col>
                    <Col sm={5}>
                      <Page.Field>
                        <Page.Field.Label>{t('labels:photo')}</Page.Field.Label>
                        <Spin spinning={isAvatarLoading}>
                          <Dropzone
                            accept="image/*"
                            onDrop={this.handleDrop}
                            style={{}}
                          >
                            {({ isDragActive }) => (
                              <Page.Dropzone
                                active={isDragActive}
                                background={avatar ? avatar.link : ''}
                              >
                                {isDragActive ? (
                                  <Page.Dropzone.Text>
                                    {t('labels:drop_file')}
                                  </Page.Dropzone.Text>
                                ) : (
                                  <Page.Dropzone.Button hide={avatar}>
                                    <Icon type="picture" />
                                    {t('buttons:upload_photo')}
                                  </Page.Dropzone.Button>
                                )}
                              </Page.Dropzone>
                            )}
                          </Dropzone>
                        </Spin>
                      </Page.Field>
                    </Col>
                  </Row>
                </Page.Form>
              </Page.Content>
              {image && (
                <ImageCropContainer
                  image={image}
                  handleUploadImage={this.handleUploadImage}
                  handleClosePopup={this.handleClosePopup}
                  file={file}
                />
              )}
            </Container>
          </Page>
        )}
        {step === 2 && (
          <Page>
            <Container>
              <Page.Head>
                <Row>
                  <Col sm={10} smOffset={1}>
                    <Page.Heading>{t('step2_title')}</Page.Heading>
                    <Page.Description>{t('step2_subtitle')}</Page.Description>
                  </Col>
                </Row>
              </Page.Head>

              <Page.Content>
                <Row>
                  <Col sm={12}>
                    {error && (
                      <Page.Error>
                        <Alert type="error" message={error} />
                      </Page.Error>
                    )}
                    <Spin spinning={isFetching}>
                      <Tags>
                        <Tags.List>
                          {list
                            .slice(
                              currentPage * pageSize,
                              currentPage * pageSize + pageSize
                            )
                            .map(topic => (
                              <Tags.Item key={topic.id}>
                                <Tag
                                  onClick={() => this.handleTag(topic.id)}
                                  selected={userTopics.includes(topic.id)}
                                >
                                  {topic.title}
                                </Tag>
                              </Tags.Item>
                            ))}
                        </Tags.List>
                        <Tags.Pagination>
                          {[...Array(Math.floor(list.length / pageSize))].map(
                            (val, index) => (
                              <Tags.Page
                                key={index}
                                selected={index === currentPage}
                                onClick={() => this.handlePage(index)}
                              />
                            )
                          )}
                        </Tags.Pagination>
                      </Tags>
                    </Spin>
                  </Col>
                </Row>
              </Page.Content>
            </Container>
          </Page>
        )}

        <Page.Footer>
          <Row>
            <Col sm={6} align="left">
              {step === 2 && (
                <Button
                  className="rounded"
                  size="large"
                  onClick={() => this.toStep(1)}
                  style={{
                    minWidth: '15rem',
                    border: 'none',
                    color: '#0068FF',
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    fontFamily: 'Mulish'
                  }}
                >
                  <Icon type="left" /> {t('buttons:previous_step')}
                </Button>
              )}
            </Col>
            <Col sm={6} align="right">
              {step === 1 && (
                <Button
                  className="rounded"
                  size="large"
                  type="primary"
                  onClick={this.handleFormSubmit}
                  style={{
                    minWidth: '15rem',
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    fontFamily: 'Mulish'
                  }}
                >
                  {t('buttons:next')}{' '}
                  <Icon
                    type="right"
                    style={{
                      position: 'absolute',
                      right: '1.6rem',
                      top: '1.3rem'
                    }}
                  />
                </Button>
              )}
              {step === 2 && (
                <Button
                  className="rounded"
                  size="large"
                  type="primary"
                  style={{
                    minWidth: '15rem',
                    fontSize: '1.4rem',
                    fontWeight: 'bold',
                    fontFamily: 'Mulish'
                  }}
                  onClick={this.handleSubmit}
                  loading={isSubmiting}
                  disabled={isSubmiting}
                >
                  {t('buttons:finish')}{' '}
                  <Icon
                    type="right"
                    style={{
                      position: 'absolute',
                      right: '1.6rem',
                      top: '1.3rem'
                    }}
                  />
                </Button>
              )}
            </Col>
          </Row>
        </Page.Footer>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('onboarding')(Onboarding))
