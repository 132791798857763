import React from 'react'
import { Button, Modal } from 'antd'
import ReactPlayer from 'react-player'
import history from '../../../../../history'
import './styles.scss'
import { useTranslation, withTranslation } from 'react-i18next'

const InformationModal = props => {
  const { t } = useTranslation()
  console.log('infomodalprops', props)
  const {
    title,
    description1,
    description2,
    id,
    icon,
    videoURL,
    URL
  } = props.currentInfo

  return (
    <>
      <Modal
        style={{ top: 50 }}
        bodyStyle={{ height: '90vh' }}
        visible={props.visible}
        onCancel={props.onCancel}
        centered
        footer={null}
        width="80%"
        className="information-modal"
      >
        <div className="info-modal-header">
          <div className="info-modal-header-title">
            <img src={icon} alt="" />
            <h2>{title}</h2>
          </div>
          <h3>{description1}</h3>
          <h3>{description2}</h3>
          <ReactPlayer
            className="information-react-player"
            url={videoURL}
            height="95%"
            width="75%"
            controls="true"
            //   onEnded={onFirstShow}
          />
          <Button
            shape="circle"
            size="large"
            className="info-modal-button"
            type="primary"
            onClick={() => {
              props.closeModal()
              if (id === 5) {
                props.redirectVoice()
              } else {
                history.push(URL)
              }
            }}
          >
            {t('v4:get_started')}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default withTranslation()(InformationModal)
