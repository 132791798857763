import React, { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { popUpAudioMixer, popUpAvatar } from '../../images'
import { Col, Icon, Row } from 'antd'
import { AudioPopCard } from './styled'

const AudioPopUpCard = props => {
  const [hide, setHide] = useState(false)
  const { t } = useTranslation()
  return (
    <Fragment>
      {!hide && (
        <AudioPopCard>
          <AudioPopCard.Img>
            <img src={popUpAvatar} alt="" />
            <img src={popUpAudioMixer} alt="" />
          </AudioPopCard.Img>
          <AudioPopCard.P>
            <p>
              Where you can use many voice stlye over the text that you type bla
              Bla bla Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s,
            </p>
          </AudioPopCard.P>

          <Row>
            <Col span={12}>
              <AudioPopCard.Span onClick={() => setHide(true)}>
                <span>
                  <Icon type="close" />
                </span>
                Close
              </AudioPopCard.Span>
            </Col>
            <Col span={12}>
              <AudioPopCard.Span>
                <AudioPopCard.Span.TextSuccess>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={props.onShowCreateModal}
                  >
                    {t('media:go_create_audio')}
                  </span>
                </AudioPopCard.Span.TextSuccess>
              </AudioPopCard.Span>
            </Col>
          </Row>
        </AudioPopCard>
      )}
    </Fragment>
  )
}

export default AudioPopUpCard
