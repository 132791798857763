import styled from 'styled-components'
import { media } from '../../../styles'
import { styleguide } from '../../../constants'

const { typography } = styleguide

export const Container = styled.div`
  width: 100%;
  padding: 3rem 0;

  ${media.md`
    padding: 1rem 0;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .search {
    span {
      input {
        width: 300px;
      }
    }
  }
`

export const HelpMessage = styled.p`
  font-size: 300;
  font-size: ${typography.h1FontSize};
  font-style: italic;
  text-align: center;
`
