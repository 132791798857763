/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-26 13:55:28
 * @LastEditTime: 2019-08-27 14:04:28
 * @LastEditors: Please set LastEditors
 */
import http from './http'

/**
 * Get list of lessons.
 * @param {object} params {{limit: integer, offset: integer, search: string, order: string}}
 * order should be 'field, [asc, desc]'
 */
function getLessonsList (params) {
  params.order = 'created_at'
  return http({
    method: 'GET',
    url: '/lessons/cloneable',
    params
  })
}

/**
 * Get single lesson.
 * @param {number} id
 */
function getLessonById (id) {
  return http({
    method: 'GET',
    url: '/lessons/' + id
  })
}

/**
 * Create new course.
 * @param {object} data
 * @param {number} id
 */
function lessonCreate (data, id) {
  return http({
    method: 'POST',
    url: `/courses/${id}/lessons`,
    data
  })
}

/**
 * Update lesson.
 * @param {object} data
 * @param {number} id
 */
function lessonUpdate (data, id) {
  return http({
    method: 'PUT',
    url: '/lessons/' + id,
    data
  })
}

/**
 * Delete lesson.
 * @param {number} id
 */
function lessonDelete (id) {
  return http({
    method: 'DELETE',
    url: '/lessons/' + id
  })
}

function cloneLessonToCourse (courseId, lessonId) {
  return http({
    method: 'POST',
    url: `/courses/${courseId}/lessons/${lessonId}/clone`
  })
}

export {
  getLessonsList,
  getLessonById,
  lessonCreate,
  lessonUpdate,
  lessonDelete,
  cloneLessonToCourse
}
