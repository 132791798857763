import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Field } from '../../../../../../../styles'
import { Input, Checkbox } from 'antd'

import { useSelector } from 'react-redux'

import styled from 'styled-components'
import {
  GroupPicker,
  OrganizationPicker,
  PrivacySelector
} from '../../../../../../../components'
import { general } from '../../../../../../../constants'

import ChannelContext from '../../ChannelContext'

const ChannelFormPublish = ({ onCheckedTerms }) => {
  const user = useSelector(state => state.user)
  const { active_organization_id } = user.info

  const { apiData, marketplaceData } = useContext(ChannelContext)

  const [selectedType, setSelectedType] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState([
    active_organization_id
  ])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [checkedTerms, setCheckedTerms] = useState(false)

  const { t } = useTranslation()

  const handleSelectedType = type => setSelectedType(type)

  const checkSelectedType = typeId => selectedType === typeId

  const handleSelectedOrganizations = id => {
    if (selectedOrganizations.includes(id)) {
      setSelectedOrganizations(
        selectedOrganizations.filter(organizationId => organizationId !== id)
      )
    } else {
      setSelectedOrganizations([...selectedOrganizations, id])
    }
  }

  useEffect(() => {
    if (apiData) {
      getOldSelectedType()
    }
  }, [])

  const handleSelectedGroups = groupId => {
    setSelectedGroups(
      selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId])
    )
  }

  const getOldSelectedType = () => {
    if (apiData && apiData.setting) {
      const { publish_option, organization_ids, group_ids } = apiData.setting
      handleOldType(publish_option)
      setSelectedOrganizations(organization_ids)
      setSelectedGroups(group_ids)
    }
  }

  const handleOldType = publish_option => {
    if (publish_option === general.ORGANIZATION) {
      handleSelectedType(2)
    } else if (publish_option === general.ORGANIZATION_GROUP) {
      handleSelectedType(3)
    } else if (publish_option === general.SPECIFIC_USER) {
      handleSelectedType(4)
    } else if (publish_option === general.PUBLIC) {
      handleSelectedType(1)
    } else if (publish_option === general.MARKETPLACE) {
      handleSelectedType(5)
    }
  }

  const handleChangeTerms = e => {
    const isChecked = e.target.checked
    setCheckedTerms(isChecked)
    sendApiPublishData(isChecked)
  }

  const sendApiPublishData = isChecked => {
    if (!isChecked) {
      onCheckedTerms(isChecked)
      return
    }

    const orgList = checkSelectedType(4)
      ? [...selectedOrganizations, 1]
      : selectedOrganizations.filter(organizationId => organizationId !== 1)

    const publishSettings = {
      privacy: {
        emails: [],
        group_ids: checkSelectedType(3) ? selectedGroups : [],
        is_marketplace: checkSelectedType(4),
        is_public: checkSelectedType(1),
        organization_ids: checkSelectedType(2) ? orgList : [],
        publish_option: getPublishOption()
      }
    }
    onCheckedTerms(isChecked, publishSettings)
  }

  const getPublishOption = () => {
    if (checkSelectedType(1)) {
      return general.PUBLIC
    } else if (checkSelectedType(2)) {
      return general.ORGANIZATION
    } else if (checkSelectedType(3)) {
      return general.ORGANIZATION_GROUP
    } else if (checkSelectedType(4)) {
      return general.MARKETPLACE
    } else {
      return general.SPECIFIC_USER
    }
  }

  const isPublishable = useMemo(() => !!selectedType, [selectedType])

  const isMarketPlaceDataComplete = useMemo(() => {
    return (
      marketplaceData.course_obj &&
      marketplaceData.course_outline &&
      marketplaceData.hasOwnProperty('is_free') &&
      (marketplaceData.is_free === false
        ? marketplaceData.price_monthly ||
          marketplaceData.price_yearly ||
          marketplaceData.price_one_time
        : true)
    )
  }, [marketplaceData])

  return (
    <section className="section_divider" style={{ paddingTop: '1.5rem' }}>
      <Page>
        <PrivacySelector
          selectedType={selectedType}
          onChange={handleSelectedType}
          isMarketPlaceEnabled={isMarketPlaceDataComplete}
          label={t('v3:channel')}
        />
      </Page>
      {checkSelectedType(2) && (
        <Page>
          <OrganizationPicker
            selectedType={selectedType}
            activeOrgId={active_organization_id}
            onChange={handleSelectedOrganizations}
            selectedOrganizations={selectedOrganizations}
          />
        </Page>
      )}
      {checkSelectedType(3) && (
        <Page>
          <GroupPicker
            selectedGroups={selectedGroups}
            onChange={handleSelectedGroups}
            activeOrgId={active_organization_id}
            // onChangeAll={handleSelectedAllOrganizations}
          />
        </Page>
      )}
      {isPublishable && (
        <Page>
          <Field>
            <Field.Label>{t('labels:disclaimer')}</Field.Label>
            <Field.Control>
              <Input.TextArea
                disabled
                rows={6}
                style={{ resize: 'none' }}
                value={t('events:disclaimer')}
              />
            </Field.Control>
          </Field>
          <Field>
            <Field.Label>
              <Checkbox checked={checkedTerms} onChange={handleChangeTerms}>
                <Trans i18nKey="labels:terms">
                  {t('i_agree_to_these')}
                  <a href="/pages/terms-conditions" target="_blank">
                    {t('terms_and_conditions')}
                  </a>
                </Trans>
              </Checkbox>
            </Field.Label>
          </Field>
        </Page>
      )}
    </section>
  )
}

const Page = styled.div`
  padding: 4rem 0.2rem;
`

export default ChannelFormPublish
