import classNames from 'classnames'

const positionY = 400
const positionX = 40
const customContentClass = (node = null) => {

  if (node) {
    const { x, y } = node.params
    if (y > positionY && x < positionX) {
      return classNames({
        'wrap-crop-image': true,
        'left0-hw0': true

      })
    } else if (y < positionY && x < positionX) {
      return classNames({
        'wrap-crop-image': true,
        'right0-hw0': true

      })
    } else if (y < positionY && x > positionX) {
      return classNames({
        'wrap-crop-image': true,
        'left0-hw0': true

      })
    } else {
      return classNames({
        'wrap-crop-image': true,
        'hw0': true

      })
    }
  } else {
    return classNames({
      'wrap-crop-image': true
    })
  }
}

export {
  customContentClass
}
