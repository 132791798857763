const VOICES = [
  {
    label: 'Matthew',
    value: 'Matthew',
    gender: 'Male'
  },
  {
    label: 'Amy',
    value: 'Amy',
    gender: 'Female'
  },
  {
    label: 'Emma',
    value: 'Emma',
    gender: 'Female'
  },
  {
    label: 'Brian',
    value: 'Brian',
    gender: 'Male'
  },
  {
    label: 'Kendra',
    value: 'Kendra',
    gender: 'Female'
  },
  {
    label: 'Kimberly',
    value: 'Kimberly',
    gender: 'Female'
  }
]

export default { VOICES }
