import styled from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Page = styled.div`
  padding: 8rem 0 16rem 0;
`

Page.Head = styled.div`
  text-align: center;
  margin-bottom: 6.4rem;
`

Page.Heading = styled.h1`
  margin-bottom: 1.6rem;
`

Page.Description = styled.h3`
  font-size: 1.6rem;
`

Page.Content = styled.div`

`

Page.Footer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5rem 4rem;
  background: ${colors.white};
  border-top: 1px solid ${colors.darkGreyThree};
`

Page.Form = styled.div``

Page.Field = styled.div`
  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }
`

Page.Field.Label = styled.label`
  display: block;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: .4rem;
`

Page.Field.Input = styled.input`
  width: 100%;
  padding: .8rem;
  border: 1px solid ${colors.black};
  outline: none;
  transition: border-color .2s;

  &:focus {
    border-color: ${colors.blue};
  }
`

Page.Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24rem;
  border: 1px dashed ${colors.black};
  transition: border-color .2s;
  cursor: pointer;

  &:hover {
    border-color: ${colors.blue};
  }

  ${props => props.active && `
    background: rgba(0, 104, 255, 0.1);
    border-color: ${colors.blue};
  `}

  ${props => props.background && `
    background-image: url(${props.background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`

Page.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`
Page.Dropzone.Button = styled.div`
  padding: .8rem 2.4rem;
  text-decoration: uppercase;
  border: 1px solid ${colors.darkGreyThree};
  border-radius: 5rem;

  display: flex;
  align-items: center;
  transition: border-color .2s, color .2s, opacity .2s;;

  i {
    margin-right: .8rem;
  }

  ${Page.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props => props.hide && `
      background: rgba(255, 255, 255, 0.3);
      border-color: ${colors.white};
      color: ${colors.white};
      opacity: 1;
    `}
  }

  ${props => props.hide && `
    opacity: 0;
    border-color: ${colors.white};
    color: ${colors.white};
  `}
`

Page.Error = styled.div`
  margin-bottom: 2.4rem;
`

const Tags = styled.div`

`

Tags.List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

Tags.Item = styled.div`
  margin: 0 1.6rem 1.6rem 0;
`

Tags.Pagination = styled.div`
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
`
Tags.Page = styled.div`
  margin: .5rem;
  width: 1rem;
  height: 1rem;
  background: ${colors.darkGreyTwo};
  border-radius: 50%;
  
  &:hover {
    background: ${colors.blue};
  }

  ${props => props.selected && `
  background: ${colors.darkGreyOne};
`}
`

const Tag = styled.div`
  border: 1px solid ${colors.blue};
  border-radius: .4rem;
  color: ${colors.blue};
  padding: .8rem 1.6rem;
  font-weight: 700;
  transition: background .2s, color .2s;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: rgba(0, 104, 255, 0.1);
  }

  ${props => props.selected && `
    background: ${colors.blue};
    color: ${colors.white};

    &:hover {
      background: ${colors.blue};
    }
  `}
`

export {
  Page,
  Tags,
  Tag
}
