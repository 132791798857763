import React from 'react'
import { Icon } from 'antd'

import { Progress } from './styled'

const ProgressBar = ({ percent, ...rest }) => {
  return (
    <Progress {...rest}>
      <Progress.Percent percent={percent}/>
      {percent === 100 &&
      <Progress.Finish>
        <Icon type='check' className='progress-bar-icon'/>
      </Progress.Finish>
      }
    </Progress>
  )
}

export default ProgressBar
