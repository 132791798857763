/*
 * @Author: your name
 * @Date: 2019-11-28 10:55:23
 * @LastEditTime: 2019-11-28 10:55:32
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Events/Details/styled.js
 */

import { Icon } from 'antd'
import styled from 'styled-components'
import { Button, Collapse } from 'antd'
import { styleguide } from '../../../constants'
import { Filter } from '../../../components'
import { Head, Container } from '../../../styles'

const { colors } = styleguide

const DropzoneLoading = styled(Icon)`
  && {
    font-size: 3.6rem;
    color: ${colors.blue};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 12.8rem;
  }
`

const modal_style = {
  overflow: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}

const Page = styled.div`
  padding: 4rem 0.2rem;
`

Page.Head = styled(Head)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`

Page.Head.Button = styled(Button)`
  && {
    height: 4rem;
    padding: 0 4rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`
Page.Head.Search = styled.div`
  margin-right: 2rem;
  width: 530px;
  display: flex;
  align-items: center;
  .rounded-select {
    min-width: 140px;
    .ant-select-selection--single {
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0 4rem;
      border-radius: 50px;
      color: #fff;
      background-color: #0068ff;
      border-color: #0068ff;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      padding: 0 10px;
    }
    svg {
      fill: white;
    }
  }
`
Page.Container = styled(Container)``

const Column = styled.div``
const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

Page.Filter = styled(Filter)`
  && {
    justify-content: flex-start;
  }
`
const EventContainer = styled.div`
  width: 100%;
  padding: 3.2rem;
  padding-top: 0;
`

const CollapseContainer = styled(Collapse)`
  && {
    & .ant-collapse-item {
      border: 0;
      margin-bottom: 0.5rem;

      & .ant-collapse-content {
        & .ant-collapse-content-box {
          padding: 0 3rem 1.5rem 2.3rem;
          display: block !important;
        }
      }
    }
  }
`
CollapseContainer.Panel = styled(Collapse.Panel)``

export {
  modal_style,
  DropzoneLoading,
  Page,
  Row,
  Column,
  EventContainer,
  CollapseContainer
}
