import { combineReducers } from 'redux'

import user from './user'
import menu from './menu'
import loading from './loading'
import courseBuilder from './courseBuilder'
import lessonBuilder from './lessonBuilder'
import lessons from './lessons'
import courses from './courses'
import organizations from './organizations'
import annotation from './annotation'
import roles from './roles'
import quizes from './quizes'
import library from './library'
import reports from './reports'
import assessments from './assessments'
import discussion from './discussion'
import events from './events'
import topics from './topics'
import audioSynthesizer from './audioSynthesizer'
import pageBuilder from './pageBuilder'
import notes from './notes'
import badge from './badge'
import mazeBuilder from './mazeBuilder'
import maze from './maze'
import skillJourney from './skillJourney'
import reviews from './reviews'
import channel from './channel'
import trivia from './trivia'

export default combineReducers({
  user,
  menu,
  loading,
  courseBuilder,
  lessonBuilder,
  lessons,
  courses,
  organizations,
  annotation,
  roles,
  quizes,
  library,
  reports,
  assessments,
  discussion,
  events,
  topics,
  audioSynthesizer,
  pageBuilder,
  notes,
  badge,
  mazeBuilder,
  maze,
  skillJourney,
  reviews,
  channel,
  trivia
})
