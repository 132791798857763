import {
  LOAD_DISCUSSION_BY_EVENT,
  CLEAR_DISCUSSIONS,
  ADD_COMMENT,
  FETCH_DISCUSSION_BY_EVENT,
  FETCH_DISCUSSION_BY_EVENT_SUCCESS,
  FETCH_DISCUSSION_BY_EVENT_FAIL,
  POST_COMMENT,
  REPLY_TO,
  EDIT_COMMENT,
  EDIT_COMMENT_SUCCESS,
  EDIT_COMMENT_FAILURE,
  EDITING_MESSAGE,
  DONE_EDITING_MESSAGE,
  DELETE_COMMENT,
  DELETE_COMMENT_FAIL,
  DELETE_COMMENT_SUCCESS,
  FETCH_EVENT_LOG,
  FETCH_EVENT_LOG_SUCCESS,
  FETCH_EVENT_LOG_FAIL
} from '../types'

const defaultState = {
  discussionByEvent: [],
  isFetching: false,
  activeReplyId: null,
  error: {},
  isLoading: false,
  isEditing: false,
  postingComment: false,
  log: []
}

const discussion = (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_DISCUSSION_BY_EVENT:
      return {
        ...state,
        discussionByEvent: action.payload.data
      }

    case FETCH_DISCUSSION_BY_EVENT:
      return {
        ...state,
        isFetching: true
      }

    case FETCH_DISCUSSION_BY_EVENT_SUCCESS:
      return {
        ...state,
        isFetching: false
      }

    case FETCH_DISCUSSION_BY_EVENT_FAIL:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }

    case CLEAR_DISCUSSIONS:
      return {
        ...state,
        discussionByEvent: null
      }

    case POST_COMMENT:
      return {
        ...state,
        postingComment: action.payload
      }

    case REPLY_TO: {
      return {
        ...state,
        activeReplyId: action.payload
      }
    }

    case ADD_COMMENT:
      const newComment = { ...action.payload, reply: [], replies: 0 }
      const newState = state.discussionByEvent
        ? [...state.discussionByEvent]
        : []
      let newLog = [...state.log]
      if (newComment.parent_id) {
        const parent = newState.find(item => item.id === newComment.parent_id)
        const index = newState.indexOf(parent)
        newState.splice(index, 1, {
          ...parent,
          replies: parent.replies + 1,
          reply: [...parent.reply, newComment]
        })
      } else {
        newState.push(newComment)
        if(newLog[0]) {
          newLog[0].summary.current_messages += 1
        }
      }

      return {
        ...state,
        discussionByEvent: newState,
        activeReplyId: null,
        postingComment: false,
        log: newLog
      }

    case DELETE_COMMENT:
    case EDIT_COMMENT:
      return {
        ...state,
        isLoading: true
      }
    case EDIT_COMMENT_SUCCESS:
      let newArray = [...state.discussionByEvent]
      const editedArray = newArray.map(item => {
        if (
          item.replies === 0 &&
          item.id === decodeURIComponent(action.payload.commentId)
        ) {
          return {
            ...item,
            body: action.payload.content
          }
        } else if (item.replies > 0) {
          return {
            ...item,
            reply: item.reply.map(o => {
              if (o.id === decodeURIComponent(action.payload.commentId)) {
                return {
                  ...o,
                  body: action.payload.content
                }
              }
              return o
            })
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        discussionByEvent: editedArray,
        isLoading: false
      }
    case DELETE_COMMENT_FAIL:
    case EDIT_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    case EDITING_MESSAGE:
      return {
        ...state,
        isEditing: true
      }
    case DONE_EDITING_MESSAGE:
      return {
        ...state,
        isEditing: false
      }
    case DELETE_COMMENT_SUCCESS:
      const filteredArray = [...state.discussionByEvent].reduce((acc, item) => {
        if (item.id === decodeURIComponent(action.payload)) return acc
        if (
          item.replies === 0 &&
          item.id !== decodeURIComponent(action.payload)
        ) {
          acc.push(item)
        } else if (item.replies > 0) {
          acc.push({
            ...item,
            reply: item.reply.filter(
              o => o.id !== decodeURIComponent(action.payload)
            )
          })
        } else {
          acc.push(item)
        }
        return acc
      }, [])
      return {
        ...state,
        discussionByEvent: filteredArray,
        isLoading: false
      }

    case FETCH_EVENT_LOG:
      return {
        ...state,
        isLoading: true
      }

    case FETCH_EVENT_LOG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        log: action.payload
      }

    case FETCH_EVENT_LOG_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default discussion
