import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { isSafari } from 'react-device-detect'
import {
  Button,
  Row,
  Col,
  Tabs,
  Input,
  List,
  Avatar,
  Tooltip,
  Select,
  Modal,
  Form,
  InputNumber,
  Popover,
  Spin
} from 'antd'
import { PlayCircleOutlined, CloseOutlined } from '@ant-design/icons'
import { activateSoundIcon, deactiveSoundIcon } from '../../../images'
import { AudioPlayer } from '../../../components'
import { saveBase64AsAudioFile } from '../../../../../../../../../../utils'
import './CreateAudio.scss'
import Autocomplete from 'react-autocomplete'
import styles from './CreateAudio.style'
import { translationKeys } from '../../../../../../../../../../constants'
import { api } from '../../../../../../../../../../services'
import TextLibrary from '../../../../../../../../../../components/Builder/Elements/Text/internal/TextLibrary'
import {
  textIconInsideRectangle,
  textIconRectangle
} from '../../../../Text/images'
import QuestionAnswerAI from '../../../../../../../../../../components/QuestionAnswerAI/QuestionAnswerAI'

const { TabPane } = Tabs
const { TextArea } = Input
const { Option } = Select

const styleTitle = { color: 'rgb(103, 104, 108)', fontWeight: 'normal' }

class CreateAudio extends Component {
  initInterval = null
  initSummarizeInterval = null
  initGermanInterval = null
  state = {
    src: '',
    voiceList: [],
    selectedText: '',
    languageValue: '',
    languages: [],
    filteredList: [],
    selectedVoice: '',
    autoPlay: false,
    lang: '',
    showTextModal: false,
    isInsertText: false,
    selectedTextViewer: '',
    showSearchResult: false,
    isLoading: false,
    resultData: [],
    hasInitialize: true,
    loadSummarize: false,
    errorMsg: '',
    sampleSrc: '',
    summariseResultOptionOne: [],
    summariseResult: [],
    scheduleTime: null,
    audioprevLoading: false,
    hasInitializeGerman: false,
    ai_language: 'en-US',
    processAudio: false
  }

  componentDidMount() {
    const { listTTSVoices, text } = this.props
    this.setState({ selectedText: text })

    console.log('Audio Premium')

    listTTSVoices().then(res => {
      console.log('Check _listTTSVoices', res)
      if (res) {
        this.updateVoiceList(res.data)
      }
    })
    this.audioPremiumTalen6()
  }

  audioPremiumTalen6 = async () => {
    Promise.all([
      api.audioSynthesizer.getNonEnglishVoices(),
      api.user.getSharelookVoices()
    ]).then(([list1, list2]) => {
      const mockData = { ...list1.data.data, ...list2.data.data }
      const mockDataArr = Object.entries(mockData).map(item => {
        return {
          ...item,
          premium: true
        }
      })
      const mockDatavalues = Object.values(mockData)
      const mockDataArrLang = mockDatavalues.map(t => {
        return t.language
      })
      this.setState({
        ...this.state,
        voiceList: [...mockDataArr, ...this.state.voiceList],
        languages: [...this.state.languages, ...mockDataArrLang]
      })
    })
  }

  handleChangeOption = e => {
    this.setState(
      {
        lang: e
      },
      () => {
        const { selectedText } = this.state
        if (selectedText === '') {
          return
        }
        const temp = {
          text: selectedText
        }
        this.setState({
          selectedText: 'Please wait... preparing translation'
        })
        api.audioSynthesizer
          .textTranslate(temp)
          .then(res => {
            if (res.data && res.data.result) {
              const tempVar = {
                text: selectedText,
                source_language: res.data.data,
                language: e
              }
              this.setState({
                selectedText: 'Please wait... translating'
              })
              api.audioSynthesizer
                .textTranslate(tempVar)
                .then(res => {
                  this.setState({
                    selectedText: res.data.data
                  })
                })
                .catch(() => {
                  this.setState({
                    selectedText: ''
                  })
                })
            }
          })
          .catch(() => {
            this.setState({
              selectedText: ''
            })
          })
      }
    )
  }

  updateVoiceList = data => {
    const voiceArr = Object.entries(data)
    const values = Object.values(data)
    const allLanguage = values.map(t => {
      return t.language
    })
    const languages = allLanguage.reduce((all, item) => {
      return all.includes(item) ? all : [...all, item]
    }, [])

    this.setState({
      voiceList: [...voiceArr, ...this.state.voiceList],
      languages: [...languages, ...this.state.languages]
    })
  }

  onChangeText = e => {
    this.setState({
      src: '',
      autoPlay: false,
      selectedText: e.target.value
    })
  }

  onClickCard = value => {
    this.setState({
      src: '',
      autoPlay: false,
      selectedVoice: value[0]
    })
  }

  postTTS = (txt, type) => {
    const { postTTSVoices } = this.props
    const { selectedVoice } = this.state
    postTTSVoices({
      voice: selectedVoice,
      text: txt
    }).then(data => {
      if (data && data.result) {
        if (type === 'preview') {
          this.setState({
            sampleSrc:
              'data:' + data.data.mimetype + ';base64,' + data.data.audio
          })
          return
        }
        this.setState({
          src: 'data:' + data.data.mimetype + ';base64,' + data.data.audio
        })
      }
    })
  }

  onClickButton = async () => {
    const { onShowDetailModal } = this.props
    const { src, selectedText, selectedVoice } = this.state
    console.log('selectedVoice', selectedVoice)
    const premvoicelist = this.state.voiceList
      .map(item => {
        if (item.premium && item.premium === true) {
          return item[0]
        }
        return null
      })
      .filter(item => item !== null)

    if (premvoicelist.includes(selectedVoice)) {
      if (_.isEmpty(src)) {
        this.setState({
          processAudio: true
        })
      }
      if (!_.isEmpty(src)) {
        let filename = `${'voiceover' +
          Math.floor(1000 + Math.random() * 9000)}.mp3`
        const response = await fetch(src, { mode: 'cors' })
        const blob = await response.blob()
        const file = new File([blob], filename, { type: 'audio/mpeg' })
        onShowDetailModal(file, selectedText)
        return
      }
      try {
        let dataForPreview = {
          text: selectedText,
          model_name: selectedVoice
        }

        const res =
          selectedVoice.includes('matea') || selectedVoice.includes('gerel')
            ? await api.user.getNonEnglishAudioPreview(dataForPreview)
            : selectedVoice.includes('sharelook')
            ? await api.user.getEnglishAudioPreview(dataForPreview)
            : await api.audioSynthesizer.postNewAudioAdminRes(dataForPreview)
        const srcurl = res.data.s3_presigned_url
        this.setState({
          ...this.state,
          autoPlay: true,
          src: srcurl,
          processAudio: false
        })
      } catch (err) {}
      return
    }
    if (!_.isEmpty(src)) {
      let block = src.split(';')
      let dataType = block[0].split(':')[1]
      let data = block[1].split(',')[1]
      let filename = `${'voiceover' +
        Math.floor(
          1000 + Math.random() * 9000
        )}.mp3` /* @TODO Update logic later */
      let mp3File = saveBase64AsAudioFile(filename, data, dataType)
      onShowDetailModal(mp3File, selectedText)
      return
    }
    this.setState(
      {
        sampleSrc: '',
        processAudio: false
      },
      () => {
        this.postTTS(selectedText, 'create')
      }
    )
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values)
        const { ai_language } = this.state
        this.setState({
          ...this.state,
          isLoading: true,
          showSearchResult: false,
          summariseResultOptionOne: [],
          summariseResult: [],
          resultData: []
        })

        const {
          info: { active_organization_id }
        } = this.props.user

        const temp = {
          question: values.question,
          no_of_documents: String(1),
          org_id: String(active_organization_id),
          ai_language: ai_language
        }

        api.audioSynthesizer
          .questionBot(temp)
          .then(res => {
            console.log('response question bot', res.data.body)
            if (res.data.body) {
              this.setState({
                ...this.state,
                showSearchResult: true,
                isLoading: false,
                resultData: res.data.body,
                tabIndex: Array(res.data.body.length).fill('1')
              })
            }
          })
          .catch(err => {
            console.log('error in starting event', err)
            this.setState({
              ...this.state,
              showSearchResult: false,
              isLoading: false,
              resultData: []
            })
          })
      }
    })
  }

  onClickPreviewIcon = async value => {
    console.log('course', 'samefile', value)
    this.setState({
      ...this.state,
      src: '',
      autoPlay: true,
      selectedVoice: value[0],
      sampleSrc: ''
    })

    const dataForPreview = {
      text: value[1] && value[1].sample,
      model_name: value[0]
    }

    if (value.premium && value.premium === true) {
      this.setState({
        ...this.setState,
        audioprevLoading: true
      })
      try {
        const res = !value[1].file
          ? await api.user.getEnglishAudioPreview(dataForPreview)
          : value[0].includes('matea') || value[0].includes('gerel')
          ? await api.audioSynthesizer.getNonEnglishVoicePreviewByFile(
              value[1].file
            )
          : // value[0].includes('sharelook')
            await api.audioSynthesizer.getVoicePreviewByFile(value[1].file)
        const srcurl = res.data.s3_presigned_url
        this.setState({
          ...this.state,
          autoPlay: true,
          selectedVoice: value[0],
          sampleSrc: srcurl,
          audioprevLoading: false
        })
      } catch (err) {
        this.setState({
          ...this.state,
          selectedVoice: value[0],
          sampleSrc: '',
          audioprevLoading: false
        })
      } finally {
        this.setState({
          ...this.state,
          audioprevLoading: false
        })
      }
      return
    }
    const sample = value[1].sample
    this.setState(
      {
        src: '',
        autoPlay: true,
        selectedVoice: value[0],
        sampleSrc: ''
        // selectedText: value[1].sample
      },
      () => {
        this.postTTS(sample, 'preview')
      }
    )
  }

  handleClickImportText = () => {
    const { showTextModal } = this.props
    showTextModal({ isShowTextModal: true })
  }

  onLanguageSearch = e => {
    this.setState({ languageValue: e })
  }

  onCloseTextViewer = () => {
    this.setState({ selectedTextViewer: '' })
  }

  onLanguageSelect = value => {
    const { voiceList } = this.state
    const arr = []
    voiceList.forEach(v => {
      if (v[1].language === value) {
        arr.push(v)
      }
    })
    this.setState({ languageValue: value, filteredList: arr })
  }

  onHideTextModal = () => {
    const { showTextModal } = this.props
    showTextModal({ isShowTextModal: false })
  }

  showText = val => {
    this.setState({
      selectedTextViewer: val
    })
  }

  handleInsertText = () => {
    this.setState(
      {
        isInsertText: true
      },
      () => {
        const { showTextModal } = this.props
        showTextModal({ isShowTextModal: false })
      }
    )
  }

  handleClickQAText = () => {
    this.setState(prevState => ({
      ...this.state,
      isWideView: !prevState.isWideView,
      showSearchResult: false,
      isLoading: false
    }))
  }

  toggleWideView = () => {
    this.setState(
      prevState => ({
        ...this.state,
        isWideView: !prevState.isWideView
      }),
      () => {
        this.onHideTextModal()
      }
    )
  }

  handleSubmitSummarise = (e, param) => {
    e.preventDefault()
    const {
      summariseResult,
      summariseResultOptionOne,
      ai_language
    } = this.state
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form2: ', values)
        this.setState({
          ...this.state,
          idx: param.idx
        })

        const dataT1 = {
          content: param.context,
          max_length: values.num_words,
          min_length: 30,
          lang: ai_language || 'en-US'
        }

        const dataT = {
          text: param.context,
          num_words: values.num_words,
          num_keywords: 30
        }

        // Option 1
        api.summarization
          .getSummarized(dataT1, 'abstractive')
          .then(res => {
            let data = res.data
            let dataToUpdate = summariseResultOptionOne

            const isDataAlreadyExist =
              dataToUpdate && dataToUpdate.some(data => data.id === param.idx)
            if (!isDataAlreadyExist) {
              dataToUpdate = [
                ...dataToUpdate,
                {
                  id: param.idx,
                  data: data.output
                }
              ]
            }
            const fetchIndexOfExistingData = dataToUpdate.findIndex(
              data => data.id === param.idx
            )
            if (fetchIndexOfExistingData !== -1) {
              dataToUpdate[fetchIndexOfExistingData] = {
                id: dataToUpdate[fetchIndexOfExistingData].id,
                data: data.output
              }
            }
            let newTabIndex = this.state.tabIndex
            newTabIndex[param.idx] = '3'
            this.setState({
              ...this.state,
              summariseResultOptionOne: dataToUpdate,
              loadSummarize: false,
              errorMsg: '',
              tabIndex: newTabIndex
            })
          })
          .catch(err => {
            this.setState({
              loadSummarize: false,
              errorMsg:
                err.error && err.error.msg
                  ? err.error.msg
                  : 'Something went wrong.'
            })
          })

        // Option 2
        this.setState({ loadSummarize: true, errorMsg: '' }, () => {
          api.summarization
            .getSummarized(dataT, 'extractive')
            .then(response => {
              let data = response.data
              let dataToUpdate = summariseResult
              console.log('get data', data.summary)
              const isDataAlreadyExist =
                dataToUpdate && dataToUpdate.some(data => data.id === param.idx)
              if (!isDataAlreadyExist) {
                dataToUpdate = [
                  ...dataToUpdate,
                  {
                    id: param.idx,
                    data: data.summary
                  }
                ]
              }
              const fetchIndexOfExistingData = dataToUpdate.findIndex(
                data => data.id === param.idx
              )
              if (fetchIndexOfExistingData !== -1) {
                dataToUpdate[fetchIndexOfExistingData] = {
                  id: dataToUpdate[fetchIndexOfExistingData].id,
                  data: data.summary
                }
              }
              console.log(
                'final data',
                data.summary,
                'summaryData',
                dataToUpdate
              )
              let newTabIndex = this.state.tabIndex
              newTabIndex[param.idx] = '3'
              this.setState({
                ...this.state,
                summariseResult: dataToUpdate,
                loadSummarize: false,
                errorMsg: '',
                tabIndex: newTabIndex
              })
            })
            .catch(e => {
              console.log(e)
              this.setState({
                loadSummarize: false,
                errorMsg: e.error && e.error.msg && e.error.msg
                // : 'Something went wrong.'
              })
            })
        })
      }
    })
  }

  onChangeTab = (key, idx) => {
    let newTabIndex = this.state.tabIndex
    newTabIndex[idx] = key
    this.setState({ tabIndex: newTabIndex })
  }

  handleAiLanguage = value => {
    this.setState({
      ai_language: value,
      summariseResultOptionOne: [],
      summariseResult: []
    })
  }

  // handleSubmitSummarise = (e, param) => {
  //   e.preventDefault()
  //   const { summariseResult } = this.state
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       console.log('Received values of form2: ', values)
  //       this.setState({
  //         ...this.state,
  //         idx: param.idx
  //       })

  //       const dataT = {
  //         text: param.context,
  //         num_words: values.num_words,
  //         num_keywords: 35
  //       }

  //       const testURL = config.summarizeURL
  //       const myInit = {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify(dataT)
  //       }

  //       const myRequest = new Request(testURL, myInit)
  //       this.setState({ loadSummarize: true, errorMsg: '' }, () => {
  //         fetch(myRequest)
  //           .then(response => {
  //             console.log(
  //               'response question summarisation',
  //               response.body,
  //               'data summary inside',
  //               summariseResult,
  //               'param.idx',
  //               param.idx
  //             )
  //             return response.json()
  //           })
  //           .then(data => {
  //             let dataToUpdate = summariseResult
  //             console.log('get data', data.summary)

  //             const isDataAlreadyExist =
  //               dataToUpdate && dataToUpdate.some(data => data.id === param.idx)
  //             if (!isDataAlreadyExist) {
  //               dataToUpdate = [
  //                 ...dataToUpdate,
  //                 {
  //                   id: param.idx,
  //                   data: data.summary
  //                 }
  //               ]
  //             }
  //             const fetchIndexOfExistingData = dataToUpdate.findIndex(
  //               data => data.id === param.idx
  //             )

  //             if (fetchIndexOfExistingData !== -1) {
  //               dataToUpdate[fetchIndexOfExistingData] = {
  //                 id: dataToUpdate[fetchIndexOfExistingData].id,
  //                 data: data.summary
  //               }
  //             }

  //             console.log(
  //               'final data',
  //               data.summary,
  //               'summaryData',
  //               dataToUpdate
  //             )
  //             this.setState({
  //               ...this.state,
  //               summariseResult: dataToUpdate,
  //               loadSummarize: false,
  //               errorMsg: ''
  //             })
  //           })
  //           .catch(e => {
  //             console.log(e)
  //             this.setState({
  //               loadSummarize: false,
  //               errorMsg:
  //                 e.error && e.error.msg ? e.error.msg : 'Something went wrong.'
  //             })
  //           })
  //       })
  //     }
  //   })
  // }

  render() {
    const {
      t,
      audioSynthesizer: { isProcessing },
      pageBuilder: { isShowTextModal },
      getLibraryList,
      getPublicList,
      getOrgList,
      clearUploadList,
      updateLibraryStatus,
      updateOrgLib,
      handleMakeType,
      library,
      user,
      topics,
      updateTopicList,
      upload
    } = this.props
    const { getFieldDecorator } = this.props.form
    const {
      src,
      voiceList,
      selectedText,
      selectedVoice,
      languages,
      languageValue,
      filteredList,
      isInsertText,
      selectedTextViewer,
      isWideView,
      isLoading,
      showSearchResult,
      hasInitialize,
      sampleSrc,
      tabIndex,
      resultData,
      summariseResultOptionOne,
      summariseResult,
      loadSummarize,
      errorMsg,
      processAudio
    } = this.state
    console.log(
      'state in audio',
      sampleSrc,
      'languages: ',
      languages,
      'src',
      src,
      'selectedtxt',
      selectedText
    )
    let allList = []
    let maleList = []
    let femaleList = []
    let neuralList = []
    if (_.isEmpty(languageValue)) {
      allList = voiceList
      maleList = voiceList.filter(m => m[1].gender === 'Male')
      femaleList = voiceList.filter(f => f[1].gender === 'Female')
      neuralList = voiceList.filter(n => !!n[1].neural)
    } else {
      allList = filteredList
      maleList = filteredList.filter(m => m[1].gender === 'Male')
      femaleList = filteredList.filter(f => f[1].gender === 'Female')
      neuralList = filteredList.filter(n => !!n[1].neural)
    }

    const filteredLanguages = [
      ...new Set(
        languages.map(item => {
          return item
        })
      )
    ]

    console.log('voiceList', voiceList, languages)

    const textIcon = (
      <div className="toolbar__item-icon" style={styleTitle}>
        <div className="wrap-video-icons">
          <img src={textIconRectangle} alt="" />{' '}
          <span style={{ color: '#67686C' }}> {t('v4:insert_text')} </span>
          <img
            src={textIconInsideRectangle}
            alt=""
            className="text-icon-inside-rectangle"
          />
        </div>
      </div>
    )

    const summariseConfirm = param => (
      <div className="sum_form">
        <p>*{t('v2:enter_number_words')}:</p>
        <Form
          layout="inline"
          onSubmit={e => this.handleSubmitSummarise(e, param)}
        >
          <Form.Item style={{ marginBottom: '10px' }}>
            {getFieldDecorator('num_words', {
              initialValue: 100,
              rules: [{ required: true, message: t('v2:enter_number_words') }]
            })(<InputNumber min={100} />)}
          </Form.Item>
          <Button type="primary" shape="round" htmlType="submit">
            {t('v2:summarize')}
          </Button>
        </Form>
      </div>
    )

    const titleSearch = () => {
      return (
        <p>
          <strong>{t('v2:answer')} </strong>
        </p>
      )
    }

    const summarySearch = (answer, context, idx) => {
      return (
        <Popover content={summariseConfirm({ context: context, idx: idx })}>
          {t('v2:summarize')}
        </Popover>
      )
    }

    // const searchResult =
    //   showSearchResult &&
    //   this.state.resultData &&
    //   this.state.resultData.map((data, idx) => {
    //     const showSummary = this.state.summariseResult.filter(s => s.id === idx)
    //     const showSummary1 = this.state.summariseResultOptionOne.filter(
    //       s => s.id === idx
    //     )
    //     console.log('in list', showSummary, 'main idx', idx)
    //     return (
    //       <Fragment>
    //         &nbsp;
    //         <Card
    //           size="small"
    //           title={titleSearch(data.answer, data.context, idx)}
    //           style={{ width: '100%' }}
    //         >
    //           <Tabs defaultActiveKey="1" onChange={this.onChangeTab}>
    //             <TabPane tab="Answer" key="1">
    //               <p>{data.answer}</p>
    //             </TabPane>
    //             <TabPane tab="Context" key="2">
    //               <p>{data.context}</p>
    //             </TabPane>
    //             <TabPane
    //               tab={summarySearch(data.answer, data.context, idx)}
    //               // tab="Summarised"
    //               key="3"
    //             >
    //               {this.state.loadSummarize ? (
    //                 'Processing...'
    //               ) : this.state.errorMsg === '' ? (
    //                 <>
    //                   {showSummary1[0] ||
    //                     (showSummary[0] && (
    //                       <div className="sum__ans">
    //                         <div className="sum__ans_item">
    //                           <p>{t('v2:option_1')}</p>
    //                           {showSummary1[0] && showSummary1[0].data ? showSummary1[0].data :  <Spin tip="Loading..." size="small"/>}
    //                         </div>
    //                         <div className="sum__ans_item">
    //                           <p> {t('v2:option_2')}</p>
    //                           {showSummary[0] && showSummary[0].data}
    //                         </div>
    //                       </div>
    //                     ))}
    //                 </>
    //               ) : (
    //                 this.state.errorMsg
    //               )}
    //             </TabPane>
    //           </Tabs>
    //         </Card>
    //         &nbsp;
    //       </Fragment>
    //     )
    //   })

    return (
      <Fragment>
        <Modal
          className="custom-ant-modal"
          title={textIcon}
          visible={isShowTextModal}
          onCancel={this.onHideTextModal}
          width={'85%'}
          footer={[
            <Button
              type="default"
              shape="round"
              size="default"
              className="ant-btn rounded"
              onClick={this.onHideTextModal}
            >
              {t('buttons:cancel')}
            </Button>,
            <Button
              type="primary"
              shape="round"
              size="default"
              className="ant-btn rounded ant-btn-primary"
              onClick={this.handleInsertText}
            >
              {t('buttons:insert')}
            </Button>
          ]}
        >
          <TextLibrary
            isInsertText={isInsertText}
            getLibraryList={getLibraryList}
            getPublicList={getPublicList}
            getOrgList={getOrgList}
            upload={upload}
            clearUploadList={clearUploadList}
            updateLibraryStatus={updateLibraryStatus}
            updateOrgLib={updateOrgLib}
            handleMakeType={handleMakeType}
            library={library}
            user={user}
            topics={topics}
            updateTopicList={updateTopicList}
            showValue={selectedTextViewer}
            showText={this.showText}
          />
        </Modal>
        <div className="bg-modal">
          {!isWideView && (
            <Row gutter={24}>
              <Col span={16}>
                <div className="d-flex justify-content-between">
                  <div>
                    <Select
                      showSearch
                      style={{ width: '200px' }}
                      placeholder={t('v4:translate_to')}
                      optionFilterProp="children"
                      onChange={e => this.handleChangeOption(e)}
                    >
                      {translationKeys.map(item => (
                        <Option key={item.code} value={item.code}>
                          {/* <Flag code={item.code} height='13' /> */}{' '}
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                    &nbsp;&nbsp;
                    <Button
                      className="rounded"
                      size="large"
                      onClick={this.handleClickImportText}
                    >
                      {t('v4:import_from_library')}
                    </Button>
                    &nbsp;&nbsp;
                    {this.props.user &&
                      this.props.user.info &&
                      this.props.user.info.organization &&
                      this.props.user.info.organization.enable_ai && (
                        <Button
                          className="rounded"
                          type="dashed"
                          size="large"
                          onClick={this.handleClickQAText}
                        >
                          {t('v4:question_answer_ai')}
                        </Button>
                      )}
                  </div>
                </div>
                <div className="textarea-warp">
                  <TextArea
                    disabled={!!isProcessing}
                    value={selectedText}
                    onChange={this.onChangeText}
                    placeholder={t('audio:type_something')}
                    autoSize={{ minRows: 25, maxRows: 25 }}
                  />

                  <div
                    className={isSafari ? 'audio-warp-safari' : 'audio-warp'}
                  >
                    {/* {_.isEmpty(src) && <AudioPlayer src='' autoPlay={autoPlay} />}{' '} */}
                    {!_.isEmpty(src) ? (
                      <AudioPlayer src={src} autoPlay={true} />
                    ) : (
                      <AudioPlayer src="" autoPlay={false} />
                    )}
                    <Button
                      type="submit"
                      shape="round"
                      size="default"
                      disabled={
                        _.isEmpty(selectedText) ||
                        _.isEmpty(selectedVoice) ||
                        isProcessing ||
                        processAudio
                      }
                      className="button-create btn-success"
                      onClick={() => this.onClickButton()}
                    >
                      {isProcessing || processAudio
                        ? t('media:processing_audio')
                        : !_.isEmpty(src)
                        ? t('v4:publish')
                        : t('media:create')}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col span={8} className={'audio-tab'}>
                <Row>
                  <Col span={6}>
                    {/* <h3>{t('audio:select_voice')}</h3> */}
                    <Autocomplete
                      getItemValue={item => item}
                      shouldItemRender={(item, value) =>
                        item &&
                        item.toLowerCase().indexOf(value.toLowerCase()) > -1
                      }
                      items={filteredLanguages}
                      renderItem={(item, isHighlighted) => (
                        <div
                          style={
                            isHighlighted ? styles.HighLight : styles.NormalText
                          }
                        >
                          {item}
                        </div>
                      )}
                      inputProps={{ placeholder: 'Select language' }}
                      wrapperStyle={styles.AutoWrappper}
                      menuStyle={styles.autoCompleteModal}
                      value={languageValue}
                      onChange={e => this.onLanguageSearch(e.target.value)}
                      onSelect={(val, item) => this.onLanguageSelect(item)}
                    />
                  </Col>
                </Row>
                <Tabs animated={false}>
                  <TabPane
                    tab={t('media:all')}
                    disabled={selectedTextViewer !== ''}
                    key="1"
                  >
                    {selectedTextViewer !== '' && (
                      <Fragment>
                        <span style={{ marginLeft: '25em' }}>
                          <CloseOutlined onClick={this.onCloseTextViewer} />
                        </span>
                        <TextArea
                          value={selectedTextViewer}
                          autoSize={{ rows: 24 }}
                        />
                      </Fragment>
                    )}
                    {selectedTextViewer === '' && (
                      <div
                        style={{
                          height: '520px',
                          overflowY: 'scroll',
                          width: '375px'
                        }}
                      >
                        <Row gutter={24}>
                          <List
                            itemLayout="horizontal"
                            dataSource={allList}
                            renderItem={item => (
                              <List.Item
                                actions={[
                                  <span
                                    style={{
                                      color: '#1890ff',
                                      cursor: 'pointer'
                                    }}
                                    key="list-loadmore-edit"
                                    role="button"
                                    tabIndex="0"
                                  >
                                    <Tooltip title="Preview">
                                      {item && item[1].sample !== '' && (
                                        <PlayCircleOutlined
                                          onClick={() =>
                                            this.onClickPreviewIcon(item)
                                          }
                                        />
                                      )}
                                    </Tooltip>
                                  </span>
                                ]}
                              >
                                <List.Item.Meta
                                  avatar={
                                    <Avatar
                                      src={
                                        selectedVoice === item[0]
                                          ? activateSoundIcon
                                          : deactiveSoundIcon
                                      }
                                    />
                                  }
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => this.onClickCard(item)}
                                  title={`${item[0]}.mp3`}
                                />
                              </List.Item>
                            )}
                          />
                        </Row>
                      </div>
                    )}
                  </TabPane>
                  <TabPane
                    tab={t('media:male')}
                    disabled={selectedTextViewer !== ''}
                    key="2"
                  >
                    <div
                      style={{
                        height: '520px',
                        overflowY: 'scroll',
                        width: '386px'
                      }}
                    >
                      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                        <List
                          itemLayout="horizontal"
                          dataSource={maleList}
                          renderItem={item => (
                            <List.Item
                              actions={[
                                <span
                                  style={{
                                    color: '#1890ff',
                                    cursor: 'pointer'
                                  }}
                                  key="list-loadmore-edit"
                                  role="button"
                                  tabIndex="0"
                                >
                                  <Tooltip title="Preview">
                                    {item && item[1].sample !== '' && (
                                      <PlayCircleOutlined
                                        onClick={() =>
                                          this.onClickPreviewIcon(item)
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                </span>
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={
                                      selectedVoice === item[0]
                                        ? activateSoundIcon
                                        : deactiveSoundIcon
                                    }
                                  />
                                }
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={() => this.onClickCard(item)}
                                title={`${item[0]}.mp3`}
                              />
                            </List.Item>
                          )}
                        />
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={t('media:female')}
                    disabled={selectedTextViewer !== ''}
                    key="3"
                  >
                    <div
                      style={{
                        height: '520px',
                        overflowY: 'scroll',
                        width: '386px'
                      }}
                    >
                      <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
                        <List
                          itemLayout="horizontal"
                          dataSource={femaleList}
                          renderItem={item => (
                            <List.Item
                              actions={[
                                <span
                                  style={{
                                    color: '#1890ff',
                                    cursor: 'pointer'
                                  }}
                                  key="list-loadmore-edit"
                                  role="button"
                                  tabIndex="0"
                                >
                                  <Tooltip title="Preview">
                                    {item && item[1].sample !== '' && (
                                      <PlayCircleOutlined
                                        onClick={() =>
                                          this.onClickPreviewIcon(item)
                                        }
                                      />
                                    )}
                                  </Tooltip>
                                </span>
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    src={
                                      selectedVoice === item[0]
                                        ? activateSoundIcon
                                        : deactiveSoundIcon
                                    }
                                  />
                                }
                                style={{
                                  cursor: 'pointer'
                                }}
                                onClick={() => this.onClickCard(item)}
                                title={`${item[0]}.mp3`}
                              />
                            </List.Item>
                          )}
                        />
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane
                    tab={t('v4:human')}
                    disabled={selectedTextViewer !== ''}
                    key="4"
                  >
                    <div
                      style={{
                        height: '520px',
                        overflowY: 'scroll',
                        width: '386px'
                      }}
                    >
                      <List
                        itemLayout="horizontal"
                        dataSource={neuralList}
                        renderItem={item => (
                          <List.Item
                            actions={[
                              <span
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                key="list-loadmore-edit"
                                role="button"
                                tabIndex="0"
                              >
                                <Tooltip title="Preview">
                                  {item && item[1].sample !== '' && (
                                    <PlayCircleOutlined
                                      onClick={() =>
                                        this.onClickPreviewIcon(item)
                                      }
                                    />
                                  )}
                                </Tooltip>
                              </span>
                            ]}
                          >
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  src={
                                    selectedVoice === item[0]
                                      ? activateSoundIcon
                                      : deactiveSoundIcon
                                  }
                                />
                              }
                              style={{
                                cursor: 'pointer'
                              }}
                              onClick={() => this.onClickCard(item)}
                              title={`${item[0]}.mp3`}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </TabPane>
                </Tabs>
                <Spin spinning={this.state.audioprevLoading}>
                  <div
                    className={isSafari ? 'audio-warp-safari' : 'audio-warp'}
                  >
                    {!_.isEmpty(sampleSrc) ? (
                      <AudioPlayer src={sampleSrc} autoPlay={true} />
                    ) : (
                      <AudioPlayer src="" autoPlay={false} />
                    )}
                  </div>
                </Spin>
              </Col>
            </Row>
          )}
          {isWideView && (
            <>
              <div className="d-flex justify-content-between">
                <div>
                  <Select
                    showSearch
                    style={{ width: '200px' }}
                    placeholder={t('v4:translate_to')}
                    optionFilterProp="children"
                    onChange={e => this.handleChangeOption(e)}
                  >
                    {translationKeys.map(item => (
                      <Option key={item.code} value={item.code}>
                        {/* <Flag code={item.code} height='13' /> */}{' '}
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                  &nbsp;&nbsp;
                  <Button
                    className="rounded"
                    size="large"
                    onClick={this.handleClickImportText}
                  >
                    {t('v4:import_from_library')}
                  </Button>
                  &nbsp;&nbsp;
                  {this.props.user &&
                    this.props.user.info &&
                    this.props.user.info.organization &&
                    this.props.user.info.organization.enable_ai && (
                      <Button
                        className="rounded"
                        type="dashed"
                        size="large"
                        onClick={this.handleClickQAText}
                      >
                        {t('v4:question_answer_ai')}
                      </Button>
                    )}
                </div>
                <Button
                  // style={{ float: 'right', top: '-2em' }}
                  className="rounded"
                  type="primary"
                  size="large"
                  onClick={this.toggleWideView}
                >
                  {t('v2:proceed_to_add_voice')}
                </Button>
              </div>
              <QuestionAnswerAI
                isWideView={isWideView}
                handleSubmit={this.handleSubmit}
                getFieldDecorator={getFieldDecorator}
                handleAiLanguage={e => this.handleAiLanguage(e)}
                hasInitialize={hasInitialize}
                hasInitializeGerman={true}
                isLoading={isLoading}
                showSearchResult={showSearchResult}
                resultData={resultData}
                summariseResult={summariseResult}
                summariseResultOptionOne={summariseResultOptionOne}
                onChangeTab={this.onChangeTab}
                tabIndex={tabIndex}
                summarySearch={summarySearch}
                loadSummarize={loadSummarize}
                errorMsg={errorMsg}
                titleSearch={titleSearch()}
                isProcessing={isProcessing}
                selectedText={selectedText}
                onChangeText={this.onChangeText}
                audio
              />
            </>
          )}
        </div>
      </Fragment>
    )
  }
}

const WrappedCreateAudio = Form.create({ name: 'text_form' })(CreateAudio)

export default withTranslation('')(WrappedCreateAudio)
