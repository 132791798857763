import React, { Component } from 'react'
import className from 'classnames'
import Dropzone from 'react-dropzone'
import { Button, Form, Modal } from 'antd'
import ImageCropContainer from '../../../../../components/ImageCrop'
import { DropzoneLoading } from '../../styled'
import { general, config } from '../../../../../constants'
import AiImages from '../../../../Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiImages'

export default class InsertMedia extends Component {
  state = {
    file: null,
    image: null,
    isImageLoading: false,
    isAiModal: false
  }

  onDrop = (dropped, handleAfterUpload, handlebeforeUpload) => {
    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      image
    })
  }

  // onDrop = dropped => {
  //   const file = dropped[0]
  //   if (!file) {
  //     return
  //   }
  //   const image = URL.createObjectURL(file)
  //   this.setState({
  //     file,
  //     image
  //   })
  // }

  handleUploadImage = data => {
    const {
      upload,
      clearUploadList,
      App: { handleParam, setCover }
    } = this.props

    return this.setState({ isImageLoading: true }, () => {
      upload(data).then(response => {
        setCover(response.id, response.link)
        handleParam()
        clearUploadList()
        this.setState({
          image: null,
          file: null,
          isImageLoading: false
        })
      })
    })
  }

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
    }
  }

  onImage = item => {
    this.handleImageUrl(item.link, 'Presentation ai')
    this.setState({ isAiModal: false })
  }

  render() {
    const { image, file, isImageLoading, isAiModal } = this.state
    const {
      t,
      App: { toggleMediaModal },
      library: { uploadProgress },
      validate,
      coverId,
      coverLink
    } = this.props

    return (
      <>
        {isAiModal && (
          <Modal
            visible={isAiModal}
            footer={false}
            destroyOnClose
            onCancel={e => {
              e.preventDefault()
              this.setState({ isAiModal: false })
            }}
            width={'80%'}
          >
            <AiImages selectedImage={this.setCover} onImage={this.onImage} />
          </Modal>
        )}
        <Form.Item label={t('labels:picture')} colon={false} required={false}>
          <Dropzone
            accept={'image/*'}
            onDrop={this.onDrop}
            style={{}}
            disabled={uploadProgress.length > 0}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return isImageLoading ? (
                <DropzoneLoading type="loading" />
              ) : (
                <div
                  className={className(
                    'upload-dropzone',
                    'upload-dropzone_event',
                    {
                      'upload-dropzone_active': isDragActive,
                      'upload-dropzone_invalid': !coverId && validate
                    }
                  )}
                  style={{
                    backgroundImage: 'url(' + coverLink + ')'
                  }}
                >
                  <div className="wrap_upload_buttons">
                    <Button
                      className="rounded"
                      size="large"
                      icon="picture"
                      style={{ margin: '5px auto' }}
                    >
                      {t('buttons:upload_image')}
                    </Button>
                    <Button
                      style={{ margin: '5px auto' }}
                      className="rounded"
                      size="large"
                      icon="picture"
                      onClick={e => {
                        e.stopPropagation()
                        toggleMediaModal()
                      }}
                    >
                      {t('buttons:select_media_library')}
                    </Button>
                    <Button
                      style={{ margin: '5px auto' }}
                      className="rounded"
                      size="large"
                      icon="picture"
                      onClick={e => {
                        e.stopPropagation()
                        this.useCanva()
                      }}
                    >
                      {t('v4:canva')}
                    </Button>
                    <Button
                      style={{ margin: '5px auto' }}
                      className="rounded"
                      size="large"
                      icon="picture"
                      onClick={e => {
                        e.preventDefault()
                        this.setState({ isAiModal: true })
                      }}
                    >
                      {t('v3:ai_creator')}
                    </Button>
                  </div>
                </div>
              )
            }}
          </Dropzone>
          {image && (
            <ImageCropContainer
              image={image}
              handleUploadImage={this.handleUploadImage}
              handleClosePopup={this.handleClosePopup}
              file={file}
              t={t}
            />
          )}
        </Form.Item>
      </>
    )
  }
}
