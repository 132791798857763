import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { api } from '../../../services'

const { Option } = Select

function AllowAccess(props) {
  const { t } = useTranslation()

  const [emailList, setEmailList] = useState([])
  const [fetching, setFetching] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    fetchUserEmails()
  }, [])

  const handleChange = value => {
    const { onChange } = props
    if (onChange) {
      onChange(value)
    }
  }

  const fetchUserEmails = () => {
    const {
      info: { active_organization_id }
    } = user
    setFetching(true)
    api.organizations
      .getUsersByOrganizationId(active_organization_id)
      .then(({ data }) => {
        const userEmails =
          data &&
          data
            .filter(item => item.user !== null)
            .map(item => ({
              text: item.user.email,
              value: item.user.email,
              id: item.user.id
            }))

        setEmailList(userEmails)
        setFetching(false)
      })
      .catch(err => {
        setFetching(false)
        setEmailList([])
      })
  }

  const {
    form: { getFieldDecorator },
    defaultHostEmails = []
  } = props

  console.log({ defaultHostEmails })

  return (
    <Form.Item label={t('v4:allow_others')} colon={false} required={false}>
      {getFieldDecorator('host_emails', {
        initialValue: defaultHostEmails
      })(
        <Select
          mode="multiple"
          placeholder={t('placeholders:email')}
          filterOption={true}
          loading={fetching}
          disabled={fetching}
          onChange={handleChange}
          style={{ width: '100%' }}
        >
          {emailList.map(d => (
            <Option key={d.value}>{d.text}</Option>
          ))}
        </Select>
      )}
    </Form.Item>
  )
}

export default AllowAccess
