import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { config } from '../../../constants'
import history from '../../../history'
import { api } from '../../../services'

import * as userActions from '../../../store/actions/user'

function AuthContainer({ children }) {
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('visibilitychange', async () => {
      const userSession = await api.user.getSession()
      if (userSession) {
        const urlParam = window.location.search
        if (urlParam.includes('marketplace')) {
          goToMarketplace()
        } else if (window.location.pathname.includes('auth')) {
          dispatch(userActions.authenticate())
          history.push('/training')
        }
      }
    })

    const goToMarketplace = () => {
      let a = document.createElement('a')
      a.href = 'http://' + config.cookieDomain + '/marketplace'
      a.click()
    }
  }, [])

  return <>{children}</>
}

export default AuthContainer
