import http from './http'

function getTopics (data) {
  let params = {
    offset: 0,
    limit: 1000
  }
  if (!!data) {
    params = data
  }
  return http({
    method: 'GET',
    url: '/topics',
    params
  })
}

function createTopics (data) {
  return http({
    method: 'POST',
    url: '/topics',
    data
  })
}

const getTopicsByIds = async (ids) => {
  return await http({
    method: 'GET',
    url: `/topics?topicIds=${ids}`
  })
}

export {
  getTopics,
  createTopics,
  getTopicsByIds
}
