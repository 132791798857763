import ReactDOM from 'react-dom'
import React, { useRef, useEffect, useState } from 'react'
// import { WhiteWebSdk } from 'white-web-sdk'
import './AgoraWhiteboard.css'
import { Modal, Button } from 'antd'
import config from '../../../../../constants/config'

export default function Whiteboard({
  wbRoomUUID,
  wbRoomToken,
  userId,
  consumerId
}) {
  const wbRef = useRef(null)
  const toolRef = useRef(null)
  const roomRef = useRef(null)
  const controlsRef = useRef(null)
  const containerRef = useRef(null)
  const colorInput = useRef(null)
  const widthInput = useRef(null)
  const textSizeInput = useRef(null)

  const [visible, setVisible] = useState(false)

  //const className = consumerId === userId ? 'active' : 'inactive'
  const className = 'active'

  const showModal = () => setVisible(true)
  const handleCancel = () => setVisible(false)

  // useEffect(() => {
  //   const whiteboard = wbRef.current

  //   // Create an instance of the WhiteWebSdk class.
  //   const whiteWebSdk = new WhiteWebSdk({
  //     // Pass in your App Identifier.
  //     appIdentifier: config.agoraWbAppId,
  //     // Set the data center as Silicon Valley, US.
  //     region: 'us-sv'
  //   })

  //   var joinRoomParams = {
  //     uuid: wbRoomUUID,
  //     // The unique identifier of a user. If you use versions earlier than v2.15.0, do not add this line.
  //     uid: `${userId}`,
  //     roomToken: wbRoomToken
  //   }

  //   whiteWebSdk
  //     .joinRoom(joinRoomParams)
  //     .then(function(room) {
  //       room.bindHtmlElement(whiteboard)
  //       roomRef.current = room
  //       var toolbar = toolRef.current
  //       var toolNames = [
  //         'pencil',
  //         'selector',
  //         'rectangle',
  //         'eraser',
  //         'text',
  //         'arrow',
  //         'ellipse',
  //         'hand',
  //         'straight'
  //       ]

  //       // Create a button for each tool.
  //       for (var idx in toolNames) {
  //         var toolName = toolNames[idx]
  //         var btn = document.createElement('span')
  //         ReactDOM.render(
  //           <Button
  //             type="primary"
  //             id={'btn' + toolName}
  //             onClick={obj => {
  //               var ele = obj.target
  //               // Call the setMemberState method to set the whiteboard tool.
  //               room.setMemberState({
  //                 currentApplianceName: ele.getAttribute('id').substring(3),
  //                 shapeType: 'pentagram',
  //                 strokeColor: [47, 96, 168],
  //                 strokeWidth: 3,
  //                 textSize: 15
  //               })
  //             }}
  //           >
  //             {toolName}
  //           </Button>,
  //           btn
  //         )

  //         toolbar.appendChild(btn)
  //       }
  //     })
  //     .catch(function(err) {
  //       console.error(err)
  //     })
  // }, [])

  // Convert the hexadecimal color code to RGB color code.
  function hexToRgb(hex) {
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)
    return { r, g, b }
  }

  // Set the color, width, and text size of the whiteboard tool.
  function handleColorInput() {
    const color = colorInput.current.value
    const width = widthInput.current.value
    const textSize = textSizeInput.current.value
    const rgb = hexToRgb(color)
    const { r, g, b } = rgb
    roomRef.current.setMemberState({
      strokeColor: [r, g, b],
      strokeWidth: width,
      textSize: textSize
    })
  }

  function handleWidthInput() {
    const color = colorInput.current.value
    const width = widthInput.current.value
    const textSize = textSizeInput.current.value
    const rgb = hexToRgb(color)
    const { r, g, b } = rgb
    roomRef.current.setMemberState({
      strokeColor: [r, g, b],
      strokeWidth: width,
      textSize: textSize
    })
  }

  function handleTextSizeInput() {
    const color = colorInput.current.value
    const width = widthInput.current.value
    const textSize = textSizeInput.current.value
    const rgb = hexToRgb(color)
    const { r, g, b } = rgb
    roomRef.current.setMemberState({
      strokeColor: [r, g, b],
      strokeWidth: width,
      textSize: textSize
    })
  }

  return (
    <div
      id="sharewhiteboard"
      style={{ display: 'flex', flexDirection: 'row', height: '95%' }}
    >
      <div id="container">
        <Button onClick={showModal}>Change Style</Button>
        <Modal
          visible={visible}
          title="Style"
          onCancel={handleCancel}
          footer={null}
        >
          <div ref={containerRef}>
            <div ref={controlsRef}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px',
                  height: '100hv'
                }}
              >
                <label htmlFor="colorInput">Color: </label>
                <input
                  type="color"
                  id="colorInput"
                  defaultValue="#2F60A8"
                  ref={colorInput}
                  onInput={handleColorInput}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px'
                }}
              >
                <label htmlFor="widthInput">Width: </label>
                <input
                  type="range"
                  id="widthInput"
                  min="1"
                  max="50"
                  defaultValue="5"
                  ref={widthInput}
                  onInput={handleTextSizeInput}
                  style={{ width: '300px' }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: '10px'
                }}
              >
                <label htmlFor="textInput">Text Size: </label>
                <input
                  type="number"
                  id="textInput"
                  min="1"
                  defaultValue="15"
                  ref={textSizeInput}
                  onInput={handleWidthInput}
                  style={{ width: '50px' }}
                />
              </div>
            </div>
          </div>
        </Modal>

        <div
          ref={toolRef}
          id="toolbar"
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: '0',
            left: '0'
          }}
        ></div>
      </div>

      <div className={className} ref={wbRef} id="whiteboard"></div>
    </div>
  )
}
