import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dropdown as AntDropdown } from 'antd'
import { DropdownToggle, IconArrow } from './styled'
import IconChevron from './images/chevron.svg'

class Dropdown extends Component {
  state = {
    isActive: false
  }

  handleClick = () => {
    const { isActive } = this.state

    document[!isActive ? 'addEventListener' : 'removeEventListener']('click', this.handleOutsideClick, false)

    this.setState({
      isActive: !isActive
    })
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleOutsideClick, false)
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) return

    this.handleClick()
  }

  render () {
    const props = this.props
    const { children, list } = this.props
    const { isActive } = this.state

    return (
      <AntDropdown
        overlay={list}
        trigger={['click']}
        placement='bottomRight'
        {...props}
      >
        <DropdownToggle
          onClick={this.handleClick}
          ref={(node) => {
            this.node = node
          }}
          active={isActive}
        >
          {children}
          <IconArrow src={IconChevron}/>
        </DropdownToggle>
      </AntDropdown>
    )
  }
}

Dropdown.propTypes = {
  children: PropTypes.node
}

export default Dropdown
