import React from 'react'
import { Field } from '../../../../../../../styles'
import { useTranslation, Trans } from 'react-i18next'
import './styles.scss'

const Description = ({ isAdmin }) => {
  const { t } = useTranslation()
  return (
    <div className="privacy-setting">
      <Field.Label>{t('labels:privacy_settings')}</Field.Label>
      <div style={{ paddingBottom: '10px' }}>
        {!isAdmin && (
          <Trans i18nKey="helpers:public_description_normal">
            <span>{t('labels:course')}</span>
          </Trans>
        )}
        {isAdmin && (
          <Trans i18nKey="helpers:public_description_admin">
            <span>{t('labels:course')}</span>
          </Trans>
        )}
      </div>
    </div>
  )
}

export default Description
