import React from 'react'
import moment from 'moment'
import { isPublish, handlePublish } from './internal/Controller'

import { Footer, CourseExpire, Btn } from './styled'

const ViewContentFooter = ({ App, isOwner }) => {
  const {
    course: {
      item: { id, exp_date: expDate }
    },
    history,
    user: {
      info: { isAdmin, isSuperAdmin }
    },
    t
  } = App.props

  return (
    <Footer>
      <Footer.Wrapp>
        {expDate && (
          <CourseExpire>
            {t('labels:expire')} {moment(expDate).format('L')}
          </CourseExpire>
        )}

        {/* {(isAdmin || isOwner || isSuperAdmin) &&
        <Btn
          type='primary'
          className='rounded'
          onClick={() => App.cloneCourse(item)}
        >
          {t('buttons:clone_course')}
        </Btn>
        } */}

        {/* {isAccess &&
        <Btn
          type='primary'
          className='rounded'
          onClick={() => handleEdit(history, id)}
        >
          {t('buttons:edit')}
        </Btn>
        } */}

        {isPublish(isAdmin, isOwner, isSuperAdmin) && (
          <Btn
            type="primary"
            className="rounded"
            onClick={() => handlePublish(history, id)}
          >
            {t('buttons:publish')}
          </Btn>
        )}

        {/* <Spin spinning={isCourseEnrollment}>
          {enrollment ?
            <Btn
              type='primary'
              className='rounded'
              disabled={!publisherId}
              onClick={App.unenroll}
            >
              {t('buttons:unenroll')}
            </Btn>
            :
            <Btn
              type='primary'
              className='rounded'
              disabled={!publisherId}
              onClick={App.enroll}
            >
              {t('buttons:enroll')}
            </Btn>
          }
        </Spin> */}
      </Footer.Wrapp>
    </Footer>
  )
}

export default ViewContentFooter
