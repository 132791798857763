import Swal from 'sweetalert2'
import './styles.scss'

const notification = (
  status = 'STATUS-HERE',
  type = 'success',
  title = null,
  width = null
) => {

  const config = {
    position: 'bottom-end',
    width: width || 500,
    heightAuto: false,
    toast: true,
    backdrop: false,
    type,
    title: title || `Your files has been made to ${status} successfully.`,
    showConfirmButton: false,
    timer: 5000,
    customClass: 'swal_custom_class'
  }

  return Swal.fire(config)
}

export default notification
