import axios from 'axios'
import http, { httpCustomInstance } from './http'
import { config } from '../../constants'

/**
 * Upload some media data.
 * @param {object} data
 */
function upload(data, options) {
  console.log(`Library API data`, data)

  const isScormZipUpload = options && options.isScormZipUpload
  return http({
    method: 'POST',
    url: isScormZipUpload ? '/library/scorm-extract' : '/library',
    data
  })
}

/**
 * Update AR Objects.
 * @param {object} data
 */
function updateAR(data, id) {
  console.log(`Update AR Objects`, data)

  return http({
    method: 'PUT',
    url: '/library/' + id,
    data
  })
}

/**
 * Get list of courses.
 * @param {obj} params {{limit: integer, offset: integer, search: string}}
 */
function getLibraryList(params) {
  return http({
    method: 'GET',
    url: '/library',
    params
  })
}

/**
 * Get list of courses.
 * @param {obj} params {{limit: integer, offset: integer, search: string, isPublic: boolean}}
 */
function getPublicList(params) {
  return http({
    method: 'GET',
    url: '/library',
    params
  })
}

/**
 * Download particular media data.
 * @param {number} id
 */
function download(data) {
  const { size, id } = data
  const params = size ? `?type=${size}` : ''
  return http({
    method: 'GET',
    url: `/library/${id}/downloadableLink${params}`
    // headers:{
    //   Accept: 'image/jpeg'
    // },
    // responseType: 'blob',
  })
}

/**
 * Get sign url for upload data to s3.
 */
const sign = (data, isAR, category) => {
  const url =
    isAR === 'zipAR'
      ? '/library/sign?category=ar'
      : category
      ? `/library/sign?category=${category}`
      : '/library/sign'
  console.log('signdataurl', data, category, url)
  return http({
    method: 'POST',
    url: url,
    data
  })
}

/**
 * Put file to s3.
 */
const putToS3 = (url, file, options) => {
  return axios.put(url, file, options)
}

/**
 * Update Library status
 * Public & Private
 */
const updateLibraryStatus = (data, options) => {
  const isScorm = options.isScorm
  return http({
    method: 'PUT',
    url: `/library${isScorm ? '/scorm-extract' : ''}`,
    data
  })
}

const updateMedia = async (id, data) => {
  return await http({
    method: 'PUT',
    url: `/library/${id}`,
    data
  })
}

const removeMedias = async data => {
  return await http({
    method: 'DELETE',
    url: '/library',
    data
  })
}

const getMediaInfoById = async id => {
  return await http({
    method: 'GET',
    url: `/library/${id}/info`
  })
}

function getStockImages(data) {
  return axios
    .get(
      `${config.fetchPexelPhotos}?query=${data.query}&per_page=${data.perPage}&page=${data.page}`,
      {
        headers: { Authorization: config.pexel }
      }
    )
    .then(res => {
      return res
    })

  /* return http({      // @TODO : interceptors header issue. Check it later
    method: 'GET',
    url: `${endpoint.FETCH_STOCK_PHOTOS}?query=computer&per_page=15&page=1`
  },{headers: { "Authorization": config.pexels.API_KEY }}) */
}

const getOrgLibrary = async params => {
  return await http({
    method: 'GET',
    url: '/library',
    params
  })
}

const updateOrgLibrary = data => {
  return http({
    method: 'PUT',
    url: '/library/org',
    data
  })
}

const sharePdf = data => {
  return http({
    method: 'POST',
    url: '/library/share',
    data
  })
}

const getAiImages = params => {
  return httpCustomInstance({
    method: 'GET',
    url: `/openai-dalle`,
    params
  })
}

export {
  upload,
  updateAR,
  sign,
  putToS3,
  download,
  getLibraryList,
  getPublicList,
  updateLibraryStatus,
  updateMedia,
  removeMedias,
  getMediaInfoById,
  getStockImages,
  getOrgLibrary,
  updateOrgLibrary,
  sharePdf,
  getAiImages
}
