import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Tag } from 'antd'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { UsersModal } from '../UsersModal'
import './AddGuest.scss'

const AddGuest = ({
  t,
  isEnd,
  users = [],
  selectedGuests,
  setSelectedGuests,
  roomName,
  setRoomName,
  roomNameError,
  hostError,
  setRoomNameError,
  changeHostError
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)
  const [roomNameStyle, setRoomNameStyle] = useState({ borderRadius: '4px' })
  const [mainUserList, setMainUserList] = useState([])

  const [filteredUsers, setFilteredUsers] = useState([])

  useEffect(() => {
    const mainUsers = users.filter(user => user)

    setMainUserList(mainUsers)
    setFilteredUsers(mainUsers)
  }, [users])

  useEffect(() => {
    setRoomNameStyle(
      roomNameError
        ? { borderRadius: '4px', border: '1px solid #ff4d4f' }
        : { borderRadius: '4px' }
    )
  }, [roomNameError])

  const handleElementClick = () => {
    setModalVisible(true)
  }

  const handleModalClose = () => {
    setModalVisible(false)
  }

  const handleUserCheckboxChange = user => {
    const isSelected = selectedGuests.find(u => u.id === user.id)
    if (isSelected) {
      const updatedGuests = selectedGuests.filter(u => u.id !== user.id)
      setSelectedGuests(updatedGuests)
    } else {
      changeHostError(false)
      setSelectedGuests([...selectedGuests, user])
    }
  }

  const handleSearchChange = (searchType, searchValue) => {
    let results

    switch (searchType) {
      case 'id':
        results = mainUserList.filter(
          user => user.id && user.id.toString().includes(searchValue)
        )
        break
      case 'name':
        results = mainUserList.filter(
          user =>
            (user.first_name && user.first_name.includes(searchValue)) ||
            (user.last_name && user.last_name.includes(searchValue))
        )
        break
      case 'email':
        results = mainUserList.filter(
          user => user.email && user.email.includes(searchValue)
        )
        break
      default:
        // 'all'
        results = mainUserList.filter(
          user =>
            (user.id && user.id.toString().includes(searchValue)) ||
            (user.username && user.username.includes(searchValue)) ||
            (user.email && user.email.includes(searchValue)) ||
            (user.first_name && user.first_name.includes(searchValue)) ||
            (user.last_name && user.last_name.includes(searchValue))
        )
    }

    setFilteredUsers(results)
    setSearchText(searchValue)
  }

  const handleGuestUnselect = user => {
    const updatedGuests = selectedGuests.filter(u => u.id !== user.id)
    setSelectedGuests(updatedGuests)
  }

  const handleDeleteAll = () => {
    setSelectedGuests([])
    setIsSelectAllChecked(false)
  }

  const handleSelectAll = e => {
    if (e.target.checked) {
      setSelectedGuests(filteredUsers)
      setIsSelectAllChecked(true)
    } else {
      setSelectedGuests([])
      setIsSelectAllChecked(false)
    }
  }

  const handleRoomNameChange = e => {
    setRoomName(e.target.value)
    setRoomNameError(false)
  }

  const isDeleteAllButtonDisabled = selectedGuests.length === 0
  const deleteAllButtonClassName = isDeleteAllButtonDisabled
    ? 'delete-all-button disabled'
    : 'delete-all-button'
  return (
    <div className="add-guest">
      <div className="add-guest__content">
        <div className="ant-row ant-form-item">
          <Form.Item label={t('v4:room_name')} colon={false} required={false}>
            <Input
              readOnly={isEnd}
              value={roomName}
              style={roomNameStyle}
              size="large"
              placeholder={t('v4:room_name')}
              maxLength={100}
              onChange={handleRoomNameChange}
            />

            {roomNameError && (
              <div
                style={{
                  color: '#ff4d4f',
                  fontSize: '12px',
                  height: '1.5rem'
                }}
              >
                {t('v3:room_required_name')}
              </div>
            )}

            <div className="discussion-add-button">
              <div className="add-container">
                <div
                  className="add-button-container"
                  onClick={() => {
                    handleElementClick()
                  }}
                >
                  <div className="add-icon">
                    <PlusOutlined />
                  </div>
                  <div className="add-text">
                    {t('v4:add_guest_speaker').toUpperCase()}
                  </div>
                  <div className="add-icon">
                    <SearchOutlined
                      style={{
                        marginBottom: '0.2rem',
                        marginLeft: '1rem',
                        fontSize: '1.4rem'
                      }}
                    />
                  </div>
                </div>

                <div className="selected-guests">
                  {selectedGuests.map(guest => (
                    <Tag
                      key={guest.email}
                      className="selected-guest-tag"
                      onClose={() => handleGuestUnselect(guest)}
                    >
                      {guest.email}
                    </Tag>
                  ))}
                </div>
                {hostError && (
                  <div
                    style={{
                      color: '#ff4d4f',
                      fontSize: '12px',
                      height: '1.5rem'
                    }}
                  >
                    {t('v3:add_guest_list')}
                  </div>
                )}
              </div>

              <UsersModal
                visible={modalVisible}
                onCancel={handleModalClose}
                searchText={searchText}
                onSearchChange={handleSearchChange}
                users={filteredUsers}
                selectedUsers={selectedGuests}
                onCheckboxChange={handleUserCheckboxChange}
                onSelectAllChange={handleSelectAll}
                isSelectAllChecked={isSelectAllChecked}
              />

              <div className="delete-all-container">
                <Button
                  className={deleteAllButtonClassName}
                  type="link"
                  danger
                  onClick={handleDeleteAll}
                >
                  {t('v3:delete_all')}
                </Button>
              </div>
            </div>
            {/* <Divider></Divider> */}
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

export default AddGuest
