import { call, put } from 'redux-saga/effects'
import * as api from '../../services/api/quizes'
import * as quizes from '../actions/quizes'

export function * fetchQuizes () {
  yield put(quizes.fetchRequest(false))

  try {
    const response = yield call(api.getQuizesList)

    yield put(quizes.loadQuizes(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * fetchQuizById (action) {
  yield put(quizes.fetchRequest(false))

  try {
    const { id } = action.payload
    const response = yield call(api.getQuizById, id)

    yield put(quizes.loadQuizById(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * fetchQuizByIdAnswers (action) {
  yield put(quizes.fetchRequest(false))

  try {
    const { id } = action.payload
    const response = yield call(api.getQuizByIdAnswers, id)

    yield put(quizes.loadQuizById(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * validateQuiz (action) {
  yield put(quizes.loadQuizValidate({
    result: {},
    fetch: true
  }))

  try {
    const { data, id } = action.payload
    const response = yield call(api.quizValidate, data, id)

    yield put(quizes.loadQuizValidate({
      result: response.data,
      fetch: false
    }))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * createQuiz (action) {
  yield put(quizes.fetchRequest(false))

  try {
    const response = yield call(api.quizCreate, action.payload)

    yield put(quizes.updateQuizes(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * updateQuiz (action) {
  yield put(quizes.fetchRequest(false))

  try {
    const { data, id } = action.payload
    const response = yield call(api.quizUpdate, data, id)

    yield put(quizes.loadQuiz(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * updateQuestions (action) {
  yield put(quizes.fetchRequest(false))

  try {
    const { data, id } = action.payload
    const response = yield call(api.quizUpdate, data, id)

    yield put(quizes.loadQuizById(response.data))
    yield put(quizes.fetchRequest(true))
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * removeQuizById (action) {
  try {
    const { id } = action.payload
    yield call(api.quizDelete, id)
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
