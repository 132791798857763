import React, { Component } from 'react'
import { Button, Tooltip } from 'antd'
import { Row, Col, Field } from '../../../../../styles'
import { withTranslation, Trans } from 'react-i18next'

class PrivacySelector extends Component {
  onClick = id => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render() {
    const { isCoaching, types, t, isAdmin, checkSelectedType } = this.props
    return (
      <Field>
        <Field.Label>{t('labels:privacy_settings')}</Field.Label>
        {!isCoaching && (
          <div style={{ paddingBottom: '10px' }}>
            {!isAdmin && (
              <Trans i18nKey="helpers:public_description_normal">
                <span>{t('labels:event')}</span>
              </Trans>
            )}
            {isAdmin && (
              <Trans i18nKey="helpers:public_description_admin">
                <span>{t('labels:event')}</span>
              </Trans>
            )}
          </div>
        )}
        <Field.Control>
          <Row>
            {types.map(type => (
              <Col md={types.length > 1 ? 12 / types.length : 4} key={type.id}>
                {type.name === 'Marketplace' && type.disabled ? (
                  <Tooltip placement="bottom" title={t('v4:not_complete')}>
                    <Button block icon="exclamation-circle" disabled>
                      {type.name}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    block
                    type={checkSelectedType(type.id) ? 'primary' : ''}
                    onClick={() => this.onClick(type.id)}
                    disabled={type.disabled}
                  >
                    {type.name}
                  </Button>
                )}
              </Col>
            ))}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(PrivacySelector)
