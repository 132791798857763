import http from './http'

const get = params => {
  return http({
    method: 'GET',
    url: '/organizations_web',
    params
  })
}

const create = data => {
  return http({
    method: 'POST',
    url: '/organizations',
    data
  })
}

const update = (id, data) => {
  return http({
    method: 'PUT',
    url: `/organizations/${id}`,
    data
  })
}

const deleteById = id => {
  return http({
    method: 'DELETE',
    url: `/organizations/${id}`
  })
}

function getById(id) {
  return http({
    method: 'GET',
    url: `/organizations/${id}`
  })
}

function getUsersByOrganizationId(id) {
  return http({
    method: 'GET',
    url: `/organizations/${id}/users`
  })
}

const createOrganizationUser = (id, data) => {
  return http({
    method: 'POST',
    url: `/organizations/${id}/users`,
    data
  })
}

const deleteOrganizationUser = (orgId, id) => {
  return http({
    method: 'DELETE',
    url: `/organizations/${orgId}/users/${id}`
  })
}

const updateOrganizationUser = (orgId, id, data) => {
  return http({
    method: 'PUT',
    url: `/organizations/${orgId}/users/${id}`,
    data
  })
}

const deleteGroup = (orgId, groupId) => {
  return http({
    method: 'DELETE',
    url: `/organizations/${orgId}/groups/${groupId}`
  })
}

const updateGroup = (id, orgId, data) => {
  return http({
    method: 'PUT',
    url: `/organizations/${orgId}/groups/${id}`,
    data
  })
}

const createGroup = (orgId, data) => {
  return http({
    method: 'POST',
    url: `/organizations/${orgId}/groups`,
    data
  })
}
const getCategories = () => {
  return http({
    method: 'GET',
    url: `/org_categories/`
  })
}
const deleteCategory = (id) => {
  return http({
    method: 'DELETE',
    url: `/org_categories/${id}`
  })
}

const updateCategory = (id, data) => {
  return http({
    method: 'PUT',
    url: `/org_categories/${id}`,
    data
  })
}

const createCategory = (data) => {
  return http({
    method: 'POST',
    url: `/org_categories`,
    data
  })
}

const searchCategory = params => {
  return http({
    method: 'GET',
    url: `/organizations/${params.orgId}/categories?${params.searchType}=${params.searchValue}`
  })
}
const searchUsersInOrganization = params => {
  return http({
    method: 'GET',
    url: `/organizations/${params.orgId}/users?${params.searchType}=${params.searchValue}`
  })
}

const getUsageReport = params => {
  return http({
    method: 'GET',
    url: `organizations_web?limit=${params.limit}&date=${params.date}&get_broadcast_usage=true&get_transcribe_usage=true&searchByGroup=&from=${params.dateFrom}&to=${params.dateTo}&isActive=true&active_org=${params.activeOrg}`
  })
}

const sendEmailToUsers = data => {
  return http({
    method: 'POST',
    url: `organizations/sendEmailToOrgUsers`,
    data
  })
}

const getUserList = id => {
  return http({
    method: 'GET',
    url: `organizations/orgUsersLists?&limit&offset&isActive=true&active_org=${id}`
  })
}

export {
  get,
  getById,
  getUsersByOrganizationId,
  create,
  update,
  deleteById,
  createOrganizationUser,
  deleteOrganizationUser,
  updateOrganizationUser,
  deleteGroup,
  createGroup,
  updateGroup,
  getCategories,
  deleteCategory,
  createCategory,
  updateCategory,
  searchCategory,
  searchUsersInOrganization,
  getUsageReport,
  sendEmailToUsers,
  getUserList
}
