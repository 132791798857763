import PublishMaze from './PublishMaze'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../store/actions'

const mapStateToProps = state => ({
  list: state.organizations.list.rows,
  isFetching: state.organizations.isFetching,
  mazeBuilder: state.mazeBuilder,
  user: state.user,
  maze: state.maze
})

const mapDispatchToProps = dispatch => ({
  handleMazeData: data => dispatch(actions.maze.handleMazeData(data)),
  resetMazeData: bindActionCreators(actions.mazeBuilder.resetMazeBuilder)
})

export default connect(mapStateToProps, mapDispatchToProps)(PublishMaze)
