import styled from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Organization = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  border: 1px solid ${colors.darkGreyTwo};
  transition: border-color 0.2s;
  cursor: pointer;

  &:hover {
    border-color: rgba(0, 104, 255, 0.5);
  }

  ${props =>
    props.active &&
    `
    border-color: ${colors.blue};

    &:hover {
      border-color: ${colors.blue};
    }
  `}

  ${props =>
    props.disabled &&
    `
  pointer-events: none;
  opacity: 0.4;
  `}
`

Organization.Checkbox = styled.div`
  margin-right: 1.6rem;
`

Organization.Image = styled.img`
  display: block;
  width: 4rem;
  height: 4rem;
  margin-right: 1.6rem;
  border-radius: 100%;
`

Organization.DefaultImage = styled.div`
  display: block;
  width: 4rem;
  height: 4rem;
  margin-right: 1.6rem;
  border-radius: 100%;
  background: #2d6ff2;
  font-size: 28px;
  color: #fff;
  text-align: center;
`

Organization.Name = styled.div`
  font-weight: 600;
`

const Groups = styled.div`
  margin-bottom: 3.2rem;

  ${props =>
    props.disabled &&
    `
  pointer-events: none;
  opacity: 0.4;
  `}
`

Groups.List = styled.div``

Groups.Item = styled.div`
  border-bottom: 1px solid #f8f8f8;
`

const Group = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    color: ${colors.blue};

    &:hover {
      color: ${colors.blue};
    }
  `}
`

Group.Checkbox = styled.div`
  margin-right: 1.6rem;
`
Group.Name = styled.div``

export { Organization, Groups, Group }
