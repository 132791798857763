import React, { useEffect, useState } from 'react'
import {
  ReportTable,
  CustomTabs,
  PaginationRightAligned,
  BackButton
} from '../../../Reports/styled'
import { LeftOutlined } from '@ant-design/icons'
import { Tabs, Icon, Row, Col, Card, Divider, Pagination } from 'antd'
import { generateCSV } from '../../../../utils'
import moment from 'moment/moment'
import '../../../Reports/Report/style.css'
import { isSafari } from 'react-device-detect'
import { Search } from '../../../../components'
import { useTranslation } from 'react-i18next'
import ReportHeader from '../../../Reports/components/ReportHeader.jsx'

import { report } from '../../../../constants'
import MarketplaceTypeSelect from '../../../Reports/components/MarketplaceTypeSelect'

const { TabPane } = Tabs

const columns = t => [
  {
    title: 'Buyer Type',
    dataIndex: 'buyer_type'
  },
  {
    textWrap: 'word-break',
    dataIndex: 'username'
  },
  {
    title: t('Name'),
    dataIndex: 'buyer_name'
  },

  {
    title: t('reports:purchased_date'),
    dataIndex: 'purchased_date',
    render: x => {
      return moment(x).format('ll')
    },
    sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    sortDirections: ['descend', 'ascend']
  },

  {
    title: t('v3:edit_option'),
    dataIndex: 'edit_option',
    render: editOption => (editOption === 'Enabled' ? 'Enabled' : 'Disabled')
  },

  {
    title: t('v3:quanity'),
    dataIndex: 'buying_quantity'
  },
  {
    title: t('v3:total_amount'),
    dataIndex: 'total_amount',
    className: 'remove-icon-show'
  },
  {
    title: t('v3:channel_unit_amount'),
    dataIndex: 'unit_amount',
    className: 'remove-icon-show'
  }

  // {
  //   title: t('v3:net_amount'),
  //   dataIndex: 'net_amount'
  // }
]

const expandSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="#F0F2F5" />
    <path d="M4 6L8.5 11L13 6" stroke="#246CF6" />
  </svg>
)

const Details = props => {
  const [state, setState] = useState({
    isLoading: true,
    isLesson: false,
    expandedRowKeys: 0,
    page: report.PAGE_NUMBER,
    pageSize: report.PAGE_SIZE,
    searchType: 'search',
    isCover: { link: '' },
    isTitle: '',
    allDataList: [],
    dataList: [],
    paginateData: [],
    searchInput: ''
  })
  const { t } = useTranslation()
  const {
    marketCourseAnalytics: { fetch },
    marketCourseAnalytics: {
      data: { created_at, enrolled_users = [] }
    }
  } = props.data

  const download = value => {
    const {
      marketCourseAnalytics: {
        data: { enrolled_users = [] }
      }
    } = props.data
    const {
      marketCourseAnalytics: { data }
    } = props.data
    let dataCSV = []
    let header = []
    data &&
      dataCSV.push({
        title: data.title,
        buyer_name: '',
        buyer_type: '',
        buyer_id: '',
        buying_quantity: '',
        purchased_date: '',
        unit_amount: '',
        edit_option: '',
        total_amount: ''
      })
    enrolled_users &&
      enrolled_users.forEach(item => {
        dataCSV.push({
          title: '',
          buyer_name: item.buyer_name,
          buyer_type: item.buyer_type,
          buyer_id: item.buyer_id,
          purchased_date: item.purchased_date,
          edit_option: item.edit_option,
          total_amount: item.total_amount,
          buying_quantity: item.buying_quantity,
          unit_amount: item.unit_amount,
          net_amount: item.net_amount
        })
      })

    header = [
      'Title',
      'Name',
      'Buyer Type',
      'Buyer Id',
      'Edit Option',
      'Purchased Date',
      'Quantity',
      'Total Amount',
      'Net Amount'
    ]

    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      document.body.appendChild(ele)
      ele.click()
      // generateCSV(dataCSV, header)
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'course-report.csv')
      ele.click()
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onExpand = (expanded, record) => {
    var keys = []
    if (expanded) {
      keys.push(record.id)
    }

    setState({ ...state, expandedRowKeys: keys })
  }

  const customExpandIcon = props => {
    return <Icon component={expandSvg} {...props} />
  }

  const onChangePagination = page => {
    const { allDataList, pageSize, paginateData, searchInput } = state
    if (searchInput.length > 0) {
      if (paginateData) {
        const searchResult = paginateData.slice(
          page * pageSize - pageSize,
          page * pageSize
        )
        setState({
          ...state,
          page: page,
          dataList: searchResult || []
        })
      }
    } else {
      if (allDataList) {
        const result = allDataList.slice(
          page * pageSize - pageSize,
          page * pageSize
        )
        setState({
          ...state,
          page: page,
          dataList: result || []
        })
      }
    }
  }

  const onSearchWithType = val => {
    const { searchType, allDataList } = state

    if (!allDataList) {
      // Handle the case where 'list' is undefined or null
      return
    }

    if (val === '') {
      setState({
        ...state,
        dataList: allDataList.slice(0, 10),
        searchInput: '',
        paginateData: []
      })
      return
    }

    if (searchType === 'search' || searchType === 'searchByname') {
      const searchResult = allDataList.filter(item => {
        return (
          item &&
          item.buyer_name &&
          item.buyer_name.toLowerCase().includes(val.toLowerCase())
        )
      })
      const dataList = searchResult.slice(0, 10)
      setState({
        ...state,
        paginateData: searchResult,
        page: 1,
        dataList: dataList || [],
        searchInput: val
      })
    }
  }

  const fetchData = () => {
    const {
      match: { params },
      getMarketCourseReport
    } = props

    getMarketCourseReport(params.id, 'course')
      .then(data => {
        if (data) {
          setState(prevState => ({
            ...prevState,
            isCover: data.cover,
            isLoading: false,
            isTitle: data.title,
            allDataList: data.buyers,
            dataList: data.buyers && data.buyers.slice(0, prevState.pageSize)
          }))
        }
        setState(prevState => ({
          ...prevState,
          isLoading: false
        }))
      })
      .catch(err => {
        setState(prevState => ({
          ...prevState,
          isLoading: false
        }))
      })
  }

  const callbackTabClicked = (key, event) => {
    if (key === '9') {
      props.history.push(`/reports`, 'marketplace')
    } else if (key === '1') {
      props.history.push('/reports')
    } else if (key === '2') {
      props.history.push('/reports', 'user')
    }
  }

  const {
    isLoading,
    page,
    pageSize,
    isCover,
    isTitle,
    dataList,
    allDataList,
    paginateData,
    searchInput
  } = state

  const filterPassedData = enrolled_users.filter(users => users.status === true)

  const total_percentage = parseInt((filterPassedData / enrolled_users) * 100)

  return (
    <div>
      <div className="content-warp">
        <div className="report-warp" style={{ direction: 'ltr' }}>
          <CustomTabs
            defaultActiveKey="9"
            onTabClick={callbackTabClicked}
            tabBarExtraContent={
              <ReportHeader
                download={() => download(total_percentage)}
                isDateActive
              />
            }
          >
            <TabPane tab={t('reports:workshop_report')} key="1"></TabPane>
            <TabPane tab={t('reports:user_report')} key="2"></TabPane>
            <TabPane
              className="sharelok-marketplace-report"
              tab={t('v4:marketplace_report')}
              key="9"
            >
              <MarketplaceTypeSelect defaultValue="courses" />
              <div className="custom-table">
                <Row>
                  <Col span={7}>
                    <Row>
                      <BackButton
                        onClick={() =>
                          props.history.push('/reports/market-place/course/')
                        }
                        size="large"
                        style={{
                          marginTop: '20px',
                          marginLeft: '15px'
                        }}
                      >
                        <LeftOutlined />
                        BACK
                      </BackButton>
                    </Row>
                    <Card
                      className={
                        dataList.length === 0
                          ? 'custom-card-empty'
                          : 'market-place-custom-card'
                      }
                    >
                      {isCover.link && (
                        <img className="table-img" src={isCover.link} alt="" />
                      )}

                      <h3 className="custom-title">{isTitle}</h3>

                      <Divider
                        type="horizontal"
                        dashed
                        style={{ borderColor: 'rgb(155 155 155)' }}
                      />
                      <Row gutter={[0, 40]}>
                        <Col
                          span={12}
                          push={1}
                          style={{ fontWeight: 'bold', color: 'black' }}
                        >
                          {t('reports:created_date').toUpperCase()}
                        </Col>
                        <Col span={12} push={6}>
                          {moment(created_at).format('YYYY/MM/DD')}
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={12}
                          push={1}
                          style={{ fontWeight: 'bold', color: 'black' }}
                        >
                          {t('reports:participants').toUpperCase()}
                        </Col>
                        <Col span={12} push={6}>
                          {dataList.length}
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col span={17}>
                    <ReportTable
                      rowKey="id"
                      columns={columns(t)}
                      dataSource={dataList}
                      onExpand={onExpand}
                      expandIcon={customExpandIcon}
                      expandedRowKeys={state.expandedRowKeys || []}
                      expandIconAsCell={false}
                      expandIconColumnIndex={6}
                      expandRowByClick
                      pagination={false}
                      loading={isLoading}
                      width
                      style={{
                        width: '92%',
                        marginLeft: '35px'
                      }}
                      title={() => (
                        <>
                          <Row>
                            <Col span={12}>
                              <div className="reports-user-search">
                                <Search
                                  placeholder={t('v4:search_people')}
                                  onChange={e =>
                                    onSearchWithType(e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                            <Col className="report-title" span={5} push={2}>
                              {t('v4:total')} {dataList.length} {}
                              {t('reports:participants').toUpperCase()}
                            </Col>

                            <Col span={6} style={{ marginTop: '14px' }}>
                              <PaginationRightAligned>
                                <Pagination
                                  total={
                                    searchInput === ''
                                      ? allDataList.length
                                      : paginateData.length
                                  }
                                  current={page}
                                  pageSize={pageSize}
                                  onChange={onChangePagination}
                                  size="small"
                                  simple
                                />
                              </PaginationRightAligned>
                            </Col>
                          </Row>
                          <Divider style={{ margin: '0px' }} />
                        </>
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </TabPane>
          </CustomTabs>
        </div>
      </div>
    </div>
  )
}

export default Details
