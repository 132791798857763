const scenarioOptions = {
  learningObjective: {
    value: '',
    description:
      'The key concept to be tested (learning objective(s) that the scenario should address). <Open ended>'
  },
  difficultyLevel: {
    value: ['Beginner', 'Intermediate', 'Advanced', 'Expert'],
    description:
      'Level of difficulty for the scenario. Dropdown values: <Beginner, Intermediate, Advanced, Expert>'
  },
  learnerRole: {
    value: [
      'Technician',
      'Developer',
      'Software Engineer',
      'Data Analyst',
      'Network Administrator',
      'Student',
      'Teacher',
      'Professor',
      'Administrator',
      'Researcher',
      'Tutor',
      'System Administrator',
      'Security Analyst',
      'IT Specialist',
      'Manager',
      'Sales Representative',
      'Marketing Specialist',
      'Customer Service Representative',
      'Accountant',
      'Project Manager',
      'Human Resources Specialist',
      'Doctor',
      'Nurse',
      'Therapist',
      'Pharmacist',
      'Medical Assistant',
      'Scientist',
      'Engineer',
      'Lab Technician',
      'Carpenter',
      'Electrician',
      'Plumber',
      'Welder',
      'Construction Worker',
      'Architect',
      'Other'
    ],
    description:
      'The role or perspective the learner takes in the scenario. Dropdown values: <Technician, Developer, Software Engineer, Data Analyst, Network Administrator, Student, Teacher, Professor, Administrator, Researcher, Tutor, System Administrator, Security Analyst, IT Specialist, Manager, Sales Representative, Marketing Specialist, Customer Service Representative, Accountant, Project Manager, Human Resources Specialist, Doctor, Nurse, Therapist, Pharmacist, Medical Assistant, Researcher, Scientist, Engineer, Lab Technician, Carpenter, Electrician, Plumber, Welder, Construction Worker, Architect, Other>'
  },
  problemStatement: {
    value: '',
    description:
      'A brief description of the scenario e.g., the case study is of a web developer struggling to keep up with framework updates. <Open ended>'
  },
  setting: {
    value: [
      'Education',
      'Healthcare',
      'Business',
      'Customer Service',
      'Construction',
      'Technology',
      'Agriculture',
      'Mining',
      'Transportation and Logistics',
      'Hospitality and Tourism',
      'Government and Public Administration',
      'Non-Profit Organizations',
      'Other'
    ],
    description:
      'The context or environment in which the scenario takes place. Dropdown values: <Education, Healthcare, Business, Customer Service, Construction, Technology, Agriculture, Mining, Transportation and Logistics, Hospitality and Tourism, Government and Public Administration, Non-Profit Organizations, Other>'
  },
  numberOfOptions: {
    value: [2, 3, 4],
    description:
      'The number of choices or paths available to the learner. This section to be disabled if the learner selects open-ended scenario type. <2, 3, 4>'
  }
}

const scenarioDummyValues = {
  learningObjective: {
    value: 'To teach a computer language for beginners to intermediate',
    description:
      'The key concept to be tested (learning objective(s) that the scenario should address). <Open ended>'
  },
  difficultyLevel: {
    value: ['Beginner', 'Intermediate', 'Advanced', 'Expert'],
    description:
      'Level of difficulty for the scenario. Dropdown values: <Beginner, Intermediate, Advanced, Expert>'
  },
  learnerRole: {
    value: 'Student',
    description:
      'The role or perspective the learner takes in the scenario. Dropdown values: <Technician, Developer, Software Engineer, Data Analyst, Network Administrator, Student, Teacher, Professor, Administrator, Researcher, Tutor, System Administrator, Security Analyst, IT Specialist, Manager, Sales Representative, Marketing Specialist, Customer Service Representative, Accountant, Project Manager, Human Resources Specialist, Doctor, Nurse, Therapist, Pharmacist, Medical Assistant, Researcher, Scientist, Engineer, Lab Technician, Carpenter, Electrician, Plumber, Welder, Construction Worker, Architect, Other>'
  },
  problemStatement: {
    value: 'To teach a computer language for beginners to intermediate',
    description:
      'A brief description of the scenario e.g., the case study is of a web developer struggling to keep up with framework updates. <Open ended>'
  },
  setting: {
    value: 'Technology',
    description:
      'The context or environment in which the scenario takes place. Dropdown values: <Education, Healthcare, Business, Customer Service, Construction, Technology, Agriculture, Mining, Transportation and Logistics, Hospitality and Tourism, Government and Public Administration, Non-Profit Organizations, Other>'
  },
  numberOfOptions: {
    value: [2, 3, 4],
    description:
      'The number of choices or paths available to the learner. This section to be disabled if the learner selects open-ended scenario type. <2, 3, 4>'
  }
}

export default scenarioOptions
