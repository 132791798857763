import React from 'react'
import { Button } from 'antd'
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop'

import '../styles.scss'
import actions from '../../../../../../../../store/actions'
import ContentLoader from '../../../../../internal/ContentLoader'

const portraitAspectRatio = 0.75
const landscapeAspectRatio = 1.96

class BackgroundImageCropper extends React.Component {
  state = {
    isLandscape: false,
    base64dataUrl: null,
    base64CroppedImage: null,
    crop: { aspect: portraitAspectRatio }
  }

  componentDidMount() {
    this.toDataURL(this.props.selectedBgImage, base64dataUrl =>
      this.setState({ base64dataUrl })
    )
  }

  toDataURL({ id, resizes: { extra_large } }, callback) {
    const { download } = this.props
    download({ id, size: extra_large }).then(response => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function() {
        const reader = new FileReader()
        reader.onloadend = function() {
          callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open('GET', response.downloadble_link)
      xhr.responseType = 'blob'
      xhr.send()
    })
  }

  handleCropComplete = async (crop, percentCrop) => {
    const base64CroppedImage = await this.getCroppedImg(this.imageRef, crop)
    this.setState({ base64CroppedImage })
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    return new Promise((resolve, reject) => {
      const base64Image = canvas.toDataURL('image/jpeg')
      resolve(base64Image)
    })
  }

  handleImageLoaded = image => (this.imageRef = image)

  handleCropChange = (crop, percentCrop) => this.setState({ crop })

  toggleLandscape = () => {
    const isLandscape = !this.state.isLandscape
    this.setState({
      isLandscape,
      crop: isLandscape
        ? { aspect: landscapeAspectRatio }
        : { aspect: portraitAspectRatio }
    })
  }

  render() {
    const { t, onClickClose, onClickDone } = this.props
    const { crop, isLandscape, base64dataUrl, base64CroppedImage } = this.state
    return base64dataUrl ? (
      <>
        <Button
          size="large"
          onClick={this.toggleLandscape}
          className="ant-btn landscape-portrait-button"
        >
          {isLandscape ? t('labels:portrait') : t('labels:landscape')}
        </Button>
        <div>
          <ReactCrop
            crop={crop}
            src={base64dataUrl}
            crossorigin="anonymous"
            onChange={this.handleCropChange}
            onImageLoaded={this.handleImageLoaded}
            onComplete={(crop, pixelCrop) =>
              this.handleCropComplete(crop, pixelCrop)
            }
          />
          <div className="wrap-btn-actions">
            <Button size="large" shape="round" onClick={onClickClose}>
              {t('labels:cancel')}
            </Button>
            <Button
              size="large"
              shape="round"
              type="primary"
              className="ml-4"
              onClick={() => {
                const data =
                  base64CroppedImage === 'data:,' ? null : base64CroppedImage
                onClickDone(data)
              }}
            >
              {t('labels:done')}
            </Button>
          </div>
        </div>
      </>
    ) : (
      <ContentLoader />
    )
  }
}

export default connect(null, {
  download: actions.library.download
})(BackgroundImageCropper)
