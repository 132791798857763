import { TOPICS_SUCCESS, TOPICS_FETCHING, UPDATE_NEW_TOPIC } from '../types'

const initialState = {
  isFetching: false,
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOPICS_SUCCESS:
      return {
        ...state,
        list: action.payload
      }
    case UPDATE_NEW_TOPIC:
      return {
        ...state,
        list: [...state.list, action.payload]
      }
    case TOPICS_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    default:
      return state
  }
}
