import http, {
  httpCustomAudioInstance,
  httpCustomSummarizedInstance,
  httpShareBotInstance,
  questionAnswerAiInstance
} from './http'
import { config } from '../../constants'

/**
 * Create new audio synthesized voice.
 * @param {object} data
 */
function audioSynthesizerCreate(data) {
  return http({
    method: 'POST',
    url: config.audioSynthesizer,
    data
  })
}

function getTTSAudioSynthesizerList() {
  return http({
    method: 'GET',
    // url: `${config.ttsAudioSynthesizer}`
    url: `https://f92rt8vby8.execute-api.ap-southeast-1.amazonaws.com/prod/tts`
  })
}

function getTTSAudioListForMaze() {
  return fetch(
    'https://texttospeech.googleapis.com/v1/voices?key=AIzaSyA2tjVQMLdLK_q969dwNv5awFFIQH19ZwY'
  )
    .then(response => response.json())
    .then(data => {
      let voicesObj = {}
      data.voices.forEach(element => {
        console.log('VOICES RESPONSE DATA INTERNAL', element)
        let languageNames = new Intl.DisplayNames(['en'], { type: 'language' })
        voicesObj[element.name] = {
          gender: element.ssmlGender,
          language: languageNames.of(element.languageCodes[0]),
          sample: `https://cloud.google.com/static/text-to-speech/docs/audio/${element.name}.wav`
        }
      })
      return voicesObj
      //console.log("VOICES RESPONSE voicesObj", voicesObj)
      //console.log("VOICES RESPONSE DATA", data)
    })
}

function getNonEnglishVoices() {
  return httpCustomSummarizedInstance({
    method: 'GET',
    url: 'non-english-coqui-tts'
  })
}

function postTTSAudioSynthesizerList(data) {
  return http({
    method: 'POST',
    // url: `${config.ttsAudioSynthesizer}`,
    url: `https://f92rt8vby8.execute-api.ap-southeast-1.amazonaws.com/prod/tts`,
    data
  })
}

function textTranslate(data) {
  return http({
    method: 'POST',
    url: `https://f92rt8vby8.execute-api.ap-southeast-1.amazonaws.com/prod/translate`,
    data
  })
}
function questionBot(data) {
  return questionAnswerAiInstance({
    method: 'POST',
    url: '/',
    data: {
      params: data
    }
  })
}

function openAIBot(data) {
  return httpShareBotInstance({
    method: 'POST',
    url: '/openai',
    data: {
      params: data
    }
  })
}

function summarize(data) {
  return httpCustomSummarizedInstance({
    method: 'POST',
    url: `/summarization?dpath=abstractive-summarization`,
    data
  })
}

function getNewAudioAdminRes() {
  return httpCustomAudioInstance({
    method: 'GET',
    url: '/'
  })
}

function postNewAudioAdminRes(data) {
  return httpCustomAudioInstance({
    method: 'POST',
    url: '/tts',
    data: data
  })
}

function getVoicePreviewByFile(file) {
  return httpCustomSummarizedInstance({
    method: 'GET',
    url: `nvidia-english/tts/voice/${file}`
  })
}

function getNonEnglishVoicePreviewByFile(file) {
  return httpCustomSummarizedInstance({
    method: 'GET',
    url: `non-english-coqui-tts/tts/voice/${file}`
  })
}

export {
  audioSynthesizerCreate,
  getTTSAudioSynthesizerList,
  getTTSAudioListForMaze,
  postTTSAudioSynthesizerList,
  textTranslate,
  questionBot,
  summarize,
  getNewAudioAdminRes,
  postNewAudioAdminRes,
  getVoicePreviewByFile,
  getNonEnglishVoices,
  getNonEnglishVoicePreviewByFile,
  openAIBot
}
