import React, { useState, useMemo, Fragment } from 'react'

import { Col, Divider, Icon, Pagination, Row, Statistic } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'
import moment from 'moment'
import { Search } from '../../../../../../../components'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { userImageNoBack } from '../../../../../images'
import { report } from '../../../../../../../constants'

const customExpandIcon = props => {
  return <Icon type={props.expanded ? 'up' : 'down'} {...props} />
}

const UserQuizTable = props => {
  const {
    t,
    data: {
      quizzes: { data, loading }
    },
    isUserAsHost: isHost
  } = props

  const participantQuizColumns = useMemo(
    () => [
      {
        title: t('v2:item'),
        dataIndex: 'title'
      },
      {
        title: t('reports:score'),
        dataIndex: 'score',
        render: (text, record) => record.points
      },
      { title: t('v2:questions'), dataIndex: 'question_count' },
      {
        title: t('reports:submit'),
        dataIndex: 'attempts',
        render: (item, record) =>
          record && record.is_simulation
            ? Number(record.attempts_count)
            : item && item.length
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const hostQuizColumns = useMemo(
    () => [
      {
        title: t('v2:item'),
        dataIndex: 'title'
      },
      {
        title: t('v2:total_points'),
        dataIndex: 'score',
        render: (text, record) => record.quiz_points
      },
      {
        title: t('v2:total_attempts'),
        dataIndex: 'attempts_count'
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(data.length)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const quizzes = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  console.log('check data log', total)

  return (
    <ReportTable
      isDateActive
      borderRadius
      columns={isHost ? hostQuizColumns : participantQuizColumns}
      loading={loading}
      dataSource={quizzes}
      expandIconAsCell={false}
      expandIconColumnIndex={isHost ? 3 : 4}
      expandIcon={customExpandIcon}
      expandRowByClick
      pagination={false}
      expandedRowRender={item => {
        return (
          <div className="question-description">
            <Row>
              {isHost ? (
                <div className="host_quiz_data">
                  <h3>
                    <Icon type="check" style={{ color: 'green' }} />{' '}
                    {t('v2:correct_feedback')} : {item.feedback_correct}
                  </h3>
                  <p>
                    <Icon type="close" style={{ color: 'red' }} />{' '}
                    {t('v2:incorrect_feedback')} : {item.feedback_incorrect}
                  </p>
                </div>
              ) : (
                <div className="question-list">
                  {renderParticipantQuizData(item, t)}
                </div>
              )}
            </Row>
          </div>
        )
      }}
      title={() => (
        <>
          <div className="user-report-table-title">
            <div>
              <SearchEventMargin>
                <Search
                  placeholder={t('placeholders:search')}
                  onChange={e => onSearch(e.target.value)}
                />
              </SearchEventMargin>
            </div>
            <div className="user-report-table-pagination">
              <span>{t('general:page').toUpperCase()}</span>
              <PaginationRightAligned>
                <Pagination
                  total={total}
                  simple
                  current={currentPage}
                  pageSize={10}
                  onChange={onPaginationChange}
                />
              </PaginationRightAligned>
            </div>
          </div>
          <Divider style={{ margin: '0px' }} />
        </>
      )}
    />
  )
}

const renderParticipantQuizData = (item, t) => {
  if (item.is_simulation) {
    const isPassed = item.status === 'pass'
    return (
      <div className="shl-simulation-user-detail">
        <Statistic title="Type" value="Simulation" />
        <Statistic title="Pass Score" value={item.pass_score} />
        <Statistic title="Total Score" value={item.total_scores} />
        <Statistic
          title="Time Spent"
          value={moment
            .duration(item.time_spent_seconds, 'seconds')
            .format('m [min] s [s]')}
        />
        <Statistic
          title="Status"
          value={isPassed ? 'Passed' : 'Not Passed'}
          valueStyle={{ color: isPassed ? 'green' : 'blue' }}
          prefix={<Icon type={isPassed ? 'check' : 'exclamation-circle'} />}
        />
      </div>
    )
  }
  return (
    <>
      {item &&
        item.attempts &&
        item.attempts.map((item, i) => (
          <Fragment key={i}>
            {item.type === 'single' && (
              <Fragment>
                <Col span={18}>
                  <p>
                    <span>Q{i + 1}. </span>
                    <b>{item.quiz_question}</b>
                  </p>

                  <ul>
                    {_.isEmpty(item.answer) && (
                      <li
                        key={i}
                        style={{
                          textDecoration: 'line-through',
                          background: 'rgba(235,235,235,0.9)'
                        }}
                      >
                        {t('v2:answer_not_available')}
                      </li>
                    )}
                    {!_.isEmpty(item.answer) &&
                      item.answer.map((item, i) => (
                        <li key={i}>
                          <span
                            className={
                              !item.hasOwnProperty('correct')
                                ? 'radio'
                                : item.correct
                                ? 'radio active'
                                : 'radio inactive'
                            }
                          />
                          {item.answer}
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col span={6}>
                  <div className="tb-report-warp">
                    {/* <span>
                        score
                      </span>
                      <span className="score">
                        {item.scores}
                      </span> */}
                  </div>
                </Col>
              </Fragment>
            )}

            {item.type === 'multiple' && (
              <Fragment key={i}>
                <Col span={18}>
                  <p>
                    <span>Q{i + 1}. </span>
                    <b>{item.quiz_question}</b>
                  </p>

                  <ul>
                    {item.answer.map((item, i) => (
                      <Fragment key={i}>
                        <li
                          key={i}
                          className={
                            item.hasOwnProperty('correct')
                              ? item.correct
                                ? 'text-success'
                                : 'text-danger'
                              : ''
                          }
                        >
                          <span
                            className={
                              item.hasOwnProperty('correct')
                                ? item.correct
                                  ? 'checkbox filled'
                                  : 'checkbox danger'
                                : 'checkbox'
                            }
                          >
                            {item.hasOwnProperty('correct') ? (
                              <Icon type="check" />
                            ) : (
                              ''
                            )}
                          </span>
                          {item.answer}
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </Col>
                <Col span={6}>
                  <div className="tb-report-warp">
                    {/* <span>
                        score
                      </span>
                      <span className="score">
                        {item.scores}
                      </span> */}
                  </div>
                </Col>
              </Fragment>
            )}

            {item.type === 'free' && (
              <Fragment key={i}>
                <Col span={18}>
                  <p>
                    <span>Q{i + 1}. </span>
                    <b>{item.quiz_question}</b>
                  </p>

                  <ul>
                    {_.isEmpty(item.answer) && (
                      <li
                        style={{
                          textDecoration: 'line-through',
                          background: 'rgba(235,235,235,0.9)'
                        }}
                      >
                        {report.ANSWER_NOT_AVAILABLE}
                      </li>
                    )}

                    {!_.isEmpty(item.answer) &&
                      item.answer.map((item, i) => (
                        <Fragment key={i}>
                          <li
                            key={i}
                            className={item.correct ? 'text-blue' : 'text-red'}
                          >
                            {item.answer}
                          </li>
                        </Fragment>
                      ))}
                  </ul>
                </Col>
                <Col span={6}>
                  <div className="tb-report-warp">
                    {/* <span>
                        score
                      </span>
                      <span className="score">
                        {item.scores}
                      </span> */}
                  </div>
                </Col>
              </Fragment>
            )}

            {item.type === 'sorting' && (
              <Fragment key={i}>
                <Col span={18}>
                  <p>
                    <span>Q{i + 1}. </span>
                    <b>{item.quiz_question}</b>
                  </p>

                  <ul>
                    {item.hasOwnProperty('answer') &&
                      item.answer.map((item, i) => (
                        <Fragment key={i}>
                          <li className="box">
                            <Icon type="more" />
                            <Icon type="more" />{' '}
                            <p
                              className={
                                item.correct ? 'text-success' : 'text-red'
                              }
                            >
                              {item.answer}
                            </p>
                          </li>
                        </Fragment>
                      ))}
                  </ul>
                </Col>
                <Col span={6}>
                  <div className="tb-report-warp">
                    {/* <span>
                        score
                      </span>
                      <span className="score">
                        {item.scores}
                      </span> */}
                  </div>
                </Col>
              </Fragment>
            )}

            {item.type === 'matrix' && (
              <Fragment key={i}>
                <Col span={18}>
                  <p>
                    <span>Q{i + 1}. </span>
                    <b>{item.quiz_question}</b>
                  </p>

                  <ul>
                    {item.answer.map((item, i) => (
                      <Fragment key={i}>
                        <li key={i}>
                          {item.content !== '' && (
                            <img alt="" src={item.content} />
                          )}
                          {item.content === '' && (
                            <img alt="" src={userImageNoBack} />
                          )}
                          <span
                            className={
                              item.correct ? 'box-success' : 'box-danger'
                            }
                          >
                            {item.element}
                          </span>
                        </li>
                      </Fragment>
                    ))}
                  </ul>
                </Col>
                <Col span={6}>
                  <div className="tb-report-warp">
                    {/* <span>
                        score
                      </span>
                      <span className="score">
                        {item.scores}
                      </span> */}
                  </div>
                </Col>
              </Fragment>
            )}
          </Fragment>
        ))}
    </>
  )
}
export default withTranslation()(UserQuizTable)
