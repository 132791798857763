import styled, { css } from 'styled-components'
import { Form, Icon } from 'antd'
import { Modal } from '../../../../../../components'

import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const ModalOrganisations = styled(Modal)`
  && {
    .ant-form-item-label {
      label {
        font-weight: 600;
        font-size: 1.25rem;
        text-transform: none;
        color: #000000 !important;

        &.ant-form-item-required {
          &:before {
            display: none;
          }
        }
      }
    }
  }
`

ModalOrganisations.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 16px;
`

ModalOrganisations.Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.blue};
`

ModalOrganisations.Close = styled.div`
  cursor: pointer;
`

ModalOrganisations.Icon = styled(Icon)`
  && {
    svg {
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
    }
  }
`
ModalOrganisations.Container = styled.div`
  width: 100%;
`

ModalOrganisations.Upload = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  .ant-spin-nested-loading {
    width: 100%;
  }
`

ModalOrganisations.Dropzone = styled.div`
  height: 100%;
  width: 100%;
  border: 1px dashed ${colors.darkGreyTwo};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1.4rem 0;
  transition: border-color 0.2s;

  &:hover {
    border-color: ${colors.blue};
  }

  ${props =>
    props.active &&
    css`
      background: rgba(0, 104, 255, 0.1);
      border-color: ${colors.blue};
    `}

  ${props =>
    props.background &&
    css`
      height: 15rem;
      background: url(${props.background}) no-repeat center;
      background-size: cover;
    `}
`

ModalOrganisations.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`

ModalOrganisations.Dropzone.Button = styled.div`
  border-radius: 5rem;
  border: 1px solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  padding: 0.4rem 2.4rem;
  text-decoration: uppercase;
  transition: border-color 0.2s, color 0.2s, opacity 0.2s;

  i {
    margin-right: 0.8rem;
  }

  ${ModalOrganisations.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props =>
      props.hide &&
      css`
        background: rgba(0, 104, 255, 0.3);
        border-color: ${colors.white};
        color: ${colors.white};
        opacity: 1;
      `}
  }

  ${props =>
    props.hide &&
    css`
      opacity: 0;
      border-color: ${colors.white};
      color: ${colors.white};
    `}
`

ModalOrganisations.BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 1.5rem 0;
`

const FormItem = styled(Form.Item)`
  && {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    &::before,
    &::after {
      display: none;
    }
    width: 100%;
    margin-bottom: 0.5rem;

    .ant-calendar-picker,
    .ant-time-picker {
      width: 100%;
    }
  }
`

const LargeFormItem = styled(FormItem)`
  && {
    align-items: flex-start;
    margin-top: 1rem;
    .ant-form-item-label {
      margin-top: 1rem !important;
    }
  }
`

export { ModalOrganisations, FormItem, LargeFormItem }
