import React from 'react'
import QuizView from '../QuizView'
import AnnotationView from '../AnnotationView'

import { Preview } from '../../../../../components/Builder'
import { builder } from '../../../../../constants'
import ScenarioView from '../ScenarioView'

const { types } = builder

export default props => {
  const {
    node,
    node: {
      auto,
      type,
      params,
      params: { width, height, x, y }
    },
    ids,
    onDragStart,
    onDragStop,
    toggleQuizRestriction,
    isFullscreen,
    scaleFactor: scale
  } = props

  const style = {
    position: 'absolute',
    width: isFullscreen ? width * scale : width,
    height: isFullscreen ? height * scale : height,
    top: isFullscreen ? y * scale : y,
    left: isFullscreen ? x * scale : x,
    zIndex: 5
  }

  if (type === types.BACKGROUND_IMAGE) {
    return (
      <img
        style={{ width: '100%', maxHeight: isFullscreen ? '100vh' : '586px' }}
        src={params.content}
        alt=""
      />
    )
  }

  if (type === types.BACKGROUND_IMAGE_AUTO && auto === true) {
    return (
      <img
        style={{ width: '100%', maxHeight: isFullscreen ? '100vh' : '586px' }}
        src={params.content}
        alt=""
      />
    )
  }

  return (
    <div style={style}>
      {type === types.TEXT && <Preview node={node} isView scale={scale} />}
      {type === types.IMAGE && <Preview node={node} />}
      {type === types.ARIMAGE && <Preview node={node} />}
      {type === types.VIDEO && <Preview node={node} />}
      {type === types.AUDIO && (
        <Preview
          elementHeight={isFullscreen ? 40 : 32}
          elementWidth={isFullscreen ? width * scale : width < 199 ? 45 : width}
          node={node}
          scale={scale}
        />
      )}
      {type === types.VIDEO_STREAM && <Preview node={node} />}
      {type === types.ANNOTATION && (
        <AnnotationView
          isFullscreen={isFullscreen}
          content={params.content}
          ids={ids}
        />
      )}
      {type === types.QUIZ && (
        <QuizView
          onDragStart={onDragStart}
          onDragStop={onDragStop}
          content={params}
          ids={ids}
          toggleQuizRestriction={toggleQuizRestriction}
          scale={scale}
          isFullscreen={isFullscreen}
        />
      )}
      {type === types.SCENARIO && <ScenarioView content={params} />}
    </div>
  )
}
