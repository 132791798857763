import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content } from '../../styled'
import { Form, Button, Input, Spin, Icon } from 'antd'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import { api } from '../../../../services'
import classnames from 'classnames'

class Feedback extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageFile: [],
      feedback: '',
      isSent: false,
      isSending: false,
      error: false,
      attachementId: '',
      showRequired: []
    }
  }
  handleSubmit = () => {
    const { library } = this.props
    const { feedback, imageFile } = this.state
    this.setState({
      isSending: true
    })
    const attachmentId = imageFile
      ? library.uploadSuccess[0]
        ? library.uploadSuccess[0].id
        : ''
      : ''
    api.help
      .feedback({
        message: feedback,
        attachement_id: attachmentId
      })
      .then(() => {
        this.setState({
          isSent: true,
          imageFile: null,
          feedback: '',
          isSending: false,
          error: null,
          showRequired: []
        })
      })
      .then(() => {
        setTimeout(() => {
          this.setState({
            isSent: false
          })
        }, 3000)
      })
      .catch(error => {
        this.setState({
          error: error,
          imageFile: null,
          feedback: ''
        })
      })
  }

  handleUpdate = event => {
    this.setState({
      feedback: event.target.value
    })
  }

  handleCancel = () => {
    this.setState({
      feedback: '',
      imageFile: []
    })
  }

  onDrop = imageFiles => {
    const file = imageFiles[0]
    this.setState({
      imageFile: file
    })
    this.props.upload(file)
  }

  handleBlurValidation = event => {
    if (!this.state.showRequired.includes('event.target.name]')) {
      this.setState({
        showRequired: [...this.state.showRequired, event.target.name]
      })
    }
  }

  render() {
    const { t, library } = this.props
    const { isSent, feedback, isSending, imageFile } = this.state
    return (
      <Content>
        <Content.Heading>{t('feedback.title')}</Content.Heading>
        <Content.Form>
          {isSent ? (
            <p>
              <Icon type="check" />
              {t('feedback.sent')}
            </p>
          ) : (
            <>
              <Form.Item>
                {this.state.showRequired.includes('feedback') &&
                  feedback.length <= 0 && (
                    <span className="required-warning-text">
                      {t('feedback.content_required')}
                    </span>
                  )}
                <Input.TextArea
                  rows={4}
                  placeholder={t('feedback')}
                  style={{ resize: 'none' }}
                  value={feedback}
                  onChange={event => this.handleUpdate(event)}
                  disabled={isSending}
                  name="feedback"
                  onBlur={event => this.handleBlurValidation(event)}
                  className={classnames({
                    'required-warning':
                      this.state.showRequired.includes('feedback') &&
                      feedback.length <= 0
                  })}
                />
                <Content.Flex>
                  <Content.Label>{t('feedback.attach_title')}</Content.Label>
                  <div>
                    <Dropzone
                      onDrop={this.onDrop}
                      className="dropzone"
                      activeClassName="active-dropzone"
                      multiple={false}
                    >
                      <Button type="default" size="default" className="ant-btn">
                        {library.uploadFetching ? (
                          <Spin spinning={library.uploadFetching}>
                            {t('feedback.choose')}
                          </Spin>
                        ) : (
                          t('feedback.choose')
                        )}
                      </Button>

                      {!isSent &&
                        imageFile &&
                        imageFile.length > 0 &&
                        library.uploadSuccess &&
                        library.uploadSuccess[0] && (
                          <p>{library.uploadSuccess[0].filename}</p>
                        )}
                      <p>{t('feedback.detail')}</p>
                    </Dropzone>
                  </div>
                </Content.Flex>
              </Form.Item>
              <div className="form-footer">
                <Button
                  type="default"
                  size="default"
                  className="ant-btn"
                  onClick={this.handleCancel}
                  disabled={isSending || !feedback}
                >
                  {t('feedback.cancel')}
                </Button>
                <Button
                  type="primary"
                  size="default"
                  className="ant-btn ant-btn-primary"
                  onClick={this.handleSubmit}
                  disabled={isSending || !feedback}
                >
                  {isSending ? (
                    <Spin spinning={isSending}>{t('feedback.submit')}</Spin>
                  ) : (
                    t('feedback.submit')
                  )}
                </Button>
              </div>
            </>
          )}
        </Content.Form>
      </Content>
    )
  }
}

const mapStateToProps = state => ({
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('help_center')(Feedback))
