/*
* @Description: In User Settings Edit
* @Author: your name
* @Date: 2019-08-09 16:41:54
* @LastEditTime: 2019-12-19 10:53:49
* @LastEditTime: 2019-09-09 11:43:43
* @LastEditors: Please set LastEditors
*/

export default {
  general: {
    save: 'Сохранить',
    autosaved: 'Сохранено автоматически',
    back_to: 'Назад к',
    by: 'от',
    chat: 'Чат',
    completed: 'Завершено',
    canceled: 'Отменено',
    component: 'Компонент',
    confirm: 'Подтвердить',
    course: 'Курс',
    date: 'Дата',
    details: 'Детали',
    empty: 'Нет данных',
    enrolled: 'Зачислен',
    free: 'Бесплатно',
    graph: 'График',
    loading: 'Загрузка...',
    lesson: 'занятие',
    lessons: 'занятия',
    live_assessment: 'Оценивание в режиме реального времени',
    live_event: 'Прямая трансляция',
    no: 'Нет',
    of: 'Из',
    or: 'Или',
    organization: 'Организация',
    page: 'Страница',
    pages: 'Страницы',
    personal: 'Личный',
    poll: 'Опрос',
    rating: 'Рейтинг',
    reset: 'Сбросить',
    signin: 'Войти',
    signup: 'Зарегистрироваться',
    soon: 'Вскоре',
    type: 'Тип',
    uploading: 'Загрузка...',
    yes: 'Да',
    something_went_wrong: 'Что-то пошло не так!',
    view_all: 'Просмотреть все',
    upload_successful: 'Загрузка успешно завершена',
    upload_successful_message:
      'Ваши документы Excel успешно загружены и в настоящее время обрабатываются. Это может занять некоторое время.',
    check_email_upload_successful:
      'Пожалуйста, <0>проверьте свою электронную почту</0> на предмет наличия <1>отчета</1> о загруженном списке пользователей.',
    is_private: 'Приватный'
  },

  account: {
    title: 'Аккаунт',
    subtitle: 'Управляйте настройками и информацией о своем аккаунте.',
    saved: 'Аккаунт успешно изменен'
  },

  assessments: {
    title: 'Оценивание',
    subtitle: 'Создать оценивание',
    finished: 'Оценивание завершено',
    countdown: ' Оценивание в режиме реального времени будет транслироваться через ',
    create: 'Создать оценивание',
    list_title: 'Ваши оценивания',
    delete_warning: 'Вы уверены, что хотите удалить этот элемент?',
    details_title: 'Добавить детали оценивания',
    details_subtitle:
      'Дайте название своему оцениванию и расскажите аудитории, чего они должны ожидать от него.',
    disclaimer: `Я гарантирую, что записанное мной оценивание не будет: I
    - нарушать права третьих лиц
    - содержать противозаконные действия, то есть не будет содержать клеветческий, непристойный, неприличный, пошлый, порнографический, насильственный, оскорбительный, обидный, угрожающий или дискриминационный контент, даже замаскированный звездочками
    - содержать каких-либо личных нападок на других лиц
    - содержать любую выдачу себя за других участников, общественных деятелей или знаменитостей
    - содержать каких-либо записей или личной информации о каком-либо человеке без согласия такого лица и не будет нарушать какие-либо правила защиты данных в любой части мира
    - содержать коммерческих предложений или «спама» в любой форме`
  },

  buildersettings: {
    title: 'Опубликовать настройки',
    subtitle:
      'Добавьте настройки для вашего мероприятия относительно того, кто может просматривать и взаимодействовать с вашим мероприятием'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Добавить',
    add_page: 'Добавить страницу',
    add_lesson: 'Добавить занятия',
    add_answer: 'Добавить новый ответ',
    add_group: 'Добавить группу',
    add_organization: 'Добавить организацию',
    add_question: 'Добавить вопрос',
    add_quiz: 'Добавить викторину',
    add_users: 'Добавить пользователей',
    back: 'Назад',
    back_to_course: 'Назад к обзору курса',
    back_to_lesson: 'Назад к обзору занятия',
    back_to_media: 'Назад в медиатеку',
    back_to_details: 'Назад к деталям',
    cancel: 'Отмена',
    clear: 'Очистить',
    close: 'Закрыть',
    clone: 'Клонировать',
    close_lesson: 'Закрыть занятие',
    clone_course: 'Клонировать курс',
    confirm: 'Подтвердить',
    create: 'Создать',
    download: 'Скачать',
    download_mass_user_tamplate: 'Скачать шаблон',
    add_mass_users: 'Добавить массовых пользователей',
    upload_excel_list: 'Загрузить список Excel',
    delete: 'Удалить медиа',
    edit: 'Редактировать',
    edit_course: 'Редактировать курс',
    enroll: 'Записаться',
    finish: 'Завершить',
    upload_image: 'Загрузить изображение',
    upload_image_video: 'Загрузить изображение или видео',
    select_media_library: 'Выбрать медиатеку',
    make_public: 'Сделать публичным',
    make_private: 'Сделать приватным',
    make_org: 'Создать организацию',
    media: 'Медиатека',
    my_theme: 'Моя тема',
    next: 'Далее',
    next_lesson: 'Следующее занятие',
    next_step: 'Следующий шаг',
    previous_step: 'Предыдущий шаг',
    publish: 'Опубликовать',
    publish_assessment: 'Опубликуйте свое оценивание',
    publish_course: 'Опубликуйте свой курс',
    publish_event: 'Опубликуйте свое мероприятие',
    reset: 'Сбросить',
    reset_elements: 'Сбросить элементы',
    save: 'Сохранить',
    creating: 'Создание...',
    updating: 'Сохранение...',
    saved: 'Сохранено',
    save_for_later: 'Сохранить на потом',
    save_settings: 'Сохранить настройки',
    select_files: 'Выбрать файлы',
    select_theme: 'Выбрать тему',
    start_lesson: 'Начать занятие',
    update: 'Обновить',
    upload: 'Загрузить',
    upload_audio: 'Загрузить аудио',
    upload_photo: 'Загрузить фото профиля',
    upload_video: 'Загрузить видео',
    enrolled: 'Зачислен',
    unenroll: 'Покинуть',
    from_library: 'Медиатека',
    remove: 'Удалить',
    removed: 'Удалено',
    start_broadcast: 'Начать трансляцию',
    end_broadcast: 'Завершить трансляцию',
    camera: 'Камера',
    screen: 'Экран',
    screen_with_camera: 'Экран с камерой'
  },

  card: {
    quick_add: 'Быстрое добавление',
    add: 'Добавить',
    clone: 'Клонировать курс',
    completed: 'завершенные занятия'
  },

  course: {
    upcoming_course: 'Предстоящий курс',
    unPublishEvent: 'Отменить публикацию мероприятия',
    unPublishAssessment: 'Отменить публикацию оценивания',
    pastCourse: 'Учебный курс',
    upcoming: 'Предстоящие прямые трансляции',
    pastEvent: 'Прошедшие прямые трансляции',
    pastAssessment: 'Прошедшие оценивания в режиме реального времени',
    upcomingAssessment: 'Предстоящие оценивания в режиме реального времени',
    created: 'Дата создана:',
    assignees: 'Уполномоченные лица',
    organisations:
      ' {{ organizations }} Организации, <2> {{ users }} пользователи</2>',
    event_date: 'Дата прямой трансляции<1> {{ date }} </1>',
    delete_warning: 'Вы уверены, что хотите удалить это?',
    clonning_no_lesson:
      'Пожалуйста, добавьте хотя бы одно занятие, чтобы клонировать этот курс.',
    clone_warning: 'Вы уверены, что хотите клонировать это?',
    clone_success: 'находится в процессе клонирования',
    disclaimer: `Я гарантирую, что мой курс не:
    - нарушает права третьих лиц
    - содержит противозаконные действия, то есть не содержит клеветческий, непристойный, неприличный, пошлый, порнографический, насильственный, оскорбительный, обидный, угрожающий или дискриминационный контент, даже замаскированный звездочками
    - содержит каких-либо личных нападок на других лиц
    - содержит любую выдачу себя за других участников, общественных деятелей или знаменитостей
    - содержит каких-либо записей или личной информации о каком-либо человеке без согласия такого лица и не нарушает какие-либо правила защиты данных в любой части мира
    - содержит коммерческих предложений или «спама» в любой форме`
  },

  coursecreate: {
    dropzone_text: 'Вставить медиа',
    sidebar_text: 'Выберите тему<1/> для своих <3/> занятий',
    delete_warning: 'Вы уверены, что хотите удалить это занятие?'
  },

  coursepage: {
    quizview_loading: 'Проверка ответов. Пожалуйста, подождите...',
    quizview_answer: 'Ваш ответ'
  },

  coursequiz: {
    title: 'Викторины во всех занятиях'
  },

  dashboard: {
    tooltip_title: 'Больше не показывать это видео',
    page_title: 'Статистика ваших курсов',
    page_subtitle: 'Статус созданных вами курсов',
    profile_add_info:
      'Добавьте <1>{{uncompletedText}}</1>, чтобы завершить заполнение своего профиля.',
    profile_build:
      'Создайте свой профиль <1>здесь</1>, расскажите нам о своих интересах и навыках.',
    profile_title: 'Эффективность профиля',
    more_details: 'Детальнее'
  },

  errors: {
    answer: 'Пожалуйста, введите ответ',
    answer_correct: 'Выберите правильный ответ',
    code: 'Пожалуйста, введите свой код',
    criterion: 'Пожалуйста, введите критерий',
    duration: 'Продолжительность является обязательной',
    element: 'Пожалуйста, введите элемент',
    email: 'Пожалуйста, введите свой адрес электронной почты',
    email_invalid: 'Введен неверный адрес электронной почты',
    feedback_correct: 'Пожалуйста, введите правильный отзыв',
    feedback_incorrect: 'Пожалуйста, введите неверный отзыв',
    host: 'Пожалуйста, введите организатора мероприятия',
    host_assessment: ' Пожалуйста, введите организатора оценивания',
    image: 'Пожалуйста, загрузите изображение',
    location: 'Пожалуйста, введите свое местонахождение',
    live_location: 'Пожалуйста, выберите место трансляции',
    limit: 'Пожалуйста, введите минуты прямой трансляции',
    min_3char: 'минимум 3 символа',
    number: 'пожалуйста, введите положительное число.',
    host_email_require: `Адреса электронной почты организаторов являются обязательными.`,
    attendee_email_require: `Электронные адреса участников являются обязательными.`,
    name: 'Пожалуйста, введите свое имя',
    not_found_course: 'Курс не найден',
    not_found_event: 'Мероприятие не найдено',
    not_found_assessment: 'Оценивание не найдено',
    password: 'Пожалуйста, введите свой пароль',
    password_digit: 'Как минимум одна цифра',
    password_short: 'Пароль слишком короткий, минимум 10 символов',
    password_special: 'Как минимум один специальный символ',
    password_uppercase: 'Как минимум один символ верхнего регистра',
    password_lowercase: 'Как минимум один символ нижнего регистра',
    phone: 'Пожалуйста, введите номер своего телефона',
    phone_invalid: 'Неверный номер телефона',
    position: 'Пожалуйста, введите свою должность',
    question: 'Пожалуйста, введите вопрос',
    start_date: 'Дата и время начала являются обязательными',
    tag: 'Пожалуйста, выберите тег',
    topics: 'Пожалуйста, хотя бы одну тему.',
    live_topics: 'Пожалуйста, добавьте темы',
    title: 'Пожалуйста, введите название ',
    title_assessment: 'Пожалуйста, введите название оценивания',
    title_course: 'Пожалуйста, введите название курса',
    title_empty: 'Поле названия не может быть пустым',
    title_event: 'Пожалуйста, введите название мероприятия',
    title_lesson: 'Пожалуйста, введите название занятия',
    title_quiz: 'Пожалуйста, введите название викторины',
    username: 'Пожалуйста, введите свое имя пользователя',
    upload_error: 'Ошибка – файл несовместим!',
    upload_more_than_10: 'За раз вы можете загрузить не более 10 файлов.',
    topics_max: 'Пожалуйста, добавьте максимум 3 темы.',
    upload_excel: 'Пожалуйста, убедитесь в том, что вы загрузили правильный файл.',
    title_excel: 'Ошибка при загрузке шаблона:',
    file_size_exceed: 'Превышен размер файла',
    subtitle_excel:
      'Пожалуйста, убедитесь в том, что вы используете предоставленный шаблон и столбцы заполнены правильно.',
    select_broadcast_option: 'Пожалуйста, выберите вариант для трансляции этого мероприятия',
    multi_hosts_number_error:
      'Количество ведущих не может превышать количество, определенное для мероприятия.',
    conference_people_number_error: `Количество участников не может превышать количества, указанного для мероприятия.`,
    select_max_host: 'Выберите как минимум 1 ведущего для мероприятия.',
    select_max_conference: 'Выберите как минимум 1 участника для мероприятия.',
    stream_stop_error: 'Потоковая передача неожиданно остановлена!',
    unsecure_context:
      'Неустранимая ошибка: браузер не может получить доступ к камере и микрофону из-за небезопасного контекста. Пожалуйста, установите SSL и получите доступ через https',
    ws_not_supported: 'Неустранимая ошибка: WebSocket не поддерживается в этом браузере'
  },

  events: {
    title: 'Добавить детали прямой трансляции',
    change_host: 'Изменить организатора',
    finished: 'Мероприятие завершено',
    countdown: 'Прямая трансляция будет осуществляться через ',
    subtitle:
      'Дайте название своему мероприятию и расскажите аудитории, чего они должны ожидать от него.',
    poll_disabled: 'В этом мероприятии опрос недоступен',
    disclaimer: `Я гарантирую, что записанное мной событие не будет: 
    - нарушать права третьих лиц
    - содержать противозаконные действия, то есть не будет содержать клеветческий, непристойный, неприличный, пошлый, порнографический, насильственный, оскорбительный, обидный, угрожающий или дискриминационный контент, даже замаскированный звездочками
    - содержать каких-либо личных нападок на других лиц
    - содержать любую выдачу себя за других участников, общественных деятелей или знаменитостей
    - содержать каких-либо записей или личной информации о каком-либо человеке без согласия такого лица и не будет нарушать какие-либо правила защиты данных в любой части мира
    - содержать коммерческих предложений или «спама» в любой форме`,
    prompt_message:
      'Если вы покинете эту страницу, ваше мероприятие завершится. Вы уверены, что хотите покинуть эту страницу?',
    duration_exceed_message:
      'Время вашей трансляции превышает максимальное ограничение, приложение вскоре прекратит трансляцию.',
    before_schedule_message:
      'Вы собираетесь начать трансляцию раньше запланированного времени мероприятия. Обратите внимание, что остановка трансляции завершит мероприятие.',
    browser_unsupportive_message:
      'Ваш браузер не поддерживает демонстрацию экрана. Вы можете ознакомиться с поддерживаемыми браузерами по этой ссылке',
    cancel_switch_host_message:
      'Организатор недоступен. Мероприятие будет продолжено с вашего конца.',
    coaching_prompt_message:
      'Вы не можете покинуть эту страницу, пока мероприятие не закончится.',
    event_modal_warn: 'Вы хотите покинуть это мероприятие?',
    confirm_message: 'Ваше мероприятие закончится, если вы нажмете ОК.',
    screen_share_denied: 'Вы отказались предоставить доступ к вашему экрану',
    media_not_found:
      'Камера или микрофон не найдены или доступ к ним запрещен на вашем устройстве.',
    media_not_access:
      'Камера или микрофон используются другим процессом, который не позволяет считать устройства',
    constraint_error:
      'Не найдено ни одного устройства, которое бы соответствовало вашим требованиям к видео и аудио. Вы можете изменить ограничения видео и звука',
    media_access_denied: 'У вас нет доступа к камере и микрофону.'
  },

  filters: {
    days7: 'Последние 7 дней',
    days15: 'Последние 15 дней',
    days30: 'Последние 30 дней',
    days365: 'Последние 365 дней',
    date: 'Дата создана',
    relevance: 'Релевантность',
    popularity: 'Популярность',
    name: 'Имя',
    group: 'Группа',
    Newest_to_Oldest: 'От более новых к более старым',
    Oldest_to_Newest: 'От более старых к более новым',
    A_Z: 'От А до Я',
    Z_A: 'От Я до А'
  },

  helpers: {
    password:
      'Чтобы обеспечить безопасность вашего аккаунта, нам требуется как минимум один символ нижнего регистре и одну цифру в вашем пароле. Также минимальная длина должна составлять 10 символов.',
    phone: 'Пожалуйста, укажите телефонный код вашей страны, например: +65',
    username:
      'Ваше имя пользователя является общедоступной информацией и по мере необходимости передается другим пользователям. Поэтому мы рекомендуем вам не использовать личную информацию в своем имени пользователя',
    public_description_normal:
      'Ваш <0><0/></0> был сохранен и по умолчанию считается приватным. Это означает, что данный контент можете видеть только вы. Чтобы опубликовать этот контент, вы можете выбрать один из вариантов ниже. Публичная публикация курса означает, что он будет доступен всем пользователям платформы. Параметр «Определенные пользователи» позволит вам поделиться своим курсом с определенными пользователями, используя адрес электронной почты, связанный с их аккаунтом Sharelook.',
    public_description_admin:
      'Данный<0><0/></0> был сохранен и по умолчанию считается приватным. Это означает, что только вы (и создатель контента) можете видеть этот контент. Чтобы опубликовать этот контент, вы можете выбрать один из вариантов ниже. Публичная публикация курса означает, что он будет доступен для всех пользователей платформы, включая пользователей вне вашей организации. Публикация его для организации означает, что его смогут увидеть все пользователи организации. Вы также можете опубликовать его для одной или нескольких групп, то есть увидеть его смогут только пользователи этих групп. Наконец, у вас есть возможность опубликовать его для определенных пользователей внутри и за пределами вашей организации, используя адрес электронной почты их аккаунта Sharelook.'
  },

  labels: {
    add: 'Добавить',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Тип ответа',
    answers: 'Ответы',
    back_to_quiz: 'Назад к списку викторин',
    back_to_question: 'Назад к списку вопросов',
    chat: 'Чат',
    chat_available: 'В этом мероприятии чат недоступен',
    chat_report: 'Отчет о чате',
    choice_single: 'Единственный выбор',
    choice_multiple: 'Множественный выбор',
    choice_free: '"Свободный" выбор',
    choice_sorting: 'Выбор "порядка"',
    choice_matrix: `Выбор "матричного порядка`,
    clone_lesson_permission: 'Разрешение на клонирование',
    code: 'Код',
    correct: 'Правильно',
    created: 'Дата создана',
    clone_modal: 'Клонировать курс',
    cloning_course: 'Клонирование курса...',
    cloning: 'Клонирование...',
    cloning_course_desc: 'Этому процессу потребуется некоторое время. Пожалуйста, будьте терпеливы',
    cloning_success: 'Клонирование курса "<0/>" успешно завершено!',
    description: 'Описание',
    disclaimer: 'Отказ от ответственности',
    drop_file: 'Перетащите файл сюда...',
    duration: 'Продолжительность',
    edit: 'Редактировать',
    email: 'Адрес электронной почты',
    event: 'Мероприятие',
    event_all: 'Все его мероприятия',
    event_date: 'Местная дата/время трансляции',
    event_this: 'Это мероприятие',
    expire: 'Срок действия',
    expiry_date: 'Дата завершения',
    forgot: 'Забыли пароль?',
    group: 'Группа',
    groups_list: 'Перечень групп',
    host: 'Организатор',
    id_annotation: 'Идентификатор аннотации',
    id_quiz: 'Идентификатор викторины',
    incorrect: 'Неправильно',
    interests: 'Интересы',
    invited: 'Приглашены',
    live_chat: 'Живое обсуждение',
    live_stream_limit: 'Минуты прямой трансляции',
    contact_text: 'Чтобы изменить ежемесячный лимит прямых трансляций, пожалуйста, свяжитесь с',
    contact_admin:
'<0>Если вы хотите изменить ежемесячный лимит прямых трансляций, пожалуйста, свяжитесь с </0> <1>admin@sharelook.com</1>',
    limit_stream_text:
'Осталось минут прямой трансляции: <0>{{ limit }}</0> минут <1/><2/> <3>Чтобы изменить ежемесячный лимит прямых трансляций, пожалуйста, свяжитесь с</3> <4>admin@sharelook.com</4>',
    location: 'Местонахождение',
    lessons: 'Занятия',
    location_broadcast: 'Местонахождение сервера организатора',
    location_broadcast_subtitle:
'Добавьте местонахождение, наиболее близкое к местонахождению организатора трансляции, чтобы уменьшить аудиовизуальную задержку',
    media_manager: 'Медиа-менеджер',
    minutes: 'Минуты',
    name: 'Название',
    online_quiz: 'Онлайн-викторина',
    organiser: 'Автор',
    organization: 'Организация',
    overall_emotion: 'Общий опыт:',
    organizations_list: 'Перечень организаций',
    password: 'Пароль',
    participants: 'Участники',
    picture: 'Картинка (16:9)',
    phone: 'Телефон',
    photo: 'Фото',
    poll: 'Опрос',
    position: 'Должность',
    processing: 'Обработка',
    privacy_settings: 'Настройки конфиденциальности',
    question: 'Вопрос',
    quiz_create: 'Создать новую викторину',
    quiz_name: 'Название викторины',
    quiz_update: 'Обновить викторину',
    select_organization: 'Выбрать организацию',
    settings: 'Настройки',
    show: 'Показать',
    show_graph: 'Показать результат графика',
    signin: 'Войти',
    signup: 'Зарегистрироваться',
    sort_by: 'Сортировать по',
    start_date: 'Дата начала',
    tags: 'Теги',
    terms: 'Я согласен с этими <1>условиями и положениями</1>.',
    title: 'Заголовок',
    topics: 'Темы',
    topic: 'Тема',
    total_comments: 'Всего комментариев',
    username: 'Имя пользователя',
    users_list: 'Список пользователей',
    users_emails: 'Вставьте адреса электронной почты конкретных пользователей',
    description_specifiction_email:
'Пожалуйста, введите по одному адресу электронной почты в каждой строке, чтобы поделиться этим с несколькими конкретными пользователями.',
    message_specific_email: 'Пожалуйста, введите адрес электронной почты конкретного пользователя.',
    viewers: 'Зрители',
    edit_course: 'Редактировать',
    edit_assessment: 'Редактировать',
    edit_event: 'Редактировать',
    delete_course: 'Удалить',
    delete_event: 'Удалить',
    delete_assessment: 'Удалить',
    view_course: 'Просмотреть',
    view_event: 'Просмотреть',
    view_assessment: 'Просмотреть',
    date_event: 'Дата прямой трансляции',
    date_assessment: 'Дата оценивания в режиме реального времени',
    clone_of: 'Клон',
    clone_course:
'Создайте клон этого курса, который вы можете изменять независимо от оригинала, включая все занятия и страницы.',
    make: 'Сделать',
    available_to: 'доступным для любого пользователя ShareLook',
    uploaded_by: 'Загружено: ',
    course: 'Курс',
    assessment: 'Оценивание',
    user_not_exist: 'Пользователь не существует, вы хотите отправить приглашение?',
    check_user: 'Уведомление',
    user_not_exist_in_org:
'Пользователь не существует в этой организации, вы хотите отправить приглашение?',
    title_modal_stream: 'Изменить лимит прямой трансляции для организации',
    des_warning_stream: 'Вы собираетесь изменить',
    monthly_stream: 'месячный лимит прямой трансляции:',
    min_per_month: 'мин/месяц',
    broadcast_label: 'Я хочу сделать:',
    hosts_emails: `Вставить адреса электронной почты конкретных организаторов`,
    attendee_emails: `Вставить адреса электронной почты конкретных участников`,
    description_host_specifiction_email:
'Пожалуйста, введите по одному адресу электронной почты в каждой строке.',
    presenter_label: 'Ведущий',
    presenter_label_conference: 'К-тво людей',
    event_privacy: 'Режим конференции'
  },

  broadcastOptions: {
    stream_from_phone: 'Прямая трансляция с моего телефона',
    other_webrtc: 'Вебинар с моего ПК',
    multihost: 'Многоканальный вебинар с моего ПК',
    peer2peer: 'Сеанс коучинга',
    conference: 'Конференция в режиме реального времени'
  },

  lessoncreate: {
    title: 'Добавить детали занятия',
    subtitle:
  'Дайте название своему занятию и расскажите аудитории, получение каких знаний они могут ожидать от него.',
    input_label_title: 'Название занятия',
    input_label_topics: 'Темы занятия',
    input_label_description: 'Описание занятия',
    delete_warning: 'Вы уверены, что хотите удалить эту страницу?'
  },

  lessonoverview: {
    title: 'Добавить занятие',
    subtitle: 'Добавьте существующее занятие или создайте новое',
    new_lesson: 'создайте новое занятие',
    new_lesson_sub: 'Создайте новое занятие с нуля!',
    existing_lesson: 'Выбрать существующее занятие',
    existing_lesson_sub:
  'Ссылка на существующее занятие, изменения, внесенные в это занятие, также повлияют на это занятие в других курсах.',
    clone_lesson: 'дублировать существующее занятие',
    clone_lesson_sub:
  'Создайте уникальную копию занятия из этого курса, изменения, внесенные в это занятие, не повлияют на исходное занятие.',
    clone_lesson_modal_sub:
  'Пожалуйста, нажмите кнопку клонирования под занятием, которое вы хотите добавить в свой курс.'
  },

  management: {
    groups_title: 'Управление группами',
    delete_warning: 'Вы уверены?',
    organizations_title: 'Мои организации',
    organizations_subtitle: 'Добавить организации в свой список',
    users_title: 'Управление пользователями',
    users_position: 'Старший менеджер по работе с клиентами',
    user_not_found: 'Пользователь не найден',
    select_org: 'Пожалуйста, найдите и выберите организацию для управления пользователями'
  },

  audio: {
    modal_title: 'Вставить аудио',
    list_view: 'Просмотр списка',
    create_audio_modal_title: 'Создать аудио',
    edit_audio_modal_title: 'Редактировать аудио',
    public_audio_not_found: 'Аудио не найдено.',
    private_audio_not_found: 'Вы еще не загрузили ни одного аудио',
    are_you_sure_to_delete: 'Вы действительно хотите удалить?',
    type_something: 'Напечатайте что-нибудь',
    select_voice: 'Выбрать',
    file_name: 'Название файла',
    description: 'Описание',
    topic: 'Тема',
    key_words_tag: 'Тег ключевых слов',
    mp3_audio: 'MP3 аудио',
    created: 'Создано',
    duration: 'Продолжительность',
    close: 'закрыть'
  },

  image: {
    modal_title: 'Вставить изображение',
    image: 'Изображение',
    pixel: 'Пиксель',
    dimension: 'Размер',
    public_image_not_found: 'Изображение не найдено.',
    private_image_not_found: 'Вы еще не загрузили ни одного изображения'
  },

  annotation: {
    modal_title: 'Аннотация'
  },

  text: {
    modal_title: 'Текстовый редактор'
  },

  video: {
    modal_title: 'Вставить видео',
    image: 'Видео',
    mp4_video: 'MP4 видео',
    public_video_not_found: 'Видео не найдено.',
    private_video_not_found: 'Вы еще не загрузили ни одного видео'
  },

  media: {
    title: 'Медиатека',
    subtitle: 'Библиотека ваших файлов',
    modal_title: 'Загрузить медиафайл',
    delete_warning: 'Подтвердите удаление',
    delete_message:
  'Удаленные медиафайлы будут по-прежнему отображаться на тех страницах, где они использовались',
    dropzone_text: 'или перетащите',
    delete_success: 'Медиафайлы успешно удалены',
    upload_file: 'Загрузить файл',
    drag_drop: 'или перетащите',
    import_file: 'Импортируйте файл(ы) со своего компьютера',
    no_media: 'Вы еще не загрузили ни одного медиафайла',
    media_type: 'Тип медиафайла',
    video: 'Видео',
    image: 'Изображение',
    audio: 'Аудио',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Медиафайл не найден.',
    of: 'из',
    disclaimer: `Я гарантирую, что мои медиафайлы не:
    - нарушают права третьих лиц
    - содержат противозаконные действия, то есть не содержат клеветческий, непристойный, неприличный, пошлый, порнографический, насильственный, оскорбительный, обидный, угрожающий или дискриминационный контент, даже замаскированный звездочками
    - содержат каких-либо личных нападок на других лиц
    - содержат любую выдачу себя за других участников, общественных деятелей или знаменитостей
    - содержат каких-либо записей или личной информации о каком-либо человеке без согласия такого лица и не нарушают какие-либо правила защиты данных в любой части мира
    - содержат коммерческих предложений или «спама» в любой форме`
  },

  navigation: {
    account: 'Аккаунт',
    assessments: 'Оценивания',
    dashboard: 'Личный кабинет',
    groups: 'Управление группами',
    logout: 'Выйти',
    organisations: 'Организации',
    organizations_users: 'Организации и пользователи',
    reports: 'Отчеты',
    schedule: 'Расписание',
    training: 'Обучение',
    nowLive: 'трансляция',
    users: 'Пользователи',
    workshop: 'Мой воркшоп'
  },

  onboarding: {
    step1_title: 'Шаг 1: Расскажите нам немного о себе',
    step1_subtitle:
  'Как вас зовут? Откуда вы? Какая у вас должность?',
    step2_title: 'Шаг 2: Выберите навык, который вы могли бы улучшить',
    step2_subtitle:
  'Затем расскажите нам, что вы хотели бы лучше понять. Возможно, вам еще предстоит кое-что изучить? Новое направление обучения? Какое ваше любимое направление?'
  },

  pagebuilder: {
    title: 'Создать занятие',
    subtitle:
  'Самое время создать контент, которому бы могла научиться ваша аудитория, вы можете добавлять картинки, видео и инфографику',
    select_layout: 'Выберите макет страницы',
    template1: 'Пустой',
    template2: 'Заголовок и подзаголовок',
    template3: 'Заголовок и текст',
    template4: 'Заголовок, медиа и текст',
    template5: 'Заголовок и медиа',
    template6: 'Заголовок и викторина'
  },

  pageconstructor: {
    title: 'Создать и клонировать занятие',
    subtitle:
  'Самое время создать контент, которому бы могла научиться ваша аудитория, вы можете добавлять картинки, видео и инфографику'
  },

  passwordreset: {
    success_coded:
    'Мы отправили код подтверждения по электронной почте на указанный вами адрес. Скопируйте код в поле ниже, чтобы установить новый пароль.',
    success_changed:
  'Вы успешно установили новый пароль. Теперь вы можете войти в систему.'
  },

  placeholders: {
    search: 'Поиск',
    answer: 'Ответ',
    chat: 'Скажите что-нибудь...',
    code: 'Код',
    criterion: 'Критерий',
    date_event: 'Выберите дату и время',
    date_start: 'Дата начала',
    date_expire: 'Дата окончания',
    description: 'Описание',
    elements: 'Сортировать элементы',
    email: 'Адрес электронной почты',
    feedback_correct: 'Правильный отзыв',
    feedback_incorrect: 'Неправильный отзыв',
    host: 'Организатор',
    keywords: 'Поиск ключевого(ых) слов(а)',
    lesson_description: 'Описание занятия',
    location: 'Местонахождение',
    minutes: 'Минут',
    name: 'Название',
    organiser: 'Автор',
    organization: 'Организация',
    password: 'Пароль',
    phone: 'Телефон',
    photo: 'Фото',
    picture: 'Картинка',
    point: 'Балл',
    position: 'Должность',
    question: 'Вопрос',
    quiz: 'Название викторины',
    search_course: 'Поиск по курсу',
    search_media: 'Поиск медиа',
    search_name: 'Поиск по имени',
    search_title: 'Поиск по названию',
    search_training: 'Поиск обучения',
    settings: 'Настройки',
    tags: 'Теги',
    title: 'Заголовок',
    topics: 'Темы',
    username: 'Имя пользователя',
    keyword: 'Ключевые слова',
    max_500_characters: 'Максимум 500 символов',
    no_description: 'У этого файла нет описания',
    no_keyword: 'У этого файла нет ключевых слов',
    no_topics: 'У этого файла нет набора тем',
    feedback: 'Сообщение',
    contact: 'Как мы можем помочь?'
  },

  publishsettings: {
    title: 'Опубликовать настройки',
    event_subtitle:
  'Добавьте настройки для вашего мероприятия относительно того, кто может просматривать и взаимодействовать с вашим мероприятием',
    assessment_subtitile:
  'Дайте название своему оцениванию и расскажите аудитории, получение каких знаний они могут ожидать от него.',
    course_subtitle:
  'Добавьте настройки для вашего курса относительно того, кто может просматривать и взаимодействовать с вашим курсом',
    disclaimer:

  `1.	Регистрация

  Регистрируясь в качестве пользователя/создавая аккаунт, вы соглашаетесь с нашим [Пользовательским соглашением] и подтверждаете прочтение нашей [Политики конфиденциальности]

  2.	Создание курса

  Используя наши инструменты и создавая контент курса, вы соглашаетесь с нашим [Пользовательским соглашением] и подтверждаете прочтение нашей [Политики конфиденциальности]

  3.	Создание мероприятия

  Используя наши инструменты и создавая мероприятие, вы соглашаетесь с нашим [Пользовательским соглашением] и подтверждаете прочтение нашей [Политики конфиденциальности]

  4.	Создание оценивания

  Используя наши инструменты и создавая оценивание, вы соглашаетесь с нашим [Пользовательским соглашением] и подтверждаете прочтение нашей [Политики конфиденциальности]`,
    dates_validation_error: 'Обе даты должны быть заполнены.',
    emails_validation_error: 'Список рассылки недействителен.',
    specific_email_validation_error: 'Разрешен только один адрес электронной почты.',
    i_agree_to_these: 'Я соглашаюсь с этими',
    terms_and_conditions: 'условиями и положениями.',
    PublicDescription:
  'Бесплатные независимые пользователи: (публичная и приватная опции) Ваш [курс|мероприятие|оценивание] был сохранен и по умолчанию считается приватным. Это означает, что данный контент можете видеть только вы. Чтобы опубликовать этот контент, вы можете сделать его публичным ниже. This means that the course will be available to all users in the platform.',
    OrganisationsDescription:
  'Организационные пользователи: (Вместо ""Завершить"" используйте ""Опубликовать"" и выведите их на страницу публикации так же, как и другие пользователи, но без каких-либо опций публикации.) Ваш [курс|мероприятие|оценивание] был сохранен и является приватным. Это означает, что данный контент можете видеть только вы и администраторы вашей организации. Как пользователь, вы не можете самостоятельно публиковать свой контент в своей организации, поскольку для этого требуются права администратора. Пожалуйста, свяжитесь с одним из своих администраторов и попросите их опубликовать ваш контент публично, для всей организации, для определенных групп внутри вашей организации или для определенных пользователей.',
    OrganisationGroupsDescription:
  'Организационные администраторы: (Для контента, созданного в рамках организации: приватно, публично, для организации, групп, определенных пользователей): Этот [курс|мероприятие|оценивание] был сохранен и по умолчанию считается приватным. Это означает, что данный контент можете видеть только вы (и создатель контента). Чтобы опубликовать этот контент, вы можете выбрать один из вариантов ниже. Публичная публикация курса означает, что он будет доступен для всех пользователей платформы, включая пользователей вне вашей организации. Публикация его для организации означает, что его смогут увидеть все пользователи организации. Вы также можете опубликовать его для одной или нескольких групп, то есть увидеть его смогут только пользователи этих групп. Наконец, у вас есть возможность опубликовать его для определенных пользователей внутри и за пределами вашей организации, используя адрес электронной почты их аккаунта Sharelook.',
    SpecificUsersDescription:
  'Платные независимые пользователи: (приватно, публично и для определенных пользователей) Ваш [курс|мероприятие|оценивание] был сохранен и по умолчанию считается приватным. Это означает, что данный контент можете видеть только вы. Чтобы опубликовать этот контент, вы можете выбрать один из вариантов ниже. Публичная публикация курса означает, что он будет доступен всем пользователям платформы. Параметр «Определенные пользователи» позволит вам поделиться своим курсом с определенными пользователями, используя адрес электронной почты, связанный с их аккаунтом Sharelook.'
  },

  quiz: {
    quiz_empty: 'Вы еще не создали викторину',
    questions_empty: 'Вы еще не создали вопрос'
  },

  regions: {
    asia_pacific_australia: 'Австралия',
    asia_pacific_india: 'Индия',
    asia_pacific_japan: 'Япония',
    asia_pacific_s_korea: 'Южная Корея',
    asia_pacific_singapore: 'Сингапур',
    asia_pacific_taiwan: 'Тайвань',
    eu_belgium: 'Бельгия',
    eu_germany: 'Германия',
    eu_ireland: 'Ирландия',
    south_america_brazil: 'Бразилия',
    us_central_iowa: 'Айова',
    us_east_s_carolina: 'Южная Каролина',
    us_east_virginia: 'Вирджиния',
    us_west_california: 'Калифорния',
    us_west_oregon: 'Орегон'
  },

  reports: {
    title: 'Отчеты'
  },

  signup: {
    success_step1:
    'Мы отправили код подтверждения по электронной почте на указанный вами адрес. Пожалуйста, скопируйте код в поле ниже, чтобы подтвердить вашу регистрацию.',
    success_step2: 'Спасибо. Вы успешно зарегистрированы.',
    privacy: `Я соглашаюсь с <1>условиями и положениями</1>, а также с <3>политикой конфиденциальности</3> Sharelook`
  },

  stream: {
    emotion_impressive: 'Впечатляюще',
    emotion_unimpressive: 'Невпечатляюще',
    message_not_started: 'Скоро',
    message_running: 'Прямая трансляция',
    message_processing:
  'В настоящее время мы обрабатываем живую запись для воспроизведения. Повторите попытку позже, чтобы просмотреть видео и продолжить добавление оценок и отзывов'
  },

  tabs: {
    audience: 'Аудитория',
    course: 'Обзор курса',
    details: 'Детали прямой трансляции',
    description: 'Описание',
    host: 'Организатор',
    lesson: 'Обзор занятия',
    lessons: 'Занятия',
    overview: 'Обзор',
    page: 'Конструктор страниц',
    publish: 'Опубликовать',
    settings: 'Опубликовать настройки',
    total_views: 'Всего просмотров',
    live_participates: 'Участники в режиме реального времени',
    live_chats: 'Чаты в режиме реального времени',
    live_quiz: 'Опросы в режиме реального времени',
    trainer_rating: 'Рейтинг инструктора',
    trainer_times: 'Время инструктора',
    courses_quizzes: 'Викторины курсов',
    user_list: 'Список пользователей',
    discussion: 'Обсуждение',
    notes: 'Мои заметки'
  },

  topics: {
    SALES_TRAINING: 'Обучение продажам',
    NEGOTIATION_TRAINING: 'Обучение переговорам',
    CROSS_CULTURAL_AWARENESS: 'Межкультурная осведомленность',
    NETWORKING_SKILLS: 'Навыки установления контактов',
    SOFTWARE_ARCHITECTURE: 'Архитектура программного обеспечения',
    PROJECT_MANAGEMENT: 'Управление проектами',
    DIGITAL_TRANSFORMATION: 'Цифровая трансформация',
    IT_CONSULTING: 'ИТ-консалтинг',
    MANAGEMENT_CONSULTING: 'Управленческий консалтинг',
    HR_CONSULTING: 'Кадровый консалтинг',
    INTERNET_OF_THINGS: 'Интернет вещей (IoT)',
    CYBERSECURITY: 'Кибербезопасность',
    PRIVACY_AND_GDRP: 'Конфиденциальность и GDPR'
  },

  training: {
    title: 'Обучение',
    title_assessment: 'Оценивания',
    subtitle: 'Курсы и мероприятия',
    enrolled_title: 'Запись на курсы и мероприятия',
    enrolled_subtitle: 'Курсы и мероприятия, на которые вы записались',
    enrolled_message:
  'В настоящее время вы не записаны ни на один курс или мероприятие.',
    past_live_event_title: 'Прошедшие прямые трансляции',
    past_live_event_subTitle: 'Завершенные прямые трансляции',
    past_live_event: 'В настоящее время прошедшие прямые трансляции отсутствуют.',
    recomended_title: 'Рекомендуемые курсы и трансляции',
    now_live_event_title: 'Транслируются сейчас',
    now_live_event_subTitle: 'Мероприятия, которые транслируются сейчас или уже скоро будут транслироваться',
    now_live_event: 'В настоящее время нет трансляций в режиме реального времени.',
    webinar_event_title: 'Канал вебинара',
    webinar_event_subTitle: 'Завершенные вебинары',
    webinar_event: 'В настоящее время вебинары отсутствуют.',
    p2p_event_title: 'Сессии коучинга',
    p2p_event_subTitle: 'Завершенная сессия коучинга',
    p2p_event: 'В настоящее время сессии коучинга отсутствуют.',

    now_live_assessment_title: 'Транслируются сейчас',
    now_live_assessment_subTitle:
  'Оценивания, которые транслируются сейчас или уже скоро будут транслироваться',
    now_live_assessment_message: 'В настоящее время оценивания в режиме реального времени отсутствуют.',

    upcoming_assessment_title: 'Предстоящие оценивания в режиме реального времени',
    upcoming_assessment_subtitle: 'Предстоящие оценивания в режиме реального времени',
    upcoming_assessment_message:
  'В настоящее время нет предстоящих оцениваний в режиме реального времени.',

    past_live_assessment_title: 'Прошедшие оценивания в режиме реального времени',
    past_live_assessment_subTitle: 'Завершенные оценивания в режиме реального времени',
    past_live_assessment_message:
  'В настоящее время прошедшие оценивания в режиме реального времени отсутствуют.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle: `Мы организовали курсы и мероприятия в прямом эфире на основе вашего <0><0/></0>`,
    /**
   * and we also can get data from index to replace in recommended_subtitle by like above string.
   * for exp -->
   * at the end of the recomended_subtitle above will get data from index of component to display
   * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
   */
    search: 'Поиск обучения',
    search_assessment: 'Поиск оценивания',
    recomended_message: 'В настоящий момент рекомендуемые курсы и мероприятия отсутствуют.'
  },

  warnings: {
    delete: 'Вы уверены?'
  },

  workshop: {
    title: 'Мой воркшоп',
    subtitle: 'Создайте собственный курс или проведите собственное мероприятие в прямом эфире',
    new_course: 'Создать новый курс',
    new_event: 'Создать новое мероприятие с трансляцией в режиме реального времени',
    new_assessment: 'Создать новое оценивание в режиме реального времени',
    courses_title: 'Созданные курсы и мероприятия с трансляцией в режиме реального времени',
    courses_subtitle: 'Редактируйте и публикуйте созданный вами курс и планируйте мероприятия с трансляцией в режиме реального времени',
    tags_course: 'Курс',
    tags_live: 'Мероприятие с трансляцией в режиме реального времени',
    tags_assessment: 'Оценивание в режиме реального времени'
  },

  help_center: {
    sidebar: {
      title: 'Как мы можем вам помочь?',
      back_to: 'Личный кабинет'
    },
    menu: {
      help_center: 'Центр помощи',
      whats_new: 'Что нового',
      feedback: 'Обратная связь',
      feedback_title: 'Отправьте отзыв sharelook',
      terms_and_conditions: 'Условия и положения',
      privacy_policy: 'Политика конфиденциальности',
      contact_us: 'Связь с нами'
    },
    get_started: {
      title: 'Начните с share look',
      description: {
        main: 'МЫ ИНТЕГРИРОВАЛИ ЖИВОЕ ВИДЕО В ПРОСТОЙ В ИСПОЛЬЗОВАНИИ КОНСТРУКТОР КУРСОВ',
        tagline:
      'С запатентованной системой прямой трансляции ShareLook вы можете привлечь внимание, автоматизировать обучение и максимально расширить обмен знаниями.'
      }
    },
    whats_new: {
      title: 'Начните с share look',
      description: {
        main:
        'А вы знали, что создание стиля обучения на примерах можно создать за короткое время и не так дорого? Вам следует объединить конструктор курсов ShareLook и соответствующие таланты. Вы можете спросить у нас "КАК',
        tagline: ''
      }
    },
    create_workshop: 'Как создать воркшоп',
    create_course: 'Как создать курс',
    create_user: 'Как создать пользователя',
    create_group: 'Как создать группу',
    create_webinar: 'Как создать вебинар',
    create_live_event: 'Как создать мероприятие с трансляцией',
    create_live_assessment: 'Как создать оценивание в режиме реального времени',
    create_live_on_mobile: 'Создать трансляцию с мобильного телефона',
    personalize_profile: 'Персонализировать профиль',
    manage_group: 'Управлять группой',
    report: 'Отчет',
    add_annotations: 'Как добавить аннотации/всплывающие окна',
    add_audio: 'Как добавить аудио',
    add_header_and_footer: 'Добавление хедера и футера',
    add_image: 'Как добавить изображение',
    add_quiz_content: 'Как добавить викторину',
    add_text_content: 'Как добавить текстовый контент',
    add_user: 'Как добавить пользователя',
    add_video: 'Как добавить видео',
    clone_course: 'Как клонировать курс',
    find_live_broadcast: 'Как найти прямую трансляцию ',
    contact_us: {
      title: 'Свяжитесь с нами',
      subject_placeholder: 'Введите тему*',
      content_placeholder: 'Введите сообщение*',
      subject_required: '*Тема является обязательной',
      content_required: '*Сообщение является обязательным',
      submit: 'Отправить',
      cancel: 'Отмена',
      sent: 'Отправлено'
    },
    feedback: {
      title: 'Отправьте отзыв sharelook',
      attach_title: 'Прикрепить файл',
      choose: 'Выбрать файл',
      detail: 'Файл будет передан в службу поддержки',
      content_placeholder: 'Ввести сообщение*',
      content_required: '*Сообщение является обязательным',
      submit: 'Отправить',
      cancel: 'Отмена',
      sent: 'Отправлено'
    }
  },
  discussion: {
    join_discussion: 'Присоединиться к обсуждению'
  },
  notes: {
    title: 'Мои заметки',
    add_note: 'Добавить новую заметку'
  }
}
