import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../../../../store/actions'
import CreateAudio from './CreateAudio'

const mapStateToProps = state => ({
  audioSynthesizer: state.audioSynthesizer,
  pageBuilder: state.pageBuilder,
  droppedFile: state.lessonBuilder.droppedFile,
  library: state.library,
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAudioSynthesizer: actions.audioSynthesizer.createAudioSynthesizer,
      getSelectedTextVoiceType:
        actions.audioSynthesizer.getSelectedTextVoiceType,
      listTTSVoices: actions.audioSynthesizer.listTTSAudioSynthesizer,
      postTTSVoices: actions.audioSynthesizer.postAudioSynthesizer,
      showTextModal: actions.pageBuilder.showTextModal,
      handleFileDropIn: actions.lessonBuilder.handleFileDropIn,
      getLibraryList: actions.library.getLibraryList,
      getPublicList: actions.library.getPublicList,
      getOrgList: actions.library.getOrgList,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateLibraryStatus: actions.library.updateLibraryStatus,
      updateOrgLib: actions.library.updateOrgLib,
      handleMakeType: actions.library.handleMakeType,
      updateTopicList: actions.topics.updateTopicList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(CreateAudio)
