import React, { useState, useMemo } from 'react'

import { Divider, Icon, Pagination } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'
import moment from 'moment'
import { Search } from '../../../../../../../components'
import { getMinutes } from '../../../../../../../utils'
import TableInfoColumns from './internal/TableInfoColumns'
import TableCoverImage from './internal/TableCoverImage'

const customExpandIcon = props => {
  return <Icon type={props.expanded ? 'up' : 'down'} {...props} />
}

const UserAssessmentTable = props => {
  const {
    t,
    data: {
      assessments: { data, loading }
    },
    isUserAsHost: isHost
  } = props

  const hostAssessmentColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      {
        title: t('v4:avg_score'),
        dataIndex: 'info',
        render: (text, record) => {
          const { live_assessment_host_scores } = record
          const averageScore =
            live_assessment_host_scores &&
            live_assessment_host_scores.reduce((total, item) => {
              return total + item.score
            }, 0) / live_assessment_host_scores.length
          console.log('averageScore', averageScore)
          return (
            <div>
              <span>
                {live_assessment_host_scores && averageScore
                  ? averageScore.toFixed(2)
                  : '-'}
              </span>
            </div>
          )
        }
      },
      {
        title: t('v2:questions'),
        dataIndex: 'live_assessment_questions',
        render: questions => questions && questions.length
      },
      { title: t('v4:pass_score'), dataIndex: 'pass_score' },
      {
        title: t('reports:live_date'),
        dataIndex: 'start_date_time',
        render: date => moment(date).format('Do MMM')
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const participantAssessmentColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      {
        title: t('reports:start_end_live'),
        dataIndex: 'info',
        render: (text, record) => {
          const { stream_start, stream_stop } = record
          return (
            <div>
              {stream_start === null || stream_stop === null ? (
                '- -'
              ) : (
                <span>
                  {moment(stream_start).format('HH:mm')} /{' '}
                  {moment(stream_stop).format('HH:mm')}
                </span>
              )}
            </div>
          )
        }
      },
      {
        title: t('reports:watched_time'),
        dataIndex: 'time',
        render: (text, item) => {
          return (
            <>
              {item.stream_start === null || item.stream_stop === null
                ? '- -'
                : getMinutes(item.stream_start, item.stream_stop)}
            </>
          )
        }
      },

      {
        title: t('v4:avg_score'),
        dataIndex: 'info',
        render: (text, record) => {
          const { live_assessment_scores } = record
          const averageScore =
            live_assessment_scores &&
            live_assessment_scores.reduce((total, item) => {
              return total + item.score
            }, 0) / live_assessment_scores.length
          return (
            <div>
              <span>
                {live_assessment_scores && averageScore
                  ? averageScore.toFixed(2)
                  : '-'}
              </span>
            </div>
          )
        }
      },
      {
        title: t('v2:questions'),
        dataIndex: 'live_assessment_questions',
        render: questions => (questions && questions.length) || 0
      },
      {
        title: t('v4:pass_score'),
        dataIndex: 'pass_score'
      },
      {
        title: t('reports:live_date'),
        dataIndex: 'start_date_time',
        render: date => moment(date).format('Do MMM')
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(data.length)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const renderLiveAssessmentsTable = record => {
    let hostData =
      record.live_assessment_questions &&
      record.live_assessment_questions.map((item, i) =>
        Object.assign(
          {},
          item,
          (record &&
            record.live_assessment_scores &&
            record.live_assessment_scores[i]) ||
            (record &&
              record.live_assessment_host_scores &&
              record.live_assessment_host_scores[i])
        )
      )
    let totalScore =
      (record &&
        record.live_assessment_scores &&
        record.live_assessment_scores.reduce(
          (total, item) => total + item.score,
          0
        )) ||
      (record &&
        record.live_assessment_host_scores &&
        record.live_assessment_host_scores.reduce(
          (total, item) => total + item.score,
          0
        ))
    return (
      <table className="lessons-table">
        <thead>
          <tr>
            <th>{t('labels:question')}</th>
            <th>{t('reports:score')}</th>
          </tr>
        </thead>
        <tbody>
          {hostData &&
            hostData.map((item, index) => (
              <tr key={index} style={{ fontWeight: 'normal' }}>
                <td>{item.question}</td>
                <td style={{ textAlign: 'center' }}>
                  {item.score ? item.score : '-'}
                </td>
              </tr>
            ))}
          <tr>
            <td>{t('v2:total')}</td>
            <td style={{ textAlign: 'center' }}>
              {totalScore === 0 ? '-' : totalScore ? totalScore : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  const events = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  console.log('check data log', total)

  return (
    <ReportTable
      isDateActive
      borderRadius
      columns={isHost ? hostAssessmentColumns : participantAssessmentColumns}
      loading={loading}
      dataSource={events}
      expandIconAsCell={false}
      expandIconColumnIndex={isHost ? 6 : 8}
      expandIcon={customExpandIcon}
      expandRowByClick
      pagination={false}
      expandedRowRender={record => {
        return renderLiveAssessmentsTable(record)
      }}
      title={() => (
        <>
          <div className="user-report-table-title">
            <div>
              <SearchEventMargin>
                <Search
                  placeholder={t('placeholders:search')}
                  onChange={e => onSearch(e.target.value)}
                />
              </SearchEventMargin>
            </div>
            <div className="user-report-table-pagination">
              <span>{t('general:page').toUpperCase()}</span>
              <PaginationRightAligned>
                <Pagination
                  total={total}
                  simple
                  current={currentPage}
                  pageSize={10}
                  onChange={onPaginationChange}
                />
              </PaginationRightAligned>
            </div>
          </div>
          <Divider style={{ margin: '0px' }} />
        </>
      )}
    />
  )
}
export default UserAssessmentTable
