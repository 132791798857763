import styled from 'styled-components'

const Page = styled.div``

Page.Head = styled.div`
  margin-bottom: 2.4rem;
`

Page.Heading = styled.h3`
  padding: 1.6rem 1.6rem 0 1.6rem;
`

Page.Content = styled.div``

const Controls = styled.div`
  display: inline-flex;
`

Controls.Search = styled.div`
  width: 24rem;
  
  input {
    border-radius: 5rem;
    width: 100%;
  }
  margin-right: 1.6rem;
`

Controls.Button = styled.div`
  flex-shrink: 0;
`

export {
  Page,
  Controls
}
