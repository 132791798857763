import React, { Component } from 'react'
import './Audio.scss'
import iconPlay from './images/icon-play.png'
import iconPause from './images/icon-pause.png'

export default class extends Component {
  state = {
    isPlaying: false
  }

  audio = new window.Audio()

  componentDidMount () {
    const { link } = this.props.library

    this.audio.src = link
    this.audio.addEventListener('ended', this.stop)
  }

  stop = () => {
    this.audio.currentTime = 0
    this.pause()
  }

  play = () => {
    this.setState({
      isPlaying: true
    }, () => {
      this.audio.play()
    })
  }

  pause = () => {
    this.audio.pause()

    this.setState({
      isPlaying: false
    })
  }

  componentWillUnmount () {
    this.pause()
  }

  render () {
    const { isPlaying } = this.state

    const image = isPlaying ? iconPause : iconPlay

    return <div
      className='b-audio'
      onClick={() => isPlaying ? this.pause() : this.play()}
      style={{ backgroundImage: 'url(' + image + ')' }}
    />
  }
}
