import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Dropdown, Menu, Spin } from 'antd'
import {
  DropzoneWrapper,
  DropzoneInner,
  DropzoneLoading,
  DropzoneImageWrapper
} from './styled'
import AiImages from '../../../views/Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiImages'
import './style.scss'
import ReactSVG from 'react-svg'
import { uploadImage, uploadMedia, uploadAi, uploadCanve } from './images'
import CancelIcon from '../../../assets/delete.svg'
import EditIcon from '../../../assets/edit.svg'

const ImageUpload = props => {
  const { t } = useTranslation()
  const {
    cover,
    mode,
    error,
    disabled,
    handleDrop,
    loadImage = false,
    handleOpenSelectLibraryModal,
    isImageBackground = false,
    handleOpenCanva,
    handleAiImage,
    courseFileName,
    coverFileSize,
    size = 'large'
  } = props

  const [imageRemove, setImageRemove] = useState(true)
  const [loading, setLoading] = useState(false)
  const [isAiModal, setAiModal] = useState(false)
  const [isPreviewModal, setPreviewModal] = useState(false)
  const inputFileRef = useRef(null) // Reference for file input

  useEffect(() => {
    setImageRemove(true)
  }, [props])

  const onDrop = files => {
    const file = files[0]

    if (!file) {
      handleAfterUpload()
      return
    }

    handleDrop(file, isImageBackground, handleAfterUpload, handlebeforeUpload)
  }

  const handleAfterUpload = () => {
    setLoading(false)
  }

  const handlebeforeUpload = () => {
    setLoading(true)
  }

  const onImage = item => {
    handleAiImage(item)
    setAiModal(false)
  }

  const handleCanva = () => {
    handleOpenCanva()
  }

  const handleAiCreator = () => {
    setAiModal(true)
  }

  const handleFileInputClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      onDrop([file])
    }
  }

  const handleImageClick = () => {
    setPreviewModal(true)
  }

  const menu = (
    <Menu className="secondary-image-upload">
      <Menu.Item key="1" onClick={e => handleFileInputClick(e.domEvent)}>
        <ReactSVG src={uploadImage} />
        {t('buttons:upload_image')}
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={e => handleOpenSelectLibraryModal(e.domEvent)}
      >
        <ReactSVG src={uploadMedia} />
        {t('buttons:select_media_library')}
      </Menu.Item>
      <Menu.Item key="3" onClick={e => handleCanva(e.domEvent)}>
        <ReactSVG src={uploadCanve} />
        {t('v4:canva')}
      </Menu.Item>
      <Menu.Item key="4" onClick={e => handleAiCreator(e.domEvent)}>
        <ReactSVG src={uploadAi} />
        {t('v3:ai_creator')}
      </Menu.Item>
    </Menu>
  )

  console.log({
    loading,
    loadImage
  })

  return (
    <div className="upload-btn-wrap">
      {isAiModal && (
        <Modal
          visible={isAiModal}
          footer={false}
          destroyOnClose
          onCancel={e => {
            e.preventDefault()
            setAiModal(false)
          }}
          width={'80%'}
          centered
        >
          <AiImages selectedImage={cover} onImage={onImage} />
        </Modal>
      )}

      {cover && imageRemove ? (
        <Spin spinning={loading || loadImage} tip="Uploading Image">
          <div className="shl-image-upload">
            <div className="shl-image-upload-inner">
              <div className="shl-image-image-wrap" onClick={handleImageClick}>
                <div className="shl-image-data">
                  <img src={cover} alt="media" />
                </div>
                <div className="shl-image-info">
                  <div>
                    <label>{t('v4:image_name')}</label>
                    <span>{courseFileName || 'N/A'}</span>
                  </div>
                  <div>
                    <label>{t('v4:image_size')}</label>
                    <span>{coverFileSize ? coverFileSize + ' KB' : 'N/A'}</span>
                  </div>
                </div>
              </div>

              <div className="shl-image-choose">
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <span className="image-edit" size="large">
                    <img src={EditIcon} alt="" />
                  </span>
                </Dropdown>
                <input
                  type="file"
                  ref={inputFileRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  accept="image/jpeg, image/png"
                />
              </div>
            </div>
          </div>
        </Spin>
      ) : (
        <DropzoneWrapper
          className={size}
          multiple={false}
          onDrop={onDrop}
          accept="image/jpeg, image/png"
          cover={cover}
          mode={mode}
          error={error}
          disabled={disabled}
        >
          {({ getRootProps, getInputProps, isDragActive }) => {
            return loading || loadImage ? (
              <DropzoneLoading type="loading" />
            ) : (
              <DropzoneInner className={size}>
                <Button className="rounded upload-btn" size="small">
                  <ReactSVG src={uploadImage} />
                  {t('buttons:upload_image')}
                </Button>

                <Button
                  className="rounded upload-btn"
                  size="small"
                  onClick={handleOpenSelectLibraryModal}
                >
                  <ReactSVG src={uploadMedia} />
                  {t('buttons:select_media_library')}
                </Button>

                <Button
                  className="rounded upload-btn"
                  size="small"
                  onClick={handleOpenCanva}
                >
                  <ReactSVG src={uploadCanve} />
                  {t('v4:canva')}
                </Button>

                <Button
                  className="rounded upload-btn"
                  size="small"
                  onClick={e => {
                    e.preventDefault()
                    setAiModal(true)
                  }}
                >
                  <ReactSVG src={uploadAi} />
                  {t('v3:ai_creator')}
                </Button>
              </DropzoneInner>
            )
          }}
        </DropzoneWrapper>
      )}

      {error ? (
        <div className="error">{t('v3:required_cover_image')}</div>
      ) : null}

      {isPreviewModal && (
        <Modal
          visible={isPreviewModal}
          footer={false}
          centered
          onCancel={() => setPreviewModal(false)}
          className="image-popup-show"
        >
          <img src={cover} alt="Preview" style={{ width: '100%' }} />
        </Modal>
      )}
    </div>
  )
}

export default ImageUpload
