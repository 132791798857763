/*
 * @Description: Page ctrl
 * @Author: Mario
 * @Date: 2019-08-12 10:14:30
 * @LastEditTime: 2019-08-12 13:59:01
 * @LastEditors: Please set LastEditors
 */
const ACCEPT_TYPE = [
  'audio/* ',
  'video/AVI',
  'video/WMV',
  'video/MP4',
  'video/MOV',
  'video/FLV',
  'video/*',
  'image/*'
]

export { ACCEPT_TYPE }
