import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../store/actions/index'
import Details from './Details'

export default connect(
  state => ({
    reports: state.reports
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getMazeReport: bindActionCreators(actions.reports.getMazeReport, dispatch)
    // getCourseReport: bindActionCreators(
    //   actions.reports.getCourseReport,
    //   dispatch
    // )
  })
)(Details)
