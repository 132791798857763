import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageBuilder from './PageBuilder'

import * as lessonBuilderActions from '../../../../../store/actions/lessonBuilder'

export default connect(
  (state) => ({
    lesson: state.lessonBuilder,
    courseId: state.courseBuilder.courseId
  }),
  (dispatch) => ({
    lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch)
  })
)(PageBuilder)
