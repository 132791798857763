import CourseList from './CourseList'
import EventList from './EventList'
import MazeList from './MazeList'
import NowLiveList from './NowLiveList'
import SkillJourneyList from './SkillJourneyList'
import ChannelList from './ChannelList'
import PodcastList from './PodcastList'

export default {
  CourseList,
  EventList,
  MazeList,
  NowLiveList,
  SkillJourneyList,
  ChannelList,
  PodcastList
}
