import React, { Component } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { Field, Row, Col } from '../../../../../styles'
import { Organization } from './styled'
import { withTranslation } from 'react-i18next'

class OrganizationPicker extends Component {
  handleChange = id => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }

  render() {
    const {
      list,
      selectedOrganizations,
      t,
      encoder: tag,
      activeOrg
    } = this.props

    console.log('on org change', this.props)

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <Field.Control>
          <Row style={{ marginBottom: '-3.2rem' }}>
            {!_.isEmpty(list) &&
              list.map(organization => {
                const { id, title, logo } = organization
                const isPublic =
                  tag ? tag.value === 'agora_conference' || tag.value === 'webinar' : true

                return (
                  <Col key={id} md={3}>
                    <Organization
                      active={id === activeOrg}
                      onClick={() => this.handleChange(id)}
                      disabled={isPublic ? false : id !==activeOrg}
                    >
                      <Organization.Checkbox>
                        <Checkbox
                          checked={selectedOrganizations.includes(id)}
                        />
                      </Organization.Checkbox>
                      {logo ? (
                        <Organization.Image
                          src={logo ? logo.link : ''}
                          alt=""
                        />
                      ) : (
                        <Organization.DefaultImage>
                          {title.charAt(0).toUpperCase()}
                        </Organization.DefaultImage>
                      )}

                      <Organization.Name>{title}</Organization.Name>
                    </Organization>
                  </Col>
                )
              })}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(OrganizationPicker)
