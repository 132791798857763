import React, { Component } from 'react'
import { Prompt } from 'react-router-dom'
import { WebRTCAdaptor } from './webrtcAdapter'
import { config } from '../../../../../constants'
import { Row, Tooltip, message, Modal } from 'antd'
import { NetworkDetection } from '../NetworkDetection'
import cameraOff from '../../assets/camera_off.svg'
import cameraOn from '../../assets/camera_on.svg'
import micOff from '../../assets/mic_off.svg'
import micOn from '../../assets/mic_on.svg'
import join from '../../assets/join.svg'
import leave from '../../assets/leave.svg'
import shareScreenLogo from '../../assets/share_screen.svg'
import stopShareScreenLogo from '../../assets/stop_screen_share.svg'
import ShareScreenCamera from '../../assets/share_screen_camera.svg'
import StopShareScreenCamera from '../../assets/stop_share_screen_camera.svg'
import { Card } from '../../../../../components/Card/styled'
import '../../../../../components/Card/Card.scss'
import { api } from '../../../../../services'

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

/* const pcConfig = {
  iceServers: [
    {
      urls: `${config.turnURL}`, // A TURN server
      username: 'sharelookmahesh',
      credential: 'mahesh@12345'
    }
  ]
} */

const pcConfig = {
  iceServers: [
    {
      urls: 'turn:3.84.124.47', // A TURN server
      username: 'mahesh',
      credential: 'mahesh@123'
    },
    {
      urls: 'stun:stun.l.google.com:19302'
    }
  ]
}

/*const pcConfig_my =
  {
    iceServers: [
      {
        urls: 'turn:52.207.57.21', // A TURN server
        username: 'mahesh',
        credential: 'mahesh@123'
      },
      {
        'urls': 'stun:stun.l.google.com:19302'
      }
    ]
  }*/

const sdpConstraints = {
  OfferToReceiveAudio: true,
  OfferToReceiveVideo: true
}

let mediaConstraints = {
  video: {
    width: 426,
    height: 245,
    frameRate: 15
  },
  audio: true
}

let webRTCAdaptor = null
// let mediaRecorder = null
let localStream = null
let chunks = []
let prompt = null

declare var MediaRecorder: any
declare var Blob: any

class AntPeerService extends Component {
  state = {
    showVideo: false,
    isJoined: false,
    isCameraOff: false,
    isMicOff: false,
    hasParticipant: null,
    hasHost: null,
    isShareScreen: false,
    isShareScreenCamera: false,
    hasStarted: false,
    localvideoClassName: '',
    error: '',
    canShowModal: false,
    showPrompt: false,
    isFirstParticipantToLeave: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { hasStarted } = this.state
    // const { isDurationExceed } = this.props
    if (prevState.hasStarted !== hasStarted) {
      if (hasStarted) {
        this.handleChangeClass('localvideo')
      } else {
        this.handleChangeClass('')
      }
    }
    // if (
    //   prevProps.isDurationExceed !== isDurationExceed &&
    //   isDurationExceed === 'yes'
    // ) {
    //   setTimeout(() => {
    //     this.leave()
    //   }, 2000)
    // }
  }

  componentWillUnmount() {
    if (prompt && this.state.showVideo) {
      console.log('unmount', prompt)
      this.leave()
      prompt = null
    }
  }

  initializeLocalStream = () => {
    localStream = webRTCAdaptor.localVideo
    // localStream = webRTCAdaptor.localStream
    console.log('initializeLocalStream localStream:', localStream)
  }

  startRecording = () => {
    if (localStream !== null) {
      console.log('startRecording localStream:', localStream)
      let mediaRecorder = new MediaRecorder(localStream.captureStream(30))
      // let mediaRecorder = new MediaRecorder(localStream)
      let mimeType = mediaRecorder.mimeType

      console.log('mimeType:', mimeType)

      mediaRecorder.ondataavailable = function(e) {
        console.log('event callback: ', e)
        chunks.push(e.data)
      }

      mediaRecorder.onstop = function() {
        let blob = new Blob(chunks, {
          type: 'video/mp4'
        })
        let url = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.href = url
        a.download = 'test.mp4'
        a.click()
        window.URL.revokeObjectURL(url)
      }

      mediaRecorder.start(10)
    }
  }

  handleChangeClass = classname => {
    this.setState({ localvideoClassName: classname })
  }

  initService = () => {
    const {
      calculateStopTime,
      calculateWarnTime,
      onStreamStopped,
      handleBroadcastChange,
      streamId,
      stopstream
    } = this.props
    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: config.antWSURL,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      remoteVideoId: 'remoteVideo',
      cameraLocation: 'bottom',
      debug: true,
      bandwidth: 900,
      streamId: streamId,
      callback: info => {
        console.log('info', info)
        if (info === 'initialized') {
          console.log('initialized')
          webRTCAdaptor.join(streamId)
          // this.initializeLocalStream()
        } else if (info === 'joined') {
          // joined the stream
          console.log('joined')
          this.setState({
            showPrompt: true
          })
          // this.startRecording()
        } else if (info === 'leaved') {
          // leaved the stream
          console.log('leaved')
          /* if (mediaRecorder !== null) {
            mediaRecorder.stop()
          } */
        } else if (info === 'start') {
          this.handleStartApi()
          console.log('started p2p')
        } else if (info === 'screen_share_stopped') {
          this.setState({
            isShareScreen: false,
            isShareScreenCamera: false
          })
          console.log('screen_share_stopped')
        } else if (info === 'stop') {
          console.log('stopped')
          const { isFirstParticipantToLeave } = this.state
          prompt = false
          this.setState(
            {
              isJoined: false,
              isCameraOff: false,
              isMicOff: false,
              showVideo: false,
              hasStarted: false,
              showPrompt: false
            },
            () => {
              if (!isFirstParticipantToLeave) {
                onStreamStopped()
                handleBroadcastChange()
                stopstream()
              }
            }
          )
        } else if (info === 'takeConfiguration') {
          // joined the stream
          console.log('"takeConfiguration"')
          calculateStopTime()
          calculateWarnTime()
          this.setState({
            hasStarted: true
          })
        }
      },
      callbackError: error => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        const { t } = this.props
        console.log('error callback: ' + error)
        this.setState(
          { error, isShareScreen: false, isShareScreenCamera: false },
          () => {
            if (error === 'ScreenSharePermissionDenied') {
              message.error(t('events:screen_share_denied'))
            }
          }
        )
        // alert(error)
      }
    })
  }

  join = () => {
    const { handleBroadcastChange } = this.props
    this.setState({ isJoined: true, showVideo: true }, () => {
      handleBroadcastChange()
      this.initService()
    })
  }

  leave = () => {
    const { streamId } = this.props
    webRTCAdaptor.leave(streamId)
    webRTCAdaptor.turnOffCamera()
    this.handleStopApi()
  }

  toggleModal = () => {
    this.setState(state => ({ canShowModal: !state.canShowModal }))
  }

  handleOk = () => {
    const { stopstream, onStreamStopped, handleBroadcastChange } = this.props
    stopstream()
    this.toggleModal()
    handleBroadcastChange()
    this.leave()
    onStreamStopped()
    this.setState({
      isJoined: false,
      showVideo: false,
      isFirstParticipantToLeave: true
    })
  }

  turnOffLocalCamera = () => {
    this.setState({ isCameraOff: true }, () => {
      webRTCAdaptor.turnOffLocalCamera()
    })
  }

  turnOnLocalCamera = () => {
    this.setState({ isCameraOff: false }, () => {
      webRTCAdaptor.turnOnLocalCamera()
    })
  }

  muteLocalMic = () => {
    this.setState({ isMicOff: true }, () => {
      webRTCAdaptor.muteLocalMic()
    })
  }

  unmuteLocalMic = () => {
    this.setState({ isMicOff: false }, () => {
      webRTCAdaptor.unmuteLocalMic()
    })
  }

  screenShare = () => {
    const { streamId } = this.props
    const { isShareScreen } = this.state
    if (!isShareScreen) {
      webRTCAdaptor.switchDesktopCapture(streamId)
      this.setState({
        isShareScreen: true
      })
    } else {
      webRTCAdaptor.switchVideoCapture(streamId)
      this.setState({
        isShareScreen: false
      })
    }
  }

  screenShareCamera = () => {
    const { streamId } = this.props
    const { isShareScreenCamera } = this.state
    if (!isShareScreenCamera) {
      webRTCAdaptor.switchDesktopCaptureWithCamera(streamId)
      this.setState({
        isShareScreenCamera: true
      })
    } else {
      webRTCAdaptor.switchVideoCapture(streamId)
      this.setState({
        isShareScreenCamera: false
      })
    }
  }

  handleStartApi = () => {
    const { eventId } = this.props
    api.events
      .startLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in starting event', err)
      })
  }

  handleStopApi = () => {
    const { eventId } = this.props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }

  render() {
    const {
      showVideo,
      isCameraOff,
      isMicOff,
      isJoined,
      localvideoClassName,
      isShareScreen,
      isShareScreenCamera,
      hasStarted,
      canShowModal,
      showPrompt
    } = this.state
    const { isStreamStopped, t } = this.props
    console.log('peer console', localvideoClassName, 'hasStarted', hasStarted)

    console.log('pcConfig', pcConfig)
    console.log('config.turnURL check', config.turnURL)

    return (
      <div className="div-section">
        {showVideo && (
          <>
            <Prompt
              when={showPrompt}
              message={() => {
                prompt = window.confirm(t('events:prompt_message'))
                return prompt
              }}
            />
            <Row>
              <video
                className={localvideoClassName}
                // style={{
                //   display: `${hasStarted ? 'none' : 'block'}`
                // }}
                id="localVideo"
                autoPlay
                muted
              />

              <video
                style={{
                  display: `${
                    localvideoClassName === 'localvideo' ? 'block' : 'none'
                  }`
                }}
                id="remoteVideo"
                autoPlay
                controls
              />
            </Row>
          </>
        )}
        {!hasStarted && isJoined && (
          <Card.OverlayPlayer>{t('errors:waiting_to_join')}</Card.OverlayPlayer>
        )}
        {!isStreamStopped && (
          <Row className="controller">
            {!isJoined && (
              <Tooltip placement="top" title={t('v4:join')}>
                <div
                  role="button"
                  className="controller-item-join"
                  onClick={() => this.join()}
                >
                  <img src={join} alt="join" />
                </div>
              </Tooltip>
            )}
            <div className={`${isJoined ? 'controller-items' : ''}`}>
              {isJoined &&
                (isShareScreen ? (
                  <Tooltip
                    placement="top"
                    title={t('labels:stop_share_screen')}
                  >
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.screenShare()}
                    >
                      <img src={stopShareScreenLogo} alt="stop-share-screen" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('labels:share_screen')}>
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.screenShare()}
                    >
                      <img src={shareScreenLogo} alt="share-screen" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined &&
                (isShareScreenCamera ? (
                  <Tooltip
                    placement="top"
                    title={t('v4:stop_share_screen_with_camera')}
                  >
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.screenShareCamera()}
                    >
                      <img
                        src={StopShareScreenCamera}
                        alt="stop-share-screen-camera"
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="top"
                    title={t('v4:share_screen_with_camera')}
                  >
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.screenShareCamera()}
                    >
                      <img src={ShareScreenCamera} alt="share-screen-camera" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined &&
                (isCameraOff ? (
                  <Tooltip placement="top" title={t('v4:camera_on')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOnLocalCamera()}
                    >
                      <img src={cameraOff} alt="camera-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:camera_off')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOffLocalCamera()}
                    >
                      <img src={cameraOn} alt="camera-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined &&
                (isMicOff ? (
                  <Tooltip placement="top" title={t('v4:mic_on')}>
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.unmuteLocalMic()}
                    >
                      <img src={micOff} alt="mic-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:mic_off')}>
                    <div
                      role="button"
                      className="controller-item"
                      onClick={() => this.muteLocalMic()}
                    >
                      <img src={micOn} alt="mic-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined && (
                <Tooltip placement="top" title={t('v4:leave')}>
                  <div
                    role="button"
                    /* className={`controller-item ${
                      !hasStarted ? 'disable-item' : ''
                    }`} */
                    className="controller-item"
                    onClick={() => this.toggleModal()}
                  >
                    <img src={leave} alt="leave" />
                  </div>
                </Tooltip>
              )}
            </div>
          </Row>
        )}
        {canShowModal && (
          <Modal
            title={t('labels:alert')}
            visible={canShowModal}
            onOk={this.handleOk}
            onCancel={this.toggleModal}
            // okButtonProps={{ disabled: true }}
            // cancelButtonProps={{ disabled: true }}
          >
            <h3>{t('events:event_modal_warn')}</h3>
            <span>{t('events:confirm_message')}</span>
          </Modal>
        )}
      </div>
    )
  }
}

export default NetworkDetection(AntPeerService)
