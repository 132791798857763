import { LIST_QUESTIONS, LIST_QUESTIONS_PENDING } from '../types'
import { api } from '../../services'
const fetchQuestions = payload => dispatch => {
  if (payload !== 'DELETE') {
    dispatch({
      type: LIST_QUESTIONS_PENDING,
      payload: true
    })
  }

  return api.trivia
    .fetchQuestions(payload)
    .then(response => {
      dispatch({
        type: LIST_QUESTIONS,
        payload: response.data
      })
      return response
    })
    .catch(error => {
      dispatch({
        type: LIST_QUESTIONS_PENDING,
        payload: false
      })
      return error
    })
}
const gameQuestions = payload => () => {
  return api.trivia
    .gameQuestions(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error fetching game questions:', error)
    })
}
const fetchQuestionDetail = payload => dispatch => {
  return api.trivia
    .fetchQuestionDetail(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error fetching question detail:', error)
    })
}
const getBucketNames = payload => () => {
  return api.trivia
    .getBucketNames(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error fetching question detail:', error)
    })
}
const createQuitz = payload => () => {
  return api.trivia
    .createQuitz(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error creating quitz question!', error)
    })
}
const updateQuitz = payload => () => {
  return api.trivia
    .updateQuitz(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error updating quitz question!', error)
    })
}
const getScore = payload => () => {
  return api.trivia
    .getScore(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error getting quitz question!', error)
    })
}
const updateScore = payload => () => {
  return api.trivia
    .updateScore(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error updating quitz question!', error)
    })
}
const deleteQuestion = payload => dispatch => {
  return api.trivia
    .deleteQuestion(payload)
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Error fetching question detail:', error)
    })
}

export {
  fetchQuestions,
  fetchQuestionDetail,
  deleteQuestion,
  getBucketNames,
  createQuitz,
  updateQuitz,
  gameQuestions,
  updateScore,
  getScore
}
