import React, { useState, useEffect, useCallback } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import {
  Row,
  Col,
  Button,
  Progress,
  Input,
  Spin,
  Checkbox,
  Popover,
  InputNumber,
  Switch,
  Radio,
  Modal
} from 'antd'
import { Unity, useUnityContext } from 'react-unity-webgl-latest'
import { QuestionCircleOutlined } from '@ant-design/icons'
import _ from 'lodash'
import className from 'classnames'
import { uploadMedia } from '../../../../internal/List/images'
import Map from './Map'
import { config } from '../../../../../../constants'

const View = props => {
  const [checkBoxValue, setCheckBoxValue] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [switchInteractive, setSwitchInteractive] = useState(false)
  const { t } = useTranslation()
  const { TextArea } = Input
  const {
    mediaDat,
    zipLoading,
    imgLoading,
    isZip,
    submitLoading,
    imgPreviewLoading,
    audioLoading,
    selectedData,
    imageSizeCheck,
    zipSizeCheck,
    editAssets,
    onChangeTitle,
    onChangeDescription,
    onDropTargetImage,
    onDropppedZip,
    onDropppedAudio,
    onDropTargetPreviewImage,
    onClickUpdate,
    onClickSubmit,
    onChangeZipScale,
    isMarkerBased,
    onChangeMarkerBased,
    onGeoLocation,
    onUnitySaveClicked,
    distroyUnity
  } = props

  const enableSave = true

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    unload,
    sendMessage,
    addEventListener,
    removeEventListener
  } = useUnityContext({
    loaderUrl: '/arBuild/CreatorWithIntegration.loader.js',
    dataUrl: '/arBuild/CreatorWithIntegration.data',
    frameworkUrl: '/arBuild/CreatorWithIntegration.framework.js',
    codeUrl: '/arBuild/CreatorWithIntegration.wasm',
    cacheControl: handleCacheControl
  })

  // useEffect(() => {
  //   return () => {+
  //     unityProvider.setUnityInstance(null)
  //   }
  // }, [])

  const handleCacheControl = () => {
    return 'no-store'
  }

  const unloadUnity = async () => {
    await unload()
    editAssets ? onClickUpdate() : onClickSubmit()
  }

  const index =
    mediaDat &&
    mediaDat.ar_files &&
    mediaDat.ar_files.findIndex(obj => {
      return obj.media_format === 'IMAGE'
    })

  const audioIndex =
    mediaDat &&
    mediaDat.ar_files &&
    mediaDat.ar_files.findIndex(obj => {
      return obj.media_format === 'AUDIO'
    })

  const compressedIndex =
    mediaDat &&
    mediaDat.ar_files &&
    mediaDat.ar_files.findIndex(obj => {
      return obj.media_format === 'COMPRESSED'
    })

  // const condn = (!editAssets && !mediaDat.hasOwnProperty('ar_files'))
  // const enableSave = ((isMarkerBased === 2) && mediaDat.hasOwnProperty('customData') && mediaDat.customData.hasOwnProperty('geoloc'))

  const onChangeInputScale = v => {
    console.log('onChangeInputScale: ', v)
    onChangeZipScale(v)
  }

  const onSwitchInteractionMode = () => {
    setSwitchInteractive(switchInteractive => !switchInteractive)
    //unityProvider.setUnityInstance(null)
    //await handleUnloadUnity()
  }

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowUnityUI(false);
  //   }, 5000);
  //   return () => {
  //     console.log("killed timer");
  //     clearTimeout(timer);
  //   };
  // }, [switchInteractive]);

  // async function handleUnloadUnity() {
  //   if (!switchInteractive) {
  //     console.log("I am unload here");
  //     await unload();
  //   }
  // }

  // useEffect(() => {
  //   if (!switchInteractive) {
  //     async function handleClickBack() {
  //       console.log("I am unload here");
  //       await unload();
  //     }
  //     handleClickBack()
  //   }
  // }, [switchInteractive])

  useEffect(() => {
    const distroyUnityInstance = async () => {
      if (distroyUnity && isLoaded) {
        await unload()
      }
    }
    distroyUnityInstance()
  }, [distroyUnity])

  useEffect(() => {
    if (!checkBoxValue) {
      onChangeZipScale(0.15)
    }
  }, [checkBoxValue])

  useEffect(() => {
    console.log('MediaData useEffect', mediaDat)
    console.log('MediaData useEffect is_interactive', mediaDat.is_interactive)
    if (
      mediaDat &&
      mediaDat.hasOwnProperty('ar_files') &&
      editAssets &&
      !switchInteractive &&
      mediaDat.is_interactive === true &&
      _.has(mediaDat.custom_data, 'unity_coordinates')
    ) {
      onSwitchInteractionMode()
    }
  }, [mediaDat])

  useEffect(() => {
    if (
      mediaDat.hasOwnProperty('ar_files') &&
      isLoaded &&
      switchInteractive &&
      !editAssets
    ) {
      sendMessage(
        'DataReceiver',
        'StartCreator',
        `${config.s3BucketURL}/${mediaDat.ar_files[compressedIndex].key}`
      )
    }
    if (
      mediaDat &&
      mediaDat.hasOwnProperty('ar_files') &&
      editAssets &&
      isLoaded &&
      mediaDat.is_interactive === true &&
      _.has(mediaDat.custom_data, 'unity_coordinates') &&
      mediaDat.custom_data.unity_coordinates !== ''
    ) {
      console.log(
        'edit mediaDat.custom_data.unity_coordinates',
        JSON.stringify(mediaDat.custom_data.unity_coordinates)
      )
      sendMessage(
        'DataReceiver',
        'EditQuiz',
        JSON.stringify(mediaDat.custom_data.unity_coordinates)
      )
    }
    // ELSE SHOW ERROR TODO
  }, [isLoaded])

  useEffect(() => {
    addEventListener('OpenModal', isModalOpenListener)
    return () => {
      removeEventListener('OpenModal', isModalOpenListener)
    }
  }, [addEventListener, removeEventListener, isModalOpenListener])

  const isModalOpenListener = useCallback(param => {
    console.log('isModalOpenListener : ', param)
    if (param === 1) {
      setModalVisible(true)
      return
    }
    setModalVisible(false)
  }, [])

  useEffect(() => {
    addEventListener('SendFinalJson', receiveDataListener)
    return () => {
      removeEventListener('SendFinalJson', receiveDataListener)
    }
  }, [addEventListener, removeEventListener, receiveDataListener])

  const receiveDataListener = useCallback(param => {
    console.log('receiveDataListener : ', param)
    console.log('receiveDataListener switchInteractive : ', switchInteractive)
    const sendJSON = {
      switchInteractive: editAssets ? mediaDat.is_interactive : true,
      json: param
    }
    onUnitySaveClicked(sendJSON)
  }, [])

  const onChangeAdvancedSetting = () => {
    setCheckBoxValue(prevCheck => !prevCheck)
  }

  const closeModal = () => {
    setModalVisible(false)
    sendMessage(
      'DataReceiver',
      'UploadDone',
      JSON.stringify({ url: '', isOpen: 0 })
    )
  }

  const onModelUpload = () => {
    setModalVisible(false)
    console.log('mediaDat.ar_files ', mediaDat.ar_files)
    console.log('mediaDat.ar_files ', mediaDat.ar_files[compressedIndex].key)
    sendMessage(
      'DataReceiver',
      'UploadDone',
      JSON.stringify({
        url: `${process.env.REACT_APP_S3_BUCKET_URL}/${mediaDat.ar_files[compressedIndex].key}`,
        isOpen: 1
      })
    )
  }

  const content = (
    <div>
      <p>
        This is 3D Model's Scale Value. <br />
        Change it only if you know what <br />
        you are doing.
      </p>
    </div>
  )
  return (
    <>
      <br />
      <Row>
        <Modal
          title="Upload 3D Model Zip"
          centered
          visible={modalVisible}
          onOk={onModelUpload}
          // footer={null}
          // width={416}
          onCancel={closeModal}
        >
          <div className="upload-modal__dropzone">
            <Dropzone
              //accept={['application/zip', 'video/*']}
              accept={['application/zip', 'application/x-zip-compressed']}
              maxFiles={1}
              maxSize={60000000}
              onDrop={onDropppedZip}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className={className('upload-dropzone', {
                    'upload-dropzone_active': isDragActive
                  })}
                >
                  <Spin size="large" spinning={zipLoading}>
                    <div className="upload-dropzone__text-wrapper">
                      <img src={uploadMedia} alt="" />
                      <p
                        className="upload-dropzone__text"
                        style={{ width: '131px' }}
                      >
                        <span>Upload 3D Zip</span> {t('v2:drag_n_drop')}
                      </p>
                      <br />
                      {zipSizeCheck && (
                        <em style={{ color: 'red' }}>
                          {t('v2:zip_size_should_not_exceed')}
                        </em>
                      )}
                      {!zipLoading && mediaDat.hasOwnProperty('ar_files') && (
                        <div>
                          <Progress type="circle" percent={100} width={30} />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '118px' }}
                          >
                            {t('v2:content_attached')}
                          </p>
                        </div>
                      )}
                    </div>
                  </Spin>
                </div>
              )}
            </Dropzone>
          </div>
        </Modal>
        <col span={2}></col>
        <Col span={20} offset={2}>
          <Radio.Group
            onChange={onChangeMarkerBased}
            disabled={isLoaded ? true : false}
            value={isMarkerBased}
          >
            <Radio value={1}>Marker Based</Radio>
            <Radio value={2}>Geolocation Based</Radio>
          </Radio.Group>
        </Col>
        <col span={2}></col>
      </Row>
      <br />
      <Row>
        <col span={2}></col>
        <Col span={20} offset={2}>
          <h3 className="upload-modal__text" style={{ textAlign: 'left' }}>
            {isMarkerBased === 2 ? 'Title' : t('v2:trigger_title')}
          </h3>
          <Input
            placeholder={
              isMarkerBased === 2
                ? 'Enter image title *'
                : t('v2:enter_trigger_image_title')
            }
            defaultValue={selectedData && selectedData.title}
            allowClear
            onChange={onChangeTitle}
          />
        </Col>
        <col span={2}></col>
      </Row>
      <br />
      <br />
      <Row>
        <col span={2}></col>
        <Col span={20} offset={2}>
          <h3 className="upload-modal__text" style={{ textAlign: 'left' }}>
            {isMarkerBased === 2 ? 'Description' : t('v2:trigger_description')}
          </h3>
          <TextArea
            placeholder={
              isMarkerBased === 2
                ? 'Please type image description'
                : t('v2:please_type_target_description')
            }
            defaultValue={selectedData && selectedData.description}
            autoSize={{ minRows: 2, maxRows: 6 }}
            allowClear
            onChange={onChangeDescription}
            tabIndex={2}
          />
        </Col>
        <col span={2}></col>
      </Row>
      <br />
      <br />

      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <h3
            className="upload-modal__text"
            style={{ textAlign: 'left', marginLeft: '-32px' }}
          >
            {isMarkerBased === 2 ? 'Image *' : t('v2:trigger_image')}
          </h3>
          <p style={{ textAlign: 'left', fontSize: '13px' }}>
            {t('v2:_jpg_files')}
          </p>
          <div className="upload-modal__inner">
            <div className="upload-modal__dropzone">
              <Dropzone
                accept={['image/jpeg']}
                maxFiles={1}
                maxSize={512000}
                onDrop={onDropTargetImage}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div>
                    <img
                      id="preview-img"
                      src={selectedData && selectedData.link}
                      style={{
                        position: 'absolute',
                        opacity: '0.4',
                        objectFit: 'cover'
                      }}
                      width="900"
                      height="189"
                      alt=""
                    />
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <Spin size="large" spinning={imgLoading}>
                        <div className="upload-dropzone__text-wrapper">
                          <img src={uploadMedia} alt="" />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '148px' }}
                          >
                            <span>
                              {isMarkerBased === 2
                                ? 'Upload Image'
                                : t('v2:upload_trigger_image')}
                            </span>
                            <br />
                            {t('v2:drag_n_drop')}
                            <br />
                            {imageSizeCheck && (
                              <em style={{ color: 'red' }}>
                                {t('v2:file_size_should_not_exceed')}
                              </em>
                            )}
                          </p>
                        </div>
                      </Spin>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>

      <br />
      <br />

      {isMarkerBased === 2 && (
        <Row>
          <Col span={3}></Col>
          <Col span={19}>
            <h3
              className="upload-modal__text"
              style={{ textAlign: 'left', marginLeft: '-32px' }}
            >
              GeoLocation *
            </h3>
            <p style={{ textAlign: 'left', fontSize: '13px' }}>
              Place marker to desired location on Map
            </p>
            <Map
              onGeoLocation={onGeoLocation}
              mediaDat={mediaDat}
              enableSave={enableSave}
              editAssets={editAssets}
            />
          </Col>
          <Col span={2}></Col>
        </Row>
      )}
      <br />
      <br />

      {!switchInteractive && (
        <Row>
          <Col span={3}></Col>
          <Col span={6}>
            <h3
              className="upload-modal__text"
              style={{ textAlign: 'left', marginLeft: '-32px' }}
            >
              {t('Interactive Mode')}
            </h3>
            <p style={{ textAlign: 'left', fontSize: '13px' }}>
              {t('(Enable or disable 3D interaction)')}
            </p>
          </Col>
          <Col span={15}>
            <Switch defaultChecked={false} onChange={onSwitchInteractionMode} />
          </Col>
          {/* <Button
          type="primary"
          shape="round"
          size="large"
          onClick={onClickButtonDown}
        ></Button> */}
        </Row>
      )}
      <br />
      <br />

      {!isLoaded && (
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <h3
              className="upload-modal__text"
              style={{
                textAlign: 'left',
                fontSize: '14px',
                marginLeft: '-32px'
              }}
            >
              {/* {t('v2:ar_content')} */}
              {switchInteractive
                ? t('Interactive AR Content')
                : t('Non Interactive AR Content')}
            </h3>
            <p style={{ textAlign: 'left', fontSize: '13px' }}>
              {switchInteractive
                ? t('v2:_3d_files_mp4_ar')
                : t('v2:_3d_files_mp4')}
            </p>
            <div className="upload-modal__inner">
              <div className="upload-modal__dropzone">
                <Dropzone
                  //accept={['application/zip', 'video/*']}
                  accept={[
                    'application/zip',
                    'application/x-zip-compressed',
                    'video/*'
                  ]}
                  maxFiles={1}
                  maxSize={60000000}
                  onDrop={onDropppedZip}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <Spin size="large" spinning={zipLoading}>
                        <div className="upload-dropzone__text-wrapper">
                          <img src={uploadMedia} alt="" />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '131px' }}
                          >
                            <span>
                              {switchInteractive
                                ? 'Upload 3D Zip'
                                : 'Upload Zip/Video'}
                            </span>{' '}
                            {t('v2:drag_n_drop')}
                          </p>
                          <br />
                          {zipSizeCheck && (
                            <em style={{ color: 'red' }}>
                              {t('v2:zip_size_should_not_exceed')}
                            </em>
                          )}
                          {mediaDat.hasOwnProperty('ar_files') && (
                            <div>
                              <Progress
                                type="circle"
                                percent={100}
                                width={30}
                              />
                              <p
                                className="upload-dropzone__text"
                                style={{ width: '118px' }}
                              >
                                {isZip
                                  ? t('v2:content_attached')
                                  : t('v2:video_attached')}
                              </p>
                            </div>
                          )}
                        </div>
                      </Spin>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </Col>
          <Col span={3}></Col>
        </Row>
      )}
      <br />
      <br />
      {mediaDat.hasOwnProperty('ar_files') && switchInteractive && (
        <Row>
          <Col span={1}></Col>
          <Col span={22}>
            <Unity
              unityProvider={unityProvider}
              style={{ width: 1024, height: 700 }}
              tabIndex={1}
            />
          </Col>
          <Col span={1}></Col>
        </Row>
      )}

      {((isZip && !zipLoading) || (isZip && editAssets)) && !switchInteractive && (
        <Row>
          <Col span={6} offset={18}>
            <Checkbox
              checked={checkBoxValue}
              onChange={onChangeAdvancedSetting}
            >
              Advanced Setting
            </Checkbox>{' '}
            <Popover content={content}>
              <QuestionCircleOutlined />
            </Popover>
            {checkBoxValue && (
              <>
                <p>
                  Scale:{' '}
                  <InputNumber
                    defaultValue="0.15"
                    min="0"
                    max="1"
                    onChange={onChangeInputScale}
                    stringMode
                  />
                </p>
              </>
            )}
          </Col>
        </Row>
      )}
      <br />
      <br />
      {isZip && !switchInteractive && !zipLoading && (
        <Row>
          <Col span={3}></Col>
          <Col span={18}>
            <h3
              className="upload-modal__text"
              style={{ textAlign: 'left', marginLeft: '-32px' }}
            >
              {t('v2:audio_content')}
            </h3>
            <p
              className="upload-modal__text"
              style={{ textAlign: 'left', fontSize: '13px' }}
            >
              {t('v2:_mp3')}
            </p>
            <div className="upload-modal__inner">
              <div className="upload-modal__dropzone">
                <Dropzone
                  accept={['audio/*']}
                  maxFiles={1}
                  onDrop={onDropppedAudio}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <Spin size="large" spinning={audioLoading}>
                        <div className="upload-dropzone__text-wrapper">
                          <img src={uploadMedia} alt="" />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '112px' }}
                          >
                            <span>{t('v2:upload_audio')}</span>{' '}
                            {t('v2:drag_n_drop')}
                          </p>
                          <br />
                          {audioIndex > -1 &&
                            mediaDat.hasOwnProperty('ar_files') && (
                              <div>
                                <Progress
                                  type="circle"
                                  percent={100}
                                  width={30}
                                />
                                <p
                                  className="upload-dropzone__text"
                                  style={{ width: '118px' }}
                                >
                                  {t('v2:audio_attached')}
                                </p>
                              </div>
                            )}
                        </div>
                      </Spin>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          </Col>
          <Col span={3}></Col>
        </Row>
      )}
      <br />
      <br />
      <Row>
        <Col span={3}></Col>
        <Col span={18}>
          <h3
            className="upload-modal__text"
            style={{ textAlign: 'left', marginLeft: '-32px' }}
          >
            {t('v2:ar_preview_image')}
          </h3>
          <div className="upload-modal__inner">
            <div className="upload-modal__dropzone">
              <Dropzone
                accept={['image/*']}
                maxFiles={1}
                onDrop={onDropTargetPreviewImage}
              >
                {({ getRootProps, getInputProps, isDragActive }) => (
                  <div>
                    <img
                      id="preview-ar-img"
                      style={{
                        position: 'absolute',
                        opacity: '0.4',
                        objectFit: 'cover'
                      }}
                      src={
                        editAssets &&
                        index > -1 &&
                        mediaDat.ar_files[index].link
                      }
                      width="900"
                      height="189"
                      alt=""
                    />
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <Spin size="large" spinning={imgPreviewLoading}>
                        <div className="upload-dropzone__text-wrapper">
                          <img src={uploadMedia} alt="" />
                          <p
                            className="upload-dropzone__text"
                            style={{ width: '155px' }}
                          >
                            <span>{t('v2:upload_preview_image')}</span>{' '}
                            {t('v2:drag_n_drop')}
                          </p>
                        </div>
                      </Spin>
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
        </Col>
        <Col span={3}></Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <Col span={4} offset={20}>
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={
              editAssets
                ? isLoaded
                  ? unloadUnity
                  : onClickUpdate
                : isLoaded
                ? unloadUnity
                : onClickSubmit
            }
            disabled={
              !editAssets &&
              !(
                mediaDat.hasOwnProperty('category') &&
                mediaDat.hasOwnProperty('ar_files') &&
                enableSave
              )
            }
          >
            {editAssets
              ? submitLoading
                ? t('v2:updating___')
                : t('v2:update')
              : submitLoading
              ? t('v2:creating___')
              : t('v2:create')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default View
