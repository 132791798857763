const translateKeys = [
  { name: 'Afrikaans', key: 'afrikaans', code: 'af' },
  { name: 'Albanian', key: 'albanian', code: 'sq' },
  { name: 'Amharic', key: 'amharic', code: 'am' },
  { name: 'Arabic', key: 'arabic', code: 'ar' },
  { name: 'Armenian', key: 'albanian', code: 'hy' },
  { name: 'Azerbaijani', key: 'albanian', code: 'az' },
  { name: 'Basque', key: 'albanian', code: 'eu' },
  { name: 'Belarusian', key: 'albanian', code: 'be' },
  { name: 'Bengali', key: 'albanian', code: 'bn' },
  { name: 'Bosnian', key: 'albanian', code: 'bs' },
  { name: 'Bulgarian', key: 'albanian', code: 'bg' },
  { name: 'Catalan', key: 'albanian', code: 'ca' },
  { name: 'Cebuano', key: 'albanian', code: 'ceb' },
  { name: 'Chinese (Simplified)', key: 'albanian', code: 'zh-CN' },
  { name: 'Chinese (Traditional)', key: 'albanian', code: 'zh-TW' },
  { name: 'Corsican', key: 'albanian', code: 'co' },
  { name: 'Croatian', key: 'albanian', code: 'hr' },
  { name: 'Czech', key: 'albanian', code: 'cs' },
  { name: 'Danish', key: 'albanian', code: 'da' },
  { name: 'Dutch', key: 'albanian', code: 'nl' },
  { name: 'English', key: 'albanian', code: 'en' },
  { name: 'Esperanto', key: 'albanian', code: 'eo' },
  { name: 'Estonian', key: 'albanian', code: 'et' },
  { name: 'Finnish', key: 'albanian', code: 'fi' },
  { name: 'French', key: 'albanian', code: 'fr' },
  { name: 'Frisian', key: 'albanian', code: 'fy' },
  { name: 'Galician', key: 'albanian', code: 'gl' },
  { name: 'Georgian', key: 'albanian', code: 'ka' },
  { name: 'German', key: 'albanian', code: 'de' },
  { name: 'Greek', key: 'albanian', code: 'el' },
  { name: 'Gujarati', key: 'albanian', code: 'gu' },
  { name: 'Haitian Creole', key: 'albanian', code: 'ht' },
  { name: 'Hausa', key: 'albanian', code: 'ha' },
  { name: 'Hawaiian', key: 'albanian', code: 'haw' },
  { name: 'Hebrew', key: 'albanian', code: 'he' },
  { name: 'Hindi', key: 'albanian', code: 'hi' },
  { name: 'Hmong', key: 'albanian', code: 'hmn' },
  { name: 'Hungarian', key: 'albanian', code: 'hu' },
  { name: 'Icelandic', key: 'albanian', code: 'is' },
  { name: 'Igbo', key: 'albanian', code: 'ig' },
  { name: 'Indonesian', key: 'albanian', code: 'id' },
  { name: 'Irish', key: 'albanian', code: 'ga' },
  { name: 'Italian', key: 'albanian', code: 'it' },
  { name: 'Japanese', key: 'albanian', code: 'ja' },
  { name: 'Kannada', key: 'albanian', code: 'kn' },
  { name: 'Kazakh', key: 'albanian', code: 'kk' },
  { name: 'Khmer', key: 'albanian', code: 'km' },
  { name: 'Kinyarwanda', key: 'albanian', code: 'rw' },
  { name: 'Korean', key: 'albanian', code: 'ko' },
  { name: 'Kurdish', key: 'albanian', code: 'ku' },
  { name: 'Kyrgyz', key: 'albanian', code: 'ky' },
  { name: 'Lao', key: 'albanian', code: 'lo' },
  { name: 'Latin', key: 'albanian', code: 'la' },
  { name: 'Latvian', key: 'albanian', code: 'lv' },
  { name: 'Lithuanian', key: 'albanian', code: 'lt' },
  { name: 'Luxembourgish', key: 'albanian', code: 'lb' },
  { name: 'Macedonian', key: 'albanian', code: 'mk' },
  { name: 'Malagasy', key: 'albanian', code: 'mg' },
  { name: 'Malay', key: 'albanian', code: 'ms' },
  { name: 'Malayalam', key: 'albanian', code: 'ml' },
  { name: 'Maltese', key: 'albanian', code: 'mt' },
  { name: 'Maori', key: 'albanian', code: 'mi' },
  { name: 'Marathi', key: 'albanian', code: 'mr' },
  { name: 'Mongolian', key: 'albanian', code: 'mn' },
  { name: 'Myanmar (Burmese)', key: 'albanian', code: 'my' },
  { name: 'Nepali', key: 'albanian', code: 'ne' },
  { name: 'Norwegian', key: 'albanian', code: 'no' },
  { name: 'Nyanja (Chichewa)', key: 'albanian', code: 'ny' },
  { name: 'Odia (Oriya)', key: 'albanian', code: 'or' },
  { name: 'Pashto', key: 'albanian', code: 'ps' },
  { name: 'Persian', key: 'albanian', code: 'fa' },
  { name: 'Polish', key: 'albanian', code: 'pl' },
  { name: 'Portuguese (Portugal, Brazil)', key: 'albanian', code: 'pt' },
  { name: 'Punjabi', key: 'albanian', code: 'pa' },
  { name: 'Romanian', key: 'albanian', code: 'ro' },
  { name: 'Russian', key: 'albanian', code: 'ru' },
  { name: 'Samoan', key: 'albanian', code: 'sm' },
  { name: 'Scots Gaelic', key: 'albanian', code: 'gd' },
  { name: 'Serbian', key: 'albanian', code: 'sr' },
  { name: 'Sesotho', key: 'albanian', code: 'st' },
  { name: 'Shona', key: 'albanian', code: 'sn' },
  { name: 'Sindhi', key: 'albanian', code: 'sd' },
  { name: 'Sinhala (Sinhalese)', key: 'albanian', code: 'si' },
  { name: 'Slovak', key: 'albanian', code: 'sk' },
  { name: 'Slovenian', key: 'albanian', code: 'sl' },
  { name: 'Somali', key: 'albanian', code: 'so' },
  { name: 'Spanish', key: 'albanian', code: 'es' },
  { name: 'Sundanese', key: 'albanian', code: 'su' },
  { name: 'Swahili', key: 'albanian', code: 'sw' },
  { name: 'Swedish', key: 'albanian', code: 'sv' },
  { name: 'Tagalog (Filipino)', key: 'albanian', code: 'tl' },
  { name: 'Tajik', key: 'albanian', code: 'tg' },
  { name: 'Tamil', key: 'albanian', code: 'ta' },
  { name: 'Tatar', key: 'albanian', code: 'tt' },
  { name: 'Telugu', key: 'albanian', code: 'te' },
  { name: 'Thai', key: 'albanian', code: 'th' },
  { name: 'Turkish', key: 'albanian', code: 'tr' },
  { name: 'Turkmen', key: 'albanian', code: 'tk' },
  { name: 'Ukrainian', key: 'albanian', code: 'uk' },
  { name: 'Urdu', key: 'albanian', code: 'ur' },
  { name: 'Uyghur', key: 'albanian', code: 'ug' },
  { name: 'Uzbek', key: 'albanian', code: 'uz' },
  { name: 'Vietnamese', key: 'albanian', code: 'vi' },
  { name: 'Welsh', key: 'albanian', code: 'cy' },
  { name: 'Xhosa', key: 'albanian', code: 'xh' },
  { name: 'Yiddish', key: 'albanian', code: 'yi' },
  { name: 'Yoruba', key: 'albanian', code: 'yo' },
  { name: 'Zulu', key: 'albanian', code: 'zu' }
]

export default translateKeys
