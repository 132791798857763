import { Col } from 'antd'
import React from 'react'
import { withTranslation } from 'react-i18next'
import history from '../../../../history'

function ActivityCounter({
  route,
  searchParams,
  totalCount,
  type,
  participants,
  timeSpent,
  icon,
  t
}) {
  return (
    <Col lg={4} sm={7}>
      <div
        className="report-count-container bluish-background"
        onClick={() => history.push(route, searchParams)}
      >
        <div className="report-count-info">
          <div className="count">
            <span>{totalCount}</span>
            <h3 className="title">{type}</h3>
          </div>
          {/* <hr /> */}
          <div className="activity">
            <h3>
              {participants} {t('reports:participants')}
            </h3>
            <h3>{timeSpent}</h3>
          </div>
        </div>
        <img alt="" src={icon} />
      </div>
    </Col>
  )
}

export default withTranslation()(ActivityCounter)
