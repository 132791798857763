import styled from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Nav = styled.nav``

Nav.List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

Nav.Item = styled.li`
  border-bottom: none;
`

export { Nav }
