import styled from 'styled-components'

const DiscussionContainer = styled.div`
width: '100%';
overflow-y: 'auto'
`

DiscussionContainer.Overlay = styled.div`
    position fixed;
    display none;
    top 0;
    left 0;
    width 100%;
    height 100%;
    z-index -1;
`

export {
  DiscussionContainer
}
