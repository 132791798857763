import React, {
  Children,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import uuidv4 from 'uuid/v4'

import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  Modal,
  Input,
  Button,
  Popconfirm,
  DatePicker,
  Checkbox,
  Form,
  message,
  Spin,
  Typography,
  Steps,
  Card,
  Alert,
  Icon,
  Popover
} from 'antd'

import './mycalendar.scss'
import { useTranslation, withTranslation } from 'react-i18next'
import { getCalendar, updateCalendar } from '../../services/api/calendar'
import _ from 'lodash'
import NewHeader from '../../components/NewHeader'

const localizer = momentLocalizer(moment)
const { Text } = Typography
const { Step } = Steps

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

const getKey = () =>
  uuidv4()
    .split('-')
    .pop()
const formats = {
  weekdayFormat: (date, culture, localizer) =>
    localizer.format(date, 'dddd', culture)
}

/*
  props
    => isModal is for calendar shown in coaching marketplace form
*/

const MyCalendar = props => {
  const [events, setEvents] = useState([])
  const [showEventModal, setShowEventModal] = useState(false)
  const [selectedDay, setSelectedDay] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [isEdit, setEdit] = useState(false)
  const [date, setDate] = useState(new Date())
  const [isCalendarFetching, setIsCalendarFetching] = useState(false)
  const [isCalendarUpdating, setIsCalendarUpdating] = useState(false)

  const onNavigate = useCallback(newDate => setDate(newDate), [setDate])
  const isInitialMount = useRef(true)

  const { t } = useTranslation()

  // to restrict update on first render and first fetch
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      handleUpdateCalendar()
    }
  }, [events])

  const fetchCalendar = () => {
    getCalendar()
      .then(res => {
        if (res) {
          const userEvents = formatEvents(res.data.calendar)
          isInitialMount.current = true // to restrict update on first fetch
          setEvents(userEvents)
        }
        setIsCalendarFetching(false)
      })
      .catch(err => {
        setIsCalendarFetching(false)
        message.error(err)
      })
  }

  useEffect(() => {
    const { isModal, events = [] } = props
    if (isModal) {
      setEvents(formatEvents(events))
    } else {
      setIsCalendarFetching(true)
      fetchCalendar()
    }
  }, [])

  // useEffect(() => {
  //   if (!isCalendarFetching) {
  //     handleUpdateCalendar()
  //   }
  // }, [events])

  const handleUpdateCalendar = _.debounce(() => {
    if (isCalendarUpdating) {
      handleUpdateCalendar()
    } else {
      updateApi()
    }
  }, 1000)

  const formatEvents = eventsArray =>
    eventsArray.map(item => ({
      ...item,
      start: new Date(item.start),
      end: new Date(item.end)
    }))

  const updateApi = () => {
    if (!isCalendarUpdating) {
      setIsCalendarUpdating(true)
    }

    updateCalendar({ calendar: events, duration: 60 })
      .then(updateRes => {
        setIsCalendarUpdating(false)
        console.log({ updateRes })
        const { onUpdate } = props
        if (onUpdate) {
          onUpdate(events)
        }
      })
      .catch(updateError => {
        setIsCalendarUpdating(false)
        console.log({ updateError })
      })
  }

  const handleSelectMonthSlot = day => {
    const { start } = day
    const isPastDate = moment(start).isBefore(new Date(), 'day')
    if (isPastDate) return
    setDate(start)
    setSelectedDay(day)
  }

  const handleSelectDaySlot = slot => {
    setSelectedSlot(slot)
    props.form.setFieldsValue({ title: slot.title || '' })
    setShowEventModal(true)
  }

  const handleSelectEvent = event => {
    console.log('on select event', event)
    if (event && event.key && event.title) setEdit(true)
    handleSelectDaySlot(event)
  }

  const handleTitle = e => {
    const text = e.target.value
    setSelectedSlot(slot => ({
      ...slot,
      title: text
    }))
  }

  const handleSubmit = () => {
    const { form } = props
    const {
      start,
      end,
      key,
      title,
      coaching_availability = false
    } = selectedSlot

    form.validateFields(err => {
      if (err) return

      if (isEdit) {
        const newEvents = events.map(item => {
          if (item.key === key) {
            return selectedSlot
          }
          return item
        })

        setEvents(newEvents)
      } else {
        setEvents(prev => [
          ...prev,
          {
            key: getKey(),
            start,
            end,
            title,
            coaching_availability,
            is_booked: false
          }
        ])
      }
      // handleUpdateCalendar()
      handleCancel(true)
    })
  }

  const handleCancel = cancelFromModal => {
    if (!cancelFromModal) {
      setSelectedDay(null)
    }
    setSelectedSlot(null)
    setEdit(false)
    setShowEventModal(false)
  }

  const getTimeFromUTC = utc => {
    const event = new Date(utc)
    return event.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }

  const renderSteps = () => {
    const { start, end } = selectedSlot

    const duration = moment.duration(moment(end).diff(moment(start)))
    const hours = duration.hours()
    const minutes = duration.minutes()

    return (
      <Card title={getCardTitle(start)}>
        <Steps progressDot current={3}>
          <Step title={getTimeFromUTC(start)} />
          <Step title={getTimeFromUTC(end)} />
        </Steps>
        <div className="duration-time">
          <span>
            {hours > 0 && hours + ' hours'}{' '}
            {minutes > 0 && minutes + ' minutes'}{' '}
          </span>
        </div>
      </Card>
    )
  }

  const getCardTitle = start => {
    const date = moment(start).date()
    const month = moment(start).format('MMMM')
    const dayOfWeek = days[moment(start).day()]

    return <b>{`${dayOfWeek}, ${month} ${date}`}</b>
  }

  const handleDeleteSchedule = () => {
    const key = selectedSlot.key
    const remainingEvents = events.filter(item => item.key !== key)
    setEvents(remainingEvents)
    handleCancel()
    setDate(new Date())
  }

  const onCoachingAvailability = e => {
    const checked = e.target.checked
    setSelectedSlot(slot => ({
      ...slot,
      coaching_availability: checked
    }))
  }

  const onDateChange = (date, dateString) => {
    const newDate = dateString ? moment(dateString).format() : moment().format()
    handleSelectMonthSlot({ start: newDate })
  }

  const day = selectedDay && selectedDay.start

  const {
    form: { getFieldDecorator },
    isModal = false
  } = props

  console.log({ events, date })

  return (
    <Spin spinning={isCalendarFetching}>
      {!isModal && <CalendarHeader />}
      <div className="MyCalendar">
        <Calendar
          date={date}
          selectable
          events={events}
          views={['month']}
          formats={formats}
          defaultView="month"
          localizer={localizer}
          onNavigate={onNavigate}
          className="month-calendar"
          onSelectSlot={handleSelectMonthSlot}
          components={{
            toolbar: props => (
              <CalendarToolbar
                {...props}
                date={date}
                onDateChange={onDateChange}
              />
            ),
            month: {
              header: ({ label }) => (
                <h3 className="calendar-day-header">{label}</h3>
              ),
              dateHeader: props => (
                <CustomDayInMonthView
                  {...props}
                  events={events}
                  selectedDate={date}
                />
              ),
              dateCellWrapper: props => (
                <CustomDateCellWrapper {...props} events={events} date={date} />
              )
            },
            event: () => <></>
          }}
        />

        {day && (
          <Calendar
            date={day}
            selectable
            views={['day']}
            events={events}
            defaultView="day"
            step={60}
            timeslots={1}
            localizer={localizer}
            className="day-calendar"
            onSelectSlot={handleSelectDaySlot}
            onSelectEvent={handleSelectEvent}
          />
        )}

        <Modal
          centered
          onCancel={handleCancel}
          visible={showEventModal}
          title={
            selectedSlot && selectedSlot.is_booked
              ? 'Booked Schedule'
              : 'Add schedule'
          }
          footer={
            selectedSlot && !selectedSlot.is_booked ? (
              <div className="calendar-modal-footer">
                {isEdit && (
                  <Popconfirm
                    title="Are you sure delete this schedule?"
                    onConfirm={handleDeleteSchedule}
                    okText="Yes"
                    cancelText="No"
                    icon={false}
                  >
                    <Button
                      className="remove-schedule-button"
                      type="danger"
                      shape="round"
                    >
                      {t('v4:delete_schedule')}
                    </Button>
                  </Popconfirm>
                )}
                <Button type="primary" shape="round" onClick={handleSubmit}>
                  {t('buttons:save')}
                </Button>
              </div>
            ) : (
              false
            )
          }
        >
          <Form onSubmit={handleSubmit}>
            <div className="calendar-title-container">
              {selectedSlot &&
                (selectedSlot.start || selectedSlot.slots) &&
                renderSteps()}
              <Form.Item label="Add Task" className="title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a task !',
                      blackspace: false
                    }
                  ],
                  initialValue:
                    selectedSlot && selectedSlot.title ? selectedSlot.title : ''
                })(
                  <Input
                    type="text"
                    key={selectedSlot}
                    value={selectedSlot ? selectedSlot.title : ''}
                    onChange={handleTitle}
                    disabled={selectedSlot && selectedSlot.is_booked}
                  />
                )}
              </Form.Item>

              <Checkbox
                className="coaching-checkbox"
                onChange={onCoachingAvailability}
                disabled={selectedSlot && selectedSlot.is_booked}
                checked={
                  selectedSlot && selectedSlot.coaching_availability
                    ? selectedSlot.coaching_availability
                    : false
                }
              >
                {t('v4:make_as_coaching')}
              </Checkbox>

              {selectedSlot && selectedSlot.is_booked && (
                <Alert
                  style={{ marginTop: '2rem' }}
                  type="success"
                  message="This timeslot is booked by a user for coaching."
                />
              )}
            </div>
          </Form>
        </Modal>
      </div>
      <CalendarFooter
        loading={isCalendarUpdating}
        onUpdate={handleUpdateCalendar}
        isModal={isModal}
      />
    </Spin>
  )
}

const CustomDateCellWrapper = ({ children, value, date, events }) => {
  const pastDate = value < moment().toDate()
  const isSameMonth = moment(value).isSame(date, 'month')
  const isDayClicked = moment(value).isSame(date, 'day')
  const today = moment(value).isSame(new Date(), 'day')
  const dayHasSelection = events.find(item =>
    moment(item.start).isSame(moment(value), 'day')
  )
  return React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      pointerEvents: pastDate ? 'null' : 'default',
      backgroundColor: today
        ? '#14b314'
        : isDayClicked
        ? 'blue'
        : dayHasSelection
        ? '#cddeff'
        : pastDate
        ? 'rgba(245, 245, 245, 0.3)'
        : isSameMonth
        ? '#f5f5f5d8'
        : '#F5F5F5'
    }
  })
}

const CalendarHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="shl-calendar-head">
      <div className="calendar-header">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="2.5"
            stroke="black"
          />
          <line
            x1="0.533447"
            y1="5.83337"
            x2="14.9334"
            y2="5.83337"
            stroke="black"
          />
          <rect x="3" y="8" width="2" height="2" fill="black" />
          <rect x="3" y="11" width="2" height="2" fill="black" />
          <rect x="7" y="8" width="2" height="2" fill="black" />
          <rect x="7" y="11" width="2" height="2" fill="black" />
          <rect x="11" y="8" width="2" height="2" fill="black" />
        </svg>

        <span>{t('v3:my_calendar')}</span>
      </div>
      <div>
        <NewHeader />
      </div>
    </div>
  )
}

const CustomDayInMonthView = props => {
  const { date, label, selectedDate, onDrillDown, events } = props

  const today = new Date()
  const isToday = moment(date).isSame(today, 'day')
  const isSameMonth = moment(selectedDate).isSame(date, 'month')
  const isDayClicked = moment(date).isSame(selectedDate, 'day')
  const isPastDate = moment(date).isBefore(today, 'day')
  const dayHasSelection = events.find(
    item => moment(item.start).isSame(moment(date), 'day') && item.is_booked
  )
  return (
    <Text
      className={`dayInMonth ${isSameMonth &&
        !isPastDate &&
        'sameMonthFont'} ${dayHasSelection && 'redDot'} ${(isDayClicked ||
        isToday) &&
        'white-text'}`}
      disabled={isPastDate}
      onClick={onDrillDown}
    >
      {label}
    </Text>
  )
}

const CalendarFooter = props => {
  const { loading, onUpdate, isModal } = props
  return (
    <div className="calendar-footer">
      <Button
        type={isModal ? 'primary' : 'default'}
        shape="round"
        loading={loading}
        onClick={onUpdate}
      >
        {isModal ? 'Save' : 'Update Calendar'}
      </Button>
    </div>
  )
}

const CalendarToolbar = props => {
  const dateFormat = 'YYYY/MM/DD'
  const { date, onDateChange } = props
  const { t } = useTranslation()
  const dayText = moment(date).format('MMMM YYYY')

  const content = (
    <div className="calendar-legend-container">
      <div className="item">
        <div className="box green"></div>
        <span>{t('reports:today')}</span>
      </div>
      <div className="item">
        <div className="box teal"></div>
        <div className="item-text">
          <span>{t('v3:task_day')}</span>
          <p>{t('v3:created_task_day')}</p>
        </div>
      </div>
      <div className="item">
        <div className="box red"></div>
        <div className="item-text">
          <span>{t('v3:event_day')} </span>
          <p>{t('v3:has_booked_coaching')}</p>
        </div>
      </div>
      <div className="item">
        <div className="box blue"></div>
        <div className="item-text">
          <span>{t('v3:active_day')} </span>
          <p>{t('v3:selectable_time_slots')}</p>
        </div>
      </div>
    </div>
  )

  return (
    <div className="datepick-container">
      <>
        {t('general:date')}:{' '}
        <DatePicker
          defaultValue={moment(date || new Date(), dateFormat)}
          format={dateFormat}
          onChange={onDateChange}
          className="month-picker"
          disabledDate={current => {
            return moment().add(-1, 'days') >= current
          }}
        />
      </>
      <div className="datepick-text">{dayText}</div>
      <Popover placement="leftTop" content={content}>
        <Icon type="question-circle" className="datepick-question" />
      </Popover>
    </div>
  )
}

export default Form.create()(withTranslation('calendar')(MyCalendar))
