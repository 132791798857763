import React, { Fragment, useEffect, useState } from 'react'
import {
  PanelItem,
  LessonsBlock,
  HeadWrap,
  CourseContent
} from '../../Courses/View/styled.js'
import { useTranslation } from 'react-i18next'
import { api } from '../../../services'
import { Spin, Button, Icon, message } from 'antd'

import { Head, Tab } from '../../../styles'
import { Lessons } from '../../../styles/blocks'
import { TopPageLink } from '../../../components/index.js'

import Discussion from '../../../components/Discussion'
import Notes from '../../../components/Notes'

import { chatIcon } from '../../../components/Card/images/index.js'
import ReactSVG from 'react-svg'
import EmptyMessage from '../../../components/EmptyMessage/EmptyMessage.jsx'

const { enroll, unEnroll, getSkillById } = api.skillJourney

const ViewSkillJourney = props => {
  const {
    user: {
      info: { id: userId }
    },
    match: {
      params: { id }
    },
    log,
    history
  } = props
  const [isSkillLoading, setIsSkillLoading] = useState(true)
  const [enrolled, setEnrolled] = useState(false)
  const [handlingEnroll, setHandlingEnroll] = useState(false)
  const [skillNotFound, setSkillNotFound] = useState(false)
  const [skillJourney] = useState(true)
  const [skillJourneyId, setSkillJourneyId] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [cover, setCover] = useState('')
  const [courses, setCourses] = useState([])
  const [events, setEvents] = useState([])
  const [scorms, setScorms] = useState([])
  const [mazes, setMazes] = useState([])
  const [videos, setVideos] = useState([])
  const [podcasts, setPodcasts] = useState([])
  const [data, setData] = useState({})

  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      setIsSkillLoading(true)
      const { getDiscussionLog } = props
      if (getDiscussionLog) {
        const type = 'skill'
        getDiscussionLog({ id, type })
      }

      getSkillById(id)
        .then(({ data }) => {
          setTitle(data.title)
          setDescription(data.description)
          setCover(data.cover)
          setIsSkillLoading(false)
          setCourses(
            data.course_list.map(item => ({ ...item, type: 'Course' }))
          )
          setEvents(data.event_list.map(item => ({ ...item, type: 'Event' })))
          setScorms(data.scorm_list.map(item => ({ ...item, type: 'Scorm' })))
          setMazes(data.maze_list.map(item => ({ ...item, type: 'Maze' })))
          setVideos(
            data.collection_list.map(item => ({ ...item, type: 'Video' }))
          )
          setPodcasts(
            data.discussion_room_list.map(item => ({
              ...item,
              type: 'Podcast'
            }))
          )
          setEnrolled(data.hasOwnProperty('enrollment'))
          setData(data)
          setSkillJourneyId(id)
        })
        .catch(err => {
          setSkillNotFound(true)
          setIsSkillLoading(false)
        })
    }

    return () => {
      const { clearNotes, clearDiscussion } = props
      clearNotes()
      clearDiscussion()
    }
  }, [id])

  const handleSkillItem = item => {
    const { id, type } = item
    if (!enrolled) {
      message.error(`Please enroll to take this ${type}`)
      return
    }
    const { history } = props
    switch (type) {
      case 'Course':
        history.push({
          pathname: `/courses/${id}`,
          state: { skillJourney, skillJourneyId }
        })
        break
      case 'Event':
        history.push({
          pathname: `/events/${id}`,
          state: { skillJourney, skillJourneyId }
        })
        break
      case 'Scorm':
        history.push({
          pathname: `/scorm/${id}`,
          state: { skillJourney, skillJourneyId }
        })
        break
      case 'Maze':
        history.push({
          pathname: `/maze-detail/${id}`,
          state: { skillJourney, skillJourneyId }
        })
        break
      case 'Video':
        history.push({
          pathname: `/channel/${id}`,
          state: { skillJourney, skillJourneyId }
        })
        break
      case 'Podcast':
        history.push({
          pathname: `/podcast/${id}/play`,
          state: { skillJourney, skillJourneyId }
        })
        break
      default:
        break
    }
  }

  const getLessonItemClassName = (id, activeLessonId) =>
    id === activeLessonId ? 'active' : null

  const handleEnrollment = () => {
    setHandlingEnroll(true)
    if (enrolled) {
      unEnroll(id).then(res => {
        setHandlingEnroll(false)
        setEnrolled(false)
      })
    } else {
      enroll(id).then(res => {
        setHandlingEnroll(false)
        setEnrolled(true)
      })
    }
  }

  let combinedList = [
    ...courses,
    ...scorms,
    ...mazes,
    ...videos,
    ...podcasts,
    ...events
  ]
  const allItems = combinedList.sort((a, b) => a.order - b.order)
  let unreadDiscussions = 0
  if (log && log[0]) {
    unreadDiscussions +=
      log[0].summary.current_messages - log[0].userLog.messages || 0
    unreadDiscussions +=
      log[0].summary.current_replies - log[0].userLog.replies || 0
    unreadDiscussions +=
      log[0].summary.current_votes - log[0].userLog.votes || 0
  }

  const itemsCount = log && log[0] && log[0].summary.current_messages

  return (
    <Spin spinning={isSkillLoading}>
      {skillNotFound && (
        <EmptyMessage
          title={t('v3:skill_not_found')}
          buttonTitle={t('general:back_to') + ' ' + t('navigation:training')}
          onClickAction={() => history.push('/training')}
        />
      )}
      {!skillNotFound && (
        <>
          <TopPageLink page={t('navigation:training')} path={'/training'} />
          <Fragment>
            <section
              className="section skill-journey-wrapper"
              style={{ paddingBottom: '0' }}
            >
              <HeadWrap style={{ marginBottom: '0 !important' }}>
                <h2 style={{ marginBottom: '0 !important' }}>{title}</h2>
                {/* <h3>{topics}</h3> */}
              </HeadWrap>
            </section>
          </Fragment>
          <Fragment>
            <CourseContent>
              <Tab
                //   activeKey={'1'}
                animated={false}
                //   onChange={App.handleChangeTab}
                style={{ position: 'relative' }}
              >
                <Tab.Panel
                  tab={t('v2:skill_journey_overview')}
                  key="course_overview"
                >
                  <PanelItem>
                    <Head>
                      <PanelItem.Title>{title}</PanelItem.Title>
                    </Head>
                    <PanelItem.Content>
                      <PanelItem.Description>
                        {description}
                      </PanelItem.Description>
                      <PanelItem.Image
                        src={cover ? cover.resizes.medium_large : ''}
                      />
                    </PanelItem.Content>
                  </PanelItem>
                </Tab.Panel>
                <Tab.TabPane
                  tab={`${t('tabs:discussion')} 
              (${isSkillLoading ? '...' : isNaN(itemsCount) ? 0 : itemsCount})`}
                  key="discussion"
                >
                  <Discussion
                    discussionId={id}
                    discussionType={'skill'}
                    userId={userId}
                  />
                </Tab.TabPane>
                <Tab.TabPane tab={t('tabs:notes')} key="notes">
                  <Notes noteId={id} noteType={'skill'} userId={userId} />
                </Tab.TabPane>
                <div className="card__wrapper" tab={''} key="log">
                  {unreadDiscussions > 0 && (
                    <div className="card__log_wrapper">
                      <ReactSVG src={chatIcon} className="card__log" />
                      <span className="card__log_unread">
                        {unreadDiscussions >= 10 ? '9+' : unreadDiscussions}
                      </span>
                    </div>
                  )}
                </div>
              </Tab>
              <LessonsBlock>
                <Lessons>
                  <Lessons.Count>
                    {allItems.length} {t('v3:items').toLowerCase()}
                  </Lessons.Count>
                  <Lessons.List>
                    {allItems.map((skillItem, index) => {
                      const { id, cover, title } = skillItem
                      // const img = getThumbnail(cover)
                      // const percent = App.getPercent(course)

                      return (
                        <Lessons.Item
                          key={id}
                          onClick={() => handleSkillItem(skillItem)}
                          className={getLessonItemClassName(id)}
                          style={{ flexDirection: 'column' }}
                        >
                          <div className="wrap-lesson-info">
                            <Lessons.ItemNumber>{index + 1}</Lessons.ItemNumber>
                            <Lessons.ItemCover
                              cover={cover ? cover.resizes.medium_large : ''}
                            />
                            <Lessons.ItemTitle>{title}</Lessons.ItemTitle>
                          </div>
                        </Lessons.Item>
                      )
                    })}
                  </Lessons.List>
                </Lessons>
              </LessonsBlock>
            </CourseContent>
          </Fragment>

          <div className="p-footer">
            <div className="p-footer__inner">
              <div className="p-footer__item">
                <Button
                  onClick={handleEnrollment}
                  loading={handlingEnroll}
                  type="primary"
                  className="rounded"
                  size="large"
                  disabled={data && data.host && data.host.id === userId}
                >
                  {enrolled ? t('buttons:unenroll') : t('buttons:enroll')}
                  <Icon type="right" />
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Spin>
  )
}

export default ViewSkillJourney
