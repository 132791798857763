import http from './http'

function add (data) {
  return http({
    method: 'POST',
    url: '/popups',
    data
  })
}

function getAll () {
  return http({
    method: 'GET',
    url: '/popups'
  })
}

function getById (id) {
  return http({
    method: 'GET',
    url: '/popups/' + id
  })
}

function updateById (id, data) {
  return http({
    method: 'PUT',
    url: '/popups/' + id,
    data
  })
}

function deleteById (id) {
  return http({
    method: 'DELETE',
    url: '/popups/' + id
  })
}

export {
  add,
  getAll,
  getById,
  updateById,
  deleteById
}
