import React, { Component } from 'react'
import { Prompt } from 'react-router-dom'
import './style.scss'
import { WebRTCAdaptor } from './webrtcAdapter'
import { api } from '../../../../../services'
import { config } from '../../../../../constants'
import { Row, Radio } from 'antd'
import { Card } from '../../../../../components/Card/styled'
import '../../../../../components/Card/Card.scss'
import { NetworkDetection } from '../NetworkDetection'

let autoRepublishEnabled = true
let autoRepublishIntervalJob = null
let pcConfig = null
let sdpConstraints = {
  OfferToReceiveAudio: false,
  OfferToReceiveVideo: false
}

let mediaConstraints = {
  video: {
    width: 640,
    height: 360,
    frameRate: 15
  },
  audio: true
}

let webRTCAdaptor = null
let textValue = {}
let prompt = null
let token = ''
let video = null

class AntMediaService extends Component {
  SOCKET_URL_PUBLISH = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com/WebRTCAppEE/websocket?rtmpForward=undefined`
  initSocket = null
  state = {
    showMsg: false,
    streamId: this.props.userId + '_' + this.props.eventId,
    streamMsg: '',
    selectedOption: 'shareCamera',
    messages: {},
    hasFinished: false,
    isScreenShareSupport: false,
    disableRadio: true,
    showVideo: false,
    broadcastState: null,
    showPrompt: false,
    wserrorMessage: '',
    loading: false,
    showReconnectModal: false,
    showCancelMessage: false,
    webSocketConnect: false
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      props: { isBroadcast, isDisconnected, userId, initSocket, isSocketClose }
    } = this

    const { hasFinished } = this.state

    if (
      prevProps.isDisconnected !== isDisconnected ||
      prevState.hasFinished !== hasFinished
    ) {
      console.log('outside isBroadcast check', isDisconnected)
      if (isDisconnected) {
        console.log('didupdate isBroadcast')
        if (autoRepublishIntervalJob !== null) {
          clearInterval(autoRepublishIntervalJob)
          autoRepublishIntervalJob = null
        }
      } else {
        console.log('didupdate else isBroadcast', hasFinished)
        if (hasFinished) {
          // toggleEventProcessing && toggleEventProcessing()
          if (isSocketClose) {
            initSocket(userId)
          }
          this.initWebRTCAdaptor(true, autoRepublishEnabled)
        }
      }
    }

    if (prevProps.isBroadcast !== isBroadcast) {
      if (isBroadcast) {
        this.setState({ showVideo: true }, () => {
          this.handleWSConnection()
        })
      }

      if (!isBroadcast) {
        this.setState({ showVideo: false, showPrompt: false }, () => {
          this.stopPublishing()
        })
      }
    }

    video = document.getElementById('localVideo')

    if (video) {
      if (!video.muted) {
        video.volume = 0.0
        // video.play()
      }
      video.onvolumechange = () => {
        video.volume = 0.0
      }
    }
  }

  componentWillUnmount() {
    if (this.state.showVideo) {
      this.setState({ showPrompt: false })
      prompt = null
      video = null
      this.stopPublishing()
    }
    if (this.connectionInterval) {
      clearInterval(this.connectionInterval)
    }
  }

  handleWSConnection = () => {
    const { toggleEventProcessing } = this.props
    this.setState({ loading: true, showPrompt: true }, () => {
      toggleEventProcessing && toggleEventProcessing()
      this.initWebRTCAdaptor(false, autoRepublishEnabled)
    })
  }

  startPublishing = () => {
    const { streamId } = this.state
    const { eventId, setHostId } = this.props
    console.log('publish strm', streamId)

    api.events
      .startLiveEvent(eventId)
      .then(() => {
        this.setState(
          {
            disableRadio: false
          },
          () => {
            this.startStreaming()
            setHostId(streamId)
            webRTCAdaptor.publish(streamId, token)
          }
        )
      })
      .catch(err => {
        console.log('error in starting event test', err)
      })
  }

  stopPublishing = () => {
    console.log('stopPublishing ')
    const { streamId } = this.state
    const { eventId } = this.props
    if (autoRepublishIntervalJob !== null) {
      clearInterval(autoRepublishIntervalJob)
      autoRepublishIntervalJob = null
    }
    if (this.initSocket !== null) {
      console.log('this.initSocket !== null')
      this.stopStreaming()
      webRTCAdaptor.stop(streamId)
      this.initSocket.close()
    }
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        console.log('api response', res)
        this.setState({
          disableRadio: true
        })
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }

  startStreaming = () => {
    const { streamId } = this.state
    const { eventId, userId, userOrg } = this.props
    this.initSocket = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )

    this.initSocket.onopen = () => {
      this.initSocket.send(
        JSON.stringify({
          action: 'startConference',
          stream_id: `event_${eventId}`,
          channel_type: 'EVENT',
          channel_id: eventId
        })
      )
      this.initSocket.send(
        JSON.stringify({
          action: 'startStream',
          stream_id: streamId,
          channel_type: 'EVENT',
          channel_id: eventId
        })
      )
      this.initSocket.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: userOrg
        })
      )
      this.connectionInterval = setInterval(() => {
        this.initSocket.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 20000)
    }

    this.initSocket.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(this.connectionInterval)
    }

    this.initSocket.onerror = error => {
      console.log('Error ' + error.message)
      this.stopStreaming()
      clearInterval(this.connectionInterval)
    }
  }

  stopStreaming = () => {
    const { streamId } = this.state
    const { eventId, userId, userOrg } = this.props
    this.initSocket.send(
      JSON.stringify({
        action: 'stopStream',
        stream_id: streamId,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
    this.initSocket.send(
      JSON.stringify({
        action: 'stopConference',
        stream_id: `event_${eventId}`,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
    this.initSocket.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: userOrg
      })
    )
  }

  switchMode = chbx => {
    const { streamId } = this.state
    const { value } = chbx
    if (value === 'shareCamera') {
      this.setState(
        {
          selectedOption: value
        },
        () => {
          webRTCAdaptor.switchVideoCapture(streamId)
        }
      )
    } else if (value === 'shareScreen') {
      this.setState(
        {
          selectedOption: value
        },
        () => {
          webRTCAdaptor.switchDesktopCapture(streamId)
        }
      )
    } else if (value === 'shareCameraWithScreen') {
      this.setState(
        {
          selectedOption: value
        },
        () => {
          webRTCAdaptor.switchDesktopCaptureWithCamera(streamId)
        }
      )
    } else {
      chbx.checked = true
    }
  }

  checkAndRepublishIfRequired = () => {
    const { streamId } = this.state
    const iceState = webRTCAdaptor.signallingState(streamId)
    if (
      iceState === null ||
      iceState === 'failed' ||
      iceState === 'disconnected'
    ) {
      console.log('Publish has stopped and will try to re-publish')
      webRTCAdaptor.stop(streamId)
      webRTCAdaptor.closePeerConnection(streamId)
      webRTCAdaptor.closeWebSocket()
      this.initWebRTCAdaptor(true, autoRepublishEnabled)
    }
  }

  // startAnimation() {
  //   const { streamId } = this.props
  //   const state = webRTCAdaptor.signallingState(streamId)
  //   this.setState({ broadcastState: state })
  //   console.log('state of signal', state)
  // }

  initWebRTCAdaptor(publishImmediately, autoRepublishEnabled) {
    const { streamId } = this.state
    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.SOCKET_URL_PUBLISH,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      debug: true,
      bandwidth: 200,
      streamId: streamId,
      callback: (info, obj) => {
        console.log('in state call', info, obj)
        if (info === 'initialized') {
          console.log('initialized')
          if (publishImmediately) {
            webRTCAdaptor.publish(streamId, token)
          } else {
            console.log('else initialized')
            this.startPublishing()
          }
        } else if (info === 'publish_started') {
          console.log('publish started')
          const {
            toggleEventProcessing,
            calculateStopTime,
            calculateWarnTime
          } = this.props
          this.setState(
            {
              hasFinished: false,
              loading: false,
              webSocketConnect: false
            },
            () => {
              calculateStopTime()
              calculateWarnTime()
              toggleEventProcessing && toggleEventProcessing()
              if (autoRepublishEnabled && autoRepublishIntervalJob === null) {
                autoRepublishIntervalJob = setInterval(() => {
                  this.checkAndRepublishIfRequired()
                }, 3000)
              }
            }
          )
        } else if (info === 'publish_finished') {
          const { isBroadcast } = this.props
          console.log('publish finished')
          console.log('broadcast state', isBroadcast)
          setTimeout(() => {
            if (isBroadcast) {
              console.log('if broadcast state', isBroadcast)
              webRTCAdaptor.closeStream()
              this.setState({
                showPrompt: false,
                hasFinished: true,
                selectedOption: 'shareCamera'
              })
            } else {
              console.log('else broadcast state', isBroadcast)
              webRTCAdaptor.closeStream()
            }
          }, 700)
        } else if (info === 'browser_screen_share_supported') {
          console.log('browser screen share supported')
          this.setState({ isScreenShareSupport: true })
          // browser_screen_share_doesnt_support.style.display = 'none'
        } else if (info === 'screen_share_stopped') {
          this.setState({ selectedOption: 'shareCamera' })
          console.log('screen share stopped')
        } else if (info === 'closed') {
          console.log('Connection closed')
          const { hasFinished } = this.state
          if (!hasFinished) {
            webRTCAdaptor.closeStream()
            this.setState({
              hasFinished: true
            })
          }
          // message.info('Connection closed')
        } else if (info === 'pong') {
        } else if (info === 'refreshConnection') {
          // this.checkAndRepublishIfRequired()
          // message.info('refresh connection')
        } else if (info === 'ice_connection_state_changed') {
          console.log('iceConnectionState Changed: ', JSON.stringify(obj))
        } else if (info === 'updated_stats') {
          console.log(
            'Average outgoing bitrate ' +
              obj.averageOutgoingBitrate +
              ' kbits/sec' +
              ' Current outgoing bitrate: ' +
              obj.currentOutgoingBitrate +
              ' kbits/sec'
          )
        } else if (info === 'data_received') {
          console.log(
            'Data received: ' +
              obj.event.data +
              ' type: ' +
              obj.event.type +
              ' for stream: ' +
              obj.streamId
          )
          return textValue
        } else {
          console.log(info + ' notification received')
        }
      },
      callbackError: (error, message) => {
        const { t } = this.props
        const { webSocketConnect } = this.state
        console.log('error callback: ' + JSON.stringify(error), message)
        let errorMessage = ''
        if (error.isTrusted) {
          console.log('inside  error.isTrusted', error.isTrusted)
          return
        }
        if (typeof message !== 'undefined') {
          errorMessage = message
        }
        // var errorMessage = JSON.stringify(error)
        if (error.indexOf('NotFoundError') !== -1) {
          errorMessage = t('events:media_not_found')
        } else if (
          error.indexOf('NotReadableError') !== -1 ||
          error.indexOf('TrackStartError') !== -1
        ) {
          errorMessage = t('events:media_not_access')
        } else if (
          error.indexOf('OverconstrainedError') !== -1 ||
          error.indexOf('ConstraintNotSatisfiedError') !== -1
        ) {
          errorMessage = t('events:constraint_error')
        } else if (
          error.indexOf('NotAllowedError') !== -1 ||
          error.indexOf('PermissionDeniedError') !== -1
        ) {
          errorMessage = 'You are not allowed to access camera and mic.'
        } else if (error.indexOf('TypeError') !== -1) {
          errorMessage = 'Video/Audio is required'
        } else if (error.indexOf('ScreenSharePermissionDenied') !== -1) {
          errorMessage = 'You are not allowed to access screen share'
          this.setState({ selectedOption: 'shareCamera' })
        } else if (error.indexOf('WebSocketNotConnected') !== -1) {
          console.log('WebSocketNotConnected', webSocketConnect)
          if (autoRepublishIntervalJob !== null) {
            clearInterval(autoRepublishIntervalJob)
            autoRepublishIntervalJob = null
          }
          if (!webSocketConnect) {
            this.setState(
              {
                webSocketConnect: true
              },
              () => {
                const { webSocketConnect } = this.state
                if (webSocketConnect) {
                  console.log('webSocketConnect true')
                  // webRTCAdaptor.turnOffCamera()
                  this.initWebRTCAdaptor(true, autoRepublishEnabled)
                  // message.info(
                  //   'Websocket disconnected. trying to connect automatically'
                  // )
                }
              }
            )
          }
        } else if (error.indexOf('streamIdInUse') !== -1) {
          console.log('streamIdInUse')
        } else {
          errorMessage = t('general:something_went_wrong')
        }
        // Object.keys(errorMessage).length > 0 && alert(errorMessage)
        console.log('errorMessage: ', errorMessage)
      }
    })
  }

  render() {
    const {
      selectedOption,
      showVideo,
      disableRadio,
      showPrompt,
      loading,
      hasFinished
    } = this.state
    const { t } = this.props
    console.log('autoRepublishIntervalJob render', autoRepublishIntervalJob)
    console.log('hasFinished render', hasFinished)
    return (
      <div
        className="div-section"
        style={{ marginBottom: `${showVideo ? '-30px' : '0px'}` }}
      >
        {showVideo && (
          <>
            <Prompt
              when={showPrompt}
              message={() => {
                prompt = window.confirm(t('events:prompt_message'))
                return prompt
              }}
            />
            <Row>
              <video id="localVideo" autoPlay muted controls playsInline />
              {loading && (
                <Card.OverlayPlayer>
                  {t('labels:processing')}
                </Card.OverlayPlayer>
              )}
              {hasFinished && (
                <Card.OverlayPlayer>{t('v2:connecting')}</Card.OverlayPlayer>
              )}
            </Row>
            <Row>
              <Radio.Group
                onChange={e => {
                  this.switchMode(e.target)
                }}
                value={selectedOption}
              >
                <Radio value="shareCamera">{t('buttons:camera')}</Radio>
                <Radio value="shareScreen" disabled={disableRadio}>
                  {t('buttons:screen')}
                </Radio>
                <Radio value="shareCameraWithScreen" disabled={disableRadio}>
                  {t('buttons:screen_with_camera')}
                </Radio>
              </Radio.Group>
              <a
                id="browser_screen_share_doesnt_support"
                href="https://caniuse.com/#search=getDisplayMedia"
                style={{ display: 'none' }}
              >
                {t('events:browser_unsupportive_message')}
              </a>
            </Row>
          </>
        )}
      </div>
    )
  }
}
export default NetworkDetection(AntMediaService)
