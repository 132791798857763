import { getSession } from '../../user'

export default (instance) => {
  instance.interceptors.request.use(function(config) {
    return getSession()
      .then(session => {
        config.headers.Authorization = 'Bearer ' + session.idToken.jwtToken
        return Promise.resolve(config)
      })
      .catch(() => {
        return Promise.resolve(config)
      })
  })
}
