import React, { Component } from 'react'
import { connect } from 'react-redux'
import Routes from './routes'
import { Page } from './styled'
import actions from '../../store/actions'
import Menu from '../../components/Menu'
import { withTranslation } from 'react-i18next'
import './style.scss'
import { TopPageLink } from '../../components'

class HelpCenter extends Component {
  render() {
    const { t } = this.props
    const menuItems = [
      {
        url: '/help-center',
        exact: true,
        heading: t('menu.help_center')
      },
      {
        url: '/help-center/whats-new',
        exact: true,
        heading: t('menu.whats_new')
      },
      {
        url: '/help-center/feedback',
        exact: true,
        heading: t('menu.feedback')
      },
      {
        url: '/help-center/terms-and-conditions',
        exact: true,
        heading: t('menu.terms_and_conditions')
      },
      {
        url: '/help-center/privacy-policy',
        exact: true,
        heading: t('menu.privacy_policy')
      },
      {
        url: '/help-center/contact-us',
        exact: true,
        heading: t('menu.contact_us')
      }
    ]
    return (
      <div className="help-center-wrap">
        <Page>
          <div className="help-center-top">
            <TopPageLink page="Dashboard" path="/" />
          </div>
          <Menu items={menuItems} />
          <Routes />
        </Page>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch => ({
  updateUser: (id, data, dbData) =>
    dispatch(actions.user.updateUser(id, data, dbData)),
  upload: (file, opts) => dispatch(actions.library.upload(file, opts))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('help_center')(HelpCenter))
