import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Form, Button, Select, Input, Icon, Checkbox, Alert, Modal } from 'antd'
import { withTranslation } from 'react-i18next'
import './AddMediaDetail.scss'
import { api } from '../../../../services'
import {
  updateLibraryStatus,
  updateMedia
} from '../../../../services/api/library'
import { general } from '../../../../constants'
import CreatableSelect from 'react-select/creatable'
import AiLanguageSelect from '../../../../components/AiLanguageSelect'

const { Option } = Select
const { TextArea } = Input

class AddMediaDetail extends Component {
  static propTypes = {
    topicList: PropTypes.array,
    isLoadingTopic: PropTypes.bool,
    isRemovingMedia: PropTypes.bool,
    mediaRemoved: PropTypes.bool,
    ctrlHandleCancel: PropTypes.func,
    ctrlHandleRemove: PropTypes.func,
    keywords: PropTypes.array
  }

  static defaultProps = {
    topicList: {},
    isLoadingTopic: false,
    isRemovingMedia: false,
    mediaRemoved: false
  }

  constructor(props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.state = {
      checked: false,
      topics: [],
      keywords: '',
      description: '',
      isLoading: false,
      saveLabel: this.props.t('buttons:save'),
      disableButton: false,
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false,
      isAiEnabled: false,
      isLanguageEnabled: false,
      ai_language: 'en-US',
      publish_to_channel: false,
      loadingChannels: false,
      channelsData: [],
      selectedChannelId: null,
      disableAiButton: false,
      isRemoveModalVisible: false,
      isCancelModalVisible: false
    }
  }

  handleShowRemoveModal = () => {
    this.setState({ isRemoveModalVisible: true })
  }
  handleCancelRemove = () => {
    this.setState({ isRemoveModalVisible: false })
  }
  handleConfirmRemove = () => {
    this.setState({ isRemoveModalVisible: false })
    this.props.ctrlHandleRemove()
  }

  handleShowCancelModal = () => {
    this.setState({ isCancelModalVisible: true })
  }

  handleCancelCancel = () => {
    this.setState({ isCancelModalVisible: false })
  }

  handleConfirmCancel = () => {
    this.setState({ isCancelModalVisible: false })
    this.props.ctrlHandleCancel()
  }

  enableSaveButton = () => {
    const { t } = this.props
    this.setState({
      saveLabel: t('buttons:save'),
      disableButton: false
    })
  }

  // handleChangeTopic = value => {
  //   const topicList = Object.assign([], this.props.topicList)
  //   const topicId = _.filter(topicList, item => {
  //     return item.title === value
  //   })

  //   this.setState({ topics: [topicId[0].id] })
  //   // Show save button
  //   this.enableSaveButton()
  // }

  handleChangeKeyword = value => {
    const keywords = value.join(',')
    this.setState({ keywords })
    // Show save button
    this.enableSaveButton()
  }

  handleChangeDescription = e => {
    const description = e.target.value
    this.setState({ description })
    // Show save button
    this.enableSaveButton()
  }

  handleEnableAi = e => {
    const isChecked = e.target.checked
    this.setState(preState => {
      return {
        isAiEnabled: !preState.isAiEnabled,
        isLanguageEnabled: !preState.isLanguageEnabled,
        disableButton: isChecked
      }
    })
    // Show save button
    this.enableSaveButton()
  }

  handleSubmit = async e => {
    e.preventDefault()
    const { form, mediaId, t } = this.props
    const { isScorm, scormExtractedMedia, user } = this.props
    const {
      value,
      description,
      keywords,
      isAiEnabled,
      ai_language,
      publish_to_channel,
      selectedChannelId
    } = this.state

    form.validateFields(async err => {
      if (!err) {
        this.setState({ isLoading: true })

        const {
          info: { is_super_admin, role }
        } = user
        const isAdmin = is_super_admin || (role && role.name !== 'User')

        const data = {
          topics: _.map(value, 'id'),
          description,
          keywords,
          isAiEnabled,
          ai_language,
          ...(publish_to_channel && { collection_id: selectedChannelId })
        }

        if (!isAdmin) {
          delete data.collection_id
        }

        if (isScorm) {
          data.mediaIds = scormExtractedMedia.map(item => item.id)
          const libraryStatusRes = await updateLibraryStatus(data, {
            isScorm: true
          })
          this.handleResponse(libraryStatusRes.data)
          return
        }

        const mediaRes = await updateMedia(mediaId, data)
        this.handleResponse(mediaRes.data)
      }
    })
  }

  handleResponse = res => {
    this.setState({
      isLoading: false,
      saveLabel: this.props.t('buttons:saved'),
      isAiEnabled: res.is_ai_enabled,
      ai_language: res.ai_language || this.state.ai_language,
      isLanguageEnabled: res.is_ai_enabled,
      disableButton: true,
      disableAiButton: res.is_ai_enabled
    })
  }

  handleDisableButton = id => {
    const { isScorm, isUploading } = this.props
    if (isScorm && isUploading) {
      return 'hidden'
    }
    return id ? '' : 'hidden'
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableSaveButton()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableSaveButton()
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        console.log()
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.enableSaveButton()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  handleAiLanguage = value => {
    this.setState(
      {
        ai_language: value
      },
      () => {
        this.enableSaveButton()
      }
    )
  }

  handlePublishToChannel = value => {
    this.setState(
      prevState => ({
        publish_to_channel: !prevState.publish_to_channel,
        loadingChannels: true,
        ...(value === false && {
          selectedChannelId: null
        })
      }),
      () => {
        if (this.state.publish_to_channel) {
          this.fetchChannels()
        }
        this.enableSaveButton()
      }
    )
  }

  fetchChannels = () => {
    api.channel.listChannels({ mode: 'workshop' }).then(({ data }) => {
      this.setState({
        channelsData: data,
        loadingChannels: false
      })
    })
  }

  handleChannelSelect = channelId => {
    this.setState(
      {
        selectedChannelId: channelId
      },
      () => {
        this.enableSaveButton()
      }
    )
  }

  render() {
    const {
      t,
      mediaId,
      status,
      isRemovingMedia,
      mediaRemoved,
      fileType,
      user,
      isLibrary,
      isScorm,
      isChannel = false,
      scormExtractedMedia = [],
      isUploadComplete
    } = this.props
    const {
      isLoading,
      saveLabel,
      disableButton,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      isLanguageEnabled,
      publish_to_channel,
      loadingChannels,
      channelsData
    } = this.state
    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom: isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      placeholder: provided => ({
        ...provided,
        color: '#BFBFBF'
      })
    }

    console.log({ checkStatus: status })

    return (
      <Form onSubmit={this.handleSubmit}>
        <div key={this.props.ind} className="upload-form">
          {/* <div style={{marginBottom: '4px', fontSize: '1.2rem'}}>{t('labels:topics').toUpperCase()}</div> */}
          <CreatableSelect
            styles={customStyles}
            components={{
              ClearIndicator: null,
              DropdownIndicator: null,
              LoadingIndicator: this.LoadingIndicator
            }}
            isMulti
            placeholder={t('labels:topics')}
            inputId="react-select-2-input"
            isLoading={isTopicLoading}
            menuIsOpen={this.isModalSelectOpen()}
            onBlur={() => this.setState({ inputValue: '' })}
            onChange={this.handleChange}
            onCreateOption={this.handleCreate}
            onInputChange={this.loadOptions}
            options={options}
            value={value}
          />

          {isTopicsMax && (
            <div className="error_message">{t('errors:topics_max')}</div>
          )}

          <Select
            disabled={mediaRemoved}
            mode="tags"
            placeholder={t('placeholders:keyword')}
            style={{ width: '100%' }}
            className="custom-select-tag"
            dropdownClassName="disable-select-dropdown"
            tokenSeparators={[',', '.', ';']}
            onChange={this.handleChangeKeyword}
          />
          <div className="upload-form__textarea">
            <TextArea
              disabled={mediaRemoved}
              placeholder={t('labels:description')}
              autoSize={{ minRows: 3, maxRows: 6 }}
              onChange={this.handleChangeDescription}
              maxLength={500}
            />
            <label>{t('placeholders:max_500_characters')}</label>
          </div>
          <div className="upload-form__checkbox__container">
            {isLibrary &&
              user &&
              user.info &&
              user.info.organization &&
              user.info.organization.enable_ai &&
              ((user.info.role && user.info.role.name !== 'User') ||
                user.info.is_super_admin) &&
              (fileType === 'text' ||
                fileType === 'application' ||
                fileType === 'video' ||
                fileType === 'audio') && (
                <Checkbox
                  checked={this.state.isAiEnabled}
                  disabled={this.state.disableAiButton}
                  onChange={this.handleEnableAi}
                >
                  {t('v4:ai')}
                </Checkbox>
              )}
            {isLanguageEnabled && (
              <AiLanguageSelect
                disabled={this.state.disableAiButton}
                handleAiLanguage={this.handleAiLanguage}
              />
            )}

            {fileType === 'video' && isLanguageEnabled && (
              <Alert
                message="AI processing of the video will take about 24 hours to complete and reflect on Sharebot."
                type="info"
              />
            )}

            {fileType === 'video' && !isChannel && (
              <Checkbox onChange={this.handlePublishToChannel}>
                {t('v3:publish_to_channel')}
              </Checkbox>
            )}

            {publish_to_channel && (
              <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp="children"
                placeholder="Select Channel"
                onChange={e => this.handleChannelSelect(e)}
                loading={loadingChannels}
              >
                {channelsData.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.title}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {status !== general.CANCELED && (
            <Fragment>
              <Form.Item
                className={`visible-button ${this.handleDisableButton(
                  mediaId
                )}`}
              >
                <Button
                  className="cancel-button"
                  type="danger"
                  // onClick={ctrlHandleCancel}
                  onClick={this.handleShowCancelModal}
                >
                  {t('buttons:cancel')}
                </Button>
                <Modal
                  title="Confirm Cancel"
                  visible={this.state.isCancelModalVisible}
                  onOk={this.handleConfirmCancel}
                  onCancel={this.handleCancelCancel}
                >
                  <p>{t('v3:are_you_cancel')}</p>
                </Modal>
              </Form.Item>
              <Form.Item
                className={`visible-button ${this.handleDisableButton(
                  mediaId
                )}`}
              >
                <Button
                  className="remove-button"
                  shape="round"
                  type="danger"
                  disabled={mediaRemoved}
                  loading={isRemovingMedia}
                  // onClick={ctrlHandleRemove}
                  onClick={this.handleShowRemoveModal}
                >
                  {mediaRemoved && t('buttons:removed')}
                  {!mediaRemoved && t('buttons:remove')}
                </Button>
                <Modal
                  title="Confirm Media Removal"
                  visible={this.state.isRemoveModalVisible}
                  onOk={this.handleConfirmRemove}
                  onCancel={this.handleCancelRemove}
                >
                  <p>{t('v3:are_you_remove_media')}</p>
                </Modal>
                <Button
                  className="save-button"
                  type="primary"
                  shape="round"
                  htmlType="submit"
                  disabled={disableButton || mediaRemoved}
                  loading={isLoading}
                >
                  {saveLabel}
                </Button>
              </Form.Item>
            </Fragment>
          )}
        </div>
      </Form>
    )
  }
}

export default Form.create()(withTranslation()(AddMediaDetail))
