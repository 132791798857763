import React, { Component } from 'react'
import { Modal } from '../../../../../components'

import api from '../../../../../services/api'
import { withTranslation } from 'react-i18next'
import {ScormPlayer} from '../SubComponent'

class ModalScormContent extends Component {
  state = {
    modalWidth: 789,
    height: 0,
  }

  
  componentWillUnmount() {
    window.removeEventListener('resize', this.doResize)
    this.postCheckOutData();
  }
  
  postCheckOutData = (param) => {
    const { userToken, course: { id } } = this.props

    const data = {
      user_token: userToken
    }

    api.courses
      .checkOutCourse(id, data)
      .then(({ data }) => {
        console.log('response log checkout', data)
      })
      .catch(e => {
        console.log('response log checkout error', e)
      })
  }

  handleExpandModal = () => {
    this.setState({
      modalWidth: '100%',
      height: '100vh'
    })
    return
  }

  handleCollapseModal = () => {
    this.setState({
      modalWidth: 789,
      height: 0
    })
    return
  }

  render() {
    const {
      toggleModal,
      modalVisible,
    } = this.props
    const {modalWidth, height} = this.state

    console.log('in view mode', modalWidth, height)
    return (
      <Modal
        width={789}
        visible={modalVisible}
        onCancel={() => toggleModal()}
        destroyOnClose
      >
        <ScormPlayer />
      </Modal>
    )
  }
}

export default withTranslation('coursepage')(ModalScormContent)
