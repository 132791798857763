import React, { Component } from 'react'
import './styles.scss'

import { Spin, Icon } from 'antd'

import GalleryIcon from '../../../../../../assets/gallery_icon.png'
import AvatarIcon from '../../../../../../assets/avatarIcon2.png'
import SoundIcon from '../../../../../../assets/sound_icon.png'

export default class MazeRightPanel extends Component {
  render() {
    const {
      audio,
      onAddVoice,
      background,
      onAddBackground,
      isBackgroundLoading,
      mazeAvatar,
      backgroundError,
      onAvatarChange
    } = this.props
    return (
      <div className="maze_right">
        <div className="maze_right_bottom">
          <div
            className="maze_action column"
            style={{
              backgroundImage: background
                ? `url('${background.link}')`
                : 'none',
              borderColor: backgroundError ? 'red' : '#d7d4d4'
            }}
            onClick={() => onAddBackground()}
          >
            {isBackgroundLoading && <Spin spinning={true} />}
            {!isBackgroundLoading && (
              <>
                <img alt="" src={GalleryIcon} style={{ objectFit: 'cover' }} />
                <span
                  style={{
                    color: background ? 'white' : ' #136cfb',
                    backgroundColor: background ? '#136cfb' : 'none'
                  }}
                >
                  {background ? 'Background added' : 'Add Background'}
                </span>
              </>
            )}
          </div>
          <br />
          <div
            className="maze_action column"
            style={{
              backgroundImage: mazeAvatar
                ? `url('${mazeAvatar.link}')`
                : 'none',
              borderColor: backgroundError ? 'red' : '#d7d4d4'
            }}
            onClick={() => onAvatarChange()}
          >
            {isBackgroundLoading && <Spin spinning={true} />}
            {!isBackgroundLoading && (
              <>
                <img alt="" src={AvatarIcon} style={{ objectFit: 'cover', marginTop: '4px', marginBottom: '-8px' }} height="80px" width="80px" />
                <span
                  style={{
                    color: background ? 'white' : ' #136cfb',
                    backgroundColor: background ? '#136cfb' : 'none'
                  }}
                >
                  {mazeAvatar ? 'Avatar added' : 'Select Avatar'}
                </span>
              </>
            )}
          </div>
          <br />
          <div
            className={`maze_action column ${background ? '' : 'div_disabled'}`}
            onClick={() => (background ? onAddVoice() : null)}
          >
            {audio ? (
              <Icon
                type="check-circle"
                style={{ color: '#136cfb', fontSize: '30px' }}
              />
            ) : (
              <img alt="" src={SoundIcon} />
            )}
            <span style={{ textAlign: 'center' }}>
              {audio ? `Audio added \n (${audio.toUpperCase()})` : 'Add audio'}
            </span>
          </div>
        </div>
      </div>
    )
  }
}
