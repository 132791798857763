import React, { Fragment, useEffect, useState } from 'react'
import { CustomTabPane, HeaderTabs } from '../../styled.js'
import { Tabs, Icon, Row, Col, message, Tooltip, Spin } from 'antd'
import { generateCSV } from '../../../../utils'
import '../../Report/style.css'
import { useTranslation } from 'react-i18next'
import ReportHeader from '../../components/ReportHeader.jsx'
import TypeSelect from '../../components/TypeSelect.jsx'
import InfoIcon from '../../../../assets/info_icon.png'
import CategoryChart from '../../components/CategoryChart/CategoryChart.jsx'
import CategoryGraph from '../../components/CategoryGraph/CategoryGraph.jsx'
import { api } from '../../../../services'
import { isSafari } from 'react-device-detect'

const { TabPane } = Tabs

const Details = props => {
  useEffect(() => {
    getData()
  }, [])
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [trivia_quiz_categories, setPiecartData] = useState({ total: [] })
  const [trivia_user_quiz_attempts_categories, setBarChartData] = useState({
    total: []
  })
  const getData = async () => {
    setLoading(true)
    let query = {
      type: 'workshop',
      search: '',
      date: null,
      from: null,
      to: null
    }
    await api.reports
      .getReport(query)
      .then(({ data }) => {
        setPiecartData(data.trivia_quiz_categories)
        setBarChartData(data.trivia_user_quiz_attempts_categories)
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        message.error('ShareSphere report fetch failed!')
      })
  }
  const callbackTabClicked = (key, event) => {
    if (key === '0') {
      props.history.push(`/reports`)
    } else if (key === '2') {
      props.history.push('/reports', 'user')
    } else if (key === '3') {
      props.history.push(`/reports`, 'marketplace')
    }
  }
  const download = () => {
    let dataCSV = []
    let header = []
    header = [
      'Category Name',
      'Success Attempts',
      'Failed Attempts',
      'Total Attempts'
    ]
    dataCSV = trivia_user_quiz_attempts_categories.total.map(x => {
      return {
        category: x.name,
        success: x.successAttempts,
        failed: x.failedAttempts,
        total: x.totalAttempts
      }
    })
    if (!isSafari) {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'share_sphere_report.csv')
      document.body.appendChild(ele)
      ele.click()
    } else {
      let ele = document.createElement('a')
      ele.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' +
          encodeURIComponent(generateCSV(dataCSV, header))
      )
      ele.setAttribute('download', 'share_sphere_report.csv')
      ele.click()
    }
  }
  return (
    <Fragment>
      <div className="content-warp">
        <div
          className="report-warp sharelook-table"
          style={{ direction: 'ltr' }}
        >
          <HeaderTabs
            defaultActiveKey="1"
            onTabClick={callbackTabClicked}
            tabBarExtraContent={<ReportHeader download={download} />}
          >
            <TabPane
              tab={
                <CustomTabPane>
                  <Icon type="left" style={{ fontSize: '18px' }} />
                </CustomTabPane>
              }
              key="0"
            />
            <TabPane tab={t('reports:workshop_report')} key="1">
              <TypeSelect defaultValue="sharesphere" />
              <Spin spinning={isLoading}>
                <Row style={{ paddingTop: '3rem' }}>
                  <div className="report-view-wrap">
                    <Col lg={7} sm={24}>
                      <ul className="report-view-item left-short-margin">
                        <li>{t('v3:overview').toUpperCase()}</li>{' '}
                        <Tooltip
                          placement="topLeft"
                          title={
                            'Pie chart of all categories based on their use on sharelook.'
                          }
                          arrow
                        >
                          <img alt="" src={InfoIcon} />
                        </Tooltip>
                      </ul>
                      <CategoryChart graphData={trivia_quiz_categories} />
                    </Col>
                    <Col lg={17} sm={24}>
                      <ul className="report-view-item left-margin">
                        <li>
                          {t('v3:share_sphere')}&nbsp;
                          {t('v3:overviewGraph').toUpperCase()}
                        </li>{' '}
                        <Tooltip
                          placement="topLeft"
                          title={
                            'Total ShareSphere questions answered by the users in the current organization, with these categories and their respective results of success and failures.'
                          }
                          arrow
                        >
                          <img alt="" src={InfoIcon} />
                        </Tooltip>
                      </ul>
                      <CategoryGraph
                        graphData={trivia_user_quiz_attempts_categories}
                      />
                    </Col>
                  </div>
                </Row>
              </Spin>
            </TabPane>
            <TabPane tab={t('reports:user_report')} key="2"></TabPane>
            <TabPane tab={t('v4:marketplace_report')} key="3"></TabPane>
          </HeaderTabs>
        </div>
      </div>
    </Fragment>
  )
}
export default Details
