import React, { Component, createRef } from 'react'
import _ from 'lodash'
import {
  Form,
  Icon,
  Input,
  InputNumber,
  Button,
  Popconfirm,
  Spin,
  Modal,
  message,
  Select,
  Tooltip
} from 'antd'
import uuidv4 from 'uuid/v4'
import { SearchOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove
} from 'react-sortable-hoc'

import api from '../../../../../services/api'
import { config } from '../../../../../constants'
import { general } from '../../../../../constants'
import './CourseCreateMain.scss'

import Badge from './components/Badge'
import Certificate from './components/Certificate'
import ContentLoader from '../../internal/ContentLoader'
import { EditImageContainer } from '../../../../../components/TextOnImage'
import { Row, Col } from '../../../../../styles'
import { Lessons } from '../../../../../styles/blocks'
import {
  FormHeader,
  MarketPlaceTopSection,
  MarketPlaceMidSection,
  MarketPlaceBottomSection
} from './components'
import ShowContactsModal from '../../../../Events/Create/components/internal/ShowContactsModal'
import ImageUpload from '../../../../../components/Core/ImageUpload/ImageUpload'
import ImageUploadModal from '../../../../../components/Core/ImageUpload/ImageUploadModal'
import { FileUpload } from '../../../../../components'
import { languages } from '../../../../../constants'
import AiCourseCreation from '../../../../../components/AiCourseCreation/index.'
import { plusIcon } from './images'
import ReactSVG from 'react-svg'
import { border } from 'styled-system'
import AiCourseForm from '../../../../../components/AiCourseCreation/AiCourseForm'
import history from '../../../../../history'

const { Option } = Select
const { confirm } = Modal

const DragHandle = sortableHandle(() => <div className="b-page__drag" />)

const Page = SortableElement(({ children }) => {
  return <li className="b-pages__item">{children}</li>
})

const Pages = SortableContainer(({ children }) => {
  return <ul className="b-pages">{children}</ul>
})

class CourseCreateMain extends Component {
  search = window.location.search
  params = new URLSearchParams(this.search)
  isMarketPlaceQuery = this.params.get('mpquery')

  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.state = {
      isCover: false,
      aiVisible: false,
      isMarketPlace: false,
      isCoverLoading: props.courseId && true,
      isFinishing: false,
      options: [],
      value: [],
      group: null,
      inputValue: '',
      isTopicLoading: false,
      image: null, // your-image-url or as base64
      file: null,
      isTopicsMax: false,
      newImageUrl: null,
      showTextModal: false,
      selectedContent: '',
      content: '',
      contentHTML: '',
      textPosition: {
        height: 200,
        width: 250,
        x: 23,
        y: 100
      },
      applyChanges: false,
      isImageLoading: false,
      isCertificateLoading: false,
      isBadgeLoading: false,
      openBadgeModal: false,
      openCertificateModal: false,
      fetching: false,
      emails: [],
      emailList: [],
      categories: [],
      categoryList: [],
      allCategories: [],
      showContactModal: false,
      isCreator: true,
      lang: '',
      isCreatingCourseByHuman: true,
      showAiCourseCreationModal: false,
      isFetchingAiContent: false,
      aiContent: null
    }
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.imageRef = createRef()
  }

  componentDidMount() {
    const {
      courseBuilderActions: { getLessonsList },
      courseBuilder: { courseId },
      match: {
        params: { id }
      },
      course: { item, isFetching },
      isMarketPlace: isMarketPlaceQuery
    } = this.props

    this.setState({
      isMarketPlace: this.isMarketPlaceQuery == 'true' ? true : false
    })
    this.fetchUserEmails()
    this.fetchCategories()
    if (id || courseId) {
      getLessonsList(id || courseId)

      if (!_.isEmpty(item)) {
        this.handleOldTopics()
      }
    }
  }

  handleOldTopics = () => {
    const {
      course: { item }
    } = this.props

    if (!item) return
    const { topics, creator_id } = item
    this.getOldTopics(topics)

    if (creator_id !== this.props.user.info.id) {
      this.setState({
        isCreator: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { showTextModal, contentHTML } = this.state
      const div = document.getElementById('showtext')
      if (!showTextModal && contentHTML && div) {
        div.innerHTML = contentHTML
        return div
      }

      const {
        isCourseReducerUpdated,
        courseBuilder: { courseId },
        course: { item },
        match: {
          params: { id }
        }
      } = this.props
      if (prevProps.course.item !== item) {
        this.handleOldTopics()
      }

      if (
        isCourseReducerUpdated &&
        isCourseReducerUpdated !== prevProps.isCourseReducerUpdated
      ) {
        this.showErrorMessageForMarketPlace()
      }

      if (!id && courseId) {
        window.history.replaceState(null, null, `/courses/${courseId}/edit`)
      }
    }
  }

  handleValue = async (field, value) => {
    const { courseBuilderActions } = this.props
    await courseBuilderActions.handleCourseInput(field, value)
    this.handleSubmit()
  }

  handleTitle = _.debounce(v => this.handleValue('courseTitle', v), 1000)
  handleCategory = _.debounce(v => this.handleValue('courseCategory', v), 1000)
  handleSwitch = _.debounce(v => this.handleValue('courseIsFree', v), 1000)
  handlePrice = _.debounce(v => this.handleValue('coursePrice', v), 1000)
  handleVideoLink = _.debounce(
    v => this.handleValue('courseVideoLink', v),
    1000
  )
  handleNumberOfLessons = _.debounce(
    v => this.handleValue('courseNoOfLessons', v),
    1000
  )

  handleNumberOfVideos = _.debounce(v => {
    if (v >= 0) {
      this.handleValue('courseNoOfVideos', v)
    } else {
      console.log('show error')
    }
  }, 1000)

  // handleOrganization = _.debounce(
  //   v => this.handleValue('courseOrganization', v),
  //   1000
  // )
  // handleGroup = _.debounce(v => this.handleValue('courseGroup', v), 1000)

  handleOrganiser = _.debounce(
    v => this.handleValue('courseOrganiser', v),
    1000
  )
  handleTopics = _.debounce(v => this.handleValue('courseTags', v), 1000)
  handleDescription = _.debounce(
    v => this.handleValue('courseDescription', v),
    1000
  )
  handleMarketPlaceDescription = _.debounce(
    v => this.handleValue('courseMarketPlaceDescription', v),
    1000
  )
  handleObjectives = _.debounce(
    v => this.handleValue('courseObjectives', v),
    1000
  )
  handleOutline = _.debounce(v => this.handleValue('courseOutline', v), 1000)
  handlePassScore = _.debounce(
    v => this.handleValue('coursePassScore', v),
    1000
  )

  handleShareProfile = _.debounce(
    v => this.handleValue('courseShareProfile', v),
    1000
  )

  handlePriceToEdit = _.debounce(
    v => this.handleValue('coursePriceToEdit', v),
    1000
  )

  handleEditPermission = _.debounce(
    v => this.handleValue('courseEditPermission', v),
    1000
  )

  handleGroupSelect = val => {
    this.handleGroup(val)
    this.setState({ group: val })
  }

  onDrop = (file, handleAfterUpload, handlebeforeUpload) => {
    const image = URL.createObjectURL(file)
    this.handleAfterUpload = handleAfterUpload
    this.handlebeforeUpload = handlebeforeUpload
    this.setState({
      file,
      image
    })
  }

  useCanva = type => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: type
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle, type)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title, imageType) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    if (imageType === 'Presentation') {
      this.handleUploadImage(body)
    } else {
      this.badgePresentationUpload(body, imageType)
    }
  }

  badgePresentationUpload = (body, imageType) => {
    const { t, upload } = this.props
    this.toggleLoadingState(imageType)
    this.setState({ openBadgeModal: false, openCertificateModal: false })
    upload(body)
      .then(res => {
        const { id } = res
        this.toggleLoadingState(imageType)
        this.handleRewardImageUpload(imageType, id)
      })
      .catch(() => {
        this.toggleLoadingState(imageType)
        message.error(t('v4:error_occured_try_again'))
      })
  }

  handleRewardImageUpload = (type, id) => {
    const { courseBuilderActions } = this.props
    if (type === 'Certificate') {
      courseBuilderActions.handleCourseInput('courseCertId', id)
    } else {
      courseBuilderActions.handleCourseInput('courseBadgeId', id)
    }
    this.handleSubmit()
  }

  toggleLoadingState = type => {
    if (type === 'Certificate') {
      this.setState(state => ({
        isCertificateLoading: !state.isCertificateLoading
      }))
    } else {
      this.setState(state => ({ isBadgeLoading: !state.isBadgeLoading }))
    }
  }

  deleteLesson = (e, lessonId) => {
    e.stopPropagation()

    const {
      courseBuilderActions: { deleteLessonFromCourse },
      courseBuilder: { courseId }
    } = this.props

    deleteLessonFromCourse(courseId, lessonId)
  }

  handleSubmit = e => {
    e && e.preventDefault()
    const { value, group, lang } = this.state

    const {
      form,
      courseBuilderActions: { updateCourse, createCourse },
      course: { item: course },
      courseBuilder: {
        courseImgId,
        courseId,
        isCreation,
        coursePassScore,
        courseCertId,
        courseBadgeId,
        courseEmails,
        courseCategories,
        courseAttachments,
        courseEditPermission
      },
      user: {
        info: { username, bio, linkedin }
      }
    } = this.props
    form.validateFields((err, values) => {
      if (!courseImgId) {
        this.setState({ isCover: true })
        return
      }

      if (isCreation) {
        return
      }

      if (coursePassScore && (coursePassScore < 1 || coursePassScore > 99)) {
        return
      }

      const courseData = {
        ...values,
        cover_id: courseImgId,
        cert_id: courseCertId,
        badge_id: courseBadgeId,
        host_emails: courseEmails,
        categories: courseCategories,
        attachments: courseAttachments
      }
      courseData.topics = _.map(value, 'id')
      courseData.group_id = group
      courseData.lang = lang || course.lang

      if (!courseData.title || !courseData.cover_id) return

      if (courseId) {
        const {
          category_id,
          cover_id,
          group_id,
          is_free,
          cert_id,
          badge_id,
          no_of_lesson,
          no_of_video,
          organiser,
          organization_id,
          price,
          description,
          title,
          topics,
          trainer,
          video_link,
          course_obj,
          course_outline,
          course_description,
          is_shared_profile,
          pass_score,
          attachments,
          priceToEdit,
          isSendMail
        } = courseData

        // price to edit must be lesser than price to buy
        if (priceToEdit > price) return

        const newCourseData = {
          title: title,
          organiser: organiser,
          cover_id: cover_id,
          topics: topics,
          cert_id: cert_id,
          badge_id: badge_id,
          pass_score: pass_score,
          description: description,
          lang: lang || course.lang,
          host_emails: courseEmails,
          categories: courseCategories,
          attachments: attachments,

          marketplace: {
            trainer: trainer,
            username: username,
            category_id: category_id,
            is_free: Boolean(is_free),
            price: Number(price) || 5,
            video_link: video_link,
            no_of_video: Number(no_of_video),
            no_of_lesson: Number(no_of_lesson),
            organization_id: organization_id,
            group_id: group_id,
            course_obj: course_obj,
            course_outline: course_outline,
            course_description: course_description,
            is_shared_profile: Boolean(is_shared_profile),
            price1: !priceToEdit ? 1 : Number(priceToEdit),
            edit_permission: Boolean(courseEditPermission),
            bio,
            linkedin
          },
          isSendMail
        }
        const debouncedUpdate = _.debounce(() => {
          updateCourse(newCourseData, courseId)
        }, 500)

        debouncedUpdate()
        //
      } else {
        createCourse(courseData)
      }
    })
  }

  showErrorMessageForMarketPlace = () => {
    const {
      courseBuilder: {
        courseId,
        courseTitle,
        courseImgId,
        courseOrganiser,
        // courseCategory,
        courseIsFree,
        courseMarketPlaceDescription,
        courseNoOfLessons,
        courseNoOfVideos,
        courseObjectives,
        // courseOrganization,
        courseOutline,
        coursePrice,
        // courseGroup,
        courseVideoLink
      },
      course: { item: course },
      t,
      user: {
        info: { isAdmin }
      }
    } = this.props

    if (!isAdmin || !courseId || course.is_restricted) return

    let data = {
      courseId,
      courseTitle,
      courseImgId,
      courseOrganiser,
      // courseCategory,
      courseIsFree,
      courseMarketPlaceDescription,
      courseNoOfLessons,
      courseNoOfVideos,
      courseObjectives,
      courseOutline,
      ...(!courseIsFree && { coursePrice }),
      courseVideoLink
    }

    let errorData = Object.keys(data).filter(k => {
      if (data[k] === '' || data[k] === undefined || data[k] === null) {
        return true
      } else {
        return false
      }
    })
    if (errorData.length) {
      message.warning(t('v4:not_complete'))
      return true
    } else {
      message.success(t('v4:complete'))
      return false
    }
  }

  finish = isPublish => {
    const { value, lang } = this.state

    const {
      form,
      course: { item: course },
      courseBuilder: {
        courseImgId,
        courseId,
        isCreation,
        courseCertId,
        courseBadgeId,
        courseEmails,
        courseCategories,
        lessons: { list }
      },
      history,
      rootPath
    } = this.props

    const allLessonsHaveCoverId = list.every(lesson => lesson.cover_id)

    if (!allLessonsHaveCoverId) {
      Modal.error({
        title: 'Error ! Please add a cover image for all the lessons.',
        content:
          'Click on the lesson title to visit the lesson and add a cover image.',
        centered: true,
        style: { minWidth: '500px' }
      })

      return
    }

    form.validateFields((err, values) => {
      if (isCreation) {
        return
      }

      const courseData = {
        ...values,
        cover_id: courseImgId,
        cert_id: courseCertId,
        badge_id: courseBadgeId,
        is_finished: true,
        host_emails: courseEmails,
        categories: courseCategories,
        lang: lang || course.lang
      }
      courseData.topics = _.map(value, 'id')

      this.setState({ isFinishing: true })
      return api.courses.courseUpdate(courseData, courseId).then(() => {
        this.setState({ isFinishing: false })
        if (isPublish) {
          history.push(`${rootPath}/publish`)
        } else {
          history.push('/workshop')
        }
      })
    })
  }

  showLessonById = id => {
    const { fetchLessonById, history, rootPath } = this.props

    fetchLessonById(id)
    history.push(`${rootPath}/lesson-create/${id}`)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const {
      courseBuilder: { lessons, courseId },
      reorderLessons
    } = this.props
    const newLessons = arrayMove(lessons.list, oldIndex, newIndex)
    const newOrderIds = newLessons.map(el => el.id)

    if (reorderLessons) {
      reorderLessons(newLessons)
    }

    api.courses.updateLessonsOrderByCourseId(courseId, newOrderIds)
  }

  getOldTopics = topics => {
    const {
      topics: { list: topicList, isFetching }
    } = this.props
    if (!isFetching) {
      const topic =
        topics &&
        topics.map(item => _.head(topicList.filter(topic => topic.id === item)))
      const newValue =
        topic && topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('courseTags', _.map(newValue, 'id'))
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 5) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleSubmit()
          this.handleValue('courseTags', _.map(newValue, 'id'))
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 5) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleSubmit()
              this.handleValue('courseTags', _.map([...value, newOption], 'id'))
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  handleUploadImage = file => {
    const { upload, courseBuilderActions } = this.props
    this.handlebeforeUpload && this.handlebeforeUpload()
    this.setState({
      isCoverLoading: true,
      openBadgeModal: false
    })
    return upload(file)
      .then(res => {
        const { id, link, title, filename } = res
        courseBuilderActions.handleCourseInput('courseImgId', id)
        courseBuilderActions.handleCourseInput('courseImg', link)
        this.handleImageSuccess(res)
      })
      .catch(() => {
        this.handleAfterUpload && this.handleAfterUpload()
      })
  }

  // handleFileInsert = item => {
  //   const imageEditorInst = this.imageRef.current.imageEditorInst
  //   const isEdited = !imageEditorInst.isEmptyUndoStack()
  //   if (isEdited === true) {
  //     // Upload In this Case
  //     const data = imageEditorInst.toDataURL()
  //     const { courseBuilderActions } = this.props
  //     const { id } = item
  //     courseBuilderActions.handleCourseInput('courseImgId', id)
  //     const body = {
  //       croppedImage: data,
  //       lastModifiedDate: new Date(),
  //       name: item.filename,
  //       size: item.file_size,
  //       type: item.media_mime_type
  //     }
  //     this.setState(
  //       {
  //         isImageLoading: true,
  //         modalOpen: false,
  //         selectedItem: null
  //       },
  //       () => {
  //         this.handleUploadImage(body)
  //       }
  //     )
  //     courseBuilderActions.handleCourseInput('courseImg', data)
  //   } else {
  //     // Just Use Data URL
  //     const { courseBuilderActions } = this.props
  //     const { id, link } = item
  //     courseBuilderActions.handleCourseInput('courseImgId', id)
  //     courseBuilderActions.handleCourseInput('courseImg', link)
  //     this.setState(
  //       {
  //         isImageLoading: true,
  //         modalOpen: false,
  //         selectedItem: null
  //       },
  //       () => {
  //         this.handleImageSuccess(item)
  //       }
  //     )
  //   }
  // }

  handleOpenModel = () => {
    const {
      courseBuilder: { courseId }
    } = this.props
    history.push(`/course/${courseId}/course-ai`)
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current
      ? this.imageRef.current.imageEditorInst
      : null

    // Check if imageEditorInst exists before accessing its properties
    const isEdited = imageEditorInst
      ? !imageEditorInst.isEmptyUndoStack()
      : false

    if (isEdited) {
      // Upload the edited image
      const data = imageEditorInst.toDataURL()
      const { courseBuilderActions } = this.props
      const { id } = item

      // Set courseImgId and courseImg in courseBuilder state
      courseBuilderActions.handleCourseInput('courseImgId', id)
      courseBuilderActions.handleCourseInput('courseImg', data)

      const body = {
        croppedImage: data,
        lastModifiedDate: new Date(),
        name: item.filename,
        size: item.file_size,
        type: item.media_mime_type
      }

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Upload the edited image
          this.handleUploadImage(body)
        }
      )
    } else {
      // Use the original image URL
      const { courseBuilderActions } = this.props
      const { id, link } = item

      // Set courseImgId and courseImg in courseBuilder state
      courseBuilderActions.handleCourseInput('courseImgId', id)
      courseBuilderActions.handleCourseInput('courseImg', link)

      this.setState(
        {
          isImageLoading: true,
          modalOpen: false,
          selectedItem: null
        },
        () => {
          // Process the original image URL
          this.handleImageSuccess(item)
        }
      )
    }
  }

  handleImageSuccess = res => {
    const { id, title, filename } = res

    if (id) {
      this.setState(
        {
          isCover: false,
          isCoverLoading: false,
          file: null,
          image: null,
          newImageUrl: null,
          showTextModal: false,
          selectedContent: '',
          content: '',
          contentHTML: '',
          textPosition: {
            height: 200,
            width: 250,
            x: 23,
            y: 100
          },
          applyChanges: false,
          isImageLoading: false,
          currentImageFileName: title || filename
        },
        () => {
          this.handleAfterUpload && this.handleAfterUpload()
        }
      )
    }
    this.handleSubmit()
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
      this.handleAfterUpload && this.handleAfterUpload()
    }
  }

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  handleShowTextModal = () => {
    this.setState({ showTextModal: !this.state.showTextModal })
  }

  onToggleMarketPlace = () => {
    this.setState({ isMarketPlace: !this.state.isMarketPlace }, () => {
      this.showErrorMessageForMarketPlace()
    })
  }

  toggleBadgeModal = () => {
    this.setState(state => ({ openBadgeModal: !state.openBadgeModal }))
  }

  toggleCertificateModal = () => {
    this.setState(state => ({
      openCertificateModal: !state.openCertificateModal
    }))
  }

  toggleContactModal = async () => {
    this.setState(prevState => ({
      showContactModal: !prevState.showContactModal
    }))
  }

  fetchUserEmails = () => {
    const {
      user: {
        info: { active_organization_id }
      }
    } = this.props
    this.setState({ allUsers: [], fetching: true }, () => {
      api.organizations
        .getUsersByOrganizationId(active_organization_id)
        .then(({ data }) => {
          const userData = data && data.filter(item => item.user !== null)

          this.setState({
            allUsers: userData,
            emailList: userData,
            fetching: false
          })
        })
    })
  }
  fetchCategories = async () => {
    const { getCategories } = this.props
    await getCategories().then(data => {
      this.setState({ categoryList: data, allCategories: data })
    })
  }

  simplifyUserList = arr =>
    arr.map(item => ({
      text: item.user.email,
      value: item.user.email,
      id: item.user.id
    }))
  handleUserSearch = value => {
    const { allUsers } = this.state
    const results = allUsers.filter(item =>
      item.user.email.toLowerCase().includes(value.toLowerCase())
    )
    this.setState({
      emailList: value ? results : allUsers
    })
  }
  handleCategorySearch = value => {
    const { allCategories } = this.state
    const results = allCategories.filter(item =>
      item.name.toLowerCase().includes(value.toLowerCase())
    )
    this.setState({
      categoryList: value ? results : allCategories
    })
  }

  handleChangeLang = e => {
    this.setState(
      {
        lang: e
      },
      () => this.handleSubmit()
    )
  }

  handleEmailChange = selectedValue => {
    // if (selectedValue.length > 5)
    //   return
    this.setState(
      prevState => {
        if (prevState.showContactModal) {
          return {
            emails: selectedValue,
            showContactModal: false
          }
        }
        return {
          emails: selectedValue
        }
      },
      () => {
        const {
          form: { setFieldsValue }
        } = this.props
        setFieldsValue({ host_emails: this.state.emails })
        this.handleValue('courseEmails', selectedValue)
        // this.props.App.handleAttendeeEmailChange(this.state.emails)
      }
    )
  }
  handleCategoryChange = _.debounce(selectedValue => {
    this.setState(
      prevState => {
        if (prevState.showContactModal) {
          return {
            categories: selectedValue,
            showContactModal: false
          }
        }
        return {
          categories: selectedValue
        }
      },
      () => {
        const {
          form: { setFieldsValue }
        } = this.props
        setFieldsValue({ categories: this.state.categories })
        this.handleValue('courseCategories', selectedValue)
      }
    )
  }, 1000)

  onFiles = filesArray => {
    const {
      courseBuilder: { courseAttachments = [] },
      courseBuilderActions
    } = this.props
    if (_.isEmpty(filesArray) && _.isEmpty(courseAttachments)) return
    const filesById = filesArray.map(item => item.id)
    courseBuilderActions.handleCourseInput(
      'courseAttachmentDetails',
      filesArray
    )
    filesById && this.handleValue('courseAttachments', filesById)
  }

  onToggleCreatingCourseByHuman = val => {
    this.setState({
      isCreatingCourseByHuman: val
    })
  }

  handleTableOfContentModalClose = hasContentGenerationStarted => {
    if (hasContentGenerationStarted) {
      this.setState({ showAiCourseCreationModal: false })
      return
    }
    confirm({
      title: 'Are you sure you want to close?',
      content: `All your changes will be lost.`,
      onOk: () => {
        this.setState({ showAiCourseCreationModal: false })
      }
    })
  }

  handleOpenSelectLibraryModal = e => {
    if (e) {
      e.stopPropagation()
    }
    this.setState({
      modalOpen: true
    })
  }

  handleOpenCanva = e => {
    if (e) {
      e.stopPropagation()
    }
    this.useCanva('Presentation')
  }

  render() {
    const {
      form: { getFieldDecorator, getFieldValue },
      courseBuilder: {
        courseTitle,
        courseOrganiser,
        courseImg,
        courseImgId,
        lessons,
        courseId,
        coursePassScore,
        courseBadgeId,
        courseCertId,
        courseAttachmentDetails,
        courseEmails,
        courseCategories,
        courseFileName
      },
      course: { isFetching, item: course },
      history,
      rootPath,
      user: {
        info: {
          id: userId,
          isAdmin,
          active_organization_id: activeOrgId,
          is_super_admin: isSuperAdmin,
          organization
        }
      },

      organizations: { rows },
      t,
      form
    } = this.props

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '8px',
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom: isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }
    const groupOptions = rows ? rows.filter(o => o.id === activeOrgId)[0] : {}

    const {
      isCover,
      isCoverLoading,
      isFinishing,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      image,
      file,
      modalOpen,
      selectedItem,
      isMarketPlace,
      isImageLoading,
      isBadgeLoading,
      isCertificateLoading,
      openBadgeModal,
      openCertificateModal,
      showContactModal,
      emailList,
      fetching,
      isCreator,
      lang,
      isCreatingCourseByHuman,
      showAiCourseCreationModal,
      isFetchingAiContent,
      aiContent,
      categoryList,
      currentImageFileName
    } = this.state

    const publishButtonCondition = course.is_restricted
      ? false
      : activeOrgId ||
        isCreator ||
        (course && course.host_ids && course.host_ids.includes(userId)) ||
        isSuperAdmin
    return (
      <Spin className="global_ant_spin" spinning={isFetching}>
        <section className="section" style={{ paddingBottom: '10rem' }}>
          <FormHeader
            courseId={courseId}
            t={t}
            form={form}
            isAdmin={isAdmin}
            isSuperAdmin={isSuperAdmin}
            isMarketPlace={isMarketPlace}
            onToggleMarketPlace={this.onToggleMarketPlace}
            isRestricted={course.is_restricted}
            isCreatingCourseByHuman={isCreatingCourseByHuman}
            onToggleCreatingCourseByHuman={this.onToggleCreatingCourseByHuman}
            isCourseAiEnabled={organization && organization.enable_ai_course}
            isAiGenerated={course.is_ai_generated}
          />

          <Form onSubmit={this.handleSubmit}>
            <div className="create-course">
              <div className="create-course__content">
                <Row>
                  <Col md={isMarketPlace ? 3 : 4}>
                    <Form.Item
                      label={t('labels:title')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('title', {
                        initialValue: courseTitle,
                        rules: [
                          {
                            required: true,
                            message: t('errors:title_course')
                          },
                          {
                            whitespace: true,
                            pattern: '.{3,}',
                            message: t('errors:min_3char')
                          }
                        ]
                      })(
                        <Input
                          size="large"
                          placeholder={t('placeholders:title')}
                          maxLength={100}
                          onChange={e => this.handleTitle(e.target.value)}
                        />
                      )}
                    </Form.Item>
                  </Col>

                  {!isMarketPlace && (
                    <Col md={4}>
                      <Form.Item
                        label={t('labels:organiser')}
                        colon={false}
                        required={false}
                      >
                        {getFieldDecorator('organiser', {
                          initialValue: courseOrganiser
                        })(
                          <Input
                            addonBefore={t('general:by')}
                            size="large"
                            tighter="true"
                            placeholder={t('placeholders:organiser')}
                            onChange={e => this.handleOrganiser(e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  <Col md={isMarketPlace ? 3 : 4}>
                    <div className="marketplace-topic">
                      {t('labels:topics')}
                    </div>
                    <CreatableSelect
                      styles={customStyles}
                      components={{
                        ClearIndicator: null,
                        DropdownIndicator: null,
                        LoadingIndicator: this.LoadingIndicator
                      }}
                      isMulti
                      placeholder={t('v3:add_topic')}
                      inputId="react-select-2-input"
                      isLoading={isTopicLoading}
                      menuIsOpen={this.isModalSelectOpen()}
                      onBlur={() => this.setState({ inputValue: '' })}
                      onChange={this.handleChange}
                      onCreateOption={this.handleCreate}
                      onInputChange={this.loadOptions}
                      options={options}
                      value={value}
                    />

                    {isTopicsMax && (
                      <div className="error_message">
                        {t('errors:topics_max')}
                      </div>
                    )}
                  </Col>
                  {isMarketPlace && (
                    <>
                      <Col md={3}>
                        <Form.Item
                          label={t('v4:pass_score')}
                          colon={false}
                          required={false}
                        >
                          {getFieldDecorator('pass_score', {
                            initialValue: coursePassScore,
                            rules: [
                              {
                                type: 'number',
                                asyncValidator: (rule, value) => {
                                  return new Promise((resolve, reject) => {
                                    if (
                                      (value > 0 && value <= 100) ||
                                      value === undefined ||
                                      value === null
                                    ) {
                                      resolve()
                                    } else {
                                      reject(t('v4:pass_score_mark'))
                                    }
                                  })
                                }
                              }
                            ]
                          })(
                            <InputNumber
                              size="large"
                              tighter="true"
                              style={{ width: '100%' }}
                              onChange={value => this.handlePassScore(value)}
                              placeholder="Minimum Score"
                            />
                          )}
                        </Form.Item>
                      </Col>
                      <Col md={3}>
                        <Form.Item
                          label={t('v4:allow_others')}
                          colon={false}
                          required={false}
                        >
                          {getFieldDecorator('host_emails', {
                            initialValue: courseEmails
                          })(
                            <Select
                              mode="multiple"
                              placeholder={t('placeholders:email')}
                              filterOption={false}
                              onSearch={this.handleUserSearch}
                              onChange={this.handleEmailChange}
                              style={{ width: '100%' }}
                              size="large"
                              tighter="true"
                            >
                              {this.simplifyUserList(emailList).map(d => (
                                <Option key={d.value}>{d.text}</Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                        <span
                          role="button"
                          style={{
                            position: 'relative',
                            top: '-53px',
                            float: 'right',
                            paddingRight: '10px',
                            cursor: 'pointer',
                            height: 0
                          }}
                          onClick={() =>
                            !fetching ? this.toggleContactModal() : null
                          }
                        >
                          {fetching ? (
                            <Icon type="loading" />
                          ) : (
                            <SearchOutlined />
                          )}
                        </span>
                      </Col>
                    </>
                  )}
                </Row>
                {isMarketPlace && (
                  <MarketPlaceTopSection
                    props={this.props}
                    onChangeAuthor={val => this.handleOrganiser(val)}
                  />
                )}
                <Row>
                  <Col md={3}>
                    <Form.Item
                      label={t('v4:pass_score')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('pass_score', {
                        initialValue: coursePassScore,
                        rules: [
                          {
                            type: 'number',
                            asyncValidator: (rule, value) => {
                              return new Promise((resolve, reject) => {
                                if (
                                  (value > 0 && value <= 100) ||
                                  value === undefined ||
                                  value === null
                                ) {
                                  resolve()
                                } else {
                                  reject(t('v4:pass_score_mark'))
                                }
                              })
                            }
                          }
                        ]
                      })(
                        <InputNumber
                          size="large"
                          tighter="true"
                          style={{ width: '100%' }}
                          onChange={value => this.handlePassScore(value)}
                          placeholder="Minimum Score"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={3}>
                    <Form.Item
                      label={t('v4:allow_others')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('host_emails', {
                        initialValue: courseEmails
                      })(
                        <Select
                          mode="multiple"
                          placeholder={t('placeholders:email')}
                          filterOption={false}
                          onSearch={this.handleUserSearch}
                          onChange={this.handleEmailChange}
                          style={{ width: '100%' }}
                          size="large"
                          tighter="true"
                        >
                          {this.simplifyUserList(emailList).map(d => (
                            <Option key={d.value}>{d.text}</Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <span
                      role="button"
                      style={{
                        position: 'relative',
                        top: '-53px',
                        float: 'right',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        height: 0
                      }}
                      onClick={() =>
                        !fetching ? this.toggleContactModal() : null
                      }
                    >
                      {fetching ? <Icon type="loading" /> : <SearchOutlined />}
                    </span>
                  </Col>

                  <Col md={3}>
                    <Form.Item
                      label={t('v4:select_course_language')}
                      colon={false}
                    >
                      <Select
                        placeholder={t('v4:select_language')}
                        // value={languages[5].name}
                        // defaultValue={course.item.lang}
                        value={lang ? lang : course.lang}
                        onChange={e => this.handleChangeLang(e)}
                        style={{ width: '100%' }}
                        size="large"
                        tighter="true"
                      >
                        {languages.map(item => (
                          <Option key={item.key} value={item.code}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={3}>
                    <Form.Item
                      label={t('v3:category')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('categories', {
                        initialValue: courseCategories
                      })(
                        <Select
                          mode="multiple"
                          placeholder={t('v3:phCategory')}
                          filterOption={false}
                          style={{ width: '100%' }}
                          size="large"
                          tighter="true"
                          onSearch={this.handleCategorySearch}
                          onChange={this.handleCategoryChange}
                        >
                          {categoryList.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                    <span
                      role="button"
                      style={{
                        position: 'relative',
                        top: '-53px',
                        float: 'right',
                        paddingRight: '10px',
                        cursor: 'pointer',
                        height: 0
                      }}
                      onClick={() =>
                        !fetching ? this.toggleContactModal() : null
                      }
                    >
                      {fetching ? <Icon type="loading" /> : <SearchOutlined />}
                    </span>
                  </Col>
                </Row>
                <Row className="is-mobile-edit-item">
                  <Col md={12}>
                    <div className="canva-editor-items">
                      <Button
                        className={`item ${courseCertId ? 'icon-check' : ''}`}
                        shape="round"
                        icon={courseCertId ? 'check' : 'plus'}
                        onClick={this.toggleCertificateModal}
                        loading={isCertificateLoading}
                      >
                        {courseCertId
                          ? t('v4:certificate_added')
                          : t('v4:create_certificate')}
                      </Button>
                      <Button
                        className={`item ${courseBadgeId ? 'icon-check' : ''}`}
                        icon={courseBadgeId ? 'check' : 'plus'}
                        onClick={this.toggleBadgeModal}
                        shape="round"
                        loading={isBadgeLoading}
                      >
                        {courseBadgeId
                          ? t('v4:badge_added')
                          : t('v4:create_badge')}
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    <Form.Item
                      label={t('labels:picture')}
                      colon={false}
                      required={false}
                      className="no_bottom_margin"
                    >
                      <ImageUpload
                        cover={courseImgId && courseImg}
                        error={isCover ? 1 : 0}
                        courseFileName={
                          currentImageFileName ||
                          (course.cover &&
                            (course.cover.filename || course.cover.title))
                        }
                        coverFileSize={
                          course.cover &&
                          parseInt(course.cover.file_size / 1024)
                        }
                        disabled={isCoverLoading}
                        loadImage={isImageLoading || isCoverLoading}
                        handleDrop={this.onDrop}
                        handleOpenSelectLibraryModal={e =>
                          this.handleOpenSelectLibraryModal(e)
                        }
                        handleAiImage={({ id, link }) =>
                          this.handleImageUrl(
                            link,
                            'ai_generated_image',
                            'Presentation'
                          )
                        }
                        handleOpenCanva={e => this.handleOpenCanva(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    <FileUpload
                      files={courseAttachmentDetails}
                      onFiles={this.onFiles}
                    />
                  </Col>
                </Row>

                {isMarketPlace && (
                  <MarketPlaceMidSection
                    {...this.props}
                    courseId={courseId}
                    onCategory={this.handleCategory}
                    onSwitch={this.handleSwitch}
                    onPrice={this.handlePrice}
                    onVideoLink={this.handleVideoLink}
                    onNoOfVideo={this.handleNumberOfVideos}
                    onNoOfLessons={this.handleNumberOfLessons}
                    onTrainerLinkedIn={this.handleTrainerLinkedIn}
                    onTrainerBio={this.handleTrainerBio}
                    onPriceToEdit={this.handlePriceToEdit}
                    onEditPermission={this.handleEditPermission}
                  />
                )}
              </div>
              {!isMarketPlace && isCreatingCourseByHuman && (
                <div className="create-course__sidebar">
                  <Lessons>
                    <Lessons.Count left darkBorder>
                      {t('general:lessons')} ({lessons.list.length})
                    </Lessons.Count>

                    <Lessons.List dashed>
                      {!lessons.fetch ? (
                        <>
                          <Pages
                            useDragHandle
                            onSortEnd={this.onSortEnd}
                            helperClass="_drag"
                          >
                            {lessons.list.map((lesson, index) => (
                              <Page key={`item-${index}`} index={index}>
                                <div className="b-page">
                                  <div className="b-page__handler">
                                    <DragHandle />
                                  </div>
                                  <div
                                    className="b-page__info"
                                    onClick={() =>
                                      this.showLessonById(lesson.id)
                                    }
                                  >
                                    <div className="b-page__name">
                                      {!lesson.cover ? (
                                        <Tooltip title="This lesson has no cover image.">
                                          <Lessons.ItemCover cover="">
                                            <Icon
                                              type="question"
                                              style={{ color: 'red' }}
                                            />
                                          </Lessons.ItemCover>
                                        </Tooltip>
                                      ) : (
                                        <Lessons.ItemCover
                                          cover={lesson.cover.link}
                                        />
                                      )}
                                      <Tooltip title={lesson.title}>
                                        <span className="lesson_title">
                                          {lesson.title}
                                        </span>
                                      </Tooltip>
                                    </div>
                                    <div className="b-page__control">
                                      <Popconfirm
                                        title={t('delete_warning')}
                                        okText={t('general:yes')}
                                        cancelText={t('general:no')}
                                        placement="leftTop"
                                        onClick={e => e.stopPropagation()}
                                        onCancel={e => e.stopPropagation()}
                                        onConfirm={e =>
                                          this.deleteLesson(e, lesson.id)
                                        }
                                        icon={false}
                                      >
                                        <Icon type="delete" />
                                      </Popconfirm>
                                    </div>
                                  </div>
                                </div>
                              </Page>
                            ))}
                          </Pages>
                          <div className="add-lession-btn">
                            <Button
                              type="primary"
                              icon="plus"
                              className="shl-secondary-btn"
                              onClick={() => history.push(`${rootPath}/lesson`)}
                              disabled={!courseId || isCoverLoading}
                              loading={isCoverLoading}
                            >
                              {t('buttons:add_lesson')}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <ContentLoader />
                      )}
                    </Lessons.List>
                  </Lessons>
                </div>
              )}
            </div>
            <div className="create-course">
              <MarketPlaceBottomSection
                {...this.props}
                isMarketPlace={isMarketPlace}
                onDescription={this.handleDescription}
                onObjectives={this.handleObjectives}
                onOutline={this.handleOutline}
                onShareProfile={this.handleShareProfile}
                onMarketPlaceDescription={this.handleMarketPlaceDescription}
              />
            </div>
          </Form>
        </section>

        <div className="p-footer">
          <div className="p-footer__inner">
            <div className="p-footer__item">
              <Button className="rounded" onClick={this.handleSubmit}>
                {t('buttons:save_for_later')}{' '}
                {courseId && <span>({t('general:autosaved')})</span>}
              </Button>
            </div>
            <div className="p-footer__item">
              {/* history.push(`/course/${courseId}/course-ai`, {
                      aiContent,
                      courseTitle,
                      courseId
                    }) */}
              {!isCreatingCourseByHuman ? (
                <Button
                  onClick={() => this.handleOpenModel()}
                  disabled={!courseId}
                  loading={isFetchingAiContent}
                  type="primary"
                  className="rounded shl-primary-btn"
                >
                  {t('buttons:next')}
                  <Icon type="right" />
                </Button>
              ) : publishButtonCondition ? (
                <Button
                  onClick={() => this.finish(true)}
                  loading={isFinishing}
                  type="primary"
                  className="rounded"
                  disabled={!lessons.list.length}
                >
                  {t('buttons:publish')}
                  <Icon type="right" />
                </Button>
              ) : (
                <Button
                  onClick={() => this.finish(false)}
                  loading={isFinishing}
                  type="primary"
                  className="rounded"
                  disabled={!lessons.list.length}
                >
                  {t('buttons:finish')}
                </Button>
              )}
            </div>
          </div>
        </div>

        {showAiCourseCreationModal && (
          <AiCourseCreation
            aiContent={aiContent}
            onCancel={this.handleTableOfContentModalClose}
            courseTitle={courseTitle}
          />
        )}

        {image && (
          <EditImageContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}
        {modalOpen && (
          <ImageUploadModal
            modalOpen={modalOpen}
            selectedItem={selectedItem}
            hideModal={this.hideModal}
            imageRef={this.imageRef}
            handleInsertData={this.handleInsertData}
            handleFileInsert={this.handleFileInsert}
            hidePreviewModal={this.hidePreviewModal}
          />
        )}
        {openBadgeModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openBadgeModal}
            onCancel={this.toggleBadgeModal}
            footer={null}
            width="1229px"
            centered
          >
            <Badge
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Badge')
              }
              {...this.props}
            />
          </Modal>
        )}
        {openCertificateModal && (
          <Modal
            style={{ top: 1 }}
            bodyStyle={{ height: 'auto' }}
            visible={openCertificateModal}
            onCancel={this.toggleCertificateModal}
            footer={null}
            width="1229px"
            centered
          >
            <Certificate
              onUploadImage={body =>
                this.badgePresentationUpload(body, 'Certificate')
              }
              {...this.props}
            />
          </Modal>
        )}
        <Modal
          visible={showContactModal}
          onCancel={this.toggleContactModal}
          width={700}
          footer={null}
        >
          <ShowContactsModal
            t={t}
            fetching={fetching}
            orgUsers={emailList}
            groupOptions={groupOptions}
            addEmails={this.handleEmailChange}
            active_organization_id={activeOrgId}
            selectedEmails={getFieldValue('host_emails')}
            tagValue="course"
          />
        </Modal>
      </Spin>
    )
  }
}

export default Form.create()(withTranslation('coursecreate')(CourseCreateMain))
