import {
  CREATE_AUDIO_SYNTHESIZER,
  PROCESSING_AUDIO_SYNTHESIZER,
  AUDIO_SYNTHESIZER_ERROR,
  SHOW_AUDIO_EDIT_MODAL,
  GET_SELECTED_TEXT_VOICE_TYPE,
  SHOW_AUDIO_DETAIL_EDIT_MODAL,
  LOAD_ALL_VOICES,
  CREATE_TTS_AUDIO_SYNTHESIZER
} from '../types'

const initialState = {
  audioData: {},
  selected: {},
  isProcessing: false,
  isError: false,
  contentId: '',
  isShowEditAudio: false,
  isShowDetailEditAudio: false,
  loadAudioData: {}
}

const audioSynthesizer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_AUDIO_SYNTHESIZER:
      return Object.assign({}, state, {
        audioData: action.payload,
        isProcessing: false,
        isError: false
      })
    case PROCESSING_AUDIO_SYNTHESIZER:
      return Object.assign({}, state, {
        audioData: {},
        isProcessing: true,
        isError: false
      })
    case AUDIO_SYNTHESIZER_ERROR:
      return Object.assign({}, state, {
        audioData: action.payload,
        isProcessing: false,
        isError: true
      })
    case SHOW_AUDIO_EDIT_MODAL:
      return {
        ...state,
        contentId: action.payload.contentId,
        isShowEditAudio: action.payload.isShowEditAudio
      }

    case SHOW_AUDIO_DETAIL_EDIT_MODAL:
      return {
        ...state,
        contentId: action.payload.contentId,
        isShowDetailEditAudio: action.payload.isShowDetailEditAudio
      }

    case GET_SELECTED_TEXT_VOICE_TYPE:
      return {
        ...state,
        selected: {
          ...state.selected,
          selectedText: action.payload.selectedText,
          selectedVoice: action.payload.selectedVoice
        }
      }

    case LOAD_ALL_VOICES:
      return {
        ...state,
        loadAudioData: action.payload,
        isProcessing: false,
        isError: false
      }
    case CREATE_TTS_AUDIO_SYNTHESIZER:
      return Object.assign({}, state, {
        audioData: action.payload,
        isProcessing: false,
        isError: false
      })
    default:
      return state
  }
}

export default audioSynthesizer
