import React, { Fragment } from 'react'
import { Row, Col } from 'antd'
import { ProgressBar } from '../../../../../components'
import { Head, Tab } from '../../../../../styles'
import { Lessons } from '../../../../../styles/blocks'
import { checkCover, getThumbnail, getLessonItemClassName } from '../Controller'
import {
  PanelItem,
  LessonsBlock,
  Btn,
  HeadWrap,
  CourseOwner,
  CourseContent
} from '../../styled'
import '../../styles.scss'
import Discussion from '../../../../../components/Discussion'
import Notes from '../../../../../components/Notes'
import triangleImg from '../../img/triangle.svg'
import chatIcon from '../../img/chat.svg'
import ReactSVG from 'react-svg'
import { useTranslation } from 'react-i18next'
// import { Switch } from 'antd'

const ScormPlayer = () => {
  return (
    <Row>
      <div id="sha-sidebar" className="sr-only">
        {/* <h5> Course Menu </h5> */}
        <ul id="MenuPlaceHolderDiv" className="hidden"></ul>
      </div>
      <Col span={12} id="sha-main">
        <iframe
          title="iframe title"
          id="scromIframe"
          style={{
            objectFit: 'cover',
            marginLeft: '7px'
          }}
          width="734"
          height="591"
          frameBorder="0"
        >
          Your browser doesnot support iframes
        </iframe>
      </Col>
    </Row>
  )
}

const PanelCourse = (title, description, cover) => (
  <Fragment>
    <PanelItem>
      <Head>
        <PanelItem.Title>{title}</PanelItem.Title>
      </Head>
      <PanelItem.Content>
        <PanelItem.Description>{description}</PanelItem.Description>
        <PanelItem.Image src={checkCover(cover)} />
      </PanelItem.Content>
    </PanelItem>
  </Fragment>
)

const PanelLesson = (
  App,
  title,
  enrollment,
  isOwner,
  description,
  cover,
  t,
  canPlay
) => (
  <Fragment>
    <PanelItem>
      <Head>
        <PanelItem.Wrapper>
          <PanelItem.Title>{title}</PanelItem.Title>

          {/* {(enrollment || isOwner) && (
            <Switched
              startlesson="true"
                style={{
                  position: 'absolute',
                right: '20rem'}}
              unCheckedChildren={t('v4:portrait')}
              checkedChildren={t('v4:landscape')}
              onClick={() => App.handleSwitchViewMode()}
            />
          )} */}
          {(enrollment || isOwner) && (
            <Btn
              startlesson="true"
              type="primary"
              className="rounded"
              onClick={() => App.openLessonContent(0)}
              disabled={!canPlay}
            >
              <img src={triangleImg} alt="triangle" />
              {t('buttons:start_lesson')}
            </Btn>
          )}
        </PanelItem.Wrapper>
      </Head>
      {/* <PanelItem.Content>
        <PanelItem.Description>
          {description}
        </PanelItem.Description>
      </PanelItem.Content> */}
      {/* <PanelItem.Image src={checkCover(cover)} /> */}
    </PanelItem>
  </Fragment>
)

const LessonSection = (App, lessons, cover, activeLessonId, t) => (
  <Fragment>
    <LessonsBlock>
      <Lessons>
        <Lessons.Count>
          {lessons.length} {t('general:lessons')}
        </Lessons.Count>
        <Lessons.List>
          {lessons.map((lesson, index) => {
            const { id, cover, title } = lesson
            const img = getThumbnail(cover)
            const percent = App.getPercent(lesson)

            return (
              <Lessons.Item
                key={id}
                onClick={() => App.handleChangeLesson(id)}
                className={getLessonItemClassName(id, activeLessonId)}
                style={{ flexDirection: 'column' }}
              >
                <div className="wrap-lesson-info">
                  <Lessons.ItemNumber>{index + 1}</Lessons.ItemNumber>
                  <Lessons.ItemCover cover={img} />
                  <Lessons.ItemTitle>{title}</Lessons.ItemTitle>
                </div>

                {!!percent && (
                  <ProgressBar
                    percent={percent}
                    style={{ marginTop: '1rem' }}
                  />
                )}
              </Lessons.Item>
            )
          })}
        </Lessons.List>
      </Lessons>
    </LessonsBlock>
  </Fragment>
)

const CourseSection = ({ App, isOwner }) => {
  const { t } = useTranslation()
  const {
    course: {
      item: { title, cover, description, enrollment, id }
    },
    user: {
      info: { id: userId }
    },
    log
  } = App.props
  const { activeTab, canPlay } = App.state

  let unreadDiscussions = 0
  if (log && log[0]) {
    unreadDiscussions +=
      log[0].summary.current_messages - log[0].userLog.messages
    unreadDiscussions += log[0].summary.current_replies - log[0].userLog.replies
    unreadDiscussions += log[0].summary.current_votes - log[0].userLog.votes
  }

  return (
    <Fragment>
      <CourseContent>
        <Tab
          activeKey={activeTab}
          animated={false}
          onChange={App.handleChangeTab}
          style={{ position: 'relative' }}
        >
          <Tab.Panel tab={t('tabs:course')} key="course_overview">
            {PanelCourse(title, description, cover)}
          </Tab.Panel>

          <Tab.Panel tab={t('tabs:lessons')} key="lessons">
            {/* {activeLesson && */}
            {PanelLesson(
              App,
              title,
              description,
              cover,
              enrollment,
              isOwner,
              t,
              canPlay
            )}
          </Tab.Panel>
          <Tab.TabPane
            tab={`${t('tabs:discussion')} (${log &&
              log[0] &&
              log[0].summary.current_messages})`}
            key="discussion"
          >
            <Discussion
              discussionId={id}
              discussionType={'course'}
              userId={userId}
            />
          </Tab.TabPane>
          <Tab.TabPane tab={t('tabs:notes')} key="notes">
            <Notes noteId={id} noteType={'course'} userId={userId} />
          </Tab.TabPane>
          <div className="card__wrapper" tab={''} key="log">
            {unreadDiscussions > 0 && (
              <div className="card__log_wrapper">
                <ReactSVG src={chatIcon} className="card__log" />
                <span className="card__log_unread">
                  {unreadDiscussions >= 10 ? '9+' : unreadDiscussions}
                </span>
              </div>
            )}
          </div>
        </Tab>

        {/* {lessons && LessonSection(App, lessons, cover, activeLessonId, t)} */}
      </CourseContent>
    </Fragment>
  )
}

const CourseOwnerSection = ({ organiser, t }) => (
  <Fragment>
    <CourseOwner>
      <CourseOwner.Name>
        {t('general:by')}&nbsp;
        <span>{organiser}</span>
      </CourseOwner.Name>
    </CourseOwner>
  </Fragment>
)

const HeaderSection = ({ title, topics }) => (
  <Fragment>
    <section className="section" style={{ paddingBottom: '0' }}>
      <HeadWrap>
        <h2>{title}</h2>
        <h3>{topics}</h3>
      </HeadWrap>
    </section>
  </Fragment>
)

export {
  PanelCourse,
  PanelLesson,
  LessonSection,
  CourseSection,
  HeaderSection,
  CourseOwnerSection,
  ScormPlayer
}
