import { border } from 'styled-system'

const sizes = {
  xxl: 1600,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576
}

const colors = {
  blue: '#0068FF',
  purple: '#4652E5',
  mainPurple: '#5B6EE2',
  red: '#FF4B55',
  green: '#00BD5D',
  orange: '#FF6332',
  gray: '#EDF2F5',
  blakGray: '#212121',
  darkGreyOne: '#777777',
  darkGreyTwo: '#D8D8D8',
  darkGreyThree: '#EEEEEE',
  darkGreyFour: '#28303B',
  textDark: '#676973',
  borderDark: '#E3E3E3',

  white: '#FFFFFF',
  black: '#111926',
  light: '#f7f7f7',
  pageBuilderBorder: '#979797',
  PrimaryLinear: 'linear-gradient(106.91deg, #f8f4e8 1.04%, #e9eef5 44.36%)',
  linearGradient: 'linear-gradient(180deg, #698bf2 0%, #5057d5 100%)'
}

const linearGradient = {
  border: '1px solid',
  borderImage: 'linear-gradient(180deg, #698BF2 0%, #5057D5 100%)',
  borderImageSlice: 1
}

const base = {
  fontSize: '1.4rem',
  fontFamily:
    'Mulish, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif',
  fontWeight: 400,
  fontColor: colors.darkGreyOne
}

const typography = {
  h1FontSize: '1.8rem',
  h1FontWeight: 400,

  h2FontSize: '1.6rem',
  h2FontWeight: 700,

  h3FontSize: '1.4rem',
  h3FontWeight: 700,
  h3LineHeight: '2rem',

  h4FontSize: '1.2rem',
  h4FontWeight: 400,
  h4LineHeight: '2rem',

  labelFontSize: '1.4rem',
  labelFontWeight: 400,

  hyperLinkFontSize: '1.4rem',
  hyperFontWeight: 400
}

export default {
  sizes,
  colors,
  base,
  typography,
  linearGradient
}
