import { connect } from 'react-redux'

import ModalLessonLandscape from './ModalLessonLandscape'

export default connect(
  state => ({
    lesson: state.courses.lessonById,
    user: state.user
  }),
  dispatch => ({})
)(ModalLessonLandscape)
