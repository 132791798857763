import styled from 'styled-components'

const MessageContainer = styled.div`
  display: flex;
  padding-bottom: 20px;
`

MessageContainer.Avatar = styled.div`
  display: flex;
  flex: 0 0 60px;
`
MessageContainer.Main = styled.div`
  flex: 1 1 auto;
`

MessageContainer.Main.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

MessageContainer.Main.Header.Info = styled.div`
  display: flex;
  align-items: center;
`

MessageContainer.Main.Header.Name = styled.span`
  color: #136cfb;
  font-weight: bold;
  margin-right: 10px;
`

MessageContainer.Main.Header.Date = styled.span`
  color: rgb(119, 119, 119);
  font-size: 1.1rem;
`

MessageContainer.Main.Header.More = styled.div`
  position: relative;
  color: rgb(119, 119, 119);
  font-size: 1.1rem;
  margin-left: 20px;

  .message-more-options {
    svg {
      &:hover {
        fill: #136cfc;
      }
    }
  }

  .message-more-options-active {
    svg {
      fill: #136cfc;
    }
  }
`

MessageContainer.Main.Header.MoreOptions = styled.div`
  width: 86px;
  position: absolute;
  top: 20px;
  right: 0;
  border-radius: 4px;
  background: rgb(255, 255, 255);
  box-shadow: 0 3px 4px 1px #adb2b9;
  padding: 10px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    margin-bottom: 5px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .more-options-item {
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
`

MessageContainer.Main.Message = styled.div`
  a:hover {
    text-decoration: underline;
  }
`

MessageContainer.Main.Footer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`

MessageContainer.Main.Footer.Link = styled.a`
  color: rgb(119, 119, 119);
  font-weight: bold;
  margin-right: 10px;
`

export { MessageContainer }
