import React, { Component, Fragment } from 'react'
import { Popconfirm, Spin, Select, Pagination, Button, Modal } from 'antd'
import _ from 'lodash'
import Autocomplete from 'react-autocomplete'
import { Page, Column, Table, Row } from './styled'
import { ModalAddUsers } from './internal'
import Dropzone from 'react-dropzone'
import readXlsxFile from 'read-excel-file'
import { pencil, trash } from '../../images'
import { Spinner, Search } from '../../../../components'
import { withTranslation, Trans } from 'react-i18next'
import styles from './users.style'
import api from '../../../../services/api'

const filterSort = [
  { value: 'username', text: 'filters:name' },
  { value: 'groups', text: 'filters:group' }
]

class Users extends Component {
  state = {
    isModalVisible: false,
    selectedOrgId: null,
    selectedUser: null,
    deletedUserId: null,
    sortField: filterSort[0].value,
    page: 1,
    pageSize: 10,
    searchList: [],
    isActive: false,
    isModalMassUser: false,
    isErrorUploading: false,
    search: '',
    searchType: 'search',
    userSearchList: [],
    groupList: [],
    isActiveGroup: false,
    selectedGroupId: null
  }

  componentDidMount() {
    const {
      getRoles,
      organizations: { isFetching }
    } = this.props

    getRoles()

    if (!isFetching) {
      this.getUsers()
    }
  }

  getUsers = () => {
    const {
      organizations: {
        list: { rows: list }
      },
      user: { active_organization_id: isSuperAdmin }
    } = this.props
    let orgList = []
    if (!_.isEmpty(list)) {
      orgList = isSuperAdmin
        ? list
        : list.filter(i => i.role && i.role.name === 'Admin')
    }
    return orgList
    // if (activeOrganizationId) {
    //   getUsersByOrganizationId(activeOrganizationId)
    // }
    // else if (orgList.length) {
    //   getUsersByOrganizationId(orgList[0].id)
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      organizations: { isFetching }
    } = this.props
    const {
      organizations: { isFetching: isPrevFetching }
    } = prevProps

    if (isPrevFetching && isPrevFetching !== isFetching) {
      this.getUsers()
      // this.fetchUsers()
    }
  }

  componentWillUnmount() {
    this.setState({
      searchList: [],
      selectedOrgId: null
    })
    this.props.resetUsersByOrganizationId()
  }

  setModalVisible = (value, orgId, needToReload) => {
    this.setState({
      isModalVisible: value,
      selectedUser: null
    })
    if (needToReload) {
      this.fetchUsers({ id: orgId })
    }
  }

  onSelectOrg = org => {
    const inputAutoComplete = document.getElementById('inputOrg')
    inputAutoComplete.blur()
    this.setState({
      value: org.title,
      isActive: true,
      groupList: org.groups
    })
    this.props.getUsersByOrganizationId(org.id).then(() => {
      this.setState({
        selectedOrgId: org.id
      })
    })
  }

  fetchUsers = org => {
    this.props.getUsersByOrganizationId(org.id).then(() => {
      this.setState({
        selectedOrgId: org.id
      })
    })
  }

  selectUser = user => {
    this.setState({
      selectedUser: user,
      isModalVisible: true
    })
  }

  deleteUser = (e, orgId, id) => {
    e.stopPropagation()
    this.setState({
      deletedUserId: id
    })
    this.props.deleteOrganizationUser(orgId, id).then(() => {
      this.setState({
        deletedUserId: null
      })
    })
  }

  getValidUser = usersList => {
    const validList = usersList.map(item => {
      if (item.user) {
        return {
          ...item,
          username: item.user.username || item.user.email,
          isInvited: item.user.is_invited,
          groups: item.user.groups.map(g => g.title).join(', ')
        }
      } else {
        return null
      }
    })
    return _.compact(validList)
  }

  searchGroup = val => {
    const search = val.target.value
    if (_.isEmpty(search)) {
      this.setState({
        userSearchList: [],
        loading: false
      })
    }
    this.setState({
      groupValue: search,
      isSearching: true
    })
    // setTimeout(() => {
    //   return api.organizations
    //     .searchUsersInOrganization({ selectedOrgId, searchType, search })
    //     .then(res => {
    //       this.setState({
    //         userSearchList: res.data,
    //         loading: false
    //       })
    //     })
    //   // this.handleSearchType(search)
    // }, 1000)
  }

  onSelectGroup = group => {
    const { selectedOrgId } = this.state
    const { title } = group
    const searchType = 'searchBygroup'
    const orgId = selectedOrgId
    const searchValue = title
    // const inputAutoComplete = document.getElementById('inputOrgGroup')
    // inputAutoComplete.blur()
    this.setState({
      groupValue: title
    })
    return api.organizations
      .searchUsersInOrganization({ orgId, searchType, searchValue })
      .then(res => {
        this.setState({
          userSearchList: res.data,
          loading: false
        })
      })
  }

  searchOrg = val => {
    const search = val.target.value
    if (_.isEmpty(search)) {
      this.props.resetUsersByOrganizationId()
    }
    this.setState({
      value: search,
      isLoading: true
    })
    setTimeout(() => {
      this.getOrganizationsList(search)
    }, 1000)
  }
  getOrganizationsList = search => {
    const { getOrganizationsAutoComplete } = this.props
    const data = {
      offset: 0,
      limit: 10,
      search
    }
    getOrganizationsAutoComplete(data).then(resp => {
      this.setState({
        searchList: resp.rows,
        isLoading: false
      })
    })
  }

  getHeight = () => {
    const { searchList } = this.state
    if (_.isEmpty(searchList)) {
      return 0
    }
    return searchList.length * 30 + 20
  }

  getOpenModal = () => {
    const { isLoading, isActive, value } = this.state
    if (isLoading) {
      return !isLoading
    } else if (isActive) {
      return !isActive
    } else if (!value) {
      return false
    }
    return !isLoading
  }

  getOpenGroupModal = () => {
    const { isSearching, isActiveGroup, groupValue } = this.state
    if (isSearching) {
      return !isSearching
    } else if (isActiveGroup) {
      return !isActiveGroup
    } else if (!groupValue) {
      return false
    }
    return !isSearching
  }

  removeActive = () => {
    this.setState({
      isActive: false,
      isActiveGroup: false
    })
  }

  setVisibleMassUser = () => {
    this.setState({
      isModalMassUser: !this.state.isModalMassUser,
      isErrorUploading: false
    })
  }
  onDrop = dropped => {
    const file = dropped[0]
    this.setState({
      isExcelUploading: true
    })
    if (!file) {
      return
    }
    readXlsxFile(file, { sheet: 'download_sheet' })
      .then(rawData => {
        const data = { users: this.handleDataFromExcel(rawData) }
        api.user
          .uploadMassUser(data)
          .then(resp => {
            const { status } = resp
            if (status === 200) {
              this.setState({
                isExcelUploading: false,
                isModalMassUser: false,
                isErrorUploading: false
              })
              this.callSuccessModal()
            }
          })
          .catch(() => {
            this.setState({
              isErrorUploading: true,
              isExcelUploading: false
            })
          })
      })
      .catch(() => {
        this.setState({
          isErrorUploading: true,
          isExcelUploading: false
        })
      })
  }
  callSuccessModal = () => {
    const { t } = this.props
    Modal.success({
      title: t('general:upload_successful'),
      content: (
        <div style={{ marginTop: '16px' }}>
          <p>{t('general:upload_successful_message')}</p>
          <Trans i18nKey="general:check_email_upload_successful">
            <u>{t('v3:check_email')}</u>
            <u>{t('reports:report')}</u>
          </Trans>
        </div>
      ),
      width: '500px',
      okButtonProps: {
        shape: 'round'
      },
      centered: true,
      onOk() {}
    })
  }
  handleDataFromExcel = excelData => {
    let userList = []
    excelData.forEach(element => {
      const tmpUser = {
        name: element[0] && element[0].toString().trim(),
        email: element[1] && element[1].trim(),
        employeeId: element[2] && element[2].toString().trim(),
        organization: element[3] && element[3].toString().trim(),
        groups: [
          element[4] && element[4].toString().trim(),
          element[5] && element[5].toString().trim(),
          element[6] && element[6].toString().trim(),
          element[7] && element[7].toString().trim(),
          element[8] && element[8].toString().trim()
        ]
      }
      userList.push(tmpUser)
    })
    return _.filter(userList, (user, index) => index > 0)
  }

  fetchData = () => {
    const orgId = this.state.selectedOrgId
    const searchValue = this.state.search
    const searchType = this.state.searchType
    if (searchValue) {
      return api.organizations
        .searchUsersInOrganization({ orgId, searchType, searchValue })
        .then(res => {
          this.setState({
            userSearchList: res.data,
            loading: false
          })
        })
    }
  }

  handleSearchType = type => {
    if (type === 'name') {
      this.setState(
        {
          searchType: 'searchByname',
          groupValue: ''
        },
        () => this.fetchData()
      )
    } else if (type === 'id') {
      this.setState(
        {
          searchType: 'searchById',
          groupValue: ''
        },
        () => this.fetchData()
      )
    } else if (type === 'email') {
      this.setState(
        {
          searchType: 'searchByemail',
          groupValue: ''
        },
        () => this.fetchData()
      )
    } else if (type === 'group') {
      this.setState(
        {
          searchType: 'searchBygroup'
        },
        () => this.fetchData()
      )
    } else {
      this.setState(
        {
          searchType: 'search',
          groupValue: ''
        },
        () => this.fetchData()
      )
    }
  }

  onSearch = _.debounce(
    value =>
      this.setState(
        {
          search: value,
          page: 1
        },
        () => {
          this.fetchData(true)
        }
      ),
    1000
  )

  render() {
    const {
      isModalVisible,
      selectedOrgId,
      selectedUser,
      deletedUserId,
      sortField,
      isModalMassUser,
      isExcelUploading,
      isErrorUploading,
      groupValue
    } = this.state

    const {
      organizations: {
        // isFetching: isOrgFetching,
        // list: {
        //   rows: list,
        // },
        usersByOrganizationId: { list: usersList, isFetching }
      },
      user: {
        active_organization_id: activeOrganizationId,
        isSuperAdmin,
        isAdmin,
        id
      },
      t
    } = this.props
    const {
      searchList,
      isLoading,
      isActive,
      search,
      userSearchList,
      groupList
    } = this.state
    let orgList = []

    // if(!_.isEmpty(list)) {
    //   orgList = isSuperAdmin
    //   ? list
    //   : list.filter(i => i.role && i.role.name === 'Admin')
    // }

    if (!_.isEmpty(searchList)) {
      orgList = isSuperAdmin
        ? searchList
        : searchList.filter(i => i.role && i.role.name === 'Admin')
    }

    let groups = groupList.length > 0 ? groupList : []
    console.log(
      'searchlist',
      searchList,
      orgList,
      groups,
      'userSearchList',
      userSearchList,
      'groupValue',
      groupValue
    )

    const orgId =
      selectedOrgId ||
      activeOrganizationId ||
      (!!orgList.length && orgList[0].id)

    const selectedOrg = orgList.find(i => i.id === orgId) || {}

    const isOrgAdmin =
      selectedOrg && selectedOrg.role && selectedOrg.role.name === 'Admin'

    let sortedList
    if (search || groupValue) {
      sortedList = _.sortBy(
        this.getValidUser(userSearchList),
        user => user[sortField]
      )
    } else {
      sortedList = _.sortBy(
        this.getValidUser(usersList),
        user => user[sortField]
      )
    }

    return (
      <Page className="sharelook-user-management">
        {false ? (
          <Spinner />
        ) : (
          <Fragment>
            <Page.Head>
              <Column>
                <h2>{t('users_title')}</h2>
                <Row>
                  <h3 style={{ paddingRight: '1rem' }}>
                    {t('labels:organization')}
                  </h3>
                  <div style={{ position: 'relative' }}>
                    <Autocomplete
                      getItemValue={item => item.title}
                      items={orgList}
                      renderItem={(item, isHighlighted) => (
                        <div
                          style={
                            isHighlighted ? styles.HighLight : styles.NormalText
                          }
                        >
                          {item.title}
                        </div>
                      )}
                      renderInput={props => (
                        <div style={styles.InputWrapper}>
                          <input
                            id="inputOrg"
                            {...props}
                            style={styles.InputStyle}
                            onFocus={this.removeActive}
                            autoFocus="off"
                          />
                          {isLoading && (
                            <Button
                              shape="circle"
                              size="small"
                              loading
                              style={styles.ButtonStyle}
                            />
                          )}
                        </div>
                      )}
                      wrapperStyle={styles.AutoWrappper}
                      menuStyle={styles.autoCompleteModal}
                      value={this.state.value}
                      onChange={e => this.searchOrg(e)}
                      onSelect={(val, item) => this.onSelectOrg(item)}
                      open={this.getOpenModal()}
                    />
                  </div>
                </Row>
              </Column>
            </Page.Head>
            {groups.length > 0 && (
              <Page.Head>
                <Column className="sharelook-user-management">
                  <Row>
                    <h3 style={{ paddingRight: '1rem' }}>
                      {t('filters:group')}
                    </h3>
                    <div style={{ position: 'relative' }}>
                      <Autocomplete
                        getItemValue={item => item.title}
                        items={groups}
                        renderItem={(item, isHighlighted) => (
                          <div
                            style={
                              isHighlighted
                                ? styles.HighLight
                                : styles.NormalText
                            }
                          >
                            {item.title}
                          </div>
                        )}
                        wrapperStyle={styles.AutoWrappper}
                        menuStyle={styles.autoCompleteModal}
                        value={this.state.groupValue}
                        // onChange={e => (value = e.target.value)}
                        onSelect={(val, item) => this.onSelectGroup(item)}
                      />
                    </div>
                  </Row>
                </Column>
              </Page.Head>
            )}
            <Page.Head>
              {(isSuperAdmin || isAdmin) && (
                <Column className="sharelook-user-management-select">
                  <Row>
                    <Page.Head.Search>
                      <Select
                        onChange={this.handleSearchType}
                        className="rounded-select"
                        defaultValue={'All'}
                      >
                        <Select.Option value={'All'}>All</Select.Option>
                        <Select.Option value={'name'}>Name</Select.Option>
                        <Select.Option value={'id'}>ID Number</Select.Option>
                        <Select.Option value={'email'}>Email</Select.Option>
                        {/* <Select.Option value={'group'}>Group</Select.Option> */}
                      </Select>
                      <Search
                        placeholder={t('placeholders:search')}
                        onChange={e => this.onSearch(e.target.value)}
                        style={{ marginLeft: '10px' }}
                      />
                    </Page.Head.Search>
                    {/* <Page.Head.Button type='primary' className='rounded'>
                        <a href='/excel/template_sheet.xlsx' target='_blank'>
                          {t('buttons:download_mass_user_tamplate')}
                        </a>
                      </Page.Head.Button> */}

                    <Page.Head.Button
                      type="primary"
                      className="rounded"
                      onClick={this.setVisibleMassUser}
                    >
                      {t('buttons:add_mass_users')}
                    </Page.Head.Button>
                    <Spin spinning={isFetching}>
                      <Page.Head.Button
                        type="primary"
                        className="rounded"
                        onClick={() => this.setModalVisible(true, orgId)}
                        disabled={!isActive}
                      >
                        {t('buttons:add_users')}
                      </Page.Head.Button>
                    </Spin>
                  </Row>

                  <Modal
                    title={t('buttons:add_mass_users')}
                    visible={isModalMassUser}
                    onCancel={this.setVisibleMassUser}
                    centered
                    footer={null}
                  >
                    <div style={styles.buttonRow}>
                      <Button
                        style={{ padding: '0 4rem' }}
                        type="primary"
                        shape="round"
                        onClick={this.setVisibleMassUser}
                      >
                        <a href="/excel/template_sheet.xlsx">
                          {t('buttons:download_mass_user_tamplate')}
                        </a>
                      </Button>
                      <Dropzone
                        accept={('. xls', '.xlsx')}
                        onDrop={this.onDrop}
                        style={{}}
                      >
                        {() => (
                          <Button
                            style={{ padding: '0 4rem' }}
                            type="primary"
                            shape="round"
                            loading={isExcelUploading}
                          >
                            {t('buttons:upload_excel_list')}
                          </Button>
                        )}
                      </Dropzone>
                    </div>
                    {isErrorUploading && (
                      <div style={styles.ErrorMessageConatainer}>
                        <div style={styles.ErrorMessage}>
                          <u style={{ fontSize: '1.5rem' }}>
                            {t('errors:title_excel')}
                          </u>
                        </div>
                        <div style={styles.ErrorMessage}>
                          {t('errors:upload_excel')}
                        </div>
                        <div style={styles.ErrorMessage}>
                          {t('errors:subtitle_excel')}
                        </div>
                      </div>
                    )}
                  </Modal>

                  {orgId && (
                    <ModalAddUsers
                      organizationId={orgId}
                      user={selectedUser}
                      groups={selectedOrg.groups}
                      visible={isModalVisible}
                      onCancel={needToReload =>
                        this.setModalVisible(false, orgId, needToReload)
                      }
                    />
                  )}
                </Column>
              )}
            </Page.Head>
            {_.isEmpty(sortedList) && !isActive ? (
              <div style={styles.Message}>
                <img src="/images/search-error-icon.png" alt="" />
                <div>{t('management:select_org')}</div>
              </div>
            ) : (
              <Table>
                <Table.Header>
                  <Table.Label>{t('labels:users_list')}</Table.Label>

                  <Page.Filter
                    data={filterSort}
                    defaultValue={filterSort[0].text}
                    callback={item => this.setState({ sortField: item.value })}
                    label={t('labels:sort_by')}
                    noIndent
                  />
                </Table.Header>

                <Spin spinning={isFetching}>
                  <Table.Body style={{ minHeight: '20rem' }}>
                    <Table.Head>
                      <Table.Head.Column>{t('v4:id_number')}</Table.Head.Column>
                      <Table.Head.Column>{t('labels:name')}</Table.Head.Column>
                      <Table.Head.Column>{t('labels:group')}</Table.Head.Column>
                    </Table.Head>

                    {sortedList
                      .slice(
                        (this.state.page - 1) * this.state.pageSize,
                        this.state.page * this.state.pageSize
                      )
                      .map(item => {
                        const {
                          username,
                          isInvited,
                          groups,
                          user,
                          user_id: userId,
                          employee_id: employeeId,
                          role
                        } = item

                        return (
                          <Spin
                            spinning={deletedUserId && deletedUserId === userId}
                            key={userId}
                          >
                            <Table.Item>
                              <Table.Column>
                                <Table.Item.IdNum>
                                  {employeeId || 'N/A'}
                                </Table.Item.IdNum>
                              </Table.Column>
                              <Table.Column>
                                <Table.Item.Info>
                                  <Table.Item.UserName>
                                    {username}
                                    {isInvited && `(${t('labels:invited')})`}
                                  </Table.Item.UserName>
                                  {false && (
                                    <Table.Item.Position>
                                      {t('users_position')}
                                    </Table.Item.Position>
                                  )}
                                  <Table.Item.Role>{role.name}</Table.Item.Role>
                                </Table.Item.Info>
                              </Table.Column>

                              <Table.Column>
                                <Table.Item.Groups>{groups}</Table.Item.Groups>
                              </Table.Column>

                              {(isOrgAdmin || isSuperAdmin) && (
                                <Table.Events>
                                  <Table.EventLink>
                                    <Table.EventIcon
                                      src={pencil}
                                      onClick={() =>
                                        this.selectUser({
                                          ...user,
                                          employee_id: employeeId,
                                          role_id: role.id
                                        })
                                      }
                                    />
                                  </Table.EventLink>

                                  <Popconfirm
                                    title={t('delete_warning')}
                                    okText={t('general:yes')}
                                    cancelText={t('general:no')}
                                    placement="right"
                                    onClick={e => e.stopPropagation()}
                                    onCancel={e => e.stopPropagation()}
                                    onConfirm={e =>
                                      this.deleteUser(e, orgId, userId)
                                    }
                                    icon={false}
                                  >
                                    {id !== userId && (
                                      <Table.EventLink>
                                        <Table.EventIcon src={trash} />
                                      </Table.EventLink>
                                    )}
                                  </Popconfirm>
                                </Table.Events>
                              )}
                            </Table.Item>
                          </Spin>
                        )
                      })}
                  </Table.Body>
                </Spin>
                <div style={{ paddingTop: '2rem' }}>
                  <Pagination
                    total={sortedList.length}
                    pageSize={this.state.pageSize}
                    page={this.state.page}
                    onChange={page =>
                      this.setState({
                        page
                      })
                    }
                  />
                </div>
              </Table>
            )}
          </Fragment>
        )}
      </Page>
    )
  }
}

export default withTranslation('management')(Users)
