import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import AccountContent from './Account.content'
import libphonenumber from 'google-libphonenumber'
import ImageCropContainer from '../../components/ImageCrop'

import { Form, message, Modal, Button } from 'antd'
import { general, countries } from '../../constants'
import { Page } from './styled'
import { getCertificatesById } from '../../services/api/certificate'
import {
  sendCertificateByEmail,
  getIntegrationStatus,
  revokeIntegration
} from '../../services/api/user'
import history from '../../history'
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

class Account extends Component {
  state = {
    isAvatarLoading: false,
    image: null, // your-image-url or as base64
    file: null,
    timezone: '',
    phoneNumber: '',
    rewardModal: false,
    selectedBadge: null,
    selectedCertificate: {},
    badgeLoading: true,
    certificateLoading: true,
    hasIntegrated: false,
    revokeModal: false,
    revokeLoading: false,
    certificateData: [],
    hasIntegratedStripe: false,
    isLoading: false
  }

  componentDidMount() {
    const {
      user: { info, stripe_connect },
      getBadgesById
    } = this.props

    getBadgesById(info.id).then(res => this.setState({ badgeLoading: false }))
    getCertificatesById(info.id).then(res => {
      this.setState({
        certificateLoading: false,
        certificateData: res.data
      })
    })
    this.checkIntegrationStatus()

    this.setState({
      hasIntegratedStripe: info.stripe_connect && info.stripe_connect.status
    })
  }

  checkStripe = status => {
    this.setState({
      hasIntegratedStripe: status
    })
  }

  checkIntegrationStatus = () => {
    getIntegrationStatus()
      .then(res =>
        this.setState({ hasIntegrated: res.data ? res.data.integrated : false })
      )
      .catch(() => this.setState({ hasIntegrated: false }))
  }

  revokeIntegrationStatus = () => {
    this.setState({ revokeLoading: true }, () => {
      revokeIntegration()
        .then(res =>
          this.setState({
            hasIntegrated: res.data ? !res.data.deleted : true,
            revokeModal: false,
            revokeLoading: false
          })
        )
        .catch(() =>
          this.setState({
            hasIntegrated: true,
            revokeModal: false,
            revokeLoading: false
          })
        )
    })
  }

  toggleRevokeModal = () => {
    this.setState(prevState => ({ revokeModal: !prevState.revokeModal }))
  }

  handleTimezone = zone => {
    // console.log('timezone', zone)
    const {
      user: { info },
      updateUser
    } = this.props
    const timezone = { value: zone.value, label: zone.label }
    // const attributes = {
    //   'custom:timezone': JSON.stringify(timezone)
    // }
    this.setState({ timezone: JSON.stringify(timezone) })
    // if (updateUser) {
    //   updateUser(info.id, attributes, null)
    // }
  }

  handlePhoneNumber = data => {
    this.setState({ phoneNumber: data.value })
  }

  handleSave = e => {
    const {
      form: { validateFields },
      updateUser,
      user: { info },
      t
    } = this.props
    const { timezone } = this.state
    e.preventDefault()
    validateFields((err, values) => {
      values.phone_number = values.phone_number.includes('+')
        ? values.phone_number
        : '+' + values.phone_number

      console.log('values', values)

      if (!err) {
        const number = phoneUtil.parseAndKeepRawInput(values.phone_number)

        const {
          email,
          name = '',
          position = '',
          bio = '',
          linkedin = '',
          first_name,
          last_name
        } = values
        const attributes = {
          name,
          email,
          address:
            countries[phoneUtil.getRegionCodeForNumber(number)].name ||
            values.address,
          phone_number: values.phone_number,
          'custom:position': position,
          'custom:timezone': timezone,
          'custom:linkedin': linkedin,
          'custom:bio': bio,
          'custom:firstName': first_name,
          'custom:lastName': last_name
        }
        const dbAttributes = {
          email,
          first_name,
          last_name
        }

        console.log(attributes)

        if (updateUser) {
          updateUser(info.id, attributes, dbAttributes)
            .then(() => {
              message.success(t('saved'))
            })
            .catch(err => {
              message.error(err.message)
            })
        }
      }
    })
  }

  handleTopic = topic => {
    const {
      user: {
        info: { id, topics: userTopics }
      },
      updateUser
    } = this.props

    const oldTopics = userTopics || []
    let newTopics = []

    if (
      (oldTopics.length === 1 && oldTopics.includes(topic)) ||
      (oldTopics.length === 5 && !oldTopics.includes(topic))
    ) {
      return
    }

    if (oldTopics.includes(topic)) {
      newTopics = oldTopics.filter(el => el !== topic)
    } else {
      newTopics = [...oldTopics, topic]
    }

    if (updateUser) {
      updateUser(id, null, { topics: newTopics })
    }
  }

  handleDrop = dropped => {
    if (!dropped) return

    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  validatePhoneNumber = (rule, value, callback) => {
    const { t } = this.props

    try {
      const number = phoneUtil.parseAndKeepRawInput(value)

      if (phoneUtil.isValidNumber(number)) {
        callback()
      } else {
        callback(t('errors:phone_invalid'))
      }
    } catch (e) {
      callback(t('errors:phone_invalid'))
    }
  }

  handleUploadImage = data => {
    const {
      updateUser,
      user: { info },
      upload
    } = this.props
    const opts = { category: 'profile' }

    upload(data, opts)
      .then(response => {
        updateUser(info.id, null, { avatar_id: response.id })
      })
      .then(() => {
        this.setState({
          isAvatarLoading: false,
          file: null
        })
      })
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null,
        isAvatarLoading: true
      })
    } else {
      this.setState({
        image: null,
        file: null,
        isAvatarLoading: false
      })
    }
  }

  openModal = (item, type) => {
    if (type === 'badge') {
      this.setState({
        rewardModal: true,
        selectedBadge: item,
        selectedCertificate: null
      })
    } else {
      this.setState({
        rewardModal: true,
        selectedCertificate: item,
        selectedBadge: null
      })
    }
  }

  downloadCertificate = e => {
    e.preventDefault()
    const certificate = this.state.selectedCertificate.certificate
    const certificateUrl = certificate.custom_data.pdf_link
    const certificateName = `Certificate - ${certificate.title}`
    const link = document.createElement('a')
    link.href = certificateUrl
    link.target = '__blank'
    link.download = certificateName
    link.click()
  }

  uploadCertificate = body => {
    const { upload, t } = this.props
    upload(body).then(res => {
      const params = {
        library_id: res.id,
        course_id: this.state.selectedCertificate.course_id
      }
      sendCertificateByEmail(params)
        .then(res => {
          this.setState({ sendingCertificate: false, rewardModal: false })
          message.success(`${t('v4:certificate_sent_sucessfully')}`)
        })
        .catch(err => {
          this.setState({ sendingCertificate: false })
          message.error(`${t('v4:error_try_again')}`)
        })
    })
  }

  handleDeleteAccount = () => {
    history.push('/account/delete-user')
  }

  render() {
    const {
      image,
      file,
      rewardModal,
      selectedBadge,
      selectedCertificate,
      revokeModal,
      revokeLoading
    } = this.state

    const certificate = selectedCertificate && selectedCertificate.certificate

    const {
      t,
      user: {
        info: { name, username }
      }
    } = this.props

    return (
      <Fragment>
        <AccountContent App={this} checkStripe={this.checkStripe} />

        {image && (
          <ImageCropContainer
            circle
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
            t={t}
          />
        )}

        {rewardModal && (
          <Modal
            footer={null}
            visible={rewardModal}
            onCancel={() =>
              this.setState({
                selectedBadge: null,
                selectedCertificate: {},
                rewardModal: false,
                iconById: '',
                logoById: '',
                signaturesById: ''
              })
            }
          >
            <Page.Modal>
              {selectedBadge && (
                <Page.Modal.Image src={selectedBadge.badge.link} />
              )}
              {certificate && (
                <Page.CertificateContainer id="cert-template">
                  <img src={certificate.link} />
                </Page.CertificateContainer>
              )}
              <Page.Modal.Title>
                {selectedBadge && selectedBadge.badge.filename}
                {certificate && certificate.filename}
              </Page.Modal.Title>
              {selectedBadge && (
                <Page.Modal.Details>
                  {selectedBadge.badge.description}
                </Page.Modal.Details>
              )}
              {certificate && (
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.downloadCertificate}
                >
                  {t('v2:download_certificate')}
                </Button>
              )}
            </Page.Modal>
          </Modal>
        )}

        <Modal
          title="Alert!"
          visible={revokeModal}
          onOk={this.revokeIntegrationStatus}
          onCancel={this.toggleRevokeModal}
          footer={[
            <Button key="back" onClick={this.toggleRevokeModal}>
              {t('buttons:cancel')}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={revokeLoading}
              onClick={this.revokeIntegrationStatus}
            >
              {t('general:yes')}
            </Button>
          ]}
        >
          <p>{t('v2:are_sure_to_disale_zoom_integration')}</p>
        </Modal>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('account')(Account))
