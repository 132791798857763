/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'tallenna',
    autosaved: 'tallennettu automaattisesti',
    back_to: 'takaisin',
    by: 'tekijältä',
    chat: 'chat',
    completed: 'valmis',
    canceled: 'Peruutettu',
    component: 'Komponentti',
    confirm: 'Vahvista',
    course: 'Kurssi',
    date: 'Päivämäärä',
    details: 'Lisätietoja',
    empty: 'Ei tietoja',
    enrolled: 'Osallistunut',
    free: 'Ilmainen',
    graph: 'Graafi',
    loading: 'Ladataan...',
    lesson: 'oppitunnit',
    lessons: 'oppitunnut',
    live_assessment: 'Live arviointi',
    live_event: 'Live tapahtuma',
    no: 'Ei',
    of: 'sta',
    or: 'tai',
    organization: 'Organisaatio',
    page: 'Sivu',
    pages: 'Sivut',
    personal: 'Henkilökohtainen',
    poll: 'Kysely',
    rating: 'Arviointi',
    reset: 'Nollaa',
    signin: 'Kirjaudu sisään',
    signup: 'Rekisteröidy',
    soon: 'Pian',
    type: 'Tyyppi',
    uploading: 'Ladataan...',
    yes: 'Kyllä',
    something_went_wrong: 'Jokin meni vikaan!',
    view_all: 'Näytä kaikki',
    upload_successful: 'Lataus onnistui',
    upload_successful_message:
      'Excel tiedostosi on ladattu onnistuneesti ja se prosessoidaan. Tähän saattaa mennä hetki.',
    check_email_upload_successful:
      'Tarkista <0>sähköpostiosoitteesi</0> <1>report</1> ladatussa käyttäjälistassa',
    is_private: 'Yksityinen'
  },

  account: {
    title: 'Tili',
    subtitle: 'Hallitse asetuksia ja tilin tietoja.',
    saved: 'Tili muutettu onnistuneesti'
  },

  assessments: {
    title: 'Arvioinnit',
    subtitle: 'Luo arviointi',
    finished: 'Arviointi on valmis',
    countdown: 'Live arviointi lähetetään ',
    create: 'Luo arviointi',
    list_title: 'Arviointisi',
    delete_warning: 'Haluatko varmasti poistaa tämän kohteen?',
    details_title: 'Lisää arvioinnin tiedot',
    details_subtitle:
      'Nimeä arviointisi ja kerro yleisöllesi mitä heidän tulee oppia siitä',
    disclaimer: `Takaan, että tallentamani arviointi ei riko:
- riko kolmannen osapuolen oikeuksia
- sisällä laitonta, loukkaavaa, julkeaa, epäsopivaa, pornografista, väkivaltaista, hyväksikäyttöistä, nimittelevää, uhkailevaa tai syrjivää sisältöä, edes asteriskeiksi naamioituneena
- sisällä henkilökohtaisia hyökkäyksiä toista ihmistä kohtaan
- sisällä toisia osallistujia esittävää sisältöä, mukana lukien julkiset henkilöt ja julkisuuden henkilöt
- sisällä tallenteita henkilökohtaisista tiedoista tai henkilöistä ilman heidän erillistä lupaansa, eikä riko minkään maailman osan tietosuojalakeja
- sisällä kaupallista sisältöä tai mitään ”spam” sisältöä'`,
    assessment_details: 'Arvioinnin tiedot',
    assessment_settings: 'Arvioinnin asetukset'
  },

  buildersettings: {
    title: 'Julkaise asetukset',
    subtitle: 'Lisää asetukset tapahtumaasi, ketkä voivat katsoa tapahtumaasi'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Lisää',
    add_page: 'Lisää sivu',
    add_lesson: 'Lisää oppitunnit',
    add_answer: 'Lisää uusi vastaus',
    add_group: 'Lisää ryhmä',
    add_organization: 'Lisää organisaatio',
    add_question: 'Lisää kysymys',
    add_quiz: 'Lisää kysely',
    add_users: 'Lisää käyttäjiä',
    back: 'Takaisin',
    back_to_course: 'Takaisin kurssin yleiskatsaukseen',
    back_to_lesson: 'Takaisin oppitunnin yleiskatsaukseen',
    back_to_media: 'Takaisin mediakirjastoon',
    back_to_details: 'Takaisin tietoihin',
    cancel: 'Peruuta',
    clear: 'Tyhjää',
    close: 'Sulje',
    clone: 'Kloonaa',
    close_lesson: 'Sulje oppitunti',
    clone_course: 'Kloonaa kurssi',
    confirm: 'Vahvista',
    create: 'Luo',
    download: 'Lataa',
    download_mass_user_tamplate: 'Lataa pohja',
    add_mass_users: 'Lisää massa käyttäjiä',
    upload_excel_list: 'Lataa Excel-lista',
    delete: 'Poista Media',
    edit: 'Muokkaa',
    edit_course: 'Muokkaa kurssia',
    enroll: 'Rekisteröidy',
    finish: 'Valmis',
    upload_image: 'Lataa kuva',
    upload_image_video: 'Lataa kuva tai video',
    select_media_library: 'Valitse mediakirjasto',
    make_public: 'Tee julkiseksi',
    make_private: 'Tee yksityiseksi',
    make_org: 'Tee organisaatio',
    media: 'Mediakirjasto',
    my_theme: 'Oma teema',
    next: 'Seuraava',
    next_lesson: 'Seuraava oppitunti',
    next_step: 'Seuraava vaihe',
    previous_step: 'Edellinen vaihe',
    publish: 'Julkaise',
    publish_assessment: 'Julkaise arviointisi',
    publish_course: 'Julkaise kurssisi',
    publish_event: 'Julkaise tapahtumasi',
    reset: 'Nollaa',
    reset_elements: 'Nollaa elementit',
    save: 'Tallenna',
    creating: 'Luodaan...',
    updating: 'Päivitetään...',
    saved: 'Tallennettu',
    save_for_later: 'Tallenna myöhemmäksi',
    save_settings: 'Tallenna asetukset',
    select_files: 'Valitse tiedostot',
    select_theme: 'Valitse teema',
    start_lesson: 'Aloita oppitunti',
    update: 'Päivitä',
    upload: 'Lataa',
    upload_audio: 'Lataa Audio',
    upload_photo: 'Lataa profiilikuva',
    upload_video: 'Lataa Video',
    enrolled: 'Rekisteröitynyt',
    unenroll: 'Peruuta rekisteröityminen',
    from_library: 'Mediakirjasto',
    remove: 'Poista',
    removed: 'Poistettu',
    start_broadcast: 'Aloita lähetys',
    end_broadcast: 'Lopeta lähetys',
    camera: 'Kamera',
    screen: 'Näyttö',
    screen_with_camera: 'Näyttö kameralla',
    insert: 'Syötä',
    inserting: 'Syötetään'
  },

  card: {
    quick_add: 'Pikalisäys',
    add: 'Lisää',
    clone: 'Kloonaa',
    completed: 'oppituntia suoritettu'
  },

  course: {
    upcoming_course: 'Tuleva kurssi',
    unPublishEvent: 'Peruuta tapahtuman julkaisu',
    unPublishAssessment: 'Peruuta arvioinnin julkaisu',
    pastCourse: 'Harjoittelukurssi',
    upcoming: 'Tuleva livetapahtuma',
    pastEvent: 'Mennyt livetapahtuma',
    pastAssessment: 'Mennyt Live arviointi',
    upcomingAssessment: 'Tuleva Live Arviointi',
    created: 'Luomispäivä:',
    assignees: 'Arvioitavat',
    organisations:
      ' {{ organisaatiot }} Organisaatiot, <2> {{ käyttäjät }} käyttäjät</2>',
    event_date: 'Livetapahtuman päivämäärä <1> {{ date }} </1>',
    delete_warning: 'Haluatko varmasti poistaa tämän',
    clonning_no_lesson:
      'Lisää vähintään yksi oppitunti kloonataksesi tämän kurssin.',
    clone_warning: 'Haluatko varmasti kloonata tämän',
    clone_success: 'kloonataan',
    disclaimer: `Takaan, että kurssini ei:
- riko kolmannen osapuolen oikeuksia
- sisällä laitonta, loukkaavaa, julkeaa, epäsopivaa, pornografista, väkivaltaista, hyväksikäyttöistä, nimittelevää, uhkailevaa tai syrjivää sisältöä, edes asteriskeiksi naamioituneena
- sisällä henkilökohtaisia hyökkäyksiä toista ihmistä kohtaan
- sisällä toisia osallistujia esittävää sisältöä, mukana lukien julkiset henkilöt ja julkisuuden henkilöt
- sisällä tallenteita henkilökohtaisista tiedoista tai henkilöistä ilman heidän erillistä lupaansa, eikä riko minkään maailman osan tietosuojalakeja
- sisällä kaupallista sisältöä tai mitään ”spam” sisältöä'`
  },

  coursecreate: {
    dropzone_text: 'Syötä media',
    sidebar_text: 'Valitse teema <1/>omalle<3/> oppitunnille',
    delete_warning: 'Haluatko varmasti poistaa tämän oppitunnin?'
  },

  coursepage: {
    quizview_loading: 'Tarkistetaan vastauksia. Odota...',
    quizview_answer: 'Vastauksesi'
  },

  coursequiz: {
    title: 'Kysymykset kaikissa oppitunneissa'
  },

  dashboard: {
    tooltip_title: 'Älä näytä tätä videota uudestaan',
    page_title: 'Kurssisi tilastot',
    page_subtitle: 'Luomiesi kurssien status',
    profile_add_info:
      'Lisää <1>{{uncompletedText}}</1> viimeistelläksesi profiilisi.',
    profile_build:
      'Rakenna profiilisi <1>täällä</1>, kerro meille kiinnostuksen kohteistasi ja taidoistasi.',
    profile_title: 'Profiilin vahvuus',
    more_details: 'Lisää tietoja'
  },

  errors: {
    answer: 'Syötä vastauksesi',
    answer_correct: 'Valitse oikea vastaus',
    code: 'Syötä koodisi',
    criterion: 'Syötä kriteeri',
    duration: 'Kesto vaaditaan',
    element: 'Syötä elementti',
    email: 'Syötä sähköpostiosoitteesi',
    email_invalid: 'Sähköposti ei ole kelvollinen',
    feedback_correct: 'Syötä palaute oikein',
    feedback_incorrect: 'Syötä palaute väärin',
    host: 'Syötä tapahtuman isäntä',
    host_assessment: 'Syötä arvioinnin isäntä',
    image: 'Syötä kuva',
    location: 'Syötä sijaintisi',
    live_location: 'Valitse lähetyksen sijainti',
    limit: 'Syötä livelähetyksen minuutit',
    min_3char: '3 merkkiä vähintään',
    number: 'syötä positiivisia numeroita.',
    host_email_require: `Isännän sähköpostit vaaditaan.`,
    attendee_email_require: `Osallistujan sähköpostit vaaditaan.`,
    name: 'Syötä nimesi',
    not_found_course: 'Kurssia ei löydy',
    not_found_event: 'Tapahtumaa ei löydy',
    not_found_assessment: 'Arviointia ei löydy',
    password: 'Syötä salasanasi',
    password_digit: 'Vähintään yksi numero',
    password_short: 'Salasana on liian lyhyt, vähintään 10 merkkiä',
    password_special: 'Vähintään yksi erikoismerkki',
    password_uppercase: 'Vähintään yksi iso kirjain',
    password_lowercase: 'Vähintään yksi pieni kirjain',
    phone: 'Syötä puhelimesi',
    phone_invalid: 'Puhelinnumero ei kelpaa',
    position: 'Syötä asemasi',
    question: 'Syötä kysymys',
    start_date: 'Aloitusaika vaaditaan',
    tag: 'Valitse tag',
    topics: 'Valitse vähintään yksi aihe.',
    live_topics: 'Lisää aiheet',
    title: 'Syötä otsikko',
    title_assessment: 'Syötä arvioinnin otsikko',
    title_course: 'Syötä kurssin otsikko',
    title_empty: 'Otsikko ei voi olla tyhjä',
    title_event: 'Syötä tapahtuman otsikko',
    title_lesson: 'Syötä oppitunnin otsikko',
    title_quiz: 'Syötä kyselyn nimi',
    username: 'Syötä käyttäjänimesi',
    upload_error: 'Error – Tiedosto ei ole yhteensopiva!',
    upload_more_than_10: 'Voit ladata korkeintaan 10 tiedostoa kerrallaan.',
    topics_max: 'Lisää korkeintaan 3 aihetta.',
    upload_excel: 'Varmista, että olet ladannut oikean tiedoston.',
    title_excel: 'Virhe pohjan lataamisessa:',
    file_size_exceed: 'Tiedostokoko ylitetty',
    subtitle_excel:
      'Varmista, että käytät annettua pohjaa ja että sarakkeet ovat oikein.',
    select_broadcast_option: 'Valitse lähetystapa tapahtumalle',
    multi_hosts_number_error:
      'Esittäjien määrä ei saa ylittää tapahtumassa määritettyä.',
    conference_people_number_error: `Osallistujien määrä ei saa ylittää tapahtumassa määritettyä määrää.`,
    select_max_host: 'Valitse vähintään 1 esittäjä tapahtumalle.',
    select_max_conference: 'Valitse vähintään 1 osallistuja tapahtumaan.',
    stream_stop_error: 'Lähetys loppui odottamattomasti!',
    unsecure_context:
      'Fatal Error: Selain ei voi käyttää kameraa ja mikrofonia epävarmojen olosuhteiden takia. Asenna SSL ja käytä hhhtps-yhteyttä',
    ws_not_supported: 'Fatal Error: WebSocket ei tuettu tällä selaimella',

    no_org: 'Valitse vähintään 1 organisaatio jatkaaksesi.',
    no_org_group: 'Valitse vähintään 1 organisaatioryhmä jatkaaksesi.',
    waiting_to_join: 'Odotetaan jonkun liittymistä!'
  },

  events: {
    title: 'Lisää livetapahtuman tiedot',
    change_host: 'Vaihda isäntää',
    finished: 'Tapahtuma on valmis',
    countdown: 'Livetapahtuma lähetetään ',
    subtitle:
      'Nimeä tapahtumasi ja kerro yleisölle mitä heidän pitäisi odottaa.',
    poll_disabled: 'Kysely ei saatavilla tässä tapahtumassa',
    disclaimer: `Takaan, että tallentamani tapahtuma ei:
- riko kolmannen osapuolen oikeuksia
- sisällä laitonta, loukkaavaa, julkeaa, epäsopivaa, pornografista, väkivaltaista, hyväksikäyttöistä, nimittelevää, uhkailevaa tai syrjivää sisältöä, edes asteriskeiksi naamioituneena
- sisällä henkilökohtaisia hyökkäyksiä toista ihmistä kohtaan
- sisällä toisia osallistujia esittävää sisältöä, mukana lukien julkiset henkilöt ja julkisuuden henkilöt
- sisällä tallenteita henkilökohtaisista tiedoista tai henkilöistä ilman heidän erillistä lupaansa, eikä riko minkään maailman osan tietosuojalakeja
- sisällä kaupallista sisältöä tai mitään ”spam” sisältöä'`,
    prompt_message:
      'Tältä sivulta lähteminen lopettaa tapahtumasi. Haluatko varmasti lähteä tältä sivulta?',
    duration_exceed_message:
      'Lähetyksesi aika ylittyi, Sovellus sulkee lähetyksen.',
    before_schedule_message:
      'Olet aloittamassa lähetyksen ennen ajastettua aikaa. Ota huomioon, että lähetyksen lopettaminen ei lopeta tapahtumaa.',
    browser_unsupportive_message:
      'Selaimesi ei tue näytön jakamista. Voit nähdä tuetut selaimet tästä linkistä',
    cancel_switch_host_message:
      'Isäntä ei ole saatavilla. Tapahtuma jatkuu sinun kauttasi.',
    coaching_prompt_message:
      'Et voi lähteä tältä sivulta, ennen kuin tapahtuma on loppunut.',
    event_modal_warn: 'Haluatko lähteä tästä tapahtumasta?',
    confirm_message: 'Tapahtumasi päättyy, jos painat OK.',
    screen_share_denied: 'Kieltäydyit ruudun jakamisesta',
    media_not_found:
      'Kameraa tai mikrofonia ei löydy tai ole sallittu laitteellasi.',
    media_not_access:
      'Kamera tai mikrofoni on jonkin toisen prosessin käytössä',
    constraint_error:
      'Saatavilla ei ole laitetta, joka sopii video ja audio vaatimuksiin. Voit muuttaa video ja audio vaatimuksia',
    media_access_denied: 'Sinulla ei ole lupaa käyttää kameraa ja mikrofonia.'
  },

  filters: {
    days7: 'Viimeiset 7 päivää',
    days15: 'Viimeiset 15 päivää',
    days30: 'Viimeiset 30 päivää',
    days365: 'Viimeiset 365 päivää',
    date: 'Luomispäivä',
    relevance: 'Sopivuus',
    popularity: 'Suosio',
    name: 'Nimi',
    group: 'Ryhmä',
    Newest_to_Oldest: 'Uusimmasta vanhimpaan',
    Oldest_to_Newest: 'Vanhimmasta uusimpaan',
    A_Z: 'A - Z',
    Z_A: 'Z - A'
  },

  helpers: {
    password:
      'Taataksesi tilisi turvallisuuden, vaadimme vähintään yhden pienen kirjaimen ja numeron salasanassasi. Salasanan pituus on myös oltava vähintään 10 merkkiä.',
    phone: 'Laita puhelinnumeroon maakohtainen numero, esimerkiksi: +65',
    username:
      'Käyttäjänimeäsi pidetään julkisena tietona ja se voidaan jakaa muille tarpeen vaatiessa. Emme suosittele laittamaan henkilökohtaisia tietoja käyttäjänimeesi',
    public_description_normal:
      'Sinun <0><0/></0> on tallennettu ja sitä käsitellään oletuksena yksityisenä. Tämä tarkoittaa sitä, että vain sinä voit nähdä tämän sisällön. Julkaistaksesi tämän sisällön, valitse yksi alla olevista vaihtoehdoista. Kurssisi asettaminen julkiseksi tarkoittaa sitä, että kaikki alustan käyttäjät voivat käyttää sitä. Tiettyjen käyttäjien asetukset mahdollistavat kurssin jakamisen tietyille käyttäjille Sharelook-sähköpostin kautta.',
    public_description_admin:
      'Tämä <0><0/></0> on tallennettu ja sitä pidetään oletuksena Yksityisenä. Tämä tarkoittaa sitä, että vain sinä (ja sisällön tuottaja) näkevät tämän sisällön. Julkaistaksesi tämän sisällön, valitse yksi alla olevista vaihtoehdoista. Kurssisi asettaminen julkiseksi tarkoittaa sitä, että kaikki alustan käyttäjät voivat käyttää sitä – mukaan lukien organisaatiosi jäsenet. Organisaatiolle julkaiseminen tarkoittaa, että kaikki organisaation jäsenet näkevät sisällön- Voit myös julkaista 1 tai useammalle ryhmälle, tarkoittaen, että vain näiden ryhmien jäsenet näkevät sen. Voit myös julkaista tietyille käyttäjille organisaatiosi sisällä ja sen ulkopuolella käyttämällä heidän Sharelook-sähköpostiosoitetta.'
  },

  labels: {
    add: 'Lisää',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Vastauksen tyyppi',
    answers: 'Vastaukset',
    back_to_quiz: 'Takaisin kyselylistaan',
    back_to_question: 'Takaisin kysymyslistaan',
    chat: 'Chat',
    chat_available: 'Chat ei ole saatavilla tässä tapahtumassa',
    chat_report: 'Chat Report',
    choice_single: 'Yksivalinta',
    choice_multiple: 'Monivalinta',
    choice_free: '"Vapaa" valinta',
    choice_sorting: '"Lajittelu" valinta',
    choice_matrix: '"Matrix lajittelu" valinta',
    clone_lesson_permission: 'Kloonaa lupa',
    code: 'Koodi',
    correct: 'Oikein',
    created: 'Luomispäivä',
    clone_modal: 'Kloonaa kurssi',
    cloning_course: 'Kloonataan kurssia...',
    cloning: 'Kloonataan...',
    cloning_course_desc: 'Tähän prosessiin kuluu hetki. Odota kärsivällisesti.',
    cloning_success: 'Kurssi "<0/>" on kloonattu onnistuneesti!',
    description: 'Kuvaus',
    disclaimer: 'Disclaimer',
    drop_file: 'Pudota tiedosto tähän...',
    duration: 'Kesto',
    edit: 'Muokkaa',
    email: 'Sähköposti',
    event: 'Tapahtuma',
    event_all: 'Kaikki tapahtumat',
    event_date: 'Live Livetapahtuman päivä/aika',
    event_this: 'Tämä tapahtuma',
    expire: 'Voimassaolo lakkaa',
    expiry_date: 'Eräpäivä',
    forgot: 'Unohditko salasanan?',
    group: 'Ryhmä',
    groups_list: 'Ryhmien lista',
    host: 'Isäntä',
    id_annotation: 'Merkinnän id',
    id_quiz: 'Kyselyn id',
    incorrect: 'Väärin',
    interests: 'Kiinnostuksen kohteet',
    invited: 'Kutsuttu',
    live_chat: 'Live Keskustelu',
    live_stream_limit: 'Live-lähetyksen minuutit',
    contact_text:
      'Vaihtaaksesi kuukausittaista lähetysrajaa, valitse ota yhteyttä',
    contact_admin:
      '<0>Jos haluat muuttaa kuukausittaista livelähetyksen rajaa, ota yhteyttä y</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Live-lähetyksen minuutteja jäljellä: <0>{{ limit }}</0> minuuttia <1/><2/> <3>Vaihtaaksesi kuukausittaista lähetysrajaa, valitse ota yhteyttä</3> <4>admin@sharelook.com</4>',
    location: 'Sijainti',
    lessons: 'Oppitunnit',
    location_broadcast: 'Isännän palvelimen sijainti',
    location_broadcast_subtitle:
      'Lisää sijainti, joka on lähimpänä isännän sijaintia vähentääksesi viivettä',
    media_manager: 'Media manager',
    minutes: 'Minuuttia',
    name: 'Nimi',
    online_quiz: 'Online Quiz',
    organiser: 'Tekijä',
    organization: 'Organisaatio',
    overall_emotion: 'Yleinen kokemus:',
    organizations_list: 'Lista organisaatioista',
    password: 'Salasana',
    participants: 'Osallistujat',
    picture: 'Kuva (16:9)',
    phone: 'Puhelin',
    photo: 'Kuva',
    poll: 'Kysely',
    position: 'Sijainti',
    processing: 'Prosessoidaan',
    privacy_settings: 'Yksityisyysasetukset',
    question: 'Kysymys',
    quiz_create: 'Luo uusi kysely',
    quiz_name: 'Kyselyn nimi',
    quiz_update: 'Päivitä kysely',
    select_organization: 'Valitse organisaatio',
    settings: 'Asetukset',
    show: 'Näytä',
    show_graph: 'Näytä graafin tulos',
    signin: 'Kirjaudu sisään',
    signup: 'Rekisteröidy',
    sort_by: 'Järjestä',
    start_date: 'Alkamispäivä',
    tags: 'Tagit',
    terms: 'Hyväksyn nämä <1>käyttöehdot</1>.',
    title: 'Otsikko',
    topics: 'Aiheet',
    topic: 'Aihe',
    total_comments: 'Kommentteja yhteensä',
    username: 'Käyttäjänimi',
    users_list: 'Käyttäjälista',
    users_emails: 'Syötä tiettyjen käyttäjien sähköpostit',
    description_specifiction_email:
      'Syötä yksi kelvollinen sähköpostiosoite per linja jakaaksesi monelle käyttäjälle.',
    message_specific_email: 'Anna tietyn käyttäjän sähköpostiosoite.',
    viewers: 'Katsojat',
    edit_course: 'Muokkaa',
    edit_assessment: 'Muokkaa',
    edit_event: 'Muokkaa',
    delete_course: 'Poista',
    delete_event: 'Poista',
    delete_assessment: 'Poista',
    view_course: 'Näytä',
    view_event: 'Näytä',
    view_assessment: 'Näytä',
    date_event: 'Live tapahtuman päivämäärä',
    date_assessment: 'Live arvioinnin päivämäärä',
    clone_of: 'Kloonaa',
    clone_course:
      'Luo klooni tästä kurssista, jota voit muokata itsenäisesti, mukaan lukien oppitunnit ja sivut.',
    make: 'Tee',
    available_to: 'saatavilla kaikille ShareLook käyttäjille',
    uploaded_by: 'Lataaja: ',
    course: 'Kurssi',
    assessment: 'Arviointi',
    user_not_exist: 'Käyttäjää ei ole olemassa, haluatko lähettää kutsun?',
    check_user: 'Huomautus',
    user_not_exist_in_org:
      'Käyttäjää ei ole olemassa organisaatiossa, haluatko lähettää kutsun?',
    title_modal_stream: 'Muuta organisaation livelähetysrajaa',
    des_warning_stream: 'Olet muuttamassa',
    monthly_stream: 'kuukausittaista livelähetysten rajaa:',
    min_per_month: 'minuuttia/kuukausi',
    broadcast_label: 'Haluan tehdä:',
    hosts_emails: `Syötä tiettyjen isäntien sähköpostit`,
    attendee_emails: `Syötä tiettyjen osallistujien sähköpostit`,
    description_host_specifiction_email:
      'Syötä yksi sähköpostiosoite per linja.',
    presenter_label: 'Esittäjä',
    presenter_label_conference: 'Ihmisten määrä',
    event_privacy: 'Konferenssitila'
  },

  broadcastOptions: {
    stream_from_phone: 'Live lähetys puhelimellani',
    other_webrtc: 'Webinaari tietokoneeltani',
    multihost: 'Multicast webinaari tietokoneeltani',
    peer2peer: 'Koulutussessio',
    conference: 'Live konferenssi'
  },

  lessoncreate: {
    title: 'Lisää oppitunnin tiedot',
    subtitle: 'Nimeä oppituntisi ja kerro yleisölle mitä haluat oppia.',
    input_label_title: 'Oppitunnin otsikko',
    input_label_topics: 'Oppitunnin aiheet',
    input_label_description: 'Oppitunnin kuvaus',
    delete_warning: 'Haluatko varmasti poistaa tämän sivun?'
  },

  lessonoverview: {
    title: 'Lisää oppitunti',
    subtitle: 'Lisää olemassa oleva oppitunti tai luo uusi',
    new_lesson: 'luo uusi oppitunti',
    new_lesson_sub: 'Luo uusi oppitunti alusta!',
    existing_lesson: 'Valitse olemassa oleva oppitunti',
    existing_lesson_sub:
      'Yhdistä olemassa olevaan oppituntiin, muutokset vaikuttavat muihin kursseihin.',
    clone_lesson: 'kopioi olemassa oleva oppitunti',
    clone_lesson_sub:
      'Tee uniikki kopio tästä kurssita, tämän kurssin muutokset eivät vaikuta alkuperäiseen versioon.',
    clone_lesson_modal_sub:
      'Klikkaa kloonausnappia sen oppitunnin alla, jonka haluat lisätä kurssille.'
  },

  management: {
    groups_title: 'Hallitse ryhmiä',
    delete_warning: 'Oletko varma?',
    organizations_title: 'Organisaationi',
    organizations_subtitle: 'Lisää organisaatio listaasi',
    users_title: 'Hallitse käyttäjiä',
    users_position: 'Senior Account Manager',
    user_not_found: 'Käyttäjää ei löydy',
    select_org: 'Valitse organisaatio hallitaksesi käyttäjiä'
  },

  audio: {
    modal_title: 'Syötä Audio',
    list_view: 'Listanäkymä',
    create_audio_modal_title: 'Luo Audio',
    edit_audio_modal_title: 'Muokkaa Audio',
    public_audio_not_found: 'Audio ei löydy.',
    private_audio_not_found: 'Et ole ladannut Audio tiedostoja',
    are_you_sure_to_delete: 'Haluatko varmasti poistaa?',
    type_something: 'Kirjoita jotain',
    select_voice: 'Valitse',
    file_name: 'Tiedoston nimi',
    description: 'Kuvaus',
    topic: 'Aihe',
    key_words_tag: 'Avainsanat Tag',
    mp3_audio: 'MP3 Audio',
    created: 'Luotu',
    duration: 'Kesto',
    close: 'sulje'
  },

  image: {
    modal_title: 'Syötä kuva',
    image: 'Kuva',
    pixel: 'Pikseli',
    dimension: 'Pituus',
    public_image_not_found: 'Kuvaa ei löydy.',
    private_image_not_found: 'Et ole vielä ladannut kuvaa'
  },

  annotation: {
    modal_title: 'Merkintä'
  },

  text: {
    modal_title: 'Tekstieditori'
  },

  video: {
    modal_title: 'Syötä Video',
    image: 'Video',
    mp4_video: 'MP4 Video',
    public_video_not_found: 'Video ei löytynyt.',
    private_video_not_found: 'Et ole vielä ladannut videoita'
  },

  media: {
    title: 'Mediakirjasto',
    subtitle: 'Tiedostojesi kirjasto',
    modal_title: 'Lataa media',
    delete_warning: 'Vahvista poisto',
    delete_message:
      'Poistettu media näkyy silti sivuilla, joissa sitä on käytetty',
    dropzone_text: 'tai raahaa ja pudota',
    delete_success: 'Media on poistettu onnistuneesti',
    upload_file: 'Lataa tiedosto',
    drag_drop: 'tai raahaa ja pudota',
    import_file: 'Tuo tiedosto(t) tietokoneeltasi',
    no_media: 'Et ole vielä ladannut mediaa',
    media_type: 'Median tyyppi',
    video: 'Video',
    image: 'Kuva',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Mediaa ei löytynyt.',
    of: 'paikasta',
    disclaimer: `Takaan, että mediani ei:
- riko kolmannen osapuolen oikeuksia
- sisällä laitonta, loukkaavaa, julkeaa, epäsopivaa, pornografista, väkivaltaista, hyväksikäyttöistä, nimittelevää, uhkailevaa tai syrjivää sisältöä, edes asteriskeiksi naamioituneena
- sisällä henkilökohtaisia hyökkäyksiä toista ihmistä kohtaan
- sisällä toisia osallistujia esittävää sisältöä, mukana lukien julkiset henkilöt ja julkisuuden henkilöt
- sisällä tallenteita henkilökohtaisista tiedoista tai henkilöistä ilman heidän erillistä lupaansa, eikä riko minkään maailman osan tietosuojalakeja
- sisällä kaupallista sisältöä tai mitään ”spam” sisältöä'`,
    my_media: 'Oma Media',
    public_library: 'Julkinen kirjasto',
    organization_library: 'Organisaation kirjasto',
    upload_from_computer: 'Lataa tietokoneesta',
    create: 'Luo',
    all: 'Kaikki',
    male: 'Mies',
    female: 'Nainen',
    processing_audio: 'Prosessoidaan Audiota',
    create_annotation: 'Luo merkintä',
    popup_over_lesson_text:
      'Merkinnät ilmestyvät sivun ylälaitaan klikkauksen jälkeen',
    edit_detail: 'Muokkaa tietoja',
    delete: 'Poista',
    go_create_audio: 'Luo Audio',
    show_more_details: 'näytä lisää tietoja',
    show_less_details: 'näytä vähemmän tietoja',
    drop_files: 'Pudota tiedostot ja minne ladata',
    or: 'Tai',
    import_files: 'Tuo tiedostot tietokoneelta'
  },

  navigation: {
    account: 'Tili',
    assessments: 'Arvioinnit',
    dashboard: 'Etusivu',
    groups: 'Ryhmähallinta',
    logout: 'Kirjaudu ulos',
    organisations: 'Organisaatiot',
    organizations_users: 'Organisaatiot ja käyttäjät',
    reports: 'Raportit',
    schedule: 'Aikataulu',
    training: 'Harjoittelu',
    users: 'Käyttäjät',
    workshop: 'Oma Workshop',
    nowLive: 'lähetetään nyt'
  },

  onboarding: {
    step1_title: 'Vaihe 1 : Kerro meille itsestäsi',
    step1_subtitle: 'Mikä on nimesi? Mistä tulet? Mikä on työnkuvaus?',
    step2_title: 'Vaihe 2 : Valitse taidot, joita haluat parantaa',
    step2_subtitle:
      'Seuraavaksi, kerro meille mitä haluat ymmärtää paremmin. Ehkä jotain uutta? Uusi ala? Mitä lähimmäisesi tekee?'
  },

  pagebuilder: {
    title: 'Luo oppitunti',
    subtitle:
      'Aika luoda sisältöä yleisöllesi, jotta he voivat tuntea sinut. Voit laittaa kuvia, videoita tai infografeja',
    select_layout: 'Valitse sivun asettelu',
    template1: 'Tyhjä',
    template2: 'Otsikko & alaotsikko ',
    template3: ' Otsikko & Teksti',
    template4: 'Otsikko, Media & Teksti',
    template5: ' Otsikko & Media',
    template6: ' Otsikko & Kysely'
  },

  pageconstructor: {
    title: 'Luo ja kloonaa oppitunti',
    subtitle:
      'Aika luoda sisältöä yleisöllesi, jotta he voivat tuntea sinut. Voit laittaa kuvia, videoita tai infografeja'
  },

  passwordreset: {
    success_coded:
      'Olemme lähettäneet vahvistusviestin antamaasi osoitteeseen, kopioi koodi alla olevaan kenttään asettaaksesi uuden salasanan.',
    success_changed:
      'Olet asettanut uuden salasanan onnistuneesti. Voit nyt kirjautua sisään.'
  },

  placeholders: {
    search: 'Etsi',
    answer: 'Vastaa',
    chat: 'Sano jotain...',
    code: 'Koodi',
    criterion: 'Kriteeri',
    date_event: 'Valitse aika',
    date_start: 'Aloitusaika',
    date_expire: 'Voimassaoloaika',
    description: 'Kuvaus',
    elements: 'Lajittele elementit',
    email: 'Sähköpostiosoite',
    feedback_correct: 'Palaute oikein',
    feedback_incorrect: 'Palaute väärin',
    host: 'Isäntä',
    keywords: 'Etsi avainsana(t)',
    lesson_description: 'Oppitunnin kuvaus',
    location: 'Sijainti',
    minutes: 'Minuuttia',
    name: 'Nimi',
    organiser: 'Tekijä',
    organization: 'Organisaatio',
    password: 'Salasana',
    phone: 'Puhelin',
    photo: 'Kuva',
    picture: 'Kuva',
    point: 'Piste',
    position: 'Sijainti',
    question: 'Kysymys',
    quiz: 'Kyselyn nimi',
    search_course: 'Etsi kurssin mukaan',
    search_media: 'Etsi media',
    search_name: 'Etsi nimen mukaan',
    search_title: 'Etsi otsikon mukaan',
    search_training: 'Etsi harjoittelu',
    settings: 'Asetukset',
    tags: 'Tagit',
    title: 'Otsikko',
    topics: 'Aiheet',
    username: 'Käyttäjänimi',
    keyword: 'Avainsanat',
    max_500_characters: 'Max 500 merkkiä ',
    no_description: 'Tällä tiedostolla ei ole kuvausta',
    no_keyword: 'Tämä tiedosto ei tue avainsanoja',
    no_topics: 'Tällä tiedostolla ei ole aihetta',
    feedback: 'Viesti',
    contact: 'Miten voimme auttaa?'
  },

  publishsettings: {
    title: 'Julkaise asetukset',
    event_subtitle:
      'Lisää uusi asetus tapahtumaan, kuka voi katsoa ja osallistua tapahtumaan',
    assessment_subtitile:
      'Nimeä arviointisi ja kerro yleisölle, mitä heidän tulee oppia siitä',
    course_subtitle:
      'Lisää asetus kurssiin, kuka voi katsoa ja osallistua kurssiin',
    disclaimer: `1. Rekisteröinti

    Rekisteröimällä tilin hyväksyt meidän [Käyttäjäsopimuksen] ja olet lukenut [Yksityisyydensuojakäytäntö]



    2. Luo kurssi

    Käyttämällä työkalujamme ja luomalla sisältöä, hyväksyt [Käyttäjäsopimuksen] ja olet lukenut [Yksityisyydensuojakäytäntö]



    3. Luo tapahtuma

    Käyttämällä työkalujamme ja luomalla sisältöä, hyväksyt [Käyttäjäsopimuksen] ja olet lukenut [Yksityisyydensuojakäytäntö]


    4. Luo arviointi

    Käyttämällä työkalujamme ja luomalla arvioinnin, hyväksyt [Käyttäjäsopimuksen] ja olet lukenut [Yksityisyydensuojakäytäntö] `,
    dates_validation_error: 'Molemmat päivämäärät tulee syöttää.',
    emails_validation_error: 'Sähköpostilista on väärin.',
    specific_email_validation_error: 'Vain yksi sähköpostiosoite sallitaan.',
    i_agree_to_these: 'Hyväksyn nämä ',
    terms_and_conditions: 'käyttöehdot.',
    PublicDescription:
      'Ilmaiset itsenäiset käyttäjät: (yksityiset ja julkiset vaihtoehdot) Teidän [Course|Event|Assessment] on tallennettu ja sitä pidetään yksityisenä oletuksella. Tämä tarkoittaa sitä, että vain sinä voit nähdä tämän sisällön. Julkaistaksesi tämän sisällön, voit valita tehdä siitä julkisen alapuolella. Tämä tarkoittaa sitä, että kaikki alustan käyttäjät näkevät sisällön.',
    OrganisationsDescription:
      'Organisaatiokäyttäjät: ( ""Valmis"" sijaan käytä ""Julkaise"" ja tuo heidän julkaise sivulle kuten muutkin käyttäjät, mutta ilman julkaisvaihtoehtoja.) Sinun [Course|Event|Assessment] on tallennettu ja se on yksityinen. Tämä tarkoittaa sitä, että vain sinä ja organisaation järjestelmänvalvojat voivat nähdä sen. Käyttäjänä et voi julkaista tätä sisältöä organisaatiossa itsenäsi, koska se vaatii järjestelmänvalvojan oikeuksia. Ota yhteyttä järjestelmänvalvojaan ja pyydä heitä julkaisemaan sisällöt julkisesti koko organisaatiolle, tietyille ryhmille, tietyille ryhmille organisaatiossa tai tietyille käyttäjille.',
    OrganisationGroupsDescription:
      'Org Adminit: (Organisaation sisällä tehdylle sisällölle: Yksityinen, julkinen, organisaatio, ryhmät, tietyt käyttäjät): Tämä [Course|Event|Assessment] on tallennettu ja sitä pidetään yksityisenä oletuksella. Tämä tarkoittaa sitä, että vain sinä voit nähdä tämän sisällön. Julkaistaksesi tämän sisällön, valitse yksi alla olevista vaihtoehdoista. Kurssin julkaiseminen Julkiseksi tarkoittaa sitä, että kaikki alustan käyttäjät näkevät sen – mukaan lukien organisaation ulkopuoliset käyttäjät. Organisaatiolle julkaiseminen tarkoittaa sitä, että kaikki organisaation jäsenet näkevät sen. Voit myös julkaista sen 1 tai useammalle ryhmälle, tarkoittaen, että näissä ryhmissä olevat jäsenet voivat nähdä sen. Viimeiseksi, sinulla on vaihtoehto julkaista se tietyille käyttäjille organisaatiossasi ja sen ulkopuolella käyttämällä heidän Sharelook -tilinsä sähköpostiosoitetta.',
    SpecificUsersDescription:
      'Maksetut yksityiset käyttäjät: (yksityinen, julkinen ja tietyt käyttäjät) Teidän [Course|Event|Assessment] on tallennettu ja sitä pidetään yksityisenä oletuksella. Tämä tarkoittaa sitä, että vain sinä voit nähdä tämän sisällön. Julkaistaksesi tämän sisällön, valitse yksi alla olevista vaihtoehdoista. Kurssin julkaiseminen Julkiseksi tarkoittaa sitä, että kaikki alustan käyttäjät näkevät sen. Tietyt käyttäjät -vaihtoehto tarkoittaa sitä, että voit jakaa kurssin tietyille käyttäjille heidän Sharelook -tilinsä sähköpostiosoitteen kautta.'
  },

  quiz: {
    quiz_empty: 'Et ole vielä luonut kyselyä',
    questions_empty: 'Et ole vielä luonut kysymystä'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'Intia',
    asia_pacific_japan: 'Japani',
    asia_pacific_s_korea: 'Etelä-Korea',
    asia_pacific_singapore: 'Singapore',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgia',
    eu_germany: 'Saksa',
    eu_ireland: 'Irlanti',
    south_america_brazil: 'Brasilia',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Etelä-Carolina',
    us_east_virginia: 'Virginia',
    us_west_california: 'Kalifornia',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Raportit',
    workshop_report: 'Työpajan raportti',
    user_report: 'Käyttäjäraportti',
    organization_workshop: 'Organisaation työpaja',
    course: 'Kurssi',
    live_event: 'Live tapahtuma',
    workShop_graph: 'Työpajan graafi',
    organization_account: 'Organisaation tili',
    all: 'Kaikki',
    name: 'Nimi',
    id_number: 'ID Numero',
    email: 'Sähköpostiosoite',
    search_user_report: 'Etsi käyttäjäraportti',
    participant: 'Osallistuja',
    participants: 'Osallistujat',
    host: 'Isäntä',
    username: 'Käyttäjä',
    event: 'Tapahtuma',
    quiz: 'Kysely',
    assessment: 'Arviointi',
    time: 'Aika',
    no_recent_activity: 'Ei viimeaikaista toimintaa',
    workshops: 'Työpajat',
    courses: 'Kurssit',
    live_events: 'Livetapahtumat',
    live_assessments: 'Livearvioinnit',
    quizzes: 'Quizzes',
    overview: 'Yleiskatsaus',
    by: 'Tekijältä',
    views: 'Näyttökerrat',
    status: 'Tila',
    poll: 'Äänestys',
    comment: 'Kommentti',
    comments: 'Kommentit',
    no_comments: 'Ei kommentteja',
    no_polls: 'Ei äänestyksiä',
    live_date: 'Live päivämäärä',
    score: 'Pisteet',
    question: 'Kysymys',
    submit: 'Lähetä',
    recent_activity: 'Viimeaikainen toiminta',
    ago: 'Sitten',
    no_data_found: 'Tietoja ei löytynyt',
    start_end_live: 'Aloita/Lopeta live',
    join_leave_date: 'Liity / Lähde',
    watched_time: 'Katsottu aika',
    complete: 'Valmis',
    in_complete: 'Valmiissa',
    today: 'Tänään',
    seven_days: '7 Päivää',
    last_month: 'Viime kuukausi',
    one_year: '1 Vuosi',
    all_time: 'Koko ajan',
    report: 'Raportti',
    from_date: 'Päivästä',
    to_date: 'Päivään',
    all_workshop: 'Kaikki workshopit',
    all_participants: 'Kaikki osallistujat',
    live_assessment: 'Live arvioinnit',
    users: 'Käyttäjät',
    download: 'Lataa',
    recent: 'Viimeaikainen',
    WorkShop: 'Workshop',
    Activity: 'Toiminta',
    created_date: 'Luomispäivä',
    lessons: 'Oppitunnit',
    lesson: 'Oppitunti',
    completed: 'Suoritettu',
    report_download: 'Raportoi lataus',
    this_month: 'Tässä kuussa',
    search_course: 'Etsi kurssista',
    participants_graph: 'Osallistujien tilasto',
    viewed: 'Katsottu',
    enrolled: 'Rekisteröity',
    description: 'Kuvaus',
    topic: 'Aihe',
    analytic: 'Tilasto',
    quiz_list: 'Kyselylista',
    select_one: 'Valitse yksi',
    group_status: 'Ryhmän status',
    duration_min: 'Kesto(min)',
    submit_poll: 'Lähetä kysely',
    download_events: 'Lataa tapahtumat',
    search_event: 'Etsi tapahtumasta',
    duration: 'Kesto',
    total_participants: 'Osallistujia yhteensä',
    message: 'Viesti',
    avg_message: 'Avg. Viesti',
    per_message: 'PROSENTTIOSUUS VIESTEISTÄ / TAPAHTUMASTA'
  },

  signup: {
    success_step1:
      'Olemme lähettäneet vahvistuskoodin antamaasi sähköpostiosoitteeseen. Kopioi koodi alla olevaan kenttään vahvistaaksesi rekisteröitymisen.',
    success_step2: 'Kiitos. Olet rekisteröitynyt onnistuneesti.',
    privacy: `Hyväksyn Sharelookin <1> käyttöehdot</1> ja <3> yksityisyydensuojakäytännön</3>`
  },

  stream: {
    emotion_impressive: 'Vaikuttavaa',
    emotion_unimpressive: 'Ei vaikuttavaa',
    message_not_started: 'Tulossa pian',
    message_running: 'Live',
    message_processing:
      'Prosessoimme livelähetystä toistoa varten. Tarkista myöhemmin video ja jatka arvostelujen ja palautteen antamista'
  },

  tabs: {
    audience: 'Yleisö',
    course: 'Kurssin yleiskatsaus',
    details: 'Live tapahtuman tiedot',
    description: 'Kuvaus',
    host: 'Isäntä',
    lesson: 'Oppitunnin yliskatsaus',
    lessons: 'Oppitunnit',
    overview: 'Yleiskatsaus',
    page: 'Sivunrakentaja',
    publish: 'Julkaise',
    settings: 'Julkaise asetus',
    total_views: 'Näyttökerrat',
    live_participates: 'Live osallistujat',
    live_chats: 'Live Chatit',
    live_quiz: 'Live Kyselyt',
    trainer_rating: 'Kouluttajan taso',
    trainer_times: 'Kouluttajan ajat',
    courses_quizzes: 'Kurssin kyselyt',
    user_list: 'Käyttäjälista',
    discussion: 'Keskustelu',
    notes: 'Muistiinpanoni'
  },

  topics: {
    SALES_TRAINING: 'Kauppakoulutus',
    NEGOTIATION_TRAINING: 'Neuvottelukoulutus',
    CROSS_CULTURAL_AWARENESS: 'Kulttuurien välinen ymmärrys',
    NETWORKING_SKILLS: 'Verkostoitumistaidot',
    SOFTWARE_ARCHITECTURE: 'Ohjelmistoarkkitehtuuri',
    PROJECT_MANAGEMENT: 'Projektihallinta',
    DIGITAL_TRANSFORMATION: 'Digitaalinen muokkaus',
    IT_CONSULTING: 'IT konsultointi',
    MANAGEMENT_CONSULTING: 'Hallintokonsultointi',
    HR_CONSULTING: 'HR Konsultointi',
    INTERNET_OF_THINGS: 'Internet of Things (IoT)',
    CYBERSECURITY: 'Kyberturvallisuus',
    PRIVACY_AND_GDRP: 'Yksityisyys ja GDPR'
  },

  training: {
    title: 'Koulutus',
    title_assessment: 'Arvioinnit',
    subtitle: 'Kurssit ja livetapahtumat',
    enrolled_title: 'Osallistumani kurssit ja livetapahtumat',
    enrolled_subtitle: 'Kurssit ja Livetapahtumat, joihin olet osallistunut',
    enrolled_message:
      'Et ole tällä hetkellä osallistunut millekään kursseille tai tapahtumiin.',
    past_live_event_title: 'Menneet livetapahtumat',
    past_live_event_subTitle: 'Suoritetut livetapahtumat',
    past_live_event: 'Menneitä livetapahtumia ei ole.',
    recomended_title: 'Suositellut kurssit ja livetapahtumat',
    now_live_event_title: 'Nyt Live',
    now_live_event_subTitle: 'Tapahtumat, jotka ovat livenä pian',
    now_live_event: 'Tällä hetkellä ei ole livetapahtumia.',
    webinar_event_title: 'Webinaarikanava',
    webinar_event_subTitle: 'Suoritetut webinaarit',
    webinar_event: 'Tällä hetkellä ei ole webinaareja.',
    p2p_event_title: 'Valmennussessiot',
    p2p_event_subTitle: 'Suoritetut valmennussessiot',
    p2p_event: 'Tällä hetkellä ei ole valmennussessioita.',

    now_live_assessment_title: 'Nyt Live',
    now_live_assessment_subTitle:
      'Arvioinnit, jotka ovat livenä tai tulevat pian',
    now_live_assessment_message: 'Livearviointeja ei ole tällä hetkellä.',

    upcoming_assessment_title: 'Tulevat livearvioinnit',
    upcoming_assessment_subtitle: 'Tulevat livearvioinnit',
    upcoming_assessment_message:
      'Tulevia Livearviointeja ei ole tällä hetkellä..',

    past_live_assessment_title: 'Menneet livearvioinnit',
    past_live_assessment_subTitle: 'Suoritetut livearvioinnit',
    past_live_assessment_message: 'Menneitä livearviointeja ei ole.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     * <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     * <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Olemme kuratoineet kursseja ja livetapahtumia perustuen sinun <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Etsi koulutus',
    search_assessment: 'Etsi arviointi',
    recomended_message:
      'Tällä hetkellä ei ole suositeltuja kursseja ja tapahtumia.'
  },

  warnings: {
    delete: 'Oletko varma?'
  },

  workshop: {
    title: 'Oma Workshop',
    subtitle: 'Luo kurssi tai isännöi oma livetapahtuma',
    new_course: 'Luo uusi kurssi',
    new_event: 'Luo uusi livetapahtuma',
    new_assessment: 'Luo uusi livearviointi',
    courses_title: 'Luodut kurssit ja livetapahtumat',
    courses_subtitle:
      'Muokkaa & Julkaise luomasi kurssi ja suunnittele livetapahtuma',
    tags_course: 'Kurssi',
    tags_live: 'Livetapahtuma',
    tags_assessment: 'Livearviointi'
  },

  help_center: {
    sidebar: {
      title: 'Miten voimme auttaa?',
      back_to: 'Etusivu'
    },
    menu: {
      help_center: 'Tukikeskus',
      whats_new: 'Uutiset',
      feedback: 'Palaute',
      feedback_title: 'Lähetä palautetta Sharelookille',
      terms_and_conditions: 'Käyttöehdot',
      privacy_policy: 'Yksityisyydensuojakäytäntö',
      contact_us: 'Ota yhteyttä'
    },
    get_started: {
      title: 'Aloita Sharelook',
      description: {
        main:
          'OLEMME INTEGROINEED LIVEVIDEON HELPOSTI KÄYTETTÄVÄÄN KURSSINRAKENTAJAAN',
        tagline:
          'ShareLookin patentoidulla livelähetysjärjestelmällä voit luoda tapahtumia, automatisoida oppimista ja maksimoida tiedon jakamisen.'
      }
    },
    whats_new: {
      title: 'Aloita share look',
      description: {
        main:
          'Tiesitkö, että Tarinankerrontatyylisen oppimisen luominen on nopeaa ja halpaa? Sinun kannattaa yhdistää ShareLook työkalut ja oikeat talentit. Voit kysyä meiltä ”MITEN”',
        tagline: ''
      }
    },
    create_workshop: 'Miten luoda Workshop',
    create_course: 'Miten luoda kurssi',
    create_user: 'Miten luoda käyttäjä',
    create_group: 'Miten luoda ryhmä',
    create_webinar: 'Miten luoda webinaari',
    create_live_event: 'Miten luoda livetapahtuma',
    create_live_assessment: 'Miten luoda livearviointi',
    create_live_on_mobile: 'Tee livenä mobiililaitteella',
    personalize_profile: 'Personalisoi profiili',
    manage_group: 'Hallitse ryhmää',
    report: 'Raportti',
    add_annotations: 'Miten lisätä ilmoitukset/Popupit',
    add_audio: 'Miten lisätä Audio',
    add_header_and_footer: 'Header & Footer lisääminen',
    add_image: 'Miten lisätä kuva',
    add_quiz_content: 'Miten lisätä kyselysisältö',
    add_text_content: 'Miten lisätä tekstisisältö',
    add_user: 'Miten lisätä käyttäjä',
    add_video: 'Miten lisätä video',
    clone_course: 'Miten kloonata kurssi',
    find_live_broadcast: 'Miten löytää livelähetys',
    contact_us: {
      title: 'Ota yhteyttä',
      subject_placeholder: 'Syötä aihe*',
      content_placeholder: 'Syötä viesti*',
      subject_required: '*Aihe vaaditaan',
      content_required: '*Viesti vaaditaan',
      submit: 'Lähetä',
      cancel: 'Peruuta',
      sent: 'Lähetetty'
    },
    feedback: {
      title: 'Lähetä palaute Sharelookille',
      attach_title: 'Liitä tiedosto',
      choose: 'Valitse tiedosto',
      detail: 'Tiedosto jaetaan tukitiimille',
      content_placeholder: 'Syötä viesti*',
      content_required: '*Viesti vaaditaan',
      submit: 'Lähetä',
      cancel: 'Peruuta',
      sent: 'Lähetetty'
    }
  },
  discussion: {
    join_discussion: 'Liity keskusteluun'
  },
  notes: {
    title: 'Muistiinpanoni',
    add_note: 'Lisää uusi muistiinpano'
  }
}
