import React, { useEffect, useState } from 'react'
import {
  useRemoteVideoTileState,
  RemoteVideo,
  ContentShare,
  useContentShareState,
  useMeetingManager,
  useToggleLocalMute,
  useSelectAudioInputDevice,
  useRosterState,
  useActiveSpeakersState,
  MicrophoneActivity
} from 'amazon-chime-sdk-component-library-react'
// import { useLocation } from "react-router-dom";
import moment from 'moment'
import { Icon, Button } from 'antd'
import http from '../../../../services/api/http'
import userImage from '../../../../assets/user.svg'
import Whiteboard from '../../../Events/Preview/components/Whiteboard/App'

let globalAttendees
let globalEstimatedTimeToStop

export default function ChimeRecording(props) {
  const meetingManager = useMeetingManager()
  const reqURL = `events/${props.eventId}/chime/`
  let checkDurationInterval = null
  const eventTitle = `event_${props.eventId}`
  const selectAudioInput = useSelectAudioInputDevice()

  const { tiles, attendeeIdToTileId } = useRemoteVideoTileState()
  const { sharingAttendeeId } = useContentShareState()
  const activeSpeakers = useActiveSpeakersState()
  const { toggleMute } = useToggleLocalMute()
  const { roster } = useRosterState()

  const [estimatedTimeToStop, setEstimatedTimeToStop] = useState('')
  const [isDurationExceed, setDurationExceed] = useState(false)
  const [hasEnd, setHasEnd] = useState(false)

  const [isWhiteboardSharing, setIsWhiteboardSharing] = useState(false)
  const [whiteboardRequest, setWhiteboardRequest] = useState(0)
  const [whiteboardResponse, setWhiteboardResponse] = useState(0)

  const ATTENDEES = Object.values(roster)

  globalAttendees = ATTENDEES
  globalEstimatedTimeToStop = estimatedTimeToStop

  const activeTileId = activeSpeakers[0]

  const attendees = ATTENDEES.filter(
    item =>
      item.externalUserId.split('#')[1] !== 'recording' &&
      !item.externalUserId.split('#')[0].includes('passive')
  )

  const localChimeId = attendees[0] && attendees[0].chimeAttendeeId

  const [prevTileId, setPrevSpeakerId] = useState([null])

  useEffect(() => {
    if (activeSpeakers.length > 0) {
      setPrevSpeakerId(activeSpeakers[0])
    }
  }, [activeSpeakers.length])

  useEffect(() => {
    if (sharingAttendeeId || isWhiteboardSharing) {
      return
    }
    const localVideoElements = document.querySelectorAll('.local_video')
    const remoteVideoElement = document.querySelector(
      `.chimeId_${activeTileId}`
    )
    const previousVideoElement = document.querySelectorAll(
      `.chime_main__view.chimeId_${prevTileId}`
    )

    const activeSpeaker = attendees.filter(
      item => item.chimeAttendeeId === activeTileId
    )[0]

    if (activeTileId === localChimeId || !activeTileId || !activeSpeaker) {
      removeFromActiveSpeaker(previousVideoElement[0])
      localVideoElements.forEach(element => {
        makeActiveSpeaker(element)
      })
    } else if (activeTileId !== localChimeId) {
      makeActiveSpeaker(remoteVideoElement)

      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [activeTileId, sharingAttendeeId, attendees.length, isWhiteboardSharing])

  useEffect(() => {
    if (sharingAttendeeId) {
      const screenShareElement = document.getElementById('sharevideo')
      const localVideoElements = document.querySelectorAll('.local_video')
      const previousVideoElement = document.querySelectorAll(
        `.chime_main__view.chimeId_${prevTileId}`
      )

      makeActiveSpeaker(screenShareElement)
      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [sharingAttendeeId])

  useEffect(() => {
    if (isWhiteboardSharing) {
      const screenShareElement = document.getElementById('sharewhiteboard')
      const localVideoElements = document.querySelectorAll('.local_video')
      const previousVideoElement = document.querySelectorAll(
        `.chime_main__view.chimeId_${prevTileId}`
      )

      makeActiveSpeaker(screenShareElement)
      if (previousVideoElement && previousVideoElement.length > 0) {
        previousVideoElement.forEach(item => {
          removeFromActiveSpeaker(item)
        })
      } else {
        localVideoElements.forEach(element => {
          removeFromActiveSpeaker(element)
        })
      }
    }
  }, [isWhiteboardSharing])

  const makeActiveSpeaker = element => {
    if (element) {
      if (!element.classList.contains('chime_main__view')) {
        element.classList.add('chime_main__view')
      }
      if (element.classList.contains('remoteChimePlayer')) {
        element.classList.remove('remoteChimePlayer')
      }
    }
  }

  const removeFromActiveSpeaker = element => {
    if (element) {
      if (element.classList.contains('chime_main__view')) {
        element.classList.remove('chime_main__view')
      }
      if (!element.classList.contains('remoteChimePlayer')) {
        element.classList.add('remoteChimePlayer')
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      document.getElementById('join_event').click()
    }, 3000)
  }, [])

  // to unsubs the topic after close whiteboard
  useEffect(() => {
    if (!isWhiteboardSharing) {
      unsubscribeMessage(props.eventId)
    }
  }, [isWhiteboardSharing])

  // To get wb data
  useEffect(() => {
    const { eventId } = props
    try {
      meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage(
        `${eventId}-recording`,
        data => {
          data = JSON.parse(new TextDecoder().decode(data.data))

          if (data.state === 'response') {
            if (data.wbStatus) {
              setIsWhiteboardSharing(true)
            } else {
              setIsWhiteboardSharing(false)
            }
          }
        }
      )
    } catch (err) {
      console.log(err)
    }
  }, [meetingManager.audioVideo])

  const handleClick = () => {
    selectAudioInput(null)
    // toggleAudioRef.current.click()
    calculateStopDurationOnConference()
  }

  const startEvent = async () => {
    const payload = {
      title: eventTitle,
      name: 'recording#User',
      region: 'us-east-1'
    }
    const response = await http({
      method: 'PUT',
      url: `${reqURL}record-join`,
      data: payload
    })

    const { Meeting, Attendee } = response.data.JoinInfo
    const joinData = {
      meetingInfo: Meeting,
      attendeeInfo: Attendee
    }

    // Use the join API to create a meeting session
    await meetingManager.join(joinData)

    // At this point you can let users setup their devices, or start the session immediately
    await meetingManager.start()
    handleClick()

    checkDurationInterval = setInterval(() => {
      checkDurationExceed()
    }, 30000)
    // setTimeout(() => {
    //   document.getElementById('mute_event').click();
    // }, 2000)
  }

  const endEvent = async () => {
    setIsWhiteboardSharing(false)
    setHasEnd(true)
    const payload = {
      title: eventTitle
    }
    meetingManager.leave()
    const response = await http({
      method: 'PUT',
      url: `${reqURL}end-event-record`,
      data: payload
    })
    if (response) setHasEnd(false)
    return
  }

  // calculate duration for conference to stop event
  const calculateStopDurationOnConference = () => {
    const { startdatetime, duration } = props
    console.log('in check exceed duration', startdatetime, 'duration', duration)
    const estimatedTimeToStop = moment(startdatetime)
      .add(duration, 'm')
      .toDate()
    if (!isDurationExceed) {
      setEstimatedTimeToStop(estimatedTimeToStop)
    }
  }

  const checkDurationExceed = () => {
    if (globalAttendees === undefined) {
      return
    }
    const diffMinutes = moment().diff(globalEstimatedTimeToStop, 'minutes')
    // console.log('diffMinutes outside exceed duration', diffMinutes, globalAttendees,globalAttendees && globalAttendees.length<2);

    const filteredUser = globalAttendees.filter(attendee => {
      const indexOfRecordingUser = attendee.externalUserId.indexOf(
        'recording#User'
      )
      return indexOfRecordingUser === -1
    })

    if (
      (diffMinutes === 0 || diffMinutes > 0) &&
      filteredUser &&
      filteredUser.length < 1
    ) {
      // console.log('diffMinutes inside exceed duration', hasEnd,diffMinutes, filteredUser);
      setDurationExceed(true)
      clearInterval(checkDurationInterval)
      checkDurationInterval = null
      setEstimatedTimeToStop('')
      if (!hasEnd) {
        endEvent()
      }
    }
  }

  const unsubscribeMessage = topic => {
    try {
      meetingManager.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(topic)
    } catch (err) {
      console.log(err)
    }
  }

  const userSharingScreenId =
    sharingAttendeeId && sharingAttendeeId.split('#')[0]

  return (
    <div>
      <div className="conference_chime" style={{ height: '100vh' }}>
        <div className="scrollbar_chime">
          {attendees && attendees.length === 0 && (
            <>
              <div className="chime_main__view">
                <div className="disabled__container">
                  <h1 style={{ color: 'white', fontWeight: 'bolder' }}>
                    Waiting for host to join...
                  </h1>
                </div>
              </div>
            </>
          )}

          {attendees &&
            attendees.map((item, index) => {
              const remoteTileId = attendeeIdToTileId[item.chimeAttendeeId]

              const chimeId = item.chimeAttendeeId
              const tileItem = tiles.filter(item => item === remoteTileId)

              const isRemoteVideoEnabled = tileItem && tileItem.length > 0

              const userParam = item.externalUserId.split('#')
              const name = userParam[0]
              const id = userParam[1]
              return (
                <div
                  className={`${
                    index === 0
                      ? 'local_video chime_main__view'
                      : 'remoteChimePlayer'
                  } chimeId_${chimeId}`}
                  style={{
                    order: userSharingScreenId === chimeId ? -1 : 0
                  }}
                  key={chimeId}
                >
                  {isRemoteVideoEnabled ? (
                    <RemoteVideo tileId={remoteTileId} />
                  ) : (
                    <DisabledContainer id={id} />
                  )}
                  <VolumeControls name={name} chimeId={chimeId} />
                </div>
              )
            })}
          <ContentShare id="sharevideo" />

          {isWhiteboardSharing && (
            <Whiteboard
              meetingManager={meetingManager}
              eventId={props.eventId}
              isWhiteboardSharing={isWhiteboardSharing}
              whiteboardRequest={whiteboardRequest}
              whiteboardResponse={whiteboardResponse}
              setWhiteboardRequest={setWhiteboardRequest}
              setWhiteboardResponse={setWhiteboardResponse}
            />
          )}
        </div>
      </div>
      <Button
        id="join_event"
        className="button__hidden"
        onClick={() => startEvent()}
      >
        Join
      </Button>
      {/* <div ref={toggleAudioRef}>
        <Button
          id='mute_event'
          className='button__hidden'
          onClick={toggleMute}
          >
          Mute
        </Button>
      </div> */}
      <Button id="mute_event" className="button__hidden" onClick={toggleMute}>
        Mute
      </Button>
    </div>
  )
}

export const DisabledContainer = () => {
  return (
    <div className="disabled__container">
      <img alt="" src={userImage} className="disabled__video__image" />
      <div className="disabled__video__icon">
        <Icon className="video_off_icon" type="video-camera" />
      </div>
    </div>
  )
}

export const VolumeControls = ({ chimeId, name }) => (
  <div className="volume__controls">
    <button className={`volume_button`}>
      <MicrophoneActivity attendeeId={chimeId} />
    </button>
    {''}
    <span>{name}</span>
  </div>
)
