import React, { useMemo } from 'react'
import { List, Select, Spin } from 'antd'
const { Option } = Select

const AddParticipant = React.memo(props => {
  const {
    emailList,
    fetching,
    handleEmailChange,
    t,
    emails = [],
    event,
    invitedEmails = []
  } = props

  const filteredEmails = useMemo(
    () => emailList.filter(email => !invitedEmails.includes(email.value)),
    [emailList, invitedEmails]
  )

  const selectedEmails = useMemo(
    () => emails.filter(email => !invitedEmails.includes(email)),
    [emails, invitedEmails]
  )

  const isMaxParticipants = useMemo(
    () =>
      event &&
      event.event_type === 'webinar' &&
      selectedEmails.length + invitedEmails.length >= 5,
    [event, emails, invitedEmails]
  )
  return (
    <>
      <List
        bordered
        header={
          <h3>
            Invited Participants{' '}
            {event.event_type === 'webinar' &&
              '(' + invitedEmails.length + '/5)'}
          </h3>
        }
        dataSource={invitedEmails}
        renderItem={(item, index) => (
          <List.Item>
            {index + 1}. {item}
          </List.Item>
        )}
      />
      <Select
        mode="multiple"
        placeholder={t('placeholders:email')}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        value={selectedEmails}
        {...(isMaxParticipants && {
          open: false
        })}
        onChange={handleEmailChange}
        style={{ width: '100%', marginTop: 16 }}
      >
        {filteredEmails.map(d => (
          <Option key={d.value}>{d.text}</Option>
        ))}
      </Select>

      {isMaxParticipants && (
        <div style={{ color: 'red' }}>{t('v2:max_five_users_allowed')}</div>
      )}
    </>
  )
})

export default AddParticipant
