import React from 'react'
import { withRouter } from 'react-router'
import { Item, HelpItem } from './internal'
import { Nav } from './styled'

import {
  courses,
  events,
  reports,
  group,
  organisations,
  users,
  workshop,
  help,
  nowLive,
  courseAiImage,
  nav_trivia,
  category
} from './images'

import BrainIcon from '../../assets/brain.svg'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const Navigation = props => {
  const { t, isKnowledgeCenter, match } = props
  const isManagementMode = match.path.includes('/management')

  const renderNavItems = isManagementMode => {
    const user = useSelector(state => state.user)
    const info = user && user.info

    const enable_ai_course =
      info && info.organization && info.organization.enable_ai_course

    let items = [
      {
        url: '/training',
        exact: true,
        icon: courses,
        heading: t('training')
      },
      {
        url: '/share-sphere',
        exact: true,
        icon: nav_trivia,
        heading: t('v3:share_sphere')
      },
      {
        url: '/library/list',
        exact: true,
        icon: events,
        heading: t('buttons:from_library')
      },
      {
        url: '/workshop',
        exact: true,
        icon: workshop,
        heading: t('workshop')
      },
      {
        url: '/reports',
        exact: true,
        icon: reports,
        heading: t('reports')
      },
      {
        url: '/events',
        exact: true,
        icon: nowLive,
        heading: t('nowLive')
      }
    ]

    const knowledgeCenterItems = [
      {
        url: '/knowledge-center',
        exact: true,
        icon: BrainIcon,
        heading: 'Knowledge Center'
      },
      ...(enable_ai_course
        ? [
            {
              url: '/knowledge-center/course-ai',
              exact: true,
              icon: courseAiImage,
              heading: 'AI Course'
            }
          ]
        : []),
      {
        url: '/knowledge-center/library/list',
        exact: true,
        icon: events,
        heading: t('buttons:from_library')
      }
    ]

    const itemsManagement = [
      {
        url: '/management',
        exact: true,
        icon: organisations,
        heading: t('organisations')
      },
      {
        url: '/management/users',
        exact: true,
        icon: users,
        heading: t('users')
      },
      {
        url: '/management/groups',
        exact: true,
        icon: group,
        heading: t('groups')
      },
      {
        url: '/management/category',
        exact: true,
        icon: category,
        heading: t('v3:category')
      }
    ]

    let navItems = []
    if (isManagementMode) {
      navItems = itemsManagement
    } else if (isKnowledgeCenter) {
      navItems = knowledgeCenterItems
    } else {
      // Find the index of the report item
      let reportIndex = items.findIndex(item => item.url === '/reports')
      // Find the index of the trivia item
      let index = items.findIndex(item => item.url === '/share-sphere')

      // Remove the reports item if it exists and is not active organization
      if (
        reportIndex !== -1 &&
        !info.active_organization_id &&
        !info.is_super_admin
      ) {
        items.splice(reportIndex, 1)
      }
      // Remove the trivia item if it exists and is not active organization
      if (
        index !== -1 &&
        !info.active_organization_id &&
        !info.is_super_admin
      ) {
        items.splice(index, 1)
      }
      navItems = items
    }

    return navItems.map((item, index) => (
      <Nav.Item key={index}>
        <Item
          url={item.url}
          icon={item.icon}
          heading={item.heading}
          exact={item.exact}
        />
      </Nav.Item>
    ))
  }

  return (
    <Nav>
      <Nav.List>{renderNavItems(isManagementMode)}</Nav.List>
      <HelpItem
        url={'/help-center'}
        icon={help}
        heading={t('help_center:menu:help_center')}
        exact={'help'}
      />
    </Nav>
  )
}

export default withRouter(withTranslation('navigation')(Navigation))
