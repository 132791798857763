import {Communication} from './Communication.js';
import Controller from './Controller.js';
import {RunTimeAPI} from './scorm1_2.js'

window.API_URL = "http://localhost:3000/DEV/"


export function onBodyLoad(courseId, userId){

	//scorm id ->> scorm_scoes -> create tree of activity with scorm_scoes and null cmi_core -> load first node -> find or get core with scorm_scoes_id

	// const urlParams = new URLSearchParams(window.location.search);
	// const courseId = Number(urlParams.get("course"))
	// const userId = Number(urlParams.get("user"))

	window.lmsInfo = {
		courseId,
		userId
	}
	
	communication.getScorm(courseId, {
		success: (response)=>{
			controller.Initialize(response.data)
		}
	})

    // const act = new Activity()
    // act.scormSco = new ScormSco()
    // act.cmiCore = new CmiCore()
    // act.scormSco.href = "index_scorm.html"
    // window.API.InitializeForDelivery(act)
		// const newlink = `${config.scormURL}/${playerLink}`
		// console.log('newlink', newlink)
    // //Integration.SetCurrentActivity(act, 'http://mj-scorm-bucket.s3-website-us-east-1.amazonaws.com/')//change base location later
    // // document.getElementById("scromIframe").src = "http://mj-scorm-bucket.s3-website-us-east-1.amazonaws.com/scorm_file/"
    // document.getElementById("scromIframe").src = newlink

    //  console.log('window.location.href', window.location.href)


	// const reqBody = JSON.stringify(lmsInfo)

	// $.ajax({
	// 	type: "POST",
	// 	url: API_URL + "scorm/lmsgetruntime",
	// 	data: reqBody,
	// 	dataType: "json",
	// 	contentType: "application/json; charset=utf-8",
	// 	async: true,
	// 	success: function (response) {
	// 		const {
	// 			cmi_core,
	// 			cmi_data	
	// 		} = response

	// 		const activity = new Activity({cmi_core, cmi_data})


	// 		API.InitializeForDelivery(activity)
	// 		communication.setActivity(activity)

	// 		document.getElementById("scromIframe").src = "/scorm_file/index_scorm.html"
	// 	},
	// 	error: function (request, error) {
	// 		return "false";
	// 	}
	// });
}

window.API = new RunTimeAPI()
var communication = new Communication(window.API_URL)
var controller = new Controller(communication)
window.onBodyLoad = onBodyLoad