import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Form, Button, Input, Spin, Icon } from 'antd'
import { ModalGroups, FormItem } from './styled'
import { withTranslation } from 'react-i18next'

class ModalAddGroups extends Component {
  state = {
    fetchUpload: false,
    logoLink: null,
    logoId: null
  }

  onCancel = () => {
    const { form, onCancel } = this.props

    form.resetFields()

    this.setState({
      logoId: null,
      logoLink: null
    })

    onCancel()
  }

  handleDrop = dropped => {
    const { upload } = this.props
    const file = dropped[0]

    const opts = {
      isPublic: false
    }

    this.setState({ fetchUpload: true })

    return upload(file, opts).then(res => {
      console.log(res)
      this.setState({
        fetchUpload: false,
        logoId: res.id,
        logoLink: res.link
      })
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const { form, onSubmit, group } = this.props
    const { logoId } = this.state

    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values,
          logo_id: (group && group.logo_id) || logoId
        }

        onSubmit(data, !!group).then(() => {
          this.setState({
            logoId: null,
            logoLink: ''
          })
        })
      }
    })
  }

  render() {
    const { form, visible, loading, group, t } = this.props
    const { fetchUpload, logoLink } = this.state

    const groupLogo = group && group.logo ? group.logo.link : ''

    return (
      <ModalGroups
        title={`${group ? t('labels:edit') : t('labels:add')} ${t(
          'labels:group'
        )}`}
        visible={visible}
        onCancel={this.onCancel}
        style={{ maxWidth: 400 }}
      >
        <Form onSubmit={this.handleSubmit}>
          <ModalGroups.Container>
            <FormItem label={t('labels:title')}>
              {form.getFieldDecorator('title', {
                rules: [
                  { required: true, message: t('errors:title') },
                  { min: 3, message: t('errors:min_3char') },
                  { whitespace: true, message: t('errors:title_empty') }
                ],
                initialValue: group ? group.title : ''
              })(<Input type="text" />)}
            </FormItem>

            <FormItem label={t('labels:description')}>
              {form.getFieldDecorator('description', {
                initialValue: group ? group.description : ''
              })(<Input.TextArea rows={4} />)}
            </FormItem>

            {false && (
              <ModalGroups.Container>
                <ModalGroups.Upload>
                  <Spin spinning={fetchUpload}>
                    <Dropzone
                      accept="image/*"
                      onDrop={this.handleDrop}
                      style={{ width: '100%' }}
                    >
                      {({ isDragActive }) => (
                        <ModalGroups.Dropzone
                          active={isDragActive}
                          background={logoLink || groupLogo}
                        >
                          {isDragActive ? (
                            <ModalGroups.Dropzone.Text>
                              {t('labels:drop_file')}
                            </ModalGroups.Dropzone.Text>
                          ) : (
                            <ModalGroups.Dropzone.Button hide={false}>
                              <Icon type="picture" />{' '}
                              {t('buttons:upload_image')}
                            </ModalGroups.Dropzone.Button>
                          )}
                        </ModalGroups.Dropzone>
                      )}
                    </Dropzone>
                  </Spin>
                </ModalGroups.Upload>
              </ModalGroups.Container>
            )}

            <ModalGroups.BtnGroup>
              <Spin spinning={loading || fetchUpload}>
                <Button
                  type="primary"
                  className="rounded"
                  htmlType="submit"
                  style={{ margin: '0 1rem' }}
                >
                  {`${group ? t('labels:edit') : t('labels:add')} ${t(
                    'labels:group'
                  )}`}
                </Button>
              </Spin>

              <Button
                className="rounded"
                onClick={this.onCancel}
                style={{ margin: '0 1rem' }}
              >
                {t('buttons:cancel')}
              </Button>
            </ModalGroups.BtnGroup>
          </ModalGroups.Container>
        </Form>
      </ModalGroups>
    )
  }
}

export default Form.create()(withTranslation('management')(ModalAddGroups))
