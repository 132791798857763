import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { MazeCreate, PublishMaze, MazeScenario } from './components'
import * as mazeBuilderActions from '../../../store/actions/mazeBuilder'
class MazeRoutes extends Component {
  componentWillUnmount() {
    const {
      mazeBuilderActions: { resetMazeBuilder }
    } = this.props
    resetMazeBuilder()
  }

  componentDidMount() {
    const {
      mode,
      match: { params },
      location: { pathname },
      mazeBuilder,
      user: {
        info: { isAdmin, isSuperAdmin }
      },
      history
    } = this.props

    const isEdit = mode === 'edit'

    const rootPathTemplate = isEdit ? '/maze/:id/edit' : '/maze/create'
    var rootPath = rootPathTemplate.replace(':id', params.id)

    if (isEdit) {
      if (pathname.includes('scenario')) {
        rootPath = `${rootPath}/scenario`
      } else if (pathname.includes('publish')) {
        rootPath = `${rootPath}/publish`
      }
    }

    if (
      (isAdmin || isSuperAdmin) &&
      isEdit &&
      pathname === `${rootPath}/publish`
    ) {
      return null
    }

    if (pathname !== rootPath && !mazeBuilder.mazeId) {
      history.push(rootPath)
      return null
    }
  }

  render() {
    const {
      match: { params },
      mode,
    } = this.props

    const isEdit = mode === 'edit'

    const rootPathTemplate = isEdit ? '/maze/:id/edit' : '/maze/create'
    const rootPath = rootPathTemplate.replace(':id', params.id)

    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path={rootPathTemplate}
            render={props => <MazeCreate {...props} />}
          />
          <Route
            exact
            path={`${rootPath}/scenario`}
            render={props => (
              <MazeScenario {...props} fetching={this.props.fetching} />
            )}
          />
          <Route
            exact
            path={`${rootPath}/publish`}
            render={props => <PublishMaze {...props} />}
          />
        </Switch>
      </Fragment>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      mazeBuilder: state.mazeBuilder,
      user: state.user
    }),
    dispatch => ({
      mazeBuilderActions: bindActionCreators(mazeBuilderActions, dispatch)
    })
  )(MazeRoutes)
)
