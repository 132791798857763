import React from 'react'
import Footer from '../../../../../components/Form/Footer'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

function CreateChannelFooter(props) {
  const {
    onPublish,
    onSaveForLater,
    isPublishDisabled = false,
    loadingSave = false,
    loadingPublish = false
  } = props
  const handlePublish = () => {
    if (onPublish) {
      onPublish()
    }
  }

  const handleSaveForLater = () => {
    if (onSaveForLater) {
      onSaveForLater()
    }
  }
  const { t } = useTranslation()

  return (
    <Footer>
      <Button shape="round" onClick={handleSaveForLater} loading={loadingSave}>
        {t('v3:save_for_later')}
      </Button>
      <Button
        style={{ marginLeft: '2rem' }}
        shape="round"
        type="green"
        onClick={handlePublish}
        loading={loadingPublish}
        disabled={isPublishDisabled}
      >
        {t('buttons:publish')}
      </Button>
    </Footer>
  )
}

export default CreateChannelFooter
