import React from 'react'
import { Icon, Tooltip } from 'antd'

import './PreviewHeader.scss'
import { withTranslation } from 'react-i18next'

import DiscussionIcon from '../../assets/discussion.svg'

class PreviewHeader extends React.Component {
  render() {
    const {
      ITEMS,
      event: { title },
      onHeaderItemClick,
      show: { overview, notes, hosts, attachments, discussion },
      t
    } = this.props
    return (
      <div className="preview__header">
        <div className="title">
          <Tooltip title={title}>{title}</Tooltip>
        </div>
        <div className="menus">
          <div
            className={`menu_item ${overview ? 'active_menu_item' : null}`}
            onClick={() => onHeaderItemClick(ITEMS.OVERVIEW)}
          >
            <Icon type="read" />
            <div className="name">{t('reports:overview')}</div>
          </div>

          <div
            className={`menu_item ${hosts ? 'active_menu_item' : null}`}
            onClick={() => onHeaderItemClick(ITEMS.HOST)}
          >
            <Icon type="user" />
            <div className="name">{t('labels:host')}</div>
          </div>

          <div
            className={`menu_item ${attachments ? 'active_menu_item' : null}`}
            onClick={() => onHeaderItemClick(ITEMS.ATTACHMENT)}
          >
            <Icon type="paper-clip" />
            <div className="name">
              {t('v2:additional')} <br /> {t('v2:attachment')}
            </div>
          </div>

          <div
            className={`menu_item ${notes ? 'active_menu_item' : null}`}
            onClick={() => onHeaderItemClick(ITEMS.NOTES)}
          >
            <Icon type="form" />
            <div className="name">{t('tabs:notes')}</div>
          </div>

          <div
            className={`menu_item ${discussion ? 'active_menu_item' : null}`}
            onClick={() => onHeaderItemClick(ITEMS.DISCUSSION)}
          >
             <img alt=""
              src={DiscussionIcon}
              style={{
                filter: discussion ? null : 'brightness(0) saturate(100%)'
              }}
            />
            <div className="name">{t('tabs:discussion')}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(PreviewHeader)
