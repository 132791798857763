/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'Lưu',
    autosaved: 'đã lưu tự động',
    back_to: 'Quay về',
    by: 'bởi',
    chat: 'Trò chuyện',
    completed: 'Đã hoàn thành',
    canceled: 'Đã hủy',
    component: 'Thành phần',
    confirm: 'Xác nhận',
    course: 'Khóa học',
    date: 'Ngày',
    details: 'Thông tin chi tiết',
    empty: 'Không có dữ liệu',
    enrolled: 'Đã ghi danh',
    free: 'Miễn phí',
    graph: 'Biểu đồ',
    loading: 'Đang tải...',
    lesson: 'Bài học',
    lessons: 'Bài học',
    live_assessment: 'Đánh giá trực tiếp',
    live_event: 'Sự kiện trực tiếp',
    no: 'Không',
    of: 'Của',
    or: 'Hoặc',
    organization: 'Tổ chức',
    page: 'Trang',
    pages: 'Trang',
    personal: 'Cá nhân',
    poll: 'Thăm dò ý kiến',
    rating: 'Điểm đánh giá',
    reset: 'Đặt lại',
    signin: 'Đăng nhập',
    signup: 'Đăng ký',
    soon: 'Sắp có',
    type: 'Loại',
    uploading: 'Đang tải lên...',
    yes: 'Đồng ý',
    something_went_wrong: 'Có lỗi phát sinh!',
    view_all: 'Xem toàn bộ',
    upload_successful: 'Tải lên thành công',
    upload_successful_message:
      'Các tệp excel của bạn đã được tải lên thành công và hiện đang được xử lý. Việc này có thể mất chút thời gian.',
    check_email_upload_successful:
      'Vui lòng <0>kiểm tra email của bạn</0> để xem <1>báo cáo</1> về danh sách người dùng đã tải lên.',
    is_private: 'Riêng tư'
  },

  account: {
    title: 'Tài khoản',
    subtitle: 'Quản lý cài đặt và thông tin tài khoản của bạn.',
    saved: 'Tài khoản đã được thay đổi'
  },

  assessments: {
    title: 'Bài đánh giá',
    subtitle: 'Tạo Bài đánh giá',
    finished: 'Bài đánh giá đã kết thúc',
    countdown: 'Buổi đánh giá trực tiếp sẽ được phát sóng sau ',
    create: 'Tạo bài đánh giá',
    list_title: 'Bài đánh giá của bạn',
    delete_warning: 'Bạn có chắc chắn muốn xóa mục này không?',
    details_title: 'Thêm thông tin về bài đánh giá',
    details_subtitle:
      'Đặt tên cho Bài đánh giá của bạn và cho biết người xem sẽ học được gì từ nó.',
    disclaimer: `Tôi bảo đảm rằng bài đánh giá được ghi lại của tôi sẽ không:     - vi phạm quyền của bên thứ ba bất kỳ
    - chứa nội dung bất hợp pháp bất kỳ, bao gồm nội dung mang tính phỉ báng, tục tĩu, khiếm nhã, dâm ô, khiêu dâm, bạo lực, lạm dụng, lăng mạ, đe dọa hoặc phân biệt đối xử, kể cả nội dung đã được che bằng dấu hoa thị
    - mang tính công kích cá nhân đối với những người khác
    - chứa hành vi mạo danh người tham gia khác, giả dạng nhân vật của công chúng hoặc người nổi tiếng
    - chứa bản ghi hoặc thông tin cá nhân về một cá nhân bất kỳ mà không có sự đồng ý của cá nhân đó hoặc vi phạm các quy định bảo vệ dữ liệu ở bất kỳ nơi nào trên thế giới
    - chứa lời mời chào thương mại hoặc bất kỳ hình thức "spam" nào`,
    assessment_details: 'Chi tiết Bài đánh giá',

    assessment_settings: 'Cài đặt Bài đánh giá'
  },

  buildersettings: {
    title: 'Cài đặt Đăng tải',
    subtitle:
      'Thêm các thiết lập cho sự kiện của bạn, đối tượng nào có thể xem và tương tác với sự kiện này'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Thêm',
    add_page: 'Thêm trang',
    add_lesson: 'Thêm bài học',
    add_answer: 'Thêm câu trả lời mới',
    add_group: 'Thêm nhóm',
    add_organization: 'Thêm tổ chức',
    add_question: 'Thêm câu hỏi',
    add_quiz: 'Thêm bài kiểm tra',
    add_users: 'Thêm người dùng',
    back: 'Quay lại',
    back_to_course: 'Quay lại trang Tổng quan về khóa học',
    back_to_lesson: 'Quay lại trang Tổng quan về bài học',
    back_to_media: 'Quay lại Thư viện tệp tin',
    back_to_details: 'Quay lại trang thông tin chi tiết',
    cancel: 'Hủy',
    clear: 'Xóa',
    close: 'Đóng',
    clone: 'Sao chép',
    close_lesson: 'Đóng bài học',
    clone_course: 'Sao chép Khóa học',
    confirm: 'Xác nhận',
    create: 'Tạo',
    download: 'Tải về',
    download_mass_user_tamplate: 'Tải xuống Mẫu',
    add_mass_users: 'Thêm người dùng hàng loạt',
    upload_excel_list: 'Tải lên danh sách Excel',
    delete: 'Xóa tệp',
    edit: 'Sửa',
    edit_course: 'Sửa khóa học',
    enroll: 'Ghi danh',
    finish: 'Hoàn thành',
    upload_image: 'Tải ảnh lên',
    upload_image_video: 'Tải lên hình ảnh hoặc video',
    select_media_library: 'Chọn thư viện tệp tin',
    make_public: 'Đặt ở chế độ công khai',
    make_private: 'Đặt ở chế độ riêng tư',
    make_org: 'Đặt ở chế độ dành cho tổ chức',
    media: 'Thư viện tệp tin',
    my_theme: 'Giao diện của tôi',
    next: 'Tiếp',
    next_lesson: 'Bài học tiếp theo',
    next_step: 'Bước tiếp theo',
    previous_step: 'Bước trước đó',
    publish: 'Đăng tải',
    publish_assessment: 'Đăng tải Bài đánh giá của bạn',
    publish_course: 'Đăng tải Khóa học của bạn',
    publish_event: 'Đăng tải Sự kiện của bạn',
    reset: 'Đặt lại',
    reset_elements: 'Thiết lập lại các yếu tố',
    save: 'Lưu',
    creating: 'Đang tạo...',
    updating: 'Đang cập nhật...',
    saved: 'Đã lưu',
    save_for_later: 'Lưu lại để sau',
    save_settings: 'Lưu cài đặt',
    select_files: 'Chọn tệp tin',
    select_theme: 'Lựa chọn giao diện',
    start_lesson: 'Bắt đầu bài học',
    update: 'Cập nhật',
    upload: 'Tải lên',
    upload_audio: 'Tải lên âm thanh',
    upload_photo: 'Tải lên ảnh hồ sơ',
    upload_video: 'Tải video lên',
    enrolled: 'Đã ghi danh',
    unenroll: 'Hủy đăng ký',
    from_library: 'Thư viện tệp tin',
    remove: 'Xóa',
    removed: 'Đã xóa',
    start_broadcast: 'Bắt đầu phát sóng',
    end_broadcast: 'Kết thúc phát sóng',
    camera: 'Camera',
    screen: 'Màn hình',
    screen_with_camera: 'Màn hình có Camera',
    insert: 'Chèn',

    inserting: 'Đang chèn'
  },

  card: {
    quick_add: 'Thêm nhanh',
    add: 'Thêm',
    clone: 'Sao chép',
    completed: 'Hoàn tất bài học'
  },

  course: {
    upcoming_course: 'Khóa học sắp tới',
    unPublishEvent: 'Hủy đăng tải Sự kiện',
    unPublishAssessment: 'Hủy đăng tải Bài đánh giá',
    pastCourse: 'Khóa đào tạo',
    upcoming: 'Sự kiện trực tiếp sắp diễn ra',
    pastEvent: 'Sự kiện trực tiếp từng diễn ra',
    pastAssessment: 'Bài đánh giá trực tiếp từng diễn ra',
    upcomingAssessment: 'Bài đánh giá trực tiếp sắp diễn ra',
    created: 'Ngày tạo:',
    assignees: 'Người được giao',
    organisations:
      ' {{ organizations }} Tổ chức, <2> {{ users }} người dùng</2>',
    event_date: 'Ngày diễn ra sự kiện trực tiếp <1> {{ date }} </1>',
    delete_warning: 'Bạn chắc chắn muốn xóa chứ?',
    clonning_no_lesson:
      'Nếu bạn muốn sao chép Khóa học này, vui lòng thêm ít nhất một Bài học.',
    clone_warning: 'Bạn chắc chắn muốn sao chép mục này chứ?',
    clone_success: 'đang trong quá trình sao chép',
    disclaimer: `Tôi bảo đảm rằng khóa học của tôi sẽ không:     - vi phạm quyền của bên thứ ba bất kỳ
    - chứa nội dung bất hợp pháp bất kỳ, bao gồm nội dung mang tính phỉ báng, tục tĩu, khiếm nhã, dâm ô, khiêu dâm, bạo lực, lạm dụng, lăng mạ, đe dọa hoặc phân biệt đối xử, kể cả nội dung đã được che bằng dấu hoa thị
    - mang tính công kích cá nhân đối với những người khác
    - chứa hành vi mạo danh người tham gia khác, giả dạng nhân vật của công chúng hoặc người nổi tiếng
    - chứa thông tin cá nhân về một đối tượng bất kỳ mà không có sự đồng ý của cá nhân đó hoặc vi phạm các quy định bảo vệ dữ liệu ở bất kỳ nơi nào trên thế giới
    - chứa lời mời chào thương mại hoặc bất kỳ hình thức "spam" nào`
  },

  coursecreate: {
    dropzone_text: 'Chèn tệp',
    sidebar_text: 'Chọn giao diện chủ đề <1/> cho <3/> bài học của bạn',
    delete_warning: 'Bạn có chắc muốn xóa bài học này không?'
  },

  coursepage: {
    quizview_loading: 'Đang kiểm tra câu trả lời. Vui lòng đợi...',
    quizview_answer: 'Câu trả lời của bạn'
  },

  coursequiz: {
    title: 'Bài kiểm tra của toàn bộ Bài học'
  },

  dashboard: {
    tooltip_title: 'Không hiển thị lại video này',
    page_title: 'Thống kê các khóa học của bạn',
    page_subtitle: 'Trạng thái của các khóa học bạn đã tạo',
    profile_add_info:
      'Thêm thông tin về <1>{{uncompletedText}}</1> để hoàn thiện hồ sơ của bạn.',
    profile_build:
      'Xây dựng hồ sơ cá nhân <1>tại đây</1>, chia sẻ cho chúng tôi biết các sở thích và kỹ năng của bạn.',
    profile_title: 'Cấp độ hoàn thiện hồ sơ',
    more_details: 'Thêm thông tin chi tiết'
  },

  errors: {
    answer: 'Vui lòng nhập câu trả lời',
    answer_correct: 'Chọn câu trả lời đúng',
    code: 'Vui lòng nhập mã của bạn',
    criterion: 'Vui lòng nhập tiêu chí',
    duration: 'Bạn bắt buộc phải nêu rõ thười lượng',
    element: 'Vui lòng nhập phần tử',
    email: 'Vui lòng nhập email của bạn',
    email_invalid: 'Email đã nhập không hợp lệ',
    feedback_correct: 'Vui lòng nhập phản hồi khi chọn đáp án chính xác',
    feedback_incorrect: 'Vui lòng nhập phản hồi khi chọn đáp án sai',
    host: 'Vui lòng nhập người chủ trì sự kiện',
    host_assessment: 'Vui lòng nhập ngời chủ trì bài đánh giá',
    image: 'Vui lòng tải lên một hình ảnh',
    location: 'Vui lòng nhập vị trí của bạn',
    live_location: 'Vui lòng chọn vị trí phát sóng',
    limit: 'Vui lòng nhập số phút phát trực tiếp',
    min_3char: 'Tối thiểu 3 ký tự',
    number: 'Vui lòng nhập số dương.',
    host_email_require: `Cần phải điền email của người chủ trì.`,
    attendee_email_require: `Cần phải điền email của người tham gia.`,
    name: 'Vui lòng nhậ tên của bạn',
    not_found_course: 'Không tìm thấy khóa học',
    not_found_event: 'Không tìm thấy sự kiện',
    not_found_assessment: 'Không tìm thấy bài đánh giá',
    password: 'Vui lòng nhập mật khẩu',
    password_digit: 'Ít nhất một chữ số',
    password_short: 'Mật khẩu quá ngắn, hãy nhập tối thiểu 10 ký tự',
    password_special: 'Ít nhất một ký tự đặc biệt',
    password_uppercase: 'Ít nhất một ký tự viết hoa',
    password_lowercase: 'Ít nhất một ký tự viết thường',
    phone: 'Vui lòng nhập điện thoại của bạn',
    phone_invalid: 'Số điện thoại không hợp lệ',
    position: 'Vui lòng nhập chức vụ của bạn',
    question: 'Vui lòng nhập câu hỏi',
    start_date: 'Bạn buộc phải chọn Ngày/Giờ bắt đầu',
    tag: 'Vui lòng chọn một thẻ',
    topics: 'Vui lòng chọn ít nhất một chủ đề',
    live_topics: 'Vui lòng thêm các chủ đề',
    title: 'Vui lòng nhập tiêu đề',
    title_assessment: 'Vui lòng nhập tiêu đề bài đánh giá',
    title_course: 'Vui lòng nhập tiêu đề khóa học',
    title_empty: 'Không thể để trống phần tiêu đề',
    title_event: 'Vui lòng nhập tiêu đề sự kiện',
    title_lesson: 'Vui lòng nhập tiêu đề bài học',
    title_quiz: 'Vui lòng nhập tên bài kiểm tra',
    username: 'Vui lòng nhập tên người dùng của bạn',
    upload_error: 'Lỗi - Tệp không tương thích!',
    upload_more_than_10: 'Bạn chỉ có thể tải lên tối đa 10 tệp cùng một lúc.',
    topics_max: 'Vui lòng thêm tối đa 3 chủ đề.',
    upload_excel: 'Hãy đảm bảo tệp bạn tải lên là chính xác.',
    title_excel: 'Lỗi khi tải file mẫu:',
    file_size_exceed: 'Kích thước tệp quá lớn',
    subtitle_excel:
      'Hãy đảm bảo bạn đã dùng đúng bản mẫu được cung cấp và điền chuẩn xác thông tin vào các cột.',
    select_broadcast_option: 'Hãy chọn một tùy chọn để phát sự kiện này',
    multi_hosts_number_error:
      'Số người thuyết trình không thể vượt quá mức quy định cho sự kiện này.',
    conference_people_number_error: `Số người tham gia không thể vượt quá mức quy định cho sự kiện này.`,
    select_max_host: 'Chọn ít nhất 1 người thuyết trình cho sự kiện.',
    select_max_conference: 'Chọn ít nhất 1 người tham dự sự kiện.',
    stream_stop_error: 'Video phát trực tiếp bị dừng đột ngột!',
    unsecure_context:
      'Lỗi nghiêm trọng: Trình duyệt không thể truy cập camera và micrô do bối cảnh không an toàn. Vui lòng cài đặt SSL và truy cập qua https',
    ws_not_supported:
      'Lỗi nghiêm trọng: WebSocket không được hỗ trợ trong trình duyệt này',
    no_org: 'Hãy chọn ít nhất 1 tổ chức để tiếp tục.',
    no_org_group: 'Hãy chọn ít nhất 1 nhóm tổ chức để tiếp tục.',
    waiting_to_join: 'Đang chờ ai đó tham gia!'
  },

  events: {
    title: 'Thêm thông tin về sự kiện trực tiếp',
    change_host: 'Đổi Người chủ trì',
    finished: 'Sự kiện đã kết thúc',
    countdown: 'Sự kiện trực tiếp sẽ lên sóng sau ',
    subtitle:
      'Đặt tên cho Sự kiện trực tiếp của bạn và cho khán giả biết họ sẽ học được gì từ nó.',
    poll_disabled: 'Sự kiện này không có bỏ phiếu thăm dò ý kiến.',
    disclaimer: `Tôi bảo đảm rằng sự kiện được ghi lại của tôi sẽ không:     - vi phạm quyền của bên thứ ba bất kỳ
    - chứa nội dung bất hợp pháp bất kỳ, bao gồm nội dung mang tính phỉ báng, tục tĩu, khiếm nhã, dâm ô, khiêu dâm, bạo lực, lạm dụng, lăng mạ, đe dọa hoặc phân biệt đối xử, kể cả nội dung đã được che bằng dấu hoa thị
    - mang tính công kích cá nhân đối với những người khác
    - chứa hành vi mạo danh người tham gia khác, giả dạng nhân vật của công chúng hoặc người nổi tiếng
    - chứa bản ghi hoặc thông tin cá nhân về một cá nhân bất kỳ mà không có sự đồng ý của cá nhân đó hoặc vi phạm các quy định bảo vệ dữ liệu ở bất kỳ nơi nào trên thế giới
    - chứa lời mời chào thương mại hoặc bất kỳ hình thức "spam" nào`,
    prompt_message:
      'Sự kiện sẽ kết thúc nếu bạn rời khỏi trang này. Bạn có chắc muốn rời khỏi trang này không?',
    duration_exceed_message:
      'Đã vượt quá thời lượng lên sóng, ứng dụng sẽ ngừng phát.',
    before_schedule_message:
      'Bạn sắp bắt đầu chương trình phát sóng trước thời gian dự kiến. Xin lưu ý rằng sự kiện sẽ kết thúc nếu bạn dừng phát sóng.',
    browser_unsupportive_message:
      'Trình duyệt của bạn không hỗ trợ chia sẻ màn hình. Bạn có thể xem trình duyệt nào được hỗ trợ tại liên kết này',
    cancel_switch_host_message:
      'Người chủ trì không có mặt. Sự kiện sẽ được tiếp tục từ đầu cầu của bạn.',
    coaching_prompt_message:
      'Trước khi sự kiện kết thúc, bạn không thể rời trang này.',
    event_modal_warn: 'Bạn có muốn rời khỏi sự kiện này không?',
    confirm_message: 'Sự kiện này sẽ kết thúc nếu bạn nhấp nút OK.',
    screen_share_denied: 'Bạn đã từ chối chia sẻ màn hình của mình',
    media_not_found:
      'Không tìm thấy Camera/Mic trên thiết bị của bạn, có thể do bạn chưa cấp quyền truy cập.',
    media_not_access:
      'Camera/Mic của bạn đang được dùng trong một quy trình khác không cho phép đọc thiết bị',
    constraint_error:
      'Không tìm thấy thiết bị nào phù hợp với các tiêu chí về video và âm thanh của bạn. Bạn có thể thay đổi các tiêu chí về video và âm thanh',
    media_access_denied: 'Bạn không được phép truy cập vào camera và micrô.'
  },

  filters: {
    days7: '7 ngày qua',
    days15: '15 ngày qua',
    days30: '30 ngày qua',
    days365: '365 ngày qua',
    date: 'Ngày tạo',
    relevance: 'Liên quan',
    popularity: 'Phổ biến',
    name: 'Tên',
    group: 'Nhóm',
    Newest_to_Oldest: 'Mới nhất đến Cũ nhất',
    Oldest_to_Newest: 'Cũ nhất đến Mới nhất',
    A_Z: 'Từ A đến Z',
    Z_A: 'Từ Z đến A'
  },

  helpers: {
    password:
      'Để đảm bảo tính bảo mật cho tài khoản của bạn, chúng tôi yêu cầu mật khẩu của bạn phải chứa ít nhất một chữ thường và chữ số. Mật khẩu cũng phải dài tối thiểu 10 ký tự.',
    phone:
      'Vui lòng điền cả mã quốc gia trong số điện thoại của bạn, ví dụ: +65',
    username:
      'Tên người dùng của bạn được coi là thông tin công khai và được chia sẻ với những người dùng khác khi cần. Do đó, chúng tôi khuyên bạn không nên dùng thông tin cá nhân để đặt tên người dùng.',
    public_description_normal:
      '<0><0/> </0> của bạn đã được lưu và được đặt ở trạng thái Riêng tư theo mặc định. Điều này nghĩa là chỉ bạn mới có thể xem nội dung này. Để công khai nội dung này, bạn có thể chọn một trong các tùy chọn bên dưới. Đăng tải khóa học của bạn ở chế độ Công khai có nghĩa là tất cả người dùng trong nền tảng đều có thể truy cập. Tùy chọn Người dùng cụ thể sẽ cho phép bạn chia sẻ khóa học của mình với những người dùng cụ thể bằng cách điền địa chỉ email được liên kết với tài khoản Sharelook của họ.',
    public_description_admin:
      '<0><0/></0> của bạn đã được lưu và được đặt ở trạng thái Riêng tư theo mặc định. Điều này nghĩa là chỉ bạn (và người tạo nội dung) mới có thể xem nội dung này. Để công khai nội dung này, bạn có thể chọn một trong các tùy chọn bên dưới. Đăng tải khóa học của bạn ở chế độ Công khai có nghĩa là tất cả người dùng trong nền tảng - bao gồm những người không thuộc tổ chức của bạn - đều có thể truy cập. Đăng tải ở chế độ Tổ chức có nghĩa là tất cả người dùng trong tổ chức được nêu sẽ có quyền xem. Bạn cũng có thể đăng ở chế độ công khai với 1 hoặc nhiều nhóm, nghĩa là chỉ những người dùng trong các nhóm đó mới có thể xem.  Cuối cùng, bạn có thể chọn đăng tải ở chế độ công khai với những người dùng cụ thể trong và ngoài tổ chức bằng cách điền địa chỉ email liên kết với tài khoản Sharelook của họ.'
  },

  labels: {
    add: 'Thêm',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Loại câu trả lời',
    answers: 'Câu trả lời',
    back_to_quiz: 'Quay lại danh sách Bài kiểm tra',
    back_to_question: 'Quay lại danh sách Câu hỏi',
    chat: 'Trò chuyện',
    chat_available: 'Sự kiện này không cho phép chat',
    chat_report: 'Báo cáo trò chuyện',
    choice_single: 'Chọn 1 đáp án',
    choice_multiple: 'Nhiều lựa chọn',
    choice_free: 'Lựa chọn "tự do"',
    choice_sorting: 'Lựa chọn "sắp xếp"',
    choice_matrix: 'Lựa chọn "Sắp xếp theo ma trận"',
    clone_lesson_permission: 'Quyền sao chép',
    code: 'Mã',
    correct: 'Chính xác',
    created: 'Ngày tạo',
    clone_modal: 'Sao chép Khóa học',
    cloning_course: 'Đang sao chép khóa học...',
    cloning: 'Đang sao chép...',
    cloning_course_desc:
      'Quá trình này sẽ mất chút thời gian. Xin hãy kiên nhẫn',
    cloning_success: 'Khóa học "<0/>" đã được sao chép thành công!',
    description: 'Mô tả',
    disclaimer: 'Tuyên bố khước từ trách nhiệm',
    drop_file: 'Thả tệp vào đây...',
    duration: 'Thời lượng',
    edit: 'Sửa',
    email: 'Email',
    event: 'Sự kiện',
    event_all: 'Tất cả sự kiện của người này',
    event_date: 'Ngày/giờ địa phương diễn ra sự kiện trực tiếp',
    event_this: 'Sự kiện này',
    expire: 'Hết hạn vào',
    expiry_date: 'Ngày hết hạn',
    forgot: 'Quên mật khẩu?',
    group: 'Nhóm',
    groups_list: 'Danh sách các nhóm',
    host: 'Chủ trì',
    id_annotation: 'ID chú thích',
    id_quiz: 'ID câu hỏi',
    incorrect: 'Sai',
    interests: 'Sở thích',
    invited: 'Đã mời',
    live_chat: 'Thảo luận trực tiếp',
    live_stream_limit: 'Số phút phát trực tiếp',
    contact_text:
      'Để thay đổi hạn mức phát trực tiếp hàng tháng của bạn, vui lòng liên hệ',
    contact_admin:
      '<0> Nếu bạn muốn thay đổi hạn mức phát trực tiếp hàng tháng của mình, vui lòng liên hệ </0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Số phút phát trực tiếp còn lại: <0>{{ limit }}</0> phút <1/><2/> <3>Để thay đổi hạn mức phát trực tiếp hàng tháng của bạn, vui lòng liên hệ với </3> <4>admin@sharelook.com</4>',
    location: 'Vị trí',
    lessons: 'Bài học',
    location_broadcast: 'Vị trí máy chủ',
    location_broadcast_subtitle:
      'Thêm vị trí gần nhất với vị trí của máy chủ phát sóng để giảm độ trễ nghe nhìn',
    media_manager: 'Quản lý tệp tin',
    minutes: 'Phút',
    name: 'Tên',
    online_quiz: 'Bài kiểm tra trực tuyến',
    organiser: 'Tác giả',
    organization: 'Tổ chức',
    overall_emotion: 'Trải nghiệm tổng thể:',
    organizations_list: 'Danh sách các tổ chức',
    password: 'Mật khẩu',
    participants: 'Người tham gia',
    picture: 'Ảnh (16:9)',
    phone: 'Điện thoại',
    photo: 'Ảnh',
    poll: 'Thăm dò ý kiến',
    position: 'Chức vụ',
    processing: 'Đang xử lý',
    privacy_settings: 'Thiết lập riêng tư',
    question: 'Câu hỏi',
    quiz_create: 'Tạo bài kiểm tra mới',
    quiz_name: 'Tên bài kiểm tra',
    quiz_update: 'Cập nhật bài kiểm tra',
    select_organization: 'Chọn một tổ chức',
    settings: 'Cài đặt',
    show: 'Hiện',
    show_graph: 'Hiển thị biểu đồ kết quả',
    signin: 'Đăng nhập',
    signup: 'Đăng ký',
    sort_by: 'Sắp xếp theo',
    start_date: 'Ngày bắt đầu',
    tags: 'Thẻ',
    terms: 'Tôi đồng ý với các <1>điều khoản và điều kiện</1> này.',
    title: 'Tiêu đề',
    topics: 'Chủ đề',
    topic: 'Chủ đề',
    total_comments: 'Tổng số bình luận',
    username: 'Tên người dùng',
    users_list: 'Danh sách người dùng',
    users_emails: 'Điền email từng người dùng cụ thể',
    description_specifiction_email:
      'Vui lòng nhập 1 địa chỉ email trên mỗi dòng để chia sẻ nội dung này với nhiều người dùng đích danh.',
    message_specific_email: 'Vui lòng nhập email của người dùng cụ thể.',
    viewers: 'Người xem',
    edit_course: 'Sửa',
    edit_assessment: 'Sửa',
    edit_event: 'Sửa',
    delete_course: 'Xóa',
    delete_event: 'Xóa',
    delete_assessment: 'Xóa',
    view_course: 'Lượt xem',
    view_event: 'Lượt xem',
    view_assessment: 'Lượt xem',
    date_event: 'Ngày diễn ra sự kiện trực tiếp',
    date_assessment: 'Ngày diễn ra bài đánh giá trực tiếp',
    clone_of: 'Sao chép',
    clone_course:
      'Tạo bản sao của khóa học này, bạn có thể chỉnh sửa độc lập bản sao mà không ảnh hưởng tới bản gốc, bao gồm mọi bài học và trang của khóa học.',
    make: 'Để',
    available_to: 'công khai với mọi người dùng ShareLook',
    uploaded_by: 'Được tải lên bởi: ',
    course: 'Khóa học',
    assessment: 'Bài đánh giá',
    user_not_exist:
      'Người dùng này không tồn tại, bạn có muốn gửi lời mời cho họ không?',
    check_user: 'Thông báo',
    user_not_exist_in_org:
      'Người dùng này không có mặt trong tổ chức, bạn có muốn gửi lời mời cho họ không?',
    title_modal_stream: 'Thay đổi giới hạn phát trực tiếp của tổ chức',
    des_warning_stream: 'Bạn sắp thay đổi',
    monthly_stream: 'giới hạn phát trực tiếp hàng tháng:',
    min_per_month: 'phút/tháng',
    broadcast_label: 'Tôi muốn làm:',
    hosts_emails: `Điền email cụ thể của người chủ trì`,
    attendee_emails: `Điền email cụ thể của người tham gia`,
    description_host_specifiction_email:
      'Vui lòng nhập 1 địa chỉ email trên mỗi dòng.',
    presenter_label: 'Người thuyết trình',
    presenter_label_conference: 'Số người',
    event_privacy: 'Chế độ hội nghị'
  },

  broadcastOptions: {
    stream_from_phone: 'Phát sóng trực tiếp qua điện thoại',
    other_webrtc: 'Hội thảo online qua máy tính',
    multihost: 'Hội thảo online Multicast qua máy tính',
    peer2peer: 'Buổi huấn luyện',
    conference: 'Hội nghị trực tiếp'
  },

  lessoncreate: {
    title: 'Thêm thông tin về bài học',
    subtitle:
      'Đặt tên cho bài học của bạn và cho khán giả biết họ sẽ học được gì từ nó.',
    input_label_title: 'Tên bài học',
    input_label_topics: 'Chủ đề bài học',
    input_label_description: 'Mô tả bài học',
    delete_warning: 'Bạn có chắc muốn xóa trang này không?'
  },

  lessonoverview: {
    title: 'Thêm bài học',
    subtitle: 'Thêm bài học hiện có hoặc tạo một bài học mới',
    new_lesson: 'tạo một bài học mới',
    new_lesson_sub: 'Tạo một bài học mới hoàn toàn!',
    existing_lesson: 'Chọn bài học hiện có',
    existing_lesson_sub:
      'Liên kết với bài học hiện có, thay đổi trong bài học này cũng sẽ tác động tới bài học có liên quan trong các khóa học khác.',
    clone_lesson: 'sao chép bài học hiện có',
    clone_lesson_sub:
      'Tạo một bản sao độc lập của bài học trong khóa học này, những thay đổi trong bài học này sẽ không ảnh hưởng đến bài học gốc.',
    clone_lesson_modal_sub:
      'Vui lòng nhấn vào nút sao chép bên dưới bài học bạn muốn thêm vào khóa học của mình.'
  },

  management: {
    groups_title: 'Quản lý nhóm',
    delete_warning: 'Bạn chắc chứ?',
    organizations_title: 'Tổ chức của tôi',
    organizations_subtitle: 'Thêm tổ chức vào danh sách của bạn',
    users_title: 'Quản lý người dùng',
    users_position: 'Quản lý tài khoản cấp cao',
    user_not_found: 'Không tìm thấy người dùng',
    select_org: 'Hãy tìm và chọn một tổ chức để quản lý người dùng'
  },

  audio: {
    modal_title: 'Chèn âm thanh',
    list_view: 'Chế độ xem danh sách',
    create_audio_modal_title: 'Tạo âm thanh',
    edit_audio_modal_title: 'Chỉnh sửa âm thanh',
    public_audio_not_found: 'Không tìm thấy tệp âm thanh.',
    private_audio_not_found: 'Bạn chưa tải lên bất kỳ tệp âm thanh nào',
    are_you_sure_to_delete: 'Bạn có chắc muốn xóa không?',
    type_something: 'Viết gì đó',
    select_voice: 'Chọn',
    file_name: 'Tên tệp',
    description: 'Mô tả',
    topic: 'Chủ đề',
    key_words_tag: 'Từ khóa Tag',
    mp3_audio: 'Tệp âm thanh MP3',
    created: 'Đã tạo',
    duration: 'Thời lượng',
    close: 'đóng'
  },

  image: {
    modal_title: 'Chèn hình ảnh',
    image: 'Ảnh',
    pixel: 'Pixel',
    dimension: 'Kích thước',
    public_image_not_found: 'Không tìm thấy hình ảnh.',
    private_image_not_found: 'Bạn chưa tải lên bất kỳ hình ảnh nào'
  },

  annotation: {
    modal_title: 'Chú thích'
  },

  text: {
    modal_title: 'Soạn thảo văn bản'
  },

  video: {
    modal_title: 'Chèn Video',
    image: 'Video',
    mp4_video: 'Video MP4',
    public_video_not_found: 'Không tìm thấy video.',
    private_video_not_found: 'Bạn chưa tải lên bất kỳ video nào'
  },

  media: {
    title: 'Thư viện tệp tin',
    subtitle: 'Thư viện tệp tin của bạn',
    modal_title: 'Tải tệp tin lên',
    delete_warning: 'Xác nhận xóa',
    delete_message:
      'Tệp tin đã xóa sẽ vẫn xuất hiện tại các trang mà chúng đã được sử dụng',
    dropzone_text: 'hoặc Kéo và Thả',
    delete_success: 'Đã xóa tệp tin thành công',
    upload_file: 'Tải tệp lên',
    drag_drop: 'hoặc Kéo và Thả',
    import_file: 'Nhập (các) tệp từ máy tính của bạn',
    no_media: 'Bạn chưa tải lên bất kỳ tệp tin nào',
    media_type: 'Loại tệp tin',
    video: 'Video',
    image: 'Ảnh',
    audio: 'Âm thanh',
    gif: 'Ảnh động gif',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Không tìm thấy tệp tin.',
    of: 'Của',
    disclaimer: `Tôi bảo đảm rằng tệp tin của tôi sẽ không:     - vi phạm quyền của bên thứ ba bất kỳ
    - chứa nội dung bất hợp pháp bất kỳ, bao gồm nội dung mang tính phỉ báng, tục tĩu, khiếm nhã, dâm ô, khiêu dâm, bạo lực, lạm dụng, lăng mạ, đe dọa hoặc phân biệt đối xử, kể cả nội dung đã được che bằng dấu hoa thị
    - mang tính công kích cá nhân đối với những người khác
    - chứa hành vi mạo danh người tham gia khác, giả dạng nhân vật của công chúng hoặc người nổi tiếng
    - chứa thông tin cá nhân về một đối tượng bất kỳ mà không có sự đồng ý của cá nhân đó hoặc vi phạm các quy định bảo vệ dữ liệu ở bất kỳ nơi nào trên thế giới
    - chứa lời mời chào thương mại hoặc bất kỳ hình thức "spam" nào`,
    my_media: 'Thư viện của tôi',
    public_library: 'Thư viện công cộng',
    organization_library: 'Thư viện của tổ chức',
    upload_from_computer: 'Tải lên từ Máy tính',
    create: 'Tạo',
    all: 'Tất cả',
    male: 'Nam',
    female: 'Nữ',
    processing_audio: 'Đang xử lý âm thanh',
    create_annotation: 'Tạo chú thích',
    popup_over_lesson_text:
      'Chú thích sẽ hiện ra trên trang bài học sau khi người dùng nhấp vào',
    edit_detail: 'Chỉnh sửa chi tiết',
    delete: 'Xóa',
    go_create_audio: 'Tạo âm thanh',
    show_more_details: 'Hiện thêm thông tin',
    show_less_details: 'Hiện ít thông tin hơn',
    drop_files: 'Thả file và chọn nơi tải lên',
    or: 'Hoặc',
    import_files: 'Lấy file từ máy tính'
  },

  navigation: {
    account: 'Tài khoản',
    assessments: 'Bài đánh giá',
    dashboard: 'Bảng điều khiển',
    groups: 'Quản lý nhóm',
    logout: 'Đăng xuất',
    organisations: 'Tổ chức',
    organizations_users: 'Tổ chức & Người dùng',
    reports: 'Báo cáo',
    schedule: 'Lịch trình',
    training: 'Đào tạo',
    users: 'Người dùng',
    workshop: 'Hội thảo của tôi',
    nowLive: 'Phát sóng'
  },

  onboarding: {
    step1_title: 'Bước 1: Giới thiệu đôi chút về bản thân bạn',
    step1_subtitle:
      'Tên bạn là gì? Bạn đến từ đâu? Chức danh công việc của bạn là gì?',
    step2_title: 'Bước 2: Chọn một số kỹ năng bạn muốn cải thiện',
    step2_subtitle:
      'Tiếp theo, hãy cho biết bạn muốn hiểu rõ hơn về điều gì. Có điều gì bạn chưa hiểu rõ không? Một lĩnh vực nghiên cứu mới? Người thân của bạn làm gì? '
  },

  pagebuilder: {
    title: 'Tạo bài học',
    subtitle:
      'Đã đến lúc tạo nội dung để truyền tải tri thức cho người xem, bạn có thể dùng hình ảnh, video và ảnh đồ họa thông tin',
    select_layout: 'Chọn một bố cục trang',
    template1: 'Trống',
    template2: 'Tiêu đề & Phụ đề',
    template3: 'Tiêu đề & Văn bản',
    template4: 'Tiêu đề, Tệp tin & Văn bản',
    template5: 'Tiêu đề & Tệp tin',
    template6: 'Tiêu đề & Bài kiểm tra'
  },

  pageconstructor: {
    title: 'Tạo và sao chép bài học',
    subtitle:
      'Đã đến lúc tạo nội dung để truyền tải tri thức cho người xem, bạn có thể thêm hình ảnh, video và ảnh đồ họa thông tin'
  },

  passwordreset: {
    success_coded:
      'Chúng tôi đã gửi mã xác nhận đến địa chỉ email bạn đã cung cấp, vui lòng sao chép mã này vào ô bên dưới để đặt mật khẩu mới.',
    success_changed:
      'Bạn đã đặt mật khẩu mới thành công. Bạn có thể đăng nhập ngay bây giờ.'
  },

  placeholders: {
    search: 'Tìm kiếm',
    answer: 'Câu trả lời',
    chat: 'Viết gì đó...',
    code: 'Mã',
    criterion: 'Tiêu chí',
    date_event: 'Chọn ngày và giờ',
    date_start: 'Ngày bắt đầu',
    date_expire: 'Ngày hết hạn',
    description: 'Mô tả',
    elements: 'Sắp xếp các phần tử',
    email: 'Email',
    feedback_correct: 'Phản hồi khi trả lời đúng',
    feedback_incorrect: 'Phản hồi khi trả lời sai',
    host: 'Chủ trì',
    keywords: '(Các) từ khóa tìm kiếm',
    lesson_description: 'Mô tả bài học',
    location: 'Vị trí',
    minutes: 'Phút',
    name: 'Tên',
    organiser: 'Tác giả',
    organization: 'Tổ chức',
    password: 'Mật khẩu',
    phone: 'Điện thoại',
    photo: 'Ảnh',
    picture: 'Hình ảnh',
    point: 'Điểm',
    position: 'Chức vụ',
    question: 'Câu hỏi',
    quiz: 'Tên bài kiểm tra',
    search_course: 'Tìm kiếm theo khóa học',
    search_media: 'Tìm tệp tin',
    search_name: 'Tìm kiếm theo tên',
    search_title: 'Tìm kiếm theo tiêu đề',
    search_training: 'Tìm khóa Đào tạo',
    settings: 'Cài đặt',
    tags: 'Thẻ',
    title: 'Tiêu đề',
    topics: 'Chủ đề',
    username: 'Tên người dùng',
    keyword: 'Từ khóa',
    max_500_characters: 'Tối đa 500 ký tự ',
    no_description: 'Tệp này không có thông tin mô tả',
    no_keyword: 'Tệp này không có từ khóa nào',
    no_topics: 'Tệp này không có chủ đề',
    feedback: 'Tin nhắn',
    contact: 'Chúng tôi có thể giúp gì cho bạn?'
  },

  publishsettings: {
    title: 'Cài đặt Đăng tải',
    event_subtitle:
      'Thêm các thiết lập cho sự kiện của bạn, đối tượng nào có thể xem và tương tác với sự kiện này',
    assessment_subtitile:
      'Đặt tên cho Bài đánh giá của bạn và cho biết người xem sẽ học được gì từ nó.',
    course_subtitle:
      'Thêm các thiết lập cho sự kiện của bạn, đối tượng nào có thể xem và tương tác với sự kiện này',
    disclaimer: `1.	Đăng ký

  Khi thực hiện hành động đăng ký trở thành người dùng/tạo tài khoản, bạn đồng ý với [Thỏa thuận người dùng] và xác nhận đã đọc [Chính sách bảo mật] của chúng tôi



  2.	Tạo khóa học

  Khi sử dụng các công cụ của chúng tôi và tạo nội dung khóa học, bạn đồng ý với [Thỏa thuận người dùng] và xác nhận đã đọc [Chính sách bảo mật] của chúng tôi



  3.	Tạo sự kiện

  Khi sử dụng các công cụ của chúng tôi và tạo sự kiện, bạn đồng ý với [Thỏa thuận người dùng] và xác nhận đã đọc [Chính sách bảo mật] của chúng tôi


  4.	Tạo bài đánh giá

  Khi sử dụng các công cụ của chúng tôi và tạo bài dánh giá, bạn đồng ý với [Thỏa thuận người dùng] và xác nhận đã đọc [Chính sách bảo mật] của chúng tôi`,
    dates_validation_error: 'Bạn cần điền đủ cả hai ngày.',
    emails_validation_error: 'Danh sách email không hợp lệ.',
    specific_email_validation_error: 'Chỉ được điền 1 email.',
    i_agree_to_these: 'Tôi đồng ý với ',
    terms_and_conditions: 'các Điều khoản và Điều kiện này.',
    PublicDescription:
      'Người dùng độc lập miễn phí: (tùy chọn riêng tư và công khai) [Khóa học | Sự kiện | Bài đánh giá] của bạn đã được lưu và đặt ở chế độ Riêng tư theo mặc định. Điều này nghĩa là chỉ bạn mới có thể xem nội dung này. Để đăng tải nội dung này, bạn có thể chọn chế độ công khai ở bên dưới. Điều này nghĩa là tất cả người dùng trong nền tảng đều có thể truy cập khóa học này.',
    OrganisationsDescription:
      'Người dùng trong tổ chức: (Thay vì ""Hoàn thành"", hãy chọn ""Đăng tải"" và đưa nội dung này ra trang công khai giống như những người dùng khác mà không chọn bất cứ tùy chọn công khai nào.) [Khóa học | Sự kiện | Bài đánh giá] của bạn đã được lưu và đặt ở chế độ Riêng tư theo mặc định. Điều này có là chỉ bạn và Quản trị viên trong tổ chức của bạn mới có thể xem được. Với tư cách là người dùng, bạn không thể tự đăng tải nội dung trong tổ chức vì điều này cần đến quyền của quản trị viên. Vui lòng liên hệ với một trong những Quản trị viên của bạn và yêu cầu người này đăng tải nội dung của bạn công khai với toàn bộ tổ chức, với các nhóm cụ thể trong tổ chức của bạn hoặc cho những người dùng cụ thể.',
    OrganisationGroupsDescription:
      'Quản trị viên của Tổ chức: (Đối với nội dung được tạo trong tổ chức: Riêng tư, công khai, tổ chức, nhóm, người dùng cụ thể): [Khóa học | Sự kiện | Bài đánh giá] của bạn đã được lưu và đặt ở chế độ Riêng tư theo mặc định. Điều này nghĩa là chỉ bạn (và người tạo nội dung) mới có thể xem nội dung này. Để công khai nội dung này, bạn có thể chọn một trong các tùy chọn bên dưới. Đăng tải khóa học của bạn ở chế độ Công khai có nghĩa là tất cả người dùng trong nền tảng - bao gồm những người không thuộc tổ chức của bạn - đều có thể truy cập. Đăng tải ở chế độ Tổ chức có nghĩa là tất cả người dùng trong tổ chức được nêu sẽ có quyền xem. Bạn cũng có thể đăng ở chế độ công khai với 1 hoặc nhiều nhóm, nghĩa là chỉ những người dùng trong các nhóm đó mới có thể xem.  Cuối cùng, bạn có thể chọn đăng tải ở chế độ công khai với những người dùng cụ thể trong và ngoài tổ chức bằng cách điền địa chỉ email liên kết với tài khoản Sharelook của họ.',
    SpecificUsersDescription:
      'Người dùng độc lập có trả phí: (chế độ riêng tư, công khai và người dùng cụ thể) [Khóa học | Sự kiện | Bài đánh giá] của bạn đã được lưu và đặt ở chế độ Riêng tư theo mặc định. Điều này nghĩa là chỉ bạn mới có thể xem nội dung này. Để công khai nội dung này, bạn có thể chọn một trong các tùy chọn bên dưới. Đăng tải khóa học của bạn ở chế độ Công khai có nghĩa là tất cả người dùng trong nền tảng đều có thể truy cập. Tùy chọn Người dùng cụ thể sẽ cho phép bạn chia sẻ khóa học của mình với những người dùng cụ thể bằng cách điền địa chỉ email được liên kết với tài khoản Sharelook của họ.'
  },

  quiz: {
    quiz_empty: 'Bạn chưa tạo bài kiểm tra nào',
    questions_empty: 'Bạn vẫn chưa tạo câu hỏi nào'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'Ấn Độ',
    asia_pacific_japan: 'Nhật Bản',
    asia_pacific_s_korea: 'Hàn Quốc',
    asia_pacific_singapore: 'Singapore',
    asia_pacific_taiwan: 'Đài Loan',
    eu_belgium: 'Bỉ',
    eu_germany: 'Đức',
    eu_ireland: 'Ireland',
    south_america_brazil: 'Brazil',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Nam Carolina',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Báo cáo',
    workshop_report: 'Báo cáo hội thảo',
    user_report: 'Báo cáo người dùng',
    organization_workshop: 'Tổ chức hội thảo',
    course: 'Khóa học',
    live_event: 'Sự kiện trực tiếp',
    workShop_graph: 'Biểu đồ hội thảo',
    organization_account: 'Tài khoản tổ chức',
    all: 'Tất cả',
    name: 'Tên',
    id_number: 'Số ID',
    email: 'Email',
    search_user_report: 'Tìm Báo cáo Người dùng',
    participant: 'Người tham gia',
    participants: 'Người tham gia',
    host: 'Chủ trì',
    username: 'Tên người dùng',
    event: 'Sự kiện',
    quiz: 'Bài kiểm tra',
    assessment: 'Bài đánh giá',
    time: 'Thời gian',
    no_recent_activity: 'Không có hoạt động gần đây',
    workshops: 'Hội thảo',
    courses: 'Các khóa học',
    live_events: 'Sự kiện trực tiếp',
    live_assessments: 'Đánh giá trực tiếp',
    quizzes: 'Câu hỏi',
    overview: 'Tổng quan',
    by: 'bởi',
    views: 'Lượt xem',
    status: 'Trạng thái',
    poll: 'Thăm dò ý kiến',
    comment: 'Bình luận',
    comments: 'Bình luận',
    no_comments: 'Không có bình luận',
    no_polls: 'Không có cuộc thăm dò',
    live_date: 'Ngày trực tiếp',
    score: 'Điểm',
    question: 'Câu hỏi',
    submit: 'Gửi',
    recent_activity: 'Hoạt động gần đây',
    ago: 'trước đó',
    no_data_found: 'Không tìm thấy dữ liệu',
    start_end_live: 'Bắt đầu/Kết thúc trực tiếp',
    join_leave_date: 'Tham gia / Rời khỏi',
    watched_time: 'Thời gian đã xem',
    complete: 'Hoàn thành',
    in_complete: 'Chưa hoàn thành',
    today: 'Hôm nay',
    seven_days: '7 ngày',
    last_month: 'Tháng trước',
    one_year: '1 năm',
    all_time: 'Toàn bộ thời gian',
    report: 'Báo cáo',
    from_date: 'Từ ngày',
    to_date: 'Đến ngày',
    all_workshop: 'Tất cả Hội thảo',
    all_participants: 'Tất cả Người tham gia',
    live_assessment: 'Đánh giá trực tiếp',
    users: 'Người dùng',
    download: 'Tải về',
    recent: 'Gần đây',
    WorkShop: 'Hội thảo',
    Activity: 'Hoạt động',
    created_date: 'Ngày tạo',
    lessons: 'Bài học',
    lesson: 'Bài học',
    completed: 'Đã hoàn thành',
    report_download: 'Tải báo cáo',
    this_month: 'Tháng này',
    search_course: 'Tìm kiếm khóa học',
    participants_graph: 'Biểu đồ người tham gia',
    viewed: 'Đã xem',
    enrolled: 'Đã ghi danh',
    description: 'Mô tả',
    topic: 'Chủ đề',
    analytic: 'Phân tích',
    quiz_list: 'Danh sách câu hỏi',
    select_one: 'Chọn một',
    group_status: 'Trạng thái nhóm',
    duration_min: 'Thời lượng (phút)',
    submit_poll: 'Gửi phiếu bầu',
    download_events: 'Tải sự kiện',
    search_event: 'Tìm kiếm sự kiện',
    duration: 'Thời lượng',
    total_participants: 'Tổng số người tham gia',
    message: 'Tin nhắn',
    avg_message: 'Trung bình Tin nhắn',
    per_message: 'PHẦN TRĂM TIN NHẮN/SỰ KIỆN'
  },

  signup: {
    success_step1:
      'Chúng tôi đã gửi mã xác nhận đến địa chỉ email bạn đã cung cấp, vui lòng sao chép mã này vào ô bên dưới để xác nhận đăng ký.',
    success_step2: 'Xin cảm ơn. Bạn đã đăng ký thành công.',
    privacy: `Tôi đồng ý với <1>Điều khoản & Điều kiện</1> và <3>Chính sách bảo mật</3> của Sharelook`
  },

  stream: {
    emotion_impressive: 'Ấn tượng',
    emotion_unimpressive: 'Không mấy ấn tượng',
    message_not_started: 'Sắp ra mắt',
    message_running: 'Trực tiếp',
    message_processing:
      'Chúng tôi đang xử lý bản ghi trực tiếp để phát lại. Vui lòng quay lại sau để xem video cũng như thêm đánh giá và phản hồi'
  },

  tabs: {
    audience: 'Khán giả',
    course: 'Tổng quan về khóa học',
    details: 'Thông tin về sự kiện trực tiếp',
    description: 'Mô tả',
    host: 'Chủ trì',
    lesson: 'Tổng quan về bài học',
    lessons: 'Bài học',
    overview: 'Tổng quan',
    page: 'Trình tạo trang',
    publish: 'Đăng tải',
    settings: 'Cài đặt Đăng tải',
    total_views: 'Tổng số lượt xem',
    live_participates: 'Người tham gia trực tiếp',
    live_chats: 'Trò chuyện trực tiếp',
    live_quiz: 'Cuộc thăm dò trực tiếp',
    trainer_rating: 'Đánh giá giảng viên',
    trainer_times: 'Giảng viên',
    courses_quizzes: 'Các câu hỏi trong khóa học',
    user_list: 'Danh sách người dùng',
    discussion: 'Thảo luận',
    notes: 'Ghi chú của tôi'
  },

  topics: {
    SALES_TRAINING: 'Đào tạo kỹ năng bán hàng',
    NEGOTIATION_TRAINING: 'Đào tạo kỹ năng đàm phán',
    CROSS_CULTURAL_AWARENESS: 'Nhận thức đa văn hóa',
    NETWORKING_SKILLS: 'Kỹ năng tạo lập mạng lưới',
    SOFTWARE_ARCHITECTURE: 'Kiến trúc phần mềm',
    PROJECT_MANAGEMENT: 'Quản lý dự án',
    DIGITAL_TRANSFORMATION: 'Chuyển đổi kỹ thuật số',
    IT_CONSULTING: 'Tư vấn CNTT',
    MANAGEMENT_CONSULTING: 'Tư vấn quản lý',
    HR_CONSULTING: 'Tư vấn nhân sự',
    INTERNET_OF_THINGS: 'Internet vạn vật (IoT)',
    CYBERSECURITY: 'An ninh mạng',
    PRIVACY_AND_GDRP: 'Bảo mật và GDPR'
  },

  training: {
    title: 'Đào tạo',
    title_assessment: 'Bài đánh giá',
    subtitle: 'Các khóa học và sự kiện trực tiếp',
    enrolled_title: 'Các khóa học và sự kiện trực tiếp đã ghi danh',
    enrolled_subtitle: 'Các khóa học và sự kiện trực tiếp bạn đã đăng ký',
    enrolled_message: 'Bạn hiện chưa đăng ký khóa học hay sự kiện nào.',
    past_live_event_title: 'Sự kiện trực tiếp từng diễn ra',
    past_live_event_subTitle: 'Sự kiện trực tiếp từng diễn ra',
    past_live_event: 'Không có sự kiện trực tiếp nào từng diễn ra',
    recomended_title: 'Các khóa học và sự kiện trực tiếp được đề xuất cho bạn',
    now_live_event_title: 'Đang phát trực tiếp',
    now_live_event_subTitle: 'Các sự kiện đang trực tiếp hoặc sắp diễn ra',
    now_live_event: 'Hiện không có sự kiện trực tiếp nào diễn ra.',
    webinar_event_title: 'Kênh hội thảo online',
    webinar_event_subTitle: 'Hội thảo online từng diễn ra',
    webinar_event: 'Hiện không có buổi hội thảo online nào.',
    p2p_event_title: 'Buổi huấn luyện',
    p2p_event_subTitle: 'Buổi huấn luyện từng diễn ra',
    p2p_event: 'Hiện không có buổi huấn luyện nào.',

    now_live_assessment_title: 'Đang phát trực tiếp',
    now_live_assessment_subTitle:
      'Bài đánh giá trực tiếp đang hoặc sáp diễn ra',
    now_live_assessment_message:
      'Hiện không có buổi đánh giá trực tiếp nào diễn ra.',

    upcoming_assessment_title: 'Bài đánh giá trực tiếp sắp diễn ra',
    upcoming_assessment_subtitle: 'Bài đánh giá trực tiếp sắp diễn ra',
    upcoming_assessment_message:
      'Hiện không có buổi đánh giá trực tiếp nào sắp diễn ra.',

    past_live_assessment_title: 'Các bài đánh giá trực tiếp từng diễn ra',
    past_live_assessment_subTitle: 'Các bài đánh giá trực tiếp từng diễn ra',
    past_live_assessment_message:
      'Hiện không có bài đánh giá trực tiếp từng diễn ra.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Chúng tôi có các khóa học và sự kiện trực tiếp được biên tập dựa trên <0><0/></0> của bạn',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Tìm khóa Đào tạo',
    search_assessment: 'Tìm Bài đánh giá',
    recomended_message:
      'Hiện tại không có khóa học và sự kiện nào được đề xuất cho bạn.'
  },

  warnings: {
    delete: 'Bạn chắc chứ?'
  },

  workshop: {
    title: 'Hội thảo của tôi',
    subtitle: 'Tạo khóa học hoặc tổ chức sự kiện trực tiếp của riêng bạn',
    new_course: 'Tạo một khóa học mới',
    new_event: 'Tạo một sự kiện trực tiếp mới',
    new_assessment: 'Tạo một bài đánh giá trực tiếp mới',
    courses_title: 'Các khóa học và sự kiện trực tiếp đã tạo',
    courses_subtitle:
      'Chỉnh sửa & Đăng tải khóa học bạn đã tạo và lập kế hoạch cho các sự kiện trực tiếp',
    tags_course: 'Khóa học',
    tags_live: 'Sự kiện trực tiếp',
    tags_assessment: 'Bài đánh giá trực tiếp'
  },

  help_center: {
    sidebar: {
      title: 'Chúng tôi có thể giúp gì cho bạn?',
      back_to: 'Bảng điều khiển'
    },
    menu: {
      help_center: 'Trung tâm trợ giúp',
      whats_new: 'Có gì mới',
      feedback: 'Phản hồi',
      feedback_title: 'Gửi phản hồi cho Sharelook',
      terms_and_conditions: 'Điều khoản và Điều kiện',
      privacy_policy: 'Chính sách bảo mật',
      contact_us: 'Liên hệ với chúng tôi'
    },
    get_started: {
      title: 'Bắt đầu cùng Sharelook',
      description: {
        main:
          'CHÚNG TÔI TÍCH HỢP VIDEO TRỰC TIẾP VÀO CÔNG CỤ XÂY DỰNG KHÓA HỌC RẤT DỄ DÙNG',
        tagline:
          'Với hệ thống phát sóng trực tiếp được cấp bằng sáng chế của ShareLook, bạn có thể tạo tương tác, tự động hóa việc học và tối đa hóa việc chia sẻ kiến ​​thức.'
      }
    },
    whats_new: {
      title: 'Bắt đầu cùng Sharelook',
      description: {
        main:
          'Bạn có biết rằng bài học theo phong cách Kể chuyện có thể được tạo trong thời gian ngắn và không quá tốn kém không?  Bạn nên kết hợp công cụ xây dựng khóa học ShareLook với các tài năng phù hợp. Bạn có thể hỏi chúng tôi "CÁCH THỰC HIỆN',
        tagline: ''
      }
    },
    create_workshop: 'Cách tạo hội thảo',
    create_course: 'Cách tạo khóa học',
    create_user: 'Cách tạo người dùng',
    create_group: 'Cách tạo nhóm',
    create_webinar: 'Cách tạo hội thảo online',
    create_live_event: 'Cách tạo sự kiện trực tiếp',
    create_live_assessment: 'Cách tạo bài đánh giá trực tiếp',
    create_live_on_mobile: 'Tạo buổi phát trực tiếp trên Di động',
    personalize_profile: 'Cá nhân hóa cho Hồ sơ',
    manage_group: 'Quản lý nhóm',
    report: 'Báo cáo',
    add_annotations: 'Cách thêm chú thích/cửa sổ thông báo',
    add_audio: 'Cách thêm tệp âm thanh',
    add_header_and_footer: 'Thêm Đầu trang & Chân trang',
    add_image: 'Cách thêm hình ảnh',
    add_quiz_content: 'Cách thêm nội dung bài kiểm tra',
    add_text_content: 'Cách thêm nội dung văn bản',
    add_user: 'Cách thêm người dùng',
    add_video: 'Cách thêm video',
    clone_course: 'Cách sao chép khóa học',
    find_live_broadcast: 'Cách tìm chương trình phát sóng trực tiếp',
    contact_us: {
      title: 'Liên hệ với chúng tôi',
      subject_placeholder: 'Nhập chủ đề*',
      content_placeholder: 'Nhập tin nhắn*',
      subject_required: '*Chủ đề là thông tin bắt buộc phải nhập',
      content_required: '*Tin nhắn là thông tin bắt buộc phải nhập',
      submit: 'Gửi',
      cancel: 'Hủy',
      sent: 'Đã gửi'
    },
    feedback: {
      title: 'Gửi phản hồi cho Sharelook',
      attach_title: 'Đính kèm tệp',
      choose: 'Chọn tệp',
      detail: 'Tệp này sẽ được chia sẻ với đội ngũ hỗ trợ',
      content_placeholder: 'Nhập tin nhắn*',
      content_required: '*Tin nhắn là thông tin bắt buộc phải nhập',
      submit: 'Gửi',
      cancel: 'Hủy',
      sent: 'Đã gửi'
    }
  },
  discussion: {
    join_discussion: 'Tham gia thảo luận'
  },
  notes: {
    title: 'Ghi chú của tôi',
    add_note: 'Thêm ghi chú mới'
  }
}
