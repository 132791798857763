import http from './http'

function contact (data) {
  return http({
    method: 'POST',
    url: `/help/contact`,
    data
  })
}

function feedback (data) {
  return http({
    method: 'POST',
    url: `/help/feedback`,
    data
  })
}
export {
  contact,
  feedback
}
