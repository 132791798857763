/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-09-23 10:20:38
 * @LastEditors: Please set LastEditors
 */
import styled, { css } from 'styled-components'
import { Form, Input, Select } from 'antd'

import {
  media,
  Wrapper as WrapperCommon,
  Main as MainCommon
} from '../../../styles'
import { styleguide } from '../../../constants'

const { colors } = styleguide

export const Wrapper = styled(WrapperCommon)`
  justify-content: space-between;
  height: calc(100vh - 11.5rem);
  overflow-y: auto;
`

export const Main = styled(MainCommon)`
  padding: 2.5rem 3.5rem 4.5rem;

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}
`

// export const Stage = styled.section`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin-bottom: 2.5rem;

//   ${media.md`
//     flex-direction: column;
//   `}
// `
export const Stage = styled.section`
  width: 100%;
`

export const LeftContent = styled.div`
  width: 100%;
`

export const RightAside = styled.div`
  min-width: 21.5rem;
  max-width: 21.5rem;
  text-align: center;
  margin-left: 2rem;

  ${media.md`
    min-width: none;
    max-width: none;
    width: 100%;
    margin-left: 0;
  `}
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.md`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}
`

export const FormItem = styled(Form.Item)`
  && {
    margin-bottom: 1rem;
  }
`

export const SLInput = styled(Input)`
  && {
    max-width: 100%;

    ${media.md`
      max-width: none;
      width: 100%;
    `}

    ${props =>
      props.tighter &&
      props.tighter === 'true' &&
      css`
        max-width: 37.9rem;
      `}
  }
`

export const SLSelect = styled(Select)`
  && {
    width: 100%;
    max-width: 100%;

    ${media.md`
      max-width: none;
    `}
  }
`

export const InputLabel = styled.span`
  display: block;
  color: ${colors.blakGray};
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: 600;
  text-transform: uppercase;

  ${props =>
    props.vertical &&
    css`
      margin-left: 0;
    `}

  ${media.md`
    margin-left: 0;
  `}
`

export const HR = styled.hr`
  height: 0.1rem;
  color: #ddd;
  background-color: #ddd;
  border: none;
  margin-bottom: 1.5rem;
`

// page builder special styles

export const Container = styled.section`
  background: ${colors.PrimaryLinear};
  padding: 1rem 0 12rem 0;
  height: calc(100vh - 24rem);
  overflow: auto;
  display: grid;
  place-items: center;
`

export const Pages = styled.div`
  width: 155rem;
`

Pages.List = styled.div`
  display: flex;

  align-items: flex-start;
  justify-content: center;
`

Pages.Item = styled.div`
  flex-shrink: 0;
  width: 110.5rem;
  padding: 0 1rem;
`

export const FakePage = styled.div`
  background: #fff;
  padding: 2rem;
`

export const ErrorMessage = styled.div`
  color: ${colors.red};
  display: none;

  ${props =>
    props.visible &&
    `
    display: block;
  `}
`
