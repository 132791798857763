import React from 'react'
import './EventOverview.scss'

import { Tag } from 'antd'
import { withTranslation } from 'react-i18next'

function EventOverview(props) {
  const {
    event: {
      cover: { link },
      description,
      topic_list
    },
    t
  } = props
  return (
    <div className="event__overview">
      <img src={link} alt="" className="event__overview_cover" />
      <div className="event__overview_description">
        <div className="event__overview_description_top">
          <span>{t('labels:tags')} </span>
          <div className="event__overview_description_top_tags">
            {topic_list.length > 0 &&
              topic_list.map(tag => (
                <Tag style={{ borderRadius: '10px' }} color="#108ee9">
                  {tag.title}
                </Tag>
              ))}
          </div>
        </div>
        <p>{description || t('v2:no_description_available')}</p>
      </div>
    </div>
  )
}

export default withTranslation()(EventOverview)
