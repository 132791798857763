import styled, { css } from 'styled-components'
import { Form } from 'antd'
import { Modal } from '../../../../../../components'

import { styleguide } from '../../../../../../constants'

const { colors } = styleguide

const ModalGroups = styled(Modal)`
`

ModalGroups.Container = styled.div`
`

ModalGroups.Upload = styled.div`
  width: 100%;
`

ModalGroups.Dropzone = styled.div`
  height: 7rem;
  border: 1px dashed ${colors.darkGreyTwo};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1.4rem 0;
  transition: border-color .2s;

  &:hover {
    border-color: ${colors.blue};
  }

  ${props => props.active && css`
    background: rgba(0, 104, 255, 0.1);
    border-color: ${colors.blue};
  `}

  ${props => props.background && css`
    height: 15rem;
    background: url(${props.background}) no-repeat center;
    background-size: cover;
  `}
`

ModalGroups.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`

ModalGroups.Dropzone.Button = styled.div`
  border-radius: 5rem;
  border: 1px solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  padding: .4rem 2.4rem;
  text-decoration: uppercase;
  transition: border-color .2s, color .2s, opacity .2s;;

  i {
    margin-right: .8rem;
  }

  ${ModalGroups.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props => props.hide && css`
      background: rgba(255, 255, 255, 0.3);
      border-color: ${colors.white};
      color: ${colors.white};
      opacity: 1;
    `}
  }

  ${props => props.hide && css`
    opacity: 0;
    border-color: ${colors.white};
    color: ${colors.white};
  `}
`

ModalGroups.BtnGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 1.5rem 0;
`

const FormItem = styled(Form.Item)`
  && {
    width: 100%;
    margin-bottom: .5rem;

    .ant-form-item-label {
      label {
        font-weight: 600;
        font-size: 1.1rem;

        &.ant-form-item-required {
          &:before {
            display: none;
          }
        }
      }
    }
  }
`

export {
  ModalGroups,
  FormItem
}
