function DataModelSupport(supportede, supportReadt, supportWritei) {
	this.Supported = supportede
	this.SupportsRead = supportReadt
	this.SupportsWrite = supportWritei
}

const arySupportedElements = new Array(50);
arySupportedElements["cmi._version"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.core._children"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.student_id"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.student_name"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.lesson_location"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.core.credit"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.core.lesson_status"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.core.entry"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.core.score._children"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.score.raw"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.core.total_time"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.lesson_mode"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.core.exit"] = new DataModelSupport(true, false, true)
arySupportedElements["cmi.core.session_time"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.suspend_data"] = new DataModelSupport(true, true, true)
arySupportedElements["cmi.launch_data"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.objectives._children"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.objectives._count"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.objectives.n.id"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements[
	"cmi.objectives.n.score._children"
] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.objectives.n.score.raw"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.objectives.n.score.max"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.objectives.n.score.min"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.objectives.n.status"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.core.score.max"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.core.score.min"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.comments"] = new DataModelSupport(true, true, true)
arySupportedElements["cmi.comments_from_lms"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.student_data._children"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.student_data.mastery_score"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.student_data.max_time_allowed"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.student_data.time_limit_action"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.student_preference._children"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.student_preference.audio"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.student_preference.language"] = new DataModelSupport(true, true, true)
arySupportedElements["cmi.student_preference.speed"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.student_preference.text"] = new DataModelSupport(
	true,
	true,
	true
)
arySupportedElements["cmi.interactions._children"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.interactions._count"] = new DataModelSupport(
	true,
	true,
	false
)
arySupportedElements["cmi.interactions.n.id"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.objectives._count"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.interactions.n.objectives.n.id"] = new DataModelSupport(true, false, true)
arySupportedElements["cmi.interactions.n.time"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.type"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.correct_responses._count"] = new DataModelSupport(true, true, false)
arySupportedElements["cmi.interactions.n.correct_responses.n.pattern"] = new DataModelSupport(true, false, true)
arySupportedElements["cmi.interactions.n.weighting"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.student_response"] = new DataModelSupport(true, false, true)
arySupportedElements["cmi.interactions.n.result"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.latency"] = new DataModelSupport(
	true,
	false,
	true
)
arySupportedElements["cmi.interactions.n.text"] = new DataModelSupport(
	true,
	false,
	true
)

var SCORM_EXIT_TIME_OUT = "time-out",
	SCORM_EXIT_SUSPEND = "suspend",
	SCORM_EXIT_LOGOUT = "logout",
	SCORM_EXIT_UNKNOWN = "",
	SCORM_STATUS_PASSED = "passed",
	SCORM_STATUS_COMPLETED = "completed",
	SCORM_STATUS_FAILED = "failed",
	SCORM_STATUS_INCOMPLETE = "incomplete",
	SCORM_STATUS_BROWSED = "browsed",
	SCORM_STATUS_NOT_ATTEMPTED = "not attempted",
	SCORM_TRUE_FALSE = "true-false",
	SCORM_CHOICE = "choice",
	SCORM_FILL_IN = "fill-in",
	SCORM_MATCHING = "matching",
	SCORM_PERFORMANCE = "performance",
	SCORM_SEQUENCING = "sequencing",
	SCORM_LIKERT = "likert",
	SCORM_NUMERIC = "numeric",
	SCORM_CORRECT = "correct",
	// 1.2
	SCORM_WRONG = "wrong",
	SCORM_UNANTICIPATED = "unanticipated",
	SCORM_NEUTRAL = "neutral";

const aryVocabularies = { exit: [], status: [], interaction: [], result: [] }
aryVocabularies.exit = [
	SCORM_EXIT_TIME_OUT,
	SCORM_EXIT_SUSPEND,
	SCORM_EXIT_LOGOUT,
	SCORM_EXIT_UNKNOWN
]
aryVocabularies.status = [
	SCORM_STATUS_PASSED,
	SCORM_STATUS_COMPLETED,
	SCORM_STATUS_FAILED,
	SCORM_STATUS_INCOMPLETE,
	SCORM_STATUS_BROWSED,
	SCORM_STATUS_NOT_ATTEMPTED
]
aryVocabularies.interaction = [
	SCORM_TRUE_FALSE,
	SCORM_CHOICE,
	SCORM_FILL_IN,
	SCORM_MATCHING,
	SCORM_PERFORMANCE,
	SCORM_SEQUENCING,
	SCORM_LIKERT,
	SCORM_NUMERIC
]
aryVocabularies.result = [
	SCORM_CORRECT,
	SCORM_WRONG,
	SCORM_UNANTICIPATED,
	SCORM_NEUTRAL
]
export { arySupportedElements, aryVocabularies, SCORM_STATUS_COMPLETED, SCORM_STATUS_FAILED, SCORM_STATUS_NOT_ATTEMPTED }