import React from 'react'
import TriviaGame from './Game/Game'
import TriviaAddEdit from './AddEdit/AddEdit'
import TriviaList from './List/List'
import NewHeader from '../../components/NewHeader'
import './style.scss'

const Trivia = props => {
  const currentRoute = window.location.pathname.split('/').pop()
  const isEdit = /^\d+$/.test(currentRoute)
  return (
    <>
      <div className="share-sphere-head">
        <NewHeader />
      </div>
      <div>
        {currentRoute === 'add' && <TriviaAddEdit {...props} />}
        {isEdit && <TriviaAddEdit id={currentRoute} {...props} />}
        {currentRoute === 'list' && <TriviaList {...props} />}
        {currentRoute === 'share-sphere' && <TriviaGame {...props} />}
      </div>
    </>
  )
}

export default Trivia
