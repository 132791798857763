import SkillJourney from './SkillJourney'

import { connect } from 'react-redux'
import actions from '../../store/actions'

export default connect(
    state => ({
      topics: state.topics,
      user: state.user
    }),
    dispatch => ({
      upload: (file, opts) => dispatch(actions.library.upload(file, opts)),
      updateUser: (id, data, dbData) => dispatch(actions.user.updateUser(id, data, dbData)),
      updateTopicList: newTopic =>
        dispatch(actions.topics.updateTopicList(newTopic))
    })
  )(SkillJourney)
