import React, { Component, Fragment } from 'react'
import { Alert, message } from 'antd'
import _, { intersection } from 'lodash'
import { TopPageLink, Spinner, CloneModal } from '../../../components'
import history from '../../../history'
import ViewContent from './View-content'
import ModalLessonLandscape from './internal/ModalLessonLandscape'
import { getPercent } from '../../../utils'
import api from '../../../services/api'
import { withTranslation } from 'react-i18next'

const checkFetching = (isFetching, item, isOwner, t, App) => {
  if (isFetching) {
    return <Spinner height="40rem" />
  }

  if (item && item.hasOwnProperty('id')) {
    return <ViewContent App={App} isOwner={isOwner} />
  }

  return (
    <section className="section">
      <Alert type="error" message={t('errors:not_found_course')} />
    </section>
  )
}

class SingleCoursePage extends Component {
  state = {
    activeTab: 'course_overview',
    activeLessonId: null,
    modalVisible: false,
    courseCloneTemplate: null,
    isClone: false,
    userToken: '',
    downloadingLessonId: null
  }

  componentDidMount() {
    const {
      location: { search, pathname },
      user: {
        info: { active_organization_id }
      }
    } = this.props

    const organizationUrlParam = search && search.split('id=')[1]

    const organizationId = organizationUrlParam.split('&')[0]

    if (organizationId) {
      // send null if org id is 1 from the marketplace
      const orgIdFromLink = organizationId === 1 ? null : organizationId

      // remove ?id= from url
      const newUrl = window.location.origin + pathname
      window.history.pushState({}, null, newUrl)

      if (organizationId.length && orgIdFromLink !== active_organization_id) {
        this.changeActiveOrg(orgIdFromLink, true)
        return
      }
    }

    this.getCourseData()
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      user: { isFetchingUserAttributes },
      organizations: { rows },
      course: { isFetching, item },
      location: { search }
    } = this.props

    const loadingCondition = isFetching || isFetchingUserAttributes

    if (prevProps.organizations.rows !== rows && !loadingCondition && !search) {
      this.checkAndSwitchOrg(rows)
    }

    if (item && item.course_type === 'scorm') {
      history.push(`/scorm/${item.id}`)
    }
  }

  componentWillUnmount() {
    const { resetCourseById, clearNotes, clearDiscussion } = this.props
    resetCourseById()
    clearNotes()
    clearDiscussion()
  }

  checkAndSwitchOrg = rows => {
    const {
      user: {
        info: { active_organization_id: userOrg }
      },
      course: { item }
    } = this.props

    const userOrgs = rows.map(e => e.id)

    if (!userOrg && item && item.purchased_from_marketplace) return

    if (item && item.setting) {
      const commonIds = intersection(item.setting.organization_ids, userOrgs)
      console.log('update condition 2', commonIds, userOrg)

      if (commonIds.length > 0) {
        const assignedOrg = _.findIndex(commonIds, function(o) {
          return o === userOrg
        })
        if (assignedOrg === -1) {
          this.changeActiveOrg(commonIds[0])
        }
      }
    }
  }

  getCourseData = () => {
    const { getDiscussionLog, match } = this.props
    const { id } = match.params
    this.getApiData()
    if (getDiscussionLog) {
      const type = 'course'
      getDiscussionLog({ id, type })
    }
  }

  changeActiveOrg = (orgId, isMount) => {
    const {
      user: {
        info: { id }
      },
      updateUser
    } = this.props
    updateUser(id, null, {
      active_organization_id: orgId
    }).then(() => {
      if (isMount) {
        this.getCourseData()
      } else {
        window.location.reload()
      }
    })
  }

  getApiData = () => {
    const { getCourseById, viewCourse, match } = this.props
    const id = match.params.id
    getCourseById(id)
    viewCourse(id)
  }

  handleChangeTab = activeTab => {
    const data = { activeTab }
    const { course } = this.props
    const { activeLessonId } = this.state

    if (!activeLessonId && course.item.courses_lessons.length) {
      data.activeLessonId = course.item.courses_lessons[0].id
    }

    this.setState(data)
  }

  handleChangeLesson = activeLessonId => {
    this.setState({
      activeLessonId,
      activeTab: 'lessons'
    })
  }

  toggleModal = (isGoBack = false) => {
    this.setState(
      state => ({
        modalVisible: !state.modalVisible
      }),
      () => {
        if (isGoBack) {
          this.goBack()
        }
      }
    )
  }

  downloadLesson = id => {
    this.setState({ downloadingLessonId: id }, () => {
      api.courses
        .downloadLesson(id)
        .then(res => {
          const link = res.data && res.data.link

          if (link) {
            window.open(link)
          }
        })
        .catch(err => {
          console.log(err)
          message.error(err)
        })
        .finally(() => {
          this.setState({ downloadingLessonId: null })
        })
    })
  }

  goBack = () => {
    const {
      course: {
        item: { creator_id: creatorId }
      },
      user: {
        info: { id: userId }
      }
    } = this.props
    const isOwner = userId === creatorId
    const path = this.getPath(isOwner)
    if (path) {
      history.push(path)
    }
  }

  toggleModalData = (isGoBack = false) => {
    this.setState(
      state => ({
        modalVisible: !state.modalVisible
      }),
      () => {
        if (isGoBack) {
          this.goMainBack()
        }
      }
    )
  }

  goMainBack = () => {
    const {
      course: {
        item: { creator_id: creatorId }
      },
      user: {
        info: { id: userId }
      }
    } = this.props
    const isOwner = userId === creatorId
    const path = this.getMainPath(isOwner)
    if (path) {
      history.push(path)
    }
  }

  getLesson = id => {
    const {
      enrollCourse,
      getLessonById,
      course: {
        item: { id: courseId, publisher_id: publisherId }
      }
    } = this.props

    getLessonById(id).then(({ pages }) => {
      if (!publisherId) return
      enrollCourse(courseId, id, !!pages.length && pages[0].uid)
    })
  }

  openLessonContent = id => {
    console.log('log id here', id)
    this.postCheckInData(id)
    this.toggleModal()
    this.getLesson(id)
  }

  postCheckInData = lid => {
    console.log('log this.props', this.props)
    const {
      match: {
        params: { id }
      }
    } = this.props

    const data = {
      course_id: id,
      lesson_id: lid
    }
    api.courses
      .checkInCourse(id, data)
      .then(({ data }) => {
        console.log('response log checkin', data)
        this.setState({
          userToken: data.user_token
        })
      })
      .catch(() => {})
  }

  cloneCourse = data => {
    const { courses } = this.props

    if (courses.courseCloningProgress) return

    this.setState(
      {
        isClone: true
      },
      () => {
        this.getCourseById(data)
      }
    )
  }

  getCourseById = data => {
    const { getCourseById, initCloneSocket, user } = this.props
    const { id, title } = data

    initCloneSocket({ courseId: id, user })

    getCourseById(id).then(({ courses_lessons }) => {
      const courseLessons = _.map(courses_lessons, ({ title, id }) => {
        const newLesson = {
          title,
          id,
          isChecked: true
        }
        return newLesson
      })
      this.setState({
        courseCloneTemplate: {
          courseId: id,
          courseTitle: `Copy of ${title}`,
          courseLessons,
          totalLesson: courseLessons.length
        }
      })
    })
  }

  openNextLesson = id => {
    const {
      course: {
        item: { courses_lessons: lessons = [] }
      }
    } = this.props
    const activeLessonIndex = lessons.findIndex(i => i.id === id)

    if (activeLessonIndex < lessons.length - 1) {
      const nextLessonId = lessons[activeLessonIndex + 1].id

      this.getLesson(nextLessonId)
      this.postCheckInData(nextLessonId)
      this.setState({
        activeLessonId: nextLessonId
      })
    } else {
      this.toggleModal()
    }
  }

  enroll = () => {
    const {
      course: {
        item: { id }
      },
      enrollCourse
    } = this.props

    console.log('Enroll clicked')

    enrollCourse(id)
  }

  unenroll = () => {
    const {
      course: {
        item: { id }
      },
      unenrollCourse
    } = this.props

    unenrollCourse(id)
  }

  getPercent = lesson => {
    const {
      id,
      info: { pages_uids: uids = [] }
    } = lesson
    const { enrollment } = this.props.course.item
    if (!enrollment) {
      return 0
    }
    const { progress = {} } = enrollment
    const lessonProgress = progress[id]

    if (lessonProgress) {
      const completedPages = uids.filter(uid => lessonProgress.includes(uid))

      if (completedPages.length === uids.length) {
        return 100
      }
      return getPercent(completedPages.length, uids.length)
    }
    return 0
  }

  getMainPath = () => '/training'

  getPath = isOwner =>
    isOwner
      ? `/courses/${this.props.course.item.id}`
      : `/courses/${this.props.course.item.id}`

  render() {
    const {
      course: {
        isFetching,
        item: { creator_id: creatorId, host_ids = [] },
        item
      },
      user: {
        info: { id: userId },
        isFetchingUserAttributes
      },
      enrollCourse,
      t
    } = this.props
    const {
      activeLessonId,
      modalVisible,
      isClone,
      courseCloneTemplate,
      userToken
    } = this.state
    const isOwner = userId === creatorId || host_ids.includes(userId)
    const skillJourney =
      this.props.location.state && this.props.location.state.skillJourney
    const skillJourneyId =
      this.props.location.state && this.props.location.state.skillJourneyId

    const loading = !item.id && isFetchingUserAttributes ? true : isFetching
    return (
      <Fragment>
        <TopPageLink
          page={skillJourney ? t('v4:skill') : t('navigation:training')}
          path={
            skillJourney
              ? `/skill-journey/${skillJourneyId}`
              : this.getMainPath(isOwner)
          }
        />

        {checkFetching(loading, item, isOwner, t, this)}

        {modalVisible && (
          <ModalLessonLandscape
            modalVisible={modalVisible}
            toggleModal={this.toggleModal}
            openNextLesson={this.openNextLesson}
            enrollCourse={enrollCourse}
            course={item}
            userToken={userToken}
            activeLessonId={activeLessonId}
          />
        )}
        {isClone && (
          <CloneModal
            width="800px"
            visible={isClone}
            data={courseCloneTemplate}
            app={this}
          />
        )}
      </Fragment>
    )
  }
}

export default withTranslation('coursepage')(SingleCoursePage)
