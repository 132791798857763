import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import QuizCreation from './QuizCreation'

import * as quizesActions from '../../../../../../store/actions/quizes'

export default connect(null, dispatch => ({
  quizesActions: bindActionCreators(quizesActions, dispatch)
}))(QuizCreation)
