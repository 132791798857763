import http from './http'

function getCertificatesById(id) {
  return http({
    method: 'GET',
    url: '/users/' + id + '/certs'
  })
}

export { getCertificatesById }
