import React, { Component, Fragment } from 'react'
import { Icon, Button } from 'antd'
import Dropzone from 'react-dropzone'
import _ from 'lodash'
import './Audio.scss'
import Preview from '../../Preview'
import Manager from '../../Manager'
import { withTranslation } from 'react-i18next'

class Audio extends Component {
  state = {
    isUploading: false,
    isManager: false
  }

  componentDidMount () {
    this.autoUpload()
  }

  /**
   * auto upload file if dropped file have data
   */
  autoUpload = () => {
    const { droppedFile, node } = this.props
    if (droppedFile) {
      _.each(droppedFile, data => {
        const { file, uid } = data

        if (uid === node.uid) {
          this.onDrop([file])
        }
      })
    }
  }

  openManager = () => {
    this.setState({
      isManager: true
    })
  }

  closeManager = () => {
    this.setState({
      isManager: false
    })
  }

  handleManager = (event, data, item) => {
    event.stopPropagation()

    const { uid, handleParams } = this.props

    if (handleParams) {
      handleParams(uid, data)
    }
  }

  clearContent = event => {
    event.stopPropagation()

    const { uid, handleParams } = this.props

    if (handleParams) {
      handleParams(uid, { content: '', content_id: null })
    }
  }

  onDrop = files => {
    const {
      uid,
      handleParams,
      upload,
      closeEditor,
      handleFileDropIn,
      handleMultipleDrop,
      removeFromUploadingList
    } = this.props

    const file = files[0]

    if (!file) {
      if (handleMultipleDrop) {
        handleMultipleDrop()
      }
      return
    }

    this.setState({
      isUploading: true
    })

    removeFromUploadingList(uid)

    return upload(file, { fileUid: uid })
      .then(response => {
        if (handleParams) {
          handleParams(uid, { content: response.link, content_id: response.id })
        }

        this.setState(
          {
            isUploading: false
          },
          () => {
            closeEditor()
            handleFileDropIn(null) // set Droppedfile in redux to null
          }
        )
      })
      .catch(() => {
        this.setState({
          isUploading: false
        })
      })
  }

  getPercent = ({ uid }) => {
    const { uploadProgress } = this.props.library
    const data = _.map(uploadProgress, data => {
      if (data.fileUid === uid) {
        return data
      }
    })
    const result = _.filter(data, item => item)

    return result.length > 0 ? _.head(result).percent : 0
  }

  render () {
    const { node, closeEditor, t } = this.props
    const { isUploading, isManager } = this.state
    const { content } = node.params
    return (
      <Fragment>
        <div className="el-editor">
          <div className="el-editor__component">
            <div className="element element_image">
              {content ? (
                <Preview node={node}/>
              ) : (
                <Dropzone
                  accept="audio/*"
                  disabled={isUploading}
                  multiple
                  className="element-draggable element__draggable"
                  onDrop={this.onDrop}
                >
                  <div className="element-draggable__text">
                    {isUploading ? (
                      <div
                        className="wrap_loading"
                        style={{ width: node.params.width - 40 }}
                      >
                        {t('general:uploading')} {this.getPercent(node)} %
                        <div className="upload-modal-item__percent-border">
                          <div
                            className={`upload-modal-item__percent`}
                            style={{ width: this.getPercent(node) + '%' }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <Icon type="upload"/> {t('buttons:upload_audio')}
                      </div>
                    )}
                  </div>
                </Dropzone>
              )}
            </div>
          </div>
          {!isUploading && (
            <div className="el-editor__controls">
              {node.params.content ? (
                <div className="el-editor__control">
                  <Button
                    type="default"
                    size="small"
                    icon="rest"
                    onClick={e => this.clearContent(e)}
                  >
                    {t('buttons:clear')}
                  </Button>
                </div>
              ) : (
                <div className="el-editor__control">
                  <Button
                    disabled={isUploading}
                    type="default"
                    size="small"
                    icon="folder"
                    onClick={() => this.openManager()}
                  >
                    {t('buttons:from_library')}
                  </Button>
                </div>
              )}
              <div className="el-editor__control">
                <Button
                  disabled={isUploading}
                  type="default"
                  size="small"
                  icon="check"
                  onClick={() => closeEditor()}
                >
                  {t('buttons:save')}
                </Button>
              </div>
            </div>
          )}
        </div>
        {isManager && (
          <Manager
            type={'AUDIO'}
            closeManager={this.closeManager}
            handleManager={this.handleManager}
          />
        )}
      </Fragment>
    )
  }
}

export default withTranslation()(Audio)
