import React, { Component, Fragment } from 'react'
import { Form, Input } from 'antd'
import { withTranslation } from 'react-i18next'

class ConfirmForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { confirmSignUp } = this.props

    this.props.form.validateFields((err, values) => {
      if (!err) {
        confirmSignUp(values)
      }
    })
  }

  render() {
    const { isUserNotConfirmed, username, t } = this.props

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          {isUserNotConfirmed && (
            <Form.Item>
              <Input
                addonBefore={t('labels:username')}
                defaultValue={username}
                disabled
              />
            </Form.Item>
          )}
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(withTranslation('signup')(ConfirmForm))
