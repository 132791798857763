import { connect } from 'react-redux'
import actions from '../../store/actions'
import Onboarding from './Onboarding'

const mapStateToProps = state => ({
  user: state.user,
  topics: state.topics
})

const mapDispatchToProps = dispatch => ({
  updateUser: (id, data, dbData) => dispatch(actions.user.updateUser(id, data, dbData)),
  upload: (file, opts) => dispatch(actions.library.upload(file, opts))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding)
