import http from './http'

const getReport = val => {
  console.log('val: ', val)
  const type = val.type
  const search = val.search
  const date = val.date
  const from = val.from
  const to = val.to
  return http({
    method: 'GET',
    url: '/reports',
    params: {
      type,
      offset: val.offset,
      limit: val.limit,
      search,
      date,
      from,
      to
    }
  })
}
const getHostReport = val => {
  const type = val.type
  const search = val.search
  const date = val.date
  const from = val.from
  const to = val.to
  return http({
    method: 'GET',
    url: `/reports/hostUser/${val.userId}?type=${type}`,
    params: {
      type,
      offset: val.offset,
      limit: val.limit,
      search,
      date,
      from,
      to
    }
  })
}

const getOwnerReport = val => {
  const type = val.type
  const search = val.search
  const date = val.date
  const from = val.from
  const to = val.to
  return http({
    method: 'GET',
    url: '/reports/product/owner',
    params: {
      type,
      offset: val.offset,
      limit: val.limit,
      search,
      date,
      from,
      to
    }
  })
}

const getEventOwnerReport = val => {
  const type = val.type
  const event_type = val.event_type
  const search = val.search
  const date = val.date
  const from = val.from
  const to = val.to
  return http({
    method: 'GET',
    url: '/reports/product/owner',
    params: {
      type,
      event_type,
      offset: val.offset,
      limit: val.limit,
      search,
      date,
      from,
      to
    }
  })
}

const getCourseWorkshopReport = params => {
  return http({
    method: 'GET',
    url: `reports/workshop/courses?from=${params.from}&to=${params.to}`
  })
}

const getEventList = params => {
  return http({
    method: 'GET',
    url: `reports/events?offset=${params.offset}&limit=${params.limit}&date=${params.date}${params.search ? "&search=" + params.search : ""}&from=${params.from}&to=${params.to}`
  })
}

const getCourseList = params => {
  return http({
    method: 'GET',
    url: `reports/courses?offset=${params.offset}&limit=${params.limit}&date=${params.date}${params.search ? "&search=" + params.search : ""}&from=${params.from}&to=${params.to}`
  })
}

// marketplace report

const getMarketCourseList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=course&offset=${params.offset}&limit=${params.limit}`
  })
}
const getMarketWebinarList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=event&event_type=webinar&offset=${params.offset}&limit=${params.limit}`
  })
}

const getMarketCoachingList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=event&event_type=coaching_session&offset=${params.offset}&limit=${params.limit}`
  })
}
const getMarketChannelList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=channel&offset=${params.offset}&limit=${params.limit}`
  })
}

const getMarketPublicConferenceList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=event&event_type=public_conference&offset=${params.offset}&limit=${params.limit}`
  })
}

const getMarketMazeList = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=maze&offset=${params.offset}&limit=${params.limit}`
  })
}

const getSkillList = params => {
  console.log('skill list', params)
  return http({
    method: 'GET',
    // url: `reports/skills?offset=0&limit=10&date=all&search=&from=&to=`
    url: `reports/skills?offset=${params.offset}&limit=${params.limit}&date=${params.date}${params.search ? "&search=" + params.search : ""}&from=${params.from}&to=${params.to}`
  })
}

const getLiveAssessmentsList = params => {
  console.log('liveassessments list', params)
  return http({
    method: 'GET',
    // url: `reports/live_assessment?offset=0&limit=10&date=all&search=&from=&to=`
    url: `reports/live_assessment?offset=${params.offset}&limit=${params.limit}&date=${params.date}${params.search ? "&search=" + params.search : ""}&from=${params.from}&to=${params.to}`
  })
}

const getWorkshopEventsReport = params => {
  return http({
    method: 'GET',
    url: `reports/workshop/events?from=${params.from}&to=${params.to}`
  })
}

const getWorkshopSkillJourneysReport = params => {
  return http({
    method: 'GET',
    // url: `reports?type=workshop&date=allTime&from=&to=`
    url: `reports/workshop/skills?from=${params.from}&to=${params.to}`
  })
}

const getUserWorkshopReports = params => {
  if (params.userType === 'participant') {
    return http({
      method: 'GET',
      url: `/reports/participantUser/${params.userId}?type=overall${
        params.date ? '&date=' + params.date : ''
      }${params.to ? '&to=' + params.to : ''}${
        params.from ? '&from=' + params.from : ''
      }`
    })
  } else {
    return http({
      method: 'GET',
      url: `/reports/hostUser/${params.userId}?type=overall&date=${params.date}&to=${params.to}&from=${params.from}`
    })
  }
}

const getUserReports = params => {
  return http({
    method: 'GET',
    url: `/reports/users?type=${params.userType}&offset=${params.offset}&limit=${params.limit}&date=${params.date}&from=${params.from}&to=${params.to}`
  })
}

const getUserData = params => {
  console.log('usertype detail', params)
  return http({
    method: 'GET',
    url: `/reports/${params.userType}/${params.id}?type=${params.type}&offset=${params.offset}&limit=${params.limit}&is_total=${params.is_total}${params.search ? "&search=" + params.search : ""}&date=${params.date}&to=${params.to}&from=${params.from}`
  })
}

const downloadAllReports = params => {
  console.log('usertype detail', params)
  return http({
    method: 'GET',
    url: `/reports/${params.userType}/${params.id}?type=${params.type}&is_total=${params.is_total}`
  })
}

const getUserReport = id => {
  return http({
    method: 'GET',
    url: '/reports/' + id
  })
}
const getEventReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/events/' + id,
    params: {
      type
    }
  })
}
const getCourseReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/course/' + id,
    params: {
      type
    }
  })
}

const getSkillJourneyReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/skill/' + id
    // params: {
    //   type
    // }
  })
}

const getLiveAssessmentsReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/live_assessment/' + id
    // params: {
    //   type
    // }
  })
}

const getLessonReport = (id, params) => {
  return http({
    method: 'GET',
    url: 'reports/lesson/' + id,
    params: {
      type: params.type,
      course_id: params.course_id
    }
  })
}
const getQuizReport = (id, type) => {
  return http({
    method: 'GET',
    url: 'reports/quiz/' + id,
    params: {
      type
    }
  })
}

const searchUserData = params => {
  console.log('paramsCheck', params)
  return http({
    method: 'GET',
    url: `/reports/users/?type=${params.userType}&${params.searchType}=${params.searchValue}&limit=${params.limit}&offset=${params.offset}&date=${params.date}&from=${params.from}&to=${params.to}`
  })
}

const getUserTimeSpentReport = params => {
  return http({
    method: 'GET',
    url: `/reports/workshop/users?from=${params.from}&to=${params.to}`
  })
}

const getMazeReport = params => {
  return http({
    method: 'GET',
    url: `reports/maze?limit=${params.limit}&offset=${params.offset}&search_by_title=${params.searchByTitle}&date=${params.date}&from=${params.from}&to=${params.to}`
  })
}

const getMazeReportById = id => {
  return http({
    method: 'GET',
    url: `reports/maze/${id}`
  })
}

const getMarketplaceReport = params => {
  return http({
    method: 'GET',
    url: `reports/product/owner?type=${params.userType}&offset=${params.offset}&limit=${params.limit}&date=${params.date}&from=${params.from}&to=${params.to}`
  })
}

const getUserMazeReport = params => {
  console.log('usertypemaze detailapi', params)
  const userType =
    params.userType === 'hostUser' || params.userType === 'host'
      ? 'hosts'
      : params.userType === 'participantUser'
      ? 'participant'
      : params.userType
  return http({
    method: 'GET',
    url: `reports/maze/${userType}/${params.id}?is_total=${params.is_total}&offset=${params.offset}&limit=${params.limit}${params.search ? "&search=" + params.search : ""}&date=${params.date}&to=${params.to}&from=${params.from}`
  })
}

// marketplace

const getMarketCourseReport = (id, type) => {
  return http({
    method: 'GET',
    url: `reports/product/owner/${id}`,
    params: {
      type: type
    }
  })
}
const getMarketEventReport = (id, type) => {
  return http({
    method: 'GET',
    url: `reports/product/owner/${id}`,
    params: {
      type: type
    }
  })
}

const getMarketchannelReport = (id, type) => {
  return http({
    method: 'GET',
    url: `reports/product/owner/${id}`,
    params: {
      type: type
    }
  })
}

const getMarketMazeReport = (id, type) => {
  return http({
    method: 'GET',
    url: `reports/product/owner/${id}`,
    params: {
      type: type
    }
  })
}

export {
  getReport,
  getHostReport,
  getUserReport,
  getUserReports,
  getEventReport,
  getCourseReport,
  getLessonReport,
  getQuizReport,
  getUserData,
  searchUserData,
  getUserWorkshopReports,
  getCourseWorkshopReport,
  downloadAllReports,
  getUserTimeSpentReport,
  getWorkshopEventsReport,
  getEventList,
  getCourseList,
  getMazeReportById,
  getMazeReport,
  getUserMazeReport,
  getSkillList,
  getWorkshopSkillJourneysReport,
  getSkillJourneyReport,
  getLiveAssessmentsReport,
  getLiveAssessmentsList,
  getMarketplaceReport,
  getMarketCourseList,
  getMarketWebinarList,
  getMarketCoachingList,
  getMarketPublicConferenceList,
  getMarketMazeList,
  getMarketCourseReport,
  getMarketEventReport,
  getMarketChannelList,
  getMarketchannelReport,
  getMarketMazeReport,
  getOwnerReport,
  getEventOwnerReport
}
