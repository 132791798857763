import React, { Component } from 'react'
import { Icon, Input, Button } from 'antd'
import { withTranslation } from 'react-i18next'

class Poll extends Component {
  addQuestion = () => {
    const { addQuestion } = this.props
    addQuestion()
  }

  deleteQuestion = async (index) => {
    const { deleteQuestion, savePoll } = this.props

    if (deleteQuestion) {
      await deleteQuestion(index)
      await savePoll()
    }
  }

  addAnswer = (rootIndex) => {
    const { addAnswer } = this.props

    if (addAnswer) {
      addAnswer(rootIndex)
    }
  }

  deleteAnswer = async (rootIndex, index) => {
    const { deleteAnswer, savePoll } = this.props

    if (deleteAnswer) {
      await deleteAnswer(rootIndex, index)
      await savePoll()
    }
  }

  handleQuestion = (index, e) => {
    const { handleQuestion } = this.props

    if (handleQuestion) {
      const value = e.target.value || ''
      handleQuestion(index, value)
    }
  }

  handleAnswer = (rootIndex, index, e) => {
    const { handleAnswer } = this.props

    if (handleAnswer) {
      const value = e.target.value || ''
      handleAnswer(rootIndex, index, value)
    }
  }

  savePoll = () => {
    const { savePoll } = this.props

    if (savePoll) {
      savePoll()
    }
  }

  render () {
    const { data, t } = this.props

    return (
      <div className='poll'>
        <div className='poll__head'>{t('labels:poll')}</div>
        {data.map((poll, idx) => (
          <div className='poll__content' key={`poll_${idx}`}>
            <span>{t('labels:question')}</span>
            {idx > 0 &&
              <div className='poll__delete' onClick={() => this.deleteQuestion(idx)}>
              {t('v2:delete_poll')}
                {/* <Icon type='delete' /> */}
              </div>
            }
            <div className='poll__question'>
              <Input
                placeholder={t('placeholders:question')}
                value={poll.question}
                onChange={(e) => this.handleQuestion(idx, e)}
              />
            </div>
            
            <div className='poll__answers'>
              <span>{t('labels:answers')}</span>
              <div className='poll-answers'>
                {poll.answers && poll.answers.map((answer, index) => (
                  <div className='poll-answers__row' key={index}>
                    <div className='poll-answers__input'>
                      <Input
                        placeholder={t('placeholders:answer')}
                        value={answer}
                        onBlur={this.savePoll}
                        onChange={(e) => this.handleAnswer(idx, index, e)}
                      />
                    </div>
                    <div className='poll-answers__delete' onClick={() => this.deleteAnswer(idx, index)}>
                      <Icon type='delete' />
                    </div>
                  </div>
                ))}
                <div className='poll-answers__row'>
                  <Button
                    icon='plus'
                    size='small'
                    type='primary'
                    onClick={()=> {this.addAnswer(idx)}}>
                    {t('buttons:add_answer')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Button
          icon='plus'
          size='small'
          type='primary'
          onClick={this.addQuestion}>
            {t('v2:add_poll')}
          </Button>
      </div>
    )
  }
}

export default withTranslation()(Poll)
