import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Modal,
  Input,
  Icon,
  Tooltip,
  Card,
  Row,
  Switch,
  Col,
  Radio,
  Alert,
  Select
} from 'antd'
import { CreatableSelect } from '../../../../../../../../components'
import { api } from '../../../../../../../../services'
import actions from '../../../../../../../../store/actions'
import { useDispatch } from 'react-redux'
import nanoid from 'nanoid'

import options from './storyFormOptions'
import { formatLabel } from '../utils'
import './style.scss'
import { useTranslation } from 'react-i18next'
import './style.scss'

const { Option } = Select

const StoryCreationForm = Form.create()(
  ({
    form,
    previewData = [],
    onCancel,
    lessonUpdate,
    pages,
    lessonTitle,
    currentPage
  }) => {
    const dispatch = useDispatch()
    const { getFieldDecorator } = form

    const [isGeneratingStory, setIsGeneratingStory] = useState(false)
    const [story, setStory] = useState(null)
    const [isStoryVisible, setIsStoryVisible] = useState(false)
    const [saveOriginalContent, setSaveOriginalContent] = useState(true)
    const [storyPosition, setStoryPosition] = useState('start')

    const { handleLessonInput } = actions.lessonBuilder

    const { t } = useTranslation()

    const handleSubmit = e => {
      e.preventDefault()
      form.validateFields((err, values) => {
        if (!err) {
          const storyElements = JSON.stringify(values)
          const contentFromPage = extractSelectedPageText()
          const referenceRemovedContent = removeReferenceList(contentFromPage)
          setIsGeneratingStory(true)
          api.courses
            .generateStory({
              params: {
                course_content:
                  `Course Name : ${lessonTitle} ` + referenceRemovedContent,
                story_elements: storyElements
              }
            })
            .then(res => {
              const storyObj = res.data.body[0]
              const storyString =
                storyObj.beginning + storyObj.middle + storyObj.end
              setStory(storyString)
              setIsStoryVisible(true)
            })
            .finally(() => setIsGeneratingStory(false))
        }
      })
    }

    function removeReferenceList(text) {
      var pattern = /References:(.*?)(?=<\/p>)/gs

      var textWithoutReferences = text.replace(pattern, '').trim()

      return textWithoutReferences
    }

    function extractSelectedPageText() {
      const selectedPages = previewData.filter(page => page.pageSelected)

      let concatenatedText = ''

      selectedPages.forEach(page => {
        page.data.forEach(element => {
          if (element.type === 'TEXT' && element.display === 'CONTENT') {
            concatenatedText += element.params.content + ' '
          }
        })
      })

      return concatenatedText
    }

    function splitLongString(text) {
      let sentences = text.split('. ')

      let sections = []
      let currentSection = ''
      let sectionWordCount = 0

      for (let sentence of sentences) {
        if (sectionWordCount + sentence.split(' ').length <= 150) {
          currentSection += sentence + '. '
          sectionWordCount += sentence.split(' ').length
        } else {
          sections.push(currentSection.trim())
          currentSection = sentence + '. '
          sectionWordCount = sentence.split(' ').length
        }
      }

      if (currentSection) {
        sections.push(currentSection.trim())
      }

      return sections
    }

    const onAddStory = () => {
      if (saveOriginalContent) {
        onAddStoryBlock()
      } else {
        Modal.confirm({
          title: 'Do you want to add story to the pages?',
          content: 'The original content will be replaced with the story text.',
          onOk() {
            handleAddStory()
          },
          onCancel() {
            console.log('Cancel')
          }
        })
      }
    }

    const onAddStoryBlock = () => {
      const isAddtoStart = storyPosition === 'start'
      const storyInSections = splitLongString(story)

      const { title } = getPageTitle(previewData, 1)

      let orderCount = isAddtoStart ? 0 : previewData.length + 1

      const defaultSize = { width: 1024, height: 768 }
      const defaultOrientation = 'landscape'

      const addedPages = storyInSections.map(content => ({
        size: defaultSize,
        orientation: defaultOrientation,
        data: [
          {
            uid: nanoid(8),
            type: 'TEXT',
            display: 'CONTENT',
            params: {
              content,
              height: 494,
              media_format: 'TEXT',
              width: 1023,
              x: 0,
              y: 58
            }
          },
          {
            uid: nanoid(8),
            type: 'TEXT',
            display: 'HEADING',
            params: {
              content: title,
              width: 1023,
              height: 58,
              x: 0,
              y: 0,
              media_format: 'TEXT'
            }
          }
        ],
        title,
        ordering: orderCount++
      }))

      const defaultPage = {
        data: [],
        orientation: 'portrait',
        size: defaultSize
      }

      const newPageArray = storyInSections.map(() => ({ ...defaultPage }))

      const newPages = isAddtoStart
        ? [...newPageArray, ...pages]
        : [...pages, ...newPageArray]

      const updatedData = isAddtoStart
        ? [...addedPages, ...previewData]
        : [...previewData, ...addedPages]

      newPages.forEach((page, index) => {
        page.ordering = index
      })

      updatedData.forEach((page, index) => {
        page.ordering = index
      })
      update(newPages, updatedData)
    }

    const update = async (newPages, updatedData) => {
      if (currentPage !== 1) {
        await dispatch(handleLessonInput('currentPage', 1))
      }
      await dispatch(handleLessonInput('pages', newPages))
      await dispatch(handleLessonInput('pages_landscape', updatedData))
      await lessonUpdate({ pages: newPages, pages_landScape: updatedData })
      onCancel(updatedData)
    }

    const handleAddStory = () => {
      // split story by 150 words each
      const storyInSections = splitLongString(story)
      let storyCount = 0

      // replace existing content with story on the selected pages
      let updatedData = previewData.map(page => {
        if (page.pageSelected) {
          page.data = page.data.map(element => {
            if (element.type === 'TEXT' && element.display === 'CONTENT') {
              const content = storyInSections[storyCount]
              element.params.content = content

              if (!content) {
                page.isEmpty = true
              }

              storyCount++
            }
            return element
          })
        }
        return page
      })

      let newPages = [...pages]
      let dataRef = [...updatedData]

      // add remaining pages with story
      if (storyCount < storyInSections.length) {
        storyInSections.slice(storyCount).forEach(content => {
          const { title, ordering } = getPageTitle(dataRef, storyCount)

          const addedPages = {
            size: {
              width: 1024,
              height: 768
            },
            orientation: 'landscape',
            data: [
              {
                uid: nanoid(8),
                type: 'TEXT',
                display: 'HEADING',
                params: {
                  content: title,
                  width: 1023,
                  height: 58,
                  x: 0,
                  y: 0,
                  media_format: 'TEXT'
                }
              },
              {
                uid: nanoid(8),
                type: 'TEXT',
                display: 'CONTENT',
                params: {
                  content,
                  height: 494,
                  media_format: 'TEXT',
                  width: 1023,
                  x: 0,
                  y: 58
                }
              }
            ]
          }

          const pageData = {
            data: [],
            orientation: 'portrait',
            size: { width: 1024, height: 768 }
          }

          if (ordering && ordering >= 0 && ordering <= updatedData.length) {
            updatedData.splice(ordering, 0, addedPages)
            newPages.splice(ordering, 0, pageData)
          } else {
            updatedData.push(addedPages)
            newPages.push(pageData)
          }
        })
      }

      newPages = newPages
        .filter((page, index) => {
          const data = updatedData[index]

          if (data && data.isEmpty) {
            return false
          }
          return true
        })
        .map((page, index) => ({ ...page, ordering: index + 1 }))

      updatedData = updatedData
        .filter(page => !page.isEmpty)
        .map((page, index) => ({ ...page, ordering: index + 1 }))

      // sort pages
      updatedData.sort((a, b) => a.ordering - b.ordering)
      newPages.sort((a, b) => a.ordering - b.ordering)

      // add story to pages

      update(newPages, updatedData)
    }

    const getPageTitle = (data, index) => {
      let pageInfo = {
        title: '',
        ordering: 0
      }
      let count = 0
      data.forEach(page => {
        if (page.pageSelected) {
          count++
          if (count === index) {
            const pageData = page && page.data && page.data[0]
            if (
              pageData &&
              pageData.type === 'TEXT' &&
              pageData.display === 'HEADING'
            ) {
              return (pageInfo = {
                title: pageData.params.content,
                ordering: page.ordering
              })
            }
          }
        }
      })
      return pageInfo
    }

    const onCheckChange = checked => setSaveOriginalContent(checked)

    console.log({
      saveOriginalContent,
      storyPosition
    })

    return (
      <Modal
        width={800}
        title={isStoryVisible ? 'Edit Story' : 'Story Preferences'}
        centered
        visible
        onCancel={() => onCancel()}
        footer={null}
        className={isStoryVisible ? 'edit-story' : 'story-preferences'}
      >
        {!isStoryVisible && (
          <Form className="story-course-form" onSubmit={handleSubmit}>
            {Object.entries(options).map(([key, values]) => (
              <Form.Item
                labelAlign="top"
                label={
                  <span className="story-course-form-label">
                    <span>{formatLabel(key)} </span>
                    <Tooltip title={values.description}>
                      <Icon type="info-circle" />
                    </Tooltip>
                  </span>
                }
                key={key}
              >
                {getFieldDecorator(key, {
                  rules: [
                    {
                      required: true,
                      message: `Please input your ${formatLabel(key)}!`
                    }
                  ]
                })(
                  values.value && values.value.length > 0 ? (
                    <CreatableSelect
                      fieldKey={key}
                      updateOptionValue={(value, field) =>
                        form.setFieldsValue({ [field]: value })
                      }
                      options={values.value}
                      placeholder={`Select or type your ${formatLabel(key)}`}
                    />
                  ) : (
                    <Input.TextArea
                      placeholder={`Enter your ${formatLabel(key)}`}
                    />
                  )
                )}
              </Form.Item>
            ))}
            <div className="form-button-center">
              <Form.Item>
                <Button
                  loading={isGeneratingStory}
                  className="shl-primary-btn"
                  htmlType="submit"
                  shape="round"
                  size="large"
                >
                  {t('v3:generate_story')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        )}

        {isStoryVisible && (
          <StoryEditor
            data={story}
            storyPosition={storyPosition}
            saveOriginalContent={saveOriginalContent}
            onChange={setStory}
            onAddStory={onAddStory}
            onCheckChange={onCheckChange}
            setStoryPosition={setStoryPosition}
          />
        )}
      </Modal>
    )
  }
)

const StoryEditor = ({
  data,
  saveOriginalContent,
  onChange,
  onAddStory,
  onCheckChange,
  storyPosition,
  setStoryPosition
}) => {
  const [story, setStory] = useState(null)

  useEffect(() => {
    if (data) {
      setStory(data)
    }
  }, [data])

  const handleChange = value => {
    setStory(value)
    onChange(value)
  }

  const { t } = useTranslation()

  return (
    <div className="story-editor">
      <div className="story-editor__part">
        <Input.TextArea
          value={story}
          onChange={e => handleChange(e.target.value)}
          rows={10}
        ></Input.TextArea>
      </div>

      <Card style={{ width: '100%' }}>
        <Row gutter={16}>
          {saveOriginalContent && (
            <Col span={24}>
              <Form.Item
                label={
                  <Tooltip title={t('v3:story_added_specified_position')}>
                    {t('v3:position_of_story')}
                    <Icon type="info-circle" />
                  </Tooltip>
                }
                colon={false}
              >
                <Select
                  defaultValue={storyPosition}
                  onChange={value => setStoryPosition(value)}
                >
                  <Option value="start"> {t('v3:start_the_lesson')}</Option>
                  <Option value="end"> {t('v3:end_the_lesson')}</Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <div className="story-choose">
              <Switch
                defaultChecked={saveOriginalContent}
                onChange={onCheckChange}
              />
              <p>Save previous content on the pages</p>
            </div>
          </Col>

          {!saveOriginalContent && (
            <Col span={24}>
              <Alert
                message="The existing content in the selected pages will be replaced by the generated story."
                type="info"
              />
            </Col>
          )}
        </Row>
      </Card>

      <Button className="shl-primary-btn" shape="round" onClick={onAddStory}>
        Add Story to Pages
      </Button>
    </div>
  )
}

export default StoryCreationForm
