import React, { Fragment, useState, useEffect } from 'react'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { Button, message, Spin, Modal } from 'antd'
import trophy from '../../../assets/trophy.png'
import './styles.scss'

const defaultPosition = { position: 0, points: 0, score: 0 }

const TriviaGame = props => {
  const { t, triviaActions } = props
  const [finish, setFinish] = useState(true)
  const [loading, setLoading] = useState({
    score: false,
    questions: false,
    updateScore: false
  })
  const [score, setScore] = useState(defaultPosition)
  const [finishScore, setFinishScore] = useState(0)
  const [questionList, setQuestionList] = useState([])
  const [question, setQuestion] = useState({})
  const [currentAnswer, setCurrentAnswer] = useState(null)

  useEffect(() => {
    getData()
  }, [])
  const getMyScore = async () => {
    try {
      setLoading(prev => ({ ...prev, score: true }))
      let res = await triviaActions.getScore()
      if (!res) throw 'Failed to get score!'
      if (res.data && res.data.errorMessage) {
        throw res.data.errorMessage
      } else if (res.status < 300 && res.data) {
        let score = res.data ? res.data.score.toFixed(2) : 0
        setScore({ points: 0, position: 0, score: score })
      }
    } catch (error) {
      message.error(error || 'Failed to get score!')
    }
    setLoading(prev => ({ ...prev, score: false }))
  }

  const getData = async type => {
    setLoading(prev => ({ ...prev, questions: true }))
    getMyScore()
    try {
      let res = await triviaActions.gameQuestions()
      if (!res) throw 'Failed to get questions!'
      if (res.data && res.data.errorMessage) {
        throw res.data.errorMessage
      }
      if (res.status < 300 && res.data) {
        let data = res.data
        let tempQuesttion = []
        data.forEach((el, i) => {
          tempQuesttion.push({
            idx: ++i,
            id: el.id,
            question: el.question,
            checked: false,
            image: el.main_image_url,
            answer_image: el.clarification_image_url,
            answers: [
              {
                status: el.options[0] === el.correct_option,
                checked: false,
                answer: el.options[0]
              },
              {
                status: el.options[1] === el.correct_option,
                checked: false,
                answer: el.options[1]
              },
              {
                status: el.options[2] === el.correct_option,
                checked: false,
                answer: el.options[2]
              },
              {
                status: el.options[3] === el.correct_option,
                checked: false,
                answer: el.options[3]
              }
            ]
          })
        })
        setQuestionList(tempQuesttion)
        // For play again function
        if (type === 'NEW') {
          setQuestion(tempQuesttion[0])
          setScore({ ...score, position: 1, points: 0 })
          setFinish(false)
        }

        setLoading(prev => ({ ...prev, questions: false }))
      }
    } catch (error) {
      message.error(error)
      setLoading(prev => ({ ...prev, questions: false }))
    }
  }
  const checkAnswer = (ans, index) => {
    if (!question.checked) {
      let tempAns = []
      question.answers.forEach((element, i) => {
        if (index === i) {
          tempAns.push({ ...element, checked: true })
        } else {
          tempAns.push({ ...element })
        }
      })
      if (ans.status && !ans.checked) {
        setScore({ ...score, points: score.points + 1 })
      }
      setCurrentAnswer(ans)
      setQuestion({ ...question, checked: true, answers: tempAns })
    }
  }
  const nextQuestion = async idx => {
    setLoading(prev => ({ ...prev, updateScore: true }))

    // Determine the score based on correctness of the answer
    const isCorrectAnswer = currentAnswer.status
    // Update the score for each question
    let data = {
      id: question.id,
      score: isCorrectAnswer ? 1 : -1
    }
    try {
      let res = await triviaActions.updateScore(data)
      if (res.status >= 300) {
        throw new Error('Failed to update score')
      } else {
        setFinishScore(res.data.score)
      }
    } catch (error) {
      console.error('Error updating score:', error)
    }

    // If it's the last question, set finish to true
    if (questionList.length === idx) {
      setFinish(true)
    } else {
      // If it's not the last question, proceed to the next question
      setScore({ ...score, position: idx + 1 })
      setQuestion(questionList[idx])
    }

    setLoading(prev => ({ ...prev, updateScore: false }))
  }

  const playAgain = () => {
    if (questionList.length > 0) {
      if (score.position === 0) {
        setQuestion(questionList[0])
        setScore({ ...score, position: 1, points: 0 })
        setFinish(false)
      } else {
        getData('NEW')
      }
    } else {
      message.error('Question list is currently empty!')
    }
  }

  const handleClose = () => {
    Modal.confirm({
      title: t('v3:do_you_leave'),
      okText: t('general:yes'),
      cancelText: t('general:no'),
      centered: true,
      onOk: () => {
        setScore(defaultPosition)
        setFinish(true)
        setQuestion({})
        getData()
      }
    })
  }

  const handlePlayAgain = () => {
    if (loading.questions || loading.score) return
    playAgain()
  }

  return (
    <Fragment>
      <div className="share-sphere__main">
        {finish ? (
          <div className="share-sphere__finish">
            <div className="share-sphere__container">
              <div>
                <img src={trophy} alt="trophy image" />
              </div>
              {score.position === 0 ? (
                <>
                  <h1>{t('v3:welcome')}</h1>
                  <h4>{t('v3:podcast_to')}</h4>
                  <h4>{t('v3:share_sphere')}</h4>
                  <p className="share-sphere__home-subtitle">
                    {t('v3:team_knowledge_quest')}
                  </p>

                  {!(loading.questions || loading.score) && (
                    <>
                      {score.score !== 0 ? (
                        <div className="share-sphere__container_score">
                          <h3>
                            {t('v3:your_score')}&nbsp;
                            <span style={{ color: '#009444' }}>
                              {score.score}
                            </span>
                          </h3>
                        </div>
                      ) : (
                        <>
                          <hr />
                          <h3>{t('v3:start_playing')}</h3>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <br />
                  <h4>{t('v3:share_sphere_complete')}</h4>
                  <h3>{t('v3:final_score')}</h3>
                  <h2>
                    <span style={{ color: '#009444' }}>
                      {score.points}/{questionList.length}
                    </span>
                  </h2>
                  <div className="share-sphere__container_score">
                    <h3>
                      Your Total Score&nbsp;
                      <span style={{ color: '#009444' }}>{finishScore}</span>
                    </h3>
                  </div>
                </>
              )}
            </div>

            <div
              className="share-sphere__finish_btnplay"
              onClick={handlePlayAgain}
            >
              {loading.questions || loading.score ? (
                <Spin spinning />
              ) : (
                <span>{score.position === 0 ? 'Play' : ' Play Again'}</span>
              )}
            </div>
          </div>
        ) : (
          <div className="share-sphere">
            <section className="section header">
              <div className="title">ShareSphere</div>
              <div className="header_right score-container">
                <div className="score">
                  {t('v3:your_score')} &nbsp;
                  <span>
                    {score.points}/{questionList.length}
                  </span>
                </div>

                <div className="sharesphere-close-button" onClick={handleClose}>
                  <span>x</span> {t('v3:close')}
                </div>
              </div>
            </section>
            <section className="section question">
              <div className="question_position">
                <span>
                  {score.position}&nbsp;/&nbsp;{questionList.length}
                </span>
              </div>
              <div className="question_content">
                <div className="question_column_left">
                  <div className="question_text">
                    <p>{question.question}</p>
                  </div>
                  <div
                    className={`flip-container ${
                      question.checked ? 'flip' : ''
                    }`}
                  >
                    <div className="flipper">
                      <div className="front">
                        <img
                          loading="lazy"
                          src={question.image}
                          alt="Question"
                        />
                      </div>
                      <div className="back">
                        <img
                          loading="lazy"
                          src={question.answer_image}
                          alt="Answer"
                          style={{ opacity: 1 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="question_column_right">
                  {question.answers.map((ans, i) => {
                    const isCorrectAnswer = question.checked && ans.status
                    const isWrongAnswer =
                      question.checked && !ans.status && ans.checked
                    return (
                      <div
                        className={`question_answer ${
                          isCorrectAnswer
                            ? 'question_correct'
                            : isWrongAnswer
                            ? 'question_incorrect'
                            : ''
                        }`}
                        onClick={() => checkAnswer(ans, i)}
                        key={i}
                      >
                        {isCorrectAnswer && (
                          <CheckCircleOutlined
                            style={{
                              backgroundColor: '#2a812a',
                              color: 'white'
                            }}
                          />
                        )}
                        {isWrongAnswer && (
                          <CloseCircleOutlined
                            style={{
                              backgroundColor: '#d10a0a',
                              color: 'white'
                            }}
                          />
                        )}
                        <span className="question_answer_text">
                          {ans.answer}
                        </span>
                      </div>
                    )
                  })}

                  {question.checked && (
                    <div className="question_next">
                      <Button
                        className="question_next_btn"
                        loading={loading.updateScore}
                        shape="round"
                        type="primary"
                        size="large"
                        onClick={() => nextQuestion(question.idx)}
                      >
                        {question.idx === questionList.length
                          ? t('buttons:finish')
                          : t('v3:play_next')}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default withTranslation('trivia')(TriviaGame)
