
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as triviaActions from '../../store/actions/trivia'
import actions from '../../store/actions'
import Trivia from './Router'
export default connect(
    state => ({
        isLoading: state.trivia.isLoading,
        triviaList: state.trivia.triviaList
    }),
    dispatch => ({
        triviaActions: bindActionCreators(triviaActions, dispatch),
        getBucketNames: data => dispatch(actions.trivia.getBucketNames(data)),
        createQuitz: data => dispatch(actions.trivia.createQuitz(data)),
        updateQuitz: data => dispatch(actions.trivia.updateQuitz(data)),
        updateScore: data => dispatch(actions.trivia.updateScore(data)),
        getScore: data => dispatch(actions.trivia.getScore(data)),
        gameQuestions: data => dispatch(actions.trivia.gameQuestions(data)),
        fetchQuestions: data => dispatch(actions.trivia.fetchQuestions(data)),
        fetchQuestionDetail: data => dispatch(actions.trivia.fetchQuestionDetail(data)),
        deleteQuestion: data => dispatch(actions.trivia.deleteQuestion(data)),
        getCategories: () => dispatch(actions.organizations.getCategories()),
    })
)(Trivia)
