import _ from 'lodash'

import * as types from '../types'

const initialState = {
  list: [],
  assessmentById: {},
  isFetching: false,
  hasMoreItems: true,
  workshopAssessments: {
    list: [],
    fetch: false,
    hasMore: true
  },
  pastAssessment: {
    list: [],
    fetch: false
  },
  upcomingAssessment: {
    list: [],
    fetch: false
  },
  nowLiveAssessment: {
    list: [],
    fetch: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REQUEST_ASSESSMENTS:
      return {
        ...state,
        isFetching: action.payload
      }

    case types.LOAD_ASSESSMENTS:
      return {
        ...state,
        ...action.payload
      }

    case types.UPDATE_ASSESSMENTS:
      return {
        ...state,
        list: state.list.concat(action.payload)
      }

    case types.LOAD_ASSESSMENT:
      const index = _.findIndex(state.list, ['id', action.payload.id])

      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          action.payload,
          ...state.list.slice(index + 1)
        ]
      }

    case types.LOAD_ASSESSMENT_BY_ID:
      return {
        ...state,
        assessmentById: Object.assign({}, action.payload)
      }

    case types.RESET_ASSESSMENT:
      return {
        ...state,
        assessmentById: {}
      }

    case types.REMOVE_ASSESSMENT_BY_ID:
      return {
        ...state,
        workshopAssessments: {
          ...state.workshopAssessments,
          list: state.workshopAssessments.list.filter(
            i => i.id !== action.payload.id
          )
        }
      }

    case types.WORKSHOP_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        workshopAssessments: {
          ...state.workshopAssessments,
          list: action.payload
        }
      }
    case types.WORKSHOP_ASSESSMENTS_FETCHING:
      return {
        ...state,
        workshopAssessments: {
          ...state.workshopAssessments,
          fetch: action.payload
        }
      }
    case types.WORKSHOP_ASSESSMENTS_HAS_MORE:
      return {
        ...state,
        workshopAssessments: {
          ...state.workshopAssessments,
          hasMore: action.payload
        }
      }
    case types.PAST_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        pastAssessment: {
          ...state.pastAssessment,
          list: action.payload
        }
      }
    case types.PAST_ASSESSMENTS_FETCHING:
      return {
        ...state,
        pastAssessment: {
          ...state.pastAssessment,
          fetch: action.payload
        }
      }
    case types.NOW_LIVE_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        nowLiveAssessment: {
          ...state.nowLiveAssessment,
          list: action.payload
        }
      }
    case types.NOW_LIVE_ASSESSMENTS_FETCHING:
      return {
        ...state,
        nowLiveAssessment: {
          ...state.nowLiveAssessment,
          fetch: action.payload
        }
      }
    case types.UPCOMING_ASSESSMENTS_SUCCESS:
      return {
        ...state,
        upcomingAssessment: {
          ...state.upcomingAssessment,
          list: action.payload
        }
      }
    case types.UPCOMING_ASSESSMENTS_FETCHING:
      return {
        ...state,
        upcomingAssessment: {
          ...state.upcomingAssessment,
          fetch: action.payload
        }
      }

    default:
      return state
  }
}
