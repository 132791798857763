import React, { useMemo } from 'react'

import { report } from '../../../../../../constants'
import './UserTableStyles.scss'

import {
  UserAssessmentTable,
  UserCourseTable,
  UserEventTable,
  UserMetaMazeTable,
  UserQuizTable,
  UserSKillJourneyTable
} from './components'

const UserReportTable = props => {
  const { t, activeType, enable_maze } = props

  const tableTypes = useMemo(() => {
    return {
      [`${report.COURSE}`]: {
        title: 'reports:courses',
        component: <UserCourseTable />
      },
      [`${report.LIVE_EVENT}`]: {
        title: 'general:live_event',
        component: <UserEventTable />
      },
      ...(enable_maze && {
        [`${report.MAZE}`]: {
          title: 'v2:mazes',
          component: <UserMetaMazeTable />
        }
      }),
      [`${report.LIVE_ASSESSMENT}`]: {
        title: 'reports:live_assessments',
        component: <UserAssessmentTable />
      },
      [`${report.QUIZ}`]: {
        title: 'reports:quizzes',
        component: <UserQuizTable />
      },
      [`${report.SKILL_JOURNEY}`]: {
        title: 'v4:skill_journeys',
        component: <UserSKillJourneyTable />
      }
    }
  }, [])

  const tableItem = tableTypes[activeType]

  const { title, component } = tableItem
  return (
    <div className="training-info-warp">
      <ul className="report-view-item left-margin">
        <li>{t(title).toUpperCase()}</li>
      </ul>
      <div className="training-info">
        {React.cloneElement(component, props)}
      </div>
    </div>
  )
}

export default UserReportTable
