const REG_EX_DIGITS = /\d+/g;

var SCORM_STATUS_PASSED = "passed",
	SCORM_STATUS_COMPLETED = "completed",
	SCORM_STATUS_FAILED = "failed",
	SCORM_STATUS_INCOMPLETE = "incomplete",
	SCORM_STATUS_BROWSED = "browsed",
	SCORM_STATUS_NOT_ATTEMPTED = "not attempted",
	SCORM_STATUS_UNKNOWN = "unknown"

var HUNDREDTHS_PER_YEAR = 0,
	HUNDREDTHS_PER_MONTH = 0,
	HUNDREDTHS_PER_DAY = 0,
	HUNDREDTHS_PER_HOUR = 0,
	HUNDREDTHS_PER_MINUTE = 0,
	HUNDREDTHS_PER_SECOND = 0,
	IsIso8601SectionDelimiter = 'Y'

function RemoveIndiciesFromCmiElement(element) {
	return element.replace(REG_EX_DIGITS, "n");
}



function ExtractIndex(strElementName) {
	var strIndex = "",
		aryMatches;

	// get the first ".#." from the string
	aryMatches = strElementName.match(/\.\d+\./);

	if (aryMatches !== null && aryMatches.length > 0) {
		// drop the periods
		strIndex = aryMatches[0].replace(/\./g, "");
		strIndex = parseInt(strIndex, 10);
	}

	return strIndex;
}

function ExtractSecondaryIndex(strElementName) {
	var strIndex = "",
		aryMatches;

	// get the first ".#." from the string

	aryMatches = strElementName.match(/\.\d+\./g);

	if (aryMatches !== null && aryMatches.length > 1) {
		// drop the periods
		strIndex = aryMatches[1].replace(/\./g, "");
		strIndex = parseInt(strIndex, 10);
	}

	return strIndex;
}


function TranslateDualStausToSingleStatus(completionStatus, successStatus) {
	var returnValue = null;

	switch (successStatus) {
		case SCORM_STATUS_PASSED:
			returnValue = SCORM_STATUS_PASSED;
			break;

		case SCORM_STATUS_FAILED:
			returnValue = SCORM_STATUS_FAILED;
			break;

		case SCORM_STATUS_UNKNOWN:
			if (completionStatus === SCORM_STATUS_COMPLETED) {
				returnValue = SCORM_STATUS_COMPLETED;
			} else if (completionStatus === SCORM_STATUS_INCOMPLETE) {
				returnValue = SCORM_STATUS_INCOMPLETE;
			} else if (completionStatus === SCORM_STATUS_UNKNOWN || completionStatus === SCORM_STATUS_NOT_ATTEMPTED) {
				returnValue = SCORM_STATUS_NOT_ATTEMPTED;
			} else if (completionStatus === SCORM_STATUS_BROWSED) {
				returnValue = SCORM_STATUS_BROWSED;
			}
			break;

		default:
			break;
	}

	if (returnValue === null) {
		return "";
	}
	return returnValue;
}

//0000:00:00:00.00
function IsValidCMITimeSpan(value) {
	var regValid = /^\d?\d?\d\d:\d\d:\d\d(.\d\d?)?$/;

	if (value.search(regValid) > -1) {
		return true;
	}

	return false;
}

function IsValidCMITime(value) {
	var regValid = /^\d\d:\d\d:\d\d(.\d\d?)?$/,
		aryParts,
		intHours,
		intMinutes,
		arySeconds,
		intSeconds,
		intSecondFraction = 0;

	// check for proper format
	if (value.search(regValid) < 0) {
		return false;
	}

	// break the string into its parts and validate each part
	aryParts = value.split(":");

	intHours = aryParts[0];
	intMinutes = aryParts[1];

	arySeconds = aryParts[2].split(".");
	intSeconds = arySeconds[0];

	if (arySeconds.length > 1) {
		intSecondFraction = arySeconds[1];
	}

	if (intHours < 0 || intHours > 23) {
		return false;
	}

	if (intMinutes < 0 || intMinutes > 59) {
		return false;
	}

	if (intSeconds < 0 || intSeconds > 59) {
		return false;
	}

	if (intSecondFraction < 0 || intSecondFraction > 99) {
		return false;
	}

	return true;
}


function IsValidCMIDecimal(value) {
	// check for characters "0-9", ".", and "-" only
	if (value.search(/[^.\d-]/) > -1) {
		return false;
	}

	// if contains a dash, ensure it is first and that there is only 1
	if (value.search("-") > -1 && value.indexOf("-", 1) > -1) {
		return false;
	}

	// ensure only 1 decimal point
	if (value.indexOf(".") !== value.lastIndexOf(".")) {
		return false;
	}

	// ensure there is at least 1 digit
	if (value.search(/\d/) < 0) {
		return false;
	}

	return true;
}




const isCmiString4096 = val => val !== "" && val.length <= 4096
const IsValidCMIIdentifier = val => val !== "" && val.length <= 255

const isInteractionPatternValid = (type, pattern) => {
	const dataValue = pattern.toLowerCase()

	switch (type) {
		case "true-false":
			const s = dataValue.substring(0, 1).toLowerCase();
			return s === "0" || s === "1" || s === "t" || s === "f";
		case "choice":
			return true;
		case "fill-in":
			return dataValue.length <= 255;
		case "numeric":
			return !!this.isCmiDecimal(dataValue);
		case "likert":
			return dataValue.trim() === "" || /\w/g.test(dataValue);
		case "matching":
			return true;
		case "performance":
			return dataValue.length <= 255;
		case "sequencing":
			return true;
		default:
			return false; // not a valid type
	}
}

function NormalizeRawScore(rawScore, minScore, maxScore) {
	// raw score is required to be a valid decimal 0-100 in SCORM 1.2
	var normalizedScore = null;

	if (typeof minScore !== "undefined" && minScore !== null) {
		minScore = parseFloat(minScore);
	}

	if (typeof maxScore !== "undefined" && maxScore !== null) {
		maxScore = parseFloat(maxScore);
	}

	if (typeof rawScore !== "undefined" && rawScore !== null) {
		rawScore = parseFloat(rawScore);

		if (typeof minScore !== "undefined" &&
			minScore !== null &&
			typeof maxScore !== "undefined" &&
			maxScore !== null &&
			rawScore >= minScore &&
			rawScore <= maxScore
		) {
			if (minScore === maxScore) {
				// Account for the strange case where min/max/raw are all set to zero. We should return 0
				// when the raw score is zero regardless of the min/max.
				if (rawScore === 0) {
					normalizedScore = 0;
				} else {
					normalizedScore = 1;
				}
			} else {
				normalizedScore = (rawScore - minScore) / (maxScore - minScore);
			}
		}
		// no valid min and max score, so try to assume that the score is 0-100
		else if (rawScore >= 0 && rawScore <= 100) {
			normalizedScore = rawScore / 100;
		}
	}

	if (normalizedScore !== null) {
		return RoundToPrecision(normalizedScore, 7);
	}

	return null;
}

function RoundToPrecision(number, significantDigits) {
	return Math.round(number * Math.pow(10, significantDigits)) / Math.pow(10, significantDigits);
}

function ZeroPad(num, numDigits) {
	var strTemp = num + "",
			intLen = strTemp.length,
			i;

	if (intLen > numDigits) {
			strTemp = strTemp.substr(0, numDigits);
	} else {
			for (i = intLen; i < numDigits; i += 1) {
					strTemp = "0" + strTemp;
			}
	}

	return strTemp;
}

function TranslateSingleStatusIntoSuccess(singleStatus) {
	var returnValue;

	switch (singleStatus) {
		case SCORM_STATUS_PASSED:
			returnValue = SCORM_STATUS_PASSED;
			break;

		case SCORM_STATUS_COMPLETED:
			returnValue = SCORM_STATUS_UNKNOWN;
			break;

		case SCORM_STATUS_FAILED:
			returnValue = SCORM_STATUS_FAILED;
			break;

		case SCORM_STATUS_INCOMPLETE:
			returnValue = SCORM_STATUS_UNKNOWN;
			break;

		case SCORM_STATUS_BROWSED:
			returnValue = SCORM_STATUS_UNKNOWN;
			break;

		case SCORM_STATUS_NOT_ATTEMPTED:
			returnValue = SCORM_STATUS_UNKNOWN;
			break;

		default:
			console.log("Unrecognized single status");
	}

	return returnValue;
}


function TranslateSingleStatusIntoCompletion(singleStatus) {
	var returnValue;

	switch (singleStatus) {
		case SCORM_STATUS_PASSED:
			returnValue = SCORM_STATUS_COMPLETED;
			break;

		case SCORM_STATUS_COMPLETED:
			returnValue = SCORM_STATUS_COMPLETED;
			break;

		case SCORM_STATUS_FAILED:
			returnValue = SCORM_STATUS_COMPLETED;
			break;

		case SCORM_STATUS_INCOMPLETE:
			returnValue = SCORM_STATUS_INCOMPLETE;
			break;

		case SCORM_STATUS_BROWSED:
			returnValue = SCORM_STATUS_BROWSED;
			break;

		case SCORM_STATUS_NOT_ATTEMPTED:
			returnValue = SCORM_STATUS_NOT_ATTEMPTED;
			break;

		default:
			console.log("Unrecognized single status");
	}
	return returnValue;
}


function ConvertCmiTimeSpanToIso8601TimeSpan(cmiTimeSpan) {
	var hundredths = ConvertCmiTimeSpanToHundredths(cmiTimeSpan),
		isoRepresentation = ConvertHundredthsToIso8601TimeSpan(hundredths);

	return isoRepresentation;
}


function ConvertCmiTimeSpanToHundredths(cmiTimeSpan) {
	var aryParts,
		intHours,
		intMinutes,
		intSeconds,
		intTotalHundredths;

	if (cmiTimeSpan === "") {
		return 0;
	}

	// split the string into its parts
	aryParts = cmiTimeSpan.split(":");

	// seperate the parts and multiply by the appropriate constant (360000 = num hundredths in an hour, etc)
	intHours = aryParts[0];
	intMinutes = aryParts[1];

	// don't need to worry about hundredths b/c they are expressed as fractions of a second
	intSeconds = aryParts[2];

	intTotalHundredths = (intHours * 360000) + (intMinutes * 6000) + (intSeconds * 100);

	// necessary because in JavaScript, some values for intSeconds (such as 2.01) will have a lot of decimal
	// places when multiplied by 1000. For instance, 2.01 turns into 2009.999999999999997.
	intTotalHundredths = Math.round(intTotalHundredths);

	return intTotalHundredths;
}


function ConvertHundredthsToIso8601TimeSpan(totalHundredths) {
	var iso8601Time = "",

		// decrementing counter - work at the hundreths of a second level because that is all the precision that is required
		HundredthsOfASecond = totalHundredths,
		// 100 hundreths of a seconds
		Seconds,
		// 60 seconds
		Minutes,
		// 60 minutes
		Hours,
		// 24 hours
		Days,
		// assumed to be an "average" month (figures a leap year every 4 years) = ((365*4) + 1) / 48 days - 30.4375 days per month
		Months,
		// assumed to be 12 "average" months
		Years;

	Years = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_YEAR);
	HundredthsOfASecond -= Years * HUNDREDTHS_PER_YEAR;

	Months = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_MONTH);
	HundredthsOfASecond -= Months * HUNDREDTHS_PER_MONTH;

	Days = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_DAY);
	HundredthsOfASecond -= Days * HUNDREDTHS_PER_DAY;

	Hours = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_HOUR);
	HundredthsOfASecond -= Hours * HUNDREDTHS_PER_HOUR;

	Minutes = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_MINUTE);
	HundredthsOfASecond -= Minutes * HUNDREDTHS_PER_MINUTE;

	Seconds = Math.floor(HundredthsOfASecond / HUNDREDTHS_PER_SECOND);
	HundredthsOfASecond -= Seconds * HUNDREDTHS_PER_SECOND;

	if (Years > 0) {
		iso8601Time += Years + "Y";
	}
	if (Months > 0) {
		iso8601Time += Months + "M";
	}
	if (Days > 0) {
		iso8601Time += Days + "D";
	}

	// check to see if we have any time before adding the "T"
	if ((HundredthsOfASecond + Seconds + Minutes + Hours) > 0) {
		iso8601Time += "T";

		if (Hours > 0) {
			iso8601Time += Hours + "H";
		}
		if (Minutes > 0) {
			iso8601Time += Minutes + "M";
		}
		if ((HundredthsOfASecond + Seconds) > 0) {
			iso8601Time += Seconds;

			if (HundredthsOfASecond > 0) {
				iso8601Time += "." + HundredthsOfASecond;
			}

			iso8601Time += "S";
		}
	}

	if (iso8601Time === "") {
		iso8601Time = "T0H0M0S";
	}

	iso8601Time = "P" + iso8601Time;

	return iso8601Time;
}

function ConvertCmiTimeToIso8601Time(cmiTime) {
	//
	// assumes that cmiTime is a valid CmiTime (hh:mm:ss.s)
	// since CmiTime doesn't have any concept of a date, we'll assume that the
	// current date is the best selection this assumption should be ok since in
	// SCORM 1.2, the only time CmiTime is used is for interactions which are
	// only written once
	//
	var dtmNow = new Date(),
		year = dtmNow.getFullYear(),
		month = dtmNow.getMonth(),
		day = dtmNow.getDate();

	year = ZeroPad(year, 4);
	month = ZeroPad(month + 1, 2);
	day = ZeroPad(day, 2);

	return year + "-" + month + "-" + day + "T" + cmiTime;
}

function ConvertIso8601TimeSpanToCmiTimeSpan(iso8601TimeSpan) {
	var hundredths = ConvertIso8601TimeSpanToHundredths(iso8601TimeSpan),
		cmiRepresentation = ConvertHundredthsIntoSCORMTime(hundredths);

	return cmiRepresentation;
}



function ConvertIso8601TimeSpanToHundredths(strIso8601Time) {
	var intTotalHundredths = 0,
		strNumberBuilder = "",
		strCurrentCharacter = "",
		blnInTimeSection = false,
		// 100 hundreths of a seconds
		Seconds = 0,
		// 60 seconds
		Minutes = 0,
		// 60 minutes
		Hours = 0,
		// 24 hours
		Days = 0,
		// assumed to be an "average" month (figures a leap year every 4 years) = ((365*4) + 1) / 48 days - 30.4375 days per month
		Months = 0,
		// assumed to be 12 "average" months
		Years = 0,
		i;

	if (strIso8601Time === "") {
		return 0;
	}

	strIso8601Time += "";

	// start at 1 to get past the "P"
	for (i = 1; i < strIso8601Time.length; i += 1) {
		strCurrentCharacter = strIso8601Time.charAt(i);

		if (IsIso8601SectionDelimiter(strCurrentCharacter)) {
			switch (strCurrentCharacter.toUpperCase()) {
				case "Y":
					Years = parseInt(strNumberBuilder, 10);
					HUNDREDTHS_PER_YEAR = Years;
					break;

				case "M":
					if (blnInTimeSection) {
						Minutes = parseInt(strNumberBuilder, 10);
						HUNDREDTHS_PER_MINUTE = Minutes
					} else {
						Months = parseInt(strNumberBuilder, 10);
						HUNDREDTHS_PER_MONTH = Months
					}
					break;

				case "D":
					Days = parseInt(strNumberBuilder, 10);
					HUNDREDTHS_PER_DAY = Days
					break;

				case "H":
					Hours = parseInt(strNumberBuilder, 10);
					HUNDREDTHS_PER_HOUR = Hours
					break;

				case "S":
					Seconds = parseFloat(strNumberBuilder);
					HUNDREDTHS_PER_SECOND = Seconds
					break;

				case "T":
					blnInTimeSection = true;
					break;

				default:
					break;
			}

			strNumberBuilder = "";
		} else {
			// use "" to keep the number as string concats instead of numeric additions
			strNumberBuilder += "" + strCurrentCharacter;
		}
	}

	intTotalHundredths = (Years * HUNDREDTHS_PER_YEAR) +
		(Months * HUNDREDTHS_PER_MONTH) +
		(Days * HUNDREDTHS_PER_DAY) +
		(Hours * HUNDREDTHS_PER_HOUR) +
		(Minutes * HUNDREDTHS_PER_MINUTE) +
		(Seconds * HUNDREDTHS_PER_SECOND);

	// necessary because in JavaScript, some values (such as 2.01) will have a lot of decimal
	// places when multiplied by a larger number. For instance, 2.01 turns into 2009.999999999999997.
	intTotalHundredths = Math.round(intTotalHundredths);

	return intTotalHundredths;
}


function ConvertHundredthsIntoSCORMTime(intTotalHundredths) {
	var intTotalMilliseconds,
		intHours,
		intMinutes,
		intSeconds,
		intHundredths,
		intMilliseconds,
		strCMITimeSpan;

	//
	// this function used to accept milliseconds, so just massage the argument here
	//
	intTotalMilliseconds = intTotalHundredths * 10;

	// extract time parts
	intMilliseconds = intTotalMilliseconds % 1000;
	intSeconds = ((intTotalMilliseconds - intMilliseconds) / 1000) % 60;
	intMinutes = ((intTotalMilliseconds - intMilliseconds - (intSeconds * 1000)) / 60000) % 60;
	intHours = (intTotalMilliseconds - intMilliseconds - (intSeconds * 1000) - (intMinutes * 60000)) / 3600000;

	//
	// deal with exceptional case when content used a huge amount of time and interpreted CMITimstamp
	// to allow a number of intMinutes and seconds greater than 60 i.e. 9999:99:99.99 instead of 9999:60:60:99
	// note: this case is permissable under SCORM, but will be exceptionally rare
	//
	if (intHours === 10000) {
		intHours = 9999;

		intMinutes = (intTotalMilliseconds - (intHours * 3600000)) / 60000;
		// eslint-disable-next-line eqeqeq
		if (intMinutes === 100) {
			intMinutes = 99;
		}
		intMinutes = Math.floor(intMinutes);

		intSeconds = (intTotalMilliseconds - (intHours * 3600000) - (intMinutes * 60000)) / 1000;
		// eslint-disable-next-line eqeqeq
		if (intSeconds === 100) {
			intSeconds = 99;
		}
		intSeconds = Math.floor(intSeconds);

		intMilliseconds = intTotalMilliseconds - (intHours * 3600000) - (intMinutes * 60000) - (intSeconds * 1000);
	}

	// drop the extra precision from the milliseconds
	intHundredths = Math.floor(intMilliseconds / 10);

	// put in padding 0's and concatinate to get the proper format
	strCMITimeSpan = ZeroPad(intHours, 4) + ":" + ZeroPad(intMinutes, 2) + ":" + ZeroPad(intSeconds, 2) + "." + intHundredths;

	// check for case where total milliseconds is greater than max supported by strCMITimeSpan
	if (intHours > 9999) {
		strCMITimeSpan = "9999:99:99.99";
	}

	return strCMITimeSpan;
}



export {
	RemoveIndiciesFromCmiElement,
	ExtractIndex,
	ExtractSecondaryIndex,
	TranslateDualStausToSingleStatus,
	IsValidCMITimeSpan,
	IsValidCMITime,
	IsValidCMIDecimal,
	isCmiString4096,
	IsValidCMIIdentifier,
	isInteractionPatternValid,
	NormalizeRawScore,
	TranslateSingleStatusIntoSuccess,
	TranslateSingleStatusIntoCompletion,
	ConvertCmiTimeSpanToIso8601TimeSpan,
	ConvertCmiTimeToIso8601Time,
	ConvertIso8601TimeSpanToCmiTimeSpan
}