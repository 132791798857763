import React, { Component } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import { Field, Row, Col } from '../../../../../styles'
import { Organization, Groups, Group } from './styled'
import { withTranslation } from 'react-i18next'

class GroupPicker extends Component {
  handleChange = (id, orgId) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id, orgId)
    }
  }

  handleSelectAll = organizationId => {
    const { onChangeAll } = this.props

    if (onChangeAll) {
      onChangeAll(organizationId)
    }
  }

  render() {
    const { list, selectedGroups, t } = this.props
    let filteredList = []
    if (!_.isEmpty(list)) {
      filteredList = list.filter(i => (i.groups || []).length)
    }

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <Field.Control>
          <Row style={{ marginBottom: '-3.2rem' }}>
            {filteredList.map(organization => {
              const { groups = [], id, logo, title } = organization
              const selectedOrgGroups = groups.filter(i =>
                selectedGroups.includes(i.id)
              )
              const checkedOrganization =
                selectedOrgGroups.length === groups.length
              const indeterminateOrganizationsStatus =
                selectedOrgGroups.length &&
                selectedOrgGroups.length < groups.length
              return (
                <Col key={id} md={3}>
                  <Organization onClick={() => this.handleSelectAll(id)}>
                    <Organization.Checkbox>
                      <Checkbox
                        checked={checkedOrganization}
                        indeterminate={indeterminateOrganizationsStatus}
                      />
                    </Organization.Checkbox>
                    <Organization.Image src={logo ? logo.link : ''} alt='' />
                    <Organization.Name>{title}</Organization.Name>
                  </Organization>
                  <Groups>
                    <Groups.List>
                      {groups.map(group => (
                        <Groups.Item key={group.id}>
                          <Group onClick={() => this.handleChange(group.id, id)}>
                            <Group.Checkbox>
                              <Checkbox
                                checked={selectedGroups.includes(group.id)}
                              />
                            </Group.Checkbox>
                            <Group.Name>{group.title}</Group.Name>
                          </Group>
                        </Groups.Item>
                      ))}
                    </Groups.List>
                  </Groups>
                </Col>
              )
            })}
          </Row>
        </Field.Control>
      </Field>
    )
  }
}

export default withTranslation()(GroupPicker)
