import React, { useState } from 'react'
import AiQuizForm from './AiQuizForm'
import AiQuizCreation from './AiQuizCreation'

const quizParams = {
  form: 'quizForm',
  create: 'create'
}

const AiQuizHandler = props => {
  const [selectedParam, setSelectedParam] = useState(quizParams.form)
  const [quizData, setQuizData] = useState(null)

  const { onCancel } = props

  const onQuizData = data => {
    setQuizData(data)
    setSelectedParam(quizParams.create)
  }

  return (
    <>
      {selectedParam === quizParams.form && (
        <AiQuizForm onClose={onCancel} onQuizData={onQuizData} {...props} />
      )}

      {selectedParam === quizParams.create && (
        <AiQuizCreation data={quizData} onClose={onCancel} {...props} />
      )}
    </>
  )
}

export default AiQuizHandler
