import React, { Component } from 'react'
import Text from '../../../../../../../../src/components/Builder/Elements/Text'
import { withTranslation } from 'react-i18next'
import './styles.scss'

class TextComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      content: ''
    }
  }

  handleInput = (content) => {
    console.log('handleinput Text', content)
    const { setTextContent } = this.props
    this.setState({
      content: content
    }, () => {
      setTextContent(content)
    })
  }

  render () {
    return <Text content={this.state.content} isHideSaveButton setTextContent={this.props.setTextContent} handleInput={this.handleInput} />
  }
}

export default withTranslation('text')(TextComponent)
