import React, { Component } from 'react'
import { Spin, Select, Tag, Checkbox, Button, Alert } from 'antd'
import { Page } from './../../styled'
import { Search } from '../../../../../components'
import './styles.scss'

export default class ShowContactsModal extends Component {
  state = {
    isFetching: this.props.fetching || false,
    users: [],
    search: '',
    searchType: 'search',
    inviteList: [],
    isGroupSelected: false,
    selectedGroupId: null,
    allSelected: false,
    hasMaxUsers: false,
    maxLength: ''
  }

  componentDidMount() {
    const { orgUsers = [], selectedEmails, tagValue } = this.props
    const userEmails = orgUsers
      .filter(item => item.user !== null)
      .map(item => item.user.email)

    this.setState({ users: userEmails, inviteList: selectedEmails })

    if (tagValue === 'conference') {
      this.setState({ maxLength: 24 })
    } else if (tagValue === 'webinar') {
      this.setState({ maxLength: 5 })
    } else if (tagValue === 'agora_conference') {
      this.setState({ maxLength: 10 })
    } else if (tagValue === 'live_assessment') {
      this.setState({ maxLength: 4 })
    } else if (tagValue === 'course') {
      this.setState({ maxLength: orgUsers.length })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedEmails, tagValue, orgUsers, fetching } = this.props
    console.log('selectedEmails', selectedEmails)
    const { inviteList } = this.state
    if (selectedEmails !== prevProps.selectedEmails && selectedEmails) {
      const userList = []
      orgUsers.forEach(val => {
        if (val.user) {
          userList.push(val.user.email)
        }
        this.setState({ users: userList, inviteList: selectedEmails })
      })
    }

    if (prevState.inviteList !== inviteList && inviteList.length > 4) {
      if (tagValue === 'conference') {
        this.setState({
          hasMaxUsers: inviteList.length > 24 ? true : false,
          maxLength: 24
        })
      } else if (tagValue === 'webinar') {
        this.setState({
          hasMaxUsers: inviteList.length > 4 ? true : false,
          maxLength: 5
        })
      } else if (tagValue === 'agora_conference') {
        this.setState({
          hasMaxUsers: inviteList.length > 10 ? true : false,
          maxLength: 10
        })
      } else if (tagValue === 'live_assessment') {
        this.setState({
          hasMaxUsers: inviteList.length > 5 ? true : false,
          maxLength: 5
        })
      } else if (tagValue === 'course') {
        this.setState({
          hasMaxUsers: false,
          maxLength: orgUsers.length
        })
      }
    }

    if (fetching !== prevProps.fetching) {
      this.setState({ isFetching: fetching })
    }
  }

  addUser = e => {
    const { value, checked } = e.target
    const { encoderType, tagValue } = this.props
    const { users } = this.state

    if (encoderType !== 'peer2peer') {
      if (value === 'all') {
        this.setState({
          inviteList: checked
            ? users.filter((u, i) => {
                if (tagValue === 'conference') {
                  if (i < 24) return true
                } else if (tagValue === 'agora_conference') {
                  if (i < 10) return true
                } else if (tagValue === 'course') {
                  return true
                } else {
                  if (i < 5) return true
                }
                return false // Add this line to handle cases where no condition is met
              })
            : [],
          allSelected: checked ? true : false
        })
        return null
      }

      this.setState(prevState => {
        const prevList = prevState.inviteList
        if (!prevList.includes(value) && checked) {
          return {
            inviteList: [...prevList, value],
            allSelected: false
          }
        }
        return {
          inviteList: prevList.filter(p => p !== value),
          allSelected: false
        }
      })
      return
    }
    this.setState(prevState => {
      const prevList = prevState.inviteList
      if (checked) {
        return {
          inviteList: [value],
          allSelected: false
        }
      }
      return {
        inviteList: prevList.filter(p => p !== value),
        allSelected: false
      }
    })
    return
  }

  handleInvite = () => {
    const { inviteList } = this.state
    const { addEmails } = this.props
    addEmails(inviteList)
    // setTimeout(() => {
    //   this.setState({inviteList:[], users: []})
    // }, 2000);
  }

  searchFilter = value => {
    const { orgUsers = [] } = this.props
    this.setState({
      search: value
    })
    const searchValue = value
    const newSearchType = this.state.searchType
    var searchList = []

    orgUsers.forEach(val => {
      if (val.user) {
        searchList.push(val.user)
      }
      this.setState({ users: searchList })
    })

    let filteredArray = []

    if (searchValue) {
      searchList.forEach(val => {
        if (
          val && newSearchType === 'id'
            ? val.id.toString().includes(searchValue)
            : newSearchType === 'name'
            ? val.username.toString().includes(searchValue)
            : newSearchType === 'group'
            ? val.groups.toString().includes(searchValue)
            : val.email.toString().includes(searchValue)
        ) {
          let index = searchList.indexOf(val)
          filteredArray.push(searchList[index].email)
        }
      })

      this.setState({ users: filteredArray })
    }

    if (!searchValue) {
      searchList = []
      orgUsers.forEach(val => {
        if (val.user) {
          searchList.push(val.user.email)
        }
      })
      this.setState({ users: searchList })
    }
  }

  handleSearchType = type => {
    if (type === 'group') {
      this.setState({
        searchType: type,
        isGroupSelected: true
      })
    } else {
      this.setState({
        searchType: type,
        isGroupSelected: false
      })
    }

    this.searchFilter()
  }

  handleSearchGroupType = groupId => {
    const { orgUsers } = this.props
    this.setState({
      search: groupId,
      selectedGroupId: groupId,
      page: 1
    })
    let groupUserList = []
    orgUsers.forEach(val => {
      if (val.user && val.user.groups.includes(groupId)) {
        groupUserList.push(val.user.email)
      }
      this.setState({ users: groupUserList })
    })
  }

  render() {
    const {
      isFetching,
      users,
      isGroupSelected,
      inviteList,
      allSelected,
      hasMaxUsers,
      maxLength
    } = this.state
    const { groupOptions, t, tagValue } = this.props

    return (
      <div className="email-pop-modal">
        <Page>
          <Page.Head.Search>
            <Select
              onChange={this.handleSearchType}
              className="rounded-select"
              defaultValue={'All'}
            >
              <Select.Option value={'All'}>{t('reports:all')}</Select.Option>
              <Select.Option value={'name'}>{t('reports:name')}</Select.Option>
              <Select.Option value={'id'}>
                {t('reports:id_number')}
              </Select.Option>
              <Select.Option value={'email'}>
                {t('reports:email')}
              </Select.Option>
              <Select.Option value={'group'}>{t('v4:group')}</Select.Option>
            </Select>
            {isGroupSelected && (
              <Select
                onChange={this.handleSearchGroupType}
                className="rounded-select"
                placeholder="Select a group"
                defaultValue={'all'}
              >
                <Select.Option value={'all'}>
                  {t('v4:select_group')}
                </Select.Option>
                {groupOptions.groups.map(g => (
                  <Select.Option value={g.title}>{g.title}</Select.Option>
                ))}
              </Select>
            )}
            <Search
              className="modal-header-search"
              placeholder={t('placeholders:search')}
              onChange={e => this.searchFilter(e.target.value)}
            />
          </Page.Head.Search>
          <Page.Container>
            <Spin spinning={isFetching}>
              {users && users.length > 0 ? (
                <>
                  <Checkbox
                    style={{ marginTop: '1rem' }}
                    onChange={this.addUser}
                    value={'all'}
                    checked={allSelected}
                  >
                    {'All'}
                  </Checkbox>
                  <div className="modal-spin-container">
                    {users.map(u => (
                      <Tag
                        className="modal-spin-container-tag"
                        key={`user_${u}`}
                      >
                        <Checkbox
                          onChange={this.addUser}
                          value={u}
                          checked={inviteList.includes(u)}
                        >
                          {u}
                        </Checkbox>
                      </Tag>
                    ))}
                  </div>
                </>
              ) : (
                <div>{t('v2:no_user_found')}</div>
              )}
            </Spin>
          </Page.Container>

          {hasMaxUsers && (
            <Alert
              className="modal-alert"
              message={t('v2:error')}
              description={
                tagValue === 'conference'
                  ? t('v2:max_twentyfive_users_allowed')
                  : tagValue === 'agora_conference'
                  ? t('v2:max_ten_users_allowed')
                  : tagValue === 'live_assessment' || tagValue === 'webinar'
                  ? t('v2:max_five_users_allowed')
                  : ''
              }
              type="error"
              closable
              showIcon
              banner
              // onClose={clearAlert}
            />
          )}

          <div className="wrap_btn_actions-modal">
            <Button
              type="primary"
              size="large"
              className="action_button"
              disabled={
                isFetching ||
                !inviteList.length ||
                inviteList.length > maxLength
              }
              onClick={() => this.handleInvite()}
            >
              {t('v2:invite').toUpperCase()}
            </Button>
          </div>
        </Page>
      </div>
    )
  }
}
