import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import {
  Scrollbars,
  Search,
  Filter,
  Cards,
  Spinner
} from '../../../../../../../components'
import { ExistingLesson } from './styled'
import history from '../../../../../../../history'
import { Modal, Pagination } from 'antd'
import './style.scss'

const confirm = Modal.confirm

class ModalExistingLesson extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1, // this variable use for current page to get api
      pageSize: 20, // this variable use for page size in pagination
      isBinding: false,
      isShowConfirmModal: false,
      search: ''
    }

    this.onSearch = _.debounce(this.onSearch, 500)
  }

  componentDidMount() {
    this.getLessons()
  }

  onSearch = value => {
    this.setState(
      {
        page: 1,
        search: value
      },
      () => {
        this.getLessons()
      }
    )
  }

  onFilter = value => {
    const { lessonBuilderActions } = this.props

    lessonBuilderActions.fetchLessons({
      order: value
    })
  }

  bindLesson = (lessonId, lesson = null) => {
    const {
      courseBuilder: { courseId },
      bindLesson,
      rootPath,
      isClone,
      t
    } = this.props

    if (isClone) {
      confirm({
        title: `${t('v3:clone_course')} "${lesson.title || ''}" ${t(
          'v3:intro_course'
        )}`,
        okText: t('general:yes'),
        onOk: () => this.onConfirmClone(lessonId),
        onCancel() {}
      })
    } else {
      this.setState({
        isBinding: true
      })

      return bindLesson(courseId, lessonId, { type: 'copy' }).then(() => {
        this.setState({
          isBinding: false
        })
        history.push(rootPath)
      })
    }
  }

  onConfirmClone = lessonId => {
    const {
      courseBuilder: { courseId },
      lessons: { list },
      onCreateLesson
    } = this.props

    this.setState({
      isBinding: true
    })

    const selectedLesson = _.head(
      _.filter(list.rows, lesson => lesson.id === lessonId)
    )

    onCreateLesson(() => this.cloneLessonAPI(selectedLesson, courseId))
  }

  cloneLessonAPI = ({ id }, courseId) => {
    const { lessonBuilderActions } = this.props

    return lessonBuilderActions.cloneLessonToCourse(courseId, id)
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  getLessons = () => {
    const { lessonBuilderActions } = this.props
    const { page, pageSize, search } = this.state
    lessonBuilderActions.fetchLessons({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search
    })
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize
      },
      this.getLessons
    )
  }

  render() {
    const {
      visible,
      onCancel,
      lessons: { list, fetch },
      t,
      isClone
    } = this.props
    const { isBinding, page, pageSize } = this.state

    const filterData = [
      { value: 'created_at', text: 'filters:date' },
      { value: 'relevance', text: 'filters:relevance' },
      { value: 'popularity', text: 'filters:popularity' }
    ]

    return (
      <Modal
        title={t('existing_lesson')}
        width={890}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        className="existing-card-wrapper"
      >
        <ExistingLesson>
          <ExistingLesson.Search>
            <Search
              placeholder={t('placeholders:keywords')}
              style={{ maxWidth: '26rem' }}
              onChange={e => this.onSearch(e.target.value)}
            />
          </ExistingLesson.Search>

          {false && (
            <Filter
              data={filterData}
              defaultValue={filterData[0].text}
              callback={item => this.onFilter(item.value)}
              label={t('labels:sort_by')}
              bordered
            />
          )}

          {fetch ? (
            <Spinner height="36rem" />
          ) : (
            <Fragment>
              <Scrollbars
                type="horizontal"
                style={{ width: '100%' }}
                autoHeightMin={360}
                minimize="true"
                autoHeight
              >
                <Cards
                  type="lesson"
                  data={list.rows}
                  horizontal
                  bindLesson={this.bindLesson}
                  loading={isBinding}
                  isClone={isClone}
                />
              </Scrollbars>
              <div className="media-page__controls">
                <Pagination
                  total={list.count}
                  current={page}
                  pageSize={pageSize}
                  onChange={this.onChangePage}
                />
              </div>
            </Fragment>
          )}
          {isClone && (
            <div style={{ margin: '15px 0 5px 0' }}>
              <span>{t('clone_lesson_modal_sub')}</span>
            </div>
          )}
        </ExistingLesson>
      </Modal>
    )
  }
}

export default withTranslation('lessonoverview')(ModalExistingLesson)
