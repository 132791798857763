import React, { Component } from 'react'
import LessonsPagination from '../../internal/LessonsPagination'
import SaveLesson from '../../internal/SaveLesson'
import templates from './templates'

import { Wrapper, Main, Container, Pages } from '../../styled'
import { Types, Type } from './styled'

import { one, two, three, four, five, six } from './img'
import history from '../../../../../history'
import { Button } from 'antd'
import { withTranslation } from 'react-i18next'
import { i18n } from '../../../../../services'
import nanoid from 'nanoid'

const templateTypes = [
  {
    title: i18n.t('pagebuilder:template1'),
    thumb: one,
    template: 'template1'
  },
  {
    title: i18n.t('pagebuilder:template2'),
    thumb: two,
    template: 'template2'
  },
  {
    title: i18n.t('pagebuilder:template3'),
    thumb: three,
    template: 'template3'
  },
  {
    title: i18n.t('pagebuilder:template4'),
    thumb: four,
    template: 'template4'
  },
  {
    title: i18n.t('pagebuilder:template5'),
    thumb: five,
    template: 'template5'
  },
  {
    title: i18n.t('pagebuilder:template6'),
    thumb: six,
    template: 'template6'
  }
]

// const templateTypesLandscape = [
//   {
//     title: i18n.t('pagebuilder:template1'),
//     thumb: oneLS,
//     template: 'template1'
//   }
// ]

class PageBuilder extends Component {
  state = {
    orientationMode: 'landscape'
  }
  addPage = template => {
    const {
      lesson: {
        pages: oldPages,
        pages_landscape: oldPagesLandscape,
        lessonBckgrndImg,
        lessonBckgrndImgId
      },
      lessonBuilderActions: { handleLessonInput },
      rootPath
    } = this.props

    const pages = [...oldPages]
    const pages_landscape = [...oldPagesLandscape]

    console.log('Pagebuilder lesson', this.props.lesson)

    pages.push(templates()[template])
    pages_landscape.push(templates()[template])

    if (lessonBckgrndImg !== null) {
      const dataLND = {
        uid: nanoid(8),
        type: 'BACKGROUND_IMAGE_AUTO',
        auto: true,
        params: { content: lessonBckgrndImg, content_id: lessonBckgrndImgId }
      }
      pages_landscape[pages_landscape.length - 1].data.push(dataLND)
      pages[pages.length - 1].data.push(dataLND)
    }

    console.log('after push pages', pages)
    console.log('after push pages_landscape', pages_landscape)

    if (pages.length > 0) {
      pages[pages.length - 1].orientation = 'portrait'
    }
    handleLessonInput('pages', pages)
    handleLessonInput('pages_landscape', pages_landscape)
    handleLessonInput('currentPage', pages.length)

    history.push(`${rootPath}/page-constructor`)
  }

  /*handleChangeSelect = (value) => {
    console.log(`selected ${value}`)
    this.setState({
      orientationMode: value
    })
  }*/

  render() {
    //const { orientationMode } = this.state

    //console.log('orientationMode: ', orientationMode)

    const {
      lessonBuilderActions: { handleLessonInput },
      rootPath,
      lesson: { pages, lessonId },
      t
    } = this.props
    return (
      <Wrapper>
        <Main noPadding className="main-page-wrap">
          <Container>
            <Pages>
              <Pages.List>
                {/* {pages.length > 0 ? (
                  <Pages.Item>
                    <div
                      className="page page_previous"
                      onClick={() => {
                        handleLessonInput('currentPage', pages.length)
                        history.push(`${rootPath}/page-constructor`)
                      }}
                    />
                  </Pages.Item>
                ) : (
                  <Pages.Item>
                    <div className="page page_hidden" />
                  </Pages.Item>
                )} */}

                <Pages.Item>
                  {pages.length > 0 ? (
                    <div>
                      <div
                        className="page page_previous"
                        onClick={() => {
                          handleLessonInput('currentPage', pages.length)
                          history.push(`${rootPath}/page-constructor`)
                        }}
                      />
                    </div>
                  ) : (
                    <div className="page page_hidden" />
                  )}
                  <div className="page page_dark page-choose-preview">
                    <Types>
                      <Types.Heading>{t('select_layout')}</Types.Heading>

                      <Types.List>
                        {templateTypes.map(type => (
                          <Types.Item key={type.title}>
                            <Type onClick={() => this.addPage(type.template)}>
                              <Type.Content>
                                <Type.Thumb src={type.thumb} alt={type.title} />
                              </Type.Content>
                              <Type.Title>{type.title}</Type.Title>
                            </Type>
                          </Types.Item>
                        ))}
                      </Types.List>
                    </Types>
                  </div>
                </Pages.Item>

                {/* <Pages.Item>
                  <div className='page page_hidden' />
                </Pages.Item> */}
              </Pages.List>
            </Pages>
          </Container>
        </Main>

        <div className="p-footer">
          <div className="p-footer__inner p-footer__inner_justify">
            <div className="p-footer__col">
              <div className="p-footer__item">
                <LessonsPagination rootPath={rootPath} />
              </div>
            </div>
            <div className="p-footer__col">
              <div className="p-footer__item">
                <Button
                  className="rounded"
                  onClick={() =>
                    history.push(`${rootPath}/lesson-create/${lessonId}`)
                  }
                >
                  {t('buttons:back_to_lesson')}
                </Button>
              </div>
              <div className="p-footer__item">
                <SaveLesson />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default withTranslation('pagebuilder')(PageBuilder)
