import React, { useState } from 'react'
import { Modal, Button } from 'antd'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import './ImagePreview.scss' // Import your SCSS file
import 'antd/dist/antd.css' // Import Ant Design CSS

const ImagePreview = props => {
  const [visible, setVisible] = useState(false)
  // const [rotation, setRotation] = useState(0)

  const showModal = () => {
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const rotateClockwise = () => {
    // setRotation(prevRotation => (prevRotation + 90) % 360)
  }

  const rotateCounterclockwise = () => {
    // setRotation(prevRotation => (prevRotation - 90 + 360) % 360)
  }

  return (
    <div>
      <img
        {...props}
        alt="Preview"
        onClick={showModal}
        className="shl-image-preview" // Apply prefixed SCSS class here
      />

      <Modal
        className="shl-image-preview-modal"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width="auto"
        style={{ top: 0, left: 0 }}
        bodyStyle={{ padding: 0 }}
        centered={false}
      >
        <TransformWrapper
          className="shl-image-preview-wrapper"
          initialScale={1}
          minScale={1}
          maxScale={7}
          limitToBounds={true}
          initialPositionX={0}
          initialPositionY={0}
          wheel={{ disabled: true }}
          doubleClick={{
            mode: 'reset'
          }}
          panning={{ excluded: ['input', 'textarea'] }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <div className="shl-tools">
              {' '}
              {/* Apply prefixed SCSS class here */}
              <Button onClick={() => zoomIn()}>+</Button>
              <Button onClick={() => zoomOut()}>-</Button>
              <Button onClick={() => resetTransform()}>Reset</Button>
              <Button onClick={rotateClockwise}>
                <span className="anticon">
                  <i className="iconfont">&#xe64a;</i>
                </span>
              </Button>{' '}
              {/* Clockwise rotation with Ant Design v3 icon */}
              <Button onClick={rotateCounterclockwise}>
                <span className="anticon">
                  <i className="iconfont">&#xe64b;</i>
                </span>
              </Button>{' '}
              {/* Counterclockwise rotation with Ant Design v3 icon */}
            </div>
          )}
          <TransformComponent>
            <img
              className="shl-image-preview-main"
              {...props}
              alt="Preview"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
          </TransformComponent>
        </TransformWrapper>
      </Modal>
    </div>
  )
}

export default ImagePreview
