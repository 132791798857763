import React from 'react'
import OverviewChart from '../../../../components/OverviewChart'
import OverviewGraph from '../../../../components/OverviewGraph'
import InfoIcon from '../../../../../../assets/info_icon.png'
import { axisDate, formatByDate } from '../../../../components/SortedTimeline'
import { rangeArray } from '../../../../../../utils'

import { Col } from 'antd'
const OverviewUserReport = props => {
  const {
    data: { courses, events, mazes, quizzes, skills, assessments, trivia },
    enableMaze = true,
    activeDateFilter,
    certificates,
    badges,
    t
  } = props

  const buildContentTab = (tab = []) => {
    const dates = axisDate(Number(activeDateFilter))
    let data =
      tab &&
      tab
        .map(i => ({
          date: formatByDate(i.updated_at, Number(activeDateFilter))
        }))
        .filter(i => dates && dates.includes(i.date))
        .reduce(
          (acc, item) => {
            const index = dates.findIndex(i => i === item.date)
            acc[index] += 1
            return acc
          },
          rangeArray(Number(activeDateFilter)).map(() => 0)
        )

    return data
  }

  const dates = axisDate(Number(activeDateFilter))
  return (
    <div className="report-view-wrap">
      <Col lg={7} sm={24}>
        <ul className="report-view-item left-short-margin">
          <li>{t('v2:overview_average_activity').toUpperCase()}</li>
          <img alt="" src={InfoIcon} />
        </ul>
        <OverviewChart
          graphData={{
            course: courses.data.length,
            events: events.data.length,
            maze: mazes.data.length,
            assessments: assessments.data.length,
            skillJourneys: skills.data.length,
            quizes: quizzes.data.length,
            trivia: trivia.data.length,
          }}
          isUserReport
          enableMaze={enableMaze}
        />
      </Col>
      <Col lg={17} sm={30}>
        <ul className="report-view-item left-margin">
          <li>{t('v2:overview_workshop_graph').toUpperCase()}</li>
          <img alt="" src={InfoIcon} />
        </ul>
        <OverviewGraph
          eventsData={buildContentTab(events.data)}
          courseData={buildContentTab(courses.data)}
          triviaData={buildContentTab(trivia.data)}
          liveAssessmentData={buildContentTab(assessments.data)}
          mazesData={buildContentTab(mazes.data)}
          quizesData={buildContentTab(quizzes.data)}
          skillJourneysData={buildContentTab(skills.data)}
          monthXAxis={dates}
          isUserReport
          certificates={certificates}
          badges={badges}
          enableMaze={enableMaze}
        />
      </Col>
    </div>
  )
}

export default OverviewUserReport
