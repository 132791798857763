import React, { Component } from 'react'
import { Icon } from 'antd'
import './styles.scss'

export default class MazeError extends Component {
  render() {
    const {
      error: { textError, imageError, scoreError, idealPathError }
    } = this.props
    return (
      <div className="error-container">
        <h2 className="error-heading">
          {' '}
          Please confirm all the conditions are checked before publishing.{' '}
        </h2>
        <div className="error-points">
          <div className="error-item">
            <Icon
              type={textError ? 'close-circle' : 'check-circle'}
              style={{ color: textError ? 'red' : 'green' }}
            />
            <span>
              Text in all the text boxes in scenarios and options is compulsary
            </span>
          </div>
          <div className="error-item">
            <Icon
              type={imageError ? 'close-circle' : 'check-circle'}
              style={{ color: imageError ? 'red' : 'green' }}
            />
            <span>Image must be selected in all the scenarios.</span>
          </div>
          <div className="error-item">
            <Icon
              type={scoreError ? 'close-circle' : 'check-circle'}
              style={{ color: scoreError ? 'red' : 'green' }}
            />
            <span>Score must be added in all the scenarios.</span>
          </div>
          <div className="error-item">
            <Icon
              type={idealPathError ? 'close-circle' : 'check-circle'}
              style={{ color: idealPathError ? 'red' : 'green' }}
            />
            <span>A complete ideal path is compulsary.</span>
          </div>
        </div>
      </div>
    )
  }
}
