import styled from 'styled-components'

export const Container = styled.div`
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
  `}
`

export const LabelLoader = styled.span`
  color: #fff;
  font-size: 2.1rem;
  letter-spacing: 0.08rem;
`
