import Details from './Details'
import Settings from './Settings'
import Preview from './Preview'
import Create from './Create'

export default {
  Details,
  Settings,
  Preview,
  Create
}
