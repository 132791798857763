import {
  ANNOTATION_EDITOR,
  ANNOTATION_LIST,
  ANNOTATION_GET_BY_ID,
  ANNOTATION_DELETE_BY_ID,
  ANNOTATION_FETCHING,
  ANNOTATION_DATA_ADD,
  ANNOTATION_DATA_UPDATE,
  ANNOTATION_DATA_DEFAULT,
  ANNOTATION_DATA_TITLE,
  ANNOTATION_EDIT_ELEMENT
} from '../types'

const initialState = {
  isFetching: false,
  isEditElement: false,
  list: [],
  editor: {
    active: false,
    params: {
      id: null,
      title: '',
      rtl: false,
      page: {
        size: {
          width: 768,
          height: 1024
        },
        data: []
      }
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ANNOTATION_FETCHING:
      return {
        ...state,
        isFetching: action.payload
      }

    case ANNOTATION_EDIT_ELEMENT:
      return {
        ...state,
        isEditElement: action.isEditElement
      }

    case ANNOTATION_EDITOR:
      return {
        ...state,
        editor: {
          ...state.editor,
          active: action.payload
        }
      }

    case ANNOTATION_LIST:
      return {
        ...state,
        list: action.payload
      }

    case ANNOTATION_DELETE_BY_ID:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id)
      }

    case ANNOTATION_GET_BY_ID:
      return {
        ...state,
        editor: {
          ...state.editor,
          params: action.payload
        }
      }

    case ANNOTATION_DATA_ADD:
      return {
        ...state,
        editor: {
          ...state.editor,
          params: {
            ...state.editor.params,
            page: {
              ...state.editor.params.page,
              data: [...state.editor.params.page.data, action.payload.data]
            }
          }
        }
      }

    case ANNOTATION_DATA_UPDATE:
      return {
        ...state,
        editor: {
          ...state.editor,
          params: {
            ...state.editor.params,
            page: {
              ...state.editor.params.page,
              data: action.payload.data
            }
          }
        }
      }

    case ANNOTATION_DATA_DEFAULT:
      return {
        ...state,
        editor: initialState.editor
      }

    case ANNOTATION_DATA_TITLE:
      return {
        ...state,
        editor: {
          ...state.editor,
          params: {
            ...state.editor.params,
            title: action.payload.title
          }
        }
      }

    default:
      return state
  }
}
