import React, { Fragment, useEffect, useState } from 'react'
import { Modal, Form, Upload, Button, Input } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { ImageSelect } from '../../../../../components/Form'
const UploadAudioModal = ({
  t,
  isUploading,
  audioData,
  open,
  close,
  form,
  onFinish,
  type
}) => {
  const {
    getFieldDecorator,
    validateFields,
    setFieldsValue,
    resetFields
  } = form
  const [fileList, setFileList] = useState([])
  const [image, setImage] = useState({ id: '', link: '' })
  useEffect(() => {
    if (audioData.id) {
      setFieldsValue({
        description: audioData.description,
        title: audioData.title,
        cover_id: audioData.cover && audioData.cover.id
      })
      setFileList([
        {
          uid: audioData.filename,
          name: audioData.filename,
          url: audioData.audio_link
        }
      ])
    }
    if (audioData.cover) {
      setImage({ id: audioData.cover.id, link: audioData.cover.link })
    }
  }, [audioData])
  const handleOk = () => {
    validateFields().then(values => {
      onFinish(values)
    })
  }
  const setCoverImage = data => {
    setImage(data)
    setFieldsValue({ cover_id: data.id })
  }
  const uploadProps = {
    onRemove: file => {
      setFileList(prevFileList =>
        prevFileList.filter(item => item.uid !== file.uid)
      )
    },
    beforeUpload: file => {
      setFileList(prevFileList => [...prevFileList, file])
      return false // Prevent automatic upload
    },
    fileList
  }
  return (
    <Modal
      key={'UploadAudioModal'}
      style={{ position: 'relative', zIndex: 1 }}
      visible={open}
      title={
        type === 'ADD'
          ? t('v4:audio_modal.title_upload')
          : t('v4:audio_modal.title_update') + t('v4:audio_modal.title_audio')
      }
      onCancel={close}
      afterClose={() => {
        resetFields()
        setFileList([])
        setImage({ id: '', link: '' })
      }}
      footer={[
        <Fragment>
          <Button loading={isUploading} type="primary" onClick={handleOk}>
            {type === 'ADD'
              ? t('v4:audio_modal.btn_upload')
              : t('v4:audio_modal.btn_update')}
          </Button>
          <Button disabled={isUploading} onClick={close}>
            {type === 'ADD'
              ? t('v4:audio_modal.btn_close')
              : t('v4:audio_modal.btn_cancel')}
          </Button>
        </Fragment>
      ]}
    >
      <Form layout="vertical">
        <Fragment>
          <Form.Item
            required={false}
            colon={false}
            className="no_bottom_margin"
          >
            {getFieldDecorator('cover_id', {
              initialValue: image.id,
              rules: [
                { required: true, message: t('v4:audio_modal.rule_cover') }
              ]
            })(
              <ImageSelect
                label={t('v4:audio_modal.lbl_cover')}
                coverInput={audioData.cover}
                onCoverChange={setCoverImage}
              />
            )}
          </Form.Item>
          <Form.Item
            required={false}
            colon={false}
            label={t('v4:audio_modal.lbl_title')}
          >
            {getFieldDecorator('title', {
              initialValue: audioData.title || '',
              rules: [
                { required: true, message: t('v4:audio_modal.rule_title') }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item
            required={false}
            colon={false}
            label={t('v4:audio_modal.lbl_description')}
          >
            {getFieldDecorator('description', {
              initialValue: audioData.description || '',
              rules: [
                {
                  required: true,
                  message: t('v4:audio_modal.rule_description')
                }
              ]
            })(
              <Input.TextArea
                placeholder={t('v3:podcast_description')}
                rows={3}
              />
            )}
          </Form.Item>
          <Form.Item
            required={false}
            colon={false}
            label={t('v4:audio_modal.lbl_file')}
          >
            {getFieldDecorator('files', {
              initialValue:
                (fileList.length !== 0 && fileList.map(file => file.url)) || '',
              rules: [
                { required: true, message: t('v4:audio_modal.rule_file') },
                {
                  validator: (rule, value, callback) => {
                    if (Array.isArray(value)) {
                      // Check if all files are MP3
                      const allMp3 = value.every(
                        file => file.type === 'audio/mpeg'
                      )
                      if (!allMp3) {
                        callback(t('v4:audio_modal.F')) // Customize your error message here
                      } else {
                        callback()
                      }
                    } else {
                      callback(t('v4:audio_modal.F')) // Customize your error message here if value is not an array
                    }
                  }
                }
              ],
              getValueFromEvent: e => (Array.isArray(e) ? e : e && e.fileList)
            })(
              <Upload {...uploadProps} accept=".mp3" listType="text">
                {fileList.length === 0 && (
                  <Button>
                    <UploadOutlined />
                    {t('v4:audio_modal.lbl_select_file')}
                  </Button>
                )}
              </Upload>
            )}
          </Form.Item>
        </Fragment>
      </Form>
    </Modal>
  )
}

export default Form.create({ name: 'podcast_form' })(UploadAudioModal)
