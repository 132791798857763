import Note from './Note'
import { connect } from 'react-redux'
import actions from '../../../store/actions'

const mapStateToProps = state => ({
  notes: state.notes,
  isLoading: state.notes.isLoading,
  isEditing: state.notes.isEditing
})

const mapDispatchToProps = dispatch => ({
  deleteNote: (noteId) => dispatch(actions.notes.deleteNote(noteId)),
  editNote: (noteId) => dispatch(actions.notes.editNote(noteId)),
  editingNotes: () => dispatch(actions.notes.editingNotes()),
  stopEditingNotes: () => dispatch(actions.notes.stopEditingNotes()),
  fetchNotes: (id, type) => dispatch(actions.notes.fetchNotes({ id, type }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Note)
