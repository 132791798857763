import React, { Component } from 'react'
import { Row, Col, Tabs, Spin, Typography } from 'antd'
import {
  CustomSelect,
  CustomTabs,
  CustomCard
  // CustomDatePicker
} from '../../../../styled.js'
import { withTranslation } from 'react-i18next'
import ReactEcharts from 'echarts-for-react'
// import moment from 'moment/moment'
import { rangeArray as range } from '../../../../../../utils'
import '../../style.css'
import {
  axisDate,
  axisShowData,
  formatByDate
} from '../../../../components/SortedTimeline'
import ParticipantSlider from '../../../../components/ParticipantSlider'
const { TabPane } = Tabs
const { Meta } = CustomCard
const { Title, Text } = Typography
const { Option } = CustomSelect

class Analytic extends Component {
  state = {
    activeFilterValue: 24,
    completeUserSize: 0
    // visible: false,
    // startValue: moment(new Date(), 'YYYY-MM-DD').subtract(1, 'month'),
    // endValue: moment(new Date(), 'YYYY-MM-DD'),
    // downloadType: 0
  }
  // this.handleChange = this.handleChange.bind(this);

  // showDrawer = () => {
  //   this.setState({
  //     visible: true
  //   })
  // }

  // onClose = () => {
  //   const { handlingData } = this.props
  //   handlingData('visible', false)
  // }

  // onChange = (field, value) => {
  //   const { handlingData } = this.props

  //   handlingData([field], value)
  // }
  // onStartChange = value => {
  //   this.onChange('startValue', value)
  // }

  // onEndChange = value => {
  //   this.onChange('endValue', value)
  // }
  // changeDownloadType = value => {
  //   this.onChange('downloadType', value)
  // }
  // download = () => {
  //   const {
  //     data: { completed_users = [] }
  //   } = this.props.data.Analytics
  //   let dataCSV = []
  //   let header = []
  //   if (this.state.downloadType === 0) {
  //     dataCSV = completed_users
  //       .filter(x =>
  //         moment(x.date_time).isBetween(
  //           this.state.startValue,
  //           this.state.endValue.add(1, 'days')
  //         )
  //       )
  //       .map(x => {
  //         return {
  //           id: x.id,
  //           username: x.username,
  //           date: moment(x.date_time).format('YYYY-MM-DD')
  //         }
  //       })
  //       .filter(x => {
  //         return x !==null
  //       })
  //     header = ['#', 'User Name', 'Date']
  //   }
  //   generateCSV(dataCSV, header)
  // }

  handleChange (value) {
    this.setState({
      activeFilterValue: value === '1' ? 24 : Number(value)
    })
  }
  componentDidMount () {
    const { lesson, course } = this.props
    this.props.getLessonReport(lesson, { type: 'Analytics', course_id: course })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.data.Analytics.data !== this.props.data.Analytics.data) {
      const completeUsers = this.props.data.Analytics.data.completed_users
      const completeUserSize = completeUsers ? completeUsers.length * 68 : 0
      this.setState({
        completeUserSize
      })
    }
  }
  render () {
    // const { visible } = this.props.data
    const { activeFilterValue, completeUserSize } = this.state
    const { fetch } = this.props.data.LessonAnalytic
    const {
      data: {
        completed_users = [],
        title,
        created_at,
        cover = [],
        description,
        topic_lists = []
        // topics=[],
        // info=[],
        // status,
        // updated_at,
      }
    } = this.props.data.Analytics

    const { t } = this.props

    const lang = localStorage.getItem('i18nextLng')

    const dates = axisDate(activeFilterValue)

    const showDates = axisShowData(activeFilterValue)

    const completed_usersData = completed_users
      .map(i => ({ date: formatByDate(i.date_time, activeFilterValue) }))
      .filter(i => dates.includes(i.date))
      .reduce(
        (acc, item) => {
          const index = dates.findIndex(i => i === item.date)
          acc[index] += 1
          return acc
        },
        range(activeFilterValue).map(() => 0)
      )

    return fetch ? (
      <div className='loading'>
        <Spin size='large' />
      </div>
    ) : (
      <div>
        <Row style={{ padding: '30px' }}>
          <Col
            style={{
              padding: '20px',
              backgroundColor: '#fff',
              borderRadius: '10px'
            }}
            lg={16}
          >
            <CustomTabs
              className='tab-border'
              defaultActiveKey='0'
              tabBarExtraContent={
                <CustomSelect
                  defaultValue='1'
                  onChange={value => this.handleChange(value)}
                >
                  <Option value='1'> {t('reports:today')}</Option>
                  <Option value='7'> {t('reports:seven_days')}</Option>
                  <Option value='30'> {t('reports:last_month')}</Option>
                  <Option value='12'> {t('reports:one_year')}</Option>
                  <Option value='5'> {t('reports:all_time')}</Option>
                </CustomSelect>
              }
            >
              <TabPane tab={t('reports:completed')} key='0'>
                <Row style={{ marginLeft: 75 }}>
                  <Col lg={4} sm={24}>
                    <Text>{t('reports:participant')}</Text>
                    <Title style={{ margin: '0' }}>
                      {completed_users.length}
                    </Title>
                  </Col>
                  <ParticipantSlider
                    participantSize={completeUserSize}
                    participants={completed_users}
                  />
                  {/* <Col lg={20} sm={24} style={{ marginTop: '25px' }}>
                    {completed_users
                      .filter(i => i.avatar)
                      .map(i => {
                        return (
                          <Avatar
                            style={{ marginRight: '5px' }}
                            key={i.id}
                            src={i.avatar.link}
                            alt={i.username}
                          />
                        )
                      })}
                  </Col> */}
                </Row>
                <ReactEcharts
                  option={{
                    animationDelay: 1000,
                    color: ['#7FC4FD'],
                    grid: {
                      show: true
                    },
                    tooltip: {
                      trigger: 'axis'
                    },
                    series: [
                      {
                        name: t('reports:participant'),
                        type: 'bar',
                        barWidth: '60%',
                        data: completed_usersData
                      }
                    ],
                    xAxis: {
                      type: 'category',
                      data: activeFilterValue === 24 ? showDates : dates,
                      axisTick: {
                        alignWithLabel: true
                      }
                    },
                    yAxis: {
                      name: t('reports:participant'),
                      type: 'value',
                      axisTick: {
                        alignWithLabel: true
                      },
                      min: 0,
                      max: completed_users.length,
                      minInterval: 1
                    }
                  }}
                />
              </TabPane>
            </CustomTabs>
          </Col>
          <Col lg={8} style={{ padding: '0 25px 20px 20px', direction: (lang === 'hebrew' || lang === 'arabic') ? 'rtl' : 'ltr' }}>
            <CustomCard
              bordered={false}
              cover={<img alt={title} src={cover.link} />}
            >
              <Meta title={title} />
              {/* <p key="1">Host : {host}</p> */}
              {/* <p key="3">Lesson : {lessons}</p> */}
              <p key='2'>
                <b>{t('reports:created_date')}: </b>
                {created_at}
              </p>
              {/* <p key="4">Status  : {status}</p> */}
              <p key='5'>
                <b>{t('reports:description')}: </b>
                {description || ' - -'}
              </p>
              <p key='6'>
                <b>{t('reports:topic')}: </b>{' '}
                {topic_lists.length === 0
                  ? ' - -'
                  : topic_lists.map((x, id) => {
                    return <Text key={id}>{x}</Text>
                  })}
              </p>
            </CustomCard>
          </Col>
        </Row>
        {/* <Drawer
          title="Report"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={visible}
        >
          <CustomDatePicker
            format="YYYY-MM-DD"
            value={startValue}
            placeholder="From Date"
            onChange={this.onStartChange}
          />
          <CustomDatePicker
            format="YYYY-MM-DD"
            value={endValue}
            placeholder="To Date"
            onChange={this.onEndChange}
          />
          <CustomSelect
            className="report-type-select"
            onChange={this.changeDownloadType}
            defaultValue="Select One"
          >
            <Option value="0">Completed</Option>
          </CustomSelect>
          <button onClick={this.download}>Download</button>
        </Drawer> */}
      </div>
    )
  }
}
export default withTranslation('analytic')(Analytic)
