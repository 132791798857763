import React, { Component, Fragment } from 'react'
import { Form, Input, Button, Tooltip, Icon } from 'antd'
import { withTranslation } from 'react-i18next'

class ResetPasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    const { passwordReset } = this.props

    this.props.form.validateFields((err, values) => {
      if (!err) {
        passwordReset(values)
      }
    })
  }

  validatePassword = (rule, value, callback) => {
    const { t } = this.props

    if (value.length === 0) {
      callback(t('errors:password'))
    }

    // if (!value.match(/[*@!#%&$()^~{}[\]`'"+,-_./:;<=>?±§|]+/)) {
    //   callback(t('errors:password_special'))
    // }

    // if (!value.match(/[A-Z]+/)) {
    //   callback(t('errors:password_uppercase'))
    // }

    if (value.length < 10) {
      callback(t('errors:password_short'))
    }

    if (!value.match(/[a-z]+/)) {
      callback(t('errors:password_lowercase'))
    }

    if (!value.match(/[0-9]+/)) {
      callback(t('errors:password_digit'))
    }

    callback()
  }

  render() {
    const {
      form: { getFieldDecorator },
      t,
      loading,
      username
    } = this.props

    console.log(this.props)

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} autoComplete={false}>
          <Form.Item
            label={t('labels:username')}
            colon={false}
            required={false}
          >
            <Input defaultValue={username} disabled />
          </Form.Item>

          <Form.Item label="New Password" colon={false} required={false}>
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: this.validatePassword
                }
              ]
            })(
              <Input.Password
                placeholder={t('labels:password')}
                name="password"
                addonAfter={
                  <Tooltip title={t('helpers:password')}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                }
              />
            )}
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {t('buttons:confirm')}
            </Button>
          </Form.Item>
        </Form>
      </Fragment>
    )
  }
}

export default Form.create()(
  withTranslation('passwordreset')(ResetPasswordForm)
)
