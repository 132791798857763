import React, { Component } from 'react'
import './Poll.scss'
import { withTranslation } from 'react-i18next'
import { CheckCircleOutlined } from '@ant-design/icons'

class CurrentPoll extends Component {
  state = {
    isVoted: false,
    votedArray: [],
    votedAnsIndex: null,
    votedQuestion: null
  }

  componentDidUpdate(prevProps) {
    const { pollValues, currentPoll, askedPolls } = this.props
    if (prevProps.pollValues !== pollValues && pollValues) {
      this.setVotedArray(pollValues)
    }
    if (prevProps.currentPoll !== currentPoll && currentPoll) {
      this.setState({ votedAnsIndex: null })
    }
    if (prevProps.askedPolls !== askedPolls && askedPolls) {
      this.setState({ votedAnsIndex: null })
    }
  }

  setVotedArray = polls => {
    const pollVoted = polls.map(p => {
      if (p.is_voted) {
        return JSON.parse(p.q)
      }
      return null
    })

    this.setState({ votedArray: pollVoted })
  }

  onClick = (rootIndex, index) => {
    const { onClick } = this.props
    const { votedArray } = this.state
    if (onClick && !votedArray.includes(rootIndex)) {
      this.setState(
        prevState => ({
          votedArray: [...prevState.votedArray, rootIndex],
          votedAnsIndex: index,
          votedQuestion: rootIndex
        }),
        () => {
          onClick(rootIndex, index)
          // console.log('onclick answer', rootIndex, 'idx ans', index, 'votedArray', this.state.votedArray)
        }
      )
    }
  }

  render() {
    const { votedArray, votedQuestion, votedAnsIndex } = this.state
    const {
      available,
      data,
      pollValues,
      userPollValues,
      t,
      canVote,
      askedPolls,
      currentAskedPoll,
      isFinished
    } = this.props
    // console.log(`this.propscurrent`, this.props)
    const votedQuestions = pollValues.map(a => {
      return JSON.parse(a.q)
    })
    const currentPoll =
      currentAskedPoll !== null
        ? data.filter((p, i) => i === currentAskedPoll)[0]
        : null
    const pollAnswer =
      currentAskedPoll !== null
        ? pollValues.filter(p => JSON.parse(p.q) === currentAskedPoll)[0]
        : null
    const totalCurrentVotes = pollAnswer
      ? pollAnswer.votes.reduce((a, i) => {
          return a + i.value
        }, 0)
      : 0
    // const isVoted = pollAnswer && pollAnswer.is_voted ? pollAnswer.is_voted : false
    // console.log(
    //   'current pll',
    //   data,
    //   'pollValues',
    //   pollValues,
    //   'votedArray',
    //   votedArray,
    //   'votedArray.includes(currentAskedPoll)',
    //   votedArray.includes(currentAskedPoll),
    //   'currentAskedPoll',
    //   currentAskedPoll,
    //   'currentPoll',
    //   currentPoll,
    //   'votedQuestions',
    //   votedQuestions,
    //   'pollAnswer',
    //   pollAnswer,
    //   'totalCurrentVotes',
    //   totalCurrentVotes
    // )
    if (!available) {
      return <div className="poll__disabled">{t('poll_disabled')}</div>
    }

    // console.log(
    //   'current poll js votedArray',
    //   votedArray,
    //   'isVoted',
    //   isVoted,
    //   'votedAnsIndex',
    //   votedAnsIndex,
    //   'canvote',
    //   canVote,
    //   'votedQuestion',
    //   votedQuestion
    // )
    return (
      <>
        {askedPolls.length === data.length ||
        askedPolls.length > data.length ||
        isFinished ? (
          data.map((item, idx) => {
            // const voteArr = pollValues && pollValues.find(i => i.q === idx.toString())
            // const hasVoted = voteArr && voteArr.is_voted ? voteArr.is_voted : false
            console.log('hasVoted current poll js', idx)
            return (
              <div
                className={`poll__content ${
                  isFinished ||
                  votedArray.includes(idx) ||
                  votedAnsIndex !== null
                    ? 'poll-answers__voted'
                    : 'poll-answers__vote'
                }`}
                key={`poll_${idx}`}
              >
                <div className="poll__question">{item.question}</div>
                <div className="poll__answers">
                  <div className="poll-answers">
                    {pollValues.length > 0 &&
                      pollValues.map(p => {
                        if (JSON.parse(p.q) === idx) {
                          const totalVotes = p.votes.reduce((a, i) => {
                            return a + i.value
                          }, 0)
                          return (
                            <>
                              {item.answers.map((answer, index) => {
                                if (
                                  p.votes.find(
                                    i => i.key === index.toString()
                                  ) &&
                                  totalVotes > 0
                                ) {
                                  const userVoteArray =
                                    userPollValues &&
                                    userPollValues.map(ua => {
                                      if (ua.q === JSON.parse(p.q)) {
                                        return p.votes.find(i => i.key === ua.a)
                                          .key
                                        // return userPollValues.filter(o1 =>
                                        //   p.votes.some(o2 => o1.a === o2.key)
                                        // )[0].a
                                      } else {
                                        return null
                                      }
                                    })
                                  console.log('uservotearray', userVoteArray)
                                  console.log('userPollValues', userPollValues)
                                  const userVote =
                                    userVoteArray &&
                                    userVoteArray.filter(x => x !== undefined)

                                  const percent =
                                    (100 / totalVotes) *
                                    p.votes.find(
                                      i => i.key === index.toString()
                                    ).value
                                  // console.log(
                                  //   'answer here voted',
                                  //   voted,
                                  //   'index',
                                  //   index,
                                  //   'votedAnsIndex',
                                  //   votedAnsIndex,
                                  //   'answer',
                                  //   answer,
                                  //   'percent',
                                  //   percent,
                                  //   'isVoted',
                                  //   isVoted,
                                  //   'canVote',
                                  //   canVote,
                                  //   'userVote',
                                  //   userVote
                                  // )
                                  return (
                                    <div
                                      className={`poll-answers__item`}
                                      key={index}
                                      onClick={() => this.onClick(idx, index)}
                                    >
                                      <div
                                        className="poll-answers__item-text"
                                        style={{ color: 'black' }}
                                      >
                                        {answer} ({percent.toFixed(0)}%)
                                      </div>
                                      <div
                                        className="poll-answers__item-progress"
                                        style={{
                                          width: percent + '%',
                                          backgroundColor:
                                            userVote[0] === index && '#2d6ff2'
                                        }}
                                      />
                                      {userVote[0] === index && (
                                        <span
                                          className="poll-answers__item-voted"
                                          style={{ color: 'black' }}
                                        >
                                          <CheckCircleOutlined />
                                        </span>
                                      )}
                                    </div>
                                  )
                                }
                                return (
                                  <div
                                    className="poll-answers__item"
                                    key={index}
                                    onClick={() => this.onClick(idx, index)}
                                  >
                                    <div className="poll-answers__item-text">
                                      {answer}
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          )
                        } else {
                          return <></>
                        }
                      })}
                    {!votedQuestions.includes(idx) &&
                      item.answers.map((answer, index) => (
                        <div
                          className="poll-answers__item"
                          key={index}
                          onClick={() => this.onClick(idx, index)}
                        >
                          <div className="poll-answers__item-text">
                            {answer}
                          </div>
                          {!canVote &&
                            votedAnsIndex === index &&
                            votedQuestion === idx && (
                              <span
                                className="poll-answers__item-voted"
                                style={{ color: 'black' }}
                              >
                                <CheckCircleOutlined />
                              </span>
                            )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div
            className={`poll__content ${
              isFinished ||
              votedArray.includes(currentAskedPoll) ||
              votedAnsIndex !== null
                ? 'poll-answers__voted'
                : 'poll-answers__vote'
            }`}
          >
            <div className="poll__question">
              {currentPoll && currentPoll.question}
            </div>
            <div className="poll__answers">
              <div className="poll-answers">
                {currentPoll &&
                  currentPoll.answers.map((answer, index) => {
                    if (
                      pollAnswer &&
                      pollAnswer.votes.find(i => i.key === index.toString()) &&
                      totalCurrentVotes > 0
                    ) {
                      const userVoteArray =
                        userPollValues &&
                        userPollValues.map(ua => {
                          if (ua.q === currentAskedPoll) {
                            return pollAnswer.votes.find(i => i.key === ua.a)
                              .key
                            // return userPollValues.filter(o1 =>
                            //   pollAnswer.votes.some(o2 => o1.a === o2.key)
                            // )[0].a
                          } else {
                            return null
                          }
                        })
                      const userVote =
                        userVoteArray &&
                        userVoteArray.filter(x => x !== undefined)
                      const percent =
                        (100 / totalCurrentVotes) *
                        pollAnswer.votes.find(i => i.key === index.toString())
                          .value
                      // console.log('currentPoll poll jsx', voted, index)
                      // console.log(
                      //   'answer here voted secondd',
                      //   voted,
                      //   'index',
                      //   index,
                      //   'votedAnsIndex',
                      //   votedAnsIndex,
                      //   'answer',
                      //   answer,
                      //   'percent',
                      //   percent,
                      //   'isVoted',
                      //   isVoted,
                      //   'canVote',
                      //   canVote,
                      //   'userVote',
                      //   userVote
                      // )
                      return (
                        <div
                          className="poll-answers__item"
                          key={index}
                          onClick={() =>
                            this.onClick(JSON.parse(pollAnswer.q), index)
                          }
                        >
                          <div
                            className="poll-answers__item-text"
                            style={{ color: 'black' }}
                          >
                            {answer} ({percent.toFixed(0)}%)
                          </div>
                          {userVote[0] === index && (
                            <span
                              className="poll-answers__item-voted"
                              style={{ color: 'black' }}
                            >
                              <CheckCircleOutlined />
                            </span>
                          )}
                          <div
                            className="poll-answers__item-progress"
                            style={{
                              width: percent + '%',
                              backgroundColor:
                                userVote[0] === index && '#2d6ff2'
                            }}
                          />
                        </div>
                      )
                    }
                    return (
                      <div
                        className="poll-answers__item"
                        key={index}
                        onClick={() => this.onClick(currentAskedPoll, index)}
                      >
                        <div className="poll-answers__item-text">{answer}</div>
                        {!canVote &&
                          votedAnsIndex === index &&
                          votedQuestion === currentAskedPoll && (
                            <span
                              className="poll-answers__item-voted"
                              style={{ color: 'black' }}
                            >
                              <CheckCircleOutlined />
                            </span>
                          )}
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default withTranslation('events')(CurrentPoll)

// put this back
/*<div className={`poll__content ${(isVoted || isFinished || votedArray.includes(idx)) ? 'poll-answers__voted' : 'poll-answers__vote'}`}><div className={`poll__content ${(isVoted || isFinished || votedArray.includes(idx)) ? 'poll-answers__voted' : 'poll-answers__vote'}`}>*/
