import React, { useEffect, useMemo, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import className from 'classnames'
// import { Button } from 'antd'
import moment from 'moment'
import UserImage from '../../../../../assets/user.svg'
// import ChatImage from '../../../../../assets/chat_share_image.svg'
// import ChatFile from '../../../../../assets/chat_share_file.svg'
import './Chat.scss'

const Chat = props => {
  const {
    available,
    attachments,
    t,
    messages,
    isChatAble,
    isPast,
    hostLists,
    user,
    currentPlayTime,
    isEnd,
    joinStatus
  } = props
  const [textChat, setTextChat] = useState('')

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })

    const chatContent = document.getElementById('chat-content')
    if (chatContent) {
      chatContent.scrollTop = chatContent.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const sendMessage = e => {
    const { sendMessage } = props
    if (sendMessage) {
      if (e.keyCode === 13) {
        e.preventDefault()
        sendMessage(textChat.trim())
        setTextChat('')
      }
    }
  }

  useEffect(() => {
    scrollToBottom('chat-content')
  }, [])

  if (!available) {
    return (
      <div className="chat">
        <div className="chat__head">{t('labels:live_chat')}</div>
        <div className="chat__disabled">{t('labels:chat_available')}</div>
      </div>
    )
  }

  const messageToShow = useMemo(() => {
    if (!isPast) return messages

    const pastMessages = messages.filter(
      message => currentPlayTime >= message.time || isEnd
    )
    return pastMessages
  }, [isPast, messages, currentPlayTime, isEnd])

  // const handleFileButtonClick = () => {
  //   // Logic to handle file upload
  //   // You can open a file picker dialog and handle the selected file
  // }

  // const handleVideoButtonClick = () => {
  //   // Logic to handle video recording
  //   // You can open a video recording dialog or perform other actions
  // }
  return (
    <div className="chat">
      <div
        className={`${
          attachments.length === 0 && !joinStatus
            ? 'chat__notjoined'
            : attachments.length !== 0 && !joinStatus
            ? 'chat__notjoinedattach'
            : attachments.length === 0 && joinStatus
            ? 'chat__inner'
            : 'chat__innerattach'
        }`}
      >
        <div className="chat__content" id="chat-content">
          {messageToShow.map((message, index) => (
            <div
              key={index}
              className={className('chat__message', {
                chat__message_my: message.user_name === user.info.username
              })}
            >
              <div
                className={className('chat-message', {
                  'chat-message_my': message.user_name === user.info.username
                })}
              >
                <div className="chat-message__data">
                  <ChatMessageInfo
                    user={user}
                    message={message}
                    hostLists={hostLists}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`chat__footer`}>
        {/* <div className={`chat__footer ${isVideoAble ? 'video-footer' : ''}`}> */}
        {/* {isVideoAble && (
          <>
            <Button
              onClick={handleFileButtonClick}
              className="custom-button custom-image-button"
            >
              <img src={ChatFile} alt="" className="custom-image" />
            </Button>

            <Button
              onClick={handleVideoButtonClick}
              className="custom-button custom-image-button"
            >
              <img src={ChatImage} alt="" className="custom-image" />
            </Button>
          </>
        )} */}
        <textarea
          disabled={!isChatAble || isPast}
          value={textChat}
          type="text"
          className="chat__input"
          placeholder={t('placeholders:chat')}
          onChange={e => setTextChat(e.target.value)}
          onKeyDown={e => {
            sendMessage(e)
          }}
        />
        <div className="scroll_to_here" ref={messagesEndRef} />
      </div>
    </div>
  )
}

const ChatProfileLogo = ({ message, hostLists }) => {
  let photo = UserImage
  hostLists.forEach(user => {
    if (user.username.replaceAll('_', '') === message.user_name) {
      photo = user.avatar && user.avatar.link
    }
  })
  return (
    <div className="chat-message__image">
      <img src={photo} alt="" className="chat-message__avatar" />
    </div>
  )
}

const ChatMessageInfo = ({ user, message, hostLists }) => {
  const isOwnChat = ({ info }, { user_name }) => user_name !== info.username
  const handleTimeStamp = ({ timeStamp }) => {
    if (timeStamp) {
      const newTimeStamp = moment(timeStamp).format()
      return moment(newTimeStamp).format('DD MMM YYYY HH:mm')
    }
    return ''
  }

  function urlify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return text.split(urlRegex).map(part => {
      if (part.match(urlRegex)) {
        return (
          <a target="_blank" href={part} rel="noopener noreferrer">
            {part}
          </a>
        )
      }
      return part
    })
  }

  if (isOwnChat(user, message)) {
    return (
      <div className="chat-message__author">
        <ChatProfileLogo message={message} hostLists={hostLists} />
        <div className="chat-message__content chat-message__viewer">
          <span className="chat-message__username">
            {handleTimeStamp(message)}
          </span>
          <div className={className('chat-message__wrap_info')}>
            <span className="chat-message__username">
              {message.user_name} :
            </span>
            <span className="chat-message__text">
              {' '}
              {urlify(message.content)}
            </span>
          </div>
        </div>
      </div>
    )
  }
  return <span className="chat-message__text"> {urlify(message.content)}</span>
}

export default withTranslation()(Chat)
