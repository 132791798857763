import { Activity } from "./Activity.js"

export class ActivityRepository {
	activityList = []
	activityTree = null

	InitializeFromScorm(scorm) {
		if (!scorm && !scorm.scorm_scoes) return
		const scorm_scoes = scorm.scorm_scoes
		for (let i = 0; i < scorm_scoes.length; i++) {
			this.activityList.push(new Activity({ scorm_sco: scorm_scoes[i] })) 
		}

		const rootActivities = this.activityList.filter(x=>x.scormSco.parent === null)
		console.log('this.activityList', this.activityList, 'scorm_scoes', scorm_scoes, 'rootActivities', rootActivities)

		this.activityTree = new Activity()
		this.BuildActivityTree(this.activityTree, rootActivities)
		console.log(this.activityTree)

	}

	BuildActivityTree(activity, childActivities){
		activity.childActivities.push(
			...childActivities.sort((x,y) => x.scormSco.sortorder - y.scormSco.sortorder)
		)

		activity.childActivities.forEach(x=>{
			const nestedChildActivities = this.activityList.filter(y => {
				return x.scormSco.identifier === y.scormSco.parent
			})
			if(nestedChildActivities && nestedChildActivities.length){
		 		this.BuildActivityTree(x, nestedChildActivities)
			}
		})
	}

	getFirstLaunchActivity(){
		return this.getFirstSuitableActivity(this.activityTree)
	}

	getFirstSuitableActivity(activity){
		for(let i = 0; i < activity.childActivities.length; i++){
				const x = activity.childActivities[i]
				if(x.getScormType()){
					return x
				}else{
					const a = this.getFirstSuitableActivity(x)
					if(a){
						return a;
					}
				}
			}
	}

	hasMultipleSco(){
		if(!this.activityList) return false
		let scoCount = 0
		for (let index = 0; index < this.activityList.length; index++) {
			const type = this.activityList[index].getScormType() 
			if(type === "sco"|| type ==="asset"){
				scoCount++
				if(scoCount > 1) return true
			}
		}
	}

}
