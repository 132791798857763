/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-06-19 10:54:34
 * @LastEditTime: 2019-08-29 18:38:42
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as coursesActions from '../../store/actions/courses'

import actions from '../../store/actions'

import Workshop from './Workshop'

export default connect(
  state => ({
    maze: state.maze,
    courses: state.courses,
    channel: state.channel,
    events: state.events,
    podcasts: state.events,
    assessments: state.assessments,
    user: state.user,
    skillJourney: state.skillJourney
  }),
  dispatch => ({
    coursesActions: bindActionCreators(coursesActions, dispatch),
    initCloneSocket: params =>
      dispatch(actions.courses.initCloneSocket(params)),
    getWorkshopCourses: params =>
      dispatch(actions.courses.getWorkshopCourses(params)),
    getWorkshopAssessments: params =>
      dispatch(actions.assessments.getWorkshopAssessments(params)),
    getWorkshopEvents: params =>
      dispatch(actions.events.getEventsByMode(params)),
    getWorkshopData: (params, isOrderChanged) =>
      dispatch(actions.coursesEvents.getWorkshopData(params, isOrderChanged)),
    deleteEvent: id => dispatch(actions.events.deleteEvent(id)),
    deletePodcast: id => dispatch(actions.events.deletePodcast(id)),
    deleteAssessment: id =>
      dispatch(actions.assessments.removeAssessmentById(id)),
    listMazes: data => dispatch(actions.maze.listMazes(data)),
    resetMazeList: () => dispatch(actions.maze.resetMazeList()),
    listSkillJourney: params =>
      dispatch(actions.skillJourney.listSkillJourney(params)),
    handleMazeData: data => dispatch(actions.maze.handleMazeData(data))
  })
)(Workshop)
