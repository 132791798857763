import React, { Component, Fragment } from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Page } from './styled'
import { Field } from '../../../../styles'
import {
  DatePicker,
  PrivacySelector,
  OrganizationPicker,
  GroupPicker
} from './components'
import { Input, Checkbox, Button, Spin, message, Alert, Modal } from 'antd'
import history from '../../../../history'
import { withTranslation, Trans } from 'react-i18next'
import { api, i18n } from '../../../../services'
import { general } from '../../../../constants'
import './styles.scss'
import ReactSVG from 'react-svg'

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedType: 0,
      eventTime: null,
      eventDuration: null,
      selectedOrganizations: [],
      selectedGroups: [],
      emails: [],
      checkedTerms: false,
      isFetching: false,
      isEmailsValid: false,
      isPublish: false,
      isDisable: true,
      error_message: '',
      error: false,
      current: {
        id: null,
        title: '',
        email: '',
        description: '',
        topics: [],
        topic_list: [],
        is_chat: true
      },
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: [],
      isOverLimit: false,
      errorData: {
        title: '',
        subTitle: ''
      },
      isAdminOnly: false,
      isSendMail: false
    }
    this.handleInsertUsers = _.debounce(this.handleInsertUsers, 1000)
    this.handleCheckUser = _.debounce(this.handleCheckUser, 500)
  }

  componentDidMount() {
    const { get } = this.props
    const id = this.props.id || null

    if (get) {
      get()
    }

    this.setState({
      isFetching: true
    })

    api.assessments.getById(id).then(({ data }) => {
      this.setState(
        {
          current: data,
          eventTime: data.start_date_time ? moment(data.start_date_time) : null,
          eventDuration: data.duration || 20,
          isFetching: false
        },
        () => {
          this.getOldSetting(data)
        }
      )
    })
  }

  getOldSetting = courseById => {
    const { setting } = courseById
    if (setting) {
      const {
        group_ids,
        publish_option,
        organization_ids,
        emails,
        is_admin_only: isAdminOnly
      } = setting
      if (publish_option === general.ORGANIZATION) {
        this.handleSelectedType(2)
      } else if (publish_option === general.ORGANIZATION_GROUP) {
        this.handleSelectedType(3)
      } else if (publish_option === general.SPECIFIC_USER) {
        this.handleSelectedType(4)
      } else if (publish_option === general.PUBLIC) {
        this.handleSelectedType(1)
      }
      this.handleInsertUsers(_.uniq(emails).join('\n'))
      this.setState({
        selectedOrganizations: organization_ids,
        selectedGroups: group_ids,
        emails,
        isAdminOnly
      })
    }
  }

  getLastOption = () => {
    const { selectedType } = this.state
    if (selectedType === 1) {
      return general.PUBLIC
    } else if (selectedType === 2) {
      return general.ORGANIZATION
    } else if (selectedType === 3) {
      return general.ORGANIZATION_GROUP
    } else {
      return general.SPECIFIC_USER
    }
  }

  checkFinish = (startDataTime, duration) => {
    const isAllowPermission = this.getUserRole()
    if (isAllowPermission) {
      if (startDataTime) {
        const newTime = moment(startDataTime).format('LLLL')
        const diffMinutes = moment().diff(newTime, 'minutes')
        const newDuration = duration || 0 + 10
        const checkInterval = diffMinutes - newDuration

        if (checkInterval > 0) {
          return false
        } else {
          return true
        }
      }
      return true
    } else {
      return false
    }
  }
  getUserRole = () => {
    const {
      user: {
        info: { isAdmin, isSuperAdmin }
      }
    } = this.props
    const { is_finished: isFinished } = this.state.current
    if (!isFinished) {
      return isAdmin || isSuperAdmin
    } else {
      return !isFinished
    }
  }

  handlePublish = () => {
    const { t } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      emails,
      eventTime,
      eventDuration,
      current,
      isEmailsValid,
      isAdminOnly: is_admin_only,
      isSendMail
    } = this.state
    const currentData = new Date()
    const nowPlus10 = moment(currentData).add(10, 'minutes')
    const removedSpaceEmail = _.map(emails, email => email.trim().toLowerCase())
    if (this.checkFinish(eventTime, eventDuration)) {
      if (eventTime < nowPlus10) {
        this.handleTimeError('date_time')
        return
      }
      if (!eventDuration) {
        this.handleTimeError('duration')
        return
      }
      if (!eventTime) {
        message.error(i18n.t('errors:start_date'))
        return
      }
      if (!eventDuration) {
        message.error(i18n.t('errors:duration'))
        return
      }
    }

    if (!_.isEmpty(emails) && !isEmailsValid) {
      message.error(t('publishsettings:emails_validation_error'))
      return
    }
    let privacy = {
      is_public: selectedType === 1,
      is_admin_only: selectedType === 1 ? is_admin_only : false,
      organization_ids: selectedOrganizations,
      group_ids: selectedGroups,
      emails: _.uniq([...removedSpaceEmail]),
      publish_option: this.getLastOption()
    }

    const data = {
      title: current.title,
      description: current.description,
      topics: current.topics.map(item => item.topic_id),
      cover_id: current.cover_id,
      is_chat: current.is_chat,
      is_graph: current.is_graph,
      host: current.host.email,
      start_date_time: eventTime,
      duration: Number(eventDuration),
      privacy,
      isSendMailToHost: true,
      isSendMail
    }
    if (!this.checkFinish(eventTime, eventDuration)) {
      delete data.start_date_time
      delete data.duration
    }

    const eventId = this.props.id || current.id || null

    this.checkedPublishButtonDisabling()

    api.assessments
      .updateById(eventId, data)
      .then(() => {
        history.push('/workshop')
      })
      .catch(({ response }) => {
        const {
          status,
          data: { errorMessage }
        } = response
        if (status === 422) {
          const message = errorMessage.split(':')
          const splitMessage = _.last(message).split('.')
          this.setState({
            isDisable: false,
            isPublish: false,
            errorData: {
              title: splitMessage[0],
              subTitle: splitMessage[1]
            },
            isOverLimit: true
          })
        }
      })
  }

  handleTimeError = type => {
    this.setState(
      {
        error: true,
        error_message:
          type === 'date_time' ? 'date_time_error_assessment' : 'duration_error'
      },
      () => {
        setTimeout(() => {
          if (this.state.error) {
            this.clearAlert()
          }
        }, 10000)
      }
    )
  }

  clearAlert = () => {
    this.setState({
      error: null,
      error_message: null,
      isOverLimit: false
    })
  }

  getDurationValue = value => {
    if (value <= 0) return null
    if (value > 60) return 60
    return value
  }

  handleSelectedDate = (field, value) => {
    this.setState({
      [field]: field === 'eventDuration' ? this.getDurationValue(value) : value
    })
  }

  handleSelectedType = id => {
    this.setState({
      selectedType: id
    })
  }

  handleSelectedOrganizations = id => {
    const { selectedOrganizations } = this.state

    if (selectedOrganizations.includes(id)) {
      this.setState({
        selectedOrganizations: selectedOrganizations.filter(
          organizationId => organizationId !== id
        )
      })
    } else {
      this.setState({
        selectedOrganizations: [...selectedOrganizations, id]
      })
    }
  }

  handleSelectedGroups = groupId => {
    const { selectedGroups } = this.state

    this.setState({
      selectedGroups: selectedGroups.includes(groupId)
        ? selectedGroups.filter(i => i !== groupId)
        : selectedGroups.concat([groupId])
    })
  }

  handleSelectedAllOrganizations = id => {
    const { list } = this.props
    const { selectedGroups } = this.state

    const { groups = [] } = list.find(i => i.id === id)
    const groupIds = groups.map(i => i.id)

    const selectedOrgGroups = groupIds.filter(i => selectedGroups.includes(i))

    if (groupIds.length === selectedOrgGroups.length) {
      this.setState({
        selectedGroups: selectedGroups.filter(
          i => !selectedOrgGroups.includes(i)
        )
      })
    } else {
      this.setState({
        selectedGroups: selectedGroups
          .filter(i => !groupIds.includes(i))
          .concat(groupIds)
      })
    }
  }

  handleChangeTerms = () => {
    const { isPublish } = this.state
    if (!isPublish) {
      this.setState(state => ({
        checkedTerms: !state.checkedTerms,
        isDisable: !state.isDisable
      }))
    }
  }

  checkedPublishButtonDisabling = () => {
    this.setState({
      isDisable: true,
      isPublish: true
    })
  }

  handleInsertUsers = value => {
    const emails = value.replace(/(\r\n|\n|\r)/gm, ',').split(',')
    const check = _.map(_.compact(emails), email =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())
    )
    const isSetValid = _.filter(check, item => item)
    if (isSetValid.length === check.length) {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: true
      })
    } else {
      this.setState({
        emails: value
          .trim()
          .split('\n')
          .filter(i => !!i),
        isEmailsValid: false
      })
    }
  }

  disabledDate = current => current && current < moment().startOf('day')

  onSendEmail = () => {
    this.setState(state => ({
      isSendMail: !state.isSendMail
    }))
  }
  handleCheckUser = () => {
    this.setState({
      isSaving: true
    })
    const { emails } = this.state
    if (!_.isEmpty(emails)) {
      if (this.state.isEmailsValid) {
        const removedSpaceEmail = _.map(emails, email => email.trim())
        const myJSON = JSON.stringify(removedSpaceEmail)
        const {
          user: {
            info: { active_organization_id: activeOrganization }
          }
        } = this.props
        api.events
          .checkUser(myJSON)
          .then(response => {
            const { data } = response
            const isNotExist = data.filter(email => !email.is_exist)
            const isNotInOrg = data.filter(
              email => email.is_exist && !email.in_organization
            )
            this.checkPersonalSpace(activeOrganization, isNotExist, isNotInOrg)
          })
          .catch(error => {
            const { t } = this.props
            message.error(t('publishsettings:emails_validation_error'))
          })
      } else {
        const { t } = this.props
        message.error(t('publishsettings:emails_validation_error'))
      }
    } else {
      this.handlePublish()
    }
  }

  checkPersonalSpace = (activeOrganization, isNotExist, isNotInOrg) => {
    if (activeOrganization) {
      if (_.isEmpty(isNotExist) && _.isEmpty(isNotInOrg)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg,
          isLoading: false
        })
      }
    } else {
      if (_.isEmpty(isNotExist)) {
        this.handlePublish()
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg: [],
          isLoading: false
        })
      }
    }
  }

  handleSendEmail = () => {
    this.handlePublish()
    this.setState({
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: []
    })
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('emails')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false
    })
  }

  renderError = (t, errorData) => {
    return (
      <div>
        <div>{errorData.title}.</div>
        {errorData.subTitle && <div>{errorData.subTitle}.</div>}
        <Trans i18nKey="labels:contact_admin">
          <span style={{ justifyContent: 'center' }}>
            {t('v3:live_straming_para')}
          </span>
          <a
            style={{ justifyContent: 'center' }}
            href="mailto:admin@sharelook.com"
          >
            admin@sharelook.com
          </a>
        </Trans>
      </div>
    )
  }
  onAdminOnly = () => {
    this.setState(state => ({
      isAdminOnly: !state.isAdminOnly
    }))
  }

  render() {
    const {
      list,
      isFetching,
      events: { isSaving },
      user: {
        info: { isAdmin, isSuperAdmin }
      },
      t
    } = this.props
    const {
      selectedType,
      selectedOrganizations,
      selectedGroups,
      eventTime,
      eventDuration,
      checkedTerms,
      emails,
      isFetching: isAssessmentFetching,
      isDisable,
      error_message,
      error,
      isCheckModal,
      isNotExist,
      isNotInOrg,
      errorData,
      isOverLimit,
      isAdminOnly,
      isSendMail
    } = this.state

    const privacyTypes = [
      isSuperAdmin && {
        id: 1,
        name: 'Public',
        description: 'PublicDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 2,
        name: 'Organisations',
        description: 'OrganisationsDescription'
      },
      (isSuperAdmin || isAdmin) && {
        id: 3,
        name: 'Organisation Groups',
        description: 'OrganisationGroupsDescription'
      },
      { id: 4, name: 'Specific User', description: 'SpecificUserDescription' }
    ].filter(i => !!i)

    const filteredList = list.filter(
      i => isSuperAdmin || (i.role && i.role.name === 'Admin')
    )

    return (
      <Fragment>
        <section className="section">
          <div className="head head_nopadding">
            <div className="head__title">{t('title')}</div>
            <div className="head__subtitle">{t('assessment_subtitile')}</div>
          </div>
        </section>

        <section className="section section_divider">
          <Spin spinning={isFetching || isAssessmentFetching}>
            <Page style={{ maxWidth: '120rem' }}>
              <Page.Field>
                <DatePicker
                  onChange={this.handleSelectedDate}
                  eventTime={eventTime}
                  disabledDate={this.disabledDate}
                  eventDuration={eventDuration}
                  isShowNoted
                  disabled={!this.checkFinish(eventTime, eventDuration)}
                />
              </Page.Field>
              {error && (
                <div className="date_time_error">
                  <Alert
                    message="Error"
                    description={general[error_message]}
                    type="error"
                    closable
                    showIcon
                    banner
                    onClose={this.clearAlert}
                  />
                </div>
              )}
              {isOverLimit && (
                <div className="date_time_error">
                  <Alert
                    message="Error"
                    description={this.renderError(t, errorData)}
                    type="error"
                    closable
                    showIcon
                    banner
                    onClose={this.clearAlert}
                  />
                </div>
              )}
              <Page.Field>
                <PrivacySelector
                  selected={selectedType}
                  isAdmin={isSuperAdmin || isAdmin}
                  types={privacyTypes}
                  onChange={this.handleSelectedType}
                />
              </Page.Field>
              {selectedType === 2 && (
                <Page.Field>
                  <OrganizationPicker
                    list={filteredList}
                    selectedOrganizations={selectedOrganizations}
                    onChange={this.handleSelectedOrganizations}
                  />
                </Page.Field>
              )}
              {selectedType === 3 && (
                <Page.Field>
                  <GroupPicker
                    list={filteredList}
                    selectedGroups={selectedGroups}
                    onChange={this.handleSelectedGroups}
                    onChangeAll={this.handleSelectedAllOrganizations}
                  />
                </Page.Field>
              )}
              {selectedType === 4 && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:users_emails')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        rows={3}
                        onChange={e => this.handleInsertUsers(e.target.value)}
                        defaultValue={emails.join('\n')}
                      />
                    </Field.Control>
                    <span>{t('labels:description_specifiction_email')}</span>
                  </Field>
                </Page.Field>
              )}
              {selectedType > 0 && (
                <Page.Field>
                  <Field>
                    <Field.Label>{t('labels:disclaimer')}</Field.Label>
                    <Field.Control>
                      <Input.TextArea
                        disabled
                        id="emails"
                        rows={6}
                        style={{ resize: 'none' }}
                        value={t('assessments:disclaimer')}
                      />
                    </Field.Control>
                  </Field>
                  <Field>
                    <Field.Label>
                      <Checkbox
                        value={checkedTerms}
                        onChange={this.handleChangeTerms}
                      >
                        <Trans i18nKey="labels:terms">
                          {t('i_agree_to_these')}
                          <a href="/pages/terms-conditions" target="_blank">
                            {t('terms_and_conditions')}
                          </a>
                        </Trans>
                      </Checkbox>
                    </Field.Label>
                  </Field>
                </Page.Field>
              )}
            </Page>
          </Spin>
        </section>
        {isCheckModal && (
          <Modal
            visible={isCheckModal}
            centered
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactSVG src="/images/icons/menu/bells.svg" />
                <div style={{ paddingLeft: '2rem' }}>
                  {t('labels:check_user')}
                </div>
              </div>
            }
            onCancel={this.closeCheckModal}
            footer={[
              <Button key="back" shape="round" onClick={this.closeCheckModal}>
                No
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="primary"
                onClick={this.handleSendEmail}
              >
                Yes
              </Button>
            ]}
          >
            {!_.isEmpty(isNotExist) && (
              <div style={{ marginBottom: '1rem' }}>
                <p style={{ color: '#333333' }}>{t('labels:user_not_exist')}</p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotExist) &&
                    isNotExist.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!_.isEmpty(isNotInOrg) && (
              <div>
                <p style={{ color: '#333333' }}>
                  {t('labels:user_not_exist_in_org')}
                </p>
                <div
                  style={{
                    backgroundColor: '#f1f1f1',
                    borderRadius: '.5rem',
                    padding: '1rem'
                  }}
                >
                  {!_.isEmpty(isNotInOrg) &&
                    isNotInOrg.map((item, index) => (
                      <div className="selectedMedia">
                        <div className="titleMedia">
                          {index + 1}. {item.email}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </Modal>
        )}
        <div className="p-footer">
          <div className="p-footer__inner">
            {selectedType === 1 && (
              <div className="p-footer__item1">
                <Checkbox onChange={this.onAdminOnly} checked={isAdminOnly}>
                  {general.admin_only}
                </Checkbox>
              </div>
            )}
            {(selectedType === 2 || selectedType === 3) && (
              <div className="p-footer__item1">
                <Checkbox onChange={this.onSendEmail} checked={isSendMail}>
                  {general.send_email}
                </Checkbox>
              </div>
            )}
            <div className="p-footer__item">
              <Button className="rounded" onClick={this.props.back}>
                {t('buttons:back_to_details')}
              </Button>
            </div>
            <div className="p-footer__item">
              <Button
                className="rounded"
                type="green"
                loading={isSaving}
                onClick={this.handleCheckUser}
                disabled={isDisable}
              >
                {t('buttons:publish_assessment')}
              </Button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('publishsettings')(Settings)
