import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal, Tag } from 'antd'
import MyCalendar from '../../../../MyCalendar/MyCalendar'
import _ from 'lodash'
import moment from 'moment'
import api from '../../../../../services/api'

const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

function MarketPlaceCalendar() {
  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [isCalendarFetching, setIsCalendarFetching] = useState(true)
  const [events, setEvents] = useState([])

  const toggleCalendarModal = () => setShowCalendarModal(show => !show)

  useEffect(() => {
    api.calendar
      .getCalendar()
      .then(res => {
        setEvents(res.data.calendar)
        setIsCalendarFetching(false)
      })
      .catch(err => {
        setIsCalendarFetching(false)
      })
  }, [])

  const onUpdate = events => {
    setEvents(events)
  }

  const eventsByDay = useMemo(() => {
    const availableEvents = events.filter(
      item => item.coaching_availability && !item.is_booked
    )

    const eventsByMonth = _.groupBy(availableEvents, ({ start }) =>
      moment(start).startOf('month')
    )

    return Object.keys(eventsByMonth).map(item => {
      const arr = eventsByMonth[item]
      var groupByDay = _.groupBy(arr, ({ start }) =>
        moment(start).startOf('day')
      )
      return {
        [item]: groupByDay
      }
    })
  }, [events])

  console.log({ eventsByDay })

  return (
    <div className="marketplace-schedule-container">
      <h2 className="marketplace-schedule-header">
        Setting Calendar For Booking
      </h2>

      <Button
        icon="calendar"
        type="primary"
        shape="round"
        onClick={toggleCalendarModal}
        loading={isCalendarFetching}
      >
        {' '}
        Add Calendar{' '}
      </Button>

      <Modal
        width="auto"
        visible={showCalendarModal}
        onCancel={toggleCalendarModal}
        footer={false}
      >
        <MyCalendar isModal events={events} onUpdate={onUpdate} />
      </Modal>

      {eventsByDay &&
        eventsByDay.length > 0 &&
        eventsByDay.map(monthItem => {
          const monthKey = Object.keys(monthItem)[0]
          const allMonthSlots = monthItem[monthKey]
          return (
            <div className="marketplace-schedule-events">
              {Object.keys(allMonthSlots).map((item, index) => {
                const eventsGroupedByDay = allMonthSlots[item]
                return (
                  <div className="marketplace-schedule-month">
                    {index === 0 && (
                      <h3 className="marketplace-schedule-month-title">
                        {moment(item).format('MMMM YYYY')}
                      </h3>
                    )}
                    <div className="marketplace-schedule-day">
                      <h3 className="marketplace-schedule-day-title">
                        {moment(item).date()}, {days[moment(item).day()]}
                      </h3>
                      {eventsGroupedByDay &&
                        eventsGroupedByDay.map(timeSlot => {
                          return (
                            <Tag>
                              {moment(timeSlot.start).format('hh:mm a')} -{' '}
                              {moment(timeSlot.end).format('hh:mm a')}
                            </Tag>
                          )
                        })}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
    </div>
  )
}

export default MarketPlaceCalendar
