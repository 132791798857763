import React from 'react'

function TableCoverImage(props) {
  const { cover } = props
  return (
    <div className="report-table-cover-container">
      <img
        loading="lazy"
        key={cover.id}
        src={cover.resizes && cover.resizes.thumbnail}
        alt="cover"
      />
    </div>
  )
}

export default TableCoverImage
