import React from 'react'
import { userImageNoBack } from '../../../../../../Reports/images'
import './EventHost.scss'

class EventHost extends React.Component {
  render() {
    const {
      event: {
        host: { name, username, email, avatar }
      }
    } = this.props
    return (
      <div className="event__hosts">
        <div className="event__hosts_item">
          <img src={avatar ? avatar.resizes.thumbnail : userImageNoBack} alt="" />
          <div className="event__hosts_item_info">
            <h2>{name || username}</h2>
            <p>{email}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default EventHost
