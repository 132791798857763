import React, { useCallback, useEffect, useState, useMemo } from 'react'

import './styles.scss'
import { api } from '../../services'
import { Spinner } from '../../components'
import moment from 'moment'
import { Alert, Button, Modal, Pagination, Radio, message } from 'antd'
import history from '../../history'
import SharebotSubscription from './SharebotSubscription'
import { useSelector } from 'react-redux'
import SharebotUnSubscription from './UnsubscriptionList'
import { T } from 'antd/lib/upload/utils'
import { useTranslation } from 'react-i18next'

const defaultState = {
  data: [],
  pagination: {}
}

const PAGE_SIZE = 5

function AccountSubscription() {
  const userInfo = useSelector(state => state.user.info)
  const [subscriptions, setSubscriptions] = useState(defaultState)
  const [unSubscriptions, setUnSubscriptions] = useState(defaultState)
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(true)
  const [isUnSubscriptionLoading, setIsUnSubscriptionLoading] = useState(true)
  const [isShareBotLoading, setIsShareBotLoading] = useState(true)
  const [isShareBotData, setIsShareBotData] = useState({})
  const [cancelUnSubList, setCancelUnSubList] = useState([])
  const [error, setError] = useState({
    subscriptions: false,
    unSubscriptions: false
  })

  useEffect(() => {
    fetchData()
  }, [])

  const fetchSubscriptions = useCallback((page = 0) => {
    setIsSubscriptionLoading(true)
    api.channel
      .listSubscriptions({
        mode: 'subscription',
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE
      })
      .then(res => {
        setError(prev => ({ ...prev, subscriptions: false }))
        setSubscriptions(prevState => ({
          ...prevState,
          data: res.data.data,
          pagination: res.data.pagination
        }))
      })
      .catch(err => {
        setError(prev => ({ ...prev, subscriptions: true }))
      })
      .finally(() => setIsSubscriptionLoading(false))
  }, [])

  const fetchUnsubscriptions = useCallback((page = 0) => {
    setIsUnSubscriptionLoading(true)
    api.channel
      .listSubscriptions({
        mode: 'unsubscription',
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE
      })
      .then(res => {
        setError(prev => ({ ...prev, unSubscriptions: false }))
        setUnSubscriptions(prevState => ({
          ...prevState,
          data: res.data.data,
          pagination: res.data.pagination
        }))
      })
      .catch(err => {
        setError(prev => ({ ...prev, unSubscriptions: true }))
      })
      .finally(() => setIsUnSubscriptionLoading(false))
  }, [])

  const fetchShareBotSubscription = useCallback((page = 0) => {
    setIsShareBotLoading(true)
    console.log(userInfo.active_organization_id, 'checkckckckkckckc')
    api.sharebot
      .getSharebot(userInfo.active_organization_id)

      .then(res => {
        if (res.data) {
          setIsShareBotData(res.data)
          console.log('res-------------------->', res)
        }
      })
      .catch(err => {
        console.log('subscriptionUrl2------------------------->', err)
      })
      .finally(() => setIsShareBotLoading(false))
  }, [])

  const fetchShareBotUnsubscription = useCallback((page = 0) => {
    setIsShareBotLoading(true)
    console.log(userInfo.active_organization_id, 'checkckckckkckckc')
    api.sharebot
      .sharebotUnsubscribeList()
      .then(res => {
        if (res.data) {
          setCancelUnSubList(res.data.cancelled)
        }
      })
      .catch(err => {
        console.log('subscriptionUrl2------------------------->', err)
      })
      .finally(() => setIsShareBotLoading(false))
  }, [])

  const fetchData = useCallback(() => {
    fetchSubscriptions()
    fetchUnsubscriptions()
    fetchShareBotSubscription()
    fetchShareBotUnsubscription()
  }, [])

  const { t } = useTranslation()

  return (
    <div className="account-subscription">
      <SubscriptionContent
        title="Active Subscriptions"
        data={subscriptions}
        loading={isSubscriptionLoading}
        onChangePagination={fetchSubscriptions}
        isActiveSubscriptions
        onDeleteSubscription={fetchData}
        error={error}
      />

      <SubscriptionContent
        title={t('v3:sharebot_prev_sub')}
        data={unSubscriptions}
        loading={isUnSubscriptionLoading}
        onChangePagination={fetchUnsubscriptions}
        onDeleteSubscription={fetchData}
        error={error}
      />

      {userInfo.active_organization_id !== null ? (
        <SharebotSubscription
          title={t('v3:sharebot_sub')}
          orgIdValue={userInfo.active_organization_id}
          data={isShareBotData}
          loading={isShareBotLoading}
          onChangePagination={fetchUnsubscriptions}
          isActiveSubscriptions
          onDeleteSubscription={fetchShareBotSubscription}
          error={error}
        />
      ) : (
        <></>
      )}

      {cancelUnSubList.length > 0 ? (
        <SharebotUnSubscription
          title={t('v3:sharebot_un_sub')}
          orgIdValue={userInfo.active_organization_id}
          data={cancelUnSubList}
          loading={isShareBotLoading}
          onChangePagination={fetchUnsubscriptions}
          isActiveSubscriptions
          onDeleteSubscription={fetchShareBotSubscription}
          error={error}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

const SubscriptionContent = React.memo(
  ({
    title,
    data: { data = [], pagination = {} },
    loading,
    onChangePagination,
    isActiveSubscriptions,
    onDeleteSubscription,
    error
  }) => {
    const [selectedChannelId, setSelectedChannelId] = useState(null)
    const [isSubscriptionUpdating, setIsSubscriptionUpdating] = useState(false)

    const getSubscriptionType = useMemo(
      () => obj => {
        if (obj && obj.type) {
          switch (obj.type) {
            case 'month':
              return 'Monthly Subscription'
            case 'year':
              return 'Yearly Subscription'
            case 'one_time':
              return 'One Time Buy'
            default:
              return ''
          }
        }
      },
      []
    )

    const showEndDate = useMemo(
      () => subscription => {
        if (subscription && subscription.type) {
          return subscription.type !== 'one_time'
        }
        return false
      },
      []
    )

    const handleChannelDelete = useCallback(
      id => {
        setIsSubscriptionUpdating(true)
        api.channel
          .deleteSubscription(id)
          .then(() => {
            onDeleteSubscription()
            setSelectedChannelId(null)
          })
          .catch(err => {
            message.error(`${t('v4:error_delete_channel')}`)
          })
          .finally(() => {
            setIsSubscriptionUpdating(false)
          })
      },
      [onDeleteSubscription]
    )

    const handleSubscription = useCallback(
      (id, subscription) => {
        setIsSubscriptionUpdating(true)

        const subscriptionItem = data.find(item => item.id === id)

        const priceId = getPriceId(
          subscriptionItem && subscriptionItem.prices,
          subscription
        )

        const subscriptionData = {
          cancel_at_period_end: true,
          owner_id: subscriptionItem && subscriptionItem.creator_id,
          price_id: priceId,
          price: subscription.value,
          payment_option: getPaymentOption(subscription.type)
        }

        api.channel
          .updateSubscription({
            channelId: id,
            data: subscriptionData
          })
          .then(res => {
            const redirectLink = res.data && res.data.url
            window.location.href = redirectLink
          })
          .catch(err => {
            message.error(`${t('v4:error_update_sub')}`)
            setIsSubscriptionUpdating(false)
          })
      },
      [data, onChangePagination]
    )

    const handleUnsubscribe = useCallback(
      value => {
        if (isActiveSubscriptions) {
          handleChannelDelete(selectedChannelId)
        } else {
          handleSubscription(selectedChannelId, value)
        }
      },
      [handleChannelDelete, handleSubscription, selectedChannelId]
    )

    const getPaymentOption = useCallback(subscription => {
      const options = {
        price_monthly: 'subscription',
        price_yearly: 'subscription',
        price_one_time: 'one_time'
      }
      return options[subscription.type] || null
    }, [])

    const getPriceId = (prices, subscription) => {
      switch (subscription.type) {
        case 'price_monthly':
          return prices.monthly_price_id
        case 'price_yearly':
          return prices.yearly_price_id
        case 'price_one_time':
          return prices.one_time_price_id
        default:
          return null
      }
    }

    const handleCancelUnsubscribe = useCallback(() => {
      setSelectedChannelId(null)
    }, [])

    const handleSelectChannel = useCallback(id => {
      setSelectedChannelId(id)
    }, [])

    const errorCondition = useMemo(() => {
      return (
        (isActiveSubscriptions && error.subscriptions) ||
        (!isActiveSubscriptions && error.unSubscriptions)
      )
    }, [error, isActiveSubscriptions])
    const { t } = useTranslation()
    return (
      <>
        <div className="account-subscription-title">&#x2022; {title}</div>

        <div className="account-subscription-content">
          {loading ? (
            <Spinner />
          ) : data.length > 0 ? (
            data.map(item => (
              <SubscriptionCard
                key={item.id}
                item={item}
                isActiveSubscriptions={isActiveSubscriptions}
                showEndDate={showEndDate}
                getSubscriptionType={getSubscriptionType}
                onSelectChannel={handleSelectChannel}
              />
            ))
          ) : (
            <div className="account-subscription-error">
              <Alert
                message={
                  errorCondition
                    ? t('v3:sharebot_sub_error')
                    : isActiveSubscriptions
                    ? t('v3:sharebot_no_sub')
                    : t('v3:sharebot_dont_sub')
                }
                type={errorCondition ? 'error' : 'info'}
              />
            </div>
          )}

          {!loading && data.length > 0 && pagination.total > PAGE_SIZE && (
            <div className="account-subscription-content-pagination">
              <Pagination
                defaultCurrent={1}
                pageSize={PAGE_SIZE}
                total={pagination.total}
                onChange={onChangePagination}
              />
            </div>
          )}

          {selectedChannelId && (
            <UnsubscribeModal
              selectedChannelId={selectedChannelId}
              item={data.find(item => item.id === selectedChannelId)}
              isActiveSubscriptions={isActiveSubscriptions}
              onCancel={handleCancelUnsubscribe}
              onConfirm={handleUnsubscribe}
              isSubscriptionUpdating={isSubscriptionUpdating}
            />
          )}
        </div>
      </>
    )
  }
)

const SubscriptionCard = React.memo(
  ({
    item,
    isActiveSubscriptions,
    showEndDate,
    getSubscriptionType,
    onSelectChannel
  }) => {
    const handleClick = useCallback(() => {
      onSelectChannel(item.id)
    }, [item.id, onSelectChannel])
    const { t } = useTranslation()
    return (
      <div className="account-subscription-content-card">
        <img alt="" src={item.cover && item.cover.link} />

        <div className="account-subscription-content-card-info">
          <span
            className="account-subscription-content-card-info-title"
            onClick={() => history.push(`/channel/${item.id}`)}
          >
            {item.title}
          </span>
          <div className="account-subscription-content-card-info-description">
            {item.description || t('v3:sharebot_no_available')}
          </div>

          <div className="account-subscription-content-card-info-price">
            <span>
              <h1> ${item.subscription && item.subscription.price}</h1>
              {getSubscriptionType(item.subscription)}
            </span>
            {isActiveSubscriptions && (
              <div className="account-subscription-content-card-info-date">
                <span className="start-date">
                  {t('v3:sharebot_start_date')}
                  {moment(item.subscription.start_date).format('DD.MM.YYYY')}
                </span>
                {showEndDate(item.subscription) && (
                  <span className="end-date">
                    {t('v3:sharebot_end_date')}
                    {moment(item.subscription.exp_date).format('DD.MM.YYYY')}
                  </span>
                )}
              </div>
            )}

            <Button
              className={`account-subscription-content-card-info-button ${isActiveSubscriptions &&
                'blue-bordered-button'}`}
              shape="round"
              type={isActiveSubscriptions ? 'default' : 'primary'}
              onClick={handleClick}
            >
              {isActiveSubscriptions ? 'Unsubscribe' : 'Subscribe'}
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

const UnsubscribeModal = React.memo(
  ({
    selectedChannelId,
    item,
    isActiveSubscriptions,
    onCancel,
    onConfirm,
    isSubscriptionUpdating
  }) => {
    const priceOptions = item.prices

    const getSubscriptionText = useCallback(key => {
      switch (key) {
        case 'price_monthly':
          return 'Subscription Monthly'
        case 'price_yearly':
          return 'Subscription Yearly'
        case 'price_one_time':
          return 'Buy One Time'
        default:
          return ''
      }
    }, [])

    const [newSubscription, setNewSubscription] = useState({
      type: '',
      value: ''
    })

    const handleNewSubscription = useCallback(
      e => {
        setNewSubscription({
          type: e.target.value,
          value: priceOptions[e.target.value]
        })
      },
      [priceOptions]
    )

    const handleConfirm = useCallback(() => {
      if (isActiveSubscriptions) {
        onConfirm(selectedChannelId)
      } else {
        onConfirm(newSubscription)
      }
    }, [newSubscription, onConfirm])
    const { t } = useTranslation()
    return (
      <Modal
        title=""
        footer={null}
        visible={true}
        header={null}
        centered
        onCancel={onCancel}
      >
        <div className="account-subscription-delete">
          <div className="account-subscription-delete-title">
            <h2>{t('v3:sharebot_sure')}</h2>
            <h2>
              {t('v3:sharebot_do_you_want')}{' '}
              {isActiveSubscriptions ? t('v3:unsubscribe') : t('v3:subscribe')}?
            </h2>
          </div>
          {!isActiveSubscriptions && (
            <Radio.Group
              className="account-subscription-options"
              onChange={handleNewSubscription}
              value={newSubscription.type}
            >
              {['price_monthly', 'price_yearly'].map(key => (
                <Radio
                  className="account-subscription-options-item"
                  key={key}
                  value={key}
                >
                  {getSubscriptionText(key)} ${priceOptions[key]}
                </Radio>
              ))}
            </Radio.Group>
          )}
          <div className="account-subscription-delete-buttons">
            <Button
              shape="round"
              className="no-button"
              onClick={onCancel}
              disabled={isSubscriptionUpdating}
            >
              {isActiveSubscriptions ? t('general:no') : t('buttons:cancel')}
            </Button>
            <Button
              shape="round"
              type="primary"
              onClick={handleConfirm}
              loading={isSubscriptionUpdating}
            >
              {isActiveSubscriptions ? t('general:yes') : t('v3:confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
)

export default AccountSubscription
