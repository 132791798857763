import React from 'react'
import { Cards } from './styled'

import { GridAuto } from '../../styles'

import { Card } from '../'

export default props => {
  const {
    data,
    width,
    type,
    mode,
    horizontal,
    onClick,
    bindLesson,
    loading,
    isClone,
    log,
    getDiscussionLog,
    tagName
  } = props
  return (
    <Cards>
      <Cards.List>
        <GridAuto width={width} horizontal={horizontal}>
          {data &&
            data.map((item, index) => (
              <Card
                key={index}
                type={type}
                mode={mode}
                data={item}
                onClick={onClick}
                horizontal={horizontal}
                loading={loading}
                isClone={isClone}
                log={log}
                tagName={tagName}
                getDiscussionLog={getDiscussionLog}
                bindLesson={() => bindLesson(item.id, item)}
              />
            ))}
        </GridAuto>
      </Cards.List>
    </Cards>
  )
}
