import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import {
  Input,
  Button,
  Select,
  Form,
  Progress,
  Tooltip,
  Divider,
  Spin,
  Popconfirm
} from 'antd'
import 'antd/dist/antd.css'
import { config } from '../../../../../../../../../constants'
import './AiImages.scss'
import { ImagePreview } from '../../../../../../../../../components'
import { api } from '../../../../../../../../../services'
import sendMessageIcon from '../../images/send.png'
import dummyUserImg from '../../../../../../../../../assets/user.svg'
import { useSelector } from 'react-redux'
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select
const AiImages = ({ form, onImage, selectedImage, isImageBuilder }) => {
  const [promptText, setPromptText] = useState('')
  const [promptTitle, setPromptTitle] = useState('')
  const [generationStatus, setGenerationStatus] = useState({
    status: 'idle',
    percentage: 0,
    isVariation: false
  })
  const [selectedPosition, setSelectedPosition] = useState(1)
  const [upscaledImage, setUpscaledImage] = useState('')
  const [currentGeneratedImage, setCurrentGeneratedImage] = useState({
    info: '',
    url: ''
  })

  let ws

  useEffect(() => {
    const cleanup = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close()
        console.log('WebSocket closed.')
      }
    }

    return cleanup
  }, [ws])

  const handleGenerateImage = (prompt = '') => {
    if (prompt) {
      startGeneration(prompt)
    } else {
      form.validateFields(async (errors, values) => {
        if (errors || !promptText) {
          return
        }

        startGeneration()
      })
    }
  }

  const startGeneration = prompt => {
    prompt = prompt || promptText
    if (promptTitle) {
      prompt = `${prompt} with text "${promptTitle}"`
    }
    if (!isImageBuilder) {
      prompt = prompt + " --ar 16:9"
    }
    setGenerationStatus({ status: 'waiting-to-start', percentage: 0 })
    setCurrentGeneratedImage({ info: '', url: '' })

    api.midJourney
      .generateImage({
        prompt: prompt
      })
      .then(response => {
        setupWebsocket(response.data.MessageId)
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const setupWebsocket = (messageId, isVariation = false) => {
    const socketUrl =
      config.midJourneySocket +
      (isVariation
        ? `?channel_type=SEND_VARIATION&channel_id=SEND-VARIATION-MESSAGEID-${messageId}`
        : `?channel_type=SEND_IMAGINE&channel_id=SEND-IMAGINE-MESSAGEID-${messageId}`)

    ws = new WebSocket(socketUrl)

    ws.onopen = () => {
      console.log('Websocket connected')
    }

    ws.onclose = () => {
      console.log('Websocket closed')
    }

    ws.onmessage = event => {
      const data = JSON.parse(event.data)
      const isGenerating =
        data.event === 'sendImagine' || data.event === 'sendVariation'
      if (isGenerating && data.progress === 'done') {
        setGenerationStatus({
          status: 'complete',
          percentage: 100,
          isVariation: data.event === 'sendVariation'
        })
        setCurrentGeneratedImage({ info: data.msg, url: data.msg.uri })
        ws.close()
      } else if (isGenerating) {
        setGenerationStatus({
          status: 'running',
          percentage: parseInt(data.progress.replace(/\D/g, ''), 10),
          isVariation: data.event === 'sendVariation'
        })
        setCurrentGeneratedImage({ info: '', url: data.url })
      }
    }
  }

  const generateVariations = () => {
    setGenerationStatus({
      status: 'waiting-to-start',
      percentage: 0,
      isVariation: true
    })

    api.midJourney
      .generateVariation({
        index: selectedPosition,
        msgId: currentGeneratedImage.info.id,
        hash: currentGeneratedImage.info.hash
      })
      .then(response => {
        setupWebsocket(response.data.MessageId, true)
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const upscaleImage = () => {
    setGenerationStatus({ status: 'upscalingImage', percentage: 0 })
    const data = {
      msgId: currentGeneratedImage.info.id,
      hash: currentGeneratedImage.info.hash,
      index: selectedPosition
    }

    api.midJourney
      .upscaleImage(data)
      .then(response => {
        const upscaledImageURL = response.data.uri
        setUpscaledImage(upscaledImageURL)
        setGenerationStatus({ status: 'complete', percentage: 100 })
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', percentage: 0 })
      })
  }

  const handleOperationClick = operation => {
    if (operation === 'generate') {
      generateVariations()
    } else if (operation === 'upscale') {
      upscaleImage()
    }
  }

  const selectImage = () => {
    if (selectedImage && selectedImage.link === upscaledImage) {
      return
    }

    onImage({
      link: upscaledImage,
      id: generate7DigitRandomInteger()
    })
  }

  function generate7DigitRandomInteger() {
    const min = 1000000 // Minimum value (inclusive)
    const max = 9999999 // Maximum value (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const isImageBeingGenerated = useMemo(() => {
    return (
      generationStatus.status !== 'idle' &&
      generationStatus.status !== 'complete'
    )
  }, [generationStatus])

  const handleGenerateFromAi = prompt => {
    setPromptText(prompt)
    //scroll to div with id of promptInput

    const promptInput = document.getElementById('promptInput')
    if (promptInput) {
      promptInput.scrollIntoView({ behavior: 'smooth' })
    }

    // handleGenerateImage(prompt)
  }

  return (
    <div className="ai-images-container">
      <div className="ai-images-user-input">
        <AiImagesForm
          form={form}
          promptText={promptText}
          isImageBeingGenerated={isImageBeingGenerated}
          handleGenerateImage={handleGenerateImage}
          setPromptText={setPromptText}
          promptTitle={promptTitle}
          setPromptTitle={setPromptTitle}
          generationStatus={generationStatus}
        />
        <AiChat
          generateImage={handleGenerateFromAi}
          isImageBeingGenerated={isImageBeingGenerated}
        />
      </div>

      {generationStatus.status !== 'idle' && (
        <div className="ai-images-result">
          {currentGeneratedImage.url && (
            <div className="image-preview">
              <h3>{promptText}</h3>
              <ImagePreview
                src={currentGeneratedImage.url}
                alt="Generated Image"
              />
            </div>
          )}

          {generationStatus.status === 'complete' && (
            <div className="image-preview-actions">
              <Form.Item
                label="Select Position of Image You Like"
                name="position"
              >
                <Select
                  value={selectedPosition}
                  onChange={value => setSelectedPosition(value)}
                >
                  <Option value={1}>Top Left</Option>
                  <Option value={2}>Top Right</Option>
                  <Option value={3}>Bottom Left</Option>
                  <Option value={4}>Bottom Right</Option>
                </Select>
              </Form.Item>
              {currentGeneratedImage.info && (
                <div className="image-preview-actions-buttons">
                  <ButtonWithTooltip
                    type="primary"
                    showTooltip={!selectedPosition}
                    tooltip="Select a position of image you like to generate its variations"
                    onButtonClick={() => handleOperationClick('generate')}
                    buttonText="Generate variations"
                  />
                  <ButtonWithTooltip
                    type="primary"
                    showTooltip={!selectedPosition}
                    tooltip="Select a position of image you like to upscale it"
                    onButtonClick={() => handleOperationClick('upscale')}
                    buttonText="Upscale Image For Selection"
                  />
                </div>
              )}
            </div>
          )}

          {generationStatus.status === 'running' && (
            <div>
              <Progress percent={generationStatus.percentage} />
              <p>
                {generationStatus.isVariation
                  ? 'Generating variations...'
                  : 'Generating your image...'}
              </p>
            </div>
          )}

          {generationStatus.status === 'waiting-to-start' && (
            <Spin
              // indicator={antIcon}
              tip={`Waiting to start generating ${generationStatus.isVariation ? 'variations' : 'image'
                }.`}
            />
          )}

          {generationStatus.status === 'upscalingImage' && (
            <Spin tip={'Upscaling image...'} />
          )}

          {generationStatus.status === 'error' && <p>Error generating.</p>}
          {upscaledImage && !isImageBeingGenerated && (
            <>
              <div className="image-preview">
                <h2>Upscaled Image</h2>
                <ImagePreview src={upscaledImage} alt="Upscaled Image" />
              </div>

              <Button
                type={
                  selectedImage && selectedImage.link === upscaledImage
                    ? 'default'
                    : 'primary'
                }
                onClick={selectImage}
              >
                {selectedImage && selectedImage.link === upscaledImage
                  ? 'Selected'
                  : 'Select'}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  )
}

const ButtonWithTooltip = ({
  tooltip,
  onButtonClick,
  buttonText,
  showTooltip,
  ...props
}) => {
  if (showTooltip) {
    return (
      <Tooltip title={tooltip}>
        <Button disabled {...props}>
          {buttonText}
        </Button>
      </Tooltip>
    )
  }
  return (
    <Button onClick={onButtonClick} {...props}>
      {buttonText}
    </Button>
  )
}

const AiImagesForm = ({
  form,
  promptText,
  isImageBeingGenerated,
  handleGenerateImage,
  setPromptText,
  promptTitle,
  setPromptTitle,
  generationStatus
}) => {
  return (
    <Form
      className="ai-images-form"
      onFinish={() => handleGenerateImage()}
      layout="vertical"
    >
      <div className="ai-images-form-prompt" id="promptInput">
        <h1>AI Image Generation</h1>
        <Divider style={{ color: '#000' }}>
          Generate image using your own image idea
        </Divider>
      </div>
      <Form.Item name="prompt">
        {form.getFieldDecorator('prompt', {
          initialValue: promptText,
          rules: [
            {
              required: true,
              message: 'Please enter your image idea...'
            }
          ]
        })(
          <Input.Search
            disabled={isImageBeingGenerated}
            placeholder=""
            value={promptText}
            onChange={e => setPromptText(e.target.value)}
            onPressEnter={e => handleGenerateImage()}
          />
        )}
      </Form.Item>
      <Form.Item name="title" label={<div className='ai-images-form-label'><span>Add text inside image</span><Tooltip placement="topLeft" title={"This input text will be included in the AI generated image."} arrow>
        <InfoCircleOutlined className="ai-images-form-label-icon" />
      </Tooltip></div>}>
        {form.getFieldDecorator('title', {
          initialValue: promptTitle,
          rules: [
            {
              required: false,
            }
          ]
        })(
          <Input
            disabled={isImageBeingGenerated}
            value={promptTitle}
            size='large'
            onChange={e => setPromptTitle(e.target.value)}
          />
        )}
      </Form.Item>

      <Form.Item>
        <Button
          onClick={() => handleGenerateImage()}
          disabled={
            isImageBeingGenerated && generationStatus.status !== 'error'
          }
          type="primary"
          loading={isImageBeingGenerated}
        >
          Generate Image
        </Button>
      </Form.Item>
    </Form>
  )
}

const AiChat = ({ generateImage, isImageBeingGenerated }) => {
  const defaultMessages = [
    {
      user: false,
      message:
        "What type of image would you like to generate? Please describe the scene, objects, or any specific details you have in mind, and I'll do my best to create it for you."
    }
  ]

  const [isChatStarted, setIsChatStarted] = useState(false)
  const [userInput, setUserInput] = useState('')
  const [messages, setMessages] = useState(defaultMessages)
  const [isReceivingMessage, setIsReceivingMessage] = useState(false)
  const [context, setContext] = useState([])
  const [revisedPrompt, setRevisedPrompt] = useState('')

  const messagesEndRef = useRef(null)

  const handleSubmit = () => {
    if (!userInput) return
    const data = {
      params: {
        prompt: userInput,
        context: context
      }
    }
    setMessages([...messages, { user: true, message: userInput }])
    setUserInput('')
    setIsReceivingMessage(true)
    api.midJourney.conversationAI(data).then(response => {
      const body = response.data.body
      setMessages(prevMsgs => [
        ...prevMsgs,
        { user: false, message: body && body[0].response }
      ])
      setContext(body && body[0].context)
      setRevisedPrompt(body && body[0].revised_prompt)
      setIsReceivingMessage(false)
    })
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  useEffect(() => {
    if (userInput && !isReceivingMessage) return
    scrollToBottom()
  }, [messages, userInput, isReceivingMessage])

  const handleStartNewChat = () => {
    setIsChatStarted(true)
    setMessages(defaultMessages)
    setContext([])
    setRevisedPrompt('')
  }

  const user = useSelector(state => state.user)

  const userImg =
    (user && user.info && user.info.avatar && user.info.avatar.link) ||
    dummyUserImg
  const renderChat = () => {
    return (
      <div className="ai-midjourney-chat">
        <div className="ai-midjourney-chat-messages">
          {messages.map((message, index) => (
            <div
              className={`ai-midjourney-chat-message ${message.user ? 'ai-midjourney-chat-message--user' : ''
                }`}
              key={index}
            >
              <div className="ai-midjourney-chat-message-avatar">
                {message.user ? <img src={userImg} alt="user" /> : 'AI'}
              </div>
              <p>{message.message}</p>
            </div>
          ))}

          {revisedPrompt && !isReceivingMessage && (
            <div className="ai-midjourney-chat-message ai-midjourney-chat-message--revised">
              <Button
                onClick={handleChatButton}
                disabled={isImageBeingGenerated}
              >
                Generate Image Using Suggested Prompt
              </Button>
              <Popconfirm
                title="Are you sure you want to start a new chat?"
                okText="Yes"
                cancelText="No"
                icon={null}
                onConfirm={handleStartNewChat}
              >
                <div className="ai-midjourney-chat-start-new">
                  Start New Chat
                </div>
              </Popconfirm>
            </div>
          )}

          {isReceivingMessage && (
            <div className={`ai-midjourney-chat-message`}>
              <div className="ai-midjourney-chat-message-avatar">AI</div>
              <div className="dot-container">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>

        <Form
          className="ai-midjourney-chat-input"
          onFinish={() => handleSubmit()}
        >
          <Input
            placeholder="Type a message"
            className="ai-midjourney-chat-input"
            value={userInput}
            onChange={e => setUserInput(e.target.value)}
            onPressEnter={() => handleSubmit()}
            disabled={isReceivingMessage || isImageBeingGenerated}
            suffix={
              <Tooltip title="Send">
                <img
                  className="ai-midjourney-chat-input-send"
                  onClick={() => {
                    console.log('Image clicked!') // Add this line for debugging
                    if (isReceivingMessage || isImageBeingGenerated) return
                    handleSubmit()
                  }}
                  src={sendMessageIcon}
                  style={{ opacity: userInput ? 1 : 0.3 }}
                  alt="send message"
                />
              </Tooltip>
            }
          />
        </Form>
      </div>
    )
  }

  const handleChatButton = () => {
    generateImage(revisedPrompt)

    setIsChatStarted(!isChatStarted)
  }

  return (
    <div className="ai-midjourney-chat-container">
      <p>Talk to AI about the image you want to generate</p>

      {isChatStarted && renderChat()}

      <Button
        onClick={() => {
          setIsChatStarted(!isChatStarted)
          if (!isChatStarted) {
            handleStartNewChat()
          }
        }}
        type="primary"
        ghost
      >
        {isChatStarted ? 'Stop Chat' : 'Start Chat'}
      </Button>
    </div>
  )
}

export default Form.create()(AiImages)
