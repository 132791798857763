import React, { Component } from 'react'
import { DatePicker, Input } from 'antd'
import { Row, Col, Field } from '../../../../../../styles'
import { withTranslation } from 'react-i18next'
import { general } from '../../../../../../constants'
import './styles.scss'

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target
    const reg = /^(0|[1-9][0-9]*)$/
    if ((!Number.isNaN(value) && reg.test(value)) || value === '') {
      this.props.onChange(value)
    }
  }

  render () {
    return <Input {...this.props} onChange={this.onChange} maxLength={25}/>
  }
}

class EDatePicker extends Component {
  onChange = (field, value) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(field, value)
    }
  }

  onTimeChange = value => {
    this.onChange('eventTime', value)
  }

  onDurationChange = value => {
    this.onChange('eventDuration', value)
  }

  render () {
    const {
      eventTime,
      eventDuration,
      t,
      disabled,
      isShowNoted,
      disabledDate
    } = this.props

    return (
      <Row className='wrap_date_picker'>
        <Col md={4}>
          <Field>
            <Field.Label>{t('labels:event_date')}</Field.Label>
            <Field.Control>
              <DatePicker
                style={{ width: '100%' }}
                showTime={{ format: 'HH:mm' }}
                format='YYYY-MM-DD HH:mm'
                value={eventTime}
                placeholder={t('placeholders:date_event')}
                onChange={this.onTimeChange}
                disabledDate={disabledDate ? disabledDate : false}
                disabled={disabled}
              />
            </Field.Control>
          </Field>
        </Col>
        <Col md={2}>
          <Field>
            <Field.Label>{t('labels:duration')}</Field.Label>
            <Field.Control>
              <NumericInput
                style={{ width: '100%' }}
                value={eventDuration}
                placeholder={t('placeholders:minutes')}
                onChange={this.onDurationChange}
                disabled={disabled}
              />
            </Field.Control>
          </Field>
        </Col>
        {isShowNoted && <span>{general.event_duration_message}</span>}
      </Row>
    )
  }
}

export default withTranslation()(EDatePicker)
