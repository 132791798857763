import styled from 'styled-components'
import { Card } from '../../../../../../../../../components/Card/styled.js'

Card.AudioInfo = styled.div`
  margin-left: -16px;
`

Card.AudioIcon = styled.div`
  margin-left: 58px;
  color: #c0c0c0;
  ${props =>
  props.cardSelected &&
  `
    margin-left: 48px;
    color: #08c;
  `}
  font-size: 73px;
`

Card.Icon = styled.div`
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.01em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  padding: 40px;
  align-items: center;
  justify-content: center;
  /*margin-left: -58px;*/
  margin-left: -43px;

  svg {
    height: 0.5em !important;
    width: 0.5em !important;
  }
`

export { Card }
