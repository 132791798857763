/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-20 10:17:10
 * @LastEditTime: 2019-12-06 11:57:24
 * @LastEditors: Please set LastEditors
 */
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import ReactSVG from 'react-svg'
import { Popconfirm } from 'antd'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const colorBorder = '#ddd'

const Course = styled.div`
  width: 100%;
  min-height: 144px;
  border: 0.1rem solid ${colorBorder};
  display: flex;
  align-items: stretch;
  padding: 1.3rem;
  margin-top: -0.1rem;
`

Course.Cover = styled.div`
  width: 20rem;
  min-width: 20rem;
  background: url(${props => props.src}) center no-repeat;
  background-size: cover;
  aspect-ratio: 16/9;
  display: block;
`

Course.Details = styled.div`
  width: calc(100% - 23.8rem);
  border-left: 0.1rem solid ${colorBorder};
  margin-left: 1.3rem;
  padding: 0 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
Course.Details.Top = styled.div``

Course.Details.Bottom = styled.div``

Course.Upcoming = styled.span`
  width: 100%;
  display: inline-block;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-style: italic;
`

Course.Title = styled(NavLink)`
  font-weight: 700;
`

Course.Lessons = styled.span`
  width: 100%;
  display: inline-block;
`

Course.Rating = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  font-weight: bold;
`
Course.Switch = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  margin: 1rem 0;
`

Course.DateCreated = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`

Course.Type = styled.div`
  margin-bottom: 1.5rem;
`

Course.Assign = styled.span`
  width: 100%;
  display: inline-block;
  font-size: 1.1rem;
  text-transform: uppercase;
`

Course.Organisations = styled.div`
  color: ${colors.blue};
  font-size: 1.1rem;
  margin-top: 1rem;
`

Course.Users = styled.span`
  font-weight: 500;
`

Course.DateLive = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
`

Course.DateLabel = styled.div`
  font-size: 1.1rem;
  text-transform: uppercase;
`

Course.DateStrong = styled.span`
  width: 100%;
  text-align: center;
  font-weight: 600;
  margin-top: 1rem;
`

Course.IconRating = styled(ReactSVG)`
  margin: 0 0.3rem;

  svg {
    width: 1.3rem;

    path {
      fill: ${colors.darkGreyOne};
    }
  }
`

Course.Events = styled.div`
  border-left: 0.1rem solid ${colorBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.3rem 0 2.5rem;
`

Course.Centering = styled.div`
  display: flex;
  flex-direction: column;
`

Course.Event = styled(Popconfirm)`
  cursor: pointer;
`
Course.Clone = styled(Popconfirm)`
  cursor: pointer;
  margin-bottom: 2.5rem;
`

Course.EveneLink = styled.a`
  margin-bottom: 2.5rem;
`

Course.IconEvents = styled(ReactSVG)`
  svg {
    width: 1.5rem;

    path {
      fill: ${colors.blue};
    }
  }
`

export { Course }
