import styled from 'styled-components'
import ReactSVG from 'react-svg'
import { Button } from 'antd'
import { Filter } from '../../../../components'
import { Head } from '../../../../styles'
import { styleguide } from '../../../../constants'

const { colors } = styleguide

const Page = styled.div`
  padding: 4rem 3.2rem;
`

Page.Head = styled(Head)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
`

Page.Head.Button = styled(Button)`
  && {
    height: 4rem;
    padding: 0 4rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
`
Page.Head.Search = styled.div`
  margin-right: 2rem;
  width: 450px;
  display: flex;
  align-items: center;
  .rounded-select{
    min-width: 140px;
    .ant-select-selection--single{
      display: flex;
      align-items: center;
      height: 4rem;
      padding: 0 4rem;
      border-radius: 50px;
      color: #fff;
      background-color: #0068FF;
      border-color: #0068FF;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      padding: 0 10px;
    }
    svg{
      fill: white;
    }
  }
`

const Column = styled.div``
const Row = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

Page.Filter = styled(Filter)`
  && {
    justify-content: flex-start;
  }
`

const Table = styled.div`
  margin: 3rem 0;
`

Table.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

Table.Head = styled.div`
  border-top: 0.1rem solid ${colors.darkGreyThree};
  border-bottom: 0.1rem solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
  margin-top: 1.5rem;
`

Table.Head.Column = styled.div`
  width: calc(50% - 5rem);
  font-weight: 700;

  &:not(:first-child) {
    padding-left: 1.5rem;
  }
`

Table.Label = styled.div`
  color: ${colors.blue};
  font-weight: 600;
  white-space: nowrap;
`

Table.Body = styled.div``

Table.Column = styled.div`
  width: calc(50% - 5rem);
  display: flex;
  align-items: center;
  padding-right: 1.5rem;

  &:not(:first-child) {
    border-left: 0.1rem solid ${colors.darkGreyThree};
    padding-left: 1.5rem;
  }
`

Table.Item = styled.div`
  border-bottom: 0.1rem solid ${colors.darkGreyThree};
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 2rem 0;
`

Table.Item.Info = styled.div``

Table.Item.UserName = styled.span`
  font-weight: 700;
`

Table.Item.Position = styled.span``

Table.Item.Role = styled.div`
  font-style: italic;
  margin-top: 0.5rem;
`

Table.Item.IdNum = styled.div`
  font-style: italic;
  margin-top: 0.5rem;
`

Table.Item.Groups = styled.div`
  font-style: italic;
`

Table.Events = styled.div`
  width: 10rem;
  border-left: 0.1rem solid ${colors.darkGreyThree};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
`

Table.EventLink = styled.a`
  margin: 0 1.2rem;
`

Table.EventIcon = styled(ReactSVG)`
  height: 1.7rem;

  svg {
    width: 1.5rem;

    path {
      fill: ${colors.blue};
    }
  }
`

export { Page, Column, Table, Row }
