import React, { Fragment } from 'react'
import className from 'classnames'
import { Card } from './styled'
import './ImageCard.scss'
import { withTranslation } from 'react-i18next'

const PexelCard = props => {
  const {
    data,
    cardSelected,
    handleViewDetailsStockImage,
    data: {
      photographer_id,
      horizontal,
      id,
      photographer,
      photographer_url,
      src: { medium }
    }
  } = props

  const classNames = className(
    'card-image',
    { card_horizontal: horizontal },
    { 'card_on-select': cardSelected }
  )
  return (
    <Fragment>
      <div className={classNames}>
        <div
          className="card__image-thumb"
          onClick={() => handleViewDetailsStockImage(id, data)}
        >
          <Card.ImageIcon cardSelected={cardSelected}>
            {/*<Card.Icon>
              {(is_synthesized_audio &&
                <img
                  src={cardSelected ? activeSynthesizedAudio : deactiveSynthesizedAudio}
                  alt=""
                />
              )}
              {(!is_synthesized_audio &&
                <img
                  src={cardSelected ? activateSoundIcon : deactiveSoundIcon}
                  alt=""
                />
              )}

            </Card.Icon>*/}

            <img className="img-fit-into-card" src={medium} alt="" />
            <a
              className="photo-item__photographer"
              data-overview-tooltip-user-id={photographer_id}
              href={photographer_url}
              data-overview-tooltip-initiated="true"
              style={{ cursor: 'pointer' }}
            >
              <img
                alt=""
                className="photo-item__avatar"
                height="30"
                width="30"
                data-overview-tooltip-pointer-element="true"
                src="https://images.pexels.com/users/avatars/1051433/denis-perekhrest-261.jpeg?w=60&h=60&fit=crop&crop=faces"
              />
              <span className="photo-item_name">{photographer}</span>
            </a>
          </Card.ImageIcon>
        </div>
        <div className="card__content">
          <Fragment>
            <Card.ImageInfo>
              <div
                className="card__title"
                title={photographer_id}
                onClick={() => handleViewDetailsStockImage(id, data)}
              >
                {photographer.replace(/^\w/, c => c.toUpperCase())}
              </div>
              {/*{moment(created_at).format("DD MMM YYYY")}*/}
            </Card.ImageInfo>
          </Fragment>
        </div>
      </div>
    </Fragment>
  )
}

export default withTranslation('image')(PexelCard)
