import React, { Fragment, useEffect, useState } from 'react'

import ScenarioConfirmationModal from './ScenarioConfirmationModal'
import ScenarioForm from './ScenarioForm'
import ScenarioCreation from './ScenarioCreation'
import { showScenarioModal } from '../../../../../../../../../store/actions/pageBuilder'
import { useDispatch } from 'react-redux'

const defaultViews = {
  showScenarioForm: false,
  showScenarioCreation: false,
  showScenarioConfirmation: false
}
const ScenarioHandler = ({
  onClear,
  scenarioId,
  previewData,
  isShowScenarioModal,
  ...rest
}) => {
  const [views, setViews] = useState({
    ...defaultViews,
    showScenarioConfirmation: true
  })
  const [currentScenarioId, setCurrentScenarioId] = useState(null)
  const [scenarioData, setScenarioData] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShowScenarioModal) {
      setViews({ ...defaultViews, showScenarioCreation: true })
    } else if (scenarioId) {
      setViews({ ...defaultViews, showScenarioConfirmation: true })
    } else {
      setViews({ ...defaultViews, showScenarioForm: true })
    }

    if (scenarioId) {
      setCurrentScenarioId(scenarioId)
    }
  }, [])

  const handleSubmitForm = data => {
    setScenarioData(data)
    setViews({ ...defaultViews, showScenarioCreation: true })
  }

  useEffect(() => {
    if (Object.values(views).every(view => !view)) {
      dispatch(
        showScenarioModal({
          content: null,
          isShowScenarioModal: false
        })
      )
      onClear()
    }
  }, [views])

  console.log({
    scenarioId,
    currentScenarioId
  })

  return (
    <Fragment>
      {views.showScenarioForm && (
        <ScenarioForm
          onCancel={() => setViews(defaultViews)}
          onSubmit={handleSubmitForm}
          previewData={previewData}
        />
      )}

      {views.showScenarioCreation && (
        <ScenarioCreation
          {...rest}
          scenarioId={currentScenarioId}
          setScenarioId={setCurrentScenarioId}
          scenarioData={scenarioData}
          onCancel={() => setViews(defaultViews)}
        />
      )}

      {views.showScenarioConfirmation && (
        <ScenarioConfirmationModal
          onCancel={() => setViews(defaultViews)}
          onCreate={() => {
            setCurrentScenarioId(null)
            setViews({ ...defaultViews, showScenarioForm: true })
          }}
          onEdit={() => {
            setCurrentScenarioId(scenarioId)
            setViews({ ...defaultViews, showScenarioCreation: true })
          }}
        />
      )}
    </Fragment>
  )
}

export default ScenarioHandler
