import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'

import Unity, { UnityContext } from 'react-unity-webgl'
import { config } from '../../../../../../../constants'

import './styles.scss'

const quizUnityContext = new UnityContext({
  loaderUrl: '/QuizBuild/Build.loader.js',
  dataUrl: '/QuizBuild/Build.data',
  frameworkUrl: '/QuizBuild/Build.framework.js',
  codeUrl: '/QuizBuild/Build.wasm'
})

const QuizUnityView = ({ token, quizId, gameId, onClose }) => {
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)

  console.log({ quizId, gameId })

  quizUnityContext.send('DataHolder', 'getBaseUrl', config.baseURL + 'quizes/')
  quizUnityContext.send('DataHolder', 'getTestID', String(quizId))
  quizUnityContext.send('DataHolder', 'getToken', String(token))
  quizUnityContext.send('DataHolder', 'getGameID', Number(gameId))

  useEffect(() => {
    quizUnityContext.on('loaded', () => {
      setLoading(false)
    })
    quizUnityContext.on('progress', progression => {
      setProgress(Number(progression * 100).toFixed())
    })
    quizUnityContext.on('SendResults', val => {
      onClose(JSON.parse(val))
    })

    return () => {
      quizUnityContext.quitUnityInstance()
    }
  }, [])

  if (!quizUnityContext) return null

  return (
    <div className="quiz-unity-container">
      {loading && (
        <div className="quiz-unity-loading-overlay">
          <Progress type="circle" percent={progress} />
          Loading Quiz...
        </div>
      )}

      <Unity
        className="quiz-unity"
        unityContext={quizUnityContext}
        style={{
          height: '100%',
          width: '100%'
        }}
      />
    </div>
  )
}

export default QuizUnityView
