import React, { Component } from 'react'
import { Button } from 'antd'
import { Rnd } from 'react-rnd'

import ReactCrop from 'react-image-crop'
import 'react-image-crop/lib/ReactCrop.scss'
import { customContentClass } from '../ImageCrop/cropImage.ctrl'
import './styles.scss'
import TextOnImage from './TextOnImage'
import { customContentStyle } from '../Builder/Element/Element.ctrl'
import { T } from 'antd/lib/upload/utils'
import { withTranslation } from 'react-i18next'
// import { Editor } from '../ImageEditor'

class EditImageContainer extends Component {
  state = {
    crop: {
      unit: '%', // default, can be 'px' or '%'
      x: 0,
      y: 0,
      aspect: this.props.circle ? 1 / 1 : 16 / 9,
      width: 100
    },
    croppedImageUrl: null,
    showTextModal: false,
    selectedContent: '',
    content: '',
    contentHTML: '',
    textPosition: {
      height: 200,
      width: 250,
      x: 516,
      y: 100
    },
    applyChanges: false
  }

  componentDidUpdate(prevProps, prevState) {
    const { showTextModal, contentHTML } = this.state
    if (!showTextModal && contentHTML) {
      const div = document.getElementById('showtext')

      div.innerHTML = contentHTML
      // div.addEventListener('mouseup', e => {
      //   this.getCursorPosition(div, e)
      // })
      return div
    }
  }

  getCursorPosition(element, event) {
    const rect = element.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    this.setState({
      textPosition: { ...this.state.textPosition, x, y }
    })
  }

  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop)
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      )
      this.setState({ croppedImageUrl })
    }
  }

  getCroppedImg(image, crop, fileName, content, textPosition) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
    if (content && content.length > 0) {
      let font = '8px Arial'
      let color = 'black'
      let x = textPosition.x - 516
      let y = textPosition.y - crop.y
      let newYPixel = 10

      if (content.length === 1) {
        const str = content[0].insert.split('\n')
        // let attributes = []
        // if (content[0].attributes) {
        //   attributes = Object.entries(content[0].attributes)
        // }
        // attributes.length > 0 &&
        //   attributes.map(a => {
        //     if (a[0] === 'size') {
        //       font = `${a[1]} Arial`
        //     } else if (a[0] === 'color') {
        //       color = a[1]
        //     } else {
        //       const newFont = font.replace(/^/, `${a[0]} `)
        //       font = newFont
        //     }
        //   })
        str.forEach(s => {
          ctx.font = font
          ctx.fillStyle = color
          ctx.fillText(s, x, y)
          y = y + 10
          x = textPosition.x - 516
        })
      } else {
        content.forEach(each => {
          let attributes = []
          if (each.attributes) {
            attributes = Object.entries(each.attributes)
          }
          attributes.length > 0 &&
            attributes.forEach(a => {
              if (a[0] === 'size') {
                font = `${a[1]} Arial`
                const px = a[1].split('px')
                newYPixel =
                  parseInt(px[0]) > 15 ? newYPixel + parseInt(px[0]) : newYPixel
              } else if (a[0] === 'color') {
                color = a[1]
              } else {
                const newFont = font.replace(/^/, `${a[0]} `)
                font = newFont
              }
            })

          ctx.font = font
          ctx.fillStyle = color
          ctx.fillText(each.insert, x, y)
          const textWidth = ctx.measureText(each.insert)
          if (each.insert.match(/\n/g)) {
            y = y + newYPixel
            x = textPosition.x - 516
          } else {
            x = x + textWidth.width
          }
        })
      }
    }
    // resolve as base64
    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL('image/jpeg')
      resolve(this.fileUrl)
    })
  }

  handleBtnCropAction = async type => {
    const { file, handleUploadImage, handleClosePopup } = this.props
    // const { crop, selectedContent, applyChanges, textPosition } = this.state
    if (type === 'save') {
      const data = file
      data.croppedImage = this.fileUrl
      handleUploadImage(data)
      this.clearState()
      handleClosePopup(type)
    } else if (type === 'addText') {
      this.handleShowTextModal()
    } else {
      this.clearState()
      handleClosePopup(type)
    }
  }

  clearState = () => {
    this.setState({
      crop: {
        unit: '%', // default, can be 'px' or '%'
        x: 0,
        y: 0,
        aspect: this.props.circle ? 1 / 1 : 16 / 9,
        width: 100
      },
      croppedImageUrl: null,
      showTextModal: false,
      selectedContent: '',
      content: '',
      contentHTML: '',
      textPosition: {
        height: 200,
        width: 250,
        x: 516,
        y: 100
      },
      applyChanges: false
    })
  }

  onCropChange = newCrop => {
    this.setState({
      crop: newCrop,
      textPosition: {
        ...this.state.textPosition,
        y: newCrop.y
      }
    })
  }

  handleShowTextModal = () => {
    this.setState({ showTextModal: !this.state.showTextModal })
  }

  handleUploadChanges = editedFile => {
    console.log('handleUploadChanges', editedFile)
    const { file, handleUploadImage, handleClosePopup } = this.props
    const data = file
    data.croppedImage = editedFile
    handleUploadImage(data)
    this.handleShowTextModal()
    this.clearState()
    handleClosePopup('save')
  }

  setTextContent = (content, delta, source, editor) => {
    const selectedContent = editor.getContents().ops
    // const selectedText = editor.getText()
    const contentHTML = editor.getHTML()

    this.setState({
      content: content,
      // selectedText,
      selectedContent,
      contentHTML
    })
  }

  render() {
    const {
      crop,
      showTextModal,
      contentHTML,
      textPosition: { x, y, width, height },
      croppedImageUrl
    } = this.state
    const { image, node, circle, t } = this.props

    return (
      <div className={customContentClass(node)}>
        <div className="crop-container">
          <ReactCrop
            imageStyle={{
              maxHeight: '65rem'
            }}
            style={{ backgroundColor: 'white' }}
            src={image}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            circularCrop={circle}
            keepSelection
          />
        </div>
        {!showTextModal && contentHTML && (
          <Rnd
            bounds="parent"
            enableUserSelectHack={false}
            enableResizing={{
              bottomRight: true
            }}
            default={{
              x: x,
              y: y,
              width: width,
              height: height
            }}
            maxWidth={crop.width}
            onDragStop={(e, d) => {
              this.setState({
                textPosition: { ...this.state.textPosition, x: d.x, y: d.y }
              })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              this.setState({
                textPosition: {
                  ...this.state.textPosition,
                  width: ref.style.width,
                  height: ref.style.height,
                  ...position
                }
              })
            }}
            style={customContentStyle}
          >
            <div
              className="el"
              onDoubleClick={() => this.handleShowTextModal()}
            >
              <div
                id="showtext"
                style={{ position: 'relative', zIndex: -10 }}
              />
            </div>
          </Rnd>
        )}
        <div className="wrap-btn-actions">
          <Button onClick={() => this.handleBtnCropAction('close')}>
            {t('buttons:cancel')}
          </Button>
          <Button onClick={() => this.handleBtnCropAction('addText')}>
            {t('v3:add_text')}
          </Button>
          <Button onClick={() => this.handleBtnCropAction('save')}>Done</Button>
        </div>
        {showTextModal && (
          <TextOnImage
            cropImage={croppedImageUrl}
            handleSaveChanges={this.handleUploadChanges}
            handleCloseTextModal={this.handleShowTextModal}
          />
        )}
        {/* {showTextModal && (
          <TextOnImage
            handleShowTextModal={this.handleShowTextModal}
            handleTextAdded={this.handleTextAdded}
            content={content}
            setTextContent={this.setTextContent}
            handleCloseTextModal={this.handleCloseTextModal}
          />
        )} */}
      </div>
    )
  }
}

export default withTranslation()(EditImageContainer)
