import React, { useEffect, useState } from 'react'
import { Icon, Modal, Spin } from 'antd'

import beginningImage from '../../img/beginning_of_lesson.svg'
import endImage from '../../img/end_of_lesson.svg'

import './styles.scss'

import ScenarioCreationForm from './ScenarioCreationForm'

import {
  saveScenarioData,
  createScenarioData,
  getScenarioData
} from '../../../../../../../../../services/api/courses'

import actions from '../../../../../../../../../store/actions'
import { useDispatch } from 'react-redux'

import nanoid from 'nanoid'
import _ from 'lodash'

const scenarioOptions = {
  beginning: {
    image: beginningImage,
    description: 'at the beginning'
  },
  end: {
    image: endImage,
    description: 'end of lesson'
  }
}

function ScenarioCreation({
  onCancel,
  scenarioId,
  setScenarioId,
  lessonId,
  courseId,
  pageStructureData,
  pages,
  lessonUpdate,
  scenarioData: scenarioDataFromProps
}) {
  const [selectedOption, setSelectedOption] = useState('beginning')
  const [isSaving, setIsSaving] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [scenarioData, setScenarioData] = useState({})

  const dispatch = useDispatch()

  const { handleLessonInput } = actions.lessonBuilder

  useEffect(() => {
    if (scenarioId) {
      fetchScenarioById(scenarioId)
    } else if (!_.isEmpty(scenarioDataFromProps)) {
      handleInitialScenarioData()
    }
  }, [])

  const handleInitialScenarioData = () => {
    const {
      scenario,
      options,
      correct_answer,
      feedback
    } = scenarioDataFromProps

    const newOptions = parseOptions(options, correct_answer)

    const data = {
      answers: newOptions,
      feedback
    }

    setScenarioData({ ...data, story: scenario })
    handleSubmit({ ...data, scenarioStory: scenario })
  }

  function parseOptions(optionsArray, correctAnswer) {
    const resultArray = []

    optionsArray.forEach((option, index) => {
      const isCorrect = String(correctAnswer) === String(index + 1)
      resultArray.push({
        index: nanoid(),
        answer: option,
        correct: isCorrect,
        rating: isCorrect ? 5 : 1
      })
    })

    return resultArray
  }

  const fetchScenarioById = id => {
    setIsFetching(true)

    getScenarioData(id)
      .then(res => {
        if (res && res.data) {
          const { position, ...data } = res.data
          setScenarioData(data)
          setSelectedOption(position)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleSelection = key => {
    setSelectedOption(key)
  }

  const handleSubmit = (data, type) => {
    const {
      answers,
      scenarioName = '',
      scenarioStory,
      feedback,
      attemptsCount = 0,
      scenarioMediaId = null,
      audioId = null
    } = data

    const payload = {
      answers,
      name: scenarioName,
      story: scenarioStory,
      feedback,
      attempts_count: attemptsCount,
      media_id: scenarioMediaId,
      position: selectedOption,
      story_media_id: audioId,
      lesson_id: lessonId,
      course_id: courseId
    }

    let response = null

    setIsSaving(true)

    if (scenarioId) {
      response = saveScenarioData(scenarioId, payload)
    } else {
      response = createScenarioData(lessonId, payload)
    }

    let newScenarioId = null
    response
      .then(res => {
        newScenarioId = res.data.id
        setScenarioId(newScenarioId)
        dispatch(handleLessonInput('scenarioId', newScenarioId))
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setIsSaving(false)

        if (type === 'insert') {
          handleInsertScenario(newScenarioId)
        } else {
          removeScenario(newScenarioId)
        }
      })
  }

  const removeScenario = id => {
    const newData = [...pageStructureData]
    const index = newData.findIndex(page => {
      for (const element of page.data) {
        if (
          element.type === 'SCENARIO' &&
          element.params &&
          element.params.content !== id
        ) {
          return true
        }
      }

      return false
    })

    if (index === -1) return
    const newPages = [...pages]

    newPages.splice(index, 1)
    newData.splice(index, 1)

    dispatch(handleLessonInput('currentPage', 1))
    dispatch(handleLessonInput('pages_landscape', newData))
    dispatch(handleLessonInput('pages', newPages))
    lessonUpdate({ pages_landScape: newData, pages: newPages })
  }

  const handleInsertScenario = id => {
    const newPageData = {
      size: { width: 1024, height: 768 },
      orientation: 'landscape',
      data: [
        {
          uid: nanoid(8),
          type: 'SCENARIO',
          display: 'CONTENT',
          params: {
            content: id,
            x: 0,
            y: 0,
            width: 1023,
            height: 552
          }
        }
      ]
    }

    const newPage = {
      data: [],
      orientation: 'portrait',
      size: { width: 1024, height: 768 }
    }

    const newData = [...pageStructureData]
    const newPages = [...pages]

    const index = newData.findIndex(page =>
      page.data.some(element => element.type === 'SCENARIO')
    )

    if (index !== -1) {
      newPages.splice(index, 1)
      newData.splice(index, 1)
    }

    switch (selectedOption) {
      case 'beginning':
        newData.unshift(newPageData)
        newPages.unshift(newPage)
        break

      case 'end':
        newData.push(newPageData)
        newPages.push(newPage)
        break

      default:
        break
    }

    dispatch(handleLessonInput('pages_landscape', newData))
    dispatch(handleLessonInput('pages', newPages))
    dispatch(handleLessonInput('currentPage', 1))
    lessonUpdate({ pages_landScape: newData, pages: newPages })
    onCancel()
  }

  console.log({ scenarioData })

  return (
    <Modal
      className="scenario-creation-modal"
      visible
      footer={null}
      closable={false}
      centered
      width="100%"
      style={{ maxWidth: '90vw' }}
    >
      <Spin spinning={isFetching}>
        <div className="scenario-creation">
          <div className="scenario-creation__header">
            <h3>Create Scenario AI</h3>
            <Icon type="close" onClick={onCancel} />
          </div>

          <div className="scenario-creation__body">
            <div className="scenario-creation__options">
              {Object.entries(scenarioOptions).map(([key, values], index) => (
                <div
                  key={key + index}
                  className={`scenario-creation__option ${
                    key === selectedOption ? 'active' : 'none-active'
                  }`}
                  onClick={() => handleSelection(key)}
                >
                  <div className="scenario-creation__option-item">
                    <img src={values.image} alt="" />
                    <span>{values.description}</span>
                  </div>
                </div>
              ))}
            </div>

            <ScenarioCreationForm
              onSubmit={handleSubmit}
              isSaving={isSaving}
              isFetching={isFetching}
              scenarioData={scenarioData}
            />
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export default ScenarioCreation
