import { Icon, Modal, Tooltip } from 'antd'
import React from 'react'
import ReactPlayer from 'react-player'

function MediaPreview(props) {
  const { media, types, onClearMedia, isAudio } = props

  const mediaType = media && media.type

  const isVideo = mediaType === types.video
  const isImage = mediaType === types.image

  const handleRemoveMedia = () => {
    Modal.confirm({
      title: 'Are you sure you want to remove this media?',
      content: 'The media will be removed from the scenario. ',
      onOk() {
        onClearMedia()
      },
      centered: true
    })
  }

  return (
    <div className={`media-preview-container ${isAudio ? 'audio' : ''}`}>
      {isAudio && (
        <audio controls>
          <source src={media.link} type="audio/mpeg" />
        </audio>
      )}

      {isVideo && (
        <ReactPlayer
          className="react-player"
          responsive
          width="100%"
          url={media.link}
          controls
          loop
          playing
        />
      )}

      {isImage && <img src={media.link} alt="" />}

      <Tooltip title="Remove Media">
        <Icon
          className="media-preview__close-btn"
          onClick={handleRemoveMedia}
          type="close"
        />
      </Tooltip>
    </div>
  )
}

export default MediaPreview
