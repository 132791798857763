import React from 'react'
import { Icon, Tooltip } from 'antd'
import './styles.scss'

function Attachment({ data, handleDelete, t }) {
  return (
    <div>
      {/* <h1>{t('v4:attachment_event')}</h1> */}
      <div className="form_list_file">
        {data.map(d => (
          <div key={`file_${d.id}`} className="file_section">
            <div className="file_title">{d.title}</div>
            <span role="button" tabIndex="0" onClick={() => handleDelete(d.id)}>
              <Tooltip placement="top" title={t('v4:delete')}>
                {' '}
                <Icon type="delete" />
              </Tooltip>
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Attachment
