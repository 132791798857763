import Course from './Course'
import Webinar from './Webinar'
import Coaching from './Coaching'
import PublicConference from './PublicConference'
import Maze from './Maze'
import Channel from './Channel'

export default {
  Course,
  Webinar,
  Coaching,
  PublicConference,
  Maze,
  Channel
}
