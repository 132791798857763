/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-27 14:02:20
 * @LastEditors: Please set LastEditors
 */
import {
  HANDLE_LESSON_INPUT,
  CREATE_LESSON,
  FETCH_LESSONS,
  LOAD_LESSONS,
  FETCH_LESSON_BY_ID,
  LOAD_SINGLE_LESSON,
  UPDATE_LESSON,
  RESET_LESSON_BUILDER,
  SET_SAVING_LESSON,
  FETCH_LESSON_REQUEST,
  FETCH_LESSONS_REQUEST,
  BIND_LESSON_TO_COURSE,
  HANDLE_FILE_DROP_IN,
  HANDLE_LESSON_CLONE,
  CLEAR_LESSONS
} from '../types'

import api from '../../services/api'

export const handleLessonInput = (field, payload) => ({
  type: HANDLE_LESSON_INPUT,
  field,
  payload
})

export const handleLessonClone = selectedLesson => {
  return dispatch => {
    dispatch({
      type: HANDLE_LESSON_CLONE,
      selectedLesson
    })
  }
}

export const handleFileDropIn = file => {
  return dispatch => {
    dispatch({
      type: HANDLE_FILE_DROP_IN,
      droppedFile: file
    })
  }
}

export const loadLessons = payload => ({
  type: LOAD_LESSONS,
  payload
})

export const clearLessons = () => ({
  type: CLEAR_LESSONS,
  payload: []
})

export const loadSingleLesson = payload => ({
  type: LOAD_SINGLE_LESSON,
  payload
})

export const resetLessonBuilder = () => ({
  type: RESET_LESSON_BUILDER
})

export const setSavingLesson = payload => ({
  type: SET_SAVING_LESSON,
  payload
})

/**
 Sagas
 */

export const fetchLessons = payload => ({
  type: FETCH_LESSONS,
  payload
})

export const createLesson = (data, id) => ({
  type: CREATE_LESSON,
  data,
  id
})

export const updateLesson = (data, id) => ({
  type: UPDATE_LESSON,
  data,
  id
})

export const fetchLessonById = id => ({
  type: FETCH_LESSON_BY_ID,
  id
})

export const fetchLessonRequest = () => ({
  type: FETCH_LESSON_REQUEST
})

export const fetchLessonsRequest = payload => ({
  type: FETCH_LESSONS_REQUEST,
  payload
})

export const bindLessontoCourse = (courseId, lessonId, data) => dispatch => {
  return api.courses.bindLessonsToCourse(courseId, lessonId, data).then(() => {
    dispatch({
      type: BIND_LESSON_TO_COURSE,
      courseId,
      lessonId,
      data
    })
    return Promise.resolve()
  })
}

export const cloneLessonToCourse = (courseId, lessonId) => dispatch => {
  return api.lessons.cloneLessonToCourse(courseId, lessonId).then(response => {
    dispatch({
      type: BIND_LESSON_TO_COURSE
    })
    return response
  })
}
