import styled from 'styled-components'

const Page = styled.div`
`

const Content = styled.div`
  margin-left: 30rem;
  line-height: 20px;
`

Content.Heading = styled.h1`
  margin-bottom: 1.6rem;
  line-height: 28px;
  color: #333333;
  font-weight: 600;
`

Content.Video = styled.div`
  margin-left: -15rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
`

Content.Description = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #67686E;
`

Content.Label = styled.span`
  margin-right: 20px;
  color: #000;
`

Content.Flex = styled.span`
  display: flex;
  margin-top: 30px;

  button{
    border-radius: 5px;
  }
`

Content.Form = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  max-width: 50%;

  input{
    margin-bottom: 20px;
  }

  input,
  p,
  label{
    color: #333333;
  }

  .required-warning{
    border: 1px solid red;
  }

  .required-warning-text{
    color: red;
    font-size: 0.75em;
  }
  
  .form-footer{
    display: flex;
    justify-content: flex-end;

    button{
      margin-left: 20px;
      border-radius: 5px;
      min-width: 132px;
    }
  }
`

export {
  Page,
  Content
}
