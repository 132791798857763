import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form, Button, Select, Input, Icon, Col } from 'antd'
import { withTranslation } from 'react-i18next'
import { api } from '../../../../../../../../../../services'
import CreatableSelect from 'react-select/creatable'
import { general } from '../../../../../../../../../../constants'
import Spinner from '../../../../../../../../../../components/Spinner'
import './EditDetailAudio.scss'

const { TextArea } = Input

class EditDetailAudio extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topics: [],
      keywords: null,
      fileName: '',
      description: '',
      isLoading: false,
      isFetching: false,
      saveLabel: props.t('buttons:update'),
      disableButton: false,
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      isTopicsMax: false
    }
    this.loadOptions = _.debounce(this.loadOptions, 500)
  }

  componentDidMount() {
    const {
      audioSynthesizer: { contentId }
    } = this.props

    this.setState({
      isFetching: true,
      disableButton: true
    })
    api.library.getMediaInfoById(contentId).then(data => {
      this.setState(
        {
          fileName: data.data.filename || data.data.title,
          keywords: data.data.keywords,
          description: data.data.description,
          disableButton: false
        },
        () => {
          api.topics.getTopicsByIds(data.data.topics.join()).then(topics => {
            const options =
              topics.data &&
              topics.data.map(topic => {
                return {
                  label: topic.title,
                  value: topic.title,
                  id: topic.id
                }
              })

            this.setState({
              value: options,
              disableButton: false,
              isFetching: false
            })
          })
        }
      )
    })
  }

  enableUpdateButton = () => {
    const { t } = this.props
    this.setState({
      saveLabel: t('buttons:update'),
      disableButton: false
    })
  }

  getKeywords = keywords => (keywords && keywords.split(',')) || []

  handleChangeKeyword = value => {
    const keywords = value.join(',')
    this.setState({ keywords })
    this.enableUpdateButton()
  }

  handleChangeDescription = e => {
    const description = e.target.value
    this.setState({ description })
    this.enableUpdateButton()
  }

  handleSubmit = e => {
    e.preventDefault()
    const { src, mediaId, t, onHideDetailModal } = this.props

    this.props.form.validateFields(async err => {
      if (!err) {
        let tempMediaId = ''
        // const data = {
        //   topics: _.map(value, 'id'),
        //   description,
        //   keywords,
        //   isSynthesizedAudio: !isShowDetailEditAudio
        // }
        this.setState({
          isLoading: true,
          saveLabel: t('buttons:updating')
        })
        if (mediaId) {
          tempMediaId = mediaId
        } else if (src !== undefined) {
          const {
            audioSynthesizer: { contentId }
          } = this.props
          tempMediaId = contentId
        } else {
          this.setState({
            isLoading: false,
            saveLabel: t('buttons:update'),
            disableButton: false
          })
          return
        }

        if (tempMediaId) {
          // const update_response = await updateMedia(tempMediaId, data)
          const {
            audioSynthesizer: {
              selected: { selectedText, selectedVoice },
              isShowDetailEditAudio
            }
          } = this.props

          if (!isShowDetailEditAudio) {
            let oldAudioData =
              JSON.parse(localStorage.getItem('audio_data')) || []
            oldAudioData.find(
              v => v.content_id === tempMediaId
            ).text = selectedText
            oldAudioData.find(
              v => v.content_id === tempMediaId
            ).voice_type = selectedVoice
            localStorage.setItem('audio_data', JSON.stringify(oldAudioData))
          }
          this.setState(
            {
              isLoading: false,
              saveLabel: t('buttons:updated'),
              disableButton: true
            },
            () => {
              onHideDetailModal()
            }
          )
        }
      }
    })
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableUpdateButton()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.enableUpdateButton()
        }
      )
    }
    this.isModalSelectOpen()
  }

  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.enableUpdateButton()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    return {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
  }

  loadOptions = newValue => {
    const inputValue = newValue.replace(/\W/g, '')
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue,
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  render() {
    const { t, onHideDetailModal } = this.props
    const {
      isLoading,
      isFetching,
      saveLabel,
      disableButton,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      description,
      keywords,
      fileName
    } = this.state

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }

    const keywordsArr = this.getKeywords(keywords)

    return (
      <Form onSubmit={this.handleSubmit}>
        {isFetching && (
          <div className="upload-form">
            <Spinner />
          </div>
        )}

        {!isFetching && (
          <div className="upload-form">
            <Col span={6}>
              <label>{t('audio:file_name')}*</label>
            </Col>
            <Col span={18}>
              {/*<Input value={fileName || `${src.name}.mp3`} disabled />*/}
              <Input value={fileName} disabled />
            </Col>
            <Col span={6}>
              <label>{t('audio:description')}</label>
            </Col>
            <Col span={18}>
              <div className="upload-form__textarea">
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 6 }}
                  onChange={this.handleChangeDescription}
                  maxLength={500}
                  value={description}
                />
                {/*<label>{t("placeholders:max_500_characters")}</label>*/}
              </div>
            </Col>
            <Col span={6}>
              <label>{t('audio:topic')}</label>
            </Col>
            <Col span={18}>
              <CreatableSelect
                /*styles={customStyles}*/
                components={{
                  ClearIndicator: null,
                  DropdownIndicator: null,
                  LoadingIndicator: this.LoadingIndicator
                }}
                isMulti
                inputId="react-select-2-input"
                isLoading={isTopicLoading}
                menuIsOpen={this.isModalSelectOpen()}
                onBlur={() => this.setState({ inputValue: '' })}
                onChange={this.handleChange}
                onCreateOption={this.handleCreate}
                onInputChange={this.loadOptions}
                options={options}
                value={value}
              />

              {isTopicsMax && (
                <div className="error_message">{t('errors:topics_max')}</div>
              )}
            </Col>
            <Col span={6}>
              <label>{t('audio:key_words_tag')}</label>
            </Col>
            <Col span={18}>
              {keywordsArr.length > 0 && (
                <Select
                  mode="tags"
                  onChange={this.handleChangeKeyword}
                  style={{ width: '100%' }}
                  className="custom-select-tag"
                  dropdownClassName="disable-select-dropdown"
                  defaultValue={keywordsArr}
                  tokenSeparators={[',', '.', ';']}
                />
              )}

              {keywordsArr.length === 0 && (
                <Select
                  mode="tags"
                  onChange={this.handleChangeKeyword}
                  style={{ width: '100%' }}
                  className="custom-select-tag"
                  dropdownClassName="disable-select-dropdown"
                  defaultValue={[]}
                  tokenSeparators={[',', '.', ';']}
                />
              )}
            </Col>

            <Col span={24}>
              <hr />
            </Col>

            <div className="btn-group">
              <Button
                type="default"
                shape="round"
                size="default"
                onClick={onHideDetailModal}
              >
                {general.CANCEL}
              </Button>

              <Button
                type="primary"
                shape="round"
                size="default"
                htmlType="submit"
                disabled={disableButton}
                loading={isLoading}
              >
                {saveLabel}
              </Button>
            </div>
          </div>
        )}
      </Form>
    )
  }
}

EditDetailAudio.defaultProps = {
  mediaId: 0,
  fileName: ''
}

EditDetailAudio.propTypes = {
  mediaId: PropTypes.number,
  fileName: PropTypes.string,
  handleFileUpload: PropTypes.func
}

export default Form.create()(withTranslation()(EditDetailAudio))
