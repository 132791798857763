import styled, { css } from 'styled-components'

import { styleguide } from '../../constants'

const { colors } = styleguide

export const Progress = styled.div`
  position: relative;
  background-color: #FFFFFF;
  width: 100%;
  height: 0.5rem;
`

Progress.Percent = styled.div`
  background-color: ${colors.green};
  height: 0.5rem;

  ${props => props.percent && css`
    width: ${props.percent}%;
  `}
`

Progress.Finish = styled.span`
  position: absolute;
  top: -0.5rem;
  right: -0.1rem;
  background-color: ${colors.green};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;

  .progress-bar-icon {
    color: ${colors.white};
    line-height: 1.5rem;
    font-size: 1rem;
    position: relative;
    top: -0.4rem;
    right: -0.2rem;
  }
`
