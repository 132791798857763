import React, { Component } from 'react'
import hark from 'hark'
import _ from 'lodash'
import axios from 'axios'
import { Prompt } from 'react-router-dom'
import { WebRTCAdaptor } from './webrtcAdaptor_new'
import { WebRTCAdaptorPlay } from './webrtcAdaptor_new_play'
import { Modal, Row, Tooltip, message, Button } from 'antd'
import debounce from 'lodash/debounce'
import '../../../../../components/Card/Card.scss'
import { NetworkDetection } from '../NetworkDetection'
import cameraOff from '../../assets/camera_off.svg'
import cameraOn from '../../assets/camera_on.svg'
import micOff from '../../assets/mic_off.svg'
import micOn from '../../assets/mic_on.svg'
import mute from '../../assets/mute.svg'
import unmute from '../../assets/unmute.svg'
import join from '../../assets/join.svg'
import leave from '../../assets/leave.svg'
import addParticipant from '../../assets/add_participant.png'
import fullScreen from '../../assets/full_screen.svg'
import shareScreenLogo from '../../assets/share_screen.svg'
import stopShareScreenLogo from '../../assets/stop_screen_share.svg'
import { api } from '../../../../../services'
import { Card } from '../../../../../components/Card/styled'
import { config } from '../../../../../constants'
import { AddParticipant } from './internal'

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

const pcConfig = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302'
    }
  ]
}

let sdpConstraints = {
  OfferToReceiveAudio: false,
  OfferToReceiveVideo: false
}
// 240p means width: 426, height: 240
let mediaConstraints = {
  video: {
    width: 426,
    height: 245,
    frameRate: 15
  },
  audio: true
}

let passiveMediaConstraints = {
  video: false,
  audio: true
}

let webRTCAdaptor = null
let playWebRTCAdaptor = null
let closeConnectionMsg = ''
// let streamIdInUseMsg = ''
let playOnly = false
let prompt = null
let volumeButton = null
let speech = null
let video = null
const token = 'null'
class Conference extends Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.fetchUserEmails = debounce(this.fetchUserEmails, 1000)
  }

  initSocketConf = null
  SOCKET_URL_PUBLISH = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com/WebRTCAppEE/websocket?rtmpForward=undefined`
  SOCKET_URL_PLAY = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com:5443/WebRTCAppEE/websocket?rtmpForward=undefined`
  connectionInterval = null
  state = {
    showVideo: false,
    myStreamId: this.props.userId + '_' + this.props.eventId,
    streamsList: [],
    speaker: '',
    isJoined: false,
    isCameraOff: false,
    isMicOff: false,
    isShareScreen: false,
    disableScreenSharing: '',
    hasLeave: false,
    canShowModal: false,
    showPrompt: false,
    isPublishStarted: false,
    hideElement: false,
    showParticipant: false,
    emailList: [],
    fetchingEmail: false,
    emails: [],
    initialEmails: [],
    inviting: false,
    failInvitation: false,
    successInvitation: false,
    callFirstTime: true
  }

  componentDidMount() {
    const { hostEmails } = this.props
    this.setState({ initialEmails: hostEmails, emails: hostEmails })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      speaker,
      myStreamId,
      streamsList,
      isJoined,
      isShareScreen
    } = this.state
    const { streamCount, isConferenceHost, isDisconnected } = this.props
    if (prevState.speaker !== speaker && speaker) {
      if (speaker === myStreamId) {
        console.log('speaker === myStreamId if')
        let localVideo = document.getElementById('localVideo')
        localVideo.className = 'active'
        let remoteVideo = document.getElementById(
          'remoteVideo' + prevState.speaker
        )
        let remoteController = document.getElementById(
          'controller' + prevState.speaker
        )
        let remotePlayer = document.getElementById('player' + prevState.speaker)
        let remoteUser = document.getElementById('username' + prevState.speaker)

        if (remoteVideo) {
          remoteVideo.className = 'myClass'
          remotePlayer.classList.add('remotePlayerClass')
          remoteController.classList.remove('control_active')
          remoteController.classList.add('volume-controls-user')
          remoteUser.classList.remove('username__active')
          remoteUser.classList.add('username__inactive')
        }
      } else {
        console.log('speaker === myStreamId else streamsList:', streamsList)
        let localVideo = document.getElementById('localVideo')
        if (localVideo) {
          localVideo.className = ''
        }
        if (streamsList.length > 0) {
          streamsList.forEach(item => {
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)

            if (remoteVideo) {
              if (item === speaker) {
                remoteVideo.className = 'active'
                remoteVideo.classList.remove('myClass')
                remotePlayer.classList.remove('remotePlayerClass')
                remoteController.classList.remove('volume-controls-user')
                remoteController.classList.add('control_active')
                remoteUser.classList.add('username__active')
                remoteUser.classList.remove('username__inactive')
              } else {
                remoteVideo.className = 'myClass'
                remotePlayer.classList.add('remotePlayerClass')
                remoteController.classList.add('volume-controls-user')
                remoteController.classList.remove('control_active')
                remoteUser.classList.remove('username__active')
                remoteUser.classList.add('username__inactive')
              }
            }
          })
        }
      }
    }

    if (
      (prevState.streamsList !== streamsList ||
        prevState.speaker !== speaker) &&
      isJoined &&
      !isConferenceHost
    ) {
      if (streamsList.length > 0) {
        streamsList.forEach(item => {
          if (speaker === '') {
            this.setState(
              {
                speaker: streamsList[0]
              },
              () => {
                let remoteVideo = document.getElementById(
                  'remoteVideo' + streamsList[0]
                )
                let remoteController = document.getElementById(
                  'controller' + streamsList[0]
                )
                let remotePlayer = document.getElementById(
                  'player' + streamsList[0]
                )
                let remoteUser = document.getElementById(
                  'username' + streamsList[0]
                )
                if (remoteVideo) {
                  remoteVideo.className = 'active'
                  remoteVideo.classList.remove('myClass')
                  remotePlayer.classList.remove('remotePlayerClass')
                  remoteController.classList.remove('volume-controls-user')
                  remoteController.classList.add('control_active')
                  remoteUser.classList.add('username__active')
                  remoteUser.classList.remove('username__inactive')
                }
              }
            )
          }
          if (item === speaker) {
            console.log('item === speaker if')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              console.log('item === speaker if remoteVideo', remoteVideo)
              remoteVideo.className = 'active'
              remoteVideo.classList.remove('myClass')
              remotePlayer.classList.remove('remotePlayerClass')
              remoteController.classList.remove('volume-controls-user')
              remoteController.classList.add('control_active')
              remoteUser.classList.add('username__active')
              remoteUser.classList.remove('username__inactive')
            }
          } else {
            console.log('item === speaker else')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteController = document.getElementById('controller' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              remoteVideo.className = 'myClass'
              remotePlayer.classList.add('remotePlayerClass')
              remoteController.classList.add('volume-controls-user')
              remoteController.classList.remove('control_active')
              remoteUser.classList.remove('username__active')
              remoteUser.classList.add('username__inactive')
            }
          }
        })
      }
    }

    if (prevState.streamsList !== streamsList && isJoined) {
      isConferenceHost
        ? streamCount(streamsList.length === 0 ? 1 : streamsList.length + 1)
        : streamCount(streamsList.length === 0 ? 1 : streamsList.length)

      if (
        isConferenceHost &&
        isShareScreen &&
        webRTCAdaptor &&
        !isDisconnected
      ) {
        webRTCAdaptor.sendData(myStreamId, 'SCREEN')
      }
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount log')
    const { showVideo, myStreamId } = this.state

    if (this.connectionInterval !== null) {
      clearInterval(this.connectionInterval)
      this.connectionInterval = null
    }

    if (prompt && showVideo) {
      prompt = null
    }

    this.initSocketConf && this.initSocketConf.close()

    if (playWebRTCAdaptor && playWebRTCAdaptor.roomTimerId !== -1) {
      console.log(
        'componentWillUnmount playWebRTCAdaptor.roomTimerId',
        playWebRTCAdaptor.roomTimerId
      )
      clearInterval(playWebRTCAdaptor.roomTimerId)
      playWebRTCAdaptor.roomTimerId = -1
    }

    if (playWebRTCAdaptor) {
      playWebRTCAdaptor.stop(myStreamId)
      playWebRTCAdaptor.closePeerConnection(myStreamId)
      playWebRTCAdaptor.closeWebSocket()
    }
  }

  turnOffLocalCamera = () => {
    this.setState({ isCameraOff: true }, () => {
      webRTCAdaptor.turnOffLocalCamera()
    })
  }

  turnOnLocalCamera = () => {
    this.setState({ isCameraOff: false }, () => {
      webRTCAdaptor.turnOnLocalCamera()
    })
  }

  muteLocalMic = () => {
    this.setState({ isMicOff: true }, () => {
      const { myStreamId } = this.state
      webRTCAdaptor.muteLocalMic()
      webRTCAdaptor.sendData(myStreamId, 'MUTED')
    })
  }

  unmuteLocalMic = () => {
    this.setState({ isMicOff: false }, () => {
      const { myStreamId } = this.state
      webRTCAdaptor.unmuteLocalMic()
      webRTCAdaptor.sendData(myStreamId, 'UNMUTED')
    })
  }

  fullScreenMode = () => {
    const { speaker } = this.state
    let elem = document.getElementById('remoteVideo' + speaker)
    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem && elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem && elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen()
    } else if (elem && elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  screenShare = () => {
    const { isShareScreen, myStreamId } = this.state
    if (!isShareScreen) {
      this.setState(
        {
          isShareScreen: true,
          speaker: myStreamId
        },
        () => {
          webRTCAdaptor.switchDesktopCapture(myStreamId)
          if (webRTCAdaptor) {
            webRTCAdaptor.sendData(myStreamId, 'SCREEN')
          }
        }
      )
    } else {
      this.setState(
        {
          isShareScreen: false
        },
        () => {
          webRTCAdaptor.switchVideoCameraCapture(myStreamId)
          if (webRTCAdaptor) {
            webRTCAdaptor.sendData(myStreamId, 'CAMERA')
          }
        }
      )
    }
  }

  publish = (streamName, token) => {
    webRTCAdaptor.publish(streamName, token)
  }

  playVideo = obj => {
    const { conferenceId } = this.props
    let video = document.getElementById('remoteVideo' + obj.streamId)
    console.log(
      'new stream available with id: ' +
        obj.streamId +
        'on the room:' +
        conferenceId,
      'video',
      video
    )
    if (video === null) {
      console.log('playVideo video null if')
      this.createRemoteVideo(obj)
    } else {
      console.log('playVideo video null else')
      video.srcObject = obj.stream
    }
  }

  playAudio = obj => {
    let audio = document.getElementById('audio' + obj.streamId)
    if (audio === null) {
      console.log('audio null if')
      this.createRemoteAudio(obj)
    } else {
      console.log('audio null else')
      audio.srcObject = obj.stream
    }
  }

  createRemoteAudio = obj => {
    let audioPlayer = document.createElement('audio')
    audioPlayer.id = 'audio' + obj.streamId
    audioPlayer.className = 'passive_hidden'
    audioPlayer.autoplay = true
    audioPlayer.srcObject = obj.stream
    if (audioPlayer) {
      let audioplayerElement = document.getElementById('audio_players')
      if (audioplayerElement) {
        audioplayerElement.appendChild(audioPlayer)
      }
    }
  }

  createRemoteVideo = obj => {
    let player = document.createElement('div')
    player.className = 'remotePlayerClass'
    player.id = 'player' + obj.streamId
    player.innerHTML =
      '<video id="remoteVideo' +
      obj.streamId +
      '" class="myClass" autoplay></video>' +
      '<div class="volume-controls-user" id="controller' +
      obj.streamId +
      '">' +
      '<button class= "volume_button" id="button' +
      obj.streamId +
      '" >' +
      '<img src=' +
      unmute +
      ' alt="micOn" id="icon' +
      obj.streamId +
      '" />' +
      '</button>' +
      '<span id="username' +
      obj.streamId +
      '" class="username__inactive"></span>' +
      '</div>'

    if (player) {
      console.log('createRemoteVideo if', player)
      let playerElement = document.getElementById('players')
      if (playerElement) {
        console.log('playerElement if', playerElement)
        playerElement.appendChild(player)
        video = document.getElementById('remoteVideo' + obj.streamId)
        volumeButton = document.getElementById('button' + obj.streamId)
        volumeButton.addEventListener('click', e =>
          this.toggleMute(e, obj.streamId)
        )
        if (video) {
          console.log('createRemoteVideo if video', video)
          video.srcObject = obj.stream
        }
      }
    }
  }

  removeRemoteVideo = streamId => {
    let video = document.getElementById('remoteVideo' + streamId)
    if (video !== null) {
      let player = document.getElementById('player' + streamId)
      video.srcObject = null
      document.getElementById('players').removeChild(player)
    }
  }

  removeRemoteAudio = streamId => {
    let audio = document.getElementById('audio' + streamId)
    if (audio !== null) {
      video.srcObject = null
      document.getElementById('audio_players').removeChild(audio)
    }
  }

  toggleMute = (e, streamId) => {
    e.preventDefault()
    const { isConfCreator } = this.props
    if (isConfCreator) {
      let targetVideo = document.getElementById('remoteVideo' + streamId)
      let targetIcon = document.getElementById('icon' + streamId)
      if (!targetVideo.muted) {
        targetIcon.src = mute
        targetVideo.muted = true
        playWebRTCAdaptor.sendData(streamId, 'MUTED')
      }
    }
  }

  deleteMyStream = param => {
    const { myStreamId } = this.state
    const { conferenceId, eventId } = this.props
    console.log('param from', param)
    axios({
      method: 'put',
      url: `https://antmedia-${eventId}.v2.sharelookapp.com/WebRTCAppEE/rest/v2/broadcasts/conference-rooms/${conferenceId}/delete?streamId=${myStreamId}`,
      data: {}
    }).then(
      response => {
        console.log('response from 1st api', response)
        if (response.status === 200) {
          console.log('response from 1st api status 200')
          axios({
            method: 'delete',
            url: `https://antmedia-${eventId}.v2.sharelookapp.com/WebRTCAppEE/rest/v2/broadcasts/${myStreamId}`,
            data: {}
          }).then(
            response => {
              console.log('inner response from 2nd api', response)
              if (response.status === 200) {
                console.log('inner response from 2nd api status 200')
                if (param === 'streamIdInUse') {
                  webRTCAdaptor.joinRoom(conferenceId, myStreamId)
                } else {
                  this.reConnectSocket()
                }
              }
            },
            error => {
              console.log('error delete stream inner', error)
            }
          )
        }
      },
      error => {
        console.log('error delete stream outer', error)
      }
    )
  }

  reConnectSocket = () => {
    const {
      isConferenceHost,
      userId,
      eventId,
      isSocketClose,
      initSocket
    } = this.props
    playOnly = !isConferenceHost
    console.log('reconnecting isSocketClose', isSocketClose)
    console.log('reConnectSocket webRTCAdaptor', webRTCAdaptor)
    console.log('reConnectSocket playWebRTCAdaptor', playWebRTCAdaptor)
    if (isSocketClose) {
      initSocket(userId)
    }
    this.setState(
      {
        myStreamId: userId + '_' + eventId,
        streamsList: [],
        showVideo: true,
        isJoined: true,
        hasLeave: false,
        isShareScreen: false,
        isCameraOff: false,
        isMicOff: false,
        disableScreenSharing: '',
        isPublishStarted: false,
        showPrompt: true
      },
      () => {
        const { handleBroadcastChange } = this.props
        const { hideElement, myStreamId } = this.state
        if (!hideElement) {
          handleBroadcastChange()
        }
        this.setState(
          {
            speaker: isConferenceHost ? myStreamId : ''
          },
          () => {
            console.log('reConnectSocket isConferenceHost', isConferenceHost)
            if (isConferenceHost) {
              console.log('if reConnectSocket')
              this.initService()
            } else {
              console.log('else reConnectSocket')
              this.initPlayService()
            }
          }
        )
      }
    )
  }

  handleStartApi = () => {
    const { eventId } = this.props
    api.events
      .startLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in starting event', err)
      })
  }

  initializeSocket = () => {
    const {
      eventId,
      stopstream,
      userId,
      orgId,
      onStreamStopped,
      isConfCreator
    } = this.props
    this.initSocketConf = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )
    this.initSocketConf.onopen = () => {
      if (isConfCreator) {
        this.initSocketConf.send(
          JSON.stringify({
            action: 'startConference',
            stream_id: `event_${eventId}`,
            channel_type: 'EVENT',
            channel_id: eventId
          })
        )
      }

      this.initSocketConf.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: orgId
        })
      )
      this.connectionInterval = setInterval(() => {
        this.initSocketConf.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 10000)
    }

    this.initSocketConf.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log('event log endConference', event, 'data', data)
      if (data.event === 'endConference') {
        const {
          isConferenceHost,
          handleBroadcastChange,
          streamCount,
          fineConnectionState
        } = this.props
        const { myStreamId } = this.state
        if (webRTCAdaptor) {
          webRTCAdaptor.stop(myStreamId)
          webRTCAdaptor.closePeerConnection(myStreamId)
          webRTCAdaptor.closeWebSocket()
          webRTCAdaptor.closeStream()
        }
        if (playWebRTCAdaptor) {
          playWebRTCAdaptor.stop(myStreamId)
          playWebRTCAdaptor.closePeerConnection(myStreamId)
          playWebRTCAdaptor.closeWebSocket()
        }
        this.setState(
          {
            showVideo: false,
            isJoined: false,
            myStreamId: '',
            showPrompt: false,
            speaker: '',
            streamsList: []
          },
          () => {
            if (isConferenceHost) {
              fineConnectionState()
              stopstream()
              onStreamStopped()
              /* const { hasLeave } = this.state
              if (!hasLeave) {
                handleBroadcastChange()
                streamCount('')
              } */
              prompt = null
            }
            if (!isConferenceHost) {
              const { hasLeave } = this.state
              stopstream()
              onStreamStopped()
              if (!hasLeave) {
                handleBroadcastChange()
                streamCount('')
              }
            }
            this.setState({ hasLeave: true })
            if (this.connectionInterval !== null) {
              clearInterval(this.connectionInterval)
              this.connectionInterval = null
            }
          }
        )
      }
    }

    this.initSocketConf.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(this.connectionInterval)
    }

    this.initSocketConf.onerror = error => {
      console.log('Error ' + error.message)
      clearInterval(this.connectionInterval)
    }
  }

  stopConference = () => {
    console.log('stopConference')
    const { eventId, orgId, userId, isConfCreator } = this.props
    if (isConfCreator) {
      this.initSocketConf.send(
        JSON.stringify({
          action: 'stopConference',
          stream_id: `event_${eventId}`,
          channel_type: 'EVENT',
          channel_id: eventId
        })
      )
    }

    this.initSocketConf.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: orgId
      })
    )
  }

  endConferenceEvent = () => {
    const { eventId, conferenceId } = this.props

    this.initSocketConf.send(
      JSON.stringify({
        action: 'endConference',
        stream_id: conferenceId,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }

  handleStopApi = () => {
    const { eventId } = this.props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }

  leave = () => {
    // streamIdInUseMsg = ''
    this.setState(
      {
        canShowModal: false,
        hasLeave: true,
        hideElement: false,
        isPublishStarted: false,
        callFirstTime: true
      },
      () => {
        const { conferenceId, isConfCreator } = this.props
        const { streamsList } = this.state
        if (isConfCreator && _.isEmpty(streamsList)) {
          this.handleStopApi()
        }
        if (webRTCAdaptor) {
          webRTCAdaptor.leaveFromRoom(conferenceId)
        }
        if (playWebRTCAdaptor) {
          playWebRTCAdaptor.leaveFromRoom(conferenceId)
        }
      }
    )
  }

  toggleModal = () => {
    this.setState(state => ({ canShowModal: !state.canShowModal }))
  }

  showAddparticipant = () => {
    this.setState(state => ({
      showParticipant: !state.showParticipant,
      successInvitation: false
    }))
  }

  sendInvitations = () => {
    const { event, eventId } = this.props
    const { emails, initialEmails } = this.state
    const newAdded = _.difference(emails, initialEmails)

    const data = {
      title: event.title,
      add_participant: newAdded,
      cover_id: event.cover_id
    }

    this.setState({ inviting: true }, () => {
      api.events
        .updateById(eventId, data)
        .then(response => {
          console.log('success update', response)
          const { data: respData } = response
          this.setState(
            {
              inviting: false,
              successInvitation: true,
              emails: respData.host_emails,
              initialEmails: respData.host_emails
            },
            () => {
              this.showAddparticipant()
            }
          )
        })
        .catch(error => {
          console.log('error invitation', error)
          this.setState(
            {
              inviting: false,
              failInvitation: true,
              emails
            },
            () => {
              setTimeout(() => {
                this.setState({ failInvitation: false })
              }, 3000)
            }
          )
          // message.error(error)
        })
    })
  }

  fetchUserEmails = value => {
    const { userOrg } = this.props
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ emailList: [], fetchingEmail: true })
    api.organizations.getUsersByOrganizationId(userOrg).then(res => {
      if (fetchId !== this.lastFetchId) {
        return
      }
      res.data.map(val => {
        if (val.user) {
          const dataValue = {
            text: val.user.email,
            value: val.user.email
          }
          this.setState(state => {
            if (dataValue.value.includes(value)) {
              const emailList = [...state.emailList, dataValue]
              return {
                emailList,
                fetchingEmail: false
              }
            }
          })
        }
        return null
      })
    })
  }

  handleEmailChange = selectedValue => {
    this.setState({
      emailList: [],
      emails: selectedValue,
      fetchingEmail: false
    })
  }

  initService = () => {
    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.SOCKET_URL_PUBLISH,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      isPlayMode: playOnly,
      debug: true,
      bandwidth: 200,
      callback: (info, obj) => {
        console.log('publish command', info, obj)
        const {
          conferenceId,
          handleBroadcastChange,
          isConferenceHost,
          streamCount,
          isConfCreator
        } = this.props
        const { myStreamId, streamsList, isJoined, callFirstTime } = this.state
        if (info === 'initialized') {
          console.log('publish initialized', obj)
          console.log('publish initialized conferenceId', conferenceId)
          webRTCAdaptor.joinRoom(conferenceId, myStreamId)
          if (callFirstTime) {
            this.setState(
              {
                callFirstTime: false
              },
              () => {
                console.log('log callFirstTime state', callFirstTime)
                this.initPlayService()
                this.initializeSocket()
              }
            )
          }
        } else if (info === 'start') {
          console.log('publish start ', obj)
        } else if (info === 'joinedTheRoom') {
          const { calculateStopTime, calculateWarnTime } = this.props
          // const room = obj.ATTR_ROOM_NAME
          closeConnectionMsg = ''
          console.log('publish joinedTheRoom', obj)
          this.publish(myStreamId, token)
          calculateStopTime()
          calculateWarnTime()
          this.handleStartApi()
          // webRTCAdaptor.getRoomInfo(room, obj.streamId)
        } else if (info === 'roomInformation') {
          console.log('publish roomInformation', obj)
        } else if (info === 'newStreamAvailable') {
          console.log('publish newStreamAvailable')
          // this.playVideo(obj)
        } else if (info === 'publish_started') {
          console.log('publish publish_started', obj, !!webRTCAdaptor, playOnly)
          const { fineConnectionState } = this.props
          fineConnectionState()
          // streamIdInUseMsg = ''
          this.setState(
            {
              isPublishStarted: true,
              hideElement: false
            },
            () => {
              // webRTCAdaptor.enableStats(myStreamId)
              if (webRTCAdaptor) {
                speech = hark(webRTCAdaptor.localStream, {})
              }
              if (isJoined && !playOnly) {
                speech.on('speaking', () => {
                  const { isShareScreen, disableScreenSharing } = this.state
                  const { isDisconnected } = this.props
                  if (!isDisconnected) {
                    const { myStreamId } = this.state
                    const { name } = this.props
                    if (webRTCAdaptor) {
                      webRTCAdaptor.sendData(myStreamId, `USERNAME_${name}`)
                    }
                    if (disableScreenSharing !== '') {
                      this.setState({ speaker: disableScreenSharing })
                    } else if (isShareScreen) {
                      this.setState({ speaker: myStreamId })
                    } else {
                      this.setState({ speaker: myStreamId }, () => {
                        if (webRTCAdaptor) {
                          webRTCAdaptor.sendData(myStreamId, 'SPEAKING')
                        }
                      })
                    }
                  }
                })
              }
            }
          )
        } else if (info === 'publish_finished') {
          const { hasLeave, myStreamId } = this.state
          console.log('publish finished', 'myStreamId', myStreamId)
          if (!hasLeave) {
            console.log('publish finished if')
            this.props.badConnectionState()
            webRTCAdaptor.joinRoom(conferenceId, myStreamId)
            // this.publish(myStreamId, token)
          }
        } else if (info === 'leavedFromRoom') {
          prompt = false
          console.debug('publish leavedFromRoom' + obj)
          this.initSocketConf && this.initSocketConf.close()
          if (this.connectionInterval !== null) {
            console.log(
              'interval clear connectionInterval leavedFromRoom',
              this.connectionInterval
            )
            clearInterval(this.connectionInterval)
            this.connectionInterval = null
          }
          this.setState(
            {
              showVideo: false,
              isJoined: false,
              speaker: '',
              myStreamId: '',
              showPrompt: false
            },
            () => {
              const { hasLeave } = this.state
              if (isConferenceHost) {
                if (isConfCreator && _.isEmpty(streamsList)) {
                  this.endConferenceEvent()
                }
                if (speech) {
                  speech.stop()
                }
                this.stopConference()
              }
              if (hasLeave) {
                handleBroadcastChange()
                streamCount('')
              }
              if (webRTCAdaptor) {
                webRTCAdaptor.stop(myStreamId)
                webRTCAdaptor.closePeerConnection(myStreamId)
                webRTCAdaptor.closeWebSocket()
              }
              if (playWebRTCAdaptor) {
                playWebRTCAdaptor.stop(myStreamId)
                playWebRTCAdaptor.closePeerConnection(myStreamId)
                playWebRTCAdaptor.closeWebSocket()
                webRTCAdaptor.closeStream()
              }
              this.setState({ streamsList: [] })
            }
          )
        } else if (info === 'closed') {
          console.log('publish Connection closed')
          console.log('publish Connection webRTCAdaptor', webRTCAdaptor)
          console.log('publish Connection playWebRTCAdaptor', playWebRTCAdaptor)
          const { hasLeave } = this.state
          const { isDisconnected } = this.props
          if (typeof obj !== 'undefined') {
            console.log('publish Connection closed: ' + JSON.stringify(obj))
            if (speech) {
              speech.stop()
            }
            if (!hasLeave) {
              if (webRTCAdaptor) {
                webRTCAdaptor.closeStream()
              }
              closeConnectionMsg =
                'Server closed unexpectedly. Please rejoin conference again.'
              if (isDisconnected) {
                this.initSocketConf && this.initSocketConf.close()
                if (this.connectionInterval !== null) {
                  console.log(
                    'interval clear connectionInterval leavedFromRoom',
                    this.connectionInterval
                  )
                  clearInterval(this.connectionInterval)
                  this.connectionInterval = null
                }
                this.setState(
                  {
                    streamsList: [],
                    showVideo: false,
                    isJoined: false,
                    speaker: '',
                    myStreamId: '',
                    showPrompt: false,
                    callFirstTime: true
                  },
                  () => {
                    handleBroadcastChange()
                    streamCount('')
                    if (playWebRTCAdaptor) {
                      console.log(
                        'if playWebRTCAdaptor closed',
                        playWebRTCAdaptor
                      )
                      playWebRTCAdaptor.stop(myStreamId)
                      playWebRTCAdaptor.closePeerConnection(myStreamId)
                      playWebRTCAdaptor.closeWebSocket()
                    }
                    setTimeout(() => {
                      closeConnectionMsg = ''
                    }, 2000)
                  }
                )
              } else {
                closeConnectionMsg =
                  'Server closed unexpectedly. Reconnecting again.'
                this.setState(
                  {
                    hideElement: true
                  },
                  () => {
                    this.reConnectSocket()
                  }
                )
              }
            }
          }
        } else if (info === 'takeConfiguration') {
          console.log('publish takeConfiguration')
        } else if (info === 'server_will_stop') {
          console.log('publish server_will_stop')
        } else if (info === 'data_channel_opened') {
          console.log('publish Data Channel open for stream id', obj)
        } else if (info === 'data_channel_closed') {
          console.log('publish Data Channel closed', obj)
        } else if (info === 'updated_stats') {
          // console.log('updated_stats audioLevel', obj.audioLevel)
          // console.log('updated_stats streamId', obj.streamId)
        } else if (info === 'ice_connection_state_changed') {
          console.log('iceConnectionState Changed: ', JSON.stringify(obj))
          const iceState = webRTCAdaptor.signallingState(myStreamId)

          console.log('I am in checkAndRepublishIfRequired')
          console.log('I am in checkAndRepublishIfRequired iceState', iceState)
          if (
            (obj.state === null ||
              obj.state === 'failed' ||
              obj.state === 'disconnected' ||
              iceState === null ||
              iceState === 'failed' ||
              iceState === 'disconnected') &&
            isConferenceHost
          ) {
            const { myStreamId } = this.state
            this.setState(
              {
                speaker: myStreamId
              },
              () => {
                console.log(
                  'obj.state ice connection state ',
                  obj.state,
                  'ice myStreamId',
                  myStreamId
                )
                console.log('if checkAndRepublishIfRequired')
                webRTCAdaptor.joinRoom(conferenceId, myStreamId)
              }
            )
          }
        } else if (info === 'screen_share_stopped') {
          console.log('publish screen_share_stopped', obj)
          this.setState(
            {
              disableScreenSharing: '',
              isShareScreen: false
            },
            () => {
              if (webRTCAdaptor) {
                webRTCAdaptor.sendData(myStreamId, 'CAMERA')
              }
            }
          )
        } else if (info === 'data_received') {
          if (obj.event.data === 'MUTED') {
            if (obj.streamId === myStreamId) {
              this.setState({ isMicOff: true }, () => {
                webRTCAdaptor.muteLocalMic()
              })
            }
          }

          if (obj.event.data === 'UNMUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = false
                targetIcon.src = unmute
              }
            }
          }
        }
      },
      callbackError: (error, message) => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        const { myStreamId } = this.state
        const { t } = this.props
        console.log('publish log error ', error.isTrusted)
        console.log('publish log message ', message)
        console.log('publish error callback: ' + JSON.stringify(error), message)
        let errorMessage = JSON.stringify(error)
        if (error.isTrusted) {
          console.log('inside  error.isTrusted publish', error.isTrusted)
          return
        }
        if (typeof message !== 'undefined') {
          errorMessage = message
        }
        if (error.indexOf('NotFoundError') !== -1) {
          errorMessage =
            'Camera or Mic are not found or not allowed in your device.'
        } else if (
          error.indexOf('NotReadableError') !== -1 ||
          error.indexOf('TrackStartError') !== -1
        ) {
          errorMessage =
            'Camera or Mic is being used by some other process that does not not allow these devices to be read.'
        } else if (
          error.indexOf('OverconstrainedError') !== -1 ||
          error.indexOf('ConstraintNotSatisfiedError') !== -1
        ) {
          errorMessage =
            'There is no device found that fits your video and audio constraints. You may change video and audio constraints.'
        } else if (
          error.indexOf('NotAllowedError') !== -1 ||
          error.indexOf('PermissionDeniedError') !== -1
        ) {
          errorMessage = 'You are not allowed to access camera and mic.'
        } else if (error.indexOf('TypeError') !== -1) {
          errorMessage = 'Video/Audio is required.'
        } else if (error.indexOf('publishTimeoutError') !== -1) {
          const { myStreamId } = this.state
          console.log('publishTimeOutError Inside', myStreamId)
          errorMessage = 'publish timeout error'
          this.publish(myStreamId, token)
        } else if (error.indexOf('not_initialized_yet') !== -1) {
          const { myStreamId } = this.state
          console.log('not_initialized_yet Inside', myStreamId)
          errorMessage = 'publish not_initialized_yet'
          if (webRTCAdaptor) {
            webRTCAdaptor.stop(myStreamId)
            webRTCAdaptor.closePeerConnection(myStreamId)
            webRTCAdaptor.closeWebSocket()
          }
          this.reConnectSocket()
        } else if (error.indexOf('streamIdInUse') !== -1) {
          errorMessage = 'publish streamIdInUse'
          console.log('publish streamIdInUse')
          this.deleteMyStream('streamIdInUse')
          // streamIdInUseMsg = 'publish streamIdInUse'
        } else if (error.indexOf('UnsecureContext') !== -1) {
          errorMessage =
            'Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https'
        } else if (error.indexOf('ScreenSharePermissionDenied') !== -1) {
          errorMessage = t('events:screen_share_denied')
          this.setState(
            {
              disableScreenSharing: '',
              isShareScreen: false
            },
            () => {
              if (webRTCAdaptor) {
                webRTCAdaptor.sendData(myStreamId, 'CAMERA')
              }
            }
          )
        } else if (error.indexOf('WebSocketNotSupported') !== -1) {
          errorMessage = 'Fatal Error: WebSocket not supported in this browser'
        } else if (error.indexOf('already_playing') !== -1) {
          errorMessage = 'Stream already playing'
        } else if (error.indexOf('WebSocketNotConnected') !== -1) {
          console.log('publish WebSocketNotConnected')
          errorMessage = 'publish WebSocketNotConnected'
        } else if (error.indexOf('no_stream_exist') !== -1) {
          console.log('publish No stream exist')
          errorMessage = 'publish No stream exist'
          // TODO: removeRemoteVideo(error.streamId);
        } else if (error.indexOf('data_channel_error') !== -1) {
          errorMessage =
            'publish There was a error during data channel communication'
        } else {
          errorMessage = 'Unknown error'
        }
        console.log('errorMessage: ', errorMessage)
      }
    })
  }

  initPlayService = () => {
    const {
      conferenceId,
      isConferenceHost,
      handleBroadcastChange,
      streamCount,
      allowedUser,
      eventId,
      activeUserList
    } = this.props
    playWebRTCAdaptor = new WebRTCAdaptorPlay({
      websocket_url: this.SOCKET_URL_PLAY,
      mediaConstraints: passiveMediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localAudio',
      isPlayMode: isConferenceHost ? true : false,
      debug: true,
      bandwidth: 200,
      callback: (info, obj) => {
        console.log('play log command', info, obj)
        // const {
        //   conferenceId,
        //   isConferenceHost,
        //   handleBroadcastChange,
        //   streamCount
        // } = this.props
        const {
          myStreamId,
          speaker,
          isShareScreen,
          disableScreenSharing,
          hasLeave,
          streamsList
        } = this.state
        if (info === 'initialized') {
          console.log('play initialized', obj)
          playWebRTCAdaptor.joinRoom(conferenceId, myStreamId)
        } else if (info === 'start') {
          console.log('play start', obj)
        } else if (info === 'joinedTheRoom') {
          const room = obj.ATTR_ROOM_NAME
          console.log('play joinedTheRoom', obj)
          playWebRTCAdaptor.getRoomInfo(room, obj.streamId)
          if (!isConferenceHost && playWebRTCAdaptor) {
            playWebRTCAdaptor.publishAudio(myStreamId, token)
          }
          console.log('play joinedTheRoom streamsList', streamsList)
        } else if (info === 'newStreamAvailable') {
          console.log('play newStreamAvailable')
          const cleanUser = JSON.parse(obj.streamId.split('_')[0])
          if (activeUserList.filter(i => i.id === cleanUser).length > 0) {
            this.playVideo(obj)
          } else {
            this.playAudio(obj)
          }
          if (
            _.isEmpty(disableScreenSharing) &&
            isShareScreen &&
            playWebRTCAdaptor
          ) {
            console.log('newStreamAvailable myStreamId', myStreamId)
            playWebRTCAdaptor.sendData(myStreamId, 'SCREEN')
          }
        } else if (info === 'bitrateMeasurement') {
          console.log('play bitrateMeasurement', obj)
        } else if (info === 'closed') {
          console.log('play Connection closed')
          if (typeof obj !== 'undefined') {
            const { streamsList, hasLeave } = this.state
            const { isDisconnected } = this.props
            console.log('play Connection closed: ' + JSON.stringify(obj))
            if (streamsList.length > 0) {
              streamsList.forEach(item => {
                console.log('play closed streamDifference remove ', item)
                this.removeRemoteVideo(item)
              })
            }
            if (allowedUser.length > 0) {
              allowedUser.forEach(item => {
                this.removeRemoteAudio(item + '_' + eventId)
              })
            }
            if (!hasLeave && !isDisconnected) {
              this.initPlayService()
            }
          }
        } else if (info === 'play_started') {
          console.log('play play_started', obj)
        } else if (info === 'play_finished') {
          console.log('play play_finished', obj, 'hasLeave', hasLeave)
          this.removeRemoteVideo(obj.streamId)
          if (!hasLeave) {
            if (isConferenceHost) {
              if (disableScreenSharing === speaker) {
                if (disableScreenSharing === obj.streamId) {
                  this.setState({
                    disableScreenSharing: '',
                    isShareScreen: false,
                    speaker: myStreamId
                  })
                } else {
                  this.setState({
                    speaker: disableScreenSharing
                  })
                }
              } else {
                this.setState({ speaker: myStreamId })
              }
            } else {
              this.setState({
                disableScreenSharing: '',
                isShareScreen: false,
                speaker: ''
              })
            }
          }
        } else if (info === 'roomInformation') {
          console.log('play roomInformation', obj)
          const { allowedUser, userId, eventId } = this.props
          const { streamsList } = this.state
          const streamDifference = _.difference(streamsList, obj.streams)

          console.log('streamDifference', streamDifference)
          console.log('streamDifference streamsList', streamsList)

          const activeUsers =
            obj.streams && obj.streams.length > 0
              ? obj.streams.filter(s => {
                  const cleanUser = JSON.parse(s.split('_')[0])
                  if (allowedUser && !allowedUser.includes(cleanUser)) {
                    return true
                  }
                  return false
                })
              : []
          console.log('allow user activeUsers', activeUsers)

          this.setState(
            {
              streamsList: activeUsers
            },
            () => {
              const { streamsList } = this.state
              if (!isConferenceHost) {
                if (
                  allowedUser &&
                  allowedUser.length > 0 &&
                  streamsList.length > 0
                ) {
                  console.log('if allow publish', allowedUser.includes(userId))
                  playWebRTCAdaptor.unmuteLocalMic()
                }
                if (allowedUser && !allowedUser.includes(userId)) {
                  console.log('if stop allow publish')
                  playWebRTCAdaptor.muteLocalMic()
                }
              }

              if (activeUsers.length > 0) {
                activeUsers.forEach(item => {
                  console.log('Stream playing with ID: ' + item)
                  const { conferenceId } = this.props
                  playWebRTCAdaptor.play(item, token, conferenceId)
                })
              }

              if (allowedUser.length > 0 && isConferenceHost) {
                allowedUser.forEach(item => {
                  console.log('Stream playing with ID: ' + item)
                  const { conferenceId } = this.props
                  playWebRTCAdaptor.play(
                    item + '_' + eventId,
                    token,
                    conferenceId
                  )
                })
              }

              if (!isConferenceHost && streamsList.length === 0) {
                this.setState({ speaker: '' })
                // this.leave()
              }
            }
          )
          console.log('after streamsList', streamsList)
        } else if (info === 'leavedFromRoom') {
          console.log('play leavedFromRoom')
          if (!isConferenceHost) {
            this.setState(
              {
                showVideo: false,
                isJoined: false,
                speaker: '',
                myStreamId: '',
                showPrompt: false
              },
              () => {
                handleBroadcastChange()
                streamCount('')
                if (playWebRTCAdaptor) {
                  playWebRTCAdaptor.stop(myStreamId)
                  playWebRTCAdaptor.closePeerConnection(myStreamId)
                  playWebRTCAdaptor.closeWebSocket()
                  playWebRTCAdaptor.closeStream()
                }
                this.setState({ streamsList: [] })
              }
            )
          }
        } else if (info === 'data_received') {
          console.log('play data_received', obj)
          const eventData = obj.event.data.split('_')
          if (obj.event.data === 'SPEAKING') {
            if (!disableScreenSharing && !isShareScreen) {
              this.setState({ speaker: obj.streamId })
            }
          }

          if (eventData[0] === 'USERNAME') {
            if (obj.streamId !== myStreamId) {
              let targetUser = document.getElementById(
                'username' + obj.streamId
              )
              if (targetUser) {
                targetUser.innerText = eventData[1]
              }
            }
          }

          if (obj.event.data === 'SCREEN') {
            if (!isShareScreen) {
              this.setState({
                disableScreenSharing: obj.streamId,
                speaker: obj.streamId
              })
            }
          }

          if (obj.event.data === 'CAMERA') {
            this.setState({ disableScreenSharing: '', isShareScreen: false })
          }

          if (obj.event.data === 'MUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = true
                targetIcon.src = mute
              }
            } else {
              this.setState({ isMicOff: true }, () => {
                webRTCAdaptor.muteLocalMic()
              })
            }
          }

          if (obj.event.data === 'UNMUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = false
                targetIcon.src = unmute
              }
            }
          }
        } else if (info === 'takeConfiguration') {
          console.log('takeConfiguration')
        } else if (info === 'data_channel_opened') {
          console.log('Data Channel open for stream id', obj)
        } else if (info === 'updated_stats') {
          // console.log('updated_stats audioLevel', obj.audioLevel)
          // console.log('updated_stats streamId', obj.streamId)
        }
      },
      callbackError: (error, message) => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log('play log error ', error.isTrusted)
        console.log('play error callback: ' + JSON.stringify(error), message)
        let errorMessage = JSON.stringify(error)
        if (error.isTrusted) {
          console.log('inside error.isTrusted play', error.isTrusted)
          return
        }

        if (error.indexOf('WebSocketNotConnected') !== -1) {
          console.log('play WebSocketNotConnected')
          errorMessage = 'play side web socket not connected'
        } else if (error.indexOf('no_stream_exist') !== -1) {
          console.log('play No stream exist', error.streamId)
          errorMessage = 'play No stream exist'
          this.removeRemoteVideo(error.streamId)
          // TODO: removeRemoteVideo(error.streamId);
        } else if (error.indexOf('publishTimeoutError') !== -1) {
          const { myStreamId } = this.state
          const { isConferenceHost } = this.props
          console.log('publishTimeOutError play', myStreamId)
          errorMessage = 'publish timeout error'
          if (!isConferenceHost) {
            playWebRTCAdaptor.publish(myStreamId, token)
          }
        }

        console.log(
          'play error logs',
          errorMessage,
          'playWebRTCAdaptor.roomTimerId',
          playWebRTCAdaptor.roomTimerId,
          'connectionInterval',
          this.connectionInterval
        )
      }
    })
  }

  render() {
    const {
      showVideo,
      isJoined,
      isCameraOff,
      isMicOff,
      isShareScreen,
      disableScreenSharing,
      showPrompt,
      canShowModal,
      streamsList,
      isPublishStarted,
      myStreamId,
      showParticipant,
      emailList,
      emails,
      fetchingEmail,
      inviting,
      failInvitation,
      successInvitation,
      callFirstTime,
      speaker
    } = this.state
    const {
      isStreamStopped,
      isConferenceHost,
      t,
      allowJoin,
      conferenceId,
      isConfCreator,
      activeUserList
    } = this.props

    console.log(
      'conference render logs new',
      'isStreamStopped',
      isStreamStopped,
      'allowJoin',
      allowJoin,
      'myStreamId',
      myStreamId,
      'connectionInterval',
      this.connectionInterval,
      'emailList',
      emailList,
      'emails',
      emails,
      'streamsList',
      streamsList,
      'callFirstTime',
      callFirstTime,
      'speaker',
      speaker,
      'activeUserList',
      activeUserList
    )
    return (
      <div>
        {/*{streamIdInUseMsg && message.error(streamIdInUseMsg)}*/}
        {closeConnectionMsg && message.error(closeConnectionMsg)}
        {failInvitation && message.error('Please try again.')}
        {successInvitation &&
          message.success(
            'Your invitation sent successfully. Participant(s) will get notification in their email'
          )}
        {showVideo && (
          <>
            <Prompt
              when={showPrompt}
              message={() => {
                prompt = window.confirm(t('events:prompt_message'))
                if (prompt) {
                  if (webRTCAdaptor) {
                    webRTCAdaptor.closeStream()
                    webRTCAdaptor.leaveFromRoom(conferenceId)
                  }
                  if (playWebRTCAdaptor) {
                    playWebRTCAdaptor.leaveFromRoom(conferenceId)
                  }
                  return prompt
                } else {
                  return false
                }
              }}
            />
            <Row>
              <div className="conference">
                <div className="streams">
                  <div className="scrollbar" id="players">
                    {!isConferenceHost && (
                      <audio
                        id="localAudio"
                        controls
                        autoplay={false}
                        className="passive_hidden"
                      />
                    )}
                    {isConferenceHost ? (
                      <>
                        <div id="players">
                          <div className="local__myClass">
                            <video
                              id="localVideo"
                              className="active"
                              autoPlay
                              muted
                              controls={disableScreenSharing}
                            />
                          </div>
                          {!isPublishStarted && (
                            <Card.OverlayPlayer>
                              {t('labels:processing')}
                            </Card.OverlayPlayer>
                          )}
                        </div>
                        <div id="audio_players" />
                      </>
                    ) : (
                      <>
                        {streamsList.length === 0 && (
                          <div id="players">
                            <video
                              id="remoteVideo"
                              className="active"
                              autoPlay
                              muted
                              playsInline
                            />
                            <Card.OverlayPlayer>
                              <span>{t('general:loading')}</span>
                            </Card.OverlayPlayer>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
        {allowJoin && !isStreamStopped && (
          <Row className="controller">
            {!isJoined && (
              <Tooltip placement="top" title={t('v4:join')}>
                <div
                  role="button"
                  className="controller-item-join"
                  onClick={() => this.reConnectSocket()}
                >
                  <img src={join} alt="join" />
                </div>
              </Tooltip>
            )}
            <div className={`${isJoined ? 'controller-items' : ''}`}>
              {isJoined &&
                isConferenceHost &&
                (isShareScreen ? (
                  <Tooltip
                    placement="top"
                    title={t('labels:stop_share_screen')}
                  >
                    <div
                      role="button"
                      className={`controller-item ${
                        disableScreenSharing !== '' || !isPublishStarted
                          ? 'disable-item'
                          : ''
                      }`}
                      onClick={() => this.screenShare()}
                    >
                      <img src={stopShareScreenLogo} alt="stop-share-screen" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('labels:share_screen')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        disableScreenSharing !== '' || !isPublishStarted
                          ? 'disable-item'
                          : ''
                      }`}
                      onClick={() => this.screenShare()}
                    >
                      <img src={shareScreenLogo} alt="share-screen" />
                    </div>
                  </Tooltip>
                ))}
              {isJoined && disableScreenSharing !== '' && (
                <Tooltip placement="top" title={t('v2:full_screen')}>
                  <div
                    role="button"
                    className="controller-item"
                    onClick={() => this.fullScreenMode()}
                  >
                    <img src={fullScreen} alt="full-screen" />
                  </div>
                </Tooltip>
              )}
              {isJoined &&
                isConferenceHost &&
                (isCameraOff ? (
                  <Tooltip placement="top" title={t('v4:camera_on')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen || !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOnLocalCamera()}
                    >
                      <img src={cameraOff} alt="camera-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:camera_off')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen || !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOffLocalCamera()}
                    >
                      <img src={cameraOn} alt="camera-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined &&
                isConferenceHost &&
                (isMicOff ? (
                  <Tooltip placement="top" title={t('v4:mic_on')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.unmuteLocalMic()}
                    >
                      <img src={micOff} alt="mic-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:mic_off')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.muteLocalMic()}
                    >
                      <img src={micOn} alt="mic-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined && (
                <Tooltip placement="top" title={t('v4:leave')}>
                  <div
                    role="button"
                    className="controller-item"
                    onClick={() => this.toggleModal()}
                  >
                    <img src={leave} alt="leave" />
                  </div>
                </Tooltip>
              )}

              {isJoined && isConfCreator && (
                <div style={{ paddingLeft: '2em' }}>
                  <Tooltip placement="top" title={t('v2:add_participants')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.showAddparticipant()}
                    >
                      <img
                        src={addParticipant}
                        width="30px"
                        height="30px"
                        alt="add participant"
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            {canShowModal && (
              <Modal
                title={t('labels:alert')}
                visible={canShowModal}
                onOk={() => this.leave()}
                onCancel={this.toggleModal}
              >
                <h3>{t('events:event_modal_warn')}</h3>
              </Modal>
            )}

            {showParticipant && (
              <Modal
                title={t('v2:add_participants')}
                visible={showParticipant}
                onOk={this.sendInvitations}
                // okText={'Send Invitation(s)'}
                onCancel={this.showAddparticipant}
                footer={[
                  <Button key="back" onClick={this.showAddparticipant}>
                    cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={inviting}
                    onClick={this.sendInvitations}
                  >
                    {t('v2:send_invitations')}
                  </Button>
                ]}
              >
                <h3>{t('v2:add_participants')}</h3>
                <AddParticipant
                  emailList={emailList}
                  emails={emails}
                  fetchUserEmails={this.fetchUserEmails}
                  handleEmailChange={this.handleEmailChange}
                  fetching={fetchingEmail}
                  t={t}
                />
              </Modal>
            )}
          </Row>
        )}
      </div>
    )
  }
}

export default NetworkDetection(Conference)
