import { useEffect } from 'react'

function ZoomSection({
  isHost,
  isParticipant,
  hostURL,
  participantURL,
  onJoin
}) {
  console.log('zoom', isHost, isParticipant, hostURL, participantURL)
  const redirectURL = () => {
    if (isHost) {
      console.log(hostURL)
      window.open(hostURL, '_blank')
    }
    if (isParticipant) {
      window.open(participantURL, '_blank')
    }
  }

  useEffect(() => {
    if (onJoin) {
      redirectURL()
    }
  }, [onJoin])

  return ''
}

export default ZoomSection
