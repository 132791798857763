import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import className from 'classnames'
import { Button, Modal, Pagination, Tabs, Row, Col, Icon, Empty } from 'antd'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Page } from './styled'
import './Library.scss'
import { bytesToSize, getFileType, getFileExtension } from '../../../../utils'
import {
  Card,
  AddMediaDetail,
  UpdateStatusModal,
  MediaFilter
} from '../../components'
import Spinner from '../../../../components/Spinner/Spinner'
import ARUploads from '../../components/ARUploads'
import MazeUploads from '../../components/MazeUploads'
import { Search, Filter } from '../../../../components'
import { config, general } from '../../../../constants'
import history from '../../../../history'
import notification from '../../components/notification'
import { uploadMedia, textIcon, zipIcon } from './images'
import { updateMedia, removeMedias } from '../../../../services/api/library'
import { api } from '../../../../services'
import NewHeader from '../../../../components/NewHeader'

const { TabPane } = Tabs

const filterData = [
  { value: 'NEWEST', text: 'filters:Newest_to_Oldest' },
  { value: 'OLDEST', text: 'filters:Oldest_to_Newest' },
  { value: 'ASC', text: 'filters:A_Z' },
  { value: 'DESC', text: 'filters:Z_A' }
]

class Library extends Component {
  state = {
    page: 1, // this variable use for my media for current page to get api
    pageSize: 20, // this variable use for my media for page size in pagination
    pagePublic: 1, // this variable use for public media pagination for current page to get api
    pageSizePublic: 20, // this variable user for public media  for page size in pagination
    pageOrg: 1, // this variable use for org media pagination for current page to get api
    pageSizeOrg: 20, // this variable user for org media  for page size in pagination
    search: '',
    searchPublic: '',
    searchOrg: '',
    myMediaType: '',
    publicLibraryType: '',
    orgMediaType: '',
    modal: false,
    editAssets: false,
    enableProgress: false,
    data: {},
    selectedData: {},
    orgLibrary: {},
    tab: general.MY_MEDIA,
    isFetching: true,
    publicMediaLoaded: false,
    orgMediaLoaded: false,
    files: [],
    fileNotCompatible: false,
    showStatusModal: false,
    mediaNoTopics: [],
    mediaNoKeywords: [],
    loadingModalButtion: false,
    isHideFilter: false,
    isRemovingMedia: false,
    showDeleteModal: false,
    showMyMedia: false,
    isScormZipUploading: false,
    distroyUnity: false,
    mediaType: [
      {
        label: this.props.t('all'),
        value: general.ALL,
        selected: true
      },
      {
        label: this.props.t('media:video'),
        value: general.VIDEO,
        selected: false
      },
      {
        label: this.props.t('media:image'),
        value: general.IMAGE,
        selected: false
      },
      {
        label: this.props.t('media:audio'),
        value: general.AUDIO,
        selected: false
      },
      {
        label: this.props.t('media:gif'),
        value: general.GIF,
        selected: false
      },
      {
        label: this.props.t('v4:pdf'),
        value: general.PDF,
        selected: false
      },
      {
        label: this.props.t('v4:txt'),
        value: general.TEXT,
        selected: false
      },
      {
        label: this.props.t('v2:ar_assets'),
        value: general.ARIMAGE,
        selected: false
      }
    ],
    sortBy: 'NEWEST',
    scormExtractedMedia: []
  }

  createOffset = (page, pageSize) => page * pageSize - pageSize

  handleGetLibraryList = async () => {
    const { getLibraryList } = this.props
    const { page, pageSize, search, myMediaType, sortBy } = this.state

    await getLibraryList({
      offset: this.createOffset(page, pageSize),
      limit: pageSize,
      search,
      sort: sortBy,
      type: this.checkMediaType(myMediaType),
      isActiveOrg: true
    })

    const { data } = this.props.library
    this.setState({
      data,
      isFetching: false,
      showMyMedia: data.count > 0
    })
  }

  handleGetOrgList = async () => {
    const { getOrgList } = this.props
    const { pageOrg, pageSizeOrg, searchOrg, orgMediaType, sortBy } = this.state

    await getOrgList({
      offset: this.createOffset(pageOrg, pageSizeOrg),
      limit: pageSizeOrg,
      search: searchOrg,
      sort: sortBy,
      type: this.checkMediaType(orgMediaType),
      isOrg: true
    })

    const { orgLibrary } = this.props.library
    this.setState({
      orgLibrary
    })
  }

  handleGetPublicMedia = async () => {
    const { getPublicList } = this.props
    const {
      pageSizePublic,
      pagePublic,
      searchPublic,
      publicLibraryType,
      sortBy
    } = this.state

    await getPublicList({
      offset: this.createOffset(pagePublic, pageSizePublic),
      limit: pageSizePublic,
      search: searchPublic,
      type: this.checkMediaType(publicLibraryType),
      sort: sortBy,
      isPublic: true,
      publicMediaLoaded: false
    })

    const { publicLibrary } = this.props.library
    this.setState({
      publicLibrary
    })
  }

  checkMediaType = mediaType => {
    const type = mediaType === general.ALL ? '' : mediaType
    return type
  }

  getLibrary = async () => {
    // Get My Library list
    const isKnowledgeCenter = window.location.pathname.includes(
      '/knowledge-center'
    )

    if (!isKnowledgeCenter) {
      await this.handleGetLibraryList()
      await this.handleGetPublicMedia()
    }
    await this.handleGetOrgList()

    const { isFetching } = this.props.library
    this.setState({
      isFetching,
      ...(isKnowledgeCenter && { tab: general.ORGANIZATION_LIBRARY })
    })
  }
  checkIsOrgFetch = async () => {
    const checkIsOrg = await api.roles.resistrictMediaApi()
    this.setState({
      showMyMedia: checkIsOrg.data.count > 0
    })
  }
  async componentDidMount() {
    this.getLibrary()
    // this.checkIsOrgFetch()
  }

  showModal = () => {
    const { clearUploadList } = this.props
    clearUploadList()
    this.setState({
      modal: true
    })
  }

  distroyUnityInstance = () => {
    this.setState(
      {
        distroyUnity: true
      },
      () => {
        setTimeout(() => {
          this.hideModal()
        }, 700)
      }
    )
  }

  hideModal = async () => {
    const { clearUploadList } = this.props
    this.setState({
      modal: false,
      distroyUnity: false,
      isFetching: true,
      editAssets: false,
      enableProgress: false,
      selectedData: {}
    })

    // Refrest data base on current tab
    await this.handleGetLibraryList()

    this.setState({
      isFetching: false
    })

    clearUploadList()

    setTimeout(() => {
      this.setState({
        files: []
      })
    }, 100)
  }

  onDrop = (dropped, isScormZipUpload) => {
    const { upload } = this.props
    const { files } = this.state
    const newFiles = files.concat(dropped)

    this.setState({
      files: newFiles,
      enableProgress: true
    })

    if (dropped.length === 0) {
      this.setState({ fileNotCompatible: true })
    }

    return dropped.forEach(file =>
      upload(file, isScormZipUpload ? { category: 'scorm' } : {}).then(res => {
        if (res && isScormZipUpload) {
          this.setState({
            scormExtractedMedia: res
          })
        }
      })
    )
  }

  handleCancelUpload = mediaItem => {
    if (mediaItem.source) {
      // cancel the request (the message parameter is optional)
      mediaItem.source.cancel()
    }
  }

  handleRemoveMedia = async (mediaItem, ind) => {
    this.setState({ isRemovingMedia: true })
    const data = {
      mediaIds: [mediaItem]
    }
    try {
      await removeMedias(data)
      const { files } = this.state
      files[ind].removed = true

      this.setState({
        isRemovingMedia: false,
        files
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize,
        isFetching: true
      },
      this.getLibrary
    )
  }

  handleClickEdit = item => {
    this.setState(
      {
        editAssets: true,
        selectedData: item
      },
      () => {
        this.showModal()
      }
    )
  }

  closeAssetModal = () => {
    this.setState({
      editAssets: false
    })
  }

  onChangePagePublic = (pagePublic, pageSizePublic) => {
    this.setState(
      {
        pagePublic,
        pageSizePublic,
        isFetching: true
      },
      this.handleGetPublicMedia
    )
  }

  onChangePageOrg = (pageOrg, pageSizeOrg) => {
    this.setState(
      {
        pageOrg,
        pageSizeOrg,
        isFetching: true
      },
      this.handleGetOrgList
    )
  }

  /**
   * Allow user to update their media library status
   * @singleUpdate
   * @param
   * item = media that have to update
   * ind = index of media
   */
  handleUpdateLibraryStatus = async (item, thumb, ind) => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    const remarkPublic = item.remark_public
    const remarkOrg = item.remark_org
    const hasKeywords = item.keywords !== null && item.keywords.length > 0
    const hasTopics = item.topics.length > 0

    let action
    if (isSuperAdmin) {
      if (remarkPublic) {
        action = general.PRIVATE
      } else {
        action = general.PUBLIC
      }
    } else {
      if (remarkOrg) {
        action = general.PRIVATE
      } else {
        action = general.ORG
      }
    }

    const notPrivateAction = action === general.PUBLIC || action === general.ORG

    // Open Update Media status if media no topic
    if (notPrivateAction && (!hasTopics || !hasKeywords)) {
      const { handleMakeType } = this.props
      const status = action
      item.thumb = thumb
      this.setState(
        {
          showStatusModal: true,
          mediaNoTopics: [item]
        },
        () => {
          handleMakeType(status)
        }
      )
      return
    }

    const { updateLibraryStatus, updateOrgLib, user } = this.props
    // if (isPageBuilder) {
    //   return
    // }

    const { data } = this.state
    let query = { action, library: [item.id] }
    this.setState({ isFetching: true })
    let setting = false
    if (action === general.PRIVATE) {
      // Update media library status
      if (remarkOrg) {
        await updateOrgLib(query)
      } else {
        await updateLibraryStatus(query)
      }

      // getLibrary to refresh data
      await this.getLibrary()
      this.setState(
        {
          isFetching: false,
          publicMediaLoaded: false
        },
        () => {
          notification(action)
        }
      )
    } else if (action === general.ORG) {
      // Update media library status
      // query = { action, organization_id: user.info.active_organization_id, library: [item.id] }
      const mediaHasTopics = item.topics.length > 0
      const mediaHasKeywords =
        item.keywords !== null && item.keywords.length > 0

      if (mediaHasTopics && mediaHasKeywords) {
        query.organization_id = user.info.active_organization_id
        await updateOrgLib(query)
        setting = true
      }
      if (setting) {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            orgMediaLoaded: false
          },
          () => {
            notification(action)
          }
        )
      }
    } else {
      // Show loading for Make Private button
      data.rows[ind].is_loading = true
      this.setState({ data })

      // // Update media library status
      // await updateLibraryStatus(query)

      // // getLibrary to refresh data
      // await this.getLibrary()
      // this.setState(
      //   {
      //     isFetching: false,
      //     publicMediaLoaded: false
      //   },
      //   () => {
      //     notification(action)
      //   }
      // )
      const mediaHasTopics = item.topics.length > 0
      const mediaHasKeywords =
        item.keywords !== null && item.keywords.length > 0

      if (mediaHasTopics && mediaHasKeywords) {
        await updateLibraryStatus(query)
        setting = true
      }
      if (setting) {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            publicMediaLoaded: false
          },
          () => {
            notification(action)
          }
        )
      }
    }
  }

  handleOrgLibraryUpdate = async item => {
    const { updateOrgLib, user } = this.props
    const action = general.ORG
    const query = {
      action,
      organization_id: user.info.active_organization_id,
      library: [item.id]
    }

    await updateOrgLib(query)
    await this.getLibrary()
    this.setState(
      {
        isFetching: false,
        orgMediaLoaded: false
      },
      () => {
        notification(action)
      }
    )
  }

  /**
   * Mark as Card selected or not selected
   */
  handleCheckCard = (value, thumb, ind) => {
    const { isPageBuilder } = this.props
    const { data, publicLibrary, orgLibrary, tab } = this.state
    /**
     * not allow user to select if user stay on Page builder page
     */
    if (isPageBuilder) {
      return
    }

    let activeTabData
    if (tab === general.PUBLIC_MEDIA || tab === general.ORGANIZATION_LIBRARY) {
      if (tab === general.PUBLIC_MEDIA) {
        activeTabData = publicLibrary
      } else {
        activeTabData = orgLibrary
      }
    } else {
      activeTabData = data
    }
    activeTabData.rows[ind].is_checked = value
    activeTabData.rows[ind].thumb = thumb

    this.handleHideFilter(activeTabData.rows)

    this.setState({
      activeTabData
    })
  }

  handleHideFilter = data => {
    const countSelectData = _.filter(data, item => {
      return item.is_checked
    })

    let isHideFilter = false
    if (countSelectData.length > 0) {
      isHideFilter = true
    }
    this.setState({ isHideFilter })
  }

  checkMediaHasTopic = mediaList => {
    /**
     * Filter out Media that no Topic and has checked
     */
    const mediaNoTopics = _.filter(mediaList, item => {
      return item.is_checked && item.topics.length <= 0
    })

    this.setState({ mediaNoTopics })

    return !(mediaNoTopics.length > 0)
  }

  checkMediaHasKeywords = mediaList => {
    /**
     * Filter out Media that no Keywords and has checked
     */
    const mediaNoKeywords = _.filter(mediaList, item => {
      return item.is_checked && item.keywords && item.keywords.length <= 0
    })

    this.setState({ mediaNoKeywords })

    return !(mediaNoKeywords.length > 0)
  }

  makeMediaStatus = async (status, medias) => {
    const { updateOrgLib, user } = this.props
    this.setState({ loadingModalButtion: true })
    const updateList = []

    if (status === general.ORG) {
      for (const item of medias) {
        const keywords =
          Array.isArray(item.keywords) && item.keywords.length > 0
            ? item.keywords.join(',')
            : item.keywords
        const query = {
          action: status,
          organization_id: user.info.active_organization_id,
          library: [item.id],
          topics: item.topic_ids,
          keywords
        }
        const data = { topics: item.topic_ids, keywords }
        updateList.push(updateOrgLib(query))
        updateList.push(updateMedia(item.id, data))
      }
    } else {
      for (const item of medias) {
        const keywords =
          Array.isArray(item.keywords) && item.keywords.length > 0
            ? item.keywords.join(',')
            : item.keywords
        const data = { status, topics: item.topic_ids, keywords }
        updateList.push(updateMedia(item.id, data))
      }
    }

    await Promise.all(updateList)

    this.setState(
      {
        showStatusModal: false,
        loadingModalButtion: false,
        isFetching: true,
        isHideFilter: false
      },
      () => {
        notification(status)
        this.props.handleMakeType(null)
      }
    )

    await this.getLibrary()
    await this.handleGetPublicMedia()
    await this.handleGetOrgList()

    this.setState({
      isFetching: false
    })
  }

  /**
   * Allow user to update their media library status
   * @multipleUpdate
   * @param: is_public = true (Update Public to private)
   */
  updateMediaStatus = async status => {
    const {
      updateLibraryStatus,
      updateOrgLib,
      user,
      handleMakeType
    } = this.props
    const {
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props
    const { data, publicLibrary, orgLibrary, tab } = this.state
    let activeTabData
    if (tab === general.PUBLIC_MEDIA) {
      activeTabData = publicLibrary
    } else if (tab === general.ORGANIZATION_LIBRARY) {
      activeTabData = orgLibrary
    } else {
      activeTabData = data
    }

    const mediaSelected = _.filter(activeTabData.rows, item => {
      return item.is_checked
    })
    const notPrivateStatus = status === general.PUBLIC || status === general.ORG
    const mediaHasTopics = _.filter(mediaSelected, item => {
      return item.topics.length > 0
    })

    const mediaHasKeywords = _.filter(mediaSelected, item => {
      return item.keywords !== null && item.keywords.length > 0
    })

    if (
      notPrivateStatus &&
      (mediaHasTopics.length !== mediaSelected.length ||
        mediaHasKeywords.length !== mediaSelected.length)
    ) {
      this.setState(
        {
          showStatusModal: true,
          mediaNoTopics: mediaSelected
        },
        () => {
          handleMakeType(status)
        }
      )
    } else {
      this.setState({
        showStatusModal: false
      })
    }

    const rows = activeTabData.rows
    // Filter and map library for get library ID
    const library = _.map(
      _.filter(rows, item => {
        return item.is_checked
      }),
      'id'
    )
    let query = {
      action: status,
      library
    }
    // Show loading
    let libraryOrg = []
    _.filter(mediaSelected, item => {
      if (item.remark_org) {
        libraryOrg.push(item.id)
      }
    })
    let libraryPub = []
    _.filter(mediaSelected, item => {
      if (item.remark_public) {
        libraryPub.push(item.id)
      }
    })

    if (status === general.PRIVATE) {
      try {
        this.setState({ isFetching: true })
        if ((isSuperAdmin || isAdmin) && libraryOrg.length > 0) {
          const query = { action: status, library: libraryOrg }
          await updateOrgLib(query)
        }
        if (isSuperAdmin && libraryPub.length > 0) {
          const query = { action: status, library: libraryPub }
          await updateLibraryStatus(query)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        // getLibrary to refresh data
        await this.getLibrary()
        this.setState(
          {
            isFetching: false,
            publicMediaLoaded: false
          },
          () => {
            notification(status)
          }
        )
      }
    } else if (status === general.ORG) {
      let setting = false
      try {
        this.setState({ isFetching: true })
        const mediaHasTopics = _.filter(mediaSelected, item => {
          return item.topics.length > 0
        })

        const mediaHasKeywords = _.filter(mediaSelected, item => {
          return item.keywords !== null && item.keywords.length > 0
        })
        if (
          mediaHasTopics.length === mediaSelected.length &&
          mediaHasKeywords.length === mediaSelected.length
        ) {
          query.organization_id = user.info.active_organization_id
          await updateOrgLib(query)
          setting = true
        }
        if (setting) {
          await this.getLibrary()
          this.setState(
            {
              isFetching: false,
              orgMediaLoaded: false
            },
            () => {
              notification(status)
            }
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    } else {
      this.setState({ isFetching: true })
      let setting = false
      try {
        // this.setState({ data })
        // await updateLibraryStatus(query)
        const mediaHasTopics = _.filter(mediaSelected, item => {
          return item.topics.length > 0
        })

        const mediaHasKeywords = _.filter(mediaSelected, item => {
          return item.keywords !== null && item.keywords.length > 0
        })
        if (
          mediaHasTopics.length === mediaSelected.length &&
          mediaHasKeywords.length === mediaSelected.length
        ) {
          this.setState({ data })
          await updateLibraryStatus(query)
          setting = true
        }
        if (setting) {
          // getLibrary to refresh data
          await this.getLibrary()
          this.setState(
            {
              isFetching: false,
              publicMediaLoaded: false
            },
            () => {
              notification(status)
            }
          )
        }
      } catch (error) {
        console.log('error', error)
      }
    }
  }

  updateToOrgStatus = async () => {
    const { updateOrgLib, user } = this.props
    const { data, publicLibrary, tab } = this.state
    const activeTabData = tab === general.PUBLIC_MEDIA ? publicLibrary : data
    const action = 'org'
    this.setState(
      {
        isFetching: false,
        orgMediaLoaded: false
      },
      () => {
        notification(action)
      }
    )

    const rows = activeTabData.rows
    // Filter and map library for get library ID
    const library = _.map(
      _.filter(rows, item => {
        return item.is_checked
      }),
      'id'
    )
    const query = {
      action,
      library,
      organization_id: user.info.active_organization_id
    }
    // Show loading
    this.setState({ isFetching: true })
    // Update library status
    await updateOrgLib(query)

    // Request new data
    await this.getLibrary()

    // Hide loading
    this.setState(
      {
        isFetching: false,
        publicMediaLoaded: false
      },
      () => {
        notification('org')
      }
    )
  }

  deleteMedia = () => {
    const { data } = this.state
    const { t } = this.props
    const status = ''
    const type = 'success'
    const title = t('media:delete_success')
    const width = 400
    let library = []
    let customData = []

    this.setState({
      isFetching: true,
      showDeleteModal: false,
      isHideFilter: false
    })

    if (!_.isEmpty(data.rows)) {
      library = _.map(
        _.filter(data.rows, item => {
          return item.is_checked
        }),
        'id'
      )
    }

    if (!_.isEmpty(data.rows)) {
      customData = _.map(
        _.filter(data.rows, item => {
          return item.is_checked
        }),
        'custom_data'
      )
    }

    const medias = {
      mediaIds: library
    }

    removeMedias(medias).then(resp => {
      // this.handleGetLibraryList()
      console.log('RESPONSE AFTER DELETE', resp)
      if (!_.isEmpty(customData) && customData) {
        customData.forEach(item => {
          if (!_.isEmpty(item)) {
            console.log('CHECK ITEM', item)
            const DELETE_URL = `${config.phpServerURL}?select=DeleteTarget&targetId=${item.target_id}`
            fetch(DELETE_URL)
              .then(response => {
                return response.json()
              })
              .then(response => {
                console.log('PHP response delete', response)
              })
              .catch(error => {
                // handle the error
                console.log('DELETE CATCH ERROR', error)
              })
          }
          /*axios.get(DELETE_URL).then(response => {
            console.log('PHP Delete Response', response)
          })*/
        })
      }
      this.getLibrary()
      notification(status, type, title, width)
    })
  }

  onHandleSearch = (value, type) => {
    if (type === general.MY_MEDIA) {
      this.setState(
        {
          search: value,
          page: 1,
          isFetching: true
        },
        () => {
          this.getLibrary()
        }
      )
    } else if (type === general.PUBLIC_MEDIA) {
      this.setState(
        {
          searchPublic: value,
          pagePublic: 1,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    } else {
      this.setState(
        {
          searchOrg: value,
          pageOrg: 1,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  onSearch = _.debounce((value, type) => this.onHandleSearch(value, type), 1000)

  getPage = () => {
    if (this.state.tab === general.PUBLIC_MEDIA) {
      return this.state.pagePublic
    } else if (this.state.tab === general.MY_MEDIA) {
      return this.state.page
    } else {
      return this.state.pageOrg
    }
  }

  handleChangeTabs = async tab => {
    const { publicMediaLoaded } = this.state
    this.setState({
      tab, // get type of tab when user click change
      isHideFilter: false
    })
    if (tab === general.PUBLIC_MEDIA && !publicMediaLoaded) {
      this.setState({ isFetching: true })
      await this.handleGetPublicMedia()
      this.setState({
        publicMediaLoaded: true,
        isFetching: false
      })
    }
  }

  /**
   *  data is an object contain media private media list and count
   *  rows is an array of private media list
   */
  handlePrivateFileSelect = item => {
    const { data } = this.state
    const { rows } = data
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = true
      } else {
        value.isSelected = false
      }
      return value
    })

    const newData = {
      count: data.count,
      rows: newRows
    }

    return newData
  }

  handlePublicFileSelect = item => {
    const { publicLibrary } = this.state
    const { rows } = publicLibrary
    const newRows = rows.map(value => {
      if (value.id === item.id) {
        value.isSelected = !value.isSelected
      } else {
        value.isSelected = false
      }
      return value
    })

    const newData = {
      count: publicLibrary.count,
      rows: newRows
    }

    return newData
  }

  /**
   * publicLibrary is an object contain public media list and count
   * there will a rows in publicLabrary like this.state.data is private library
   */
  handleViewDetails = item => {
    const { isPageBuilder, handleInsertData } = this.props
    const { publicLibrary } = this.state

    if (isPageBuilder) {
      const newPrivateMediaData = this.handlePrivateFileSelect(item)
      const newPublicMediaData = publicLibrary
        ? this.handlePublicFileSelect(item)
        : null

      this.setState(
        {
          data: newPrivateMediaData,
          publicLibrary: newPublicMediaData || null
        },
        () => {
          handleInsertData(newPrivateMediaData, newPublicMediaData)
        }
      )
    } else {
      const isOrg = this.state.tab === general.ORGANIZATION_LIBRARY
      const isPublic = this.state.tab === general.PUBLIC_MEDIA
      const isPrivate = this.state.tab === general.MY_MEDIA

      const params = {
        page: this.getPage(),
        search: this.getSearch(),
        type: this.getFilterType(),
        sort: this.getSortType()
      }

      const query = queryString.stringify({
        ...params,
        ...(isOrg && { isOrg }),
        ...(isPublic && { isPublic }),
        ...(isPrivate && { isActiveOrg: true })
      })

      const isKnowledgeCenter = window.location.pathname.includes(
        'knowledge-center'
      )

      if (isKnowledgeCenter) {
        history.push(
          `/knowledge-center/library/gallery/${item.id}?${query}`,
          params
        )
      } else {
        history.push(`/library/gallery/${item.id}?${query}`, params)
      }
    }
  }

  getSearch = () => {
    if (this.state.tab === general.PUBLIC_MEDIA) {
      return this.state.searchPublic
    } else if (this.state.tab === general.MY_MEDIA) {
      return this.state.search
    } else {
      return this.state.searchOrg
    }
  }

  getFilterType = () => {
    if (this.state.tab === general.PUBLIC_MEDIA) {
      return this.state.publicLibraryType
    } else if (this.state.tab === general.MY_MEDIA) {
      return this.state.myMediaType
    } else {
      return this.state.orgMediaType
    }
  }

  getSortType = () => this.state.sortBy

  cancelError = () => {
    this.setState({ fileNotCompatible: false })
  }

  handleCloseStatusModal = () => {
    this.setState(
      {
        showStatusModal: false,
        isFetching: false
      },
      () => {
        this.props.handleMakeType(null)
      }
    )
  }

  /**
   * Handle user filer by media type
   */
  hangeFilterChange = async (type, tab) => {
    this.setState({ isFetching: true })

    // Filter for My Media tab
    if (tab === general.MY_MEDIA) {
      this.setState(
        {
          myMediaType: type,
          page: 1,
          isFetching: true
        },
        () => {
          this.getLibrary()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA) {
      this.setState(
        {
          publicLibraryType: type,
          pagePublic: 1,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          orgMediaType: type,
          pageOrg: 1,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  uploadListClassName = list => {
    return list.length <= 0 ? 'upload-modal__list-wrapper__no-border' : ''
  }

  searchContainer = type => {
    const { t } = this.props
    return (
      <div className="controls head search__container">
        <div className="controls__part">
          <div className="controls__item">
            <Search
              className="search"
              placeholder={t('placeholders:search_media')}
              onChange={e => this.onSearch(e.target.value, type)}
            />
          </div>
          <div className="controls__item">
            <Button type="primary" onClick={this.showModal} shape="round">
              {t('buttons:upload')}
            </Button>
          </div>
        </div>
      </div>
    )
  }

  getMediaListing = list => {
    return list ? list.rows : []
  }
  allowUpdateStatus = () => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    return isSuperAdmin
  }

  allowOrgAdminUpdateStatus = () => {
    const {
      user: {
        info: { isAdmin }
      }
    } = this.props
    return isAdmin
  }

  allowUpdateOrgMedia = () => {
    const {
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props
    return isSuperAdmin || isAdmin
  }

  allowDelete = type => {
    const {
      user: {
        info: { isSuperAdmin }
      }
    } = this.props
    if (type === general.PUBLIC_MEDIA) {
      return isSuperAdmin
    } else {
      return true
    }
  }

  handlePagination = (type, event) => {
    const { pageSize, pageSizePublic, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return this.onChangePagePublic(event, pageSizePublic)
    } else if (type === general.MY_MEDIA) {
      this.onChangePage(event, pageSize)
    } else {
      return this.onChangePageOrg(event, pageSizeOrg)
    }
  }

  getCurrent = type => {
    const { page, pagePublic, pageOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pagePublic
    } else if (type === general.MY_MEDIA) {
      return page
    } else {
      return pageOrg
    }
  }

  getPageSize = type => {
    const { pageSizePublic, pageSize, pageSizeOrg } = this.state
    if (type === general.PUBLIC_MEDIA) {
      return pageSizePublic
    } else if (type === general.MY_MEDIA) {
      return pageSize
    } else {
      return pageSizeOrg
    }
  }

  getThumbnail = (files, ind, isScorm = false) => {
    const fileType = getFileType(files[ind] && files[ind].type)
    if (fileType === 'text') {
      return (
        <img
          className="upload-modal__thumbnail__image"
          src={textIcon}
          alt="text"
        />
      )
    }

    if (isScorm) {
      return (
        <div className="upload-modal__thumbnail__audio-video">
          <img
            className="upload-modal__thumbnail__image"
            src={zipIcon}
            alt="zip"
          />
        </div>
      )
    }

    if (fileType === 'image') {
      return (
        <img
          className="upload-modal__thumbnail__image"
          src={URL.createObjectURL(files[ind] && files[ind])}
          alt=""
        />
      )
    }

    return (
      <div className="upload-modal__thumbnail__audio-video">
        <Icon
          type={
            getFileType(files[ind] && files[ind].type) === 'audio'
              ? 'audio'
              : 'play-circle'
          }
          style={{ color: '#0068FF', fontSize: '35px' }}
        />
      </div>
    )
  }

  renderMedia = (mediaList, type) => {
    const { tab, editAssets } = this.state
    const {
      t,
      isPageBuilder,
      user: {
        info: { active_organization_id }
      }
    } = this.props
    const media = this.getMediaListing(mediaList)
    const currentPage = this.getCurrent(type)
    const currentPageSize = this.getPageSize(type)

    return (
      <Fragment>
        {!mediaList ? (
          <div className="media-page__no-medial-list">
            {t('media:no_media')}
          </div>
        ) : (
          <Fragment>
            <div className="media-page__list media-list-outer">
              {media &&
                media.map((item, ind) => (
                  <div className="media-page__item" key={item.id}>
                    <Card
                      tab={tab}
                      editAssets={editAssets}
                      handleClickEdit={this.handleClickEdit}
                      activeOrganizationId={active_organization_id}
                      handleViewDetails={this.handleViewDetails}
                      item={item}
                      user={this.props.user}
                      allowUpdateStatus={this.allowUpdateStatus(type)}
                      allowOrgAdminUpdateStatus={this.allowOrgAdminUpdateStatus(
                        type
                      )}
                      allowUpdateOrgMedia={this.allowUpdateOrgMedia(type)}
                      allowDelete={this.allowDelete(type)}
                      ctrlLibraryStatus={(item, thumb) =>
                        this.handleUpdateLibraryStatus(item, thumb, ind)
                      }
                      ctrlHandleCheckCard={(val, thumb) => {
                        this.handleCheckCard(val, thumb, ind)
                      }}
                      ctrlOrgLibrary={(item, thumb) =>
                        this.handleOrgLibraryUpdate(item, thumb, ind)
                      }
                      isPageBuilder={isPageBuilder}
                      // isSuperAdmin={isSuperAdmin}
                    />
                  </div>
                ))}
            </div>
            <div className="media-page__controls">
              <Pagination
                total={mediaList.count}
                current={currentPage}
                pageSize={currentPageSize}
                onChange={event => this.handlePagination(type, event)}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    )
  }

  enableCloseModal = (progress, isFetching) => {
    const filterCancelProgressUpload = _.filter(
      progress,
      item => item.status === general.CANCELED
    )
    const checkCancelUpload =
      filterCancelProgressUpload.length === progress.length

    if (isFetching) {
      return !checkCancelUpload
    } else if (this.state.isRemovingMedia) {
      return true
    }

    return false
  }

  /**
   * Check upload progress bar status
   * if item.load not equal item.total display upload file size
   */
  checkedCompletUpload = item => {
    const { uploadSuccess } = this.props.library
    const filterSuccessFiles = _.filter(
      uploadSuccess,
      ele => ele.id === item.id
    )

    if (uploadSuccess.length && filterSuccessFiles.length) {
      return false
    }
    return true
  }

  handleFilterClassName = isHideFilter => {
    return isHideFilter ? 'hide-filter' : ''
  }

  onFilter = (order, tab) => {
    this.setState({ isFetching: true })
    // Filter for My Media tab
    if (tab === general.MY_MEDIA) {
      this.setState(
        {
          page: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.getLibrary()
        }
      )
    }

    // Filter for Public Library tab
    if (tab === general.PUBLIC_MEDIA) {
      this.setState(
        {
          pagePublic: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetPublicMedia()
        }
      )
    }

    // Filter for Org Library tab
    if (tab === general.ORGANIZATION_LIBRARY) {
      this.setState(
        {
          pageOrg: 1,
          sortBy: order,
          isFetching: true
        },
        () => {
          this.handleGetOrgList()
        }
      )
    }
  }

  renderFilterMedia = tab => {
    const {
      t,
      user: {
        info: { isSuperAdmin, isAdmin }
      }
    } = this.props

    const { isHideFilter, mediaType } = this.state

    return (
      <div className="filter-container">
        <div
          className={`left-block ${this.handleFilterClassName(isHideFilter)}`}
        >
          <Fragment>
            <div className="filter">
              {/*<p className="label">{t('media:media_type')}</p>*/}
              <MediaFilter
                lists={mediaType}
                ctrlHandleFilterChange={value =>
                  this.hangeFilterChange(value, tab)
                }
              />
            </div>
            <div className="status-button">
              {tab === general.MY_MEDIA && isSuperAdmin && (
                <Button
                  block
                  type="primary"
                  onClick={() => this.updateMediaStatus(general.PUBLIC)}
                >
                  {t('buttons:make_public')}
                </Button>
              )}
              {(isSuperAdmin || isAdmin) && (
                <Button
                  block
                  type="primary"
                  onClick={() => this.updateMediaStatus(general.PRIVATE)}
                >
                  {t('buttons:make_private')}
                </Button>
              )}
              {tab === general.MY_MEDIA && (
                <Button
                  block
                  type="danger"
                  onClick={() => this.setState({ showDeleteModal: true })}
                >
                  {t('buttons:delete')}
                </Button>
              )}
              {(isSuperAdmin || isAdmin) &&
                (tab === general.MY_MEDIA || tab === general.PUBLIC) && (
                  <Button
                    block
                    type="primary"
                    onClick={() => this.updateMediaStatus(general.ORG)}
                  >
                    {t('buttons:make_org')}
                  </Button>
                )}
            </div>
          </Fragment>
        </div>
        <div className="right-block">
          {/* &nbsp; */}
          <Filter
            data={filterData}
            defaultValue={filterData[0].text}
            callback={item => this.onFilter(item.value, tab)}
            label={t('labels:sort_by')}
            // bordered
          />
        </div>
      </div>
    )
  }

  getSelectedMedia = () => {
    const { data } = this.state
    let library = []
    if (!_.isEmpty(data.rows)) {
      library = _.map(
        _.filter(data.rows, item => {
          return item.is_checked
        }),
        'filename'
      )
    }
    return library
  }

  onChangeUploadsTab = e => {
    this.setState({ enableProgress: false })

    const { clearUploadList } = this.props

    clearUploadList()
  }

  renderDropzone = type => {
    const isScormZipUpload = type === 'scorm'
    const droppableFiles = [
      'video/AVI',
      'video/WMV',
      'video/MP4',
      'video/MOV',
      'video/FLV',
      'video/*',
      'audio/*',
      '.pdf',
      'image/*',
      '.ppt',
      '.pptx',
      '.txt',
      '.docx'
    ]

    const {
      files,
      fileNotCompatible,
      isRemovingMedia,
      enableProgress,
      scormExtractedMedia
    } = this.state

    const { t, topics, library } = this.props
    const { uploadProgress, uploadFetching } = library

    return (
      <div className="upload-modal">
        <div className="upload-modal__inner">
          {!enableProgress && (
            <div>
              <h3 className="upload-modal__text">
                {isScormZipUpload ? 'Upload Scorm Zip' : t('media:import_file')}
              </h3>
              <div className="upload-modal__dropzone">
                <Dropzone
                  accept={
                    isScormZipUpload
                      ? ['application/zip', 'application/x-zip-compressed']
                      : droppableFiles
                  }
                  onDrop={dropped => this.onDrop(dropped, isScormZipUpload)}
                >
                  {({ isDragActive }) => (
                    <div
                      className={className('upload-dropzone', {
                        'upload-dropzone_active': isDragActive
                      })}
                    >
                      <div className="upload-dropzone__text-wrapper">
                        <img src={uploadMedia} alt="" />
                        <p className="upload-dropzone__text">
                          <span>
                            {isScormZipUpload
                              ? t('v2:upload_3d_maze_zip')
                              : t('media:upload_file')}
                          </span>{' '}
                          {t('media:drag_drop')}
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
          )}
          <div
            className={`upload-modal__list-wrapper ${this.uploadListClassName(
              uploadProgress
            )}`}
          >
            <div className="upload-modal__list-container">
              {fileNotCompatible && (
                <div className="upload-modal__error-item-wrapper">
                  <div className="upload-modal__error-message-container">
                    <div className="upload-modal__error-bar" />
                    <div className="upload-modal__error-message">
                      {t('errors:upload_error')}
                    </div>
                  </div>

                  <Button
                    className="upload-modal__cancel-error"
                    type="primary"
                    onClick={this.cancelError}
                  >
                    {t('buttons:cancel')}
                  </Button>
                </div>
              )}

              {enableProgress && uploadProgress.length > 0 && (
                <div className="upload-modal__list">
                  {uploadProgress.map((item, ind) => (
                    <div key={item.uid} className="upload-modal__item">
                      <div className="upload-modal__thumbnail">
                        {this.getThumbnail(files, ind, type === 'scorm')}
                        <div className="upload-modal__thumbnail__details">
                          <span className="upload-modal__thumbnail__media-type">
                            {type === 'scorm'
                              ? 'SCORM'
                              : getFileType(files[ind] && files[ind].type)}
                          </span>
                          <span className="upload-modal__thumbnail__extention">
                            {getFileExtension(files[ind] && files[ind].type)}
                          </span>
                          <span className="upload-modal__thumbnail__size">
                            {bytesToSize(files[ind] && files[ind].size)}
                          </span>
                        </div>
                      </div>
                      <div className="upload-modal-item">
                        <div className="upload-modal-item__percent-border">
                          <div
                            className={`upload-modal-item__percent ${
                              item.status === general.CANCELED
                                ? 'upload-modal-item__percent__cancel'
                                : ''
                            }`}
                            style={{ width: item.percent + '%' }}
                          />
                        </div>

                        {item.status !== general.CANCELED && (
                          <div className="upload-modal-item__weight">
                            {this.checkedCompletUpload(item) && (
                              <span>
                                {bytesToSize(item.loaded)} {t('of')}{' '}
                                {bytesToSize(item.total)}
                              </span>
                            )}
                            {!this.checkedCompletUpload(item) && (
                              <span className="upload-modal-item__weight__complete">
                                {files[ind] && files[ind].removed
                                  ? t('general:removed')
                                  : `${t('general:completed')} ${
                                      type === 'scorm'
                                        ? `(${scormExtractedMedia.length} media files extracted)`
                                        : ''
                                    }`}
                              </span>
                            )}
                          </div>
                        )}

                        {item.status === general.CANCELED && (
                          <div className="upload-modal-item__weight">
                            <span className="upload-modal-item__weight__canceled">
                              {t('general:canceled')}
                            </span>
                          </div>
                        )}

                        <AddMediaDetail
                          status={item.status}
                          mediaId={item.id || item.uid}
                          fileType={getFileType(files[ind] && files[ind].type)}
                          isLibrary
                          topicList={topics.list}
                          isLoadingTopic={topics.isFetching}
                          isRemovingMedia={isRemovingMedia}
                          mediaRemoved={files[ind] && files[ind].removed}
                          ctrlHandleCancel={() => this.handleCancelUpload(item)}
                          ctrlHandleRemove={() =>
                            this.handleRemoveMedia(item.id, ind)
                          }
                          isScorm={type === 'scorm'}
                          scormExtractedMedia={scormExtractedMedia}
                          isUploading={uploadFetching}
                          isUploadingComplete={this.checkedCompletUpload(item)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="upload-modal__footer">
          <Button
            type="primary"
            shape="round"
            size="large"
            onClick={this.distroyUnityInstance}
            disabled={this.enableCloseModal(uploadProgress, uploadFetching)}
          >
            {t('buttons:close')}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {
      modal,
      // isFetching,
      data,
      publicLibrary,
      showStatusModal,
      mediaNoTopics,
      loadingModalButtion,
      showDeleteModal,
      orgLibrary,
      showMyMedia,
      editAssets,
      selectedData,
      distroyUnity
    } = this.state
    const { t, topics, library } = this.props
    const {
      uploadProgress,
      uploadFetching,
      isOrgFetching,
      isPublicFetching,
      isFetching
    } = library
    const selectedMedia = this.getSelectedMedia()

    const isKnowledgeCenter = window.location.pathname.includes(
      '/knowledge-center'
    )

    return (
      <Fragment>
        <section className="section">
          <Row>
            <Col span={12}>
              <div className="head">
                <div className="head__title">{t('title')}</div>
                <div className="head__description">{t('subtitle')}</div>
              </div>
            </Col>
            <Col span={12}>
              <NewHeader />
            </Col>
          </Row>
        </section>
        <section className="section">
          <Tabs defaultActiveKey="1" onChange={this.handleChangeTabs}>
            {!isKnowledgeCenter && (
              <TabPane tab={t('media:my_media')} key={general.MY_MEDIA}>
                {this.searchContainer(general.MY_MEDIA)}
                {this.renderFilterMedia(general.MY_MEDIA)}

                <Page>
                  {isFetching ? (
                    <Spinner />
                  ) : showMyMedia ? (
                    this.renderMedia(data, general.MY_MEDIA)
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </Page>
              </TabPane>
            )}

            <TabPane
              tab={t('media:organization_library')}
              key={general.ORGANIZATION_LIBRARY}
            >
              {this.searchContainer(general.ORGANIZATION_LIBRARY)}

              {this.renderFilterMedia(general.ORGANIZATION_LIBRARY)}
              <Page>
                {isOrgFetching ? (
                  <Spinner />
                ) : (
                  this.renderMedia(orgLibrary, general.ORGANIZATION_LIBRARY)
                )}
              </Page>
            </TabPane>
            {!isKnowledgeCenter && (
              <TabPane
                tab={t('media:public_library')}
                key={general.PUBLIC_MEDIA}
              >
                {this.searchContainer(general.PUBLIC_MEDIA)}

                {this.renderFilterMedia(general.PUBLIC_MEDIA)}
                <Page>
                  {isPublicFetching ? (
                    <Spinner />
                  ) : (
                    this.renderMedia(publicLibrary, general.PUBLIC_MEDIA)
                  )}
                </Page>
              </TabPane>
            )}
          </Tabs>
        </section>
        <Modal
          className="custom-ant-modal"
          destroyOnClose
          afterClose={this.closeAssetModal}
          width={1200}
          header={null}
          //closable={true}
          footer={null}
          visible={modal}
          onCancel={this.distroyUnityInstance}
          maskClosable={!this.enableCloseModal(uploadProgress, uploadFetching)}
          centered
        >
          <Tabs
            defaultActiveKey="1"
            animated={false}
            onChange={this.onChangeUploadsTab}
          >
            {!editAssets && (
              <TabPane tab={t('v2:upload_assets')} key="1">
                {this.renderDropzone('media')}
              </TabPane>
            )}
            {!isKnowledgeCenter && (
              <TabPane
                tab={
                  editAssets ? t('v2:edit_ar_assets') : t('v2:upload_ar_assets')
                }
                key="2"
              >
                <ARUploads
                  selectedData={selectedData}
                  editAssets={editAssets}
                  distroyUnity={distroyUnity}
                  hideModal={this.hideModal}
                />
              </TabPane>
            )}
            {!isKnowledgeCenter && (
              <TabPane
                tab={
                  editAssets
                    ? t('v2:edit_maze_assets')
                    : t('v2:upload_maze_assets')
                }
                key="3"
              >
                <MazeUploads
                  selectedData={selectedData}
                  editAssets={editAssets}
                  hideModal={this.hideModal}
                />
              </TabPane>
            )}

            {!isKnowledgeCenter && (
              <TabPane tab="Upload Scorm Zip" key="4">
                {this.renderDropzone('scorm')}
              </TabPane>
            )}
          </Tabs>
        </Modal>
        {showStatusModal && (
          <UpdateStatusModal
            mediaLists={mediaNoTopics}
            showModal={showStatusModal}
            loadingModalButtion={loadingModalButtion}
            topics={topics.list}
            ctrlHandleChangeTopic={this.handleCloseStatusModal}
            ctrlMakeMediaStatus={this.makeMediaStatus}
          />
        )}
        {showDeleteModal && (
          <Modal
            visible={showDeleteModal}
            centered
            className="delete-modal"
            title={t('media:delete_warning')}
            onCancel={() => this.setState({ showDeleteModal: false })}
            footer={[
              <Button
                key="back"
                shape="round"
                onClick={() => this.setState({ showDeleteModal: false })}
              >
                No
              </Button>,
              <Button
                key="submit"
                shape="round"
                type="danger"
                onClick={this.deleteMedia}
              >
                Yes
              </Button>
            ]}
          >
            <div>
              <p style={{ color: 'red' }}>{t('media:delete_message')}</p>

              <ul
                style={{
                  listStyle: 'disc',
                  marginLeft: '2rem'
                }}
              >
                <li>
                  If you wish to remove the video's reference to Sharbot, please
                  delete the corresponding transcript along with the video.
                </li>
                <li>
                  Deleting the transcript will remove the associated video/audio
                  reference to Sharebot, but the video will still appear in the
                  media library/channel.
                </li>
              </ul>
            </div>
            <div
              style={{
                backgroundColor: '#f1f1f1',
                borderRadius: '.5rem',
                padding: '1rem'
              }}
            >
              {!_.isEmpty(selectedMedia) &&
                selectedMedia.map((item, index) => (
                  <div className="selectedMedia">
                    <div className="titleMedia">
                      {index + 1}. {item}
                    </div>
                  </div>
                ))}
            </div>
          </Modal>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('media')(Library)
