import styled from 'styled-components'
import { Icon, Tag } from 'antd'
import { styleguide } from '../../../constants'

const { colors, typography } = styleguide

export const Head = styled.section`
  background-color: ${colors.light};
  padding: 1.4rem 0.8rem;
`
export const HeadLink = styled.a`
  font-size: 1.3rem;
  color: ${colors.blue};
  span {
    font-weight: ${typography.h2FontWeight}
  }
`

export const IconLeft = styled(Icon)`
  margin-right: .8rem;
`

export const FlexBox = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
`

export const SearchResultCount = styled.span`
  font-size: 1.1rem;
  margin-left: 1rem;
`

export const FilterBox = styled.div`
  margin-top: 5rem;
  margin-bottom: 2rem;
`

export const FilterTitle = styled.p`
  font-size: 1.1rem;
  text-transform: uppercase;
`

export const FilterTitleLink = styled.a`
  font-weight: ${typography.h2FontWeight};
  color: ${colors.blue};
  text-transform: capitalize;
  margin-left: 1rem;
`

export const FilterSettingBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const CheckableTag = styled(Tag.CheckableTag)`
  background-color: ${colors.light};
  border-radius: 2rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-weight: 300;
  padding: .5rem 1rem;
  margin-right: .5rem;
  height: auto;
  border: 0.1rem solid transparent;
  &.ant-tag-checkable-checked {
    border-color: ${colors.blue};
    color: ${colors.blue};
    background-color: transparent;
  }
`
