import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../../store/actions'
import * as mazeBuilderActions from '../../../../../store/actions/mazeBuilder'

import MazeScenario from './MazeScenario'

export default connect(
  state => ({
    maze: state.maze,
    mazeBuilder: state.mazeBuilder
  }),
  dispatch => ({
    mazeBuilderActions: bindActionCreators(mazeBuilderActions, dispatch),
    handleMazeData: data => dispatch(actions.maze.handleMazeData(data)),
    getWorkshopCourses: params =>
      dispatch(actions.courses.getWorkshopCourses(params)),
    updateMaze: params => dispatch(actions.maze.updateMaze(params))
  })
)(MazeScenario)
