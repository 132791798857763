import {
  ADD_COMMENT,
  CLEAR_DISCUSSIONS,
  FETCH_DISCUSSION_BY_EVENT,
  FETCH_DISCUSSION_BY_EVENT_SUCCESS,
  FETCH_DISCUSSION_BY_EVENT_FAIL,
  LOAD_DISCUSSION_BY_EVENT,
  POST_COMMENT, REPLY_TO,
  EDIT_COMMENT, EDIT_COMMENT_SUCCESS,
  EDIT_COMMENT_FAILURE,
  DELETE_COMMENT,
  DELETE_COMMENT_FAIL, DELETE_COMMENT_SUCCESS,
  EDITING_MESSAGE, DONE_EDITING_MESSAGE,
  FETCH_EVENT_LOG, FETCH_EVENT_LOG_SUCCESS,
  FETCH_EVENT_LOG_FAIL
} from '../types'
import { api } from '../../services'

export const loadDiscussion = params => async dispatch => {
  dispatch({
    type: FETCH_DISCUSSION_BY_EVENT,
    payload: true
  })
  try {
    const { data } = await api.discussion.getById(params.id, params.type)

    dispatch({
      type: LOAD_DISCUSSION_BY_EVENT,
      payload: data
    })

    dispatch({
      type: FETCH_DISCUSSION_BY_EVENT_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: FETCH_DISCUSSION_BY_EVENT_FAIL,
      payload: error
    })
  }
}

export const clearDiscussion = () => ({
  type: CLEAR_DISCUSSIONS,
  payload: null
})

export const editingMessage = () => ({
  type: EDITING_MESSAGE
})

export const stopEditingMessage = () => ({
  type: DONE_EDITING_MESSAGE
})

export const addComment = params => async dispatch => {
  dispatch({
    type: POST_COMMENT,
    payload: true
  })

  api.discussion.add(params.id, params.type, {
    content: params.body,
    parentId: params.parentId
  })
  .then(response => {
    dispatch({
      type: ADD_COMMENT,
      payload: response.data
    })
  })
}

export const deleteComment = commentId => async dispatch => {
  dispatch({
    type: DELETE_COMMENT,
    payload: true
  })

  try {
    const response = await api.discussion.deleteById(commentId)
    if (response) {
      dispatch({
        type: DELETE_COMMENT_SUCCESS,
        payload: commentId
      })
    }
  } catch (error) {
    dispatch({
      type: DELETE_COMMENT_FAIL,
      payload: error
    })
  }
}

export const editComment = data => async dispatch => {
  dispatch({
    type: EDIT_COMMENT,
    payload: true
  })

  try {
    const response = await api.discussion.editById(data.commentId, { content: data.content })
    if (response) {
      dispatch({
        type: EDIT_COMMENT_SUCCESS,
        payload: data
      })
    }
  } catch (error) {
    dispatch({
      type: EDIT_COMMENT_FAILURE,
      payload: error
    })
  }
}

export const replyTo = messageId => ({
  type: REPLY_TO,
  payload: messageId
})

export const getDiscussionLog = params => async dispatch => {
  dispatch({
    type: FETCH_EVENT_LOG,
    payload: true
  })
  try {
    const { data } = await api.discussion.getDiscussionsCount(params.id, params.type)

    dispatch({
      type: FETCH_EVENT_LOG_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: FETCH_EVENT_LOG_FAIL,
      payload: error
    })
  }
}
