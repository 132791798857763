import View from './View'

import actions from '../../../store/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const { clearNotes } = actions.notes
const { getDiscussionLog, clearDiscussion } = actions.discussion

export default connect(
  state => ({
    user: state.user,
    log: state.discussion.log
  }),
  dispatch =>
    bindActionCreators(
      {
        clearNotes,
        getDiscussionLog,
        clearDiscussion
      },
      dispatch
    )
)(View)
