/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'simpan',
    autosaved: 'simpan otomatis',
    back_to: 'kembali ke',
    by: 'oleh',
    chat: 'percakapan',
    completed: 'selesai',
    canceled: 'Dibatalkan',
    component: 'Komponen',
    confirm: 'Konfirmasi',
    course: 'Perkuliahan',
    date: 'Tanggal',
    details: 'Detail',
    empty: 'Tidak ada data',
    enrolled: 'Mengikuti',
    free: 'Gratis',
    graph: 'Grafik',
    loading: 'Memuat...',
    lesson: 'Pelajaran',
    lessons: 'Pelajaran',
    live_assessment: 'Ujian langsung',
    live_event: 'Acara langsung',
    no: 'Tidak',
    of: 'Dari',
    or: 'Atau',
    organization: 'Organisasi',
    page: 'Halaman',
    pages: 'Halaman',
    personal: 'Pribadi',
    poll: 'Poling',
    rating: 'Penilaian',
    reset: 'Setel Ulang',
    signin: 'Masuk',
    signup: 'Daftar',
    soon: 'Segera',
    type: 'Ketik',
    uploading: 'Mengunggah...',
    yes: 'Ya',
    something_went_wrong: 'Terjadi kesalahan!',
    view_all: 'Lihat semua',
    upload_successful: 'Berhasil mengunggah',
    upload_successful_message:
      'Dokumen excel anda berhasil diunggah dan sedang diproses. Hal ini akan membutuhkan beberapa waktu. ',
    check_email_upload_successful:
      'Harap <0>periksa email anda</0> perihal <1>laporan</1> daftar pengguna yang telah diunggah.',
    is_private: 'Pribadi'
  },

  account: {
    title: 'Akun',
    subtitle: 'Kelola pengaturan dan informasi akun anda.',
    saved: 'Akun berhasil dirubah'
  },

  assessments: {
    title: 'Ujian',
    subtitle: 'Buat sebuah ujian',
    finished: 'Ujian telah berakhir',
    countdown: 'Ujian langsung akan disiarkan dalam ',
    create: 'Buat ujian',
    list_title: 'Ujian anda',
    delete_warning: 'Apakah anda yakin akan menghapus item ini?',
    details_title: 'Tambahkan Detail Ujian',
    details_subtitle:
      ' Beri Nama Ujian Anda dan informasikan kepada pemirsa hal yang akan dipelajari dari ujian ini.',
    disclaimer: `Saya menjamin ujian yang saya rekam tidak akan:
    - melanggar hak pihak ketiga
    - mengandung hal ilegal, termasuk umpatan, hal cabul, hal tidak senonoh, kemesuman, pornografi, kekerasan, penghinaan, cemoohan, ancaman, or diskriminasi, bahkan jika disamarkan dengan asterik
    - mengandung serangan pribadi terhadap pihak lain
    - mengandung peniruan terhadap peserta yang lain, publik figur maupun selebriti
    - mengandung rekaman maupun informasi pribadi individu tertentu tanpa disertai persetujuan pihak terkait dan bukan pelanggaran regulasi perlindungan data dimanapun di seluruh dunia
    - mengandung maksud komersil maupun segala bentuk "spam"'`,
    assessment_details: 'Detail Ujian',

    assessment_settings: 'Pengaturan Ujian'
  },

  buildersettings: {
    title: 'Pengaturan Publikasi',
    subtitle:
      'Tambahkan pengaturan acara anda, siapa yang dapat menyaksikan dan berinteraksi dalam acara anda'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Tambah',
    add_page: 'Tambah halaman',
    add_lesson: 'Tambah pelajaran',
    add_answer: 'Tambahkan jawaban baru',
    add_group: 'Tambah Grup',
    add_organization: 'Tambah Organisasi',
    add_question: 'Tambah pertanyaan',
    add_quiz: 'Tambah kuis',
    add_users: 'Tambah pengguna',
    back: 'Kembali',
    back_to_course: 'Kembali ke ringkasan perkuliahan',
    back_to_lesson: 'Kembali ke ringkasan pelajaran',
    back_to_media: 'Kembali ke Pustaka Media',
    back_to_details: 'Kembali ke detail',
    cancel: 'Batal',
    clear: 'Bersihkan',
    close: 'Tutup',
    clone: 'Gandakan',
    close_lesson: 'Tutup Pelajaran',
    clone_course: 'Tutup Perkuliahan',
    confirm: 'Konfirmasi',
    create: 'Buat',
    download: 'Unduh',
    download_mass_user_tamplate: 'Unduh Format',
    add_mass_users: 'Tambah Pengguna Masal',
    upload_excel_list: 'Unggah Daftar Excel',
    delete: 'Hapus Media',
    edit: 'Edit',
    edit_course: 'Edit Perkuliahan',
    enroll: 'Ikuti',
    finish: 'Selesai',
    upload_image: 'Unggah gambar',
    upload_image_video: 'Unggah gambar atau video',
    select_media_library: 'Pilih pustaka media',
    make_public: 'Jadikan untuk Publik',
    make_private: 'Jadikan untuk Pribadi',
    make_org: 'Jadikan untuk Organisasi',
    media: 'Pustaka Media',
    my_theme: 'Temaku',
    next: 'Selanjutnya',
    next_lesson: 'Pelajaran Selanjutnya',
    next_step: 'Langkah selanjutnya',
    previous_step: 'Langkah sebelumnya',
    publish: 'Publikasikan',
    publish_assessment: 'Publikasikan ujian anda',
    publish_course: 'Publikasikan perkuliahan anda',
    publish_event: 'Publikasikan acara anda',
    reset: 'Setel ulang',
    reset_elements: 'Setel ulang elemen',
    save: 'Simpan',
    creating: 'Membuat...',
    updating: 'Memperbaharui...',
    saved: 'Tersimpan',
    save_for_later: 'Simpan untuk nanti',
    save_settings: 'Simpan pengaturan',
    select_files: 'Pilih file',
    select_theme: 'Pilih tema',
    start_lesson: 'Mulai Pelajaran',
    update: 'Perbaharui',
    upload: 'Unggah',
    upload_audio: 'Unggah Audio',
    upload_photo: 'Unggah foto profil',
    upload_video: 'Unggah Video',
    enrolled: 'Mengikuti',
    unenroll: 'Batal Mengikuti',
    from_library: 'Pustaka Media',
    remove: 'Hapus',
    removed: 'Telah dihapus',
    start_broadcast: 'Mulai Siaran',
    end_broadcast: 'Akhiri Siaran',
    camera: 'Kamera',
    screen: 'Layar',
    screen_with_camera: 'Layar dengan Kamera',
    insert: 'Sisipkan',

    inserting: 'Menyisipkan'
  },
  card: {
    quick_add: 'Tambahan cepat',
    add: 'Tambah',
    clone: 'Gandakan',
    completed: 'Pelajaran telah selesai'
  },

  course: {
    upcoming_course: 'Perkuliahan Berikutnya',
    unPublishEvent: 'Acara yang belum dipublikasikan',
    unPublishAssessment: 'Ujian yang belum dipublikasikan',
    pastCourse: 'Pelatihan',
    upcoming: 'Acara langsung berikutnya',
    pastEvent: 'Acara langsung sebelumnya',
    pastAssessment: 'Ujian langsung sebelumnya',
    upcomingAssessment: 'Ujian langsung berikutnya',
    created: 'Tanggal Dibuat:',
    assignees: 'Petugas',
    organisations:
      ' {{ organisasi }} Organisasi, <2> {{ pengguna }} pengguna</2>',
    event_date: 'Tanggal acara langsung <1> {{ tanggal }} </1>',
    delete_warning: 'Apakah anda yakin akan menghapusnya',
    clonning_no_lesson:
      'Harap tambahkan setidaknya satu pelajaran jika hendak menggandakan perkuliahan ini.',
    clone_warning: 'Apakah anda yakin akan menggandakannya',
    clone_success: 'dalam proses penggandaan',
    disclaimer: `Saya menjamin perkuliahan saya tidak:
    - melanggar hak pihak ketiga
    - mengandung hal ilegal, termasuk umpatan, hal cabul, hal tidak senonoh, kemesuman, pornografi, kekerasan, penghinaan, cemoohan, ancaman, or diskriminasi, bahkan jika disamarkan dengan asterik
    - mengandung serangan pribadi terhadap pihak lain
    - mengandung peniruan terhadap peserta yang lain, publik figur maupun selebriti
    - mengandung rekaman maupun informasi pribadi individu tertentu tanpa disertai persetujuan pihak terkait dan bukan pelanggaran regulasi perlindungan data dimanapun di seluruh dunia
    - mengandung maksud komersil maupun segala bentuk "spam"'`
  },

  coursecreate: {
    dropzone_text: 'Sisipkan media',
    sidebar_text: 'Pilih tema <1/> untuk <3/> pelajaran anda',
    delete_warning: 'Apakah anda yakin akan menghapus pelajaran ini?'
  },

  coursepage: {
    quizview_loading: 'Memeriksa jawaban. Harap menunggu...',
    quizview_answer: 'Jawaban Anda'
  },

  coursequiz: {
    title: 'Kuis di setiap Pelajaran'
  },

  dashboard: {
    tooltip_title: 'Jangan tampilkan video ini lagi',
    page_title: 'Statistik Perkuliahan Anda',
    page_subtitle: 'Status perkuliahan yang anda buat',
    profile_add_info:
      'Tambahkan <1>{{uncompletedText}}</1> anda untuk melengkapi profil anda.',
    profile_build:
      'Buat profil anda <1>here</1>, beritahu kami minat dan ketrampilan anda.',
    profile_title: 'Kekuatan Profil',
    more_details: 'Lebih Banyak Detail'
  },

  errors: {
    answer: 'Harap masukkan jawaban',
    answer_correct: 'Pilih jawaban yang benar',
    code: 'Harap masukkan kode anda',
    criterion: 'Harap masukkan kriteria',
    duration: 'Durasi harus diisi',
    element: 'Harap masukkan elemen',
    email: 'Harap masukkan email anda',
    email_invalid: 'Anda memasukkan email yang tidak valid',
    feedback_correct: 'Harap masukkan umpan balik jika benar',
    feedback_incorrect: 'Harap masukkan umpan balik jika salah',
    host: 'Harap masukkan pembawa acara',
    host_assessment: 'Harap masukkan pembawa acara ujian',
    image: 'Harap unggah sebuah gambar',
    location: 'Harap masukkan lokasi anda',
    live_location: 'Harap pilih sebuah lokasi siaran',
    limit: 'Harap masukkan menit lamanya siaran langsung',
    min_3char: 'Minimal 3 karakter',
    number: 'Harap masukkan angka positif.',
    host_email_require: `Email pembawa acara harus diisi.`,
    attendee_email_require: `Email peserta harus diisi.`,
    name: 'Harap masukkan nama anda',
    not_found_course: 'Perkuliahan tidak ditemukan',
    not_found_event: 'Acara tidak ditemukan',
    not_found_assessment: 'Ujian tidak ditemukan',
    password: 'Harap masukkan kata sandi anda',
    password_digit: 'Gunakan setidaknya satu angka',
    password_short: 'Kata sandi terlalu pendek, minimal 10 karakter',
    password_special: 'At least one special character',
    password_uppercase: 'Gunakan setidaknya satu huruf besar',
    password_lowercase: 'Gunakan setidaknya satu huruf kecil',
    phone: 'Harap masukkan nomer telepon anda',
    phone_invalid: 'Nomer telepon tidak valid',
    position: 'Harap masukkan jabatan anda',
    question: 'Harap masukkan pertanyaan',
    start_date: 'Tanggal dan waktu mulai harus diisi',
    tag: 'Harap pilih sebuah penanda',
    topics: 'Harap pilih setidaknya satu topik.',
    live_topics: 'Harap tambahkan topik',
    title: 'Harap masukkan judul',
    title_assessment: 'Harap masukkan judul ujian',
    title_course: 'Harap masukkan judul perkuliahan',
    title_empty: 'Judul tidak boleh dikosongkan',
    title_event: 'Harap masukkan judul acara',
    title_lesson: 'Harap masukkan judul pelajaran',
    title_quiz: 'Harap masukkan nama kuis',
    username: 'Harap masukkan nama pengguna anda',
    upload_error: 'Kesalahan – File tidak kompatibel!',
    upload_more_than_10: 'Maksimal 10 file sekali unggah.',
    topics_max: 'Harap tambahkan maksimal 3 topik.',
    upload_excel: 'Harap pastikan anda mengunggah file yang benar.',
    title_excel: 'Kesalahan mengunggah format:',
    file_size_exceed: 'Ukuran file terlalu besar',
    subtitle_excel:
      'Harap pastikan anda menggunakan format yang disediakan dan kolom diisi dengan benar.',
    select_broadcast_option:
      'Harap pilih sebuah opsi untuk menyiarkan acara ini',
    multi_hosts_number_error:
      'Jumlah penyaji tidak boleh melebihi yang telah ditentukan untuk acara.',
    conference_people_number_error: `Jumlah peserta tidak boleh melebihi yang telah ditentukan untuk acara.`,
    select_max_host: 'Pilih setidaknya 1 penyaji untuk acara.',
    select_max_conference: 'Pilih setidaknya 1 peserta untuk acara.',
    stream_stop_error: 'Siaran tiba-tiba berhenti!',
    unsecure_context:
      'Kesalahan Fatal: Peramban tidak dapat mengakses kamera dan mikropon disebabkan konteks yang tidak aman. Harap pasang SSL dan akses melalui https',
    ws_not_supported:
      'Kesalahan Fatal: WebSocket tidak didukung oleh peramban ini',
    no_org: 'Pilih setidaknya 1 organisasi untuk melanjutkan.',
    no_org_group: 'Pilih setidaknya 1 grup organisasi untuk melanjutkan.',
    waiting_to_join: 'Menunggu seseorang untuk bergabung!'
  },

  events: {
    title: 'Tambahkan Detail Acara Langsung',
    change_host: 'Ganti Pembawa Acara',
    finished: 'Acara telah berakhir',
    countdown: 'Acara Langsung akan disiarkan dalam ',
    subtitle:
      'Beri nama Acara Langsung anda dan informasikan kepada pemirsa hal yang akan dipelajari dari acara ini.',
    poll_disabled: 'Poling tidak tersedia dalam acara ini',
    disclaimer: `Saya menjamin bahwa rekaman acara saya tidak akan:
- melanggar hak pihak ketiga
    - mengandung hal ilegal, termasuk umpatan, hal cabul, hal tidak senonoh, kemesuman, pornografi, kekerasan, penghinaan, cemoohan, ancaman, or diskriminasi, bahkan jika disamarkan dengan asterik
    - mengandung serangan pribadi terhadap pihak lain
    - mengandung peniruan terhadap peserta yang lain, publik figur maupun selebriti
    - mengandung rekaman maupun informasi pribadi individu tertentu tanpa disertai persetujuan pihak terkait dan bukan pelanggaran regulasi perlindungan data dimanapun di seluruh dunia
    - mengandung maksud komersil maupun segala bentuk "spam"'`,
    prompt_message:
      'Meninggalkan halaman ini akan menyelesaikan acara anda, Apakah anda yakin? ',
    duration_exceed_message:
      'Siaran anda melebihi waktu yang ditentukan, aplikasi akan menghentikan siaran.',
    before_schedule_message:
      'Anda akan memulai siaran sebelum waktu yang dijadwalkan, harap diperhatikan bahwa menghentikan siaran akan mengakhiri acara.',
    browser_unsupportive_message:
      'Peramban anda tidak mendukung screen share. Anda dapat melihat peramban yang mendukung di sini',
    cancel_switch_host_message:
      'Pembawa acara tidak tersedia. Acara akan dilanjutkan dari sisi anda.',
    coaching_prompt_message:
      'Anda tidak dapat meninggalkan halaman ini sampai acara berakhir.',
    event_modal_warn: 'Apakah anda ingin meninggalkan acara ini?',
    confirm_message: 'Acara anda akan berakhir jika anda memilih OK.',
    screen_share_denied: 'Anda menolak untuk membagikan layar anda',
    media_not_found:
      'Kamera dan Mikropon tidak ditemukan atau tidak diijinkan di alat yang anda gunakan.',
    media_not_access:
      'Kamera atau Mikropon sedang digunakan oleh proses lain yang tidak mengijinkan aplikasi untuk membacanya',
    constraint_error:
      'Komponen sesuai batasan audio dan video yang anda tentukan tidak ditemukan. Harap merubah batasan audio dan video',
    media_access_denied: 'Anda tidak diijinkan mengakses kamera dan mikropon.'
  },

  filters: {
    days7: '7 hari terakhir',
    days15: '15 hari terakhir',
    days30: '30 hari terakhir',
    days365: '365 hari terakhir',
    date: 'Tanggal dibuat',
    relevance: 'Relevansi',
    popularity: 'Popularitas',
    name: 'Nama',
    group: 'Grup',
    Newest_to_Oldest: 'Dari yang Terbaru',
    Oldest_to_Newest: 'Dari yang Terlama',
    A_Z: 'A ke Z',
    Z_A: 'Z ke A'
  },

  helpers: {
    password:
      'Demi memastikan keamanan akun anda, kami mengharuskan penggunaan setidaknya satu huruf kecil dan angka dalam kata sandi anda, dengan panjang minimal 10 karakter.',
    phone: 'Harap sertakan kode telepon negara anda, contoh: +65',
    username:
      'Nama pengguna anda diberlakukan sebagai informasi publik dan akan dibagikan kepada pengguna lain sesuai kebutuhan. Oleh sebab itu maka kami menyarankan anda untuk tidak menggunakan data pribadi dalam nama pengguna anda',
    public_description_normal:
      ' <0><0/></0> anda telah disimpan dan dikategorikan Pribadi secara default. Hal ini berarti hanya anda yang dapat melihat isinya. Untuk mempublikasikannya anda dapat memilih salah satu opsi dibawah. Dengan mempublikasikan perkuliahan anda berarti ini akan tersedia bagi semua pengguna di platform. Opsi pengguna tertentu akan memperbolehkan anda membagikan perkuliahan anda hanya kepada pengguna tertentu menggunakan alamat email yang didaftarkan dalam akun Sharelooknya.',
    public_description_admin:
      ' <0><0/></0> telah disimpan dan dikategorikan Pribadi secara default. Hal ini berarti hanya anda (dan pembuatnya) yang dapat melihat isinya. Untuk mempublikasikannya anda dapat memilih salah satu opsi dibawah. Dengan mempublikasikan perkuliahan anda berarti ini akan tersedia bagi semua pengguna di platform – termasuk bagi pengguna di luar organisasi anda. Mempublikasikannya bagi organisasi berarti pengguna dalam organisasi dapat melihatnya. Anda juga dapat mempublikasikannya bagi 1 grup atau lebih yang berarti hanya anggota grup tersebut yang dapat melihatnya. Terakhir anda dapat memilih untuk mempublikasikannya kepada pengguna tertentu di dalam dan di luar organisasi anda dengan menggunakan alamat email akun Sharelooknya. '
  },

  labels: {
    add: 'Tambah',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Tipe Jawaban',
    answers: 'Jawaban',
    back_to_quiz: 'Kembali ke daftar kuis',
    back_to_question: 'Kembali ke daftar pertanyaan',
    chat: 'Percakapan',
    chat_available: 'Percakapan tidak tersedia dalam acara ini',
    chat_report: 'Laporan Percakapan',
    choice_single: 'Pilihan Tunggal',
    choice_multiple: 'Pilihan Ganda',
    choice_free: 'Pilihan "Bebas"',
    choice_sorting: 'Pilihan "Urutan"',
    choice_matrix: 'Pilihan "Urutan Matriks"',
    clone_lesson_permission: 'Ijin Penggandaan',
    code: 'Kode',
    correct: 'Benar',
    created: 'Tanggal dibuat',
    clone_modal: 'Gandakan Perkuliahan',
    cloning_course: 'Perkuliahan... sedang digandakan',
    cloning: 'Menggandakan...',
    cloning_course_desc: 'Proses ini membutuhkan waktu. Harap bersabar',
    cloning_success: 'Perkuliahan "<0/>" berhasil digandakan!',
    description: 'Deskripsi',
    disclaimer: 'Sangkalan',
    drop_file: 'Taruh file di sini...',
    duration: 'Durasi',
    edit: 'Edit',
    email: 'Email',
    event: 'Acara',
    event_all: 'Semua Acara',
    event_date: 'Tanggal/Waktu Lokal Acara Langsung',
    event_this: 'Acara ini',
    expire: 'Berakhir dalam',
    expiry_date: 'Tanggal Berakhir',
    forgot: 'Lupa kata sandi?',
    group: 'Grup',
    groups_list: 'Daftar Grup',
    host: 'Pembawa Acara',
    id_annotation: 'id Keterangan',
    id_quiz: 'id Kuis',
    incorrect: 'Salah',
    interests: 'Minat',
    invited: 'Diundang',
    live_chat: 'Diskusi Langsung',
    live_stream_limit: 'menit Siaran Langsung',
    contact_text: 'Untuk merubah batas siaran langsung bulanan harap hubungi',
    contact_admin:
      '<0>Jika anda ingin merubah batas siaran langsung bulanan anda silahkan menghubungi </0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Menit siaran langsung tersisa: <0>{{ limit }}</0> menit <1/><2/> <3>Untuk merubah batas siaran langsung bulanan anda silahkan menghubungi </3> <4>admin@sharelook.com</4>',
    location: 'Lokasi',
    lessons: 'Pelajaran',
    location_broadcast: 'Lokasi server pembawa acara',
    location_broadcast_subtitle:
      'Tambahkan lokasi terdekat dengan lokasi pembawa acara untuk mengurangi jeda audiovisual',
    media_manager: 'Pengelola media',
    minutes: 'Menit',
    name: 'Nama',
    online_quiz: 'Kuis Daring',
    organiser: 'Pembuat',
    organization: 'Organisasi',
    overall_emotion: 'Keseluruhan Kesan:',
    organizations_list: 'Daftar Organisasi',
    password: 'Kata sandi',
    participants: 'Peserta',
    picture: 'Gambar (16:9)',
    phone: 'Telepon',
    photo: 'Foto',
    poll: 'Poll',
    position: 'Jabatan',
    processing: 'Memproses',
    privacy_settings: 'Pengaturan Privasi',
    question: 'Pertanyaan',
    quiz_create: 'Buat Kuis Baru',
    quiz_name: 'Nama Kuis',
    quiz_update: 'Perbaharui Kuis',
    select_organization: 'Pilih Organisasi',
    settings: 'Pengaturan',
    show: 'Tampilkan',
    show_graph: 'Tampilkan hasil Grafik',
    signin: 'Masuk',
    signup: 'Daftar',
    sort_by: 'Urut berdasarkan',
    start_date: 'Tanggal Mulai',
    tags: 'Penanda',
    terms: 'Saya menyetujui <1>syarat dan ketentuan</1> ini.',
    title: 'Judul',
    topics: 'Topik',
    topic: 'Topik',
    total_comments: 'Total Komentar',
    username: 'Nama Pengguna',
    users_list: 'Daftar Pengguna',
    users_emails: 'Sisipkan email pengguna tertentu',
    description_specifiction_email:
      ' Harap masukkan 1 alamat email per baris untuk membagikan dengan beberapa pengguna tertentu..',
    message_specific_email: 'Harap masukkan email pengguna tertentu.',
    viewers: 'Pemirsa',
    edit_course: 'Edit',
    edit_assessment: 'Edit',
    edit_event: 'Edit',
    delete_course: 'Hapus',
    delete_event: 'Hapus',
    delete_assessment: 'Hapus',
    view_course: 'Lihat',
    view_event: 'Lihat',
    view_assessment: 'Lihat',
    date_event: 'Tanggal Acara langsung',
    date_assessment: 'Tanggal Ujian langsung',
    clone_of: 'Gandakan',
    clone_course:
      'Buat salinan perkuliahan ini yang dapat anda modifikasi secara independen, termasuk pelajaran dan halamannya.',
    make: 'Buat',
    available_to: 'Tersedia bagi semua pengguna ShareLook',
    uploaded_by: 'Diunggah oleh: ',
    course: 'Perkuliahan',
    assessment: 'Ujian',
    user_not_exist:
      'Pengguna tidak ditemukan, apakah anda ingin mengirim undangan?',
    check_user: 'Perhatikan',
    user_not_exist_in_org:
      'Pengguna tidak ditemukan dalam organisasi ini, apakah anda ingin mengirim undangan?',
    title_modal_stream: 'Rubah Batas Siaran Langsung Organisasi',
    des_warning_stream: 'Anda akan merubah',
    monthly_stream: 'Batas waktu siaran langsung bulanan:',
    min_per_month: 'menit/bulan',
    broadcast_label: 'Saya ingin:',
    hosts_emails: `Sisipkan email pembawa acara tertentu `,
    attendee_emails: `Sisipkan email peserta tertentu `,
    description_host_specifiction_email:
      'Harap masukkan 1 alamat email per baris.',
    presenter_label: 'Penyaji',
    presenter_label_conference: 'Jumlah orang',
    event_privacy: 'Mode Konferensi'
  },

  broadcastOptions: {
    stream_from_phone: 'Siaran Langsung dengan menggunakan telepon saya',
    other_webrtc: 'Webinar dari komputer saya',
    multihost: 'Webinar Multicast dari komputer saya',
    peer2peer: 'Sesi Pelatihan',
    conference: 'Konferensi Langsung'
  },

  lessoncreate: {
    title: 'Tambahkan Detail Pelajaran',
    subtitle:
      ' Beri nama Pelajaran anda dan informasikan kepada pemirsa hal yang akan dipelajari dari acara ini.',
    input_label_title: 'Judul Pelajaran',
    input_label_topics: 'Topik Pelajaran',
    input_label_description: 'Deskripsi Pelajaran',
    delete_warning: 'Apakah anda yakin akan menghapus halaman ini?'
  },

  lessonoverview: {
    title: 'Tambah Pelajaran',
    subtitle: 'Tambah pelajaran yang sudah ada atau buat yang baru',
    new_lesson: 'Buat Pelajaran Baru',
    new_lesson_sub: 'Buat pelajaran baru dari awal!',
    existing_lesson: 'Pilih pelajaran yang sudah ada',
    existing_lesson_sub:
      'Hubungkan ke pelajaran yang sudah ada, merubah pelajaran ini akan merubah pelajaran yang sama di Perkuliahan lainnya.',
    clone_lesson: 'duplikasi pelajaran yang sudah ada',
    clone_lesson_sub:
      'Buat duplikasi unik dari pelajaran di perkuliahan ini, merubah  pelajaran ini tidak akan merubah pelajaran aslinya.',
    clone_lesson_modal_sub:
      'Klik tombol gandakan dibawah pelajaran yang ingin anda tambahkan ke perkuliahan.'
  },

  management: {
    groups_title: 'Kelola Grup',
    delete_warning: 'Apakah anda yakin?',
    organizations_title: 'Organisasi saya',
    organizations_subtitle: 'Tambahkan organisasi ke daftar anda',
    users_title: 'Kelola Pengguna',
    users_position: 'Senior Account Manager',
    user_not_found: 'Pengguna tidak ditemukan',
    select_org: 'Harap cari dan pilih organisasi untuk mengelola pengguna'
  },

  audio: {
    modal_title: 'Sisipkan Audio',
    list_view: 'Tampilan daftar',
    create_audio_modal_title: 'Buat Audio',
    edit_audio_modal_title: 'Edit Audio',
    public_audio_not_found: 'Audio tidak ditemukan.',
    private_audio_not_found: 'Anda belum mengunggah audio',
    are_you_sure_to_delete: 'Apakah anda yakin akan menghapus?',
    type_something: 'Ketik sesuatu',
    select_voice: 'Pilih',
    file_name: 'Nama File',
    description: 'Deskripsi',
    topic: 'Topik',
    key_words_tag: 'Kata Kunci Penanda',
    mp3_audio: 'Audio MP3',
    created: 'Dibuat',
    duration: 'Durasi',
    close: 'tutup'
  },

  image: {
    modal_title: 'Sisipkan Gambar',
    image: 'Gambar',
    pixel: 'Piksel',
    dimension: 'Ukuran',
    public_image_not_found: 'Gambar tidak ditemukan.',
    private_image_not_found: 'Anda belum mengunggah gambar'
  },

  annotation: {
    modal_title: 'Keterangan'
  },

  text: {
    modal_title: 'Editor Teks'
  },

  video: {
    modal_title: 'Sisipkan Video',
    image: 'Video',
    mp4_video: 'Video MP4',
    public_video_not_found: 'Video tidak ditemukan.',
    private_video_not_found: 'Anda belum mengunggah video'
  },

  media: {
    title: 'Pustaka Media',
    subtitle: 'Pustaka file anda',
    modal_title: 'Unggah media',
    delete_warning: 'Konfirmasi penghapusan',
    delete_message:
      'Media yang telah dihapus akan tetap muncul pada halaman media tersebut digunakan',
    dropzone_text: 'atau Seret dan Taruh',
    delete_success: 'Media berhasil dihapus',
    upload_file: 'Unggah file',
    drag_drop: ' atau Seret dan Taruh ',
    import_file: 'Impor file dari komputer anda',
    no_media: 'Anda belum mengunggah media',
    media_type: 'Tipe Media',
    video: 'Video',
    image: 'Gambar',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Media tidak ditemukan.',
    of: 'dari',
    disclaimer: `Saya menjamin bahwa media saya tidak:
    - melanggar hak pihak ketiga
    - mengandung hal ilegal, termasuk umpatan, hal cabul, hal tidak senonoh, kemesuman, pornografi, kekerasan, penghinaan, cemoohan, ancaman, or diskriminasi, bahkan jika disamarkan dengan asterik
    - mengandung serangan pribadi terhadap pihak lain
    - mengandung peniruan terhadap peserta yang lain, publik figur maupun selebriti
    - mengandung rekaman maupun informasi pribadi individu tertentu tanpa disertai persetujuan pihak terkait dan bukan pelanggaran regulasi perlindungan data dimanapun di seluruh dunia
    - mengandung maksud komersil maupun segala bentuk "spam"`,
    my_media: 'Mediaku',
    public_library: 'Perpustakaan Publik',
    organization_library: 'Perpustakaan Organisasi',
    upload_from_computer: 'Unggah dari Komputer',
    create: 'Buat',
    all: 'Semua',
    male: 'Pria',
    female: 'Wanita',
    processing_audio: 'Memproses Audio',
    create_annotation: 'Beri Keterangan',
    popup_over_lesson_text:
      'Keterangan akan muncul di laman pelajaran setelah di klik',
    edit_detail: 'Hapus Detail',
    delete: 'Hapus',
    go_create_audio: 'Buat Audio',
    show_more_details: 'Tampilkan lebih banyak detail',
    show_less_details: 'Tampilkan lebih sedikit detail',
    drop_files: 'Taruh file dan kemana diunggah',
    or: 'Atau',
    import_files: 'Impor file dari komputer'
  },

  navigation: {
    account: 'Akun',
    assessments: 'Ujian',
    dashboard: 'Dasbor',
    groups: 'Pengelolaan Grup',
    logout: 'Keluar',
    organisations: 'Organisasi',
    organizations_users: 'Organisasi & Pengguna',
    reports: 'Laporan',
    schedule: 'Jadwal',
    nowLive: 'Siaran',
    training: 'Latihan',
    users: 'Pengguna',
    workshop: 'Ruang Kerjaku'
  },

  onboarding: {
    step1_title: 'Langkah 1 : Beritahukan Kami tentang Diri Anda',
    step1_subtitle:
      'Siapa nama anda? Anda berasal dari? Apa sebutan bagi pekerjaan anda?',
    step2_title: 'Langkah 2 : Pilih keterampilan yang dapat anda kembangkan',
    step2_subtitle:
      'Selanjutnya, beritahu kami apa yang ingin anda pahami lebih jauh. Mungkin sesuatu yang belum anda pelajari? Bidang studi yang baru? Apa yang dilakukan kekasih anda?'
  },

  pagebuilder: {
    title: 'Buat Pelajaran',
    subtitle:
      'Waktunya membuat konten bagi pemirsa untuk belajar dari anda, anda dapat menambahkan gambar, video maupun infografik',
    select_layout: 'Pilih tata letak halaman',
    template1: 'Kosong',
    template2: 'Judul & Sub judul',
    template3: 'Judul & Teks',
    template4: 'Judul, Media & Teks',
    template5: 'Judul & Media',
    template6: 'Judul & Kuis'
  },

  pageconstructor: {
    title: 'Buat dan Gandakan Pelajaran',
    subtitle:
      ' Waktunya membuat konten bagi pemirsa untuk belajar dari anda, anda dapat menambahkan gambar, video maupun infografik'
  },

  passwordreset: {
    success_coded:
      'Kami telah mengirimkan email berisi kode konfirmasi ke alamat email yang anda sediakan, harap salin kode tersebut ke dalam bidang di bawah untuk mengatur kata sandi yang baru.',
    success_changed:
      'Anda berhasil mengatur kata sandi yang baru. Silahkan masuk.'
  },

  placeholders: {
    search: 'Cari',
    answer: 'Jawab',
    chat: 'Katakan sesuatu...',
    code: 'Kode',
    criterion: 'Kriteria',
    date_event: 'Pilih tanggal & waktu',
    date_start: 'Tanggal Mulai',
    date_expire: 'Tanggal Berakhir',
    description: 'Deskripsi',
    elements: 'Urutkan elemen',
    email: 'Email',
    feedback_correct: 'Umpan Balik sesuai',
    feedback_incorrect: 'Umpan Balik tidak sesuai',
    host: 'Pembawa Acara',
    keywords: 'Cari Kata Kunci',
    lesson_description: 'Deskripsi Pelajaran',
    location: 'Lokasi',
    minutes: 'Menit',
    name: 'Nama',
    organiser: 'Pembuat',
    organization: 'Organisasi',
    password: 'Kata Sandi',
    phone: 'Telepon',
    photo: 'Foto',
    picture: 'Gambar',
    point: 'Poin',
    position: 'Jabatan',
    question: 'Pertanyaan',
    quiz: 'Nama Kuis',
    search_course: 'Cari berdasarkan Perkuliahan',
    search_media: 'Cari Media',
    search_name: 'Cari berdasarkan Nama',
    search_title: 'Cari berdasarkan Judul',
    search_training: 'Cari Latihan',
    settings: 'Pengaturan',
    tags: 'Penanda',
    title: 'Judul',
    topics: 'Topik',
    username: 'Nama Pengguna',
    keyword: 'Kata Kunci',
    max_500_characters: 'Maks 500 Karakter ',
    no_description: 'File ini tidak memiliki deskripsi',
    no_keyword: 'File ini tidak memiliki kata kunci',
    no_topics: 'File ini tidak memiliki set topik',
    feedback: 'Pesan',
    contact: 'Bantuan apa yang anda butuhkan?'
  },

  publishsettings: {
    title: 'Pengaturan Publikasi',
    event_subtitle:
      'Tambahkan pengaturan acara anda, siapa yang dapat menyaksikan dan berinteraksi dalam acara anda',
    assessment_subtitile:
      ' Beri nama Ujian anda dan informasikan kepada pemirsa hal yang akan dipelajari dari acara ini',
    course_subtitle:
      'Tambahkan pengaturan perkuliahan anda, siapa yang dapat menyaksikan dan berinteraksi dalam acara anda',
    disclaimer: `Sangkalan

1.	Pendaftaran

    Dengan mendaftar sebagai pengguna/membuat sebuah akun maka anda menyatakan setuju terhadap (Perjanjian Pengguna) kami dan mengakui telah membaca (Kebijakan Privasi) kami


2.	Membuat perkuliahan

Dengan menggunakan aplikasi kami dan membuat muatan perkuliahan di dalamnya, anda menyatakan setuju terhadap (Perjanjian Pengguna) dan mengakui telah membaca (Kebijakan Privasi) kami



3.	Membuat acara

Dengan menggunakan aplikasi kami dan membuat sebuah acara di dalamnya, anda menyatakan setuju terhadap (Perjanjian Pengguna) dan mengakui telah membaca (Kebijakan Privasi) kami


4.	Membuat ujian

Dengan menggunakan aplikasi kami dan membuat ujian di dalamnya, anda menyatakan setuju terhadap (Perjanjian Pengguna) dan mengakui telah membaca (Kebijakan Privasi) kami`,

    dates_validation_error: 'Kedua tanggal harus diisi.',
    emails_validation_error: 'Daftar Email tidak valid.',
    specific_email_validation_error: 'Hanya satu email yang diperbolehkan.',
    i_agree_to_these: 'Saya menyetujuinya ',
    terms_and_conditions: 'syarat dan ketentuan.',
    PublicDescription:
      'Pengguna Independen Gratis: (opsi pribadi dan publik) [Perkuliahan|Acara|Ujian] anda telah disimpan dan dikategorikan Pribadi secara default. Hal ini berarti hanya anda yang dapat melihat isinya. Untuk mempublikasikannya anda dapat memilih salah satu opsi di bawah. Dengan mempublikasikan perkuliahan anda berarti ini akan tersedia bagi semua pengguna di platform.',
    OrganisationsDescription:
      'Pengguna Organisasi: (Daripada memilih “SELESAI” kami menyarankan untuk menggunakan “PUBLIKASIKAN” dan pindahkan ke halaman publikasi sebagaimana pengguna lainnya namun tanpa opsi publikasi.) [Perkuliahan|Acara|Ujian] anda telah disimpan dan dikategorikan Pribadi. Hal ini berarti hanya anda dan administrator Organisasi anda yang dapat melihatnya. Sebagai pengguna anda tidak dapat mempublikasikan konten anda dalam organisasi, karena untuk melakukannya anda membutuhkan hak administrator. Harap hubungi Administrator anda untuk meminta dipublikasikannya konten anda, kepada seluruh organisasi, grup tertentu atau ke pengguna tertentu.',
    OrganisationGroupsDescription:
      'Admin Organisasi: (Untuk konten yang dibuat di d alam organisasi: Pribadi, Publik, Organisasi, Grup, Pengguna tertentu): [Perkuliahan|Acara|Ujian] ini telah disimpan dan dikategorikan Pribadi secara default. Hal ini berarti hanya anda (dan pembuat konten) yang dapat melihat konten ini. Untuk mempublikasikan konten ini anda dapat memilih salah satu opsi di bawah. Dengan mempublikasikan perkuliahan anda berarti ini akan tersedia bagi semua pengguna di platform – termasuk bagi pengguna di luar organisasi anda. Mempublikasikannya bagi organisasi berarti pengguna dalam organisasi dapat melihatnya. Anda juga dapat mempublikasikannya bagi 1 grup atau lebih yang berarti hanya anggota grup tersebut yang dapat melihatnya. Terakhir anda dapat memilih untuk mempublikasikannya kepada pengguna tertentu di dalam dan di luar organisasi anda dengan menggunakan alamat email akun Sharelooknya.',
    SpecificUsersDescription:
      'Pengguna Independen Berbayar: (pribadi, publik dan pengguna tertentu) [Perkuliahan|Acara|Ujian] anda telah disimpan dan dikategorikan Pribadi secara default. Hal ini berarti hanya anda yang dapat melihat isinya. Untuk mempublikasikannya anda dapat memilih salah satu opsi di bawah. Dengan mempublikasikan perkuliahan anda berarti ini akan tersedia bagi semua pengguna di platform. Opsi pengguna tertentu akan memperbolehkan anda membagikan perkuliahan anda hanya kepada pengguna tertentu menggunakan alamat email yang didaftarkan dalam akun Sharelooknya.'
  },

  quiz: {
    quiz_empty: 'Anda belum membuat kuis',
    questions_empty: 'Anda belum membuat pertanyaan'
  },

  regions: {
    asia_pacific_australia: 'Australia',
    asia_pacific_india: 'India',
    asia_pacific_japan: 'Jepang',
    asia_pacific_s_korea: 'Korea Selatan',
    asia_pacific_singapore: 'Singapura',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgia',
    eu_germany: 'Jerman',
    eu_ireland: 'Irlandia',
    south_america_brazil: 'Brasil',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: 'Carolina Selatan',
    us_east_virginia: 'Virginia',
    us_west_california: 'California',
    us_west_oregon: 'Oregon'
  },

  reports: {
    title: 'Laporan',
    workshop_report: 'Laporan Ruang Kerja',
    user_report: 'Laporan Pengguna',
    organization_workshop: 'Ruang Kerja Organisasi',
    course: 'Perkuliahan',
    live_event: 'Acara Langsung',
    workShop_graph: 'Grafik Ruang Kerja',
    organization_account: 'Akun Organisasi',
    all: 'Semua',
    name: 'Nama',
    id_number: 'Nomer ID',
    email: 'Email',
    search_user_report: 'Cari Laporan Pengguna',
    participant: 'Peserta',
    participants: 'Peserta',
    host: 'Pembawa Acara',
    username: 'Nama Pengguna',
    event: 'Acara',
    quiz: 'Kuis',
    assessment: 'Ujian',
    time: 'Waktu',
    no_recent_activity: 'Tidak Ada Aktivitas terbaru',
    workshops: 'Ruang Kerja',
    courses: 'Perkuliahan',
    live_events: 'Acara Langsung',
    live_assessments: 'Ujian Langsung',
    quizzes: 'Kuis',
    overview: 'Ringkasan',
    by: 'Oleh',
    views: 'Dilihat',
    status: 'Status',
    poll: 'Poling',
    comment: 'Komentar',
    comments: 'Komentar',
    no_comments: 'Tidak ada komentar',
    no_polls: 'Tidak ada Poling',
    live_date: 'Tanggal disiarkan langsung',
    score: 'Nilai',
    question: 'Pertanyaan',
    submit: 'Kirim',
    recent_activity: 'Aktivitas Terbaru',
    ago: 'Sebelumnya',
    no_data_found: 'Data tidak ditemukan',
    start_end_live: 'Mulai / Akhiri Siaran Langsung',
    join_leave_date: 'Bergabung / Tinggalkan',
    watched_time: 'Durasi melihat',
    complete: 'Selesai',
    in_complete: 'Belum Selesai',
    today: 'Hari ini',
    seven_days: '7 Hari',
    last_month: 'Sebulan terakhir',
    one_year: '1 Tahun',
    all_time: 'Sepanjang waktu',
    report: 'Laporan',
    from_date: 'Mulai tanggal',
    to_date: 'Hingga tanggal',
    all_workshop: 'Semua Ruang Kerja',
    all_participants: 'Semua Peserta',
    live_assessment: 'Ujian Langsung',
    users: 'Pengguna',
    download: 'Unduh',
    recent: 'Terkini',
    WorkShop: 'Ruang Kerja',
    Activity: 'Aktivitas',
    created_date: 'Tanggal dibuat',
    lessons: 'Pelajaran',
    lesson: 'Pelajaran',
    completed: 'Telah selesai',
    report_download: 'Unduh Laporan',
    this_month: 'Bulan ini',
    search_course: 'Cari Perkuliahan',
    participants_graph: 'Grafik Peserta',
    viewed: 'Telah ditonton',
    enrolled: 'Mengikuti',
    description: 'Deskripsi',
    topic: 'Topik',
    analytic: 'Analitik',
    quiz_list: 'Daftar Kuis',
    select_one: 'Pilih Satu',
    group_status: 'Status Grup',
    duration_min: 'Durasi (menit)',
    submit_poll: 'Kirim Poling',
    download_events: 'Unduh Acara',
    search_event: 'Cari Acara',
    duration: 'Durasi',
    total_participants: 'Total Peserta',
    message: 'Pesan',
    avg_message: 'Rata-rata Pesan',
    per_message: 'PERSENTASE PESAN / ACARA'
  },

  signup: {
    success_step1:
      ' Kami telah mengirimkan email berisi kode konfirmasi ke alamat email yang anda sediakan, harap salin kode tersebut ke dalam bidang di bawah untuk mengkonfirmasi pendaftaran anda.',
    success_step2: 'Terima kasih. Anda berhasil mendaftar.',
    privacy: `Saya menyetujui <1> syarat & ketentuan</1> and <3> kebijakan privasi</3> Sharelook `
  },

  stream: {
    emotion_impressive: 'Mengesankan',
    emotion_unimpressive: 'Tidak Mengesankan',
    message_not_started: 'Segera Hadir',
    message_running: 'Langsung',
    message_processing:
      'Saat ini kami sedang memproses rekaman langsung untuk ditampilkan. Harap periksa kembali nanti untuk melihat video dan menambahkan penilaian serta umpan balik.'
  },

  tabs: {
    audience: 'Pemirsa',
    course: 'Ringkasan Perkuliahan',
    details: 'Detail Acara Langsung',
    description: 'Deskripsi',
    host: 'Pembawa Acara',
    lesson: 'Ringkasan Pelajaran',
    lessons: 'Pelajaran',
    overview: 'Ringkasan',
    page: 'Pembuat Halaman',
    publish: 'Publikasi',
    settings: 'Pengaturan Publikasi',
    total_views: 'Total dilihat',
    live_participates: 'Peserta yang sedang mengikuti',
    live_chats: 'Percakapan Berlangsung',
    live_quiz: 'Poling Berlangsung',
    trainer_rating: 'Penilaian Pelatih',
    trainer_times: 'Jam Terbang Pelatih',
    courses_quizzes: 'Kuis Perkuliahan',
    user_list: 'Daftar Pengguna',
    discussion: 'Diskusi',
    notes: 'Catatanku'
  },

  topics: {
    SALES_TRAINING: 'Pelatihan Sales',
    NEGOTIATION_TRAINING: 'Pelatihan Negosiasi',
    CROSS_CULTURAL_AWARENESS: 'Kesadaran Lintas Kultur',
    NETWORKING_SKILLS: 'Keterampilan Networking',
    SOFTWARE_ARCHITECTURE: 'Arsitektur Perangkat Lunak',
    PROJECT_MANAGEMENT: 'Manajemen Proyek',
    DIGITAL_TRANSFORMATION: 'Transformasi Digital',
    IT_CONSULTING: 'Konsultasi IT',
    MANAGEMENT_CONSULTING: 'Konsultasi Manajemen',
    HR_CONSULTING: 'Konsultasi Sumber Daya Manusia (SDM)',
    INTERNET_OF_THINGS: 'Internet of Things (IoT)',
    CYBERSECURITY: 'Keamanan Siber',
    PRIVACY_AND_GDRP: 'Privasi dan GDPR'
  },

  training: {
    title: 'Pelatihan',
    title_assessment: 'Ujian',
    subtitle: 'Acara Langsung dan Perkuliahan',
    enrolled_title: ' Acara Langsung dan Perkuliahan yang diikuti',
    enrolled_subtitle:
      ' Acara Langsung dan Perkuliahan yang anda ikut di dalamnya',
    enrolled_message:
      'Saat ini anda tidak sedang mengikuti Perkuliahan dan Acara apapun.',
    past_live_event_title: 'Acara Langsung Sebelumnya',
    past_live_event_subTitle: 'Acara Langsung yang telah berakhir',
    past_live_event: 'Saat ini acara langsung sebelumnya tidak ada.',
    recomended_title: 'Acara Langsung dan Perkuliahan yang disarankan',
    now_live_event_title: 'Berlangsung saat ini',
    now_live_event_subTitle:
      'Acara yang sedang siaran langsung atau akan disiarkan dalam waktu dekat',
    now_live_event: 'Saat ini Acara Langsung tidak ada.',
    webinar_event_title: 'Saluran Webinar',
    webinar_event_subTitle: 'Webinar yang telah berakhir',
    webinar_event: 'Saat ini Webinar tidak ada.',
    p2p_event_title: 'Sesi Pelatihan',
    p2p_event_subTitle: 'Sesi Pelatihan yang telah berakhir',
    p2p_event: 'Saat ini sesi pelatihan tidak ada.',

    now_live_assessment_title: 'Sedang berlangsung',
    now_live_assessment_subTitle:
      'Ujian yang sedang siaran langsung atau akan disiarkan dalam waktu dekat ',
    now_live_assessment_message: ' Saat ini Ujian Langsung tidak ada.',

    upcoming_assessment_title: 'Ujian Langsung berikutnya',
    upcoming_assessment_subtitle: ' Ujian Langsung berikutnya ',
    upcoming_assessment_message:
      'Saat ini Ujian Langsung berikutnya tidak ada.',

    past_live_assessment_title: 'Ujian Langsung sebelumnya',
    past_live_assessment_subTitle: 'Ujian Langsung yang telah berakhir',
    past_live_assessment_message:
      ' Saat ini ujian langsung sebelumnya tidak ada.',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Kami telah mengkurasi perkuliahan dan acara langsung berdasarkan <0><0/></0> anda',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Cari Latihan',
    search_assessment: 'Cari Ujian',
    recomended_message:
      'Saat ini Acara Langsung dan Perkuliahan yang disarankan tidak ada.'
  },

  warnings: {
    delete: 'Apakah anda yakin?'
  },

  workshop: {
    title: 'Ruang Kerjaku',
    subtitle: 'Buatlah sendiri perkuliahan atau bawakan siaran langsungmu',
    new_course: 'Buat Perkuliahan Baru',
    new_event: 'Buat acara langsung baru',
    new_assessment: 'Buat ujian langsung baru',
    courses_title: 'Acara Langsung dan Perkuliahan yang telah dibuat',
    courses_subtitle:
      'Edit & Publikasikan rencana acara langsung dan Perkuliahan yang telah dibuat',
    tags_course: 'Perkuliahan',
    tags_live: 'Acara Langsung',
    tags_assessment: 'Ujian Langsung'
  },

  help_center: {
    sidebar: {
      title: 'Bantuan apa yang anda butuhkan?',
      back_to: 'Dasbor'
    },
    menu: {
      help_center: 'Pusat Bantuan',
      whats_new: 'Yang baru',
      feedback: 'Umpan Balik',
      feedback_title: 'Beikan umpan balik untuk sharelook',
      terms_and_conditions: 'Syarat & Ketentuan',
      privacy_policy: 'Kebijakan Privasi',
      contact_us: 'Hubungi kami'
    },
    get_started: {
      title: 'Mulai menggunakan share look',
      description: {
        main:
          'KAMI TELAH MENGINTEGRASIKAN VIDEO LIVE KEDALAM APLIKASI PEMBUAT PERKULIAHAN YANG MUDAH DIGUNAKAN',
        tagline:
          'Bersama ShareLook, sistem siaran langsung yang telah dipatenkan, anda dapat membangun kebersamaan, otomatisasi pembelajaran dan berbagi pengetahuan secara maksimal.'
      }
    },
    whats_new: {
      title: 'Mulai menggunakan share look',
      description: {
        main: `Tahukah anda, pola pembelajaran dengan cara bercerita dapat dibuat dalam waktu singkat dengan biaya murah? Anda harus menggabungkan aplikasi pembuat perkuliahan ShareLook dengan bakat yang tepat. Anda dapat bertanya kepada kami “Bagaimana Caranya”`,
        tagline: ''
      }
    },
    create_workshop: 'Bagaimana cara membuat sebuah Ruang Kerja',
    create_course: 'Bagaimana cara membuat sebuah Perkuliahan',
    create_user: 'Bagaimana cara membuat Pengguna',
    create_group: 'Bagaimana cara membuat sebuah Grup',
    create_webinar: 'Bagaimana cara membuat Webinar',
    create_live_event: 'Bagaimana cara membuat Acara Langsung',
    create_live_assessment: 'Bagaimana cara membuat Ujian Langsung',
    create_live_on_mobile: 'Siaran Langsung dari Gawai',
    personalize_profile: 'Pengaturan Profil',
    manage_group: 'Mengelola Grup',
    report: 'Laporan',
    add_annotations: 'Bagaimana cara menambahkan Keterangan /Popups',
    add_audio: 'Bagaimana cara menambahkan Audio',
    add_header_and_footer: 'Menambahkan Header & Footer',
    add_image: 'Bagaimana cara menambahkan Gambar',
    add_quiz_content: 'Bagaimana cara menambahkan Konten Kuis',
    add_text_content: 'Bagaimana cara menambahkan Konten Teks',
    add_user: 'Bagaimana cara menambahkan pengguna',
    add_video: 'Bagaimana cara menambahkan Video',
    clone_course: 'Bagaimana cara menggandakan Perkuliahan',
    find_live_broadcast: 'Bagaimana cara mencari Siaran Langsung',
    contact_us: {
      title: 'Hubungi kami',
      subject_placeholder: 'Masukkan Subyek *',
      content_placeholder: 'Masukkan Pesan*',
      subject_required: '*Subyek harus diisi',
      content_required: '*Pesan harus diisi',
      submit: 'Kirim',
      cancel: 'Batal',
      sent: 'Terkirim'
    },
    feedback: {
      title: 'Kirim umpan balik ke sharelook',
      attach_title: 'Lampirkan sebuah file',
      choose: 'Pilih file',
      detail: 'File akan dibagikan dengan tim support',
      content_placeholder: 'Masukkan pesan*',
      content_required: '*Pesan harus diisi',
      submit: 'Kirim',
      cancel: 'Batal',
      sent: 'Terkirim'
    }
  },
  discussion: {
    join_discussion: 'Bergabung dalam diskusi'
  },
  notes: {
    title: 'Catatanku',
    add_note: 'Tambah catatan baru'
  }
}
