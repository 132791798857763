import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import actions from '../../../../store/actions/index'
import Preview from './Preview'

export default connect(
  state => ({
    data: state.reports,
    user: state.user,
  }),
  dispatch => ({
    handlingData: bindActionCreators(actions.reports.handlingData, dispatch),
    getEventReport: bindActionCreators(actions.reports.getEventReport, dispatch)
  })
)(Preview)
