/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-13 15:10:37
 * @LastEditTime: 2019-08-16 18:03:42
 * @LastEditors: Please set LastEditors
 */
import http from './http'

function create(data) {
  const { isSendMail, isSendMailToHost } = data
  const query = `?isSendMail=${isSendMail ||
    false}&isSendMailToHost=${isSendMailToHost || false}`
  return http({
    method: 'POST',
    url: `/events${query}`,
    data
  })
}

function checkUser(data) {
  return http({
    method: 'GET',
    url: `/users/check-existing?emails=${data}`
  })
}

const get = params => {
  return http({
    method: 'GET',
    url: '/events',
    params
  })
}

function getById(id) {
  return http({
    method: 'GET',
    url: '/events/' + id
  })
}

function getChatById(id) {
  return http({
    method: 'GET',
    url: '/events/' + id + '/messages'
  })
}

function updateById(id, data) {
  const { isSendMail, isSendMailToHost } = data
  const query = `?isSendMail=${isSendMail ||
    false}&isSendMailToHost=${isSendMailToHost || false}`

  return http({
    method: 'PUT',
    url: `/events/${id}${query}`,
    data
  })

}

function deleteById(id) {
  return http({
    method: 'DELETE',
    url: '/events/' + id
  })
}

/**
 * View event.
 * @param {number} eventId
 */
const viewEvent = eventId => {
  return http({
    method: 'POST',
    url: `/events/${eventId}/view`
  })
}

const startLiveEvent = eventId => {
  return http({
    method: 'POST',
    url: `/events/${eventId}/start`
  })
}

const stopLiveEvent = eventId => {
  return http({
    method: 'POST',
    url: `/events/${eventId}/stop`
  })
}

const getLiveCount = streamId => {
  return http({
    method: 'GET',
    url: `/events/${streamId}/live_count`
  })
}

const createBreakoutEvent = (eventId, rooms) => {
  console.log('room api', rooms)
  return http({
    method: 'PUT',
    url: `/events/${eventId}/add-breaking-room`,
    data: { rooms }
  })
}

const putScoreInAssessment = (params, data) => {
  return http({
    method: 'PUT',
    url: `/events/${params.id}/assessment-score?is_submitted=${params.is_submitted}`,
    data
  })
}

const getAttachements = id => {
  return http({
    method: 'GET',
    url: `/events/${id}/library`
  })
}

const getUploadLink = eventId => {
  return http({
    method: 'GET',
    url: `/events/${eventId}/drawing/sign`
  })
}

const getDownloadLink = eventId => {
  return http({
    method: 'GET',
    url: `/events/${eventId}/drawing`
  })
}
const getPodcastAudioSign = eventId => {
  return http({
    method: 'GET',
    url: `/podcast/${eventId}/sign/`
  })
}
const setSequence = (type, data) => {
  return http({
    method: 'PUT',
    url: `/events/setsequence`,
    data
  })
}

export {
  create,
  get,
  getById,
  getChatById,
  updateById,
  deleteById,
  viewEvent,
  checkUser,
  startLiveEvent,
  stopLiveEvent,
  getLiveCount,
  createBreakoutEvent,
  putScoreInAssessment,
  getAttachements,
  getUploadLink,
  getDownloadLink,
  getPodcastAudioSign,
  setSequence
}
