import React, { Component } from 'react'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { TabsCategories, TabsCharts } from './styled'
import { rangeArray as range } from '../../../../../utils'

import { styleguide } from '../../../../../constants'
import { withTranslation } from 'react-i18next'

const { colors } = styleguide

const format = date => moment(date).format('D MMM')

const buildChartOptions = (dates, data) => ({
  grid: {
    top: '35px',
    left: '35px',
    bottom: '50px',
    right: '50px'
  },
  calculable: true,
  tooltip: {
    trigger: 'axis'
  },
  textStyle: {
    color: colors.darkGreyOne
  },
  xAxis: [{
    type: 'category',
    data: dates,
    axisTick: {
      show: false
    },
    axisLine: {
      lineStyle: {
        color: '#D8D8D8'
      }
    }
  }],
  yAxis: {
    type: 'value',
    minInterval: 1,
    position: 'right',
    interval: 1,
    axisTick: {
      show: false
    },
    axisLine: {
      show: false
    },
    axisLabel: {
      show: false
    },
    splitLine: {
      lineStyle: {
        color: '#ddd'
      }
    }
  },
  series: data
    .map(({ name, value }, index) => ({
      name,
      data: value,
      type: 'line',
      color: index % 2 === 0 ? colors.blue : colors.green
    }))
})

class Tabs extends Component {
  render () {
    const {
      item: {
        viewers = [],
        enrollments = []
      },
      activeFilterValue,
      t
    } = this.props

    const dates = range(activeFilterValue)
      .map(i => moment().subtract(i, 'days').format('D MMM'))
      .reverse()

    const audienceData = enrollments
      .map(i => {
        return i.is_count && ({ date: format(i.created_at), ...i })
      })
      .filter(i => dates.includes(i.date))
      .reduce((acc, item) => {
        const index = dates.findIndex(i => i === item.date)
        acc[index] += 1
        return acc
      }, range(activeFilterValue).map(() => 0))

    const viewsData = viewers
      .map(i => ({ date: format(i.created_at), ...i }))
      .filter(i => dates.includes(i.date))
      .reduce((acc, item) => {
        const index = dates.findIndex(i => i === item.date)
        acc[index] += 1
        return acc
      }, range(activeFilterValue).map(() => 0))

    const tabs = [
      {
        title: t('tabs:total_views'),
        value: viewers.length,
        option: buildChartOptions(dates, [{ name: 'Views', value: viewsData }])
      },
      {
        title: t('tabs:audience'),
        value: _.filter(enrollments, enrollment => enrollment.is_count).length,
        option: buildChartOptions(dates, [{ name: 'Audience', value: audienceData }])
      }
      // {
      //   title: t('rating'),
      //   value: '4.5',
      //   option: buildChartOptions(dates, [{ value: [0, 3.5, 3, 3.8, 4, 4.5, 5] }, { value: [0, 2, 4.2, 2.8, 3.4, 5, 1.2] }])
      // }
    ]

    return (
      <TabsCategories defaultActiveKey='1'>
        <TabsCategories.Tab tab={t('overview')} key='1'>
          <TabsCharts defaultActiveKey='1' type='card'>
            {tabs.map((tab, i) =>
              <TabsCharts.Tab
                tab={
                  <TabsCharts.Tab.Title>
                    <TabsCharts.Tab.Title.Count>{tab.value}</TabsCharts.Tab.Title.Count>
                    {tab.title}
                  </TabsCharts.Tab.Title>
                }
                key={i + 1}
              >
                <ReactEcharts
                  option={tab.option}
                  notMerge
                  lazyUpdate
                />
              </TabsCharts.Tab>
            )}
          </TabsCharts>

          {false && <TabsCategories.Tab.Details to='/'>
            {t('more_details')}
          </TabsCategories.Tab.Details>
          }
        </TabsCategories.Tab>

        {false && <TabsCategories.Tab tab='Traffic' key='2'/>}
      </TabsCategories>
    )
  }
}

export default withTranslation('dashboard')(Tabs)
