import Discussion from './Discussion'
import actions from '../../store/actions'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  user: state.user,
  discussion: state.discussion,
  isFetching: state.discussion.isFetching
})

const mapDispatchToProps = dispatch => ({
  postComment: (id, type, parent, comment) => dispatch(actions.discussion.addComment({ id, type, parent, comment })),
  deleteComment: (commentId) => dispatch(actions.discussion.deleteComment({ commentId: commentId })),
  getDiscussions: (id, type) => dispatch(actions.discussion.loadDiscussion({ id, type }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Discussion)
