import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NoteContainer } from './styled'
import moment from 'moment'
import { config } from '../../../constants'
import { Input } from 'antd'
import ReactSVG from 'react-svg'
import More from '../../../views/Events/Preview/assets/more.svg'
import Edit from '../../../views/Events/Preview/assets/edit.svg'
import Delete from '../../../views/Events/Preview/assets/delete.svg'
import classnames from 'classnames'
import './Notes.scss'

class Note extends Component {
  state = {
    showMoreOptions: false,
    editing: false,
    editedNote: '',
    shown: false
  }

  componentDidMount() {
    this.setState({
      editedNote: this.props.note.body
    })
  }

  toggleShowMore = () => {
    this.props.onClickTabItem(this.props.note.id)
    this.setState({
      showMoreOptions: !this.state.showMoreOptions
    })
  }

  handleEditNote = note => {
    this.props.setEditing()
    this.setState(
      {
        editing: !this.state.edit,
        activeId: note.id
      },
      this.toggleShowMore()
    )
    // if (!this.props.isEditing) {
    //   this.props.editingNotes()
    //   this.setState({
    //     editing: !this.state.edit,
    //     activeId: note.id
    //   }, this.toggleShowMore())
    // }
  }

  handleDeleteNote = async note => {
    await this.props.deleteNote(encodeURIComponent(note.id))
    this.toggleShowMore()
    await this.props.fetchNotes(this.props.noteId, this.props.noteType)
  }

  handleNoteChange = event => {
    this.setState({
      editedNote: event.target.value
    })
  }

  handleKeyPress = async event => {
    this.props.stopEditingNotes()
    if (event.key === 'Enter' && this.state.editedNote.length > 0) {
      await this.props.editNote({
        noteId: encodeURIComponent(this.props.note.id),
        content: this.state.editedNote
      })
      this.setState({
        editing: false,
        showMoreOptions: false
      })
    }
  }

  renderNote = note => {
    return note.body.split('\n').map((item, key) => {
      return <p key={key}>{item}</p>
    })
  }

  renderEditNote = () => {
    return (
      <Input.TextArea
        name="note"
        value={this.state.editedNote}
        onChange={this.handleNoteChange}
        onKeyPress={this.handleKeyPress}
      />
    )
  }

  closeAll = () => {
    this.setState({
      showMoreOptions: false,
      editing: false,
      editedNote: ''
    })
  }

  cancelEdit = () => {
    this.setState({
      editing: false
    })
  }

  render() {
    const {
      note,
      currentUser,
      isLoading,
      userName,
      clickedOutside
    } = this.props
    const { editing, showMoreOptions } = this.state

    return (
      <NoteContainer className="notes-container">
        <NoteContainer.Avatar onMouseOver={this.setShown}>
          <img
            alt=""
            src={config.baseURL + 'users/' + note.user_id + '/avatar'}
            width="50px"
            height="50px"
          />
        </NoteContainer.Avatar>
        <NoteContainer.Main>
          <NoteContainer.Main.Header>
            <NoteContainer.Main.Header.Name>
              {userName}
            </NoteContainer.Main.Header.Name>
            <NoteContainer.Main.Header.Date>
              {moment(note.timestamp).format('lll')}
            </NoteContainer.Main.Header.Date>
            <NoteContainer.Main.Header.More>
              {Number(note.user_id) === currentUser && (
                <ReactSVG
                  src={More}
                  className={classnames(
                    showMoreOptions
                      ? 'notes-more-options-active'
                      : 'notes-more-options'
                  )}
                  onClick={this.toggleShowMore}
                />
              )}
              {!editing && !isLoading && !clickedOutside && showMoreOptions && (
                <NoteContainer.Main.Header.MoreOptions>
                  <div
                    className="more-options-item"
                    onClick={() => this.handleEditNote(note)}
                  >
                    {' '}
                    <img alt="" src={Edit} /> <span>Edit</span>
                  </div>
                  <div
                    className="more-options-item"
                    onClick={() => this.handleDeleteNote(note)}
                  >
                    {' '}
                    <img alt="" src={Delete} /> <span>Delete</span>
                  </div>
                </NoteContainer.Main.Header.MoreOptions>
              )}
            </NoteContainer.Main.Header.More>
          </NoteContainer.Main.Header>
          <NoteContainer.Main.Note>
            {editing && !isLoading && !clickedOutside
              ? this.renderEditNote(note)
              : this.renderNote(note)}
          </NoteContainer.Main.Note>
          {editing && (
            <NoteContainer.Main.Footer>
              <NoteContainer.Main.Footer.Link onClick={this.cancelEdit}>
                Cancel
              </NoteContainer.Main.Footer.Link>
            </NoteContainer.Main.Footer>
          )}
        </NoteContainer.Main>
      </NoteContainer>
    )
  }
}

export default withTranslation()(Note)
