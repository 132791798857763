import React, { useState, useEffect } from 'react'
import './EventMenus.scss'
import moment from 'moment'

import cameraOff from '../../assets/camera_off.svg'
import cameraOn from '../../assets/camera_on.svg'
import micOff from '../../assets/mic_off.svg'
import micOn from '../../assets/mic_on.svg'
import grid from '../../assets/grid.svg'
import leave from '../../assets/leave.svg'
import greenHand from '../../assets/raise.svg'
import yellowHand from '../../assets/yellowHandRaise.svg'
import grayHand from '../../assets/grayHandRaise.svg'
import record from '../../assets/record.svg'
import fullScreen from '../../assets/full_screen.svg'
import shareScreenLogo from '../../assets/share_screen.svg'
import stopShareScreenLogo from '../../assets/stop_screen_share.svg'
import whiteboardLogo from '../../assets/whiteboard.png'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import { Icon, Menu, Popover } from 'antd'

const EventMenus = props => {
  const {
    onScreenShare,
    onClickBreakoutRooms,
    onClickParticipants,
    onClickMute,
    onHandRaise,
    onClickVideo,
    onClickGrid,
    onExitHandRaise,
    isBreakoutRoom = true,
    isParticipants = true,
    isRecord = true,
    isAudio = true,
    isVideo = true,
    videoRef,
    audioRef,
    isScreenSharing,
    isRecording,
    isConfCreator,
    onLeave,
    show: { participants, rooms },
    onEndForAll,
    onClickFullScreen,
    isRemoteScreenSharing,
    isConferenceHost,
    raiseHandAllowed,
    isConferenceParticipant,
    isHandRaised,
    isGridActive,
    raiseHandRequesting,
    allowScreenSharing = true,
    localStream,
    isAgora,
    t,
    isWhiteboardSharing,
    onClickWhiteboardSharing,
    allowWhiteboardSharing = true,
    eventStartTime,
    consumerId,
    userId,
    wbRoomToken
  } = props

  const [wait, setWait] = useState(true) // dont forget: it must be true
  const [isWbButtonEnable, setIsWbButtonEnable] = useState(false)
  const [popoverStatus, setPopoverStatus] = useState(false)

  const closePopover = () => {
    setPopoverStatus(false)
    return popoverStatus
  }

  const handleVisibleChange = value => {
    setPopoverStatus(value)
  }

  const showAudio = isConferenceHost || (isHandRaised && !raiseHandRequesting)
  const showVideo = isConferenceHost

  const handIcon = !isHandRaised
    ? grayHand
    : raiseHandRequesting
    ? yellowHand
    : greenHand
  const raiseHandSubtext = !isHandRaised
    ? t('v2:raise_hand')
    : raiseHandRequesting
    ? t('v2:waiting__')
    : t('v2:audio_active')

  const isScreenSharingActive = isScreenSharing || isRemoteScreenSharing

  const setWaitWhiteboard = async () => {
    var now = moment(new Date())
    var startTime = moment(eventStartTime)
    var waitUntil = moment(startTime).add(1, 'minute')

    if (now.isBefore(waitUntil)) {
      setTimeout(() => {
        setWait(false)
      }, Math.abs(1000 * now.diff(waitUntil, 'seconds')))
    } else setWait(false)
  }

  useEffect(() => {
    setIsWbButtonEnable(
      !wait &&
        !isScreenSharing &&
        (!isWhiteboardSharing || consumerId === userId) &&
        (!isAgora || wbRoomToken)
    )
  }, [
    isScreenSharingActive,
    wait,
    consumerId,
    isWhiteboardSharing,
    wbRoomToken
  ])

  // no need to wait for agora events
  useEffect(() => {
    if (!isAgora && eventStartTime) {
      setWaitWhiteboard()
    } else if (isAgora) setWait(false)
  }, [eventStartTime])

  const menu = (
    <Menu>
      <Menu.Item>
        <span
          style={{ color: '#1890ff', cursor: 'pointer' }}
          role="button"
          onClick={() => onLeave()}
        >
          {t('v4:leave')}
        </span>
      </Menu.Item>
      <Menu.Item>
        <span
          style={{ color: '#1890ff', cursor: 'pointer' }}
          role="button"
          onClick={() => onEndForAll()}
        >
          {t('v2:end_for_all')}
        </span>
      </Menu.Item>
    </Menu>
  )

  const popoverMenu = (
    <div className="popoverItem">
      <div
        className={`menu ${
          isScreenSharingActive || isWhiteboardSharing ? 'disabled' : null
        }`}
        onClick={() => {
          if (!isScreenSharingActive || !isWhiteboardSharing)
            return onClickGrid()
          else return null
        }}
      >
        <img src={grid} alt="Grid icon" />
        <div className="name">
          {' '}
          {isGridActive ? t('v2:hide_grid') : t('v2:show_grid')}
        </div>
      </div>
      {isRemoteScreenSharing && (
        <div className="menu" onClick={() => onClickFullScreen()}>
          <img src={fullScreen} alt="full screen" />
          <div className="name">{t('v2:full_screen')}</div>
        </div>
      )}

      {isConferenceHost && allowScreenSharing && (
        <div
          className={
            isWhiteboardSharing
              ? 'menu disabled'
              : `menu ${isScreenSharing ? 'active_item' : null}`
          }
          onClick={() => (!isWhiteboardSharing ? onScreenShare() : null)}
        >
          <img
            alt=""
            src={isScreenSharing ? stopShareScreenLogo : shareScreenLogo}
          />

          <div className="name">
            {isScreenSharing ? t('v2:stop_screen_share') : t('v2:screen_share')}
          </div>
        </div>
      )}

      {!isAgora && isConferenceHost && allowWhiteboardSharing && (
        <div
          className={
            !isWbButtonEnable
              ? 'menu disabled'
              : `menu ${isWhiteboardSharing ? 'active_item' : null}`
          }
          onClick={async () => {
            //isWbButtonEnable ? await onClickWhiteboardSharing() : null
            if (isWbButtonEnable) {
              await onClickWhiteboardSharing()
              closePopover()
            }
          }}
        >
          <img alt="" src={whiteboardLogo} />
          <div className="name">
            {isWhiteboardSharing
              ? t('v2:leave whiteboard')
              : t('v2:whiteboard')}
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className="menus__container">
      <Popover
        visible={popoverStatus}
        placement="topLeft"
        content={popoverMenu}
        trigger="click"
        onPopupVisibleChange={handleVisibleChange}
      >
        <div className="menu">
          <Icon type="ellipsis" />
          <div className="name">{t('v2:more')}</div>
        </div>
      </Popover>

      <div
        className={`menu ${participants ? 'active_item' : null}`}
        onClick={() => onClickParticipants('PARTICIPANTS')}
        disabled={!isParticipants}
      >
        <Icon type="usergroup-add" />
        <div className="name">{t('labels:participants')}</div>
      </div>

      {!isConferenceHost && isConferenceParticipant && raiseHandAllowed && (
        <div
          className="menu"
          onClick={() => (isHandRaised ? onExitHandRaise() : onHandRaise())}
        >
          <img alt="" src={handIcon} />
          <div className="name">{raiseHandSubtext}</div>
        </div>
      )}

      {isBreakoutRoom && (
        <div
          className={`menu ${
            !isBreakoutRoom ? 'disabled' : rooms ? 'active_item' : null
          }`}
          onClick={() => (isBreakoutRoom ? onClickBreakoutRooms('ROOM') : null)}
        >
          <Icon type="appstore" />
          <div className="name">{t('v2:breakout_room')}</div>
        </div>
      )}

      <div className="menu" disabled={!isRecord}>
        <img
          alt=""
          style={{
            filter: isRecording
              ? 'invert(43%) sepia(99%) saturate(980%) hue-rotate(114deg) brightness(97%) contrast(106%)'
              : null
          }}
          src={record}
        />
        <div className="name">
          {t('v4:is_record')}
          {isRecording && 'ing'}
        </div>
      </div>

      {showAudio && (
        <div
          // className="menu"
          className={
            isAgora
              ? `menu ${_.isEmpty(localStream) ? 'disabled' : null}`
              : 'menu'
          }
          onClick={() =>
            isAgora
              ? !_.isEmpty(localStream)
                ? onClickMute()
                : null
              : showAudio
              ? onClickMute()
              : null
          }
          ref={audioRef}
        >
          <img alt="" src={isAudio ? micOn : micOff} />
          <div className="name">{isAudio ? t('v2:mute') : t('v2:unmute')}</div>
        </div>
      )}

      {showVideo && (
        <div
          // className="menu"
          className={
            isAgora
              ? `menu ${_.isEmpty(localStream) ? 'disabled' : null}`
              : 'menu'
          }
          onClick={() =>
            isAgora
              ? !_.isEmpty(localStream)
                ? onClickVideo()
                : null
              : showAudio
              ? onClickVideo()
              : null
          }
          ref={videoRef}
        >
          <img alt="" src={isVideo ? cameraOn : cameraOff} />
          <div className="name">{t('media:video')}</div>
        </div>
      )}

      {isConfCreator ? (
        <Popover placement="topLeft" content={menu}>
          <img src={leave} alt="end" />
        </Popover>
      ) : (
        <img alt="" src={leave} onClick={() => onLeave()} />
      )}
    </div>
  )
}

export default withTranslation()(EventMenus)
