import React, { Component } from 'react'
import { Checkbox } from 'antd'
import { Field, Row, Col } from '../../../../../../../styles'
import { Organization } from './styled'
import { withTranslation } from 'react-i18next'
import './styles.scss'

class OrganizationPicker extends Component {
  handleChange = (id, e) => {
    const { onChange } = this.props

    if (onChange) {
      onChange(id)
    }
  }
  getFirstLetter = title => {
    return title ? title.charAt(0).toUpperCase() : ''
  }

  render() {
    const { list, selectedOrganizations, t } = this.props

    return (
      <Field>
        <Field.Label>{t('labels:select_organization')}</Field.Label>
        <div className="org-group">
          <div className="org-group-row">
            {list.map(organization => {
              const { id, title, logo } = organization
              const isActive = selectedOrganizations.includes(id)
              return (
                <div className="org-group-list" key={id}>
                  <div
                    className={`org-group-btn ${isActive ? 'active' : ''}`}
                    onClick={e => this.handleChange(id, e)}
                  >
                    <Organization.Checkbox>
                      <Checkbox checked={selectedOrganizations.includes(id)} />
                    </Organization.Checkbox>
                    {logo ? (
                      <Organization.Image src={logo.link} alt={title} />
                    ) : (
                      <div className="org-group-initial">
                        {this.getFirstLetter(title)}
                      </div>
                    )}
                    <Organization.Name>{title}</Organization.Name>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Field>
    )
  }
}

export default withTranslation()(OrganizationPicker)
