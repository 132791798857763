import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Switch from '../../../../../../../../../components/Switch/switch'

import './PriceSection.scss'
import { InputNumber, Form, Radio } from 'antd'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const paymentTypes = {
  subscription: 'subscription',
  one_time: 'one_time'
}

function PriceSection(props) {
  const {
    form,
    form: { getFieldDecorator, setFieldsValue },
    onPriceObj,
    values = {}
  } = props

  const [isFreeChannel, setIsFreeChannel] = useState(values.is_free || false)

  const [priceMonthly, setPriceMonthly] = useState(null)

  const [priceYearly, setPriceYearly] = useState(null)
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    paymentTypes.one_time
  )

  const [priceOneTime, setPriceOneTime] = useState(null)

  const { t } = useTranslation()

  const handleSwitchChange = val => {
    setIsFreeChannel(val)
    updateApi(null)
  }

  useEffect(() => {
    if (values && !values.is_free) {
      setPriceMonthly(values.price_monthly)
      setPriceYearly(values.price_yearly)
      setPriceOneTime(values.price_one_time)

      setFieldsValue({
        price_monthly: values.price_monthly,
        price_yearly: values.price_yearly,
        price_one_time: values.price_one_time
      })

      if (values.price_monthly && values.price_yearly) {
        setSelectedPaymentType(paymentTypes.subscription)
      } else if (values.price_one_time) {
        setSelectedPaymentType(paymentTypes.one_time)
      }
    }
  }, [])

  const updateApi = (hasPriceObj, priceObj) => {
    const isFree = !hasPriceObj
    onPriceObj({
      is_free: isFree,
      ...(!isFree && priceObj)
    })
  }

  const handlePrice = _.debounce((type, val) => updatePrice(type, val), 1000, {
    leading: true,
    trailing: false
  })

  const isError = useMemo(
    () => !isFreeChannel && !((priceMonthly && priceYearly) || priceOneTime),
    [isFreeChannel, priceMonthly, priceOneTime, priceYearly]
  )

  const updatePrice = (type, val) => {
    const prices = {
      price_monthly: setPriceMonthly,
      price_yearly: setPriceYearly,
      price_one_time: setPriceOneTime
    }

    if (prices[type]) {
      prices[type](val)
    }
  }

  useEffect(() => {
    const isSubscription = selectedPaymentType === paymentTypes.subscription
    const isOneTime = selectedPaymentType === paymentTypes.one_time

    const priceObj = {
      price_monthly: isSubscription ? priceMonthly : null,
      price_yearly: isSubscription ? priceYearly : null,
      price_one_time: isOneTime ? priceOneTime : null,
      payment_option: selectedPaymentType
    }

    form.validateFields()

    if (!isError) {
      updateApi(true, priceObj)
    }
  }, [priceMonthly, priceYearly, priceOneTime])

  const handlePaymentOptions = useCallback(e => {
    form.validateFields()
    setSelectedPaymentType(e.target.value)
  }, [])

  return (
    <div className="price-section-wrapper">
      <Form.Item required>
        <Switch
          label={isFreeChannel ? 'Free' : 'Not Free'}
          onChanged={handleSwitchChange}
          value={isFreeChannel}
        />
      </Form.Item>

      {!isFreeChannel && (
        <Form.Item label="Payment Options">
          {getFieldDecorator('payment_options', {
            initialValue: selectedPaymentType
          })(
            <Radio.Group buttonStyle="solid" onChange={handlePaymentOptions}>
              <Radio.Button value={paymentTypes.one_time}>
                {'Buy One Time'}
              </Radio.Button>
              <Radio.Button value={paymentTypes.subscription}>
                {'Subscription'}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
      )}

      {!isFreeChannel && selectedPaymentType === paymentTypes.subscription && (
        <>
          <Form.Item label="Subscription Monthly">
            {getFieldDecorator('price_monthly', {
              initialValue: priceMonthly,
              rules: [
                {
                  required: true,
                  message: t('v3:monthly_price_required')
                }
              ]
            })(
              <InputNumber
                min={1}
                style={{ width: 300 }}
                placeholder="Price"
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={value => handlePrice('price_monthly', value)}
              />
            )}
          </Form.Item>
          <Form.Item label="Subscription Yearly">
            {getFieldDecorator('price_yearly', {
              initialValue: priceYearly,
              rules: [
                {
                  required: true,
                  message: t('v3:yearly_price_required')
                }
              ]
            })(
              <InputNumber
                min={1}
                style={{ width: 300 }}
                placeholder="Price"
                formatter={value =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={value => handlePrice('price_yearly', value)}
              />
            )}
          </Form.Item>
        </>
      )}

      {!isFreeChannel && selectedPaymentType === paymentTypes.one_time && (
        <Form.Item label="One Time Price">
          {getFieldDecorator('price_one_time', {
            initialValue: priceOneTime,
            rules: [
              {
                required: true,
                message: t('v3:one_time_price_required')
              }
            ]
          })(
            <InputNumber
              min={1}
              style={{ width: 300 }}
              placeholder="Price"
              formatter={value =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={value => handlePrice('price_one_time', value)}
            />
          )}
        </Form.Item>
      )}
    </div>
  )
}

export default PriceSection
