import { Checkbox, Col, Form, Input, Row, Switch, TreeSelect } from 'antd'
import React, { useEffect, useState } from 'react'
import { api } from '../../../../../services'
import { debounce } from 'lodash'
import TrainerSection from '../../../../../components/TrainerSection'

const { TreeNode } = TreeSelect

const MarketPlaceForm = props => {
  const { t, marketPlaceData, setMarketPlaceData, user } = props
  const { getFieldDecorator, validateFields } = props.form
  const [categories, setCategories] = useState([])
  const [isFetchingCategories, setIsFetchingCategories] = useState(false)
  const fetchCategories = () => {
    setIsFetchingCategories(true)
    api.courses
      .fetchCategory()
      .then(res => {
        setCategories(res.data)
        setIsFetchingCategories(false)
      })
      .catch(() => {
        setIsFetchingCategories(false)
      })
  }
  useEffect(() => {
    validateFields()
  }, [validateFields])
  useEffect(() => {
    fetchCategories()
    if (user) {
      setMarketPlaceData({
        ...marketPlaceData,
        username: user.username,
        bio: user.bio,
        linkedin: user.linkedin,
        user_id: user.user_id
      })
    }
  }, [])

  const onFieldChange = (key, val) => {
    setMarketPlaceData(prevState => ({
      ...prevState,
      [key]: val
    }))
  }
  const handleFieldChange = debounce((fieldName, value) => {
    onFieldChange(fieldName, value)
  }, 1000)
  return (
    <div className="podcast-marketplace-main">
      <div className="content">
        <TrainerSection />
        <Row gutter={[16, 0]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={'Podcast Objectives'}
              colon={false}
              required={false}
            >
              {getFieldDecorator('course_obj', {
                rules: [
                  {
                    required: true,
                    message: 'Please Input Podcast Objectives'
                  }
                ],
                initialValue: marketPlaceData.course_obj
              })(
                <Input.TextArea
                  placeholder={'Podcast Objectives'}
                  autoSize={{ minRows: 2, maxRows: 20 }}
                  onChange={e =>
                    handleFieldChange('course_obj', e.target.value)
                  }
                  style={{ fontSize: '1.6rem', resize: 'none' }}
                  wrap={'soft'}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={'Podcast Outline'} colon={false} required={false}>
              {getFieldDecorator('course_outline', {
                rules: [
                  {
                    required: true,
                    message: 'Please Input Podcast Outline'
                  }
                ],
                initialValue: marketPlaceData.course_outline
              })(
                <Input.TextArea
                  placeholder={'Podcast Outline'}
                  autoSize={{ minRows: 2, maxRows: 20 }}
                  onChange={e =>
                    handleFieldChange('course_outline', e.target.value)
                  }
                  style={{ fontSize: '1.6rem', resize: 'none' }}
                  wrap={'soft'}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={'Podcast Description'}
              colon={false}
              required={false}
            >
              {getFieldDecorator('course_description', {
                rules: [
                  {
                    required: true,
                    message: 'Please Input Podcast Description'
                  }
                ],
                initialValue: marketPlaceData.course_description
              })(
                <Input.TextArea
                  placeholder={'Podcast Description'}
                  autoSize={{ minRows: 2, maxRows: 20 }}
                  onChange={e =>
                    handleFieldChange('course_description', e.target.value)
                  }
                  style={{ fontSize: '1.6rem', resize: 'none' }}
                  wrap={'soft'}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={t('v4:category')} colon={false} required={false}>
              {getFieldDecorator('category', {
                rules: [
                  {
                    required: true,
                    message: t('v4:select_category')
                  }
                ],
                initialValue: marketPlaceData.category_id
              })(
                <TreeSelect
                  size="large"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder={t('v4:please_select')}
                  allowClear
                  onChange={val => onFieldChange('category_id', val)}
                  loading={isFetchingCategories}
                >
                  {categories &&
                    categories.map(category => (
                      <TreeNode
                        value={category.id}
                        title={category.name}
                        key={category.id}
                      >
                        {category.children &&
                          category.children.map(option => (
                            <TreeNode
                              value={option.id}
                              title={option.name}
                              key={option.id}
                            />
                          ))}
                      </TreeNode>
                    ))}
                </TreeSelect>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:promotional_video_link')}
            >
              {getFieldDecorator('video_link', {
                rules: [{ required: true, message: t('v4:input_video_link') }],
                initialValue: marketPlaceData.video_link
              })(
                <Input
                  size="large"
                  maxLength={100}
                  onChange={e => onFieldChange('video_link', e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={[8, 0]} type="flex" justify="start" align="middle">
              <Col span={4}>
                <Form.Item
                  colon={false}
                  required={false}
                  label={t('general:free')}
                >
                  {getFieldDecorator('is_free', {
                    initialValue: marketPlaceData.is_free
                  })(
                    <Switch
                      label={` ${t('general:free')}  ${t('general:event')}`}
                      onChange={e => onFieldChange('is_free', e)}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={20}>
                <Form.Item
                  colon={false}
                  required={false}
                  className="price_style"
                  label={t('labels:price (usd)')}
                >
                  {marketPlaceData.is_free ? (
                    <Input
                      size="large"
                      type="number"
                      min={5}
                      maxLength={100}
                      onChange={e => onFieldChange('price', e.target.value)}
                      disabled={marketPlaceData.is_free}
                    />
                  ) : (
                    getFieldDecorator('price', {
                      initialValue: marketPlaceData.price,
                      rules: [
                        {
                          validator: (rule, value) => value >= 5,
                          required: false,
                          message: t('v4:enter_price')
                        }
                      ]
                    })(
                      <Input
                        size="large"
                        type="number"
                        min={5}
                        maxLength={100}
                        onChange={e => onFieldChange('price', e.target.value)}
                        disabled={marketPlaceData.is_free}
                      />
                    )
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('is_shared_profile', {
                valuePropName: 'checked',
                initialValue: marketPlaceData.hasOwnProperty(
                  'is_shared_profile'
                )
                  ? marketPlaceData.is_shared_profile
                  : false
              })(
                <Checkbox
                  className="marketplace__checkbox"
                  onChange={e =>
                    onFieldChange('is_shared_profile', e.target.checked)
                  }
                >
                  {t('v3:allow_sharing_trainer_info')}
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Form.create()(MarketPlaceForm)
