import React, { Component, Fragment } from 'react'
import { Icon } from 'antd'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { ReportSelect } from '../styled'
import { withRouter } from 'react-router-dom'
import CourseIcon from '../../../assets/course_report_icon.png'
import MazeIcon from '../../../assets/maze_report_icon.png'
import SkillJourneyIcon from '../../../assets/skilljourney_report_icon.png'
import LiveAssessmentsIcon from '../../../assets/live_assessments_report_icon.png'
import CoachingIcon from '../../../assets/coaching.png'
import WebinarIcon from '../../../assets/webinar_icon.png'

const { Option } = ReportSelect
class MarketPlaceTypeSelect extends Component {
  handleSelect = value => {
    if (value === 'maze') {
      this.props.history.push(`/reports/market-place/maze/`)
    } else if (value === 'courses') {
      this.props.history.push(`/reports/market-place/course/`)
    } else if (value === 'coaching') {
      this.props.history.push(`/reports/market-place/coaching`)
    } else if (value === 'webinar') {
      this.props.history.push(`/reports/market-place/webinar/`)
    } else if (value === 'channel') {
      this.props.history.push(`/reports/market-place/channel/`)
    } else if (value === 'public_conference') {
      this.props.history.push(`/reports/market-place/public-conference/`)
    }
  }
  render() {
    const { defaultValue, t, enableMaze } = this.props
    console.log('selectedValueprops', this.props)
    return (
      <Fragment>
        <div id="report_type_select" style={{ marginBottom: '18px' }}>
          <ReportSelect
            defaultValue={defaultValue}
            dropdownStyle={{ borderRadius: '9px' }}
            onSelect={value => this.handleSelect(value)}
            getPopupContainer={() =>
              document.getElementById('report_type_select')
            }
          >
            <Option value="courses" className="select-option">
              <img
                src={CourseIcon}
                style={{
                  height: '43px',
                  width: '40px',
                  marginRight: '5px'
                }}
              />
              {/* <Icon type="book" theme="twoTone" style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v4:report_courses')}</span>
            </Option>
            <Option value="webinar" className="select-option">
              <img
                src={WebinarIcon}
                style={{
                  height: '22px',
                  width: '32px',
                  marginRight: '11px'
                }}
              />
              {/* <Icon type="video-camera" theme="twoTone"  style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v3:report_webinar')}</span>
            </Option>
            <Option value="coaching" className="select-option">
              <img
                src={CoachingIcon}
                style={{
                  height: '22px',
                  width: '32px',
                  marginRight: '11px'
                }}
              />
              {/* <Icon type="video-camera" theme="twoTone"  style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v3:report_coaching')}</span>
            </Option>

            <Option value="maze" className="select-option">
              <img
                src={MazeIcon}
                style={{
                  height: '27px',
                  width: '27px',
                  marginRight: '13px'
                }}
              />
              {/* <Icon type="build" theme="twoTone"  style={{marginRight: "10px", fontSize: "20px"}} /> */}
              <span>{t('v4:report_maze')}</span>
            </Option>

            <Option value="channel" className="select-option">
              <img
                src={LiveAssessmentsIcon}
                style={{
                  height: '37px',
                  width: '33px',
                  marginRight: '11px'
                }}
              />
              <span>{t('v3:channel').toUpperCase()}</span>
            </Option>
            <Option value="public_conference" className="select-option">
              <img
                src={SkillJourneyIcon}
                style={{
                  height: '37px',
                  width: '33px',
                  marginRight: '11px'
                }}
              />
              <span>{t('v3:report_public_conference')}</span>
            </Option>
          </ReportSelect>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(withTranslation('report')(MarketPlaceTypeSelect))
