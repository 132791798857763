import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { Comment, Avatar, Tooltip, Rate, Alert, Empty } from 'antd'

import './EventRatings.scss'

import { ReviewsButtonModal } from '../../../../../../../components'

const EventRatings = ({ eventId, userId }) => {
  const reviews = useSelector(state => state.reviews.reviews)

  const [eventReviews, setEventReviews] = useState(reviews)

  useEffect(() => {
    setEventReviews(reviews)
  }, [reviews])

  const hasUserReviewed = !!reviews.find(item => item.user_id === userId)
  const noReviews = reviews.length === 0

  console.log('list reviews', eventReviews)
  return (
    <div className="reviews_container">
      <div className="reviews_list">
        {!noReviews &&
          eventReviews.map(review => <RatingItem review={review} />)}
        {noReviews && <Empty description={'No Reviews Found.'} />}
      </div>

      {!hasUserReviewed && (
        <ReviewsButtonModal id={eventId} userId={userId} type="event" />
      )}
      {hasUserReviewed && (
        <Alert
          message={`You have already rated this live event.`}
          type="info"
        />
      )}
    </div>
  )
}

const RatingItem = ({ review }) => {
  const {
    avatar,
    username,
    rating,
    review: text,
    created_at  } = review

  const actions = [
    <span key="comment-basic-like">
      <Tooltip title="Rating">
        <Rate disabled value={rating} />
      </Tooltip>
      <span style={{ paddingLeft: 8, cursor: 'auto' }}>
        {rating.toFixed(1)} / 5
      </span>
    </span>
  ]

  return (
    <>
      <Comment
        actions={actions}
        author={<b>{username}</b>}
        avatar={<Avatar src={avatar} alt={username} />}
        content={<p>{text}</p>}
        datetime={
          <Tooltip title={moment(created_at).format('YYYY-MM-DD hh:mm A')}>
            <span className="reviews_list_time">
              {moment(created_at).fromNow()}
            </span>
          </Tooltip>
        }
      />
      <hr />
    </>
  )
}

export default EventRatings
