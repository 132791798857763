import DiscussionForm from "./Form";
import actions from "../../../store/actions";
import {connect} from "react-redux";


const mapStateToProps = state => ({
    user: state.user,
    posting: state.discussion.postingComment
})

const mapDispatchToProps = dispatch => ({
    postComment: (id, type, parentId, body) => actions.discussion.addComment({
        id,
        type,
        parentId,
        body
    })(dispatch)
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiscussionForm)
