/*
 * @Author: your name
 * @Date: 2019-09-05 15:53:46
 * @LastEditTime: 2019-11-21 15:08:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Assessments/View/styled.js
 */
import styled, { keyframes } from 'styled-components'

import { styleguide } from '../../../constants'

const { colors } = styleguide

const Video = styled.div``

const Page = styled.div`
  width: 100%;

  .ReactVirtualized__Grid {
    &.ReactVirtualized__List {
      outline: none;
    }
  }
`
const RangeSliderContainer = styled.div`
  .input-range {
    width: ${props => props.width};
    height: 13rem;
    position: absolute;
    bottom: 7.4rem;
    left: ${props => props.left};
  }
`

Video.Media = styled.div`
  width: 100%;
  height: 38rem;
  background-color: ${colors.black};
  margin-bottom: 1rem;
  position: relative;
`

Video.Centering = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

Video.Live = styled.div`
  background-color: ${colors.orange};
  border-radius: 2rem;
  color: ${colors.white};
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0.3rem 1.3rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 1;
`

Video.Controls = styled.div``

Video.Progress = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: #ddd;
  cursor: pointer;
  position: relative;
`

Video.Progress.Bar = styled.div`
  width: ${props => props.position}%;
  background-color: #b3b3b3;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: 0.3s;

  &:after {
    content: '';
    width: 0.1rem;
    height: 1.2rem;
    border: 0.1rem solid #979797;
    background-color: ${colors.white};
    cursor: pointer;
    position: absolute;
    top: -0.3rem;
    right: -0.1rem;
  }
`

Video.Details = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`

Video.Details.Item = styled.div`
  font-size: 1.1rem;
`

const Lines = styled.div`
  width: 100%;
  min-height: 15rem;
  margin-bottom: 2rem;
  position: relative;
`

Lines.Box = styled.div`
  margin-top: 1rem;
  padding: 1.5rem 0;
`

Lines.Pointer = styled.div`
  width: 0.1rem;
  height: calc(100% - 2rem);
  background-color: #4652e5;
  position: absolute;
  top: 1rem;
  left: ${props => props.position}rem;
  z-index: 10;
  transition: 0.3s;
`

const slideUpFromBottom = ({ beforeRotate, afterRotate }) => keyframes`
  
  from {
    bottom: 0;
    opacity: 1;
    width: 1.5rem;
    transform: rotate(${beforeRotate}deg);
  }

  to {
    bottom: 100%;
    opacity: 0.5;
    width: 4rem;
    transform: rotate(${afterRotate}deg);
  }
`

const FloatingContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  top: 0px;
  right: 0;
  position: absolute;
`

const EmotionRnd = styled.div`
  position: absolute;
  width: 2rem;
  left: ${props => props.position}px;
  opacity: 0;
  animation: ${props => props.speed}s ease-in 0s 1
    ${props => slideUpFromBottom(props)};
  img {
    width: 100%;
  }
`

export {
  Page,
  Video,
  Lines,
  RangeSliderContainer,
  FloatingContainer,
  EmotionRnd
}
