import { api } from '../../services'
import { ROLES_SUCCESS, ROLES_FETCHING } from '../types'

const getRoles = () => dispatch => {
  dispatch({
    type: ROLES_FETCHING,
    payload: true
  })

  return api.roles.getRoles().then(response => {
    dispatch({
      type: ROLES_SUCCESS,
      payload: response.data
    })
    dispatch({
      type: ROLES_FETCHING,
      payload: false
    })
    return response.data
  })
}

export { getRoles }
