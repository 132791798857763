import React from 'react'
import { Drawer } from 'antd'
import { CustomDatePicker, CustomSelect } from '../styled'
import { withTranslation } from 'react-i18next'

const { Option } = CustomSelect

const DownloadModal = ({
  title,
  handleClose,
  visible,
  startDate,
  endDate,
  handleStartChange,
  handleEndChange,
  handleChangeDownloadType,
  options,
  handleDownload,
  defaultValue,
  t
}) => (
  <Drawer
    title={title}
    placement="right"
    closable={true}
    onClose={handleClose}
    visible={visible}
  >
    <CustomDatePicker
      format="YYYY-MM-DD"
      value={startDate}
      placeholder={t('reports:from_date')}
      onChange={handleStartChange}
    />
    <CustomDatePicker
      format="YYYY-MM-DD"
      value={endDate}
      placeholder={t('reports:to_date')}
      onChange={handleEndChange}
    />
    <CustomSelect
      className="report-type-select"
      onChange={handleChangeDownloadType}
      defaultValue={defaultValue}
    >
      {options.map(option => (
        <Option key={`option_${option.key}`} value={option.key}>
          {option.name}
        </Option>
      ))}
    </CustomSelect>
    <button onClick={handleDownload}>{t('reports:download')}</button>
  </Drawer>
)

export default withTranslation('reports')(DownloadModal)
