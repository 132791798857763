import styled from 'styled-components'
import { Select } from 'antd'

const Page = styled.div`
  padding: 1rem;
`
Page.Select = styled(Select)`
  border-radius: 10px;
  width: 100%;
`

Page.Title = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
  margin-bottom: 2rem;
`

Page.ROW = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
`

export { Page }
