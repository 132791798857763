/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'حفظ',
    autosaved: ' محفوظ تلقائيًا ',
    back_to: 'العودة إلى',
    by: 'بواسطة',
    chat: 'دردشة',
    completed: 'مكتمل',
    canceled: 'ملغي',
    component: 'مكون',
    confirm: 'تأكيد',
    course: 'دورة',
    date: 'تاريخ',
    details: 'تفاصيل',
    empty: 'لا توجد بيانات,',
    enrolled: 'المقيدين',
    free: 'مجانا',
    graph: ' رسم بياني',
    loading: ' جاري التحميل...',
    lesson: ':درس',
    lessons: 'دروس',
    live_assessment: 'تقييم مباشر',
    live_event: 'فعالية مباشر',
    no: 'لا',
    of: 'من',
    or: 'بدلا من',
    organization: 'منظمة',
    page: 'صفحة',
    pages: 'صفحات',
    personal: 'شخصي',
    poll: 'تصويت',
    rating: 'تقييم',
    reset: 'إعادة تعيين',
    signin: 'تسجيل الدخول',
    signup: 'الاشتراك  تسجيل',
    soon: 'قريبا',
    type: 'نوع',
    uploading: ': جارٍ التحميل ... ',
    yes: 'نعم',
    something_went_wrong: 'هناك خطأ ما!',
    view_all: 'عرض الكل',
    upload_successful: 'تحميل ناجح',
    upload_successful_message:
      '"تم تحميل مستندات excel بنجاح وهي قيد المعالجة حاليًا. هذا قد يستغرق بعض الوقت.',
    check_email_upload_successful:
      'الرجاء <0> التحقق من بريدك الإلكتروني </0> للحصول على <1> تقرير </ 1> لقائمة المستخدمين التي تم تحميلها.',
    is_private: 'خاص',
    to_attach: 'أرفق ملف',
    file_attached: 'ملف مرفق'
  },

  account: {
    title: 'الحساب',
    subtitle: 'إدارة إعداداتك ومعلومات حسابك.',
    saved: 'تم تغيير الحساب بنجاح'
  },

  assessments: {
    title: 'التقييمات',
    subtitle: 'إنشاء تقييم',
    finished: 'التقييم انتهى',
    countdown: 'سيتم بث التقييم المباشر في خلال',
    create: 'إنشاء التقييم',
    list_title: 'تقييماتك',
    delete_warning: 'هل أنت متأكد أنك تريد حذف هذا البند؟',
    details_title: 'إضافة تفاصيل التقييم',
    details_subtitle:
      'قم بتسمية تقييمك وأخبر جمهورك بما يجب عليهم توقعه للتعلم منه.',
    disclaimer: `أتعهد بأن تقييمي المسجل لن: '
     - ينتهك حقوق أي طرف ثالث
     - يحتوي على أي عدم شرعية ، بما في ذلك المحتوى التشهيري أو الفاحش أو غير اللائق أو الفاحش أو الإباحية أو العنيفة أو المسيئة أو المهينة أو التهديدية أو التمييزية ، حتى المخفاة بعلامات النجمة
     - يحتوي على أي اعتداءات شخصية على أشخاص آخرين
     - يحتوي على أي انتحال صفة لمشاركين آخرين أو شخصيات عامة أو مشاهير
     - يحتوي على أي تسجيل أو معلومات شخصية عن أي فرد دون موافقة هذا الفرد ولا ينتهك أي لائحة لحماية البيانات في أي جزء من العالم
     - يحتوي على أي دعوة تجارية أو أي شكل من أشكال "البريد العشوائي",`,

    assessment_details: 'تفاصيل التقييم',
    assessment_settings: 'إعدادات التقييم'
  },

  buildersettings: {
    title: 'نشر الإعدادات',
    subtitle:
      'إضافة إعدادات إلى الفعالية الخاص بك ، من الذي يمكنه مشاهدة الفعالية الخاص بك والتفاعل معه'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'إضافة',
    add_page: 'إضافة صفحة',
    add_lesson: 'إضافة دروس',
    add_answer: 'إضافة إجابات جديدة',
    add_group: 'إضافة مجموعة',
    add_organization: 'إضافة منظمة',
    add_question: 'إضافة سؤال',
    add_quiz: ' إضافة اختبار',
    add_users: 'إضافة مستخدمين',
    back: 'العودة',
    back_to_course: 'العودة إلى نظرة عامة على الدورة التدريبية',
    // back_to_lesson: العودة إلى نظرة عامة على الدروس,
    back_to_media: 'العودة إلى مكتبة الوسائط',
    back_to_details: 'العودة إلى التفاصيل',
    cancel: 'إلغاء',
    clear: ' مسح ',
    close: 'إغلاق',
    clone: 'نسخ',
    close_lesson: 'إغلاق الدرس',
    clone_course: 'نسخ الدورة',
    confirm: 'تأكيد',
    create: 'إنشاء',
    download: `تحميل,`,
    download_mass_user_tamplate: 'تحميل القالب',
    add_mass_users: 'إضافة مستخدمين جماعيين ',
    upload_excel_list: 'تحميل قائمة Excel',
    delete: 'حذف الوسائط',
    edit: 'تحرير',
    edit_course: ' تحرير الدورة',
    enroll: 'تسجيل',
    finish: 'إنهاء',
    upload_image: 'تحميل الصور',
    upload_image_video: 'تحميل صورة أو فيديو',
    select_media_library: 'حدد مكتبة الوسائط',
    make_public: 'اجعله عاماً',
    make_private: 'اجعله خاصًا',
    make_org: 'اصنع منظمة',
    media: 'مكتبة الوسائط',
    my_theme: ' نمطي',
    next: 'التالى',
    next_lesson: 'الدرس التالي',
    next_step: 'الخطوة التالية',
    previous_step: ' الخطوة السابقة',
    publish: ' نشر',
    publish_assessment: 'نشر تقييمك',
    publish_course: 'نشر دورتك',
    publish_event: 'نشر فعالية',
    reset: 'إعادة تعيين',
    reset_elements: 'إعادة تعيين العناصر',
    save: ' حفظ',
    creating: ' إنشاء...',
    updating: ' تحديث...',
    saved: ' تم الحفظ',
    save_for_later: 'احفظ لوقت لاحق',
    save_settings: 'احفظ التغييرات',
    select_files: 'اختر الملفات',
    select_theme: 'اختر نمطا',
    start_lesson: 'بدء الدرس',
    update: 'تحديث',
    upload: ' تحميل ',
    upload_audio: 'تحميل الصوت',
    upload_photo: 'تحميل صورة ملف شخصي',
    upload_video: 'تحميل الفيديو',
    enrolled: 'المقيدين',
    unenroll: 'إلغاء التسجيل',
    from_library: 'مكتبة الوسائط',
    remove: ' إزالة',
    removed: 'تمت الإزالة',
    start_broadcast: 'بدء البث',
    end_broadcast: 'إنهاء البث',
    camera: 'الة تصوير',
    screen: 'عرض على الشاشة ',
    screen_with_camera: ' التصوير بواسطة كاميرا',

    insert: 'إدخال',
    inserting: 'إدخال',
    upload_pdf: 'تحميل ملف PDF',

    portrait: 'صورة',
    landscape: 'المناظر الطبيعيه'
  },

  card: {
    quick_add: 'إضافة سريعة',
    add: 'إضافة,',
    clone: 'نسخ',
    completed: 'الدروس أكتملت'
  },

  course: {
    upcoming_course: 'الدورة القادمة',
    unPublishEvent: ' عدم نشر الفعالية',
    unPublishAssessment: 'عدم نشر التقييم ',
    pastCourse: 'دورة تدريبية',
    upcoming: 'فعالية مباشر قادم',
    pastEvent: 'فعالية مباشر فائت',
    pastAssessment: 'تقييم مباشر فائت',
    upcomingAssessment: 'تقييم مباشر قادم',
    created: 'تاريخ الإنشاء:',
    assignees: ' المعهود إليه, ',
    organisations: '{{المنظمات}} المؤسسات ، <2> {{users}} المستخدمين </2>',
    event_date: 'تاريخ الفعالية المباشر <1> {{date}} </1>',
    delete_warning: 'هل أنت متأكد أنك تريد حذف هذا',
    clonning_no_lesson: 'يرجى إضافة درس واحد على الأقل لاستنساخ هذه الدورة.',
    clone_warning: 'هل أنت متأكد أنك تريد استنساخ هذا',
    clone_success: 'في عملية استنساخ',
    disclaimer: `أضمن أن دورتي لا::
	•	تنتهك حقوق أي طرف ثالث
  - تحتوي على أي محتوى غير قانوني ، بما في ذلك المحتوى التشهيري أو الفاحش أو غير اللائق أو البذيء أو الإباحي أو العنيف أو المسيء أو المهين أو التهديد أو التمييزي ، حتى المقنع بعلامات النجمة
  - تحتوي على أي اعتداء شخصي على أشخاص آخرين
  - تحتوي على أي انتحال صفة لمشاركين آخرين أو شخصيات عامة أو مشاهير
  - تحتوي على أي معلومات شخصية عن أي فرد دون موافقة هذا الفرد ولا تنتهك أي لائحة لحماية البيانات في أي جزء من العالم
  - تحتوي على أي طلب تجاري أو أي شكل من أشكال "البريد العشوائي",`
  },

  coursecreate: {
    dropzone_text: 'إدراج الوسائط',
    sidebar_text: 'حدد نمطا <1 /> لـ <3 /> الدروس',
    delete_warning: ' هل أنت متأكد أنك تريد حذف هذا الدرس؟'
  },

  coursepage: {
    quizview_loading: ' التحقق من الإجابات. يرجى الانتظار...',
    quizview_answer: 'اجابتك'
  },

  coursequiz: {
    title: 'اختبارات في كل الدروس'
  },

  dashboard: {
    tooltip_title: 'لا تعرض هذا الفيديو مرة أخرى',
    // page_title: "الدورات الخاصة بك" الإحصاء ",
    page_subtitle: 'حالة الدورات التي أنشأتها',
    profile_add_info: 'أضف <1> {{نص غير مكتمل}} </1> لاستكمال ملفك الشخصي.',
    profile_build:
      'أنشئ ملفك الشخصي <1> هنا </1> ، وأخبرنا عن اهتماماتك ومهاراتك.',
    profile_title: 'قوة الملف الشخصي',
    more_details: 'المزيد من التفاصيل'
  },

  errors: {
    answer: 'الرجاء إدخال إجابة',
    answer_correct: 'اختر الاجابة الصحيحة',
    code: 'الرجاء إدخال الرمز الخاص بك',
    criterion: 'الرجاء إدخال المعيار',
    duration: 'المدة مطلوبة',
    element: 'الرجاء إدخال عنصر',
    email: 'الرجاء إدخال البريد الإلكتروني الخاص بك',
    email_invalid: 'المدخل ليس بريدًا إلكترونيًا صالحًا',
    feedback_correct: 'يرجى إدخال تعليقات صحيحة',
    feedback_incorrect: 'الرجاء إدخال تعليقات غير صحيحة',
    host: 'الرجاء إدخال مضيف الفعالية',
    host_assessment: 'الرجاء إدخال مضيف التقييم',
    image: 'يرجى تحميل صورة',
    location: 'الرجاء إدخال موقعك',
    live_location: 'الرجاء اختيار موقع البث',
    limit: 'يُرجى إدخال دقائق البث المباشر',
    min_3char: '3 أحرف كحد أدنى',
    number: 'الرجاء إدخال رقم موجب',
    host_email_require: 'رسائل البريد الإلكتروني للمضيف مطلوبة',
    attendee_email_require: 'رسائل البريد الإلكتروني للحضور مطلوبة ',
    name: 'الرجاء إدخال اسمك',
    not_found_course: 'لم يتم العثور على الدورة التدريبية',
    not_found_event: 'لم يتم العثور على الفعالية',
    not_found_assessment: 'لم يتم العثور على التقييم ',
    password: 'الرجاء إدخال كلمة المرور الخاصة بك',
    password_digit: 'رقم واحد على الأقل',
    password_short: 'كلمة المرور قصيرة جدًا ، الحد الأدنى 10 أحرف',
    password_special: 'حرف واحد مميز على الأقل',
    password_uppercase: 'حرف واحد كبير على الأقل',
    password_lowercase: 'حرف واحد صغير على الأقل',
    phone: 'الرجاء إدخال هاتفك',
    phone_invalid: 'رقم الهاتف غير صحيح',
    position: 'يرجى إدخال وظيفتك',
    question: 'الرجاء إدخال السؤال',
    start_date: 'تاريخ ووقت البدء مطلوبان',
    tag: 'يرجى تحديد علامة',
    topics: 'الرجاء تحديد موضوع واحد على الأقل.',
    live_topics: 'الرجاء إضافة مواضيع',
    title: 'الرجاء إدخال العنوان',
    title_assessment: 'الرجاء إدخال عنوان التقييم',
    title_course: 'الرجاء إدخال عنوان الدورة',
    title_empty: 'العنوان لا يمكن أن يكون فارغا',
    title_event: 'يرجى إدخال عنوان الفعالية',
    title_lesson: 'يرجى إدخال عنوان الدرس',
    title_quiz: 'يرجى إدخال عنوان الاختبار',
    username: 'الرجاء إدخال اسم المستخدم الخاص بك',
    upload_error: 'خطأ - الملف غير متوافق!',
    upload_more_than_10: 'يمكنك فقط تحميل 10 ملفات كحد أقصى في وقت واحد',
    topics_max: 'يرجى إضافة 3 مواضيع كحد أقصى',
    upload_excel: 'يرجى التأكد من تحميل الملف الصحيح',
    title_excel: 'خطأ في تحميل النموذج',
    file_size_exceed: 'حجم الملف يتجاوز',
    subtitle_excel:
      'يرجى التأكد من استخدام النموذج المقدم وملء الأعمدة بشكل صحيح',
    select_broadcast_option: 'يرجى تحديد خيار لبث هذا الفعالية',
    multi_hosts_number_error:
      'لا يمكن أن يتجاوز عدد المقدمين العدد المحدد للفعالية',
    conference_people_number_error:
      'لا يمكن أن يتجاوز عدد الحضور العدد المحدد للفعالية',
    select_max_host: 'حدد مقدمًا واحدًا على الأقل للفعالية',
    select_max_conference: 'حدد ضيفًا واحدًا على الأقل لهذا الفعالية',
    stream_stop_error: 'توقف البث بشكل غير متوقع!',
    unsecure_context:
      'خطأ فادح: لا يمكن للمستعرض الوصول إلى الكاميرا والميكروفون بسبب السياق غير الآمن. يرجى تثبيت SSL والوصول عبر https ',
    ws_not_supported: 'خطأ فادح: WebSocket غير مدعوم في هذا المستعرض',

    no_org: 'حدد مؤسسة واحدة على الأقل للمتابعة. ',
    no_org_group: 'حدد مجموعة تنظيمية واحدة على الأقل للمتابعة.',
    waiting_to_join: 'في انتظار شخص ما للانضمام!',
    select_transcribe_locale: 'حدد اللغة المراد نسخها',
    transcribe_limit: 'الرجاء إدخال نسخ دقيقة',
    monthly_transcribe: 'حد الكتابة الشهري:',
  },

  events: {
    title: 'إضافة تفاصيل الفعالية المباشر',
    change_host: 'تغيير المضيف',
    finished: 'انتهى الفعالية',
    countdown: 'سيتم بث الفعالية المباشر في',
    subtitle:
      'قم بتسمية فعاليةك المباشر وأخبر جمهورك بما يجب عليهم توقعه للتعلم منه',
    poll_disabled: 'الاستطلاع غير متوفر في هذا الفعالية',
    disclaimer: `أتعهد بأن فعاليةي المسجل لن'
     - ينتهك حقوق أي طرف ثالث
     - يحتوي على أي محتوى غير قانوني ، بما في ذلك المحتوى التشهيري أو الفاحش أو غير اللائق أو البذيء أو الإباحي أو العنيف أو المسيء أو المهين أو التهديد أو التمييزي ، حتى المقنع بعلامات النجمة
     - يحتوي على أي اعتداء شخصي على أشخاص آخرين
     - يحتوي على أي انتحال صفة لمشاركين آخرين أو شخصيات عامة أو مشاهير
     - يحتوي على أي تسجيل أو معلومات شخصية عن أي فرد دون موافقة هذا الفرد ولا تنتهك أي لائحة لحماية البيانات في أي جزء من العالم
     - يحتوي على أي التماس تجاري أو أي شكل من أشكال "البريد المزعج "،`,
    prompt_message:
      ', ستؤدي مغادرة هذه الصفحة إلى إنهاء الفعالية. هل أنت متأكد من مغادرة هذه الصفحة؟',
    duration_exceed_message: 'تجاوز وقت البث لديك ، سيتوقف التطبيق عن البث',
    before_schedule_message:
      'أنت على وشك بدء البث قبل وقت الفعالية المجدول. يرجى ملاحظة أن إيقاف البث سينهي الفعالية ',
    browser_unsupportive_message:
      'متصفحك لا يدعم مشاركة الشاشة. يمكنك مشاهدة المتصفحات المدعومة في هذا الرابط',
    cancel_switch_host_message: 'المضيف غير متوفر. سيستمر الفعالية من نهايتك',
    coaching_prompt_message: 'لا يمكنك مغادرة هذه الصفحة حتى انتهاء الفعالية.',
    event_modal_warn: 'هل تريد مغادرة هذا الفعالية؟',
    // confirm_message:  "سينتهي فعاليةك إذا قمت بالنقر فوق" موافق ",
    screen_share_denied: 'رفضت مشاركة شاشتك',
    media_not_found:
      'الكاميرا أو الميكروفون غير موجودان أو غير مسموح بهما في جهازك',
    media_not_access:
      'يتم استخدام الكاميرا أو الميكروفون من قبل عملية أخرى لا تسمح بقراءة الأجهزة',
    constraint_error: `  لم يتم العثور على جهاز يناسب قيود الفيديو والصوت. يمكنك تغيير قيود الفيديو والصوت, `,
    media_access_denied: 'غير مسموح لك بالوصول إلى الكاميرا والميكروفون'
  },

  filters: {
    days7: 'اخر 7 ايام',
    days15: 'اخر 15 يوم',
    days30: ` 'اخر 30 يوم, `,
    days365: 'آخر 365 يومًا',
    date: 'تاريخ الإنشاء',
    relevance: 'ملاءمة',
    popularity: 'شعبية',
    name: 'اسم',
    group: 'مجموعة',
    Newest_to_Oldest: 'من الأفعالية إلى الأقدم ',
    Oldest_to_Newest: 'من الأقدم إلى الأفعالية',
    A_Z: 'أ إلى ي',
    Z_A: 'ي إلى أ'
  },

  helpers: {
    password: ` ,"لضمان أمان حسابك ، نطلب حرفاً صغيرًا واحدًا ورقماً على الأقل ، في كلمة مرورك. يجب أيضًا أن يكون الحد الأدنى للطول هو 10 أحرف"`,
    phone: 'يرجى تضمين رمز بلد هاتفك ، على سبيل المثال: +65',
    username:
      ' يتم التعامل مع اسم المستخدم الخاص بك كمعلومات عامة ويتم مشاركته مع المستخدمين الآخرين حسب الحاجة. على هذا النحو ، نوصي بعدم استخدام المعلومات الشخصية في اسم المستخدم الخاص بك ',
    public_description_normal:
      'تم حفظ <0><0/> </0> الخاص بك ويعتبر خاصًا بشكل افتراضي. هذا يعني أنه يمكنك فقط رؤية هذا المحتوى. لنشر هذا المحتوى ، يمكنك تحديد أحد الخيارات أدناه. يعني نشر الدورة التدريبية الخاصة بك للجمهور أنها ستكون متاحة لجميع المستخدمين في المنصة. سيتيح لك خيار المستخدمين المحددين مشاركة دورتك التدريبية مع مستخدمين محددين باستخدام عنوان البريد الإلكتروني المرتبط بحساب Sharelook الخاص بهم',
    public_description_admin:
      '   تم حفظ هذا <0><0/> </0> ويتم اعتباره خاصًا بشكل افتراضي. هذا يعني أنه يمكنك أنت (ومنشئ المحتوى) فقط مشاهدة هذا المحتوى. لنشر هذا المحتوى ، يمكنك تحديد أحد الخيارات أدناه. يعني نشر الدورة التدريبية الخاصة بك للجمهور أنها ستكون متاحة لجميع المستخدمين في النظام الأساسي - بما في ذلك أولئك الذين هم خارج مؤسستك. يعني نشره في المؤسسة أن جميع المستخدمين داخل المؤسسة سيتمكنون من رؤيته. يمكنك أيضًا نشره إلى مجموعة واحدة أو أكثر ، مما يعني أنه لن يتمكن من رؤيته سوى المستخدمين داخل هذه المجموعات. أخيرًا ، لديك خيار نشره إلى مستخدمين محددين داخل مؤسستك وخارجها باستخدام عنوان البريد الإلكتروني لحساب Sharelook الخاص بهم'
  },

  labels: {
    add: 'إضافة',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'نوع الإجابة',
    answers: 'الإجابات',
    back_to_quiz: 'العودة إلى قائمة الاختبارات',
    back_to_question: 'العودة إلى قائمة الأسئلة',
    chat: 'دردشة',
    chat_available: 'الدردشة غير متاحة في هذا الفعالية',
    chat_report: 'تقرير الدردشة',
    choice_single: 'خيار واحد',
    choice_multiple: 'متعدد الخيارات',
    choice_free: '" حرية الاختيار',
    choice_sorting: ` 'اختيار "الفرز", `,
    // choice_matrix: " فرز المصفوفة "" اختيار" ,
    clone_lesson_permission: 'إذن استنساخ',
    code: 'الشفرة',
    correct: 'صحيح',
    created: 'تاريخ الإنشاء',
    clone_modal: 'الدورة   استنساخ ',
    // cloning_course: الدورة   استنساخ ",
    cloning: 'استنساخ...',
    cloning_course_desc:
      ' سوف تستغرق هذه العملية بعض الوقت. يرجى التحلي بالصبر ',
    cloning_success: `  تم استنساخ "الدورة التدريبية" <0 /> بنجاح "!", `,
    description: 'وصف',
    disclaimer: 'إخلاء المسئولية',
    drop_file: `"اسقاط الملف هنا ...',`,
    duration: 'المدة الزمنية',
    edit: 'تعديل',
    email: 'البريد الإلكتروني',
    event: 'فعالية',
    event_all: 'كل فعالياته',
    event_date: 'فعالية مباشر التاريخ / الوقت المحلي',
    event_this: 'هذا الفعالية',
    expire: 'انتهاء الصلاحية',
    expiry_date: 'تاريخ الانتهاء',
    forgot: 'هل نسيت كلمة المرور؟',
    group: 'مجموعة',
    groups_list: 'قائمة المجموعات',
    host: 'مضيف',
    id_annotation: 'معرف التعليق التوضيحي',
    id_quiz: 'معرف الاختبار',
    incorrect: 'غير صحيح',
    interests: 'الإهتمامات',
    invited: 'مدعو',
    live_chat: 'مناقشة مباشرة',
    live_stream_limit: 'دقائق البث المباشر',
    contact_text: 'لتغيير حد البث المباشر الشهري ، يرجى الاتصال',
    contact_admin:
      'إذا كنت ترغب في تغيير حد البث المباشر الشهري ، فيرجى الاتصال بـ </0> <1<0>  admin@sharelook.com </1>',
    limit_stream_text:
      'دقائق البث المباشر المتبقية: <0> {{limit}} </0> دقيقة <1 /> <2 /> <3> لتغيير حد البث المباشر الشهري الخاص بك ، يُرجى الاتصال بـ </3> <4> admin @ sharelook.com </4> ',
    location: 'الموقع',
    lessons: 'الدروس',
    location_broadcast: ' موقع خادم المضيف<0> ',
    location_broadcast_subtitle:
      'أضف موقعًا قريبًا من موقع مضيف البث لتقليل التأخير السمعي البصري',
    media_manager: 'مدير الإعلام',
    minutes: 'الدقائق',
    name: 'اسم',
    online_quiz: 'اختبار عبر الإنترنت',
    organiser: 'مؤلف',
    organization: 'منظمة',
    overall_emotion: ' الخبرة الشاملة',
    organizations_list: ` قائمة المنظمات, `,
    password: 'كلمه السر',
    participants: 'المشاركين',
    picture: ' صورة (16:9 ) ',
    phone: 'هاتف',
    photo: 'صورة فوتوغرافية',
    poll: 'تصويت',
    position: 'موضع',
    processing: 'معالجة',
    privacy_settings: 'إعدادات الخصوصية',
    question: 'سؤال',
    quiz_create: 'إنشاء اختبار جديد',
    quiz_name: 'اسم الاختبار',
    quiz_update: 'تحديث الاختبار',
    select_organization: 'اختر منظمة',
    settings: 'الإعدادات',
    show: 'يعرض',
    show_graph: 'إظهار نتيجة الرسم البياني',
    signin: 'تسجيل الدخول',
    signup: 'سجل',
    sort_by: 'صنف حسب',
    start_date: 'تاريخ البدء',
    tags: 'العلامات',
    terms: 'أوافق على <1> هذه الأحكام والشروط </1>.',
    title: 'عنوان',
    topics: 'المواضيع',
    topic: 'موضوع',
    total_comments: 'إجمالي التعليقات',
    username: 'اسم المستخدم',
    users_list: 'قائمة المستخدمين',
    users_emails: 'إدراج رسائل بريد إلكتروني محددة للمستخدمين',
    description_specifiction_email:
      'الرجاء إدخال عنوان بريد إلكتروني واحد في كل سطر لمشاركة هذا مع عدة مستخدمين محددين',
    message_specific_email: 'الرجاء إدخال بريد إلكتروني للمستخدم المحدد',
    viewers: 'المشاهدون',
    edit_course: 'تعديل',
    edit_assessment: 'تعديل',
    edit_event: 'تعديل',
    delete_course: 'حذف',
    delete_event: 'حذف',
    delete_assessment: 'حذف',
    view_course: 'عرض',
    view_event: 'عرض',
    view_assessment: 'عرض',
    date_event: 'تاريخ الفعالية المباشر',
    date_assessment: 'تاريخ التقييم المباشر',
    clone_of: 'استنساخ',
    clone_course:
      'إنشاء نسخة من هذه الدورة التدريبية يمكنك تعديلها بشكل مستقل عن الأصل ، وتتضمن جميع الدروس والصفحات',
    make: ' جعل',
    available_to: 'متاح لأي مستخدم ShareLook',
    uploaded_by: 'تم الرفع بواسطة: ',
    course: 'دورة',
    assessment: 'تقييم',
    user_not_exist: 'المستخدم غير موجود ، هل ترغب في إرسال دعوة؟',
    check_user: 'تنويه',
    user_not_exist_in_org:
      'المستخدم غير موجود في هذه المؤسسة ، هل ترغب في إرسال دعوة؟',
    title_modal_stream: 'تغيير حد البث المباشر للمؤسسة',
    des_warning_stream: 'أنت على وشك التغيير',
    monthly_stream: 'حد البث المباشر الشهري:',
    min_per_month: 'دقيقة / شهر',
    broadcast_label: 'أريد أن أفعل',
    hosts_emails: ' أدخل رسائل البريد الإلكتروني الخاصة بالمضيف ،',
    attendee_emails: ' أدخل رسائل البريد الإلكتروني الخاصة بالحضور',
    description_host_specifiction_email:
      'الرجاء إدخال عنوان بريد إلكتروني واحد في كل سطر.',
    presenter_label: 'مقدم',
    presenter_label_conference: 'عدد الناس',
    event_privacy: 'وضع المؤتمر',
    alert: 'إنذار!',
    warning: 'تحذير!',
    ok: 'حسنا',
    cancel: 'إلغاء',
    done: 'منجز',
    insert: 'إدراج',
    private: 'نشر',
    public: 'عامة',
    no_connection: 'انقطع الاتصال بالإنترنت',
    switch: 'مفتاح كهربائي',
    share_screen: 'شاشة المشاركة',
    stop_share_screen: 'إيقاف مشاركة الشاشة',
    share_screen_with_camera: 'مشاركة الشاشة مع الكاميرا',
    stop_share_screen_with_camera: 'وقف مشاركة الشاشة مع الكاميرا',
    camera_on: 'الكاميرا قيد التشغيل',
    camera_off: 'إيقاف تشغيل الكاميرا',
    mic_on: 'إعادة الصوت',
    mic_off: 'كتم الصوت',
    fullscreen: 'تكبير الشاشة',
    live_now: 'يعيش الآن',
    schedule_on: 'جدول ل',
    organisations: 'المنظمات',
    org_groups: 'مجموعات المنظمة',
    specific_user: 'مستخدم محدد',
    gif: 'GIF',
    unavailable: 'غير متوفره',
    yes: 'نعم',
    no: 'لا',
    event_duration_message: '300 دقيقة كحد أقصى (5 ساعات)',
    send_email: 'إرسال إشعار بالبريد الإلكتروني',
    admin_only: 'المسؤول فقط',
    join: 'انضم',
    leave: 'غادر',
    share: 'شارك',
    share_text: 'الرجاء كتابة البريد الإلكتروني لمن تريد مشاركة هذا الملف',
    share_text_title: 'شارك PDF',
    select_users: 'حدد المستخدمين',
    delete: 'حذف', // to_translate
    attachment_event: 'مرفقات الفعالية:', // to_translate
    transcribe_limit: 'كتابة الدقائق'
  },

  broadcastOptions: {
    stream_from_phone: 'البث المباشر بهاتفي',
    other_webrtc: 'Webinar من جهاز الكمبيوتر الخاص بي',
    multihost: 'برنامج تعليمي على الويب متعدد البث من جهاز الكمبيوتر الخاص بي',
    peer2peer: 'دورة تدريب',
    conference: 'مؤتمر مباشر'
  },

  lessoncreate: {
    title: 'إضافة تفاصيل الدرس',
    subtitle: 'قم بتسمية درسك وأخبر جمهورك بما يجب عليهم توقعه للتعلم منه',
    input_label_title: ' عنوان الدرس ',
    input_label_topics: 'مواضيع الدرس',
    input_label_description: 'وصف الدرس',
    delete_warning: 'هل أنت متأكد أنك تريد حذف هذه الصفحة؟'
  },

  lessonoverview: {
    title: 'إضافة درس',
    subtitle: 'إضافة درس موجود أو إنشاء درس جديد',
    new_lesson: 'إنشاء درس جديد',
    new_lesson_sub: 'أنشئ درسًا جديدًا تمامًا من البداية!',
    existing_lesson: 'اختر الدرس الحالي',
    existing_lesson_sub:
      'الارتباط بدرس موجود ، ستؤثر التغييرات التي يتم إجراؤها على هذا الدرس أيضًا على هذا الدرس في الدورات التدريبية الأخرى',
    clone_lesson: 'تكرار الدرس الحالي',
    clone_lesson_sub:
      'قم بإنشاء نسخة فريدة من الدرس في هذه الدورة التدريبية ، ولن تؤثر التغييرات التي يتم إجراؤها على هذا الدرس على الدرس الأصلي',
    clone_lesson_modal_sub:
      'الرجاء النقر فوق زر النسخ الموجود أسفل الدرس الذي ترغب في إضافته إلى الدورة التدريبية الخاصة بك'
  },

  management: {
    groups_title: 'إدارة المجموعات',
    delete_warning: 'هل أنت واثق؟',
    organizations_title: 'مؤسساتي',
    organizations_subtitle: 'أضف منظمات إلى قائمتك',
    users_title: 'ادارة المستخدمين',
    users_position: 'مدير حسابات أول',
    user_not_found: 'المستخدم ليس موجود',
    select_org: 'الرجاء البحث عن واختيار منظمة لإدارة المستخدمين'
  },

  audio: {
    modal_title: 'أدخل الصوت',
    list_view: 'عرض القائمة',
    create_audio_modal_title: 'إنشاء الصوت',
    edit_audio_modal_title: 'تحرير الصوت',
    public_audio_not_found: 'الصوت غير موجود',
    private_audio_not_found: 'لم تقم بتحميل أي ملف صوتي بعد',
    are_you_sure_to_delete: 'هل تريد بالتأكيد الحذف؟',
    type_something: 'اطبع شيئا',
    select_voice: 'تحديد',
    file_name: 'اسم الملف',
    description: 'وصف',
    topic: 'موضوع',
    key_words_tag: ' علامة الكلمات الرئيسية ',
    mp3_audio: 'صوت MP3',
    created: ' تم الإنشاء ',
    duration: 'المدة الزمنية',
    close: 'إغلاق'
  },

  image: {
    modal_title: 'إدراج صورة',
    image: 'صورة',
    pixel: 'بكسل',
    dimension: 'البعد',
    public_image_not_found: 'الصورة غير موجودة',
    private_image_not_found: 'لم تقم بتحميل أي صورة بعد'
  },

  annotation: {
    modal_title: 'حاشية. ملاحظة'
  },

  text: {
    modal_title: 'محرر النص'
  },

  video: {
    modal_title: 'إدراج فيديو',
    image: `فيديو,`,
    mp4_video: 'MP4 فيديو',
    public_video_not_found: 'لم يتم العثور على الفيديو',
    private_video_not_found: 'لم تقم بتحميل أي فيديو حتى الآن'
  },

  media: {
    title: 'مكتبة الوسائط',
    subtitle: 'مكتبة ملفاتك',
    modal_title: 'تحميل الوسائط',
    delete_warning: ' تأكيد الحذف ',
    delete_message:
      'ستظل الوسائط المحذوفة تظهر في الصفحات التي تم استخدامها فيها',
    dropzone_text: 'أو السحب والإفلات',
    delete_success: 'تم حذف الوسائط بنجاح',
    upload_file: 'رفع ملف',
    drag_drop: 'أو السحب والإفلات',
    import_file: 'استيراد ملف (ملفات) من جهاز الكمبيوتر الخاص بك',
    no_media: 'لم تقم بتحميل أي وسائط حتى الآن',
    media_type: 'نوع الوسائط',
    video: 'فيديو',
    image: 'صورة',
    transcribed_text: 'Transcribed Text',
    audio: 'صوت',
    gif: 'صورة متحركة',
    pdf: 'PDF',
    media_not_found: 'الوسائط غير موجودة',
    of: 'of',
    disclaimer: ` أضمن أن وسائط الإعلام الخاصة بي لا:
     - تنتهك حقوق أي طرف ثالث
     - تحتوي على أي محتوى غير قانوني ، بما في ذلك المحتوى التشهيري أو الفاحش أو غير اللائق أو البذيء أو الإباحي أو العنيف أو المسيء أو المهين أو التهديد أو التمييزي ، حتى المقنع بعلامات النجمة
     - تحتوي على أي اعتداء شخصي على أشخاص آخرين
     - تحتوي على أي انتحال صفة لمشاركين آخرين أو شخصيات عامة أو مشاهير
     - تحتوي على أي معلومات شخصية عن أي فرد دون موافقة هذا الفرد ولا تنتهك أي لائحة لحماية البيانات في أي جزء من العالم
     - تحتوي على أي طلب تجاري أو أي شكل من أشكال "البريد العشوائي",`,
    my_media: 'وسائطي',
    public_library: 'مكتبة عامه',
    organization_library: 'مكتبة المؤسسه',
    upload_from_computer: 'التحميل من الكمبيوتر',
    create: 'إنشاء',
    all: 'الجميع',
    male: 'رجل',
    female: 'أنثى',
    processing_audio: 'معالجة الصوت',
    create_annotation: 'إنشاء تعليق توضيحي',
    popup_over_lesson_text: 'سيظهر التعليق التوضيحي على صفحة الدرس بعد النقر',
    edit_detail: 'تعديل التفاصيل',
    delete: 'حذف',
    go_create_audio: `اذهب لإنشاء الصوت`,
    show_more_details: `اذهب لإنشاء الصوت`,
    show_less_details: `اذهب لإنشاء الصوت`,
    drop_files: `اذهب لإنشاء الصوت`,
    or: `اذهب لإنشاء الصوت`,
    import_files: `اذهب لإنشاء الصوت`,
    human: 'بشري',
    shared_file: 'تم مشاركة ملفك بنجاح', // to_translate
    error_shared_file: 'هناك مشكلة في مشاركة الملف ، يرجى المحاولة مرة أخرى' // to_translate
  },
  navigation: {
    account: 'الحساب',
    assessments: ' التقييمات',
    dashboard: 'لوحة القيادة',
    groups: 'إدارة المجموعة',
    logout: 'تسجيل خروج',
    organisations: 'المنظمات',
    organizations_users: 'المنظمات والمستخدمون',
    reports: 'التقارير',
    schedule: 'جدول',
    training: 'تدريب',
    users: 'المستخدمون',
    workshop: 'ورشة العمل الخاصة بي',
    nowLive: 'بث'
  },

  onboarding: {
    step1_title: 'الخطوة 1: أخبرنا قليلاً عن نفسك',
    step1_subtitle: 'ما اسمك؟ من أي بلد أنت؟ ما هو المسمى الوظيفي الخاص بك؟',
    step2_title: 'الخطوة 2: حدد بعض المهارات التي يمكنك تحسينها',
    step2_subtitle:
      ' بعد ذلك ، أخبرنا بما تريد أن تفهمه بشكل أفضل. ربما شيء لم تلتقطه بعد؟ مجال جديد للدراسة؟ ماذا يفعل محبوبك؟'
  },

  pagebuilder: {
    title: 'إنشاء درس',
    subtitle:
      'حان الوقت لإنشاء المحتوى لكي يتعلم جمهورك منك ، يمكنك الصور ومقاطع الفيديو والرسوم البيانية',
    select_layout: 'حدد تخطيط الصفحة',
    template1: 'فارغ',
    template2: 'العنوان والعنوان الفرعي',
    template3: 'العنوان والنص',
    template4: 'العنوان والوسائط والنص',
    template5: 'العنوان والوسائط',
    template6: 'العنوان والاختبار'
  },

  pageconstructor: {
    title: 'إنشاء واستنساخ الدرس',
    subtitle:
      'حان الوقت لإنشاء المحتوى لجمهورك للتعلم منك ، يمكنك إضافة الصور ومقاطع الفيديو والرسوم البيانية'
  },

  passwordreset: {
    success_coded:
      'لقد أرسلنا رمز تأكيد بالبريد الإلكتروني إلى العنوان الذي قدمته ، يرجى نسخ الرمز في الحقل أدناه لتعيين كلمة مرور جديدة.',
    success_changed:
      'لقد قمت بتعيين كلمة مرور جديدة بنجاح. يمكنك تسجيل الدخول الآن '
  },

  placeholders: {
    search: 'بحث',
    answer: 'إجابة',
    chat: 'قل شيئا...',
    code: 'الشفرة',
    criterion: 'معيار',
    date_event: 'حدد التاريخ والوقت',
    date_start: 'تاريخ البدء',
    date_expire: 'تاريخ الانتهاء',
    description: 'وصف',
    elements: 'فرز العناصر',
    email: 'البريد الإلكتروني',
    feedback_correct: 'الملاحظات صحيحة',
    feedback_incorrect: 'الملاحظات غير صحيحة',
    host: 'مضيف',
    keywords: 'كلمات البحث)',
    lesson_description: 'وصف الدرس',
    location: 'موقعك',
    minutes: 'الدقائق',
    name: 'اسم',
    organiser: 'مؤلف',
    organization: 'منظمة',
    password: 'كلمه السر',
    phone: 'هاتف',
    photo: 'صورة فوتوغرافية',
    picture: 'صورة',
    point: 'نقطة',
    position: 'منصب',
    question: 'سؤال',
    quiz: 'اسم الاختبار',
    search_course: 'البحث بالدورة',
    search_media: 'البحث في الوسائط',
    search_name: 'البحث عن طريق الإسم',
    search_title: 'البحث حسب العنوان',
    search_training: 'التدريب على البحث',
    settings: 'الإعدادات',
    tags: 'العلامات',
    title: 'عنوان',
    topics: 'المواضيع',
    username: 'اسم المستخدم',
    keyword: 'الكلمات الدالة',
    max_500_characters: `500 حرف كحد أقصى`,
    no_description: 'لا يحتوي هذا الملف على وصف',
    no_keyword: 'هذا الملف لا يحتوي على أية كلمات رئيسية',
    no_topics: 'لا يحتوي هذا الملف على مجموعة مواضيع',
    feedback: 'رسالة',
    contact: ` "كيف يمكننا المساعدة؟', `
  },

  publishsettings: {
    title: 'إعدادات النشر',
    event_subtitle:
      'إضافة إعدادات إلى الفعالية الخاص بك ، الذي يمكنه عرض الفعالية والتفاعل معه',
    assessment_subtitile:
      'حدد اسمًا لتقييمك وأخبر جمهورك بما يجب أن يتوقعوا تعلمه منه',
    course_subtitle:
      'إضافة إعدادات إلى مقررك الدراسي ، الذي يمكنه عرض الدورة التدريبية والتفاعل معها',
    disclaimer: `•	التسجيل

بالتسجيل كمستخدم / إنشاء حساب فإنك توافق على [اتفاقية المستخدم] وتقر بقراءة [سياسة الخصوصية] الخاصة بنا



	•	إنشاء دورة

باستخدام أدواتنا وإنشاء محتوى الدورة التدريبية ، فإنك توافق على [اتفاقية المستخدم] وتقر بقراءة [سياسة الخصوصية]



	•	إنشاء فعالية

باستخدام أدواتنا وإنشاء فعالية ، فإنك توافق على [اتفاقية المستخدم] وتقر بقراءة [سياسة الخصوصية]


	•	إنشاء التقييم

باستخدام أدواتنا وإنشاء تقييم ، فإنك توافق على [اتفاقية المستخدم] وتقر بقراءة [سياسة الخصوصية] الخاصة بنا"`,
    dates_validation_error: 'يجب ملء كلا التاريخين.',
    emails_validation_error: 'قائمة البريد الإلكتروني غير صالحة.',
    specific_email_validation_error: 'مسموح بريد إلكتروني واحد فقط',
    i_agree_to_these: 'أوافق على هذه',
    terms_and_conditions: 'الأحكام والشروط.',
    PublicDescription:
      'المستخدمون المستقلون المستقلون: (الخيارات الخاصة والعامة) تم حفظ [الدورة التدريبية | الفعالية | التقييم] واعتبرت خاصة بشكل افتراضي. هذا يعني أنه يمكنك فقط رؤية هذا المحتوى. لنشر هذا المحتوى ، يمكنك تحديده لجعله عامًا أدناه. هذا يعني أن الدورة ستكون متاحة لجميع المستخدمين في المنصة.,',
    OrganisationsDescription:
      'المستخدمون في المؤسسة: (بدلاً من "" إنهاء "" ، استخدم "" نشر "" وأحضرهم إلى صفحة النشر مثل المستخدمين الآخرين ولكن بدون أي من خيارات النشر.) [الدورة التدريبية | فعالية | التقييم] تم حفظه وهو خاص. هذا يعني أنه يمكنك أنت والمسؤولون في مؤسستك فقط رؤيتها. كمستخدم ، لا يمكنك نشر المحتوى الخاص بك داخل مؤسستك بنفسك لأن هذا يتطلب حقوق المسؤول. يرجى الاتصال بأحد المسؤولين لديك واطلب منهم نشر المحتوى الخاص بك علنًا أو للمؤسسة بأكملها أو لمجموعات محددة داخل مؤسستك أو لمستخدمين محددين.,',
    OrganisationGroupsDescription:
      'مسؤولو المؤسسة: (للمحتوى الموجود داخل المؤسسة: خاص ، عام ، مؤسسة ، مجموعات ، مستخدمين محددين): هذا [الدورة التدريبية | فعالية | التقييم] تم حفظه ويعتبر خاصًا بشكل افتراضي. هذا يعني أنه يمكنك أنت (ومنشئ المحتوى) فقط مشاهدة هذا المحتوى. لنشر هذا المحتوى ، يمكنك تحديد أحد الخيارات أدناه. يعني نشر الدورة التدريبية الخاصة بك للجمهور أنها ستكون متاحة لجميع المستخدمين في النظام الأساسي - بما في ذلك أولئك الذين هم خارج مؤسستك. يعني نشره في المؤسسة أن جميع المستخدمين داخل المؤسسة سيتمكنون من رؤيته. يمكنك أيضًا نشره إلى مجموعة واحدة أو أكثر ، مما يعني أنه لن يتمكن من رؤيته سوى المستخدمين داخل هذه المجموعات. أخيرًا ، لديك خيار نشره إلى مستخدمين محددين داخل مؤسستك وخارجها باستخدام عنوان البريد الإلكتروني لحساب Sharelook الخاص بهم., ',
    SpecificUsersDescription: ` المستخدمون المستقلون المدفوعون: (المستخدمون الخاصون والعامون والمستخدمون المحددون) [دورة | فعالية | التقييم] تم حفظه ويعتبر خاصًا بشكل افتراضي. هذا يعني أنه يمكنك فقط رؤية هذا المحتوى. لنشر هذا المحتوى ، يمكنك تحديد أحد الخيارات أدناه. يعني نشر الدورة التدريبية الخاصة بك للجمهور أنها ستكون متاحة لجميع المستخدمين في المنصة. سيتيح لك خيار المستخدمون المحددون مشاركة الدورة التدريبية الخاصة بك مع مستخدمين محددين باستخدام عنوان البريد الإلكتروني المرتبط بحساب Sharelook الخاص بهم.'`
  },

  quiz: {
    quiz_empty: 'لم تقم بإنشاء اختبار حتى الآن',
    questions_empty: 'لم تقم بعد بإنشاء سؤال'
  },

  regions: {
    asia_pacific_australia: 'أستراليا',
    asia_pacific_india: 'الهند',
    asia_pacific_japan: 'اليابان',
    asia_pacific_s_korea: 'كوريا الجنوبية',
    asia_pacific_singapore: 'سنغافورة',
    asia_pacific_taiwan: 'تايوان',
    eu_belgium: 'بلجيكا',
    eu_germany: 'ألمانيا',
    eu_ireland: 'أيرلندا',
    south_america_brazil: 'البرازيل',
    us_central_iowa: 'أيوا',
    us_east_s_carolina: 'كارولينا الجنوبية',
    us_east_virginia: 'فرجينيا',
    us_west_california: 'كاليفورنيا',
    us_west_oregon: 'أوريغون'
  },

  reports: {
    title: 'التقارير',

    workshop_report: 'تقرير ورشة العمل',
    user_report: 'تقرير المستخدم',
    organization_workshop: 'ورشة التنظيم',
    course: 'دورة',
    live_event: 'حدث مباشر',
    workShop_graph: 'الرسم البياني لورشة العمل',
    organization_account: 'حساب المؤسسه',
    all: 'الجميع',
    name: 'الأسم',
    id_number: 'رقم الهويه',
    email: 'البريد الألكتروني',
    search_user_report: 'بحث في تقرير المستخدم',
    participant: 'المشارك',
    participants: 'المشاركون',
    host: 'ضيف',
    username: 'أسم المستخدم',
    event: 'حدث',
    quiz: 'أختبار',
    assessment: 'التقييم',
    time: 'الوقت',
    no_recent_activity: 'لا يوجد نشاط حديث',
    workshops: 'ورش العمل',
    courses: 'الدورات',
    live_events: 'حدث مباشر',
    live_assessments: 'تقييمات حيه',
    quizzes: 'أختبارات قصيره',
    overview: ' نظرة عامة',
    by: 'بواسطة',
    views: 'وجهات النظر',
    status: 'الحاله',
    poll: 'أستطلاع الرأي',
    comment: 'تعليق',
    comments: 'تعليقات',
    no_comments: 'لا تعليقات',
    no_polls: 'لا استطلاعات رأي',
    live_date: 'تاريخ حي',
    score: 'النتيجة',
    question: 'سؤال',
    submit: 'إرسال تقديم',
    recent_activity: 'النشاط الأخير',
    ago: 'منذ',
    no_data_found: 'لم يتم العثور على بيانات',
    start_end_live: ' بدء / إنهاء البث المباشر',
    join_leave_date: ' انضمام / مغادرة',
    watched_time: `وقت المشاهده`,
    complete: 'مكتمل',
    in_complete: `Iغير مكتمل`,
    today: 'اليوم',
    seven_days: `سبعة أيام`,
    last_month: `الشهر الماضي`,
    one_year: ' سنة واحدة',
    all_time: 'كل الوقت',
    report: 'تقرير',
    from_date: 'من تاريخ',
    to_date: 'حتى الان',
    all_workshop: 'جميع ورش العمل',
    all_participants: 'كل المشاركين',
    live_assessment: 'تقييم حي',
    users: 'المستخدمون',
    download: 'تنزيل',
    recent: 'الأخيرة',
    WorkShop: 'ورشة عمل',
    Activity: 'نشاط',
    created_date: 'تاريخ الإنشاء',
    lessons: 'دروس',
    lesson: 'درس',
    completed: 'مكتمل',
    search_course: `الدورة التي يتم البحث عنها`,
    participants_graph: 'الرسم البياني للمشاركين',
    viewed: `ينظر`,
    enrolled: `المقيدين`,
    description: `وصف`,
    topic: `موضوع`,
    analytic: `تحليلي`,
    quiz_list: `قائمة الاختبار`,
    report_download: `تقرير تنزيل`,
    this_mont: `هذا الشهر`,
    select_one: 'حدد واحدًا ',
    group_status: 'حالة المجموعه',
    duration_min: 'المدة الزمنية(دقيقه)',
    submit_poll: 'إرسال الاستطلاع',
    download_events: 'تنزيل الأحداث',
    search_event: 'الحدث الذي يتم البحث عنه',
    duration: 'المدة الزمنية',
    total_participants: 'إجمالي عدد المشاركين',
    message: 'رسالة',
    avg_message: 'متوسط الرسالة',
    per_message: 'النسبة المئوية من الرسائل / الحدث'
  },

  signup: {
    success_step1:
      'لقد أرسلنا رمز التحقق عبر البريد الإلكتروني إلى العنوان الذي قدمته ، يرجى نسخ الرمز في الحقل أدناه لتأكيد تسجيلك.',
    success_step2: `'شكر. لقد تم تسجيلك بنجاح. ", `,
    privacy:
      ' أوافق على <1> البنود والشروط </ 1> و <3> سياسة الخصوصية </3 < الخاصة بـ Sharelook'
  },

  stream: {
    emotion_impressive: 'مثير للإعجاب',
    emotion_unimpressive: 'غير مثير للإعجاب',
    message_not_started: 'قريبا',
    message_running: 'مباشر',
    message_processing:
      'نقوم حاليًا بمعالجة التسجيل المباشر للتشغيل. يرجى إعادة التحقق لاحقًا لمشاهدة الفيديو ولمتابعة إضافة التقييمات والتعليقات '
  },

  tabs: {
    audience: 'الجمهور',
    course: 'نظرة عامة على الدورة التدريبية',
    details: 'تفاصيل الفعالية المباشر',
    description: 'وصف',
    host: 'مضيف',
    lesson: 'نظرة عامة على الدرس',
    lessons: 'الدروس',
    overview: 'نظرة عامة',
    page: 'منشئ الصفحات',
    publish: 'ينشر',
    settings: 'إعداد النشر',
    total_views: 'عدد المشاهدات',
    live_participates: 'المشاركون المباشرون',
    live_chats: 'محادثات مباشرة',
    live_quiz: 'الاستطلاعات المباشرة',
    trainer_rating: 'تقييم المدرب',
    trainer_times: 'أوقات المدرب',
    courses_quizzes: 'اختبارات الدورات',
    user_list: 'قائمة المستخدم',
    discussion: 'المناقشة',
    notes: 'ملاحظاتي',
    attachments: 'مرفق إضافي'
  },

  topics: {
    SALES_TRAINING: 'تدريب المبيعات',
    NEGOTIATION_TRAINING: ' تدريب على التفاوض',
    CROSS_CULTURAL_AWARENESS: 'الوعي بين الثقافات',
    NETWORKING_SKILLS: 'مهارات التواصل',
    SOFTWARE_ARCHITECTURE: 'هيكلة البرمجيات',
    PROJECT_MANAGEMENT: 'ادارة مشروع',
    DIGITAL_TRANSFORMATION: 'التحول الرقمي',
    IT_CONSULTING: 'استشارات تكنولوجيا المعلومات',
    MANAGEMENT_CONSULTING: 'الاستشارات الإدارية',
    HR_CONSULTING: 'استشارات الموارد البشرية',
    INTERNET_OF_THINGS: 'إنترنت الأشياء (IoT)',
    CYBERSECURITY: 'الأمن السيبراني',
    PRIVACY_AND_GDRP: 'الخصوصية واللائحة العامة لحماية البيانات'
  },

  training: {
    title: 'تدريب',
    title_assessment: 'التقييمات',
    subtitle: 'الدورات والفعاليات المباشرة',
    enrolled_title: 'الدورات المسجلة والفعاليات المباشرة',
    enrolled_subtitle: 'الدورات والفعاليات المباشرة التي قمت بالتسجيل فيها',
    enrolled_message: 'أنت غير مسجل حاليًا في أي دورات أو فعاليات.',
    past_live_event_title: 'الفعاليات المباشرة الماضية',
    past_live_event_subTitle: 'الفعاليات المباشرة المنتهية',
    past_live_event: 'لا توجد حاليًا فعاليات مباشرة سابقة',
    recomended_title: ' الدورات الموصى بها والفعاليات المباشرة ',
    now_live_event_title: 'مباشرة الان',
    now_live_event_subTitle: 'الفعاليات المباشرة أو التي ستصبح قريبًا جدًا',
    now_live_event: ' لا يوجد حاليا أي فعالة مباشرة ',
    webinar_event_title: 'قناة الندوات عبر الإنترنت ',
    webinar_event_subTitle: 'ندوات عبر الإنترنت منتهية',
    webinar_event: 'لا توجد حاليًا أي ندوة عبر الإنترنت',
    p2p_event_title: 'جلسات التدريب',
    p2p_event_subTitle: 'جلسة تدريب منتهية',
    p2p_event: 'لا توجد حاليا جلسة تدريب',

    now_live_assessment_title: ' مباشرة الان',
    now_live_assessment_subTitle: 'تقييم مباشر أو قريب جدًا',
    now_live_assessment_message: ' لا يوجد تقييم مباشر حاليًا',

    upcoming_assessment_title: 'التقييمات المباشرة القادمة',
    upcoming_assessment_subtitle: 'التقييمات المباشرة القادمة',
    upcoming_assessment_message: 'لا يوجد تقييم مباشر قادم حاليًا.',

    past_live_assessment_title: 'تقييمات مباشرة سابقة',
    past_live_assessment_subTitle: 'تقييمات مباشرة منتهية',
    past_live_assessment_message: 'لا توجد حاليًا تقييمات حية سابقة',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'قمنا بتنظيم دورات تدريبية وأحداث مباشرة بناءً على <0><0/> </0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'التدريب على البحث',
    search_assessment: 'تقييم البحث',
    recomended_message: 'لا توجد دورات وفعاليات موصى بها حاليًا'
  },

  warnings: {
    delete: 'هل أنت متأكد؟'
  },

  workshop: {
    title: 'ورشة العمل الخاصة بي',
    subtitle: 'إنشاء دورة أو استضافة فعالية مباشرة خاصة بك',
    new_course: ' إنشاء دورة جديدة',
    new_event: 'إنشاء فاعلية مباشرة جديدة',
    new_assessment: 'إنشاء تقييم مباشر جديد',
    courses_title: 'الدورات التي تم إنشاؤها والفعاليات المباشرة',
    courses_subtitle:
      'تحرير ونشر الدورة التدريبية التي تم إنشاؤها وتخطيط الفعالية المباشرة',
    tags_course: 'دورة',
    tags_live: 'حدث مباشر',
    tags_assessment: 'تقييم مباشر'
  },

  help_center: {
    sidebar: {
      title: 'كيف يمكننا مساعدتك؟',
      back_to: 'لوحة القيادة'
    },
    menu: {
      help_center: 'مركز المساعدة',
      whats_new: ' ما هو الجديد',
      feedback: 'تغذية راجعة',
      feedback_title: 'إرسال ملاحظات إلى Sharelook',
      terms_and_conditions: 'الشروط والأحكام',
      privacy_policy: 'سياسة الخصوصية',
      contact_us: 'اتصل بنا'
    },
    get_started: {
      title: 'ابدأ بـ ShareLook ',
      description: {
        main:
          'لقد قمنا بدمج فيديو مباشر متكامل إلى برنامج إنشاء دورة تدريبية سهل الاستخدام',
        tagline:
          '         مع نظام البث المباشر ShareLook الحاصل على براءة اختراع ، يمكنك إنشاء تفاعل وأتمتة التعلم وتعظيم مشاركة المعرفة, '
      }
    },
    whats_new: {
      title: 'ابدأ ب ShareLook ',
      description: {
        main: `"هل تعلم أن إنشاء أسلوب تعلم القص يمكن أن يخلق في وقت قصير وغير مكلف؟ يجب عليك الجمع بين منشئ دورة ShareLook والمواهب ذات الصلة. يمكنك أن تسألنا "كيف"،`,
        tagline: ''
      }
    },
    create_workshop: ' كيفية إنشاء ورشة عمل',
    create_course: 'كيفية إنشاء دورة',
    create_user: 'كيفية إنشاء مستخدم',
    create_group: 'كيفية إنشاء مجموعة',
    create_webinar: 'كيفية إنشاء ندوة عبر الإنترنت',
    create_live_event: 'كيفية إنشاء فعالية مباشرة',
    create_live_assessment: 'كيفية إنشاء تقييم مباشر',
    create_live_on_mobile: 'إنشاء مباشر على الجوال',
    personalize_profile: 'تخصيص ملف تعريف',
    manage_group: 'إدارة المجموعة',
    report: 'الإبلاغ',
    add_annotations: 'كيفية إضافة التعليقات التوضيحية / النوافذ المنبثقة',
    add_audio: 'كيفية إضافة صوت',
    add_header_and_footer: 'إضافة رأس وتذييل',
    add_image: 'كيفية إضافة صورة',
    add_quiz_content: 'كيفية إضافة محتوى الاختبار',
    add_text_content: 'كيفية إضافة محتوى نصي',
    add_user: 'كيفية إضافة مستخدم',
    add_video: 'كيفية إضافة فيديو',
    clone_course: 'كيفية استنساخ دورة',
    find_live_broadcast: 'كيفية البحث عن البث المباشر',
    contact_us: {
      title: 'اتصل بنا',
      subject_placeholder: ' أدخل الموضوع *',
      content_placeholder: 'أدخل رسالة *',
      subject_required: '* الموضوع مطلوب ',
      content_required: ` '* الرسالة مطلوبة ", `,
      submit: 'إرسال',
      cancel: 'إلغاء',
      sent: 'أرسلت'
    },
    feedback: {
      title: 'إرسال ملاحظات إلى شارلوك',
      attach_title: 'إرفاق ملف',
      choose: 'اختر ملف',
      detail: 'ستتم مشاركة الملف مع فريق الدعم',
      content_placeholder: ` أدخل رسالة *', `,
      content_required: `'* الرسالة مطلوبة `,
      submit: 'إرسال',
      cancel: 'إلغاء',
      sent: 'أرسلت'
    }
  },
  discussion: {
    join_discussion: 'انضم إلى المناقشة'
  },
  notes: {
    title: 'ملاحظاتي',
    add_note: 'إضافة ملاحظة جديدة'
  },
  groups: {
    search_group: `بحث المجموعة`
  }
}
