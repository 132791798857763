/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-08 11:28:55
 * @LastEditTime: 2019-08-19 13:45:13
 * @LastEditors: Please set LastEditors
 */
import {
  REPORTS_HANDLING_DATA,
  FETCH_REPORT_DATA,
  FETCH_USER_REPORT_DATA,
  GET_USER_REPORT_DATA,
  GET_EVENTS_REPORT_DATA,
  FETCH_EVENTS_REPORT_DATA,
  GET_COURSES_REPORT_DATA,
  FETCH_COURSES_REPORT_DATA,
  GET_LESSON_REPORT_DATA,
  FETCH_LESSON_REPORT_DATA,
  FETCH_QUIZ_LIST_REPORT_DATA,
  GET_QUIZ_LIST_REPORT_DATA,
  FETCH_QUIZ_REPORT_DATA,
  GET_QUIZ_REPORT_DATA,
  GET_WORKSHOP_COURSES_REPORT_DATA,
  FETCH_TIME_SPENT_DATA,
  GET_USER_TIME_SPENT_REPORT_DATA,
  FETCH_EVENT_WORKSHOP_DATA,
  GET_EVENT_WORKSHOP_DATA,
  GET_COURSE_LIST,
  GET_MARKET_COURSE_LIST,
  GET_EVENT_LIST,
  FETCH_COURSE_LIST,
  FETCH_EVENT_LIST,
  GET_LIVE_ASSESSMENTS_LIST,
  FETCH_LIVE_ASSESSMENTS_LIST,
  GET_SKILL_LIST,
  FETCH_SKILL_LIST,
  GET_MAZE_REPORT,
  FETCH_MAZE_REPORT,
  GET_MAZE_REPORT_BY_ID,
  FETCH_MAZE_REPORT_BY_ID,
  FETCH_SKILL_JOURNEY_WORKSHOP_DATA,
  GET_SKILL_JOURNEY_WORKSHOP_DATA,
  FETCH_SKILL_JOURNEYS_REPORT_DATA,
  GET_SKILL_JOURNEYS_REPORT_DATA,
  FETCH_LIVE_ASSESSMENTS_REPORT_DATA,
  GET_LIVE_ASSESSMENTS_REPORT_DATA,
  GET_MARKET_WEBINAR_LIST,
  GET_MARKET_COACHING_LIST,
  GET_MARKET_PUBLIC_CONFERENCE_LIST,
  GET_MARKET_MAZE_LIST,
  GET_MARKET_COURSE_ANALYTICS,
  GET_MARKET_CHANNEL_LIST
} from '../types'

const initialState = {
  activeKey: '0',
  visible: false,
  userList: {
    fetch: false,
    list: [],
    records: 10
  },
  courseQuiz: {
    fetch: false,
    list: [],
    records: 10
  },
  trainerTimes: {
    fetch: false,
    list: [],
    records: 6
  },
  trainerRating: {
    fetch: false,
    list: [],
    records: 10
  },
  liveQuizes: {
    fetch: false,
    list: [],
    records: 10
  },
  liveChats: {
    fetch: false,
    list: [],
    records: 10
  },
  liveParticipates: {
    fetch: false,
    list: [],
    records: 10
  },
  singleUser: {
    fetch: false,
    data: {}
  },
  liveEvent: {
    fetch: false,
    data: {},
    records: 10
  },
  poll: {
    fetch: false,
    list: []
  },
  analytic: {
    fetch: false,
    list: []
  },
  comment: {
    fetch: false,
    list: []
  },
  courseLists: {
    fetch: false,
    data: {},
    records: 10
  },
  marketCourseLists: {
    fetch: false,
    data: {},
    records: 10
  },
  marketChannelLists: {
    fetch: false,
    data: {},
    records: 10
  },
  marketWebinarLists: {
    fetch: false,
    data: {},
    records: 10
  },
  marketCoachingLists: {
    fetch: false,
    data: {},
    records: 10
  },
  marketPublicConferenceLists: {
    fetch: false,
    data: {},
    records: 10
  },

  marketMazeLists: {
    fetch: false,
    data: {},
    records: 10
  },
  Lessons: {
    fetch: false,
    data: {}
  },
  LessonAnalytic: {
    fetch: false,
    data: {}
  },
  CourseAnalytic: {
    fetch: false,
    data: {}
  },
  Analytics: {
    fetch: false,
    data: {}
  },
  marketCourseAnalytics: {
    fetch: false,
    data: {}
  },
  marketEventAnalytics: {
    fetch: false,
    data: {}
  },
  marketChannelAnalytics: {
    fetch: false,
    data: {}
  },
  LessonQuiz: {
    fetch: false,
    data: {}
  },
  Quiz: {
    fetch: false,
    data: {}
  },
  overAll: {
    fetch: false,
    data: {}
  },
  byUser: {
    fetch: false,
    data: {}
  },
  workshop: {
    fetch: false,
    list: []
  },
  workshopCourses: [],
  userTimeSpentData: {
    list: [],
    fetch: false
  },
  workshopEvents: {
    list: [],
    fetch: false
  },
  reportMazes: {
    list: [],
    fetch: false
  },
  maze: {
    data: {},
    fetch: false
  },
  skillList: {
    fetch: false,
    data: {},
    records: 10
  },
  liveAssessmentsList: {
    fetch: false,
    data: {},
    records: 10
  },
  workshopSkillJourneys: {
    list: [],
    fetch: false
  },
  skillJourney: {
    data: {},
    fetch: false
  },
  liveAssessments: {
    data: {},
    fetch: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_HANDLING_DATA:
      if (action.payload.field.type) {
        return {
          ...state,
          [action.payload.field.type]: action.payload.value
        }
      } else {
        return {
          ...state,
          [action.payload.field]: action.payload.value
        }
      }
    case FETCH_REPORT_DATA:
      return {
        ...state,
        [action.payload.field.type]: {
          ...state[action.payload.field.type],
          fetch: action.payload.value
        }
      }

    case FETCH_USER_REPORT_DATA:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          fetch: action.payload
        }
      }

    case GET_USER_REPORT_DATA:
      return {
        ...state,
        singleUser: {
          ...state.singleUser,
          data: action.payload
        }
      }
    case FETCH_EVENTS_REPORT_DATA:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          data: action.payload.value
        }
      }
    case GET_EVENTS_REPORT_DATA:
      return {
        ...state,
        [action.payload.field]: action.payload
      }
    case FETCH_COURSES_REPORT_DATA:
      return {
        ...state,
        CourseAnalytic: {
          ...state.CourseAnalytic,
          fetch: action.payload
        }
      }
    case GET_COURSES_REPORT_DATA:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          data: action.payload.data
          // ...state,
          // [action.payload.field]: action.payload
        }
      }
    case FETCH_SKILL_JOURNEYS_REPORT_DATA:
      return {
        ...state,
        skillJourney: {
          ...state.skillJourney,
          fetch: action.payload
        }
      }

    case GET_SKILL_JOURNEYS_REPORT_DATA:
      return {
        ...state,
        skillJourney: {
          ...state.skillJourney,
          data: action.payload
        }
      }

    case FETCH_LIVE_ASSESSMENTS_REPORT_DATA:
      return {
        ...state,
        liveAssessments: {
          ...state.liveAssessments,
          fetch: action.payload
        }
      }

    case GET_LIVE_ASSESSMENTS_REPORT_DATA:
      return {
        ...state,
        liveAssessments: {
          ...state.liveAssessments,
          data: action.payload
        }
      }
    // return {
    //   ...state,
    //   [action.payload.field]: {
    //     ...state[action.payload.field],
    //     data: action.payload.data
    //   }
    case FETCH_LESSON_REPORT_DATA:
      return {
        ...state,
        LessonAnalytic: {
          ...state.LessonAnalytic,
          fetch: action.payload
        }
      }

    case GET_LESSON_REPORT_DATA:
      return {
        ...state,
        [action.payload.field.type]: {
          ...state[action.payload.field.type],
          data: action.payload.data
        }
      }
    case FETCH_QUIZ_LIST_REPORT_DATA:
      return {
        ...state,
        Quiz: {
          ...state.Quiz,
          fetch: action.payload
        }
      }

    case GET_QUIZ_LIST_REPORT_DATA:
      return {
        ...state,
        [action.payload.field.type]: {
          ...state[action.payload.field.type],
          data: action.payload.data
        }
      }
    case FETCH_QUIZ_REPORT_DATA:
      return {
        ...state,
        LessonQuiz: {
          ...state.LessonQuiz,
          fetch: action.payload
        }
      }

    case GET_QUIZ_REPORT_DATA:
      return {
        ...state,
        [action.payload.field]: {
          ...state[action.payload.field],
          data: action.payload.data
        }
      }

    case GET_WORKSHOP_COURSES_REPORT_DATA:
      return {
        ...state,
        workshopCourses: action.payload
      }

    case GET_USER_TIME_SPENT_REPORT_DATA:
      return {
        ...state,
        userTimeSpentData: {
          ...state.userTimeSpentData,
          list: action.payload
        }
      }

    case FETCH_TIME_SPENT_DATA:
      return {
        ...state,
        userTimeSpentData: {
          ...state.userTimeSpentData,
          fetch: action.payload
        }
      }

    case GET_EVENT_WORKSHOP_DATA:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          list: action.payload
        }
      }

    case FETCH_EVENT_WORKSHOP_DATA:
      return {
        ...state,
        workshopEvents: {
          ...state.workshopEvents,
          fetch: action.payload
        }
      }

    case GET_SKILL_JOURNEY_WORKSHOP_DATA:
      return {
        ...state,
        workshopSkillJourneys: {
          ...state.workshopSkillJourneys,
          list: action.payload
        }
      }

    case FETCH_SKILL_JOURNEY_WORKSHOP_DATA:
      return {
        ...state,
        workshopSkillJourneys: {
          ...state.workshopSkillJourneys,
          fetch: action.payload
        }
      }

    case GET_COURSE_LIST:
      return {
        ...state,
        courseLists: {
          ...state.courseLists,
          list: action.payload
        }
      }

    case GET_MARKET_COURSE_LIST:
      return {
        ...state,
        marketCourseLists: {
          ...state.marketCourseLists,
          list: action.payload
        }
      }

    case GET_MARKET_CHANNEL_LIST:
      return {
        ...state,
        marketChannelLists: {
          ...state.marketChannelLists,
          list: action.payload
        }
      }

    case GET_MARKET_WEBINAR_LIST:
      return {
        ...state,
        marketWebinarLists: {
          ...state.marketWebinarLists,
          list: action.payload
        }
      }
    case GET_MARKET_COACHING_LIST:
      return {
        ...state,
        marketCoachingLists: {
          ...state.marketCoachingLists,
          list: action.payload
        }
      }

    case GET_MARKET_PUBLIC_CONFERENCE_LIST:
      return {
        ...state,
        marketPublicConferenceLists: {
          ...state.marketPublicConferenceLists,
          list: action.payload
        }
      }
    case GET_MARKET_MAZE_LIST:
      return {
        ...state,
        marketMazeLists: {
          ...state.marketMazeLists,
          list: action.payload
        }
      }

    case GET_EVENT_LIST:
      return {
        ...state,
        liveEvent: {
          ...state.liveEvent,
          list: action.payload
        }
      }
    case GET_SKILL_LIST:
      return {
        ...state,
        skillList: {
          ...state.skillList,
          list: action.payload
        }
      }
    case FETCH_SKILL_LIST:
      return {
        ...state,
        skillList: {
          ...state.skillList,
          fetch: action.payload
        }
      }
    case GET_LIVE_ASSESSMENTS_LIST:
      return {
        ...state,
        liveAssessmentsList: {
          ...state.liveAssessmentsList,
          list: action.payload
        }
      }
    case FETCH_LIVE_ASSESSMENTS_LIST:
      return {
        ...state,
        liveAssessmentsList: {
          ...state.liveAssessmentsList,
          fetch: action.payload
        }
      }
    case FETCH_COURSE_LIST:
      return {
        ...state,
        courseLists: {
          ...state.courseLists,
          fetch: action.payload
        }
      }
    case FETCH_EVENT_LIST:
      return {
        ...state,
        liveEvent: {
          ...state.liveEvent,
          fetch: action.payload
        }
      }

    case FETCH_MAZE_REPORT: {
      return {
        ...state,
        reportMazes: {
          ...state.reportMazes,
          fetch: action.payload
        }
      }
    }

    case GET_MAZE_REPORT: {
      return {
        ...state,
        reportMazes: {
          ...state.reportMazes,
          list: action.payload
        }
      }
    }

    case GET_MARKET_COURSE_ANALYTICS: {
      return {
        ...state,
        marketCourseAnalytics: {
          ...state.marketCourseAnalytics,
          list: action.payload
        }
      }
    }

    case GET_MARKET_COURSE_ANALYTICS: {
      return {
        ...state,
        marketEventAnalytics: {
          ...state.marketEventAnalytics,
          list: action.payload
        }
      }
    }

    case GET_MARKET_COURSE_ANALYTICS: {
      return {
        ...state,
        marketChannelAnalytics: {
          ...state.marketChannelAnalytics,
          list: action.payload
        }
      }
    }

    case FETCH_MAZE_REPORT_BY_ID: {
      return {
        ...state,
        maze: {
          ...state.maze,
          fetch: action.payload
        }
      }
    }

    case GET_MAZE_REPORT_BY_ID: {
      return {
        ...state,
        maze: {
          ...state.maze,
          data: action.payload
        }
      }
    }

    default:
      return state
  }
}
