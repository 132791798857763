import React from 'react'
import ScenarioConfirmationImage from '../../img/scenario-paper.svg'
import { Button, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import './styles.scss'

function ScenarioConfirmationModal({ onEdit, onCreate, onCancel }) {
  const handleClick = type => {
    const handler = type === 'edit' ? onEdit : onCreate
    if (handler) handler()
  }
  const { t } = useTranslation()

  return (
    <Modal visible footer={null} closable centered onCancel={onCancel}>
      <div className="scenario-confirmation">
        <img src={ScenarioConfirmationImage} alt="" />

        <p>{t('v3:existing_edit_scenario')}</p>

        <div className="btn-group">
          <Button
            type="primary"
            shape="round"
            onClick={() => handleClick('edit')}
          >
            {t('buttons:edit')}
          </Button>
          <Button
            type="green"
            shape="round"
            onClick={() => handleClick('create')}
          >
            {t('v2:create')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ScenarioConfirmationModal
