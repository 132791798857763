// size-list for custom React-Quill Size
const sizeList = [
  '8px',
  '10px',
  '12px',
  '14px',
  '16px',
  '18px',
  '20px',
  '24px',
  '36px',
  '48px'
]

const toolbar = {
  container: [
    {
      header: [1, 2, 3, false]
    },
    { size: sizeList },
    'bold',
    'italic',
    'underline',
    'link',
    { list: 'ordered' },
    { list: 'bullet' },
    {
      color: [
        '#ff0000',
        '#ff7f00',
        '#ffff00',
        '#00ff00',
        '#0000ff',
        '#2e2b5f',
        '#8b00ff',
        'color-picker'
      ]
    },
    { align: ['', 'right', 'center', 'justify'] }
  ],
  handlers: {
    color: function(value) {
      let quill = this.quill
      if (value === 'color-picker') {
        var picker = document.getElementById('color-picker')
        if (!picker) {
          picker = document.createElement('input')
          picker.id = 'color-picker'
          picker.type = 'color'

          picker.addEventListener('change', function() {
            quill.format('color', picker.value)
          })

          let colorItems = document.querySelectorAll(
            `[data-value='color-picker']`
          )

          let colorItem =
            colorItems &&
            Object.values(colorItems).filter(item =>
              item.classList.contains('ql-picker-item')
            )

          if (colorItem && colorItem[0]) {
            colorItem[0].appendChild(picker)
          }
        }
        picker.click()
      } else {
        quill.format('color', value)
      }
    }
  }
}

export { sizeList, toolbar }
