import React, { Component } from 'react'
import hark from 'hark'
import _ from 'lodash'
import axios from 'axios'
import { Prompt } from 'react-router-dom'
import { WebRTCAdaptor } from './webrtcAdaptor_new'
import { Modal, Row, Tooltip, message } from 'antd'
import '../../../../../components/Card/Card.scss'
import { NetworkDetection } from '../NetworkDetection'
import cameraOff from '../../assets/camera_off.svg'
import cameraOn from '../../assets/camera_on.svg'
import micOff from '../../assets/mic_off.svg'
import micOn from '../../assets/mic_on.svg'
import mute from '../../assets/mute.svg'
import unmute from '../../assets/unmute.svg'
import join from '../../assets/join.svg'
import leave from '../../assets/leave.svg'
import fullScreen from '../../assets/full_screen.svg'
import shareScreenLogo from '../../assets/share_screen.svg'
import stopShareScreenLogo from '../../assets/stop_screen_share.svg'
import { api } from '../../../../../services'
import { Card } from '../../../../../components/Card/styled'
import { config } from '../../../../../constants'

message.config({
  top: 100,
  duration: 5,
  maxCount: 1
})

const pcConfig = {
  iceServers: [
    {
      urls: 'stun:stun.l.google.com:19302'
    }
  ]
}

let sdpConstraints = {
  OfferToReceiveAudio: false,
  OfferToReceiveVideo: false
}
// 240p means width: 426, height: 240
let mediaConstraints = {
  video: {
    width: 426,
    height: 245,
    frameRate: 15
  },
  audio: true
}

let webRTCAdaptor = null
let closeConnectionMsg = ''
// let streamIdInUseMsg = ''
let prompt = null
let volumeButton = null
let speech = null
let video = null
const token = 'null'
class OneTwoOne extends Component {
  // constructor(props) {
  //   super(props)
  // }

  initSocketConf = null
  SOCKET_URL_PUBLISH = `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com/WebRTCAppEE/websocket?rtmpForward=undefined`
  connectionInterval = null
  state = {
    showVideo: false,
    myStreamId: this.props.userId + '_' + this.props.eventId,
    streamsList: [],
    speaker: '',
    isJoined: false,
    isCameraOff: false,
    isMicOff: false,
    isShareScreen: false,
    disableScreenSharing: '',
    hasLeave: false,
    canShowModal: false,
    showPrompt: false,
    isPublishStarted: false,
    hideElement: false
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      speaker,
      myStreamId,
      streamsList,
      isJoined,
      isShareScreen
    } = this.state
    const {
      streamCount,
      isConferenceHost,
      isDisconnected,
      isJoined: isStreamJoined
    } = this.props
    if (prevProps.isJoined !== isStreamJoined) {
      if (isStreamJoined) {
        this.reConnectSocket()
      }
    }

    if (prevState.speaker !== speaker && speaker) {
      if (speaker === myStreamId) {
        console.log('speaker === myStreamId if')
        let localVideo = document.getElementById('localVideo')
        localVideo.className = 'active'
        let remoteVideo = document.getElementById(
          'remoteVideo' + prevState.speaker
        )
        let remoteController = document.getElementById(
          'controller' + prevState.speaker
        )
        let remotePlayer = document.getElementById('player' + prevState.speaker)
        let remoteUser = document.getElementById('username' + prevState.speaker)

        if (remoteVideo) {
          remoteVideo.className = 'myClass'
          remotePlayer.classList.add('remotePlayerClass')
          remoteController.classList.remove('control_active')
          remoteController.classList.add('volume-controls')
          remoteUser.classList.remove('username__active')
          remoteUser.classList.add('username__inactive')
        }
      } else {
        console.log('speaker === myStreamId else streamsList:', streamsList)
        let localVideo = document.getElementById('localVideo')
        if (localVideo) {
          localVideo.className = ''
        }
        if (streamsList.length > 0) {
          streamsList.forEach(item => {
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)

            if (remoteVideo) {
              if (item === speaker) {
                remoteVideo.className = 'active'
                remoteVideo.classList.remove('myClass')
                remotePlayer.classList.remove('remotePlayerClass')
                remoteController.classList.remove('volume-controls')
                remoteController.classList.add('control_active')
                remoteUser.classList.add('username__active')
                remoteUser.classList.remove('username__inactive')
              } else {
                remoteVideo.className = 'myClass'
                remotePlayer.classList.add('remotePlayerClass')
                remoteController.classList.add('volume-controls')
                remoteController.classList.remove('control_active')
                remoteUser.classList.remove('username__active')
                remoteUser.classList.add('username__inactive')
              }
            }
          })
        }
      }
    }

    if (
      (prevState.streamsList !== streamsList ||
        prevState.speaker !== speaker) &&
      isJoined &&
      !isConferenceHost
    ) {
      if (streamsList.length > 0) {
        streamsList.forEach(item => {
          if (speaker === '') {
            this.setState(
              {
                speaker: streamsList[0]
              },
              () => {
                let remoteVideo = document.getElementById(
                  'remoteVideo' + streamsList[0]
                )
                let remoteController = document.getElementById(
                  'controller' + streamsList[0]
                )
                let remotePlayer = document.getElementById(
                  'player' + streamsList[0]
                )
                let remoteUser = document.getElementById(
                  'username' + streamsList[0]
                )
                if (remoteVideo) {
                  remoteVideo.className = 'active'
                  remoteVideo.classList.remove('myClass')
                  remotePlayer.classList.remove('remotePlayerClass')
                  remoteController.classList.remove('volume-controls')
                  remoteController.classList.add('control_active')
                  remoteUser.classList.add('username__active')
                  remoteUser.classList.remove('username__inactive')
                }
              }
            )
          }
          if (item === speaker) {
            console.log('item === speaker if')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remoteController = document.getElementById('controller' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              console.log('item === speaker if remoteVideo', remoteVideo)
              remoteVideo.className = 'active'
              remoteVideo.classList.remove('myClass')
              remotePlayer.classList.remove('remotePlayerClass')
              remoteController.classList.remove('volume-controls')
              remoteController.classList.add('control_active')
              remoteUser.classList.add('username__active')
              remoteUser.classList.remove('username__inactive')
            }
          } else {
            console.log('item === speaker else')
            let remoteVideo = document.getElementById('remoteVideo' + item)
            let remotePlayer = document.getElementById('player' + item)
            let remoteController = document.getElementById('controller' + item)
            let remoteUser = document.getElementById('username' + item)
            if (remoteVideo) {
              remoteVideo.className = 'myClass'
              remotePlayer.classList.add('remotePlayerClass')
              remoteController.classList.add('volume-controls')
              remoteController.classList.remove('control_active')
              remoteUser.classList.remove('username__active')
              remoteUser.classList.add('username__inactive')
            }
          }
        })
      }
    }

    if (prevState.streamsList !== streamsList && isJoined) {
      isConferenceHost
        ? streamCount(streamsList.length === 0 ? 1 : streamsList.length + 1)
        : streamCount(streamsList.length === 0 ? 1 : streamsList.length)

      if (
        isConferenceHost &&
        isShareScreen &&
        webRTCAdaptor &&
        !isDisconnected
      ) {
        webRTCAdaptor.sendData(myStreamId, 'SCREEN')
      }
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount log')
    const { showVideo } = this.state

    if (this.connectionInterval !== null) {
      clearInterval(this.connectionInterval)
      this.connectionInterval = null
    }

    if (prompt && showVideo) {
      prompt = null
    }
    this.initSocketConf && this.initSocketConf.close()
  }

  turnOffLocalCamera = () => {
    this.setState({ isCameraOff: true }, () => {
      webRTCAdaptor.turnOffLocalCamera()
    })
  }

  turnOnLocalCamera = () => {
    this.setState({ isCameraOff: false }, () => {
      webRTCAdaptor.turnOnLocalCamera()
    })
  }

  muteLocalMic = () => {
    this.setState({ isMicOff: true }, () => {
      const { myStreamId } = this.state
      webRTCAdaptor.muteLocalMic()
      webRTCAdaptor.sendData(myStreamId, 'MUTED')
    })
  }

  unmuteLocalMic = () => {
    this.setState({ isMicOff: false }, () => {
      const { myStreamId } = this.state
      webRTCAdaptor.unmuteLocalMic()
      webRTCAdaptor.sendData(myStreamId, 'UNMUTED')
    })
  }

  fullScreenMode = () => {
    const { speaker } = this.state
    let elem = document.getElementById('remoteVideo' + speaker)
    if (elem && elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem && elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen()
    } else if (elem && elem.webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen()
    } else if (elem && elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen()
    }
  }

  screenShare = () => {
    const { isShareScreen, myStreamId } = this.state
    if (!isShareScreen) {
      this.setState(
        {
          isShareScreen: true,
          speaker: myStreamId
        },
        () => {
          webRTCAdaptor.switchDesktopCapture(myStreamId)
          if (webRTCAdaptor) {
            webRTCAdaptor.sendData(myStreamId, 'SCREEN')
          }
        }
      )
    } else {
      this.setState(
        {
          isShareScreen: false
        },
        () => {
          webRTCAdaptor.switchVideoCameraCapture(myStreamId)
          if (webRTCAdaptor) {
            webRTCAdaptor.sendData(myStreamId, 'CAMERA')
          }
        }
      )
    }
  }

  publish = (streamName, token) => {
    webRTCAdaptor.publish(streamName, token)
  }

  playVideo = obj => {
    const { conferenceId } = this.props
    let video = document.getElementById('remoteVideo' + obj.streamId)
    console.log(
      'new stream available with id: ' +
        obj.streamId +
        'on the room:' +
        conferenceId,
      'video',
      video
    )
    if (video === null) {
      console.log('playVideo video null if')
      this.createRemoteVideo(obj)
    } else {
      console.log('playVideo video null else')
      video.srcObject = obj.stream
    }
  }

  createRemoteVideo = obj => {
    let player = document.createElement('div')
    player.className = 'remotePlayerClass'
    player.id = 'player' + obj.streamId
    player.innerHTML =
      '<video id="remoteVideo' +
      obj.streamId +
      '" class="myClass" autoplay></video>' +
      '<div class="volume-controls" id="controller' +
      obj.streamId +
      '">' +
      '<button class= "volume_button" id="button' +
      obj.streamId +
      '" >' +
      '<img src=' +
      unmute +
      ' alt="micOn" id="icon' +
      obj.streamId +
      '" />' +
      '</button>' +
      '<span id="username' +
      obj.streamId +
      '" class="username__inactive"></span>' +
      '</div>'

    if (player) {
      console.log('createRemoteVideo if', player)
      let playerElement = document.getElementById('players')
      if (playerElement) {
        console.log('playerElement if', playerElement)
        playerElement.appendChild(player)
        video = document.getElementById('remoteVideo' + obj.streamId)
        volumeButton = document.getElementById('button' + obj.streamId)
        volumeButton.addEventListener('click', e =>
          this.toggleMute(e, obj.streamId)
        )
        if (video) {
          console.log('createRemoteVideo if video', video)
          video.srcObject = obj.stream
        }
      }
    }
  }

  removeRemoteVideo = streamId => {
    let video = document.getElementById('remoteVideo' + streamId)
    if (video !== null) {
      let player = document.getElementById('player' + streamId)
      video.srcObject = null
      document.getElementById('players').removeChild(player)
    }
  }

  toggleMute = (e, streamId) => {
    e.preventDefault()
    const { isConfCreator } = this.props
    if (isConfCreator) {
      let targetVideo = document.getElementById('remoteVideo' + streamId)
      let targetIcon = document.getElementById('icon' + streamId)
      if (!targetVideo.muted) {
        targetIcon.src = mute
        targetVideo.muted = true
        webRTCAdaptor.sendData(streamId, 'MUTED')
      }
    }
  }

  deleteMyStream = param => {
    const { myStreamId } = this.state
    const { conferenceId, eventId } = this.props
    console.log('param from', param)
    axios({
      method: 'put',
      url: `https://antmedia-${eventId}.v2.sharelookapp.com/WebRTCAppEE/rest/v2/broadcasts/conference-rooms/${conferenceId}/delete?streamId=${myStreamId}`,
      data: {}
    }).then(
      response => {
        console.log('response from 1st api', response)
        if (response.status === 200) {
          console.log('response from 1st api status 200')
          axios({
            method: 'delete',
            url: `https://antmedia-${eventId}.v2.sharelookapp.com/WebRTCAppEE/rest/v2/broadcasts/${myStreamId}`,
            data: {}
          }).then(
            response => {
              console.log('inner response from 2nd api', response)
              if (response.status === 200) {
                console.log('inner response from 2nd api status 200')
                if (param === 'streamIdInUse') {
                  webRTCAdaptor.joinRoom(conferenceId, myStreamId)
                } else {
                  this.reConnectSocket()
                }
              }
            },
            error => {
              console.log('error delete stream inner', error)
            }
          )
        }
      },
      error => {
        console.log('error delete stream outer', error)
      }
    )
  }

  reConnectSocket = () => {
    const {
      isConferenceHost,
      userId,
      eventId,
      isSocketClose,
      initSocket
    } = this.props
    console.log('reconnecting isSocketClose', isSocketClose)
    console.log('reConnectSocket webRTCAdaptor', webRTCAdaptor)
    if (isSocketClose) {
      initSocket(userId)
    }
    this.setState(
      {
        myStreamId: userId + '_' + eventId,
        streamsList: [],
        showVideo: true,
        isJoined: true,
        hasLeave: false,
        isShareScreen: false,
        isCameraOff: false,
        isMicOff: false,
        disableScreenSharing: '',
        isPublishStarted: false,
        showPrompt: true
      },
      () => {
        const { handleBroadcastChange } = this.props
        const { hideElement, myStreamId } = this.state
        if (!hideElement) {
          handleBroadcastChange()
        }
        this.setState(
          {
            speaker: isConferenceHost ? myStreamId : ''
          },
          () => {
            console.log('reConnectSocket isConferenceHost', isConferenceHost)
            if (isConferenceHost) {
              console.log('if reConnectSocket')
              this.initService()
            }
          }
        )
      }
    )
  }

  handleStartApi = () => {
    const { eventId } = this.props
    api.events
      .startLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in starting event', err)
      })
  }

  initializeSocket = () => {
    const { eventId, stopstream, userId, orgId, onStreamStopped } = this.props
    this.initSocketConf = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )
    this.initSocketConf.onopen = () => {
      this.initSocketConf.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: orgId
        })
      )
      this.connectionInterval = setInterval(() => {
        this.initSocketConf.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 10000)
    }

    this.initSocketConf.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log('event log endConference', event)
      console.log('data log endConference', data)
      if (data.event === 'endConference') {
        console.log('inside endConference', data.event)
        const {
          isConferenceHost,
          handleBroadcastChange,
          streamCount,
          fineConnectionState
        } = this.props
        const { myStreamId } = this.state
        if (webRTCAdaptor) {
          webRTCAdaptor.stop(myStreamId)
          webRTCAdaptor.closePeerConnection(myStreamId)
          webRTCAdaptor.closeWebSocket()
          webRTCAdaptor.closeStream()
        }
        this.setState(
          {
            showVideo: false,
            isJoined: false,
            myStreamId: '',
            showPrompt: false,
            speaker: '',
            streamsList: []
          },
          () => {
            if (isConferenceHost) {
              console.log('if endConference', isConferenceHost)
              fineConnectionState()
              stopstream()
              onStreamStopped()
              /* const { hasLeave } = this.state
              if (!hasLeave) {
                handleBroadcastChange()
                streamCount('')
              } */
              prompt = null
            }
            if (!isConferenceHost) {
              console.log('else if endConference', isConferenceHost)
              const { hasLeave } = this.state
              stopstream()
              onStreamStopped()
              if (!hasLeave) {
                handleBroadcastChange()
                streamCount('')
              }
            }
            this.setState({ hasLeave: true })
            if (this.connectionInterval !== null) {
              clearInterval(this.connectionInterval)
              this.connectionInterval = null
            }
          }
        )
      }
    }

    this.initSocketConf.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(this.connectionInterval)
    }

    this.initSocketConf.onerror = error => {
      console.log('Error ' + error.message)
      clearInterval(this.connectionInterval)
    }
  }

  stopConference = () => {
    console.log('stopConference')
    const { eventId, conferenceId, orgId, userId } = this.props
    this.initSocketConf.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: orgId
      })
    )

    this.initSocketConf.send(
      JSON.stringify({
        action: 'endConference',
        stream_id: conferenceId,
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }

  handleStopApi = () => {
    const { eventId } = this.props
    api.events
      .stopLiveEvent(eventId)
      .then(res => {
        if (res.status === 200) {
          console.log('response on starting event', res.data)
        }
      })
      .catch(err => {
        console.log('error in stopping event', err)
      })
  }

  leave = () => {
    // streamIdInUseMsg = ''
    this.setState(
      {
        canShowModal: false,
        hasLeave: true,
        hideElement: false,
        isPublishStarted: false
      },
      () => {
        const { conferenceId, isConfCreator } = this.props
        const { streamsList } = this.state
        if (isConfCreator && _.isEmpty(streamsList)) {
          console.log('handleStopApi')
          this.handleStopApi()
        }
        if (webRTCAdaptor) {
          webRTCAdaptor.leaveFromRoom(conferenceId)
        }
      }
    )
  }

  toggleModal = () => {
    this.setState(state => ({ canShowModal: !state.canShowModal }))
  }

  initService = () => {
    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.SOCKET_URL_PUBLISH,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      isPlayMode: false,
      debug: true,
      bandwidth: 200,
      callback: (info, obj) => {
        console.log('publish command', info, obj)
        const {
          conferenceId,
          handleBroadcastChange,
          isConferenceHost,
          streamCount,
          isConfCreator
        } = this.props
        const {
          myStreamId,
          streamsList,
          isJoined,
          disableScreenSharing,
          isShareScreen
        } = this.state
        if (info === 'initialized') {
          console.log('publish initialized', obj)
          console.log('publish initialized conferenceId', conferenceId)
          webRTCAdaptor.joinRoom(conferenceId, myStreamId)
          this.initializeSocket()
        } else if (info === 'start') {
          console.log('publish start ', obj)
        } else if (info === 'joinedTheRoom') {
          const { calculateStopTime, calculateWarnTime } = this.props
          closeConnectionMsg = ''
          const room = obj.ATTR_ROOM_NAME
          console.log('publish joinedTheRoom', obj)
          webRTCAdaptor.getRoomInfo(room, obj.streamId)
          console.log('publish joinedTheRoom', obj)
          this.publish(myStreamId, token)
          calculateStopTime()
          calculateWarnTime()
          this.handleStartApi()
        } else if (info === 'newStreamAvailable') {
          console.log('publish newStreamAvailable')
          this.playVideo(obj)
          if (
            _.isEmpty(disableScreenSharing) &&
            isShareScreen &&
            webRTCAdaptor
          ) {
            console.log('newStreamAvailable myStreamId', myStreamId)
            webRTCAdaptor.sendData(myStreamId, 'SCREEN')
          }
        } else if (info === 'publish_started') {
          console.log('publish publish_started', obj, !!webRTCAdaptor)
          const { fineConnectionState } = this.props
          fineConnectionState()
          // streamIdInUseMsg = ''
          this.setState(
            {
              isPublishStarted: true,
              hideElement: false
            },
            () => {
              // webRTCAdaptor.enableStats(myStreamId)
              if (webRTCAdaptor) {
                speech = hark(webRTCAdaptor.localStream, {})
              }
              if (isJoined) {
                speech.on('speaking', () => {
                  const { isShareScreen, disableScreenSharing } = this.state
                  const { isDisconnected } = this.props
                  if (!isDisconnected) {
                    const { myStreamId } = this.state
                    const { name } = this.props
                    if (webRTCAdaptor) {
                      webRTCAdaptor.sendData(myStreamId, `USERNAME_${name}`)
                    }
                    if (disableScreenSharing !== '') {
                      this.setState({ speaker: disableScreenSharing })
                    } else if (isShareScreen) {
                      this.setState({ speaker: myStreamId })
                    } else {
                      this.setState({ speaker: myStreamId }, () => {
                        if (webRTCAdaptor) {
                          webRTCAdaptor.sendData(myStreamId, 'SPEAKING')
                        }
                      })
                    }
                  }
                })
              }
            }
          )
        } else if (info === 'publish_finished') {
          const { hasLeave, myStreamId } = this.state
          console.log('publish finished', 'myStreamId', myStreamId)
          if (!hasLeave) {
            console.log('publish finished if')
            this.props.badConnectionState()
            // webRTCAdaptor.joinRoom(conferenceId, myStreamId)
            this.publish(myStreamId, token)
          }
        } else if (info === 'leavedFromRoom') {
          prompt = false
          console.debug('publish leavedFromRoom' + obj)
          // this.initSocketConf && this.initSocketConf.close()
          if (this.connectionInterval !== null) {
            console.log(
              'interval clear connectionInterval leavedFromRoom',
              this.connectionInterval
            )
            clearInterval(this.connectionInterval)
            this.connectionInterval = null
          }
          this.setState(
            {
              showVideo: false,
              isJoined: false,
              speaker: '',
              myStreamId: '',
              showPrompt: false
            },
            () => {
              const { hasLeave } = this.state
              if (isConferenceHost) {
                if (isConfCreator && _.isEmpty(streamsList)) {
                  this.stopConference()
                }
                if (speech) {
                  speech.stop()
                }
              }
              if (hasLeave) {
                handleBroadcastChange()
                streamCount('')
              }
              if (webRTCAdaptor) {
                webRTCAdaptor.stop(myStreamId)
                webRTCAdaptor.closePeerConnection(myStreamId)
                webRTCAdaptor.closeWebSocket()
                webRTCAdaptor.closeStream()
              }
              this.setState({ streamsList: [] })
            }
          )
        } else if (info === 'play_finished') {
          const { hasLeave, disableScreenSharing, speaker } = this.state
          console.log('publish play_finished', obj, 'hasLeave', hasLeave)
          this.removeRemoteVideo(obj.streamId)
          if (!hasLeave) {
            if (isConferenceHost) {
              if (disableScreenSharing === speaker) {
                if (disableScreenSharing === obj.streamId) {
                  this.setState({
                    disableScreenSharing: '',
                    isShareScreen: false,
                    speaker: myStreamId
                  })
                } else {
                  this.setState({
                    speaker: disableScreenSharing
                  })
                }
              } else {
                this.setState({ speaker: myStreamId })
              }
            } else {
              this.setState({
                disableScreenSharing: '',
                isShareScreen: false,
                speaker: ''
              })
            }
          }
        } else if (info === 'roomInformation') {
          console.log('publish roomInformation', obj)
          // const streamDifference = _.difference(streamsList, obj.streams)
          // console.log('streamDifference', streamDifference)
          this.setState(
            {
              streamsList: obj.streams
            },
            () => {
              const { streamsList } = this.state
              if (obj.streams.length > 0) {
                obj.streams.forEach(item => {
                  console.log('Stream playing with ID: ' + item)
                  const { conferenceId } = this.props
                  webRTCAdaptor.play(item, token, conferenceId)
                })
              }

              if (!isConferenceHost && streamsList.length === 0) {
                this.setState({ speaker: '' })
                this.leave()
              }
            }
          )
          console.log('after streamsList', streamsList)
        } else if (info === 'closed') {
          console.log('publish Connection closed')
          console.log('publish Connection webRTCAdaptor', webRTCAdaptor)
          const { hasLeave, streamsList } = this.state
          const { isDisconnected } = this.props

          if (typeof obj !== 'undefined') {
            console.log('publish Connection closed: ' + JSON.stringify(obj))
            if (streamsList.length > 0) {
              streamsList.forEach(item => {
                console.log('publish closed streamDifference remove ', item)
                this.removeRemoteVideo(item)
              })
            }

            if (speech) {
              speech.stop()
            }
            if (!hasLeave) {
              if (webRTCAdaptor) {
                webRTCAdaptor.closeStream()
              }
              closeConnectionMsg =
                'Server closed unexpectedly. Please rejoin conference again.'
              if (isDisconnected) {
                this.initSocketConf && this.initSocketConf.close()
                if (this.connectionInterval !== null) {
                  console.log(
                    'interval clear connectionInterval closed',
                    this.connectionInterval
                  )
                  clearInterval(this.connectionInterval)
                  this.connectionInterval = null
                }
                this.setState(
                  {
                    streamsList: [],
                    showVideo: false,
                    isJoined: false,
                    speaker: '',
                    myStreamId: '',
                    showPrompt: false,
                    callFirstTime: true
                  },
                  () => {
                    handleBroadcastChange()
                    streamCount('')
                    setTimeout(() => {
                      closeConnectionMsg = ''
                    }, 2000)
                  }
                )
              } else {
                closeConnectionMsg =
                  'Server closed unexpectedly. Reconnecting again.'
                this.setState(
                  {
                    hideElement: true
                  },
                  () => {
                    this.reConnectSocket()
                  }
                )
              }
            }
          }
        } else if (info === 'takeConfiguration') {
          console.log('publish takeConfiguration')
        } else if (info === 'server_will_stop') {
          console.log('publish server_will_stop')
        } else if (info === 'data_channel_opened') {
          console.log('publish Data Channel open for stream id', obj)
        } else if (info === 'data_channel_closed') {
          console.log('publish Data Channel closed', obj)
        } else if (info === 'updated_stats') {
          // console.log('updated_stats audioLevel', obj.audioLevel)
          // console.log('updated_stats streamId', obj.streamId)
        } else if (info === 'ice_connection_state_changed') {
          console.log('iceConnectionState Changed: ', JSON.stringify(obj))
          const iceState = webRTCAdaptor.signallingState(myStreamId)

          console.log('I am in checkAndRepublishIfRequired')
          console.log('I am in checkAndRepublishIfRequired iceState', iceState)
          if (
            (obj.state === null ||
              obj.state === 'failed' ||
              obj.state === 'disconnected' ||
              iceState === null ||
              iceState === 'failed' ||
              iceState === 'disconnected') &&
            isConferenceHost
          ) {
            const { myStreamId } = this.state
            this.setState(
              {
                speaker: myStreamId
              },
              () => {
                console.log(
                  'obj.state ice connection state ',
                  obj.state,
                  'ice myStreamId',
                  myStreamId
                )
                console.log('if checkAndRepublishIfRequired')
                // webRTCAdaptor.joinRoom(conferenceId, myStreamId)
                this.publish(myStreamId, token)
              }
            )
          }
        } else if (info === 'screen_share_stopped') {
          console.log('publish screen_share_stopped', obj)
          this.setState(
            {
              disableScreenSharing: '',
              isShareScreen: false
            },
            () => {
              if (webRTCAdaptor) {
                webRTCAdaptor.sendData(myStreamId, 'CAMERA')
              }
            }
          )
        } else if (info === 'data_received') {
          if (obj.event.data === 'MUTED') {
            if (obj.streamId === myStreamId) {
              this.setState({ isMicOff: true }, () => {
                webRTCAdaptor.muteLocalMic()
              })
            }
          }

          if (obj.event.data === 'UNMUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = false
                targetIcon.src = unmute
              }
            }
          }

          const eventData = obj.event.data.split('_')
          if (obj.event.data === 'SPEAKING') {
            if (!disableScreenSharing && !isShareScreen) {
              this.setState({ speaker: obj.streamId })
            }
          }

          if (eventData[0] === 'USERNAME') {
            if (obj.streamId !== myStreamId) {
              let targetUser = document.getElementById(
                'username' + obj.streamId
              )
              if (targetUser) {
                targetUser.innerText = eventData[1]
              }
            }
          }

          if (obj.event.data === 'SCREEN') {
            if (!isShareScreen) {
              this.setState({
                disableScreenSharing: obj.streamId,
                speaker: obj.streamId
              })
            }
          }

          if (obj.event.data === 'CAMERA') {
            this.setState({ disableScreenSharing: '', isShareScreen: false })
          }

          if (obj.event.data === 'MUTED') {
            if (obj.streamId !== myStreamId) {
              let targetVideo = document.getElementById(
                'remoteVideo' + obj.streamId
              )
              let targetIcon = document.getElementById('icon' + obj.streamId)
              if (targetVideo) {
                targetVideo.muted = true
                targetIcon.src = mute
              }
            } else {
              this.setState({ isMicOff: true }, () => {
                webRTCAdaptor.muteLocalMic()
              })
            }
          }
        }
      },
      callbackError: (error, message) => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        const { myStreamId } = this.state
        const { t } = this.props
        console.log('publish log error ', error.isTrusted)
        console.log('publish log message ', message)
        console.log('publish error callback: ' + JSON.stringify(error), message)
        let errorMessage = JSON.stringify(error)
        if (error.isTrusted) {
          console.log('inside  error.isTrusted publish', error.isTrusted)
          return
        }
        if (typeof message !== 'undefined') {
          errorMessage = message
        }
        if (error.indexOf('NotFoundError') !== -1) {
          errorMessage =
            'Camera or Mic are not found or not allowed in your device.'
        } else if (
          error.indexOf('NotReadableError') !== -1 ||
          error.indexOf('TrackStartError') !== -1
        ) {
          errorMessage =
            'Camera or Mic is being used by some other process that does not not allow these devices to be read.'
        } else if (
          error.indexOf('OverconstrainedError') !== -1 ||
          error.indexOf('ConstraintNotSatisfiedError') !== -1
        ) {
          errorMessage =
            'There is no device found that fits your video and audio constraints. You may change video and audio constraints.'
        } else if (
          error.indexOf('NotAllowedError') !== -1 ||
          error.indexOf('PermissionDeniedError') !== -1
        ) {
          errorMessage = 'You are not allowed to access camera and mic.'
        } else if (error.indexOf('TypeError') !== -1) {
          errorMessage = 'Video/Audio is required.'
        } else if (error.indexOf('publishTimeoutError') !== -1) {
          const { myStreamId } = this.state
          console.log('publishTimeOutError Inside', myStreamId)
          errorMessage = 'publish timeout error'
          this.publish(myStreamId, token)
        } else if (error.indexOf('not_initialized_yet') !== -1) {
          const { myStreamId } = this.state
          console.log('not_initialized_yet Inside', myStreamId)
          errorMessage = 'publish not_initialized_yet'
          if (webRTCAdaptor) {
            webRTCAdaptor.stop(myStreamId)
            webRTCAdaptor.closePeerConnection(myStreamId)
            webRTCAdaptor.closeWebSocket()
          }
          this.reConnectSocket()
        } else if (error.indexOf('streamIdInUse') !== -1) {
          errorMessage = 'publish streamIdInUse'
          console.log('publish streamIdInUse')
          this.deleteMyStream('streamIdInUse')
          // streamIdInUseMsg = 'publish streamIdInUse'
        } else if (error.indexOf('UnsecureContext') !== -1) {
          errorMessage =
            'Fatal Error: Browser cannot access camera and mic because of unsecure context. Please install SSL and access via https'
        } else if (error.indexOf('ScreenSharePermissionDenied') !== -1) {
          errorMessage = t('events:screen_share_denied')
          this.setState(
            {
              disableScreenSharing: '',
              isShareScreen: false
            },
            () => {
              if (webRTCAdaptor) {
                webRTCAdaptor.sendData(myStreamId, 'CAMERA')
              }
            }
          )
        } else if (error.indexOf('WebSocketNotSupported') !== -1) {
          errorMessage = 'Fatal Error: WebSocket not supported in this browser'
        } else if (error.indexOf('already_playing') !== -1) {
          errorMessage = 'Stream already playing'
        } else if (error.indexOf('WebSocketNotConnected') !== -1) {
          console.log('publish WebSocketNotConnected')
          errorMessage = 'publish WebSocketNotConnected'
        } else if (error.indexOf('no_stream_exist') !== -1) {
          console.log('publish No stream exist')
          errorMessage = 'publish No stream exist'
          this.removeRemoteVideo(error.streamId)
          // TODO: removeRemoteVideo(error.streamId);
        } else if (error.indexOf('data_channel_error') !== -1) {
          errorMessage =
            'publish There was a error during data channel communication'
        } else {
          errorMessage = 'Unknown error'
        }
        console.log('errorMessage: ', errorMessage)
      }
    })
  }

  render() {
    const {
      showVideo,
      isJoined,
      isCameraOff,
      isMicOff,
      isShareScreen,
      disableScreenSharing,
      showPrompt,
      canShowModal,
      streamsList,
      isPublishStarted,
      myStreamId,
      callFirstTime,
      speaker
    } = this.state
    const {
      isStreamStopped,
      isConferenceHost,
      t,
      allowJoin,
      conferenceId,
      isConfCreator
    } = this.props

    console.log(
      'conference render logs new',
      'isStreamStopped',
      isStreamStopped,
      'allowJoin',
      allowJoin,
      'isJoined',
      isJoined,
      'myStreamId',
      myStreamId,
      'connectionInterval',
      this.connectionInterval,
      'streamsList',
      streamsList,
      'callFirstTime',
      callFirstTime,
      'speaker',
      speaker,
      'initSocketConf',
      this.initSocketConf,
      'isStreamStopped',
      this.props.isStreamStopped,
      'isConferenceHost',
      isConferenceHost,
      'isConfCreator',
      isConfCreator
    )
    return (
      <div>
        {/*{streamIdInUseMsg && message.error(streamIdInUseMsg)}*/}
        {closeConnectionMsg && message.error(closeConnectionMsg)}
        {showVideo && (
          <>
            <Prompt
              when={showPrompt}
              message={() => {
                prompt = window.confirm(t('events:prompt_message'))
                if (prompt) {
                  if (webRTCAdaptor) {
                    webRTCAdaptor.closeStream()
                    webRTCAdaptor.leaveFromRoom(conferenceId)
                  }
                  return prompt
                } else {
                  return false
                }
              }}
            />
            <Row>
              <div className="conference">
                <div className="streams">
                  <div className="scrollbar" id="players">
                    {isConferenceHost ? (
                      <div id="players">
                        <div className="local__myClass">
                          <video
                            id="localVideo"
                            className="active"
                            autoPlay
                            muted
                            controls={disableScreenSharing}
                          />
                        </div>
                        {!isPublishStarted && (
                          <Card.OverlayPlayer>
                            {t('labels:processing')}
                          </Card.OverlayPlayer>
                        )}
                      </div>
                    ) : (
                      <>
                        {streamsList.length === 0 && (
                          <div id="players">
                            <video
                              id="remoteVideo"
                              className="active"
                              autoPlay
                              muted
                              playsInline
                            />
                            <Card.OverlayPlayer>
                              <span>{t('general:loading')}</span>
                            </Card.OverlayPlayer>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
        {allowJoin && !isStreamStopped && (
          <Row className="controller">
            {!isJoined && (
              <Tooltip placement="top" title={t('v4:join')}>
                <div
                  role="button"
                  className="controller-item-join"
                  onClick={() => this.reConnectSocket()}
                >
                  <img src={join} alt="join" />
                </div>
              </Tooltip>
            )}
            <div className={`${isJoined ? 'controller-items' : ''}`}>
              {isJoined &&
                isConferenceHost &&
                (isShareScreen ? (
                  <Tooltip
                    placement="top"
                    title={t('labels:stop_share_screen')}
                  >
                    <div
                      role="button"
                      className={`controller-item ${
                        disableScreenSharing !== '' || !isPublishStarted
                          ? 'disable-item'
                          : ''
                      }`}
                      onClick={() => this.screenShare()}
                    >
                      <img src={stopShareScreenLogo} alt="stop-share-screen" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('labels:share_screen')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        disableScreenSharing !== '' || !isPublishStarted
                          ? 'disable-item'
                          : ''
                      }`}
                      onClick={() => this.screenShare()}
                    >
                      <img src={shareScreenLogo} alt="share-screen" />
                    </div>
                  </Tooltip>
                ))}
              {isJoined && disableScreenSharing !== '' && (
                <Tooltip placement="top" title={t('v2:full_screen')}>
                  <div
                    role="button"
                    className="controller-item"
                    onClick={() => this.fullScreenMode()}
                  >
                    <img src={fullScreen} alt="full-screen" />
                  </div>
                </Tooltip>
              )}
              {isJoined &&
                isConferenceHost &&
                (isCameraOff ? (
                  <Tooltip placement="top" title={t('v4:camera_on')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen || !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOnLocalCamera()}
                    >
                      <img src={cameraOff} alt="camera-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:camera_off')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        isShareScreen || !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.turnOffLocalCamera()}
                    >
                      <img src={cameraOn} alt="camera-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined &&
                isConferenceHost &&
                (isMicOff ? (
                  <Tooltip placement="top" title={t('v4:mic_on')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.unmuteLocalMic()}
                    >
                      <img src={micOff} alt="mic-on" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top" title={t('v4:mic_off')}>
                    <div
                      role="button"
                      className={`controller-item ${
                        !isPublishStarted ? 'disable-item' : ''
                      }`}
                      onClick={() => this.muteLocalMic()}
                    >
                      <img src={micOn} alt="mic-off" />
                    </div>
                  </Tooltip>
                ))}

              {isJoined && (
                <Tooltip placement="top" title={t('v4:leave')}>
                  <div
                    role="button"
                    className="controller-item"
                    onClick={() => this.toggleModal()}
                  >
                    <img src={leave} alt="leave" />
                  </div>
                </Tooltip>
              )}
            </div>
            {canShowModal && (
              <Modal
                title={t('labels:alert')}
                visible={canShowModal}
                onOk={() => this.leave()}
                onCancel={this.toggleModal}
              >
                <h3>{t('events:event_modal_warn')}</h3>
              </Modal>
            )}
          </Row>
        )}
      </div>
    )
  }
}

export default NetworkDetection(OneTwoOne)
