import React from 'react'
import { Col, Icon, Form, Input, TreeSelect } from 'antd'
import './index.css'
import { api } from '../../../../../../services'
import TrainerSection from '../../../../../../components/TrainerSection'
import PriceManagement from './PriceManagement'

const { TreeNode } = TreeSelect

class MarketPlaceMidSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCourseFree: false,
      orgs: [],
      groups: [],
      categories: [],
      isLoading: false,
      value: '',
      isValueLoading: false
    }
  }

  componentDidMount() {
    // this.fetchOrganization()
    this.fetchCategories()
    this.handleInitialSwitch()
    // this.handleGroupSelect()
  }

  // fetchOrganization = () => {
  //   api.organizations.get().then(res => {
  //     this.setState({
  //       orgs: res.data.rows.map(item => ({
  //         title: item.title,
  //         orgId: item.id,
  //         groups: item.groups
  //       }))
  //     })
  //     this.fetchGroups()
  //   })
  // }

  // fetchGroups = () => {
  //   const {
  //     courseBuilder: { courseOrganization, courseGroup }
  //   } = this.props

  //   if (courseOrganization) {
  //     this.setState({
  //       groups: this.state.orgs
  //         .filter(item => courseOrganization === item.orgId)
  //         .map(item => item.groups)[0]
  //         .map(item => ({
  //           id: item.id,
  //           value: item.title,
  //           label: item.title
  //         }))
  //     })
  //   }

  //   if (courseGroup) {
  //     this.setState({
  //       value: this.state.groups.filter(group => group.id === courseGroup)[0]
  //     })
  //   }

  //   if (this.state.selectedGroup) {
  //     this.setState({
  //       value: this.state.groups.filter(
  //         group => group.id === this.state.selectedGroup
  //       )[0],
  //       isValueLoading: false
  //     })
  //   }
  // }

  fetchCategories = () => {
    api.courses
      .fetchCategory()
      .then(res =>
        this.setState({
          categories: res.data
        })
      )
      .catch(() => {
        console.log('Error in fetching categories')
      })
  }

  // handleOrganizationSelect = id => {
  //   const { onOrganizationSelect } = this.props
  //   this.setState({
  //     groups: this.state.orgs
  //       .filter(item => id === item.orgId)
  //       .map(item => item.groups)[0]
  //       .map(item => ({
  //         id: item.id,
  //         value: item.title,
  //         label: item.title
  //       }))
  //   })
  //   onOrganizationSelect(id)
  // }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  // handleGroupSelect = newValue => {
  //   const {
  //     onGroupSelect,
  //     courseBuilder: { courseGroup }
  //   } = this.props

  //   if (!newValue && courseGroup) {
  //     onGroupSelect(courseGroup)
  //     return
  //   }
  //   if (!newValue) return
  //   onGroupSelect(newValue.id)
  //   this.setState({
  //     value: newValue
  //   })
  // }

  // handleCreate = val => {
  //   const {
  //     createGroup,
  //     courseBuilder: { courseOrganization },
  //     onGroupSelect
  //   } = this.props

  //   this.setState({ isValueLoading: true })

  //   createGroup(courseOrganization, { title: val }).then(async res => {
  //     this.setState({ selectedGroup: res.id })
  //     onGroupSelect(this.state.selectedGroup)
  //     await this.fetchOrganization()
  //   })
  // }

  handleSwitch = () => {
    const {
      onSwitch,
      courseBuilder: { courseIsFree }
    } = this.props

    console.log(
      'clohandleinitialfirst',
      courseIsFree,
      this.props,
      this.state.isCourseFree
    )

    if (courseIsFree === null) {
      onSwitch(false)
      return
    }

    this.setState(
      {
        isCourseFree: !this.state.isCourseFree
      },
      () => {
        onSwitch(this.state.isCourseFree)
      }
    )
  }

  handleInitialSwitch = () => {
    const {
      onSwitch,
      courseBuilder: { courseIsFree }
    } = this.props

    // console.log(
    //   'clohandleswitchinitialfirst',
    //   courseIsFree,
    //   this.props,      this.state.isCourseFree
    // )

    if (courseIsFree === null || courseIsFree === undefined) {
      onSwitch(false)
      return
    }

    this.setState(
      {
        isCourseFree: courseIsFree ? courseIsFree : this.state.isCourseFree
      },
      () => {
        onSwitch(this.state.isCourseFree)
      }
    )
  }

  handleCategorySelect = id => {
    const { onCategory } = this.props
    onCategory(id)
  }

  render() {
    const {
      t,
      form: { getFieldDecorator },
      onVideoLink,
      onNoOfVideo,
      onNoOfLessons,
      courseBuilder: {
        courseCategory,
        courseVideoLink,
        courseNoOfVideos,
        courseNoOfLessons
      }
    } = this.props
    const { categories } = this.state

    // console.log('cloisfreeemarket', courseIsFree)

    return (
      <>
        <TrainerSection courseId={this.props.courseId} />
        <div className="mp-top_section_row">
          <Col md={8}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:category')}
            >
              {getFieldDecorator('category_id', {
                rules: [{ required: true, message: t('v4:select_category') }],
                initialValue: courseCategory
              })(
                <TreeSelect
                  size="large"
                  style={{ width: '100%' }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select"
                  allowClear
                  onChange={this.handleCategorySelect}
                >
                  {categories &&
                    categories.map(category => (
                      <TreeNode
                        value={category.id}
                        title={category.name}
                        key={category.id}
                      >
                        {category.children &&
                          category.children.map(option => (
                            <TreeNode
                              value={option.id}
                              title={option.name}
                              key={option.id}
                            />
                          ))}
                      </TreeNode>
                    ))}
                </TreeSelect>
              )}
            </Form.Item>
          </Col>
          <Col md={7}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:promotional_video_link')}
            >
              {getFieldDecorator('video_link', {
                rules: [{ required: true, message: t('v4:input_video_link') }],
                initialValue: courseVideoLink
              })(
                <Input
                  size="large"
                  maxLength={100}
                  onChange={e => onVideoLink(e.target.value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:number_of_video')}
            >
              {getFieldDecorator('no_of_video', {
                rules: [{ required: true, message: t('v4:error_video') }],
                initialValue: courseNoOfVideos
              })(
                <Input
                  size="large"
                  type="number"
                  min={0}
                  maxLength={100}
                  // onChange={e => onNoOfVideo(e.target.value)}
                  onChange={e => {
                    const value = Math.abs(parseInt(e.target.value)) || 0
                    e.target.value = value > 100 ? 100 : value
                    onNoOfVideo(value)
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          <Col md={15}>
            <PriceManagement {...this.props} handleSwitch={this.handleSwitch} />
          </Col>
          <Col md={8}>
            <Form.Item
              colon={false}
              required={false}
              label={t('v4:number_of_lessions')}
            >
              {getFieldDecorator('no_of_lesson', {
                rules: [{ required: true, message: t('v4:lessons') }],
                initialValue: courseNoOfLessons
              })(
                <Input
                  min={0}
                  size="large"
                  type="number"
                  maxLength={100}
                  // onChange={e => onNoOfLessons(e.target.value)}
                  onChange={e => {
                    const value = Math.abs(parseInt(e.target.value)) || 0
                    e.target.value = value > 100 ? 100 : value
                    onNoOfLessons(value)
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </div>
        <div className="mp-top_section_row">
          {/* <Col md={11}>
            <Form.Item
              colon={false}
              required={false}
              label={t('labels:organization (for paid course/webinar)')}
            >
              {getFieldDecorator('organization_id', {
                initialValue: courseOrganization,
                rules: [{ required: true, message: t('v4:organization') }]
              })(
                <Select
                  size="large"
                  placeholder="Select an organization"
                  onChange={this.handleOrganizationSelect}
                >
                  {orgs &&
                    orgs.map(org => (
                      <Option key={org.orgId} value={org.orgId}>
                        {org.title}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col> */}
        </div>
        {/* <div className="mp-top_section_row">
          <Col md={11}>
            <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
              {t('labels:group (for paid course/webinar)').toUpperCase()}
            </div>
            <CreatableSelect
              styles={customStyles}
              placeholder="Select or create a group"
              components={{
                ClearIndicator: null,
                LoadingIndicator: this.LoadingIndicator
              }}
              isLoading={isValueLoading}
              onChange={this.handleGroupSelect}
              onCreateOption={this.handleCreate}
              options={groups}
              value={value}
              isDisabled={isValueLoading}
            />
          </Col>
        </div> */}
      </>
    )
  }
}

export { MarketPlaceMidSection }
