import React, { useMemo, useEffect, useRef, useState } from 'react'
import { Icon, Modal, Popover, Progress, Select, Tooltip } from 'antd'
import i18n from 'i18next'
import Flag from 'react-world-flags'
import ChatBot from 'react-simple-chatbot'
import { withTranslation } from 'react-i18next'
import { ThemeProvider } from 'styled-components'

import { Logo } from '..'
// import { Header } from './styled'
import './style.scss'

import { config, languages } from '../../constants'
import { Profile, ChatBotView, Informations } from './internal'

import chatbotIcon from './images/new_sharebot.svg'
import botAvatar from './images/sharebot-robot.png'
import userDummyIcon from './images/user.svg'
import history from '../../history'
import aivideoIcon from './images/get_started.svg'
import AiVideos from '../../views/Courses/Create/nestedComponents/PageBuilder/Components/Image/components/AiVideo/AiVideos'
const { Option } = Select

const getPercent = app => {
  const { courseCloningProgress } = app.props
  if (!courseCloningProgress) return 0
  if (courseCloningProgress) {
    return courseCloningProgress.percent.toFixed(0)
  }
}

// all available props
const theme = {
  background: '#f5f8fb',
  // fontFamily: 'Lucida Sans',
  headerBgColor: '#389dd7',
  headerFontColor: '#ff544b',
  headerFontSize: '20px',
  botBubbleColor: '#fff', //'#389dd7',
  botFontColor: '#4a4a4a',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a'
}

const pathname = window.location.pathname

const shareBotResources = {
  organization: 'ORGANIZATION',
  worldKnowledge: 'WORLD_KNOWLEDGE'
}

const getAccessInfos = () => {
  const accessKeys = ['refreshToken', 'accessToken', 'idToken']
  let accessObj = {}
  for (let key in accessKeys) {
    const item = accessKeys[key]
    const accessValue = localStorage.getItem(item)
    accessObj[item] = accessValue
  }
  return accessObj
}

function NewHeaderMain(props) {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [isVideoModal, setVideoModal] = useState(false)
  const [showBot, setShowBot] = useState(false)
  const [lang, setLang] = useState('en-us')
  const chatBot = useRef()
  const [showMenu, setShowMenu] = useState(false)
  const [activeBotResource, setActiveBotResource] = useState(
    shareBotResources.organization
  )

  const [isShowingKnowledgeCenter, setIsShowingKnowledgeCenter] = useState(
    false
  )

  const [isJustSignedIn, setIsJustSignedIn] = useState(true)

  useEffect(() => {
    handleLanguage()
  }, [])

  useEffect(() => {
    setIsJustSignedIn(props.user.isLogin)
  }, [props.user])

  useEffect(() => {
    setIsJustSignedIn(false)
    setIsShowingKnowledgeCenter(pathname.includes('/knowledge-center'))
  }, [pathname])

  const handleLanguage = () => {
    if (localStorage.getItem('i18nextLng') === 'en-US') {
      localStorage.setItem('i18nextLng', 'english')
    }
    const lang = localStorage.getItem('i18nextLng') || 'en-us'

    if (checkHebrewArabic(lang)) {
      document.body.style.direction = 'rtl'
    } else {
      document.body.style.direction = 'ltr'
    }

    setLang(lang)
  }

  const toggleInfoModal = () => setShowInfoModal(val => !val)

  const toggleBot = () => setShowBot(val => !val)

  const handleChangeOption = async e => {
    if (checkHebrewArabic(e) || checkHebrewArabic(lang)) {
      window.location.reload()
    }
    setLang(e)
    i18n.changeLanguage(e)
  }

  const checkHebrewArabic = someLang =>
    someLang === 'hebrew' || someLang === 'arabic'

  const goToMarketplace = e => {
    const { accessToken, refreshToken, idToken } = getAccessInfos()
    const marketplaceUrl = `http://${config.cookieDomain}/marketplace?accessToken=${accessToken}&refreshToken=${refreshToken}&idToken=${idToken}`

    if (e.ctrlKey) {
      window.open(marketplaceUrl, '_blank').focus()
    } else {
      window.location.replace(marketplaceUrl)
    }
  }

  const { user, updateUser, organizations, t, isShowPopover } = props

  const { userImage, headerLogo, headerColor } = useMemo(() => {
    const userImage =
      (user && user.info.avatar && user.info.avatar.resizes.thumbnail) ||
      userDummyIcon
    const activeOrgInfo =
      organizations &&
      organizations.rows &&
      organizations.rows.find(
        org => user && user.info.active_organization_id === org.id
      )
    const isOrgWhiteLabelEnabeld =
      activeOrgInfo && activeOrgInfo.enable_white_label

    const orgHasWhiteLabelData =
      isOrgWhiteLabelEnabeld && activeOrgInfo && activeOrgInfo.white_label_data
    const headerInfo = orgHasWhiteLabelData && orgHasWhiteLabelData.header
    const headerColor = headerInfo && headerInfo.color
    const headerLogo = headerInfo && headerInfo.logo && headerInfo.logo.link

    const languageSelect = document.getElementById('language_select')

    const selectElement =
      languageSelect && languageSelect.querySelector('.ant-select-selection')

    if (selectElement && headerColor)
      selectElement.style.backgroundColor = headerColor
    else if (selectElement && !headerColor)
      selectElement.style.backgroundColor = '#333333'

    return { userImage, headerLogo, headerColor }
  }, [user, organizations])

  const handleToggleCenter = () => {
    if (isShowingKnowledgeCenter) {
      history.push('/training')
    } else {
      history.push('/knowledge-center')
    }
  }

  return (
    <>
      <div color={headerColor} className="main_header">
        <div className="main-herader-inner">
          <div className="main-herader-inner">
            {!isShowingKnowledgeCenter && (
              <Tooltip title={t('v4:sharebot')}>
                <div
                  className="sharebot_button"
                  role="button"
                  onClick={toggleBot}
                >
                  <img alt="" src={chatbotIcon} />
                </div>
              </Tooltip>
            )}
            <Tooltip title="Ai Video">
              <div
                className="sharebot_button"
                style={{ marginLeft: '1rem' }}
                role="button"
                onClick={() => {
                  setVideoModal(true)
                }}
              >
                <img alt="" src={aivideoIcon} />
              </div>
            </Tooltip>

            {isVideoModal && (
              <Modal
                visible={isVideoModal}
                footer={false}
                destroyOnClose
                onCancel={e => {
                  e.preventDefault()
                  setVideoModal(false)
                }}
                width={'80%'}
              >
                <AiVideos
                  data={''}
                  onData={data => {
                    console.log('test', data)
                  }}
                />
              </Modal>
            )}
            {showBot && activeBotResource && (
              <div className="shareboot-main-wrapper">
                <ThemeProvider theme={theme}>
                  <ChatBot
                    ref={chatBot}
                    userAvatar={userImage}
                    botAvatar={botAvatar}
                    bubbleStyle={{ maxWidth: '70%' }}
                    bubbleOptionStyle={{ borderRadius: '50%' }}
                    headerComponent={
                      <div className="sharebot-header">
                        <h2>{t('v3:sharebot')}</h2>
                        <span onClick={toggleBot}>&#215;</span>
                      </div>
                    }
                    width="450px"
                    height="calc(100vh - 15rem)"
                    className="sharebot-container"
                    activeBotResource={activeBotResource}
                    steps={[
                      {
                        id: 'customComponent',
                        delay: 0,
                        component: (
                          <ChatBotHeader
                            t={t}
                            chatBot={chatBot}
                            activeBotResource={activeBotResource}
                            setActiveBotResource={val => {
                              setActiveBotResource(val)
                            }}
                          />
                        ),
                        trigger: 'first'
                      },
                      {
                        id: 'first',
                        message: t('v3:sharebot_help_message'),
                        trigger: 'search'
                      },
                      {
                        id: 'search',
                        user: true,
                        trigger: '1'
                      },
                      {
                        id: '1',
                        asMessage: true,
                        component: (
                          <ChatBotView
                            user={user}
                            triggerNext={() =>
                              chatBot && chatBot.current.triggerNextStep()
                            }
                            chatBot={chatBot}
                          />
                        ),
                        waitAction: true,
                        trigger: 'search'
                      }
                    ]}
                  />
                </ThemeProvider>
              </div>
            )}

            {showInfoModal && (
              <React.Fragment>
                <Modal
                  style={{ top: 0, minHeight: '100vh' }}
                  visible={showInfoModal}
                  onCancel={toggleInfoModal}
                  footer={null}
                  width="100%"
                  className="info-modal"
                  centered
                >
                  <Informations {...props} onMainMenu={toggleInfoModal} />
                </Modal>
              </React.Fragment>
            )}

            {/* Language Selection */}

            <div id="language_select">
              <Tooltip title={t('v4:selected_language')}>
                <Select
                  size="large"
                  value={lang}
                  onChange={e => handleChangeOption(e)}
                  suffixIcon={null}
                  getPopupContainer={() =>
                    document.getElementById('language_select')
                  }
                >
                  {languages.map(item => (
                    <Option key={item.key} value={item.key}>
                      <div className="ssl-flag-wrap">
                        <Flag code={item.code === 'en-us' ? 'us' : item.code} />
                        {item.name}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* Profile Menus */}
        <div className="author-menu mr-mobile-author-menu">
          <div>
            <Profile
              user={user}
              updateUser={updateUser}
              organizations={organizations}
            />
          </div>
        </div>

        <div className="menu-toggle-wrapper">
          <button
            href="#"
            onClick={() => setShowMenu(!showMenu)}
            className="menu-toggle"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      {/* mobile responsive menu */}

      <div className={showMenu ? 'mr-mobile-menu show-menu' : 'mr-mobile-menu'}>
        <div className="mr-mobile-content">
          <div className="mr-mobile-brand-wrapper">
            <div className="brand">
              <Logo color="white" logo={headerLogo} />
            </div>
            <div
              className="mr-close-wrapper"
              onClick={() => setShowMenu(!showMenu)}
            >
              <button aria-label="Close Menu" className="mr-close">
                <span className="mr-inner"></span>
              </button>
            </div>
          </div>
          <div className="sharelook-line"></div>

          <ul className="mr-mobile-item">
            {!isJustSignedIn && (
              <li className="mr-mobile-list-item">
                <div role="button" onClick={handleToggleCenter}>
                  <Icon type="bulb" />
                  <span>
                    {isShowingKnowledgeCenter
                      ? t('v3:learning_center')
                      : t('v3:knowledge_center')}
                  </span>
                </div>
              </li>
            )}

            {!isShowingKnowledgeCenter && (
              <li className="mr-mobile-list-item">
                <div role="button" onClick={toggleBot}>
                  <Icon type="message" />
                  <span>{t('v3:sharebot')}</span>
                </div>
              </li>
            )}

            <li className="mr-mobile-list-item item-btn">
              <div id="mobile_language_select">
                <Select
                  size="large"
                  value={lang}
                  placeholder="Select Language"
                  style={{ width: '165px' }}
                  onChange={e => handleChangeOption(e)}
                  getPopupContainer={() =>
                    document.getElementById('mobile_language_select')
                  }
                >
                  {languages.map(item => (
                    <Option key={item.key} value={item.key}>
                      <Flag code={item.code} height="13" /> {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </li>
            <li className="mr-mobile-list-item item-btn">
              <div className="author-menu">
                <div>
                  <Profile
                    user={user}
                    updateUser={updateUser}
                    organizations={organizations}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

const ChatBotHeader = ({
  activeBotResource: defaultActiveBotResource,
  setActiveBotResource,
  chatBot,
  t
}) => {
  const {
    props: { activeBotResource: changedActiveBotResource }
  } = chatBot.current

  const activeBotResource = changedActiveBotResource || defaultActiveBotResource
  return (
    <div className="sharebot-tabs">
      <div
        className={`${
          activeBotResource === shareBotResources.organization ? 'active' : ''
        } sharebot-tab`}
        role="button"
        onClick={() => setActiveBotResource(shareBotResources.organization)}
      >
        {t('v3:my_organization')}
      </div>
      <div
        className={`${
          activeBotResource === shareBotResources.worldKnowledge ? 'active' : ''
        } sharebot-tab`}
        role="button"
        onClick={() => setActiveBotResource(shareBotResources.worldKnowledge)}
      >
        {t('v3:world_knowledge')}
      </div>
    </div>
  )
}

export default withTranslation()(NewHeaderMain)
