import { connect } from 'react-redux'
import Menu from './Menu'

export default connect(
  (state) => ({
    menu: state.menu,
    user: state.user
  }),
  null,
  null,
  { pure: false }
)(Menu)
