const autoCompleteModal = {
  borderRadius: '3px solid red',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  // padding: '10px 0px',
  fontSize: '90%',
  position: 'absolute',
  top: 30,
  left: 0,
  overflowY: 'scroll',
  zIndex: 100,
  height: '300px'
}

const HighLight = {
  backgroundColor: '#0068FF',
  color: 'white',
  zIndex: 100,
  padding: '5px 10px'
}
const NormalText = {
  backgroundColor: 'white',
  color: '#333333',
  zIndex: 100,
  padding: '5px 10px'
}
const AutoWrappper = {
  display: 'inline-block',
  width: '300px'
}

export default {
  autoCompleteModal,
  HighLight,
  NormalText,
  AutoWrappper
}
