/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-29 11:51:30
 * @LastEditTime: 2019-08-29 11:55:58
 * @LastEditors: Please set LastEditors
 */
import styled from 'styled-components'

import { media } from '../../styles'
import { styleguide } from '../../constants'
import { DropdownToggle, IconArrow } from '../Dropdown/styled'

const { colors } = styleguide

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: stretch;
  background: ${colors.black};
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;

  ${props => {
    console.log('color', props)
    return (
      props.color &&
      `
    background: ${props.color};
    `
    )
  }}
`

Header.Inner = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.4rem;
`

Header.Profile = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
    flex-shrink: 0;
  }
`

Header.Content = styled.div`
  display: flex;
  align-items: center;

  ${DropdownToggle} {
    padding-right: 0;
  }

  ${IconArrow} {
    right: 1.5rem;

    svg {
      path {
        fill: ${colors.white};
        stroke: ${colors.white};
      }
    }

    ${media.md`
      display: none;
    `}
  }
`

Header.Item = styled.div`
  &:not(:last-child) {
    margin-right: 1.6rem;
  }
`

export { Header }
