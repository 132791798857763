import React, { useState } from 'react'
import buildCourse from '../../images/build_course.svg'
import inviteUsers from '../../images/invite_users.svg'
import liveBroadcast from '../../images/live_broadcast.svg'
import uploadMedia from '../../images/upload_media.svg'
import viewReports from '../../images/view_reports.svg'
import voiceOver from '../../images/voice_over.svg'
import history from '../../../../history'
import { Button } from 'antd'
import PlayIcon from '../../../../assets/play_icon.png'
import InformationModal from './InformationModal'
import './styles.scss'

const Informations = props => {
  const { t } = props

  const [showInfoModal, setShowInfoModal] = useState(false)
  const [currentInfo, setCurrentInfo] = useState({})

  const modalInfos = [
    {
      id: 1,
      icon: buildCourse,
      URL: '/course-create',
      videoURL: 'https://youtu.be/cixP6V0nKsk',
      title: t('v4:build_course'),
      description1: '',
      description2: ''
    },
    {
      id: 2,
      icon: liveBroadcast,
      URL: '/events',
      videoURL: 'https://youtu.be/xQFX8xxh82M',
      title: t('v4:live_broadcast'),
      description1: t('v2:live_broadcast_description'),
      description2: t('v2:live_broadcast_subdescription')
    },
    {
      id: 3,
      icon: uploadMedia,
      URL: '/library/list',
      videoURL: 'https://youtu.be/HMxDugq_BQA',
      title: t('v4:upload_media'),
      description1: '',
      description2: ''
    },
    {
      id: 4,
      icon: inviteUsers,
      URL: '/management/users',
      videoURL: 'https://youtu.be/X6MwHdKHIUM',
      title: t('v4:invite_users'),
      description1: '',
      description2: ''
    },
    {
      id: 5,
      icon: voiceOver,
      URL: '',
      videoURL: 'https://www.youtube.com/watch?v=TPoWvKdQXxI',
      title: t('v4:build_voice_over'),
      description1: '',
      description2: ''
    },
    {
      id: 6,
      icon: viewReports,
      URL: '/reports',
      videoURL: 'https://youtu.be/JkC5OMxEQXs',
      title: t('v4:view_reports'),
      description1: '',
      description2: ''
    }
  ]

  const closeModal = () => {
    props.onMainMenu(false)
  }

  const redirectVoiceOver = () => {
    window.open('https://www.sharelookvoices.com/create-voice.html', '_blank')
  }

  const toggleInfoModal = value => {
    setShowInfoModal(!showInfoModal)
    modalInfos.forEach(item => {
      if (item.id === value) setCurrentInfo(item)
    })
  }

  return (
    <>
      <div className="info-wrapper">
        <div className="title">{t('v4:title')}</div>

        <div className="items">
          {modalInfos.map(item => {
            return (
              <div className="info">
                <div
                  className="info-item"
                  role="button"
                  onClick={() => {
                    closeModal()
                    if (item.id === 5) {
                      redirectVoiceOver()
                    } else {
                      history.push(item.URL)
                    }
                  }}
                >
                  <div className="img-container">
                    <img alt="" src={item.icon} />
                  </div>
                  <h3>{item.title}</h3>
                </div>
                <div className="info-button-div">
                  <Button
                    className="info-button"
                    onClick={() => toggleInfoModal(item.id)}
                    type="text"
                  >
                    {t('v2:how_to_use').toUpperCase()}
                  </Button>
                  <img alt="" src={PlayIcon} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className="menu_link"
        role="button"
        onClick={() => {
          closeModal()
          history.push('/')
        }}
      >
        {t('v4:main_menu')}
      </div>
      {showInfoModal && (
        <InformationModal
          visible={showInfoModal}
          onCancel={toggleInfoModal}
          currentInfo={currentInfo}
          closeModal={closeModal}
          redirectVoice={redirectVoiceOver}
        />
      )}
    </>
  )
}

export default Informations
