import React, { Component } from 'react'
import { Button } from 'antd'
import { DropzoneWrapper, DropzoneInner, DropzoneLoading } from './styled'

import { config } from '../../../../../constants'

import { withTranslation } from 'react-i18next'

class LessonDropzone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }
  }

  onDrop = files => {
    const { onDrop } = this.props
    const file = files[0]

    if (!file) {
      this.handleAfterUpload()
      return
    }

    onDrop(file, this.handleAfterUpload, this.handlebeforeUpload)
  }

  handleAfterUpload = () => {
    this.setState({ loading: false })
  }

  handlebeforeUpload = () => {
    this.setState({ loading: true })
  }

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    const { app } = this.props
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    app.handleUploadImage(body)
  }

  render() {
    const { loading } = this.state

    const {
      cover,
      mode,
      error,
      disabled,
      onDropAccepted,
      t,
      app,
      loadImage
    } = this.props

    return (
      <DropzoneWrapper
        multiple={false}
        onDrop={this.onDrop}
        onDropAccepted={onDropAccepted}
        accept="image/jpeg, image/png"
        cover={cover}
        mode={mode}
        error={error}
        disabled={disabled}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return loading || loadImage ? (
            <DropzoneLoading type="loading" />
          ) : (
            <DropzoneInner>
              <div className="wrap_upload_buttons">
                <Button className="rounded" size="large" icon="picture">
                  {t('buttons:upload_image')}
                </Button>
                <Button
                  className="rounded"
                  size="large"
                  icon="picture"
                  onClick={e => {
                    e.stopPropagation()
                    app.setState({
                      modalOpen: true
                    })
                  }}
                >
                  {t('buttons:select_media_library')}
                </Button>

                <Button
                  className="rounded"
                  size="large"
                  icon="picture"
                  onClick={e => {
                    e.stopPropagation()
                    this.useCanva()
                  }}
                >
                  {t('v4:canva')}
                </Button>
              </div>
            </DropzoneInner>
          )
        }}
      </DropzoneWrapper>
    )
  }
}

export default withTranslation('coursecreate')(LessonDropzone)
