import styled from 'styled-components'

const AudioPopCard = styled.div`
  position: absolute;
  background: #f9f9f9;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  width: 246px;
  z-index: 9;
  padding: 20px;
  top: 35px;
  right: -50px;
  text-align: center;
  font-size: 12px;
`

AudioPopCard.Img = styled.div`
  margin-bottom: 10px;
`

AudioPopCard.P = styled.div`
  margin-top: 15px;
  text-align: left;
`
AudioPopCard.Span = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #87939f;
  text-align: left;
  display: block;
`
AudioPopCard.Span.TextSuccess = styled.div`
  color: #1dba61;
`
export { AudioPopCard }
