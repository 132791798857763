import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import Create from './Create'

const mapStateToProps = state => ({
  events: state.events,
  topics: state.topics,
  isFetching: state.organizations.isFetching,
  list: state.organizations.list.rows,
  library: state.library,
  user: state.user,
  organizations: state.organizations.list
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      updateUser: actions.user.updateUser,
      updateTopicList: actions.topics.updateTopicList,
      get: actions.organizations.get
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Create)
