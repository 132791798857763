import React, { Component } from 'react'
import { Button, Modal } from 'antd'
import { DropzoneWrapper, DropzoneInner, DropzoneLoading } from './styled'
import { withTranslation } from 'react-i18next'
import AiImages from '../../nestedComponents/PageBuilder/Components/Image/components/AiImages'

class CourseDropzone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      isAiModal: false
    }
  }

  onDrop = files => {
    const { onDrop } = this.props
    const file = files[0]

    if (!file) {
      this.handleAfterUpload()
      return
    }

    onDrop(file, this.handleAfterUpload, this.handlebeforeUpload)
  }

  handleAfterUpload = () => {
    this.setState({ loading: false })
  }

  handlebeforeUpload = () => {
    this.setState({ loading: true })
  }

  onImage = item => {
    const { handleAiImage } = this.props
    handleAiImage(item)
    this.setState({ isAiModal: false })
  }

  render() {
    const { loading, isAiModal } = this.state

    const { cover, mode, error, app, t, loadImage } = this.props
    // console.log('load', loading, loadImage)
    return (
      <>
        {isAiModal && (
          <Modal
            visible={isAiModal}
            footer={false}
            destroyOnClose
            onCancel={e => {
              e.preventDefault()
              this.setState({ isAiModal: false })
            }}
            width={'80%'}
          >
            <AiImages selectedImage={cover} onImage={this.onImage} />
          </Modal>
        )}

        <DropzoneWrapper
          multiple={false}
          onDrop={this.onDrop}
          // onDropAccepted={onDropAccepted}
          accept="image/jpeg, image/png"
          cover={cover}
          mode={mode}
          error={error}
          // disabled={disabled}
        >
          {() => {
            return loading || loadImage ? (
              <DropzoneLoading type="loading" />
            ) : (
              <DropzoneInner>
                <Button
                  className="rounded"
                  size="large"
                  icon="picture"
                  style={{ margin: '5px auto' }}
                >
                  {t('buttons:upload_image')}
                </Button>

                <Button
                  style={{ margin: '5px auto' }}
                  className="rounded"
                  size="large"
                  icon="picture"
                  onClick={e => {
                    e.stopPropagation()
                    app.setState({
                      modalOpen: true
                    })
                  }}
                >
                  {t('buttons:select_media_library')}
                </Button>

                <Button
                  style={{ margin: '5px auto' }}
                  className="rounded"
                  size="large"
                  icon="picture"
                  onClick={e => {
                    e.stopPropagation()
                    app.useCanva('Presentation')
                  }}
                >
                  {t('v4:canva')}
                </Button>
                <Button
                  style={{ margin: '5px auto' }}
                  className="rounded"
                  size="large"
                  icon="picture"
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ isAiModal: true })
                  }}
                >
                  {t('v3:ai_creator')}
                </Button>
              </DropzoneInner>
            )
          }}
        </DropzoneWrapper>
      </>
    )
  }
}

export default withTranslation('coursecreate')(CourseDropzone)
