import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../../../../store/actions'
import EditAudio from './EditAudio'

const mapStateToProps = state => ({
  audioSynthesizer: state.audioSynthesizer
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createAudioSynthesizer: actions.audioSynthesizer.createAudioSynthesizer,
      getSelectedTextVoiceType:
      actions.audioSynthesizer.getSelectedTextVoiceType,
      showEditAudioModal: actions.audioSynthesizer.showEditAudioModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditAudio)
