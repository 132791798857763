import React, { Component, Fragment } from 'react'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import ReactPlayer from 'react-player'
import 'react-input-range/lib/css/index.css'
import InputRange from 'react-input-range'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'
import { Alert, Statistic } from 'antd'
import { config } from '../../../constants'
import { Spinner } from '../../../components'
import { options, reactChartStyles, legendStyles } from './ChartOptions'
import { Emotions } from './components'
import { api } from '../../../services'
import { withTranslation } from 'react-i18next'
import { RangeSliderContainer, FloatingContainer, EmotionRnd } from './styled'
import './view.scss'

const { Countdown } = Statistic

const statuses = {
  NOT_STARTED: 'NOT_STARTED',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
  FINISHED: 'FINISHED'
}

const assessmentPrefix = {
  sendMessage: 'sendMessage',
  startStream: 'startStream',
  stopStream: 'stopStream',
  historyChannel: 'historyChannel',
  sendEmotion: 'sendEmotion',
  emotion: 'emotion'
}

let onEvents = null

// const token = 'eyJraWQiOiIrTW9lcjYrRWZ2UXNxdjhsUDlcL0UyR3RUU2FWUDVEY1hsM2V6YXBuZkFRUT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI0Mjk5Mjk1NS05YzhmLTQ5MjgtOTViZC1lZDdmOTg3MDc0MmEiLCJjdXN0b206dGltZXpvbmUiOiJ7XCJ2YWx1ZVwiOlwiQXNpYVwvS2F0bWFuZHVcIixcImxhYmVsXCI6XCIoR01ULTU6NDUpIEFzaWFcL0thdG1hbmR1XCJ9IiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImFkZHJlc3MiOnsiZm9ybWF0dGVkIjoiU2luZ2Fwb3JlIn0sImN1c3RvbTpwb3NpdGlvbiI6IkRFViIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzRWMUpPbjFleiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImNvZ25pdG86dXNlcm5hbWUiOiJtYWhlc2guc2hhcmVsb29rIiwiYXVkIjoiMzBxZGdpMm80azBjZWYwZGE2NHNnamxncXYiLCJldmVudF9pZCI6ImU2YTVlNmRjLWY3YTEtNDY5Ny1iOTIxLWZmMWJmNWZhZjBlZCIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA2OTgxMjM1LCJuYW1lIjoiTWFoZXNoIEpvc2hpIiwicGhvbmVfbnVtYmVyIjoiKzY1NjIwMDAwMDAiLCJleHAiOjE2MDcwODI4MjMsImlhdCI6MTYwNzA3OTIyMywiZW1haWwiOiJtYWhlc2hAc2hhcmVsb29rYXBwLmNvbSJ9.gDiRTseg614oy0kEFSjTfnnKCQ351ZDTSnsLYlQWJI0vtCUR4wkUvS422r94NgmoEJOJ2x8af1RlIjtnN6r2epFo6ch5MEOvUmZxoPQfsR7gRBcbzMWdlb1_uP8RP4KJXGP6SmdcxxnPlLtAVTx5zGOR0U5gSka5C2I3tQKyUi6kW3WLUiyjPUOjnrVa0knd44JtbtbcvCdAqtIgteXrHxQ-cXFP4oHB_5kHwz0hnIWCqv830q8vKjESoAc9CHgCRZhh68queFEPO0WfhDGWyN7Kt1E_c0NUyRSnZNRkU9wxfV-JSUI5SsMtizAlmzIXRi1adzuiXX8NqrKWdRlo0A'

class View extends Component {
  socket = null
  yAxisValue = null
  legendColor = ['#F9A271', '#F9F871', '#A9FAFF', '#00BFE1', '#3E9CD4']
  state = {
    status: statuses.NOT_STARTED,
    messages: [],
    isFetching: true,
    assessment: null,
    userId: null,
    isNoProfile: false,
    isLoading: true,
    isStreaming: false,
    socketConnected: false,
    currentPlayTime: 0,
    videoDuration: 0,
    isEnd: false,
    graphEmotionsData: [],
    floatingEmotionsList: [],
    pollValues: {
      result: [
        {
          key: '0',
          value: 0
        }
      ]
    },
    isRefresh: false,
    isScrollBottom: true,
    isChatAble: false,
    countDown: false,
    totalEmotions: 0,
    rawEmotions: null,
    sendToSocket: true,
    emotionsData: [
      {
        type: 1,
        name: 'angry',
        value: 0
      },
      {
        type: 2,
        name: 'sad',
        value: 0
      },
      {
        type: 3,
        name: 'neutral',
        value: 0
      },
      {
        type: 4,
        name: 'smile',
        value: 0
      },
      {
        type: 5,
        name: 'happy',
        value: 0
      }
    ]
  }

  setPlayerRef = node => {
    this.playerRef = node
  }

  componentDidMount = async () => {
    Auth.configure({
      Auth: {
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: config.cognito.MANDATORY_SIGN_IN,
        authenticationFlowType: config.cognito.AUTHENTICATION_FLOW_TYPE
      }
    })

    const user = await Auth.signIn('3view', 'Q2wert6y!')

    console.log('user detail', user)

    Auth.currentSession().then((session) => {
      console.log('currentSession log', session)
      const id = this.props.match.params.id

      console.log('this.props.match.params', this.props.match.params)
      console.log('id assignment', id)
      // console.log('id assignment', token)

      console.log('session.accessToken.jwtToken', session)

      // const id = 854
      if (id && session.idToken.jwtToken) {
        this.interval = setInterval(() => {
          this.handleShowChat()
        }, 10000)
        this.getAssessmentById(id, session.idToken.jwtToken)
        this.getEmotionsById(id, session.idToken.jwtToken)
        onEvents = {
          click: this.onChartClick,
          legendselectchanged: {}
        }
      }
    }, (err) => {
      console.log(err)
    })
  }

  componentWillUnmount () {
    this.stopStream()
    clearInterval(this.interval)
    if (this.player) {
      this.player.dispose()
    }
  }

  handleChatAble = ({ start_date_time: startDataTime }) => {
    const newTime = moment(startDataTime).format('LLLL')
    const diffMinutes = moment().diff(newTime, 'minutes')
    return diffMinutes >= -10
  }

  handleVoteAble = ({
    start_date_time: startDataTime,
    duration,
    is_finished,
    host_id
  }) => {
    const { user } = this.props
    if (host_id === user.info.id) {
      return false
    } else {
      if (is_finished) {
        return !is_finished
      }
      const newTime = moment(startDataTime).format('LLLL')
      const diffMinutes = moment().diff(newTime, 'minutes')
      return diffMinutes >= 0 && diffMinutes <= duration
    }
  }

  onChartClick = (...params) => {
    const { spaceGraphValue, secondArray } = this.state
    const currentChartClick = _.filter(
      secondArray,
      (item, index) => index === params[0].dataIndex
    )
    this.setState({
      currentPlayTime: this.getscData(currentChartClick, spaceGraphValue)
    })

    this.playerRef.seekTo(this.getscData(currentChartClick, spaceGraphValue))
  }

  getAssessmentById = (id, token) => {
    const { viewAssessment } = this.props
    /* api.assessments
      .getById(id)
      .then(({ data }) => {
        this.setState({
          assessment: data,
          isLoading: false,
          isChatAble: this.handleChatAble(data),
          isVoteAble: this.handleVoteAble(data)
        })
        this.getUserInfo(data)
        data && viewAssessment(data.id)
      })
      .catch(() => {
        this.setState({
          isLoading: false
        })
      }) */

    const URL = 'https://dev-api.v2.sharelookapp.com/v1/assessments/' + id
    fetch(URL, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then(response => response.json())
      .then((data) => {
        console.log('Hello', data)

        this.setState({
          assessment: data,
          isLoading: false,
          isChatAble: this.handleChatAble(data),
          isVoteAble: this.handleVoteAble(data)
        })
        // this.getUserInfo(data)
        data && viewAssessment(data.id)
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  getEmotionsById = (id, token) => {
    const { emotionsData } = this.state
    /* api.assessments.getEmotionsById(id).then(({ data: { messages } }) => {
      const emotionsContents = _.map(messages, emo => emo.content)
      const groupEmotions = _.groupBy(emotionsContents)
      if (groupEmotions) {
        _.each(groupEmotions, (group, index) => {
          emotionsData[index - 1].value = group.length
        })
      }
      this.setState({
        emotionsData,
        rawEmotions: messages,
        totalEmotions: emotionsContents.length
      })
    }) */

    const URL = `https://dev-api.v2.sharelookapp.com/v1/assessments/${id}/emotions`
    fetch(URL, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then(response => response.json())
      .then((data) => {
        console.log('data messages log', data)

        const emotionsContents = _.map(data.messages, emo => emo.content)
        const groupEmotions = _.groupBy(emotionsContents)
        if (groupEmotions) {
          _.each(groupEmotions, (group, index) => {
            emotionsData[index - 1].value = group.length
          })
        }
        this.setState({
          emotionsData,
          rawEmotions: data.messages,
          totalEmotions: emotionsContents.length
        })
      })
  }

  getUserInfo = assessment => {
    api.user
      .getUser()
      .then(() => {
        // const { id: 757 } = response
        this.setState(
          {
            userId: 757
          },
          () => {
            const { userId } = this.state
            this.initSocket(userId, assessment)
          }
        )
      })
      .catch(error => console.log('error', error))
  }

  initSocket = (userId, assessmentDetail) => {
    const id = this.props.match.params.id
    const { user } = this.props
    const user_id = user.info.id || userId

    this.socket = new window.WebSocket(
      `${config.socketURL}?channel_type=ASSESSMENT&channel_id=${id}&user_id=${user_id}`
    )

    this.socket.onopen = () => {
      this.setState({
        socketConnected: true
      })
      this.getChatHistory()
    }

    this.socket.onmessage = assessment => {
      const { emotionsData, totalEmotions } = this.state

      const data = JSON.parse(assessment.data)

      if (data.messages) {
        _.each(data.messages, item => {
          item.isReadMore = item.content && item.content.length >= 200
        })
        this.setState(() => ({
          messages: data.messages
        }))
      }

      if (
        data.event === assessmentPrefix.sendEmotion &&
        data.channel_id === id
      ) {
        _.each(emotionsData, item => {
          if (item.type === data.content) {
            item.value = item.value + 1
          }
        })
        if (user_id !== data.user_id) {
          this.addEmojiToList(data.content)
        }
        this.setState(() => ({
          emotionsData,
          totalEmotions: totalEmotions + 1
        }))
      }

      if (
        data.event === assessmentPrefix.sendMessage &&
        data.channel_id === id
      ) {
        if (data.content) {
          data.isReadMore = data.content.length >= 200
        }
        this.setState(state => ({
          messages: [...state.messages, data]
        }))
      }

      if (
        data.event === assessmentPrefix.startStream &&
        data.channel_id === id
      ) {
        this.initStream()
      }

      if (
        data.event === assessmentPrefix.stopStream &&
        data.channel_id === id
      ) {
        this.stopStream()
        window.location.reload()
      }

      if (
        data.event === assessmentPrefix.historyChannel &&
        data.channel_id === id
      ) {
        if (data.liveStatus === statuses.NOT_STARTED) {
          this.setState({
            liveStatus: statuses.NOT_STARTED
          })
        }

        if (data.liveStatus === statuses.RUNNING) {
          if (!assessmentDetail.is_finished) {
            this.initStream()
          }
        }

        if (data.liveStatus === statuses.STOPPED) {
          this.stopStream()
        }
      }
    }

    this.socket.onerror = error => {
      console.log('Error ' + error.message)
    }
  }

  getChatHistory = () => {
    const id = this.props.match.params.id

    this.socket.send(
      JSON.stringify({
        action: assessmentPrefix.historyChannel,
        channel_id: id,
        channel_type: 'ASSESSMENT'
      })
    )
  }

  clearAlert = () => {
    this.setState({
      isNoProfile: false
    })
  }

  sendMessage = content => {
    const {
      user: { info }
    } = this.props

    if (!info.name) {
      this.setState(
        {
          isNoProfile: true
        },
        () => {
          setTimeout(() => {
            if (this.state.isNoProfile) {
              this.clearAlert()
            }
          }, 5000)
        }
      )
      return
    }
    const { userId } = this.state
    const id = this.props.match.params.id
    const user_id = info.id || userId

    this.socket.send(
      JSON.stringify({
        action: assessmentPrefix.sendMessage,
        channel_type: 'ASSESSMENT',
        channel_id: id,
        user_id,
        user_name: info.name,
        time: 0,
        content
      })
    )
  }

  stopStream = () => {
    const myPlayer = window.WowzaPlayer
      ? window.WowzaPlayer.get('stream')
      : null

    if (myPlayer !== null) {
      myPlayer.finish()
      myPlayer.destroy()

      this.setState({
        liveStatus: statuses.STOPPED,
        isStreaming: false
      })
    } else {
      this.setState({
        liveStatus: statuses.STOPPED,
        isStreaming: false
      })
    }
  }

  initStream = () => {
    // const {
    //   match: { params }
    // } = this.props
    const { assessment } = this.state
    const streamUrl = assessment.stream
      ? assessment.stream.player_hls_playback_url
      : ''

    this.setState({
      liveStatus: statuses.RUNNING,
      isStreaming: true
    })

    window.WowzaPlayer.create('stream', {
      license: config.wowza.license,
      sourceURL: streamUrl,
      autoPlay: true,
      mute: false,
      loop: false,
      audioOnly: false,
      uiShowQuickRewind: true,
      uiQuickRewindSeconds: '30',
      withCredentials: false
    })
  }
  addEmojiToList = data => {
    const rotateValue = [0, 360]
    const randomValue = maxValue =>
      Math.floor(Math.random() * Math.floor(maxValue))
    const floatingEmotionsList = {
      content: data,
      beforeRotate: rotateValue[randomValue(2)],
      afterRotate: rotateValue[randomValue(2)],
      speed: Math.floor(Math.random() * 5) + 3,
      position: randomValue(200)
    }
    this.setState({
      floatingEmotionsList: [
        ...this.state.floatingEmotionsList,
        floatingEmotionsList
      ]
    })
  }
  sendEmotion = kind => {
    const { user } = this.props
    const { userId } = this.state
    const id = this.props.match.params.id
    const user_id = user.info.id || userId
    const user_name = user.info.name || user.info.username
    this.addEmojiToList(kind)

    if (!this.state.sendToSocket) {
      return
    }

    this.setState(
      {
        sendToSocket: false
      },
      () => {
        this.socket.send(
          JSON.stringify({
            action: assessmentPrefix.emotion,
            channel_type: 'ASSESSMENT',
            channel_id: id,
            user_name,
            user_id,
            kind
          })
        )
      }
    )

    setTimeout(() => {
      this.setState({
        sendToSocket: true
      })
    }, 15000)
  }

  getscData = (newObj, value) => {
    if (newObj < value) {
      return 0
    }
    if (newObj >= value) {
      const result = newObj - value
      return result
    }
  }

  handleVideoDuration = e => {
    const value = e / 30
    const tempArray = []
    const secondArray = []
    for (let i = 1; i <= 30; i++) {
      tempArray.push(i)
    }
    const graphDuration = _.map(tempArray, (obj, index) => {
      const newObj = (index + 1) * value.toFixed(1)
      const scData = this.getscData(newObj, value)
      secondArray.push(newObj)
      return this.timeConvert(scData)
    })

    this.setState(
      {
        videoDuration: e || 0,
        graphDuration,
        secondArray,
        spaceGraphValue: value
      },
      () => {
        this.handleGraphHover(secondArray)
      }
    )
  }

  handleGraphHover = graphDataList => {
    const { rawEmotions } = this.state
    let tempEmotions = _.cloneDeep(rawEmotions)
    let result = []
    _.each(graphDataList, (graph, gIndex) => {
      _.each(tempEmotions, (emo) => {
        if (emo.time <= graph) {
          result.push({
            content: emo.content,
            graphIndex: gIndex,
            time: emo.time
          })
          tempEmotions = _.filter(tempEmotions, item => item.time > graph)
        }
      })
    })

    this.filterGraphData(result)
  }
  insertDataIntoIndexByType = (data, type) => {
    if (data[type]) {
      const result = []
      for (let i = 0; i < 30; i++) {
        const value = _.filter(data[type], item => item.graphIndex === i)
        result.push(value.length)
      }
      return result
    } else {
      const defaultData = []
      for (let i = 0; i < 30; i++) {
        defaultData.push(0)
      }
      return defaultData
    }
  }
  getType = value => {
    let type = ''
    switch (`${value}`) {
      case '1':
        type = 'angry'
        break
      case '2':
        type = 'sad'
        break
      case '3':
        type = 'neutral'
        break
      case '4':
        type = 'smile'
        break
      case '5':
        type = 'happy'
        break
      default:
        break
    }
    return type
  }
  filterGraphData = data => {
    const tempEmotionsData = _.groupBy(data, 'content')
    const result = {
      angry: this.insertDataIntoIndexByType(tempEmotionsData, '1'),
      sad: this.insertDataIntoIndexByType(tempEmotionsData, '2'),
      neutral: this.insertDataIntoIndexByType(tempEmotionsData, '3'),
      smile: this.insertDataIntoIndexByType(tempEmotionsData, '4'),
      happy: this.insertDataIntoIndexByType(tempEmotionsData, '5')
    }

    this.setState({
      graphEmotionsData: result
    })
  }

  timeConvert = value => {
    const time = Math.floor(value)
    const minutes = Math.floor(time / 60)
    let second = `${time - minutes * 60}`
    if (second < 10) {
      second = `0${second}`
    }
    if (second === '60') {
      second = '00'
    }

    return minutes + ':' + second
  }

  handleVideoProgress = e => {
    this.setState({
      currentPlayTime: e.playedSeconds
    })
  }

  checkPastVideo = assessment => {
    if (assessment.is_live) {
      return false
    } else if (assessment.video_links && assessment.video_links.length > 0) {
      return true
    }
    return false
  }

  handlSeekOrEnd = (isEnd = false) => {
    if (isEnd) {
      this.setState({
        isEnd: true
      })
    } else {
      this.setState({
        isEnd: false
      })
    }
  }

  handleScroll = e => {
    const isScrollBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    this.setState({
      isScrollBottom
    })
  }

  onCountDownFinish = () => {
    this.setState(
      {
        isChatAble: true
      },
      () => {
        window.location.reload()
      }
    )
  }

  handleShowChat = () => {
    const { assessment } = this.state
    if (!assessment) return

    const now = new Date()
    const timeTillDate = moment(assessment.start_date_time).diff(now, 'minute')
    if (timeTillDate <= 10) {
      this.setState({
        isChatAble: true
      })
    }
  }
  displaySchedule = ({ start_date_time: startDataTime }) => {
    if (!startDataTime) return
    const assessmentStartTime = moment(startDataTime).format('lll')
    const format = moment(assessmentStartTime).format('DD MMM YYYY, HH: mm')
    return `Schedule for ${format}`
  }

  displayCountdownTime = ({ start_date_time: startDataTime }) => {
    if (!startDataTime) return false
    const now = new Date()
    const timeTillDate = moment(startDataTime).diff(now, 'minute')

    if (timeTillDate <= 1440) {
      return true
    }
    return false
  }

  handleReadmore = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    const { messages, isRefresh } = this.state
    messages[index].isReadMore = !messages[index].isReadMore
    this.setState({
      messages,
      isRefresh: !isRefresh
    })
  }

  getRangeWidth = () => {
    if (!this.yAxisValue) {
      return '77rem'
    }
    if (this.yAxisValue <= 3) {
      return '74.4rem'
    }
    const digit = `${this.yAxisValue}`.length - 1
    const rangeLeft = 75.6 - 0.8 * digit
    return `${rangeLeft}rem`
  }

  getRangeLeft = () => {
    if (!this.yAxisValue) {
      return '0rem'
    }
    if (this.yAxisValue <= 3) {
      return '2.4rem'
    }
    const digit = `${this.yAxisValue}`.length - 1
    const rangeLeft = 1.4 + 0.7 * digit
    return `${rangeLeft}rem`
  }

  render () {
    const {
      assessment,
      isLoading,
      // pollValues,
      isStreaming,
      currentPlayTime,
      isVoteAble,
      videoDuration,
      emotionsData,
      totalEmotions,
      graphDuration,
      graphEmotionsData,
      floatingEmotionsList
    } = this.state
    const { t } = this.props

    console.log('state assessment', assessment)

    if (isLoading) {
      return <Spinner height='40rem' />
    }

    return (
      <Fragment>
        {!isLoading && !assessment && (
          <section className='section'>
            <Alert message={t('errors:not_found_assessment')} type='error' />
          </section>
        )}
        {assessment !== null && assessment.hasOwnProperty('id') && (
          <div className='graph__content'>
            <div className='graph__stream'>
              {isStreaming ? (
                <div className='graph-stream'>
                  <div className='graph-stream__video' id='stream' />

                  {floatingEmotionsList && (
                    <FloatingContainer>
                      {floatingEmotionsList.map((item, emoIndex) => {
                        return (
                          <EmotionRnd
                            position={item.position}
                            speed={item.speed}
                            key={emoIndex + 2}
                            beforeRotate={item.beforeRotate}
                            afterRotate={item.afterRotate}
                          >
                            <img
                              src={
                                '/images/icons/emotions/color/' +
                                this.getType(item.content) +
                                '.svg'
                              }
                              alt={item.content}
                            />
                          </EmotionRnd>
                        )
                      })}
                    </FloatingContainer>
                  )}
                  {/* <div className='graph-stream__badge'>Live</div> */}
                </div>
              ) : (
                <div className='graph-stream'>
                  {/* if past live event have streaming videos will display instead of thumnail image */}
                  {this.checkPastVideo(assessment) && (
                    <ReactPlayer
                      ref={rp => {
                        this.setPlayerRef(rp)
                      }}
                      url={
                        assessment.video_links
                          ? _.head(assessment.video_links).link
                          : ''
                      }
                      // url='https://www.youtube.com/watch?v=LNKdQyXhvh0'
                      onDuration={e => {
                        this.handleVideoDuration(e)
                      }}
                      onProgress={e => {
                        this.handleVideoProgress(e)
                      }}
                      onEnded={() => {
                        this.handlSeekOrEnd(true)
                      }}
                      onSeek={() => {
                        this.handlSeekOrEnd(false)
                      }}
                      youtubeConfig={{ playerVars: { showinfo: 1 } }}
                      controls
                    />
                  )}
                  {!this.checkPastVideo(assessment) && (
                    <div
                      className='graph-stream__cover'
                      style={{
                        backgroundImage: `url(${assessment.cover.link})`
                      }}
                    >
                      {assessment.start_date_time && (
                        <div className='__schedule'>
                          {this.displaySchedule(assessment)}
                        </div>
                      )}
                      {this.displayCountdownTime(assessment) && (
                        <div className='__count_down'>
                          {assessment.is_finished &&
                          t('assessments:finished')}
                          {!assessment.is_finished &&
                          t('assessments:countdown')}
                          {!assessment.is_finished && (
                            <Countdown
                              value={assessment.start_date_time}
                              style={{ marginLeft: '0.5rem' }}
                              valueStyle={{
                                color: '#0068FF',
                                fontSize: '1.8rem'
                              }}
                              onFinish={this.onCountDownFinish}
                            />
                          )}
                          <img alt=""
                            className='icon_message'
                            src={'/images/icons/menu/info-message.svg'}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className='graph__head'>
              {/*<div className='graph__wrap_header'>
                <div className='graph__heading'>{assessment.title}</div>
              </div>*/}
              {/* <div className='graph__users'>
                        <div className='graph-users'>
                          <div className='graph-users__icon'><Icon type='user' /></div>
                          <div className='graph-users__text'>134 Watching Now</div>
                        </div>
                      </div> */}

              <Emotions
                onClick={this.sendEmotion}
                translate={t}
                emotionsData={emotionsData}
                totalEmotions={totalEmotions}
                isVoteAble={isVoteAble}
              />

              {assessment.is_graph && this.checkPastVideo(assessment) && (
                <div className='wrap_video_graph'>
                  {
                    <RangeSliderContainer
                      width={this.getRangeWidth()}
                      left={this.getRangeLeft()}
                    >
                      <InputRange
                        maxValue={videoDuration || 1}
                        minValue={0}
                        value={currentPlayTime}
                        formatLabel={value => this.timeConvert(value)}
                        disabled
                        draggableTrack={false}
                        onChange={value => {
                          this.setState({
                            currentPlayTime: value
                          })
                          this.playerRef.seekTo(value)
                        }}
                      />
                    </RangeSliderContainer>
                  }
                  <ReactEcharts
                    ref={e => {
                      this.echartsReactRef = e
                    }}
                    option={options(
                      graphDuration,
                      this,
                      graphEmotionsData
                    )}
                    onEvents={onEvents}
                    style={reactChartStyles}
                  />
                  <div style={legendStyles}>
                    {this.legendColor.map((color, index) => (
                      <div
                        key={index}
                        className='legendColor'
                        style={{
                          backgroundColor: `${color}`
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default withTranslation('stream')(View)
