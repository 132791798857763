import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { List, Gallery } from './internal'

const Library = () => (
  <Switch>
    <Route
      path={['/knowledge-center/library/list', '/library/list']}
      render={() => <List />}
    />
    <Route
      path={['/knowledge-center/library/gallery/:id', '/library/gallery/:id']}
      render={() => <Gallery />}
    />
  </Switch>
)

export default Library
