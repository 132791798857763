import moment from 'moment'
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'

function TableInfoColumn(props) {
  const { record = {}, t } = props
  const {
    title,
    host_name = '',
    organiser = '',
    views = 0,
    created_at,
    host = {}
  } = record

  const hostName = useMemo(() => {
    if (organiser) return organiser
    if (host) {
      let name = host.first_name
        ? host.first_name + ' ' + host.last_name
        : host.username
      return name
    }
    if (host_name) return host_name

    return 'Username Not Set'
  }, [])

  return (
    <div className="user-table-info">
      <span className="user-table-info-title">{title}</span>
      <span className="user-table-info-host">By {hostName}</span>
      {Object(record).hasOwnProperty('views') && (
        <span className="user-table-info-views">
          {Number(views)} {t('v2:views')}
        </span>
      )}
      <span className="user-table-info-created">
        Created {moment(created_at).fromNow()}
      </span>
    </div>
  )
}

export default withTranslation()(TableInfoColumn)
