import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import './styles.scss'
import colors from '../colors.json'
import noData from '../noData.png'
import { Col, Row } from 'antd'

const CategoryGraph = ({ graphData }) => {
  const [chartData, setChartData] = useState({
    success: [],
    failed: [],
    name: [],
    maxValue: 0
  })
  const [isEmpty, setEmpty] = useState(false)
  useEffect(() => {
    if (graphData && graphData.total.length !== 0) {
      let successData = []
      let failedData = []
      let nameData = []
      let maxData = 0
      graphData.total.forEach(el => {
        successData.push(el.successAttempts)
        failedData.push(el.failedAttempts)
        nameData.push(el.name)
        el.totalAttempts >= maxData && (maxData = el.totalAttempts)
      })
      if (maxData < 5) {
        maxData = 5
      } else if (maxData < 10 && maxData > 5) {
        maxData = 10
      } else if (maxData < 15 && maxData > 10) {
        maxData = 15
      }

      maxData = parseInt(maxData + maxData * 0.1)
      setChartData({
        success: successData,
        failed: failedData,
        name: nameData,
        maxValue: maxData
      })
      setEmpty(false)
    } else {
      setEmpty(true)
    }
  }, [graphData])

  return (
    <div className="overview-graph-category">
      {isEmpty ? (
        <Row type="flex" justify="center" align="middle">
          <Col style={{ textAlign: 'center' }}>
            <img src={noData} alt="noData" height={200} />
          </Col>
        </Row>
      ) : (
        <ReactEcharts
          option={{
            // title: {
            //   text: 'ShareSphere Category Overview',
            //   subtext: 'Total Number of Attempts'
            // },
            legend: {
              data: chartData.name,
              icon: 'square',
              top: 'bottom'
            },
            // grid: {
            //   left: '3%',
            //   right: '4%',
            //   bottom: '8%',
            //   containLabel: true
            // },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },

            xAxis: {
              name: 'Category', // Add this line for x-axis label
              nameLocation: 'middle',
              nameGap: 30,
              type: 'category',
              data: chartData.name
            },
            yAxis: {
              name: 'Total Number of Attempts',
              nameLocation: 'middle',
              type: 'value',
              nameGap: 30,
              min: 0,
              max: chartData.maxValue
            },
            series: [
              {
                name: 'Success',
                type: 'bar',
                stack: 'total',
                data: chartData.success,
                itemStyle: {
                  color: function(params) {
                    var colorList = colors
                    return colorList[params.dataIndex]
                  }
                }
              },
              {
                name: 'Failed',
                type: 'bar',
                stack: 'total',
                data: chartData.failed,
                itemStyle: {
                  color: function(params) {
                    var colorList = colors
                    return colorList[params.dataIndex] + 40
                  }
                }
              }
            ]
          }}
          style={{ width: '100%' }}
        />
      )}
    </div>
  )
}

export default CategoryGraph
