const transcribeKeys = [
  { name: "Afrikaans (South Africa)", code : "af-ZA" },
  { name: "Albanian (Albania)", code : "sq-AL" },
  { name: "Amharic (Ethiopia)", code : "am-ET" },
  { name: "Arabic (Algeria)", code : "ar-DZ" },
  { name: "Arabic (Bahrain)", code : "ar-BH" },
  { name: "Arabic (Egypt)", code : "ar-EG" },
  { name: "Arabic (Iraq)", code : "ar-IQ" },
  { name: "Arabic (Israel)", code : "ar-IL" },
  { name: "Arabic (Jordan)", code : "ar-JO" },
  { name: "Arabic (Kuwait)", code : "ar-KW" },
  { name: "Arabic (Lebanon)", code : "ar-LB" },
  { name: "Arabic (Morocco)", code : "ar-MA" },
  { name: "Arabic (Oman)", code : "ar-OM" },
  { name: "Arabic (Qatar)", code : "ar-QA" },
  { name: "Arabic (Saudi Arabia)", code : "ar-SA" },
  { name: "Arabic (State of Palestine)", code : "ar-PS" },
  { name: "Arabic (Tunisia)", code : "ar-TN" },
  { name: "Arabic (United Arab Emirates)", code : "ar-AE" },
  { name: "Arabic (Yemen)", code : "ar-YE" },
  { name: "Armenian (Armenia)", code : "hy-AM" },
  { name: "Azerbaijani (Azerbaijan)", code : "az-AZ" },
  { name: "Basque (Spain)", code : "eu-ES" },
  { name: "Bengali (Bangladesh)", code : "bn-BD" },
  { name: "Bengali (India)", code : "bn-IN" },
  { name: "Bosnian (Bosnia and Herzegovina)", code : "bs-BA" },
  { name: "Bulgarian (Bulgaria)", code : "bg-BG" },
  { name: "Burmese (Myanmar)", code : "my-MM" },
  { name: "Catalan (Spain)", code : "ca-ES" },
  { name: "Chinese, Cantonese (Traditional Hong Kong)", code : "yue-Hant-HK" },
  { name: "Chinese, Mandarin (Simplified, China)", code : "zh (cmn-Hans-CN)" },
  { name: "Chinese, Mandarin (Traditional, Taiwan)", code : "zh-TW (cmn-Hant-TW)" },
  { name: "Croatian (Croatia)", code : "hr-HR" },
  { name: "Czech (Czech Republic)", code : "cs-CZ" },
  { name: "Danish (Denmark)", code : "da-DK" },
  { name: "Dutch (Belgium)", code : "nl-BE" },
  { name: "Dutch (Netherlands)", code : "nl-NL" },
  { name: "English (Australia)", code : "en-AU" },
  { name: "English (Canada)", code : "en-CA" },
  { name: "English (Ghana)", code : "en-GH" },
  { name: "English (Hong Kong)", code : "en-HK" },
  { name: "English (India)", code : "en-IN" },
  { name: "English (Ireland)", code : "en-IE" },
  { name: "English (Kenya)", code : "en-KE" },
  { name: "English (New Zealand)", code : "en-NZ" },
  { name: "English (Nigeria)", code : "en-NG" },
  { name: "English (Pakistan)", code : "en-PK" },
  { name: "English (Philippines)", code : "en-PH" },
  { name: "English (Singapore)", code : "en-SG" },
  { name: "English (South Africa)", code : "en-ZA" },
  { name: "English (Tanzania)", code : "en-TZ" },
  { name: "English (United Kingdom)", code : "en-GB" },
  { name: "English (United States)", code : "en-US" },
  { name: "Estonian (Estonia)", code : "et-EE" },
  { name: "Filipino (Philippines)", code : "fil-PH" },
  { name: "Finnish (Finland)", code : "fi-FI" },
  { name: "French (Belgium)", code : "fr-BE" },
  { name: "French (Canada)", code : "fr-CA" },
  { name: "French (France)", code : "fr-FR" },
  { name: "French (Switzerland)", code : "fr-CH" },
  { name: "Galician (Spain)", code : "gl-ES" },
  { name: "Georgian (Georgia)", code : "ka-GE" },
  { name: "German (Austria)", code : "de-AT" },
  { name: "German (Germany)", code : "de-DE" },
  { name: "German (Switzerland)", code : "de-CH" },
  { name: "Greek (Greece)", code : "el-GR" },
  { name: "Gujarati (India)", code : "gu-IN" },
  { name: "Hebrew (Israel)", code : "iw-IL" },
  { name: "Hindi (India)", code : "hi-IN" },
  { name: "Hungarian (Hungary)", code : "hu-HU" },
  { name: "Icelandic (Iceland)", code : "is-IS" },
  { name: "Indonesian (Indonesia)", code : "id-ID" },
  { name: "Italian (Italy)", code : "it-IT" },
  { name: "Italian (Switzerland)", code : "it-CH" },
  { name: "Japanese (Japan)", code : "ja-JP" },
  { name: "Javanese (Indonesia)", code : "jv-ID" },
  { name: "Kannada (India)", code : "kn-IN" },
  { name: "Kazakh (Kazakhstan)", code : "kk-KZ" },
  { name: "Khmer (Cambodia)", code : "km-KH" },
  { name: "Korean (South Korea)", code : "ko-KR" },
  { name: "Lao (Laos)", code : "lo-LA" },
  { name: "Latvian (Latvia)", code : "lv-LV" },
  { name: "Lithuanian (Lithuania)", code : "lt-LT" },
  { name: "Macedonian (North Macedonia)", code : "mk-MK" },
  { name: "Malay (Malaysia)", code : "ms-MY" },
  { name: "Malayalam (India)", code : "ml-IN" },
  { name: "Marathi (India)", code : "mr-IN" },
  { name: "Mongolian (Mongolia)", code : "mn-MN" },
  { name: "Nepali (Nepal)", code : "ne-NP" },
  { name: "Norwegian Bokmål (Norway)", code : "no-NO" },
  { name: "Persian (Iran)", code : "fa-IR" },
  { name: "Polish (Poland)", code : "pl-PL" },
  { name: "Portuguese (Brazil)", code : "pt-BR" },
  { name: "Portuguese (Portugal)", code : "pt-PT" },
  { name: "Punjabi (Gurmukhi India)", code : "pa-Guru-IN" },
  { name: "Romanian (Romania)", code : "ro-RO" },
  { name: "Russian (Russia)", code : "ru-RU" },
  { name: "Serbian (Serbia)", code : "sr-RS" },
  { name: "Sinhala (Sri Lanka)", code : "si-LK" },
  { name: "Slovak (Slovakia)", code : "sk-SK" },
  { name: "Slovenian (Slovenia)", code : "sl-SI" },
  { name: "Spanish (Argentina)", code : "es-AR" },
  { name: "Spanish (Bolivia)", code : "es-BO" },
  { name: "Spanish (Chile)", code : "es-CL" },
  { name: "Spanish (Colombia)", code : "es-CO" },
  { name: "Spanish (Costa Rica)", code : "es-CR" },
  { name: "Spanish (Dominican Republic)", code : "es-DO" },
  { name: "Spanish (Ecuador)", code : "es-EC" },
  { name: "Spanish (El Salvador)", code : "es-SV" },
  { name: "Spanish (Guatemala)", code : "es-GT" },
  { name: "Spanish (Honduras)", code : "es-HN" },
  { name: "Spanish (Mexico)", code : "es-MX" },
  { name: "Spanish (Nicaragua)", code : "es-NI" },
  { name: "Spanish (Panama)", code : "es-PA" },
  { name: "Spanish (Paraguay)", code : "es-PY" },
  { name: "Spanish (Peru)", code : "es-PE" },
  { name: "Spanish (Puerto Rico)", code : "es-PR" },
  { name: "Spanish (Spain)", code : "es-ES" },
  { name: "Spanish (United States)", code : "es-US" },
  { name: "Spanish (Uruguay)", code : "es-UY" },
  { name: "Spanish (Venezuela)", code : "es-VE" },
  { name: "Sundanese (Indonesia)", code : "su-ID" },
  { name: "Swahili (Kenya)", code : "sw-KE" },
  { name: "Swahili (Tanzania)", code : "sw-TZ" },
  { name: "Swedish (Sweden)", code : "sv-SE" },
  { name: "Tamil (India)", code : "ta-IN" },
  { name: "Tamil (Malaysia)", code : "ta-MY" },
  { name: "Tamil (Singapore)", code : "ta-SG" },
  { name: "Tamil (Sri Lanka)", code : "ta-LK" },
  { name: "Telugu (India)", code : "te-IN" },
  { name: "Thai (Thailand)", code : "th-TH" },
  { name: "Turkish (Turkey)", code : "tr-TR" },
  { name: "Ukrainian (Ukraine)", code : "uk-UA" },
  { name: "Urdu (India)", code : "ur-IN" },
  { name: "Urdu (Pakistan)", code : "ur-PK" },
  { name: "Uzbek (Uzbekistan)", code : "uz-UZ" },
  { name: "Vietnamese (Vietnam)", code : "vi-VN" },
  { name: "Zulu (South Africa)", code : "zu-ZA" },
]

export default transcribeKeys
