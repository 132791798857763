import styled from 'styled-components'
import { media } from '../../../styles'
import { styleguide } from '../../../constants'
import ReactSVG from 'react-svg'
const { typography } = styleguide
const { colors } = styleguide
const colorBorder = '#ddd'

export const Container = styled.div`
  width: 100%;
  padding: 3rem 0;

  ${media.md`
    padding: 1rem 0;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .search {
    span {
      input {
        width: 300px;
      }
    }
  }
`

export const HelpMessage = styled.p`
  font-weight: 300;
  font-size: ${typography.h2FontSize};
  font-style: italic;
  text-align: center;
`


export const Course = styled.div`
  min-height: 144px;
  border: 0.1rem solid ${colorBorder};
  background:#fff;
  display: flex;
  padding: 1.3rem;
  margin-top: -0.1rem;
  cursor: grab;
`

Course.Cover = styled.div`
  width: 20rem;
  min-width: 20rem;
  height: auto;
  background: url(${props => props.src}) center no-repeat;
  background-size: cover;
  display: block;
`
Course.Remove = styled.div`
  margin: 5px;
  background: #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

Course.Details = styled.div`
  width: 100%;
  border-left: 0.1rem solid ${colorBorder};
  margin-left: 1.3rem;
  padding: 0 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
Course.Details.Top = styled.div``

Course.Details.Bottom = styled.div``

Course.Title = styled.div`
  font-weight: 700;
  color:#0068FF;
`

Course.Lessons = styled.span`
  width: 100%;
  display: inline-block;
`
Course.DateCreated = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
`

Course.Type = styled.div`
  margin-bottom: 1.5rem;
`