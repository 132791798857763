import axios from 'axios'

import { log, token, connection } from './interceptors'

import { config } from '../../../constants'

const instance = axios.create({
  baseURL: config.baseURL,
  headers: {},
  data: {},
  timeout: 180000
})
const httpCustomInstance = axios.create({
  baseURL: process.env.REACT_APP_MODAL_ORG_API,
  headers: {},
  data: {},
  timeout: 180000
})

const httpCustomAudioInstance = axios.create({
  baseURL: process.env.REACT_APP_AUDIO_API,
  headers: {},
  data: {}
})

const httpCustomSummarizedInstance = axios.create({
  baseURL: process.env.REACT_APP_SUMMARIZATION,
  headers: {},
  data: {}
})

const httpShareBotInstance = axios.create({
  baseURL: process.env.REACT_APP_SHAREBOT_API,
  headers: {},
  data: {}
})

const questionAnswerAiInstance = axios.create({
  baseURL: config.questionAnswerAiUrl,
  headers: {},
  data: {}
})

const aiCourseCreationInstance = axios.create({
  baseURL: process.env.REACT_APP_AI_COURSE_CREATION_URL,
  headers: {},
  data: {}
})

token(instance)
log(instance)
connection(instance)

token(httpCustomInstance)
log(httpCustomInstance)
connection(httpCustomInstance)

token(httpCustomAudioInstance)
log(httpCustomAudioInstance)
connection(httpCustomAudioInstance)

token(httpCustomSummarizedInstance)
log(httpCustomSummarizedInstance)
connection(httpCustomSummarizedInstance)

token(httpShareBotInstance)
log(httpShareBotInstance)
connection(httpShareBotInstance)

token(questionAnswerAiInstance)
log(questionAnswerAiInstance)
connection(questionAnswerAiInstance)

token(aiCourseCreationInstance)
log(aiCourseCreationInstance)
connection(aiCourseCreationInstance)

export {
  httpCustomInstance,
  httpCustomAudioInstance,
  httpCustomSummarizedInstance,
  httpShareBotInstance,
  questionAnswerAiInstance,
  aiCourseCreationInstance
}

export default instance
