import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Collapse, Tabs } from 'antd'

import { styleguide } from '../../../../../constants'

const { TabPane } = Tabs
const { colors } = styleguide

const Accordion = styled(Collapse)`
  && {
    & > .ant-collapse-item {
      border: 0;
      margin-bottom: .5rem;

      & > .ant-collapse-content {
        & > .ant-collapse-content-box {
          padding: 0 3rem 1.5rem 2.3rem;
        }
      }

      &.ant-collapse-no-arrow {
        & > .ant-collapse-header {
          padding-left: 2.3rem;
        }
      }

      & > .ant-collapse-header {
        background-color: #ddd;
        padding: 1.6rem 2.3rem;
      }

      &.ant-collapse-item-active {
        & > .ant-collapse-header {
          background-color: ${colors.white};
        }
      }
    }
  }
`

Accordion.Panel = styled(Collapse.Panel)`
`

Accordion.Title = styled.div`
  color: ${colors.blue};
  font-weight: 600;
`

Accordion.Lessons = styled.div`
  color: ${colors.darkGreyOne};
`

Accordion.IconEvent = styled.span`
  width: 1.6rem;
  display: block;
  position: absolute;
  top: 50%;
  right: 2.2rem;
  transform: translateY(-50%);

  &:after,
  &:before {
    content: '';
    width: 100%;
    height: .2rem;
    background-color: ${colors.darkGreyOne};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  &:before {
    transform: translateY(-50%) rotate(90deg);
  }

  .ant-collapse-item-active & {
    &:before {
      display: none;
    }
  }
`

const TabsCategories = styled(Tabs)`
  && {
    .ant-tabs-bar {
      border-top: .1rem solid #ddd;
      border-bottom: 0;
    }

    .ant-tabs-tab {
      padding: 1.3rem .5rem;
      margin-right: 3rem;
      color: ${colors.darkGreyOne};
      font-weight: 500;

      &.ant-tabs-tab-active {
        font-weight: 600;
      }
    }

    .ant-tabs-ink-bar {
      top: 0;
      bottom: initial;
    }
  }
`

TabsCategories.Tab = styled(TabPane)`
`

TabsCategories.Tab.Details = styled(NavLink)`
  float: right;
  font-weight: 600;
  margin-top: 1rem;
`

const TabsCharts = styled(Tabs)`
  && {
    .ant-tabs-bar {
      border: 0;
      margin-bottom: 0;
    }

    .ant-tabs-content {
      border: .1rem solid #ddd;
    }

    &.ant-tabs-card {
      .ant-tabs-card-bar {
        .ant-tabs-nav-container {
          height: auto;
        }

        .ant-tabs-tab {
          min-width: 10rem;
          line-height: 1;
          background-color: #ddd;
          border: .1rem solid #ddd;
          padding: 1rem 1.5rem;
          min-height: 50px;

          &.ant-tabs-tab-active {
            background-color: ${colors.white};
          }
        }
      }
    }
  }
`

TabsCharts.Tab = styled(TabPane)`
`

TabsCharts.Tab.Title = styled.div`
  color: ${colors.darkGreyOne};
  font-size: 1.2rem;
  text-align: center;

  .ant-tabs-tab-active & {
    color: ${colors.blue};
  }
`

TabsCharts.Tab.Title.Count = styled.div`
  font-size: 1.8rem;
  font-weight: 400;

  .ant-tabs-tab-active & {
    color: ${colors.blue};
    font-weight: 600;
  }
`

export {
  Accordion,
  TabsCategories,
  TabsCharts
}
