import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { RenderArrows } from '../../../../../components'

import { SlPagination } from './styled'

class LessonsPagination extends Component {
  changePage = page => {
    const {
      lessonBuilderActions: { handleLessonInput },
      history,
      rootPath,
      uploadFetching
    } = this.props

    if (uploadFetching) {
      return
    }

    handleLessonInput('currentPage', page)
    history.push(`${rootPath}/page-constructor`)
  }

  render() {
    const { pages, currentPage } = this.props.lesson

    return (
      <SlPagination
        total={pages.length}
        defaultPageSize={1}
        onChange={this.changePage}
        itemRender={RenderArrows}
        current={currentPage}
      />
    )
  }
}

export default withRouter(LessonsPagination)
