import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'antd'
import { Form } from './components'
import { Auth } from '../../../styles'
import { withTranslation } from 'react-i18next'
import { Logo } from '../../../components'
import { config } from '../../../constants/index'
import AuthContainer from '../components/AuthContainer'

class SignIn extends Component {
  render() {
    const { t, location } = this.props

    const isMarketplace = location.search.includes('marketplace=true')

    return (
      <AuthContainer>
        <Auth>
          <Auth.Wrapper>
            <Auth.Inner>
              <Auth.Head>
                <Logo color="blue" />
              </Auth.Head>

              <Auth.Content>
                <Auth.Form>
                  <Form isMarketplace={isMarketplace} />
                </Auth.Form>

                <Link to="/auth/reset">{t('labels:forgot')}</Link>
              </Auth.Content>
            </Auth.Inner>
            <Auth.Footer align="center">
              <a href={config.marketingURL}>
                <Icon type="arrow-left" />
                &nbsp;{t('labels:Home')}
              </a>
              <Link to="/auth/signup">
                {t('labels:signup')}&nbsp;
                <Icon type="arrow-right" />
              </Link>
            </Auth.Footer>
          </Auth.Wrapper>
        </Auth>
      </AuthContainer>
    )
  }
}

export default withTranslation()(SignIn)
