import React, { useState, useMemo } from 'react'

import { Divider, Pagination } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'
import moment from 'moment'
import { Search } from '../../../../../../../components'

import TableCoverImage from './internal/TableCoverImage'

const UserSkillJourneyTable = props => {
  const {
    t,
    data: {
      skills: { data, loading }
    },
    isUserAsHost: isHost
  } = props

  const participantSkillJourneyColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      { dataIndex: 'title', textWrap: 'word-break', width: 200 },

      {
        title: t('reports:created_date'),
        dataIndex: 'created_at',
        render: x => moment(x).format('ll')
      },
      {
        title: t('reports:status'),
        dataIndex: 'status',
        render: status => {
          let color = status === 'fail' ? '#BC0000' : '#20AA26'
          return <p style={{ color: color }}>{status ? status : '-'}</p>
        }
      },

      { title: t('v4:pass_score'), dataIndex: 'pass_score' },
      {
        title: t('reports:score'),
        dataIndex: 'scores',
        render: score => score || 0
      },
      {
        title: t('v2:time_spent'),
        dataIndex: 'spend_time',
        render: duration =>
          moment.duration(duration, 'minutes').format('m[min] s[s]')
      }
    ],
    []
  )

  const hostSkillJourneyColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      { dataIndex: 'title', textWrap: 'word-break', width: 200 },
      {
        title: t('reports:created_date'),
        dataIndex: 'created_at',
        render: x => moment(x).format('ll')
      },
      { title: t('reports:participants'), dataIndex: 'participants' },
      { title: t('v4:minimum_pass_score'), dataIndex: 'pass_score' },
      {
        title: t('v4:passed'),
        dataIndex: 'passed',
        render: passed => passed || '0'
      },
      {
        title: t('v4:failed'),
        dataIndex: 'failed',
        render: failed => failed || '0'
      }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(data.length)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const skills = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  console.log('check data log', total)

  return (
    <ReportTable
      isDateActive
      borderRadius
      columns={
        isHost ? hostSkillJourneyColumns : participantSkillJourneyColumns
      }
      loading={loading}
      dataSource={skills}
      pagination={false}
      title={() => (
        <>
          <div className="user-report-table-title">
            <div>
              <SearchEventMargin>
                <Search
                  placeholder={t('placeholders:search')}
                  onChange={e => onSearch(e.target.value)}
                />
              </SearchEventMargin>
            </div>
            <div className="user-report-table-pagination">
              <span>{t('general:page').toUpperCase()}</span>
              <PaginationRightAligned>
                <Pagination
                  total={total}
                  simple
                  current={currentPage}
                  pageSize={10}
                  onChange={onPaginationChange}
                />
              </PaginationRightAligned>
            </div>
          </div>
          <Divider style={{ margin: '0px' }} />
        </>
      )}
    />
  )
}

export default UserSkillJourneyTable
