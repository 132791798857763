/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-08-07 15:55:38
 * @LastEditors: your name
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PageMaker from './PageMaker'
import actions from '../../../../../../../store/actions'

const mapStateToProps = state => ({
  lesson: state.lessonBuilder,
  courseId: state.courseBuilder.courseId,
  uploadFetching: state.library.uploadFetching,
  library: state.library,
  audioSynthesizer: state.audioSynthesizer,
  pageBuilder: state.pageBuilder
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLibraryList: actions.library.getLibraryList,
      getPublicList: actions.library.getPublicList,
      getOrgList: actions.library.getOrgList,
      handleLessonInput: actions.lessonBuilder.handleLessonInput,
      updateLesson: actions.lessonBuilder.updateLesson,
      upload: actions.library.upload,
      clearUploadList: actions.library.clearUploadList,
      handleFileDropIn: actions.lessonBuilder.handleFileDropIn,
      showEditAudioModal: actions.audioSynthesizer.showEditAudioModal,
      showEditAudioDetailModal:
      actions.audioSynthesizer.showEditAudioDetailModal,

      showEditTextModal: actions.pageBuilder.showEditTextModal,
      showEditImageModal: actions.pageBuilder.showEditImageModal,
      showEditVideoModal: actions.pageBuilder.showEditVideoModal,
      showAnnotationImageModal: actions.pageBuilder.showAnnotationImageModal,
      showAnnotationTextModal: actions.pageBuilder.showAnnotationTextModal,
      showAnnotationVideoModal: actions.pageBuilder.showAnnotationVideoModal,
      showAnnotationAudioModal: actions.pageBuilder.showAnnotationAudioModal,
      showAnnotationModal: actions.pageBuilder.showAnnotationModal,
      showEditQuizModal: actions.pageBuilder.showEditQuizModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(PageMaker)
