import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import ReactSVG from 'react-svg'

import { styleguide } from '../../constants'

const { colors } = styleguide

const CoursesContainer = styled.div`
  width: 100%;
`

CoursesContainer.PanelHeader = styled.div`
  background-color: #F7F7F7;
  padding: 3.5rem;
`

CoursesContainer.PanelBody = styled.div`
  padding: 3.5rem;
`

CoursesContainer.ListCreate = styled.div`
  display: flex;
`

CoursesContainer.LinkCreate = styled(NavLink)`
  border: 1px dashed ${colors.darkGreyTwo};
  color: ${colors.darkGreyOne};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.3rem 5rem;
  margin-right: 1.7rem;
  transition: border-color .25s, color .25s;

  &:hover {
    border-color: ${colors.blue};
  }
`

CoursesContainer.IconCreate = styled(ReactSVG)`
  width: 3rem;
  height: 3rem;
  line-height: 3.2rem;
  background-color: ${colors.blue};
  border-radius: 50%;
  text-align: center;
  margin-right: 1rem;

  svg {
    width: 1.4rem;

    path {
      stroke: ${colors.white};
      fill: ${colors.white};
    }
  }
`

export {
  CoursesContainer
}
