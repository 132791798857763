import http from './http'

function getRatings(id, type) {
  return http({
    method: 'GET',
    url: `/ratings`,
    params: {
      type,
      id
    }
  })
}

function addRating(id, type, data) {
  return http({
    method: 'POST',
    url: `/rating`,
    data,
    params: {
      type,
      id
    }
  })
}

function deleteById(ratingid) {
  return http({
    method: 'DELETE',
    url: `/rating/${ratingid}`
  })
}

function editById(ratingid, data) {
  return http({
    method: 'PUT',
    url: `/rating/${ratingid}`,
    data
  })
}

export { getRatings, addRating, deleteById, editById }
