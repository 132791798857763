import styled, { css } from 'styled-components'
import { styleguide } from '../constants'
import { Auth } from './blocks'
import { Tabs } from 'antd'

const { colors, base } = styleguide

/**
 * Media
 */

const mediaService = sizes =>
  Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `

    return acc
  }, {})

const media = mediaService(styleguide.sizes)

/**
 * Tabs
 */

const Tab = styled(Tabs)`
  width: 100%;
  flex-basis: 100%;

  && {
    .ant-tabs-nav {
      ${props =>
        props.topBorder &&
        css`
          border-top: 1px solid ${colors.darkGreyThree};
        `}
    }

    .ant-tabs-bar {
      border-bottom: 0;
      margin-bottom: 0.8rem;
    }

    .ant-tabs-tab {
      padding: 2rem;
      margin: 0;
      color: ${base.fontColor};
      font-weight: ${base.fontWeight};
      font-size: ${base.fontSize};

      &.ant-tabs-tab-active {
        font-weight: bold;
      }
    }

    .ant-tabs-ink-bar {
      bottom: unset;
      top: 0;
      transform: translateX(-50%);
    }
  }
`

Tab.Panel = styled(Tabs.TabPane)``

/**
 * Layout Components
 */

const Wrapper = styled.div``

const Main = styled.main`
  width: 100%;
  position: relative;
  // padding-top: 7.2rem;
  flex: 1;
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`

const SidebarExpandable = styled.aside`
  width: 7rem;
  position: fixed;
  // top: 7.2rem;
  top: 0;
  left: 0;
  bottom: 0;
  flex-shrink: 0;
  border-right: 0.1rem solid ${colors.darkGreyThree};

  transition: width 0.5s;
  z-index: 100;
  background: ${colors.black};
  transition: width 0.25s, box-shadow 0.25s;
  transition-delay: 0.2s;
  ${props => props.backgroundColor && `background: ${props.backgroundColor};`}

  // &:hover {
  //   width: 24rem;
  //   transition-delay: 0s;

  //   box-shadow: 0.2rem 0 0.4rem rgba(0, 0, 0, 0.05);
  // }

  ${props =>
    props.opened &&
    `
    width: 24rem;
  `}
`

const Sidebar = styled.aside`
  color: ${colors.black};
  width: 30rem;
  overflow: hidden;
  position: fixed;
  // top: 7.2rem;
  top: 0;
  left: 0;
  bottom: 0;
  flex-shrink: 0;
  border-right: 0.1rem solid ${colors.darkGreyThree};
  background: ${colors.white};
  transition: width 0.25s, box-shadow 0.25s;
  transition-delay: 0.2s;
  z-index: 100;
  padding: 1.5rem;
  overflow: scroll;

  // &:hover {
  //   transition-delay: 0s;

  //   box-shadow: 0.2rem 0 0.4rem rgba(0, 0, 0, 0.05);
  // }

  .p-breadcrumbs {
    font-weight: 700;
    position: fixed;
    bottom: 0;
    background: ${colors.darkGreyThree};
    width: 30rem;
    left: 0;
  }
  .help-center-title {
    color: #333333;
    font-weight: 500;
    font-size: 20px;
  }

  li:last-of-type {
    margin-bottom: 40px;
  }
`

const Section = styled.section`
  padding: 3.2rem;

  ${props =>
    props.type &&
    `
    background: ${colors[props.type]};
  `}

  ${props =>
    props.divider &&
    `
    border-top: 1px solid #ececec;
  `}
`

const Container = styled.div`
  width: 100%;
  max-width: 86rem;
  margin: 0 auto;
  padding: 0 1.6rem;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -1.6rem;
  margin-right: -1.6rem;
`

const GridAuto = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(${props => props.width}rem, 1fr)
  );
  grid-gap: 1.6rem;

  ${props =>
    props.horizontal &&
    `
    display: flex;
  `}
`

const Col = styled.div`
  width: 100%;
  padding: 0 1.6rem;

  ${props =>
    props.sm &&
    `
    @media screen and (min-width: 576px) {
      width: ${(100 / 12) * props.sm}%;
    }
  `}

  ${props =>
    props.smOffset &&
    `
    @media screen and (min-width: 576px) {
      margin-left: ${(100 / 12) * props.smOffset}%;
    }
  `}

  ${props =>
    props.md &&
    `
    @media screen and (min-width: 768px) {
      width: ${(100 / 12) * props.md}%;
    }
  `}

  ${props =>
    props.mdOffset &&
    `
    @media screen and (min-width: 768px) {
      margin-left: ${(100 / 12) * props.mdOffset}%;
    }
  `}

  ${props =>
    props.lg &&
    `
    @media screen and (min-width: 991px) {
      width: ${(100 / 12) * props.lg}%;
    }
  `}

  ${props =>
    props.lgOffset &&
    `
    @media screen and (min-width: 991px) {
      margin-left: ${(100 / 12) * props.lgOffset}%;
    }
  `}

  ${props =>
    props.xl &&
    `
    @media screen and (min-width: 1200px) {
      width: ${(100 / 12) * props.xl}%;
    }
  `}

  ${props =>
    props.xlOffset &&
    `
    @media screen and (min-width: 1200px) {
      margin-left: ${(100 / 12) * props.xlOffset}%;
    }
  `}

  ${props =>
    props.xxl &&
    `
    @media screen and (min-width: 1600px) {
      width: ${(100 / 12) * props.xxl}%;
    }
  `}

  ${props =>
    props.xxlOffset &&
    `
    @media screen and (min-width: 1600px) {
      margin-left: ${(100 / 12) * props.xxlOffset}%;
    }
  `}

  ${props =>
    props.align &&
    `
    text-align: ${props.align};
  `}

`

const Head = styled.div`
  padding-bottom: 2.4rem;

  ${props =>
    props.nopadding &&
    `
    padding: 0;
  `}

  h2 {
    font-weight: 300;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
  }

  h3 {
    font-weight: 300;
  }
`

const Field = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }
`

Field.Label = styled.div`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.6rem;
  color: #212121;
`

Field.Control = styled.div``

export {
  Wrapper,
  Content,
  Main,
  SidebarExpandable,
  Sidebar,
  Section,
  GridAuto,
  Container,
  Row,
  Col,
  Head,
  media,
  Auth,
  Field,
  Tab
}
