/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-07 15:55:38
 * @LastEditTime: 2019-09-19 10:44:42
 * @LastEditors: Please set LastEditors
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../store/actions'
import Preview from './Preview'

const { viewEvent, setCountdownTimer } = actions.events
const { clearNotes } = actions.notes
const { getDiscussionLog, clearDiscussion } = actions.discussion
const { updateUser } = actions.user
const mapStateToProps = state => ({
  user: state.user,
  log: state.discussion.log,
  organizations: state.organizations.list
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      viewEvent,
      setCountdownTimer,
      clearNotes,
      getDiscussionLog,
      clearDiscussion,
      updateUser
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Preview)
