import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Icon, Rate, Spin, Tooltip } from 'antd'

import { ITEMS } from '../../viewChannelHelper'
import { useFetchReviews } from '../../../../../components/Reviews/Reviews.jsx'
import DiscussionIcon from '../../../../../assets/discussion.svg'

import './ViewChannelHeader.scss'

const ViewChannelHeader = props => {
  const {
    title = '',
    onHeaderItemClick,
    channelId,
    showHeaderItems: { notes, discussion },
    t
  } = props

  if (channelId) {
    useFetchReviews(channelId, 'channel')
  }
  return (
    <div className="preview__header channel_header">
      <div className="title">
        <Tooltip title={title}>{title}</Tooltip>
      </div>

      <ReviewStatus t={t} onHeaderItemClick={onHeaderItemClick} />
      <div className="menus">
        <div
          className={`menu_item ${notes ? 'active_menu_item' : null}`}
          onClick={() => onHeaderItemClick(ITEMS.NOTES)}
        >
          <Icon type="form" />
          <div className="name">{t('tabs:notes')}</div>
        </div>

        <div
          className={`menu_item ${discussion ? 'active_menu_item' : null}`}
          onClick={() => onHeaderItemClick(ITEMS.DISCUSSION)}
        >
          <img alt=""
            src={DiscussionIcon}
            style={{
              filter: discussion ? null : 'brightness(0) saturate(100%)'
            }}
          />
          <div className="name">{t('tabs:discussion')}</div>
        </div>
      </div>
    </div>
  )
}

const ReviewStatus = props => {
  const reviews = useSelector(state => state.reviews)

  const { reviews: allReviews = [], avg_rating, isFetching } = reviews

  const [averageRating, setAverageRating] = useState(avg_rating)

  useEffect(() => {
    const rating = allReviews.reduce((acc, item) => acc + item.rating, 0)
    setAverageRating(rating / allReviews.length || 0)
  }, [allReviews.length])

  const { t } = props
  return (
    <Spin spinning={isFetching}>
      <div className="review__container">
        <span>{t('v2:average_rating')}</span>
        <Rate disabled value={Number(averageRating)} />
        <span>{averageRating && Number(averageRating).toFixed(1)} </span>
        <span
          className="review__total"
          onClick={() => props.onHeaderItemClick(ITEMS.REVIEW)}
        >
          {t('v2:review')}({allReviews && allReviews.length})
        </span>
      </div>
    </Spin>
  )
}

export default withTranslation()(ViewChannelHeader)
