import http from './http'

function createSkillJourney(data) {
  return http({
    method: 'POST',
    url: '/skills/',
    data
  })
}

function updateSkillJourney(data, id) {
  const { isSendMail } = data
  const query = `?isSendMail=${isSendMail || false}`
  return http({
    method: 'PUT',
    url: `/skills/${id}${query}`,
    data
  })
}

function listSkillJourneys(params) {
  return http({
    method: 'GET',
    url: `/skills?mode=${params.mode}&offset=${params.offset}&limit=${params.limit}${params.search ? "&search=" + params.search : ""}`
  })
}
function getSkillList(params) {
  return http({
    method: 'GET',
    url: '/skills',
    params
  })
}
const setSequence = data => {
  return http({
    method: 'PUT',
    url: `/skills/priorities`,
    data
  })
}

function deleteSkillJourney(id) {
  return http({
    method: 'DELETE',
    url: `/skills/${id}`
  })
}

function getSkillById(id) {
  return http({
    method: 'GET',
    url: `/skills/${id}`
  })
}

function enroll(id) {
  return http({
    method: 'POST',
    url: `/skills/${id}/enroll`
  })
}

function unEnroll(id) {
  return http({
    method: 'POST',
    url: `/skills/${id}/unenroll`
  })
}

export {
  createSkillJourney,
  updateSkillJourney,
  listSkillJourneys,
  deleteSkillJourney,
  getSkillById,
  enroll,
  unEnroll,
  getSkillList,
  setSequence
}
