import classNames from 'classnames'

const checkResize = (isText, isView) => {
  if (isView) {
    return false
  } else if (isText) {
    return true
  } else {
    return false
  }
}

const customContentClass = (isText = false, isView = false) =>
  classNames({
    el__preview: true,
    el__triangle: checkResize(isText, isView)
  })

export { customContentClass }
