import {
  LIST_MAZES,
  GET_MAZE_BY_ID,
  UPDATE_MAZE,
  DELETE_MAZE,
  FETCH_MAZES,
  FETCH_MAZE_BY_ID,
  UPDATING_MAZE,
  RESET_MAZE_LIST
} from '../types'

import { api } from '../../services'

function handleMazeData(data) {
  return dispatch => {
    const { action } = data
    console.log('cloactiondata', data)

    if (action === 'programme_publish') {
      dispatch({
        type: UPDATING_MAZE,
        payload: true
      })
      return api.maze.mazeProvider(data).then(response => {
        console.log('cloresponse', response)
        // if (action === 'delete_programme') {
        //   console.log('cloactiondelete')
        //   dispatch({
        //     type: DELETE_MAZE,
        //     payload: data.params.programme_id
        //   })
        // } else

        if (action === 'programme_publish') {
          dispatch({
            type: UPDATING_MAZE,
            payload: false
          })
        }
      })
    }

    if (action === 'delete_programme') {
      console.log('cloactionwroekd')
      return api.maze.deleteById(data.params.programme_id).then(() => {
        dispatch({
          type: DELETE_MAZE,
          payload: data.params.programme_id
        })
        console.log('cloactionwroekdinside', data.params.programme_id)
        // return Promise.resolve()
      })
    }
  }
}

// const deleteMaze = id => dispatch => {
//   console.log('clodeleteMazeworked', id)
//   return api.maze.deleteById(id).then(() => {
//     dispatch({
//       type: DELETE_MAZE,
//       payload: data.params.programme_id
//     })
//     return Promise.resolve()
//   })
// }

// return api.maze.mazeProvider(data).then(response => {
//   console.log('cloresponse', response)
// if (action === 'delete_programme') {
//   console.log('cloactiondelete')
//   dispatch({
//     type: DELETE_MAZE,
//     payload: data.params.programme_id
//   })
// } else

//   if (action === 'programme_publish') {
//     dispatch({
//       type: UPDATING_MAZE,
//       payload: false
//     })
//   }
// })

const listMazes = field => dispatch => {
  dispatch({
    type: FETCH_MAZES,
    payload: true
  })
  return api.maze.listMazes(field).then(response => {
    dispatch({
      type: LIST_MAZES,
      payload: response.data
    })
    dispatch({
      type: FETCH_MAZES,
      payload: false
    })
  })
}

const getMazeById = id => dispatch => {
  dispatch({
    type: FETCH_MAZE_BY_ID,
    payload: true
  })
  return api.maze
    .getMazeById(id)
    .then(response => {
      dispatch({
        type: GET_MAZE_BY_ID,
        payload: response.data
      })
      dispatch({
        type: FETCH_MAZE_BY_ID,
        payload: false
      })
      return response.data
    })
    .catch(err => {
      dispatch({
        type: FETCH_MAZE_BY_ID,
        payload: false
      })

      throw err
    })
}

const resetMazeList = () => dispatch => {
  dispatch({
    type: RESET_MAZE_LIST
  })
}

const updateMaze = params => dispatch => {
  dispatch({
    type: UPDATING_MAZE,
    payload: true
  })

  return api.maze
    .updateMaze(params.id, params.data)
    .then(response => {
      dispatch({
        type: UPDATE_MAZE,
        payload: response.data
      })
      dispatch({
        type: UPDATING_MAZE,
        payload: false
      })
      return response.data
    })
    .catch(err =>
      dispatch({
        type: UPDATING_MAZE,
        payload: false
      })
    )
}

export { handleMazeData, listMazes, getMazeById, resetMazeList, updateMaze }
