import api from '../../../services/api';
import { config } from '../../../constants'
import axios from 'axios'
import {getSession} from '../../../services/api/user'

// const communication = {}

// let API_URL = ''
var activity = '';
var courseId = '';
var isPosting = false;

export function Communication() {
	var self = this;

	setInterval(function () { self.setDataToServer(); }, 3000);
	// self.start = function(){
	// };

	self.setActivity = function (a) {
		console.log(a)
		activity = a
	}

	self.setDataToServer = function (force) {
		if ((activity && activity.hasDirtyData && !isPosting) || force) {
			console.log("data to server")

			activity.setDirtyDataPosted()
			const cmi_core = activity.cmiCore
			const reqBody = JSON.stringify({
        cmi_core:cmi_core
      })

			console.log('reqBody in req', reqBody, courseId)
			isPosting = true

			
			getSession()
				.then(session => {
					const token = 'Bearer ' + session.idToken.jwtToken
					axios({
						method: 'put',
						url: `${config.baseURL}/scorm/lmssetruntime/course/${courseId}`,
						data: reqBody,
						headers: { 'Content-Type': 'application/json', 'Authorization': token },
					})
						.then(response => {
						console.log('response in post scorm runtime', response);
						isPosting = false
					})
						.catch(e => {
							console.log('error in post scorm', e)
							isPosting = false
						})
					return
      })
      .catch((err) => {
        console.log('error', err);
      })

			// if (courseId) {
				// api.scorm.postLMSRunTime(reqBody, courseId)
				// .then(response => {
				// 	console.log('response in post scorm runtime', response);
				// 	isPosting = false
				// })
				// 	.catch(e => {
				// 		console.log('error in post scorm', e)
				// 		isPosting = false
				// 	})
			// }
			
			// $.ajax({
			// 	type: "POST",
			// 	url: API_URL + "scorm/lmssetruntime",
			// 	data: reqBody,
			// 	dataType: "json",
			// 	contentType: "application/json; charset=utf-8",
			// 	async: true,
			// 	success: function (response) {

			// 		isPosting = false
			// 		console.log("success")
			// 	},
			// 	error: function (request, error) {

			// 		isPosting = false
			// 		console.log("failed")
			// 	}
			// });
		}
	};
	
	self.getRuntime = function ({ sco_id, user_id }, { success }) {
    api.scorm.getLMSRunTime(user_id, sco_id)
      .then(response => {
        console.log('response in get scorm get runtime', response);
        success(response)
      })
      .catch(e => { console.log('error in get scorm', e) })
	}

	self.getScorm = function (course_id, { success }) {
		courseId = course_id
		api.scorm.getScormByCourseId(course_id)
			.then(response => {
				console.log('response in get scorm', response);
				success(response)
			})
			.catch(e => { console.log('error in get scorm', e) })
	}
}
	// 	$.ajax({
	// 		type: "GET",
	// 		url: API_URL + `scorm/course/${course_id}`,
	// 		dataType: "json",
	// 		contentType: "application/json; charset=utf-8",
	// 		async: true,
	// 		success: function (response) {
	// 			success(response)
	// 		},
	// 		error: function (request, e) {
	// 			if(error){
	// 				error(e)
	// 			}
	// 		}
	// 	});
	// }

// var run = new Run();

// run.start();

// export class Communication {

// 	activity
// 	isPosting = false
// 	constructor(API_URL) {
// 		API_URL = API_URL
// 		setInterval(function () { communication.setDataToServer() }, 3000)
// 	}

// 	setActivity(a) {
// 		console.log(a)
// 		this.activity = a
// 	}

// 	setDataToServer(force) {
// 		if ((this.activity && this.activity.hasDirtyData && !this.isPosting) || force) {
// 			console.log("data to server")

// 			this.activity.setDirtyDataPosted()
// 			const cmi_core = this.activity.cmiCore
// 			const core_id = cmi_core.id
// 			console.log(window.lmsInfo)
// 			const user_id = window.lmsInfo.userId
// 			const reqBody = JSON.stringify({
// 				core_id,
// 				user_id,
// 				cmi_core
// 			})

// 			this.isPosting = true

// 			let that = this
// 			$.ajax({
// 				type: "POST",
// 				url: API_URL + "scorm/lmssetruntime",
// 				data: reqBody,
// 				dataType: "json",
// 				contentType: "application/json; charset=utf-8",
// 				async: true,
// 				success: function (response) {

// 					that.isPosting = false
// 					console.log("success")
// 				},
// 				error: function (request, error) {

// 					that.isPosting = false
// 					console.log("failed")
// 				}
// 			});
// 		}
// 	}

// 	getRuntime({ sco_id, user_id }, {success, error}) {
// 		$.ajax({
// 			type: "POST",
// 			url: API_URL + `scorm/lmsgetruntime/user/${user_id}/sco/${sco_id}/`,
// 			dataType: "json",
// 			contentType: "application/json; charset=utf-8",
// 			async: true,
// 			success: function (response) {
// 				const	cmi_core = response
// 				success(cmi_core)
// 			},
// 			error: function (request, e) {
// 				if(error){
// 					error(e)
// 				}
// 			}
// 		});
// 	}

// 	getScorm(course_id, {success, error}){
// 		$.ajax({
// 			type: "GET",
// 			url: API_URL + `scorm/course/${course_id}`,
// 			dataType: "json",
// 			contentType: "application/json; charset=utf-8",
// 			async: true,
// 			success: function (response) {
// 				success(response)
// 			},
// 			error: function (request, e) {
// 				if(error){
// 					error(e)
// 				}
// 			}
// 		});
// 	}

// }