import _ from 'lodash'

import * as types from '../types'

const defaultState = {
  list: [],
  quizById: {
    questions: []
  },
  validate: {
    result: {},
    fetch: false
  },
  fetch: false,
  showAiQuizModal: false
}

const quizes = (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCH_REQUEST:
      return {
        ...state,
        fetch: action.payload
      }
    case types.LOAD_QUIZES:
      return {
        ...state,
        list: action.payload
      }
    case types.LOAD_QUIZ_VALIDATE:
      return {
        ...state,
        validate: action.payload
      }
    case types.UPDATE_QUIZES:
      return {
        ...state,
        list: state.list.concat(action.payload)
      }
    case types.LOAD_QUIZ:
      const index = _.findIndex(state.list, ['id', action.payload.id])

      return {
        ...state,
        list: [
          ...state.list.slice(0, index),
          action.payload,
          ...state.list.slice(index + 1)
        ]
      }
    case types.LOAD_QUIZ_BY_ID:
      return {
        ...state,
        quizById: Object.assign({}, action.payload)
      }
    case types.REMOVE_QUIZ_BY_ID:
      return {
        ...state,
        list: state.list.filter(i => i.id !== action.payload.id)
      }
    case types.GENERATE_AI_QUIZ:
      return {
        ...state,
        showAiQuizModal: action.payload
      }
    default:
      return state
  }
}

export default quizes
