import styled from 'styled-components'

const AudioPlayerStyle = styled.div`
  audio {
    border: 1px solid #bdcadb;
    box-sizing: border-box;
    border-radius: 10px;
    background: #f1f3f4;
  }
`

AudioPlayerStyle.Center = styled.div`
  text-align: center;
`

export { AudioPlayerStyle }
