import { Typography } from 'antd'
import React, { useState, useEffect } from 'react'

const { Text } = Typography

const CustomSwitch = ({ label, onChanged, value }) => {
  const [isOn, setIsOn] = useState(true)

  useEffect(() => {
    setIsOn(value)
  }, [value])

  const toggleSwitch = () => {
    onChanged(!isOn)
    setIsOn(!isOn)
  }

  return (
    <div className="margin-bottom">
      {label && (
        <div className="margin-bottom line-height">
          <Text className="switch-label">{label.toUpperCase()}</Text>
        </div>
      )}
      <div
        onClick={() => toggleSwitch()}
        className={isOn ? 'switch-bg_active' : 'switch-bg_inactive'}
      >
        <div className="switch-knob" />
      </div>
    </div>
  )
}

export { CustomSwitch }
