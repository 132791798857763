import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import {
  MeetingProvider,
  lightTheme
} from 'amazon-chime-sdk-component-library-react'

import { store } from './store'
import App from './App'

import './services/i18n'

import './styles/app.scss'
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <MeetingProvider>
        {/* <MicSelection />
      <CameraSelection />
      <QualitySelection/>
      <SpeakerSelection />
      <FeaturedRemoteVideos /> */}
        <App />
      </MeetingProvider>
    </ThemeProvider>
  </Provider>,
  document.querySelector('#app')
)
