import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Wrapper, Main } from '../../styles'

import { Loading } from '../'

export default class extends Component {
  search = window.location.search
  params = new URLSearchParams(this.search)
  isSharebot = this.params.get('isSharebot')

  render() {
    const {
      component: Component,
      isAuthenticated,
      path,
      isFetching,
      ...rest
    } = this.props
    console.log('public path', path)
    if (path === '/assessments/:id/graph') {
      return (
        <Route
          {...rest}
          render={props => (
            <Wrapper>
              <Main style={{ paddingTop: 0 }}>
                <Component {...props} />
              </Main>
              <Loading />
            </Wrapper>
          )}
        />
      )
    }
    if (path === '/events/:id/recording') {
      return (
        <Route
          {...rest}
          render={props => (
            <Wrapper>
              <Main style={{ paddingTop: 0 }}>
                <Component {...props} />
              </Main>
              <Loading />
            </Wrapper>
          )}
        />
      )
    }
    return !isAuthenticated ? (
      <Route
        {...rest}
        render={props => (
          <Wrapper>
            <Main style={{ paddingTop: 0 }}>
              <Component {...props} />
            </Main>
            <Loading />
          </Wrapper>
        )}
      />
    ) : this.isSharebot === 'true' ? (
      <Redirect to="/account?isSharebotSubscription=true" />
    ) : (
      <Redirect to="/" />
    )
  }
}
