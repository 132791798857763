export const quotes = [
  {
    text: 'When you choose an option in your maze it means that this is the option you will make in a real situation.',
    by: 'ShareLook'
  },
  {
    text:
      'Every option has an outcome and consequences.  This is the maze.',
    by: 'ShareLook'
  },
  {
    text: 'When you see the path of your choices and you compare it to the ideal path, you will know how far you are from the path.',
    by: 'ShareLook'
  },
  {
    text:
      'Our lives are full of decisions and outcomes. That’s actually a maze.',
    by: 'ShareLook'
  },
  {
    text:
      'Click the button next to your path that will take you to your customized course.',
    by: 'ShareLook'
  }
]
