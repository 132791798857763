import React from 'react'
import { Prompt } from 'react-router-dom'
import { WebRTCAdaptor } from './webrtcAdapter'
import { Row } from 'antd'
import { config } from '../../../../../constants'
import { Card } from '../../../../../components/Card/styled'
import '../../../../../components/Card/Card.scss'
import { NetworkDetection } from '../NetworkDetection'

let pcConfig = null
let sdpConstraints = {
  OfferToReceiveAudio: true,
  OfferToReceiveVideo: true
}

let mediaConstraints = {
  video: true,
  audio: true
}

let prompt = null
let webRTCAdaptor = null
let token = ''
let video = {}

class AntPlayer extends React.Component {
  SOCKET_URL_PLAY =
    `wss://antmedia-${this.props.eventId}.v2.sharelookapp.com:5443/WebRTCAppEE/websocket?rtmpForward=undefined`
  initSocket = null
  intervalId = null
  intervalTimeOut = null
  state = {
    showRetry: false,
    isLoading: false,
    showPrompt: false,
    presenterLoader: false,
    playStream: ''
  }

  componentDidMount() {
    this.setState({ isLoading: true }, () => {
      this.initPlayer()
    })
    video = document.getElementById('remoteVideo')
    if (video.muted === true) {
      video.muted = false
      video.play()
    }
  }

  componentWillUnmount() {
    const { playStream } = this.state
    console.log('componentWillUnmount before playStream', playStream)
    console.log('componentWillUnmount after')
    if (this.intervalId !==null) {
      console.log('if componentWillUnmount this.intervalId', this.intervalId)
      clearInterval(this.intervalId)
      this.intervalId = null
    }
    // if (this.intervalTimeOut !==null) {
    //   console.log('if componentWillUnmount this.intervalTimeOut', this.intervalTimeOut)
    //   clearTimeout(this.intervalTimeOut)
    //   this.intervalTimeOut = null
    // }
    if (this.connectionInterval) {
      clearInterval(this.connectionInterval)
    }
    this.setState({ showPrompt: false }, () => {
      prompt = null
      this.stopPlaying()
    })
  }

  getHostId = () => {
    const { eventId } = this.props
    this.initSocket.send(
      JSON.stringify({
        action: 'getStream',
        channel_type: 'EVENT',
        channel_id: eventId
      })
    )
  }

  startStreaming = () => {
    const { eventId, userId, orgId } = this.props
    this.initSocket = new window.WebSocket(
      `${config.socketURL}?channel_type=EVENT&channel_id=${eventId}`
    )

    this.initSocket.onopen = () => {
      this.initSocket.send(
        JSON.stringify({
          action: 'connectStream',
          channel_type: 'EVENT',
          channel_id: eventId,
          user_id: userId,
          org_id: orgId
        })
      )
      this.connectionInterval = setInterval(() => {
        this.initSocket.send(
          JSON.stringify({
            action: 'pingPong',
            msg: 'ping'
          })
        )
      }, 20000)
      this.getHostId()
    }

    this.initSocket.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log('play listen', data)
      if (data.event === 'getStream') {
        console.log('getStream')
        this.setState({ playStream: data.stream_id }, () => {
          this.setState(
            {
              showPrompt: true
            },
            () => {
              const { setHostId } = this.props
              this.startPlaying()
              setHostId(this.state.playStream)
            }
          )
        })
      }
      if (data.event === 'startStream') {
        console.log('startStream')
        this.setState({ playStream: data.stream_id }, () => {
          this.setState(
            {
              showPrompt: true
            },
            () => {
              const { setHostId } = this.props
              this.startPlaying()
              setHostId(this.state.playStream)
            }
          )
        })
      }

      if (data.event === 'stopStream' || data.event === 'endConference') {
        console.log('stopStream')
        this.stopPlaying()
        clearInterval(this.connectionInterval)
      }
    }
  

    this.initSocket.onclose = () => {
      console.log('stop web socket ko')
      clearInterval(this.connectionInterval)
    }

    this.initSocket.onerror = error => {
      console.log('Error ' + error.message)
      this.stopStreaming()
      clearInterval(this.connectionInterval)
    }
  }

  stopStreaming = () => {
    const { eventId, userId, orgId, handleBroadcastChange } = this.props
    this.initSocket.send(
      JSON.stringify({
        action: 'disconnectStream',
        channel_type: 'EVENT',
        channel_id: eventId,
        user_id: userId,
        org_id: orgId
      })
    )
    handleBroadcastChange()
  }

  initPlayer = () => {
    webRTCAdaptor = new WebRTCAdaptor({
      websocket_url: this.SOCKET_URL_PLAY,
      mediaConstraints: mediaConstraints,
      peerconnection_config: pcConfig,
      sdp_constraints: sdpConstraints,
      remoteVideoId: 'remoteVideo',
      isPlayMode: true,
      debug: true,
      candidateTypes: ['tcp', 'udp'],
      callback: (info, obj) => {
        if (info === 'initialized') {
          console.log('initialized')
          this.startStreaming()
          
        } else if (info === 'play_started') {
          // joined the stream
          console.log('play started this.intervalId', this.intervalId)
          this.getHostId()
          this.setState({
            showRetry: false,
            showPrompt: true,
            isLoading: false,
          }, () => {
            const {handleBroadcastChange} = this.props
            handleBroadcastChange()
            if (this.intervalId !==null) {
              console.log('if this.intervalId', this.intervalId)
              clearInterval(this.intervalId)
              this.intervalId = null
            }
  
            // if (this.intervalTimeOut !==null) {
            //   console.log('if this.intervalTimeOut', this.intervalTimeOut)
            //   clearTimeout(this.intervalTimeOut)
            //   this.intervalTimeOut = null
            // }
          })
         
        } else if (info === 'play_finished') {
          console.log('play finished')
          this.setState({ showPrompt: false }, () => {
            this.stopStreaming()
            this.intervalId = setInterval(() => {
              console.log('interval counting')
              webRTCAdaptor.getStreamInfo(obj.streamId)
            }, 5000)
          })
        } else if (info === 'closed') {
          console.log('closed')
          if (typeof obj !== 'undefined') {
            console.log('Connecton closed: ' + JSON.stringify(obj))
          }
        } else if (info === 'streamInformation') {
          if (obj.streamId !== null) {
            webRTCAdaptor.play(obj.streamId, token)
          }
        } else if (info === 'ice_connection_state_changed') {
          console.log('iceConnectionState Changed: ', JSON.stringify(obj))
          } else if (info === 'updated_stats') {
          // obj is the PeerStats which has fields
          // averageIncomingBitrate - kbits/sec
          // currentIncomingBitrate - kbits/sec
          // packetsLost - total number of packet lost
          // fractionLost - fraction of packet lost
          console.log(
            'Average incoming kbits/sec: ' +
              obj.averageIncomingBitrate +
              ' Current incoming kbits/sec: ' +
              obj.currentIncomingBitrate +
              ' packetLost: ' +
              obj.packetsLost +
              ' fractionLost: ' +
              obj.fractionLost +
              ' audio level: ' +
              obj.audioLevel
          )
        } else if (info === 'data_received') {
          console.log(
            'Data received: ' +
              obj.event.data +
              ' type: ' +
              obj.event.type +
              ' for stream: ' +
              obj.streamId
          )
        } else {
          console.log(info + ' notification received')
        }
      },
      callbackError: error => {
        // some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

        console.log('error callback: ' + JSON.stringify(error))
        if (error.indexOf('no_stream_exist') !== -1) {
          console.log('no_stream_exist this.intervalId', JSON.stringify(error), this.intervalId)
          this.setState({ showRetry: true }, ()=>{
            // this.intervalTimeOut = setTimeout(() => {
            //   this.getHostId()
            // }, 5000)
          })
        } else if (error.indexOf('already_playing') !== -1) {
          console.log('already_playing')
          this.setState({showRetry: false},()=>{
            if (this.intervalId !==null) {
              console.log('if this.intervalId', this.intervalId)
              clearInterval(this.intervalId)
              this.intervalId = null
            }
            // if (this.intervalTimeOut !==null) {
            //   console.log('if this.intervalTimeOut', this.intervalTimeOut)
            //   clearTimeout(this.intervalTimeOut)
            //   this.intervalTimeOut = null
            // }
          })
        }else {
          console.log('error: ', JSON.stringify(error))
        }
      }
    })
  }

  startPlaying = () => {
    const { playStream } = this.state
    console.log('playing stream', playStream)
    if(webRTCAdaptor && playStream){
      webRTCAdaptor.play(playStream, token)
    }
  }

  stopPlaying = () => {
    const { playStream } = this.state
    console.log('stopPlaying stream', playStream)
    if(webRTCAdaptor){
      webRTCAdaptor.stop(playStream)
    }
  }

  render() {
    const {
      showRetry,
      isLoading,
      showPrompt,
      playStream
    } = this.state
    const { t } = this.props


    console.log('render isLoading', isLoading)
    console.log('render intervalId', this.intervalId)

    return (
      <div className="div-section">
        <>
          <Prompt
            when={showPrompt}
            message={() => {
              prompt = window.confirm('The broadcast is ongoing. Do you really want to navigate away from this page?')
              console.log('prompt value: ', prompt)
              if(prompt){
                console.log('if prompt value: ')
                if(webRTCAdaptor){
                  webRTCAdaptor.stop(playStream)
                }
                return prompt
              } else {
                console.log('else prompt value: ')
                return false
              }
            }}
          />
          <Row>
            <video id="remoteVideo" autoPlay muted controls playsInline />
          </Row>
          {showRetry && (
            <Card.OverlayPlayer>
              <span>{t('v2:currently_broadcaster_unavailable')}</span>
            </Card.OverlayPlayer>
          )}
          {isLoading && (
            <Card.OverlayPlayer>
              <span>{t('general:loading')}</span>
            </Card.OverlayPlayer>
          )}
        </>
      </div>
    )
  }
}

export default NetworkDetection(AntPlayer)
