import React, { useState, useEffect, useMemo, Fragment } from 'react'
import {
  Input,
  Button,
  Select,
  Form,
  message,
  Modal,
  Tooltip,
  Divider,
  Spin,
  List,
  Row,
  Col
} from 'antd'
import 'antd/dist/antd.css'
import { config } from '../../../../../../../../../constants'
import './AiVideos.scss'
import { api } from '../../../../../../../../../services'
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import ImageUploadModal from '../../../../../../../../../components/Core/ImageUpload/ImageUploadModal'
import AiImages from '../AiImages'
import { useDispatch } from 'react-redux'
import actions from '../../../../../../../../../store/actions'
const { Option } = Select
const AiVideos = ({ form, onData, data }) => {
  const [error, setError] = useState({ status: false, text: "Please enter your idea..." })
  const [generationStatus, setGenerationStatus] = useState({
    status: 'idle',
    duration: "short",
  })
  const [currentGenerated, setCurrentGenerated] = useState({
    info: '',
    url: ''
  })

  let ws

  useEffect(() => {
    const cleanup = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close()
        console.log('WebSocket closed.')
      }
    }

    return cleanup
  }, [ws])

  const handleGenerateVideo = (data) => {
    if (data.prompt) {
      startGeneration(data)
      setError({ ...error, status: false })
    } else {
      message.error("Please input your video idea!")
      setError({ ...error, status: true })
    }
  }

  const startGeneration = data => {
    setGenerationStatus({ status: 'waiting-to-start', duration: data.duration })
    setCurrentGenerated({ info: '', url: '' })
    let dataCopy = JSON.parse(JSON.stringify(data));
    let payload = {
      prompt: dataCopy.prompt,
      // duration: dataCopy.duration,
      image: data.images[0] ? data.images[0].upload_filename : "",
      // style: dataCopy.style
    };
    api.midJourney
      .generateVideo(payload)
      .then(response => {
        setupWebsocket(response.data.MessageId)
      })
      .catch(error => {
        console.error(error)
        setGenerationStatus({ status: 'error', duration: "short" })
      })
  }

  const setupWebsocket = (messageId) => {
    const socketUrl =
      config.midJourneySocket + `?channel_type=SEND_CREATE&channel_id=SEND-CREATE-MESSAGEID-${messageId}`

    ws = new WebSocket(socketUrl)

    ws.onopen = () => {
      console.log('Websocket connected')
    }

    ws.onclose = () => {
      console.log('Websocket closed')
    }

    ws.onmessage = event => {
      const data = JSON.parse(event.data)
      console.log("tesst event", data, data.event)
      const isGenerating =
        data.event === 'sendCreate'
      if (isGenerating && data.progress === 'done') {
        setGenerationStatus({
          ...generationStatus,
          status: 'complete'
        })
        setCurrentGenerated({ info: data.msg, url: data.msg.uri })
        ws.close()
      } else if (isGenerating) {
        setGenerationStatus({
          ...generationStatus,
          status: 'running',
        })
        setCurrentGenerated({ info: '', url: data.url })
      }
    }
  }

  const selectVideo = () => {
    onData({
      link: currentGenerated.url,
      id: generate7DigitRandomInteger()
    })
  }

  function generate7DigitRandomInteger() {
    const min = 1000000 // Minimum value (inclusive)
    const max = 9999999 // Maximum value (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const isBeingGenerated = useMemo(() => {
    return (
      generationStatus.status !== 'idle' &&
      generationStatus.status !== 'complete'
    )
  }, [generationStatus])

  return (
    <div className="ai-videos-container">
      <div className="ai-videos-user-input">
        <AiVideosForm
          form={form}
          handleError={(val) => { setError(val) }}
          isBeingGenerated={isBeingGenerated}
          handleGenerateVideo={handleGenerateVideo}
          generationStatus={generationStatus}
        />
      </div>
      {generationStatus.status !== 'idle' && (
        <div className="ai-videos-result">
          {currentGenerated.url && (
            <div className="video-preview">
              <h3>{data.prompt}</h3>
              <VideoPreview link={currentGenerated.url} />
            </div>
          )}

          {/* {generationStatus.status === 'complete' && (
            <div className="video-preview-actions">

              {currentGenerated.info && (
                <div className="video-preview-actions-buttons">
                  <Button type="primary" disabled={isBeingGenerated} loading onClick={(e) => { e.preventDefault(); selectVideo() }}>
                    {"Insert Video"}
                  </Button>
                </div>
              )}
            </div>
          )} */}
          {generationStatus.status === 'waiting-to-start' && (
            <Spin
              tip={`Generating your video, Avg. Wait: ${generationStatus.duration === "short" ? "2-5" : generationStatus.duration === "medium" ? "4-8" : "8-12"} minutes...`}
            />
          )}

          {generationStatus.status === 'error' && <p>Error generating video.</p>}
        </div>
      )}
    </div>
  )
}
const VideoPreview = props => {
  const { link } = props
  const isVimeo = link.indexOf('vimeo') > 0

  if (isVimeo) {
    return (
      <div className="embed-container">
        <iframe
          title="video"
          src={link + '?color=0068FF&title=0&byline=0&portrait=0'}
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
        />
      </div>
    )
  }

  return (
    <div className="preview preview_video embed-container">
      <video controls style={{ width: '100%', height: '100%' }}>
        <source src={link} />
      </video>
    </div>
  )
}
const AiVideosForm = ({
  form,
  isBeingGenerated,
  handleGenerateVideo,
  generationStatus,
  handleError
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [imageFile, setImageFile] = useState({
    file: {},
    url: '',
    croppedUrl: ''
  })
  const [isUploading, setUploading] = useState(false)
  const [libraryModal, setLibraryMdal] = useState(false)
  const [isAiModal, setAiModal] = useState(false)
  const [formData, setFormData] = useState({ prompt: "", style: "Realism", duration: "short", images: [] })
  const [styleList] = useState(["Comic Book", "Fantasy", "Realism", "Amine/Manga", "3D Animation"])
  const [durationList] = useState(["short", "medium", "long"])
  const uploadImage = (link) => {
    setUploading(true)
    api.midJourney
      .uploadMidjourneyImage({ link })
      .then(response => {
        // let tempImages = formData.images
        // tempImages.push({ upload_filename: response.data.upload_filename, link: link })
        setFormData({ ...formData, images: [{ upload_filename: response.data.upload_filename, link: link }] })
        setUploading(false)
      })
      .catch(error => {
        setUploading(false)
        console.error(error)
      })
  }
  const handleFileInsert = item => {
    const { link } = item
    uploadImage(link)
    setLibraryMdal(false)
  }
  const selectedAiImage = img => {
    handleImageUrl(img.link, 'ai_video_image')
  }
  const handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title.split(' ').join('_'),
      size: blob.size,
      type: blob.type
    }
    handleUploadImage(body)
  }
  const handleUploadImage = file => {
    setUploading(true)

    return dispatch(actions.library.upload(file))
      .then(res => {
        const { link } = res
        uploadImage(link)
        setAiModal(false)
        setUploading(false)
      })
      .catch(() => {
        setUploading(false)
        message.error('Failed to upload cover image!')
      })
  }
  const addFile = () => {
    const element = document.getElementById('video_image')
    if (element) {
      element.click()
    } else {
      console.error(`Element with not found.`)
    }
  }
  const changeImage = (e, id) => {
    let file = e.target.files[0]
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error('Please select a valid image.')
      return
    }
    let img = new Image()
    img.onload = () => {
      uploadFile(file)
    }

    img.onerror = () => {
      message.error('Failed to load the image. Please try again!')
      return
    }
    img.src = URL.createObjectURL(file)
    e.target.value = ''
  }
  const uploadFile = async file => {
    let reader = new FileReader()
    reader.onload = e => {
      if (e.target && e.target.result) {
        setImageFile({ file: file, url: e.target.result, croppedUrl: '' })
        handleUploadImage(file)
      }
    }
    reader.readAsDataURL(file)
  }
  const removeImage = (index) => {
    let tempImages = formData.images
    tempImages.splice(index, 1)
    setFormData({
      ...formData, images: tempImages
    })
  }
  return (
    <Fragment>
      {libraryModal && (
        <ImageUploadModal
          modalOpen={libraryModal}
          hideModal={() => {
            setLibraryMdal(false)
          }}
          handleFileInsert={handleFileInsert}
        />
      )}
      {isAiModal && (
        <Modal
          visible={isAiModal}
          footer={false}
          destroyOnClose
          onCancel={e => {
            e.preventDefault()
            setAiModal(false)
          }}
          width={'80%'}
        >
          <AiImages
            onImage={selectedAiImage}
          />
        </Modal>
      )}
      <Form
        className="ai-videos-form"
        layout="vertical"
      >
        <div className="ai-videos-form-prompt" id="promptInput">
          <h1>AI Video Generation</h1>
          <Divider style={{ color: '#000' }}>
            Generate video using your own idea
          </Divider>
        </div>
        <Form.Item name="prompt">
          {form.getFieldDecorator('prompt', {
            initialValue: formData.prompt,
            rules: [
              {
                required: true,
                message: 'Please enter your idea...'
              }
            ]
          })(
            <Input.Search
              disabled={isBeingGenerated}
              placeholder=""
              value={formData.prompt}
              onChange={e => {
                let val = e.target.value;
                if (val) {
                  handleError({ status: false, text: "Please enter your idea..." })
                } else {
                  handleError({ status: true, text: "Please enter your idea..." })
                }
                setFormData({ ...formData, prompt: val });
              }}
              onPressEnter={e => handleGenerateVideo(formData)}
            />
          )}
        </Form.Item>
        <Row justify='space-between' align='middle' gutter={[8, 8]}>
          {/* <Col xs={24} sm={24} md={12}>
            <Form.Item name="title" label={<div className='ai-videos-form-label'><span>Video Style</span><Tooltip placement="topLeft" title={"This indicates the style of the video you want to generate."} arrow>
              <InfoCircleOutlined className="ai-videos-form-label-icon" />
            </Tooltip></div>}>
              <Select
                style={{ width: 200 }}
                placeholder="Video Style"
                optionFilterProp="children"
                size='large'
                disabled={isBeingGenerated}
                defaultValue={formData.style}
                onPressEnter={e => handleGenerateVideo(formData)}
                onChange={(value) => {
                  setFormData({ ...formData, style: value })
                }}
              >
                {styleList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="title" label={<div className='ai-videos-form-label'><span>Video Duration</span><Tooltip placement="topLeft" title={"This indicates the length of the generated video."} arrow>
              <InfoCircleOutlined className="ai-videos-form-label-icon" />
            </Tooltip></div>}>
              <Select
                style={{ width: 200 }}
                placeholder="Video Style"
                optionFilterProp="children"
                size='large'
                disabled={isBeingGenerated}
                defaultValue={formData.duration}
                onChange={(value) => {
                  setFormData({ ...formData, duration: value })
                }}
                onPressEnter={e => handleGenerateVideo(formData)}
              >
                {durationList.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
        </Row>

        <Row justify='space-between' align='middle' gutter={[8, 8]} style={{ border: "1px solid #e9e9e9", padding: "1.2rem", borderRadius: "1.2rem" }}>
          <Col span={24}><div className='ai-videos-form-label'><Tooltip placement="topLeft" title={"This indicates the reference image of the video you want to generate will be based on."} arrow>
            <span>Reference Images &nbsp;<InfoCircleOutlined className="ai-videos-form-label-icon" /></span>
          </Tooltip></div>
          </Col>
          <Col span={24}>
            <div className='ai-images-action'>
              <input
                type="file"
                id="video_image"
                style={{ display: 'none' }}
                onChange={e => {
                  changeImage(e, 'video_image')
                }}
              />
              <Button
                style={{ margin: 'auto 5px' }}
                className="rounded"
                size="large"
                icon="picture"
                onClick={e => {
                  e.preventDefault()
                  addFile()
                }}
              >
                Upload Image
              </Button>
              <Button
                style={{ margin: 'auto 5px' }}
                className="rounded"
                size="large"
                icon="picture"
                onClick={e => {
                  e.preventDefault()
                  setLibraryMdal(true)
                }}
              >
                {t('buttons:select_media_library')}
              </Button>
              <Button
                style={{ margin: 'auto 5px' }}
                className="rounded"
                size="large"
                icon="picture"
                onClick={e => {
                  e.preventDefault()
                  setAiModal(true)
                }}
              >
                {t('v3:upload_ai_creator')}
              </Button>
            </div>
          </Col>
          <Col span={24}>
            <List
              className='ai-images'
              size="small"
              footer={!isUploading ? false : (
                <Spin tip="uploading..." />
              )}
              bordered
              dataSource={formData.images}
              renderItem={(item, index) => <div className='ai-images-list'><img className='ai-images-list-cover' src={item.link} />&nbsp; &nbsp;<Button type='danger' disabled={isBeingGenerated} onClick={() => removeImage(index)}>Delete</Button></div>}
            />
          </Col>
        </Row>

        <Form.Item>
          <Button
            disabled={
              isUploading && isBeingGenerated && generationStatus.status !== 'error'
            }
            onClick={(e) => { e.preventDefault(); handleGenerateVideo(formData); }}
            type="primary"
            loading={isBeingGenerated && generationStatus.status !== 'error'}
          >
            Generate Video
          </Button>
        </Form.Item>
      </Form>
    </Fragment>

  )
}

export default Form.create()(AiVideos)
