/*
 * @Author: your name
 * @Date: 2019-12-03 15:13:43
 * @LastEditTime: 2019-12-03 15:14:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/views/Courses/Create/nestedComponents/LessonCreate/styled.js
 */
export const modal_style = {
  overflow: 'auto',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
}
