import React from 'react'
import { Select, Icon, Input, Switch } from 'antd'
import _ from 'lodash'
import './BreakoutRoom.scss'
import { transcribeKeys } from '../../../../../constants'
import { withTranslation } from 'react-i18next'

const { Option } = Select

const countOptions = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' }
]

function BreakoutForm({
  setBreakoutRoomCount,
  breakoutRoom,
  handleBreakoutRoom,
  rooms,
  removeRoom,
  organization,
  handleChangeLocale,
  handleCheckChange,
  errors,
  t
}) {
  const handleBreakoutSetting = (index, field, value) => {
    handleBreakoutRoom(index, field, value)
  }

  const handleSwitchSetting = (index, field, value) => {
    handleCheckChange(index, field, value)
  }

  const handleChangeTranscribeLocale = (index, value) => {
    handleChangeLocale(index, value)
  }

  return (
    <div>
      <div className="breakout__top">
        <h2>{t('v2:how_many_breakout_room_to_create')}</h2>
        <Select
          showSearch
          style={{ width: 100 }}
          value={breakoutRoom}
          placeholder="Room Count"
          optionFilterProp="children"
          onChange={e => {
            setBreakoutRoomCount(e)
          }}
          filterOption={(input, option) =>
            !_.isArray(option.props.children) &&
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0
          }
        >
          {countOptions.map(item => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
        <p>{t('v2:create_upto_three_breakout_rooms')}</p>
      </div>
      {breakoutRoom > 0 &&
        rooms.length > 0 &&
        rooms.map((e, i) => (
          <div className="breakout" key={`room_${i}`}>
            <div className="breakout__head">
              {t('v2:breakout_room')} {i + 1}
              <div className="breakout__delete" onClick={() => removeRoom(i)}>
                <Icon color="red" type="delete" />
              </div>
            </div>
            <div className="breakout__content">
              <div className="breakout__room">
                {/* <span>Name of Room</span> */}
                <Input
                  placeholder="Room Name"
                  value={e.title}
                  onChange={e =>
                    handleBreakoutSetting(i, 'title', e.target.value)
                  }
                />
                {errors[i] && errors[i].title && (
                  <div style={{ color: 'red' }}>{errors[i].title}</div>
                )}
              </div>
              <div className="breakout__room">
                <Select
                  showSearch
                  style={{ marginRight: '10px', width: '100%' }}
                  value={e.transcribe_locale}
                  placeholder="Transcribe to"
                  optionFilterProp="children"
                  onChange={e => handleChangeTranscribeLocale(i, e)}
                  filterOption={(input, option) =>
                    !_.isArray(option.props.children) &&
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={!e.is_transcribed}
                >
                  {transcribeKeys.map(item => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="breakout__room__buttons">
              <Switch
                name="is_recording"
                checkedChildren={'Record'}
                unCheckedChildren={'Record'}
                style={{ marginRight: '10px' }}
                onChange={e => {
                  handleSwitchSetting(i, 'is_recording', e)
                }}
                value={e.is_recording}
              />
              <Switch
                name="is_transcribed"
                checkedChildren={'Transcribe'}
                unCheckedChildren={'Transcribe'}
                style={{ marginRight: '10px' }}
                onChange={e => {
                  handleBreakoutSetting(i, 'is_transcribed', e)
                }}
                value={e.is_transcribed}
                disabled={!e.is_recording}
              />
              {organization && organization.enable_ai && (
                <Switch
                  name="is_ai_enabled"
                  checkedChildren={'AI Enable'}
                  unCheckedChildren={'AI Enable'}
                  onChange={e => {
                    handleBreakoutSetting(i, 'is_ai_enabled', e)
                  }}
                  disabled={!e.is_transcribed || !e.is_recording}
                  value={e.is_ai_enabled}
                  style={{ marginRight: '10px' }}
                />
              )}
            </div>
            {errors[i] && errors[i].error_msg_ai && (
              <div style={{ color: 'red' }}>{errors[i].error_msg_ai}</div>
            )}
          </div>
        ))}
    </div>
  )
}

export default withTranslation()(BreakoutForm)
