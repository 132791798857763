import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import history from '../../../history'

import * as courseBuilderActions from '../../../store/actions/courseBuilder'
import * as lessonBuilderActions from '../../../store/actions/lessonBuilder'

import {
  CourseCreateMain,
  PublishSettings
} from './nestedComponents'

class CourseCreateRoutes extends Component {
  componentWillUnmount() {
    const {
      courseBuilderActions: { resetCourseBuilder },
      lessonBuilderActions: { resetLessonBuilder }
    } = this.props

    resetCourseBuilder()
    resetLessonBuilder()
  }

  componentDidMount() {
    const {
      mode,
      match: { params },
      location: { pathname },
      courseBuilder,
      courseById: { item },
      user: {
        info: { isAdmin, isSuperAdmin, id }
      }
    } = this.props

    const isEdit = mode === 'edit'

    const rootPathTemplate = isEdit ? '/scorm/:id/edit' : '/scorm-create'
    const rootPath = rootPathTemplate.replace(':id', params.id)

    if (
      (isAdmin || isSuperAdmin) &&
      isEdit &&
      item.creator_id !== id &&
      pathname === `${rootPath}/publish`
    ) {
      return null
    }

    if (pathname !== rootPath && !courseBuilder.courseId) {
      history.push(rootPath)
      return null
    }
  }

  render() {
    const {
      mode,
      match: { params }
    } = this.props

    const rootPathTemplate =
      mode === 'edit' ? '/scorm/:id/edit' : '/scorm-create'
    const rootPath = rootPathTemplate.replace(':id', params.id)

    return (
      <Switch>
        <Route
          exact
          path={rootPathTemplate}
          render={props => <CourseCreateMain {...props} rootPath={rootPath} />}
        />
        <Route
          path={`${rootPathTemplate}/publish`}
          render={props => <PublishSettings {...props} rootPath={rootPath} />}
        />
      </Switch>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      courseBuilder: state.courseBuilder,
      courseById: state.courses.courseById,
      user: state.user
    }),
    dispatch => ({
      courseBuilderActions: bindActionCreators(courseBuilderActions, dispatch),
      lessonBuilderActions: bindActionCreators(lessonBuilderActions, dispatch)
    })
  )(CourseCreateRoutes)
)
