/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-07-19 19:34:23
 * @LastEditTime: 2019-08-12 18:01:10
 * @LastEditors: Please set LastEditors
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'
import Video from './Video'

const mapStateToProps = state => ({
  droppedFile: state.lessonBuilder.droppedFile,
  library: state.library
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleFileDropIn: actions.lessonBuilder.handleFileDropIn,
      removeFromUploadingList: actions.library.removeFromUploadingList
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Video)
