import React, { Component, createRef } from 'react'
import {
  Select,
  InputNumber,
  Form,
  Modal,
  Spin,
  Icon,
  Tag,
  Button,
  Popconfirm,
  Tooltip,
  Badge,
  Progress,
  Col,
  Row
} from 'antd'
import './styles.scss'

import _ from 'lodash'

import axios from 'axios'

import { config, general } from '../../../../../../constants'
import ImageCropContainer from '../../../../../../components/ImageCrop'
import { PopupMedia } from '../../../../../../views/Library/internal'
import { Editor } from '../../../../../../components/ImageEditor'
import { AudioModalTitleIcon } from '../../../../../Courses/Create/nestedComponents/PageConstructor/internal/PageMaker/components/Icons'
import {
  CreateAudio,
  AddDetailAudio
} from '../../../../../Courses/Create/nestedComponents/PageBuilder/Components/Audio/internal/Create'

import GalleryIcon from '../../../../../../assets/gallery_icon.png'
import DeleteIcon from '../../../../../../assets/bin.svg'
import MultipleImageSelect from './MultipleImageSelect/MultipleImageSelect'

const { Option } = Select

const sceneTypes = {
  0: 'Image',
  1: '3D Model'
}

class Template extends Component {
  constructor(props) {
    super(props)
    this.state = {
      templateId: props.templateId,
      lastScenario: props.lastScenarioIndex,
      content: '',
      src: '',
      isImageLoading: false,
      isVideoLoading: false,
      score: null,
      openCreateImageModal: false,
      openImagesModal: false,
      scoreError: false,
      courseFetching: true,
      lessonFetching: false,
      voicesFetching: true,
      isActiveImageModal: false,
      isActiveVideoModal: false,
      isActive3DModal: false,
      isTextOverflow: false,
      voices: [],
      editImage: {},
      images: [],
      image_list: [],
      selectedSceneType: sceneTypes[0],
      showImageSelectModal: false,
      selected3DModel: {}
    }
    this.imageRef = createRef()
  }

  async componentDidMount() {
    const { data } = this.props

    console.log({ props: this.props })

    this.fetchCourses()
    if (data && data.values) {
      this.putData()
    }
  }

  putData = () => {
    const { data } = this.props
    const values = data && data.values
    const {
      content,
      score,
      course,
      lesson,
      voice,
      video_obj,
      images,
      image_list,
      model_obj
    } = values

    const scenarioCode = data && data.possible_scenario_code
    this.setState(
      {
        content: content || '',
        score: score || null,
        ...(course && { course: course }),
        ...(lesson && { lesson: lesson }),
        ...(voice && { selectedAudio: voice }),
        ...(scenarioCode && { scenarioCode: scenarioCode }),
        images: images,
        image_list:
          image_list &&
          image_list.map(({ id, link }) => ({
            id: id,
            link: link
          })),
        selectedSceneType:
          (images && images.length > 0) || (!images && !model_obj)
            ? sceneTypes[0]
            : sceneTypes[1],
        selected3DModel: model_obj && {
          id: model_obj.id,
          link: model_obj.link
        }
      },
      () => {
        if (course) {
          this.handleCourseSelect(course, true)
        }
        if (video_obj) {
          this.getVideoPreview(video_obj)
        }
      }
    )
  }

  handleVoiceList = () => {
    const {
      audioSynthesizer: {
        loadAudioData: { data }
      }
    } = this.props
    var voices = []
    Promise.resolve(
      Object.keys(data).forEach(item => {
        voices.push(item)
      })
    ).then(() => this.setState({ voices, voicesFetching: false }))
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      this.fetchCourses()
      const { data } = this.props
      if (data !== prevProps.data && data && data.values) {
        this.putData()
      }
    }
    if (prevProps.lastScenarioIndex !== this.props.lastScenarioIndex) {
      this.setLastScenarioIndex()
    }
  }

  fetchCourses = async () => {
    this.setState({
      courses: this.props.courses.workshopCourses.list,
      courseFetching: false
    })
  }

  deleteTemplate = () => {
    const { optionIndex, isScenario, scenarioIndex, onDelete } = this.props
    const { templateId } = this.state
    onDelete({
      optionIndex,
      isScenario,
      scenarioIndex,
      templateId
    })
  }

  setLastScenarioIndex = () =>
    this.setState({ lastScenario: this.props.lastScenarioIndex })

  toggleImageModal = (param, isOnMount) => {
    const mediaAttribute =
      param === 'img'
        ? 'isActiveImageModal'
        : param === '3d'
        ? 'isActive3DModal'
        : 'isActiveVideoModal'

    const mediaValue =
      param === 'img'
        ? this.state.isActiveImageModal
        : param === '3d'
        ? this.state.isActive3DModal
        : this.state.isActiveVideoModal

    this.setState(state => ({
      openImagesModal: isOnMount ? false : !state.openImagesModal,
      [mediaAttribute]: !mediaValue,
      editImage: null
    }))
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []
    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    if (this.state.isActiveImageModal) {
      this.setState({
        editImage: selectedItem
      })
    } else if (this.state.isActiveVideoModal) {
      this.setState({
        isVideoLoading: true
      })
      this.getVideoPreview(selectedItem)
    } else if (this.state.isActive3DModal && selectedItem) {
      this.setState(
        {
          selected3DModel: {
            id: selectedItem.id,
            link: selectedItem.link
          },
          image_list: []
        },
        () => {
          this.handleClosePopup()
          const { onTemplateValue, templateId } = this.props
          onTemplateValue('model_id', selectedItem.id, templateId)
        }
      )
    }
  }

  getVideoPreview = selectedItem => {
    axios
      .get(
        'https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/' +
          selectedItem.vimeo_id
      )
      .then(response => {
        this.setState(
          {
            selectedItem: {
              ...selectedItem,
              link: response.data.thumbnail_url
            },
            isVideoLoading: false
          },
          () => {
            this.handleClosePopup()
            const { onTemplateValue, templateId } = this.props
            onTemplateValue('video', selectedItem.id, templateId)
          }
        )
      })
  }

  hidePreviewModal = () => {
    this.setState({
      // selectedItem: null
      editImage: null
    })
  }

  handleFileInsert = item => {
    const imageEditorInst = this.imageRef.current.imageEditorInst
    const data = imageEditorInst.toDataURL()
    const isEdited = !imageEditorInst.isEmptyUndoStack()
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: item.filename,
      size: item.file_size,
      type: item.media_mime_type
    }
    this.setState(
      {
        openImagesModal: false,
        selectedItem: null,
        editImage: null
      },
      () => {
        this.handleClosePopup()
        if (isEdited === true) {
          this.handleUploadImage(body)
        } else {
          const { image_list = [] } = this.state
          this.setState(
            {
              image_list: [...image_list, { id: item.id, link: item.link }],
              isImageLoading: false,
              selected3DModel: {}
            },
            () => {
              const { onTemplateValue, templateId } = this.props
              console.log('Image', item.id, templateId)
              onTemplateValue('images', item.id, templateId)
            }
          )
        }
      }
    )
  }

  handleUploadImage = async file => {
    const { isActiveVideoModal, isActiveImageModal } = this.state

    if (isActiveVideoModal) this.handleClosePopup()

    this.setState(
      {
        [isActiveVideoModal ? 'isVideoLoading' : 'isImageLoading']: true
      },
      () => {
        const { upload } = this.props
        return upload(file).then(res => {
          if (!isActiveVideoModal) this.handleClosePopup()

          debugger
          this.setState(
            {
              ...(isActiveImageModal && {
                image_list: [
                  ...this.state.image_list,
                  { id: res.id, link: res.link }
                ],
                isImageLoading: false
              }),
              ...(isActiveVideoModal && {
                selectedItem: res,
                isVideoLoading: false
              }),

              selected3DModel: {}
            },
            () => {
              const { onTemplateValue, templateId } = this.props

              if (isActiveVideoModal) {
                onTemplateValue('video', res.id, templateId)
              } else {
                onTemplateValue('images', res.id, templateId)
              }
            }
          )
        })
      }
    )
  }

  toggleVoiceModal = () => {
    this.setState({ openCreateVoiceModal: !this.state.openCreateVoiceModal })
  }

  onShowDetailModal = (src, text) => {
    this.setState({ src: src, isShowDetailModal: true, content: text })
  }

  onHideDetailModal = () => {
    this.setState({ isShowDetailModal: false })
  }

  handleFileUpload = files => {
    if (!files) return
    this.setState({ selectedAudio: files[0] }, () => {
      this.onHideDetailModal()
      this.toggleVoiceModal()
    })
  }

  handleValue = value => {
    const { templateId, isScenario } = this.props

    if (!isScenario && value.length > 78) {
      this.setState({ isTextOverflow: true })
      return
    }

    this.setState({ content: value, isTextOverflow: false }, () => {
      this.submitContentValue('content', value, templateId)
    })
  }

  submitContentValue = _.debounce(
    (n, v, i) => this.handleContentValue(n, v, i),
    1000
  )

  handleContentValue = (n, v, i) => {
    const { onTemplateValue } = this.props
    onTemplateValue(n, v, i)
  }

  onDeleteImage = imgId => {
    const { images = [], image_list = [] } = this.state
    this.setState(
      {
        images: images.filter(item => item !== imgId),
        image_list: image_list.filter(item => item.id !== imgId)
      },
      () => {
        const { onTemplateValue, templateId } = this.props
        onTemplateValue('delImage', imgId, templateId)
      }
    )
  }

  handleScore = value => {
    if (value < 1 || value > 5) {
      this.setState({ score: value, scoreError: true })
      return
    }

    const { onTemplateValue, templateId } = this.props
    this.setState({ score: value, scoreError: false }, () => {
      onTemplateValue('score', value, templateId)
    })
  }

  handleAudioSelect = value => {
    const { onTemplateValue, templateId } = this.props
    this.setState({ voice: value }, () => {
      onTemplateValue('voice', value, templateId)
    })
  }

  handleCheck = () => {
    const {
      onTemplateValue,
      templateId,
      scenarioIndex,
      isOptionChecked
    } = this.props
    onTemplateValue(
      'is_ideal_option',
      !isOptionChecked,
      templateId,
      scenarioIndex
    )
  }

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = (url, title) => {
    this.setState({ isImageLoading: true }, async () => {
      let blob = await fetch(url).then(r => r.blob())
      let data = await new Promise(resolve => {
        let reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
      const body = {
        croppedImage: data,
        lastModifiedDate: new Date(),
        name: title,
        size: blob.size,
        type: blob.type
      }
      this.handleUploadImage(body)
    })
  }

  onDrop = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }

    const { isActiveVideoModal } = this.state

    if (isActiveVideoModal) {
      this.handleUploadImage(file)
      return
    }

    const droppedImage = URL.createObjectURL(file)
    this.setState(
      {
        file,
        droppedImage
      },
      () => this.toggleImageModal('img')
    )
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        droppedImage: null,
        openImagesModal: false,
        isActiveImageModal: false,
        isActiveVideoModal: false,
        isActive3DModal: false
      })
    } else {
      this.setState({
        droppedImage: null,
        openImagesModal: false,
        file: null,
        isActiveImageModal: false,
        isActiveVideoModal: false,
        isActive3DModal: false
      })
    }
  }

  handleCourseSelect = async (id, isMount) => {
    const { getLessonsById, onTemplateValue, templateId } = this.props
    getLessonsById(id)
    this.setState({ course: id, lessonFetching: true }, () => {
      console.log(isMount)
      if (typeof isMount === 'boolean') return
      onTemplateValue('course', id, templateId)
    })
  }

  handleScenarioLink = scenarioCode => {
    const { handleScenarioLink, templateId } = this.props
    this.setState({ scenarioCode }, () => {
      handleScenarioLink(scenarioCode, templateId)
    })
  }

  handleLessonSelect = value => {
    const { onTemplateValue, templateId } = this.props
    this.setState({ lesson: value }, () => {
      onTemplateValue('lesson', value, templateId)
    })
  }

  handleSceneType = value => this.setState({ selectedSceneType: value })

  toggleImageSelectModal = () =>
    this.setState({ showImageSelectModal: !this.state.showImageSelectModal })

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  render() {
    const {
      isScenario,
      scenarioIndex,
      optionIndex,
      t,
      data,
      courseBuilder: {
        lessons: { list, fetch }
      },
      form: { getFieldDecorator },
      templateId,
      lastTemplateIndex = '',
      isOptionChecked,
      showDeadEndOption,
      handleDeadEnd,
      scenarios,
      library: { uploadProgress, uploadSuccess }
    } = this.props
    const {
      src,
      file,
      score,
      course,
      lesson,
      content,
      courses,
      editImage,
      scoreError,
      image_list,
      selectedItem,
      droppedImage,
      lastScenario,
      scenarioCode,
      isTextOverflow,
      isImageLoading,
      isVideoLoading,
      courseFetching,
      selected3DModel,
      isActive3DModal,
      openImagesModal,
      selectedSceneType,
      isShowDetailModal,
      isActiveImageModal,
      openCreateVoiceModal,
      showImageSelectModal
      // openCreateImageModal,
    } = this.state

    const audioModalTitle = (
      <AudioModalTitleIcon
        t={t}
        isShowCreateAudioModal={openCreateVoiceModal}
      />
    )

    const isOptionDelete =
      scenarioIndex === lastScenario &&
      optionIndex > 2 &&
      templateId === lastTemplateIndex
    const isScenarioDelete =
      scenarioIndex === lastScenario && isScenario && templateId !== 1

    var isDeadEndOption = false
    if (!isScenario && data) {
      isDeadEndOption = data.is_dead_end
    }

    console.log({ selectedItem })

    const isImageSelection = selectedSceneType === sceneTypes[0]
    const is3DSelection = selectedSceneType === sceneTypes[1]

    const showLibraryProgress =
      uploadProgress.length > 0 &&
      uploadSuccess.length !== uploadProgress.length &&
      uploadProgress.find(item => item.percent < 100)

    return (
      <div
        className="maze_create__template"
        id={`maze-template-${templateId}`}
        style={{
          width: isScenario ? '97%' : '80%',
          marginLeft: isScenario ? 'null' : 'auto',
          marginRight: isScenario ? 'null' : '3rem'
        }}
      >
        <div className="maze_create__top">
          {!isScenario && isDeadEndOption && (
            <Form.Item colon={false} required={false}>
              {getFieldDecorator('possible_scenario_code', {
                initialValue: scenarioCode
              })(
                <Select
                  placeholder="Link to Scenario"
                  style={{ width: '155px' }}
                  className="template-select"
                  onChange={this.handleScenarioLink}
                  allowClear
                >
                  {scenarios &&
                    scenarios.map(scenario => (
                      <Option key={scenario} value={scenario}>
                        Scenario {scenario}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          )}
          <Form.Item colon={false} required={false}>
            {getFieldDecorator('course', {
              initialValue: course
            })(
              <Select
                placeholder="course"
                style={{ width: '100px' }}
                className="template-select"
                onChange={this.handleCourseSelect}
                loading={courseFetching}
              >
                {courses &&
                  courses.map(item => (
                    <Option
                      className="maze-option"
                      key={item.id}
                      value={item.id}
                    >
                      <Tooltip
                        placement="left"
                        key={item.id}
                        title={item.title}
                      >
                        {item.title}
                      </Tooltip>
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item colon={false} required={false}>
            {getFieldDecorator('lessons', {
              initialValue: lesson
            })(
              <Select
                placeholder="lessons"
                className="template-select"
                style={{ width: '100px' }}
                onChange={this.handleLessonSelect}
                loading={fetch}
                disabled={!course}
              >
                {list &&
                  list.map(item => (
                    <Option key={item.id} value={item.id}>
                      <Tooltip
                        placement="left"
                        key={item.id}
                        title={item.title}
                      >
                        {item.title}
                      </Tooltip>
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          {/* {isScenario && (
            <Button type="default" style={{ borderRadius: '10px' }}>
              Change space
            </Button>
          )} */}
          {isScenario && (
            <>
              Score
              <div className="maze-score">
                <InputNumber
                  name="score"
                  onChange={this.handleScore}
                  style={{
                    width: '50px',
                    borderColor: scoreError || !score ? 'red' : '#d9d9d9',
                    position: 'relative'
                  }}
                  value={score}
                />
                {scoreError && <div className="error_message">Score 1 - 5</div>}
              </div>
              <Form.Item colon={false} required={false}>
                {getFieldDecorator('scene_type', {
                  initialValue: selectedSceneType
                })(
                  <Select
                    placeholder="Scene Type"
                    className="template-select"
                    style={{ width: '110px' }}
                    onChange={this.handleSceneType}
                  >
                    {Object.values(sceneTypes).map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Spin spinning={isImageLoading}>
                <Badge
                  count={
                    (isImageSelection && image_list && image_list.length) ||
                    null
                  }
                >
                  <div
                    className="select_box"
                    onClick={() =>
                      isImageSelection
                        ? this.toggleImageSelectModal()
                        : this.toggleImageModal('3d')
                    }
                    style={{
                      borderColor:
                        (isImageSelection &&
                          image_list &&
                          image_list.length === 0) ||
                        (is3DSelection &&
                          selected3DModel &&
                          !selected3DModel.link)
                          ? 'red'
                          : '#d9d9d9'
                    }}
                  >
                    <img
                      alt=""
                      src={
                        is3DSelection && selected3DModel && selected3DModel.link
                          ? selected3DModel.link
                          : GalleryIcon
                      }
                      style={{
                        objectFit:
                          is3DSelection &&
                          selected3DModel &&
                          selected3DModel.link
                            ? 'fill'
                            : 'contain',
                        height:
                          is3DSelection &&
                          selected3DModel &&
                          selected3DModel.link
                            ? '3rem'
                            : '2.5rem'
                      }}
                    />
                  </div>
                </Badge>
              </Spin>
              {isImageSelection && (
                <>
                  Video
                  <div
                    className={
                      'select_box' +
                      (selectedItem && !selectedItem.vimeo_id ? ' black' : '')
                    }
                    onClick={() =>
                      isVideoLoading ? null : this.toggleImageModal('vid')
                    }
                  >
                    {isVideoLoading ? (
                      <Spin size="small" />
                    ) : selectedItem && selectedItem.vimeo_id ? (
                      <img
                        alt=""
                        src={selectedItem.link}
                        style={{
                          objectFit: 'fill',
                          height: '3rem',
                          backgroundColor: 'black'
                        }}
                      />
                    ) : (
                      <Icon
                        type={
                          selectedItem && !selectedItem.vimeo_id
                            ? 'check-circle'
                            : 'play-circle'
                        }
                        style={{ color: '#136cfb', fontSize: '2rem' }}
                      />
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {isVideoLoading && showLibraryProgress && (
          <div className="maze_create__progress">
            <p>Uploading video...</p>
            <Progress percent={showLibraryProgress.percent} size="small" />
          </div>
        )}

        <div>
          {!isScenario && (
            <input
              style={{ marginRight: '5px' }}
              type="checkbox"
              name={`Scenario ${scenarioIndex}`}
              id={`Scenario ${scenarioIndex} Option ${optionIndex}`}
              checked={isOptionChecked}
              onChange={this.handleCheck}
            />
          )}
          <label for={`Scenario ${scenarioIndex} Option ${optionIndex}`}>
            {isScenario
              ? 'Scenario ' + scenarioIndex
              : `Scenario ${scenarioIndex} - Option ${optionIndex} `}
          </label>
        </div>
        <div className="maze_create__bottom">
          <textarea
            rows="4"
            name="content"
            value={this.capitalizeFirstLetter(content)}
            onChange={e => this.handleValue(e.target.value)}
            style={{ borderColor: content ? '#d9d9d9' : 'red' }}
          />
          {!isScenario && (
            <p
              className={`maze_create__bottom_text ${
                isTextOverflow ? 'red__text' : ''
              }`}
            >
              {content.length + ' / 78'}
            </p>
          )}
        </div>
        {!isScenario && showDeadEndOption && (
          <>
            <div className="maze_create__deadend">
              {isDeadEndOption && <Tag color="red">Dead End</Tag>}{' '}
              <span
                style={{ color: isDeadEndOption ? 'red' : 'blue' }}
                onClick={() =>
                  isDeadEndOption
                    ? handleDeadEnd(templateId, true)
                    : handleDeadEnd(templateId)
                }
              >
                {isDeadEndOption ? 'Unmark' : 'Mark'} as deadend
              </span>
            </div>
          </>
        )}
        {(isOptionDelete || isScenarioDelete) && (
          <Popconfirm
            title={`Are you sure to delete this ${
              isScenario ? 'scenario' : 'option'
            } ?`}
            onConfirm={this.deleteTemplate}
            okText="Yes"
            cancelText="No"
            icon={false}
          >
            <img alt="" className="maze_create__delete" src={DeleteIcon} />
          </Popconfirm>
        )}

        {showImageSelectModal && (
          <Modal
            className="custom-ant-modal"
            visible={showImageSelectModal}
            onCancel={this.toggleImageSelectModal}
            onOk={this.toggleImageSelectModal}
            width={'80%'}
          >
            <MultipleImageSelect
              images={image_list}
              isImageLoading={isImageLoading}
              onOpenModal={() => this.toggleImageModal('img')}
              onDeleteImage={this.onDeleteImage}
            />
          </Modal>
        )}

        {isShowDetailModal && (
          <Modal
            className="custom-ant-modal insert-audio-detail"
            visible={isShowDetailModal}
            onCancel={this.onHideDetailModal}
            width={'40%'}
            footer={false}
          >
            <AddDetailAudio
              handleFileUpload={this.handleFileUpload}
              src={src}
              isFromCreateAudio
              onHideDetailModal={this.onHideDetailModal}
              t={t}
            />
          </Modal>
        )}

        {droppedImage && (
          <ImageCropContainer
            image={droppedImage}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
            t={t}
          />
        )}

        {openCreateVoiceModal && (
          <Modal
            visible={openCreateVoiceModal}
            onCancel={this.toggleVoiceModal}
            title={audioModalTitle}
            className="custom-ant-modal"
            footer={null}
            width={'88%'}
          >
            <CreateAudio
              t={t}
              onShowDetailModal={this.onShowDetailModal}
              text={content}
            />
          </Modal>
        )}
        {openImagesModal && (
          <Modal
            visible={openImagesModal}
            onCancel={() =>
              this.toggleImageModal(isActiveImageModal ? 'img' : '')
            }
            footer={null}
            width={'80%'}
            // style={modal_style}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                isMazeBuilder
                handleInsertData={this.handleInsertData}
                handleInsertFile={this.handleInsertData}
                types={
                  isActiveImageModal
                    ? 'images'
                    : isActive3DModal
                    ? 'AR'
                    : 'videos'
                }
                droppedImage={droppedImage}
                handleUploadImage={this.handleUploadImage}
                handleClosePopup={this.handleClosePopup}
                imageDrop={this.onDrop}
                file={file}
              />
            </div>

            {editImage && (
              <Modal
                visible={editImage}
                width="90%"
                onCancel={this.hidePreviewModal}
                footer={null}
              >
                <Editor
                  library={editImage}
                  ref={this.imageRef}
                  disableOptions={true}
                />
                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(editImage)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
      </div>
    )
  }
}

export default Form.create({ name: 'template' })(Template)
