import React, { Component, Fragment } from 'react'
import { api } from '../../../services'
import './Manager.scss'
import { Modal, Spin, Icon, Pagination, Input } from 'antd'
import className from 'classnames'
import { withTranslation } from 'react-i18next'
import { general } from '../../../constants'

const icon = {
  AUDIO: 'sound',
  VIDEO: 'play-circle',
  IMAGE: 'picture'
}

const Item = props => {
  const { item, type, handleSelect } = props

  if (type === general.AUDIO || type === general.VIDEO) {
    return (
      <div className="media-manager-item" onClick={e => handleSelect(e, item)}>
        <Icon type={icon[type]} /> {item.filename}
      </div>
    )
  } else if (type === general.IMAGE) {
    return (
      <Fragment>
        <div
          className="media-manager-item"
          onClick={e => handleSelect(e, item)}
          style={{ backgroundImage: 'url(' + item.link + ')' }}
        />
        {item.filename}
      </Fragment>
    )
  }
}

class Manager extends Component {
  constructor(props) {
    super(props)
    this.onHandleSearch = this.onHandleSearch.bind(this)
    this.state = {
      isFetching: true,
      page: 1,
      pageSize: 6,
      data: {
        count: null,
        rows: []
      }
    }
  }

  getLibrary = (search = '') => {
    const { type } = this.props
    const { page, pageSize } = this.state

    api.library
      .getLibraryList({
        type,
        search,
        offset: page * pageSize - pageSize,
        limit: pageSize
      })
      .then(response => {
        this.setState({
          isFetching: false,
          data: response.data
        })
      })
  }

  componentDidMount() {
    this.getLibrary()
  }

  onClose = () => {
    const { closeManager } = this.props

    if (closeManager) {
      closeManager()
    }
  }

  handleSelect = (e, item) => {
    const { handleManager } = this.props

    if (handleManager) {
      handleManager(
        e,
        {
          content: item.link,
          content_id: item.id
        },
        item
      )

      this.onClose()
    }
  }

  onChangePage = (page, pageSize) => {
    this.setState(
      {
        page,
        pageSize
      },
      this.getLibrary
    )
  }

  onHandleSearch = e => {
    var searchText = e.target.value // this is the search text
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      //search function
      this.setState(
        {
          isFetching: true
        },
        () => {
          this.getLibrary(searchText)
        }
      )
    }, 500)
  }

  render() {
    const { isFetching, data, page, pageSize } = this.state
    const { type, t } = this.props

    return (
      <Modal
        visible
        onCancel={this.onClose}
        title={t('labels:media_manager')}
        footer={null}
        destroyOnClose
        centered
      >
        <Spin spinning={isFetching}>
          <div className="media-manager">
            <div className="media-manager__inner">
              <div>
                <Input
                  type="text"
                  placeholder={t(`placeholders:Search ${type} `)}
                  size="large"
                  onChange={e => {
                    this.onHandleSearch(e)
                  }}
                />
              </div>
              <div
                className={className({
                  'media-manager__list':
                    type === general.AUDIO || type === general.VIDEO,
                  'media-manager__grid': type === general.IMAGE
                })}
              >
                {data.rows.map(item => (
                  <div key={item.id} className="media-manager__item">
                    <Item
                      item={item}
                      type={type}
                      handleSelect={this.handleSelect}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/*
             * Library pagination
             */}

            <div className="media-manager__controls">
              <Pagination
                total={data.count}
                current={page}
                pageSize={pageSize}
                onChange={this.onChangePage}
              />
            </div>
          </div>
        </Spin>
      </Modal>
    )
  }
}

export default withTranslation()(Manager)
