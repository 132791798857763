import React from 'react'

function Footer({ children }) {
  return (
    <div className="p-footer">
      <div className="p-footer__inner">{children}</div>
    </div>
  )
}

export default Footer
