import { call, put } from 'redux-saga/effects'
import { courseCreate, courseUpdate, getLessonsInCourse, lessonFromCourseDelete } from '../../services/api/courses'

import * as courses from '../actions/courseBuilder'

export function * createCourse (action) {
  yield put(courses.setCourseCreation(true))
  try {
    const response = yield call(courseCreate, action.data)
console.log('middlewaredata', action.data);
    if (response.status === 200) {
      yield put(courses.handleCourseInput('courseId', response.data.id))
      yield put(courses.setCourseCreation(false))
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * updateCourse (action) {
  try {
    yield call(courseUpdate, action.data, action.id)
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * getLessonsList (action) {
  try {
    yield put(courses.handleCourseInput('lessons', {
      list: [],
      fetch: true
    }))

    const response = yield call(getLessonsInCourse, action.id)

    if (response.status === 200) {
      yield put(courses.handleCourseInput('lessons', {
        list: response.data,
        fetch: false
      }))
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * deleteLessonFromCourse (action) {
  try {
    yield put(courses.handleCourseInput('lessons', {
      list: [],
      fetch: true
    }))

    yield call(lessonFromCourseDelete, action.courseId, action.lessonId)

    const response = yield call(getLessonsInCourse, action.courseId)
    if (response.status === 200) {
      yield put(courses.handleCourseInput('lessons', {
        list: response.data,
        fetch: false
      }))
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
