import styled from 'styled-components'
import { styleguide } from '../../constants'

const { colors } = styleguide

const Page = styled.div``

Page.Column = styled.div`
  margin-bottom: 2.4rem;
`

Page.Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.3rem;
  border: 1px dashed ${colors.darkGreyTwo};
  transition: border-color 0.2s;
  cursor: pointer;
  height: 300px;
  width: 100%
  &:hover {
    border-color: ${colors.blue};
  }

  ${props =>
    props.active &&
    `
    background: rgba(0, 104, 255, 0.1);
    border-color: ${colors.blue};
  `}

  ${props =>
    props.background &&
    `
    background-image: url(${props.background});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  `}
`

Page.Dropzone.Text = styled.div`
  font-weight: 700;
  font-size: 1.6rem;
`
Page.Dropzone.Button = styled.div`
  padding: 0.4rem 2.4rem;
  text-decoration: uppercase;
  border: 1px solid ${colors.darkGreyThree};
  border-radius: 5rem;
  background: rgba(255, 255, 255, 0.8);

  display: flex;
  align-items: center;
  transition: border-color 0.2s, color 0.2s, opacity 0.2s;

  i {
    margin-right: 0.8rem;
  }

  ${Page.Dropzone}:hover & {
    border-color: ${colors.blue};
    color: ${colors.blue};

    ${props =>
      props.hide &&
      `
      background: rgba(255, 255, 255, 0.3);
      border-color: ${colors.white};
      color: ${colors.white};
      opacity: 1;
    `}
  }

  ${props =>
    props.hide &&
    `
    opacity: 0;
    border-color: ${colors.white};
    color: ${colors.white};
  `}
`

export { Page }
