/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: 'enregistrer',
    autosaved: 'Enregistré automatiquement',
    back_to: 'Retour à',
    by: 'Par',
    chat: 'Bavarder',
    completed: 'Terminé',
    canceled: 'Annulé',
    component: 'Composante',
    confirm: 'Confirmer',
    course: 'Cours',
    date: 'Date',
    details: 'Détails',
    empty: ' Aucune donnée',
    enrolled: ' Inscrit',
    free: 'Gratuit',
    graph: 'Graphique',
    loading: 'Chargement...',
    lesson: 'leçon',
    lessons: 'leçons',
    live_assessment: 'Evaluation en direct',
    live_event: ' Evénement en direct',
    no: 'Non',
    of: 'de',
    or: 'ou',
    organization: 'Organisation',
    page: 'Page',
    pages: 'Pages',
    personal: 'Personnel',
    poll: 'Sondage',
    rating: 'évaluation',
    reset: 'Réinitialiser',
    signin: 'Se connecter',
    signup: `S'inscrire`,
    soon: 'Bientôt',
    type: 'Type',
    uploading: 'Chargement...',
    yes: 'Oui',
    something_went_wrong: 'Quelque chose a mal tourné !',
    view_all: 'Tout afficher',
    upload_successful: 'Télécharger avec succès',
    upload_successful_message:
      'Vos documents excel ont été téléchargés avec succès et sont en cours de traitement. Cela peut prendre un certain temps',
    check_email_upload_successful:
      'Veuillez <0>vérifier votre email</0> pour le <1>rapport</1> de la liste des utilisateurs téléchargés',
    is_private: 'Privé'
  },

  account: {
    title: 'Compte',
    subtitle:
      'Gérer vos paramètres et les informations relatives à votre compte',
    saved: 'Compte modifié avec succès'
  },

  assessments: {
    title: 'Évaluations',
    subtitle: 'Créer une évaluation',
    finished: `L'évaluation est terminée`,
    countdown: `L'évaluation en direct sera diffusée en`,
    create: 'Créer une évaluation',
    list_title: `Vos évaluations`,
    delete_warning: `Etes-vous sûr de vouloir supprimer ce point ?`,
    details_title: `Ajouter les détails de l'évaluation`,
    details_subtitle: `Nommez votre évaluation et dites à votre public ce qu'il doit s'attendre à en tirer`,
    disclaimer: `Je garantis que mon évaluation enregistrée ne le sera pas :
    - ne portera pas atteinte aux droits d'un tiers
  - contenir toute illégalité, y compris un contenu diffamatoire, obscène, indécent, obscène, pornographique, violent, abusif, insultant, menaçant ou discriminatoire, même déguisé par des astérisques
  - contenir toute attaque personnelle contre d'autres personnes
  - contenir toute usurpation d'identité d'autres participants, de personnalités publiques ou de célébrités
  - contiennent un enregistrement ou des informations personnelles sur un individu sans le consentement de cet individu et ne violent aucune réglementation en matière de protection des données dans aucune partie du monde
  - contenir toute sollicitation commerciale ou toute forme de "spam".`,
    assessment_details: 'Détails de l’évaluation',
    assessment_settings: 'Paramètres d”évaluation'
  },
  buildersettings: {
    title: 'Publier les paramètres',
    subtitle:
      'Ajouter des paramètres à votre événement, qui peut voir et interagir avec votre événement'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: 'Ajouter',
    add_page: 'Ajouter une page.',
    add_lesson: 'Ajouter des leçons',
    add_answer: 'Ajouter une nouvelle réponse',
    add_group: 'Ajouter un groupe',
    add_organization: 'Ajouter une organisation',
    add_question: 'Ajouter une question',
    add_quiz: 'Ajouter un quiz',
    add_users: 'Ajouter des utilisateurs',
    back: 'Retour',
    back_to_course: `Retour à l'aperçu des cours`,
    back_to_lesson: `Retour à l'aperçu des leçons`,
    back_to_media: `Retour à la médiathèque`,
    back_to_details: 'Retour aux détails',
    cancel: 'Annuler',
    clear: 'Effacer',
    close: ' Fermer',
    clone: 'Clone',
    close_lesson: 'Fermer la leçon',
    clone_course: 'Cours de clonage',
    confirm: 'Confirmer',
    create: ' Créer',
    download: 'Télécharger',
    download_mass_user_tamplate: 'Télécharger le modèle',
    add_mass_users: 'Ajouter des utilisateurs en masse',
    upload_excel_list: 'Téléchargez la liste Excel',
    delete: 'Supprimer les médias',
    edit: 'Modifier',
    edit_course: 'Modifier le Cours',
    enroll: 'Inscrivez-vous',
    finish: 'Finir',
    upload_image: `Téléchargez l'image`,
    upload_image_video: 'Téléchargez une image ou une vidéo',
    select_media_library: 'Sélectionnez la médiathèque',
    make_public: 'Rendre public',
    make_private: 'Rendre privé',
    make_org: `Faire de l'organisation`,
    media: 'Médiathèque',
    my_theme: 'Mon thème',
    next: 'Suivant',
    next_lesson: 'Leçon suivante',
    next_step: 'Prochaine étape',
    previous_step: 'Étape précédente',
    publish: 'Publier',
    publish_assessment: 'Publiez votre évaluation',
    publish_course: 'Publiez votre cours',
    publish_event: 'Publiez votre événement',
    reset: 'Réinitialisation',
    reset_elements: 'Réinitialisation des éléments',
    save: 'Enregistrer',
    creating: 'Créer...',
    updating: 'Mise à jour...',
    saved: 'Enregistré',
    save_for_later: 'Enregistrer pour plus tard',
    save_settings: 'Enregistrer les paramètres',
    select_files: 'Sélectionner les fichiers',
    select_theme: 'Sélectionnez un thème',
    start_lesson: 'Commencer une leçon',
    update: 'Mise à jour',
    upload: 'Télécharger',
    upload_audio: `Téléchargez l'audio`,
    upload_photo: 'Téléchargez une photo de profil',
    upload_video: 'Téléchargez la vidéo',
    enrolled: 'Inscrit',
    unenroll: 'Désinscription',
    from_library: 'Médiathèque',
    remove: 'Supprimer',
    removed: 'Supprimée',
    start_broadcast: 'Démarrer la diffusion',
    end_broadcast: 'Fin de la diffusion',
    camera: 'Caméra',
    screen: 'Écran',
    screen_with_camera: 'Écran avec caméra',
    insert: 'Insérer',
    inserting: 'Insertion'
  },
  card: {
    quick_add: 'Ajout rapide',
    add: 'Ajouter',
    clone: 'Clonage',
    completed: 'leçons achevées.'
  },
  course: {
    upcoming_course: 'Cours à venir',
    unPublishEvent: 'Événement non publié',
    unPublishAssessment: 'Évaluation non publiée',
    pastCourse: 'Cours de formation',
    upcoming: 'Prochain événement en direct',
    pastEvent: 'Evénements passés en direct',
    pastAssessment: 'Évaluation de la vie passée',
    upcomingAssessment: 'Évaluation en direct à venir',
    created: 'Date de création :',
    assignees: 'cessionnaires',
    organisations:
      '{{{ organisations }} organisations, <2> {{{{{utilisateurs}} utilisateurs</2>',
    event_date: `Date de l'événement en direct <1> {{ date }} </1>`,
    delete_warning: 'Etes-vous sûr de vouloir supprimer ?',
    clonning_no_lesson:
      'Veuillez ajouter au moins une leçon afin de cloner ce cours',
    clone_warning: 'Vous êtes sûr de vouloir cloner ceci ?',
    clone_success: 'est en cours de clonage',
    disclaimer: `Je garantis que mon cours ne le fait pas :
    - ne porte pas atteinte aux droits d'un tiers
    - contenir toute illégalité, y compris un contenu diffamatoire, obscène, indécent, obscène, pornographique, violent, abusif, insultant, menaçant ou discriminatoire, même déguisé par des astérisques
    - contenir toute attaque personnelle contre d'autres personnes
    - contenir toute usurpation d'identité d'autres participants, de personnalités publiques ou de célébrités
    - ne contiennent aucune information personnelle sur un individu sans le consentement de celui-ci et ne violent aucune réglementation sur la protection des données dans aucune partie du monde
    - contenir toute sollicitation commerciale ou toute forme de "spam".`
  },

  coursecreate: {
    dropzone_text: 'Insérer un média',
    sidebar_text: `Sélectionnez un thème <1/> pour vos cours <3/>`,
    delete_warning: `Etes-vous sûr de vouloir supprimer cette leçon ?`
  },
  coursepage: {
    quizview_loading: 'Vérification des réponses. Veuillez patienter...',
    quizview_answer: 'Votre réponse'
  },
  coursequiz: {
    title: 'Des quizz dans tous les cours'
  },

  dashboard: {
    tooltip_title: 'Ne plus montrer cette vidéo',
    page_title: 'Statistiques Vos cours',
    page_subtitle: 'Statut des cours que vous avez créés',
    profile_add_info: `Ajoutez votre <1>{{{texte non complété}}</1> pour compléter votre profil`,
    profile_build: `Construisez votre profil <1>ici</1>, faites-nous part de vos intérêts et de vos compétences`,
    profile_title: 'Force du profil',
    more_details: 'Plus de détails'
  },

  errors: {
    answer: 'Veuillez saisir votre réponse',
    answer_correct: 'Choisissez la bonne réponse',
    code: 'Veuillez entrer votre code',
    criterion: 'Veuillez saisir le critère',
    duration: 'La durée est obligatoire',
    element: `Veuillez saisir l'élément`,
    email: 'Veuillez saisir votre adresse électronique',
    email_invalid: `Ce n'est pas un courriel valide`,
    feedback_correct: 'Veuillez saisir les informations correctement',
    feedback_incorrect: 'Veuillez saisir un commentaire incorrect',
    host: `Veuillez indiquer le nom de l'organisateur de l'événement`,
    host_assessment: `Veuillez indiquer l'hôte de l'évaluation`,
    image: 'Veuillez télécharger une image',
    location: 'Veuillez indiquer votre localisation',
    live_location: 'Veuillez choisir un lieu de diffusion',
    limit: 'Veuillez saisir les minutes de retransmission en direct',
    min_3char: '3 caractères minimum',
    number: 'Veuillez saisir un nombre positif',
    host_email_require: `Les courriels de l'hôte sont obligatoires`,
    attendee_email_require: `Les courriels des participants sont obligatoires`,
    name: 'Veuillez saisir votre nom',
    not_found_course: 'Cours introuvable',
    not_found_event: 'Événement introuvable',
    not_found_assessment: 'Évaluation introuvable',
    password: 'Veuillez saisir votre mot de passe',
    password_digit: 'Au moins un chiffre',
    password_short: 'Le mot de passe est trop court, 10 caractères minimum',
    password_special: 'Au moins un caractère spécial',
    password_uppercase: 'Au moins un caractère majuscule',
    password_lowercase: 'Au moins un caractère minuscule',
    phone: 'Veuillez saisir votre numéro de téléphone',
    phone_invalid: 'Numéro de téléphone invalide',
    position: 'Veuillez indiquer votre position',
    question: 'Veuillez saisir la question',
    start_date: `La date et l'heure de début sont requises`,
    tag: 'Veuillez sélectionner une balise',
    topics: 'Veuillez sélectionner au moins un sujet',
    live_topics: 'Veuillez ajouter des sujets',
    title: 'Veuillez saisir le titre',
    title_assessment: `Veuillez saisir le titre de l'évaluation`,
    title_course: 'Veuillez saisir le titre du cours',
    title_empty: 'Le titre ne peut pas être vide',
    title_event: `Veuillez saisir le titre de l'événement`,
    title_lesson: `Veuillez saisir le titre de la leçon`,
    title_quiz: 'Veuillez saisir le nom du quiz',
    username: `Veuillez saisir votre nom d'utilisateur`,
    upload_error: `Erreur - Fichier non compatible !`,
    upload_more_than_10:
      'Vous ne pouvez télécharger que 10 fichiers maximum à la fois',
    topics_max: 'Veuillez ajouter 3 sujets au maximum',
    upload_excel:
      'Veuillez vous assurer que vous avez téléchargé le bon fichier',
    title_excel: `Modèle de téléchargement d'erreur :`,
    file_size_exceed: 'La taille du fichier est supérieure',
    subtitle_excel:
      'Veuillez vous assurer que vous utilisez le modèle fourni et que les colonnes sont correctement remplies',

    select_broadcast_option:
      'Veuillez sélectionner une option pour la diffusion de cet événement',
    multi_hosts_number_error: `Le nombre de présentateurs ne peut pas dépasser celui qui est spécifié pour l'événement`,
    conference_people_number_error: `Le nombre de participants ne peut pas dépasser celui qui est spécifié pour l'événement`,
    select_max_host: `Sélectionnez au moins un présentateur pour l'événement`,
    select_max_conference: `Sélectionnez au moins un participant pour l'événement`,
    stream_stop_error: `Le streaming s'est arrêté de manière inattendue !`,
    unsecure_context: `Erreur fatale : Le navigateur ne peut pas accéder à la caméra et au micro en raison d'un contexte non sécurisé. Veuillez installer le SSL et l'accès via https`,
    ws_not_supported: `Erreur fatale : WebSocket n'est pas pris en charge par ce navigateur.`,
    no_org: 'Choisir au moins une organisation pour continuer.',
    no_org_group: 'Choisir au moins un groupe d’organisations pour continuer.',
    waiting_to_join: `En attendant que quelqu'un se joigne!`
  },

  events: {
    title: `Ajouter les détails d'un événement en direct`,
    change_host: `Changer d'hôte`,
    finished: `L'événement est terminé`,
    countdown: `L'événement sera retransmis en direct`,
    subtitle: `Nommez votre événement Live et dites à votre public ce qu'il doit en attendre`,
    poll_disabled: `Le sondage n'est pas disponible dans ce cas`,
    disclaimer: `Je garantis que mon événement enregistré ne le sera pas :
      - porter atteinte aux droits de tout tiers
    - contenir toute illégalité, y compris un contenu diffamatoire, obscène, indécent, obscène, pornographique, violent, abusif, insultant, menaçant ou discriminatoire, même déguisé par des astérisques
    - contenir toute attaque personnelle contre d'autres personnes
    - contenir toute usurpation d'identité d'autres participants, de personnalités publiques ou de célébrités
    - contiennent un enregistrement ou des informations personnelles sur un individu sans le consentement de cet individu et ne violent aucune réglementation en matière de protection des données dans aucune partie du monde
    - contiennent toute sollicitation commerciale ou toute forme de "spam"`,
    prompt_message:
      'En quittant cette page, vous terminez votre événement. Etes-vous sûr de quitter cette page ?',
    duration_exceed_message: `Votre temps de diffusion dépasse, l'application va cesser de diffuser`,
    before_schedule_message: `Vous êtes sur le point de commencer la diffusion avant l'heure prévue de l'événement. Veuillez noter que l'arrêt de la diffusion mettra fin à l'événement`,
    browser_unsupportive_message: `Votre navigateur ne prend pas en charge le partage d'écran. Vous pouvez voir les navigateurs pris en charge dans ce lien`,
    cancel_switch_host_message: `L'hôte n'est pas disponible. L'événement sera poursuivi de votre côté`,
    coaching_prompt_message: `Vous ne pouvez pas quitter cette page tant que l'événement n'est pas terminé`,
    event_modal_warn: `Voulez-vous quitter cet événement ?`,
    confirm_message: `Votre événement se terminera si vous cliquez sur OK`,
    screen_share_denied: 'Vous avez refusé de partager votre écran',
    media_not_found: `L'appareil photo ou le micro ne sont pas trouvés ou ne sont pas autorisés dans votre appareil`,
    media_not_access:
      'La caméra ou le micro est utilisé par un autre procédé qui ne permet pas de lire les appareils',
    constraint_error: `Il n'y a pas de dispositif trouvé qui corresponde à vos contraintes vidéo et audio. Vous pouvez modifier les contraintes vidéo et audio`,
    media_access_denied: `Vous n'êtes pas autorisé à accéder à la caméra et au micro.`
  },

  filters: {
    days7: 'Les 7 derniers jours',
    days15: 'Les 15 derniers jours',
    days30: 'Les 30 derniers jours',
    days365: 'Les 365 derniers jours',
    date: ' Date de création',
    relevance: 'Pertinence',
    popularity: 'Popularité',
    name: 'Nom',
    group: 'Groupe',
    Newest_to_Oldest: 'Du plus récent au plus ancien',
    Oldest_to_Newest: 'Du plus ancien au plus récent',
    A_Z: 'De A à Z',
    Z_A: 'De Z à A'
  },

  helpers: {
    password: `Pour garantir la sécurité de votre compte, nous vous demandons d'entrer au moins un numéro en minuscules dans votre mot de passe. Vous devez également avoir une longueur minimale de 10 caractères`,
    phone: `Veuillez indiquer l'indicatif de votre pays, par exemple : +65`,
    username: `Votre nom d'utilisateur est traité comme une information publique et partagé avec d'autres utilisateurs selon les besoins. Nous vous recommandons donc de ne pas utiliser d'informations personnelles dans votre nom d'utilisateur`,
    public_description_normal: `Votre <0><0/></0> a été enregistré et est considéré comme privé par défaut. Cela signifie que vous seul pouvez voir ce contenu. Pour publier ce contenu, vous pouvez sélectionner l'une des options ci-dessous. Publier votre cours en Public signifie qu'il sera disponible pour tous les utilisateurs de la plate-forme. L'option Utilisateurs spécifiques vous permettra de partager votre cours avec des utilisateurs spécifiques en utilisant l'adresse électronique associée à leur compte Sharelook`,

    public_description_admin: `Ce <0><0/></0> a été enregistré et est considéré comme privé par défaut. Cela signifie que seul vous (et le créateur du contenu) pouvez voir ce contenu . Pour publier ce contenu, vous pouvez sélectionner l'une des options ci-dessous. Publier votre cours au public signifie qu'il sera disponible pour tous les utilisateurs de la plate-forme - y compris ceux qui ne font pas partie de votre organisation. La publication au sein de l'organisation signifie que tous les utilisateurs au sein de l'organisation pourront le voir. Vous pouvez également le publier pour un ou plusieurs groupes, ce qui signifie que seuls les utilisateurs de ces groupes pourront le voir.  Enfin, vous avez la possibilité de le publier à des utilisateurs spécifiques au sein et en dehors de votre organisation en utilisant l'adresse électronique de leur compte Sharelook.`
  },

  labels: {
    add: 'Ajouter',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: 'Type de réponse',
    answers: 'Réponses',
    back_to_quiz: 'Retour à la liste des quiz',
    back_to_question: 'Retour à la liste des questions',
    chat: 'Chat',
    chat_available: `Le chat n'est pas disponible dans ce cas`,
    chat_report: 'Rapport de chat',
    choice_single: 'Choix unique',
    choice_multiple: 'Choix multiple',
    choice_free: `Le "libre" choix`,
    choice_sorting: `Choix de "tri"`,
    choice_matrix: `Choix de "tri matriciel"`,
    clone_lesson_permission: 'Permission de cloner',
    code: 'Code',
    correct: 'Correct',
    created: 'Date de création',
    clone_modal: 'Cours de clonage',
    cloning_course: 'Cours de clonage...',
    cloning: 'Clonage...',
    cloning_course_desc: `Ce processus prendra un certain temps. Soyez patients`,
    cloning_success: `Le cours "<0/>" a été cloné avec succès !`,
    description: 'Description',
    disclaimer: 'Clause de non-responsabilité',
    drop_file: 'Déposez le fichier ici...',
    duration: 'Durée',
    edit: 'Modifier',
    email: 'Email',
    event: 'Evènement',
    event_all: 'Tous ses événements',
    event_date: 'Événement en direct, date/heure locale',
    event_this: 'Cet événement',
    expire: 'Expire le',
    expiry_date: `Date d'expiration`,
    forgot: `Mot de passe oublié`,
    group: 'Groupe',
    groups_list: 'Liste de Groupes',
    host: 'Hôte',
    id_annotation: 'ID de l’annotation',
    id_quiz: ' id du Quiz',
    incorrect: 'Incorrect',
    interests: 'Intérêts',
    invited: 'Invité',
    live_chat: 'Discussion en direct',
    live_stream_limit: 'diffusion en direct',
    contact_text: `Pour modifier votre limite mensuelle de diffusion en direct, veuillez contacter`,
    contact_admin:
      '<0>Si vous souhaitez modifier votre limite mensuelle de diffusion en direct, veuillez contacter</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      'Les minutes restantes de la diffusion en direct : <0>{{{limite }}</0> minutes <1/><2/> <3>Pour modifier votre limite mensuelle de diffusion en direct, veuillez contacter</3> <4>admin@sharelook.com</4>',
    location: 'Localisation',
    lessons: 'Lecons',
    location_broadcast: 'Emplacement du serveur hôte',
    location_broadcast_subtitle: `Ajouter un emplacement qui soit le plus proche de celui de l'hôte de la diffusion afin de réduire le retard audiovisuel`,
    media_manager: 'Responsable des médias',
    minutes: 'Minutes',
    name: 'Nom',
    online_quiz: 'Quiz en ligne',
    organiser: 'Auteur',
    organization: 'Organisation',
    overall_emotion: 'Expérience globale :',
    organizations_list: 'Liste des organisations',
    password: 'Mot de passe',
    participants: 'Participants',
    picture: 'image (16:9)',
    phone: 'Tél',
    photo: 'Photo',
    poll: 'Sondage',
    position: 'Poste',
    processing: 'Traitement',
    privacy_settings: 'Paramètres de confidentialité',
    question: 'Question',
    quiz_create: 'Créer un nouveau quiz',
    quiz_name: 'Nom du quiz',
    quiz_update: 'Mise à jour du quiz',
    select_organization: 'Sélectionnez une organisation',
    settings: 'Paramètres',
    show: 'Afficher',
    show_graph: 'Afficher le résultat du graphique',
    signin: 'Connexion',
    signup: 'Inscription',
    sort_by: 'Trier par',
    start_date: 'Date de début',
    tags: 'Balises',
    terms: `Je suis d'accord avec ces <1>modalités et conditions</1>`,
    title: 'Titre',
    topics: 'Sujets',
    topic: 'Sujet',
    total_comments: 'Total des commentaires',
    username: `Nom d'utilisateur`,
    users_list: 'Liste des utilisateurs',
    users_emails: `Insérer les courriels d'utilisateurs spécifiques`,
    description_specifiction_email:
      'Veuillez entrer une adresse électronique par ligne pour la partager avec plusieurs utilisateurs spécifiques',
    message_specific_email: `Veuillez saisir l'adresse électronique de l'utilisateur spécifié`,
    viewers: 'Spectateurs',
    edit_course: 'Modifier',
    edit_assessment: 'Modifier',
    edit_event: 'Modifier',
    delete_course: 'Effacer',
    delete_event: 'Effacer',
    delete_assessment: ' Effacer ',
    view_course: ' Voir',
    view_event: ' Voir ',
    view_assessment: ' Voir ',
    date_event: `Date de l'événement en direct`,
    date_assessment: `Date de l'évaluation en direct`,
    clone_of: ' Clone',
    clone_course: `Créez un clone de ce cours que vous pouvez modifier indépendamment de l'original et qui comprend toutes les leçons et les pages`,
    make: 'Faire',
    available_to: `disponible à tout utilisateur de ShareLook`,
    uploaded_by: 'Téléchargé par : ',
    course: 'Cours',
    assessment: ' Évaluation',
    user_not_exist: `L'utilisateur n'existe pas, vous voulez envoyer une invitation?`,
    check_user: 'Avis',
    user_not_exist_in_org: `L'utilisateur n'existe pas dans cette organisation, souhaitez-vous envoyer une invitation ?`,
    title_modal_stream: `Changer l'organisation de la limite du flux de vie`,
    des_warning_stream: 'Vous êtes sur le point de changer',
    monthly_stream: 'limite mensuelle de diffusion en direct :',
    min_per_month: 'Min/mois',
    broadcast_label: 'Je veux faire :',
    hosts_emails: `Insérer les e-mails d'un hôte spécifique`,
    attendee_emails: `Insérer les courriels d'un participant spécifique`,
    description_host_specifiction_email:
      'Veuillez entrer une adresse électronique par ligne',
    presenter_label: 'Présentateur',
    presenter_label_conference: 'Nombre de personnes',
    event_privacy: 'Mode conférence',
    alert: 'Alerte!',
    warning: 'Avertissement!',
    ok: `D'accord`,
    cancel: 'Annuler',
    done: 'Terminé',
    insert: 'Insérer',
    private: 'Privée',
    public: 'Publique',
    no_connection: 'Connexion Internet perdue',
    switch: 'Commutateur',
    share_screen: `Partager l'écran`,
    stop_share_screen: `Arrêter l'écran de partage`,
    share_screen_with_camera: `Partager l'écran avec la caméra`,
    stop_share_screen_with_camera: `Arrêter le partage d'écran avec la caméra`,
    camera_on: 'Caméra allumée',
    camera_off: 'Caméra désactivée',
    mic_on: 'réactiver',
    mic_off: 'muette',
    fullscreen: 'plein écran',
    live_now: 'Vis maintenant',
    schedule_on: 'Programmer pour',
    organisations: 'Organisations',
    org_groups: `Groupes d'organisation`,
    specific_user: 'Utilisateur spécifique',
    gif: 'GIF',
    unavailable: 'indisponible',
    yes: 'Oui',
    no: 'Non',
    event_duration_message: 'Max 300 minutes (5 heures)',
    send_email: 'Envoyer une notification par e-mail',
    admin_only: 'Admin seulement',
    join: 'Joindre',
    leave: 'Laisser'
  },

  broadcastOptions: {
    stream_from_phone: 'Diffusion en direct avec mon téléphone',
    other_webrtc: 'Webinaire depuis mon PC',
    multihost: 'Webinaire multidiffusion à partir de mon PC',
    peer2peer: 'Session de coaching',
    conference: 'Conférence en direct'
  },

  lessoncreate: {
    title: 'Ajoutez les détails de la leçon',
    subtitle: `Nommez votre leçon et dites à votre public ce qu'il doit s'attendre à en tirer`,
    input_label_title: 'Titre de la leçon',
    input_label_topics: 'Thèmes des leçons',
    input_label_description: 'Description de la leçon',
    delete_warning: `Etes-vous sûr de vouloir supprimer cette page ?`
  },

  lessonoverview: {
    title: 'Ajouter une leçon',
    subtitle: 'Ajouter une leçon existante ou en créer une nouvelle',
    new_lesson: 'créer une nouvelle leçon',
    new_lesson_sub: 'Créez une toute nouvelle leçon à partir de zéro !',
    existing_lesson: 'Sélectionnez une leçon existante',
    existing_lesson_sub: `Lien vers une leçon existante, les modifications apportées à cette leçon affecteront également cette leçon dans d'autres cours`,

    clone_lesson: 'dupliquer une leçon existante',
    clone_lesson_sub: `Faites une copie unique de la leçon dans ce cours, les modifications apportées à cette leçon n'affecteront pas la leçon originale`,
    clone_lesson_modal_sub: `Veuillez cliquer sur le bouton "clone" situé sous la leçon que vous souhaitez ajouter à votre cours.`
  },

  management: {
    groups_title: 'Gérer les groupes',
    delete_warning: 'Êtes-vous sûr ?',
    organizations_title: 'Mes Organisations',
    organizations_subtitle: 'Ajoutez des organisations à votre liste',
    users_title: 'Gérer les utilisateurs',
    users_position: 'Responsable de compte',
    user_not_found: 'Utilisateur introuvable',
    select_org:
      'Veuillez trouver et choisir une organisation pour gérer les utilisateurs'
  },

  audio: {
    modal_title: 'Insérer un Audio',
    list_view: 'Vue de la liste',
    create_audio_modal_title: 'Créez un fichier audio',
    edit_audio_modal_title: 'Editer un Audio',
    public_audio_not_found: 'Audio non trouvé',
    private_audio_not_found: `Vous n'avez pas encore téléchargé d'audio`,
    are_you_sure_to_delete: `Êtes-vous sûr de vouloir supprimer ?`,
    type_something: 'Tapez quelque chose',
    select_voice: 'Selectionner',
    file_name: 'Nom du fichier',
    description: 'Description',
    topic: 'Sujet',
    key_words_tag: 'Mot clé',
    mp3_audio: ' Audio MP3 ',
    created: ' Créé ',
    duration: 'Durée',
    close: 'Fermer'
  },

  image: {
    modal_title: 'Insérer une Image',
    image: 'Image',
    pixel: 'Pixel',
    dimension: 'Dimension',
    public_image_not_found: 'Image introuvable.',
    private_image_not_found: `Vous n'avez pas encore téléchargé d'image`
  },

  annotation: {
    modal_title: 'Annotation'
  },

  text: {
    modal_title: 'Éditeur de texte'
  },

  video: {
    modal_title: 'Insérer une Video',
    image: 'Vidéo',
    mp4_video: 'MP4 Vidéo',
    public_video_not_found: 'Vidéo introuvable.',
    private_video_not_found: `Vous n'avez pas encore téléchargé de vidéo`
  },

  media: {
    title: 'Médiathèque',
    subtitle: 'Bibliothèque de vos dossiers',
    modal_title: 'Téléchargez les médias',
    delete_warning: 'Confirmer la suppression',
    delete_message:
      'Les supports supprimés apparaîtront toujours sur les pages où ils ont été utilisés',
    dropzone_text: `ou "glisser-déposer"`,
    delete_success: 'Le média a été supprimé avec succès',
    upload_file: 'Téléchargez le fichier',
    drag_drop: 'ou "glisser-déposer"',
    import_file: 'Importer un ou plusieurs fichiers de votre ordinateur',
    no_media: `Vous n'avez encore téléchargé aucun média`,
    media_type: 'Type de média',
    video: 'Vidéo',
    image: 'Image',
    audio: 'Audio',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: 'Médias introuvables',
    of: 'de',
    disclaimer: `Je garantis que mes médias ne le font pas :
    - n'enfreignent pas les droits d'un tiers
    - contenir toute illégalité, y compris un contenu diffamatoire, obscène, indécent, obscène, pornographique, violent, abusif, insultant, menaçant ou discriminatoire, même déguisé par des astérisques
    - contenir toute attaque personnelle contre d'autres personnes
    - contenir toute usurpation d'identité d'autres participants, de personnalités publiques ou de célébrités
    - ne contiennent aucune information personnelle sur un individu sans le consentement de celui-ci et ne violent aucune réglementation sur la protection des données dans aucune partie du monde
    - contenir toute sollicitation commerciale ou toute forme de "spam".`,
    my_media: 'Mes médias',
    public_library: 'Bibliothèque publique',
    organization_library: `Bibliothèque de l'organisation`,
    upload_from_computer: `Télécharger à partir de l'ordinateur`,
    create: 'Créer',
    all: 'Tous',
    male: 'Masculin',
    female: 'Feminin',
    processing_audio: `Traitement de l'audio`,
    create_annotation: 'Créer une annotation',
    popup_over_lesson_text: `L'annotation apparaîtra sur la page de la leçon après le clic`,
    edit_detail: 'Modifier le détail',
    delete: 'Supprimer',
    go_create_audio: `créer de l'audio`,
    show_more_details: 'montrer plus de détails',
    show_less_details: 'montrer moins de détails',
    drop_files: 'Déposer des fichiers et où les télécharger',
    or: 'Ou',
    import_files: 'Importer les fichiers à partir de l’ordinateur) '
  },

  navigation: {
    account: 'Compte',
    assessments: 'Évaluations',
    dashboard: 'Tableau de bord',
    groups: 'Gestion de groupe',
    logout: 'Déconnexion',
    organisations: 'Organisations',
    organizations_users: 'Organisations et utilisateurs',
    reports: 'Rapports',
    schedule: 'Programme',
    nowLive: 'Diffuser',
    training: 'Formation',
    users: 'Utilisateurs',
    workshop: 'Mon atelier'
  },

  onboarding: {
    step1_title: `Étape 1 : Parlez-nous un peu de vous`,
    step1_subtitle: `Quel est votre nom ? D'où venez-vous ? Quel est votre titre de fonction ?`,
    step2_title: `Étape 2 : Sélectionnez certaines compétences que vous pourriez améliorer`,

    step2_subtitle: `Ensuite, dites-nous ce que vous aimeriez mieux comprendre. Peut-être quelque chose que vous n'avez pas encore compris ? Un nouveau domaine d'étude ? Ce que fait votre proche ?`
  },

  pagebuilder: {
    title: 'Créer une leçon',
    subtitle: `Il est temps de créer le contenu pour que votre public apprenne de vous, vous pouvez faire des photos, des vidéos et des infographies`,
    select_layout: 'Sélectionnez une mise en page',
    template1: 'Vide',
    template2: 'Titre et sous-titre',
    template3: 'Titre et texte',
    template4: `Titre, médias et texte`,
    template5: 'Titre et médias',
    template6: 'Titre et quiz'
  },

  pageconstructor: {
    title: 'Créer et cloner une leçon',
    subtitle: `Il est temps de créer le contenu pour que votre public apprenne de vous, vous pouvez ajouter des photos, des vidéos et des infographies".`
  },

  passwordreset: {
    success_coded: `Nous avons envoyé par e-mail un code de confirmation à l'adresse que vous avez fournie, veuillez copier le code dans le champ ci-dessous pour définir un nouveau mot de passe`,
    success_changed: `Vous avez réussi à définir un nouveau mot de passe. Vous pouvez vous connecter maintenant.`
  },

  placeholders: {
    search: 'Recherche',
    answer: 'Réponse',
    chat: 'Dis quelque chose...',
    code: 'Code',
    criterion: 'Critère',
    date_event: `Sélectionnez la date et l'heure`,
    date_start: 'Date de début',
    date_expire: `Date d'expiration`,
    description: 'Description',
    elements: 'Trier les éléments',
    email: 'Email',
    feedback_correct: 'Feedback Correct',
    feedback_incorrect: 'Feedback Incorrect',
    host: 'Hôte',
    keywords: `Recherche de mot(s) clé(s)`,
    lesson_description: 'Description de la leçon',
    location: 'Localisation',
    minutes: 'Minutes',
    name: 'Nom',
    organiser: 'Auteur',
    organization: 'Organisation',
    password: `Mot de passe`,
    phone: 'Téléphone',
    photo: 'Photo',
    picture: 'image',
    point: 'Point',
    position: 'Poste',
    question: 'Question',
    quiz: 'Nom du quiz',
    search_course: `Recherche par cours`,
    search_media: `Recherche de médias`,
    search_name: 'Recherche par nom',
    search_title: 'Recherche par titre',
    search_training: 'Formation à la recherche',
    settings: 'Paramètres',
    tags: 'Balises',
    title: 'Titre',
    topics: 'Sujets',
    username: `Nom d'utilisateur`,
    keyword: 'Mots-clés',
    max_500_characters: 'Max 500 caractères',
    no_description: `Ce fichier n'a pas de description`,
    no_keyword: `Ce fichier n'a pas de mots-clés`,
    no_topics: `Ce fichier n'a pas de thème défini`,
    feedback: 'Message',
    contact: `Comment pouvons-nous vous aider ?`
  },

  publishsettings: {
    title: 'Publier les paramètres',
    event_subtitle: `Ajoutez des paramètres à votre événement, qui peut voir et interagir avec votre événement`,
    assessment_subtitile: `Nommez votre évaluation et dites à votre public ce qu'il doit en attendre`,
    course_subtitle: `Ajouter des paramètres à votre cours, qui peut voir et interagir avec votre cours`,
    disclaimer: `
     1.	Inscription

En vous inscrivant en tant qu'utilisateur/en créant un compte, vous acceptez nos [Conditions d'utilisation] et reconnaissez avoir lu notre [Politique de confidentialité].



2.	Créer un cours

En utilisant nos outils et en créant le contenu des cours, vous acceptez notre [Contrat d'utilisation] et reconnaissez avoir lu notre [Politique de confidentialité].



3.	Créer un événement

En utilisant nos outils et en créant un événement, vous acceptez nos [Conditions d'utilisation] et reconnaissez avoir lu notre [Politique de confidentialité].


4.	Créer une évaluation

En utilisant nos outils et en créant une évaluation, vous acceptez nos [Conditions d'utilisation] et reconnaissez avoir lu notre [Politique de confidentialité].`,

    dates_validation_error: 'Les deux dates doivent être remplies',
    emails_validation_error: `La liste d'adresses électroniques n'est pas valable`,
    specific_email_validation_error: 'Un seul courriel est autorisé',
    i_agree_to_these: `Je suis d'accord avec celles-ci`,
    terms_and_conditions: 'conditions générales',
    PublicDescription: `Utilisateurs indépendants gratuits : (options privées et publiques)Votre [Cours|Événement|Évaluation] a été enregistré et est considéré comme privé par défaut. Cela signifie que vous seul pouvez voir ce contenu. Pour publier ce contenu, vous pouvez choisir de le rendre public ci-dessous. Cela signifie que le cours sera disponible pour tous les utilisateurs de la plate-forme`,
    OrganisationsDescription: `Utilisateurs de l'Org : (Au lieu de "Terminer", utilisez "Publier" et amenez-les à la page de publication comme les autres utilisateurs, mais sans aucune des options de publication. Cela signifie que seuls vous et les administrateurs de votre organisation pouvez le voir. En tant qu'utilisateur, vous ne pouvez pas publier vous-même votre contenu au sein de votre organisation car cela nécessite des droits d'administrateur. Veuillez contacter l'un de vos administrateurs et lui demander de publier votre contenu publiquement, à l'ensemble de l'organisation, à des groupes spécifiques au sein de votre organisation ou à des utilisateurs spécifiques`,

    OrganisationGroupsDescription: `Org Admins : (Pour les contenus réalisés au sein de l'organisation : Privé, public, organisation, groupes, utilisateurs spécifiques) : Ce [Cours|Événement|Évaluation] a été enregistré et est considéré comme privé par défaut. Cela signifie que seul vous (et le créateur du contenu) pouvez voir ce contenu . Pour publier ce contenu, vous pouvez sélectionner l'une des options ci-dessous. Publier votre cours au public signifie qu'il sera disponible pour tous les utilisateurs de la plate-forme, y compris ceux qui ne font pas partie de votre organisation. La publication au sein de l'organisation signifie que tous les utilisateurs au sein de l'organisation pourront le voir. Vous pouvez également le publier pour un ou plusieurs groupes, ce qui signifie que seuls les utilisateurs de ces groupes pourront le voir.  Enfin, vous avez la possibilité de le publier à des utilisateurs spécifiques au sein et en dehors de votre organisation en utilisant l'adresse électronique de leur compte Sharelook`,

    SpecificUsersDescription: `Utilisateurs indépendants rémunérés : (utilisateurs privés, publics et spécifiques)Votre [Cours|Événement|Évaluation] a été enregistré et est considéré comme privé par défaut. Cela signifie que vous seul pouvez voir ce contenu. Pour publier ce contenu, vous pouvez sélectionner l'une des options ci-dessous. Publier votre cours en public signifie qu'il sera disponible pour tous les utilisateurs de la plate-forme. L'option Utilisateurs spécifiques vous permettra de partager votre cours avec des utilisateurs spécifiques en utilisant l'adresse électronique associée à leur compte Sharelook.`
  },

  quiz: {
    quiz_empty: `Vous n'avez pas encore créé de quiz`,
    questions_empty: `Vous n'avez pas encore créé de question`
  },

  regions: {
    asia_pacific_australia: 'Australie',
    asia_pacific_india: 'Inde',
    asia_pacific_japan: 'Japon',
    asia_pacific_s_korea: ' Corée du Sud',
    asia_pacific_singapore: ' Singapour',
    asia_pacific_taiwan: 'Taiwan',
    eu_belgium: 'Belgique',
    eu_germany: 'Allemagne',
    eu_ireland: ' Irlande',
    south_america_brazil: 'Brésil',
    us_central_iowa: 'Iowa',
    us_east_s_carolina: ' Caroline du Sud',
    us_east_virginia: 'Virginie',
    us_west_california: 'Californie',
    us_west_oregon: ' Oregon'
  },

  reports: {
    title: 'Rapports',
    workshop_report: `Rapport d'atelier`,
    user_report: `Rapport d'utilisateur`,
    organization_workshop: `Atelier d'organisation`,
    course: 'Cours',
    live_event: 'Evénement en direct',
    workShop_graph: `Graphique d'atelier`,
    organization_account: `Compte de l'organisation`,
    all: 'Tous',
    name: 'Nom',
    id_number: `Numéro d'identification`,
    email: 'Email',
    search_user_report: `Rapport de l'utilisateur de recherche`,
    participant: 'Participant',
    participants: 'Participants',
    host: 'Hôte',
    username: `Nom d'utilisateur`,
    event: 'Événement',
    quiz: 'Quiz',
    assessment: 'Évaluation',
    time: 'Heure',
    no_recent_activity: `Aucune activité récente`,
    workshops: 'Ateliers',
    courses: 'Cours',
    live_events: 'Événements en direct',
    live_assessments: 'Évaluations en direct',
    quizzes: 'Quizz',
    overview: `Vue d'ensemble`,
    by: 'Par',
    views: 'Vues',
    status: 'Statuts',
    poll: 'Sondage',
    comment: ' Commentaire',
    comments: 'Commentaires',
    no_comments: 'Aucun commentaire',
    no_polls: 'Pas de sondages',
    live_date: ' Date de diffusion',
    score: 'Score',
    question: 'Question',
    submit: 'Envoyer',
    recent_activity: ' Activité récente',
    ago: 'Il y a longtemps',
    no_data_found: 'Aucune donnée trouvée',
    start_end_live: 'Début / fin du direct',
    join_leave_date: 'Rejoindre / quitter',
    watched_time: 'Le temps regardé',
    complete: 'Terminer',
    in_complete: ' Entièrement',
    today: `Aujourd'hui`,
    seven_days: '7 Jours',
    last_month: 'Le mois dernier',
    one_year: '1 an',
    all_time: 'Tout le temps',
    report: 'Rapport',
    from_date: 'Date de début',
    to_date: 'à',
    all_workshop: 'Tous les ateliers',
    all_participants: 'Tous les Participants',
    live_assessment: 'Évaluation en direct',
    users: 'Utilisateurs',
    download: 'Télécharger',
    recent: 'Récent',
    WorkShop: 'Atelier',
    Activity: 'Activité',
    created_date: 'Date de création',
    lessons: 'Leçons',
    lesson: 'Leçon',
    completed: 'Terminé',

    report_download: 'Téléchargement de rapports',
    this_month: 'Ce mois-ci',
    search_course: 'rechercher un Cours',
    participants_graph: 'Graphique des participants',
    viewed: 'Vu',
    enrolled: 'Inscrit',
    description: 'Description',
    topic: 'Sujet',
    analytic: 'Analytique',
    quiz_list: 'Liste de quiz',
    select_one: 'Sélectionner un',
    group_status: 'Statut de groupe',
    duration_min: 'Durée (min)',
    submit_poll: 'Soumettre un sondage',
    download_events: 'Télécharger les événements',
    search_event: 'Rechercher un événement',
    duration: 'Durée',
    total_participants: 'Total des participants',
    message: 'Message',
    avg_message: 'Moyenne. Message',
    per_message: 'UN POURCENTAGE DE MESSAGES / ÉVÉNEMENT'
  },

  signup: {
    success_step1: `Nous avons envoyé par e-mail un code de vérification à l'adresse que vous avez fournie, veuillez copier le code dans le champ ci-dessous pour confirmer votre inscription`,
    success_step2: `Merci. Vous êtes enregistré avec succès`,
    privacy: `J'accepte les conditions générales <1> de Sharelook</1> et <3> de la politique de confidentialité</3>.`
  },

  stream: {
    emotion_impressive: 'Impressionnant',
    emotion_unimpressive: 'Peu impressionnant',
    message_not_started: 'A venir',
    message_running: 'En direct',
    message_processing: `Nous sommes en train de traiter l'enregistrement en direct pour la lecture. Veuillez revenir plus tard pour visionner la vidéo et continuer à ajouter des évaluations et des commentaires.`
  },

  tabs: {
    audience: 'Audience',
    course: ' Aperçu du cours',
    details: `Détails de l'événement en direct`,
    description: 'Description',
    host: ' Hôte :',
    lesson: ' Aperçu de la leçon',
    lessons: 'Leçons',
    overview: `Vue d'ensemble`,
    page: ' Créateur de pages',
    publish: 'Publier',
    settings: 'Publier les Paramètres',
    total_views: 'vues totales',
    live_participates: ' Participants en direct',
    live_chats: 'Chats en direct',
    live_quiz: 'Sondages en direct',
    trainer_rating: 'Évaluation des formateurs',
    trainer_times: ' Horaires des formateurs',
    courses_quizzes: ' Quiz sur les cours',
    user_list: ' Liste des utilisateurs',
    discussion: ' Discussion',
    notes: ' Mes notes'
  },

  topics: {
    SALES_TRAINING: ' Formation à la vente',
    NEGOTIATION_TRAINING: ' Formation à la négociation',
    CROSS_CULTURAL_AWARENESS: ' Sensibilisation interculturelle',
    NETWORKING_SKILLS: ' Compétences en matière de mise en réseau',
    SOFTWARE_ARCHITECTURE: ' Architecture logicielle',
    PROJECT_MANAGEMENT: ' Gestion de projet',
    DIGITAL_TRANSFORMATION: ' Transformation numérique',
    IT_CONSULTING: ' Conseil en informatique',
    MANAGEMENT_CONSULTING: ' Conseil en gestion',
    HR_CONSULTING: ' Conseil en ressources humaines',
    INTERNET_OF_THINGS: ' Internet des objets (IoT)',
    CYBERSECURITY: ' Cyber sécurité',
    PRIVACY_AND_GDRP: ' Vie privée et GDPR'
  },

  training: {
    title: ' Formation',
    title_assessment: ' Évaluations',
    subtitle: ' Cours et événements en direct',
    enrolled_title: ' Cours inscrits et événements en direct',
    enrolled_subtitle:
      ' Cours et événements en direct auxquels vous vous êtes inscrit',
    enrolled_message: `Vous n'êtes actuellement inscrit à aucun cours ou événement `,
    past_live_event_title: ' Événements passés en direct',
    past_live_event_subTitle: ' Événements en direct terminés',
    past_live_event: ` Il n'y a actuellement aucun événement passé en direct.`,
    recomended_title: ' Cours et événements en direct recommandés',
    now_live_event_title: ' Maintenant en direct',
    now_live_event_subTitle: ' Les événements en direct ou à venir',
    now_live_event: `Il n'y a actuellement aucun événement en direct.`,
    webinar_event_title: ' Canal webinaire',
    webinar_event_subTitle: ' Webinaires terminés',
    webinar_event: `Il n'y a pas de webinaire actuellement.`,
    p2p_event_title: `Séances d'entraînement`,
    p2p_event_subTitle: `Séance d'entraînement terminé`,
    p2p_event: `Il n'y a actuellement aucune session de coaching.`,
    now_live_assessment_title: ' Maintenant en direct',
    now_live_assessment_subTitle:
      'Les évaluations sont en cours ou le seront très bientôt',
    now_live_assessment_message: `Il n'y a actuellement aucune évaluation en direct`,
    upcoming_assessment_title: `Prochaines évaluations en direct`,
    upcoming_assessment_subtitle: 'Prochaines évaluations en direct',
    upcoming_assessment_message: `Il n'y a actuellement aucune évaluation en direct à venir`,

    past_live_assessment_title: ' Évaluations en direct antérieures',
    past_live_assessment_subTitle: 'Evaluation en direct terminé',
    past_live_assessment_message: `Il n'y a actuellement aucune évaluation en direct`,

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle:
      'Nous avons organisé des cours et des événements en direct sur la base de vos <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: 'Formation à la recherche',
    search_assessment: 'Évaluation de la recherche',
    recomended_message: `Il n'y a actuellement aucun cours ou événement recommandé.`
  },

  warnings: {
    delete: 'Vous êtes sûr ?'
  },

  workshop: {
    title: 'Mon atelier',
    subtitle: ' Créez un cours ou organisez votre propre événement en direct',
    new_course: 'Créer un nouveau cours',
    new_event: 'Créer un nouvel événement en direct',
    new_assessment: 'Créer une nouvelle évaluation en direct',
    courses_title: `Création de cours et d'événements en direct`,
    courses_subtitle: 'Edit & Publish your created course and plan live events',
    tags_course: 'Cours',
    tags_live: 'Événement en direct',
    tags_assessment: 'Évaluation en direct'
  },

  help_center: {
    sidebar: {
      title: 'Comment pouvons-nous vous aider ?',
      back_to: 'Tableau de bord'
    },
    menu: {
      help_center: `Centre d'aide`,
      whats_new: 'Quoi de neuf',
      feedback: 'Feedback',
      feedback_title: `Envoyez vos commentaires à sharelook`,
      terms_and_conditions: ' Conditions générales',
      privacy_policy: ' Politique de confidentialité',
      contact_us: 'Nous contacter'
    },
    get_started: {
      title: 'Commencez par partager votre regard',
      description: {
        main:
          'NOUS AVONS INTÉGRÉ LA VIDÉO EN DIRECT DANS UN GÉNÉRATEUR DE COURS FACILE À UTILISER',
        tagline: `Avec le système breveté de diffusion en direct de ShareLook, vous pouvez créer un engagement, automatiser l'apprentissage et maximiser le partage des connaissances.`
      }
    },
    whats_new: {
      title: 'Commencez par partager votre regard',

      description: {
        main: `Saviez-vous que l'apprentissage du style narratif peut se faire en peu de temps et à moindre coût ?  Vous devriez combiner le concepteur de cours ShareLook et les talents respectifs. Vous pouvez nous demander "COMMENT"`,
        tagline: ''
      }
    },
    create_workshop: 'Comment créer un atelier',
    create_course: 'Comment créer un cours',
    create_user: 'Comment créer un utilisateur',
    create_group: 'Comment créer un groupe',
    create_webinar: 'Comment créer un webinaire',
    create_live_event: 'Comment créer un événement en direct',
    create_live_assessment: 'Comment créer une évaluation en direct',
    create_live_on_mobile: 'Créer un direct sur le mobile',
    personalize_profile: 'Personnaliser un profil',
    manage_group: 'Gérer un groupe',
    report: 'Rapport',
    add_annotations: 'Comment ajouter des annotations et des popups',
    add_audio: `Comment ajouter de l'audio`,
    add_header_and_footer: `Ajout d'un en-tête et d'un pied de page`,
    add_image: 'Comment ajouter une image',
    add_quiz_content: 'Comment ajouter du contenu au quiz',
    add_text_content: 'Comment ajouter du contenu textuel',
    add_user: 'Comment ajouter un utiliateur',
    add_video: 'Comment ajouter une vidéo',
    clone_course: 'Comment cloner un cours',
    find_live_broadcast: 'Comment trouver une diffusion en direct',
    contact_us: {
      title: 'Nous contacter',
      subject_placeholder: 'Saisir un Objet*',
      content_placeholder: 'Saisir un message*',
      subject_required: '* Un objet est obligatoire ',
      content_required: '* Un message est obligatoire ',
      submit: 'Envoyer',
      cancel: 'Annuler',
      sent: 'Envoyé'
    },
    feedback: {
      title: 'Envoyer un feddback à sharelook',
      attach_title: 'Joindre un fichier',
      choose: 'Choisir un fichier',
      detail: `Le dossier sera partagé avec l'équipe de soutien`,
      content_placeholder: 'saisir un message*',
      content_required: '*Un message est obligatoire',
      submit: 'envoyer',
      cancel: 'Annuler',
      sent: 'envoyé'
    }
  },
  discussion: {
    join_discussion: 'rejoindre en discussion'
  },
  notes: {
    title: 'Mes Notes',
    add_note: 'Ajouter une nouvelle note'
  }
}
