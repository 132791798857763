import http from './http'

function getById (id, type) {
  return http.get('/discussions', {
    params: {
      type,
      id: id
    }
  })
}

function add (id, type, data) {
  return http({
    method: 'POST',
    url: '/discussions',
    data,
    params: {
      type,
      id
    }
  })
}

function deleteById (id) {
  return http({
    method: 'DELETE',
    url: `/discussions/${id}`
  })
}

function editById (id, data) {
  return http({
    method: 'PUT',
    url: `/discussions/${id}`,
    data
  })
}

function getDiscussionsCount (ids, type) {
  let query = ''

  if (ids && Array.isArray(ids)) {
    query = ids.join(',')
  } else {
    query = ids
  }
  
  return http.get('/discussionsCount', {
    params: {
      type,
      ids: query
    }
  })
}

export {
  getById,
  add,
  deleteById,
  editById,
  getDiscussionsCount
}
