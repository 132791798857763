import React, { Component } from 'react'
import {
  Form,
  Icon,
  Select,
  Input,
  Button,
  Switch,
  Spin,
  Tabs,
  message,
  Modal
} from 'antd'
import moment from 'moment'
import { Row, Col } from '../../../styles'
import Dropzone from 'react-dropzone'
import className from 'classnames'
import _ from 'lodash'
import ImageCropContainer from '../../../components/ImageCrop'
import { withTranslation } from 'react-i18next'
import { api } from '../../../services'
import { Page } from './styled'
import Settings from './Settings'
import history from '../../../history'
import { regions, general } from '../../../constants'
import CreatableSelect from 'react-select/creatable'
import { PopupMedia } from '../../../views/Library/internal'
import Preview from '../../../views/Library/components/Preview'
import { modal_style } from './styled'
import './styles.scss'
import ReactSVG from 'react-svg'
import { config } from '../../../constants'

class Builder extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = _.debounce(this.loadOptions, 500)
    this.state = {
      isFetching: true,
      isSaving: false,
      coverId: null,
      coverLink: null,
      validate: false,
      tab: 'details',
      current: {
        id: null,
        title: '',
        email: '',
        description: '',
        broadcast_location: '',
        topics: [],
        topic_list: [],
        is_chat: true,
        is_graph: true
      },
      options: [],
      value: [],
      inputValue: '',
      isTopicLoading: false,
      file: null,
      image: null,
      isTopicsMax: false,
      disabled: false,
      modalOpen: false
    }
  }

  componentDidMount() {
    const { clearUploadList } = this.props
    const id = this.props.match.params.id || null

    clearUploadList()

    if (id) {
      api.assessments.getById(id).then(response => {
        if (!response.data.is_access) {
          history.push(`/assessments/${id}`)
          return
        }
        const cover = response.data.cover ? response.data.cover.link : ''
        this.setCover(response.data.cover_id, cover)
        const {
          data: { topics }
        } = response
        this.setState(
          {
            current: {
              ...this.state.current,
              ...response.data
            },
            isFetching: false
          },
          () => {
            this.getOldTopics(topics)
          }
        )
      })
    } else {
      this.setState({
        isFetching: false
      })
    }
  }

  setCover = (id, link) => {
    this.setState({
      coverId: id,
      coverLink: link
    })
  }

  handleForm = (isGoback = false) => {
    const { form } = this.props
    const { current, coverId, value } = this.state
    const id = this.props.match.params.id || current.id || null
    this.setState({
      validate: true
    })
    form.validateFields((err, values) => {
      if (!coverId) {
        return
      }

      if (_.isEmpty(value)) {
        return
      }

      if (!err) {
        const data = {
          title: values.title,
          description: values.description,
          location: values.location,
          host: values.host,
          topics: _.map(value, 'id'),
          cover_id: coverId,
          is_chat: values.is_chat,
          is_graph: values.is_graph
        }

        this.setState({
          isSaving: true,
          disabled: true
        })

        if (id) {
          api.assessments
            .updateById(id, data)
            .then(response =>
              this.setState(
                {
                  isSaving: false,
                  current: {
                    ...this.state.current,
                    ...response.data
                  },
                  disabled: isGoback
                },
                () => {
                  if (isGoback) {
                    history.push('/workshop')
                  }
                }
              )
            )
            .catch(error => {
              message.error(error.response.data.errorMessage)

              this.setState({
                isSaving: false
              })
            })
        } else {
          api.assessments.create(data).then(response =>
            this.setState({
              isSaving: false,
              current: {
                ...this.state.current,
                ...response.data
              }
            })
          )
        }
      }
    })
  }

  handleParam = _.debounce(() => {
    this.handleForm()
  }, 1000)

  onDrop = dropped => {
    const file = dropped[0]
    if (!file) {
      return
    }
    const image = URL.createObjectURL(file)
    this.setState({
      file,
      image
    })
  }

  getOldTopics = topics => {
    const topic = topics.map(item => item.topic)
    if (!_.isEmpty(topic)) {
      const newValue = topic.map(item => item && this.createOption(item))
      this.setState({
        value: newValue
      })
    }
  }

  createOptionList = list => {
    const options = list.map(topic => this.createOption(topic))
    this.setState({
      options,
      isTopicLoading: false
    })
  }

  handleChange = (newValue, actionMeta) => {
    if (actionMeta.removedValue) {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    } else if (!_.isEmpty(newValue) && newValue.length > 3) {
      this.setState({
        isTopicsMax: true
      })
    } else {
      this.setState(
        {
          value: newValue,
          inputValue: '',
          isTopicLoading: false,
          isTopicsMax: false
        },
        () => {
          this.handleParam()
        }
      )
    }
    this.isModalSelectOpen()
  }
  handleCreate = inputValue => {
    this.setState({ isTopicLoading: true })
    const { options, value } = this.state
    const topic = { title: inputValue }
    if (!_.isEmpty(value) && value.length >= 3) {
      this.setState({
        isTopicsMax: true,
        isTopicLoading: false
      })
    } else {
      api.topics.createTopics(topic).then(({ data }) => {
        if (!_.isEmpty(data[0])) {
          const newOption = this.createOption(data[0])
          this.props.updateTopicList(data[0])

          this.setState(
            {
              isTopicLoading: false,
              options: [...options, newOption],
              isTopicsMax: false,
              value: [...value, newOption],
              inputValue: ''
            },
            () => {
              this.handleParam()
            }
          )
        }
      })
    }
    this.isModalSelectOpen()
  }

  createOption = topic => {
    const option = {
      label: topic.title,
      value: topic.title,
      id: topic.id
    }
    return option
  }

  loadOptions = inputValue => {
    if (inputValue.length > 0 && inputValue.length <= 50) {
      this.setState({
        isTopicLoading: true
      })
      const params = {
        search: inputValue.trim(),
        limit: 10,
        offset: 0
      }
      api.topics.getTopics(params).then(({ data }) => {
        this.setState({
          inputValue
        })
        this.createOptionList(data)
      })
    }
  }

  LoadingIndicator = () => (
    <Icon style={{ paddingRight: '10px' }} type="loading" />
  )

  useCanva = () => {
    var script = document.createElement('script')
    const url = 'https://sdk.canva.com/designbutton/v2/api.js'
    script.src = url
    script.onload = () => {
      if (window.Canva && window.Canva.DesignButton) {
        window.Canva.DesignButton.initialize({
          apiKey: config.canvaKey
        }).then(api => {
          api.createDesign({
            design: {
              type: 'Presentation'
            },
            onDesignPublish: ({ exportUrl, designTitle }) => {
              this.handleImageUrl(exportUrl, designTitle)
            }
          })
        })
      }
    }
    document.body.appendChild(script)
  }

  handleImageUrl = async (url, title) => {
    let blob = await fetch(url).then(r => r.blob())
    let data = await new Promise(resolve => {
      let reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
    const body = {
      croppedImage: data,
      lastModifiedDate: new Date(),
      name: title,
      size: blob.size,
      type: blob.type
    }
    this.handleUploadImage(body)
  }

  handleUploadImage = data => {
    const { upload, clearUploadList } = this.props
    return upload(data).then(response => {
      this.setCover(response.id, response.link)
      this.handleParam()
      clearUploadList()
      this.setState({
        image: null,
        file: null
      })
    })
  }

  handleFileInsert = item => {
    this.setCover(item.id, item.link)
    this.setState(
      {
        modalOpen: false,
        selectedItem: null
      },
      () => {
        this.handleParam()
      }
    )
  }

  handleClosePopup = type => {
    if (type === general.SAVE) {
      this.setState({
        image: null
      })
    } else {
      this.setState({
        image: null,
        file: null
      })
    }
  }

  isModalSelectOpen = () => {
    const { inputValue, value, isTopicsMax } = this.state
    return !_.isEmpty(value) && value.length >= 3 && isTopicsMax
      ? false
      : inputValue.length > 0
  }

  handleSendEmail = () => {
    this.handleForm()
    this.setState({
      isCheckModal: false,
      isNotExist: [],
      tab: 'settings'
    })
  }

  closeCheckModal = () => {
    const inputHost = document.getElementById('host')
    if (inputHost) {
      inputHost.focus()
    }
    this.setState({
      isCheckModal: false,
      isNotExist: [],
      isNotInOrg: []
    })
  }
  handleCheckUser = () => {
    this.setState({
      isLoading: true
    })
    const {
      form,
      user: {
        info: { active_organization_id: activeOrganization }
      }
    } = this.props
    const email = form.getFieldValue('host').trim()
    const myJSON = JSON.stringify([email])
    api.events.checkUser(myJSON).then(response => {
      const { data } = response
      const isNotExist = data.filter(email => !email.is_exist)
      const isNotInOrg = data.filter(
        email => email.is_exist && !email.in_organization
      )
      this.checkPersonalSpace(activeOrganization, isNotExist, isNotInOrg)
    })
  }

  checkPersonalSpace = (activeOrganization, isNotExist, isNotInOrg) => {
    if (activeOrganization) {
      if (_.isEmpty(isNotExist) && _.isEmpty(isNotInOrg)) {
        this.handleForm()
        this.setState({
          tab: 'settings',
          isLoading: false
        })
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg,
          isLoading: false
        })
      }
    } else {
      if (_.isEmpty(isNotExist)) {
        this.handleForm()
        this.setState({
          tab: 'settings',
          isLoading: false
        })
      } else {
        this.setState({
          isCheckModal: true,
          isNotExist,
          isNotInOrg: [],
          isLoading: false
        })
      }
    }
  }

  checkFinish = ({ start_date_time: startDataTime, duration = 0 }) => {
    const isAllowPermission = this.getUserRole()
    if (isAllowPermission) {
      if (startDataTime) {
        const newTime = moment(startDataTime).format('LLLL')
        const diffMinutes = moment().diff(newTime, 'minutes')
        const newDuration = duration || 0 + 10
        const checkInterval = diffMinutes - newDuration

        if (checkInterval > 0) {
          return false
        } else {
          return true
        }
      }
      return true
    } else {
      return false
    }
  }
  getUserRole = () => {
    const {
      user: {
        info: { isAdmin, isSuperAdmin }
      }
    } = this.props
    const { is_finished: isFinished } = this.state.current
    if (!isFinished) {
      return isAdmin || isSuperAdmin
    } else {
      return !isFinished
    }
  }

  hideModal = () => {
    this.setState({
      modalOpen: false
    })
  }

  handleInsertData = (privateMedia, publicMedia, orgMedia) => {
    let tempData = []

    if (privateMedia) {
      const { rows: privateData } = privateMedia
      privateData.forEach(data => {
        tempData.push(data)
      })
    }

    if (publicMedia) {
      const { rows: publicData } = publicMedia
      publicData.forEach(publicData => {
        tempData.push(publicData)
      })
    }

    if (orgMedia) {
      const { rows: orgData } = orgMedia
      orgData.forEach(orgData => {
        tempData.push(orgData)
      })
    }

    const selectedItem = _.head(tempData.filter(value => value.isSelected))

    this.setState({
      selectedItem
    })
  }

  hidePreviewModal = () => {
    this.setState({
      selectedItem: null
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      // topics,
      library: { uploadProgress },
      user,
      t
    } = this.props

    const {
      coverId,
      coverLink,
      validate,
      current,
      isFetching,
      isSaving,
      tab,
      value,
      options,
      isTopicLoading,
      isTopicsMax,
      file,
      image,
      isCheckModal,
      isNotExist,
      isNotInOrg,
      isLoading,
      modalOpen,
      selectedItem
    } = this.state
    const routeId = this.props.match.params.id || null
    const eventId = this.props.match.params.id || current.id || null
    const spinning = routeId ? isFetching : false

    const selectInput = document.getElementById('react-select-2-input')
    if (selectInput) {
      selectInput.maxLength = 50
    }
    // const eventTopics = current.topic_list.map(i => String(i.id))

    const customStyles = {
      control: provided => ({
        ...provided,
        borderRadius: '0px',
        borderColor: _.isEmpty(value) && validate ? '#FF4B55' : '#D8D8D8',
        boxShadow: 0,
        transition: 'transparent 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)',
        marginBottom:
          (_.isEmpty(value) && validate) || isTopicsMax ? '0px' : '24px'
      }),
      menu: provided => ({
        ...provided,
        maxHeight: '185px'
      }),
      menuList: provided => ({
        ...provided,
        maxHeight: '185px'
      })
    }

    if (isFetching) {
      return null
    }

    return (
      <Page>
        <div className="p-tabs">
          <Tabs activeKey={tab} animated={false}>
            <Tabs.TabPane
              tab={t('assessments:assessment_details')}
              key="details"
              disabled
            />

            <Tabs.TabPane
              tab={t('assessments:assessment_settings')}
              key="settings"
              disabled
            >
              <Settings
                id={eventId}
                back={() => this.setState({ tab: 'details' })}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
        {tab === 'details' && (
          <section className="section">
            <div className="head head_nopadding">
              <div className="head__title">{t('details_title')}</div>
              <div className="head__subtitle">{t('details_subtitle')}</div>
            </div>
          </section>
        )}
        {tab === 'details' && (
          <Spin spinning={spinning}>
            <section
              className="section section_divider"
              style={{ paddingBottom: '10rem' }}
            >
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Item
                      label={t('labels:title')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('title', {
                        rules: [
                          {
                            whitespace: true,
                            required: true,
                            message: t('errors:title_assessment'),
                            max: 100
                          }
                        ],
                        initialValue: current.title
                      })(
                        <Input
                          name="title"
                          placeholder={t('placeholders:title')}
                          size="large"
                          maxLength={100}
                          onChange={this.handleParam}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      label={t('labels:host')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('host', {
                        rules: [
                          {
                            required: true,
                            max: 100,
                            type: 'email',
                            asyncValidator: (rule, value) => {
                              return new Promise((resolve, reject) => {
                                const check = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                                  value.trim()
                                )
                                if (value === '') {
                                  reject(t('errors:host_assessment'))
                                } else if (!check) {
                                  reject(t('errors:email_invalid'))
                                } else {
                                  resolve()
                                }
                              })
                            }
                          }
                        ],
                        initialValue:
                          current.host && current.host.email
                            ? current.host.email
                            : user.info.email
                      })(
                        <Input
                          name="host"
                          placeholder={t('placeholders:email')}
                          disabled={!this.checkFinish(current)}
                          size="large"
                          maxLength={100}
                          onChange={this.handleParam}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      label={t('labels:description')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('description', {
                        initialValue: current.description
                      })(
                        <Input.TextArea
                          rows={4}
                          name="description"
                          placeholder={t('placeholders:description')}
                          size="large"
                          maxLength={1000}
                          onChange={this.handleParam}
                          style={{ fontSize: '1.6rem', resize: 'none' }}
                        />
                      )}
                    </Form.Item>
                    <div style={{ marginBottom: '4px', fontSize: '1.2rem' }}>
                      {t('labels:topics').toUpperCase()}
                    </div>
                    <CreatableSelect
                      styles={customStyles}
                      components={{
                        ClearIndicator: null,
                        DropdownIndicator: null,
                        LoadingIndicator: this.LoadingIndicator
                      }}
                      isMulti
                      placeholder=""
                      inputId="react-select-2-input"
                      isLoading={isTopicLoading}
                      menuIsOpen={this.isModalSelectOpen()}
                      onChange={this.handleChange}
                      onCreateOption={this.handleCreate}
                      onInputChange={this.loadOptions}
                      options={options}
                      value={value}
                    />

                    {_.isEmpty(value) && validate && (
                      <div className="error_message">
                        {t('errors:live_topics')}
                      </div>
                    )}

                    {isTopicsMax && (
                      <div className="error_message">
                        {t('errors:topics_max')}
                      </div>
                    )}

                    <Form.Item
                      label={t('labels:location_broadcast')}
                      extra={t('labels:location_broadcast_subtitle')}
                      colon={false}
                      required={false}
                    >
                      {getFieldDecorator('location', {
                        rules: [
                          {
                            required: true,
                            message: t('errors:live_location')
                          }
                        ],
                        initialValue: current.broadcast_location
                      })(
                        <Select
                          showSearch
                          size="large"
                          disabled={!this.checkFinish(current)}
                          onChange={this.handleParam}
                          filterOption={(input, option) =>
                            !_.isArray(option.props.children) &&
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {regions.map(continent => (
                            <Select.OptGroup
                              key={continent.title}
                              value={continent.title}
                            >
                              {Object.keys(continent.list).map(item => (
                                <Select.Option key={item} value={item}>
                                  {t(`regions:${item}`)}
                                </Select.Option>
                              ))}
                            </Select.OptGroup>
                          ))}
                        </Select>
                      )}
                    </Form.Item>

                    {this.checkFinish(current) && (
                      <Row md={6}>
                        <Col md={2}>
                          <Form.Item
                            label={t('labels:settings')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('is_chat', {
                              valuePropName: 'checked',
                              initialValue: current.is_chat
                            })(
                              <Switch
                                name="is_chat"
                                checkedChildren={t('general:chat')}
                                unCheckedChildren={t('general:chat')}
                                style={{ marginRight: '10px' }}
                                onChange={this.handleParam}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col md={2}>
                          <Form.Item
                            label={t('labels:show_graph')}
                            colon={false}
                            required={false}
                          >
                            {getFieldDecorator('is_graph', {
                              valuePropName: 'checked',
                              initialValue: current.is_graph
                            })(
                              <Switch
                                name="is_graph"
                                checkedChildren={t('labels:show')}
                                unCheckedChildren={t('labels:show')}
                                style={{ marginRight: '10px' }}
                                onChange={this.handleParam}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      label={t('labels:picture')}
                      colon={false}
                      required={false}
                    >
                      <Dropzone
                        accept={'image/*'}
                        onDrop={this.onDrop}
                        style={{}}
                        disabled={uploadProgress.length > 0}
                      >
                        {({ isDragActive }) => (
                          <div
                            className={className(
                              'upload-dropzone',
                              'upload-dropzone_event',
                              {
                                'upload-dropzone_active': isDragActive,
                                'upload-dropzone_invalid': !coverId && validate
                              }
                            )}
                            style={{
                              backgroundImage: 'url(' + coverLink + ')'
                            }}
                          >
                            <div className="upload-dropzone__button">
                              {uploadProgress.length ? (
                                <Button
                                  className="rounded"
                                  size="large"
                                  icon="loading"
                                >
                                  {uploadProgress[0].percent + '%'}
                                </Button>
                              ) : (
                                <div className="wrap_upload_buttons">
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                  >
                                    {t('buttons:upload_image')}
                                  </Button>
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setState({
                                        modalOpen: true
                                      })
                                    }}
                                  >
                                    {t('buttons:select_media_library')}
                                  </Button>
                                  <Button
                                    className="rounded"
                                    size="large"
                                    icon="picture"
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.useCanva()
                                    }}
                                  >
                                    {t('v4:canva')}
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </section>
            {isCheckModal && (
              <Modal
                visible={isCheckModal}
                centered
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReactSVG src="/images/icons/menu/bells.svg" />
                    <div style={{ paddingLeft: '2rem' }}>
                      {t('labels:check_user')}
                    </div>
                  </div>
                }
                onCancel={this.closeCheckModal}
                footer={[
                  <Button
                    key="back"
                    shape="round"
                    onClick={this.closeCheckModal}
                  >
                    {t('general:no')}
                  </Button>,
                  <Button
                    key="submit"
                    shape="round"
                    type="primary"
                    onClick={this.handleSendEmail}
                  >
                    {t('general:yes')}
                  </Button>
                ]}
              >
                {!_.isEmpty(isNotExist) && (
                  <div>
                    <p style={{ color: '#333333' }}>
                      {t('labels:user_not_exist')}
                    </p>
                    <div
                      style={{
                        backgroundColor: '#f1f1f1',
                        borderRadius: '.5rem',
                        padding: '1rem'
                      }}
                    >
                      {!_.isEmpty(isNotExist) &&
                        isNotExist.map((item, index) => (
                          <div className="selectedMedia">
                            <div className="titleMedia">
                              {index + 1}. {item.email}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                {!_.isEmpty(isNotInOrg) && (
                  <div>
                    <p style={{ color: '#333333' }}>
                      {t('labels:user_not_exist_in_org')}
                    </p>
                    <div
                      style={{
                        backgroundColor: '#f1f1f1',
                        borderRadius: '.5rem',
                        padding: '1rem'
                      }}
                    >
                      {!_.isEmpty(isNotInOrg) &&
                        isNotInOrg.map((item, index) => (
                          <div className="selectedMedia">
                            <div className="titleMedia">
                              {index + 1}. {item.email}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </Modal>
            )}
            <div className="p-footer">
              <div className="p-footer__inner">
                <div className="p-footer__item">
                  <Button
                    type="default"
                    className="rounded"
                    onClick={this.handleForm}
                    icon={isSaving ? 'loading' : null}
                  >
                    {t('buttons:save_for_later')}
                  </Button>
                </div>
                <div className="p-footer__item">
                  <Button
                    type="primary"
                    className="rounded"
                    disabled={!eventId}
                    loading={isLoading}
                    onClick={() => this.handleCheckUser(eventId)}
                  >
                    {t('buttons:publish')} <Icon type="right" />
                  </Button>
                </div>
              </div>
            </div>
          </Spin>
        )}
        {image && (
          <ImageCropContainer
            image={image}
            handleUploadImage={this.handleUploadImage}
            handleClosePopup={this.handleClosePopup}
            file={file}
          />
        )}
        {modalOpen && (
          <Modal
            visible={modalOpen}
            onCancel={this.hideModal}
            footer={null}
            width={'80%'}
            style={modal_style}
          >
            <div className="wrap_modal_tabs">
              <PopupMedia
                isPageBuilder
                handleInsertData={this.handleInsertData}
                types="images"
              />
            </div>
            {selectedItem && (
              <Modal
                visible={selectedItem}
                onCancel={this.hidePreviewModal}
                footer={null}
              >
                <Preview library={selectedItem} />
                <div className="wrap_btn_actions">
                  <Button
                    type="danger"
                    size="large"
                    className="action_button"
                    onClick={this.hidePreviewModal}
                  >
                    {general.CANCEL}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    className="action_button"
                    onClick={() => this.handleFileInsert(selectedItem)}
                  >
                    {general.INSERT}
                  </Button>
                </div>
              </Modal>
            )}
          </Modal>
        )}
      </Page>
    )
  }
}

export default Form.create()(withTranslation('assessments')(Builder))
