import * as types from '../types'

const initialState = {
  isFetching: false,
  reviews: [],
  avg_rating: null,
  selected_review: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_REVIEWS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case types.GET_ALL_REVIEWS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        avg_rating: action.payload.avg_rating,
        reviews: action.payload.ratings
      }
      case types.GET_ALL_REVIEWS_FAILURE:
        return {
          ...state,
          isFetching: false,
          error: true
        }
    case types.ADD_REVIEW_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      }
    case types.ADD_REVIEW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        reviews: [action.payload, ...state.reviews]
      }
    case types.ADD_REVIEW_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      }
    case types.CLEAR_REVIEWS_REQUEST:
      return {
        ...state,
        isFetching: false,
        avg_ratings: null,
        error: null,
        selected_review: {},
        reviews: []
      }
    case types.EDIT_REVIEW_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case types.EDIT_REVIEW_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
        reviews: state.reviews.map(item =>
          item.id === action.payload.id ? action.payload : item
        )
      }
    default:
      return state
  }
}
