import { api } from '../../services'

import {
  REPORTS_HANDLING_DATA,
  FETCH_REPORT_DATA,
  FETCH_USER_REPORT_DATA,
  GET_USER_REPORT_DATA,
  // GET_EVENTS_REPORT_DATA,
  FETCH_EVENTS_REPORT_DATA,
  FETCH_COURSES_REPORT_DATA,
  GET_COURSES_REPORT_DATA,
  FETCH_LESSON_REPORT_DATA,
  GET_LESSON_REPORT_DATA,
  FETCH_QUIZ_REPORT_DATA,
  GET_QUIZ_REPORT_DATA,
  FETCH_QUIZ_LIST_REPORT_DATA,
  GET_QUIZ_LIST_REPORT_DATA,
  GET_WORKSHOP_COURSES_REPORT_DATA,
  FETCH_TIME_SPENT_DATA,
  GET_USER_TIME_SPENT_REPORT_DATA,
  FETCH_EVENT_WORKSHOP_DATA,
  GET_EVENT_WORKSHOP_DATA,
  GET_COURSE_LIST,
  GET_MARKET_COURSE_LIST,
  GET_EVENT_LIST,
  FETCH_COURSE_LIST,
  FETCH_EVENT_LIST,
  GET_MAZE_REPORT_BY_ID,
  FETCH_MAZE_REPORT_BY_ID,
  GET_MAZE_REPORT,
  FETCH_MAZE_REPORT,
  GET_SKILL_LIST,
  FETCH_SKILL_LIST,
  FETCH_SKILL_JOURNEY_WORKSHOP_DATA,
  GET_SKILL_JOURNEY_WORKSHOP_DATA,
  FETCH_SKILL_JOURNEYS_REPORT_DATA,
  GET_SKILL_JOURNEYS_REPORT_DATA,
  FETCH_LIVE_ASSESSMENTS_REPORT_DATA,
  GET_LIVE_ASSESSMENTS_REPORT_DATA,
  FETCH_LIVE_ASSESSMENTS_LIST,
  GET_LIVE_ASSESSMENTS_LIST,
  FETCH_MARKET_COURSE_LIST,
  GET_MARKET_WEBINAR_LIST,
  GET_MARKET_COACHING_LIST,
  FETCH_MARKET_WEBINAR_REPORT_DATA,
  FETCH_MARKET_COACHING_REPORT_DATA,
  FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA,
  GET_MARKET_PUBLIC_CONFERENCE_LIST,
  FETCH_MARKET_MAZE_REPORT_DATA,
  GET_MARKET_MAZE_LIST,
  FETCH_MARKET_CHANNEL_REPORT_DATA,
  GET_MARKET_CHANNEL_LIST
} from '../types'

const handlingData = (field, value) => dispatch => {
  dispatch({
    type: REPORTS_HANDLING_DATA,
    payload: {
      field,
      value
    }
  })
}

const getCourseWorkshopReport = field => dispatch => {
  dispatch({
    type: FETCH_COURSES_REPORT_DATA,
    payload: true
  })
  return api.reports
    .getCourseWorkshopReport(field)
    .then(({ data }) => {
      dispatch({
        type: GET_WORKSHOP_COURSES_REPORT_DATA,
        payload: data
      })
      dispatch({
        type: FETCH_COURSES_REPORT_DATA,
        payload: false
      })
    })
    .catch(err => {
      dispatch({
        type: FETCH_COURSES_REPORT_DATA,
        payload: false
      })

      throw err
    })
}

const getUserTimeSpentReport = field => dispatch => {
  dispatch({
    type: FETCH_TIME_SPENT_DATA,
    payload: true
  })
  return api.reports.getUserTimeSpentReport(field).then(({ data }) => {
    dispatch({
      type: GET_USER_TIME_SPENT_REPORT_DATA,
      payload: data
    })
    dispatch({
      type: FETCH_TIME_SPENT_DATA,
      payload: false
    })
  })
}

const getReport = field => (dispatch, getState) => {
  const state = getState()
  dispatch({
    type: FETCH_REPORT_DATA,
    payload: {
      field,
      value: true
    }
  })
  return api.reports
    .getReport(field)
    .then(res => {
      dispatch(
        handlingData(field, { ...state.reports.courseQuiz, list: res.data })
      )
    })
    .then(() => {
      dispatch({
        type: FETCH_REPORT_DATA,
        payload: {
          field,
          value: false
        }
      })
    })
}

const getUserReport = id => dispatch => {
  dispatch({
    type: FETCH_USER_REPORT_DATA,
    payload: true
  })
  return api.reports.getUserReport(id).then(({ data }) => {
    dispatch({
      type: GET_USER_REPORT_DATA,
      payload: data
    })
    dispatch({
      type: FETCH_USER_REPORT_DATA,
      payload: false
    })
  })
}

const getCourseList = field => dispatch => {
  dispatch({
    type: FETCH_COURSE_LIST,
    payload: true
  })
  return api.reports.getCourseList(field).then(({ data }) => {
    dispatch({
      type: GET_COURSE_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_COURSE_LIST,
      payload: false
    })
  })
}

// marketplce report

const getMarketCourseList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_COURSE_LIST,
    payload: true
  })
  return api.reports.getMarketCourseList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_COURSE_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_COURSE_LIST,
      payload: false
    })
  })
}

const getMarketWebinarList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_WEBINAR_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketWebinarList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_WEBINAR_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_WEBINAR_REPORT_DATA,
      payload: false
    })
  })
}

const getMarketCoachingList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_COACHING_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketCoachingList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_COACHING_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_COACHING_REPORT_DATA,
      payload: false
    })
  })
}

const getMarketChannelList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_CHANNEL_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketChannelList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_CHANNEL_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_CHANNEL_REPORT_DATA,
      payload: false
    })
  })
}

const getMarketPublicConferenceList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketPublicConferenceList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_PUBLIC_CONFERENCE_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA,
      payload: false
    })
  })
}

const getMarketMazesList = field => dispatch => {
  dispatch({
    type: FETCH_MARKET_MAZE_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketMazeList(field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_MAZE_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_MARKET_MAZE_REPORT_DATA,
      payload: false
    })
  })
}

const getEventList = field => dispatch => {
  dispatch({
    type: FETCH_EVENT_LIST,
    payload: true
  })
  return api.reports.getEventList(field).then(({ data }) => {
    dispatch({
      type: GET_EVENT_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_EVENT_LIST,
      payload: false
    })
  })
}

const getSkillList = field => dispatch => {
  dispatch({
    type: FETCH_SKILL_LIST,
    payload: true
  })
  return api.reports.getSkillList(field).then(({ data }) => {
    console.log('dataskilllistaction', data)
    dispatch({
      type: GET_SKILL_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_SKILL_LIST,
      payload: false
    })
  })
}

const getLiveAssessmentsList = field => dispatch => {
  dispatch({
    type: FETCH_LIVE_ASSESSMENTS_LIST,
    payload: true
  })
  return api.reports.getLiveAssessmentsList(field).then(({ data }) => {
    console.log('dataliveassessmentsaction', data)
    dispatch({
      type: GET_LIVE_ASSESSMENTS_LIST,
      payload: data
    })
    dispatch({
      type: FETCH_LIVE_ASSESSMENTS_LIST,
      payload: false
    })
  })
}

const getEventWorkshopReport = field => dispatch => {
  dispatch({
    type: FETCH_EVENT_WORKSHOP_DATA,
    payload: true
  })
  return api.reports.getWorkshopEventsReport(field).then(({ data }) => {
    dispatch({
      type: GET_EVENT_WORKSHOP_DATA,
      payload: data
    })
    dispatch({
      type: FETCH_EVENT_WORKSHOP_DATA,
      payload: false
    })
  })
}

// getWorkshopSkillJourneysReport
const getSkillJourneyWorkshopReport = field => dispatch => {
  dispatch({
    type: FETCH_SKILL_JOURNEY_WORKSHOP_DATA,
    payload: true
  })
  return api.reports.getWorkshopSkillJourneysReport(field).then(({ data }) => {
    dispatch({
      type: GET_SKILL_JOURNEY_WORKSHOP_DATA,
      payload: data
    })
    dispatch({
      type: FETCH_SKILL_JOURNEY_WORKSHOP_DATA,
      payload: false
    })
  })
}

const getEventReport = (id, field) => (dispatch, getState) => {
  dispatch({
    type: FETCH_EVENTS_REPORT_DATA,
    payload: {
      field,
      value: false
    }
  })
  return api.reports
    .getEventReport(id, field)
    .then(res => {
      dispatch(handlingData(field, { list: res.data }))
      return res.data
    })
    .then(res => {
      dispatch({
        type: FETCH_EVENTS_REPORT_DATA,
        payload: {
          field,
          value: false
        }
      })
      return res
    })
}
const getCourseReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_COURSES_REPORT_DATA,
    payload: true
  })
  return api.reports.getCourseReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_COURSES_REPORT_DATA,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_COURSES_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getLessonReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_LESSON_REPORT_DATA,
    payload: true
  })
  return api.reports.getLessonReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_LESSON_REPORT_DATA,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_LESSON_REPORT_DATA,
      payload: false
    })
  })
}
const getQuizListReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_QUIZ_LIST_REPORT_DATA,
    payload: true
  })
  return api.reports.getLessonReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_QUIZ_LIST_REPORT_DATA,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_QUIZ_LIST_REPORT_DATA,
      payload: false
    })
  })
}
const getQuizReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_QUIZ_REPORT_DATA,
    payload: true
  })
  return api.reports.getQuizReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_QUIZ_REPORT_DATA,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_QUIZ_REPORT_DATA,
      payload: false
    })
  })
}

const getMazeReport = field => dispatch => {
  dispatch({
    type: FETCH_MAZE_REPORT,
    payload: true
  })
  return api.reports.getMazeReport(field).then(response => {
    dispatch({
      type: GET_MAZE_REPORT,
      payload: response.data.data
    })
    dispatch({
      type: FETCH_MAZE_REPORT,
      payload: false
    })
  })
}

const getMazeReportById = id => dispatch => {
  dispatch({
    type: FETCH_MAZE_REPORT_BY_ID,
    payload: true
  })
  return api.reports.getMazeReportById(id).then(response => {
    dispatch({
      type: GET_MAZE_REPORT_BY_ID,
      payload: response.data.data
    })
    dispatch({
      type: FETCH_MAZE_REPORT_BY_ID,
      payload: false
    })
  })
}

const getSkillJourneyReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_SKILL_JOURNEYS_REPORT_DATA,
    payload: true
  })
  return api.reports.getSkillJourneyReport(id).then(response => {
    console.log('skilldetaildata', response)
    dispatch({
      type: GET_SKILL_JOURNEYS_REPORT_DATA,
      payload: response.data
    })
    dispatch({
      type: FETCH_SKILL_JOURNEYS_REPORT_DATA,
      payload: false
    })
  })
}

const getLiveAssessmentsReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_LIVE_ASSESSMENTS_REPORT_DATA,
    payload: true
  })
  return api.reports.getLiveAssessmentsReport(id).then(response => {
    console.log('liveassessmentsdata', response)
    dispatch({
      type: GET_LIVE_ASSESSMENTS_REPORT_DATA,
      payload: response.data
    })
    dispatch({
      type: FETCH_LIVE_ASSESSMENTS_REPORT_DATA,
      payload: false
    })
  })
}

// for marketplace report
const getMarketplaceCourseReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_COURSES_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketCourseReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_COURSES_REPORT_DATA,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_COURSES_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getMarketplaceWebinarReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_MARKET_WEBINAR_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketEventReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_WEBINAR_LIST,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_MARKET_WEBINAR_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getMarketplaceCoachingReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_MARKET_COACHING_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketEventReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_COACHING_LIST,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_MARKET_COACHING_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getMarketplaceChannelReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_MARKET_CHANNEL_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketchannelReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_CHANNEL_LIST,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_MARKET_CHANNEL_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getMarketplacePublicConferenceReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketEventReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_PUBLIC_CONFERENCE_LIST,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_MARKET_PUBLIC_CONFERENCE_REPORT_DATA,
      payload: false
    })
    return data
  })
}

const getMarketplaceMazeReport = (id, field) => dispatch => {
  dispatch({
    type: FETCH_MARKET_MAZE_REPORT_DATA,
    payload: true
  })
  return api.reports.getMarketMazeReport(id, field).then(({ data }) => {
    dispatch({
      type: GET_MARKET_MAZE_LIST,
      payload: { field, data: data }
    })
    dispatch({
      type: FETCH_MARKET_MAZE_REPORT_DATA,
      payload: false
    })
    return data
  })
}

export {
  handlingData,
  getReport,
  getUserReport,
  getEventReport,
  getCourseReport,
  getLessonReport,
  getQuizReport,
  getQuizListReport,
  getCourseList,
  getEventList,
  getCourseWorkshopReport,
  getUserTimeSpentReport,
  getEventWorkshopReport,
  getMazeReport,
  getMazeReportById,
  getSkillList,
  getSkillJourneyWorkshopReport,
  getSkillJourneyReport,
  getLiveAssessmentsReport,
  getLiveAssessmentsList,
  getMarketplaceCourseReport,
  getMarketCourseList,
  getMarketplaceWebinarReport,
  getMarketWebinarList,
  getMarketCoachingList,
  getMarketplaceCoachingReport,
  getMarketPublicConferenceList,
  getMarketplacePublicConferenceReport,
  getMarketMazesList,
  getMarketplaceMazeReport,
  getMarketChannelList,
  getMarketplaceChannelReport
}
