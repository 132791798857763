import EditImage from './EditImage'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../../../../../../../store/actions'

const mapStateToProps = state => ({
  audioSynthesizer: state.audioSynthesizer,
  pageBuilder: state.pageBuilder
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTopicList: actions.topics.updateTopicList,
      upload: actions.library.upload,
      getSelectedTextVoiceType:
      actions.audioSynthesizer.getSelectedTextVoiceType
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(EditImage)
