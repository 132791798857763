import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import './MediaFilter.scss'


class PopupMediaFilter extends Component {
  static defaultProps = {
    lists: []
  }

  static propTypes = {
    ctrlHandleFilterChange: PropTypes.func
  }

  state = { mediaTypes: this.props.lists }
  // mediaTypes: [
  //   // {
  //   //   label: this.props.t('media:video'),
  //   //   value: general.VIDEO,
  //   //   selected: true
  //   // },
  //   {
  //     label: this.props.t('media:image'),
  //     value: general.IMAGE,
  //     selected: true
  //   },
  //   // {
  //   //   label: this.props.t('media:audio'),
  //   //   value: general.AUDIO,
  //   //   selected: true
  //   // },
  //   {
  //     label: this.props.t('media:gif'),
  //     value: general.GIF,
  //     selected: true
  //   }
  // ]

  generateMediaType = mediaTypes => {
    const filterMediaType = _.filter(mediaTypes, item => item.selected)

    const mediaTypeParam = _.map(filterMediaType, 'value').toString()

    this.props.ctrlHandleFilterChange(mediaTypeParam)
  }

  hangeFilterChange = (value, ind) => {
    const { mediaTypes } = this.state

    const temp = mediaTypes.map((obj, index) => {
      if (index === ind) {
        obj.selected = value
      } else {
        if (!value) {
          obj.selected = !value
        }
      }
      return obj
    })
    this.generateMediaType(temp)

    this.setState({
      mediaTypes: temp
    })
  }

  render() {
    return (
      <Fragment>
        <div className="filter-media">
          {this.state.mediaTypes.map((item, ind) => (
            <label className="filtermedia-wrapper">
              <input
                name="checkbox"
                type="checkbox"
                checked={item.selected}
                onChange={e => this.hangeFilterChange(e.target.checked, ind)}
              />
              <span className="checkmark" data-label={item.label}>
                {item.label}
              </span>
            </label>
          ))}
        </div>
      </Fragment>
    )
  }
}

export default withTranslation('media')(PopupMediaFilter)
