/*
 * @Author: your name
 * @Date: 2019-07-29 13:47:33
 * @LastEditTime: 2019-12-18 14:52:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /frontend/src/store/sagas/courses.js
 */
import { call, put, select } from 'redux-saga/effects'
import { getCoursesList, courseDelete } from '../../services/api/courses'

import * as courses from '../actions/courses'

export function * fetchCourses (action) {
  try {
    const state = yield select()
    yield put(
      courses.loadCourses(action.list, true, state.courses.hasMoreItems)
    )

    const response = yield call(getCoursesList, action.params)
    const data = response.data

    yield put(
      courses.loadCourses(action.list.concat(data), false, !!data.length)
    )
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}

export function * removeCourseById (action) {
  try {
    const response = yield call(courseDelete, action.id)

    if (response.status === 200) {
      yield put(courses.removeCourseFromList(action.id))
    }
  } catch (err) {
    console.log(JSON.stringify(err))
  }
}
