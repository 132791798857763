/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 16:41:54
 * @LastEditTime: 2019-12-19 10:53:49
 * @LastEditTime: 2019-09-09 11:43:43
 * @LastEditors: Please set LastEditors
 */

export default {
  general: {
    save: '儲存',
    autosaved: '已自動儲存',
    back_to: '返回到',
    by: '由',
    chat: '聊天',
    completed: '已完成',
    canceled: '已取消',
    component: '成分',
    confirm: '確定',
    course: '課程',
    date: '日期',
    details: '詳情',
    empty: '沒有數據',
    enrolled: '已注册',
    free: '免費',
    graph: '圖表',
    loading: '裝載中...',
    lesson: '課堂',
    lessons: '課堂',
    live_assessment: '現場直播評估',
    live_event: '現場直播活動',
    no: '否',
    of: '的',
    or: '或',
    organization: '組織',
    page: '頁面',
    pages: '頁面',
    personal: '個人',
    poll: '民意調查',
    rating: '評分',
    reset: '重設',
    signin: '登入',
    signup: '注冊',
    soon: '不久',
    type: '種類',
    uploading: '上載中...',
    yes: '是',
    something_went_wrong: '某些事出錯了!',
    view_all: '查看所有',
    upload_successful: '上載成功',
    upload_successful_message:
      '你的excel 表格文件已被成功上載和現在處理中。這可能需要一些時間。',
    check_email_upload_successful:
      '請 <0>檢查你的電郵</0> 來取得 <1>報告</1> 中的已上載用戶清單',
    is_private: '私人'
  },

  account: {
    title: '帳戶',
    subtitle: '管理你的設定和帳戶資料',
    saved: '帳戶已成功更改'
  },

  assessments: {
    title: '評估',
    subtitle: '創造一個評估',
    finished: '這評估已完成',
    countdown: '現場直播評估將會廣播於Live assessment will be broadcasting in ',
    create: '創造評估',
    list_title: '你的評估',
    delete_warning: '你確認想要刪除這項目？',
    details_title: '添加評估詳情',
    details_subtitle: '給你的評估名字和說給你的觀眾他們能期望由此學到什麼',
    disclaimer: `我保證我已記錄的評估將不會：
-違犯任何第三方的權利
-包含任何違法行為，包括誹謗，猥褻，不雅，淫蕩，色情，暴力，濫用，侮辱，威脅，或歧視內容，甚至偽裝成星號
-包含任何人身攻擊在其他人上
-包含任何冒充其他參加者，公眾人物或名人
-未經該指定人同意前包含任何人的任何記錄或個人資訊和不會違反世界各地的任何數據保護法規 
(This sentence has without the consent of such individual put at the front)
-包含任何商業招標或各種不當訊息`,
    assessment_details: '評估詳情',

    assessment_settings: '評估設定'
  },

  buildersettings: {
    title: '發佈設定',
    subtitle: '添加設定到你的活動，誰可觀看和與你的活動互動'
  },

  buttons: {
    market_place: 'Market Place',
    back_to_normal: 'Back To Normal',
    add: '添加',
    add_page: '添加頁面',
    add_lesson: '添加課堂',
    add_answer: '添加新答案',
    add_group: '添加群組',
    add_organization: '添加組織',
    add_question: '添加問題',
    add_quiz: '添加測驗',
    add_users: '添加用戶',
    back: '返回',
    back_to_course: '返回到課程概要',
    back_to_lesson: '返回到課堂概要',
    back_to_media: '返回到媒體圖書館',
    back_to_details: '返回到詳情',
    cancel: '取消',
    clear: '清除',
    close: '關閉',
    clone: '複製',
    close_lesson: '關閉課堂',
    clone_course: '複製課程',
    confirm: '確認',
    create: '創造',
    download: '下載',
    download_mass_user_tamplate: '下載樣板',
    add_mass_users: '添加大量用戶',
    upload_excel_list: '上載excel表格清單',
    delete: '刪除媒體',
    edit: '編輯',
    edit_course: '編輯課程',
    enroll: '註冊',
    finish: '完成',
    upload_image: '上載圖片',
    upload_image_video: '上載圖片或視頻',
    select_media_library: '選擇媒體圖書館',
    make_public: '設成公開',
    make_private: '設成私人',
    make_org: '設成組織',
    media: '媒體圖書館',
    my_theme: '我的主題',
    next: '下一個',
    next_lesson: '下一個課堂',
    next_step: '下一步',
    previous_step: '上一步',
    publish: '發佈',
    publish_assessment: '發佈你的評估',
    publish_course: '發佈你的課程',
    publish_event: '發佈你的活動',
    reset: '重設',
    reset_elements: '重設元件',
    save: '儲存',
    creating: '創造中...',
    updating: '上載中...',
    saved: '已儲存',
    save_for_later: '儲存留下來後用',
    save_settings: '儲存設定',
    select_files: '選擇文件',
    select_theme: '選擇主題',
    start_lesson: '開始課堂',
    update: '更新',
    upload: '上載',
    upload_audio: '上載音訊',
    upload_photo: '上載個人資料圖片',
    upload_video: '上載視頻',
    enrolled: '已注冊',
    unenroll: '取消註冊',
    from_library: '媒體圖書館',
    remove: '清除',
    removed: '已清除',
    start_broadcast: '開始廣播',
    end_broadcast: '完結廣播',
    camera: '相機',
    screen: '屏幕',
    screen_with_camera: '有相機的屏幕',
    insert: '插入',

    inserting: '插入中'
  },

  card: {
    quick_add: '快速添加',
    add: '添加',
    clone: '複製',
    completed: '課堂已完成'
  },

  course: {
    upcoming_course: '即將來臨的課程',
    unPublishEvent: '未發佈的活動',
    unPublishAssessment: '未發佈的評估',
    pastCourse: '訓練課程',
    upcoming: '即將來臨的現場直播活動',
    pastEvent: '過去的現場直播活動',
    pastAssessment: '過去的現場直播評估',
    upcomingAssessment: '即將來臨的現場直播評估',
    created: '創造日期：',
    assignees: '受託人',
    organisations: ' {{ 組織 }} 組織 , <2> {{ 用戶 }} 用戶</2>',
    event_date: '現場直播活動日期 <1> {{ 日期 }} </1>',
    delete_warning: '你確認想要刪除這個',
    clonning_no_lesson: '請添加最少一個課堂來復製這個課程',
    clone_warning: '你確認你想要複製這個',
    clone_success: '正在處理複製中 ',
    disclaimer: `我保證我的課程不會：
-違犯任何第三方的權利
-包含任何違法行為，包括誹謗，猥褻，不雅，淫蕩，色情，暴力，濫用，侮辱，威脅，或歧視內容，甚至偽裝成星號
-包含任何人身攻擊在其他人上
-包含任何冒充其他參加者，公眾人物或名人
-未經該指定人同意前包含任何人的任何記錄或個人資訊和不會違反世界各地的任何數據保護法規 
(This sentence has without the consent of such individual put at the front)
-包含任何商業招標或各種不當訊息`
  },

  coursecreate: {
    dropzone_text: '插入媒體',
    sidebar_text: '選擇一個主題 <1/>給你的 <3/>課堂',
    delete_warning: '你確認你想要刪除這課堂？'
  },

  coursepage: {
    quizview_loading: '檢查答案中。請稍候...',
    quizview_answer: '你的答案'
  },

  coursequiz: {
    title: '所有課堂的測驗'
  },

  dashboard: {
    tooltip_title: '不要再顯示這個視頻',
    page_title: '你的課程統計',
    page_subtitle: '你創造的課程狀態',
    profile_add_info: '添加你的 <1>{{未完成文字}}</1> 來完成你的個人資料',
    profile_build: '建立你的個人資料 <1>here</1>, 給我們知道你的興趣和技能',
    profile_title: 'Profile Strength 個人資料強度',
    more_details: '更多詳情'
  },

  errors: {
    answer: '請輸入答案 ',
    answer_correct: '選擇正確的答案',
    code: '請輸入你的驗證碼',
    criterion: '請輸入標準 ',
    duration: '時間長短是需要的 ',
    element: 'Please input element 請輸入元件',
    email: '請輸入你的電郵',
    email_invalid: '輸入的電郵無效',
    feedback_correct: '請輸入正確的反饋 ',
    feedback_incorrect: '請輸入不正確的反饋',
    host: '請輸入活動主持人',
    host_assessment: '請輸入評估主持人',
    image: '請上載一張圖片',
    location: '請輸入你的所在地 ',
    live_location: '請選擇一個廣播地點',
    limit: '請輸入現場直播分鐘',
    min_3char: '3 characters minimum 最少三個字',
    number: '請輸入正數 ',
    host_email_require: `主持人的電郵是需要的`,
    attendee_email_require: `參加者的電郵是需要的`,
    name: '請輸入你的名稱',
    not_found_course: '找不到課程',
    not_found_event: '找不到活動',
    not_found_assessment: '找不到評估',
    password: '請輸入你的密碼',
    password_digit: '最少一個數字',
    password_short: '密碼太短，最少十個字母',
    password_special: '最少一個特殊符號',
    password_uppercase: '最少一個大階字母',
    password_lowercase: '最少一個小階字母',
    phone: '請輸入你的電話',
    phone_invalid: '電話號碼無效',
    position: '請輸入你的職位',
    question: '請輸入問題',
    start_date: '開始日期和時間是需要的',
    tag: '請選擇一個標籤',
    topics: '請選擇最少一個題目',
    live_topics: '請添加題目',
    title: '請輸入標題',
    title_assessment: '請輸入評估標題',
    title_course: '請輸入課程標題',
    title_empty: '標題不能是空的',
    title_event: '請輸入活動標題',
    title_lesson: '請輸入課堂標題',
    title_quiz: '請輸入測驗名稱',
    username: '請輸入你的用戶名字 ',
    upload_error: '錯誤 – 文件不兼容！',
    upload_more_than_10: '你只可以一次上載十個文件',
    topics_max: '請添加最多三個題目',
    upload_excel: '請確認你已上載正確的文件',
    title_excel: '錯誤上載樣板：',
    file_size_exceed: '文件大小超過',
    subtitle_excel: '請確認你使用已提供的樣板和已正確地填寫各欄',
    select_broadcast_option: '請選擇一個選項來廣播這個活動 ',
    multi_hosts_number_error: '演講者人數不能超過已在這活動指定的',
    conference_people_number_error: '參加者人數不能超過已在這活動指定的',
    select_max_host: '為你的活動選擇最少一個演講者',
    select_max_conference: '為你的活動選擇最少一個參加者',
    stream_stop_error: '流播已意外地停止！ ',
    unsecure_context:
      '致命錯誤：瀏覽器不能存取相機和麥克風因為不安全的環境。請安裝SSL安全套接層並使用https超文本傳輸安全協定存取',
    ws_not_supported: '致命錯誤：網絡傳輸協定不支援在這個瀏覽器',

    no_org: '選擇最少一個組織來繼續',
    no_org_group: '選擇最少一個組織群組來繼續.',
    waiting_to_join: '等待某人加入！'
  },

  events: {
    title: '添加現場直播活動詳情',
    change_host: '改變主持人',
    finished: '這活動已完成 ',
    countdown: '現場直播活動將會廣播於',
    subtitle: '給你的現場直播活動名稱和說給你的觀眾他們能期望由此學到什麼',
    poll_disabled: '民意調查不適用於這活動',
    disclaimer: `我保證我已記錄的活動不會：
  -違犯任何第三方的權利
  -包含任何違法行為，包括誹謗，猥褻，不雅，淫蕩，色情，暴力，濫用，侮辱，威脅，或歧視內容，甚至偽裝成星號
  -包含任何人身攻擊在其他人上
  -包含任何冒充其他參加者，公眾人物或名人
  -未經該指定人同意前包含任何人的任何記錄或個人資訊和不會違反世界各地的任何數據保護法規
  -包含任何商業招標或各種不當訊息'`,
    prompt_message: '離開這個頁面將會完成你的活動，你確認要離開這個頁面？',
    duration_exceed_message: '你的廣播超時，這程式會停止廣播',
    before_schedule_message:
      '你將會於這活動預定時間前開始這廣播，請注意停止這廣播將會結束這活動',
    browser_unsupportive_message:
      '你的瀏覽器不支援屏幕分享。你可以看到支援的瀏覽器在這鏈結',
    cancel_switch_host_message: '主持人沒有空。這活動將會由你的終端上繼續',
    coaching_prompt_message: '你不能離開這頁面直至這活動完成為止',
    event_modal_warn: '你想要離開這個活動？',
    confirm_message: '你的活動將會完結如果你點擊好',
    screen_share_denied: '你已拒絕去分享你的屏幕',
    media_not_found: '相機或麥克風不能被找到或你的裝置不允許',
    media_not_access: '相機或麥克風正在被其他流程使用所以你的裝置不允許閱讀',
    constraint_error:
      '這裡沒有已找到的設備可適合你的視頻和音訊限制。你可以改變視頻和音訊的限制',
    media_access_denied: '你不允許去存取相機和麥克風'
  },

  filters: {
    days7: '上七天',
    days15: '上十五天',
    days30: '上三十天',
    days365: '上三百六十五天',
    date: '日期已創造',
    relevance: '關聯',
    popularity: '普及',
    name: '名稱',
    group: '群組',
    Newest_to_Oldest: '最新至最舊',
    Oldest_to_Newest: '最舊至最新',
    A_Z: 'A to Z',
    Z_A: 'Z to A'
  },

  helpers: {
    password:
      '為確保你的帳戶安全我們需要最少一個小階字母，數字在你的密碼裏。你也需要最少有十個字母的長度',
    phone: '請包含你的電話國家代碼，例如：+65 ',
    username:
      '你的用戶名字會當成公開資訊和會有需要時分享給其他用戶。所以，我們建議你不要使用個人資料在你的用戶名字上',

    public_description_normal:
      '你的 <0><0/></0>已被儲存和默認當作為私人。這意指只有你可以看到這內容。要發佈這內容你可以選擇以下其中一個選項。公開發佈你的課程意指它將會供應 （I changed available into provide – the red highlight, as available doesn’t make sense in Chinese. Change it back to 有空 if you wish to stick with available）給這平台上的所有用戶。那指定用戶選項 使你能去分享你的課程給已聯系到分享看帳戶的電郵地址的指定用戶。',

    public_description_admin:
      '這個 <0><0/></0> 已被儲存和默認當作為私人。這意指只有你（和內容創造者）可以看到這內容。 要發佈這內容你可以選擇以下其中一個選項。公開發佈你的課程意指它將會供應 （changed available into provide. Replace with有空 if you want to stick with available）給這平台上的所有用戶 - 包括不在你組織裏的用戶）發佈它到這組織 意指這組織內的所有用戶將會能看到它。你也可以發佈它到一個或多個群組，意指只有在這些群組裏的用戶才能看見它。最後，你有個選項去發佈它給指定用戶的分享看帳戶電郵地址，不管指定用戶是否包括在你的組織裏。'
  },

  labels: {
    add: '添加',
    portrait: 'Crop Portrait',
    landscape: 'Crop Landscape',
    answer_type: '答案類型',
    answers: '答案',
    back_to_quiz: '返回到測驗清單',
    back_to_question: '返回到問題清單',
    chat: '聊天',
    chat_available: '聊天功能不可用該這活動',
    chat_report: '聊天報告',
    choice_single: '單一選擇',
    choice_multiple: '多項選擇',
    choice_free: '「自由」選擇 ',
    choice_sorting: '「排序」選擇',
    choice_matrix: '「矩陣排序」選擇',
    clone_lesson_permission: '複製准許',
    code: '驗證碼',
    correct: '正確',
    created: '日期已創造',
    clone_modal: '複製課程',
    cloning_course: '複製課程中…',
    cloning: '複製中… ',
    cloning_course_desc: '這過程將會需要一些時間。 請耐心等候',
    cloning_success: '課程 "<0/>" 已經被複製成功！',
    description: '簡介',
    disclaimer: '免責聲明',
    drop_file: '放下文件在這裡…',
    duration: '時間長短',
    edit: '編輯 ',
    email: '電郵',
    event: '活動',
    event_all: '所有他的活動',
    event_date: '現場直播活動的當地日期/時間',
    event_this: '這個活動',
    expire: '過期在',
    expiry_date: '過期日',
    forgot: '忘記密碼？',
    group: '群組',
    groups_list: '群組清單',
    host: '主持人',
    id_annotation: '注釋身份',
    id_quiz: '測驗身份',
    incorrect: '不正確',
    interests: '興趣',
    invited: '已邀請',
    live_chat: '現場討論',
    live_stream_limit: '現場直播分鐘',
    contact_text: '要改變你的每月現場直播限制請聯絡 ',
    contact_admin:
      '<0>如你想要改變你的每月現場直播限制請聯絡</0> <1>admin@sharelook.com</1>',
    limit_stream_text:
      '剩餘的現場直播分鐘：<0>{{ 限制 }}</0>分鐘<1/><2/> <3>要改變你的每月現場直播限制請聯絡</3> <4>admin@sharelook.com</4>',
    location: '所在地',
    lessons: '課堂',
    location_broadcast: '主持人服務器所在地 ',
    location_broadcast_subtitle:
      '添加一個最近廣播主持人地點的位置來减少視聽延遲',
    media_manager: '媒體管理員',
    minutes: '分鐘',
    name: '名字',
    online_quiz: '線上測驗',
    organiser: '作者',
    organization: '組織',
    overall_emotion: '整體經驗：',
    organizations_list: '組織清單',
    password: '密碼',
    participants: '參加者',
    picture: '相片（十六比九） ',
    phone: '電話',
    photo: '圖片',
    poll: 'Poll 民意調查',
    position: 'Position 職位',
    processing: 'Processing 處理中',
    privacy_settings: 'Privacy Settings 私人設定',
    question: 'Question 問題',
    quiz_create: 'Create New Quiz 創造新測驗',
    quiz_name: 'Quiz Name 測驗名稱',
    quiz_update: 'Update quiz 更新測驗',
    select_organization: 'Select an organization 選擇一個組織',
    settings: 'Settings 設定',
    show: 'Show 顯示',
    show_graph: 'Show graph result 顯示圖形結果',
    signin: 'Sign In 登入',
    signup: 'Sign Up 註冊',
    sort_by: 'Sort by排序方法 ',
    start_date: 'Start date 開始日期',
    tags: 'Tags 標籤',
    terms: '我同意這些 <1> 條款及細則 </1>.',
    title: 'Title 標題',
    topics: 'Topics 題目',
    topic: 'Topic 題目',
    total_comments: 'Total Comments 總共意見',
    username: 'Username 用戶名字',
    users_list: 'List of Users 用戶清單',
    users_emails: 'Insert specific users emails 插入指定用戶電郵',
    description_specifiction_email:
      '請在各行輸入一個電郵地址來把我分享到多個指定用戶',
    message_specific_email: '請輸入一個指定用戶的電郵',
    viewers: '觀眾',
    edit_course: '編輯',
    edit_assessment: '編輯',
    edit_event: '編輯',
    delete_course: '刪除',
    delete_event: '刪除',
    delete_assessment: '刪除',
    view_course: '觀看',
    view_event: '觀看',
    view_assessment: '觀看',
    date_event: '現場直播活動日期',
    date_assessment: '現場直播評估日期',
    clone_of: '複製',
    clone_course:
      'Create a clone of this course that you can modify independently of the original, includes all lessons and pages. ',
    make: '制造',
    available_to: '提供(available changed into provide)給所有分享看用戶',
    uploaded_by: '上載者： ',
    course: '課程',
    assessment: '評估',
    user_not_exist: '用戶不存在，你想要送出一個邀請？',
    check_user: '通知',
    user_not_exist_in_org: '用戶不存在這組織內，你想要送出一個邀請？',
    title_modal_stream: '改變組織現場直播限制 ',
    des_warning_stream: '你將會改變 ',
    monthly_stream: '每月的現場直播限制：',
    min_per_month: '分鐘/月份',
    broadcast_label: '我想要做：',
    hosts_emails: `插入指定主持人的電郵`,
    attendee_emails: `插入指定參加者的電郵`,
    description_host_specifiction_email: '請在各行輸入一個電郵',
    presenter_label: '主持人',
    presenter_label_conference: '人數',
    event_privacy: '會議模式'
  },

  broadcastOptions: {
    stream_from_phone: '在我的手機上作現場廣播',
    other_webrtc: '由我的電腦上作線上研討會',
    multihost: '由我的電腦上作多播線上研討會',
    peer2peer: '輔導課程',
    conference: '現場直播會議'
  },

  lessoncreate: {
    title: '添加課堂詳情',
    subtitle: '給你的課堂名稱和說給你的觀眾他們能期望由此學到什麼',
    input_label_title: '課堂標題',
    input_label_topics: '課堂題目',
    input_label_description: '課堂簡介',
    delete_warning: '你確定你想要刪除這個頁面？'
  },

  lessonoverview: {
    title: '添加課堂',
    subtitle: '添加現有課堂或創造一個新的',
    new_lesson: '創造一個新的課堂',
    new_lesson_sub: '由零開始創造一個全新課堂！',
    existing_lesson: '選擇現有課堂',
    existing_lesson_sub:
      '連接到一個現存的課堂，這課堂所作的改變將也會影響到該課堂在其他的課程裏',
    clone_lesson: '複製現存課堂',
    clone_lesson_sub:
      '為在這課程裏的課堂制造一個獨特副本，這課堂所作的改變將不會影響到這課堂的正本',
    clone_lesson_modal_sub:
      '請點擊課堂下方的複製按鍵來把你想要的添加到你的課程裏'
  },

  management: {
    groups_title: '管理群組',
    delete_warning: '你確認？',
    organizations_title: '我的組織',
    organizations_subtitle: '添加組織到你的清單',
    users_title: '管理用戶',
    users_position: '高級帳戶管理員',
    user_not_found: '找不到用戶',
    select_org: '請找和選擇一個組織來管理用戶'
  },

  audio: {
    modal_title: '插入音訊',
    list_view: '列表顯示',
    create_audio_modal_title: '創造音訊',
    edit_audio_modal_title: '編輯音訊',
    public_audio_not_found: '找不到音訊',
    private_audio_not_found: '您還沒有上傳任何音訊',
    are_you_sure_to_delete: '您確認想要刪除？',
    type_something: '輸入一些東西',
    select_voice: '選擇',
    file_name: '文件名字',
    description: '簡介',
    topic: '題目',
    key_words_tag: '關鍵字標籤',
    mp3_audio: 'MP3音訊',
    created: '已創造',
    duration: '時間長短',
    close: '關閉'
  },

  image: {
    modal_title: '插入圖片',
    image: '圖片',
    pixel: '像素',
    dimension: '尺寸',
    public_image_not_found: '找不到圖片',
    private_image_not_found: '你還沒有上載任何圖片'
  },

  annotation: {
    modal_title: '注釋'
  },

  text: {
    modal_title: '文字編輯器'
  },

  video: {
    modal_title: '插入視頻',
    image: '視頻',
    mp4_video: 'MP4視頻 ',
    public_video_not_found: '找不到視頻 ',
    private_video_not_found: '你還沒有上載任何視頻'
  },

  media: {
    title: '媒體圖書館',
    subtitle: '你的文件圖書館',
    modal_title: '上載媒體',
    delete_warning: '確認刪除',
    delete_message: '已刪除的媒體將仍然會出現在使用過它們的頁面上',
    dropzone_text: '或拖動和放下',
    delete_success: '媒體已經被成功刪除 ',
    upload_file: '上載文件',
    drag_drop: '或拖動和放下',
    import_file: '由你的電腦中導入文件',
    no_media: '你還沒有上載任何媒體',
    media_type: '媒體類型',
    video: '視頻',
    image: '圖片',
    audio: '音訊',
    gif: 'GIF',
    transcribed_text: 'Transcribed Text',
    media_not_found: '找不到媒體 ',
    of: '的',
    disclaimer: `我保證我的媒體不會：
-違犯任何第三方的權利
-包含任何違法行為，包括誹謗，猥褻，不雅，淫蕩，色情，暴力，濫用，侮辱，威脅，或歧視內容，甚至偽裝成星號
-包含任何人身攻擊在其他人上
-包含任何冒充其他參加者，公眾人物或名人
-未經該指定人同意前包含任何人的任何記錄或個人資訊和不會違反世界各地的任何數據保護法規 
(This sentence has without the consent of such individual put at the front)
-包含任何商業招標或各種不當訊息`,
    my_media: '我的媒體',
    public_library: '公共圖書館',
    organization_library: '組織圖書館',
    upload_from_computer: '由電腦上載',
    create: '創造',
    all: '所有',
    male: '男性',
    female: '女性',
    processing_audio: '處理音訊中',
    create_annotation: '創造注釋',
    popup_over_lesson_text: '註釋將會於點擊後在課堂頁面上彈出',
    edit_detail: '編輯詳情',
    delete: '刪除',
    go_create_audio: '去創造音訊',
    show_more_details: '顯示更多詳情',
    show_less_details: '顯示較少詳情',
    drop_files: '放下文件和哪裡去上載',
    or: '或',
    import_files: '由電腦輸入文件'
  },

  navigation: {
    account: '帳戶',
    assessments: '評估',
    dashboard: '儀表板',
    groups: '群組管理',
    logout: '登出',
    organisations: '組織',
    organizations_users: '組織和用戶',
    reports: '報告',
    schedule: '時間表',
    training: '訓練',
    users: '用戶',
    nowLive: '現在播放',
    workshop: '我的工作坊'
  },

  onboarding: {
    step1_title: '第一步：說給我們一些關於你的某些事',
    step1_subtitle: '你叫什麼名字？ 你從哪裡來？ 你的職位是什麼？',
    step2_title: '第二步：選擇一些技能你能改善的',
    step2_subtitle:
      '然後，告訴我們你有什麼想明白更多。 可能是一些事你還沒學過的？一個新的學習領域？你的愛人做什麼的？'
  },

  pagebuilder: {
    title: '創造課堂',
    subtitle:
      '是時候創造內容給你的觀眾從你學習，你可以添加相片，視頻和信息圖表',
    select_layout: '選擇一個頁面設計',
    template1: '空白',
    template2: '標題和字幕',
    template3: '標題和文字',
    template4: '標題，媒體和文字',
    template5: '標題和媒體',
    template6: '標題和測驗'
  },

  pageconstructor: {
    title: '創造和複製課堂',
    subtitle: '是時候創造內容給你的觀眾從你學習，你可以添加相片，視頻和信息圖表'
  },

  passwordreset: {
    success_coded:
      '我們已寄出一個驗證碼到你提供的電郵地址，請複製該驗證碼到下面這欄來設定一個新密碼 ',
    success_changed: '你已成功設定一個新密碼。你可以現在登入'
  },

  placeholders: {
    search: '搜尋',
    answer: '答案',
    chat: '說些話…',
    code: '驗證碼',
    criterion: '標準',
    date_event: '選擇日期和時間',
    date_start: '開始日期',
    date_expire: '過期日',
    description: '簡介',
    elements: '分類元件',
    email: '電郵',
    feedback_correct: '反饋正確 ',
    feedback_incorrect: '反饋不正確',
    host: '主持人',
    keywords: '搜尋（多個）關鍵字',
    lesson_description: '課堂簡介',
    location: '所在地',
    minutes: '分鐘',
    name: '名稱',
    organiser: '作者',
    organization: '組織',
    password: '密碼',
    phone: '電話',
    photo: '圖片',
    picture: '相片',
    point: '點',
    position: '職位',
    question: '問題',
    quiz: '測驗名稱',
    search_course: '使用課程搜尋',
    search_media: '搜尋媒體',
    search_name: '使用名字搜尋',
    search_title: '使用標題搜尋',
    search_training: '搜尋訓練',
    settings: '設定',
    tags: '標籤',
    title: '標題',
    topics: '題目',
    username: '用戶名字',
    keyword: '關鍵字',
    max_500_characters: '最多五百個字',
    no_description: '這個文件沒有簡介',
    no_keyword: '這個文件沒有關鍵詞 ',
    no_topics: '這個文件沒有一個題目集',
    feedback: '信息',
    contact: '我們可以怎樣幫忙？'
  },

  publishsettings: {
    title: '發佈設定',
    event_subtitle: '添加設定到你的活動，誰能觀看和與你的活動互動',
    assessment_subtitile:
      '給你的評估名稱和說給你的觀眾他們應該期望由此學到什麼',
    course_subtitle: '添加設定到你的課程，誰能觀看和與你的課程互動',
    disclaimer: `1.	注冊

  你在注冊為用戶/創造帳戶時你同意我們的[用戶協議]和確認閱讀了我們的[隱私權政策]

  2.	創造課程

  你在創造課程內容和使用我們的工具時，你同意我們的[用戶協議]和確認閱讀了我們的[隱私權政策]

  3.	創造活動

  你在創造活動和使用我們的工具時，你同意我們的[用戶協議]和確認閱讀了我們的[隱私權政策]

  4.	創造評估

  你在創造評估和使用我們的工具時，你同意我們的[用戶協議]和確認閱讀了我們的[隱私權政策]`,
    dates_validation_error: '兩個日期都應該要填寫',
    emails_validation_error: '電郵清單無效',
    specific_email_validation_error: '只允許一個電郵',
    i_agree_to_these: '我同意這些',
    terms_and_conditions: '條款及細則',
    PublicDescription:
      '免費獨立用戶: （私人和公共選項）你的［課程｜活動｜評估］已被儲存和默認當作為私人。這意指只有你可以看到這內容。要發布這內容你可以在下面選擇將它公開。這意指這課程將會供應 （changed available into provide. Replace with有空 if you want to stick with available）給這平台上的所有用戶。',

    OrganisationsDescription:
      '組織用戶：（使用「發佈」來代替「完成」和把它們帶到與其他用戶相同，但沒有任何發佈選項的發佈頁面上。）你的［課程｜活動｜評估］已被儲存和默認當作為私人。 這意指只有你和你的組織管理員能看到它。作為用戶，你不能在你的組織裏自我發佈你的內容因為這需要管理員權限。請和你的管理員聯絡和問他們來公開發佈你的內容，到整個組織，到指定用戶或組織內的指定群組。',

    OrganisationGroupsDescription:
      '組織管理員：（用於組織內製作的內容：私人，公開，組織，群組，指定用戶）：這［課程｜活動｜評估］已被儲存和默認當作為私人。這意指只有你（和內容創造者）可以看到這內容。要發佈這內容你可以選擇以下其中一個選項。公開發佈你的課程意指這課程將會供應 給這平台上的所有用戶 - 包括不在你組織裏的用戶）發佈它到這組織意指這組織內的所有用戶將會能看到它。你也可以發佈它到一個或多個群組，意指只有在這些群組裏的用戶才能看見它。最後，你有個選項去發佈它給已聯系到分享看帳戶的電郵地址的指定用戶，不管指定用戶是否包括在你的組織裏。',

    SpecificUsersDescription:
      '已付費獨立用戶：（私人，公共和指定用戶）你的［課程｜活動｜評估］已被儲存和默認當作為私人。這意指只有你可以看到這內容。要發佈這內容你可以選擇以下其中一個選項。發佈你的課程到公開意它將會供應(changed available into provide. Replace with有空 if you want to stick with available)給這平台上的所有用戶。那指定用戶選項 使你能去分享你的課程給已聯系到分享看帳戶的電郵地址的指定用戶。'
  },

  quiz: {
    quiz_empty: '你還沒有創造一個測驗',
    questions_empty: '你還沒有創造一條問題'
  },

  regions: {
    asia_pacific_australia: '澳大利亞',
    asia_pacific_india: '印度',
    asia_pacific_japan: '日本',
    asia_pacific_s_korea: '南韓',
    asia_pacific_singapore: '新加坡',
    asia_pacific_taiwan: '臺灣',
    eu_belgium: '比利時',
    eu_germany: '德國',
    eu_ireland: '愛爾蘭',
    south_america_brazil: '巴西',
    us_central_iowa: '愛荷華州',
    us_east_s_carolina: '南北卡羅來納州',
    us_east_virginia: '維珍尼亞州',
    us_west_california: '加州',
    us_west_oregon: '俄勒岡州'
  },

  reports: {
    title: '報告',
    workshop_report: '工作坊報告',
    user_report: '用戶報告',
    organization_workshop: '組織工作坊',
    course: '課程',
    live_event: '現場直播活動',
    workShop_graph: '工作坊圖表',
    organization_account: '組織帳戶',
    all: '所有',
    name: '名稱',
    id_number: '身份號碼',
    email: '電郵',
    search_user_report: '搜尋用戶報告',
    participant: '參加者',
    participants: '參加者',
    host: '主持人',
    username: '用戶名字',
    event: '活動',
    quiz: '測驗',
    assessment: '評估',
    time: '時間',
    no_recent_activity: '沒有最近活動',
    workshops: '工作坊',
    courses: '課程',
    live_events: '現場直播活動',
    live_assessments: '現場直播評估',
    quizzes: '測驗',
    overview: '總體',
    by: '由',
    views: '觀眾數量',
    status: '狀態',
    poll: '民意調查',
    comment: '評論',
    comments: '評論',
    no_comments: '沒有評論',
    no_polls: '沒有民意調查',
    live_date: '現場直播日期',
    score: '得分',
    question: '問題',
    submit: '提交',
    recent_activity: '最近活動',
    ago: '前',
    no_data_found: '找不到數據',
    start_end_live: '開始 / 完結現場直播',
    join_leave_date: '加入 / 離開',
    watched_time: '已觀看時間',
    complete: '完成',
    in_complete: '未完成',
    today: '今天',
    seven_days: '七天',
    last_month: '上個月',
    one_year: '一年',
    all_time: '所有時間',
    report: '報告',
    from_date: '由日期',
    to_date: '到日期',
    all_workshop: '所有工作坊',
    all_participants: '所有參加者',
    live_assessment: '現場直播評估',
    users: '用戶',
    download: '下載',
    recent: '最近',
    WorkShop: '工作坊',
    Activity: '活動',
    created_date: '創造日期',
    lessons: '課堂',
    lesson: '課堂',
    completed: '已完成',
    report_download: '報告下載',
    this_month: '這個月',
    search_course: '搜尋課程',
    participants_graph: '參加者圖表',
    viewed: '已觀看',
    enrolled: '已註冊',
    description: '簡介',
    topic: '題目',
    analytic: '分析',
    quiz_list: '測驗清單',
    select_one: '選擇一個',
    group_status: '群組狀態',
    duration_min: '時間長短(分鐘)',
    submit_poll: '提交民意調查',
    download_events: '下載活動',
    search_event: '搜尋活動',
    duration: '時間長短',
    total_participants: '參加者總計',
    message: '信息',
    avg_message: '一般信息',
    per_message: '信息/活動的百分比'
  },

  signup: {
    success_step1:
      '我們已發送一個驗證碼到你提供的電子郵件，請複製這驗證碼到下面該欄來確認你的註冊',
    success_step2: '謝謝，你已成功注冊',
    privacy: `我同意分享看的 <1> 條款及細則 </1> 和 <3> 隱私權政策 </3>`
  },

  stream: {
    emotion_impressive: '印象深刻的',
    emotion_unimpressive: '普通的',
    message_not_started: '即將來臨',
    message_running: '現場直播',
    message_processing:
      '我們正在處理現場直播記錄中來作回播。請等待回來去查看這視頻和繼續添加評分和反饋'
  },

  tabs: {
    audience: '觀眾',
    course: '課程概要',
    details: '現場直播活動詳情',
    description: '簡介',
    host: '主持人',
    lesson: '課堂概要',
    lessons: '課堂',
    overview: '概要',
    page: '頁面建設工具',
    publish: '發佈 ',
    settings: '發佈設定',
    total_views: '總觀看數字',
    live_participates: '現場直播參加者',
    live_chats: '現場直播聊天',
    live_quiz: '現場直播民意調查',
    trainer_rating: '訓練者評分',
    trainer_times: '訓練者時間',
    courses_quizzes: '課程測驗',
    user_list: '用戶清單',
    discussion: '討論',
    notes: '我的筆記'
  },

  topics: {
    SALES_TRAINING: '銷售訓練',
    NEGOTIATION_TRAINING: '談判訓練',
    CROSS_CULTURAL_AWARENESS: '「跨文化」意識',
    NETWORKING_SKILLS: '社交技能',
    SOFTWARE_ARCHITECTURE: '軟件架構',
    PROJECT_MANAGEMENT: '項目管理',
    DIGITAL_TRANSFORMATION: '數碼轉型',
    IT_CONSULTING: '技術諮詢',
    MANAGEMENT_CONSULTING: '管理諮詢',
    HR_CONSULTING: '人力資源諮詢',
    INTERNET_OF_THINGS: '物聯網',
    CYBERSECURITY: '網絡安全',
    PRIVACY_AND_GDRP: '隱私權和〈一般數據保護條例〉'
  },

  training: {
    title: '訓練',
    title_assessment: '評估',
    subtitle: '課程和現場直播活動',
    enrolled_title: '己註冊的課程和現場直播活動',
    enrolled_subtitle: '你己註冊到的課程和現場直播活動',
    enrolled_message: '你現在沒有註冊到任何課程和活動',
    past_live_event_title: '過去的現場直播活動',
    past_live_event_subTitle: '已完成的現場直播活動',
    past_live_event: '這裡現在沒有過去的現場直播活動',
    recomended_title: '推薦的課程和現場直播活動',
    now_live_event_title: '現在現場直播',
    now_live_event_subTitle: '已開始或將會開始的活動',
    now_live_event: '這裡現在沒有現場直播活動',
    webinar_event_title: '線上研討會頻道',
    webinar_event_subTitle: '已完成的線上研討會',
    webinar_event: '這裡現在沒有線上研討會',
    p2p_event_title: '輔導課程',
    p2p_event_subTitle: '已完成的輔導課程',
    p2p_event: '這裡現在沒有輔導課程',

    now_live_assessment_title: '現在現場直播',
    now_live_assessment_subTitle: '已開始或將會開始的評估',
    now_live_assessment_message: '這裡現在沒有現場直播評估',

    upcoming_assessment_title: '即將來臨的現場直播評估',
    upcoming_assessment_subtitle: '即將來臨的現場直播評估',
    upcoming_assessment_message: '這裡現在沒有即將來臨的現場直播評估',

    past_live_assessment_title: '過去的現場直播評估',
    past_live_assessment_subTitle: '已完成的現場直播評估',
    past_live_assessment_message: '這裡現在沒有過去的現場直播評估',

    /**
     * <0/> get index 0 of data from Trans component
     * exp -->
     * <Trans i18nKey='training:recomended_subtitle' >
     *    <Navlink>{...something}</Navlink> --> is index 0 of Trans component
     *    <h1>{...something}</h1> --> is index 1 of Trans component
     * </Trans>
     */
    recomended_subtitle: '我們有精選課程和現場直播活動  根據你的 <0><0/></0>',
    /**
     * and we also can get data from index to replace in recommended_subtitle by like above string.
     * for exp -->
     * at the end of the recomended_subtitle above will get data from index of component to display
     * <0><0/></0> mean get data index 0 of Trans component above = <Navlink> and <0/> get data index 0 from <Navlink> = {...something}
     */
    search: '搜尋訓練',
    search_assessment: '搜尋評估',
    recomended_message: '這裡現在沒有推薦的課程和活動'
  },

  warnings: {
    delete: '你確定？'
  },

  workshop: {
    title: '我的工作坊',
    subtitle: '創造一個課程或主持一個你的現場直播活動 ',
    new_course: '創造一個新課程',
    new_event: '創造一個新現場直播活動',
    new_assessment: '創造一個新現場直播評估',
    courses_title: '已創造的課程和現場直播活動',
    courses_subtitle:
      'Edit & Publish your created course and plan live events 編輯和發佈你已創造的課程和已計劃的現場直播活動',
    tags_course: '課程',
    tags_live: '現場直播活動',
    tags_assessment: '現場直播評估'
  },

  help_center: {
    sidebar: {
      title: '我們能怎樣幫你？',
      back_to: '儀表板'
    },
    menu: {
      help_center: '幫助中心',
      whats_new: '什麼是新的',
      feedback: '反饋',
      feedback_title: '發送反饋給分享看',
      terms_and_conditions: '條款及細則',
      privacy_policy: '隱私權政策',
      contact_us: '聯繫我們'
    },
    get_started: {
      title: '開始使用分享看',
      description: {
        main: '我們把現場直播視頻集成到容易使用的課程建設工具',
        tagline:
          '使用分享看的現場廣播系統專利權，你能夠創造吸引的課程，自動化學習，和最大化知識分享'
      }
    },
    whats_new: {
      title: '開始使用分享看',
      description: {
        main:
          '你知道要創造融入說故事的學習風格課程只需要短時間，而又不會太昂貴嗎？你應該結合分享看的課程建設工具和所需的人才。你可以問我們「如何」',
        tagline: ''
      }
    },
    create_workshop: '怎樣去創造一個工作坊',
    create_course: '怎樣去創造一個課程',
    create_user: '怎樣去創造一個用戶',
    create_group: '怎樣去創造一個群組',
    create_webinar: '怎樣去創造線上研討會',
    create_live_event: '怎樣去創造現場直播活動',
    create_live_assessment: '怎樣去創造現場直播評估',
    create_live_on_mobile: '在手機上創造現場直播',
    personalize_profile: '個人化個人資料',
    manage_group: '管理群組',
    report: '報告',
    add_annotations: '怎樣去添加注釋/彈出式視窗',
    add_audio: '怎樣去添加音訊',
    add_header_and_footer: '添加頁眉和頁腳 ',
    add_image: '怎樣去添加圖片',
    add_quiz_content: '怎樣去添加測驗內容',
    add_text_content: '怎樣去添加文字內容',
    add_user: '怎樣去添加用戶',
    add_video: '怎樣去添加視頻',
    clone_course: '怎樣去複製課程',
    find_live_broadcast: '怎樣找現場廣播',
    contact_us: {
      title: '聯繫我們',
      subject_placeholder: '輸入主題*',
      content_placeholder: '輸入信息*',
      subject_required: '*主題是需要的',
      content_required: '*信息是需要的',
      submit: '發送',
      cancel: '取消',
      sent: '已發送'
    },
    feedback: {
      title: '送出反饋給分享看',
      attach_title: '附上一個文件',
      choose: '選擇文件',
      detail: '文件將會分享給支持團隊',
      content_placeholder: '打入信息*',
      content_required: '*信息是需要的',
      submit: '發送',
      cancel: '取消',
      sent: '已發送'
    }
  },
  discussion: {
    join_discussion: '加入討論'
  },
  notes: {
    title: '我的筆記',
    add_note: '添加新筆記'
  }
}
