import React, { useState, useMemo } from 'react'

import { Alert, Divider, Icon, Pagination } from 'antd'
import {
  PaginationRightAligned,
  ReportTable,
  SearchEventMargin
} from '../../../../../styled'
import moment from 'moment'
import { Search } from '../../../../../../../components'
import TableCoverImage from './internal/TableCoverImage'
import TableInfoColumns from './internal/TableInfoColumns'

const customExpandIcon = props => {
  return <Icon type={props.expanded ? 'up' : 'down'} {...props} />
}

const millisToMinutesAndSeconds = millis => {
  if (millis === 0) {
    return 0
  }
  let minutes = Math.floor(millis / 60000)
  let seconds = ((millis % 60000) / 1000).toFixed(0)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

const UserCourseTable = props => {
  const {
    t,
    data: {
      courses: { data, loading }
    },
    isUserAsHost: isHost
  } = props

  const participantCourseColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'title',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      {
        title: t('v2:time_spent'),
        dataIndex: 'spending_time',
        render: val => {
          return val || '0 mins'
        }
      },
      {
        title: t('reports:quizzes'),
        dataIndex: 'quizzes',
        render: val => {
          return val || '0'
        }
      },
      {
        title: t('v2:pass_score'),
        dataIndex: 'pass_score',
        render: val => {
          return val || 'Not Set'
        }
      },
      {
        title: t('reports:score'),
        dataIndex: 'score',
        render: (text, record) => {
          const {
            avg_scores = 0,
            pass_equal = false,
            pass_course = false
          } = record
          return (
            <h4
              style={{
                color:
                  avg_scores === null
                    ? 'black'
                    : pass_equal
                    ? 'black'
                    : pass_course
                    ? 'green'
                    : 'red'
              }}
            >
              {avg_scores ? avg_scores.toFixed(2) + '%' : 'n/a'}
            </h4>
          )
        }
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const hostCourseColumns = useMemo(
    () => [
      {
        title: t('v4:item').toLowerCase(),
        dataIndex: 'cover',
        render: cover => <TableCoverImage cover={cover} />
      },
      {
        title: '',
        dataIndex: 'info',
        render: (text, record) => <TableInfoColumns record={record} />
      },
      {
        title: t('reports:quizzes'),
        dataIndex: 'quizzes',
        render: val => val.length || '0'
      },
      {
        title: t('labels:lessons'),
        dataIndex: 'lessons',
        render: record => record.length
      },
      {
        title: t('v2:pass_score'),
        dataIndex: 'pass_score',
        render: val => val || 'Not Set'
      },
      { title: t('v4:detail') }
    ],
    []
  )

  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(0)

  const getData = (current, pageSize) => {
    let searchData = ''
    if (search) {
      searchData = data.filter(item =>
        item.title.toLowerCase().includes(search.toLowerCase())
      )
    }
    const dataToFilter = search ? searchData : data
    setTotal(search ? searchData.length : data.length)
    return dataToFilter.slice((current - 1) * pageSize, current * pageSize)
  }

  const onPaginationChange = page => setCurrentPage(page)
  const onSearch = value => {
    setSearch(value)
    setCurrentPage(1)
  }

  const courses = useMemo(() => getData(currentPage, 10), [
    data,
    currentPage,
    search
  ])

  return (
    <ReportTable
      isDateActive
      borderRadius
      loading={loading}
      expandIconColumnIndex={isHost ? 5 : 6}
      columns={isHost ? hostCourseColumns : participantCourseColumns}
      dataSource={courses}
      expandIcon={customExpandIcon}
      expandRowByClick
      expandIconAsCell={false}
      pagination={false}
      expandedRowRender={record => {
        if (record && record.lessons && record.lessons.length > 0) {
          return renderLessonTable(record, t, isHost)
        } else {
          return (
            <Alert
              message="Sorry ! No Lesson Available For This Course."
              type="info"
              showIcon
            />
          )
        }
      }}
      title={() => (
        <>
          <div className="user-report-table-title">
            <div>
              <SearchEventMargin>
                <Search
                  placeholder={t('reports:search_course')}
                  onChange={e => onSearch(e.target.value)}
                />
              </SearchEventMargin>
            </div>
            <div className="user-report-table-pagination">
              <span>{t('general:page').toUpperCase()}</span>
              <PaginationRightAligned>
                <Pagination
                  total={total}
                  current={currentPage}
                  pageSize={10}
                  simple
                  onChange={onPaginationChange}
                />
              </PaginationRightAligned>
            </div>
          </div>
          <Divider style={{ margin: '0px' }} />
        </>
      )}
    />
  )
}

const renderLessonTable = (record, t, isHost) => {
  let totalTime = []
  record &&
    record.lessons &&
    record.lessons.length > 0 &&
    record.lessons.map(lesson =>
      lesson.checkin_checkout && lesson.checkin_checkout.length > 0
        ? lesson.checkin_checkout.map(i =>
            totalTime.push(moment(i.checkout).diff(moment(i.checkin)))
          )
        : 0
    )

  const total = totalTime.reduce((total, item) => total + item, 0)

  const isParticipant = !isHost
  return (
    <div className="lesson-table">
      <table>
        <thead>
          <tr>
            <th>{t('reports:lesson')}</th>
            {isParticipant && (
              <>
                <th>{t('v2:checkin_checkout')}</th>
                <th>{t('v2:time_spent')}</th>
                <th>{t('reports:score')}</th>
              </>
            )}
            {isHost && <th>{t('general:date')}</th>}
          </tr>
        </thead>
        <tbody>
          {record &&
            record.lessons &&
            record.lessons.length > 0 &&
            record.lessons.map(lesson => (
              <tr>
                <td>{lesson.title}</td>
                {isParticipant && (
                  <>
                    <td>
                      {lesson.checkin_checkout &&
                      lesson.checkin_checkout.length > 0
                        ? lesson.checkin_checkout.map(i => (
                            <div>
                              <span>{moment(i.checkin).format('HH:mm')} </span>-
                              <span> {moment(i.checkout).format('HH:mm')}</span>
                            </div>
                          ))
                        : '--'}
                    </td>
                    <td>
                      {lesson.checkin_checkout &&
                      lesson.checkin_checkout.length > 0
                        ? lesson.checkin_checkout.map(i => (
                            <div>
                              {millisToMinutesAndSeconds(
                                moment(i.checkout).diff(moment(i.checkin))
                              )}{' '}
                              ({t('v2:minutes')})
                            </div>
                          ))
                        : '--'}
                    </td>
                    <td>{lesson.quize_scores}</td>{' '}
                  </>
                )}
                {isHost && (
                  <td>{moment(lesson.created_at).format('YYYY-MM-DD')}</td>
                )}
              </tr>
            ))}
          {isParticipant && (
            <tr>
              <td></td>
              <td>{t('v2:total')}</td>
              <td>
                {millisToMinutesAndSeconds(total)} ({t('v2:minutes')})
              </td>
              <td>{record.total_points}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default UserCourseTable
