import Settings from './Settings'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actions from '../../../../store/actions'

const mapStateToProps = state => ({
  list: state.organizations.list.rows,
  isFetching: state.organizations.isFetching,
  events: state.events,
  user: state.user
})

const mapDispatchToProps = dispatch => bindActionCreators({
  get: actions.organizations.get
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
