import React, { useState } from 'react'
import { Row, Col, Form, Input, Checkbox } from 'antd'
import './TextSection.scss'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const valuesType = {
  course_obj: 'course_obj',
  course_outline: 'course_outline',
  is_shared_profile: 'is_shared_profile'
}

const TextSection = props => {
  const {
    form: { getFieldDecorator },
    onValues,
    values
  } = props

  const { t } = useTranslation()

  const [marketplaceValues, setMarketplaceValues] = useState(
    values || {
      course_obj: '',
      course_outline: '',
      is_shared_profile: false
    }
  )

  const handleValue = _.debounce((v, f) => handleMarketplaceValues(v, f), 1000)

  const handleMarketplaceValues = (val, field) => {
    const newValueParam = valuesType[field]

    const newValue = { ...marketplaceValues, [newValueParam]: val }
    setMarketplaceValues(newValue)
    if (onValues) {
      onValues(newValue)
    }
  }

  const getMessage = params => {
    switch (params) {
      case 'course_obj':
        return 'Channel Objectives is required.'
      case 'course_outline':
        return 'Channel Outline is required'
      default:
        return 'Please enter a value.'
    }
  }

  const generateEditor = (title, params, description) => {
    return (
      <div className="margin_top ">
        <Form.Item label={title} colon={false} required={true}>
          {getFieldDecorator(params, {
            rules: [
              {
                required: params === 'description' ? false : true,
                message: getMessage(params)
              }
            ],
            initialValue: description
          })(
            <Input.TextArea
              placeholder={t('v4:text')}
              autoSize={{ minRows: 5, maxRows: 20 }}
              onKeyDown={e => {
                if (
                  e.keyCode === 32 &&
                  (e.target.value.charAt(e.target.selectionStart - 1) === ' ' ||
                    e.target.value.charAt(e.target.selectionStart) === ' ')
                ) {
                  e.preventDefault()
                }
              }}
              onChange={e => handleValue(e.target.value, params)}
              style={{ fontSize: '1.6rem', resize: 'none' }}
              wrap={'soft'}
            />
          )}
        </Form.Item>
      </div>
    )
  }
  return (
    <div style={{ width: '100%' }}>
      <Row className="quill-div_row">
        <Col span={24}>
          {generateEditor(
            'Channel Objectives',
            'course_obj',
            marketplaceValues.course_obj
          )}
          {generateEditor(
            'Channel Outline',
            'course_outline',
            marketplaceValues.course_outline
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item colon={false} required={true}>
            {getFieldDecorator('is_shared_profile', {
              valuePropName: 'checked',
              initialValue: marketplaceValues.is_shared_profile
            })(
              <Checkbox
                className="marketplace__checkbox"
                onChange={e =>
                  handleValue(e.target.checked, 'is_shared_profile')
                }
              >
                {t('v3:allow_sharing_trainer_info')}
              </Checkbox>
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default TextSection
