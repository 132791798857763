import * as types from '../types'

export const fetchRequest = payload => ({
  type: types.FETCH_REQUEST,
  payload
})

export const fetchQuizes = () => ({
  type: types.FETCH_QUIZES
})

export const loadQuizes = payload => ({
  type: types.LOAD_QUIZES,
  payload
})

export const updateQuizes = payload => ({
  type: types.UPDATE_QUIZES,
  payload
})

export const fetchQuizById = id => ({
  type: types.FETCH_QUIZ_BY_ID,
  payload: {
    id
  }
})

export const fetchQuizByIdAnswers = id => ({
  type: types.FETCH_QUIZ_BY_ID_ANSWERS,
  payload: {
    id
  }
})

export const loadQuizById = payload => ({
  type: types.LOAD_QUIZ_BY_ID,
  payload
})

export const createQuiz = payload => ({
  type: types.CREATE_QUIZ,
  payload
})

export const validateQuiz = (data, id) => ({
  type: types.VALIDATE_QUIZ,
  payload: {
    data,
    id
  }
})

export const loadQuizValidate = payload => ({
  type: types.LOAD_QUIZ_VALIDATE,
  payload
})

export const updateQuiz = (data, id) => ({
  type: types.UPDATE_QUIZ,
  payload: {
    data,
    id
  }
})

export const updateQuestions = (data, id) => ({
  type: types.UPDATE_QUESTIONS,
  payload: {
    data,
    id
  }
})

export const loadQuiz = payload => ({
  type: types.LOAD_QUIZ,
  payload
})

export const removeQuizById = id => ({
  type: types.REMOVE_QUIZ_BY_ID,
  payload: {
    id
  }
})

export const generateAiQuiz = payload => ({
  type: types.GENERATE_AI_QUIZ,
  payload
})
